const data = {
  trainers: [
    {
      "_id": {
        "$oid": "582d698e2dd8e45444cbb4d5"
      },
      "lastUpdated": {
        "$date": "2018-01-19T16:01:34.574Z"
      },
      "createdAt": 1479371150160,
      "email": "darryl@thefitnessexplorer.com",
      "firstName": "Darryl",
      "surname": "Edwards",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "darryl_edwards_rkU5YysZe",
      "provider": "local",
      "salt": "mRc2c3vP/XFvPFuCz/S0vg==",
      "hashedPassword": "d3MpNZgumX403EIQ1+Dy9SlWTtuR6s+h1k1NEfSeYnnsgDbAc5UOFV9Z2r4TbkvfN2W2fP7TWQBSaaHCt0gNGQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9ilj3Kvq-IMG_3281.JPG"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Group Classes",
            "Mobile Trainer",
            "Nutrition",
            "Online Coaching"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 2,
          "price": {
            "min": 75,
            "max": 95
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rb6KMqvC-IMG_3210.JPG",
            "size": {},
            "offset": 0
          },
          "companyWebsite": null,
          "locations": [
            {
              "geolocation": [
                -0.25073555850096646,
                51.49290069269793
              ],
              "city": "London",
              "postcode": "W4",
              "_id": {
                "$oid": "5a62165e765eba094646f91f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7956552470",
            "code": "+44"
          },
          "biography": "My name is Darryl Edwards, owner of Fitness Explorer Training & Nutrition based in London, England.\n\nDarryl Edwards, MSc. Founder of HEALTH Unplugged and owner of Fitness Explorer Training, is an international speaker, coach, nutritionist and author of Paleo Fitness and Paleo from A to Z.  He has been published in titles such as Men’s Health, Women’s Health, Elle, Men's Fitness and featured on the BBC, ABC in Australia and the international documentary We Love Paleo.\n\nAfter almost two decades working as a technologist in investment banking, Darryl transformed his health after adopting an ancestral model to well-being.  Darryl now advises people on maintaining a healthy lifestyle – amidst the epidemic of obesity and other chronic lifestyle diseases.\n\nAs the founder of the PRIMAL PLAY methodology, he makes activity fun, effective and engaging while getting individuals healthier, fitter and stronger in the process. He specialises in working with children from 4 to 94 - fitness for those who hate to exercise - as well as for those who love it but relish a new challenge.\n\nI teach groups, conduct one-to-one training sessions and host seminars, focussing on Paleo/Primal/Ancestral nutrition/fitness, bodyweight exercises, strength/conditioning and play.",
          "offers": "Please contact me for details",
          "qualifications": [
            "Author: Paleo Firness",
            "Author Paleo from A-Z",
            "Founder: Primal Play",
            "Bsc computing &  Information Systems",
            "Msc Information Technology"
          ],
          "strapLine1": "Movement Coach, Natural Lifestyle Educator, Nutritionist and Creator of the Primal Play Method™"
        },
        "score": 66,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 17,
      "password_reset_time": 1485860930331,
      "password_reset_code": "X",
      "feature_in_area": "none",
      "__v": 12
    },
    {
      "_id": {
        "$oid": "582da3202dd8e45444cbbb59"
      },
      "lastUpdated": {
        "$date": "2018-01-19T15:35:16.973Z"
      },
      "createdAt": 1479385888766,
      "providerId": "10154682193120365",
      "provider": "facebook",
      "surname": "Filipovych",
      "firstName": "Mykola",
      "email": "philipovych@yandex.ru",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154682193120365/",
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 15,
          "price": {
            "min": 40,
            "max": 50
          },
          "locations": [
            {
              "geolocation": [
                -0.017595,
                51.50975
              ],
              "city": "London",
              "postcode": "E14",
              "_id": {
                "$oid": "5a621034765eba094646f911"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "78602075343",
            "code": "+44"
          },
          "biography": "Mykola is a motivated and accomplished trainer with over 6 years experience in sport, fitness and as a personal trainer. Street workout was Mykola’s first training experience and it became his true passion. Calisthenics training allowed him to expand his skills , built up his body and letting him to evolved as a trainer. He has been working with wide variety of clients and always achieving great results.",
          "offers": "Please contact me for details",
          "qualifications": [
            "Premier Diploma in Fitness Instruction",
            "Personal Training - STR FITNESS ACADEMY 2013",
            "First Aid"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/XTvIZK7v_profilePicture.jpeg"
        },
        "score": 60,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "mykola_filipovych_HkY77Qibe",
      "approved": true,
      "num_votes": 13,
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "583655d85ef7b35807704d72"
      },
      "lastUpdated": {
        "$date": "2018-01-19T15:25:22.070Z"
      },
      "createdAt": 1479955928719,
      "providerId": "10153995766231820",
      "provider": "facebook",
      "surname": "Faupel",
      "firstName": "Marc",
      "email": "marcfaupel@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10153995766231820/",
          "disciplines": [
            "Functional Training",
            "Nutrition",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 8,
          "price": {
            "min": 70,
            "max": 90
          },
          "locations": [
            {
              "geolocation": [
                -0.15704900000002908,
                51.49782159999999
              ],
              "city": "London",
              "postcode": "SW1X",
              "_id": {
                "$oid": "5a620de2765eba094646f90c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Oneshoo Ambassador",
            "BSc (Hons) Sports Science Brunel University",
            "Advanced Functional Trainer",
            "Strength & Conditioning Coach (Poliquin International)",
            "Corrective Exercise (CHEK Institute & NASM)",
            "Advanced Metabolic Typing",
            "Functional Diagnostic Nutrition (Functional Medicine Uni)"
          ],
          "companyName": null,
          "companyWebsite": null,
          "phone": {
            "number": "7976268570",
            "code": "+44"
          },
          "biography": "A specialist in Functional Exercise, Strength & Conditioning Marc leads the team at The Natural Fitness Studio. A Sports Science graduate of Brunel University, with many years of experience, he brings a very holistic approach to the Studio.\n\nHe has been influenced by studying Kinesiology, Functional Nutrition, Natural Movement and energy work. He has worked with a wide range of people including many high profile clients and companies such as BBC, Reuters & American Express.   \n\nHis own training interests are varied and reflect his multidisciplinary approach to health - they include natural and primal movement, kettlebell training, rugby, football, meditation, chi gong, running and generally lifting heavy things!",
          "offers": "Please contact me for details",
          "strapLine1": "The Natural Fitness Studio"
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fg4g5TNq-filename.jpeg"
        },
        "score": 69,
        "type": "individual",
        "parent": {
          "$oid": "5888bd97739f5b6c586cbaf6"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "marc_faupel",
      "approved": true,
      "num_votes": 7,
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "583740fd5ef7b35807706b68"
      },
      "lastUpdated": {
        "$date": "2018-01-19T14:02:25.710Z"
      },
      "createdAt": {
        "$numberLong": "1480016125301"
      },
      "email": "r.c.dymek@live.com",
      "firstName": "Ross",
      "surname": "Dymek",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "ross_dymek",
      "provider": "local",
      "salt": "mFdBNccVheKYPbxdKHakmA==",
      "hashedPassword": "kDMUw3xf+q/Zw7bE3z2lyROwCQpOgpFj/dBcmJ2j+irlZT9A9qw5X39Zp3C89dUqNPOJrrx8fEreT2WJTnJiig==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Hkaun1MH-IMG_2934.PNG"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Boxing",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "price": {
            "min": 40,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SyIDPYcO-IMG_2554.JPG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.457055,
                51.356614
              ],
              "city": "Weybridge",
              "postcode": "KT13",
              "_id": {
                "$oid": "5a61fa71765eba094646f8f1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7949721072",
            "code": "+44"
          },
          "biography": "I have been involved in the fitness industry for around 8 years now, having started coaching in college when i was studying sport and exercise science. It was during my time on this course that I gained experience in the health and leisure industry as well as filling out my work experience hours in the hilton hotel gym and spa. \n\nFollowing my time at college. I then went on to study a Bsc in Strength and Conditioning Science. It was here that I established a keen interest in athletic development having completed an internship in a Strength and Conditioning based facility while maintaining relations with my clients through personal training.\n\nOver the years I have managed to adapt my coaching philosophy as well as my knowledge of sport, health and exercise to allow me to help anyone regardless of their circumstances achieve their goals. In having a wealth of both knowledge and experience in many areas of the industry, i am confident that I will be able to help anyone achieve success!",
          "offers": "Please contact me for details",
          "qualifications": [
            "Level 3 Diploma in Personal Training (QCF)",
            "Level 3 Diploma in Exercise Nutrition (QCF)",
            "Sports Nutrition",
            "Nutrition for exercise (QCF)",
            "Group Training (QCF)",
            "Pad Work for Personal Trainers (QCF)",
            "Emergency First Aid (QCF)",
            "Bachelor’s Degree in Strength and Conditioning Science (Bsc)",
            "Level 2 Diploma in Fitness instructing (QCF)"
          ],
          "strapLine1": "Tapered Fit Training"
        },
        "score": 66,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58374ec35ef7b35807706d10"
      },
      "lastUpdated": {
        "$date": "2018-01-19T13:55:28.049Z"
      },
      "createdAt": {
        "$numberLong": "1480019651056"
      },
      "email": "caro.pemberton@inspiring-fitness.co.uk",
      "firstName": "Caro",
      "surname": "Pemberton",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "caro_pemberton_SyipC6Vze",
      "provider": "local",
      "salt": "SHcNXCO56klWDsAjEaDmJw==",
      "hashedPassword": "nwBVEPU9PV4wRrXa48mLH80Rps9+05ywwcWD1xnULrggntTlOc5EjLs0xlPso0YleIsfWOBCdN8Fc8dLvQUjPQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cNoxPihw-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 30,
          "price": {
            "min": 30,
            "max": 30
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/I9BJpMfO-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -1.806590300000039,
                52.4676406
              ],
              "city": "Birmingham",
              "postcode": "B26",
              "_id": {
                "$oid": "5a61f8d0765eba094646f8eb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7514002001",
            "code": "+44"
          },
          "biography": "Hi I'm Caro Pemberton, I am a personal trainer based in\nBirmingham.\n\nThe strategies I like to use to help me keep my clients fit, feeling good and, losing the weight that they have targeted to achieve throughout their personal training sessions, I would say that it all comes down to how well I keep track of the clients progress and update their records accurately and focusing on what every client wants from personal training. Providing one to one support to my clients helps me give them all of the help necessary to enable them to achieve their health & fitness goals in and out side of Birmingham.\n\nIt is essential that I make the right choices of exercise for you so you get what you want from your personal training sessions and keeping exercise safe and enjoyable every time.\n\n \n\nI also produce weight management plans that help clients reduce their weight much faster. i am an expert at doing this\n\n \n\nI keep my clients feeling happy, motivated, looking good and confident within themselves whilst they are doing personal training with me. I will continue on running my service like this throughout my career being a personal trainer.",
          "offers": "Please contact me for details",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ]
        },
        "score": 57,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58386263e2b474023bb1d6dd"
      },
      "lastUpdated": {
        "$date": "2018-01-19T02:23:41.851Z"
      },
      "createdAt": 1480090211670,
      "providerId": "1639188769440810",
      "provider": "facebook",
      "surname": "Walsh",
      "firstName": "Bill",
      "email": "bill.walsh1992@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1639188769440810/",
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 15,
          "price": {
            "min": 50,
            "max": 50
          },
          "locations": [
            {
              "geolocation": [
                -0.5319273979887843,
                51.54556281830224
              ],
              "city": "Iver",
              "postcode": "SL0",
              "_id": {
                "$oid": "5a6156ad765eba094646f88d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7533714771",
            "code": "+44"
          },
          "biography": "Hi my name is Bill and I am a personal trainer and Strength and Conditioning Coach. I also have expertise in body transformations. I have been training clients since 2013. Originally in Ireland and now in the UK. I moved to London at the beginning of 2016 with the opportunity of working with David Kingsbury at his personal training studio in Pinewood Studios (based in Iver, Buckinghamshire).\n\nI have been passionate about health and fitness all my life – playing many sports, including Hurling at County level. This passion led me to a career in the Irish Army and it was here I really developed my fitness. Learning how far the human body can be pushed and then pushed some more! With my time in the army coming to a natural end my focus has been to build a career in health and fitness.\n\nWith expertise and passion I promise to help you reach your health, fitness and/or sports goal. I’ll give you nutritional advice and encourage you every step of the way, pushing you to your limit and  often beyond. I promise to motivate you but will also hold you accountable for the results you want to achieve.\n\nWith our combined determination and desire we can achieve great results in great time!",
          "offers": "Please contact me for details",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 4: Strength & Conditioning"
          ],
          "strapLine1": "Bill Walsh Fitness"
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UDS7MXRg-image.jpeg"
        },
        "score": 69,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "bill_walsh_Sy3PMyUGe",
      "approved": true,
      "num_votes": 1,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5838652fe2b474023bb1d72b"
      },
      "lastUpdated": {
        "$date": "2018-01-19T02:17:27.412Z"
      },
      "createdAt": 1480090927716,
      "email": "nicky@nickybaker-pt.co.uk",
      "firstName": "Nicky",
      "surname": "Baker",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "nicky_baker",
      "provider": "local",
      "salt": "tY1vyeUvsU2L2TRw9l3Ugw==",
      "hashedPassword": "phrnp+JFJog16BuYm1uv5cblsM4pmBC/BODibEWOaeN60AWtaNWBnhtKuCkzylIaMzwTNylT4s6ad9bSy0tGKw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5RcZJ902-IMG_0395.JPG"
        },
        "personal": {
          "disciplines": [
            "Endurance",
            "Nutrition",
            "Strength & Conditioning",
            "Toning"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Personal Trainer",
          "radius": 3,
          "price": {
            "min": 45,
            "max": 45
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YNeozVMG-IMG_0017.JPG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.25926300000003266,
                51.40066299999999
              ],
              "city": "New Malden",
              "postcode": "KT3",
              "_id": {
                "$oid": "5a615537765eba094646f88c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7525027447",
            "code": "+44"
          },
          "biography": "Welcome!\nI'm a female trainer working with both men and women in my home/garden, theirs or in the park.\nMy clients ages range from 22 to 57 - so it's never too early or too late.\n\nFitness does not have to be boring, massively expensive or time consuming.\n\nEvery programme I design is tailored to a specific individual with specific goals. And, no two weeks would be the same.\n\nWhether you want to build strength, improve fitness, lose weight, tone up, correct postural imbalances or, have specific issues such as knee or back pain I can help.\n\nWorking alongside physios, chiropractors and osteopaths ensures the best results after injuries and a return to fitness in a safe and controlled way. \nI specialise in:\n\ncardiovascular fitness\nresistance training (muscular strength and endurance)\nweight management\nnutrition\nlifestyle changes\nI'm always happy to have a chat with no obligation :-)",
          "offers": "Please contact me for details",
          "qualifications": [
            "Level 3 YMCA Awards Diploma in Personal Training",
            "Level 3 CYQ certificate in Anatomy and Physiology",
            "Level 3 CYQ certificate in Nutrition",
            "Level 3 CYQ certificate in Personal Training",
            "Level 3 CYQ certificate in Instructing Circuit Training",
            "Level 3 CYQ certificate in Instructing Exercise in the Outdoor Environment",
            "Level 3 CYQ certificate in Sports Conditioning",
            "Level 3 YMCA Awards Diploma in Exercise Referral",
            "Level 2 CYQ certificate in gym based instructing",
            "Level 2 CYQ certificate in Nutrition",
            "Level 2 CYQ certificate in Anatomy and Physiology"
          ]
        },
        "score": 57,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "583963aee2b474023bb1f77e"
      },
      "lastUpdated": {
        "$date": "2018-01-19T02:12:46.264Z"
      },
      "createdAt": 1480156078612,
      "providerId": "350219732020285",
      "provider": "facebook",
      "surname": "Pinchen",
      "firstName": "Rich",
      "email": "rich@rpinchen.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/350219732020285/",
          "disciplines": [
            "Rehabilitation",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "price": {
            "min": 45,
            "max": 45
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IRV7eA1i-IMG_3466.PNG",
            "size": {},
            "offset": 0
          },
          "yearOfBirth": 1971,
          "biography": "I have been a level three qualified Personal Trainer for 17 years. I am fully insured and am a member of the NRPT. I am fully Qualified in Nutrition so we can incorporate the use of food diaries and meal plans in our programs which often with some simple suggestions can have a great impact with training.    My rate includes a weekly and monthly training programme for times for when you train alone which will be supported by email or texts to make sure you complete your exercise when not with me.  For the training I bring everything needed to the home/office/park or gym if you have the use of one.   Finally, I offer a complimentary 45 minute no obligation consultation so we can discuss your training. Please contact me on the details below so we can discuss what we can achieve together.  Richard Pinchen   m. 07493305771   e. info@rpinchen.co.uk   w. www.rpinchen.co.uk",
          "strapLine1": "I specialise in those new to fitness and may not feel comfortable using a gym",
          "locations": [
            {
              "geolocation": [
                -0.26599609999993845,
                51.4926361
              ],
              "city": "London",
              "postcode": "W4",
              "_id": {
                "$oid": "5a61541e765eba094646f88a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7493305771",
            "code": "+44"
          },
          "offers": "Please contact me for details",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Nutrition"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/W9bSgnQV_profilePicture.jpeg"
        },
        "score": 69,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "rich_pinchen_B1P2m1PGx",
      "approved": true,
      "num_votes": 1,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "583b3e0be2b474023bb233c2"
      },
      "lastUpdated": {
        "$date": "2018-01-19T00:40:04.404Z"
      },
      "createdAt": 1480277515574,
      "email": "hannah@hfsfitness.co.uk",
      "firstName": "Hannah",
      "surname": "Smith",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "hannah_smith",
      "provider": "local",
      "salt": "h1ajLxqbS4p38eCAbARf/A==",
      "hashedPassword": "Fo6duZbiO57mGhZTI+dpweuTJQ7NQoH4VyKhmNwZkPA1AfrB7SOnB5hhodLpcFYW4yRrrJtzBiyJZVjDVjOO5A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZvYFwpsI-IMG_2106.JPG"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Group Classes",
            "Nutrition",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Personal Trainer",
          "price": {
            "min": 20,
            "max": 30
          },
          "radius": 10,
          "locations": [
            {
              "geolocation": [
                -1.636106847615635,
                55.03395182935523
              ],
              "city": "Newcastle upon Tyne",
              "postcode": "NE13",
              "_id": {
                "$oid": "5a613e64765eba094646f867"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7540776174",
            "code": "+44"
          },
          "biography": "After graduating from university and spending the last six years working in the NHS as a qualified nurse, Hannah made the decision to follow her passion for exercise and nutrition by following her dreams of becoming a personal trainer.\n\nHannah understands from her previous experience as a health professional the importance of exercise and nutrition for people of all ages thus from this has developed a deep passion to spread her knowledge and educate her clients to make effective lifestyle changes.\n\nWith a keen interest in the scientific theory underpinning the practical application of exercise and performance Hannah takes great pride in striving to achieve the very best results for her clients.\n\nOn a personal level, Hannah is a highly ambitious and goal driven individual taking her own training and nutrition seriously to increase her endurance and performance in her chosen sports of snowboarding and rock climbing believing that each training session brings her desired goals closer.",
          "offers": "Please contact me for details",
          "qualifications": [
            "Level 3 Personal Training",
            "Level 2 Gym Instructor",
            "Qualified Nurse BSc",
            "Six years nursing experience"
          ]
        },
        "score": 57,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "583bf13ce2b474023bb24a80"
      },
      "lastUpdated": {
        "$date": "2018-01-19T00:34:46.183Z"
      },
      "createdAt": 1480323388303,
      "email": "neiltargetfit@gmail.com",
      "firstName": "Neil",
      "surname": "Heppel",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "neil_heppel_rJ4SZutMx",
      "provider": "local",
      "salt": "XfrRKMFPKnHqBiS1SBkLWQ==",
      "hashedPassword": "13ClOagxkl29g4DcplvcvBdz1CYHLoU0R/bXCg7iMUGi3f3VAzlHoG1h85mI6RVTMMLpD482YMHWhmNCEMlBIQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ASh7w2ja-logo2.jpg"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 50,
          "price": {
            "min": 30,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0A0GsG6j-profile%20image.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                0.15097240000000056,
                51.5984718
              ],
              "city": "Romford",
              "postcode": "RM5",
              "_id": {
                "$oid": "5a613d26765eba094646f865"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7961210272",
            "code": "+44"
          },
          "biography": "I have been in the health & fitness industry for my entire career which is over 15 years experience, where I have worked my way from a Gym Instructor to becoming a Club Manager.  Working as a Personal Trainer and a Stroke Rehabilitation Instructor I am now doing what I love, training people so that they achieve their goals through training and nutritional advice. I have trained a variety of people of levels ranging from amateur athletes through to older clientele with medical conditions, therefore building upon my knowledge and experience.\n\nMy background is very sporty and this has always been an interest. My sporting ground is athletics, I have represented the Essex cross-country and was ranked 7th in the country at 3000 metres.\n\nIn 2015 I completed my 1st marathon in Edinburgh with a time of 3hours 18mins.",
          "qualifications": [
            "Active IQ Advanced Gym & Personal Trainer Instructor",
            "Nutrition & Weight Management + medical Conditions",
            "Core Stability",
            "ARNI Stroke Rehabilitation Instructor",
            "Lifetime Health & Fitness Gym Instructor",
            "Lifetime Health & Fitness Circuit Trainer"
          ],
          "offers": "Please contact me for details"
        },
        "score": 57,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "583c0efde2b474023bb24e3d"
      },
      "lastUpdated": {
        "$date": "2018-01-19T00:20:42.757Z"
      },
      "createdAt": 1480331005102,
      "email": "nataliehendy16@googlemail.com",
      "firstName": "Natalie",
      "surname": "Hendy",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "natalie_hendy",
      "provider": "local",
      "salt": "2AJSfXFBw9+Uqto5yahOFQ==",
      "hashedPassword": "hRGpAazURozRbG13DMHgOxOr53vvG6fyCoduMxmpFl/91v+dP/T84Pq3GThAguJHEbLVJbqzNFxgnHWTZsvAuQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PlzLT3Fm-IMG_0558.JPG"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Nutrition",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 30,
            "max": 30
          },
          "locations": [
            {
              "geolocation": [
                -1.3069779999999582,
                52.970142
              ],
              "city": "Ilkeston",
              "postcode": "DE7",
              "_id": {
                "$oid": "5a6139da765eba094646f862"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7967854594",
            "code": "+44"
          },
          "biography": "I have a Premier Global Level 3 Advanced Personal Trainer qualification as well as over 8 years experience in the fitness industry with many success stories. I can understand what you feel like as I  myself  have struggled with weight gain after the birth of my son 15 years ago when I developed a thyroid disorder.\n\nMy role as a Personal Trainer is to help you reach your goal. At the moment I have a wide variety of clients working towards their goals of weight loss, weight gain, toning and firming, confidence and self esteem and injury recovery, as well as 10k runs, a half marathon and general fitness.I help with diet as well as fitness and practice functional fitness on all three planes of motion, to work with the body to improve life function, muscle balance, pressure pointing, postural analysis and much more. I work with men and women, old and young, fit and unfit, injured or healthy.",
          "qualifications": [
            "Premier Global Level 3 Advanced Personal Trainer",
            "Active IQ Certificate in Personal Training",
            "GP referrals qualification",
            "First Aid and Adult CPR",
            "Certificate in Sports Psychology",
            "Fitness and Nutrition",
            "Premier Global Gym Instructor Certificate"
          ],
          "offers": "Please contact me for details"
        },
        "score": 57,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "583ca82fe0dfa72d2acfb515"
      },
      "lastUpdated": {
        "$date": "2018-01-18T23:39:39.762Z"
      },
      "createdAt": 1480370223312,
      "providerId": "10206068815157836",
      "provider": "facebook",
      "surname": "Clapham",
      "firstName": "Robert",
      "email": "clapham.robert@googlemail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10206068815157836/",
          "disciplines": [
            "Bootcamp",
            "Nutrition",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "price": {
            "min": 19,
            "max": 38
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/XbukJWyG-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -1.152195436803492,
                52.967027710405546
              ],
              "city": "Nottingham",
              "postcode": "NG3 5BP",
              "_id": {
                "$oid": "5a61303b765eba094646f849"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7735512513",
            "code": "+44"
          },
          "biography": "Personal Trainer and Nutritionist specialising in weight loss and sports specific fitness. \nEnjoy 1-2-1 Personal Training or train with a friend or two and reduce the cost.\nI run Fun Fit Bootcamps every Tuesday at 9.10am, Wednesday at 7.30pm and Friday at 6.15pm. All ages and abilities welcome.\n\nContact me for your free consultation",
          "qualifications": [
            "Premier - Level 3 Diploma in Fitness Training & Sports Therapy",
            "Postural Assessment & Correction",
            "Premier - Level 2 Certificate in Gym. Premier - Certificate in Kettlebell Training",
            "Premier - Core Stability Ball",
            "Premier - Exercise for Special Polutlations & Consultation Skills",
            "Premier - Fitness Instruction & Gymnasium Management",
            "Premier - Nutrition & Weight Management",
            "SAQ (Speed Agility and Quickness)"
          ],
          "strapLine1": "Fun Fit"
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2BRbNAQa-filename.jpeg"
        },
        "score": 66,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "robert_clapham_HyvVumcGg",
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "583d8053e0dfa72d2acfd069"
      },
      "lastUpdated": 1480426277531,
      "createdAt": 1480425555825,
      "email": "gninova@yahoo.com",
      "firstName": "Gergana",
      "surname": "Ninova",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "gergana_ninova_Byn8lWize",
      "provider": "local",
      "salt": "ShhI/V22i2rTatwW9Ax+OQ==",
      "hashedPassword": "snlgNQdezS08fCCeg4I4EphjC+Fss8UVZ1N6LYIF5zqh87bQpr64ZBNnuHo768nq14ws7F0JmDIGk0hIBLfJtA==",
      "settings": {
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/20ITokw6-20160509_162408.jpg"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "HIIT",
            "Personal Training",
            "Rehabilitation",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Personal Trainer",
          "radius": 6,
          "price": {
            "min": 30,
            "max": 30
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rEkqlBIX-%D0%A1%D0%BD%D0%B8%D0%BC%D0%BA%D0%B00915.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.16807500000004438,
                51.420845
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "SW17 9LH"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7448808315"
          }
        },
        "score": 59,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false
    },
    {
      "_id": {
        "$oid": "583d958fe0dfa72d2acfd317"
      },
      "lastUpdated": {
        "$date": "2018-01-18T23:26:23.534Z"
      },
      "createdAt": 1480430991331,
      "email": "spencertrottpt@hotmail.com",
      "firstName": "spencer",
      "surname": "trott",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "spencer_trott_SkP5rGoGe",
      "provider": "local",
      "salt": "F4T2+Hc7/aWHkAFvci/DOA==",
      "hashedPassword": "QXSbCGg0/o8BKitcZ+1J2kRRmNi3U0h1N6UHWABe6u/eMcnpgvYCNkZ/KlSm+BSi24bf3wxN+DAfJ1oab3qgCg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/V36j2OQ4-STPTpic.jpg"
        },
        "personal": {
          "disciplines": [
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 40,
            "max": 60
          },
          "locations": [
            {
              "geolocation": [
                -0.9665469999999914,
                51.476798
              ],
              "city": "Reading",
              "postcode": "RG4 8TP",
              "_id": {
                "$oid": "5a612d1f765eba094646f847"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7958431723",
            "code": "+44"
          },
          "biography": "Health and fitness is something I’m passionate about.  By sharing my experience and knowledge I can enhance your fitness levels through clear goals in a fun, interesting and varied way.\n\n\nI'm a 44 year old family man with well over 20 years of gym based experience; certified with a REPs level 3 Personal Training Diploma.\n\n\n\nWhatever your age, fitness level or goals I can teach you to work out safely and efficiently. I can show you new ways to exercise and advise you on your diet, helping you prevent conditions like back problems, diabetes, osteoporosis, and heart disease.\n\n\n\n​I offer continuous support and am contactable by email or phone.",
          "offers": "Please contact me for details",
          "qualifications": [
            "YMCA Diploma in Personal training",
            "Discovery Level 2 Certificate in Fitness Instructing"
          ],
          "strapLine1": "ST Bespoke PersonalTraining"
        },
        "score": 66,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "583d95f9e0dfa72d2acfd33e"
      },
      "lastUpdated": {
        "$date": "2018-01-18T23:23:45.151Z"
      },
      "createdAt": 1480431097307,
      "providerId": "10157657663555411",
      "provider": "facebook",
      "surname": "Tilley",
      "firstName": "James",
      "email": "koho76@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10157657663555411/",
          "disciplines": [
            "Functional Training",
            "Rehabilitation",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 40,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N4CJJY4g-IMG_1110.JPG",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Optimal Performance Clinic",
          "biography": "How it all began:\n\nOptimal Performance is the brainchild of James Tilley, Salisbury’s top functional personal trainer. \nAfter becoming disillusioned with every trainer out there telling you that they’re a celeb trainer and how important “having a six pack and beach ready biceps” is in life, or and being able to perform a “five minute plank” James decided to focus on helping people discover a way of training designed for life and not just the gym or the beach!\n\nOptimal Performance does exactly what it says, we design a body that not only looks great but achieves “Optimal levels of Performance”.\n\nIf you’re bored of normal one dimensional gym training, bored of treadmills, fixed machines and spin bikes, contact us today and start your journey to a new incredible way to train.",
          "yearOfBirth": 1973,
          "locations": [
            {
              "geolocation": [
                -1.7877727999999706,
                51.0992353
              ],
              "city": "Old Sarum",
              "postcode": "SP4 6QX",
              "_id": {
                "$oid": "5a612c81765eba094646f846"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7912978554",
            "code": "+44"
          },
          "qualifications": [
            "Level 3: Personal Trainer",
            "Neuro Skeletal Practitioner",
            "Certified Postural conditioning Expert",
            "Stick Mobility Coach"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/E0dfpTXl-DSC04432.JPG"
        },
        "score": 70,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "james_tilley_r1ZZIfoGl",
      "approved": true,
      "num_votes": 6,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "583d9f97e0dfa72d2acfd470"
      },
      "lastUpdated": {
        "$date": "2018-01-18T23:10:19.061Z"
      },
      "createdAt": 1480433559958,
      "email": "michael@fifo-lifestyle.com",
      "firstName": "Michael",
      "surname": "Ojo",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "michael_ojo_B1giJQsGe",
      "provider": "local",
      "salt": "YoHJTn+VGKw4FbOWVS0I5g==",
      "hashedPassword": "88fK7H+2a5x5imp8WB+9V53JLoLs46ECw8dyU2648x9JJVJSYXKe95JmSi/e17WqrdoYdAjKkuR4L5aVXXBN+g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1XhLGpZE-IMG_20150120_102944.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Boxing",
            "Functional Training",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 8,
          "price": {
            "min": 50,
            "max": 70
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wMHBlxHw-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.018175,
                51.4823217
              ],
              "city": "London",
              "postcode": "SE8",
              "_id": {
                "$oid": "5a61295b765eba094646f843"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7961025591",
            "code": "+44"
          },
          "biography": "\"I'm passionate about life and well being. FIFO Lifestyle is my calling to share that passion with you and help you on your journey to being healthy and fit - inside and out. My background is as varied as my approach to training my clients though my active pursuits stemmed from a love of athletics and movement which prompted me to compete at national level in sprint and jump events. The work ethic and discipline saw me through university and professional examinations before I found my calling living and working abroad. I pride myself in bringing the best out of my clients and making the seemingly impossible a reality. It's about embracing your potential and stepping out of comfort zones. I'll be with you every step of the way.\"",
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "strapLine1": "FIFO Lifestyle: Personal Training & Wellness"
        },
        "score": 63,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "583d9face0dfa72d2acfd471"
      },
      "lastUpdated": {
        "$date": "2018-01-18T23:07:05.393Z"
      },
      "createdAt": 1480433580223,
      "providerId": "10153837072882126",
      "provider": "facebook",
      "surname": "Oldridge",
      "firstName": "Adam",
      "email": "paddyadam@hotmail.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10153837072882126/",
          "disciplines": [
            "Bodybuilding",
            "Functional Training",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 8,
          "price": {
            "min": 40,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/752ql0J5-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Physique Solutions",
          "biography": "Physique Solutions provides a bespoke personal training service in Wimbledon and surrounding areas.",
          "yearOfBirth": 1987,
          "locations": [
            {
              "geolocation": [
                -0.22062879999998586,
                51.41833889999999
              ],
              "city": "London",
              "postcode": "SW19 4NP",
              "_id": {
                "$oid": "5a612899765eba094646f842"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7927762158",
            "code": "+44"
          },
          "offers": "Hi, my name is Adam and I am dedicated to help you reach your health & fitness goals.",
          "qualifications": [
            "Level 3: Personal Trainer"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WGzyEpEg-filename.jpeg"
        },
        "score": 67,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "adam_oldridge_ryV2yQjMl",
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "583da412e0dfa72d2acfd50c"
      },
      "lastUpdated": {
        "$date": "2018-01-18T22:58:19.737Z"
      },
      "createdAt": 1480434706372,
      "email": "aaron_cattell@hotmail.co.nz",
      "firstName": "Aaron",
      "surname": "Cattell",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "aaron_cattell_BkcMNXjGg",
      "provider": "local",
      "salt": "t8+V6xSMNEAGAs6BSaJ31A==",
      "hashedPassword": "5rA9EIahMA6SKp2cK6VYforAA1Uz8FZGjTkVaYF08Vs+gi3Sv/sD/VxHN6P8+GQLufT+e8MatoyFOV7r6qUyrQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3nK3khgO-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Nutrition"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 3,
          "price": {
            "min": 45,
            "max": 45
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154287829813871/",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xFDkn1nZ-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.0914172,
                51.5230329
              ],
              "city": "London",
              "postcode": "EC1Y 8QQ",
              "_id": {
                "$oid": "5a61268b765eba094646f841"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7741613780",
            "code": "+44"
          },
          "biography": "I focus on one thing , and one thing only.. Helping real people in the real world get real & long lasting transformations. On the outside your body will lose fat and look more toned, but more importantly on the inside you will feel better, have increased confidence and feel your best in your own skin.\n\nYou don't want to diet, especially in a way that tells you not to eat the things you love. You don't want to have to eat separately from you family and friends or always order the salad.\n\nThat all stops now.\n\nI want you to stop worrying about which carbs are right, good fats vs. bad fats and all of that other information, because that doesn't help you get any closer to your goal. \n\nLet's simplify weight-loss.  \n\nRather than building a program around what you can't or should'nt eat. We build it around what you want to eat, allowing you to go out and socialise, eat the same food as your family and still get amazing results.\n\nFood makes us happy. Let's keep it that way. \n\nSo tell me what are you goals and what are your favourite foods? I'll then design your eating plan around that. Yes, you will still be energy deficient but you will also get an allowance to eat what you like.\n\nTell me what kinds of exercise you like to do, and I'll build your workout around that.\n\nTraditional diets don't work, I bet that is why you are here. \n\nIt doesn't matter if its Keto, Atkins, Paleo or anything else if you cut out the things you love it is never going to work.",
          "offers": "Please contact me for details",
          "qualifications": [
            "Bachelor of Sport and Exercise Science: Auckland University",
            "Graduate Diploma Endorsed in Human Nutrition: Massey University."
          ],
          "strapLine1": "The Transformation Coach",
          "testimonials": ""
        },
        "score": 69,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "583db5cde0dfa72d2acfd751"
      },
      "lastUpdated": {
        "$date": "2018-01-18T22:45:10.755Z"
      },
      "createdAt": 1480439245645,
      "providerId": "10157747189385368",
      "provider": "facebook",
      "surname": "Fitzsimons",
      "firstName": "Craig",
      "email": "craigfitz@msn.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10157747189385368/",
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Mobile Trainer",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 13,
          "price": {
            "min": 25,
            "max": 30
          },
          "locations": [
            {
              "geolocation": [
                -2.596950099999958,
                53.3900441
              ],
              "city": "Warrington",
              "postcode": "WA1 1UJ",
              "_id": {
                "$oid": "5a612376765eba094646f83f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7734342036",
            "code": "+44"
          },
          "biography": "My name is Craig Fitzsimons,  the owner and trainer at FITZ Personal Training.\n\n​\n\nFor many years now I have been into Health and Fitness.  Before, I was just a nine-stone male with a complete lack of confidence and didn’t have much to show for it!  I felt the time was right for me to make some adjustments to my lifestyle, so I decided to go for it before it was too late.  My knowledge of the Health and Fitness industry has increased through the years, and over time my self-confidence and motivation to succeed have grown. \n\n \n\nMore recently I decided to further expand my knowledge in the industry of Health & Fitness, and using my unique passion and motivation for what I do, help others succeed in their lifestyle goals.  I want to give my clients the confidence and self-motivation that they deserve.  Together we will work on the fastest but safest way possible to achieve your desired goals, whilst maintaining realistic training and nutrition targets.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Certified Outdoor Bootcamp training",
            "Certified Gym Based Boxing",
            "Certified Circuits/Group training sessions",
            "Certified Kettlebell Training",
            "Certified TRX Suspension Training"
          ],
          "strapLine1": "Fitz Personal Training",
          "offers": "Please contact me for details of prices, packages and offers"
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mVHRQSls_profilePicture.jpeg"
        },
        "score": 69,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "craig_fitzsimons_HyURrEjfl",
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "583ec0f9e0dfa72d2acff96d"
      },
      "lastUpdated": {
        "$date": "2018-01-18T22:38:34.429Z"
      },
      "createdAt": 1480507641989,
      "email": "chris@libra-fitness.co.uk",
      "firstName": "Chris",
      "surname": "Bailey",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "chris_bailey_BJGgMoANz",
      "provider": "local",
      "salt": "CL3Nf3eeveaMU9lHm/kJRQ==",
      "hashedPassword": "kBB0YaBFi0Hy4P3InEkl/58aM2xVANd6+LQPHSkChTrhDAusB0g3zkHX5gh7thJvCmdkf0D51TAK6NpZJvhurQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WSZ08yBk-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 40,
          "price": {
            "min": 20,
            "max": 40
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mH4NhyFC-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -2.4545889999999417,
                52.688971
              ],
              "city": "Ketley Bank",
              "postcode": "TF2",
              "_id": {
                "$oid": "5a6121ea765eba094646f83d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7866641227",
            "code": "+44"
          },
          "biography": "Hello, my name is Chris Bailey and I am owner of Libra Fitness Lifestyle & Wellbeing. Master personal trainer & graduate of the European Institute of Fitness, Providing a mobile personal training service by bringing the gym to you. Home, work or local park, we can train anywhere at your convenience.\nSo about me… I have always had an interest in sport and fitness from an early age, always trying out different sports and activities and being very active through school and college, but once into full time employment found less and less time to fit in the things I used to enjoy and like many, become more sedentary and having to try harder to manage weight correctly. Having reignited my passion for sports and fitness and working hard to gain all the necessary qualifications now feel proud to be able to help other people regain a balance in their life and motivate them with nutritional guidance and exercise so that they can enjoy a more fulfilled, happier, healthier & fitter lifestyle.\nThanks for reading – Chris Bailey, Libra Fitness.",
          "qualifications": [
            "Level 3: Master Personal Trainer",
            "Level 2: Gym Instructor",
            "EIF - Diploma in Nutrition & Weight Management",
            "EIF - Diploma in Motivation & Lifestyle Coaching",
            "EIF Diploma in Rehab & Exercise Referral",
            "EIF - Diploma in Clinical Exercise & Specific Populations",
            "EIF - Diploma in Fitness Business"
          ]
        },
        "score": 54,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "583ec1bbe0dfa72d2acff997"
      },
      "lastUpdated": {
        "$date": "2018-01-18T22:28:10.083Z"
      },
      "createdAt": 1480507835685,
      "email": "andrea@agfitness.org",
      "firstName": "Andrea",
      "surname": "Groholy",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "andrea_groholy_rJVabr2fx",
      "provider": "local",
      "salt": "gA7wRdESy1SUaSeeunRNzg==",
      "hashedPassword": "iinUu3XHm6llqTZcLQYQ358P4kqVKhGXDs552poQ+A6XRVZOKeYIn4VnniHQVVjbZxIMZoz6NeHFamJyLJL/bQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jWNS3ESD-FB_IMG_1478081256432.jpg"
        },
        "personal": {
          "disciplines": [
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 50,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gKzJYT9p-received_10208817137562994.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.09072579999997288,
                51.5210187
              ],
              "city": "London",
              "postcode": "EC1Y",
              "_id": {
                "$oid": "5a611f7a765eba094646f83c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7572875282",
            "code": "+44"
          },
          "biography": "I run personal training sessions in Budapest and London and I have experience training my clients on sailing boats.\n\nI feel that many of our fitness services could greatly benefit your work force, reduced loss of productivity and more vibrant and energetic performance at work.\n\nTake advantage of our personal trainers to relax the body and mind during break hours or before/after work.\n\nAbout AG fitness:\n\nAG fitness Personal Training Studio is located in the heart of Budapest and in the City of London.\n\nThe studio provides a unique environment to put the “personal” back in to personal training.\n\nThe studio benefits from the latest equipment and natural light of a first floor studio, a rarity among the City’s personal training studios.",
          "qualifications": [
            "Fitness Instructor Level 2 CYQ Certificate",
            "Personal Trainer Level 3 CYQ Certificate",
            "Kettlebells Instructor Level 2-3 A&P Grips",
            "Piloxing Instructor Level 2",
            "Level 2: First Aid / CPR",
            "Boxing Instructor Level 2"
          ],
          "strapLine1": "AG Fitness"
        },
        "score": 63,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "583ee414f3d0114b06a8bfa4"
      },
      "lastUpdated": {
        "$date": "2018-01-18T22:13:27.516Z"
      },
      "createdAt": 1480516628808,
      "email": "alvaro@hotmail.co.uk",
      "firstName": "Alvaro",
      "surname": "Da Silva",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "alvaro_da_silva",
      "provider": "local",
      "salt": "YMzR9SDWN/fVvLIQCoCzEg==",
      "hashedPassword": "zryAAq280u5qBp/2P5S/B2xXOGgq2ZpWecNfGWV5NqsNEz6eFMOGSjSxybt/vGYUdm4f16pSJe41/dKuiHgzfw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4ToKzf1o-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Nutrition",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 15,
          "price": {
            "min": 45,
            "max": 75
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DpHTuFes-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.0715052000000469,
                51.4597164
              ],
              "city": "London",
              "postcode": "SE22 9HB",
              "_id": {
                "$oid": "5a611c07765eba094646f838"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7863115264",
            "code": "+44"
          },
          "biography": "Alvaro has been working in the fitness industry for over 12 years. He is one of the most popular personal trainers in East Dulwich, London. Alvaro doesn’t only make sure that your body is performing at the best possible level, but he also works on your motivation and nutrition plans.",
          "qualifications": [
            "YMCA Advanced Fitness Instructor",
            "YMCAfit - Level 3 Personal Trainer Award",
            "Fitness Pilates: Core Fitness",
            "GP Exercise Referral",
            "Shiatsu Massage"
          ]
        },
        "score": 54,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 2,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "583f3a81f3d0114b06a8cbd0"
      },
      "lastUpdated": {
        "$date": "2018-01-18T22:03:45.885Z"
      },
      "createdAt": 1480538753560,
      "email": "josh.blackall@gmail.com",
      "firstName": "Josh",
      "surname": "Blackall",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "josh_blackall_r19Kc32zx",
      "provider": "local",
      "salt": "evLVmlf2sBjbNuH1w3Rt9A==",
      "hashedPassword": "n0+citgc9Srzu5hJvOe7MjITn1jDIcUOUHPtPEKZD8JksFBRiHDK2jDaYvB6k+imOKXtbJQQkNr2ylbve2GzMA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dVEw8bhj-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Circuit Training",
            "Online Coaching",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "price": {
            "min": 30,
            "max": 30
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BiakqqHB-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -1.0811820254541686,
                51.26468586550772
              ],
              "city": "Basingstoke",
              "postcode": "RG21 4AS",
              "_id": {
                "$oid": "5a6119c1765eba094646f837"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7414050105",
            "code": "+44"
          },
          "affiliation": "REPs, NRPT",
          "biography": "5 PhotosJosh Blackall Personal Trainer logo\n34 Eastrop Lane\nBasingstoke\nRG21 4AS\n Mon - Fri: 06:00-20:00   Sat / Sun: 08:00-20:00   \n0.00 Reviews\n\nWrite Review\n\n \nIf you are looking for a personal trainer to motivate and coach you to become better then contact me now. \nI will strive to motivate, educate and guide you to ensure that together we can achieve your goals!\nI am a qualified Personal Trainer and member of the Register of Exercise Professionals and National Register of Personal Trainers. I have various experience in different areas of training and whether your goal is to lose some excess weight or to improve performance in a specific area we can adapt a programme to suit your needs.\n\nI operate in Basingstoke and the surrounding area and am willing to train people either at their home or in a neutral place in the immediate area.",
          "qualifications": [
            "YMCA Diploma in Personal training",
            "Active IQ Circuit Training",
            "CYQ - Level 2 Certificate in Fitness Instructing (QCF) - Gym Based Exercise",
            "YMCA Kettlebells",
            "YMCA Suspension Fitness"
          ]
        },
        "score": 57,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58404203f3d0114b06a8f0c1"
      },
      "lastUpdated": {
        "$date": "2018-01-18T16:29:43.594Z"
      },
      "createdAt": 1480606211115,
      "providerId": "115819557655569535790",
      "provider": "google",
      "surname": "East London",
      "firstName": "At Home Fitness",
      "email": "ahfeastlondon@gmail.com",
      "settings": {
        "personal": {
          "google": "https://plus.google.com/115819557655569535790",
          "disciplines": [
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "price": {
            "min": 42,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/C3rwv7nV-IMG_3100.JPG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                0.011497899999994843,
                51.5431407
              ],
              "city": "London",
              "postcode": "E15 4ER",
              "_id": {
                "$oid": "5a60cb77765eba094646f803"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "8009774154",
            "code": "+44"
          },
          "biography": "Ben Foulis delivers fitness training and dietary advice to your home in Stratford, East London.  He always starts with a consultation, so he can ascertain exactly what you are looking for.  He then ensures you will receive a fully bespoke programme designed specifically for you.  This ensures maximum results in the shortest time, whilst training in the safest possible manner.  Whether you are looking to lose body fat, gain muscle mass, reduce a clothes size, rehabilitate an injury OR prepare for an event… Look no further for personal training in Stratford!\n\nEast London personal trainer Ben uses a wide range of equipment which he brings to your home, to allow him to develop a varied programme that will excite and motivate you to succeed!  He is also expertly trained in nutrition, which he uses in partnership with his fitness training to design a tailored plan specific to you.  With a full toolbox of knowledge to help you unlock your potential, nothing will stop you from accelerating towards your goals!",
          "qualifications": [
            "Active IQ - Level 3 Certificate in Personal Training (QCF)",
            "Certificate in Sports Massage"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/atEu9idj_profilePicture.jpeg"
        },
        "score": 57,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "at_home_fitness_east_london",
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58404280f3d0114b06a8f0c4"
      },
      "lastUpdated": {
        "$date": "2018-01-18T16:24:21.281Z"
      },
      "createdAt": 1480606336533,
      "email": "jw.personaltraining@hotmail.co.uk",
      "firstName": "John",
      "surname": "Walker",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "john_walker_SJFKMTaGx",
      "provider": "local",
      "salt": "+VkaBADRqU4F+SqknNIFpg==",
      "hashedPassword": "hIVvD8w4LEGwNv0cI9O5zsGaayRVQzgSoc5kuA20aQeiyFL0f+Hc7qncZE6vTyiICFSH1Wyq8GkkAD+U1U/Mmw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nhS2BXRX-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Functional Training",
            "Group Classes"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 30,
          "price": {
            "min": 25,
            "max": 30
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GF1bUk4p-13592591_10209799750175738_4562343169831900668_n.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.7594171000000642,
                52.0406224
              ],
              "city": "Milton Keynes",
              "postcode": "MK9 3DX",
              "_id": {
                "$oid": "5a60ca35765eba094646f7fa"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7958222894",
            "code": "+44"
          },
          "biography": "A REPs Level 3 Personal Trainer operating in the Milton Keynes area. Qualified and experienced in numerous areas of exercise and nutrition with the ability to adapt my training style to meet the needs of my clients.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "NASM Certificate",
            "Level 3 Diploma in Sports Coaching",
            "First Aid at Work (Inc. AED",
            "Oxygen & Entenox)",
            "Disabled Access to Sport and Exercise",
            "Functional Fitness Worksop"
          ]
        },
        "score": 53,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5840428cf3d0114b06a8f0c5"
      },
      "lastUpdated": {
        "$date": "2018-01-18T16:20:10.639Z"
      },
      "createdAt": 1480606348923,
      "email": "mdcahz@hotmail.co.uk",
      "firstName": "Mark",
      "surname": "Abrahams",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "mark_abrahams_BkBqzTaMl",
      "provider": "local",
      "salt": "+IfrIF/tr7w5Kml4LOGsvg==",
      "hashedPassword": "LwRjQy+IN0ffXFze3XAuAla3Q/rNj5cK1UQzd39qeyU/QwEJkKrZWKzn9SmhEFCEHTnqFW7l8Ij6CCEYVwHowA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IgFoIX78-Mark%20Abrahms.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Functional Training",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 3,
          "price": {
            "min": 40,
            "max": 40
          },
          "locations": [
            {
              "geolocation": [
                -0.19956179999996948,
                51.329076
              ],
              "city": "Banstead",
              "postcode": "SM7 2LB",
              "_id": {
                "$oid": "5a60c93a765eba094646f7f3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7834549144",
            "code": "+44"
          },
          "biography": "Over 30 years experience\n \nExclusive use of private gym in Banstead\n \nPremier Advanced Personal Trainer level 3\n \nNutritional Advisor\n \nCorrective Exercise Specialist\n \nCircuit Trainer\n \nFirst aid qualified\n \nFully Insured",
          "qualifications": [
            "Premier - Diploma in Fitness Training & Sports Therapy (Level 3)",
            "Premier - Advanced Personal Training Award",
            "NASM Correctional Exercise Specialist",
            "Level 3: Nutrition",
            "Level 2: First Aid / CPR"
          ]
        },
        "score": 53,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58404377f3d0114b06a8f0f0"
      },
      "lastUpdated": {
        "$date": "2018-01-18T16:10:24.507Z"
      },
      "createdAt": 1480606583902,
      "providerId": "113846442357167907401",
      "provider": "google",
      "surname": "Thomas",
      "firstName": "Ryan",
      "email": "ahfromford@gmail.com",
      "settings": {
        "personal": {
          "google": null,
          "disciplines": [
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 35,
            "max": 40
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UAV3Y6vo-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                0.20736729999998715,
                51.5834497
              ],
              "city": "Romford",
              "postcode": "RM2 6AP",
              "_id": {
                "$oid": "5a60c6f0765eba094646f7ed"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "8009774154",
            "code": "+44"
          },
          "biography": "Hi, my name is Ryan Thomas, a personal trainer working at PureGym Canary Wharf and a Sports Science graduate, with an ememphasis on human performance. I also provide home training for clients in my local area. I have been involved in sport from a very young age, playing football at county and academy level, as well as representing my secondary school in basketball and athletics.\n*\nMy time at university kick-started my passion for strength training and brought me where I am today; helping ordinary people take back control of their bodies.\n\n*\n\nOver years of training, I have accumulated a lot of knowledge which I feel obliged to share with those less travelled along the fitness path.\n*\nMy willingness to help educate others knows no boundaries; the more a client is able to take on board, the better information they will have to apply to their training sessions, allowing for faster and more consistent results.\n*\nMost of my free time is spent watching videos and reading articles, trying to keep up to date with as much information as possible, to provide my clients with the most current information.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Sports Science (Human Performance) BSc",
            "Pre and Post-Natal Qualified",
            "Kettlebells",
            "Postural Assessment"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Lr40qbnm_profilePicture.png"
        },
        "score": 54,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "ryan_thomas_SyltmppMl",
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58406685f3d0114b06a8f5df"
      },
      "lastUpdated": {
        "$date": "2018-01-18T16:05:28.720Z"
      },
      "createdAt": 1480615557168,
      "providerId": "10157780465990150",
      "provider": "facebook",
      "surname": "Rothwell",
      "firstName": "Peter",
      "email": "peter7rothwell769@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10157780465990150/",
          "disciplines": [
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 15,
          "price": {
            "min": 30,
            "max": 40
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/46CKZoAM-IMG_5293.JPG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -1.6197872974728627,
                55.005455472274285
              ],
              "city": "Newcastle upon Tyne",
              "postcode": "NE3 1JZ",
              "_id": {
                "$oid": "5a60c5c8765eba094646f7eb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7469207013",
            "code": "+44"
          },
          "biography": "Hi, I'm Peter Rothwell the owner of KloneFit Personal Training.\n\nI have always enjoyed sport/fitness, from a young age right through to today. More recently I have taken my interest to a new level by becoming a fitness and motivational coach. \nThis has given me the skills and attributes to go with my interest and experience in fitness. \n\nI believe that this has given me the correct blend to make a difference to peoples lives through fitness and motivational coaching. What I have learnt is that there is no right or wrong time to start becoming involved in exercise/fitness. Anyone with differing levels of fitness can benefit from structured exercise. \n\nI find there is nothing more rewarding than seeing someone progress and achieve their goals with regular exercise. \n\nThis is why I want to change people lives for the better, through exercise and nutrition whilst also promoting a positive outlook on life through coaching.",
          "qualifications": [
            "Level 3: Master Personal Trainer"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dVPDzb4G-filename.jpeg"
        },
        "score": 57,
        "type": "individual"
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "peter_rothwell_ry6Y8y0ze",
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58419729ac10c47b4c911ff0"
      },
      "lastUpdated": {
        "$date": "2018-01-18T16:03:29.217Z"
      },
      "createdAt": 1480693545634,
      "email": "hoggroland@hotmail.com",
      "firstName": "Roland",
      "surname": "Hogg",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "roland_hogg_Hyf4vz17g",
      "provider": "local",
      "salt": "AeROe62Ui5aR6MBJyehOIQ==",
      "hashedPassword": "EfqMpDYOUkoIiQuVxIX0OsBtJfj+Qz2O9UsFBJGHBhjvVxfJ78tHKhdXByLEArrHBhUb+PFN6972tslx+EBH/A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lProPHEe-image.jpg"
        },
        "personal": {
          "disciplines": [
            "Endurance",
            "Mobile Trainer",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 16,
          "price": {
            "min": 41,
            "max": 47
          },
          "strapLine1": "Bundles of Gym , Personal Training and sport specific conditioning experience 30 years +",
          "yearOfBirth": 1968,
          "biography": "Own Gym and willing to travel to you house in Surrey, SW London as well. Plenty of experience and knowledge Level 4 qualifications in Excerise referral, Obese and diabetic clients. Happy to train any clients that need motivation and to push them towards their goals. Experienced Athlete myself understanding training to achieve results weather personal or sport specific. Worked 9 years as Sport Centre Manager including coaching and making a Gym. Worked 7 years at QEF in Surrey with disabled clients achieve their life health goals, previous worked in Gyms and sports centre for another 15 years .",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/XNtB4AqF-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "google": "www.rolandfitnesstraining.co.uk",
          "companyWebsite": null,
          "locations": [
            {
              "geolocation": [
                -0.30076950000000124,
                51.3190416
              ],
              "city": "Ashtead",
              "postcode": "KT21 1PE",
              "_id": {
                "$oid": "5a60c551765eba094646f7ea"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7503156645",
            "code": "+44"
          },
          "qualifications": [
            "CYQ - Level 3 Certificate in Personal Training",
            "BACPR - Exercise Instructor Certificate",
            "British Amateur Weight Lifting Association Leader",
            "CYQ - Level 3 Diploma in Exercise Referral (QCF)",
            "Emergency First Aid at Work",
            "Lvl 4 Physical Activity & Weight Mgmt for Obese & Diabetics",
            "Sports Coaching Badges",
            "Level 2: Gym Instructor",
            "Exercise and nutrition for weight management Focus"
          ]
        },
        "score": 66,
        "type": "individual",
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5842879dac10c47b4c914196"
      },
      "lastUpdated": {
        "$date": "2018-01-18T15:52:37.395Z"
      },
      "createdAt": 1480755101600,
      "email": "melissa@thrivejoyfit.com",
      "firstName": "Melissa",
      "surname": "Joy",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "melissa_joy",
      "provider": "local",
      "salt": "d+TFIFCNqy/HJfotcZUWHQ==",
      "hashedPassword": "kviRw1XkXr4/jwSvU6f5JMZDOudeTQIvzAnQsXkMt055+vpJRcL6Ng+K80b+O5eMWkJV0VJkilE4rXQR2Dru9A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sCHEzAnY-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Sports Massage",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 39,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/iNHuwU6c-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "qualifications": [
            "Active IQ - Level 3 Certificate in Personal Training (QCF)",
            "Active IQ - Level 3 Award Designing Pre/Post Natal Exercise",
            "Active IQ - Level 3 Certificate in Sports Massage Therapy",
            "Discovery Kettlebell Instructor Training",
            "Emergency First Aid at Work",
            "Indoor Cycling (Spinning) Instructor",
            "TRX Suspension Certificate"
          ],
          "companyWebsite": null,
          "locations": [
            {
              "geolocation": [
                -0.4040202468440757,
                51.385182646544365
              ],
              "city": "Walton-on-Thames",
              "postcode": "KT12 2BX",
              "_id": {
                "$oid": "5a60c2c5765eba094646f7e6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7808174758",
            "code": "+44"
          },
          "biography": "I am a friendly, versatile personal trainer and sports massage therapist who puts the individual goals of clients at the centre of everything I do. With a background in weight management and having worked in a corporate environment for many years, I am able to empathise with clients and understand how the stresses of everyday living can take its toll. It’s my aim to put the spring back in people’s lives through enjoyable fitness, whether that is in a one to one or group session. I take pride in ensuring that all my clients have a good workout no matter what their age or ability."
        },
        "score": 54,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5844736cac10c47b4c918682"
      },
      "lastUpdated": {
        "$date": "2017-09-13T18:53:35.776Z"
      },
      "createdAt": 1480881004349,
      "email": "sparsh_lv@yahoo.co.uk",
      "firstName": "Alka",
      "surname": "Sharma",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "alka_sharma",
      "provider": "local",
      "salt": "bafofDFv/LDof7fyCjgPtw==",
      "hashedPassword": "qsnPMEpEFc7fEDhmzim0vgVyX+a1Tjj5VTiJz2ehDl899/6j7eWYHlrwkkK/CBF5aGTX9kpf1AmdWzbZe0Pzrg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5BRSSqUI-images%20(2).jpg"
        },
        "personal": {
          "disciplines": [
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Yoga"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Personal Trainer",
          "price": {
            "min": 50,
            "max": 50
          },
          "radius": 15,
          "locations": [
            {
              "geolocation": [
                -0.39031999999997424,
                51.65648899999999
              ],
              "city": "Watford",
              "postcode": "WD17 2RA",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: Exercise Music & Dance Partnership(EMDP) Teacher",
            "Nutrition for Exercise",
            "Level 2: Gym Instructor"
          ],
          "affiliation": "REPs",
          "biography": "Alka is a REPS Level 3 Advanced Personal Trainer. She is an energetic and motivating individual with a holistic approach.\nShe is born and raised in the holy place, India and has learned and experienced the great wonders of physical, mental and spiritual awareness from a very young age.\nShe has experience in helping her clients by creating the right program for each individual, combining Mainstream Western techniques with alternative methods such as Yoga, Pranayama, Lifestyle Management, Nutritional advice and Meditation. This enables the body and mind to achieve the benefits of training to the fullest.\n\nYour training will be fun yet challenging and always evolving as you do. She will train you at home, outside, in the park or at office where you will train in confidence, set and meet your goals, as well as feel and see the positive changes for yourself.\n\nQualifications\nÂ•\tYoga, Meditation, Pranayama, BSc Honours in Science, Chemistry, \nÂ•\tActive IQ Level 3 Â•\tNutrition for Exercise Â•\tLifestyle Management, Exercise to Music\nÂ•\tGroup Indoor Cycling Â•\tAqua Â•\tCircuit Training Â• Boxer blast Â• CPR\nÂ•\tVIPR Â•\tKettle bell Â•\tPower plate",
          "strapLine1": "Unique health guru - Revolution in Health & Fitness",
          "companyName": null,
          "companyWebsite": null,
          "phone": {
            "code": "+44",
            "number": "7959526939"
          },
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vfCAYmlh-filename.jpeg"
          }
        },
        "score": 66,
        "type": "individual"
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "password_reset_code": "X",
      "__v": 17,
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.78 Safari/537.36"
      },
      "lastLogin": 1505328815717
    },
    {
      "_id": {
        "$oid": "584485a4fd82c85767a1f66c"
      },
      "lastUpdated": {
        "$date": "2017-08-01T16:59:40.274Z"
      },
      "createdAt": 1480885668849,
      "email": "info@commandoactive.com",
      "firstName": "Jon",
      "surname": "Stratford",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "commando_active",
      "provider": "local",
      "salt": "dt76SvCZuMnbMXeAcwF67Q==",
      "hashedPassword": "CzEYqUvem1jPrychm5+MIHmybjxZ3DviHOvwuaks0HHAuyAs/nEWpEh9e9ho9QTjJb8og1wtkfnbnl5TC4sb1Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FiZ1xMiC-350fighting_the_war.jpg"
        },
        "personal": {
          "disciplines": [
            "Indoor Classes",
            "Personal Training",
            "Self Defense",
            "Small Groups",
            "Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Boot Camp",
          "radius": 20,
          "price": null,
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154039720783440/",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PT5MYQhQ-dsc_2151.jpg",
            "size": {},
            "offset": 0
          },
          "companyWebsite": "http://www.commandoactive.com",
          "locations": [
            {
              "geolocation": [
                -0.07211376441411631,
                51.514918827391085
              ],
              "city": "London",
              "postcode": "E1",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Commando Active",
          "qualifications": [],
          "biography": "Commando Active was formed by Jon in 2008 with one simple goal:\n\n“Help people realise how capable they really are”\n\nThought this initial statement hasn’t changed since then, we have since added some other strings to our bow, whether that’s been through our ongoing education and development or through some highly motivated an experienced individuals joining our team. Their involvement compliments and adds breadth to our offerings whilst maintaining the depth of knowledge needed to offer you true specialist training that can be only borne out of a passion for their sport or discipline.\n\nToday we train and educate individuals and groups in the following ways:\n\n> Personal Training - face to face and online\n> Outdoor group classes\n> Indoor small group classes\n> Personal and team development, both sports and corporate\n> Outdoor and fitness brand support and community building\n> OmLess Yoga\n> Self defence\n> Motivational speaking and education seminars\n> Luxury fitness retreats\n> Obstacle course training",
          "phone": {
            "code": "+44",
            "number": "7956959737"
          }
        },
        "score": 46,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "password_reset_time": 1499348087975,
      "password_reset_code": "X",
      "lastLogin": 1499349110945,
      "agent": {
        "family": "Safari",
        "major": "10",
        "minor": "1",
        "patch": "1",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_5) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.1.1 Safari/603.2.4"
      },
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5846a8b28d58185098e9b6d6"
      },
      "lastUpdated": {
        "$date": "2017-10-11T15:17:10.217Z"
      },
      "createdAt": 1481025714307,
      "email": "moose_x-training@shoomon.xyz",
      "firstName": "Nick",
      "surname": "Mercer",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "moose_x-training",
      "provider": "local",
      "salt": "dAF8TW4EenUDZ6K0NiIO+A==",
      "hashedPassword": "T6FEXcUXRXAJpBtFVi0ZYbPIPkSHfoOCfgPWfa1ZkDiD1wydW345sqeEO+byW2F+n0GRzD+nKl/K8O3IjeKbFA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9f7PeHVH-Moose%20x-training.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Group Classes",
            "Personal Training",
            "Small Group Training",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 12,
          "price": {
            "min": 46,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gLW6ZBmR-moose%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Moose X-Training! Welcome to Moose X-Training... the personal-training boutique designed to accommodate you and your busy lifestyle. The MOOSE offers a unique approach to fitness that is fun, friendly and fabulous. There are no membership fees, no crowded changing rooms and no sweaty studios. Likewise, with specialties like personal training @ home and personal training @ the park, there is no time wasted slogging to and from a gym, never mind lugging an unwieldy kit bag. Moose X-Training is all about taking the chore out of fitness.  We believe everyone deserves to feel fit and look fab.  We even offer online scheduling and easy payment schemes - it doesn’t get any simpler! By making fitness convenient while still affordable, The MOOSE achieves results. Why wait?  Sign up NOW!",
          "companyWebsite": "http://www.themoose.uk.com/",
          "locations": [
            {
              "geolocation": [
                -0.08800610000002962,
                51.49920729999999
              ],
              "city": "London",
              "postcode": "SE1 4LS",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Moose X-Training",
          "phone": {
            "code": "+44",
            "number": "2088747875"
          },
          "qualifications": []
        },
        "score": 46
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5846c9ce8d58185098e9bb72"
      },
      "lastUpdated": {
        "$date": "2017-10-11T15:35:02.452Z"
      },
      "createdAt": 1481034190425,
      "email": "kbpt@shoomon.xyz",
      "firstName": "Kate",
      "surname": "Burrows",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "kb_personal_training",
      "provider": "local",
      "salt": "XdXtWuZx84xwxlmL4yIaLQ==",
      "hashedPassword": "y7b/I2ebKhEEi/OuXo0vwSFpmWbw9SlKjMju4gt9mAoun/khCj9IdwCyF+ptw0+kdJ7c02dQPSAH+sqoiAeT1Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ljr9bGmZ-kb.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Pre / Post Natal",
            "Sports Therapy & Rehab",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 5,
          "price": {
            "min": 40,
            "max": 45
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RKqOX6DU-kbpt%20logo.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The #1 all-girl training team in London",
          "biography": "KBPT is a mobile personal training service based in Hackney, in East London. We are a team of six down to earth girls (all advanced level 3 qualified) who zip round East London on our bikes keeping East London fit and healthy! We are the most established female trainers in the area and have a range of amazing clients all with different goals which we are proven to help them attain.  The best thing about us is that we can design a programme perfectly tailored to you, from the time you want to train to where you want to do it (PT studio, park or home). We ensure that we make the training fit in with your lifestyle as there is no such thing as 9-5 in London! There are seven of us so we have cover for holidays and can be very flexible on timings and locations.  We are seven normal girls (apart from our abnormal love of exercise) who are really down to earth. I’m Kate the founder of KBPT: give me a shout for a free consultation with no obligation – you have nothing to lose.  We are qualified in everything! You name it we do it: TRX, ViPR, Vibe Spin, Rebounding, Powerplates, Pre and Post Natal, Weight Loss, Nutrition, GP Referral, Kettlebells, Powerclubs, Sandbells, Thump Boxing, Circuits, Beginners to Marathon Running training, Cycling, Primal Flow, Functional Body Weight… the list is endless. We attend every fitness convention there is to make sure we stay the best female Personal Trainers in East London. Above all our training is fun.",
          "companyWebsite": "http://www.kbpersonaltraining.co.uk/",
          "locations": [
            {
              "geolocation": [
                -0.02380100000004859,
                51.549987
              ],
              "city": "London",
              "postcode": "E9 5EF",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "KB Personal Training",
          "phone": {
            "code": "+44",
            "number": "7966257998"
          },
          "qualifications": []
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5846d78e9302770a65e56273"
      },
      "lastUpdated": {
        "$date": "2017-10-11T15:43:44.147Z"
      },
      "createdAt": 1481037710091,
      "email": "westlondonfitness@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "west_london_fitness",
      "provider": "local",
      "salt": "VOidnUlGGBhjT3h0qP9uGQ==",
      "hashedPassword": "HOC9sXiu5KvxLsozIFEaOf3ldm9MjCnN0+Wk8RBcVQUbEw62zkrkQwlA0BAf+O59HVhXg1pIBqlOVQ5607hKDg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FFHEWJWy-wlf%20james.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Sports Therapy & Rehab",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 5,
          "price": {
            "min": 42,
            "max": 53
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dMmugmkE-wlf%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Gain & Sustain a Healthier You Starting Today!",
          "biography": "Established in 2001, West London Fitness has built an enduring reputation as a fun, friendly, reassuring and results-driven Personal Training Service for anyone who needs expert guidance, support and encouragement with their health and fitness.  With the help of our highly-qualified and experienced Personal Trainers - available in the studio or at your home - our clients have found that training with West London Fitness can be the difference between achieving goals or dreaming about them - check out our Testimonials. Here at West London Fitness we train a wide spectrum of clients targeting weight loss, muscle gain, total body tone, rehabilitation from injury, the elderly client, young athletes and sports-specific strength and conditioning training for golf, tennis, swimming and rugby.  Based at Trailfinders Sports Club in West Ealing, the venue provides excellent facilities, free parking and the benefit of no gym membership costs in order to train with our Personal Trainers.  Book now to train with us at our studio in West Ealing or have us come and visit you with our mobile Personal Training service.",
          "companyWebsite": "http://www.westlondonfitness.co.uk/",
          "locations": [
            {
              "geolocation": [
                -0.3263022999999521,
                51.52449070000001
              ],
              "city": "London",
              "postcode": "W13 0DD",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "West London Fitness",
          "phone": {
            "code": "+44",
            "number": "8008406000"
          },
          "qualifications": []
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5848062c9302770a65e58cfb"
      },
      "lastUpdated": {
        "$date": "2017-10-11T15:49:08.036Z"
      },
      "createdAt": 1481115180579,
      "email": "believe_fitness@shoomon.xyz",
      "firstName": "Daniel",
      "surname": "Martin",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "believe_fitness",
      "provider": "local",
      "salt": "qpaRtTd7gRX71KHk/OHnzQ==",
      "hashedPassword": "QmWjCHSPYdcZAFaBtF+CafM1S1JZYAaejFt4OsdbZFbqDk15c7WRdDi1JJBnpcU5pB0JfV1GL9ckqs4ojOTlIw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/K7iWfFgA-believe3.jpg"
        },
        "personal": {
          "disciplines": [
            "Core Strength / Stability",
            "Pre / Post Natal",
            "Sports Therapy & Rehab",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 15,
          "price": null,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/d0N7pLkR-believe%20fitness%20logo.jpeg",
            "size": {},
            "offset": 0
          },
          "biography": "Believe Fitness is a mobile Personal-Training company that started in Shepherds Bush, London. Over the years we have increased our team of Personal Trainers and we now cover most areas of Greater London.  At Believe Fitness we have Personal Trainers that are professionally trained, qualified to degree level and have many years experience at personal training, so that you can feel comfortable in the knowledge that our trainers know what they are talking about.",
          "companyWebsite": "http://believefitness.co.uk/",
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -0.08800610000002962,
                51.49920729999999
              ],
              "city": "London",
              "postcode": "SE1 4LS",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Believe Fitness",
          "phone": {
            "code": "+44",
            "number": "8000842348"
          }
        },
        "score": 43
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "584878da4ca7d66673c618c8"
      },
      "lastUpdated": {
        "$date": "2018-01-18T15:48:58.220Z"
      },
      "createdAt": 1481144538145,
      "providerId": "105200620616997970131",
      "provider": "google",
      "surname": "summer",
      "firstName": "mark",
      "email": "marksummeremail@gmail.com",
      "settings": {
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Hiit",
            "Mobile Trainer",
            "Pilates"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 12,
          "price": {
            "min": 35,
            "max": 45
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tV9daHPV-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.2880758342450484,
                51.47632692935969
              ],
              "city": "Richmond",
              "postcode": "TW9 3NJ",
              "_id": {
                "$oid": "5a60c1ea765eba094646f7e5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7740191255",
            "code": "+44"
          },
          "biography": "I will get you the results you want, Whether you are a beginner or a fitness enthusiast I can tailor a programme especially to what you need. \n\nWhy bother with expensive gym memberships - let me take the hassle out of getting fit by coming to your home or workplace with all the equipment you will need. \n\nI create personalised programmes for you each week and monitor nutrition to make sure you are getting the best results possible.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Pilates Instructor"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dFw749qM-IMG_4487.JPG"
        },
        "companyWebsite": null,
        "score": 54
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "username": "mark_summer_SJz1Yx8Ql",
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "584896004ca7d66673c61d48"
      },
      "lastUpdated": {
        "$date": "2018-01-22T13:01:14.151Z"
      },
      "createdAt": 1481152000362,
      "email": "masonpearsall@gmail.com",
      "firstName": "Mason",
      "surname": "Pearsall",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "mason_pearsall",
      "provider": "local",
      "salt": "vZ91Ux83WxqVxfyFNxbUCQ==",
      "hashedPassword": "LTeIn7i0TyKzT2uCER5Lku1tQ8QLIhyfbn/Rd4w3P/HmHvpZTNixkE9h5V1or1e82q03U6VvqZzKZ+rasOi9WA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lLSJtswU-14237577_10207034205349304_8593957707051088774_n.jpg"
        },
        "personal": {
          "disciplines": [
            "Injury Assessment",
            "Injury Rehabilitaion",
            "Nutrition",
            "Personal Training",
            "Sports Specific"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Sports Therapist",
          "radius": 5,
          "price": {
            "max": 50,
            "min": 20
          },
          "locations": [
            {
              "geolocation": [
                -0.1994727999999668,
                51.65564509999999
              ],
              "city": "Barnet",
              "postcode": "EN5 5TJ",
              "_id": {
                "$oid": "5a65e09a5f0b475e1c9b1d17"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7970906825",
            "code": "+44"
          },
          "biography": "Hi, my name is Mason and I am dedicated to help you return to peak fitness.",
          "qualifications": [
            "Bsc Sports Therapy 2:1"
          ]
        },
        "companyWebsite": null,
        "score": 52
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "584b04e0550fc03d31c2f1cc"
      },
      "lastUpdated": 1493822375732,
      "createdAt": 1481311456472,
      "email": "carol@liveforfitness.co.uk",
      "firstName": "Carol",
      "surname": "Winterborne",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "carol_winterborne",
      "provider": "local",
      "salt": "HmyQYlcUuG3MVPJeRNJpow==",
      "hashedPassword": "EKj2K6KsFGHL2qmjdov3j+NfGmR2J4aHsGhXmAotFc8h5ScF0PNgs8/QQuZ7Ao0wajXsgmMlNIDTNOwA/fNyJg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YZJ35k9X-carolwinterlogo.png"
        },
        "personal": {
          "disciplines": [
            "Strength & Conditioning",
            "Boxing",
            "Nutrition",
            "Mobile Trainer",
            "Pre/post Natal"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 32.5,
            "max": 40
          },
          "affiliation": "REPs",
          "biography": "I am a female Personal Trainer and over the past few years I have learned to appreciate how valuable exercise and a healthy diet can be. Having exercised most of my life, it helped me overcome a life threatening illness which caused unwanted weight gain then having to adapt my exercise to suit my problems. My aim is to help those who just want a healthier lifestyle or have once suffered through illness or injury, rebuild their fitness levels by educating you in not only fitness but nutritional advice as well. I will help increase your:\nConfidence\nEnergy levels\nFlexibility and co-ordination\nStrength and agility\nLongevity\nBeing slightly older, I feel that I bring a maturity to the personal training forum with a relaxed and\nfriendly approach which still allows you to achieve the results you want. By undertaking this unique\nmanner of training, I wish to enable you:\nto effectively obtain a healthy regime\nto include fitness and diet within your normal daily life\nMy aim is to focus on you, one to one, giving you my full individual attention. I am very positive\nin what I do, so I will:\ntake an interest in you and your lifestyle\nbe encouraging, supportive and friendly\nsuggest positive ideas to make you as committed as possible\nhelp you maintain your goals\nhelp work you to your full potential\nmake you session enjoyable yet challenging\nincorporating many different forms of exercise using a variety of equipment",
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 3: Ante & Post Natal",
            "Level 3: Nutrition",
            "Level 2: Gym Instructor"
          ],
          "strapLine1": "Owner of a tranquil private gym and a mobile trainer",
          "offers": "Personal training 'taster' package:\n6 weeks, once a week - £225\n\nOngoing personal training:\n6 weeks,2 times a week - £420\n3 times a week - £585\n\nPairs: save money and train with a friend:\n6 weeks, once a week ('taster' package) - £330\n2 times a week - £600\n3 times a week - £835\n\nWedding package:\nfor the bride & groom\n6 week, 4 times a week\nincluding diet plan -\n £780\n\nIntense weight loss diet plan:\n1 hour assesment\nonce a week, 1/2 hour for 5 weeks\n12 week follow up\nincludes 6 week diet and maintenance plan - £140\n\nIntroduce a friend and get one free session \nif they book with me\n\nPostural assesment & corrective excercises:\n1 hour assesment, 3 x 1 hour sessions,\n6 week follow up - £135",
          "companyWebsite": null,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Fckqnc0j-carolwinterbg4.png",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.5159648000000061,
                51.2849892
              ],
              "country": "United Kingdom",
              "city": "Send",
              "postcode": "GU23 7DF"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7760661837"
          }
        },
        "score": 77
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false
    },
    {
      "_id": {
        "$oid": "584c067b550fc03d31c31956"
      },
      "lastUpdated": {
        "$date": "2018-01-18T15:40:00.558Z"
      },
      "createdAt": 1481377403466,
      "email": "nealwill78@hotmail.com",
      "firstName": "Neal",
      "surname": "Williams",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "neal_williams_rkXFUFt7g",
      "provider": "local",
      "salt": "CAScaRSdBd9jfWamAzqWKw==",
      "hashedPassword": "H6RFmbr1WpP2hINwt9M4cP6vtVyTtRGbDfruVAvPedAzoJC2vwF2uk/uKxIdC0b3vBQLZpySCJhmEzG6AdtXhA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/e3N2qQEJ-IMG_3431.JPG"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Hiit",
            "Mobile Trainer",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Personal Trainer",
          "radius": 4,
          "price": {
            "min": 45,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0XilmVIp-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.1660468000000037,
                51.4450778
              ],
              "city": "London",
              "postcode": "SW17 7EG",
              "_id": {
                "$oid": "5a60bfd0765eba094646f7e3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7595679224",
            "code": "+44"
          },
          "biography": "If your looking to improve your health and fitness then youve come to the right place.Come train with me and I'll help you achieve your goals.",
          "qualifications": [
            "Level 3: Personal Trainer"
          ]
        },
        "companyWebsite": null,
        "score": 53
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "584c6e81550fc03d31c32957"
      },
      "lastUpdated": {
        "$date": "2018-01-18T15:34:21.276Z"
      },
      "createdAt": 1481404033070,
      "email": "alexcruz252003@yahoo.co.uk",
      "firstName": "Sergio",
      "surname": "Oliveira",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "sergio_oliveira_BJFt0JqQg",
      "provider": "local",
      "salt": "RFksoNL+PbINIW9obAywKA==",
      "hashedPassword": "HiNWN4bZUOdH535tJ0J4n1sIhULFkb4pw/cKKl7W7h3c/EKpZPSMZOQ1ywjNsIFMcMxSJK/6JL4SPAtzQUuExg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UXwerB7N-IMG_20160918_155523.jpg"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Mat",
            "Personal Training",
            "Private Classes"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Pilates Instructor",
          "radius": 10,
          "price": {
            "min": 45,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/XlTLATXE-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.12775829999998223,
                51.5073509
              ],
              "city": "London",
              "postcode": "SW1A",
              "_id": {
                "$oid": "5a60be7d765eba094646f7e2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7400117625",
            "code": "+44"
          },
          "biography": "I've always been a very active person.\nStarted my weight training at the age of 14, mainly due to back problems.\nYears of training helped me gain a deeper knowledge of the human body, and learn different methods of training.\nIn 2002 i came to london, and i decided to study and take my level 3 personal training course.\nBeen working as a personal trainer since 2003.\nDue to my back problems i then decided to take a pilates qualification to help my gain a deeper knwoldge of how to strenght the back muscles, and improve posture.\nI took my pilates qualifiction in 2004 and been teaching pilates since.\nMy goal is to help people achieve their fitness goals.\nTo pass and share my knowledge and expertise to them, to help them reach their full potential.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Pilates Instructor"
          ]
        },
        "companyWebsite": null,
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "584e6cfa550fc03d31c377bb"
      },
      "lastUpdated": {
        "$date": "2017-10-11T16:09:24.988Z"
      },
      "createdAt": 1481534714594,
      "email": "corpushealthandfitness@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "corpus_health_+_fitness",
      "provider": "local",
      "salt": "bzD5t5vXnmFa80mQpchoRA==",
      "hashedPassword": "EDjhgQXWy+gbsllhz9aSXchyR6d43aGoDo4+0b3hw7mQIr/MklPdkxOyIKCXRuOMP4pJxu4gYDgRVymXx1kljA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ry1FlXGP-corpus%20circuits.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Diet & Nutrition",
            "Fitness Classes",
            "Partner Training",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 50,
            "max": 50
          },
          "facebook": "",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ky2nrrOY-corpus%20avocado.jpeg",
            "size": {},
            "offset": 0
          },
          "biography": "We offer a range of varied and exciting group classes each week. Our aim is to provide fun, enjoyable, S.A.F.E. classes which will help you improve your strength, balance, flexibility, stamina and overall fitness. Whilst classes are suitable for all levels of fitness, you will work to your limits! We are proud our positive, friendly and welcoming environment which encourages all our members to work hard to achieve their very best in either group classes or personal training sessions in our Boutique Gym or St Mary's church.",
          "strapLine1": "FITTER, STRONGER, HEALTHIER, HAPPIER",
          "companyWebsite": "http://corpus-fitness.co.uk/",
          "locations": [
            {
              "geolocation": [
                -0.09926410000002761,
                51.3455127
              ],
              "city": "South Croydon",
              "postcode": "CR2 0QE",
              "country": "United Kingdom",
              "primary": true
            },
            {
              "note": "St Mary's Church, Purley Oaks Road",
              "geolocation": [
                -0.0923821000000089,
                51.3442228
              ],
              "city": "South Croydon",
              "postcode": "CR2 0NY",
              "_id": {
                "$oid": "59de40cecc3d650f7e4b365c"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "Corpus Health + Fitness",
          "phone": {
            "code": "+44",
            "number": "7716429015"
          },
          "qualifications": []
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "584e8d54550fc03d31c37c97"
      },
      "lastUpdated": {
        "$date": "2017-10-11T16:14:01.492Z"
      },
      "createdAt": 1481542996685,
      "email": "pursuitfitnesstraining@shoomon.xyz",
      "firstName": "Chris",
      "surname": "Wright",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "pursuit_fitness_training",
      "provider": "local",
      "salt": "J9dwv9oFVumoyGz1b8reyA==",
      "hashedPassword": "17d5kgB1DxSp94a/DAOcfhAfR1B+iukvecbrT4EhXZT6epa8GUZkyT2UXRtVT/TjQuqT12kdfBoFmjjLLjvvEw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nJHP5kRV-pursuit%20logo2.jpg"
        },
        "personal": {
          "disciplines": [
            "Corporate Services",
            "Diet & Nutrition",
            "Partner Training",
            "Personal Training",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 50,
            "max": 60
          },
          "facebook": "https://www.facebook.com/PursuitFitnessUK",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Gg7iqdOo-pursuit%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "TAKE THAT FIRST STEP AND START YOUR FITNESS JOURNEY TODAY",
          "biography": "WELCOME TO PURSUIT FITNESS TRAINING, THE MOST REWARDING PERSONAL TRAINING, FITNESS AND WEIGHT LOSS SOLUTION IN ESHER, SURREY. Real People, Real Results and Real Stories. Come and find out exactly how YOU can get amazing results too! Pursuit Fitness Training is Esher’s premier personal training and fitness facility, specialising in private and semi-private personal training and nutrition. Our team of personal trainers have built and tested programmes, that we know when tailored to your needs, are extremely successful. All delivered to you in our state of the art exercise studio right on Esher High Street. At Pursuit Fitness Training, we promise that our expert personal trainers will help you achieve your goals. Find out how YOU can get great results too. Just request a free consultation now.",
          "companyWebsite": "http://www.pursuitfitnesstraining.co.uk/",
          "locations": [
            {
              "geolocation": [
                -0.36476649999997335,
                51.3696722
              ],
              "city": "Esher",
              "postcode": "KT10 9RQ",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Pursuit Fitness Training",
          "phone": {
            "code": "+44",
            "number": "7950305865"
          },
          "qualifications": []
        },
        "score": 57
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "584e9e5e550fc03d31c37f4b"
      },
      "lastUpdated": {
        "$date": "2017-10-11T16:29:29.343Z"
      },
      "createdAt": 1481547358149,
      "email": "lemonbody@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "lemonbody",
      "provider": "local",
      "salt": "nIaAKngkTe5sSOOSSXb7lg==",
      "hashedPassword": "Y/fu4HvW2DylDIQMoOdGwClfsL2TvfS9MCkglh/bL0oBvZ7txJti2Zh99lUjsPNxiuN1TBmYYN0DQivQBqo0Ig==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/g4ylqTke-lemon4.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing Based",
            "Competetive Games",
            "Fat Loss",
            "Interval Training",
            "Military Style"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Boot Camp",
          "radius": 0,
          "price": {
            "min": 29,
            "max": 49
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/06QeqSxE-lemonbody%20logo.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Health and Fitness for everyone",
          "biography": "If you're serious about your health and fitness, come and try one of our 1000 Calorie BootCamp Workouts for Free! High-energy fat loss and muscle toning to get you looking great – fast! We have developed a series of All Over Body Workouts to get you fit and healthy – with plenty of fun and games so you work harder and get better results - without getting bored!",
          "companyWebsite": "http://lemonbody.com/",
          "locations": [
            {
              "geolocation": [
                -0.30193950000000314,
                51.38060359999999
              ],
              "city": "Surbiton",
              "postcode": "KT6 5BH",
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.2613897999999608,
                51.39797610000001
              ],
              "city": "New Malden",
              "postcode": "KT3 5AP",
              "note": "Indoors at Holy Cross School, Sandal Road, New Malden",
              "_id": {
                "$oid": "59de4698cc3d650f7e4b365d"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.28944109999997636,
                51.38744500000001
              ],
              "city": "Surbiton",
              "postcode": "KT5 9AA",
              "note": "Outdoors at Alexandra Recreation Ground, Alexandra Drive, Surbiton",
              "_id": {
                "$oid": "59de4698cc3d650f7e4b365e"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.28663189999997485,
                51.3918553
              ],
              "city": "Surbiton",
              "postcode": "KT5 8LJ",
              "note": "Indoors at Christchurch School,  Pine Gardens, Surbiton",
              "_id": {
                "$oid": "59de4698cc3d650f7e4b365f"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.35024380000004385,
                51.4459312
              ],
              "city": "Twickenham",
              "postcode": "TW2 6PQ",
              "note": "Outdoors at Kneller Gardens, Meadway",
              "_id": {
                "$oid": "59de4698cc3d650f7e4b3660"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.34456939999995484,
                51.4337205
              ],
              "city": "Teddington",
              "postcode": "TW11 8UH",
              "note": "Indoors at Stanley School, Strathmore Road",
              "_id": {
                "$oid": "59de4698cc3d650f7e4b3661"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "Lemonbody",
          "phone": {
            "code": "+44",
            "number": "8006990696"
          },
          "qualifications": []
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "584ebeea550fc03d31c38429"
      },
      "lastUpdated": {
        "$date": "2017-10-11T16:55:21.542Z"
      },
      "createdAt": 1481555690353,
      "email": "rebootpersonaltraining@shoomon.xyz",
      "firstName": "Mack",
      "surname": "Parnell",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "reboot_personal_training",
      "provider": "local",
      "salt": "de+xf4YlNqhHch8y1ueWUA==",
      "hashedPassword": "xR7BV9nL7EkiiIPA0EgwLZWlLFwTaG7pTQuDFHSbrfb07ORyMZTCuqppZmMd2W5EGVlX65xSFa0qccPMT/IuvA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/U8eesGUm-reboot%20profile.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing Based Training",
            "Diet & Nutrition",
            "Fat Loss",
            "Martial Arts",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 10,
          "price": {
            "min": 50,
            "max": 55
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DnqDVphW-reboot%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "North and East London’s #1 Mobile Personal Training!",
          "biography": "Reboot Personal Training in North and East London offers top-quality, affordable mobile personal training which gets results. Our Personal Trainers bring exercise, nutrition and fat loss to your door. Wherever you want to train, we’ll be there guiding, motivating and encouraging you. We don’t do off-the-peg training programmes. Your training sessions need to be as individual as you and your goals. We make training enjoyable, challenging and something you’ll be proud of. And we always get results.  Our trainers are friendly, approachable and highly experienced. We want our clients to find training fun and a part of the week they’ll look forward to. As Personal Trainers, we’re proud of the rapport we build with our clients and have some great feedback which we’d be pleased to share with you. Why not give us a call, or drop us an email, so you can find out if we’re right for you? Our personal training and nutrition programmes will be fully tailored to your individual needs and goals.  You can choose between either mobile personal training anywhere North and East London or our personal training studio in East London. Whichever you prefer we are here to make sure you get the service that is right for you and fits your schedule so that your fitness goals can be reached with the least amount of hassle as possible.",
          "companyWebsite": "http://rebootpt.co.uk/",
          "locations": [
            {
              "geolocation": [
                -0.06760009999993599,
                51.60314640000001
              ],
              "city": "London",
              "postcode": "N17 0AP",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Reboot Personal Training",
          "phone": {
            "code": "+44",
            "number": "7506730607"
          },
          "qualifications": []
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "584ec790550fc03d31c3859b"
      },
      "lastUpdated": {
        "$date": "2017-10-13T11:48:07.232Z"
      },
      "createdAt": 1481557904250,
      "email": "jigsawpersonaltraining@shoomon.xyz",
      "firstName": "Adam",
      "surname": "Walker",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "jigsaw_personal_training",
      "provider": "local",
      "salt": "tG2cfqTXdvnqvXtWa4cpbA==",
      "hashedPassword": "cJvLWfu7IPJWCgBRsrPWr6D+Dih42QMuOH6Xx5bxuv27r23XPxEn6YnCe9Rt1kSWQsEy5PvW1zsuApa9p5wI0A==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NYnFHiR5-jigsaw%20p2.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Distance Running",
            "Endurance",
            "Personal Training",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 15,
          "price": {
            "min": 56,
            "max": 70
          },
          "strapLine1": "We make all shapes fit",
          "biography": "Are you looking for a Running Coach or a Personal Trainer in North London? At Jigsaw Personal Training we are passionate about providing tailored fitness solutions for our clients needs. So whether you simply need some added motivation and advice on how to start your training or whether you have a specific goal in mind then we are here to help.  We offer no-nonsense training and steer clear of whatever fads are being touted in the latest glossies!  I have personally trained clients from never running before to running their first marathon and have lost count of those who I've trained for 5k and 10k runs.   Over the last few years have also seen an upsurge in clients' interest regarding mud runs or obstacle races and from my previous experience working for Rat Race and by personally completing the world's longest obstacle race - twice (Dirty Weekend) - then I am more than prepared to help you get race fit  for whatever crazy event you have signed up to.  If the thought of running leaves you a bit cold then there is plenty of other exercise we can do to improve your fitness and with my help and encouragement (for those that wanted to) I have been able to transform many adamant non-runners into running converts. So whether you're trying to shed a few unwanted lbs, looking to improve your general health and wellbeing or training for a specific event then Personal Training could be the answer for you. Call us for a free consultation.",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AqeZtaNJ-jigsa%20bg2.gif",
            "size": {},
            "offset": 0
          },
          "companyWebsite": "http://www.jigsawpersonaltraining.co.uk/home/",
          "locations": [
            {
              "geolocation": [
                -0.13026289999993423,
                51.5945845
              ],
              "city": "London",
              "postcode": "N22 7AY",
              "_id": {
                "$oid": "59e0a7f794e4d86bf9be1854"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Jigsaw Personal Training",
          "phone": {
            "code": "+44",
            "number": "7968014012"
          },
          "qualifications": []
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 12
    },
    {
      "_id": {
        "$oid": "584ed070550fc03d31c386df"
      },
      "lastUpdated": {
        "$date": "2018-01-18T15:30:06.354Z"
      },
      "createdAt": 1481560176208,
      "email": "ben.grey@youcubedfitness.com",
      "firstName": "Ben",
      "surname": "Grey",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "ben_grey",
      "provider": "local",
      "salt": "4M7f48Se4xQGx+E+9KNkUQ==",
      "hashedPassword": "tdJeqiZncNZmWpkqntdUY9j7a5lGK4x+J2Ce5zoxJ2FLXh3L3JFur6IoMYrIBACmZaBa3tn2eUoP+YR7yv9Tfw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DdTSU916-Ben%20Grey%20-%2011.jpg"
        },
        "personal": {
          "disciplines": [
            "Injury Prevention",
            "Nutrition",
            "Personal Training",
            "Sports Specific",
            "Strength"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "profileTitle": "Strength & Conditioning Coach",
          "radius": 0,
          "price": {
            "max": 550,
            "min": 110
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1IdCobM7-12931064_472405919619031_5255447663474972207_n.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -1.8299885,
                51.5967857
              ],
              "city": "Swindon",
              "postcode": "SN25",
              "_id": {
                "$oid": "5a60bd7e765eba094646f7e1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7775445940",
            "code": "+44"
          },
          "biography": "I loved the experience of changing my body and becoming fitter and healthier.  I decided pretty quickly that I wanted to go and train as a personal trainer so that I could help people to achieve their goals the way I was achieving mine.  I looked around at how to gain my qualification and found that EIF (European Institute of Fitness) offered a Master Trainer course that was significantly above the standard offerings.  I decided to sign up and am now a fully qualified EIF Master Trainer and Change Coach.  Following on from that I decided that I would really love to work with athletes as well and to do that I would need to continually improve my skills.  I found an excellent S&C mentorship programme run by Brendan Chaplin and signed up.  I am now completing my course and gaining a level 3 coaching qualification in Strength and Conditioning.  I have just started on the BTN nutrition academy to continue my growth as I believe to be the best you have to keep learning from the best the industry has to offer.    \n\nI hope this helps you get to know me a little more and I look forward to hearing about you, your circumstances and your goals.  If you would like to arrange for a free initial consultation please get in touch.",
          "qualifications": [
            "Level 3: Master Personal Trainer",
            "S&C Coach",
            "EIF Certified Exercise Specialist",
            "Fitness & Motivation Coach (ACSM Endorsed)",
            "EIF Certified Nutrition Coach",
            "BSC (Hons) Accounting & Finance (1st Class)"
          ]
        },
        "companyWebsite": null,
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "584edae1550fc03d31c3887f"
      },
      "lastUpdated": {
        "$date": "2017-10-12T08:27:21.677Z"
      },
      "createdAt": 1481562849227,
      "email": "jamesparkerpersonaltraining@shoomon.xyz",
      "firstName": "James",
      "surname": "Parker",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "progressive_fitness",
      "provider": "local",
      "salt": "ysl/jrrVpsBvKhA9zAoQLw==",
      "hashedPassword": "QVqG1aTagEcsNMA8bIcaZY95ikYxFRBPwQInzjVLBAJNK3SQEbhFnN2DWLka+qLAwuATmm7GK4NpQFy1XA2WJw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kw8miD6f-progress%20profile.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing Based",
            "Diet & Nutrition",
            "Interval Training",
            "Kids",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Boot Camp",
          "radius": 15,
          "price": {
            "min": 35,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8RMxhQS5-progressiveX.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Progressive Fitness Personal Training & Fitness Boot Camps in Reading",
          "biography": "Progressive Fitness Training Ltd was formed in 2006 by James Parker, an ex-Army Physical Training Instructor and personal trainer.  We are a Reading, Berkshire-based personal training and group fitness company offering personal training, sports massage therapy and group/corporate fitness, as well as our very popular Boot Camp Fitness programme..  Whether you are new to exercise, a seasoned veteran, a sports team, or a group of friends or colleagues, Progressive Fitness will help you to realise your potential. Our areas of expertise are:  Weight loss and conditioning, Body transformations, Nutrition advice, Sports specific training, Motivation and lifestyle change.  Predominately our mobile personal training is in Reading, however we do cover a large area in Berkshire including Newbury, Thatcham, Bracknell and Wokingham, and parts of Basingstoke, Hampshire and Oxfordshire.  We will travel to other areas, so just ask at your initial contact with us.  We now have new facility in Tilehurst called the Progressive Fitness Unit which is purpose built for personal training, semi private training and group fitness. If training outdoors is more your style then our outdoor Boot Camp Fitness programme could be for you: We have been running Fitness Boot Camps for over 9 years and have an extremely successful Boot Camp at Prospect Park in Reading.  Please go to our website to read more about our outdoor fitness programme.  We have 2 and 3 nightsper week monthly programmes on offer.",
          "companyWebsite": "http://www.personal-trainer-reading.net/",
          "locations": [
            {
              "geolocation": [
                -1.011461000000054,
                51.44941379999999
              ],
              "city": "Reading",
              "postcode": "RG30 2ND",
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.7498302000000194,
                51.3937283
              ],
              "city": "Bracknell",
              "postcode": "RG12 7PA",
              "note": "South Hill Park in the Birch Hill Estate - we meet in the car park of The South Hill Theatre.",
              "_id": {
                "$oid": "59df26a7cc3d650f7e4b3663"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -1.011461000000054,
                51.44941379999999
              ],
              "city": "Reading",
              "postcode": "RG30 2ND",
              "note": "Prospect Park, Reading - meet in the main car park where the entrance is off Liebenrood Rd.",
              "_id": {
                "$oid": "59df26a7cc3d650f7e4b3664"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "Progressive Fitness",
          "phone": {
            "code": "+44",
            "number": "7815805232"
          },
          "qualifications": []
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "584f00ba550fc03d31c38e6f"
      },
      "lastUpdated": {
        "$date": "2017-10-17T07:49:57.309Z"
      },
      "createdAt": 1481572538473,
      "email": "fitfasttraining@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "fit_fast_trainers",
      "provider": "local",
      "salt": "8qftnAsn2BxdR8qrHKgJww==",
      "hashedPassword": "zD8/bbIROfKSaoN9aKAjCe17giVzFl2DIfJAplMedBH4BK2ryExrwPA/fCWlJRCgylbpZiHmxCq3JCI0gHUcSg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KKeE1cWx-fitfastlogo1.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing Based Training",
            "Diet & Nutrition",
            "Fat Loss",
            "Martial Arts",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 15,
          "price": {
            "min": 50,
            "max": 65
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7IpS79Lr-fitfastbg3.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "We offer a complete package from our highly-skilled team of professionals for you to achieve your ultimate goal to health and well-being. All our trainers have been rigorously selected to help you achieve your goals with a top-notch team of caring, experienced and dedicated fitness professionals. They are all fully insured and highly qualified, as well as skilled listeners who specialise in a wide range of disciplines.  If you’re looking to make a lifestyle change you have come to the right place. We offer one-to-one private and group Personal Training all over London on your doorstep with some of the best and most highly-skilled Personal Trainers London has to offer. Our team of Personal Trainers will help motivate you to get the results you want, they will help you in achieving a balanced life and a healthier, fitter body.  Our Personal Trainers will deliver personalised programmes in your home, local park, studio or at your place of work; wherever it suits you, we’ll bring the gym to you! All our Male and Female Personal Trainers cover North London, East London, South London and West London. Our team of Mobile Personal Trainers are available for private one-to-one and group Personal Training sessions all over the London area.",
          "strapLine1": "Personal Training all over London at your doorstep",
          "companyWebsite": "http://fitfasttrainers.co.uk/",
          "locations": [
            {
              "geolocation": [
                -0.12503300000003037,
                51.50820599999999
              ],
              "city": "London",
              "postcode": "WC2N 5HX",
              "_id": {
                "$oid": "59e5b62594e4d86bf9be1872"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fit Fast Trainers",
          "phone": {
            "code": "+44",
            "number": "2035532800"
          },
          "qualifications": [],
          "offers": "Personal Training sessions are one hour long and have a 24hr cancellation policy:\n\n1 x session £65\n3 x sessions £180\n10 sessions £550\n20 sessions £1000\n\n3 session starter pack valid for one month only £130\n\nCorpotate Bootcamp:\n\nAll Corporate Boot camp sessions are one hour long and have a 24hr cancellation policy £100",
          "testimonials": "“I picked Omar to do a 6-week Summer Transformation, and what a change! I soon discoved that my fitness was not as good as I thought but Omar told me I was going to be fine. Having someone so positive about it gave me a kick start and the confidence I needed to make the change. I followed Omar’s program for just over 2 months and I am so proud of my new me. I cannot hide it was hard at the beginning but things got easier and easier and the resutls came fast, thank you!”\nBen\n\n“Top Trainer” “I have been training with Peter for several months – he really knows his stufff in terms of helping set realistic but challenging goals and then creating a training and diet plan which will help you achieve it. Peter is great with motivation & support, changing the exercise & diet plan when needed etc – he really wants you to succeed and makes a real effort to help you do that. And yes, he is reliable & flexible around timing. A really good personal investment!”.\n\nTony\n\n“Perfectionist, Professional and Punctual”\nTricia Guild\nDesigners Guild"
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "584f134d550fc03d31c39123"
      },
      "lastUpdated": {
        "$date": "2017-10-17T08:17:00.284Z"
      },
      "createdAt": 1481577293403,
      "email": "trainingandtherapies@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "tandt:_training_and_therapies",
      "provider": "local",
      "salt": "27d496UNVLbrfchr+eqk8A==",
      "hashedPassword": "UUm4X80j5Kob4eHJ0lw+8Y5EHVmxpbU7w4cZhNEoyl/TIl7QTGdcC6in4WITO+P0EfaPJ3RbeB0XqJtVEr2nLQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pgJ55EyO-t%26tlogo2.jpg"
        },
        "personal": {
          "disciplines": [
            "Corporate Services",
            "Diet & Nutrition",
            "Group Classes",
            "Taster Session",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 15,
          "price": {
            "min": 32.5,
            "max": 70
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fEDwanVB-t%26tbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "MOBILE PERSONAL TRAINERS & MASSAGE THERAPISTS LONDON",
          "biography": "T&T, delivering fitness and wellness to your doorstep: we are here to help you make healthy living your lifestyle! At T&T we are passionate about what we do and we are all about delivering a premium service to help you reach your fitness goals. Whether you want to achieve weight loss, muscle growth, fitness boxing, fitness MMA, prepare for a marathon or simply maintain general well-being, we have a specialist that will successfully lead you on your fitness journey.  As your safety is our priority, all our personal trainers and therapists are highly qualified, fully insured and hold First Aid certifications. In today’s fast-moving world, many of us find we lack time. Because we acknowledge this current problem, we have made things more convenient for you by delivering our services to your chosen location, so there is no excuse for you to neglect your personal commitment to health and fitness. Prioritising your health and fitness - not only for the physical benefits, such as improving your cardiovascular and respiratory systems, and boosting your immune system - also helps to reduce stress levels, improve sleeping patterns and boost productivity. Our brains become more agile, leading to more rational thinking, better decision making and improved problem solving.  We are here to help you make healthy living your lifestyle!",
          "companyWebsite": "http://www.trainingandtherapies.com/",
          "locations": [
            {
              "geolocation": [
                -0.1883225000000266,
                51.492415
              ],
              "city": "London",
              "postcode": "SW5 0HN",
              "_id": {
                "$oid": "59e5bc7c94e4d86bf9be1879"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "T&T: Training and Therapies",
          "phone": {
            "code": "+44",
            "number": "8006894682"
          },
          "qualifications": [],
          "offers": "Please visit http://www.trainingandtherapies.com/prices for packages & offers",
          "testimonials": "Petr is a great trainer. He designs a customised program based on what you're looking to do and your specific fitness goals. He has been able to work around certain injuries I have and, most importantly, keeps our weekly sessions fresh by always working in new exercises. You may find yourself cursing his name as he encourages you to hold that plank for a little bit longer, or do that extra rep, but rest assured it is well worth it. I'd recommend Petr for anyone looking for an experienced PT.\nRuss Morgan\nBidvine.com\nCo-founder\n\nVanessa was excellent. I was suffering from a chronic illness and she was a huge help in figuring out what foods I was able to tolerate. And once we had a list of foods she somehow magically turned them into wonderful meals. She is sincere, intelligent, and an excellent cook. I highly recommend her to anyone needing a healthy cook or caterer.” \n \n​\nGiulia Levitin,\nProperty Investment."
        },
        "score": 62
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "584f21b9550fc03d31c3937b"
      },
      "lastUpdated": {
        "$date": "2017-10-17T08:38:01.236Z"
      },
      "createdAt": 1481580985269,
      "email": "fitgirlz@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "fitgirlz",
      "provider": "local",
      "salt": "vPZHVa9Pn4sgNoScF4Il2g==",
      "hashedPassword": "WvPQ+ckuzpOHx3M/bZmopTBVdrk8wkWZvy/vxQgeBTcCv9kK1Z9bhXvV9GvqHL5gJG3zzl459KFYHVPUiXxcPw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lW5XnFtc-fitgirlzprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Ladies Only",
            "Pre / Post Natal",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 8,
          "price": {
            "min": 40,
            "max": 45
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/msQhocYq-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Play Strong Train Strong",
          "biography": "Fitgirlz is a mobile personal training company based in London that specialises in women’s weight loss, women’s strength training, post pregnancy training and bespoke nutrition programs. At Fitgirlz we take a different approach to training, we do not believe one size fits all type of training, and we aim to empower women through training, education and personal experience. We don’t believe in fad diets. We believe in making lifestyle changes which last a lifetime. At the same time, we ensure that you have a fun time while achieving these goals.  When we say we specialise in women’s training what we mean is we try to research and find ways and exercises best suited to women’s physiology, problem areas, hormonal changes, etc. We believe that most women put so much emphasis on cardio training while trying to lose weight/tone up but not enough emphasis on resistance training. One of the biggest fear my clients have when we start training is that “they don’t want to bulk up”. Our particular training method is not aimed to bulk up our clients but to increase lean muscle to a certain level so that we can speed up the fat loss process. We achieve this by metabolic resistance training.  We believe that women can train like men but structuring training around women’s monthly cycles, which we call metabolic cycle training, can help increase the results in both weight loss and gaining lean muscle and we believe, “There is no impossible”.",
          "companyWebsite": "http://fitgirlzpt.uk/",
          "locations": [
            {
              "geolocation": [
                -0.22918809999998757,
                51.4525394
              ],
              "city": "London",
              "postcode": "SW15 3SX",
              "_id": {
                "$oid": "59e5c16994e4d86bf9be187b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "FITgirlz",
          "phone": {
            "code": "+44",
            "number": "7885976738"
          },
          "qualifications": [],
          "offers": "Personal Training Blocks:\n\n10 sessions £450\n20 sessions £850\n30 sessions £1200\n\nProgram design+ diet plan + 1 hour training session £69",
          "testimonials": "With Selin,there is no such thing as \"I cant\".She will push you to your limits and train you harder than you have ever trained before.The sessions,tough tough,are always enjoyable because they are so varied and because Selin,as well as being a fantastic trainer,is a great company!\n\n Hilary Layton\n\nAbout 16 months ago I decided to improve my fitness levels and sort out a weak back which caused me issues when I exercised. My weak back was as a result of carrying twins full term. I met with Selin who identified some of the issues causing my back problems and how I was approaching exercise. We immediately started a programme to improve my upper body / back strength and overall fitness levels. During this period I had no back problems and have run two half marathons and will run the London Marathon in 2015. Selin is great fun to work with and the session flies by. I actually look forward to my regular sessions, regardless of the muscle burn!\n\nNicola Barrett\n\nI’ve been training with Selin for several years and I still really enjoy our sessions. Selin keeps things fun, tough and different every week. She makes sure I push myself and is always encouraging me to do those extra ten reps!My main goal when starting training was to lose weight and keep fit. I’m by far the fittest I’ve ever been and I managed to lose nearly two stone in time for my sisters wedding with Selin’s help. I couldn’t have done it without her.\n\n Francesca Farley"
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "584fc1f4550fc03d31c3ac1b"
      },
      "lastUpdated": {
        "$date": "2017-10-17T15:00:17.243Z"
      },
      "createdAt": 1481622004661,
      "email": "dsfit@shoomon.xyz",
      "firstName": "David",
      "surname": "Staffell",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "ds_fit",
      "provider": "local",
      "salt": "maqlcxSRn5Nx0Udha48PGg==",
      "hashedPassword": "zxOpty5Dwh2+4fx/c2nSExzaC9Rb5kzEzQqlyg1xc+osvBskl7HmVc+aZ53UYrYJzYzgNOJxgt+HII8ZaugSPA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/edejgfRG-dsfitlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fat Loss",
            "Pre / Post Natal",
            "Sports Therapy & Rehab",
            "Sports Specific"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 10,
          "price": {
            "min": 42.5,
            "max": 55
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qkyPvHOq-dsfit%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Mobile Personal Training across SW London",
          "biography": "Personal Training:\n\nFat loss and weight management. Muscular toning and definition. Core stability strength training.\nAerobic/cardiovascular conditioning. Flexibility and mobility training. Speed and agility training.\n\nWe use a wide range of safe, effective and challenging exercise disciplines.\n\nAfter careful assessment we create a personalised programme that will help you reach your goals as quickly as possible.\n\nTraining is available one-to-one, in pairs (buddy training), or for groups of 3 or more people.\n\nInjury rehabilitation:\n\nWhether you’ve suffered an injury from an accident or a sports-related activity, we can help you regain full fitness and mobility.\n\nAnte & post-natal exercise:\n\nPregnancy inevitably takes its toll on both fitness and weight. We are qualified to help you cope with these effects both before and after giving birth. We are experienced in recovery from both natural and c-section births.\n\nNutritional counseling:\n\nExercise must be complemented by a balanced diet. If you are at all concerned, we can help identify problem areas in your eating habits and plan accordingly to improve them.\n\nSport/event-specific training:\n\nIf you are trying to improve your fitness for a particular sport, or you have an event coming up that requires you to be in peak physical form (for example a marathon), we can create a programme specially tailored to that end.",
          "companyWebsite": "http://dsfit.co.uk/",
          "locations": [
            {
              "geolocation": [
                -0.23286020000000462,
                51.4261562
              ],
              "city": "London",
              "postcode": "SW19 4UW",
              "_id": {
                "$oid": "59e61b0194e4d86bf9be193d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "DS FIT",
          "phone": {
            "code": "+44",
            "number": "7714961129"
          },
          "qualifications": [],
          "offers": "One-on-one training - Normal Hours:\n\nTrial session\t£35\nSingle session\t£55\n5-session pack\t£250\n10-session pack\t£475\n\nOne-on-one training - Off peak Hours: \n\nTrial session\t   £35\nSingle session\t   £49\n5-session pack   £225\n10-session pack £425\n\nBuddy training (prices for 2 people) - Normal hours:\n\nTrial session\t        £50\nSingle session\t        £77\n5-session pack\t£335\t\n10-session pack\t£600\n\nBuddy training (prices for 2 people) - Off Peak hours:\n\nTrial session\t           £50\nSingle session\t           £70\n5-session pack\t   £300\n10-session pack\t   £540\t\n\nPlease enquire about shorter or longer sessions.\n\nNew mum:\n\nYou’ve had a baby in the last 9 months and you really want to get rid of that post-pregnancy weight. Two options:\n12 x 1 hour sessions: £550 (recommended 3 sessions/week for 4 weeks)\n24 x 1 hour sessions: £1000 (recommended 3 sessions/week for 8 weeks)\n\nBride/groom to be:\n\nYou are getting married soon and you need to get in shape and look your best. Train on your own or with your partner.\nBride OR Groom – 20 x 1 hour sessions: £850\nBride AND Groom – 20 x 1 hour buddy sessions: £1000 (£25 per person per session).\n\nIntensive:\n\nFor the determined:\nAll sessions must be completed within an agreed 60 day period (roughly 3 sessions per week).\n21 x 45 minute sessions: £850",
          "testimonials": "“I just wanted to write to say a very BIG thank you for the last few months. I am so delighted with the results of my personal training with you. Not only have I enjoyed the sessions enormously but I have really benefited from your advice and expertise. It wasn’t easy dealing with my big birthday but I feel so much  better and fitter and am told I look better so am very happy!”\n\n“Katie’s sessions are tailored to your level of fitness and energy. She keeps you going with encouragement and distraction, and makes the training fun.  You will know about it the next day though!”\n\n“Sila is an excellent trainer. Incredibly knowledgeable with each session tailored specifically to your goals. I started working with Sila to train for a specific event and her help was invaluable so I plan to train with her indefinitely.”\n\n“I’ve been training with Ed for a year now and would happily recommend him. He focuses on all areas of fitness, including diet and motivation. He’s all about progress and the workouts get more challenging – it’s really satisfying to see that I can do way more now than I could a year ago.  Unlike other PTs I’ve worked with, he provides varied workouts so it stays interesting. Plus he’s great fun!”"
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 11
    },
    {
      "_id": {
        "$oid": "584fc9e3550fc03d31c3ad31"
      },
      "lastUpdated": {
        "$date": "2017-10-17T10:24:48.416Z"
      },
      "createdAt": 1481624035054,
      "email": "metabolicfitness@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "metabolic_fitness",
      "provider": "local",
      "salt": "kawx5hNj4SJ88t3xwAe2bQ==",
      "hashedPassword": "MG7LnHAoYTkke3ippfOGlEJWzEVSGzuKYC4XEl+G93556c9cdXYXflY8jF5n4QajFGeTz6IeDROGm0lB71RHHQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TKaz0HMk-metabolic%20logo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Full Body Workouts",
            "Interval Training",
            "Partner Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 10,
          "price": {
            "min": 30,
            "max": 60
          },
          "facebook": "",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TpZp0yTK-metabolicbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Mobile Personal Training across SW London",
          "biography": "Discover how our dedicated team of fitness trainers can help you: we are focused on delivering personal fitness training that will help you lose fat, get fit and feel great! Our private Fitness Studios are dedicated to your Fitness. Our vision is to inspire and empower every person to add value to their life through facilitative fitness. With more than 10 years national and international fitness experience our team are continually focused on ensuring our clients get the best solution for their Fitness needs. Our studio is located minutes away from Chiswick town hall, just opposite Chiswick post office, on prospect place. Visit us and schedule your trial session now, or arrange a mobile visit to your house or a park in the SW London area.",
          "companyWebsite": "http://www.metabolicbootcamp.co.uk/wordpress/",
          "locations": [
            {
              "geolocation": [
                -0.20408080000004247,
                51.4353147
              ],
              "city": "London",
              "postcode": "SW19 7HS",
              "_id": {
                "$oid": "59e5da7094e4d86bf9be1898"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.26879699999994955,
                51.489584
              ],
              "city": "London",
              "postcode": "W4 4JU",
              "note": "Turnham Green, Chiswick High Road (Opposite Chiswick Town Hall)",
              "_id": {
                "$oid": "59e5da7094e4d86bf9be1897"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.26230229999998755,
                51.49598049999999
              ],
              "city": "London",
              "postcode": "W4 5LH",
              "note": "Acton Green Common (opposite Laveli Bakery)",
              "_id": {
                "$oid": "59e5da7094e4d86bf9be1896"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.25556670000003123,
                51.49240940000001
              ],
              "city": "London",
              "postcode": "W4 2DT",
              "note": "W4 Fitness Studio - 155 Chiswick High Road above Chestertons. Entrance on  Annandale Road.",
              "_id": {
                "$oid": "59e5da7094e4d86bf9be1895"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "Metabolic Fitness",
          "phone": {
            "code": "+44",
            "number": "7525061139"
          },
          "qualifications": [],
          "testimonials": "“The ONLY fitness company worth training with! I purchased the Skinny Jeans workout plan and worked one to one with a trainer, the results that I achieved are amazing, I no longer feel like a lump and I’ve got so much energy. Thank you so much! x.” \n\nH - Lawyer\n\n“Our trainer was great, the small group bootcamp was refreshing much better than being stuck inside a gym. He brought a bunch of equipment with him, I had trouble doing 5 push ups but now I can do 20+ with strict form! Thanks for all your help.”\n\nJoseph Harke - Event's Manager\n\n“My goal was to lose weight before my 40th birthday, training in a small group bootcamp was great, everyone supports each other. Many a time did I curse my trainer for making me do squats and kettlebells but I’m so grateful that he did!”\n\nDavid Deas - Business Analyst\n\n“Who invented kettlebells! I hate them so much but I love the challenge! Good fun, a lot of hard work. It was a worthwhile investment, my fitness, strength improved and I’ve lost fat!”\n\nElliot"
        },
        "score": 59
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "584fd24d550fc03d31c3ae75"
      },
      "lastUpdated": {
        "$date": "2017-10-17T10:46:48.166Z"
      },
      "createdAt": 1481626189076,
      "email": "westlondonpt@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "west_london_pt",
      "provider": "local",
      "salt": "dL14xF5cC0p2iqDx+kk0Tg==",
      "hashedPassword": "v7v1VOtTV2IKl0LOfhZ01MX9UtY6UzaJJGaw+K6ZnonocBag/UtwXxl2wmXGwKW0Gy9AaSgmyYBx460iHEybeA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/C7rphBqJ-westlondonprofile.png"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Full Body Workouts",
            "Interval Training",
            "Partner Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 6,
          "price": {
            "min": 53,
            "max": 70
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vzsqtUJk-westlondonbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Our dedicated team of fitness trainers can help you. We are focused on delivering personal fitness training that will help you lose fat, get fit and feel great!  Our team of personal trainers are dedicated to your Fitness! We are based in Chiswick and Mortlake and have a team of mobile trainers that can come to your location. We train clients in Richmond, St Margarets, Twickenham, Chiswick, Mortlake, Barnes and Kew. Our vision is to inspire and empower every person to add value to their life through facilitative fitness. With more than 10 years fitness experience our team are continually focused on ensuring our clients get the best solution for their Fitness needs.  We want to help you look, feel and perform better, we focus on enhancing your movement patterns and use a smart progressive training system to deliver results. Our approach to training is holistic, we understand everyone’s lifestyle can be busy so we push when you’ve got the energy and reduce the intensity when you don’t. If you wanted to sprint to catch the bus/train, push a car up a hill or climb a tree, we want you to have the power, strength and flexibility to do so. To ensure results, we focus on identifying and improving three key areas, stress, nutrition and movement. We want to help you improve your overall performance and in doing so look and feel better!",
          "strapLine1": "Mobile Personal Training across SW London",
          "companyWebsite": "http://www.westlondonpersonaltraining.com/about-us/",
          "locations": [
            {
              "geolocation": [
                -0.20408080000004247,
                51.4353147
              ],
              "city": "London",
              "postcode": "SW19 7HS",
              "_id": {
                "$oid": "59e5df9894e4d86bf9be18ac"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.26879699999994955,
                51.489584
              ],
              "city": "London",
              "postcode": "W4 4JU",
              "note": "Turnham Green, Chiswick High Road (Opposite Chiswick Town Hall)",
              "_id": {
                "$oid": "59e5df9894e4d86bf9be18ab"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.26230229999998755,
                51.49598049999999
              ],
              "city": "London",
              "postcode": "W4 5LH",
              "note": "Acton Green Common (opposite Laveli Bakery)",
              "_id": {
                "$oid": "59e5df9894e4d86bf9be18aa"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.25556670000003123,
                51.49240940000001
              ],
              "city": "London",
              "postcode": "W4 2DT",
              "note": "W4 Fitness Studio - 155 Chiswick High Road above Chestertons. Entrance on  Annandale Road.",
              "_id": {
                "$oid": "59e5df9894e4d86bf9be18a9"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "West London PT",
          "phone": {
            "code": "+44",
            "number": "7525061139"
          },
          "qualifications": [],
          "offers": "The Express\n\n5 sessions £33 per 30 min session\n\n10 sessions - £26.50 per 30 min session\n(3 month expiry)\n\n45 and 60 min sessions available- contact us to schedule your session.\n\nPre-pay monthly (minimum 4 sessions)\n\nPeak: £30 per 30 min session\nOff peak - £27 per 30 min session (Mon - Fri 9am -5pm, 1 month expiry). Sessions to be completed before month end.",
          "testimonials": "I gave my trainer, Kang, the challenge of getting a overweight middle aged man back into shape. It’s been a journey. I’ve been training with him 3x a week and although this sounds alot, it doesn’t ever get boring. I can move tons better and lost inches off my waist line. Kang, has been a big part of my support unit, he’s different from other personal trainers and genuinely cares about your well being and health. You won’t regret making him your trainer!\n\nKang is infectiously enthusiastic and always introduces variety to a training session. It’s clear that Kang really loves his work and really cares about his clients – and he’s the first person who has made me look forward to going to the gym.  He definitely pushes me to my limits – but never beyond them – and I know that he takes me much further than I would ever have the discipline to go by myself.  At the end of a training session – which Kang always ends with a series of stretches – I feel really energised and well exercised rather than exhausted."
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "584fdb15550fc03d31c3afe7"
      },
      "lastUpdated": {
        "$date": "2017-10-17T11:03:15.361Z"
      },
      "createdAt": 1481628437381,
      "email": "letsgetufit@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "lets_get_u_fit",
      "provider": "local",
      "salt": "dkOorNM6SjKEpP8G3oMpPw==",
      "hashedPassword": "E264/lFDGRlU4HIFLQMBkheeVBb8o1V5yZMJGPUqbjd+HomwrH3KAz+h9r9oUi4pEoKNEdhWMm2ur3LOnpX5pg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EscQcch8-letsgetprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fat Loss",
            "Pre / Post Natal",
            "Sports Therapy & Rehab",
            "Sports Specific"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 10,
          "price": {
            "min": 40,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LQdmwFPE-DSC_0124.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Dedicated Personal Training for the Everyday Londoner",
          "biography": "Being fit and healthy is a journey, not a destination; there’s no secret diet, exercise or spell that will lead to the body you’ve always wished for (as far as we’re aware!). However, we at LetsGetUFit believe that knowledge is power; the capacity to change your life for the better by following diet advice and exercise techniques that are specific to YOUR needs. With the right guidance, determination and mind-set, you will have the power to become a fitter, healthier U…We offer tailored exercise programs for all types of clients, from fat loss to pre/post natal (see ‘services’ tab). Although demanding, our training sessions are designed to be as fun and effective as possible, ensuring you see results faster than ever. Furthermore, ‘muscle confusion’ is achieved by periodically varying the type of exercise you perform, meaning that your body has to adapt in response to this new workload.LetsGetUFit is mainly gym-free, meaning we train clients in their own homes or in public places (we even offer a 10% discount for certain parks!). Using our own equipment, such as TRX® and boxing pads, we use a variety of training techniques that will push your body in a non-intimidating environment. We can however provide workout sessions in our Putney studio, equipped with free weights and squat racks where we can teach you those compound moves you thought were never possible. Please get in touch as we’d love to hear from you - It’s time to GetUFit…",
          "companyWebsite": "http://www.letsgetufit.co.uk/",
          "locations": [
            {
              "geolocation": [
                -0.2059669999999869,
                51.445116
              ],
              "city": "London",
              "postcode": "SW18",
              "_id": {
                "$oid": "59e5e37394e4d86bf9be18b3"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.2059669999999869,
                51.445116
              ],
              "city": "London",
              "postcode": "SW18",
              "note": "next to Marks and Spencer, a short walk from Southfields Tube Station.",
              "_id": {
                "$oid": "59e5e37394e4d86bf9be18b2"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "LET'S GET U FIT",
          "phone": {
            "code": "+44",
            "number": "7870946561"
          },
          "qualifications": [],
          "offers": "Please visit our website for details."
        },
        "score": 57
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "584fe271550fc03d31c3b0fd"
      },
      "lastUpdated": {
        "$date": "2017-10-17T11:41:50.137Z"
      },
      "createdAt": 1481630321552,
      "email": "soulactive@shoomon.xyz",
      "firstName": "Duncan",
      "surname": "Edwards",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "soul_active_personal_training",
      "provider": "local",
      "salt": "cLbzmaWZQDAJEhiULybghA==",
      "hashedPassword": "/KECTX3KLdOGlZEt8OmCqWf/X59hYQPjMVfMVIrjGvMEbagubuVi4hsvbHdkaX+o9RUBNLv1xDFeyo7vEEgT6w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IBtlzZrH-soulprofile2.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing Based Training",
            "Endurance",
            "Pilates",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Mobile Personal Training",
          "radius": 7,
          "price": {
            "min": 38,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/07AYWjqy-soulbg2.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Mobile Personal Trainers across West London",
          "biography": "Soul Active are London’s premier personal-training team covering the whole of West London to Central London. Our personal trainers are experts in their field with years of experience offering the latest in personal-training techniques and programmes to suit each individual. Soul Active personal trainers are highly mobile and will come to you. Whether you want to train in your home, garden or local park it’s completely up to you. Soul Active work with the best personal trainers, hand picked for their combination of knowledge, professionalism and general all round friendly attitude: no screaming and shouting – guaranteed.  Choose Soul Active for personal training in West London, the premier personal-training team in the capital. We specialise in up-tempo, enjoyable workouts, so if you’re looking to get fit, lose weight or train for a specific event we can help you. We’re passionate about personal training and we can help you to discover your inner strength and transform your fitness regime in a fun and active environment. To arrange a no pressure consultation please contact Soul Active today and get ready to improve your health and fitness tomorrow!",
          "companyWebsite": "http://www.soulactive.co.uk/",
          "locations": [
            {
              "geolocation": [
                -0.15261409999993703,
                51.4428311
              ],
              "city": "London",
              "postcode": "SW12 9SJ",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1927"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.23920520000001488,
                51.4752941
              ],
              "city": "London",
              "postcode": "SW13 9RU",
              "note": "Barnes",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1926"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.1482300000000123,
                51.477992
              ],
              "city": "London",
              "postcode": "se15",
              "note": "Battersea Park",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1925"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.12769990000003872,
                51.50739069999999
              ],
              "city": "London",
              "postcode": "WC2N 5DU",
              "note": "Central London",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1924"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.1726449999999886,
                51.48885829999999
              ],
              "city": "London",
              "postcode": "SW3",
              "note": "Chelsea",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1923"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.1383883999999398,
                51.461786
              ],
              "city": "London",
              "postcode": "SW4",
              "note": "Clapham Common",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1922"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.20168169999999463,
                51.4773433
              ],
              "city": "London",
              "postcode": "SW6",
              "note": "Fulham",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1921"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.22373149999998532,
                51.4911875
              ],
              "city": "London",
              "postcode": "W6",
              "note": "Hammersmith",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1920"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.1826039000000037,
                51.50054890000001
              ],
              "city": "London",
              "postcode": "SW7",
              "note": "Hyde Park",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be191f"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.1932793999999376,
                51.5010095
              ],
              "city": "London",
              "postcode": "W8 6TR",
              "note": "Kensington",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be191e"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.21718009999995047,
                51.4605741
              ],
              "city": "London",
              "postcode": "SW15 6AQ",
              "note": "Putney",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be191d"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.1419949999999517,
                51.532692
              ],
              "city": "London",
              "postcode": "NW1",
              "note": "Regent's Park",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be191c"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.3037420000000566,
                51.46131099999999
              ],
              "city": "Richmond",
              "postcode": "TW9 1HJ",
              "note": "Richmond",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be191b"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.19968900000003487,
                51.447491
              ],
              "city": "London",
              "postcode": "SW18 5TQ",
              "note": "Southfields",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be191a"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.20600100000001476,
                51.45755
              ],
              "city": "London",
              "postcode": "SW15 2RT",
              "note": "Wandsworth",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1919"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.22062879999998586,
                51.41833889999999
              ],
              "city": "London",
              "postcode": "SW19 4NR",
              "note": "Wimbledon",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1918"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.15261409999993703,
                51.4428311
              ],
              "city": "London",
              "postcode": "SW12 9SJ",
              "note": "Balham",
              "_id": {
                "$oid": "59e5ec7e94e4d86bf9be1917"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "Soul Active Personal Training",
          "phone": {
            "code": "+44",
            "number": "7852705745"
          },
          "qualifications": [],
          "offers": "We offer various packages starting from £38 per session and are one of the few companies in London who allow you to pay monthly, so no large investment up front. You train, you pay – simples!"
        },
        "score": 58
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "58501c30550fc03d31c3b9fc"
      },
      "lastUpdated": {
        "$date": "2017-10-17T12:32:21.055Z"
      },
      "createdAt": 1481645104494,
      "email": "freefitness@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "free_fitness",
      "provider": "local",
      "salt": "vEjmB5HAOeAvfI/yZdPXWg==",
      "hashedPassword": "dxYQ45VrJF+IJg6ne/gz1O8KmctfATQAfdMzyEFrvtRvS5tqEiS13tgu3lPr0BPIi0nWMOUGRTIHc5iBXIzoww==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xv17DIVy-free%20profile2.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing Based",
            "Corporate Services",
            "Diet & Nutrition",
            "Interval Training",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "profileTitle": "Boot Camp",
          "radius": 8,
          "price": {
            "min": 38,
            "max": 50
          },
          "facebook": "",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/o4oPbebN-free%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Elite Outdoor Fitness",
          "biography": "London’s leading, most-dynamic outdoor fitness company. Free Fitness offers every aspect of fitness, from exhilarating outdoor class memberships to corporate fitness programs and elite personal-training services. We provide exclusive fitness and personal-training experiences for people who are serious about changing their bodies and improving their health and well being.  We offer a range of exceptional, high-quality fitness services at affordable prices. Our years of experience have enabled us to develop our services into some of the most elite, effective and fun fitness sessions you can have. Our highly-experienced training instructors use the latest fitness techniques, equipment and methods to motivate and challenge you into becoming the person you want to be. Start your journey with us today...* All results may vary - we cannot guarantee desired results.",
          "companyWebsite": "http://www.freefitness.uk.com/",
          "locations": [
            {
              "geolocation": [
                -0.14003200000001925,
                51.45993329999999
              ],
              "city": "London",
              "postcode": "SW4 9BS",
              "_id": {
                "$oid": "59e5f85594e4d86bf9be193a"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.1403867999999875,
                51.45970510000001
              ],
              "city": "London",
              "postcode": "SW4 9BS",
              "note": "Clapham Common: We meet on the grass where Rookery Road meets Clapham Common Southside",
              "_id": {
                "$oid": "59e5f85594e4d86bf9be1939"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.15076350000003913,
                51.4348249
              ],
              "city": "London",
              "postcode": "SW17 8JJ",
              "note": "Tooting Bec Common: the class meets on the grass opposite opposite Streathbourne Road",
              "_id": {
                "$oid": "59e5f85594e4d86bf9be1938"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.16598590000000968,
                51.4455854
              ],
              "city": "London",
              "postcode": "SW17 7EG",
              "note": "Wandsworth Common: meet on the grass by the bus stop on Bellevue Rd opposite Cafe Nero- Hope pub end",
              "_id": {
                "$oid": "59e5f85594e4d86bf9be1937"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "Free Fitness",
          "phone": {
            "code": "+44",
            "number": "8454590940"
          },
          "qualifications": [],
          "offers": "Please visit our website for details of prices & packages",
          "testimonials": "Having trained alone for years I was unsure of what to expect from a Personal Trainer, but my Freefitness Instructor has been a brilliant support, pushing me and challenging me every time we train. The sessions were fun, varied and delivered in a manner that makes me want to work hard. My trainer listened carefully and tailored my sessions to achieve my targets. I received loads of advice and knowledge and seeing how passionate they were passionate about helping their clients was a really nice touch. On top of this, they were also very funny! I hit my target weight loss target two weeks before schedule, and would wholeheartedly recommend my experience to anyone!\n\nLuke\n\nBefore we started with our freefitness package we lacked the motivation and discipline that we needed to reach our health and fitness goals. Each session is creative and exciting, a little painful but certainly rewarding! From our first session we have been pushed well outside our comfort zone but i surprised myself as to what i was actually capable of! We base our sessions in the park and even through the winter my trainer always turned up smiling and raring to go! Their style of training keeps us motivated enough to be able to see the results and keep going. We would definitely recommend our time with freefitness to anyone, whatever your fitness or motivation level!\n\nRosie & Henry"
        },
        "score": 62
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "58515a7f550fc03d31c3eab6"
      },
      "lastUpdated": {
        "$date": "2018-01-18T15:24:02.160Z"
      },
      "createdAt": 1481726591097,
      "email": "kellyanneastwood@yahoo.co.uk",
      "firstName": "Kelly",
      "surname": "Eastwood",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "username": "kelly_eastwood_BkwFcAAXg",
      "provider": "local",
      "salt": "6Ru59QF/KXNlaUKNgk4bqQ==",
      "hashedPassword": "iX624ckaOiitS8BeGHLC4K44zIUq7H9qZnVdNb8hmkYaL9ejj3F4p3KEOYvXGK1cfWXHS+1K8LK0gpT1QpDAww==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EUQvZsTf-IMG_3250.PNG"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 25,
            "max": 35
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3I8rANzB-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "companyWebsite": null,
          "yearOfBirth": 1993,
          "biography": "Mobility, hypermobility and nutrition specialist.",
          "strapLine1": "Personal trainer, Cambridgeshire based. Fit food lover. Hypermobility body transformation and mobility specialist.",
          "locations": [
            {
              "geolocation": [
                -0.23761667167359812,
                52.232152383407794
              ],
              "city": "Cambridgeshire",
              "postcode": "PE19 6DN",
              "_id": {
                "$oid": "5a60bc12765eba094646f7e0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7943815507",
            "code": "+44"
          },
          "qualifications": [
            "Level 3: Personal Trainer"
          ]
        },
        "score": 61,
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5851d3e47d4bd10cdd9bed8a"
      },
      "lastUpdated": {
        "$date": "2018-02-19T11:32:22.993Z"
      },
      "createdAt": 1481757668172,
      "email": "matt.brinkley@inspiring-fitness.co.uk",
      "firstName": "Matt",
      "surname": "Brinkley",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "MaEr8QDKj5+V4xy8yllhFg==",
      "hashedPassword": "fZWP4YesFEedDFFbPLAaw4Uc/6GtZxCOXm0sr8wb9uE3itwMSZxb0FhRqWw7lcLL872uu1cd6j/STy9UxggeSw==",
      "settings": {
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DIbwvHr7-IMG_3179.PNG"
        },
        "personal": {
          "disciplines": [
            "Mobile Trainer",
            "Nutrition",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "Http://Mattbrinkley.Inspiringfitness.co.uk",
          "profileTitle": "Personal Trainer",
          "radius": 40,
          "price": {
            "min": 40,
            "max": 45
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sTDQ3KU3-IMG_3180.PNG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                0.5221266180458076,
                51.86349037459358
              ],
              "city": "Great Notley",
              "postcode": "CM77",
              "_id": {
                "$oid": "5a8ab5c6f3d6ea3af77dc43e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Matt Brinkley Inspiring Fitness - Personal trainer",
          "phone": {
            "number": "7557118770",
            "code": "+44"
          },
          "biography": "Background:\n\nHi, I'm Matt Brinkley. I'm an Inspiring Fitness Personal Trainer delivering a quality one to one service in Essex and an innovative online training service worldwide.\n\nWhy I Chose To Be A Fitness Professional:\nI have always participated in sports activities, regular exercise and activity simply seems to make sense to me. Although, this may not be the case for others... I aim to inspire others to integrate a healthy, active lifestyle into a busy working day!",
          "offers": "Please contact me for details of prices, packages & offers.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Golf Fitness Specialist (NASM)"
          ],
          "testimonials": "My Personal Trainer Matt Brinkley was very professional, efficient and reliable.\nCatherine, Langham\n\nI have been very impressed with the service offered by Inspiring Fitness from my first contact. Matt has been very 'inspiring' and I have found my personal training sessions fun which has enabled me to stick with it. I have achieved a good level of fitness in the last 4 months and intend to continue.\nLorraine, Witham\n\nThe time arrived in my life when I did not feel as fit or flexible as I should do and those extra inches were creeping on around my body. What should I do? Look for a keep fit programme or maybe a gym? Inspiring fitness seemed to tick all the boxes and I joined up along with my husband. Matt our personal trainer has proved energetic, inspirational and has a good sense of humour. I have achieved that magical moment when I can tuck tops and shirts into my shorts/trousers and not let them hang loose outside to cover up the lumpy bits. Learning to wear gloves and use pads for boxing was not on my radar but hey its fun and a great work out. Every session is different and that keeps the motivation high and the mind alert. I feel and look better, activity rate is higher and I am always looking forward to the next session.\nJeannette, Essex",
          "affiliation": "REPs",
          "strapLine1": "Lose Weight, Get Fit and Feel Great"
        },
        "score": 70
      },
      "active": true,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "matt_brinkley",
      "approved_date": null,
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "5852db543b03c154c3376e58"
      },
      "lastUpdated": {
        "$date": "2018-01-18T15:15:55.563Z"
      },
      "createdAt": 1481825108434,
      "email": "fitnup.uk@gmail.com",
      "firstName": "Paul",
      "surname": "Joseph",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "5wQ6BIZRNI2aBV0YIKMbPw==",
      "hashedPassword": "8kSSo4l5Ed1G8Tfxcy0Z+kwSzfgG1832XHQL4E2OvwCbvLCPZH901BGmo5RfpwVzLaOsWeK+9VwSr4bmc8cwhw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/XPuQgbha-1481825192233.jpg"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Sports Massage",
            "Sports Therapy",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 30,
          "price": {
            "min": 25,
            "max": 45
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oD2wJuT9-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.261155,
                51.5647552
              ],
              "city": "London",
              "postcode": "NW9 8SA",
              "_id": {
                "$oid": "5a60ba2b765eba094646f7de"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7917351383",
            "code": "+44"
          },
          "affiliation": "Premier, IIHHT, ACSM, NAT",
          "biography": "Paul Gained his Diploma in Fitness training and Sports Therapy and a Masters Diploma in Personal Training from Premier Training & Development back in 1999\n\nThis qualified him to train, instruct and treat as wide a range of clientele as possible, building a large client base with his company \"first 4 fitness\", in 2002 Paul moved to Portugal where again he built a substantial Personal Training Portfollio (fit N up personal training) before opening a specialist gym there \"New Spartan Gym\".\n\nIn 2016 due to enforced re location Paul has started visiting clients at their homes or the park or place of work and of course at your gym if they allow.\n\nPaul has spent most of his life involved in sports of one type or another, from the normal squash, badminton and swimming at school, representing his county, to triathlons and mountain biking and a keen interest in the martial arts, attaining a black belt in aikido, in recent years Paul has been studying more of the \"Old School\" methods of fitness and strength training, competing regularly in Strongman Competitions (Recently at Masters Level) and Highland Games Competitions\n\nIf you want to get fit  and strong for a specific sport or just want to lose a bit of weight and tone up or if you have an injury or muscle damage then Paul's specialist training methods will get you where you want to be fast and efficiently.",
          "qualifications": [
            "Premier Diploma in Fitness training and Sports Therapy",
            "Premier Masters Diploma in Personal Training",
            "IIHHT Diploma in Health  and Fitness Studies",
            "IIHHT Diploma in Sports Therapy",
            "ACSM Cerified Personal Trainer",
            "ACSM Certified Health and Fitness Specialist",
            "IHHT Diploma in Sports Therapy",
            "Anatomy of Stretching Cert - NCBTMB",
            "Corrective Exercise Solutions NAT Master Trainer",
            "Muscle Energy Techniques Cert - NAT",
            "Trigger Point Therapy Certified Practicioner",
            "Trigger Point Therapy Certified Trainer",
            "Trigger Point Therapy Certified Level 1",
            "Trigger Point Therapy  Certified Level 2",
            "Trigger Point Therapy Certified Level 3",
            "Premier Training Diploma in Sports Therapy",
            "Kinesio Taping Certificate"
          ],
          "yearsOfExp": 1999
        },
        "score": 60,
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "username": "paul_joseph",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5852fb303b03c154c3377338"
      },
      "lastUpdated": {
        "$date": "2018-01-18T14:58:37.638Z"
      },
      "createdAt": 1481833264549,
      "email": "xjake182@gmail.com",
      "firstName": "Jakub",
      "surname": "Wieckowski",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "GH4b+Gb4aW+rf93J0ym9jg==",
      "hashedPassword": "ltn+AkIS/FgclhDRwItBj8XNFqKx/lOPNv8NOlzL5Dv0+HYotiIItw28jysQOiiOZ8WTeQXZBDNWp9kWKCrIVA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pJDnTrQf-FIT011016-1061.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Nutrition",
            "Online Coaching",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 50,
          "price": {
            "min": 35,
            "max": 35
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0T4MwBzJ-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -2.7015360999999984,
                51.5010045
              ],
              "city": "Bristol",
              "postcode": "BS11 9AL",
              "_id": {
                "$oid": "5a60b61d765eba094646f7d7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7341822990",
            "code": "+44"
          },
          "biography": "I take my clients fitness goals seriously. I understand that losing weight and burning fat can be challenging, and my goal is to help you overcome hurdles and reach your goals. I analyse your body type, BMI, and metabolism, to create your individualised fitness plan.\n\nWith years of success and experience, I am capable of analysing your body and creating a fitness plan that will help you reach your goals. Most plans include healthy eating and exercise, to ensure the best results for my clients. I will work with you to keep you on track and motivated to reach your goals. \n\nI understand that adapting new fitness habits is difficult and often times fails. My goal is to change your view on fitness, so that it becomes a part of who you are and your lifestyle. I strongly believe that if you practice healthy habits, you will be able to obtain a healthy lifestyle.",
          "qualifications": [
            "Level 3: Personal Trainer"
          ]
        },
        "score": 54,
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "jakub_wieckowski",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5853007f3b03c154c33773f2"
      },
      "lastUpdated": 1497369042414,
      "createdAt": 1481834623611,
      "email": "alangordonhealth@aol.com",
      "firstName": "Alan",
      "surname": "Gordon",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "yZEJ6INmLmviimpZsBcaUA==",
      "hashedPassword": "8k+Ocuo0ye9mxuxFtl1ngrTT7BB4xqa1ERM9dV6JQNf77yUEL9Zw8m1f+SvS/e39iuajcOozt2vHZWyHw019ig==",
      "settings": {
        "wizard": {
          "complete": false,
          "start": true,
          "additional": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N4CZDrMP-alan_gordon_biomechanist%20profile.jpg"
        },
        "personal": {
          "disciplines": [
            "Nutrition",
            "Personal Training",
            "Seniors",
            "Sports Therapy",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://alangordon-health.co.uk/",
          "profileTitle": "General",
          "radius": 40,
          "price": null,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wXo8Padu-alan%20gordon%20bg.jpeg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Biomechanist and Personal Trainer: Over 45s and Women's Exercise, Injury and Nutrition Specialist",
          "biography": "Alan is a Biomechanist, which is an advanced exercise and injury professional : For you, it means fitness, exercise & injury expertise at impeccable safety levels, regardless of your gender, age, exercise/medical history or goals. All results can be achieved in the privacy of your home. Biomechanists are human-movement experts. They supply very safe and injury-free one-to-one personal training at profoundly reliable levels. Alan has 6 Drs, 2 surgeons, 3 physio's. 1 osteopath, 2 pilates teachers, 1 yoga teacher and 5 personal trainers among his current exercise clients. 47 years ago Alan Gordon began his training at the Royal Air bigstock-small-green-dumbbells--isolat-52291879Force School of Physical Training and then on to the Defence Medical Rehabilitation Centre at Headley Court; followed by continuing studies at Loughborough University.  At this point, be very much aware that his Armed Forces origin is not in any way his philosophy or working practice when it comes to fitness, exercise and flexibility.  Alan is strongly opposed to the methodologies of military fitness training handed out so randomly these days to anyone and everyone at boot camps etc. Such scenarios are responsible for a great many avoidable problems and injuries appearing in later life, of which he has seen consistent and regular confirmation first hand, time and time again over the last 47 years.",
          "offers": "Alan is an advanced expert in improving strength, flexibility and tone, along with weight loss, activity/sport specific exercise prescription, injury recovery and intelligent wellbeing expertise. His skills for taking someone into a permanently -stablished, higher-life fitness condition after injury and physiotherapy treatment are extremely effective.  Most of his clients range up from their late 30s and some of them are actually over 80 years old, all at various levels from novice exercisers to advanced. Age, weight, gender, present physical condition, including dietary/physical and medical limitations, are most certainly not the obstacle you might well have thought them to be at this point in your life.  Realistically-good results in keeping with your personal potential are always possible, without ever ‘busting a gut’ via being needlessly pushed to an overly tired, or sometimes even exhausted condition to try and achieve your goals. I’m afraid this happens all too often in personal training situations, usually ‘sold’ as being someone’s necessary commitment of effort in order to achieve success. This is absolute nonsense!  As a Biomechanist, Alan’s specialised knowledge of Biomechanics and exercise movement is extremely advanced, so a gymnasium or health club is totally unnecessary in order to achieve very good to sometimes inspiring results. Achievements regularly exceed client expectations, and all happening right there in the privacy of their own homes. Fees £55 Per hour",
          "locations": [
            {
              "geolocation": [
                -1.2464674000000286,
                51.7612056
              ],
              "country": "United Kingdom",
              "city": "Oxford",
              "postcode": "OX1 3JA",
              "primary": true
            }
          ],
          "companyName": "Alan Gordon MSc. BSc. (Hons 1st) Health & Wellbeing",
          "qualifications": [],
          "phone": {
            "code": "+44",
            "number": "7833110364"
          }
        },
        "score": 59,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "alan_gordon_msc._bsc._(hons_1st)_health_and_wellbeing",
      "password_reset_time": 1482081965744,
      "password_reset_code": "X"
    },
    {
      "_id": {
        "$oid": "5853bb453b03c154c33790b4"
      },
      "lastUpdated": {
        "$date": "2018-01-18T14:46:01.957Z"
      },
      "createdAt": 1481882437020,
      "providerId": "107067764868134775626",
      "provider": "google",
      "surname": "Bowman",
      "firstName": "Rob",
      "email": "rpb201@googlemail.com",
      "settings": {
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Nutrition",
            "Pre/post Natal",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 45,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/X5NeqROq-rob%20homepage%20pic%201.JPG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                0.26369499999998425,
                51.132377
              ],
              "city": "Royal Tunbridge Wells",
              "postcode": "TN1",
              "_id": {
                "$oid": "5a60b329765eba094646f7d6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7720517424",
            "code": "+44"
          },
          "biography": "I actually weigh less now at 32 than I did when I was 13 years old! And I have lost 2 ½ stones of body fat since I was 21. I struggled for years myself before finally managing to figure out a way to lose the weight, keep it off and transform my life. This personal experience means I know how you are feeling and makes me all the more determined to help you.\n\nI am not just a personal trainer. The truth is getting a personal trainer on its own is not enough for you to lose weight.\nAlso I am a nutritional advisor and have tirelessly studied what nutritional programs work best for weight loss.\nI am a motivation and mind-set coach, having done a business and management degree at university focusing on motivation and psychology.\nI am a qualified Sports Massage and Sports Injury Therapist so can help with any injury problems you may have.\nAnd yes I am a personal trainer (Register of Exercise Professionals Level 3 Qualified) having taken the Diploma in Personal Training with Premier Training (the best provider in the industry). I make my sessions varied and specifically designed for each individual client’s needs and goals. My equipment includes; free weights, boxing and kick-boxing gloves/pads, stability/swiss ball, medicine ball, speed running harness, ab roller, etc. Basically everything you need for a great, fat burning workout.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "Sports Massage and Sports Injury Therapist"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RwSKqL1y-filename.jpeg"
        },
        "score": 54,
        "offers": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "rob_bowman",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5853d9f53b03c154c3379561"
      },
      "lastUpdated": {
        "$date": "2019-02-27T08:48:58.784Z"
      },
      "createdAt": 1481890293675,
      "providerId": "10210860390206734",
      "provider": "facebook",
      "surname": "Carruthers",
      "firstName": "Macauley",
      "email": "mac.kaos@aol.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10210860390206734/",
          "disciplines": [
            "Groups / Teams",
            "Mobile Coach",
            "Strength",
            "Sports Specific"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Strength & Conditioning Coach",
          "radius": 20,
          "price": {
            "min": 20,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RbZnJvA6-IMG_20161206_090341.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -1.6371358999999757,
                52.81402809999999
              ],
              "city": "Burton upon Trent",
              "postcode": "DE14 2QB",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7837557289",
            "code": "+44"
          },
          "qualifications": []
        },
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/J9XJqWwf-filename.jpeg"
        },
        "score": 61,
        "offers": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "macauley_carruthers",
      "agent": {
        "family": "Chrome Mobile",
        "major": "72",
        "minor": "0",
        "patch": "3626",
        "source": "Mozilla/5.0 (Linux; Android 8.0.0; SM-G950F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/72.0.3626.105 Mobile Safari/537.36"
      },
      "approved_date": null,
      "feature_in_area": "none",
      "lastLogin": 1551257338721,
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5853dca83b03c154c33795be"
      },
      "lastUpdated": 1481893315317,
      "createdAt": 1481890984056,
      "providerId": "100596529024145014543",
      "provider": "google",
      "surname": "Gladdin",
      "firstName": "Charlie",
      "email": "charlie.gladdin@gmail.com",
      "settings": {
        "personal": {
          "google": "https://plus.google.com/100596529024145014543",
          "disciplines": [
            "Bodybuilding",
            "Personal Training",
            "Circuit Training",
            "Endurance",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 30,
          "price": {
            "min": 20,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jGogZXG3-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -1.6371358999999757,
                52.81402809999999
              ],
              "country": "United Kingdom",
              "city": "Burton upon Trent",
              "postcode": "DE14 2QB"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7753177775"
          }
        },
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kZVo4zqs-IMG_20161216_083444.jpg"
        },
        "score": 61,
        "offers": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "charlie_gladdin"
    },
    {
      "_id": {
        "$oid": "5853ee343b03c154c3379872"
      },
      "lastUpdated": {
        "$date": "2018-01-18T14:23:27.311Z"
      },
      "createdAt": 1481895476473,
      "email": "jason.ptyourway@gmail.com",
      "firstName": "Jason",
      "surname": "Challiss",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "Q5JaR6XQS5u/+JNDnN4V8w==",
      "hashedPassword": "qrX53Q7xFpVQEhvTnhIuE5j7PGaW9Zp16yu4rgDkGvjf1SlGWvsWxYdPAW3S/8nuQSNYHTxgyq84g2tAYi9C/w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UttTgQge-20150817_163248.jpg"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Group Classes",
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "price": {
            "min": 40,
            "max": 50
          },
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BcR3MIuH-FB_IMG_1480092608883.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.012856300000066767,
                51.4991951
              ],
              "city": "London",
              "postcode": "E14 9TS",
              "_id": {
                "$oid": "5a60addf765eba094646f7d5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7970712176",
            "code": "+44"
          },
          "biography": "Love working with people to achieve their health and fitness goals. To get them into that dress/suit or even jeans that they thought they would never get back into.\n\nGiving people the knowledge of how to make lifestyle changes and keep to them so they can live a healthier life while still enjoying themselves. \n\nI also love to train people on how to save lives with first aid. So ow being a train the trainer in first aid not only do I get to train people how to save lives but I also get to train people how to train other people how to save lives. \n\nLife is all about helping people and enjoying what you do and I can say I love training people both in first aid and as a personal trainer, expecially as now I get to have family time to.\n\nNext to do is open a small gym where people can come a train without all the normal anxieties of a full blown commercial gym, hopefully by mid way 2016 this will happen.",
          "qualifications": [
            "Level 3: Master Personal Trainer",
            "Level 4 first aid train the trainer"
          ]
        },
        "score": 54,
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "username": "jason_challiss",
      "password_reset_time": 1482328261077,
      "password_reset_code": "X",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5853f1363b03c154c33798fe"
      },
      "lastUpdated": {
        "$date": "2018-01-18T14:19:40.465Z"
      },
      "createdAt": 1481896246873,
      "email": "bodywise1@hotmail.com",
      "firstName": "David",
      "surname": "Isaac-George",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "Fnk3eL8l9g3AFcdLXlcrxw==",
      "hashedPassword": "GJW//kQ29RUm5BGNO7i9f4VaESU0T4xlLlem5HviwGDxhyToacDZSNYP7C8yyXzzVIjqJkhQaZDMBfFkS9YjBQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/x773f8pg-Screenshot_20161215-222419.png"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Sports Massage",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 16,
          "price": {
            "min": 45,
            "max": 55
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IJetoT2K-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.01176250000003165,
                51.5886383
              ],
              "city": "London",
              "postcode": "E17 3DX",
              "_id": {
                "$oid": "5a60acfc765eba094646f7d3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7956599568",
            "code": "+44"
          },
          "biography": "Hi, my name is David and I am dedicated to help you reach your health & fitness goals.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "B.Sc. (Hons) Informal & Community Education Diploma in Diet & Nutrition",
            "Diploma in Sports Psychology",
            "Advanced Resistance Training",
            "Kettlebell Instructor",
            "Level 4 for the Management of Low Back Pain",
            "Corrective Exercise",
            "Master in Personal Training",
            "Master Personal Trainer in Corrective Exercise",
            "Movement Based Flexibility",
            "Certificate in Resisted Movement Training",
            "YMCA Cardio Kick",
            "YMCA Exercise Nutrition",
            "YMCA GP Referral",
            "YMCA Lifestyle Fitness & Assessment"
          ]
        },
        "score": 52,
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "david_isaac-george",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58540aec9cbbf929e5bf7890"
      },
      "lastUpdated": {
        "$date": "2019-10-06T08:58:34.469Z"
      },
      "createdAt": 1481902828037,
      "email": "tools@longden.net",
      "firstName": "Chris",
      "surname": "Longden",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "6v7mzkyYSfooLy2CL1DFtQ==",
      "hashedPassword": "6q4SxuSTl22APBC0n7OOvmguC4c1+lFzSZE8T/FlNYevUktqBxvxocDYoN56aNDOSV9IBAcZ8+Z6vcQNupwHgQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": true,
        "hideFreeTrialMessage": true,
        "hideCalendarIntroVideo": true,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tDo22uaJ-ProfilePicture.jpg"
        },
        "personal": {
          "disciplines": [
            "4 Kids",
            "Bodybuilding",
            "Bootcamp",
            "Body Sculpting",
            "Boxing"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "price": {
            "min": 40,
            "max": 60
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154039720783440/",
          "background": {},
          "qualifications": [],
          "twitter": {
            "screen_name": "quoffquoff",
            "link": "https://twitter.com/quoffquoff",
            "user_id": "930759114",
            "accessToken": "930759114-EaEO152TBWv0HtaCri4p6vlFsZCxtuwOyNuy0Wpm",
            "accessTokenSecret": "Xea9Gt2wajEm9q1VMOWmXR2EHSBbBomWD1zNIX82W9WqE"
          },
          "instagram": {
            "access_token": "3896717402.8640130.e2a1b464097c4c9880fc79ad87721e7a",
            "link": "https://www.instagram.com/oneshooltd"
          },
          "locations": [
            {
              "geolocation": [
                -3.15813700000001,
                56.1168249
              ],
              "city": "Kirkcaldy",
              "postcode": "KY1 1BS",
              "_id": {
                "$oid": "5a0acda4430ff53fda549b44"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": null,
          "phone": {
            "number": "7780961162",
            "code": "+44"
          },
          "biography": "my bio"
        },
        "score": 55,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": true,
      "username": "chris_longden",
      "password_reset_time": 1499692362917,
      "password_reset_code": "1e1d7cfbadcdb011402317d773fde5677acb8510",
      "lastLogin": 1570352314425,
      "agent": {
        "family": "Chrome",
        "major": "77",
        "minor": "0",
        "patch": "3865",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.90 Safari/537.36"
      },
      "feature_in_area": "none",
      "__v": 23
    },
    {
      "_id": {
        "$oid": "585478799cbbf929e5bf894a"
      },
      "lastUpdated": {
        "$date": "2017-10-17T17:48:34.506Z"
      },
      "createdAt": 1481930873995,
      "providerId": "118058152442129771364",
      "provider": "google",
      "surname": "Sports Therapies",
      "firstName": "RJ",
      "email": "athletictherapy2012@gmail.com",
      "settings": {
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Injury Rehabilitation",
            "Injury Assessment",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://rjsportstherapies.myfreesites.net/",
          "profileTitle": "Sports Therapy & Rehab",
          "radius": 5,
          "price": null,
          "offers": "Personal training: £25.00 per session (£100 for 5 sessions).  Sports Therapy: £30 per session (£150.00 for 6 sessions).  Free initial assessment.  1st personal training session free. 1st Sports massage session - 1st 30 mins. free",
          "yearOfBirth": 1973,
          "strapLine1": "Expert Sports therapy and sports science catering for all needs",
          "biography": "A Sports scientist with a background in Sports Therapy, sports massage, nutrition and health. A sports science graduate from Southbsnk university and a post graduate qulification inexercise and behavioural medicine. Previously worked at the football academy at QPR FC, and worked for Fulham FC and ran a coach education programme for Tottenham Hotspurs FC.  Also worked as a Sport Teacher at Southwark College teaching sports science, health and fitness in the sports department, as well as Anatomy & physiology for nursing students.  I currently work for a charitable community bicycle organisation - running health and well-being activities around cycling.  I also work as a freelance tutor/assessor delivering fitness qualifications.  I also work part time as a sports therapist for Leyton Orient FC as a match day Physio for the Academy U.18s in midweek.",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/crLliicI-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.08772659999999632,
                51.58140160000001
              ],
              "city": "London",
              "postcode": "N15 5AZ",
              "_id": {
                "$oid": "59e6427294e4d86bf9be193e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "RJ Sports Therapies",
          "qualifications": [],
          "facebook": "https://www.facebook.com/app_scoped_user_id/167555413720488/",
          "facebookPage": "rjsportstherapies",
          "phone": {
            "code": "+44",
            "number": "7506787035"
          }
        },
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6Lg7XCHm-filename.jpeg"
        },
        "score": 66,
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "rj_sports_therapies",
      "password_reset_time": 1482099937902,
      "password_reset_code": "X",
      "hashedPassword": "",
      "lastLogin": 1499817506136,
      "agent": {
        "family": "Chrome Mobile WebView",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Linux; Android 5.1.1; VF-1397 Build/LMY47V; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/59.0.3071.125 Safari/537.36 Instagram 10.28.0 Android (22/5.1.1; 213dpi; 800x1216; Vodafone; VF-1397; Vodafone_Tab_speed_6; qcom; en_GB)"
      },
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "585529659cbbf929e5bfa472"
      },
      "lastUpdated": {
        "$date": "2018-01-18T14:01:53.876Z"
      },
      "createdAt": 1481976165974,
      "providerId": "10154892066287783",
      "provider": "facebook",
      "surname": "Dodhia",
      "firstName": "Sakhi",
      "email": "sakhi_dodhia@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154892066287783/",
          "disciplines": [
            "Dietary Advice",
            "Food Diary Analysis",
            "Recipes"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Nutritionist",
          "radius": 2,
          "price": {
            "min": 50,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9AIBTIzf-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.17594800000006217,
                51.696636
              ],
              "city": "Potters Bar",
              "postcode": "EN6 5BB",
              "_id": {
                "$oid": "5a60a8d1765eba094646f7d2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7875304088",
            "code": "+44"
          },
          "biography": "Thanks for looing at my profile: I am a Registered Public Health Nutritionist with over 8 years of experience in the field of nutrition.\n\nMy specialist skills lie in nutrient analysis, food composition, dietary assessment, nutrition surveillance and policy.\n\nI am currently managing a programme of work to revise the UK Nutrient Profiling Model at Public Health England.",
          "qualifications": [
            "MSc in Public Health Nutrition",
            "BSc in Nutrition"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0nlnsU7Q-filename.jpeg"
        },
        "score": 57
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "sakhi_dodhia",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58555b6c9cbbf929e5bfac30"
      },
      "lastUpdated": {
        "$date": "2018-01-18T13:53:33.890Z"
      },
      "createdAt": 1481988972529,
      "email": "faydurrant@gmail.com",
      "firstName": "fay",
      "surname": "durrant",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "IzsTZmEJcXzJRNmQynTL4g==",
      "hashedPassword": "bj9wg1/nP54qq6j4iSTf/dkS0Pp4+vzMe5FBfrFaySED1z1b06IDgUtNBN2l2R/j4NPdg+wNYx3/pCZitvGIxA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wpgqm69J-013.JPG"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Hiit",
            "Nutrition",
            "Pilates",
            "Yoga"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "price": {
            "min": 40,
            "max": 100
          },
          "strapLine1": "Holistic Fitness Solutions For a Healthier Happier You",
          "yearsOfExp": 2010,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PdfiQ3U4-FP%20RB%20Oct%2020114.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                0.16248619999998937,
                52.18581
              ],
              "city": "Cambridge",
              "postcode": "CB1",
              "_id": {
                "$oid": "5a60a6dd765eba094646f7d1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7977583535",
            "code": "+44"
          },
          "biography": "Think you can't get fitter, happier and healthier? \nThink again!\nI specialize in helping people who's natural environment is NOT the gym, to discover the joys & benefits of exercise for health well-being and happiness.\nRegular exercise is proven to low blood pressure & cholesterol levels, decrease the risk of heart disease & diabetes, elevate your mood, is a wonderful antidote to stress.... and you will look better, feel better, and have more energy..... \nI teach entry level classes to make it easy for you to take the first steps to a healthier happier you, you can then progress when you are ready. \nWhat are you waiting for?!\n\nSpecialties: \n\nEating for Weight Loss\nReturn to Fitness; for older or out of condition clients, \nOutdoor training, get out in the fresh air....beats the treadmill at the gym anyday!\nFunctional Training...getting you fit for life.\nCore strength and Flexibility : The foundations of fitness.",
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR",
            "Physiology and Indian Head Massage"
          ]
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fay_durrant",
      "password_reset_time": 1483981645571,
      "password_reset_code": "X",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5857c87f9cbbf929e5c00b79"
      },
      "lastUpdated": {
        "$date": "2017-11-29T19:35:44.819Z"
      },
      "createdAt": 1482147967911,
      "email": "breathe@shoomon.xyz",
      "firstName": "Robin",
      "surname": "Catto",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "BVFjBTLDRTN4ZdEB0ibBDw==",
      "hashedPassword": "K8w+P2MnmhnntedWywFrM1eyNDCEzG6WJb7ub2dzgREML2JRYrFKhH4qgNCLPOAIfVAni0xZ3C59yPUFYu+f6w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ivJEDaHN-breathe.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Group Classes",
            "Personal Training",
            "Pilates",
            "Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://breatheyoga.co.uk/",
          "profileTitle": "Mobile Personal Training",
          "radius": 15,
          "price": {
            "min": 0,
            "max": 70
          },
          "strapLine1": "Yoga, Pilates and Treatments for all levels",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lDTrL5GI-breathe%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "We first met in a meditation class at university and began practising yoga together in 1989.\nInitially we pursued high-powered careers in music and media but became disillusioned with the relentless stress, so we made a positive choice to explore a healthier, more fulfilling lifestyle. \nWe went on to train in holistic massage, Thai massage and reflexology. \nThen we decided to explore our interest in yoga through extensive studies with teachers around the world, leading to a 15-month stay in India and South East Asia.  \nAfter qualifying as yoga teachers in India in 1997, we returned to London in 1999 after three years on the road, just in time for an explosion of interest in yoga.\nSo we formed Breathe to offer a bespoke service sending top quality yoga and Pilates teachers to people in their homes and at work.\n We pay attention to every detail when we assess the needs of each client: when you call up, you always speak to me (Robin) and we can talk for as long as you need to discuss everything you’re looking for. \nThen I can select the very best match for you from our network of highly qualified, handpicked teachers, and they are excellent teachers – our clients tell us so.\nThey offer a full range of yoga styles and always take a flexible approach to your ability, schedule and personal needs. \nBreathe is the truly bespoke service we dreamed of - we hope it suits you perfectly,  Robin and Emma",
          "offers": "It doesn’t come simpler than this:\nall our private classes in yoga and Pilates are charged at a flat rate of £70 per hour, regardless of the location, group size or time of day and the same goes for our wide range of holistic body treatments.  \nYour session can last for as long as you like, from a minimum of 1 hour.\n\nTime Changes and Cancellations: \nyou can change or cancel an appointment without charge, but we kindly request that you give us a minimum of 24 hours notice. If you notify us within less than 24 hours, the full fee for the original appointment is due.",
          "locations": [
            {
              "geolocation": [
                -0.12503300000003037,
                51.50820599999999
              ],
              "city": "London",
              "postcode": "WC2N 5HX",
              "_id": {
                "$oid": "5a1f0c10b1d6217c42dac11f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Breathe",
          "phone": {
            "number": "2084528322",
            "code": "+44"
          },
          "qualifications": [],
          "testimonials": "Have meant to email for ages to say how wonderful being taught by N is. Every lesson she picks up on my mood and tailors the session to leave me feeling exactly as I want to. Having always been a yoga sceptic, I am now a total convert, cannot fit enough practice into my working day and am truly a different person than I was when I started out. She is a fantastic inspiration, and thank you for matching her to me.\nLP\n\nThe standard of teachers that you have supplied to us has been very impressive. It’s very hard to find a decent yoga teacher in London (or anywhere), and you have supplied us with four in quick succession.\nThanks once again!\n\nPC\n\nMany thanks for contributing to my life so meaningfully in 2012 by arranging for L to be my teacher. Although my journey has a long way to go, taking up yoga this year has made an impact many times beyond my expectations.\n\nDS\n\nI just wanted to say how fantastic it has been to work with T. She has been amazing. Her patience, kindness, spirituality and sensitivity have all contributed to the increased sense of calm and happiness in my life. I love working with her. Thank you so much for recommending such a fantastic teacher.\n\nLD\n\nJust to say many, many thanks. A’s first lesson was hugely successful – you obviously chose exactly the right teacher for him.\n\nKH"
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "breathe",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5857d1959cbbf929e5c00cbd"
      },
      "lastUpdated": {
        "$date": "2017-11-29T19:35:12.966Z"
      },
      "createdAt": 1482150293043,
      "email": "stretchingthecity@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "HOTI7FHWbBeQU/QLRPzhWQ==",
      "hashedPassword": "HpJ7c4kBQYoOWEpTjkgYfF8/mkNL3wIu4pHiK/Qg1RblhHvrB0bzTj4TLXg33LZ2JGw2SAOvgksBR5sUpx7Idg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nGwMT4hW-strelogo2.jpg"
        },
        "personal": {
          "disciplines": [
            "Corporate Services",
            "Pilates",
            "Pre / Post Natal",
            "Wellness",
            "Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.stretchingthecity.com/home.html",
          "profileTitle": "Mobile Personal Training",
          "radius": 20,
          "price": {
            "min": 0,
            "max": 75
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oddl1q45-strebg3.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The UK’s leading corporate-wellbeing provider and curator of UK Yoga Retreats, Private and Group classes",
          "biography": "Founded in 2010, Stretching the City boasts over 70 of the UK’s leading wellbeing experts and teachers who deliver bespoke corporate wellbeing solutions.\n\nCity life can be stressful. We’re working harder than ever, risking burnout and overwhelm whilst our stress levels and cases of anxiety, depression and obesity steadily rise. So it’s no surprise then, that more and more people are searching for inspiration and education to help live healthier, calmer and more fulfilling lives.\n\nStretching the City was founded  to address these aches and pains, stresses and strains of city life - through our corporate wellbeing programmes, yoga retreats and London yoga classes. Fast forward to 2017 and we count our lucky stars to be considered London’s leading wellbeing provider.  Working with the likes of PwC, Harrods, Deloitte and many companies in between (see our full client list here), we're proud to be London's go-to provider for happier, healthier and more productive employees. \n\nBeyond the boardroom, we continue to share the plentiful benefits of yoga through our 'Stretching Angel' public classes and private 1:1 classes.",
          "offers": "Corporate Wellness: \nEmail us at: info@stretchingthecity.com or cCall us on : 0207 1832418 for Pricing.\n\nPrivate Yoga or Pilates classes:\nPrivate classes can be held at your home or place of work. We're also happy for you to share a class with a partner, friend or family members. We offer private one-on-one yoga classes in London zones 1-6. Just visiting London? We'll come to your hotel for a private yoga class. ​Once you've decided when you'd like your class, you'll be assigned a Stretching the City teacher.  We ask that you provide your own yoga mat, but if this poses a problem, we can usually bring you a spare.\n\nPrivate classes start from £70 per hour (depending on travelling required). Remember you can share the class with another person and split the cost. Private classes are paid via BACs prior to the class. You can save money by booking a course of 6 classes. \n\nGroup Yoga Classes in Angel, N1:\nLooking to join a group yoga class? We run Vinyasa Flow & Beginners Yoga classes at our yoga space in Angel, Islington on Monday evenings. Visit our sister company, Stretching Angel for more details.",
          "locations": [
            {
              "geolocation": [
                -0.12481159999992997,
                51.5081101
              ],
              "city": "London",
              "postcode": "WC2N 5HX",
              "_id": {
                "$oid": "5a1f0bf0b1d6217c42dac11e"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.10783300000002782,
                51.5325636
              ],
              "city": "London",
              "postcode": "N1",
              "note": "Stretching Angel: Upper Hall, Claremont Project, 24-27 White Lion Street",
              "_id": {
                "$oid": "5a1f0bf0b1d6217c42dac11d"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "Stretching The City",
          "phone": {
            "number": "2071832418",
            "code": "+44"
          },
          "qualifications": [],
          "testimonials": "\"Stretching the city provides Nabarro staff with an extensive range of fun and exciting wellbeing classes from meditation over lunch to Pilates/Yoga classes in the evening. Sally has been excellent at tailoring a wellbeing timetable for us over a 12 week period and keeping us fully informed of the logistics. Stretching the City teachers are very experienced and friendly an our employees are always keen to know when we will be running more classes.\" \nBryony Gordon, HR Manager for Nabarro LLP\n\n\"On behalf of the business and myself, I just wanted to say a heartfelt thanks to you and your Stretching in the City wonder-team! The feedback has been brilliant,  so we’ll definitely be in touch next year when we (hopefully) do our Wellbeing Week again.\"  Desiree Wagner, WH Smith \n\n\"Thank you so much for last night’s 'Sleep to Achieve, Succeed & Thrive' session – I thought it was great and the feedback I received was extremely positive. \" Sara Kutner, Credit Suisse\n\n\"By the end of the session I had lots of tips, tools, apps, and ways to make sure I control my phone, time and life and not the other way around! I would highly recommend this to anyone with a smartphone!\" Workshop Participant, Three Mobile"
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "stretching_the_city",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5857e7aeb3f2cd315cbc5ffb"
      },
      "lastUpdated": {
        "$date": "2017-11-29T19:34:37.936Z"
      },
      "createdAt": 1482155950351,
      "email": "homehealthyoga@shoomon.xyz",
      "firstName": "Michael",
      "surname": "White",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "m0/9qeU6saaeCvnSZAR31g==",
      "hashedPassword": "WohwRtjukLQo7g6+T6/inSfIm1eAubSLeg8orVvBFnY5XdHbpgT4wlOE8rIH9gUKvvc3cTMVQZcrQUeOKdVg6w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N4UA0qLX-homehprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "Corporate Services",
            "Personal Training",
            "Pilates",
            "Small Group Training",
            "Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://homehealthyoga.co.uk/",
          "profileTitle": "Mobile Personal Training",
          "radius": 15,
          "price": {
            "min": 0,
            "max": 70
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/32SgtNs2-homehbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Our Leading London Yoga Teachers & Personal Trainers can visit your home or work in London and across the South East.",
          "biography": "Home Yoga London:\n\nAre you looking for private, home or even work based Yoga in London? With guaranteed health benefits why not try out our teachers with 2 trial pay as you go lessons.\n\nIf you’re in need of Home Yoga London at your work or home then HomeHealth Yoga is here for you. Our highly qualified, expert Yoga Teachers are hand picked. We provide our clients with support and guidance whether you are an experienced yoga practitioner or new to yoga. You don’t need to be as supple as a toddler to enjoy yoga or even super skinny. Yoga is a practice enjoyed by the young and the old. Our home Yoga London teachers are some of the best in the industry. We can provide personal yoga classes in your home or workplace.\n\nWe work with children, pregnant mothers and even CEO’s to deliver a kind of yoga that will not only increase your flexibility but also relieve stress, increase well-being and with a healthy diet encourage weight loss. The benefits of yoga have been known for many years. With HomeHealth Yoga London’s pioneering way of delivering yoga in an environment in which you feel comfortable Yoga fits in with you.\n\nHome Yoga London was founded by Michael White. Our simple goal is to provide leading yoga instruction to those looking to improve their health and wellbeing. The teachers are committed to improving our client’s lives through instruction, demonstration and support.",
          "offers": "Our prices are often subject to your session time preferences and proximity to one of the team hence the encouragement to contact us.  HomeHealth Yoga are currently offering a free Yoga consultation. This is usually tied in with your 1st trial pay-as-you-go session - see below. You are entitled to 2 trial pay as you go sessions to test us out.  If you are happy and would like to continue there are then 3 money-saver advanced block packages to choose from. Please contact us for a quote.  We have a buddy discount where you can share home visit sessions with up to of 4 people and divide the cost. For work&office-based visits we can happily cater for larger groups on request.  To make life easier we now take payments instantly and securely over the phone by Debit or Credit card. Other payment methods are available.  If you would like to try us out, or would just like to find out some more information, please call Michael on 07980 545324.  So come on, take the first step towards your new happy, healthy lifestyle and enjoy your potential’!",
          "locations": [
            {
              "geolocation": [
                -0.12503300000003037,
                51.50820599999999
              ],
              "city": "London",
              "postcode": "WC2N 5HX",
              "_id": {
                "$oid": "5a1f0bcdb1d6217c42dac11c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "HomeHealth Yoga",
          "phone": {
            "number": "7980545324",
            "code": "+44"
          },
          "qualifications": [],
          "testimonials": "‘I DON’T FIT IN WITH IT, IT FITS IN WITH ME’\n\n‘WE LIKE SAVING MONEY BY SHARING THE SESSIONS WITHOUT THE LOSS OF THE BENEFIT OF HAVING OUR OWN PRIVATE HOME VISIT TEACHER’\n\n‘CLASSES IN THE OFFICE HAVE BEEN GREAT FOR WINDING DOWN THE GUYS AT THE END OF A HARD AND STRESSFUL WEEK, AN ADDED BONUS HAS BEEN THE TEAM BUILDING EFFECT OF THE SESSIONS’"
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "homehealth_yoga",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58580215b3f2cd315cbc6422"
      },
      "lastUpdated": {
        "$date": "2018-01-18T13:45:49.076Z"
      },
      "createdAt": 1482162709783,
      "providerId": "117783802689816498060",
      "provider": "google",
      "surname": "Teja",
      "firstName": "Andrea",
      "email": "andrea.tteja@gmail.com",
      "settings": {
        "personal": {
          "disciplines": [
            "Meditation",
            "Mobile Teacher",
            "Restorative",
            "Strength & Conditioning",
            "Vinyasa flow"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 10,
          "price": {
            "min": 10,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/F68JPmVt-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearOfBirth": 1970,
          "strapLine1": "I work with the moons cycles to deliver my classes and I also teach yoga for Boxers (boxingyoga)",
          "biography": "I have a studio in Chingford and I am a freelancer at various gym clubs. I also teach in the comfort of your home",
          "locations": [
            {
              "geolocation": [
                -0.008628578479966663,
                51.63557383062916
              ],
              "city": "London",
              "postcode": "E4",
              "_id": {
                "$oid": "5a60a50d765eba094646f7cf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7980567408",
            "code": "+44"
          },
          "qualifications": [
            "British Wheel of Yoga Teaching Diploma",
            "Boxing Yoga Coach"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4DZzt72g-filename.jpeg"
        },
        "score": 66,
        "offers": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "andrea_teja",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58580390b3f2cd315cbc6451"
      },
      "lastUpdated": {
        "$date": "2018-01-18T13:43:40.496Z"
      },
      "createdAt": 1482163088548,
      "providerId": "10154649895065569",
      "provider": "facebook",
      "surname": "Farrar",
      "firstName": "Debbie",
      "email": "debbiedoesyoga@hotmail.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154649895065569/",
          "disciplines": [
            "Group Classes",
            "Meditation",
            "Personal Training",
            "Private Classes",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 10,
          "price": {
            "min": 50,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/F7j5Jp4Q-image.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -2.635446399999978,
                53.6885323
              ],
              "city": "Whittle-le-Woods",
              "postcode": "PR6 7LN",
              "_id": {
                "$oid": "5a60a48c765eba094646f7cd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7940594347",
            "code": "+44"
          },
          "biography": "Yoga Elders have been teaching for at least 10 years and are recognised by the Independent Yoga Network. Debbie Farrar is the Feel Now Yoga Elder.  She has been practicing since 1977 & teaching since 1999.\n\nOur name ‘Feel Now Yoga’ is a reminder that we often ignore how we currently feel for the sake of a future that might not happen or a past that is long gone.  Feel Now Yoga invites us to tune into, reflect upon & trust our own feelings & common sense.  Hence our motto: “Sensible Yoga for Sensitive Individuals”.\n\nAt the heart of Feel Now Yoga lies a sensible, functional & principled system of yoga that honours the spirit of the Yoga Sutras whilst embracing forward thinking teaching & learning philosophies and scientific theory to meet the current needs of each individual's body & mind.\n\nFeel Now Yoga uses yoga practices as lenses through which to observe the nature of self & reclaim a sensible, balanced, harmonious & honest dialogue between body, mind & consciousness.",
          "qualifications": [
            "Independent Yoga Network Yoga Elder"
          ],
          "strapLine1": "Feel Now Yoga"
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/R2GYwmbs-image.jpg"
        },
        "score": 66,
        "offers": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "debbie_farrar",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5858081fb3f2cd315cbc650f"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:29:26.932Z"
      },
      "createdAt": 1482164255807,
      "email": "yogateam@shoomon.xyz",
      "firstName": "Katie",
      "surname": "Mutton",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "hr+uY3vDdfO65qiXFptzQg==",
      "hashedPassword": "nqNZwJIQpX39e/Nz9G5W6fPtGr9y9BQSzYpIpssAdtMcHQL+ITE1xEq2BZBht7XiT8kGL+ZMUzte3QagZaldAA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AdGaEMhG-yogateam%20fb%20logo.jpg"
        },
        "personal": {
          "disciplines": [
            "Corporate Yoga",
            "Personalised Programme",
            "Post Natal Yoga",
            "Pregnancy Yoga",
            "Private Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.yoga-team.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 20,
          "price": {
            "min": 0,
            "max": 75
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ewrOjwCy-yogateambg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Personalised to your individual Requirements - Yoga Team offers a unique and specialist approach to Yoga: our expert team delivers tailored Private Yoga Classes to offices, homes, hotels, events and schools across London. Bringing our expertise, uplifting classes and the beauty of Yoga directly to you, at a time of your convenience.  Realign, recharge, re-balance and invigorate the mind and the body, whilst boosting your overall health and well-being. Attentive to your individual needs, our classes are guaranteed to leave you smiling from the inside out:  free booking consultation and support, to ensure your classes honour and exceed your requirements; personalised, developmental classes; RYT -qualified and experienced yoga instructors; flexible scheduling and booking options; practice from the comfort of your own home or office, saving on travel time and stress. ''Yoga is the perfect addition and antidote to the stresses of modern living. Strengthening and toning the body, the practice also works to reduce stored physical and mental tension, and centres on bringing some much needed balance to busy lives. Our intention is to create personalised, targeted classes for each client. Our classes are not only convenient but complimentary to your lifestyle, your body and your overall wellness''- Katie, Yoga Team’s Founder.",
          "strapLine1": "London's Premiere Private Yoga-Class Company: Specialists in 1-to-1,  Corporate and Private Group Classes",
          "offers": "Please visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.12503300000003037,
                51.50820599999999
              ],
              "city": "London",
              "postcode": "WC2N 5HX",
              "_id": {
                "$oid": "5a219176a95f7a0d7e4bda61"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Yoga Team",
          "phone": {
            "number": "8000337865",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "Restorative Yoga Class",
            "Pregnancy Yoga Class",
            "Post Natal Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Sports Specific Yoga Class",
            "Hatha Yoga Class",
            "Kids Yoga Class",
            "Yoga Workshop",
            "Yin Yoga Class",
            "Sivananda Yoga Class",
            "Therapeutic Yoga",
            "Meditation Class",
            "Yoga Nidra",
            "General Yoga Class",
            "Corporate Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yoga_team",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "58581f46b3f2cd315cbc68ab"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:28:33.055Z"
      },
      "createdAt": 1482170182545,
      "email": "yogaloom@shoomon.xyz",
      "firstName": "Katerina",
      "surname": "Ioannou",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "b3HyCqPdtpSW7C6IZm1bVA==",
      "hashedPassword": "t8uNScTQE+oJz57jmmw89gvtukNnrDY6sExYWh8WQn1BJGZtFDl9CaIX4/PXQJfo9qZ9TBB26TLKUIKsXo7rMQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/o2UhGPsd-yogaloom_logo.png"
        },
        "personal": {
          "disciplines": [
            "Kids Yoga",
            "Mobile Services",
            "Pregnancy Yoga",
            "Private Yoga Class",
            "Studio Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.yogaloom.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 15,
          "price": {
            "min": 0,
            "max": 65
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KnRKqncG-yogaloombg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Yoga & Pilates",
          "biography": "Yoga and Pilates: we run tailored private and small group Yoga and Pilates (with equipment) classes at our North London studio in Muswell Hill. Feel free to get in touch for more information or to book a class. We organise yoga retreats in the beautiful Greek islands and around the UK, so Join us on the sun-soaked beaches of the Mediterranean. We also put together retreats and classes in response to clients’ requests, so get in touch and let us know your preferred dates and location.  Studio visits by appointment only.",
          "offers": "Private Yoga Classes:  Private and small-group Yoga classes in North London are available at a time convenient to you, either at our serene studio in North London or at your home, company or event. All programmes can include meditation and Yoga Nidra. For more information and to arrange a trial class do get in touch. Please note that for private visits more than 8 miles radius from our base in North London different prices will apply.\n\nPlease refer to our website for details of prices. packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.14297799999997096,
                51.59257419999999
              ],
              "city": "London",
              "postcode": "N10 1BS",
              "_id": {
                "$oid": "5a219141a95f7a0d7e4bda60"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Yogaloom",
          "phone": {
            "number": "7906615475",
            "code": "+44"
          },
          "qualifications": [
            "General Yoga Class",
            "Reformer Pilates Class",
            "Wunda Chair Pilates",
            "Cadillac Pilates Class",
            "Piloxing Class",
            "Kids Yoga Class",
            "Post Natal Pilates Class",
            "Pregnancy Pilates Class",
            "Post Natal Yoga Class",
            "Pregnancy Yoga Class",
            "Mother & Baby Yoga Class",
            "Pranayama Class",
            "Remedial Pilates",
            "Yoga Retreat"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yogaloom",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "58582ef2b3f2cd315cbc6b03"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:24:04.736Z"
      },
      "createdAt": 1482174194853,
      "email": "yogaat@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "y7G4BToTZc/Nzj45htIyVQ==",
      "hashedPassword": "wruAg8i8oYbxmGPwfMWgn8B7mnpvoVuCOS0eFZ9ZDp9SOezPnt+efZNDTnrE3WHih9+6FhlVYLs2hoPH1GDKsg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tLqrsAZf-yogaatlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Back Pain Yoga",
            "Meditation",
            "Personalised Programme",
            "Pregnancy Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.yogaat.com/index.php",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 200,
          "price": {
            "min": 0,
            "max": 75
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TKIrD6h4-yogaatbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Personalised Yoga and Mindfulness programs with Leading Instructors across London and the UK",
          "biography": "YogaAt offers you the benefits of a personalised yoga or Mindfulness program with the UK’s leading instructors at your office, home, hotel or wherever suits you. Our personal programs are available for all levels and ages for both individuals and groups and across the UK. We work with you to structure a yoga or Mindfulness program that best supports you, your colleagues, your guests or your pupils. With our corporate, hotel and school clients we support them to engage with and better understand the physical, emotional and mental wellbeing requirements of their colleagues, guests or pupils via marketing, social media and face-to-face meetings and surveys. With our individual clients we spend time to understand the role of yoga or Mindfulness in their lives and what they would like to achieve through regular practice. Having gone through this process we create a program tailored specifically to the aims and lifestyle of that client. YogaAt has a team of over 100 leading instructors who teach eleven styles of yoga, from Ashtanga to Kundalini and Mindfulness. They are the best in their field, highly trained, highly experienced, professional and friendly. All are fully qualified, experienced, insured and vetted by us. A session of our personal instruction often costs little more than a few scheduled group classes at a yoga studio.",
          "offers": "Please visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -1.1397591999999577,
                52.6368778
              ],
              "city": "Leicester",
              "postcode": "LE1 4SG",
              "_id": {
                "$oid": "5a219034a95f7a0d7e4bda5c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "YogaAt",
          "phone": {
            "number": "2089931597",
            "code": "+44"
          },
          "qualifications": [
            "Mother & Baby Yoga Class",
            "Hatha Yoga Class",
            "Yin Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Ashtanga Yoga Class",
            "Power Yoga Class",
            "Kids Yoga Class",
            "Post Natal Yoga Class",
            "Pregnancy Yoga Class",
            "Meditation Class",
            "Restorative Yoga Class",
            "Sivananda Yoga Class",
            "Mat Pilates Class",
            "Anusara Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yogaat",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58584b0ab3f2cd315cbc6f55"
      },
      "lastUpdated": {
        "$date": "2017-11-22T17:06:53.707Z"
      },
      "createdAt": 1482181386997,
      "email": "motivatept@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "FyB4jzMx11JVirWhy4yUVg==",
      "hashedPassword": "mtRwwLh6URca2V41t6kqnm1Q5ylq9h+jwibmK9ZzMBQLZXw6nyqiBq7nSFsuDt4nMWs9Ezf37IWMWC1egeqJUw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nLVtAJbL-motivateptlogo.png"
        },
        "personal": {
          "disciplines": [
            "Bridal Fitness",
            "Partner Training",
            "Personal Training",
            "Pre / Post Natal",
            "Small Group Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.motivatept.co.uk/",
          "profileTitle": "Mobile Personal Training",
          "radius": 20,
          "price": {
            "min": 0,
            "max": 55
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/azDiYNhV-motivateptbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "We connect you with brilliant trainers who empower you to live a great everyday",
          "biography": "Our Approach is to help individuals and companies make working out a part of their working week. Our MotivatePT ambassadors are personally vetted and go through a 3-stage recruitment process -we work hard to find the very best. We are available to train clients 7 days a week, from as early as 6am to as late as 9pm -we can even help you to escape the office at lunch. Your home. Your office, an open green space or even that dusty unused shed - we can transform any space into your training ground.",
          "offers": "Please visit our website for details of prices, packages & offers.\n\nOperating Hours: \n\n6:00 am - 9:00 pm 7 days a week",
          "locations": [
            {
              "geolocation": [
                -0.12503300000003037,
                51.50820599999999
              ],
              "city": "London",
              "postcode": "WC2N 5HX",
              "_id": {
                "$oid": "5a15aead2288dd3a30f3f537"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Motivate PT",
          "phone": {
            "number": "2032868082",
            "code": "+44"
          },
          "qualifications": [],
          "testimonials": "Mahreen:\nMy trainer is exactly what I wanted in a PT: on time and very professional. He always listens to what I say/want and then makes suggestions, he also explains to me why he makes these suggestions rather than just getting me to do something. He always makes sure that I am doing my exercises properly, i.e. posture etc. Training with him over the last two months has allowed me to strengthen my running and the pain in my knees has reduced so much. I really enjoy training, we can have a joke and be serious at the same time, and this is the balance I needed. Thank you again for the service *\n\nCharlotte:\nAs soon as I had my first taster session I knew I’d stick with it as the one to one training was addictive! The diverse range of exercises gives you a chance to try everything and not knowing what’s lined up for your next session keeps it interesting! Aside from the exercise the meal plan and nutritional side of getting fit is completely tailored to you as an individual and keeps you on track when you’re not training. *\n\nAmanda:\nI’m feeling fitter, stronger and full of life! Not bad for a female in her mid-40’s who was out of shape and condition! Then I approached MotivatePT– what a difference my personal trainer has made! First class trainer, he listens, he motivates, he inspires and sometimes he’s even funny. Everything you want in a Personal Trainer. *"
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "motivate_pt",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58594b3cb3f2cd315cbc96b1"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:16:47.948Z"
      },
      "createdAt": 1482246972266,
      "email": "yogi2me@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "6Yir/Pyh0xs8PgmJXFl+Ig==",
      "hashedPassword": "5Dp+4KQhlJKIp5WYUyNGQUn9ah3TqmVCMZkcv2LHYx9J5Yz9HrWOftUMW1vyzFg0pdDx0Ng9hqPBnHj7jjbURg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Bz97zFUf-yogi2%20melogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Beginners Yoga",
            "Corporate Yoga",
            "Kids Yoga",
            "Pregnancy Yoga",
            "Wellness"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.yogi2me.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 5,
          "price": {
            "min": 0,
            "max": 99
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/o9JDOjc5-yogi2mebg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Yogi2Me: Yogi2Me is the first start-up in the well-tech industry solely focused on providing you with top-quality yoga teachers for your yoga classes.  Book one of our fully-qualified yoga teachers to come to your home, office, studio, hotel, private garden or anywhere you would like to do Yoga.  Our yoga teachers can be with you for a private or small group class within the hour – or to suit your requirements!  Y2M has soft launched in SW1, SW3, SW5, SW6, SW7, SW10 and W8. We will be expanding to other London postcodes in due course so please stay tuned.",
          "strapLine1": "Fit Yoga to your schedule",
          "locations": [
            {
              "geolocation": [
                -0.18175959999996394,
                51.4854077
              ],
              "city": "London",
              "postcode": "SW10 9TN",
              "_id": {
                "$oid": "5a218e7fa95f7a0d7e4bda5a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "YOGI2ME",
          "phone": {
            "number": "2036377061",
            "code": "+44"
          },
          "offers": "Please visit our website for details of prices, packages and offers.",
          "qualifications": [
            "Kids Yoga Class",
            "Pregnancy Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Hatha Yoga Class",
            "Yin Yoga Class",
            "Beginners Yoga Class",
            "Corporate Yoga Class"
          ],
          "testimonials": "“The Y2M teachers were absolutely amazing”\nJennifer George\nTatler\n\n“We’re calling it the Uber of yoga”\nKatie Services\nES Mag\n\n“Floating away on what feels like a magic carpet with Yogi2Me”\nCaroline Fleming\nPresenter and Author\n\n“A real game changer for the busy bees of London”\nChristina Moi\nHealth & Fitness Blogger"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yogi2me",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58595a28b3f2cd315cbc98db"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:14:34.487Z"
      },
      "createdAt": 1482250792947,
      "email": "openhouseyoga@shoomon.xyz",
      "firstName": "Booking -",
      "surname": "Suzie",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "2plaJ++i0PDvK6urHyxcfg==",
      "hashedPassword": "hidcj5t0PCr6C1UtWHMTgfbUI831YmkKuScUKZBluTnAORAb/dEAqtWUAVcAOtTIXemlGuwoybPg64nJ1IF4HQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ddNF9nzy-openhoyogalogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Corporate Yoga",
            "General Yoga",
            "Hatha Yoga",
            "Private Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://openhouseyoga.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 5,
          "price": {
            "min": 0,
            "max": 149
          },
          "facebook": "",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/t7GMImDV-openhbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Welcome to the online YOGA home of Susie Ball and Matt Gill",
          "biography": "Susie Gill: YOGA TEACHER AND MINDFULNESS TRAINER - Yoga for me is a powerful tool for living. It helps us to become more at home in our bodies and more present in our minds. It is a joy to share it through my teaching!  I am a fully accredited yoga teacher through the British Wheel of Yoga. My yoga classes integrate a steady movement practice with conscious breath and mindful enquiry. The physical practice draws from a range of forms and is rooted in healthy alignment as a basis from which to cultivate a strong, open and balanced body. The aim of my classes is to offer a wakeful and considered approach to yoga that empowers us to live healthier, happier and more satisfying lives. I regularly run yoga courses and workshops including a 6 week Yoga Foundation course. Matt Gill:  Partly driven by a lingering childhood desire to be a Jedi Knight / Spiderman hybrid, I was initially drawn to yoga by the physicality of the practice. However, I quickly found that yoga brought greater balance and awareness into my life - my mind felt clearer and more focussed and before long yoga and meditation had become integral parts of my daily life, providing an oasis of calm away from the pressures of work and helping to improve my physical and mental well-being.",
          "locations": [
            {
              "geolocation": [
                -0.00751079999997728,
                51.3877756
              ],
              "city": "Beckenham",
              "postcode": "BR3",
              "_id": {
                "$oid": "5a218dfaa95f7a0d7e4bda59"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Open House Yoga",
          "phone": {
            "number": "7973839357",
            "code": "+44"
          },
          "qualifications": [
            "General Yoga Class",
            "Hatha Yoga Class",
            "Private Class",
            "Beginners Yoga Class",
            "Corporate Yoga Class",
            "Hot Yoga Class",
            "Vinyasa Flow Yoga Class"
          ],
          "offers": "Please visit our website for details of prices, packages & offers.",
          "testimonials": "“For me as a former gym and Zumba lover, yoga was always something that I wanted to try. Yet as a total beginner I felt a bit overwhelmed as to what type of class to go for. \n\nSince starting classes with Susie I realise that it’s more of a tool for life that has many benefits in and away from classes. For me Susie is refreshingly down to earth, patient and a great teacher as she understands each person’s requirements and structures the sessions accordingly. Also the sessions consisting of smaller numbers means that there’s more of a personal development feeling. \n\nYoga really is an inner journey and like many classes I feel you get better results with a good teacher. For me, yoga with Susie is the one and I look forward to the journey unfolding further. If you are in two minds about trying it I would definitely recommend. ”\n— Rowena Fernando\n“I was brand new to yoga before attending Susie’s class but haven’t looked back since. \n\nI was quite apprehensive as my balance, flexibility and co-ordination were somewhat questionable, but under Susie’s excellent tutelage I feel like a confident and capable member of her class.\n\nShe is extremely patient; explains and demonstrates all positions and has a hands on approach if help is needed. I would definitely recommend a yoga class with Susie, no matter what level of experience you have. She is an extremely warm, welcoming and talented teacher.”\n— Jo Coleman"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "open_house_yoga",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "585a4fbfb3f2cd315cbcc00f"
      },
      "lastUpdated": {
        "$date": "2017-11-29T18:16:19.093Z"
      },
      "createdAt": 1482313663361,
      "email": "triyogachelsea@shoomon.xyz",
      "firstName": "Chelsea",
      "surname": "Reception",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "pqmYtGahSRKNYhLoGsBPyQ==",
      "hashedPassword": "fjZKxFnyf55T8EbGsih/QFMma/2e3HZXskOQUQStxn1+mibJW8jglkF4a4Wlt620W7DDPse/ExXoSd3wPIctIA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/f8jE1f9G-triyogalogo.jpg"
        },
        "personal": {
          "disciplines": [
            "General Yoga",
            "Kids Yoga",
            "Mat Pilates",
            "Mother & Baby Yoga",
            "Pregnancy Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.triyoga.co.uk/chelsea-2",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "facebook": "",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HHowwPlw-triyogabg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "A centre of excellence for Yoga, Pilates, Treatments + much more",
          "offers": "Please visit our  website for detailed pricing and offers.\n\nOpening hours:\n\nMonday – friday: 6.00am – 9.15pm\nSaturday: 8.00am – 8.15pm\nSunday: 8.00am – 9.00pm",
          "biography": "What we offer: Triyoga offers you a place that is dedicated to looking after you, and somewhere everyone can belong. Start or deepen your yoga practice here, learn about cutting-edge personal development, yoga philosophy and meditation, or take Pilates mat + equipment classes. In addition, we have an amazing range of treatments with the best therapists. Whatever you choose, it takes place in beautiful studios or treatment rooms.  We have designed an environment, class schedule and treatments schedule to nurture the health of every individual: men and women; babies, kids and teens, through to seniors – everyone triyoga! Yoga: classes, courses and workshops - Pilates: mat classes, equipment classes courses and workshops - Treatments massage: osteopathy, facials, complementary therapies - Babies and kids: pregnancy and lots more - Courses + workshops: yoga, Pilates and lots more - Teacher training: teacher training diploma (BWY + Yoga Alliance accredited) and teaching intensives - pre + postnatal: classes, workshops, treatments - The gyrotonic method: an invigorating movement system to tone, sculpt and promote well-being - triyoga shop: selling Yoga mats, Pilates equipment, books, CDs, clothing, beauty products - Holidays: retreats for you to triyoga somewhere warm - Café: organic café with fresh juices, daily meal specials and more",
          "locations": [
            {
              "geolocation": [
                -0.17672279999999319,
                51.484196
              ],
              "city": "London",
              "postcode": "SW3",
              "_id": {
                "$oid": "5a1ef973b1d6217c42dac10a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Triyoga Chelsea",
          "phone": {
            "number": "2033623366",
            "code": "+44"
          },
          "qualifications": [
            "General Yoga Class",
            "Barre Class",
            "Kids Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Sports Specific Yoga Class",
            "Ashtanga Yoga Class",
            "Restorative Yoga Class",
            "Mat Pilates Class",
            "Pregnancy Yoga Class",
            "Iyengar Yoga Class",
            "Kundalini Yoga Class",
            "Jivamukti Yoga Class",
            "Yin Yoga Class",
            "Mysore Yoga Class",
            "Meditation Class",
            "Mother & Baby Yoga Class"
          ],
          "testimonials": "Elena:\nTriyoga has the best atmosphere and best teachers of any studio I have been to. It's completely relaxed and friendly but the teaching is really professional and the teachers really care about making sure students have the best possible experience. I love the bright and airy studios and there are so many classes to choose from-you can easily try any style you like. I also had the most incredible shiatsu massage there, which has, as yet, not been beaten.\n\nEmmeline:\nI have been to pre and post natel classes for two babies at tri yoga as well as lots of workshops and 'normal' classes and I am always impressed. The studios all have a lovely chilled out nurturing vibe and the teaching is always excellent. The food in Camden is spot on and the treatments are excellent too. A lovely place to chill out, nurture yourself and practice yoga too.\n\nEvelyna:\nAmazing studio! A lot of choice in terms of courses (any kind of yoga you could wish for and Pilates) and professors. The atmosphere is welcoming and the food at the cafe is delightful! Thanks for the complimentary porridge every morning :) lovely attention!"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "triyoga_chelsea",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "585a6001b3f2cd315cbcc2b1"
      },
      "lastUpdated": {
        "$date": "2017-11-29T18:16:37.933Z"
      },
      "createdAt": 1482317825690,
      "email": "triyogasoho@shoomon.xyz",
      "firstName": "Soho",
      "surname": "Reception",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "C4ZyKi9MWMwul26NmKmMPA==",
      "hashedPassword": "juQ8qJiZFzu5o5SVl23kAAaL9BS/dGhXyaTzTmf3DM858utEu4MhMcWEDv9ZBEGYEUASLO3dTBfmu5rPodYtIQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hTgAhR1A-triyogalogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Ashtanga Yoga",
            "General Yoga",
            "Pregnancy Yoga",
            "Restorative Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.triyoga.co.uk/soho",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/d5C2N8OL-triyogabg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "A centre of excellence for Yoga, Pilates, Treatments + much more",
          "biography": "What we offer: Triyoga offers you a place that is dedicated to looking after you, and somewhere everyone can belong. Start or deepen your yoga practice here, learn about cutting-edge personal development, yoga philosophy and meditation, or take Pilates mat + equipment classes. In addition, we have an amazing range of treatments with the best therapists. Whatever you choose, it takes place in beautiful studios or treatment rooms.  We have designed an environment, class schedule and treatments schedule to nurture the health of every individual: men and women; babies, kids and teens, through to seniors – everyone triyoga! Yoga: classes, courses and workshops - Pilates: mat classes, equipment classes courses and workshops - Treatments massage: osteopathy, facials, complementary therapies - Babies and kids: pregnancy and lots more - Courses + workshops: yoga, Pilates and lots more - Teacher training: teacher training diploma (BWY + Yoga Alliance accredited) and teaching intensives - pre + postnatal: classes, workshops, treatments - The gyrotonic method: an invigorating movement system to tone, sculpt and promote well-being - triyoga shop: selling Yoga mats, Pilates equipment, books, CDs, clothing, beauty products - Holidays: retreats for you to triyoga somewhere warm - Café: organic café with fresh juices, daily meal specials and more.",
          "offers": "Please visit our  website for details of pricing, packages and offers.\n\nOpening hours:\n\nMonday – Friday: 6.30am – 9.30pm\nSaturday: 8.00am – 8.15pm\nSunday: 8.00am – 8.30pm",
          "locations": [
            {
              "geolocation": [
                -0.1387340999999651,
                51.51233930000001
              ],
              "city": "London",
              "postcode": "W1F 9QA",
              "_id": {
                "$oid": "5a1ef985b1d6217c42dac10b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Triyoga Soho",
          "phone": {
            "number": "2033623355",
            "code": "+44"
          },
          "qualifications": [
            "General Yoga Class",
            "Iyengar Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Ashtanga Yoga Class",
            "Hot Yoga Class",
            "Sports Specific Yoga Class",
            "Kundalini Yoga Class",
            "Mat Pilates Class",
            "Yin Yoga Class",
            "Jivamukti Yoga Class",
            "Meditation Class"
          ],
          "testimonials": "Elena:\nTriyoga has the best atmosphere and best teachers of any studio I have been to. It's completely relaxed and friendly but the teaching is really professional and the teachers really care about making sure students have the best possible experience. I love the bright and airy studios and there are so many classes to choose from-you can easily try any style you like. I also had the most incredible shiatsu massage there, which has, as yet, not been beaten.\n\nEmmeline:\nI have been to pre and post natel classes for two babies at tri yoga as well as lots of workshops and 'normal' classes and I am always impressed. The studios all have a lovely chilled out nurturing vibe and the teaching is always excellent. The food in Camden is spot on and the treatments are excellent too. A lovely place to chill out, nurture yourself and practice yoga too.\n\nEvelyna:\nAmazing studio! A lot of choice in terms of courses (any kind of yoga you could wish for and Pilates) and professors. The atmosphere is welcoming and the food at the cafe is delightful! Thanks for the complimentary porridge every morning :) lovely attention!"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "triyoga_soho",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "585a64a4b3f2cd315cbcc373"
      },
      "lastUpdated": {
        "$date": "2017-11-29T18:16:55.622Z"
      },
      "createdAt": 1482319012900,
      "email": "triyogacoventgarden@shoomon.xyz",
      "firstName": "Covent G'den",
      "surname": "Reception",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "PMFrfkowHECy7Bv0GgZnjg==",
      "hashedPassword": "amItOkPGXXVHLoMxHc7obB4cv5hurFgUmum4H4/8aFf7w0QdVjWO1sUeCqwVNAL+WbNrPbhnF41L32Q+4rABJw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xr5yS0FZ-triyogalogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Yoga Workshop"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.triyoga.co.uk/covent-garden",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7J57KaBU-triyogabg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "A centre of excellence for Yoga, Pilates, Treatments + much more",
          "biography": "What we offer: Triyoga offers you a place that is dedicated to looking after you, and somewhere everyone can belong. Start or deepen your yoga practice here, learn about cutting-edge personal development, yoga philosophy and meditation, or take Pilates mat + equipment classes. In addition, we have an amazing range of treatments with the best therapists. Whatever you choose, it takes place in beautiful studios or treatment rooms.  We have designed an environment, class schedule and treatments schedule to nurture the health of every individual: men and women; babies, kids and teens, through to seniors – everyone triyoga! Yoga: classes, courses and workshops - Pilates: mat classes, equipment classes courses and workshops - Treatments massage: osteopathy, facials, complementary therapies - Babies and kids: pregnancy and lots more - Courses + workshops: yoga, Pilates and lots more - Teacher training: teacher training diploma (BWY + Yoga Alliance accredited) and teaching intensives - pre + postnatal: classes, workshops, treatments - The gyrotonic method: an invigorating movement system to tone, sculpt and promote well-being - triyoga shop: selling Yoga mats, Pilates equipment, books, CDs, clothing, beauty products - Holidays: retreats for you to triyoga somewhere warm - Café: organic café with fresh juices, daily meal specials and more.",
          "offers": "Please visit our  website for detailed pricing and offers.",
          "locations": [
            {
              "geolocation": [
                -0.12297239999998055,
                51.5146779
              ],
              "city": "London",
              "postcode": "WC2B 5RH",
              "_id": {
                "$oid": "5a1ef997b1d6217c42dac10c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Triyoga Covent Garden",
          "phone": {
            "number": "2033623355",
            "code": "+44"
          },
          "qualifications": [],
          "testimonials": "Elena:\nTriyoga has the best atmosphere and best teachers of any studio I have been to. It's completely relaxed and friendly but the teaching is really professional and the teachers really care about making sure students have the best possible experience. I love the bright and airy studios and there are so many classes to choose from-you can easily try any style you like. I also had the most incredible shiatsu massage there, which has, as yet, not been beaten.\n\nEmmeline:\nI have been to pre and post natel classes for two babies at tri yoga as well as lots of workshops and 'normal' classes and I am always impressed. The studios all have a lovely chilled out nurturing vibe and the teaching is always excellent. The food in Camden is spot on and the treatments are excellent too. A lovely place to chill out, nurture yourself and practice yoga too.\n\nEvelyna:\nAmazing studio! A lot of choice in terms of courses (any kind of yoga you could wish for and Pilates) and professors. The atmosphere is welcoming and the food at the cafe is delightful! Thanks for the complimentary porridge every morning :) lovely attention!"
        },
        "score": 62
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "triyoga_covent_garden",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "585a6a30b3f2cd315cbcc465"
      },
      "lastUpdated": {
        "$date": "2017-11-29T18:17:15.948Z"
      },
      "createdAt": 1482320432289,
      "email": "triyogacamden@shoomon.xyz",
      "firstName": "Triyoga",
      "surname": "Reception",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "RIYTYU4k+gLn/yeDQdLcUA==",
      "hashedPassword": "XXfdZYrfu9zdLZsSNi1KlcSF6Xcbx/i/cE+CbKxijMdrrjpQEaCxT1JI56nbgmWw66wPdyAce0HD4Azv5yYDFQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/r6u77kro-triyogalogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Kids Yoga",
            "Post Natal Yoga",
            "Pregnancy Yoga",
            "Private Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.triyoga.co.uk/camden",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IAv2xKUB-triyogabg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "A centre of excellence for Yoga, Pilates, Treatments + much more",
          "biography": "What we offer: Triyoga offers you a place that is dedicated to looking after you, and somewhere everyone can belong. Start or deepen your yoga practice here, learn about cutting-edge personal development, yoga philosophy and meditation, or take Pilates mat + equipment classes. In addition, we have an amazing range of treatments with the best therapists. Whatever you choose, it takes place in beautiful studios or treatment rooms.  We have designed an environment, class schedule and treatments schedule to nurture the health of every individual: men and women; babies, kids and teens, through to seniors – everyone triyoga! Yoga: classes, courses and workshops - Pilates: mat classes, equipment classes courses and workshops - Treatments massage: osteopathy, facials, complementary therapies - Babies and kids: pregnancy and lots more - Courses + workshops: yoga, Pilates and lots more - Teacher training: teacher training diploma (BWY + Yoga Alliance accredited) and teaching intensives - pre + postnatal: classes, workshops, treatments - The gyrotonic method: an invigorating movement system to tone, sculpt and promote well-being - triyoga shop: selling Yoga mats, Pilates equipment, books, CDs, clothing, beauty products - Holidays: retreats for you to triyoga somewhere warm - Café: organic café with fresh juices, daily meal specials and more.",
          "offers": "Please visit our  website for details of pricing, packages and and offers.\n\nOpening Hours:\n\nMonday – Friday: 6.00am – 9.30pm\nSaturday: 7.15am – 9.00pm\nSunday: 8.15am – 9.00pm",
          "locations": [
            {
              "geolocation": [
                -0.1468965000000253,
                51.5399046
              ],
              "city": "London",
              "postcode": "NW1 7DB",
              "_id": {
                "$oid": "5a1ef9abb1d6217c42dac10d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Triyoga Camden",
          "phone": {
            "number": "2074833344",
            "code": "+44"
          },
          "qualifications": [
            "Barre Class",
            "Yin Yoga Class",
            "Kids Yoga Class",
            "General Yoga Class",
            "Pregnancy Yoga Class",
            "Mat Pilates Class",
            "Hot Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Kundalini Yoga Class",
            "Ashtanga Yoga Class",
            "Meditation Class",
            "Mysore Yoga Class",
            "Mother & Baby Yoga Class",
            "Anusara Yoga Class",
            "Scaravelli Yoga Class",
            "Iyengar Yoga Class",
            "Sports Specific Yoga Class"
          ],
          "testimonials": "Elena:\nTriyoga has the best atmosphere and best teachers of any studio I have been to. It's completely relaxed and friendly but the teaching is really professional and the teachers really care about making sure students have the best possible experience. I love the bright and airy studios and there are so many classes to choose from-you can easily try any style you like. I also had the most incredible shiatsu massage there, which has, as yet, not been beaten.\n\nEmmeline:\nI have been to pre and post natel classes for two babies at tri yoga as well as lots of workshops and 'normal' classes and I am always impressed. The studios all have a lovely chilled out nurturing vibe and the teaching is always excellent. The food in Camden is spot on and the treatments are excellent too. A lovely place to chill out, nurture yourself and practice yoga too.\n\nEvelyna:\nAmazing studio! A lot of choice in terms of courses (any kind of yoga you could wish for and Pilates) and professors. The atmosphere is welcoming and the food at the cafe is delightful! Thanks for the complimentary porridge every morning :) lovely attention!"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "triyoga_camden",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "585a6e72b3f2cd315cbcc4f7"
      },
      "lastUpdated": {
        "$date": "2018-01-18T13:31:14.121Z"
      },
      "createdAt": 1482321522784,
      "email": "paul.persad1@gmail.com",
      "firstName": "Paul",
      "surname": "Persad",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "/0A7AR4poFfOEd/oxwulgg==",
      "hashedPassword": "CnF9kbI6HrTgqEfykVBQ4lEIObpwa7xBCtWHfpJa6Ye7pR+KLzdouY/2fUL7anOTY0zTii0KxykqFskzAneYQg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/h47BiSDu-2015-05-16%2010.36.10%20(600x800).jpg"
        },
        "personal": {
          "disciplines": [
            "Mobile Trainer",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "price": {
            "min": 40,
            "max": 60
          },
          "facebook": "",
          "strapLine1": "Transform your body | Achieve great health",
          "biography": "Hi I'm Paul and I run a mobile Personal Training service from Dorking and serving Surrey.  Do you want to drop a trouser or dress size or two? Do you want to feel fitter, happier and healthier? Would you like to firm up that stomach and feel toned throughout?! Do you visit the gym already, but haven't seen any results? Are you a novice to exercise and feel nervous - you're not sure where to start? Would you like to perform better in your sport - or achieve a personal best? Would you like to improve a chronic health condition? Do you struggle with the concept of healthy eating? Are you held back by time and family commitments? Would you like to feel fitter through pregnancy and have a healthier child?  Ask me! There are lots of ways I can help and motivate you. Why not experience a taster personal training service and arrange a chat?",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fPvBikbc-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.33334650000000005,
                51.2326561
              ],
              "city": "Dorking",
              "postcode": "RH4 1DR",
              "_id": {
                "$oid": "5a60a1a2765eba094646f7cb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7858731890",
            "code": "+44"
          },
          "qualifications": [
            "Level 3: Master Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Strength & Sports Conditioning",
            "Clinical Exercise",
            "Nutrition & Weight Management",
            "British Rowing Level 2 Coach",
            "British Red Cross First Aid at Work",
            "Occupational Health and Safety"
          ]
        },
        "score": 63,
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "paul_persad",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "585a72e7b3f2cd315cbcc5b9"
      },
      "lastUpdated": {
        "$date": "2017-11-29T15:08:58.000Z"
      },
      "createdAt": 1482322663688,
      "email": "theiyengaryogastudio@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "IdDuiELNCnnsECOpBFSimg==",
      "hashedPassword": "AIW/+tttEfk2wRcuZnOqckG1x8y27G47P5dla1iasT0YxJbX7CcCoC+f+zjbkzIwdrB8lpe79zhXfsBE1wmv4Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kZHuCUKc-theIYS.png"
        },
        "personal": {
          "disciplines": [
            "Iyengar Yoga",
            "Kids Yoga",
            "Remedial Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.tiys.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fnJLM8E1-theiyengarstudiobg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "The Iyengar Yoga Studio is a small, friendly studio opened in January 2007 by local teachers  Patsy Sparksman, Gene Dicker and Wendy Sykes. The building was designed by award-winning Architect Dean Hawkes and is dedicated to Iyengar yoga.  Our classes are graded according to level of experience but are otherwise open to everyone. Exceptions are the therapy and teenage classes.  We provide mats and other equipment, so there is no need to bring your own.  Information about our timetable and classes can be found on the Timetable and Guide To Classes pages on our website. The Iyengar Yoga Studio is an Affiliated Centre of the Iyengar Yoga Association (UK) and every teacher is a fully certified Association member. For more details about teachers at the Studio visit  the 'Teachers' section of our website.",
          "offers": "All regular students are required to join the Studio Friends Scheme, an annually renewable membership costing £40.00 that runs from 1 January to 31 December (pro-rata for anyone joining part way through the year).  Students who have not yet made a commitment to regular classes (Non-friends) pay more per class. Discounts apply to Friends who pre-pay for five or ten classes.  Please pay your teacher directly by cheque or cash - the Studio does not accept card payments. Please visit our website for full details.",
          "locations": [
            {
              "geolocation": [
                -0.1625731999999971,
                51.5913989
              ],
              "city": "London",
              "postcode": "N2 9EA",
              "_id": {
                "$oid": "5a1ecd89b1d6217c42dac100"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Iyengar Yoga Studio",
          "phone": {
            "number": "2088151918",
            "code": "+44"
          },
          "affiliation": "Iyengar Yoga Association",
          "qualifications": [
            "Iyengar Yoga Class",
            "Kids Yoga Class",
            "Remedial Yoga Class"
          ]
        },
        "score": 59
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_iyengar_yoga_studio",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "585a7ed6b3f2cd315cbcc79b"
      },
      "lastUpdated": {
        "$date": "2017-11-29T15:02:56.806Z"
      },
      "createdAt": 1482325718876,
      "email": "iyengaryogainstitute@shoomon.xyz",
      "firstName": "Alan",
      "surname": "Reynolds",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "BBVdz4sJTH0PZTOykqmzeA==",
      "hashedPassword": "PN2oTHVCSNiLLBeg89SLvSQC9fXfRcQUsAkELE+NP5Og55oeSuRpkDNa1hZ3Fa3uUkFC3vIqpju+x88g+6fnwA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DL6KsU3i-iyengaryogainstitutelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Iyengar Yoga",
            "Kids Yoga",
            "Pregnancy Yoga",
            "Remedial Yoga",
            "Restorative Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://iyi.org.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/J3A8H7F6-iyengaryogainstitutebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Dedicated to Yoga in Maida Vale: learn from the most-experienced Yoga Teachers in the country",
          "biography": "The Iyengar Yoga Institute Maida Vale is London’s original Iyengar yoga centre. We’ve been welcoming new students and experienced practitioners for more than thirty years, since B.K.S. Iyengar first taught classes here.  Located in Maida Vale, ten minutes from central London, the Institute is open daily, from early in the morning until late at night. We offer more than fifty classes a week, catering for all levels from complete beginners to more advanced practitioners, in award-winning bright, spacious studios.  As a charity, we’re run on a not-for-profit basis with a commitment to offer affordable yoga to students of all ages and a connection to its classical Indian source. We teach yoga at all levels from yoga beginners to advanced, including specialised children’s yoga classes and pregnancy yoga classes .",
          "offers": "Please visit our website for complete details on prices",
          "locations": [
            {
              "geolocation": [
                -0.18913710000003903,
                51.5321922
              ],
              "city": "London",
              "postcode": "W9 1NL",
              "_id": {
                "$oid": "5a1ecc20b1d6217c42dac0ff"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Iyengar Yoga Institute",
          "phone": {
            "number": "2076243080",
            "code": "+44"
          },
          "qualifications": [
            "Iyengar Yoga Class",
            "Restorative Yoga Class",
            "Kids Yoga Class",
            "General Yoga Class",
            "Pregnancy Yoga Class",
            "Remedial Yoga Class",
            "Older Adults Yoga Class",
            "Yoga Workshop"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "iyengar_yoga_institute",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "585aa70fb3f2cd315cbcce2f"
      },
      "lastUpdated": {
        "$date": "2017-11-29T14:51:15.596Z"
      },
      "createdAt": 1482336015212,
      "email": "batterseayoga@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "kmi/LQOtPC3XBXmcyYnpmw==",
      "hashedPassword": "R/Gt591fYkOErHkWjgYlyVQ9jetLuT3HgQoJY72XaFt1QZ93ivoqcmjmrP6FLJEMlL8SP6uqbp9Uioixa86mBw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YpjCU3sj-batterseayogalogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Hatha Yoga",
            "Meditation",
            "Post Natal Yoga",
            "Pregnancy Yoga",
            "Restorative Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://batterseayoga.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "facebook": "",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/w0VEvmxB-Batterseayogabg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Battersea Yoga, Est. 1999. Battersea Yoga is one of London’s most-loved yoga studios. We have a beautiful space, tucked away down a private driveway near Battersea Park that is the sanctuary to a wonderful community of students, teachers and therapists. We’re specialists in a number of areas and super passionate about Hatha Yoga, Restorative Yoga and Mindfulness Meditation. We offer over 50 classes per week, weekend workshops, adventure yoga holidays, counselling and healing therapies. Battersea Yoga is proud to host some of London’s finest yoga teachers, each one hand-picked for their experience, knowledge and passion for their subject. We have over 15 specialist teachers waiting to inspire, support and guide you. Whether you are a complete beginner or a seasoned professional, we look forward to welcoming you to Battersea Yoga’s Kite Yard studio.",
          "strapLine1": "No gimmicks, no sweat, no fuss - just great yoga",
          "offers": "Please visit our website for full details",
          "locations": [
            {
              "geolocation": [
                -0.1647722000000158,
                51.47441449999999
              ],
              "city": "London",
              "postcode": "SW11 4RT",
              "_id": {
                "$oid": "5a1ec963b1d6217c42dac0fd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Battersea Yoga",
          "phone": {
            "number": "2079787995",
            "code": "+44"
          },
          "qualifications": [
            "Hatha Yoga Class",
            "Meditation Class",
            "Restorative Yoga Class",
            "Pregnancy Yoga Class",
            "Post Natal Yoga Class",
            "Private Class"
          ],
          "yearsOfExp": 1999
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "battersea_yoga",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "585aac80b3f2cd315cbccef1"
      },
      "lastUpdated": {
        "$date": "2017-11-29T14:41:01.056Z"
      },
      "createdAt": 1482337408069,
      "email": "eastofeden@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "D2GnSsg0ilD2svtjAM5wtA==",
      "hashedPassword": "uo3nLa0I9vSwA86ypCy4mSMXUhCgyrMdtXaf/T7zV0qCPq1xOuM8Vjvu06rynPcntGU8k9kvO4EdpSTmopPkeg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AlFYZdTp-eastofedenlogo.png"
        },
        "personal": {
          "disciplines": [
            "Kids Yoga",
            "Ladies Only Classes",
            "Mat Pilates",
            "Reformer Pilates",
            "Restorative Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.edeneast.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GxwyaMHo-eastofedenbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Yoga - Pilates - Lifestyle: “The free exploring mind of the individual human is the most valuable thing in the world.”",
          "biography": "Yoga and Pilates studio in Walthamstow, East London, England",
          "offers": "Please visit our website for full details",
          "locations": [
            {
              "geolocation": [
                -0.020712499999945067,
                51.5865113
              ],
              "city": "London",
              "postcode": "E17 4QH",
              "_id": {
                "$oid": "5a1ec6fdb1d6217c42dac0fc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "East of Eden",
          "phone": {
            "number": "2035837530",
            "code": "+44"
          },
          "qualifications": [
            "Restorative Yoga Class",
            "Yin Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Kids Yoga Class",
            "Post Natal Yoga Class",
            "Pregnancy Yoga Class",
            "Reformer Pilates Class",
            "Mat Pilates Class",
            "Post Natal Pilates Class",
            "Barre Class",
            "Private Class",
            "Studio Hire"
          ]
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "east_of_eden",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "585ab1e6b3f2cd315cbccfe3"
      },
      "lastUpdated": {
        "$date": "2017-11-29T14:32:33.641Z"
      },
      "createdAt": 1482338790944,
      "email": "goodvibescoventgarden@shoomon.xyz",
      "firstName": "Good Vibes",
      "surname": "Bookings",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "lhZEzIwS0jJyvvtgwu2PKA==",
      "hashedPassword": "nZxQ6kXHJVphfmnDDk4bt7IgXt1RtUa9uPtLyM4pII3xWsSG55mYw2QhZohlgxkm2BSFPOweVTqZYrn/BUcc+w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/76FF2do6-goodvibeslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Mat Pilates",
            "Private Classes",
            "Reformer Pilates",
            "Vinyasa Flow Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.goodvibesfitness.co.uk/locations/covent-garden",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jWBVZ3f9-goodvibesbg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "We Love Beginners!",
          "biography": "Good Vibes is more than just a fitness studio. It is a place dedicated to the enjoyment created by positive, fun and thoughtful exercise creates. We hope to inspire people to exercise because it’s better for their minds as well their bodies. Our winning combination of classes will not only make you fitter, but happier too.  We love the atmosphere that the sociability of great classes creates and firmly believe that feeling fantastic is about generating positivity and joy together, not working in isolation.  We’ve gone to great lengths to create spaces that are a pleasure to be in and help you to get the most from your class. So that means Europe’s first FAR infra-red studio to maximise the benefits for your mind and body: Expert and compassionate teaching; Active relaxation is as important as action; Positive communities inspire healthy living. How you choose to divide your time between those classes depends on what you enjoy doing and what you want to achieve. We want everyone to enjoy classes that are welcoming and fun so we’ve made it as easy as possible to get involved and are flexible to be able to suit different schedules and needs. We offer brilliant Membership options where you can come to unlimited classes across both studios. So whether you’re an exercise expert or an exercise beginner, we will help you to fit a better body and a better mind into your life. Good Vibes is as much about feeling good as it is about looking good!",
          "offers": "Please visit our website for full details.",
          "locations": [
            {
              "geolocation": [
                -0.12403710000000956,
                51.5150726
              ],
              "city": "London",
              "postcode": "WC2H 9BU",
              "_id": {
                "$oid": "5a1ec501b1d6217c42dac0fa"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Good Vibes",
          "phone": {
            "number": "2072406111",
            "code": "+44"
          },
          "qualifications": [
            "Fitness Class",
            "Mat Pilates Class",
            "Reformer Pilates Class",
            "Vinyasa Flow Yoga Class",
            "Private Class",
            "Changing Facilities"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "good_vibes_BkcQESnlG",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "585ab607b3f2cd315cbcd076"
      },
      "lastUpdated": {
        "$date": "2017-11-29T14:33:09.877Z"
      },
      "createdAt": 1482339847878,
      "email": "goodvibesfitzrovia@shoomon.xyz",
      "firstName": "Good Vibes",
      "surname": "Booking",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "H6YllCzdRHBf2lyT0R1B6A==",
      "hashedPassword": "o8cfpL1y/1VoE9S2i/iSIDxrB2ePbm2g5XKvYrXQvQ0csL+0qB06OQLEC/iAzdXF4e9ssEjfXenabqEl65wl4w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WqL0egtJ-goodvibeslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Mat Pilates",
            "Private Classes",
            "Reformer Pilates",
            "Vinyasa Flow Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.goodvibesfitness.co.uk/locations/fitzrovia",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uJ39YGjW-goodvibesbg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "We Love Beginners!",
          "biography": "Good Vibes is more than just a fitness studio. It is a place dedicated to the enjoyment created by positive, fun and thoughtful exercise creates. We hope to inspire people to exercise because it’s better for their minds as well their bodies. Our winning combination of classes will not only make you fitter, but happier too.  We love the atmosphere that the sociability of great classes creates and firmly believe that feeling fantastic is about generating positivity and joy together, not working in isolation.  We’ve gone to great lengths to create spaces that are a pleasure to be in and help you to get the most from your class. So that means Europe’s first FAR infra-red studio to maximise the benefits for your mind and body: Expert and compassionate teaching; Active relaxation is as important as action; Positive communities inspire healthy living. How you choose to divide your time between those classes depends on what you enjoy doing and what you want to achieve. We want everyone to enjoy classes that are welcoming and fun so we’ve made it as easy as possible to get involved and are flexible to be able to suit different schedules and needs. We offer brilliant Membership options where you can come to unlimited classes across both studios. So whether you’re an exercise expert or an exercise beginner, we will help you to fit a better body and a better mind into your life. Good Vibes is as much about feeling good as it is about looking good!",
          "offers": "Please visit our website for full details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.1349239999999554,
                51.52058779999999
              ],
              "city": "London",
              "postcode": "W1T 2AG",
              "_id": {
                "$oid": "5a1ec525b1d6217c42dac0fb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Good Vibes",
          "phone": {
            "number": "2075801276",
            "code": "+44"
          },
          "qualifications": [
            "Reformer Pilates Class",
            "Fitness Class",
            "Vinyasa Flow Yoga Class",
            "Mat Pilates Class",
            "Changing Facilities",
            "Private Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "good_vibes",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "585ad660b3f2cd315cbcd5ba"
      },
      "lastUpdated": {
        "$date": "2017-11-29T14:06:57.469Z"
      },
      "createdAt": 1482348128126,
      "email": "yogaonthelane@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "g0NYaMPPLlbGiaBvE9DAtw==",
      "hashedPassword": "MqcnyFtGTv6Ao3x7PPohMN9SSBwrUmC99hEEIxbecyyChnV74L3FXZcE3KtFIwpa1+jZlO/Zpun3/T2aAkOySw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Zdod3b9R-yogaonthelanefb.jpg"
        },
        "personal": {
          "disciplines": [
            "Mother & Baby Yoga",
            "Pregnancy Yoga",
            "Private Classes",
            "Vinyasa Flow Yoga",
            "Yoga Workshop"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.yogaonthelane.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "facebook": "",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/719Ag6MU-yogaonthelanebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "East London's most-welcoming yoga space",
          "biography": "At Yoga on the Lane we teach Vinyasa yoga. Sometimes called Dynamic Flow, Vinyasa focuses on graceful, harmonized sequences that look to synchronise mind and body by aligning breath and movement. We host a range of classes, courses and workshops at our lovely light-filled studio in Dalston, east London, and we cater to everyone: complete beginners, experienced practitioners, pregnant women, and mothers with babies. Our studio isn’t hot like Bikram but our special floor-warming system means that even those winter-morning savasanas will be nice and snug. We have bolsters, blocks, straps, mats, blankets and everything you’ll need to make your class comfortable and enjoyable whatever the session.",
          "offers": "All our classes can be reserved in advance using our online booking system but we hold some back for drop-ins, so if you haven't booked make sure you get there early as the drop in spaces are first come first served. Please visit our website for details.",
          "locations": [
            {
              "geolocation": [
                -0.07000889999994797,
                51.5531338
              ],
              "city": "London",
              "postcode": "E8 2EB",
              "_id": {
                "$oid": "5a1ebf01b1d6217c42dac0f4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Yoga on the Lane",
          "phone": {
            "number": "2072541229",
            "code": "+44"
          },
          "qualifications": [
            "Mother & Baby Yoga Class",
            "Pregnancy Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Private Class"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yoga_on_the_lane",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "585adeb6b3f2cd315cbcd70d"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:32:11.332Z"
      },
      "createdAt": 1482350262517,
      "email": "indabayogastudio@shoomon.xyz",
      "firstName": "Welcome",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "WqYnPfcJ2ZTcADjzFwJHdQ==",
      "hashedPassword": "jSrPpzKpbh6Jnzrs2mruTdYfYtrhUNfarkQvecG1+lYerYvoMon7WrIjPEPyFUtWyIvr95zKnlcFy8Mq4E/PBQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BLYXA21P-indabalogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Acroyoga",
            "General Yoga",
            "Hot Yoga",
            "Private Classes",
            "Yin Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://indabayoga.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5OBB23Sw-indababg.jpg",
            "size": {},
            "offset": 0
          },
          "google": "",
          "biography": "Indaba means ‘gathering’. Perfectly located in Central London, we’ve gathered a wide range of the city’s most talented teachers and built a community of yogis. In the welcoming atmosphere of this beautiful space we’ve created what we believe is the ultimate yoga studio! Indaba offers a rich array of yoga styles, providing something for people of every ability and preference. So if you’re here to learn, head in to our Beginners or Alignment based classes. If you want to break a sweat, our Dynamic and Hot Yoga classes will be your (warm and herbal) cuppa-tea! If you’re looking to chillax, Unwind & Release is the style for you! But if you want to be kept on your toes (or hands), try our Experienced Yoga classes and be inspired by a challenge! We have a fabulous collective of experienced and inspiring yoga teachers from around the world. Drawing on a wide range of backgrounds and methods, they all have a deep respect for the power of yoga. Dedicated to the practice, they are committed to sharing their knowledge with students of all levels. We can’t decide who is our favourite! Indaba offers a variety of treatments to help you relax and re-align. Delivering the ultimate service, our team of therapists will re-balance your mind, soften stiff muscles, address injuries, keep you healthy and make your body smile again. A healthy human sometimes needs a treatment or two to improve their well-being and enhance their yoga practice!  See more",
          "offers": "With a variety of pricing options, you can save and be flexible at Indaba (no pun intended!). The more you practice, the more you save! Please visit our websire for full details.",
          "locations": [
            {
              "geolocation": [
                -0.16455020000000786,
                51.5226198
              ],
              "city": "London",
              "postcode": "NW1",
              "_id": {
                "$oid": "5a21921ba95f7a0d7e4bda63"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Indaba Yoga Studio",
          "phone": {
            "number": "2077249994",
            "code": "+44"
          },
          "qualifications": [
            "Acroyoga Class",
            "General Yoga Class",
            "Hot Yoga Class",
            "Yin Yoga Class",
            "Power Yoga Class",
            "Ashtanga Yoga Class",
            "Jivamukti Yoga Class",
            "Mysore Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Iyengar Yoga Class",
            "Mat Pilates Class",
            "Restorative Yoga Class",
            "Kundalini Yoga Class",
            "Meditation Class",
            "Pregnancy Yoga Class",
            "Yoga Workshop",
            "Beginners Yoga Class",
            "Dharma Yoga",
            "Dynamic Yoga Class",
            "Rocket Yoga Class",
            "Pranayama Class"
          ]
        },
        "score": 56
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "indaba_yoga_studio",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "585aec7eb3f2cd315cbcd94f"
      },
      "lastUpdated": {
        "$date": "2017-11-28T23:27:50.486Z"
      },
      "createdAt": 1482353790261,
      "email": "airyogastudio@shoomon.xyz",
      "firstName": "Booking",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "foMw1JPNpXeGG8PwXjju7A==",
      "hashedPassword": "2f+Vig7JdARLntTiLfYgGtGLKgK2EgBKtgvCJEshoffgTn6/zy3oeNfKRNf6Ji0hmoodTdTghGAF9bJgMuGyxg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pyZTeXjI-air%20yogaprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Hatha Yoga",
            "Pregnancy Yoga",
            "Restorative Yoga",
            "Vinyasa Flow Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.air-yoga.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dNDUDyoe-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "biography": "At Air Yoga Studio, we pride ourselves on creating an approachable, warm environment for everyone of all levels who are looking to begin or develop their Yoga or Pilates practice. The studio holds a maximum of 15 people per class so that each student receives the required attention from the teacher and feels nurtured throughout the class. We provide all the equipment you need for whichever class you attend, but you if you wish to bring your own mat please feel free to do so - blocks, straps and blankets are also provided. Based right next door to South Woodford central line tube station, we are easily accessible by public transport and there are also car-parking spaces around the studio (small charges apply). If you have any other questions regarding the studio please visit our website for contact details.",
          "strapLine1": "",
          "offers": "Please visit our website for full details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                0.028241999999977452,
                51.5917813
              ],
              "city": "London",
              "postcode": "E18 1JJ",
              "_id": {
                "$oid": "5a1df0f6b1d6217c42dac0e0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Air Yoga Studio",
          "phone": {
            "number": "7949575468",
            "code": "+44"
          },
          "qualifications": [
            "Hatha Yoga Class",
            "Pregnancy Yoga Class",
            "Restorative Yoga Class",
            "Vinyasa Flow Yoga Class"
          ]
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "air_yoga_studio",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "585af56cb3f2cd315cbcdaa1"
      },
      "lastUpdated": {
        "$date": "2018-01-18T13:22:49.343Z"
      },
      "createdAt": 1482356076195,
      "email": "scott@scottrichardsonpt.co.uk",
      "firstName": "Scott",
      "surname": "Richardson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "DKeB+vYvihVzOAMU5vkyNg==",
      "hashedPassword": "AjqeThEUQtObKDAYH6gS07nuVoKva3Qu6jkvMgG4CU0zpqB3HuNLt316kpGOorNUC2q+X73ZwQhxkXqFsiipmQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xQvAQhX2-IMG_1868.JPG"
        },
        "personal": {
          "disciplines": [
            "Endurance",
            "Functional Training",
            "Hiit",
            "Rehabilitation"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 40,
            "max": 45
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YDhAKRRU-IMG_2845.JPG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                0.2636783000000378,
                51.1319062
              ],
              "city": "Royal Tunbridge Wells",
              "postcode": "TN1 1RL",
              "_id": {
                "$oid": "5a609fa9765eba094646f7c9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7732063725",
            "code": "+44"
          },
          "biography": "I am passionate about health and fitness and have worked as a Personal Trainer for over 10 years. I have a wealth of experience and helping my clients acheive their health and fitness goals is my number one aim. I continue to keep my qualifications and knowledge up to date in order to stay ahead with the latest cutting edge information and technology. My experience and knowledge is paramount in enabling me to enhance my clients training experiences and maximising their results.\n\nOutside of working with my clients I have always been a sports fanatic! Starting off as a runner  during school days my PE teacher soon spotted my natural talent for long distance running. It wasnt long before I was competing at county level. I love to run and have competed in several marathons. One of my produest moments was 6th Britain home at the Amsterdam Marathon in 2009 in a time of 2.42. I have now diversified into triathlon, specifically 70.3 half ironman distance. At my first event, the Marlow half ironman in 2013, I managed to get the team GB qualification time for my age group. I now represent team GB annually at the European middle distance triathlon championships. In 2014 I was 4th man home, missing out on the podium by 1 minute! I continue to train and stay active in my spare time and have a very understanding girlfriend!",
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Corrective Exercise Masters in Personal Training",
            "Diploma in Sports Therapy",
            "Exercise for the Management of Low Back Pain",
            "Resisted Movement Training",
            "Movement Base Flexibility",
            "Core Stability",
            "Body Massage and Sports Massage Therapy",
            "Circuits",
            "Optimum and Sports Nutrition",
            "Scientific Back Training",
            "Scientific Core Conditioning",
            "Programme Design",
            "BOSU Integrated Balance Training (Physical Company)",
            "Chronic Respiratory Disease Exercise (Loughborough College)",
            "Fitness Instruction and Personal Training (Body In Action)"
          ]
        },
        "score": 54,
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "scott_richardson",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "585bdd4eceddc410bc5d5513"
      },
      "lastUpdated": {
        "$date": "2018-01-18T13:13:17.948Z"
      },
      "createdAt": 1482415438277,
      "email": "charltonclaudia@gmail.com",
      "firstName": "Claudia",
      "surname": "Luscombe-Whyte",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "0C6vlS0PiOdp9TBAyYOltg==",
      "hashedPassword": "PIq/s+ls7dzYOcnNvqn1l6eOG0ckJsQaGafdPbq0KBTOgeXuFIOrWg+L9/HRJOBIKafse3Lpsq5ZhTRnWHxauQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tmz8qc8i-IMG_7866.JPG"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Mobile Trainer",
            "Pre/post Natal"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 50,
          "price": {
            "min": 45,
            "max": 55
          },
          "locations": [
            {
              "geolocation": [
                -0.33943599999997787,
                51.75272500000001
              ],
              "city": "Saint Albans",
              "postcode": "AL3 5EG",
              "_id": {
                "$oid": "5a609d6d765eba094646f7c6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7585778388",
            "code": "+44"
          },
          "biography": "Hi, my name is Claudia and I am dedicated to help you reach your health & fitness goals.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "NASM - Corrective exercise specialist",
            "NASM Training the Ante Natal Client",
            "TRX Suspension Certificate"
          ]
        },
        "score": 52,
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "claudia_luscombe-whyte",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "585be931ceddc410bc5d56f7"
      },
      "lastUpdated": {
        "$date": "2017-11-29T14:01:40.804Z"
      },
      "createdAt": 1482418481158,
      "email": "thelifecentre@shoomon.xyz",
      "firstName": "Life Centre",
      "surname": "Bookings",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "+W4tHNSgBswX2MF4M9Zxuw==",
      "hashedPassword": "+uCuI5FqoOjuKqFC8efxdZcvty0Ew2XCUDDLkjlK6gekoTDM/hfE6s+0fmcY7Dg/0nj3sc82zzUJDoo4j+MWWg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/h6coGl7e-thelifecentrelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "General Yoga",
            "Jivamukti Yoga",
            "Mother & Baby Yoga",
            "Restorative Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.thelifecentre.com/centres/nottinghill",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ad88ZpUL-thelifecentrebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The Life Centre is your guide to a deeper experience: Yoga for life",
          "biography": "We know how vital it is to have the best teachers and therapists and to offer a fantastic choice of yoga styles and therapies. We understand how to nurture relationships in our specialised pregnancy, and mums & babies classes, and how to ease newcomers into a regular practice, with our beginners’ courses. Plus, we run exciting workshops with resident and guest teachers whose experience and imagination brings a freshness to yoga on each occasion .Founded in Notting Hill 1993, The Life Centre is one of London’s oldest centres dedicated to Yoga, Pilates and Therapies. Over the years, many (maybe even most) of the UK’s top yoga and Pilates teachers have passed through the big wooden door of our Notting Hill centre, a beautiful quiet converted house and chapel – and several of our earliest students (and some of our original teachers and staff) are still Life Centre regulars. In 2011, we were delighted to open our second location, in Islington, a bright and cheery space with 2 large studios in a converted warehouse which offers a similarly broad range of classes to those in Notting Hill. In 2003, we set up our yoga education arm, Yogacampus, which offers one of the UK’s very top yoga teacher training programmes, as well as a wide range of other specialist courses and workshops. And, in 2016, we opened our doors to the yoga community in Manchester.",
          "offers": "Please visit our website for full details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.19606350000003658,
                51.5076057
              ],
              "city": "London",
              "postcode": "W8 7PN",
              "_id": {
                "$oid": "5a1ebdc4b1d6217c42dac0f3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Life Centre",
          "phone": {
            "number": "2072214602",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "General Yoga Class",
            "Jivamukti Yoga Class",
            "Pregnancy Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Restorative Yoga Class",
            "Yin Yoga Class",
            "Iyengar Yoga Class",
            "Meditation Class",
            "Scaravelli Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_life_centre",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "585bf2a2ceddc410bc5d5879"
      },
      "lastUpdated": {
        "$date": "2017-11-29T14:00:58.508Z"
      },
      "createdAt": 1482420898718,
      "email": "thelifecentreislington@shoomon.xyz",
      "firstName": "Life Centre",
      "surname": "Bookings",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "0+kwKDskJYvfbZ1Narbpbw==",
      "hashedPassword": "vXH2K2PLB71jeosTq8T9plQQYxfJXJNAoxN2iqUk4OO3tpsGk7l/NRH271XzhYjfMpZPDBOsScibZ1VNpw2g1Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tsvE8Yzj-thelifecentrelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Aerial Yoga",
            "Ashtanga Yoga",
            "Iyengar Yoga",
            "Jivamukti Yoga",
            "Ladies Only Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.thelifecentre.com/centres/islington",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ex6mSnqo-thelifecentrebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The Life Centre is your guide to a deeper experience - yoga for life",
          "biography": "We know how vital it is to have the best teachers and therapists and to offer a fantastic choice of yoga styles and therapies. We understand how to nurture relationships in our specialised pregnancy, and mums & babies classes, and how to ease newcomers into a regular practice, with our beginners’ courses. Plus, we run exciting workshops with resident and guest teachers whose experience and imagination brings a freshness to yoga on each occasion .Founded in Notting Hill 1993, The Life Centre is one of London’s oldest centres dedicated to Yoga, Pilates and Therapies. Over the years, many (maybe even most) of the UK’s top yoga and Pilates teachers have passed through the big wooden door of our Notting Hill centre, a beautiful quiet converted house and chapel – and several of our earliest students (and some of our original teachers and staff) are still Life Centre regulars. In 2011, we were delighted to open our second location, in Islington, a bright and cheery space with 2 large studios in a converted warehouse which offers a similarly broad range of classes to those in Notting Hill. In 2003, we set up our yoga education arm, Yogacampus, which offers one of the UK’s very top yoga teacher training programmes, as well as a wide range of other specialist courses and workshops. And, in 2016, we opened our doors to the yoga community in Manchester.",
          "offers": "Please visit our website for full details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.09689800000001014,
                51.5375484
              ],
              "city": "London",
              "postcode": "N1 8QH",
              "_id": {
                "$oid": "5a1ebd9ab1d6217c42dac0f2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Life Centre",
          "phone": {
            "number": "2077040919",
            "code": "+44"
          },
          "qualifications": [
            "Aerial Yoga Class",
            "Ashtanga Yoga Class",
            "Iyengar Yoga Class",
            "Jivamukti Yoga Class",
            "Meditation Class",
            "Mother & Baby Yoga Class",
            "Power Yoga Class",
            "Restorative Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Yin Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_life_centre_islington",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "585cec2dceddc410bc5d806b"
      },
      "lastUpdated": {
        "$date": "2017-11-29T14:00:02.533Z"
      },
      "createdAt": 1482484781876,
      "email": "thelifecentremanchester@shoomon.xyz",
      "firstName": "Life Centre",
      "surname": "Bookings",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "7yC718/z7hEe0cYfbmde3g==",
      "hashedPassword": "xXIETi25lr8ARQOhFoV4kRXPYekSThGe/mmDLDHgYtSTG7I9Iq3WBeu7PtDFznMlOlxEEuAseqRnmpVnuWlbUg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5fI3Ax7z-thelifecentrelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "General Yoga",
            "Hot Yoga",
            "Kundalini Yoga",
            "Pregnancy Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.thelifecentrenorth.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rlwZk1mz-thelifecentrebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The Life Centre is your guide to a deeper experience - yoga for life",
          "biography": "We know how vital it is to have the best teachers and therapists and to offer a fantastic choice of yoga styles and therapies. We understand how to nurture relationships in our specialised pregnancy, and mums & babies classes, and how to ease newcomers into a regular practice, with our beginners’ courses. Plus, we run exciting workshops with resident and guest teachers whose experience and imagination brings a freshness to yoga on each occasion .Founded in Notting Hill 1993, The Life Centre is one of London’s oldest centres dedicated to Yoga, Pilates and Therapies. Over the years, many (maybe even most) of the UK’s top yoga and Pilates teachers have passed through the big wooden door of our Notting Hill centre, a beautiful quiet converted house and chapel – and several of our earliest students (and some of our original teachers and staff) are still Life Centre regulars. In 2011, we were delighted to open our second location, in Islington, a bright and cheery space with 2 large studios in a converted warehouse which offers a similarly broad range of classes to those in Notting Hill. In 2003, we set up our yoga education arm, Yogacampus, which offers one of the UK’s very top yoga teacher training programmes, as well as a wide range of other specialist courses and workshops. And, in 2016, we opened our doors to the yoga community in Manchester.",
          "offers": "Please visit our website for full details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -2.25043119999998,
                53.4763947
              ],
              "city": "Manchester",
              "postcode": "M3 4EL",
              "_id": {
                "$oid": "5a1ebd62b1d6217c42dac0f1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Life Centre",
          "phone": {
            "number": "1618346283",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "Hot Yoga Class",
            "General Yoga Class",
            "Kundalini Yoga Class",
            "Pregnancy Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Yoga Workshop",
            "Yoga Retreat"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_life_centre_manchester",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "585cf24aceddc410bc5d818d"
      },
      "lastUpdated": {
        "$date": "2017-11-28T21:29:10.166Z"
      },
      "createdAt": 1482486346123,
      "email": "fiercegracewest@shoomon.xyz",
      "firstName": "F.G. West",
      "surname": "Bookings",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "rBN+5MmsoEurio/oHiZU0w==",
      "hashedPassword": "m/yXQwDBZ6xgNjDR3Z0KPShEDsdOM+csIgDSRjWglNNvErz3E8Ej49GSrRfY/XJmKNLUBQeC0pqCDBZRv6pjuA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hb48YtHP-fiercegracelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "Bikram Yoga",
            "Hatha Yoga",
            "Hot Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.fiercegracewest.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N8NcE8IC-fiercegracebg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "HOT YOGA: LIVE LIFE WITH FIERCE GRACE",
          "biography": "Fierce Grace is a new yoga system and a new brand. We led the UK in the Hot Yoga revolution by bringing Bikram Yoga to London in 1994. Now we bring practitioners the chance to achieve the ultimate blend of strength and flexibility for both body and mind. Based on classical hatha yoga, Bikram and Ashtanga yoga and sequenced in ways that adhere to ancient yogic principles, as well as the latest cutting-edge research on attaining optimum results in the minimum amount of time, Fierce Grace is a unique series of 6 different classes based on the same core poses and principles, each with a different emphasis, duration and intensity. From the yoga shy or the injured to advanced yogis, the 6 classes – Fierce Grace, Classic, WILD, Core, Fierce (aka The Beast) and The Fix - are designed to be accessible and effective for everyone. The variety of classes gives practitioners different options to suit their experience, schedule and mood whilst still working together as a whole. Show up to any class and you will still get the core poses and core hot yoga experience our studios built their reputation on.",
          "offers": "Please visit our website for full details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.2094481999999971,
                51.5322882
              ],
              "city": "London",
              "postcode": "W10 4BA",
              "_id": {
                "$oid": "5a1dd526b1d6217c42dac0cf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fierce Grace West",
          "phone": {
            "number": "2089609644",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "Bikram Yoga Class",
            "Hatha Yoga Class",
            "Hot Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fierce_grace_west",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "585cfbcbceddc410bc5d830f"
      },
      "lastUpdated": {
        "$date": "2017-11-28T21:30:34.082Z"
      },
      "createdAt": 1482488779387,
      "email": "fiercegracenorth@shoomon.xyz",
      "firstName": "F.G. North",
      "surname": "Reception",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "/jOFgIgC0rF95v/eY4jkkw==",
      "hashedPassword": "EU4mf/F1xhoxjRilT7vY3I2h8sXT/Dkc/Lye3CJ8QyMdPHN04U0VDQjMm025W6T0euTzVSFdhVN7BPT6ZtXf3w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/udj9YOZf-fiercegracelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "Bikram Yoga",
            "Hatha Yoga",
            "Hot Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.fiercegracenorth.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/J8jm0O2Y-fiercegracebg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "HOT YOGA: LIVE LIFE WITH FIERCE GRACE",
          "biography": "Fierce Grace is a new yoga system and a new brand. We led the Hot Yoga revolution in the UK by bringing Bikram Yoga to London in 1994. Now we bring practitioners the chance to achieve the ultimate blend of strength and flexibility for both body and mind. Based on classical hatha yoga, Bikram and Ashtanga yoga and sequenced in ways that adhere to ancient yogic principles, as well as the latest cutting-edge research on attaining optimum results in the minimum amount of time, Fierce Grace is a unique series of 6 different classes based on the same core poses and principles, each with a different emphasis, duration and intensity. From the yoga shy or the injured to advanced yogis, the 6 classes - Fierce Grace, Classic, WILD, Core, Fierce (aka The Beast) and The Fix - are designed to be accessible and effective for everyone. The variety of classes gives practitioners different options to suit their experience, schedule and mood whilst still working together as a whole. Show up to any class and you will still get the core poses and core hot yoga experience our studios built their reputation on.",
          "offers": "Please visit our website for full details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.15022090000002208,
                51.550739
              ],
              "city": "London",
              "postcode": "NW5 4DS",
              "_id": {
                "$oid": "5a1dd57ab1d6217c42dac0d0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fierce Grace North",
          "phone": {
            "number": "2076926900",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "Bikram Yoga Class",
            "Hatha Yoga Class",
            "Hot Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fierce_grace_north",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "585d022dceddc410bc5d8401"
      },
      "lastUpdated": {
        "$date": "2017-11-28T21:31:13.479Z"
      },
      "createdAt": 1482490413557,
      "email": "fiercefracecity@shoomon.xyz",
      "firstName": "F.G. City",
      "surname": "Reception",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "uhMijgAYLaKuUZeM6SF9CA==",
      "hashedPassword": "x1ZlbHbaVrPFJsoi2QxjJBAHNF9INAPx3YarPcmIcqS0nt0tUGAiUAVwEH/sb9yIU9oiLk8J2OUNNvy1qEYq7g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lRxTZZLv-fiercegracelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "Bikram Yoga",
            "Hatha Yoga",
            "Hot Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.fiercegracecity.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/f6md0tni-fiercegracebg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "HOT YOGA: LIVE LIFE WITH FIERCE GRACE",
          "biography": "Fierce Grace is a new yoga system and a new brand. We led the Hot Yoga revolution in the UK by bringing Bikram Yoga to London in 1994. Now we bring practitioners the chance to achieve the ultimate blend of strength and flexibility for both body and mind. Based on classical hatha yoga, Bikram and Ashtanga yoga and sequenced in ways that adhere to ancient yogic principles, as well as the latest cutting-edge research on attaining optimum results in the minimum amount of time, Fierce Grace is a unique series of 6 different classes based on the same core poses and principles, each with a different emphasis, duration and intensity. From the yoga shy or the injured to advanced yogis, the 6 classes - Fierce Grace, Classic, WILD, Core, Fierce (aka The Beast) and The Fix - are designed to be accessible and effective for everyone. The variety of classes gives practitioners different options to suit their experience, schedule and mood whilst still working together as a whole. Show up to any class and you will still get the core poses and core hot yoga experience our studios built their reputation on.",
          "offers": "Please visit our website for full details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.08780619999993178,
                51.52877669999999
              ],
              "city": "London",
              "postcode": "N1 6AH",
              "_id": {
                "$oid": "5a1dd5a1b1d6217c42dac0d1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fierce Grace City",
          "phone": {
            "number": "2073366330",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "Bikram Yoga Class",
            "Hatha Yoga Class",
            "Hot Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fierce_grace_city",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "585d0562ceddc410bc5d8493"
      },
      "lastUpdated": {
        "$date": "2017-11-28T21:32:21.481Z"
      },
      "createdAt": 1482491234943,
      "email": "fiercegracefinchley@shoomon.xyz",
      "firstName": "F.G Finchley",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "reU/Tc1YMAPJcrfc3mM5QA==",
      "hashedPassword": "wqz61DX1d962+028AJD44VCAH4LJ/rwhbNQ1KqQEkWsgT7egDPEplKTCUKE+WctSajsTETySc5j8SDXNai/7ig==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6I3gfVty-fiercegracelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "Bikram Yoga",
            "Hatha Yoga",
            "Hot Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.fiercegracefinchley.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Iebhi9Vn-fiercegracebg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "HOT YOGA: LIVE LIFE WITH FIERCE GRACE",
          "biography": "Fierce Grace is a new yoga system and a new brand. We led the Hot Yoga revolution in the UK by bringing Bikram Yoga to London in 1994. Now we bring practitioners the chance to achieve the ultimate blend of strength and flexibility for both body and mind. Based on classical hatha yoga, Bikram and Ashtanga yoga and sequenced in ways that adhere to ancient yogic principles, as well as the latest cutting-edge research on attaining optimum results in the minimum amount of time, Fierce Grace is a unique series of 6 different classes based on the same core poses and principles, each with a different emphasis, duration and intensity. From the yoga shy or the injured to advanced yogis, the 6 classes - Fierce Grace, Classic, WILD, Core, Fierce (aka The Beast) and The Fix - are designed to be accessible and effective for everyone. The variety of classes gives practitioners different options to suit their experience, schedule and mood whilst still working together as a whole. Show up to any class and you will still get the core poses and core hot yoga experience our studios built their reputation on.",
          "offers": "Please visit our website for full details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.19742710000002717,
                51.5975056
              ],
              "city": "London",
              "postcode": "N3",
              "_id": {
                "$oid": "5a1dd5e5b1d6217c42dac0d2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fierce Grace",
          "phone": {
            "number": "2081276541",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "Bikram Yoga Class",
            "Hatha Yoga Class",
            "Hot Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fierce_grace_S1HOfIsgM",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "585d066dceddc410bc5d84c5"
      },
      "lastUpdated": {
        "$date": "2017-11-28T21:21:12.661Z"
      },
      "createdAt": 1482491501345,
      "email": "fiercegraceprimrosehill@shoomon.xyz",
      "firstName": "F.G Primrose",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "EIh390qnXFTt7OSxPeEDLg==",
      "hashedPassword": "ziZLNpO4ba5lV/u11cMwgHqGJ60D7O4yedCE+mTX73PWEUTonsxtVPVS32/d8Ko5NWUR5PtbGHdwAqUgPU/TmQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/QCYzvZip-fiercegracelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "Bikram Yoga",
            "Hatha Yoga",
            "Hot Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.fiercegraceprimrosehill.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7z4pcK64-fiercegracebg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "HOT YOGA: LIVE LIFE WITH FIERCE GRACE",
          "biography": "Fierce Grace is a new yoga system and a new brand. We led the Hot Yoga revolution in the UK by bringing Bikram Yoga to London in 1994. Now we bring practitioners the chance to achieve the ultimate blend of strength and flexibility for both body and mind. Based on classical hatha yoga, Bikram and Ashtanga yoga and sequenced in ways that adhere to ancient yogic principles, as well as the latest cutting-edge research on attaining optimum results in the minimum amount of time, Fierce Grace is a unique series of 6 different classes based on the same core poses and principles, each with a different emphasis, duration and intensity. From the yoga shy or the injured to advanced yogis, the 6 classes - Fierce Grace, Classic, WILD, Core, Fierce (aka The Beast) and The Fix - are designed to be accessible and effective for everyone. The variety of classes gives practitioners different options to suit their experience, schedule and mood whilst still working together as a whole. Show up to any class and you will still get the core poses and core hot yoga experience our studios built their reputation on.",
          "offers": "Please visit our website for full details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.1550517000000582,
                51.5432794
              ],
              "city": "London",
              "postcode": "NW1 8BE",
              "_id": {
                "$oid": "5a1dd348b1d6217c42dac0cb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fierce Grace",
          "phone": {
            "number": "2074832000",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "Bikram Yoga Class",
            "Hatha Yoga Class",
            "Hot Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fierce_grace_BkVFbUsez",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "585d075fceddc410bc5d84f7"
      },
      "lastUpdated": {
        "$date": "2017-11-28T21:05:18.176Z"
      },
      "createdAt": 1482491743440,
      "email": "fiercegracebrixton@shoomon.xyz",
      "firstName": "F.G Brixton",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "OIwLd+al+pHc4cpgFgGZmw==",
      "hashedPassword": "EQfHA725Tr1L8fuEHI1nBh2P2XIq0AAlTCIKbm6t+pxX+ySlUV1il/l6XtloOdNOxaJAwuibAL8bUVY8fmLR3A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YKLoj4JO-fiercegracelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "Bikram Yoga",
            "Hatha Yoga",
            "Hot Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://fiercegracebrixton.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/q7JpKevG-fiercegracebg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "HOT YOGA: LIVE LIFE WITH FIERCE GRACE",
          "biography": "Fierce Grace is a new yoga system and a new brand. We led the Hot Yoga revolution in the UK by bringing Bikram Yoga to London in 1994. Now we bring practitioners the chance to achieve the ultimate blend of strength and flexibility for both body and mind. Based on classical hatha yoga, Bikram and Ashtanga yoga and sequenced in ways that adhere to ancient yogic principles, as well as the latest cutting-edge research on attaining optimum results in the minimum amount of time, Fierce Grace is a unique series of 6 different classes based on the same core poses and principles, each with a different emphasis, duration and intensity. From the yoga shy or the injured to advanced yogis, the 6 classes - Fierce Grace, Classic, WILD, Core, Fierce (aka The Beast) and The Fix - are designed to be accessible and effective for everyone. The variety of classes gives practitioners different options to suit their experience, schedule and mood whilst still working together as a whole. Show up to any class and you will still get the core poses and core hot yoga experience our studios built their reputation on.",
          "offers": "Please visit our website for full details of prices, packages and offers and please register online before attending your first class.",
          "locations": [
            {
              "geolocation": [
                -0.11016489999997248,
                51.46264799999999
              ],
              "city": "London",
              "postcode": "SW9 8PL",
              "_id": {
                "$oid": "5a1dcf8eb1d6217c42dac0c7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fierce Grace",
          "phone": {
            "number": "2037193394",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "Bikram Yoga Class",
            "Hatha Yoga Class",
            "Hot Yoga Class"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fierce_grace",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "585d0cbeceddc410bc5d85b9"
      },
      "lastUpdated": {
        "$date": "2017-11-28T20:50:56.674Z"
      },
      "createdAt": 1482493118442,
      "email": "hotyogasociety@shoomon.xyz",
      "firstName": "H.Y. Society",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "SiyiOP0tn+IdQx7LjTGzrQ==",
      "hashedPassword": "Y8Iph842RdwHQewJkT2ZMz6HVCFYYS+RSj7UxjAzOD5L1oLJ64BA8Mk+01fspaj7jCD207hRY8Plf4+qn16Fkg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/800XPVvf-hotyogasocietylogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bikram Yoga",
            "Fitness Classes",
            "Hot Yoga",
            "Meditation",
            "Vinyasa Flow Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://hotyogasociety.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8DH1m9RU-hotyogasocietybg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Sweat yourself healthy: sharpen your mind as well as your body with our special introductory offer!",
          "biography": "Hello and welcome to the Hot Yoga Society!  We’re London’s biggest community of happy hot-yoga lovers, brought together by our passion for staying fit, feeling great and putting hot yoga at the centre of a healthy lifestyle for the body and mind.  Our studio, nestled in the heart of London Bridge, is where we encourage our hundreds of members to use hot yoga as the key to a happy, healthy life, whether to get fit, de-stress, lose weight or heal injuries. But don’t take our word for it – see what they have to say!  Whether you’re a complete newbie or an expert yogi, our varied range of classes makes the Hot Yoga Society the perfect place to sweat yourself amazing. And let’s not forget our workshops in mindfulness and nutritional advice, all there to give you a gentle nudge to becoming an even better version of you.  As you’ll see, our community comes in all shapes, sizes and backgrounds as we encourage everyone and anyone to give it a try. But if there’s one thing we all have in common, it’s that we’re just a little bit addicted to feeling great.",
          "offers": "At Hot Yoga Society we offer you an array of hot yoga classes. All our classes are suitable for beginners through to advanced practitioners, so booking is easy!  We recommend that you book in advance for our busy classes to avoid disappointment; however, you can just turn up to grab a space on the day. Off-peak classes are those starting between 8am and 5:30pm Monday to Friday. Please visit our website for full details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.08148619999997209,
                51.5035213
              ],
              "city": "London",
              "postcode": "SE1 2EN",
              "_id": {
                "$oid": "5a1dcc30b1d6217c42dac0c3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Hot Yoga Society",
          "phone": {
            "number": "2070363866",
            "code": "+44"
          },
          "qualifications": [
            "Bikram Yoga Class",
            "Hot Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Meditation Class",
            "Hot HIIT Class"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "hot_yoga_society",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "585d1a94ceddc410bc5d87fe"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:09:14.459Z"
      },
      "createdAt": 1482496660272,
      "email": "yotopia@shoomon.xyz",
      "firstName": "Yotopia",
      "surname": "Reception",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "NTisYe+rIPLkLdcz8GiVvQ==",
      "hashedPassword": "K9/kHEiPwHlSgOCdgpLoCHz2+WZLLKH5bAXm0VdGqmYtbfYC016+0o4orrplcz3LVD4pRQP25am+/WDySlR+4w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/CxDPT3mW-yotopialogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "Hot Yoga",
            "Rocket Yoga",
            "Vinyasa Flow Yoga",
            "Yin & Yang Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://yotopia.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "price": {
            "min": 1,
            "max": 149
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qPJ8NlYz-yotopiabg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Intelligent Movement: Yoga And Hot Yoga In The Heart Of Covent Garden",
          "biography": "Yoga and Hot Yoga in the heart of Covent Garden\nWe offer a wide variety of yoga styles in both a hot and non-heated environment from our central London oasis of calm. Our hot room is heated to 37 degrees with a state of the art heating and humidification system.\n\nFrom gentle to dynamic flows and relaxing Yin to Rocket Yoga and Dharma Mittra we have a class to suit your mood and ability with some of the best teachers in town.",
          "offers": "Please visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.1260264999999663,
                51.5129552
              ],
              "city": "London",
              "postcode": "WC2H",
              "_id": {
                "$oid": "5a218cbaa95f7a0d7e4bda57"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Yotopia",
          "phone": {
            "number": "3334058888",
            "code": "+44"
          },
          "qualifications": [
            "Hot Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Ashtanga Yoga Class",
            "Rocket Yoga Class",
            "Yin & Yang Yoga Class",
            "Meditation Class",
            "Dharma Yoga Class"
          ],
          "testimonials": "“The classes offered by Yotopia are without doubt, brilliant. I enjoyed every single class I attended – the teachers are friendly and helpful and the yoga challenging but achievable even for beginners… I’ll definitely be going back.” Rebecca Cox, Glamour Magazine\n\n“Despite my own reservations I have to admit, I loved it.” Marie Winckler, Evening Standard\n\n“So happy to have found a little yoga paradise in the centre of Covent Garden. I have been practicing yoga for many years in central London and have been searching for a dedicated yoga studio close to home, with a good vibe, similar to the beautiful yoga centers you find in LA, I found it…Yotopia.  Great teachers, wonderful classes, beautiful air conditioned studios and friendly staff who can’t do enough for you.  I highly recommend it!”  Jacqui O’Sullivan\n\n“I’ve tried many yoga studios in London, and around the world, and Yotopia comes out tops for quality of teachers, variety of classes throughout  the day – hot yoga, vinyasa flow, jivamukhti, Pilates, to name only a few of my favourites. The staff are super friendly and efficient, greeting everyone by first name upon arrival and offering a towel. The facilities are clean, bright and spacious. A great haven in the heart of Covent Garden and the perfect antidote to a busy life. Thanks to Yotopia classes, I am better equipped emotionally and physically to deal with the  challenges of my hectic job.”  Bernie O’Neill"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yotopia",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "585d2089ceddc410bc5d88f0"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:04:56.579Z"
      },
      "createdAt": 1482498185312,
      "email": "yogahavenclapham@shoomon.xyz",
      "firstName": "Yogahaven",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "CVKekMdQvwdgsSd2LcMZcA==",
      "hashedPassword": "waNo8TphY9aBRne55Ad1Saf8kauAiyaRVwPhcpR/pE0VSQ/8oWENfxQF0ReMiUE+JeJdSVFXOx5AiZwDSWxm7Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/At6JsGqj-yogahavenlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "Hot Yoga",
            "Iyengar Yoga",
            "Jivamukti Yoga",
            "Vinyasa Flow Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.yogahaven.co.uk/london-yoga/clapham/yoga",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tgFUCuWv-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to yogahaven Clapham. We believe yoga is magical, invigorating and most importantly fun – that’s why our unique style of hot yoga is called “leela”, meaning “play” in Sanskrit. Everyone is welcome to our studios, we have a wide variety of classes, suitable for all levels.  Discover our sleek, purpose-built Clapham hot yoga studio, just a minutes walk from Clapham Common tube station. Tucked away from the bustle of Clapham Old Town’s Pavement, it offers a full schedule of both hot yoga and traditional classes, perfect for keeping all yogis in tip-top shape.  We also boast our very own on-site juice bar where you can pre-order a refreshing juice or smoothie to pick up after your class.",
          "offers": "Please visit our website for full details of  prices, packages and offers.",
          "strapLine1": "Yogahaven are pleased to offer a dedicated-hot yoga facility in Clapham, London",
          "locations": [
            {
              "geolocation": [
                -0.14037059999998291,
                51.4636436
              ],
              "city": "London",
              "postcode": "SW4",
              "_id": {
                "$oid": "5a218bb8a95f7a0d7e4bda56"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Yogahaven",
          "phone": {
            "number": "2086170027",
            "code": "+44"
          },
          "qualifications": [
            "Ashtanga Yoga Class",
            "Hot Yoga Class",
            "Iyengar Yoga Class",
            "Jivamukti Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Pregnancy Yoga Class",
            "Restorative Yoga Class",
            "Yin Yoga Class",
            "Rocket Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yogahaven_clapham",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "585e8749bf53cf553a04d4bc"
      },
      "lastUpdated": {
        "$date": "2018-01-18T04:30:04.972Z"
      },
      "createdAt": 1482590025415,
      "email": "marcusdowney4@hotmail.co.uk",
      "firstName": "Marcus",
      "surname": "Downey",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "d56df76a9bddf5ec603102fd523b32c45571e40e",
      "provider": "local",
      "salt": "+EoYDHi2NRGRfLiV4e7Vxw==",
      "hashedPassword": "ZbTCaQsLyUXo83gDVSChAEpdwqzCLABtYVE6PCeMqtNpz0rVG6qCoK+YkChWY0E09Q+VsrAEA9nwguLjTlj2MA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KSOFP4pg-Marcus%20gym.jpg"
        },
        "personal": {
          "disciplines": [
            "Mobile Trainer",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 4,
          "price": {
            "min": 45,
            "max": 55
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MyR3vlld-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.28403530000002775,
                51.3912536
              ],
              "city": "Surbiton",
              "postcode": "KT5 8PP",
              "_id": {
                "$oid": "5a6022cc765eba094646f78d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7760166629",
            "code": "+44"
          },
          "biography": "With 17 years in the fitness industry, 2 personal training qualifications and a recent degree in exercise, nutrition and health, I offer services that really make a difference. I have helped many clients achieve their physical goals and make it my priority to ensure the very best possible service is provided. \nMy time as a personal trainer has taught me \nthat no two clients are the same and I pride myself on working out the very best methods to train and motivate each individual. I really enjoy training people and always keep the sessions fun and upbeat.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "BSC Honours Exercise Nutrition and Health",
            "NASM Personal Trainer Award",
            "CYQ Nutrition Award"
          ]
        },
        "score": 54,
        "offers": null
      },
      "active": false,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "marcus_downey",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5862cc32bf53cf553a058424"
      },
      "lastUpdated": {
        "$date": "2018-01-18T04:27:20.459Z"
      },
      "createdAt": 1482869810976,
      "email": "good.health.advice1@gmail.com",
      "firstName": "Awaiz",
      "surname": "Farid",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "uEVvC57ND/PAftHIWPo76A==",
      "hashedPassword": "ZweyC4gZGbfrXkpvVXQtYUAT/lcjQKBOXo1NpjP5oBVKrNV4yH8A06eYjgUIpNkCvjpKEoyKOdIf3JlXn2Tmzw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3iE93YYX-ads.jpg"
        },
        "personal": {
          "disciplines": [
            "Mobile Trainer",
            "Nutrition",
            "Online Coaching",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 40,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GC0u8kcX-ads.jpg",
            "size": {},
            "offset": 0
          },
          "google": "https://plus.google.com/108645810576232029322",
          "biography": "My name is Awaiz, . I am a fully insured level 3 CYQ (Central YMCA Qualified) personal trainer, level 4 nutritional advisor, and certified first aider. I'm fully vetted and verified by REPs (Register of Exercise Professionals)member ship no-R0134602, http://www.exerciseregister.org/members/members-directory-listing as well as vetted and verified by NRPT (National Register of Personal Trainers) http://www.nrpt.co.uk/profiles/trainers/31078/awaiz-farid.htm. For the latest updates you can browse my facebook page https://www.facebook.com/awaizptnutritionist/ and for the testimonials from my clients browse my website. www.ptnutritionist.com",
          "strapLine1": "One of the best  home/outdoor personal trainer and nutritional advisor in London for fat loss, weight loss and tonning",
          "locations": [
            {
              "geolocation": [
                -0.19708330000003116,
                51.5437594
              ],
              "city": "London",
              "postcode": "NW6 2JG",
              "_id": {
                "$oid": "5a602228765eba094646f78b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7583102971",
            "code": "+44"
          },
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR"
          ]
        },
        "score": 66,
        "offers": null
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "awaiz_farid",
      "password_reset_time": 1495107534442,
      "password_reset_code": "X",
      "lastLogin": 1495107588321,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "586d14b065bbef097a956fc6"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:03:07.201Z"
      },
      "createdAt": 1483543728758,
      "email": "yogahavenislington@shoomon.xyz",
      "firstName": "Yogahaven",
      "surname": "information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "Zkk1tQuNxRYPvLvtM0Ul9w==",
      "hashedPassword": "Ghy/JDpcBuvFrmo0UlLzlpyoVc/4L9ajlt2k8fMYK3D6wBw/8RQqG9r0Yuj+0wpH0+6l/VHchRPr9EPrhWsuWQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wEGo88Pj-yogahavenlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Beginners Yoga",
            "Hot Yoga",
            "Rocket Yoga",
            "Vinyasa Flow Yoga",
            "Yoga Workshop"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.yogahaven.co.uk/london-yoga/islington/yoga",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/bzSzUqVA-yogahavenbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Yogahaven are pleased to offer a dedicated hot-yoga facility in Islington, London",
          "biography": "Welcome to Yogahaven Islington. We believe yoga is magical, invigorating and most importantly, fun – that’s why our unique style of hot yoga is called “leela”, meaning “play” in Sanskrit. Everyone is welcome to our studios, we have a wide variety of classes, suitable for all levels. You’ll find our spacious Islington studio on Pentonville Road, just five minutes stroll from Angel tube and seven minutes from King’s Cross station. Our studio is also conveniently located for anyone in the Highbury & Islington or Barnsbury areas. This is where our love for getting sweaty on the mat really shines.",
          "offers": "Please visit our website for full details of  prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.11273919999996451,
                51.5317704
              ],
              "city": "London",
              "postcode": "N1 9JD",
              "_id": {
                "$oid": "5a218b4ba95f7a0d7e4bda55"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Yogahaven",
          "phone": {
            "number": "2078331131",
            "code": "+44"
          },
          "qualifications": [
            "Hot Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Rocket Yoga Class",
            "Beginners Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yogahaven_islington",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "586d1b8665bbef097a957118"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:00:25.247Z"
      },
      "createdAt": 1483545478760,
      "email": "yogahavenrichmond@shoomon.xyz",
      "firstName": "Yogahaven",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "Cq9wDo+bfrLO16shKLpFaQ==",
      "hashedPassword": "qj9oERyuStFPspk/bJ1Os3GjEmBETjVs1/9cXGoRHOsBK0AojHLr13FCtppHNy+nOnu9FTDAWZkdKGweFG9hVA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0rKnzxrg-yogahavenlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Hot Yoga",
            "Jivamukti Yoga",
            "Rocket Yoga",
            "Vinyasa Flow Yoga",
            "Yin & Yang Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.yogahaven.co.uk/london-yoga/richmond/yoga",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RTCDiN1H-yogahavenbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Yogahaven are pleased to offer a dedicated hot-yoga facility in Richmond, London",
          "biography": "Come play on the mat at our yoga studio in Richmond, a minute's walk from the station. Feel at home in our fun and funky space - the lightest and brightest of the bunch - with fresh design, re-purposed vintage furnishings and a friendly yoga team. It’s a special studio created to cater to our Richmond yoga community, offering a wonderfully-sweaty schedule of hot-yoga classes and traditional non-hot styles too!  In 2003, Yogahaven set out to create a compassionate environment for students to develop their practice and enhance their wellbeing. Over the past decade we’ve been delighted to build strong communities that make practicing and teaching with yogahaven such a joy.",
          "offers": "Please visit our website for full details of  prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.3006550000000061,
                51.464125
              ],
              "city": "Richmond",
              "postcode": "TW9",
              "_id": {
                "$oid": "5a218aa9a95f7a0d7e4bda54"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Yogahaven",
          "phone": {
            "number": "2089482080",
            "code": "+44"
          },
          "qualifications": [
            "Hot Yoga Class",
            "Jivamukti Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Rocket Yoga Class",
            "Yin & Yang Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yogahaven_richmond",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "586d24ba65bbef097a9572da"
      },
      "lastUpdated": {
        "$date": "2017-11-28T19:44:44.433Z"
      },
      "createdAt": 1483547834669,
      "email": "yogaplacelondon@shoomon.xyz",
      "firstName": "Yogaplace",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "f2MEYrBhIR7AduZ5L6YKYA==",
      "hashedPassword": "1ROrc5JgnnNU3cx7e5rR2Ky7FGohxoar4ZlpyFvYeSJ7bv0QtwSp2WXpi+tVB5pK+XbTv0eDzTlRz8DXUg9qOQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/h1XjZYZb-yogaplacelondonlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Ashtanga Yoga",
            "Hatha Yoga",
            "Pregnancy Yoga",
            "Vinyasa Flow Yoga",
            "Yin Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.yogaplace.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": null,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cNio5B6t-yogaplacelondonbg.png",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Yoga Place E2 is a beautiful, light and spacious yoga studio, dedicated to the study and practice of yoga in London",
          "biography": "Located in the heart of Bethnal Green, East London, Yoga Place E2 offers sanctuary from the intensity of London life with a variety of yoga classes, courses and weekend workshops as well as holistic therapies.  Known for our welcoming and friendly atmosphere, our students range from complete beginners of all ages and backgrounds to more advanced yogis who’ve practised for years.  Our classes span the spectrum of yoga styles and include Ashtanga, Mysore Self-Practice, Dynamic, Hatha & Hatha Flow, Yin, Restorative, Flow & Restore, Gentle Yoga and Iyengar as well as Meditation and Pranayama Flow. We also offer Yoga Place Baby, for pre and post-natal classes, and yoga for Mums & Babies. And for the older yogis you can join the Autumnal yoga for a small donation.  Each class is taught by a fully qualified and experienced teacher who will always show sensitivity to the individual. They’ll take into account any recent or long-standing injuries to encourage working within the limits of your own body.  We always have an exciting programme of workshops and courses perfect for those looking to deepen their practice or try something new.  We are located in East London, near Bethnal Green tube and close to the City (one stop from Liverpool Street Station), and to the colourful markets of Brick Lane and Columbia Road.  If you are looking for yoga in London, we look forward to your visit here at one of the major centres of yoga in the UK where we have been sharing these wonderful practices.",
          "offers": "Please refer to our website for details of prices, packages and offers.",
          "yearsOfExp": null,
          "qualifications": [
            "Ashtanga Yoga Class",
            "Yin Yoga Class",
            "Vinyasa Flow Yoga Class",
            "Hatha Yoga Class",
            "Pregnancy Yoga Class",
            "Meditation Class",
            "Post Natal Yoga Class"
          ],
          "locations": [
            {
              "geolocation": [
                -0.05722349999996368,
                51.5275188
              ],
              "city": "London",
              "postcode": "E2 9QW",
              "_id": {
                "$oid": "5a1dbcacb1d6217c42dac0b2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Yoga Place",
          "phone": {
            "number": "2077395195",
            "code": "+44"
          }
        },
        "score": 60
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yoga_place",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "586d42d465bbef097a95788c"
      },
      "lastUpdated": {
        "$date": "2017-11-28T19:30:30.218Z"
      },
      "createdAt": 1483555540095,
      "email": "tempopilatesshoreditch@shoomon.xyz",
      "firstName": "TempoPilates",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "KXEiwOJalBF1heNvYenS7A==",
      "hashedPassword": "Lsd/KWhO/vd4g0wD/UnNs5hFXFN7XCVSZdvuDTp0ax2rDzvld8TwQ+ajE5+ORcVi0+tgSkG4LnzfdKZ0TxZdsQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EX47aAw7-tempopilateslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "HIIT Pilates",
            "Private Classes",
            "Reformer Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://tempopilates.com/tempo-pilates-london-e1-shoreditch/#schedule",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZzSylm4S-tempopilatesbg5.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "MUSIC + PILATES = TEMPO PILATES: We do Reformer Pilates to awesome music!",
          "biography": "WELCOME TO TEMPO PILATES:\n\nTempo Pilates is not just a sign on the door.\nIt’s a comprehensive reformer pilates method to music.  We use the Tempo to provide a pace which result in classes that flow, controlled and precise Mindbody movements each guided with a breath while encouraging centering and concentration.\nIt has a fresh, upbeat approach that’s fun, challenging and stylish that will transform your fitness classes from the hour you dread to the hour that leaves you riding high. Do you want to burn calories, tone muscles and sculpt your body? And do it while time flies? Then come and do it the Tempo way…",
          "offers": "Please visit our website for full details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.07270019999998567,
                51.5235934
              ],
              "city": "London",
              "postcode": "E1 6HR",
              "_id": {
                "$oid": "5a1db956b1d6217c42dac0b0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Tempo Pilates",
          "phone": {
            "number": "7798586925",
            "code": "+44"
          },
          "qualifications": [
            "HIIT Pilates Class",
            "Reformer Pilates Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "tempo_pilates_shoreditch",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "586d4acc65bbef097a9579de"
      },
      "lastUpdated": {
        "$date": "2018-11-16T10:26:43.165Z"
      },
      "createdAt": 1483557580724,
      "email": "tempopilateshackney@shoomon.xyz",
      "firstName": "TempoPilates",
      "surname": "Hackney",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "0DMMqXvKsmsxF1Ddjy+JJg==",
      "hashedPassword": "wvkGzdY92eMHGxgJgY8anknVaKgUdbFT4De6+Ux6EtGbVkXE11WT69BIeVndr3Fjk6duANsyl2Sq6Jr+Z9WxHA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/naCaENxL-tempopilateslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "HIIT Pilates",
            "Private Classes",
            "Reformer Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://tempopilates.com/tempo-pilates-london-e8-netil-house-london-fields/#schedule",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PWRZfDdX-tempopilatesbg5.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "biography": "WELCOME TO TEMPO PILATES:\n\nTempo Pilates is not just a sign on the door.\nIt’s a comprehensive reformer pilates method to music.  We use the Tempo to provide a pace which result in classes that flow, controlled and precise Mindbody movements each guided with a breath while encouraging centering and concentration.\nIt has a fresh, upbeat approach that’s fun, challenging and stylish that will transform your fitness classes from the hour you dread to the hour that leaves you riding high. Do you want to burn calories, tone muscles and sculpt your body? And do it while time flies? Then come and do it the Tempo way…",
          "strapLine1": "MUSIC + PILATES = TEMPO PILATES: We do Reformer Pilates to awesome music!",
          "offers": "Please visit our website for full details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.05830979999996089,
                51.5375635
              ],
              "city": "London",
              "postcode": "E8 3RL",
              "_id": {
                "$oid": "5bee9b63bf18d20974b3dc16"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Tempo Pilates Hackney",
          "phone": {
            "number": "7563578165",
            "code": "+44"
          },
          "qualifications": [
            "HIIT Pilates Class",
            "Reformer Pilates Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "tempo_pilates_hackney",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "586d51bc65bbef097a957b30"
      },
      "lastUpdated": {
        "$date": "2017-11-28T19:28:04.717Z"
      },
      "createdAt": 1483559356600,
      "email": "tempopilatescoventgarden@shoomon.xyz",
      "firstName": "TempoPilates",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "cX0LXRYXAUuSc+Y0H12K2A==",
      "hashedPassword": "dAZEF92lI6k3tmOKHtX/ki8Htfg10yzHPXElVePFOSFVH8jyPGFkNPXVS0Ay1AZWkI/gzJKxVXpBmegHoIvvZA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dCKNKLIO-tempopilateslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "HIIT Pilates",
            "Private Classes",
            "Reformer Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://tempopilates.com/tempo-pilates-london-wc2-gym-box-covent-garden/#schedule",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Rn1AM1Tm-tempopilatesbg5.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "MUSIC + PILATES = TEMPO PILATES: We do Reformer Pilates to awesome music!",
          "biography": "WELCOME TO TEMPO PILATES:\n\nTempo Pilates is not just a sign on the door.\nIt’s a comprehensive reformer pilates method to music.  We use the Tempo to provide a pace which result in classes that flow, controlled and precise Mindbody movements each guided with a breath while encouraging centering and concentration.\nIt has a fresh, upbeat approach that’s fun, challenging and stylish that will transform your fitness classes from the hour you dread to the hour that leaves you riding high. Do you want to burn calories, tone muscles and sculpt your body? And do it while time flies? Then come and do it the Tempo way…",
          "offers": "Please visit our website for full details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.12637199999994664,
                51.5105058
              ],
              "city": "London",
              "postcode": "WC2N 4EJ",
              "_id": {
                "$oid": "5a1db8c4b1d6217c42dac0ac"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Tempo Pilates",
          "phone": {
            "number": "7563578165",
            "code": "+44"
          },
          "qualifications": [
            "HIIT Pilates Class",
            "Reformer Pilates Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "tempo_pilates",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "586d5ae465bbef097a957cf2"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:56:20.624Z"
      },
      "createdAt": 1483561700380,
      "email": "bootcamppilatesnottinghill@shoomon.xyz",
      "firstName": "Notting Hill",
      "surname": "Reception",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "Bf3vepdDT8upDLYdRThFyA==",
      "hashedPassword": "KBhXEVBw8xvRQuKnd3agnN8terIElG8EPSL3C9hM06rTrQosNAuqI+brtYUOnH2kbKM+/dLRLfRFdlyp85FjtA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GUW6I5LA-bootcamppilateslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Private Classes",
            "Reformer Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.bootcamppilates.com/notting-hill/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kc6SQGQt-bootcamppilatesbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "biography": "Since setting up our first studio in 2005, Bootcamp Pilates has been a proud pioneer of Dynamic Reformer Pilates. We brought this revolutionary workout to London from the US and believe that it’s simply the best, fastest and safest way to transform you posture, strength and shape. Rather than mats, Dynamic Reformer Pilates classes involve exercising on Reformer machines, based on a century-old invention by Joseph Pilates himself. Imprisoned in a First World War camp, Pilates wanted to help bedridden fellow inmates keep their muscles moving and stop them wasting. Using patients’ hospital beds and bedsprings, plus any materials he could lay his hands on, he created a clever system of springs, straps and pulleys – enabling resistance exercise while lying down. Expertly designed and totally safe, our Reformer machines are essentially a sophisticated version of Pilates’ original design, providing a highly effective, no-impact stretching and toning workout. They are kind to your skeleton and joints, and help lengthen and strengthen your muscles. As for the dynamic part of our workout, you’ll find a Bootcamp Pilates class highly energetic, invigorating, inventive and enjoyable: something different every time and with a stimulating soundtrack to boot. As a result, it’s a fantastic way to lose weight and gain a more aligned, toned body in a matter of weeks.",
          "offers": "Reservations may be made 21 days in advance and you must cancel 12 hours in advance or you will be charged for the class. You must be physically present 5 minutes prior to the start of class or your spot will be given away and you will be charged for the class. Please visit our website for full details on our prices, packages and offers.",
          "strapLine1": "Fitter Leaner Stronger",
          "locations": [
            {
              "geolocation": [
                -0.1888235999999779,
                51.51829410000001
              ],
              "city": "London",
              "postcode": "W2 6ET",
              "_id": {
                "$oid": "5a2189b4a95f7a0d7e4bda53"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Bootcamp Pilates",
          "phone": {
            "number": "3300100210",
            "code": "+44"
          },
          "qualifications": [
            "Reformer Pilates Class",
            "Changing Facilities"
          ],
          "yearsOfExp": 2005,
          "testimonials": "“Bootcamp Pilates has really opened up my mind to the Pilates experience; it’s not for the faint-hearted, but you’ll feel those benefits and results very quickly! Tightening and strengthening your body and working parts that you didn’t even know existed. I’m a big fan.”\n\nCaggie Dunlop\nMade In Chelsea\n\n“Bootcamp Pilates really has changed not only my posture, core strength and leanness , it's also provided me with a new respect to what my body needs and requires to be strong and to stay in the best shape possible. Each class is different, all the instructors are outstanding! Making it a fun yet a thorough workout!”\n\nSophie Anderton\nModel\n\n“...........loved it! Perfect way to start the weekend! ”\n\nRosie Fortescue\nMade In Chelsea"
        },
        "score": 72
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bootcamp_pilates_S1F0f4ixz",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "586d605a65bbef097a957e0c"
      },
      "lastUpdated": {
        "$date": "2017-11-28T19:03:43.128Z"
      },
      "createdAt": 1483563098950,
      "email": "bootcamppilatesshoreditch@shoomon.xyz",
      "firstName": "Shoreditch",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "YyH5mpWPpX+E6G0YWjKZPQ==",
      "hashedPassword": "xo9jkPEyU41pmQkRvDGeqFCo2fBYdEkTuBj6Rv2mvBTFdm08ictf1eWzy3TUzf9Qu5MsKnhOH9UM28Kdgkxnjw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UgG3t46B-bootcamppilateslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Private Classes",
            "Reformer Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.bootcamppilates.com/shoreditch/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Lpwpe01o-bootcamppilatesbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "biography": "Since setting up our first studio in 2005, Bootcamp Pilates has been a proud pioneer of Dynamic Reformer Pilates. We brought this revolutionary workout to London from the US and believe that it’s simply the best, fastest and safest way to transform you posture, strength and shape. Rather than mats, Dynamic Reformer Pilates classes involve exercising on Reformer machines, based on a century-old invention by Joseph Pilates himself. Imprisoned in a First World War camp, Pilates wanted to help bedridden fellow inmates keep their muscles moving and stop them wasting. Using patients’ hospital beds and bedsprings, plus any materials he could lay his hands on, he created a clever system of springs, straps and pulleys – enabling resistance exercise while lying down. Expertly designed and totally safe, our Reformer machines are essentially a sophisticated version of Pilates’ original design, providing a highly effective, no-impact stretching and toning workout. They are kind to your skeleton and joints, and help lengthen and strengthen your muscles. As for the dynamic part of our workout, you’ll find a Bootcamp Pilates class highly energetic, invigorating, inventive and enjoyable: something different every time and with a stimulating soundtrack to boot. As a result, it’s a fantastic way to lose weight and gain a more aligned, toned body in a matter of weeks.",
          "strapLine1": "Fitter Leaner Stronger",
          "offers": "Reservations may be made 21 days in advance and you must cancel 12 hours in advance or you will be charged for the class.You must be physically present 5 minutes prior to the start of class or your spot will be given away and you will be charged for the class. Please visit our website for full details on our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.07066099999997277,
                51.5196473
              ],
              "city": "London",
              "postcode": "E1 5LP",
              "_id": {
                "$oid": "5a1db30fb1d6217c42dac0a3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Bootcamp Pilates Shoreditch",
          "phone": {
            "number": "3300100210",
            "code": "+44"
          },
          "qualifications": [
            "Reformer Pilates Class"
          ],
          "testimonials": "“Bootcamp Pilates has really opened up my mind to the Pilates experience; it’s not for the faint-hearted, but you’ll feel those benefits and results very quickly! Tightening and strengthening your body and working parts that you didn’t even know existed. I’m a big fan.”\n\nCaggie Dunlop\nMade In Chelsea\n\n“Bootcamp Pilates really has changed not only my posture, core strength and leanness , it's also provided me with a new respect to what my body needs and requires to be strong and to stay in the best shape possible. Each class is different, all the instructors are outstanding! Making it a fun yet a thorough workout!”\n\nSophie Anderton\nModel\n\n“...........loved it! Perfect way to start the weekend! ”\n\nRosie Fortescue\nMade In Chelsea",
          "yearsOfExp": 2005
        },
        "score": 72
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bootcamp_pilates_shoreditch",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "586d64f865bbef097a957eee"
      },
      "lastUpdated": {
        "$date": "2017-11-28T19:04:06.432Z"
      },
      "createdAt": 1483564280357,
      "email": "bootcamppilatesfulham@shoomon.xyz",
      "firstName": "Fulham",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "KE4iZIJzL+/YjM/HtprTJA==",
      "hashedPassword": "J5wqNv6sVkmf+xMdLgoK4ROL5f9wjbPWWC6Kp5DxV44DZOHiC21TQsUxCn+zhK8W5iNYAYv1rkUMUP1YjoHf6A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TMFaqybv-bootcamppilateslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Pregnancy Pilates",
            "Private Classes",
            "Reformer Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.bootcamppilates.com/fulham/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ddwt1k1H-bootcamppilatesbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Fitter Leaner Stronger",
          "biography": "Since setting up our first studio in 2005, Bootcamp Pilates has been a proud pioneer of Dynamic Reformer Pilates. We brought this revolutionary workout to London from the US and believe that it’s simply the best, fastest and safest way to transform you posture, strength and shape. Rather than mats, Dynamic Reformer Pilates classes involve exercising on Reformer machines, based on a century-old invention by Joseph Pilates himself. Imprisoned in a First World War camp, Pilates wanted to help bedridden fellow inmates keep their muscles moving and stop them wasting. Using patients’ hospital beds and bedsprings, plus any materials he could lay his hands on, he created a clever system of springs, straps and pulleys – enabling resistance exercise while lying down. Expertly designed and totally safe, our Reformer machines are essentially a sophisticated version of Pilates’ original design, providing a highly effective, no-impact stretching and toning workout. They are kind to your skeleton and joints, and help lengthen and strengthen your muscles. As for the dynamic part of our workout, you’ll find a Bootcamp Pilates class highly energetic, invigorating, inventive and enjoyable: something different every time and with a stimulating soundtrack to boot. As a result, it’s a fantastic way to lose weight and gain a more aligned, toned body in a matter of weeks.",
          "offers": "Reservations may be made 21 days in advance and you must cancel 12 hours in advance or you will be charged for the class. You must be physically present 5 minutes prior to the start of class or your spot will be given away and you will be charged for the class. Please visit our website for full details on our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.21131230000003143,
                51.4710961
              ],
              "city": "London",
              "postcode": "SW6 3LQ",
              "_id": {
                "$oid": "5a1db326b1d6217c42dac0a4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Bootcamp Pilates",
          "phone": {
            "number": "3300100210",
            "code": "+44"
          },
          "qualifications": [
            "Pregnancy Pilates Class",
            "Reformer Pilates Class",
            "Changing Facilities"
          ],
          "testimonials": "“Bootcamp Pilates has really opened up my mind to the Pilates experience; it’s not for the faint-hearted, but you’ll feel those benefits and results very quickly! Tightening and strengthening your body and working parts that you didn’t even know existed. I’m a big fan.”\n\nCaggie Dunlop\nMade In Chelsea\n\n“Bootcamp Pilates really has changed not only my posture, core strength and leanness , it's also provided me with a new respect to what my body needs and requires to be strong and to stay in the best shape possible. Each class is different, all the instructors are outstanding! Making it a fun yet a thorough workout!”\n\nSophie Anderton\nModel\n\n“...........loved it! Perfect way to start the weekend! ”\n\nRosie Fortescue\nMade In Chelsea",
          "yearsOfExp": 2005
        },
        "score": 72
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bootcamp_pilates",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "586d699765bbef097a957fd0"
      },
      "lastUpdated": {
        "$date": "2017-11-28T19:04:36.893Z"
      },
      "createdAt": 1483565463781,
      "email": "bootcamppilateswindsor@shoomon.xyz",
      "firstName": "Windsor",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "+Y5ADkksTQRq6BCWenTDFg==",
      "hashedPassword": "JqfinD++6jFwPlhHcUU8xNUuvt37srTWQvv36e5A/XLwGzcAeFz40QdOzEpMTPEQtHd0WES3KfGlbRC3l0SIng==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VWrQ4gXG-bootcamppilateslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Private Classes",
            "Reformer Pilates",
            "Vinyasa Flow Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.bootcamppilates.com/windsor/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/39LT95iw-bootcamppilatesbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Fitter Leaner Stronger",
          "biography": "Since setting up our first studio in 2005, Bootcamp Pilates has been a proud pioneer of Dynamic Reformer Pilates. We brought this revolutionary workout to London from the US and believe that it’s simply the best, fastest and safest way to transform you posture, strength and shape. Rather than mats, Dynamic Reformer Pilates classes involve exercising on Reformer machines, based on a century-old invention by Joseph Pilates himself. Imprisoned in a First World War camp, Pilates wanted to help bedridden fellow inmates keep their muscles moving and stop them wasting. Using patients’ hospital beds and bedsprings, plus any materials he could lay his hands on, he created a clever system of springs, straps and pulleys – enabling resistance exercise while lying down. Expertly designed and totally safe, our Reformer machines are essentially a sophisticated version of Pilates’ original design, providing a highly effective, no-impact stretching and toning workout. They are kind to your skeleton and joints, and help lengthen and strengthen your muscles. As for the dynamic part of our workout, you’ll find a Bootcamp Pilates class highly energetic, invigorating, inventive and enjoyable: something different every time and with a stimulating soundtrack to boot. As a result, it’s a fantastic way to lose weight and gain a more aligned, toned body in a matter of weeks.",
          "offers": "Reservations may be made 21 days in advance and you must cancel 12 hours in advance or you will be charged for the class. You must be physically present 5 minutes prior to the start of class or your spot will be given away and you will be charged for the class. Please visit our website for full details on our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.614960500000052,
                51.4832395
              ],
              "city": "Windsor",
              "postcode": "SL4 3HU",
              "_id": {
                "$oid": "5a1db344b1d6217c42dac0a5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Bootcamp Pilates Windsor",
          "phone": {
            "number": "1753387333",
            "code": "+44"
          },
          "qualifications": [
            "Reformer Pilates Class",
            "Vinyasa Flow Yoga Class",
            "Changing Facilities"
          ],
          "testimonials": "“Bootcamp Pilates has really opened up my mind to the Pilates experience; it’s not for the faint-hearted, but you’ll feel those benefits and results very quickly! Tightening and strengthening your body and working parts that you didn’t even know existed. I’m a big fan.”\n\nCaggie Dunlop\nMade In Chelsea\n\n“Bootcamp Pilates really has changed not only my posture, core strength and leanness , it's also provided me with a new respect to what my body needs and requires to be strong and to stay in the best shape possible. Each class is different, all the instructors are outstanding! Making it a fun yet a thorough workout!”\n\nSophie Anderton\nModel\n\n“...........loved it! Perfect way to start the weekend! ”\n\nRosie Fortescue\nMade In Chelsea",
          "yearsOfExp": 2005
        },
        "score": 72
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bootcamp_pilates_windsor",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "586d6c3b65bbef097a958042"
      },
      "lastUpdated": {
        "$date": "2017-11-28T19:03:00.750Z"
      },
      "createdAt": 1483566139451,
      "email": "bootcamppilatesrichmond@shoomon.xyz",
      "firstName": "Richmond",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "15qojzH1iTrKSy+fAubxJA==",
      "hashedPassword": "aVDcouDgQOZ2JAMnmcYNYKHc4mIH5LllC0KOM+lyeKshqThDypl8lbX9Vd2MG/PySJQaFm06ZdG6UYtB+ebciw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OOwVRd37-bootcamppilateslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Private Classes",
            "Reformer Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.bootcamppilates.com/richmond/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PMo6Yvep-bootcamppilatesbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Fitter Leaner Stronger",
          "biography": "Since setting up our first studio in 2005, Bootcamp Pilates has been a proud pioneer of Dynamic Reformer Pilates. We brought this revolutionary workout to London from the US and believe that it’s simply the best, fastest and safest way to transform you posture, strength and shape. Rather than mats, Dynamic Reformer Pilates classes involve exercising on Reformer machines, based on a century-old invention by Joseph Pilates himself. Imprisoned in a First World War camp, Pilates wanted to help bedridden fellow inmates keep their muscles moving and stop them wasting. Using patients’ hospital beds and bedsprings, plus any materials he could lay his hands on, he created a clever system of springs, straps and pulleys – enabling resistance exercise while lying down. Expertly designed and totally safe, our Reformer machines are essentially a sophisticated version of Pilates’ original design, providing a highly effective, no-impact stretching and toning workout. They are kind to your skeleton and joints, and help lengthen and strengthen your muscles. As for the dynamic part of our workout, you’ll find a Bootcamp Pilates class highly energetic, invigorating, inventive and enjoyable: something different every time and with a stimulating soundtrack to boot. As a result, it’s a fantastic way to lose weight and gain a more aligned, toned body in a matter of weeks.",
          "offers": "Reservations may be made 21 days in advance and you must cancel 12 hours in advance or you will be charged for the class. You must be physically present 5 minutes prior to the start of class or your spot will be given away and you will be charged for the class. Please visit our website for full details on our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.30562639999993735,
                51.4594219
              ],
              "city": "Richmond",
              "postcode": "TW9 1RW",
              "_id": {
                "$oid": "5a1db2e4b1d6217c42dac0a2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Boot Camp Pilates",
          "phone": {
            "number": "3300100210",
            "code": "+44"
          },
          "qualifications": [
            "Reformer Pilates Class"
          ],
          "testimonials": "“Bootcamp Pilates has really opened up my mind to the Pilates experience; it’s not for the faint-hearted, but you’ll feel those benefits and results very quickly! Tightening and strengthening your body and working parts that you didn’t even know existed. I’m a big fan.”\n\nCaggie Dunlop\nMade In Chelsea\n\n“Bootcamp Pilates really has changed not only my posture, core strength and leanness , it's also provided me with a new respect to what my body needs and requires to be strong and to stay in the best shape possible. Each class is different, all the instructors are outstanding! Making it a fun yet a thorough workout!”\n\nSophie Anderton\nModel\n\n“...........loved it! Perfect way to start the weekend! ”\n\nRosie Fortescue\nMade In Chelsea",
          "yearsOfExp": 2005
        },
        "score": 72
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "boot_camp_pilates",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "586d72c165bbef097a958193"
      },
      "lastUpdated": {
        "$date": "2018-01-18T04:26:20.641Z"
      },
      "createdAt": 1483567809119,
      "providerId": "10153962738286152",
      "provider": "facebook",
      "surname": "Hirst",
      "firstName": "Matthew",
      "email": "hirst_matt@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10153962738286152/",
          "disciplines": [
            "Boxing",
            "Functional Training",
            "Mobile Trainer",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "min": 45,
            "max": 75
          },
          "strapLine1": "Over 15 years experience as an elite personal trainer and professional boxing coach",
          "biography": "I've been working at one of the most exclusive gyms in the UK for 10 years working with celebrities to CEO's. Specialised in body transformation, rehab and sports specific training.",
          "yearsOfExp": 2002,
          "offers": "upon request",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6UFj3tdH-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.34634089999997286,
                51.54232249999999
              ],
              "city": "Greenford",
              "postcode": "UB6 8PX",
              "_id": {
                "$oid": "5a6021ec765eba094646f78a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7734776313",
            "code": "+44"
          },
          "qualifications": [
            "Level 3: Personal Trainer"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7jWtTfQl-filename.jpeg"
        },
        "score": 71
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "matthew_hirst",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "586efda22be2985c1e90d9ed"
      },
      "lastUpdated": {
        "$date": "2017-12-01T18:27:48.597Z"
      },
      "createdAt": 1483668898872,
      "email": "lauren.providence@gmail.com",
      "firstName": "Lauren",
      "surname": "Chaplin Providence",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "fd520fa91f8ed04be9da529a7214034ba4b52202",
      "provider": "local",
      "salt": "iCnPLm8bRvDd1FqguEnc6A==",
      "hashedPassword": "AkhU8tb/tm09UvNT9grzz8McTuwYmTkGj6jbdvhS4i8AYJyX432P+/BCgA1i9E7c9iSdCsBhXS+HTEvDmgasJA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4huIeeDG-IMG_20161127_235959.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet/Meal Plans",
            "Online Support",
            "Personal training",
            "Personalised Programmes",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://imapfitness.efitnesstracker.com/",
          "profileTitle": "Fitness Trainer Group",
          "radius": 5,
          "price": {
            "min": 0,
            "max": 125
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Reik4HrM-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -87.62979819999998,
                41.8781136
              ],
              "city": "Chicago",
              "postcode": "60604",
              "_id": {
                "$oid": "5a219f24a95f7a0d7e4bda6c"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "companyName": "FORT PROVIDENCE FITNESS NETWORK (dba Rxercise)",
          "phone": {
            "number": "7737663300",
            "code": "+1"
          },
          "biography": "Welcome To Fort Providence Fitness!\n\nFort Providence Fitness is a provenly effective Online Personal Fitness Solution that provides men and women an Online Fitness Program and Online Meal Plan Solution.\n\nGet in shape, build muscle, burn fat, and lose weight with your very own Online Personal Trainer.\n\nFort Providence Fitness gives you the Personal Trainer Support you need from our Certified Personal Fitness Trainers as you track your personal fitness training progress.\n\nTake our FREE Fitness Analysis or dive right in by choosing one of our custom designed Online Fitness Training Plans:",
          "qualifications": [
            "Online Personal Trainer",
            "Customised Fitness Plans"
          ],
          "strapLine1": "The Online Personal Fitness Solution",
          "offers": "Please visit our website for details of prices, packages & offers.",
          "testimonials": "I started college last year and gained more weight than I was comfortable with. Thanks Lauren for helping me to lose my \"freshmen 15\"...\n\n                                                                                                                                                   Courteney K.....Bowie, Maryland"
        },
        "score": 68
      },
      "active": false,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fort_providence_fitness_network_(dba_rxercise)",
      "feature_in_area": "none",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "5873b0402be2985c1e91ba9b"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:43:06.707Z"
      },
      "createdAt": 1483976768551,
      "email": "tenhealth&fitness@shoomon.xyz",
      "firstName": "Notting Hill",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "UiPHD/Nnil/0u/YhM/qlsA==",
      "hashedPassword": "VK+baZcHyeI16SzGJTsic9H+uALqG4Vmqb701Mnem8e97UfLvDml0YE8XPwQSLqrypMg3i+QSbk1ZFqjcBkWzg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/x2hBCwtD-tenhealthandfitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Pregnancy Pilates",
            "Remedial Pilates",
            "Small Group Yoga",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.ten.co.uk/our_studios/notting_hill/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hGoiqO9F-tenhealthandfitnessbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Pilates, Physiotherapy, Sports Massage",
          "biography": "A few minutes from Ladbroke Grove, Ten’s light, bright Notting Hill Studio offers the full breadth of services, from Dynamic Pilates to Personal Training, TRX to Physiotherapy. As Ten’s HQ, it’s also our biggest set-up, with 3 studios, 3 therapy rooms and a gym. At Ten, it’s all about celebrating endorphins. We love the feeling you get at the end of a really good workout, and we love being able to see the benefits afterwards.  Everything we do at Ten is designed to help us achieve these goals. So our Trainers are among the most expert (and the most highly trained) you’ll find anywhere. They’re chosen as much for their attitude and approach as for their expertise.  Our Studios are large, light and welcoming. We offer a range of classes to suit all levels of fitness and experience – and never with more than 10 people in a session. In addition, our Personal Training programmes allow you to enjoy the privacy and individual attention of a one-on-one session, and benefit from a workout designed for your specific needs and goals. We also have our own in-house Physiotherapists (we’re recognised by all the major private health insurance companies) and Sports Massage Therapists.  Being part of the Ten experience couldn’t be easier. We make it easy to train when you want, how you want. We’re open seven days a week (weekdays, from early to late) - and we don’t charge membership or joining fees.",
          "offers": "Please visit out website for full details on our prices, packages and offers.\n\nOpening hours:\n\nMon - Thur: 0700 - 2100\nFri: 0700 - 2000\nSat: 0800 - 1800\nSun: 0900 - 1800",
          "locations": [
            {
              "geolocation": [
                -0.21744749999993473,
                51.5234986
              ],
              "city": "London",
              "postcode": "W10 6BD",
              "_id": {
                "$oid": "5a21869aa95f7a0d7e4bda4f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ten Health & Fitness Studio",
          "phone": {
            "number": "2089699677",
            "code": "+44"
          },
          "qualifications": [
            "Beginners Pilates",
            "Intermediate Pilates",
            "Advanced Pilates",
            "Mat Pilates Class",
            "Reformer Pilates Class",
            "Pregnancy Pilates Class",
            "Cardiolates",
            "Stretch & Flexibility Class",
            "Remedial Pilates",
            "Sports Massage",
            "Physiotherapy",
            "HIIT",
            "Barre"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ten_health_and_fitness_studio_SkD5rWybf",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5873b6eb2be2985c1e91bbb5"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:35:06.928Z"
      },
      "createdAt": 1483978475637,
      "email": "tenhealth&fitnesschiswick@shoomon.xyz",
      "firstName": "Chiswick",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "MCXahQrzgO3RGntX/lWiIw==",
      "hashedPassword": "H9RG/Ox1SH8UwRBJ6z6OSepARwQBDPEON79VFr3L5PD8cP3jK8GhZ6yKjmFc5s+JR1FeOiZ/tuPV+CuI0HbKFw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8H5HEeUt-tenhealthandfitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Pregnancy Pilates",
            "Private Pilates Class",
            "Reformer Pilates",
            "Small Group Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.ten.co.uk/our_studios/chiswick/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/y4hL6HB9-tenhealthandfitnessbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "biography": "Big industrial windows and high ceilings give Ten Chiswick a glorious feeling of space. At the heart of Turnham Green and close to Chiswick High Road, we’re very much part of the Chiswick community. At Ten, it’s all about celebrating endorphins. We love the feeling you get at the end of a really good workout, and we love being able to see the benefits afterwards. Everything we do at Ten is designed to help us achieve these goals. So our Trainers are among the most expert (and the most highly trained) you’ll find anywhere. They’re chosen as much for their attitude and approach as for their expertise.  Our Studios are large, light and welcoming. We offer a range of classes to suit all levels of fitness and experience – and never with more than 10 people in a session. In addition, our Personal Training programmes allow you to enjoy the privacy and individual attention of a one-on-one session, and benefit from a workout designed for your specific needs and goals. We also have our own in-house Physiotherapists (we’re recognised by all the major private health insurance companies) and Sports Massage Therapists.  Being part of the Ten experience couldn’t be easier. We make it easy to train when you want, how you want. We’re open seven days a week (weekdays, from early to late) - and we don’t charge membership or joining fees.",
          "strapLine1": "Pilates, Physiotherapy, Personal Training",
          "offers": "Please visit out website for full details on our prices, packages and offers.\n\nOpening hours:\n\nMon - Thurs: 0700 - 2100\nFri: 0700 - 2000\nSat: 0800 - 1700\nSun: 0800 - 1600",
          "locations": [
            {
              "geolocation": [
                -0.26176810000004025,
                51.4918451
              ],
              "city": "London",
              "postcode": "W4",
              "_id": {
                "$oid": "5a2184baa95f7a0d7e4bda49"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ten Health & Fitness Studio",
          "phone": {
            "number": "2089961710",
            "code": "+44"
          },
          "qualifications": [
            "Beginners Pilates",
            "Intermediate Pilates",
            "Advanced Pilates",
            "Mat Pilates Class",
            "Reformer Pilates Class",
            "Pregnancy Pilates Class",
            "Cardiolates",
            "Stretch & Flexibility Class",
            "Remedial Pilates",
            "Sports Massage",
            "Physiotherapy"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ten_health_and_fitness_studio_SJXaVWJWM",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "5873b9f62be2985c1e91bc5f"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:34:46.258Z"
      },
      "createdAt": 1483979254428,
      "email": "tenhealth&fitnessstjames's@shoomon.xyz",
      "firstName": "St James's",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "6rytWiK6NVUQovU6DyNxWQ==",
      "hashedPassword": "h9Ml9kO3dQO7BsAeY30PZ0udW33gHurLYgnOaUMizukQVKs1Qr2pr3M5bk1Y0Le0bkLwAiPvg0P/H8IYp/oHOg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xQAW4gzP-tenhealthandfitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Pregnancy Pilates",
            "Private Pilates Class",
            "Reformer Pilates",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.ten.co.uk/our_studios/st-james/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/bkjEE5d5-tenhealthandfitnessbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Pilates, HIIT, Barre",
          "biography": "Our most recently refurbished, redesigned and refitted studio. Smart but buzzy and welcoming, Ten St James’s is ideally placed for the Royal Academy (for some post-workout culture) and a scone’s throw from Fortnums (for some post-workout cake). At Ten, it’s all about celebrating endorphins. We love the feeling you get at the end of a really good workout, and we love being able to see the benefits afterwards.  Everything we do at Ten is designed to help us achieve these goals. So our Trainers are among the most expert (and the most highly trained) you’ll find anywhere. They’re chosen as much for their attitude and approach as for their expertise.  Our Studios are large, light and welcoming. We offer a range of classes to suit all levels of fitness and experience – and never with more than 10 people in a session. In addition, our Personal Training programmes allow you to enjoy the privacy and individual attention of a one-on-one session, and benefit from a workout designed for your specific needs and goals. We also have our own in-house Physiotherapists (we’re recognised by all the major private health insurance companies) and Sports Massage Therapists.  Being part of the Ten experience couldn’t be easier. We make it easy to train when you want, how you want. We’re open seven days a week (weekdays, from early to late) - and we don’t charge membership or joining fees.",
          "offers": "Please visit out website for full details on our prices, packages and offers.\n\nOpening hours:\n\nMon - Thurs: 0700 - 2115\nFri: 0700 - 2015\nSat: 0900 - 1500\nSun: 0900 - 1600",
          "locations": [
            {
              "geolocation": [
                -0.13738720000003468,
                51.5072011
              ],
              "city": "London",
              "postcode": "SW1Y 6BN",
              "_id": {
                "$oid": "5a2184a6a95f7a0d7e4bda48"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ten Health & Fitness Studio",
          "phone": {
            "number": "2079304442",
            "code": "+44"
          },
          "qualifications": [
            "Beginners Pilates",
            "Intermediate Pilates",
            "Advanced Pilates",
            "Mat Pilates Class",
            "Reformer Pilates Class",
            "Pregnancy Pilates Class",
            "Stretch & Flexibility Class",
            "Remedial Pilates",
            "Sports Massage",
            "Physiotherapy"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ten_health_and_fitness_studio_rJ0iE-J-z",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5873bdeb2be2985c1e91bd09"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:34:23.658Z"
      },
      "createdAt": 1483980267508,
      "email": "tenhealth&fitnessmayfair@shoomon.xyz",
      "firstName": "Ten Mayfair",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "+CuTTM2wM8DiP/jR9w8Kng==",
      "hashedPassword": "I8o8Fu1tDWz6bdHQESJJa6oAbQ/UATR4jPExAai/I3PNgBeIYseySRvO8b0V93sWUTDTlBlPTJvoh8B/XCcheQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/c0MMwtbH-tenhealthandfitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Personalised Programme",
            "Reformer Pilates",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.ten.co.uk/our_studios/mayfair/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fSmbUdt0-tenhealthandfitnessbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Pilates, Physiotherapy, Personal Training",
          "biography": "Located on one of London’s grandest and best-known squares, Ten Mayfair is opposite Vogue House (perhaps it’s no surprise that many of its occupants are regulars with us). A welcome retreat from the bustle of Oxford Street, and handy for Bond Street and some post-workout retail therapy. At Ten, it’s all about celebrating endorphins. We love the feeling you get at the end of a really good workout, and we love being able to see the benefits afterwards. Everything we do at Ten is designed to help us achieve these goals. So our Trainers are among the most expert (and the most highly trained) you’ll find anywhere. They’re chosen as much for their attitude and approach as for their expertise.  Our Studios are large, light and welcoming. We offer a range of classes to suit all levels of fitness and experience – and never with more than 10 people in a session. In addition, our Personal Training programmes allow you to enjoy the privacy and individual attention of a one-on-one session, and benefit from a workout designed for your specific needs and goals. We also have our own in-house Physiotherapists (we’re recognised by all the major private health insurance companies) and Sports Massage Therapists.  Being part of the Ten experience couldn’t be easier. We make it easy to train when you want, how you want. We’re open seven days a week (weekdays, from early to late) - and we don’t charge membership or joining fees.",
          "offers": "Please visit out website for full details on our prices, packages and offers.\n\nOpening hours:\n\nMon - Thur: 0700 - 2100\nFri: 0700 - 2000\nSat: 0800 - 1600\nSun: 0900 - 1600",
          "locations": [
            {
              "geolocation": [
                -0.14389759999994567,
                51.51330230000001
              ],
              "city": "London",
              "postcode": "W1S",
              "_id": {
                "$oid": "5a21848fa95f7a0d7e4bda47"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ten Health & Fitness Studio",
          "phone": {
            "number": "2074958642",
            "code": "+44"
          },
          "qualifications": [
            "Beginners Pilates",
            "Intermediate Pilates",
            "Advanced Pilates",
            "Reformer Pilates Class",
            "Mat Pilates Class",
            "Pregnancy Pilates Class",
            "Stretch & Flexibility Class",
            "Remedial Pilates",
            "Sports Massage",
            "Physiotherapy"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ten_health_and_fitness_studio_rkOqV-k-M",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "5873c0822be2985c1e91bdb3"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:33:58.308Z"
      },
      "createdAt": 1483980930049,
      "email": "tenhealth&fitnesshattongarden@shoomon.xyz",
      "firstName": "HattonGarden",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "GTyN+3MW15o5t3LruC6kgA==",
      "hashedPassword": "qqM+Nvi6+RP5Vag/hIdCi6HneZwmmP7AUOwPQ7MHrJ2nXaYjXTuiC851Be0DP4Bk0hZTzrTkKKXoYCVTb+UNtQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oxYVbWRf-tenhealthandfitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Pregnancy Pilates",
            "Reformer Pilates",
            "Sports Therapy & Rehab",
            "Studio Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.ten.co.uk/our_studios/hatton-garden/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aRyOdjDX-tenhealthandfitnessbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Pilates, HIIT, Personal Training",
          "biography": "Exposed brickwork, oak floors and clever use of natural light makes Ten Hatton Garden a welcoming contemporary space. Close to Farringdon and Chancery Lane tubes, it’s on the Clerkenwell borders, in the heart of Midtown – one of the fastest-developing and buzziest areas of London. At Ten, it’s all about celebrating endorphins. We love the feeling you get at the end of a really good workout, and we love being able to see the benefits afterwards. Everything we do at Ten is designed to help us achieve these goals. So our Trainers are among the most expert (and the most highly trained) you’ll find anywhere. They’re chosen as much for their attitude and approach as for their expertise.  Our Studios are large, light and welcoming. We offer a range of classes to suit all levels of fitness and experience – and never with more than 10 people in a session. In addition, our Personal Training programmes allow you to enjoy the privacy and individual attention of a one-on-one session, and benefit from a workout designed for your specific needs and goals. We also have our own in-house Physiotherapists (we’re recognised by all the major private health insurance companies) and Sports Massage Therapists.  Being part of the Ten experience couldn’t be easier. We make it easy to train when you want, how you want. We’re open seven days a week (weekdays, from early to late) - and we don’t charge membership or joining fees.",
          "offers": "Please visit out website for full details on our prices, packages and offers.\n\nOpening hours: \n\nMon – Thur: 0700 – 2115\nFri: 0700 - 2015\nSat: 0930 - 1500\nSun: 0900 - 1430",
          "locations": [
            {
              "geolocation": [
                -0.10809879999999339,
                51.52023909999999
              ],
              "city": "London",
              "postcode": "EC1N 8EB",
              "_id": {
                "$oid": "5a218476a95f7a0d7e4bda46"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ten Health & Fitness Studio",
          "phone": {
            "number": "2074048132",
            "code": "+44"
          },
          "qualifications": [
            "Beginners Pilates",
            "Intermediate Pilates",
            "Advanced Pilates",
            "Reformer Pilates Class",
            "Mat Pilates Class",
            "Stretch & Flexibility Class",
            "Remedial Pilates",
            "Pregnancy Pilates Class",
            "Sports Massage",
            "Physiotherapy",
            "HIIT"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ten_health_and_fitness_studio_SJRdNbyWM",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5873c2d12be2985c1e91be25"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:33:13.648Z"
      },
      "createdAt": 1483981521426,
      "email": "tenhealth&fitnessthecity@shoomon.xyz",
      "firstName": "Ten The City",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "VVqNf9wSSnhvStz+LI6abQ==",
      "hashedPassword": "bE0LhZhl0lTzpYRxuQIM0//ldxqVjn+VUKH71+0HgSESHadfDB4Q7Wn7h/PmEJQOgN+MBBLkPxiAQobo/lZlRw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0vbuKYk7-tenhealthandfitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Private Pilates Class",
            "Small Group Yoga",
            "Sports Therapy & Rehab",
            "Studio Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.ten.co.uk/our_studios/city/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kW77B0x2-tenhealthandfitnessbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "biography": "Just minutes from Liverpool Street and Spitalfields Market, you’ll find Ten City in a pretty period building. Our newest Studio spans three floors, with a large sunlit studio and a double-height top-floor gym. At Ten, it’s all about celebrating endorphins. We love the feeling you get at the end of a really good workout, and we love being able to see the benefits afterwards. Everything we do at Ten is designed to help us achieve these goals. So our Trainers are among the most expert (and the most highly trained) you’ll find anywhere. They’re chosen as much for their attitude and approach as for their expertise.  Our Studios are large, light and welcoming. We offer a range of classes to suit all levels of fitness and experience – and never with more than 10 people in a session. In addition, our Personal Training programmes allow you to enjoy the privacy and individual attention of a one-on-one session, and benefit from a workout designed for your specific needs and goals. We also have our own in-house Physiotherapists (we’re recognised by all the major private health insurance companies) and Sports Massage Therapists.  Being part of the Ten experience couldn’t be easier. We make it easy to train when you want, how you want. We’re open seven days a week (weekdays, from early to late) - and we don’t charge membership or joining fees.",
          "strapLine1": "Pilates, Sports Massage, Personal Training",
          "offers": "Please visit out website for full details on our prices, packages and offers.\n\nOpening hours:\n\nMon: 0700 - 2115\nTues: 0700 - 2115\nWed 0700 - 2115\nThurs: 0700 - 2015\nFri: 0700 - 2000\nSat: 0900 - 1600\nSun: 0900 - 1600",
          "locations": [
            {
              "geolocation": [
                -0.07861000000002605,
                51.51777240000001
              ],
              "city": "London",
              "postcode": "E1 7JF",
              "_id": {
                "$oid": "5a218449a95f7a0d7e4bda45"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ten Health & Fitness Studio",
          "phone": {
            "number": "2072478895",
            "code": "+44"
          },
          "qualifications": [
            "Beginners Pilates",
            "Intermediate Pilates",
            "Advanced Pilates",
            "Pregnancy Pilates Class",
            "Reformer Pilates Class",
            "Remedial Pilates",
            "Mat Pilates Class",
            "Stretch & Flexibility Class",
            "Sports Massage",
            "Physiotherapy",
            "HIIT"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ten_health_and_fitness_studio_ryMI4byWG",
      "feature_in_area": "none",
      "__v": 16
    },
    {
      "_id": {
        "$oid": "5873c4db2be2985c1e91be5f"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:32:37.634Z"
      },
      "createdAt": 1483982043375,
      "email": "tenhealth&fitnesslittlevenice@shoomon.xyz",
      "firstName": "LittleVenice",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "7D1Bh/j3f31Fz3SlqM8E7A==",
      "hashedPassword": "qPlk0vFwAhRKjs5Z3R7RXSbtBIdBl4s+6f7C/6K4LVvDXlNQZl6E/imEOn5LKLwsc9eUVgJxWCs2PmB8ij3afw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HHvlh5Af-tenhealthandfitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Post Natal Pilates",
            "Pregnancy Pilates",
            "Private Pilates Class",
            "Sports Therapy & Rehab",
            "Studio Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.ten.co.uk/our_studios/little-venice-pilates-physiotherapy/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/CBIAvCXl-tenhealthandfitnessbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "biography": "Ten’s Little Venice Pilates Studio and Physiotherapy Clinic is perfectly positioned for anyone living in West London including Maida Vale, St John’s Wood and Paddington Basin. The premises are located a few minutes walk from Warwick Avenue tube (Bakerloo line), opposite the entrance to Clifton Nurseries.  Previously independently operated we welcomed Little Venice into the Ten family in 2016. The balance of an integrated clinical approach with a warm and friendly environment made them the perfect fit with existing Ten studios.  Expect the same calibre of welcoming and expert Physiotherapists and Sports Massage Therapists as you will find in any Ten Physiotherapy Clinic plus a specialist focus on Dance and Sports Injury Management.  Find out more about Physiotherapy at Little Venice. Meanwhile with no more than four people per class, a focus on one-to-one sessions, and personalised programmes for all clients, Little Venice’s Pilates programme, alongside Physio-Led Pilates, is the most specialised Pilates offer at Ten with all sessions clinically supervised.",
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nOpening hours:\n\nMon - Thur: 0900 - 1300, 1730-2000\nFri: 0900 - 1300 \nSat: 0900 - 1700",
          "strapLine1": "Pilates & Physiotherapy",
          "locations": [
            {
              "geolocation": [
                -0.186166500000013,
                51.5231359
              ],
              "city": "London",
              "postcode": "W9 2JQ",
              "_id": {
                "$oid": "5a218425a95f7a0d7e4bda44"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ten Health & Fitness Studio",
          "phone": {
            "number": "2072865000",
            "code": "+44"
          },
          "qualifications": [
            "Sports Massage",
            "Physiotherapy",
            "Osteopathy",
            "Mat Pilates Class",
            "Reformer Pilates Class",
            "Cadillac Pilates Class",
            "Wunda Chair Pilates",
            "Pregnancy Pilates Class",
            "Post Natal Pilates Class"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ten_health_and_fitness_studio",
      "feature_in_area": "none",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "5873e4552be2985c1e91c44b"
      },
      "lastUpdated": {
        "$date": "2017-11-22T16:19:51.967Z"
      },
      "createdAt": 1483990101780,
      "email": "urbankings@shoomon.xyz",
      "firstName": "Urban Kings",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "XDfrIyw0ooccVbkYJrn/Mw==",
      "hashedPassword": "YETmJKn59a39F4eud0RcRd2vkHdTxJ4lpZOsREEH5V6sEb8YIWzCAhySKDZhXk1M+GVSz0Mq36XhJdHqqpWQXw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/liItdqqU-urbankingslogo.png"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Brazilian Jiu-jitsu",
            "Capoeira",
            "MMA (Mixed Martial Arts)",
            "Muay Thai"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://urbankingsgym.com/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YCTbS8q1-urbankingsbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Welcome to London's premier Mixed Martial Arts gym  and specialist-training facility.",
          "biography": "Welcome to Urban Kings Gym London's Premier MMA Gym and Specialist Training Facility.  We provide a wide array of combat disciplines such as Boxing, Thai Boxing, Capoeira, Conditioning, MMA, and Brazilian Jiu Jitsu. Combat disciplines are a great way to get in shape while learning a skill which will increase self confidence and help manage stress.  Based in the heart of London – Urban Kings welcomes all levels from beginners to fitness enthusiasts and advanced fighters.",
          "offers": "Please visit our website for full details on our prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 06:30 - 22:00\nSat & Sun: 09:00 - 16:00",
          "locations": [
            {
              "geolocation": [
                -0.12205700000004072,
                51.53132639999999
              ],
              "city": "London",
              "postcode": "N1",
              "_id": {
                "$oid": "5a15a3a72288dd3a30f3f532"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Urban Kings Gym",
          "phone": {
            "number": "2078377774",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Boxing Ring",
            "Cage Wall",
            "Dojo",
            "Punch Bags",
            "Monkey Bars",
            "Cable Machines",
            "Battle Ropes",
            "Power Bags",
            "Tyres",
            "VersaClimber",
            "Sauna",
            "Juice / Shake Bar",
            "Changing Facilities"
          ],
          "testimonials": "Dominic:\nI recently rejoined Urban Kings after having to leave because of working overseas. Before that, I had been a member since July 2015 and I can't recommend this place highly enough. This gym has everything, fast paced, fun classes that are action packed and really teach you Muay Thai, Kickboxing, BJJ and more. They've always got the best coaches and classes are available for anyone of any level whether you're a beginner, intermediate or advanced. The staff are lovely and very helpful too which is great as coming into an MMA gym can be intimidating, these guys take really good care of you. There is nowhere else I would want to train in London.\n\nMichael:\nEasy to say that it's the best club I have ever been a member of, and I have been to many 5* gyms and boxing clubs. I can't speak highly enough of Urban Kings, the atmosphere and staff experience really motivates me to keep going and to train hard.\nSpeak to Louis he was very helpful when joining, he was able to give me good advise with regards to my options and made joining the club easy."
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "urban_kings_gym",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5873ea592be2985c1e91c565"
      },
      "lastUpdated": {
        "$date": "2017-11-28T10:22:59.482Z"
      },
      "createdAt": 1483991641872,
      "email": "workitw1@shoomon.xyz",
      "firstName": "Work it",
      "surname": "Adam",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "7RaLK/+4Kv8TAZK8PJ1LLg==",
      "hashedPassword": "kzb5bN4Uvk6+yj+uGDZrxkaDVLilMOng/PkGpXcQfNHgIeULLWFIKUsFRUJTLD9tGgvMqFVvPEOvoF44oz5JfQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Q6B46KPS-workitlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Corporate Wellness",
            "Fitness Classes",
            "Personal Training",
            "Personalised Programmes",
            "Small Group Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://workit.london/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2htwgSEx-workitw1bg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Authenticity & Passion",
          "biography": "We are a fitness studio that offers classes, personal training and corporate wellness. We are professional athletes with a passion to spread the knowledge we have acquired over the years and are focussed on delivering results. We make sure our service is convenient, fun and educational. Come and join us for a Boxing, HIIT or Yoga class or enrol on a bespoke 1-2-1 training program. Fitness and strength levels are irrelevant, no egos, no BS, just hard work in a fun and relaxed environment. At Work it, you are constantly ‘WORKING’ towards improving and being the best you can be. Both our locations are based within a design led, flexible office space in an Office Group building. Work it focuses on the power of wellness. When you feel good, you work better.  Our working hours are getting longer and this has a negative effect on the body, mind and soul. Training should not be a luxury, it is an essential ingredient to ensuring you thrive and don’t just survive.",
          "offers": "Please visit our website for full details of our prices, packages and offers.\n\nOpening Hours: \n\nAlways Open",
          "locations": [
            {
              "geolocation": [
                -0.1424806999999646,
                51.51872940000001
              ],
              "city": "London",
              "postcode": "W1W 7NY",
              "_id": {
                "$oid": "5a1d39037d852e353acb6393"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Work It: Henry Wood House",
          "phone": {
            "number": "7957776013",
            "code": "+44"
          },
          "qualifications": [
            "Fitness Studio",
            "Free Weights Selection",
            "Suspension Equipment",
            "Kettlebells"
          ],
          "testimonials": "Simone:\nGreat class, great instructors and excellent facilities... would love more lunch Boxing / Kickboxing classes but the HIIT classes are also good.. I'm just being selfish to suit my own timetable!\n\nIan:\nworth travelling to very good classes & the boxing class is good too good to see the women doing it too\n\nMel:\nWork It is a great gym in central London, that doesn't cost an arm and a leg! The trainers are really welcoming and know exactly how to push you to be your best. There are a range of abilities, class sizes are kept quite small and there's always time for asking questions etc. Can't recommend this place enough\n\nCraig:\nWhat a great place to train, especially if you have to fit your exercise around work. Flexible classes, Personal Training, and great staff! Would definitely recommend a visit!"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "work_it:_henry_wood_house",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5873f10f2be2985c1e91c6b7"
      },
      "lastUpdated": {
        "$date": "2017-11-28T10:27:16.896Z"
      },
      "createdAt": 1483993359730,
      "email": "workitcity@shoomon.xyz",
      "firstName": "Work it",
      "surname": "Ben",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "uZ4Jj0E3ERUkkpURlYbZig==",
      "hashedPassword": "F9lEB6oYCQ63li1Gi4GVw3nuiKKSAvJL4NaVcV/gjfQrgJSl23sAHfSs1hLuwoKnQL7dushAIFpybAXrtpHZvQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FT0tdYZ9-workitlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Corporate Wellness",
            "Fitness Classes",
            "Personal Training",
            "Personalised Programmes",
            "Small Group Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://workit.london/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/v9n5xmEi-workitbg3.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "biography": "We are a fitness studio that offers classes, personal training and corporate wellness. We are professional athletes with a passion to spread the knowledge we have acquired over the years and are focussed on delivering results. We make sure our service is convenient, fun and educational. Come and join us for a Boxing, HIIT or Yoga class or enrol on a bespoke 1-2-1 training program. Fitness and strength levels are irrelevant, no egos, no BS, just hard work in a fun and relaxed environment. At Work it, you are constantly ‘WORKING’ towards improving and being the best you can be. Both our locations are based within a design led, flexible office space in an Office Group building. Work it focuses on the power of wellness. When you feel good, you work better.  Our working hours are getting longer and this has a negative effect on the body, mind and soul. Training should not be a luxury, it is an essential ingredient to ensuring you thrive and don’t just survive.",
          "offers": "Please visit our website for full details of our prices, packages and offers.\n\nOpening Hours: \n\nAlways Open",
          "strapLine1": "Authenticity & Passion",
          "locations": [
            {
              "geolocation": [
                -0.08518800000001647,
                51.5258924
              ],
              "city": "London",
              "postcode": "EC1V 9DD",
              "_id": {
                "$oid": "5a1d3a047d852e353acb6395"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Work it :",
          "phone": {
            "number": "7776222526",
            "code": "+44"
          },
          "qualifications": [
            "Fitness Studio",
            "Free Weights Selection",
            "Suspension Equipment",
            "Kettlebells",
            "Insanity Class"
          ],
          "testimonials": "Simone:\nGreat class, great instructors and excellent facilities... would love more lunch Boxing / Kickboxing classes but the HIIT classes are also good.. I'm just being selfish to suit my own timetable!\n\nIan:\nworth travelling to very good classes & the boxing class is good too good to see the women doing it too\n\nMel:\nWork It is a great gym in central London, that doesn't cost an arm and a leg! The trainers are really welcoming and know exactly how to push you to be your best. There are a range of abilities, class sizes are kept quite small and there's always time for asking questions etc. Can't recommend this place enough\n\nCraig:\nWhat a great place to train, especially if you have to fit your exercise around work. Flexible classes, Personal Training, and great staff! Would definitely recommend a visit!"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "work_it_:",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "5874eccd2be2985c1e91f5c5"
      },
      "lastUpdated": {
        "$date": "2018-01-18T04:24:30.658Z"
      },
      "createdAt": 1484057805020,
      "email": "info@hathayogawithlisa.co.uk",
      "firstName": "Lisa",
      "surname": "Hemmings",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "c5UolIrNMzfnH54zwIdOSg==",
      "hashedPassword": "/xgoppgcosVfHlaNaQwFHnBj4YdPZrMCFNptEooUOzuOju15CCnddHPxWzJFxRWQqRmpPJM2nLKBA23UTWqRCw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5zdAy7Hs-FullSizeRender.jpg"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Hatha",
            "Mobile Teacher",
            "Pre/Post Natal",
            "Private Classes"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 25,
          "price": {
            "min": 35,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VQAYOeqy-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "twitter": {
            "screen_name": "yogawithlisah",
            "link": "https://twitter.com/yogawithlisah",
            "user_id": "2739354616",
            "accessToken": "2739354616-AFuQBHvApGGbJ8GwJy5Ogtu5Rruuq09tBTkWg9p",
            "accessTokenSecret": "yvUfVjAKV4dwoXT1a8gBtnldcvI9igqxs7N9xgWkLYfOg"
          },
          "locations": [
            {
              "geolocation": [
                -0.1017795999999862,
                51.634683
              ],
              "city": "London",
              "postcode": "N21 1BP",
              "_id": {
                "$oid": "5a60217e765eba094646f789"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7958299706",
            "code": "+44"
          },
          "biography": "My name is Lisa Hemmings and I run Hatha Yoga classes in Enfield and Winchmore Hill, N21 which are designed for those who are beginners, new to yoga or returning to the practice after a break.\n\nYoga is for everyone and we can all enjoy its amazing benefits.\n\nbuddha-500\n\nThese benefits include:~\n\nYoga is an excellent stress reliever and aids relaxation\nA discipline which balances the mind, body and spirit\nIt improves breath control, posture and confidence\nAids individuals ability to be still and silent\nIt is a non-competitive form of exercise, which can assist with rehabilitation after injury\nA way of living your life\nYoga has personally taught me a lot about myself and the world in which we live and the journey I am on. I look forward to sharing with you.",
          "qualifications": [
            "BWY Level 4 500 hour Diploma in Teaching Yoga"
          ]
        },
        "score": 57
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "lisa_hemmings",
      "password_reset_time": 1484124329200,
      "password_reset_code": "X",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "587514352be2985c1e91fd03"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:51:31.628Z"
      },
      "createdAt": 1484067893909,
      "email": "canterburyyoga@shoomon.xyz",
      "firstName": "Cora@",
      "surname": "Canterbury Yoga",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "EvEMSni4DXswFXo73zjCRg==",
      "hashedPassword": "yFURYtjZm2PZgz68JHjyXav8ZAtTkKKENRDvDXjUWayP4HkQSGUeeFRZhkTDM+nyAHMC6d4b3813vbWbxkhTBQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9p72B2OR-canterburyyogaprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Beginners Yoga",
            "Chair Yoga",
            "Gentle Yoga",
            "Mindfulness"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://canterburyyoga.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eZIUPd7c-canterburyyogabg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Yoga classes for all levels of ability in a purpose-built studio within walking distance of Canterbury city centre.",
          "biography": "Highfield House studio was built in 2005 specifically as a yoga studio. It features state-of-the-art lighting, underfloor heating for comfort and a multi-media system. It is built in the secluded grounds of Highfield House - surrounded by gardens - while being easily accessible from Canterbury and surrounding areas. Our  lessons are  very friendly, suitable for all levels of abilities and one-to-one sessions are also available.",
          "offers": "Please visit our website for full details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                1.0583498999999392,
                51.281465
              ],
              "city": "Harbledown",
              "postcode": "CT2 8NH",
              "_id": {
                "$oid": "5a218893a95f7a0d7e4bda51"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Canterbury Yoga",
          "phone": {
            "number": "7711830275",
            "code": "+44"
          },
          "qualifications": [
            "Chair Yoga Class",
            "Gentle Yoga Class",
            "Beginners Yoga Class",
            "Mixed Ability Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "canterbury_yoga",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "587665bc2be2985c1e92409b"
      },
      "lastUpdated": 1484155336690,
      "createdAt": 1484154300523,
      "email": "manorbarn@shoomon.xyz",
      "firstName": "Johanna",
      "surname": "Murray",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "7k9ZaFDhjka8MMWwrWlXAw==",
      "hashedPassword": "JshaBIxJuV7RrKO0BE+q1Yhw4u/qZzTw9dwBndv3VJGfbLgA+WnTGBIw/+R63r3454EH/EMlsaJ7jSNmOlX+hA==",
      "settings": {
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "social": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vcPSUHFJ-manorbarnlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Yoga",
            "Pilates",
            "All Fitness Levels",
            "Nutrition",
            "Group Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.manorbarn.org.uk/index.htm",
          "profileTitle": "General",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KZIV1fDq-manorbarnbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Centre for Peace and Personal Development",
          "biography": "The Manor Barn is a beautiful, purpose-built home of healing set in a semi-rural location, accessible by car, bike or bus.  The Manor Barn provides a professional base for qualified and insured practitioners and their services are offered in a warm, safe, friendly and peaceful environment.  Facilities are light, clean and well maintained with free parking and showers on site. There is a large kitchen for communal use, three therapy suites, a roomy reception and a spacious hall for practice. The Canterbury Yoga Studio in the Manor Barn is the oldest purpose-built yoga studio in Canterbury. A 45sq metre studio primarily hosts yoga and pilates classes but is available for hire by teachers for other classes too, e.g. dance, karate or other exercise forms.  The Canterbury Yoga Studio is a purpose-built studio and training facility located on the outskirts of Canterbury, surrounded by countryside within a seven-minute drive of the city centre. We provide yoga classes in a relaxed quite and comfortable environment.  The Canterbury Yoga Studio is available to hire for barefoot disciplines, such as Yoga, Pilates and Tai Chi, on an hourly basis or by the day or half day.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                1.0531597000000374,
                51.26462779999999
              ],
              "country": "United Kingdom",
              "city": "Canterbury",
              "postcode": "CT1 3UR"
            }
          ],
          "companyName": "The Manor Barn",
          "phone": {
            "code": "+44",
            "number": "1227470256"
          }
        },
        "score": 70
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_manor_barn"
    },
    {
      "_id": {
        "$oid": "58773d472be2985c1e926b81"
      },
      "lastUpdated": {
        "$date": "2018-01-18T04:19:28.974Z"
      },
      "createdAt": 1484209479088,
      "email": "info@sasfitnessuk.com",
      "firstName": "Eugene",
      "surname": "Sobers",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "laJT/mjktlLwcwzcZIZKPw==",
      "hashedPassword": "rVARehkGJ7jWxMqx0s6I0YvUABxE9QyvZ4Nv+NEuEo96UMbxoCATfGnd0y6x+RGFx/jWag+xfxdeAoyv802K5w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/iQQ0HguZ-IMG_3292.JPG"
        },
        "personal": {
          "disciplines": [
            "Groups / Teams",
            "Personal Training",
            "Strength"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Strength & Conditioning Coach",
          "radius": 800,
          "price": {
            "min": 25,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/H2RViJAH-IMG_2791.JPG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -2.255094973372533,
                53.45116736372323
              ],
              "city": "Manchester",
              "postcode": "M16 8ET",
              "_id": {
                "$oid": "5a602050765eba094646f787"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7577188296",
            "code": "+44"
          },
          "biography": "A Seasoned professional and dedicated personal trainer, I have always been a fitness fanatic with an iron determination that pushed through boundaries in order to succeed. My unshakable persistence has awarded me the amateur strong man winner, County powerlifting team winner, and the nationally UK recognized, Mr. Fitness winner. My rock solid belief in determination and will is what made me decide to give back to the industry that made me become a personal trainer. My client range varies from MMA fighters, fitness model competitors and those looking to change their lifestyle and become healthier whilst toning up and shedding a few pounds.",
          "qualifications": [
            "Level 3: Personal Trainer",
            "B.A.W.L.A leader’s award (British amateur weight lifters association)",
            "Level 2: Gym Instructor",
            "Circuit certified",
            "Kettle bell certified",
            "Boxing coach certified"
          ],
          "strapLine1": "Strength and Tabata Specialist Personal Trainer"
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "eugene_sobers",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58778a732be2985c1e927afc"
      },
      "lastUpdated": {
        "$date": "2017-12-01T16:53:53.394Z"
      },
      "createdAt": 1484229235152,
      "email": "yogatree@shoomon.xyz",
      "firstName": "Katya@",
      "surname": "Yoga Tree Studio",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "uLWWdHSgEJIEThdaE8RT1A==",
      "hashedPassword": "LbXjNi6PmTZ7B3TZQgGiKXNFQbW46ij7YBnE2jj08Ack1vHfd1dbZXWlHyd3FxCtX9T1JWu5Bckyufbm6TwVCA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/G1zbjvSU-yogatreelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Gentle Yoga",
            "Hatha Yoga",
            "Yin & Yang Yoga",
            "Yin Yoga",
            "Yoga Workshop"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.yogatreestudio.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2rHcvset-yogatreebg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Space to grow",
          "biography": "My name is Katya Andrews and I am the founder and owner of Yoga Tree Studio. I have been a keen and dedicated yoga practitioner for about 15 years.  Yoga was love at first sight and we are still very much in love! After a period of trying different styles of yoga and self study I enrolled on a British Wheel of Yoga foundation course. Then in 2012 I started 2 year teacher training with the prestigious Yoga Academy. I have been teaching since 2013 and graduated as a 200 hr yoga teacher in 2014. I have also completed Yin and restorative teacher training and continue my studies with Simon Low and The Yoga Academy toward 500 hr accreditation. There have been many other great teachers I’ve studied with and who have inspired me. Such as Eija Tervonen, Gill Lloyd, Sue Delf, Donna Farhi, Julie Gudmestad, Gary Carter, Philip Xerri, Mimi Kuo Deemer and many others.  I’m regularly attending workshops, intensives and yoga holidays to deepen my knowledge and understanding of yoga. Always a student and never ceasing to be amazed by the transformative power of yoga.  My teaching has a strong emphasis on safety and alignment. I believe that when we treat our body gently, with patience and without force, there is a great potential to promote healing and avoid injury. I teach Hatha yoga, influenced by Iyengar and Scaravelli approach. My classes combine flowing sequences and static postures to develop strength, flexibility, fluidity and grace.",
          "offers": "We reserve the right to change the schedule or cancel classes at short notice due to illness or unforeseen circumstances - Please visit our website for full details on prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                1.2840463,
                51.3612397
              ],
              "city": "Saint Nicholas-at-Wade",
              "postcode": "CT7 0LT",
              "_id": {
                "$oid": "5a218921a95f7a0d7e4bda52"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Yoga Tree Studio",
          "phone": {
            "number": "7950305865",
            "code": "+44"
          },
          "qualifications": [
            "Hatha Yoga Class",
            "Yin Yoga Class",
            "Gentle Yoga Class",
            "Yin & Yang Yoga Class"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "yoga_tree_studio",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "587794fc2be2985c1e927d1a"
      },
      "lastUpdated": {
        "$date": "2017-11-28T17:32:45.986Z"
      },
      "createdAt": 1484231932317,
      "email": "folkestoneyoga@shoomon.xyz",
      "firstName": "Yoga",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "2/smjyvSRDB4fEpy4nz6Gg==",
      "hashedPassword": "HkC+379BLo6mJGjXL5rDQwAIzDHkOs7188QV+jWctUDg+BHSNkcF8tSqrJ4DFKBNWdvp0XAguGgvJxfJDR+Kxg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/X6MkBxWr-folkestoneyogalogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Hatha Yoga",
            "Iyengar Yoga",
            "Kundalini Yoga",
            "Remedial Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://folkestoneyoga.com/yoga-we-offer/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/33y38Mer-folkestoneyogabg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Yoga For Everyone",
          "biography": "Folkestone Yoga launched in May 2014 in a renovated a local dance school – Dance Easy – which sadly was in a state of disrepair and  offers a range of yoga classes – including general Hatha, Iyengar, Kundalini, Vinyasa Flow and beginners courses, so you can start your practise with some of the best yoga teachers in Folkestone and Kent. We also offer chair yoga for those who are older at the Glendale retirement home at 11am on Mondays. Yoga can help anyone – it aids restful sleep, concentration, mental well-being, keeps joints supple, and the heart strong.  This leads to children learning and concentrating better, adults being able to deal with the stresses and strains of work and modern life, and helps keep you fit in older age.  People are often confused by the different styles and names of yoga – please don’t be! All yoga is good for you and the styles are named after the teachers, or Gurus who developed them, and some styles are more dynamic, but all yoga is exercise.  Think of it like the notes of a song. The notes are always the same but different writers create either fast, or slow music, combining the notes in a different way. You know the music you like – and in the same way will find a teacher and style of yoga you like - I look forward to meeting you soon!",
          "offers": "Please visit our website for full details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                1.1829705000000104,
                51.0792449
              ],
              "city": "Folkestone",
              "postcode": "CT20 1SQ",
              "_id": {
                "$oid": "5a1d9dbd7d852e353acb63f0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Folkestone Yoga",
          "phone": {
            "number": "7921180081",
            "code": "+44"
          },
          "qualifications": [
            "Iyengar Yoga Class",
            "Kundalini Yoga Class",
            "Hatha Yoga Class"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "folkestone_yoga",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58779b0d2be2985c1e927e48"
      },
      "lastUpdated": {
        "$date": "2017-11-28T17:33:17.607Z"
      },
      "createdAt": 1484233485085,
      "email": "eclipsedover@shoomon.xyz",
      "firstName": "Eclipse Yoga",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "JP+2Xk+4UE7WPVGPxdAmyA==",
      "hashedPassword": "Q/SNlHkmTbAVBUAZw90kq4ZdlkUl17TJE/P8BnCd/Vu0MVqeNRhPejNENwgYq88nidnugmtf/iX5FjChmmNhQw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qlAR1RUb-eclipselogo2.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "General Yoga",
            "Pregnancy Yoga",
            "Vinyasa Flow Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.eclipseyoga.com/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/f3vHWt42-eclipsebg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "We teach mixed-ability classes so everyone can enjoy their yoga practice - take time to balance your mind and body.",
          "biography": "Eclipse Yoga was established in 2000 by Caroline Purvey ,who has been successfully providing Yoga classes and workshops for students in Dover and surrounding villages. Eclipse Yoga Centre opened in May 2012 and offers classes for all abilities in  a light, spacious and tranquil environment. The beautiful bamboo floor provides a solid, comfortable  base for the practice of Yoga and TRE (Total Release Experience) and we look forward to meeting you. You will find us on the main promenade of Dover, a hidden jewel that lies under the main building of 1-3 Waterloo Crescent - we are on the opposite end of the building to the Royal Yacht Club - the entrance is around the back. The main doors will be open 20 minutes before class starts and close 5 minutes after.  If you wish to come down to see the facilities, please call beforehand as you may not get in if a class is in progress and we would not want you to waste a journey.  Information will be available in a dispenser outside if you are passing.",
          "offers": "Fees are paid in advance and are strictly non-transferable.  N.B. Fees are payable every six weeks. We are unable to offer refunds. This policy is in-line with many other yoga teachers, private schools and Adult Education classes.  However at Eclipse we do not want you to miss out.  if you miss miss your class you may make it up by attending  another class anytime within 6 weeks (subject to availability). Please visit our website for full details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                1.3151662000000215,
                51.12283739999999
              ],
              "city": "Dover",
              "postcode": "CT16 1LA",
              "_id": {
                "$oid": "5a1d9ddd7d852e353acb63f1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Eclipse Yoga Centre",
          "phone": {
            "number": "1304211994",
            "code": "+44"
          },
          "qualifications": [
            "General Yoga Class",
            "Pregnancy Yoga Class",
            "Vinyasa Flow Yoga Class"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "eclipse_yoga_centre",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5877b54a2be2985c1e9283b4"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:57:42.428Z"
      },
      "createdAt": 1484240202021,
      "email": "anetai@shoomon.xyz",
      "firstName": "Aneta@",
      "surname": "Anetai Yoga Studio",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "AkUJ2grGqchPJP6uVIgdJg==",
      "hashedPassword": "5Xte2UhjiUXGhXKF8JUH/5/WIE+ziTfYz2Iqu6JD/XdDXxd+3DPToU812kkcgl7dWQ1UiWQvAYh4OVnfUleA7g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/XwAgVUre-anetaiprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Private Yoga Class",
            "Restorative Yoga",
            "Wellness",
            "Yin & Yang Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.anetai.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/w17Nt6tQ-anetaibg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "strapLine1": "Yoga Studio + Yoga Wellness Coach + Thai Yoga Massage Therapist",
          "biography": "Our boutique yoga studio in the heart of Ramsgate is a welcoming space for women, men and kids to come and  let go of daily stresses and pressures. It is a nurturing and healing space for you to feel at home in your own skin and create a healthier and stronger body. We create a deep sense of connection with one another and  a community of like-minded people - be part of the movement and create your best life!  The studio is situated on High Street, just a couple of minutes walk from the centre of Ramsgate. The classes are small (up to 11 students) and therefore provide a good interaction between a teacher and all attending, allowing for a more hands-on approach.  At our Ramsgate Yoga Wellness Studio we deliver workshops and groups led by experienced teachers on a variety of subjects.  We deliver Monthly Workshops:  Yin & Yang Yoga Workshops,and Mindfulness Workshops. Each workshop focuses on a different theme. All are suitable for complete beginners and more advanced students alike.  We work closely with other experienced teachers and professionals and in the past hosted a number of exciting events:  AcroYoga; Effortless Meditation; 8 week Mindfulness for Life course with Aneta; MindWise Choices for Wellbeing – Women’s Group with Aneta; Mini-Retreats ‘The real meaning of ‘Me-time’ – Taking Care of Yourself Holistically and ‘Bridging the gap between our dreams and reality’ with Aneta and Sam.",
          "offers": "Please note these are the prices for Aneta’s classes only – other teachers using the studio may charge different fees. Please contact your teacher directly for more information (concession is for retired/full time student/benefit recipient)  PLEASE ASK YOUR TEACHER DIRECTLY IF THEY OFFER EARLY BIRD DISCOUNTS/SPECIAL OFFERS ON GROUPS/CLASSES.  There will also be some flexibility as I know that life can come up with unexpected challenges. If you are unable to attend your regular class and let me know in advance you will be able to attend another class during that term (subject to available spaces). I will not be transferring any classes to next term. Please visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                1.416560000000004,
                51.33535429999999
              ],
              "city": "Ramsgate",
              "postcode": "CT11 9RX",
              "_id": {
                "$oid": "5a219816a95f7a0d7e4bda67"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Anetai",
          "phone": {
            "number": "7543973841",
            "code": "+44"
          },
          "qualifications": [
            "Restorative Yoga Class",
            "Yin & Yang Yoga Class",
            "Beginners Yoga Class",
            "Wellness Class",
            "Thai Yoga Massage",
            "Personal Training",
            "Mindfulness Yoga Class",
            "Yin Yoga Class"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "anetai",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5879eecc2be2985c1e92f5a2"
      },
      "lastUpdated": {
        "$date": "2018-01-18T04:14:20.270Z"
      },
      "createdAt": 1484385996297,
      "email": "james@pumpandflex.com",
      "firstName": "James",
      "surname": "Thompson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "L5yGz5t2SEqUBCwcilx+Aw==",
      "hashedPassword": "5riTcu7hlajxZj9O6KW8IG7JBQiZ9gbBpj/B4NZCgz1NiuMRMq98RmhzyLvST6VPxnRm5VUtx30jc0RbaEB7kg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9dkHEMyF-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Circuit Training",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "price": {
            "min": 40,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LG2ulqzW-IMG_0290.PNG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -0.17618100897585443,
                51.470150403107546
              ],
              "city": "London",
              "postcode": "SW11 3PG",
              "_id": {
                "$oid": "5a601f1c765eba094646f784"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7816148083",
            "code": "+44"
          },
          "biography": "Boxing for Earlsfield ABC in 2013 and Fight City Gym in 2015 & 2016 and with over 10 years of boxing experience, James combines this knowledge with both his REPS L4 personal training qualifications and sporting career expertise to create unique personal training experiences that keep you coming back for more. James works closely with each and every client to ensure your goals, whether that be changes to body composition, strength & conditioning, muscle gain or learning to box these all are achieved by teaching you the correct form, creating training schedules specifically for you, and then guaranteeing progression by mapping the correct training programme to your exact requirements whilst applying periodization to remove any possibility of a plateau. If needed James can assist with nutritional advice, help create diet plans, all of which whilst evaluating your current eating habits to ensure what ever your goals, we reach them as a team.\n\nA fast paced energetic workout pushing your body to the limits. Great emphasis on ensuring the correct form, from a straight right punch to a kettle bell clean, form is everything. I'm a firm believer of natural training, supplement free, gaining all your vital nutrients from simply applying the correct diet plan. Once these foundations are in place the sky is the limit to what you can achieve.",
          "qualifications": [
            "Level 4: Advanced Personal Trainer"
          ]
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "james_thompson",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "587a5c6c2be2985c1e930b6f"
      },
      "lastUpdated": 1484519231141,
      "createdAt": 1484414060711,
      "providerId": "10206987062387966",
      "provider": "facebook",
      "surname": "Raile",
      "firstName": "Rita",
      "email": "raila000@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10206987062387966/",
          "disciplines": [
            "Personal Training",
            "Private Classes",
            "Nutrition",
            "Strength & Conditioning",
            "Group Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://realenergy.fitness",
          "profileTitle": "General",
          "radius": 10,
          "price": {
            "min": 40,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/CJQL0JMt-701991_1774175959477347_5174639593746515163_o.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "",
          "yearsOfExp": 0,
          "biography": "Real Energy Fitness is a Health and Fitness orientated company that focus on a complete solution, where the connection and communication between both body and mind is key for total well-being .",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.6866247999999999,
                52.048021999999996
              ],
              "country": "United Kingdom",
              "city": "Broughton",
              "postcode": "MK10 7AA"
            }
          ],
          "companyName": "Real Energy Fitness",
          "phone": {
            "code": "+44",
            "number": "7498363151"
          }
        },
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "social": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/bE6gqU8U-8%20copy.jpg"
        },
        "score": 66
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "real_energy_fitness"
    },
    {
      "_id": {
        "$oid": "587bc6072be2985c1e9353de"
      },
      "lastUpdated": 1484522167760,
      "createdAt": 1484506631104,
      "email": "lyptfitness@gmail.com",
      "firstName": "Louise",
      "surname": "Yerby",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "US49XxeDD+IMwAqerWdgpA==",
      "hashedPassword": "4TKri9Ax4vXhfUeLO66dY9Dq0V++EvQUxNzxt2V/i80yW5eysIQZvFDOPkwIpHvshpm2RUdKOkcoN1KYpE8L9w==",
      "settings": {
        "wizard": {
          "complete": false,
          "start": true,
          "additional": false,
          "social": false,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": null,
          "profileTitle": "General",
          "radius": 10,
          "price": {
            "min": 40,
            "max": 60
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.12775829999998223,
                51.5073509
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "SW1A"
            }
          ],
          "companyName": null,
          "phone": {
            "code": "+44",
            "number": "7931413919"
          }
        },
        "score": 30
      },
      "active": false,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false
    },
    {
      "_id": {
        "$oid": "587c90e12be2985c1e937c6c"
      },
      "lastUpdated": {
        "$date": "2018-01-18T04:05:46.215Z"
      },
      "createdAt": 1484558561398,
      "providerId": "1925909080970301",
      "provider": "facebook",
      "surname": "Nikolaidis",
      "firstName": "Nikos",
      "email": "fitandfunctional2014@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1925909080970301/",
          "disciplines": [
            "Functional Training",
            "Pre/post Natal",
            "Rehabilitation",
            "Weight Loss"
          ],
          "timezone": "Europe/Athens",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "price": {
            "min": 10,
            "max": 30
          },
          "radius": 21,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MNel00v5-12345617_1739341256293752_2833169883116219881_n.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 12,
          "biography": "Delivering and developing personalized programs for my clients\nin a class format as well as on an individual bases\nBody Composition Measurments\nDevelopment, delivery and monitoring of individual and group strength and conditioning programs for wounded, injured and sick service personnel and veterans as part of their on-going rehabilitation/recovery. \nLiaising with relevant clinical practitioners to ensure continuity of exercise rehabilitation delivery for wounded, injured and sick service personnel and veterans.",
          "offers": "2 TRAININGS FREE",
          "twitter": {
            "screen_name": "NIKOLAIDISPT",
            "link": "https://twitter.com/NIKOLAIDISPT",
            "user_id": "4860864225",
            "accessToken": "4860864225-1OP1R1dKmNsfg0S3F8HHvyFXBTm0IxWa6bfarSV",
            "accessTokenSecret": "Ox0gL84PLqAmYYVgxJbrMSgPe7Lll0w9gDxh7IN1gvusO"
          },
          "google": "https://plus.google.com/102944958206545900970",
          "strapLine1": "Movemenet Assessments, Corrective Exercise, Weight Loss, Pre & Postnatal, Functional, Strength/Power, Hypertrophy",
          "locations": [
            {
              "geolocation": [
                22.96212270000001,
                40.6488749
              ],
              "city": "Συκιές",
              "postcode": "566 26",
              "_id": {
                "$oid": "5a601d1a765eba094646f783"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "6909011218",
            "code": "+30"
          },
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "CES-NASM",
            "CPT-NASM",
            "WLS-NASM",
            "PRE & POSTNATAL FITNESS SPECIALIST",
            "FMS L2 TRX INSTRUCTOR",
            "KETTLEBELLS TRAINER",
            "LEBERT FITNESS INSTRUCTOR",
            "BLACKROLL TRAINER",
            "SPORTS MASSAGE",
            "HM-ALIVE TAPING"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MCwS0TcY-filename.jpeg"
        },
        "score": 77
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "username": "nikos_nikolaidis",
      "password_reset_time": 1484727658418,
      "password_reset_code": "190e4c5c4ca0dd795ccb4c963d9c0f5750b2f11c",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "587d4bbc2be2985c1e93a1be"
      },
      "lastUpdated": 1484681246040,
      "createdAt": 1484606396270,
      "email": "info@dnanutricoach.com",
      "firstName": "DNA",
      "surname": "NUTRICOACH",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "X",
      "provider": "local",
      "salt": "dysUD1jYHB/sVLKcbq7fXA==",
      "hashedPassword": "7PcjljUup3DlAB4VgrNMzcRai1MclgXvs4+9deFSSS/3fJrQhW+K4zCp2jmkn/C/xJHj++QZcdpnW59tayB9hA==",
      "settings": {
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "social": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/F8PZq7TE-IMG_20160728_212606.jpg"
        },
        "personal": {
          "disciplines": [
            "Consultations",
            "Health Conditions",
            "Dietary Advice",
            "Intolerances",
            "Lectures"
          ],
          "timezone": "Europe/Madrid",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Nutritionist",
          "radius": 10,
          "price": {
            "min": 120,
            "max": 965
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tKIOY568-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "dnanutricoach",
            "link": "https://twitter.com/dnanutricoach",
            "user_id": "3174109001",
            "accessToken": "3174109001-E4F6I8Gyr3RYAvCwBxeWGPhqSFSpbvCRkWiEwOW",
            "accessTokenSecret": "GpzDnhXrL5o5g7cMnLPUulW1KRQw9OHkiZa1wmWrsAHzz"
          },
          "facebook": "",
          "strapLine1": "Nutrigenetic coaching for wellness and better conditioning",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                2.1734034999999494,
                41.38506389999999
              ],
              "country": "Spain",
              "city": "Barcelona",
              "postcode": "08002"
            }
          ],
          "phone": {
            "code": "+34",
            "number": "610639369"
          }
        },
        "score": 71
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "dna_nutricoach"
    },
    {
      "_id": {
        "$oid": "587e17542be2985c1e93ca88"
      },
      "lastUpdated": {
        "$date": "2018-01-18T03:55:42.658Z"
      },
      "createdAt": 1484658516886,
      "email": "ashleykieler2013@gmail.com",
      "firstName": "Ashley",
      "surname": "Kieler",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "activation_code": "9c31bbc74225e1a3aaf356ca288b127619966ffb",
      "provider": "local",
      "salt": "raNLTQcP7nUkGu3YlgjIOA==",
      "hashedPassword": "a0EqsUaRkXUSecpznOrTrK5QCMcR+CLm3jR9bxWABO0Kcit+6bKZiHShngmuWA/qUPW6hyd/SXG5l+PPoVduTg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FmE7pvIn-IMG_1705.JPG"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 40,
          "price": {
            "min": 30,
            "max": 50
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qMnw4lGF-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "locations": [
            {
              "geolocation": [
                -90.31837919999998,
                42.7267178
              ],
              "city": "Belmont",
              "postcode": "53510",
              "_id": {
                "$oid": "5a601abe765eba094646f780"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "phone": {
            "number": "6087328068",
            "code": "+1"
          },
          "biography": "I am passionate about fitness and nutrition and want to help you with your journey!",
          "qualifications": [
            "I.S.S.A. Certified Personal Trainer"
          ]
        },
        "score": 52
      },
      "active": false,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ashley_kieler",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "587f7504da51a950585c07be"
      },
      "lastUpdated": {
        "$date": "2017-11-29T20:24:37.033Z"
      },
      "createdAt": 1484748036297,
      "email": "whitstablewellnesscentre@shoomon.xyz",
      "firstName": "Gemma",
      "surname": "Dawson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Vw5ggjbdqMcwG/oCTpfwww==",
      "hashedPassword": "tjHkWKmj67vYlUJoRd+dBw03Crgsp9iMUdx2vozLC4/bBqXvbroDYTiD8nyjDp6tVjPIY8oVv5eazuO3rhhapg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MPM8g84J-whitstablewellnessprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "Kids Yoga",
            "Mother & Baby Yoga",
            "Post Natal Yoga",
            "Pregnancy Pilates",
            "Pregnancy Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.whitstablewellnesscentre.co.uk/index.html",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/euGscZc9-whitstable%20wellnessbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "",
          "biography": "Welcome to the Whitstable Wellness Centre:\n\nWhitstable Wellness Centre is a centre for health and healing in Whitstable;\ncome and enjoy the peaceful and relaxing surroundings. Our Therapists are highly knowledgeable and skilled practitioners who are fully qualified and insured with their relevant associations.\n\nLocated in the heart of Whitstable on Cromwell Road, just a quick walk from the town centre and train station, close to the Goral Tank car park or free on-street parking. The centre benefits from being dedicated to alternative health and therapy groups in a self-contained unit, allowing you to be totally undisturbed.\n\nOur space is for hire at very competitive rates for groups and therapists - please contact Gemma at Whitstable Wellness Centre via the website link or phone number.\n\nThe centre encompasses a wide variety of therapies and groups including the following:\n\nPilates\nYoga\nMother and Baby groups\nWholeness Technique (a combination of Alexander Technique and a deepening awareness of mind and body)\nHypnobirthing\nMindfulness\n\nPlease visit our website for further details.",
          "offers": "All our practitioners manage their own bookings and enquiries - please visit our website for full details of prices packages and offers and to contact them directly .",
          "strapLine1": "Pilates - Yoga - Mother and Baby - Wholeness Technique - Hypnobirthing - Mindfulness: a light and comfortable studio",
          "locations": [
            {
              "geolocation": [
                1.0294539000000213,
                51.3605498
              ],
              "city": "Whitstable",
              "postcode": "CT5 1NA",
              "_id": {
                "$oid": "5a1f1784b1d6217c42dac124"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Whitstable Wellness Centre",
          "phone": {
            "number": "7751848913",
            "code": "+44"
          },
          "qualifications": [
            "Mat Pilates Class",
            "Kids Yoga Class",
            "Mother & Baby Yoga Class",
            "Pregnancy Yoga Class",
            "Post Natal Yoga Class",
            "Pregnancy Pilates Class",
            "General Yoga Class"
          ],
          "testimonials": "Mr D Cleaver\nI have enjoyed using the facilities at Whitstable Wellness Centre. Having attended a couple of classes I enjoy the fact that I can walk within a few minutes from home.\nThe studio has a relaxing atmosphere that you don't often find in other places. I have already recommended Whitstable Wellness Centre to my friends and family\n\nEdith, Whitstable\nI am pleased with the class that I attend and feel very comfortable in the calming surroundings.\nI am looking forward to bringing my baby along to the mother and baby groups soon.\n\nMr D Cleaver\nI have enjoyed using the facilities at Whitstable Wellness Centre. Having attended a couple of classes I enjoy the fact that I can walk within a few minutes from home.\nThe studio has a relaxing atmosphere that you don't often find in other places. I have already recommended Whitstable Wellness Centre to my friends and family\n\nEdith, Whitstable\nI am pleased with the class that I attend and feel very comfortable in the calming surroundings.\nI am looking forward to bringing my baby along to the mother and baby groups soon."
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "whitstable_wellness_centre",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "587f8434da51a950585c0b03"
      },
      "lastUpdated": {
        "$date": "2017-11-28T16:33:42.964Z"
      },
      "createdAt": 1484751924951,
      "email": "thepilatesshed@shoomon.xyz",
      "firstName": "Pilates Shed",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "92aRicCKdfzFKQPR+zoOuQ==",
      "hashedPassword": "5Js/RKWpDaR5rUBRpoUV8wlZSm31KmvliTRJCs3rTCIvEJii5C5vqOM1/zI+Q9y/6SQX/7B9+7rV/xz5BKV5wg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nKMdL6Qq-pilatesshedprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "General Yoga",
            "Mat Pilates",
            "Pilates For Golf",
            "Pregnancy Yoga",
            "Reformer Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.pilatesshed.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/USwP2B1k-pilatesshedbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to The Pilates Shed:\n \nThe Pilates Shed is a fully-equipped Pilates studio and is located on the High Street in Deal, Kent. All our instructors have trained to the highest standard recognised in the U.K. We are all certified by those organisations, are R.E.P.S registered and fully insured.\n \nWe teach Mat Work Classes at beginner, intermediate and advanced levels. These classes include the use of small pieces of equipment to enhance the exercises, and sessions have a maximum of ten participants.\n \nStudio-Equipment Classes are available on a one-to-one basis or in a small group of four people. Studio sessions use the larger pieces of apparatus designed by Joseph Pilates. To Join studio sessions you need to have attended either a six week mat work course or have received private tuition.\n \nOne-to-One Tuition is recommended if you are being referred from your G.P or physiotherapist. You will recieve a full postural assessment and given homework tailored to you needs. Clinical pilates sessions are taught by the director Emma Lee.",
          "strapLine1": "Pilates Studio for Movement Therapy",
          "offers": "Pilates classes are by appointment only. You are expected to complete a 6 week beginners course before commencing an ongoing class - please call 01304 38188 for further details.\n\nPlease visit our website for full details of prices, packages and offers or call 01304 38188.",
          "locations": [
            {
              "geolocation": [
                1.4028296999999839,
                51.2255715
              ],
              "city": "Deal",
              "postcode": "CT14",
              "_id": {
                "$oid": "5a1d8fe67d852e353acb63e2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Pilates Shed",
          "phone": {
            "number": "1304381888",
            "code": "+44"
          },
          "qualifications": [
            "Private Class",
            "Reformer Pilates Class",
            "Mat Pilates Class"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_pilates_shed",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5880ac92ae34490ad140e9e9"
      },
      "lastUpdated": {
        "$date": "2017-11-22T14:13:11.116Z"
      },
      "createdAt": 1484827794310,
      "email": "totalfitnessindeal@shoomon.xyz",
      "firstName": "Totalfitness",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Sg+Txw6ss5pNOu5oL4TdtQ==",
      "hashedPassword": "B7OYDkr3g/syEQE8Kuc5CPr+du7AjvAcpDUA9IsmcNS7pzai8Fq9QJ+k5UsG8OH2TgWU1IHgiUALTRmT83wWhQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4u2LGblm-totalfitnessmixlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Diet/Meal Plans",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://totalfitnessmix.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 30
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/XNPr4Dsb-totalfitnessmixbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "strapLine1": "Gym and Physical Fitness Centre",
          "biography": "Welcome to the home of Total Fitness Mix:\n\nCome and experience our brand new 2-story facility, which is the home to a fully equipped gym, and runs over 30 group exercise classes a week to give every person that something. \n\nThat's right, here at Total Fitness we cater for everyone, whether your an early bird or a night owl we have services running all day to make sure you get your fix.\n\nAs well as looking after the exercise part, we know that isn't the only important part - we also look after your nutrition! Our fully- stocked bar with protein powder, protein bars and fruit smoothies will be sure to look after you before, during or after your workout! All shakes and smoothies are made fresh and catered to order - sounds good, right? That's because it is!",
          "offers": "Memberships include full use of facilities including showers and changing rooms - please visit our website for full details of our prices, packages and offers.\n\nOpening Times:\n\n6AM - 8PM Mon-Fri\n9AM - 2PM Weekends\n9AM - 2PM Bank Holidays",
          "locations": [
            {
              "geolocation": [
                1.3858189000000039,
                51.2124196
              ],
              "city": "Deal",
              "postcode": "CT14 9EW",
              "_id": {
                "$oid": "5a1585f72288dd3a30f3f527"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Total Fitness",
          "phone": {
            "number": "1304365076",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Changing Facilities",
            "Juice / Shake Bar",
            "Shop",
            "No Joining Fee"
          ],
          "testimonials": "Danielle:\nI started this gym roughly around a month ago. Highly recommend to anyone who is nervous about joining a gym for their first time (embarrassingly my first ever gym membership at the age 24! �) Sam has been so helpful, always there with professional help and advice. The 6 week check ins are also really helpful, they give you something to work towards and it's free! Sam made me feel at ease as soon as I walked in, and I know I can always go to her for any advice or help with equipment! The gym is really well equipt, clean, and every couple of weeks runs free workshops!\n\nEmma:\nSo when I first came total fitness I had never stepped foot in a gym it was hard just to walk through the door the staff put me at ease I had personal training lessons with Samantha Reid which was extremely hard for me at first even balance was a problem by the time I'd finished pt I was spinning I now do gym session s lovely gym amazeing equipment and amazeing staff x\n\nToby:\nExcellent service from the staff! super helpful, friendly and and very enthusiastic when it comes to asking for advice. The facility itself is imaculate and all the equipment is brand new and fully functioning. Highly recommended and great value for money."
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "total_fitness",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5880bba6ae34490ad140ed2b"
      },
      "lastUpdated": {
        "$date": "2017-11-22T13:49:24.872Z"
      },
      "createdAt": 1484831654208,
      "email": "thebodyworksgym@shoomon.xyz",
      "firstName": "Body Works",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "zFz0SyBh22V9iqASmMjo0g==",
      "hashedPassword": "nK3nJ/x9sgzHFw1UoUZGwtjdXlIrv18jAeyLx4empnoAiox4uEadk0XC2JUTk4dCj6RszT0g7KWrIkzd8Alzdw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Sfa4LYwR-bodyworkslogo1.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Older Adults",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://thebodyworksgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FcGStT6S-bodyworksgymdealbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Gym with friendly staff and clientele in Deal, Kent",
          "offers": "Please contact us for details of prices, packages and offers.\n\nOpening Times:\n\nWeekdays 6am-9pm\nSaturday 8am-1pm\nSunday 9am-1pm",
          "locations": [
            {
              "geolocation": [
                1.3858189000000039,
                51.2124196
              ],
              "city": "Deal",
              "postcode": "CT14 9EW",
              "_id": {
                "$oid": "5a1580642288dd3a30f3f520"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Body Works Gym",
          "phone": {
            "number": "1304381114",
            "code": "+44"
          },
          "biography": "BodyWorks Gym, is a very friendly welcoming gym in Deal with a good atmosphere. We have a variety of equipment, ranging from free weights to resistance machines and cardio equipment. In addition we have a supplement shop and a protein bar. We are here to help everybody reach their personal goals.",
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Supplements",
            "Juice / Shake Bar"
          ],
          "testimonials": "Amanda: \nHave recently got back into the gym and have been made to feel very welcome, fabulous place to get fit so ladies if ur worried about feeling out if place or uncomfortable going on your own, no need to its brilliant\n\nThom: \nFantastic Gym, Great feel to the equipment and great support from the owner, Callum and his team.\nReally supportive, friendly and welcoming training environment.\n\nAlex: \nFantastic gym, been training there for two years now and the place feels like a second home to me. The gym is fun by fantastic members of stuff who are all friendly and give off a great quality atmosphere.\n\nCrystal-Bliss: \nLove it here was a bit anxious at first but was made to feel welcome shown how to use all the equipment told what is best for certain areas of the body also about diet and food to avoid etc lovely relaxed atmosphere"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_body_works_gym",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5880c310ae34490ad140eeed"
      },
      "lastUpdated": {
        "$date": "2017-11-22T13:23:26.525Z"
      },
      "createdAt": 1484833552602,
      "email": "balancespa&healthclubnrdover@shoomon.xyz",
      "firstName": "Ben Kelly@",
      "surname": "Park Resorts",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Uyv8kiN/s/0T9xEtKh4C0w==",
      "hashedPassword": "6HRsqE3+gOGq60auzO13Z3ohGFabYsKHNsh86Q2Ail8agMP25FfF2a79eMU1/VoTtPk2A/JRKH8RhXk4ggzqmQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9Ha8CEzW-balancespa%26healthclubkent.png"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Fitness Classes",
            "Kids Classes",
            "Personal Training",
            "Swimming Lessons"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.balancespaandhealthclub.co.uk/index.cfm",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TSQnYBe6-balancespa%26healthclubnrdoverbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "strapLine1": "A balanced way of life is a healthy way of life: Pools, Sauna, Treatments, Yoga, Fitness Nr Dover, Kent",
          "biography": "Balance Spa & Health Club can offer you one of the finest health and fitness facilities in the South East of England. Situated on St Margaret's Bay Holiday Park, it is located in beautiful rolling countryside, just a stones throw from the White Cliffs of Dover.\n\nBalance Spa & Health Club can provide you with everything you need to enjoy a healthier and more active lifestyle.  We cater for all ages and fitness levels, and corporates - give us a call or why not come in for a trial visit, one of our friendly team members will be more than happy to help.",
          "offers": "Please visit out website for full details on prices, packages aand offers.\n\nOPENING TIMES (Christmas and Close down listed below):\n\nSaturday: 8am-8pm (8am - 6pm November to March)\nSunday: 8am-8pm (8am - 6pm November to March)\nMonday: 7am-9pm*\nTuesday: 7am-9pm\nWednesday: 7am-9pm\nThursday: 7am-9pm\nFriday: 7am-9pm\nCHILDREN (Under 16yrs) 10am-7pm Daily - we are ADULTS ONLY outside of this time.\n\n*Please be aware we shut every other Monday at 6pm for Staff Training",
          "locations": [
            {
              "geolocation": [
                1.3662105999999312,
                51.1503607
              ],
              "city": "Saint Margaret's at Cliffe",
              "postcode": "CT15 6AG",
              "_id": {
                "$oid": "5a157a4e2288dd3a30f3f518"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Balance Spa & Health Club",
          "phone": {
            "number": "1304853683",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Swimming Pool",
            "Spa Pool",
            "Sauna",
            "Jacuzzi"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "balance_spa_and_health_club",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5880ca57ae34490ad140f06f"
      },
      "lastUpdated": {
        "$date": "2017-11-22T13:09:21.375Z"
      },
      "createdAt": 1484835415453,
      "email": "genesisgymdover@shoomon.xyz",
      "firstName": "Shelley",
      "surname": "Martin",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "tfVgUc3bg0ilqHasSJJK3A==",
      "hashedPassword": "SphK+l5GERJqwVdfJ3OjODMqa3380qxShd7aHy9HtICGoy6ywWvUSEG7w0ptSNWt3FUpQIeV0uN7kuJi6M4xEw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5zL64SuJ-genesisgymdoverprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.genesisdover.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6lahcVEB-genesisgymbg2.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "New beginnings; the new you",
          "biography": "Welcome to Genesis Gym Dover:\n \nWe are a family-run company offering a functional fitness gym, with our friendly staff, protein bar and sunbed area - you won't be disappointed. \n\nAll our staff are fully qualified and there to help and our fully qualified personal trainer is also on hand for one-to-one sessions to help you achieve your goals. \n\nYou can pull up a stool and check out our fully-stocked protein/supplement bar - purchase an ice cold protein shake to take away.\n\nAlong with that  new Genesis body, top up your tan with our lay down power bed. \n\nWe hope to see you all at Genesis gym  - YES you can achieve them goals. A new beginning; a new you. \n\nHave a great day \n\nGenesis gym team xxxx",
          "offers": "Please visit our website for full details of our prices, packages and offers.\n\nOpening Hours: \n\nMon - Thurs: 07:30 - 21:00 \nFri: 07:30 - 20:00\nSat: 07:30 - 12:00",
          "locations": [
            {
              "geolocation": [
                1.3010561999999481,
                51.1338243
              ],
              "city": "Dover",
              "postcode": "CT17 0SH",
              "_id": {
                "$oid": "5a1577012288dd3a30f3f514"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Genesis Gym",
          "phone": {
            "number": "1304211288",
            "code": "+44"
          },
          "qualifications": [
            "Free Weights Selection",
            "Cardio Stations",
            "Resistance Equipment",
            "Racks",
            "Punch Bags",
            "Supplements",
            "Juice / Shake Bar",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "genesis_gym",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5880d717ae34490ad140f331"
      },
      "lastUpdated": {
        "$date": "2017-11-22T12:28:00.485Z"
      },
      "createdAt": 1484838679832,
      "email": "thewightsroomdover@shoomon.xyz",
      "firstName": "Weights Room",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "VOqgXO2vS2RcHApvnG4cug==",
      "hashedPassword": "2GL6ichnDo8sYo7N28dNE/On0gzvad9UwTqewMjcdpjKqvdr06mYDo3sRKQhXOYkxQbMSOZj5JRsfN7UtHVZew==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UDOnMov0-weightsroomdoverlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Diet/Meal Plans",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.doverweightsroom.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fKmCGDYS-weightsroomdoverbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "The Weights Room fitness club started in 2001 and we are located at Sorting Post Office, Charlton Green, Dover.\n\nSince then we have grown tremendously. We are the largest gymnasium and fitness centre in Dover with specialised training areas covering 7000sq ft and still maintaining the best friendly and approachable atmosphere - we also have a large members car park.\n\nDover Fitness Centre is situated opposite Morrison’s in the old Sorting Post Office in Dover. It's the Biggest gym in Dover !",
          "offers": "Please visit our website for full details of our prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 06:00 - 22:00\nSat: 08:00 - 18:00\nSun: 09:00 - 16:00\nBank Holidays: 09:00 - 16:00",
          "yearsOfExp": 2001,
          "strapLine1": "The largest gymnasium & fitness centre in Dover",
          "locations": [
            {
              "geolocation": [
                1.3070012000000588,
                51.13219
              ],
              "city": "Dover",
              "postcode": "CT16 2PS",
              "_id": {
                "$oid": "5a156d502288dd3a30f3f50d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Weights Room Health & Fitness Centre",
          "phone": {
            "number": "1304448292",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Sauna",
            "Supplements"
          ],
          "testimonials": "Susan: \n\nI decided to take up a mths membership and I am glad that I did. Such a friendly, clean and equipped place to come to. Felt at ease the moment I joined and look forward to my month of exercise with the hope that I will stick at it!!\n\nPaula:\n\nMet up with Nicola Joyce at the Weights Room for a leg session. Great gym and exceeded my expectations. Definitely be back. Great kit, great atmosphere and friendly and welcoming.\n\nJay:\n\nIt's great gym value for money and really good apmostphere what more could you ask for\n\nCharlette:\n\nExcellent value for money , overall decent gym. staff are always polite and helpful � \nMy only improvements really would be some water machines and hand sanitizer pumps and new bikes because the current ones are so uncomfortable to use.\n\nAlastair: \n\nReally good size gym lots of space, very helpful at reception, I will keep going as its close to work, the only thing I found annoying was it has 1 shower,1 toilet, small lockers & changing room."
        },
        "score": 71
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_weights_room_health_and_fitness_centre",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5880e14aae34490ad140f533"
      },
      "lastUpdated": {
        "$date": "2017-11-22T12:10:37.077Z"
      },
      "createdAt": 1484841290720,
      "email": "crossfitthanet@shoomon.xyz",
      "firstName": "Andrea@",
      "surname": "Crossfit Thanet",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ACUy+aT9EeNbZQ+3VDUqIw==",
      "hashedPassword": "Mnnke/6SgFRacityKcu/xOSCJlzZN7K4pgR/ZqUzcDlCs2LBfEbh+ByubAIcNVLPbs5lypGhDWOUAQkxb5MWBA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cEj9bfvk-crossfitthanetlogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Competitions/Events",
            "Olympic Lifting",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://crossfitthanet.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zYWHxyZ7-crossfitthanetbg.png",
            "size": {},
            "offset": 0
          },
          "biography": "CrossFit Thanet is the first and only CrossFit facility to open in Thanet: It was started with passion and a want to pass on this passion for CrossFit. We believe the way you train and the people that you train with make a world of difference to your performance and keep you coming back for more. We welcome all fitness levels and we are all striving for the same goals: we all want to be fitter and stronger and to feel good about ourselves, and to make others feel good about themselves. This is why our community is so strong and why we stand out from other traditional gyms.\n \nWe offer small group sessions to maximise the personal attention that you receive from our coaches. We believe this is vitally important in making sure every individual gets the attention they need to get the most out of their performance and training. You will basically be getting personal training at a fraction of the cost of what you would normally be paying at a traditional gym.\n \nMost of all, we want to show you that fitness doesn't need to be dull and repetitive. Our program changes on a day-to-day basis - you will not know the daily workout until that day. Routine is the enemy and this prepares your body for anything. Your body will constantly be adapting and be ready for anything, not only physically but mentally too. Watch this transfer into real world situations.",
          "strapLine1": "Crossfit classes and open gym-training for all fitness levels",
          "offers": "Please visit our website for full details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                1.388934000000063,
                51.3663184
              ],
              "city": "Margate",
              "postcode": "CT9 4HX",
              "_id": {
                "$oid": "5a15693d2288dd3a30f3f50b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "CrossFit Thanet",
          "phone": {
            "number": "7827225417",
            "code": "+44"
          },
          "qualifications": [
            "Free Weights Selection"
          ],
          "testimonials": "Shaun:\n\nCrossfit Thanet is a gem. In a room full of strangers I have never felt so welcome. Colin and Andrea are fantastic, the atmosphere is electric and highly addictive and makes you push yourself more than any gym I have been in and can't wait to carry on pushing myself to achieve my personal goals.\n\nMark:\n\nCF Thanet is simply the best experience I've ever had in a gym. The level of instruction is excellent and the atmosphere in the box is both welcoming and inspiring. I get excited before a workout and leave energised after (and that's a first). CF Thanet has changed my whole outlook on training and I seriously couldn't recommend them enough.\n\nStuart: \n\nWell what can I say. Not a lot really you can only know by trying it for yourself. Colin and Andrea are cool and are bloody good coaches. I have been attending crossfit Thanet since the day it opened and I have never looked back. To me this is my second home we are like one big family. So all the time your wasting in saying oh I might join. Stop click the link and get your butt down here. You don't know what your missing"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "crossfit_thanet",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5880e935ae34490ad140f6f5"
      },
      "lastUpdated": {
        "$date": "2017-11-22T11:53:08.091Z"
      },
      "createdAt": 1484843317380,
      "email": "elitefitnessthanet@shoomon.xyz",
      "firstName": "Elite",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "aYLK3xOIUeTa/4kofRFGrQ==",
      "hashedPassword": "lfUa5axKFRubp4sh4+f71/Ukf9WOo+fwS8AVvshNjJPU7EYqCcYNNnjvaUC5/kFI6jvgYLiJV4LXdSzAxR/nZg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ustwfG83-elitefitnessprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.elitefitnessstudio.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WlTTGSQ3-elitefitnessbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Eat Well...Train Hard!",
          "biography": "Welcome to Elite Fitness Studio, the neighbourhood gym with all the amenities you'd expect to find in larger gyms. Whether you're a beginner or a seasoned athlete, our helpful staff are trained to assist you in effectively reaching your goals.\n \nConveniently located in the heart of Broadstairs, Elite Fitness Studio provides the latest in cardiovascular and weight-training equipment, as well as fitness programmes and inductions with a personal touch you just won't find anywhere else.\n\nFor many years, Elite Fitness Studio has been providing friendly, personal attention to its members, helping them stay fit, active and healthy. We are here to help you reach all your fitness goals.\n \n10 years old and always improving. With our confident, honest and professional staff. outstanding customer care and pristine facilities, elite fitness is now positioned as Thanets premier fitness studio.\n \nWe pride ourselves on offering the customer that little bit extra, more attention to detail when designing bespoke training programs, consideration to lifestyle and habits when creating nutrtional plans and most importantly of all developing an environment where people of all ages and fitness levels can train with confidence without the intimidation.\n\nOur staff are trained to assist you in all aspects of your exercise routine. Regardless of whether you've ever set foot in a gym before, our trainers will listen to your needs and develop a workout individually designed for you to meet your goals.",
          "offers": "Please visit our website for full details of our prices, packages and offers.\n\nOpening Hours:\n\nMonday To Friday:  6am till 9:30pm\nSaturday and Sunday: 9.00am till 4.00pm",
          "locations": [
            {
              "geolocation": [
                1.4411835999999312,
                51.3590549
              ],
              "city": "Broadstairs",
              "postcode": "CT10 1LF",
              "_id": {
                "$oid": "5a1565242288dd3a30f3f508"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Elite Fitness Studio",
          "phone": {
            "number": "1843600868",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Punch Bags",
            "Free Parking",
            "Supplements"
          ],
          "testimonials": "Sandie:\n\nThe best gym I have ever been at. People are really friendly and helpful. Gym has everything you need and always clean and tidy.\n\nAaron:\n\nPeople are really nice helped me out a lot. Just a nice place to be apart from a few that go to the gym that think their Gods gift but you can't have a gym without them\n\nJosh:\n\nFantastic place to both train myself and clients. Lovely atmosphere, great staff and location, all the equipment you'll need for all your health and fitness goals!\n\nCleo:\n\nBrilliant place, staff are very friendly and its nice to walk though the doors and see a friendly smile. The gym is excellent nice and quiet and its lovely to get on the equipment without queing..excellent.\n\nBen:\n\nwas able to do a short term membership while I was on vacation. Great staff, clean facility and the after workout shakes were great as well!\n\nGary:\n\nBeen there last year for a training Danielle who works there as trainer. Nice place very friendly on two floors with all the cardio equipment you need there. Seriously thinking of joining up off pec or full time is cheaper than DW Sports, which I belong too at the moment."
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "elite_fitness_studio",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58810ea7ae34490ad140fef8"
      },
      "lastUpdated": {
        "$date": "2017-11-22T11:27:28.661Z"
      },
      "createdAt": 1484852903433,
      "email": "crossfitstonetowers@shoomon.xyz",
      "firstName": "Stone Towers",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "3bnM1g9QOsFro9wCgFjrMw==",
      "hashedPassword": "veZLshDoI0kJVexPjqja0IwQbdmXPTefliidiQKBTwbxQYuflc4u1LW5ju+JQrHhpDxbV+5QPTBfEN8CoFIqQg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/adr6tjl7-crossfitstonetowerslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Olympic Lifting",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.crossfitstonetowers.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3Yb7E3dR-crossfitstonetowersbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "CROSSFIT STONE TOWERS IS THE LEADING FUNCTIONAL MOVEMENT WORKOUT SPACE IN CANTERBURY:\n\nHead coach Ross Johnson is a qualified level 1 CrossFit trainer and a ten year Royal Marine Commando.\n\nThe military ethos is infused into the daily classes, they are progressive, relevant, and you will be pushed. Focusing on a steady improvement of your overall strength and mobility.\n\nWe will build your 'engine' allowing you to push yourself for longer and harder. You will rapidly notice a marked increase in your overall fitness.\n\nThe classes are friendly, social and most importantly fun, with a highly supportive group essence.\n\nWe pride ourselves on our strong community spirit at CrossFit Stone Towers where everyone, at every fitness level, is welcome.  \n\nEverything you do in every class is designed around the way your body should move. Functional, full body movements are in, isolation exercises are out. We want your body to work to the best of its ability, the way it was designed to.\n\nBy operating the workouts in small groups, we offer a near personal training experience for the cost of a regular gym. \n\nWith everyone working through the same workout at the same time, the camaraderie and support of your classmates will spur you on to push yourself beyond your limits.",
          "strapLine1": "Canterbury's premier CrossFit establishment",
          "offers": "Please visit our website fo r full details of prices, packages and offers.\n\nOPENING HOURS: \n\nMon - Fri: 06:00 - 10:30 (Tues till 12:00pm)\nMon - Fri:16:00 - 21:00\nSat: 09:30 - 11:30\nSun: 09:30 - 11:30",
          "locations": [
            {
              "geolocation": [
                1.1064724999999953,
                51.27006009999999
              ],
              "city": "Canterbury",
              "postcode": "CT1 3AA",
              "_id": {
                "$oid": "5a155f202288dd3a30f3f502"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Crossfit Stone Towers",
          "phone": {
            "number": "7767440859",
            "code": "+44"
          },
          "qualifications": [
            "Free Weights Selection",
            "Sled",
            "Suspension Equipment",
            "Kegs",
            "Gymnastic Rings",
            "Racks"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "crossfit_stone_towers",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58811526ae34490ad141007a"
      },
      "lastUpdated": {
        "$date": "2017-11-22T11:06:54.161Z"
      },
      "createdAt": 1484854566592,
      "email": "hirepgym@shoomon.xyz",
      "firstName": "Hi Rep Gym",
      "surname": "F'stone Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "IdV2Z7ZYXks8JfTr/z6JeA==",
      "hashedPassword": "FFK100QJfMRlG9evHHNxclO9s1i9UCjPNka1EFF2zLqh3ySKyjFz1K/lWGUN7t8zh7wmIx46lGGFVRhK4t47ZQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gwxubmKJ-hirepgymlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Diet & Nutrition",
            "Power Lifting",
            "Strength & Conditioning",
            "Strongman"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.hirepgym.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cdnVVuJK-hirepgymbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Bodybuilding & General Weight Training for Power & Strength  Sports",
          "yearsOfExp": 1984,
          "biography": "Hi-Rep Gym offers a full range of services and membership fees at highly-competitive rates, providing sports-food supplements, accessories and even our own in-house informational diet and training DVD's. \n\nWith expert advice from Kerry Kayes, Dr David Parry, Peter Tancred and Ernie Taylor providing a posing routine, the gym has been located in Folkestone for over twenty years and we have considerable experience in the fitness industry.",
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nOpening Times:\n\nMon - Fri: 10am to 9pm\nSat: 10am - 6pm \nSun: - 10am - 2 pm",
          "locations": [
            {
              "geolocation": [
                1.1924069000000372,
                51.0854278
              ],
              "city": "Folkestone",
              "postcode": "CT19 6BA",
              "_id": {
                "$oid": "5a155a4e2288dd3a30f3f4fe"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Hi Rep Gym",
          "phone": {
            "number": "1303245119",
            "code": "+44"
          },
          "qualifications": [
            "Free Weights Selection",
            "Resistance Equipment",
            "Benches",
            "Racks",
            "Atlas Stones",
            "Cable Machines"
          ],
          "testimonials": "Richard:\n\nI've tried every gym in Folkestone over the years but I always come back to hirep. It's a proper old school gym with everything you need to work every muscle and is the choice of some top class power lifters. I always come out of a session in high rep feeling pumped an stronger.\n\nTony:\n\nFantastic gym all the kit you need for serious trainers no fluff just real hardcore \" spit and sawdust\" ! Best \" old school\" gym in the area !\n\nKalle:\n\nBest equipment on teh island and the most nostalgic ones also. Perfect for powerlifting or bodybuilding.\n\nRichie:\n\nBest gym in town by far, great equipment and value for money, the owner has a wealth of knowledge as do a lot of the body and power lifters that train there!\n\nJane:\n\nA proper old school gym used by people serious about their training."
        },
        "score": 71
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "hi_rep_gym",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58811dd5ae34490ad141023c"
      },
      "lastUpdated": {
        "$date": "2017-11-21T22:28:36.452Z"
      },
      "createdAt": 1484856789061,
      "email": "dynamixleisure@shoomon.xyz",
      "firstName": "Dynamix",
      "surname": "Leisure Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "OwKrFn+ClikUFwOOtCihCw==",
      "hashedPassword": "hZHIHoALGOFBBtXT9yvU7GH8AbZVhXK2g7lmRnWJ2tOexWDX/DyCm8l8Zaq2suHHZcbPgj5odVqyuAlulHfowg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8joKooZG-dynamixleisurelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Osteopathy",
            "Physiotherapy",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.dynamixleisure.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xuH0c7KY-dynamixleisurebg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "strapLine1": "The gym that gets results",
          "biography": "Welcome to Dynamix Leisure: the gym that gets results.\nDynamix Leisure is a Folkestone-based gym that is family run and offers multiple activities for all levels to keep fit and get in shape.\nOur gym / leisure centre offers individuals of any age the opportunity to train, get fit, lose weight, enjoy group classes and meet new people..\nOur gym is fully equipped with a large range of ISO Machines, resistance machines, separate newly-built free weights room and also a fully-equipped cardiovascular suite.\nWe have a great studio to cover all classes such as  RUSH indoor Cycling, Body BLAST, Morning Blast, Endurance Pilates, Cross Core 180, Functional Circuits and more.\nDynamix Leisure also has a great supplement shop that provides high-quality sports nutrition and advice. This will always ensure you get the right product that works best for you.\nDynamix leisure allows none gym members to attend all fitness classes and at a very competitive price.\n\nWe have a great free-weights room and cardio room, all of which are fully air conditioned to ensure you are comfortable when training.\nWe have a range of Professional physio Therapists and gym instructors to assist you in losing weight, toning or building muscle. Personal training is also available.",
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nGym Opening Hours:\n\nMonday: 6:30am - 10pm\nTuesday: 6:30am - 10pm\nWednesday: 6:30am - 10pm\nThursday: 6:30am - 10pm\nFriday: 6:30am - 9pm\nSaturday: 7:30am - 5pm\nSunday: 9am - 3pm",
          "locations": [
            {
              "geolocation": [
                1.1350055000000339,
                51.0921276
              ],
              "city": "Folkestone",
              "postcode": "CT19 4QF",
              "_id": {
                "$oid": "5a14a8942288dd3a30f3f4f6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Dynamix Leisure",
          "phone": {
            "number": "7950305865",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Punch Bags",
            "Hairdresser",
            "Facial Treatments"
          ],
          "testimonials": "I didn’t fully appreciate what a proper equipped gym could provide until I found Dymamix. The ISO machines, free weights, guidance and advice have proven paramount in my training but also unlike other gyms you are taught respect towards the equipment you are using and other members.  Kia also does a mean post workout lunch to order.  Where else do you get that service?  Thank you Kia and Dynamix.\n\nPaula Francis\n\nI thank my lucky stars that Dynamix is in my life! The gym facility has first class equipment for a start. Kia has educated me with training, with nutrition and it’s been a learning curve that has taken me beyond my original goal. I didn’t know this much change was even possible! Dynamix Leisure and it’s staff give a completely personal service – that’s rare to find. Thank you!\n\nBella Higgins\n\n“Like to say a big thank you to Kia and dynamix. Two months and two stone lighter plus my sleep insomnia seems to have totally gone roll on the next two months.”\n\nDanny Edwards"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "dynamix_leisure",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58821644ae34490ad1413750"
      },
      "lastUpdated": {
        "$date": "2018-01-18T03:50:25.956Z"
      },
      "createdAt": 1484920388302,
      "providerId": "10209438162370170",
      "provider": "facebook",
      "surname": "Wilkes",
      "firstName": "Scott",
      "email": "scottwilkes01@yahoo.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10209438162370170/",
          "disciplines": [
            "Bodybuilding",
            "Hiit",
            "Mobile Trainer",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "price": {
            "min": 25,
            "max": 65
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OSKRnePz-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -2.0505259999999907,
                52.449845
              ],
              "city": "Halesowen",
              "postcode": "B63 4AB",
              "_id": {
                "$oid": "5a601981765eba094646f77f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7507863210",
            "code": "+44"
          },
          "biography": "Scott is an upcoming commercial model quickly gaining experience and exposure within the industry and across social media.\nWith chick-flick boy next door looks, a cheeky glint in his eye and abs to die for; thanks to his background as a personal trainer, he’s the perfect package.\n\nScott is a singer with huge amounts of live performing experience under his belt.\nBooked for a vast variety of venues time and time again Scott really knows how to work a crowd and put on a show with a set list to suit any occasion.\n\nHis latest musical venture involves his brothers who Scott always wanted to share his musical success with. The band ‘The Wilkes Brothers’ is now fully booked for the holiday season every week performing at a variety of Haven Holidays.",
          "qualifications": [
            "Level 3: Personal Trainer"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NsCw2uNj-F786B125-D23D-4298-AA5A-A6B6AD648C90.jpg"
        },
        "score": 57
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "username": "scott_wilkes",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58834036ae34490ad14176d4"
      },
      "lastUpdated": {
        "$date": "2017-11-21T22:09:45.154Z"
      },
      "createdAt": 1484996662234,
      "email": "bobprowse@shoomon.xyz",
      "firstName": "Bob Prowse",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "NYTmskQTRh8isi3Vu7DlMQ==",
      "hashedPassword": "laYHCsek9d1iT8E2LKCCbqS+OmOF7+0/b/OdcjPV/mv6cLnrMw/oGLl4W429gshvBiJZOqFl6DAevt9Wfggjfw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9sL863uj-bobprowselogo2.bmp"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Personalised Programmes",
            "Physiotherapy",
            "Small Group Training",
            "Sports Massage"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.bobprowse.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oy20gAsY-bobprowsebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The Original Maidstone Gym - operating from the same site since 1981",
          "biography": "Here at Bob Prowse Health Club, we pride ourselves on our high standard of training.\n\nWe work on a one-to-one basis catering to your individual ability, from the beginner to advanced level. We offer a free week's trial that enables you to get a feel for the club, equipment and atmosphere before signing up for a full membership. \n\nAll membership plans include 16 weeks of personal training: this is different than most establishments where you are left to your own devices after being shown how to use the equipment safely. With proper instruction much greater gains can be made.\n\nThe free week's trial is simple: it allows you to train at the club with no obligations. If you feel that the Bob Prowse Health Club is for you, then you can join at the completion of the free week.\n\nCall us now on 01622 683388 to book a visit or your free trial.",
          "offers": "We provide a range of memberships to cater for different training needs - please visit our website for full details on our prices, packages and offers.\n\nOpening Times: \n\t\nMonday: 6-30am to 10-00pm\nTuesday: 6-30am to 10-00pm\nWednesday: 6-30am to 10-00pm\nThursday: 6-30am to 10-00pm\nFriday: 6-30am to 10-00pm\nSaturday: 9-00am to 5-00pm\nSunday: 9-00am to 5-00pm\nHolidays: 9-00am to 5-00pm",
          "yearsOfExp": 1981,
          "locations": [
            {
              "geolocation": [
                0.527305599999977,
                51.2608895
              ],
              "city": "Maidstone",
              "postcode": "ME15 6AZ",
              "_id": {
                "$oid": "5a14a4292288dd3a30f3f4f5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Bob Prowse Health Club",
          "phone": {
            "number": "1622683388",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Sauna",
            "Lounge Area",
            "Juice / Shake Bar"
          ]
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bob_prowse_health_club",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58834617ae34490ad1417816"
      },
      "lastUpdated": {
        "$date": "2017-11-21T21:49:10.498Z"
      },
      "createdAt": 1484998167938,
      "email": "capelgym@shoomon.xyz",
      "firstName": "Capel Gym",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "akrw0GquK7UabsaVvFJATw==",
      "hashedPassword": "Ugq2jxtQiyzJKUl4H9rUl6GqVlQpDX2Gx7EQs/EYTWcEBHTP2IZC6o7gCubcPYB4284FNEDd+B8VOpim9Y8RDw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LUCA2SyT-capelgymlogo2.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Boot Camp",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.capelgym.co.uk/pages/home",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GYIwiTZs-capelgymbg2.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "We Would Like You To Join Us",
          "biography": "BodyMission: love the feeling of fit!\n\nWhether your mission is to lose weight, gain muscle or enjoy being fitter and looking good, we have the right place and the right people for you.  At the gym we are all a work in progress at different stages of our fitness journey and you’ll find there are plenty who are starting out, as well as some amazingly strong and fit folk to inspire you.\n \nWe’re just 7 minutes drive from Tonbridge and an easy 20 minutes from Tunbridge Wells with parking onsite.  You’ll find it convenient to get here and home again.  Our clients also come from Maidstone and all the villages around us.\n\nPersonal Training and Boot Camp fitness classes are an important part of what we do.  We have an individual approach to give you the right amount of help for your needs, whether that means a one-to-one every session or no help from us at all - you can come and visit us to have a look around and discuss your needs with no cost and no obligation to join.\n \nWe have a lovely outdoor space at our Gym that we use whenever the weather is good, with rubber-matted outside area, tyres, a rig, and a wood chip track to jog around. You can sit and enjoy a free coffee on the terrace if you like, or lie out in the sun when it occasionally shines!",
          "offers": "Please visit our website for full details on our Prices, packages and offers.\n\nOpening Hours:\n\nMonday to Thursday: 06.30-21.00* \nFriday: 06.30-20.00* \nSaturday: 07.00-14.00*\nSundays & Bank Holidays:\n07.00-13.00*\n[*last entry times - close 45 minutes later]",
          "locations": [
            {
              "geolocation": [
                0.3510211000000254,
                51.1824083
              ],
              "city": "Five Oak Green",
              "postcode": "TN12 6TJ",
              "_id": {
                "$oid": "5a149f562288dd3a30f3f4f3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "BodyMission at Capel Gym",
          "phone": {
            "number": "1892837616",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Outdoor Training Area",
            "Heavy Tyre",
            "Functional Training Rig",
            "Cafe"
          ],
          "testimonials": "Letty: \n\n“I hadn’t done any proper exercise in about 8 years, I hated gyms because I couldn’t use the equipment and the people intimidated me. I was too unfit to do classes without being embarrassed. Since starting doing personal training and classes I have lost 1 stone and 5% of my body fat; but more importantly I can fit into size 12 jeans! When I went on holiday I could climb mountains and see and do things that I could never do before. I’m not scared of exercising in front of people anymore and feel so much better in my own skin. Personal training pushes me in a way I couldn’t do by myself and the classes give me some structured exercise that I can put in my diary – the only way to achieve regular exercise in a hectic lifestyle.” – Letitia Perry\n \nJakki Hesse.  What an incredible difference.  She has lost about 7 stone to date and is still losing! It can be done, we have seen it happen.  And not just the body changes, but the whole of life. \n\nGregg:\n\n“I became a member of the gym because Caroline, already a friend of mine, needed a guinea pig for her training to become a PT. As a result, over the last eight months, I have lost nearly two stone. I now attend the gym at least twice a week”"
        },
        "score": 69
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bodymission_at_capel_gym",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58834c9fae34490ad1417998"
      },
      "lastUpdated": {
        "$date": "2018-01-18T03:46:35.932Z"
      },
      "createdAt": 1484999839309,
      "email": "info@nutr8.com",
      "firstName": "Jules",
      "surname": "Soer",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "n5BVxePmqssEahjqnZCMSQ==",
      "hashedPassword": "bOKePvJ+rdY1ITrw4cruQxx6tzqhvL124Tbr60j4VjxBjaspup1dpBTmxY+bhZMxTVouRRv9om02xHvB0UNj+g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/de2YvjE1-IMG_1521.JPG"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Hiit",
            "Nutrition",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "price": {
            "min": 39,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rYHZzpNE-IMG_1019.JPG",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "locations": [
            {
              "geolocation": [
                -0.997393358113624,
                51.47294977004425
              ],
              "city": "Reading",
              "postcode": "RG4 7EH",
              "_id": {
                "$oid": "5a60189b765eba094646f77e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7577339596",
            "code": "+44"
          },
          "biography": "A qualified nutritionist and personal trainer, I’m passionate about health and wellbeing! \n\nThrough the use of an expanding skill set in nutrition and fitness coupled with personal experience from my own continuing journey, I hope to assist others in finding their path to ultimate health and happiness.\n\nMy passion is to empower others to reach their health and fitness goals, and to believe in themselves and their abilities to change; through education, support and motivation. I take a wholistic approach to supporting my clients and believe that the journey to optimum health is more than just exercise and a good diet, it also takes self-belief and motivation to begin the journey in the first place.",
          "qualifications": [
            "Australian Institute of Fitness Master Trainer Award including Certificate 4 in Fitness",
            "Advanced Diploma in Nutritional Medicine - Australian Framework",
            "Australian Institute of Fitness - Certificate III in Fitness",
            "Community Coach Training Program"
          ]
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "jules_soer",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58834f71ae34490ad1417a1a"
      },
      "lastUpdated": {
        "$date": "2017-11-21T21:26:09.349Z"
      },
      "createdAt": 1485000561686,
      "email": "crayfordweights@shoomon.xyz",
      "firstName": "Crayford",
      "surname": "Weights Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ln3YibCQIsswZ3WB+85Pxw==",
      "hashedPassword": "9zkOnvGocKTHENjUTxqgplYetXO+JF0lLFfNmJzCQctarumdjqJU7eqKpzCE/41wZNygc9qN5PivoAejLEnj7w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IncWC6Lb-crayfordlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Personal Training",
            "Sports Massage",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.crayfordweightsandfitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NlcxSWio-crayfordbg.jpg",
            "size": {},
            "offset": 0
          },
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nOPENING TIMES:\n\nMonday\t6am - 10pm\nTuesday\t6am - 10pm\nWednesday 6am - 10pm\nThursday\t6am - 10pm\nFriday 6am - 10pm\nSaturday\t8am - 6pm\nSunday 8am - 6pm",
          "strapLine1": "The official Hammer-strength gym bringing you more",
          "biography": "CWF is Kent’s largest and arguably most impressive gym: \n\nWe offer a huge range of equipment for all types of training. You can enjoy high and low energy classes which are FREE for all members. \n\nOther benefits include sunbeds, REALFIT (High Intensity & Functional Training) and sports massage & rehabilitation.\n\nAll members will have their own account login on the CWF site, which allows them to book classes and receive personalised messages from the CWF Team.",
          "locations": [
            {
              "geolocation": [
                0.18364299999996092,
                51.4510055
              ],
              "city": "Dartford",
              "postcode": "DA1 4LB",
              "_id": {
                "$oid": "5a1499f12288dd3a30f3f4ef"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Crayford Weights and Fitness",
          "phone": {
            "number": "1322525638",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "crayford_weights_and_fitness",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58835896ae34490ad1417c1c"
      },
      "lastUpdated": {
        "$date": "2017-11-21T21:13:30.067Z"
      },
      "createdAt": 1485002902804,
      "email": "thefitnessconnectionsandwich@shoomon.xyz",
      "firstName": "Fitness",
      "surname": "Connection Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "NZa1jLxUUshXWwShw3ebSg==",
      "hashedPassword": "R6oSM5bOXDtQb6fxg+uKWGSHnwUg7tBuhwp2qX1MVTmTMsQFjBh5S6+uwlYcZtzPaT1XOjQjPrzpFLQx/tTFZg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kzszt1jx-thefitnessconnectionlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Diet/Meal Plans",
            "Fitness Classes",
            "Personal Training",
            "Sports Massage"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://fitnessconnectionsandwich.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/QI5wWuVw-thefitnessconnectionbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The Health & Fitness Club specializing in beginners and the over 40s - all are welcome",
          "biography": "Our award-winning, adults-only fitness club, offers superb personal service in a friendly relaxed atmosphere for members of all ages, shapes, sizes and abilities. We specialise in fitness and superb quality, affordable Personal Training for all levels including seniors and beginners, offering a unique, ‘Easy Start’ for all.\n\nEstablished since 1988, we offer superb, modern facilities, including fully equipped, air conditioned gym, a range of fun fitness and relaxation classes, including: Pilates, aerobics, Yoga, H.I.I.T and Insanity Live, along with our renowned One2One service with our friendly, mature team, helping you to achieve your personal goals.\n\nThe unique personal service at Fitness Connection includes full lifestyle overview and health check, dietary tips, goal setting, motivation and much more! Beginners to advanced are welcome, and for the less experienced, we offer our ‘Easy-Start’ programme, comprising of multiple One2One sessions – not just a quick induction, so you will feel really comfortable and at ease at the club. You will love the service here!\n\nFor the more advanced, our friendly mature team {offering more than 80 years experience between us} can give you all the help and attention you want and need to achieve your fitness and weight goals, having trained everybody from beginners through to professional and competitive athletes (even Olympians!).",
          "yearsOfExp": 1988,
          "offers": "Please visit our website for full details of our prices, packages and offers",
          "locations": [
            {
              "geolocation": [
                1.3416895000000295,
                51.2749824
              ],
              "city": "Sandwich",
              "postcode": "CT13 9EB",
              "_id": {
                "$oid": "5a1496fa2288dd3a30f3f4ee"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Fitness Connection",
          "phone": {
            "number": "1304614000",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Air Conditioning"
          ],
          "testimonials": "“Brian and the team are so helpful, the atmosphere is always friendly, and the members all very nice. It’s enabled me to stay fit, healthy and full of energy. I would recommend anyone thinking of joining to do so, its very relaxed and you never feel out of place or intimidated like you can in some gyms, you can be yourself and progress at your own speed with excellent advice and encouragement from the staff and other members.” GRAHAM SIMPSON\n\n“When Brian guaranteed me great results, I had absolutely no idea I would achieve my goals in such a short time! To get in shape for my wedding in just over two months, I lost a stone, toned up, dropped a size top & bottom & and lowered my blood pressure, the results were amazing! \nThe PT packages are really motivational, challenging but fun. I never know what I’m going to do next as it constantly changes which is fantastic. I would recommend the Personal Training 100%”\nJENNY DEAKIN\n\n“I’m absolutely delighted with my results, I’ve lost two and a half stone and dropped 3 dress sizes. I’ve toned up, and feel fitter and healthier than ever before! They are a great bunch at the club, really friendly, positive and helpful to everyone. My husband is so impressed, he’s now joined too! Join, I guarantee you won’t regret it!”\nBELINDA PIDGEN"
        },
        "score": 70
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_fitness_connection",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58836cedae34490ad141809e"
      },
      "lastUpdated": {
        "$date": "2017-11-21T21:00:37.216Z"
      },
      "createdAt": 1485008109953,
      "email": "halogym@shoomon.xyz",
      "firstName": "Halo Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "bOhCGyZzseBJl7aaeokV4Q==",
      "hashedPassword": "FpRdDASTtXaHVsUWFpwpfE+Glo6Oq7fj3jW8jV0BRik83pysl2p+JX4xfkVKVeZ/t/x9g4pOip9wyD0LBcF+AQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/j0KvBRkO-halologo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Fitness Classes",
            "Kids Classes",
            "Osteopathy",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.halogym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PZSLwkTR-halobg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "We are the leading independent fitness centre in Tunbridge Wells, designed for people who want to train and exercise in friendly surroundings and those who prefer a more personal approach.\n\nHalo Gym houses a range of state-of-the-art fitness equipment and personal trainers.\n\nOur personal trainers have extensive knowledge, experience and qualifications, please visit our website for full details\n\nWe also have a highly qualified Osteopath, find out more at www.facebook.com/AndreTreenOsteopath/",
          "strapLine1": "Tunbridge Wells' leading independent gym",
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nOPENING TIMES:\n\nMonday to Fridays: 6.00am - 9.30pm\nSaturdays: 8am - 6pm\nSundays: 10am - 4pm\nBank Holidays: 8am-6pm",
          "locations": [
            {
              "geolocation": [
                0.26143419999993966,
                51.135709
              ],
              "city": "Royal Tunbridge Wells",
              "postcode": "TN1 1HA",
              "_id": {
                "$oid": "5a1493f52288dd3a30f3f4ec"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Halo Gym",
          "phone": {
            "number": "1892548700",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Boxing Ring",
            "Olympic Lifting",
            "Sprint Track",
            "Punch Bags",
            "Benches",
            "Racks",
            "Sled"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "halo_gym",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "588376adae34490ad14182a2"
      },
      "lastUpdated": {
        "$date": "2017-11-28T10:32:24.387Z"
      },
      "createdAt": 1485010605561,
      "email": "assassinsgym@shoomon.xyz",
      "firstName": "Assassins",
      "surname": "Gym enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "2mhxJP/RGQHumbWADo10Qg==",
      "hashedPassword": "B8A5uhJ9s+a2oZUWE7MGcnY9+awTTmMlTmScA7Oli9YSeb4+gDXyVQGWjy9wlII2oxzT29Y/WnF1NHk/k8Ht6Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GXsHsWzz-assassinslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fight Preparation",
            "Fitness Classes",
            "Kickboxing",
            "Kids Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://assassinsgym.co.uk/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qula3sqV-assassinsbg.jpg",
            "size": {},
            "offset": 0
          },
          "offers": "Please visit our website for details of prices, packages and offers.",
          "biography": "Assassins Gym caters for those with an enthusiasm for general fitness all the way through to the serious body builder.\n\nAssassins Gym is a friendly and professional venue in Crowborough that offers kickboxing, personal training, exercise classes, kettlebells and bootcamps. We cater for those with an enthusiasm for general fitness all the way through to the serious body builder. \n\nOur fully-loaded gym boasts the highest- quality equipment, a huge range of custom made weights and a full size boxing ring with all the Martial Arts accessories to train the novice right through to a world champion.",
          "strapLine1": "Home of world champions and record breakers: Gym - K1 Kickboxing - boxing",
          "locations": [
            {
              "geolocation": [
                0.18998680000004242,
                51.04740409999999
              ],
              "city": "Lexden Lodge Industrial Estate",
              "postcode": "TN6 2NQ",
              "_id": {
                "$oid": "5a1d3b387d852e353acb6398"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Assassins Gym",
          "phone": {
            "number": "1892610933",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Boxing Ring",
            "Combat Zone",
            "Battle Ropes",
            "Kick / Body  Shields",
            "Punch Bags"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "assassins_gym",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "58837cb1ae34490ad14183e4"
      },
      "lastUpdated": {
        "$date": "2017-11-21T20:27:49.220Z"
      },
      "createdAt": 1485012145827,
      "email": "isenhurstcrowborough@shoomon.xyz",
      "firstName": "Isenhurst",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Bm+/JrDjgRMuqjeg6lnBQQ==",
      "hashedPassword": "u6jQ3UceimCUPjKPmsnbzIz13GEoQ+a9DfIG0eTXanfW3ydeJlcNXbmqNMFy8YZr5oHyEVoT9DhZRibrGMBCWw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ALsoHKzf-isenhurstlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Fitness Classes",
            "Ladies Only Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://isenhurst.co.uk/our-clubs/crowborough/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/n2T7jY20-isenhurstbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Isenhurst Health Clubs offer a range of facilities to accommodate all abilities, fitness and leisure interests.\n\nOpening its first club in Cross in Hand twenty years ago, the chain also has clubs in Crowborough and Uckfield.\n\nEach club is equipped with the latest innovative gym equipment including treadmills, striders, indoor bikes and rowers from leading gym brands Life Fitness and Concept2. The clubs also have stretch rooms, weight floors, fitness studios, changing rooms and a 15 metre swimming pool. Saunas and spa pools are also available at each club, as well as a coffee and lounge area.\n\nIsenhurst Health Clubs prides itself on having some of the best Personal Trainers in the area. The PTS run popular group classes in the fitness studios, well as being available for one-to-one, tailor-made training sessions. \n\nIf you are looking for a friendly and informal health club to help you with weight loss or improving athletic performance or just somewhere for much needed R&R, then Isenhurst Health Clubs is for you.\n\nThe baby of the group,this purpose-built, flagship health club, houses the chain’s largest gym. It also benefits from a separate spinning studio and a swimming pool with a large spa pool running alongside it. \n\nThere are daily spinning classes on offer, as well as a variety of other classes available in the fitness studio including Body Conditioning, Zumba, Body Balance, Cardio Blast, Yoga and Kettlebell Circuits. The club also has a coffee and lounge area.",
          "offers": "Please visit our website for full details of our prices, packages and offers.\n\nOpening Hours:\n\nMonday – Friday: 7am – 10pm\nSaturday and Sunday: 8am – 8pm\nMembers are requested to vacate the pool area 30 minutes before closing time.",
          "strapLine1": "Be part of the movement",
          "google": "https://plus.google.com/115945049033036313902",
          "locations": [
            {
              "geolocation": [
                0.18478640000000723,
                51.0457388
              ],
              "city": "Crowborough",
              "postcode": "TN6 2JD",
              "_id": {
                "$oid": "5a148c452288dd3a30f3f4e1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Isenhurst Health Club",
          "phone": {
            "number": "1892661777",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Swimming Pool",
            "Spa Pool",
            "Fitness Studio",
            "Cycle Studio",
            "Lounge Area",
            "Cafe",
            "Sauna",
            "Steam Room"
          ]
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "isenhurst_health_club_SJkGwdxgM",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "588380b8ae34490ad14184a6"
      },
      "lastUpdated": {
        "$date": "2017-11-20T15:27:33.048Z"
      },
      "createdAt": 1485013176009,
      "email": "isenhurstcrossinhand@shoomon.xyz",
      "firstName": "Crossin Hand",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "oGOm7CJKDxnBtZw8LH8jIw==",
      "hashedPassword": "MJ0AKz2qRY82YAP/KIYcOVDh0GLvify41OIkNv3SKsV+zmzeCzBmhSV65FOR5Oj61nxmbb4PPTTwax7mJubFuw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zpfpKXQX-isenhurstlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://isenhurst.co.uk/our-clubs/cross-in-hand/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OczhkNDW-isenhurstbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Be part of the movement",
          "biography": "Isenhurst Health Clubs offer a range of facilities to accommodate all abilities, fitness and leisure interests.\n\nThis is where it all started! The original club of the group built twenty years ago, Isenhurst Health Club in Cross in Hand is a buzzy, friendly place to train and is renowned for its popular circuit class that takes place three times a week. The studio is also kept busy with a variety of classes including Body Balance, Primal Fitness, HIIT Training, Kettlebells, Yoga, Lifecycle GX Group Cycling, Abs to name but a few.\n \nThe club’s 15 metre swimming pool is the only pool in the Heathfield area, but is available to club members only.\n\nIn celebration of the club's twentieth year, a brand new Strength & Conditioning studio is being built. Home to a fully functional training rig, the new room will allow members to work on a variety of muscle groups for athletic style training.\nThink CrossFit! Weight training classes will now take place in the studio, which has also been equipped with new rowers, Ski Ergs, Aerodyne bikes as well as Plyometric boxes.\n\nThis club also has a beauty salon as well as a coffee and lounge area.",
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nOpening Hours:\n\nMonday – Friday: 6am – 10pm\nSaturday and Sunday: 8am – 8pm\nMembers are requested to vacate the pool area 30 minutes before closing time.",
          "locations": [
            {
              "geolocation": [
                0.22307390000003124,
                50.97378699999999
              ],
              "city": "Cross in Hand",
              "postcode": "TN21 0UE",
              "_id": {
                "$oid": "5a12f465430ff53fda549bdd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Isenhurst Health Club",
          "phone": {
            "code": "+44",
            "number": "1435868616"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Rig",
            "Swimming Pool",
            "Sauna",
            "Steam Room",
            "Cafe",
            "Lounge Area",
            "Parking",
            "Wi-fi Access"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "isenhurst_health_club_H11gVOglG",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58838367ae34490ad1418568"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:38:38.359Z"
      },
      "createdAt": 1485013863155,
      "email": "isenhurstuckfield@shoomon.xyz",
      "firstName": "Isenhurst",
      "surname": "Uckfield Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "rmqTE4bxkA3wYYZ85mZK6A==",
      "hashedPassword": "QBKVU82bkcjqNJQ2FNQGS4kfGO+47JAKeECjICJHLV9goUxymMhYz7F/VyTVom41KpHIzFLavXhqfyHcUqv4LA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nyCHwpoH-isenhurstlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Ladies Only Classes",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://isenhurst.co.uk/our-clubs/uckfield/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RLNzvttp-isenhurstbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Be part of the movement",
          "biography": "Isenhurst Health Clubs offer a range of facilities to accommodate all abilities, fitness and leisure interests.\n\nOpening its first club in Cross in Hand twenty years ago, the chain also has clubs in Crowborough and Uckfield.\n\nEach club is equipped with the latest innovative gym equipment including treadmills, striders, indoor bikes and rowers from leading gym brands Life Fitness and Concept2. The clubs also have stretch rooms, weight floors, fitness studios, changing rooms and a 15 metre swimming pool. Saunas and spa pools are also available at each club, as well as a coffee and lounge area.\n\nIsenhurst Health Clubs prides itself on having some of the best Personal Trainers in the area. The PTS run popular group classes in the fitness studios, well as being available for one-to-one, tailor-made training sessions. \n\nIf you are looking for a friendly and informal health club to help you with weight loss or improving athletic performance or just somewhere for much needed R&R, then Isenhurst Health Clubs is for you.\n\nUckfield was the second health club to open. A hidden gem on the Bellbrook Industrial Estate, you will be forgiven for thinking it is just an ordinary industrial unit from the outside. \n\nBut step inside, you will find a well-equipped gym, kitted out with state of the art machines, a spacious basement studio and a swimming pool that definitely takes centre stage. The pool area is also home to a spa and sauna and also has a coffee  &lounge area.",
          "offers": "Please visit our website for full details of our prices, packages and offers.\n\nOpening Hours:\n\nMonday – Friday: 6am – 10pm\nSaturday and Sunday: 8am – 8pm\nMembers are requested to vacate the pool area 30 minutes before closing time.",
          "locations": [
            {
              "geolocation": [
                0.08939510000004702,
                50.9692224
              ],
              "city": "Uckfield",
              "postcode": "TN22 1QL",
              "_id": {
                "$oid": "5a14487e2288dd3a30f3f4bc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Isenhurst Health Club",
          "phone": {
            "number": "1825749496",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Swimming Pool",
            "Cycle Studio",
            "Fitness Studio",
            "Spa Pool",
            "Sauna",
            "Steam Room",
            "Cafe",
            "Lounge Area",
            "Changing Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "isenhurst_health_club",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "588388a6ae34490ad141866a"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:37:43.765Z"
      },
      "createdAt": 1485015206976,
      "email": "gaches@shoomon.xyz",
      "firstName": "George",
      "surname": "Gache",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "mehLdMtYKzOYnuLNPaO7bQ==",
      "hashedPassword": "EAXBRhCPeZI/y1TQFHICD3GO6oQEQWhXwu1MhHTk6Yp0iBeSVKjE8j5uEbFVpbtAl+BonaY/KhenJwfxwh5UjA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jQw6XUIt-gachesprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://gachehealthclub.webs.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nX3e1XnX-gachesbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "A second-to-none fitness service",
          "biography": "My name is George Gache, a fully-trained fitness professional with over 15 years' experience in the fitness industry. This included 4 years working as a personal trainer in Valencia, Spain with different clients of the Americas Cup sailing teams in 2007.\n\nAt Gache's Health Club we are focused on providing you with our highest levels of customer satisfaction – we will do everything we can to meet your goals and expectations.\n\nWe can offer a variety of tailor-made fitness programmes, including cardiovascular workouts, core strengthening and toning. \nWe are confident you will be happy working out in our relaxed environment. \n\nFeel free to look around our website for more details and information. Don't forget to ask us any questions you may have - just visit the 'Contact Us' page! Check back later for new updates to our website. There’s much more to come!",
          "offers": "Pleas visit the website for full details of our prices, packages and offers.\n\nOpening Hours:\n\nMonday:  Closed\nTuesday: 10.00 -13:00 Re-open: 17:00- 19:30\nWednesday:10:00 -13:00 Re-open: 17:00 - 19:30\nThursday: 10.00 -13:00 Re-open: 17:00- 19:30\nFriday: 10.00 -13:00 Re-open: 17:00- 19:00\nSaturday: 10:00-16:00\nSunday: Closed\nBank Holidays: Closed",
          "yearsOfExp": 1999,
          "locations": [
            {
              "geolocation": [
                0.03823660000000473,
                51.0986687
              ],
              "city": "Forest Row",
              "postcode": "RH18 5DW",
              "_id": {
                "$oid": "5a1448472288dd3a30f3f4bb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Gache's Health Club",
          "phone": {
            "number": "1342458257",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Changing Facilities",
            "Free Parking"
          ]
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "gaches_health_club",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58838ee5ae34490ad14187ec"
      },
      "lastUpdated": {
        "$date": "2017-11-16T10:12:49.516Z"
      },
      "createdAt": 1485016805870,
      "email": "resultshappen@shoomon.xyz",
      "firstName": "Results",
      "surname": "Happen Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "esHD3yTVDHl2AXMYszVF8Q==",
      "hashedPassword": "4e6dQ4s9t4o2DM47bLLQ2qpCs99s9sJFDSytbOrNHcJNi79TYcarg43I2CUEJE8c+5+7GU3fl2NhMEDtu/cmRw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uBhVNDL3-resultshappenlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Boot Camp",
            "Fitness Classes",
            "Personal training",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://resultshappenpt.com/",
          "profileTitle": "Fitness Trainer Group",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Sf4JvFOS-resultshappenbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "We are a small(ish) fitness company with big aspirations, but our main goal is to ensure our clients, class members and followers all get RESULTS (hence the company name, clever hey?).\n\nWe've grown over the years from a personal-training company operating out of local mainstream gyms to a fitness company offering PT, group-fitness classes, bootcamps, fitness events and sports therapies - we even have our own specialised private facility now.\n\nThe Results Happen Fitness Hub is now open: if you are looking for an exclusive and private place where you can focus on yourself and your own goals then this could be the centre for you! Our unique NO INTIMIDATION facility ensures more focus on your goals and ultimately results. \n\nIf any of this sounds like your bag, give us a shout any time - we will be happy to hear from you! TEAM RESULTS HAPPEN",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours: \n\nMon - Thurs: 6:15am - 8:00pm\nFri: 6:15am - 7:15pm\nSat: 9:00am - 2:00pm\nSun: Closed",
          "strapLine1": "The Personal Training Studio Where You Come To Reach Your Goals",
          "locations": [
            {
              "geolocation": [
                -0.06839679999995951,
                51.16141669999999
              ],
              "city": "Newchapel",
              "postcode": "RH7 6HS",
              "_id": {
                "$oid": "5a0d64a1430ff53fda549ba9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Results Happen Fitness",
          "phone": {
            "code": "+44",
            "number": "7584865129"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Functional Training Rig",
            "Synthetic Track"
          ],
          "testimonials": "“This was my third RHPT Games and it was definitely the best so far, especially as Rob hosted them at his state of the art, brand new Fitness Hub!\nAs always Rob & his team had put in a mountain of of preparation effort which resulted in a thoroughly enjoyably four hours of challenges that pushed near on every part of my body to the limit! For anyone who has not yet attended I can totally recommend the Games, what better way to spend a Saturday afternoon than in great company and surprising yourself with what you can do! Burning more than 1700 cals was a nice feeling too, especially after the naughtiness of a recent holiday!”\n\nJames Watson\n\n“Accommodating, motivating and inspirational.\nI hired Nina in November 2012 to help me achieve my goal of a sub 2 hour half marathon. Having been a runner and a regular gym goer (so no couch potato) I was still amazed by the increase in my fitness levels, in particular getting up hills at a decent speed and holding a conversation (that I have never been able to do!).\nI find Nina to be accommodating, motivating and inspirational. Nothing is too much trouble, she is even going to run the Brighton half marathon as my pace maker to ensure I achieve my goal.\nI would not hesitate to recommend Nina she is a great PT!!''\n\nNicki Degnan\nTraining with Nina\nRHPT silver client"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "results_happen_fitness",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5883935fae34490ad14188ae"
      },
      "lastUpdated": {
        "$date": "2017-11-28T13:38:25.087Z"
      },
      "createdAt": 1485017951544,
      "email": "omfhorsham@shoomon.xyz",
      "firstName": "OMF",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "NSuBWSSAT2x4vnKX9028gg==",
      "hashedPassword": "9J+caTb1X2JCGU6xcVIPyx2CW+AIutvq/CeSpmagBJq9ObInwIdhhjZ6Sf8Z+Fa8SS7IG3SsVw71IM0qRnhCgQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8gSiYTx7-omfhorsham.jpg"
        },
        "personal": {
          "disciplines": [
            "All Fitness Levels",
            "Bootcamp",
            "Group Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.outdoormilitaryfitness.co.uk/",
          "profileTitle": "General",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/muhj4Zx7-omfhorshambg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Run by currently-serving or ex-forces instructors with a fitness background",
          "biography": "Outdoor Military Fitness (OMF) Horsham Park, Albion Way, Horsham, West Sussex RH12 1AU:\n\nOur classes here are located in Horsham Park which is in the centre of the town. The park is a haven of green space to work out in with a lake, trees and even a skate park! After the morning classes we often frequent the local cafe for a tea or coffee.\n\nThe meeting point will be by the Superbowl car park, off Albion Way or look for the OMF banner on the South side of the park. For Sat Nav please use RH12 1AH.\n\nPlease note that the car park is a pay and display.\n\nEstablished over three years ago, we are the first to bring natural outdoor exercise to Victoria Park - Haywards Heath, Deers Leap Park - East Grinstead, Horsham Park - Horsham and Grattons Park - Crawley.\n\nOMF is an effective all over body workout lead by ex-military instructors. We create an infectious, fun outdoor classes which are suitable for men and women of all fitness levels. There is no chance to get bored with repetitive workouts as no class is ever the same. Each Session will include a varied mix of circuit training, relays, partner exercises and running. You will achieve all over body fitness.\n\nThis style of training is ideal for weight loss, increasing your fitness level or building muscle whilst making new friends.\n\nOMF Classes run all year, regardless of the weather.\n\nLife begins outside your comfort zone so come and give us a try. Just click on the Free Trial link and come along to a class.\n\nFirst class is free!",
          "offers": "Please visit our website for details on prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.3280647000000272,
                51.06477750000001
              ],
              "city": "Horsham",
              "postcode": "RH12 1AY",
              "_id": {
                "$oid": "5a1d664b7d852e353acb63bc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Outdoor Military Fitness - Horsham Park",
          "phone": {
            "number": "7974801611",
            "code": "+44"
          },
          "qualifications": []
        },
        "score": 57
      },
      "active": true,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "outdoor_military_fitness_-_horsham_park",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "588397d4ae34490ad14189b0"
      },
      "lastUpdated": {
        "$date": "2017-11-28T13:38:23.721Z"
      },
      "createdAt": 1485019092313,
      "email": "omfcrawley@shoomon.xyx",
      "firstName": "OMF",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "wXj075wOkCMP/RklS7S7Pw==",
      "hashedPassword": "LuPVPqmhqZfUOyXaWa7I6IxqVWSh7Yd9hO5SOayXxFdQV9ksSIE4OfUc//cLXqaUdTcpc+6i/8/R1d0beASfJg==",
      "settings": {
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "social": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/THOQ2YpO-omfhorsham.jpg"
        },
        "personal": {
          "disciplines": [
            "All Fitness Levels",
            "Bootcamp",
            "Group Classes",
            "Personal Training",
            "Micro Group Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.outdoormilitaryfitness.co.uk/",
          "profileTitle": "General",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/R69o8u9z-omfcrawleybg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Run by currently-serving or ex-forces instructors with a fitness background",
          "biography": "OMF Grattons Park, Crawley, West Sussex, RH10 3BA:\n\nOur classes in Crawley are held at Grattons Park in Pound Hill. This is a nice park to work out with 2 lakes and it also has a tributary of the River Mole running through it.  We often stop in the Tavern on the Green for a cup of tea or coffee after the morning classes.\n\nThe meeting point will be just in front of the Tavern On The Green pub. Look for the OMF banner on Grattons Drive.\n\nPlease note that there is also additional parking spaces behind the parade of shops.\n\nSat Nav use RH10 3BA\n\nEstablished over three years ago, we are the first to bring natural outdoor exercise to Victoria Park - Haywards Heath, Deers Leap Park - East Grinstead, Horsham Park - Horsham and Grattons Park - Crawley.\n\nOMF is an effective all over body workout lead by ex-military instructors. We create an infectious, fun outdoor classes which are suitable for men and women of all fitness levels. There is no chance to get bored with repetitive workouts as no class is ever the same. Each Session will include a varied mix of circuit training, relays, partner exercises and running. You will achieve all over body fitness.\n\nThis style of training is ideal for weight loss, increasing your fitness level or building muscle whilst making new friends.\n\nOMF Classes run all year, regardless of the weather.\n\nLife begins outside your comfort zone so come and give us a try. Just click on the Free Trial link and come along to a class.\n\nFirst class is free!",
          "offers": "Please visit our website for details on prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.15532519999999295,
                51.12734259999999
              ],
              "city": "Crawley",
              "postcode": "RH10 3BA",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Outdoor Military Fitness - Grattons Park, Crawley",
          "phone": {
            "number": "7974801611",
            "code": "+44"
          },
          "qualifications": []
        },
        "score": 73
      },
      "active": true,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "outdoor_military_fitness_-_grattons_park,_crawley",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58839b5cae34490ad1418a72"
      },
      "lastUpdated": {
        "$date": "2017-11-28T13:38:22.360Z"
      },
      "createdAt": 1485019996217,
      "email": "omfhaywardsheath@shoomon.xyz",
      "firstName": "OMF",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "9CFjVEysD6szGut88vwH1w==",
      "hashedPassword": "2ApWjOM14TFN+HrDUwwe+ZnfZ1VZoYRjLwKvPrH6l4oLpMCYa3dGlApaDK+JswqlRbuzS5DkWgIz+ATTtvg6dA==",
      "settings": {
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "social": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vsiKDHCd-omfhorsham.jpg"
        },
        "personal": {
          "disciplines": [
            "All Fitness Levels",
            "Bootcamp",
            "Group Classes",
            "Personal Training",
            "Micro Group Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.outdoormilitaryfitness.co.uk/locations/haywards-heath/",
          "profileTitle": "General",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AvSXzkRh-omfhaywardsheathbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "OMF Victoria Park, Haywards Heath, West Sussex, RH16 4LY\n\nWe are located within Victoria Park in the heart of Haywards Heath. Exercising here offers long views south towards the South Downs with cheeky little hills and a nice flat area which makes for a lovely natural all over body work out.\n\nThe nearest car park is Haywards Road West (RH16 4HT) which is pay and display.\n\nLook out for the banner or the van for the meeting point just off the B2272 South Road.\n\nEstablished over three years ago, we are the first to bring natural outdoor exercise to Victoria Park - Haywards Heath, Deers Leap Park - East Grinstead, Horsham Park - Horsham and Grattons Park - Crawley.\n\nOMF is an effective all over body workout lead by ex-military instructors. We create an infectious, fun outdoor classes which are suitable for men and women of all fitness levels. There is no chance to get bored with repetitive workouts as no class is ever the same. Each Session will include a varied mix of circuit training, relays, partner exercises and running. You will achieve all over body fitness.\n\nThis style of training is ideal for weight loss, increasing your fitness level or building muscle whilst making new friends.\n\nOMF Classes run all year, regardless of the weather.\n\nLife begins outside your comfort zone so come along to a class.\n\nFirst class is free!",
          "offers": "Please visit our website for details of prices, packages and offers.",
          "strapLine1": "Run by currently-serving or ex-forces instructors with a fitness background",
          "locations": [
            {
              "geolocation": [
                -0.10366260000000693,
                50.99634
              ],
              "city": "Haywards Heath",
              "postcode": "RH16 4HT",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Outdoor Military Fitness - Haywards Heath",
          "phone": {
            "number": "7974801611",
            "code": "+44"
          },
          "qualifications": []
        },
        "score": 73
      },
      "active": true,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "outdoor_military_fitness_-_haywards_heath",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58839e26ae34490ad1418b34"
      },
      "lastUpdated": {
        "$date": "2017-11-28T13:38:20.005Z"
      },
      "createdAt": 1485020710937,
      "email": "omfeastgrinstead@shoomon.xyz",
      "firstName": "OMF",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "w2dfpA12lYzUCDD9USf/mw==",
      "hashedPassword": "yMdSTbZw6MOMNnDsxqjHwRCkLVrwMKO7qTa6scDrERlWNGgyqLCAHf7JFEPpAmv06rv5qQx/rRGCwfcHAkBpNA==",
      "settings": {
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "social": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/QdJAcxWn-omfhorsham.jpg"
        },
        "personal": {
          "disciplines": [
            "All Fitness Levels",
            "Bootcamp",
            "Group Classes",
            "Personal Training",
            "Micro Group Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.outdoormilitaryfitness.co.uk/locations/east-grinstead/",
          "profileTitle": "General",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/R6fxy6K7-omfeastgbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Run by currently-serving or ex-forces instructors with a fitness background",
          "biography": "OMF Deers Leap Park, Saint Hill Green, East Grinstead, West Sussex, RH19 4NG\n\nWe are located within Deers Leap Outdoor Adventure Park which is just on the edge of East Grinstead with tons of free parking. All of Deers Leap Park is our playground and thats over 250 acres of hilly terrain to have fun in whilst you exercise. Look out for our banners on the main road and meet us in the car park.\n\nThe meeting point will be at Deers Leap Park Car Park, Saint Hill Green, East Grinstead\nWest Sussex, RH19 4NG\n\nFor more information on Deers Leap Outdoor Adventure Park please go to deersleap.co.uk\n\nEstablished over three years ago, we are the first to bring natural outdoor exercise to Victoria Park - Haywards Heath, Deers Leap Park - East Grinstead, Horsham Park - Horsham and Grattons Park - Crawley.\n\nOMF is an effective all over body workout lead by ex-military instructors. We create an infectious, fun outdoor classes which are suitable for men and women of all fitness levels. There is no chance to get bored with repetitive workouts as no class is ever the same. Each Session will include a varied mix of circuit training, relays, partner exercises and running. You will achieve all over body fitness.\n\nThis style of training is ideal for weight loss, increasing your fitness level or building muscle whilst making new friends.\n\nOMF Classes run all year, regardless of the weather.\n\nLife begins outside your comfort zone so give us a try. \n\nFirst class is free!",
          "offers": "Please visit our website for details of our prices, packages and offers",
          "locations": [
            {
              "geolocation": [
                -0.02274149999993824,
                51.10526489999999
              ],
              "city": "Saint Hill Green",
              "postcode": "RH19 4NG",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Outdoor Military Fitness - Deers Leap Park",
          "phone": {
            "number": "7974801611",
            "code": "+44"
          },
          "qualifications": []
        },
        "score": 73
      },
      "active": true,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "outdoor_military_fitness_-_deers_leap_park",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5883a281ae34490ad1418bf6"
      },
      "lastUpdated": {
        "$date": "2017-11-15T20:57:08.281Z"
      },
      "createdAt": 1485021825754,
      "email": "gover@shoomon.xyz",
      "firstName": "Gover Gym",
      "surname": "Reception",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ERfLQUws1J+FHBmgTgnDEA==",
      "hashedPassword": "e6J8Ilut7Is1eAFB0Ix8udaPhyLUbDCYS5/nFjPh4SesEo2XX8C31n7TulJ+2qDU8Wp2lmDXs272qX9TkB5tSQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/01QCKr7i-gover-gym.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Fitness Classes",
            "Personal Training",
            "Small Group Training",
            "Sports Massage"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.govergym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3spdYhGc-goverbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "We are with you all the rep of the way",
          "biography": "GO:VER GYM IS PROUD TO OFFER A HUGE VARIETY OF CAREFULLY SELECTED TRAINING EQUIPMENT. WHATEVER YOUR GOALS, FROM PERFORMANCE TO REHAB TO WEIGHT LOSS, WE HAVE THE NECESSARY TOOLS TO GET YOU THERE:\n\nAt GO:VER GYM we take responsibility for your results. This is implemented through superior, tailored programming for all members.\nOngoing testing and re-testing to monitor progress.\nA holistic approach; Heavy emphasis on correcting biomechanics and correct lifting form.\nAn enthusiastic team with a passion for training and a wealth of knowledge.",
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nOpening Hours:\n\nMon-Fri: 06.00-21.00\nSat-Sun (and Bank Holidays): 09.00-18.00",
          "locations": [
            {
              "geolocation": [
                0.27544639999996434,
                50.9748198
              ],
              "city": "Broad Oak",
              "postcode": "TN21 8RU",
              "_id": {
                "$oid": "5a0caa24430ff53fda549ba1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Go:ver Gym",
          "phone": {
            "code": "+44",
            "number": "1435408607"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Personalised Programs",
            "Biomechanics",
            "Osteopathy",
            "Reflexology",
            "Mctimoney Chiropractic",
            "Fitness Tests",
            "Free Consultation",
            "Online Training"
          ],
          "testimonials": "‘Until I met the GO:VER team I had had little enthusiasm for fitness, it was something that other people did. But, now 12 months later I am addicted, the team are professional, knowledgeable, encouraging and great fun. Working with them has impacted my whole life, I'm fitter, healthier and have a far more energy for life, I suggest that you GO:VER it too!!’\n\nStuart\nMiddle aged, but feeling younger!!'\n\n“I can't thank Serena enough. I have had, over the past year, a severe shoulder problem which she has helped me with enormously. More recently she is getting me through back problems. Her treatment is effective and she talks you through the process, which is not only helpful, but relaxing. I absolutely recommend her.”\n\nMrs. B.D\n\n\"As a nurse and a mother, my back comes under a lot of strain so it was no surprise when I hurt my back carrying my three year old. I found that I was unable to stand up straight and spent a few days hobbling around in excruciating pain. I limped in to see Serena and was able to walk out! The next day my back felt so much better that I couldn't believe it. After a couple of sessions there were not even any remainder twinges. I have seen a McTimoney Chiropractor before, but even I was surprised at how quickly Serena sorted my back. Thank you.\"\n\nMrs G.P"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "go:ver_gym",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5883aaf3ae34490ad1418db8"
      },
      "lastUpdated": {
        "$date": "2017-11-15T20:37:26.775Z"
      },
      "createdAt": 1485023987490,
      "email": "gymandgo@shoomon.xyz",
      "firstName": "Gym and Go",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "725fgH1i88XtpPv41ndfjQ==",
      "hashedPassword": "8AUmrWCJSxI63dF1W9uHFDK0dl9+yJVGQMHzKN8Dd5YuQcqdrB8yXSwxuki1J+RpvCLV4cDNSWu0Z6NamqgROw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/bRmdVJBo-gymandgologo.jpg"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Ladies Only Gym",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.gymandgo.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/b465VWZF-gymandgobg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Our women-only environment means that no matter what your shape or size is, you will be totally comfortable working out with us.\nAll our clients will be given a specific programme according to their needs within an easy going and friendly atmosphere.\nOur ladies only gym includes cardiovascular conditioning equipment, isolation exercise machines, multi-gym and vibration  plate.\nOne induction will be given to all users to ensure they achieve optimum benefit from their sessions.\nAn induction is an explanation of the machines available, with information of what each machine does, how it works and how it can best help you to achieve your personal goals.",
          "offers": "Please visit our sebsite for details of our prices, packages and offers.\n\nOpening Hours - Gym And Sunbeds:\n\nMonday - Friday: 9.30am - 10.00pm\nSaturday: 9.00am - 6.00pm",
          "strapLine1": "Women-Only Gym And Unisex Sunbed Studio",
          "locations": [
            {
              "geolocation": [
                0.5269313999999667,
                51.2715732
              ],
              "city": "Maidstone",
              "postcode": "ME15 6LE",
              "_id": {
                "$oid": "5a0ca586430ff53fda549b99"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Gym and Go",
          "phone": {
            "code": "+44",
            "number": "1622663199"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "gym_and_go",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5883ae45ae34490ad1418e7a"
      },
      "lastUpdated": {
        "$date": "2017-11-15T20:19:26.576Z"
      },
      "createdAt": 1485024837470,
      "email": "ministryofmuscle@shoomon.xyz",
      "firstName": "The Ministry",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "51jJZ/wqPi4OZBc/bO6xuw==",
      "hashedPassword": "xy3o1nFQrOkRARTwar9hadn+74L8WKaEQad263obzrIzuOHV3/UuapwoYL34moojZrH1gJXBTThhnhuQA7RClg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mh4ACzWJ-ministryofmusclelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Boxing",
            "Martial Arts",
            "Personal Training",
            "Power Lifting"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.ministryofmuscle.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xxyBu20S-ministryofmusclebg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Ministry of Muscle provides Specialist training in several different areas including Bodybuilding, Powerlifting, Martial arts, Strongman and Boxing.\n\nEvery coach not only teaches, but has experience in competing not only nationally but internationally in each different area.\n\nMIXED MARTIAL ARTS AND PERSONAL TRAINING:\n\nIf your one of those people that want to get to the gym, but do feel intimidated and not sure as to what you are doing then our mixed martial arts expert and personal trainer could change that for you.\nOffering a range of different classes and one to one sessions, our friendly yet professional approach to weight training and mixed martial arts will show you a new way of Ministry of Muscle Gym training.\n\nSTRONGMAN:\n\nStrongman has become the in vogue iron sport of the new millennium bringing with it many great champions, most of which were at school when the greats were competing at Strongman. Having been through all aspects of the sports and coming out the other end, we have over 20 years of competitive experience here at the Ministry of Muscle Gym. This is why we are producing champions.\n\nPOWERLIFITNG:\n\nMaidstone has had an illustrious history of creating great powerlifters. We have for a long time created many champions, this process has been taken one step further by combining the knowledge and scientific techniques to increase this tenfold.",
          "offers": "Please contact us for details of our prices, packages and offers",
          "locations": [
            {
              "geolocation": [
                0.44686490000003687,
                51.31111959999999
              ],
              "city": "Kent",
              "postcode": "ME20 6GP",
              "_id": {
                "$oid": "5a0ca14e430ff53fda549b93"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Ministry of Muscle - Aylesford",
          "phone": {
            "code": "+44",
            "number": "1622792211"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Personalised Program",
            "Nutrition Advice"
          ]
        },
        "score": 54
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_ministry_of_muscle_-_aylesford",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5883b50bae34490ad1418ffc"
      },
      "lastUpdated": {
        "$date": "2017-11-15T20:08:52.536Z"
      },
      "createdAt": 1485026571872,
      "email": "moorenergy@shoomon.xyz",
      "firstName": "MoorEnergy",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "7joEVABE6FScHukDfk0EHg==",
      "hashedPassword": "KTRtIAgW4Gw4TnXgxmRw4m8OVe/wS4u/c31a1KYNQJEUThyXAMtRzGDmwce+i2zOGUAQvn/yagglQWBO1UD2rA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/viicDwiu-moorenergyprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Fitness Classes",
            "Personal Training",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.moorenergyfitnessclub.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6TtdSIGt-moorenergybg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "It Is Our Aim To Bring A Sense Of Wellbeing To Your Life",
          "biography": "Set in the heart of Westerham, Kent, we are a Health and Fitness Club with a state-of-the-art, fully air-conditioned gym containing both cardiovascular and resistance-training equipment, together with a weights area. \nWe have a studio with various exercise classes, Personal Trainers and Sports Massage.\nLet us assist and help motivate you in achieving your goal.\nAll new members are offered an induction by one of our Personal Trainers, plus a programme tailor-made for you.\nClients are advised to consult a doctor before commencing any exercise program. Participation in any exercise program is entirely at your own risk.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\n\nMon: 07:00 to 21:00\nTues: 07:00 to 21:00\nWed: 07:00 to 21:00\nThurs: 07:00 to 21:00\nFri: 07:00 to 20:00\nSat: 08:00 to 15:00\nSun: 08:00 to 13:00",
          "locations": [
            {
              "geolocation": [
                0.0711549999999761,
                51.26807969999999
              ],
              "city": "Westerham",
              "postcode": "TN16 1BB",
              "_id": {
                "$oid": "5a0c9ed4430ff53fda549b90"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Moorenergy Fitness Club",
          "phone": {
            "code": "+44",
            "number": "1959561622"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Air Conditioning"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "moorenergy_fitness_club",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5885a6a91fd9097568ecfc04"
      },
      "lastUpdated": 1485154027845,
      "createdAt": 1485153961985,
      "email": "manajkelly@hotmail.com",
      "firstName": "Amanda",
      "surname": "Kelly",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "dc561d55084e1987351975840b7c01ed3fa330a0",
      "salt": "O72HK4cIBFbD8tAZnATlgw==",
      "hashedPassword": "xhYFksxu6L7v9g3g7KKud5/lrjakw95C49Ts/X2gC/FVmmUaTKrNMQVDOaVFnB3w7faw33GH3waWi3KkHlEcWw==",
      "settings": {
        "wizard": {
          "complete": false,
          "start": true,
          "additional": false,
          "social": false,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": null,
          "profileTitle": "General",
          "radius": 5,
          "price": {
            "min": 45,
            "max": 80
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.08813880000002428,
                51.5412621
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "N1 3BH"
            }
          ],
          "companyName": null,
          "phone": {
            "code": "+44",
            "number": "7792602881"
          }
        },
        "score": 30
      },
      "active": false,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false
    },
    {
      "_id": {
        "$oid": "5885c8921fd9097568ed0347"
      },
      "lastUpdated": {
        "$date": "2017-11-15T19:50:38.121Z"
      },
      "createdAt": 1485162642431,
      "email": "fitonegym@shoomon.xyz",
      "firstName": "Fit One Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "4KdyZrSMyFzMc5+kV/UNwA==",
      "hashedPassword": "fhdjpF2pODpggkBYpWjFEXLdBXnUOj4DCAKglb9lVWY46qfxgna5uJCpcu2Vk09MHtZAsxXI0jg/xZFAzTi32Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1fppvtgr-fitonelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Boxing",
            "Cardiovascular",
            "Fitness Classes",
            "Power Lifting"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.fitonegym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zMIhmvdN-fitonebg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "strapLine1": "Helping You Become The Strongest Version Of Yourself!",
          "biography": "We’ve got what you’re looking for ….\n\nFit One Gym has been designed with everyone in mind, so everybody from professional rugby players and powerlifters to regular gym goers and beginners will find exactly what they are looking for.\nWe have a large free weights area with four benches, dedicated deadlift areas on a concrete floor, and dumbbells to 50kg. Straps and chalk are provided, as well as advice, support and encouragement.  Two rooms dedicated to old school weight training.\nWe hold regular competitions in the gym for members, such as the Log Challenge, lifting 40kg Overhead Press – number of reps in one minute.  We also sponsored The UK Strongman under 105kg competition. Competitors weighed in at Fit One and used our equipment in some of the competitions.\nOur classes are designed to help you develop strength and conditioning.\nCore Strength Class & Stretch and Tone Class.\nUse of the Boxing Studio, boxing gloves and mitts is included in your membership.  Boxing bag and speedball available.\nWe are approved by the NHS to support your training and rehabilitation. Everyone is welcome, no matter what your disability, weight and fitness level. We have members who have diabetes, high blood pressure, amputated limbs, depression, anxiety, panic attacks, obesity and more.   We can organise a fitness plan starting from half hour three times a week of seated exercise, but you will need to manage the stairs.",
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nOpening Hours: \n\nMon - Fri: 8:00am - 9:00pm\nSat: 9:00am - 4:00pm\nSun: 10:00am - 2:00pm",
          "yearsOfExp": 2015,
          "locations": [
            {
              "geolocation": [
                1.1774798000000146,
                51.078306
              ],
              "city": "Folkestone",
              "postcode": "CT20 2AA",
              "_id": {
                "$oid": "5a0c9a8e430ff53fda549b8d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fit One Gym",
          "phone": {
            "code": "+44",
            "number": "1303247892"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Cable Machines",
            "Benches",
            "Boxing Studio",
            "Competitions",
            "Sauna",
            "Tanning Facilities"
          ]
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fit_one_gym",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "5885cf5a1fd9097568ed0489"
      },
      "lastUpdated": {
        "$date": "2017-11-15T19:19:51.998Z"
      },
      "createdAt": 1485164378640,
      "email": "parkclubashford@shoomon.xyz",
      "firstName": "Park Club",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "P3KX2e3kMzq5H5KeJ7XOEA==",
      "hashedPassword": "wkUBb40svr/+70HFyGtKVU6BG7ip25rsWjiASuZZbNsmkZKaV37YxIKq6G1z4GGBkK+YGVgknR9YxJe5OT/UTQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1B8LFmJY-parkclublogo.png"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Kids Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://parkclub.co.uk/clubs/ashford",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MfvQL9xY-parkclubbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "More People, More Active, More Often",
          "biography": "The Park Club Ashford is an air-conditioned, modern leisure centre that houses state-of-the-art fitness equipment. It has a dead-lifting area, a cardio zone and a functional training area.\nThe Group Exercise Studio has plenty of room. It features 2 high-quality speakers for your group classes and 9 big mirrors placed on the front wall to observe your technique. The area is also fitted with toilets and air-conditioning systems.\nJohn, Hayley, Sam and Amy are our on-site personal trainers and always ready to help you achieve your goals. All of them are certified Level 2 Gym Instructors and Level 3 Personal Trainers which makes them the most reliable source of fitness knowledge.\nFeel free to drop by and ask them some questions.\nThe facility has a very large cardio area with over 30 pieces of top-quality equipment made by Life Fitness and Technogym, starting from rowing machines and ending up with treadmills and exercise bikes.\nRight next to the cardio area, the gym has a specially created oval zone used only for dead-lifting with anti-slip rubber flooring to follow the highest standards of safety.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMonday - Thursday: 06:00 - 22:00\nFriday: 06:00 - 21:00\nSaturday - Sunday: 08:00 - 19:00\nBank Holidays: 09:00 - 18:00",
          "locations": [
            {
              "geolocation": [
                0.8699765999999727,
                51.1501442
              ],
              "city": "Ashford",
              "postcode": "TN24 8TN",
              "_id": {
                "$oid": "5a0c9357430ff53fda549b88"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Park Club",
          "phone": {
            "code": "+44",
            "number": "1233229909"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Fitness Studio",
            "Cycle Studio",
            "Sauna",
            "Steam Room",
            "Lounge Area",
            "Changing Facilities",
            "Lockers",
            "Air Conditioning",
            "Free Parking"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_park_club_ashford",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5885d7341fd9097568ed064b"
      },
      "lastUpdated": {
        "$date": "2017-11-15T19:11:25.737Z"
      },
      "createdAt": 1485166388729,
      "email": "parkclubchatham@shoomon.xyz",
      "firstName": "Park Club",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "tey4LVB6/htNMisOoEgmzg==",
      "hashedPassword": "CnWTpsm9mIpBX2of8Lvmc/hi6gWCiQ+Y3mpXpj0ca7N0/2vJIXiI0sLcprQnAA+ckuZqz811WIAahHHk+BtTVg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/p8UUE3VG-parkclublogo.png"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training",
            "Physiotherapy"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://parkclub.co.uk/clubs/chatham",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PgKFKeFJ-parkclubbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "The Park Club Chatham has a large gym with state-of-the-art fitness equipment produced by industry leaders such as Life Fitness and Technogym. Equipment includes Treadmills, Bikes, Cross Trainers, Power Mills, Steppers, Sparc Trainers, and Rowers alongside a huge range of lifting equipment.\nMinimum time, maximum impact; our committed Personal Trainers will help you achieve your fitness and health goals in a natural way preventing potential injuries.\nOur large Exercise Studio is home to over 30 classes per week, including Combat, Piloxing, HIIT and Yoga! Also available for hire, we have regular guest classes including Self-Defence and Pole Dancing. Check our timetable, there is something for everyone.\nThe facility has a very large cardio area with over 30 pieces of top-quality equipment made by Life Fitness and Technogym, starting from rowing machines and ending up with treadmills and exercise bikes.\nRight next to the cardio area, the gym has a specially created oval zone used only for dead-lifting with anti-slip rubber flooring to follow the highest standards of safety.",
          "strapLine1": "More People, More Active, More Often",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours: \n\nMon - Thurs: 06:30 - 22:00\nFri: 06.30 - 21.00\nSat & Sun: 08.00 - 19.00\nBank Holidays: 08:00 - 20:00",
          "locations": [
            {
              "geolocation": [
                0.5216500000000224,
                51.35939310000001
              ],
              "city": "Chatham",
              "postcode": "ME5 0LU",
              "_id": {
                "$oid": "5a0c915d430ff53fda549b85"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Park Club",
          "phone": {
            "code": "+44",
            "number": "1634565349"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Fitness Studio",
            "Cycle Studio",
            "Squash Court",
            "Free Parking",
            "Sauna",
            "Steam Room",
            "Ladies Only Area",
            "Changing Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_park_club_chatham",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5885dc701fd9097568ed078d"
      },
      "lastUpdated": {
        "$date": "2017-11-15T19:09:03.718Z"
      },
      "createdAt": 1485167728138,
      "email": "parkclubfleet@shoomon.xyz",
      "firstName": "Park Club",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "3Df7oyD1DNEI3ci6J070mg==",
      "hashedPassword": "a00fUp1Y5CGnc7/4yxm2G1yFHq1dl2HGucnqNma+EYS4gG6Kg0zrg1hyD0s4MKz/r8Ic/+gkqZywuZFOkyTYPw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SmYWRMDq-parkclublogo.png"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Cardiovascular",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://parkclub.co.uk/clubs/fleet",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0QzqGbYz-parkclubbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "The gym at The Park Club in Fleet is a large room with over 15 elliptical steppers, more than 20 treadmills, multiple rowing machines and weightlifting equipment. We only use top performance piece of equipment produced by industry leaders such as Life Fitness and Technogym.\nThe Park Club Fleet offers a well-equipped gym with a large free weights area.\nOur on-site personal trainer is Adam Barber, a highly motivated fitness instructor striving to help our guests achieve their fitness and lifestyle goals. Feel free to drop by and have a chat with Adam.\nOur group exercise location is a large room able to house more than 30 members at any given time. It has the front wall covered in mirrors for a better visualisation of your technique. The room is fitted with a fully functional air-conditioning system.\nThe Functional Training Zone is a dedicated room equipped with a rig with dipping bars, plyo boxes, suspension training system, kettlebells, battling rope and power bags. Also home to our Olympic Lifting Platform and extensive range of free-weights; this zone has it all.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\n\nMonday - Thursday: 06:30 - 21:30\nFriday: 06:30 - 20:00\nSaturday - Sunday: 08:00 - 18:00\nBank Holidays: 09:00 - 16:00",
          "strapLine1": "More People, More Active, More Often",
          "locations": [
            {
              "geolocation": [
                -0.8417230999999674,
                51.2807873
              ],
              "city": "Fleet",
              "postcode": "GU51",
              "_id": {
                "$oid": "5a0c90cf430ff53fda549b84"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Park Club",
          "phone": {
            "code": "+44",
            "number": "1252413104"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Fitness Studio",
            "Cycle Studio",
            "Sauna",
            "Steam Room",
            "Free Parking",
            "Changing Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_park_club",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5885e4411fd9097568ed0910"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:36:19.571Z"
      },
      "createdAt": 1485169729732,
      "email": "parkclubmilton@shoomon.xyz",
      "firstName": "Park Club",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "9ieDQ5wi9H3ByzYpqM3gcg==",
      "hashedPassword": "+m/C+U1aDLMgT+eypUUNev9ITiP2mS63vrOkKN/fQH0yoklp9Bk1V4WJ2owHWuuuRmhz/j085fRc+1hpfV6nGw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tAk4Fgf1-parkclublogo.png"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Physiotherapy",
            "Swimming Lessons"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://parkclub.co.uk/clubs/milton",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tHFoBzXE-parkclubbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "More People, More Active, More Often",
          "biography": "Our gym at The Park Club Milton in Abingdon is Oxfordshire's largest, best-equipped health club with 5 rowing machines, over 15 treadmills, more than 15 elliptical steppers and many more lifting equipment. Just outside of the big hall, an additional area is dedicated to dead-lifting exercises.\nAll machines are top of the line, made by globally known producers such as Technogym and Life Fitness.\nThe Park Club Milton has a 20m, 4 lane swimming pool which allows both recreational and sportsmen to pleasantly train or relax in a clean and spacious environment. Swimming lessons for both adults and kids are available upon request.\nOver 6 committed personal trainers are always ready to go the extra mile and help you achieve your goals faster.\nThe air-conditioned group exercise studio houses more than 60 classes per week with a capacity of up to 30 people. It features a 9-mirror front wall making it easy for our guests to observe their techniques. The room has a high-fidelity sound system and air-cooling equipment installed for a better fitness experience.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMonday - Thursday: 06:30 - 22:00\nFriday: 06:30 - 21:00\nSaturday: 07:30 - 16:00\nSunday: 09:00 - 17:00\nBank Holidays: 10:00 - 18:00",
          "locations": [
            {
              "geolocation": [
                -1.3013667999999825,
                51.62414589999999
              ],
              "city": "Milton",
              "postcode": "OX14",
              "_id": {
                "$oid": "5a1447f32288dd3a30f3f4ba"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Park Club",
          "phone": {
            "number": "1235206777",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Fitness Studio",
            "Cycle Studio",
            "Swimming Pool",
            "Sauna",
            "Steam Room",
            "Free Parking",
            "Changing Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_park_club_Hy8FWW9kM",
      "feature_in_area": "none",
      "__v": 14
    },
    {
      "_id": {
        "$oid": "5885f3f01fd9097568ed0c92"
      },
      "lastUpdated": {
        "$date": "2017-11-15T15:23:20.505Z"
      },
      "createdAt": 1485173744543,
      "email": "switchgymhheath@shoomon.xyz",
      "firstName": "Switch Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "sgF1uxh3nBZAnf8n2OPIvw==",
      "hashedPassword": "2/GZesWjSehc7uLXicp6Y1uVxfljbvVCmTN3K3kTbiKuiyZpaFsBEkfAxIQY9rjOPrWMi3W3DAtgr15U9HwX9g==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9GSymtcv-switchlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Personal Training",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.switchgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/q2cKFhBz-switchbg1.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Friendly & Professional",
          "biography": "We are a small friendly Gym right in the centre of Haywards Heath.\nOur goal is to help educate you in the best ways to exercise enabling you to achieve the maximum amount of results in the minimum amount of time.\nWith all the equipment that you could need and a dedicated team of coaches, we are able to offer a safe and clean environment in which to complete your own personalised programmes.\nYou will be able to workout on your own, along side your dedicated personal trainer or join in our fun and friendly 30 minute small group training sessions which give you access to the best trainers for an intense workout at a fraction of the cost of personal training.\nSwitch Gym was the creation of Chris and Mark who after years of working in the private and council run facilities decided that people deserved a well maintained, clean, quality gym that would not cost the earth every month. This concept became a reality in 2012 when we opened the doors and allowed our first members in.\nSince that day we have grown into the exceptional gym with all the equipment and support that you could need to complete your workouts.\nIf you have tried and failed to get the results that you dream of, or are looking to start on a new fitness journey, why not come in and speak to us and see how joining the Switch family can drive you to the fitness levels that you crave.",
          "offers": "Please visit our website for full details of our  prices, packages and offers.\n\nOpening Hours:\n\nMonday to Friday: 06.30-21.00\nSaturday: 08.00-18.00\nSunday: 08.00-17.00",
          "yearsOfExp": 2012,
          "locations": [
            {
              "geolocation": [
                -0.10229119999996783,
                50.9974689
              ],
              "city": "Haywards Heath",
              "postcode": "RH16 3TH",
              "_id": {
                "$oid": "5a0c5be8430ff53fda549b68"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Switch Gym",
          "phone": {
            "code": "+44",
            "number": "1444440683"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment"
          ]
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "switch_gym",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5885fa331fd9097568ed0dd4"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:35:15.412Z"
      },
      "createdAt": 1485175347374,
      "email": "theforestgym@shoomon.xyz",
      "firstName": "Forest Gym",
      "surname": "Enquiries (Big H)",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "moZuN4lM0mMxmfL/RBMGgA==",
      "hashedPassword": "zGO55TVPu0xfI2x4dwQrziespLMcqyYK6hiD6HSexkV8OY1LFtR6ZI3zk+6i+sxJ/OChVTrU/3yjFNhpidjfWw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Fg7NGUn8-forestgymprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Competition Preparation",
            "Personal Training",
            "Power Lifting",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.theforestgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hQqai9pJ-forestgymbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The leading Hardcore Gym In Sussex For Bodybuilders, Powerlifters, Athletes And Other Sportsmen and Sportswomen",
          "biography": "The Forest Gym was established in 1970 by Ron Ball and Pete Vickery - the latter a successful competitor in Cycling, Bodybuilding and Power Lifting, with a string of trophies to his name. Ron Ball has now retired and having moved to the North of England has completely severed his links with the gym.  Ownership now rests with Peter and Big H.\n\nHarold Marillier was the Manager for twelve years prior to his joint ownership of the gym and has really built up the reputation of the Gym via his own and his wife, Karen Marillier's, contest results. In addition, he has encouraged and given help and guidance to a string of successful competitors from the Gym in both Bodybuilding and Power Lifting.  We now have more and more competition bodybuilders visiting us and travelling 200 miles or more for guidance on diet and contest preparation.\n \nThe Forest Gym has been providing a service for Bodybuilders and Power Lifters in the Crawley area since our founding year and we intend to continue our efforts to improve the facilities available at the right price.",
          "offers": "Please visitour website for full details of our prices, packages and offers.\n\nOpening Hours:\n \nMonday to Friday: 10.30 am to 9.00 pm\nSaturday: 3.00 pm to 6.00 pm\nSunday: 8.00 am to 10.00 am\nAll Bank Holidays: 3.00 pm to 6.00 pm\nClosed: Christmas Day & Boxing Day",
          "yearsOfExp": 1970,
          "locations": [
            {
              "geolocation": [
                -0.1924721000000318,
                51.0957638
              ],
              "city": "Crawley",
              "postcode": "RH10 5PH",
              "_id": {
                "$oid": "5a1447b32288dd3a30f3f4b9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Forest Gym",
          "phone": {
            "number": "1293537229",
            "code": "+44"
          },
          "qualifications": [
            "Free Weights Selection",
            "Olympic Plates",
            "Resistance Equipment",
            "Functional Training Rig"
          ]
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_forest_gym",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5885fe2a1fd9097568ed0e96"
      },
      "lastUpdated": {
        "$date": "2017-11-13T17:00:28.453Z"
      },
      "createdAt": 1485176362903,
      "email": "glfitness@shoomon.xyz",
      "firstName": "GL Fitness",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Vp4sHi20Wfgnbpc+SaIgpQ==",
      "hashedPassword": "SIyVVUNkAIKG3gxuJMC1y6jukXKjRYVrKr/kRXnxkpdWB1iNSrMZ8sNiZYllZO1GU+Hta1TwxDqDisYzKVU3Rw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/exJMgg9v-glfitnessprofile.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Personal Training",
            "Sports Massage",
            "Sports Therapy & Rehab",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.glfitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nia53Xwn-glfitnessprofile.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "GL Fitness welcomes you to our newly built Gymnasium in Hailsham\n\nWe want you to feel part of the GL Fitness family, with our friendly staff always on hand to help you, no matter what the nature of your query. Whether you want to book into a class or perfect your technique on a new workout – we have staff on site who can help.\n\nWe pride ourselves on offering a clean environment in which you can complete your workout or class, or relax with a coffee, blended protein shake or one of our healthy snack options from our Cafe Bar area.\n\nAll of this is provided within your membership at GL Fitness. Our aim is to provide a complete service to our members, to assist you in living a healthy lifestyle.\n\nSERVICES AND FACILITIES:\n\nUnlimited re-assessments and program reviews\n\nCardio vascular and resistance equipment\n\nGreat selection of free weights\n\nGroup exercise classes\n\nCafe shake and snack bar area\n\nQuality changing rooms with showers and hair dryers",
          "offers": "Please visit our website for full details of our prices, packages and offers.\n\nOpening Hours:\n\nMonday – Friday: 6:30am – 9.30pm\nWeekends: 8:00am – 6:00pm\nBank Holidays*: 8:00am – 4:00pm\n*Subject to change",
          "strapLine1": "We have something money can’t buy – a PASSION for what we do!",
          "locations": [
            {
              "geolocation": [
                0.24632099999996626,
                50.8594449
              ],
              "city": "Hailsham",
              "postcode": "BN27 3WA",
              "_id": {
                "$oid": "5a09cfac430ff53fda549b3f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "GL Fitness",
          "phone": {
            "code": "+44",
            "number": "1323847973"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Cafe",
            "Juice / Shake Bar",
            "Changing Facilities",
            "Hair Dryers"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "gl_fitness",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "588604d41fd9097568ed1018"
      },
      "lastUpdated": {
        "$date": "2017-11-13T16:51:26.576Z"
      },
      "createdAt": 1485178068143,
      "email": "takeshape@shoomon.xyz",
      "firstName": "Take Shape",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Q3zNFCbFaf/ynwoBwoIheg==",
      "hashedPassword": "M567wNEVHx3dRgN8nWIwac3nCViI0EOg/1oRpaFQUvP9R95tVOa06d0uVZtjozV6TcVg4pxOfRmEvXJJ+gCTvw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Svd2aMmv-takeshapelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Cardiovascular",
            "Corporate Services",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.takeshapehealth.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/U2BIq6Ae-takeshapebg2.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Health - Performance - Fun",
          "biography": "Welcome to Take Shape:\n\nSince 2002 Take Shape has been helping people get healthier and fitter at Brewerstreet Dairy. We offer a functional approach to fitness in our friendly members gym, our fun and picturesque 10-acre obstacle course and our high-performance personal training.\n\nOur limited numbers membership ensures that the gym is never too busy. Membership includes the indoor functional gym and the outdoor obstacle course. It is open from 5.45am to 9pm on weekdays and 7am-7pm at weekends and has no contract / time commitment.\n\nOur obstacle course, set behind the gym in beautiful countryside, offers an amazing workout in the fresh air for ALL fitness levels. Fresh air, sunlight and functional movements ensure an invigorating and total body workout. \n\nAs well as for membership and personal training, the course is available for one-off hire to individuals, organisations and teams for adventure/mud-run training, team-building, pre-season fitness, parties, scout evenings, workshops and camps.\n\nOur personal training offers you the support and expertise to get the results you are looking for, guaranteed!",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\n\nThe gym is open weekdays from 5.45am to 9pm and weekends from 7am to 7pm.",
          "yearsOfExp": 2002,
          "locations": [
            {
              "geolocation": [
                -0.10445749999996679,
                51.25020980000001
              ],
              "city": "Bletchingley",
              "postcode": "RH1 4QP",
              "_id": {
                "$oid": "5a09cd8e430ff53fda549b3d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Take Shape Gym and Outdoor Obstacle Course",
          "phone": {
            "code": "+44",
            "number": "1883743434"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "30 Station Outdoor Obstcle Course",
            "Functional Training Rig",
            "Rack",
            "Free Parking",
            "Changing Facilities"
          ],
          "testimonials": "“I couldn’t recommended Take Shape enough”\nNick\n\n“I have lost loads of weight and feel great as a result of the training”\nStephen\n\n“I was in bad shape, the training has been marvellous and has inspired me to continue. The loss of a dress size has been the winning factor”\nSarah\n\n“Incredible attention to detail for my assessment and programme”\nMike\n\n“I lost 3 inches off my waist”\nSue\n\n“I have significantly more energy when playing with my daughter”\nMark\n\n“In 12 weeks I have lost 7cms from my waistline but, most importantly, I feel so much better with more energy and no stomach pains”\nDiane"
        },
        "score": 71
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "take_shape_gym_and_outdoor_obstacle_course",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "58860a5d1fd9097568ed115c"
      },
      "lastUpdated": {
        "$date": "2017-11-18T12:27:01.522Z"
      },
      "createdAt": 1485179485730,
      "email": "rochesterhealthclub@shoomon.xyz",
      "firstName": "Rochester",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "NUCjCPXbzXO6BtqxpXD7DQ==",
      "hashedPassword": "H+v54mceC2ecObic5w0MyTrL0A3PPDOnkGFaz35Nh7dH2RLMHOcB5MQp9YPvAKzPXFeHDIcao1MvMq2aAE6vxw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ik3VU0pG-rochesterhealthclublogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.rochesterhealthclub.co/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZgM2PESr-rochesterhealthclubbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Boxing:\n\nNewly re-furbished Boxing Studio, equipped with Pro-Box Boxing Bags, Speed Ball and 2016 Olympic Boxing Ring.\n\nSwim & Spa:\n\nOur Relaxing Pool and Spa area features a 20 metre Saline Pool, Sauna, Steam Room and Jacuzzi. Additional facilities are located in the Male and Female Changing Facilities. \n\nLadies Only Gym:\n\nSet behind frosted glass our Ladies Only Gym hosts both cardio and resistance training equipment to support all training styles.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nAll memberships here at Rochester Health Club are fully inclusive with no time or facility restrictions. We host Kent's largest gym and can support any training style.\n\nFREE PERSONAL TRAINING WITH EVERY MEMBERSHIP\n\nOur extensive class timetable of high and low impact classes are fully included in your membership with no additional cost.\n\nOpening Times:\n\nMon-Fri: 6am - 10pm\nSat-Sun: 6am - 8pm",
          "strapLine1": "Kent's Largest Membership Only Health Club",
          "locations": [
            {
              "geolocation": [
                0.49746430000004693,
                51.3576361
              ],
              "city": "Chatham",
              "postcode": "ME1 3QJ",
              "_id": {
                "$oid": "5a102715430ff53fda549bbd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Rochester Health Club",
          "phone": {
            "code": "+44",
            "number": "1634400003"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Rig",
            "Boxing Ring",
            "Swimming Pool",
            "Spa",
            "Cycle Studio",
            "Fitness Studio",
            "Sauna",
            "Steam Room",
            "Jacuzzi",
            "Punch Bags"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "rochester_health_club",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "5886112d1fd9097568ed12de"
      },
      "lastUpdated": {
        "$date": "2017-11-13T15:33:57.103Z"
      },
      "createdAt": 1485181229695,
      "email": "cruzgym@shoomon.xyz",
      "firstName": "Cruz Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "eCi5ewKYpmyWAGCsolO2aQ==",
      "hashedPassword": "/gWRmzpDnZpXWYFfaNtGM0EAefMjlTOMwW+frY4I+wVoNPdGwe318vk/MWzTJxoOOEB8FDhQIcExOF2huLecPA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/bYwDzTHu-cruzgymlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Boxing",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.cruzgyms.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FilxRAZr-cruzgymbg2.webp",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The Art Of BODYBUILDING",
          "biography": "Welcome to Cruz Gym.  Independently run by gym enthusiasts with a wealth of experience and knowledge.  Whether you want to lose weight, increase muscle or strengthen up then Cruz Gym offers you everything you need to achieve your goals.  We have the passion and expertise in providing you the best executive health and conditioning coaching in the area.   \nIdeally located just off Chatham High Street, the gym has its own car park including  plenty of spaces outside of the premises for up to 2 hours.\n \nThe building has been a gym environment for over 25 years and comprises of 3 levels including free weights area up to 60kg, cardio and resistance machine rooms and equipment suited for the stronger members giving you a new and exciting way to work out.  \n \nAdvice here is on tap and as a member you can also benefit Personal Training sessions from just £20.00.  We also run circuit training for just £3.00 - Training should be fun and part of your lifestyle so if you want to reach your goals then Cruz Gyms can help!\n \nThis is not, what we consider, today's typical gym environment - its better!",
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nOpening Hours:\n\nMonday-Friday: 7.00am-9.00pm\nSaturday: 10.00am - 4.00pm\nSunday: 10.00am - 2.00pm",
          "locations": [
            {
              "geolocation": [
                0.5337203000000272,
                51.37975489999999
              ],
              "city": "Chatham",
              "postcode": "ME4 4PF",
              "_id": {
                "$oid": "5a09bb65430ff53fda549b31"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Cruz Gym",
          "phone": {
            "code": "+44",
            "number": "1634400331"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Battle Ropes",
            "Atlas Stones",
            "Heavy Tyre",
            "Speed Ball",
            "Lunging Track",
            "Supplements",
            "Free Parking"
          ],
          "testimonials": "Nathan:\nIts an awesome gym, its got everything I need and more.   Very friendly atmosphere, have made great friendships out of Cruz Gyms and more Importantly made lots of gains. It's got all the help and support you could ask for!!\n \nAlex:\nI've been to most of the Gyms in the Medway area and this is by far the friendliest and most welcoming out of them all, great selection of free weights, machines and cardio equipment and a great atmosphere! Have made lots of good friends at this gym! All for a reasonable cost too! The staff are very experienced and are always happy to offer advice and help! Also a great variety of supplements and products!\n\nVicky:\nBrilliant Gym and friendly atmosphere. Would definately recommend!!\n\nGuy:\nBest gym in Medway by far!!  Received great training advice from the owners....equipment is quality and gym always kept clean and you'll never have to put someones weights away!! definitely recommend!\n\nRob:\nI have been in a gym environment for 30 years, of which 3 of those years are here at Cruz Gyms.  I can honestly say best gym by far, they accommodated my needs with punch bags and matted areas for me to continue with my ju-jit-su and boxing training so I got to enjoy all aspects of training under one roof.  Cruz has also become a great social meet up environment for me and many friends and now training partners made."
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "cruz_gym",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "588617511fd9097568ed1420"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:34:25.717Z"
      },
      "createdAt": 1485182801360,
      "email": "beefsstrood@shoomon.xyz",
      "firstName": "Beefs&Babes",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "/YUxQBbxhOiTq/5Ss34vpg==",
      "hashedPassword": "Q3ZoOXeIlnRuvWRIe08DRAUh5RjrKIY0LiPrx+IixSdYjot9zyZlSDxh6iXmbxY2gJqV2I5Kl+WpHd597QE94Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/CcEPgnLx-beefslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Competition Preparation",
            "Diet/Meal Plans",
            "Personal Training",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://beefs.moonfruit.com/welcome/4516370420",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/s8fwKCBf-beegsbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Serious training - no attitude",
          "biography": "We have pleasure in offering the following to all our clients:\n\nGym - 80 machines\nTechnogym Cardiovascular Equipment\nA large Free Weights section with dunbells up to 65kg\nPunch bags, Floor/Celing Ball And Speed Ball Arena\nRowing machines\nKettlebells\nTRX\nBattle ropes\nStudio\nVertical Tanner\nBeefstro serving heathy food\nDiet advisors on hand\nPersonal Trainers\nLarge supplement Shop\nSports Physio\nBody Fat Test With Callipers",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpening Times:\n\nMonday - Thursday: 06:00 - 23.00\nFriday: 06:00 - 22:00\nSaturday: 08:00 - 20:00\nSunday: 08:00 -  20:00",
          "locations": [
            {
              "geolocation": [
                0.4898772999999892,
                51.39200899999999
              ],
              "city": "Rochester",
              "postcode": "ME2 2EG",
              "_id": {
                "$oid": "5a1447812288dd3a30f3f4b8"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Beefs & Babes Gym Strood",
          "phone": {
            "number": "1634726227",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Fitness Studio",
            "Tanning Facilities",
            "Punch Bags",
            "Battle Ropes",
            "Speed Ball",
            "Supplements",
            "Cafe"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "beefs_and_babes_gym_strood",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58861b041fd9097568ed14e2"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:30:10.310Z"
      },
      "createdAt": 1485183748064,
      "email": "beefs&babesgreenhithe@shoomon.xyz",
      "firstName": "Beefs&Babes",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "vaHGf3PizHpOf+Xp9o0UlQ==",
      "hashedPassword": "JqHN9Di61SoxSE4lu88wgeCJjJZXjpUjgo3MJHtYOqelxrMOVfplKyLfBbxuwBmdpmBRmQwnEbu8JpBPoCGs/w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cNhFigs7-beefslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boot Camp",
            "Competition Preparation",
            "Fitness Classes",
            "Personal Training",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://beefsandbabesfitnesscentre.moonfruit.com/welcome/4536667665",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/n4sZvw0u-beefsgreenhithebg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Serious training - no attitude",
          "biography": "We have pleasure in offering the following to all our clients:\n\nGym - 80 machines\nTechnogym Cardiovascular Equipment\nA large Free Weights section with dunbells up to 65kg\nPunch bags, Floor/Celing Ball And Speed Ball Arena\nRowing machines\nKettlebells\nTRX\nBattle ropes\nStudio\nVertical Tanner\nBeefstro serving heathy food\nDiet advisors on hand\nPersonal Trainers\nLarge supplement Shop\nSports Physio\nBody Fat Test With Callipers\nSpa\nOsteopath\nSports Massage",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Times:\n\nMonday - Friday: 6:00am - 11pm\nSaturday: 8:00am - 8:00pm\nSunday: 8:00am - 8:00pm",
          "locations": [
            {
              "geolocation": [
                0.25878050000005715,
                51.4452828
              ],
              "city": "Dartford",
              "postcode": "DA9 9DQ",
              "_id": {
                "$oid": "5a1446822288dd3a30f3f4b6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Beefs & Babes Gym Greenhithe",
          "phone": {
            "number": "1322275277",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Functional Training Zone",
            "Punch Bags",
            "Speed Ball",
            "Battle Ropes",
            "Spa",
            "Cafe",
            "Supplements",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "beefs_and_babes_gym_greenhithe",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "58862cb61fd9097568ed18a4"
      },
      "lastUpdated": {
        "$date": "2017-11-07T16:40:35.051Z"
      },
      "createdAt": 1485188278965,
      "email": "beefs&babesgrenwich@shoomon.xyz",
      "firstName": "Beefs&Babes",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "aAqz7TYKThQ5wFpfcu46jQ==",
      "hashedPassword": "ycQPfFxhix22vG8OcjSDt9jL2FMjgPfyQ1z0CFIlGInwqYtUtzTbopboOEwn6ThpK4YrmnhNYSed42VJLf+EMA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1gM5qUnD-beefslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.beefsandbabes.com/welcome/4578087762",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GdcVkYDN-beefsbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "strapLine1": "Serious Training - No Attitude",
          "biography": "We have pleasure in offering the following to all our clients:\n\nGym - 80 machines\n \nTechnogym Cardiovascular Equipment\n \nA large Free Weights section with dunbells up to 65kg\n \nPunch bags, Floor/Celing Ball And Speed Ball Arena\n \nRowing machines\n \nKettlebells\n \nTRX\n \nBattle ropes\n \nStudio\n \nVertical Tanner\n \nBeefstro serving heathy food\n \nDiet advisors on hand\n \nPersonal Trainers\n \nLarge supplement Shop\n \nSports Physio\n \nBody Fat Test With Callipers",
          "offers": "Please visit our website for details on proces, packages and offers.\n\nOpening Times: \n\nMonday - Thursday: 06.00 - 23.00\nFriday: 06.00 - 22.00\nSaturday: 08.00 - 20.00\nSunday: 08.00 - 20.00",
          "locations": [
            {
              "geolocation": [
                -0.013661899999988236,
                51.48265319999999
              ],
              "city": "London",
              "postcode": "SE10 9BB",
              "_id": {
                "$oid": "5a01e203666de66e5617dfe6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Beefs & Babes Greenwich",
          "phone": {
            "code": "+44",
            "number": "2088534076"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Functional Training Rig",
            "Punch Bags",
            "Battle Ropes",
            "Speed Ball",
            "Supplements",
            "Cafe",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "beefs_and_babes_greenwich",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5886310f1fd9097568ed19a6"
      },
      "lastUpdated": {
        "$date": "2017-11-13T15:17:27.231Z"
      },
      "createdAt": 1485189391926,
      "email": "calsoh&fbromley@shoomon.xyz",
      "firstName": "Calso H & F",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ykoQSq4xbOX+qURPFPawQQ==",
      "hashedPassword": "pEwyWswOfOBESL+Ra9ZJovJ762m7E9nBvhyfVZS9ajLd8nHikHMg8KZq8ZrVV+dQiPIWz03uC18YbpWKzhrUlA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oDpu5lAm-calsopic.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Beauty Treatments",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.calso.com/index.php",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ir21aVqX-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Proud Club - Big Results",
          "biography": "We have a fully air conditioned spinroom where our experienced instructors will encourage and motivate you to get the most out of your work out. \n\nSpin is great for fat burning, leg toning and leg strengthening. It’s fantastic for improving the heart and lungs and giving you more energy. Above all it is great fun! \n \nWe have a fully equipped gymnasium, with a varied range of easy to use machines. Please note that all newcomers will be required to have a gym induction before they use the gym. During this induction you will be shown how to use the equipment safely and correctly.\n\nOur light and spacious mirrored aerobic studio has a wooden sprung floor. These floors are considered the best available for dance, indoor sports and physical education. They greatly reduce injuries and enhance performance. \n\nUnlimited Free parking. \n\nOur studio is available for hire.\n\nWhy put up with pain? Andrew is a highly qualified and experienced Chiropractor. If you have a problem with bones, joints, muscles or soft tissue come and see him for a consultation\n\nCatherine's Beauty Room offers a variety of Beauty Treatments for you to choose from. I'm sure you will be very happy with what she provides.\nLarge separate male and female changing rooms with lockers for your valuables. Both rooms have ample bench and hanging space with the women's shower room having a hairdryer",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Times:\n\nMonday - 6.30am - 10.00pm\nTuesday - 9.00am - 10.00pm\nWednesday - 6.30am - 9.30pm\nThursday - 9.00am - 10.00pm\nFriday - 6.30am - 8.30pm\nSaturday - 9.00am - 4.00pm\nSunday - 9.00am - 1.30pm",
          "locations": [
            {
              "geolocation": [
                0.015265699999986282,
                51.3906309
              ],
              "city": "Bromley",
              "postcode": "BR2 9BE",
              "_id": {
                "$oid": "5a09b787430ff53fda549b2e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Calso Health & Fitness Club",
          "phone": {
            "code": "+44",
            "number": "2084660506"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Cycle Studio",
            "Fitness Studio",
            "Free Parking",
            "Changing Facilities",
            "Chiropractor"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "calso_health_and_fitness_club",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5886454e1fd9097568ed1de8"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:28:31.814Z"
      },
      "createdAt": 1485194574414,
      "email": "rhino'sgymstamford@shoomon.xyz",
      "firstName": "Rhino's Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "cQdZb5B5Uhx37zKKmG3hIg==",
      "hashedPassword": "NwGPhQzgoqq7eoeUDOrgCzdYYFRtPaSUUNgrdrlUGugYfcpPO7XXUekQ7usCr6nw+SvvBNRC36sw5XL1EUo74w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sb9Po9jd-rhinosstamfordlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Strength & Conditioning",
            "Strongman"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.rhinosgymnasium.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dNX09SzS-rhinosgymstamfordbg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The UKs Best Gym - Men's Health Magazine 2016",
          "biography": "Rhino's Gymnasium Elite Sports and Conditioning is located on the north-east outskirts of Stamford, Lincolnshire on Gwash Way Industrial Estate on Ryhall Road (Opposite Jewsons/Travis Perkins) and is a unisex gymnasium for people who are serious about improving their personal health and the way they look!\n \nRhino's Gymnasium Elite Sports and Conditioning is the best equipped gym in the area, it's a great overall body conditioning gymnasium with superb services and facilities, with a huge 14,000sqft facility catering for everyones needs!\n \nRhino's is kitted out with a wide range of Cardio, Resistance, Strength, Functional equipment and Studios offering a wide range of classes.\n \nRhino's really is universal and open to everyone, whether you're after a casual jog on the treadmill, a quick conditioning class, or even some hard functional work, Rhino's welcomes everybody.\n \nRhinos was established in June 2012 by father and son Nicholas and Samuel Swann and was opened 1st June 2013 by USN Stars Richard Gozdecki and Max O'Connor.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Times:\n \nMonday - Friday: 06:00-21:30\nSaturday - Sunday: 07:00-20:00\nBank Holidays: 08:00-17:00",
          "yearsOfExp": 2013,
          "locations": [
            {
              "geolocation": [
                -0.4660464999999476,
                52.66420069999999
              ],
              "city": "Stamford",
              "postcode": "PE9 1XP",
              "_id": {
                "$oid": "5a14461f2288dd3a30f3f4b5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Rhino's Gymnasium",
          "phone": {
            "number": "7554219400",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Functional Training Zone",
            "Mat Area",
            "Sprint Track",
            "Battle Rope",
            "Sled",
            "Heavy Tyres",
            "Monkey Bars",
            "Air Conditionong",
            "Supplements",
            "Shop",
            "Barber"
          ]
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "rhinos_gymnasium",
      "feature_in_area": "none",
      "__v": 11
    },
    {
      "_id": {
        "$oid": "588648331fd9097568ed1ea9"
      },
      "lastUpdated": 1485195352160,
      "createdAt": 1485195315651,
      "providerId": "10158081530915263",
      "provider": "facebook",
      "surname": "Denes N",
      "firstName": "Adam",
      "email": "royaladam82@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10158081530915263/",
          "disciplines": [],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": null,
          "profileTitle": "General",
          "radius": 6,
          "price": {
            "min": 35,
            "max": 60
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.19341340035865093,
                51.42781143298441
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "SW19"
            }
          ],
          "companyName": null,
          "phone": {
            "code": "+44",
            "number": "7590841314"
          }
        },
        "wizard": {
          "complete": false,
          "start": true,
          "additional": false,
          "social": false,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4cYTXg7e_profilePicture.jpeg"
        },
        "score": 37
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "adam_denes_n"
    },
    {
      "_id": {
        "$oid": "58864ae01fd9097568ed1f2b"
      },
      "lastUpdated": {
        "$date": "2017-11-07T16:00:31.728Z"
      },
      "createdAt": 1485196000690,
      "email": "linearhheath@shoomon.xyz",
      "firstName": "Linear H & F",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "vk4Dv3i5BaINuTLK0DPuTw==",
      "hashedPassword": "/npdknLbUKdRdCwtKgRmLeJdEsjQXpOu8zInx9KVb6Me4PY+B0VYVTUpSt/g85OnJfxy7vtqPxcFe3F9peWG1Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Xas7rKgq-linearlogo.png"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Sports Massage",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://linearfitness.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/M6h3KiSQ-linearbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "TURN YOUR FITNESS GOAL INTO REALITY!",
          "biography": "TURN YOUR FITNESS GOAL INTO REALITY!\n\nLose Weight  - Build Strength - Increase Cardio Endurance  - Improve Flexibility . Injury Rehabilitation\n\n...or just  improve your all-round wellbeing!\n\nOur team of personal trainers and studio instructors ensure Linear Health and Fitness is the destination of choice for those who are serious about their health and fitness and want their training to be enjoyable, as well as effective.\n\nLocated in the beautiful grounds of Borde Hill Gardens, Haywards Heath, Mid Sussex, our club includes a gym, cardio loft and treatment room. Our studio at Mill House (just 200 yards from main club) is the perfect setting for our Pilates and Yoga classes.\n\nOur team of personal trainers will help you achieve your goal in our private gym using the latest training techniques, nutritional guidance and body composition analysis tools.\n\nINJURY PREVENTION & REHAB\n\nSports Massage – Osteopathy – Holistic Massage\n\nOur experienced therapists specialise in the prevention, treatment and rehabilitation of routine injuries.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:",
          "locations": [
            {
              "geolocation": [
                -0.11314560000005258,
                51.0218365
              ],
              "city": "Borde Hill Garden",
              "postcode": "RH16 1XP",
              "_id": {
                "$oid": "5a01d89f666de66e5617dfdb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Linear Health & Fitness",
          "phone": {
            "code": "+44",
            "number": "1444473421"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Free Weights Selection",
            "Running Club",
            "Free Parking",
            "Changing Facilities",
            "No Contract",
            "No Joining Fee"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "linear_health_and_fitness",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58864f881fd9097568ed202d"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:27:01.351Z"
      },
      "createdAt": 1485197192654,
      "email": "heightsh&f@shoomon.xyz",
      "firstName": "Heights H&F",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "PzSo3d6GBkdn0zVldU8a5A==",
      "hashedPassword": "NYEuBZ4vTAcrx9BLR7IZdn1XPVlNITa6HhP4okLOF7onJNIaveCGSCUFWjLz3gf44KZoF60sJlYpoZHCwJGUgg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Rv9JSuUH-heightslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://heightshealthclub.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sVWiAJkR-heightsbg.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 1997,
          "strapLine1": "It's your life - aim high",
          "biography": "AT HEIGHTS HEALTH & FITNESS WE HAVE A VARIETY OF TRAINING OPTIONS, INCLUDING A GYM, FITNESS STUDIO, GRAVITY, FITVIBE AND PERSONAL TRAINING.\nWhether you're a beginner (Some of our members had never set foot in a gym before), a seasoned athlete, needing to shift some weight, or lacking in energy, our helpful staff are trained to assist you in all aspects of your exercise routine.\n\nWe will listen to your needs and develop a workout plan individually designed for you to meet your goals.",
          "offers": "Please visit our website for details of proces, packages and offers.\n\nOpening Hours:\n\n6.40am – 9.30pm        Monday – Thursday\n6.40am – 9pm              Friday\n9am – 4pm                    Saturday\n8am – 4pm                    Sunday",
          "locations": [
            {
              "geolocation": [
                -0.13221899999996367,
                50.9559438
              ],
              "city": "The Martlets",
              "postcode": "RH15 9NN",
              "_id": {
                "$oid": "5a1445c52288dd3a30f3f4b4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Heights Health & Fitness",
          "phone": {
            "number": "1444239444",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Free Pass"
          ],
          "testimonials": "Friendly, helpful staff always happy to assist if there are any problems. I feel welcomed and the staff appear interested in what I have to say.\nHeather Harris.\n\nThe staff are friendly and helpful. The gym is a good place to work out as it has a nice friendly atmosphere.\nLinda Cooper.\n\nCompared to other gyms heights is great as it’s small, friendly and never packed so it’s a massive plus as I can get on with my workout without waiting for anything to become free. Staff are friendly. And for the first time in my life I am a member of a gym, loosing weight and actually enjoying it 😀\nKath Henderson."
        },
        "score": 71
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "heights_health_and_fitness",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "58865f0e1fd9097568ed2371"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:23:10.366Z"
      },
      "createdAt": 1485201166325,
      "email": "reflexionsdartford@shoomon.xyz",
      "firstName": "Reflexions",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "owA/2aVOyh5ak2yBdPhWPw==",
      "hashedPassword": "EEgSPCUWbfbtthNo33ULEWkKhP8uARLUDRXNoN7eE/IP6X5yvlb3uamRlrX8VxTY8/xqp571FFsh7Z9nFMcZQg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RpO7y6Wv-reflexionsdartfordlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Olympic Lifting",
            "Personal Training",
            "Strength & Conditioning",
            "Strongman"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.reflexionsgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Qou7ZnYc-reflexionsbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "If you are looking to lose weight or build muscle and strength - we can help",
          "biography": "We have an extensive range of Olympic lifting weights through to your standard steel weight selection. Now featuring a brand new set of Pro-Bell dumbbells from 2.5kg - 60kg\n\nWe offer customised PT sessions from £20 per hour. For more information check out the Personal Training page on our website\n\nWe're open 7 days a week\n\nWe have a vast range of Hammer Strength resistance machines, including dedicated Back, Chest and Shoulders machines\n\nAsk a member of staff anytime: we can offer free help and advice with your basic nutritional needs &supplements, and we can also offer help and advice with your basic training techniques to enhance your training experience and help achieve your goals.",
          "offers": "Please visit our website for full details of prices, packages and goals.\n\nOpening Times: \n\nMonday - Thursday : 9am – 10pm\nFriday : 9am – 9pm\nSaturday & Sunday : 10am - 4pm",
          "locations": [
            {
              "geolocation": [
                0.20926110000004883,
                51.4456062
              ],
              "city": "Dartford",
              "postcode": "DA1 2EU",
              "_id": {
                "$oid": "5a1444de2288dd3a30f3f4b1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Reflexions Muscle And Fitness Gym",
          "phone": {
            "number": "1322222284",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Racks",
            "Benches",
            "Olympic Plates",
            "Supplements"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "reflexions_muscle_and_fitness_gym",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "5886668a1fd9097568ed24f3"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:21:16.789Z"
      },
      "createdAt": 1485203082679,
      "email": "rokogillingham@shoomon.xyz",
      "firstName": "Roko H & F",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "2BzwQ8KNWSfNh5vr7/DVJA==",
      "hashedPassword": "ksLQIFZwn3AibF9Ys5rLGXtPJ3kDJIWTXGUF6FRpLtX5DQ725s537aG3gzw5u9GuCdEd+ofBj8pSEk0uuYvk5A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/neowAfUB-rokogillinghamlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Fitness Classes",
            "Kids Classes",
            "Personal Training",
            "Swimming Lessons"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.roko.co.uk/gillingham",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HpNHfEqW-rokogillinghambg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Roko Gillingham: gym, pools, spa, classes & salon\n\nAchieve your fitness & lifestyle goals and enjoy our stunning gym facilities at an affordable price\n\nRoko health club Gillingham, Medway, is a family friendly, premium health club and gym with an endless list of cutting edge facilities that will take your breath away.\n\nEnjoy the best health club in the Medway Towns. We have a vast range of fitness classes in our 3 dedicated studios, including Yoga, Pilates and Spin plus all your Les Mills 'Body' favourites. \n\nStay in shape on all the latest equipment in our huge gym or relax in style in our beautiful 20m pool with separate children's pool and spa. Whatever your health, fitness and lifestyle goals we can help you achieve them.\n\nThere are plenty of membership options to choose from to suit your needs so please have a good look around our website and come and see the club for yourself. Give us a call or go to our website to view our blog and latest club newsletter.\n\nWe look forward to seeing you at the club soon.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Times:\n\nMon: 6:30am - 10:00pm\nTues: 6:30am - 10:00pm\nWed: 6:30am - 10:00pm\nThurs: 6:30am - 10:00pm\nFri: 6:30am - 9:00pm\nSat: 8:00am - 7:00pm\nSunday and Bank Holidays: 8:00am - 8:00pm",
          "strapLine1": "Achieve your fitness & lifestyle goals and enjoy our stunning gym facilities at an affordable price",
          "locations": [
            {
              "geolocation": [
                0.5697305999999571,
                51.3704354
              ],
              "city": "Gillingham",
              "postcode": "ME8 6BY",
              "_id": {
                "$oid": "5a14446c2288dd3a30f3f4af"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Roko Health Club",
          "phone": {
            "number": "1634233348",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Free Weights Selection",
            "Functional Training Zone",
            "Fitness Studio",
            "Swimming Pool",
            "Spa",
            "Creche"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "roko_health_club_gillingham",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "58866a0e1fd9097568ed25b5"
      },
      "lastUpdated": {
        "$date": "2017-11-21T15:18:43.377Z"
      },
      "createdAt": 1485203982261,
      "email": "onepremiumreigate@shoomon.xyz",
      "firstName": "One Premium",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "hE1icHj6pORkGdabc0BwJA==",
      "hashedPassword": "NqGb1qBUVtlHUwc6t5CBBXk7yxfNIkHWVNUMciLW0/cACDKPCVtv0TUA/vg3dGgbQ1tfY6/o3QHGFdtbrmmOng==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nlfnT3bq-onepremiumlogo.png"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.onepremiumfitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Wj9gXCNB-fithubreigatebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Functional training zone & a wide range of studio classes",
          "biography": "GYM\n\nEverybody and every body is different.\nWhether you’re looking for free weights for your strength training, a treadmill to escape the stresses of real life, a way to dip your toe back into exercise, the latest technology in HIIT training, somewhere to train with friends, or a personal trainer, One Premium Fitness has it all.\nWith over 150 pieces of equipment we cater for every type of health and fitness discipline.\nWhether you want to run, cycle, row, step, cross train, or stretch, we’ve got you covered.\n\nSTUDIO ONE\n\nWith over 20 classes taking place in our exercise studio. Whether you’re into kettlebells, yoga or indoor cycling, our personal trainers are on hand to take you from start to finish with advice (if needed) to help you reach those goals.\nIf you’re somebody who struggles with motivation or needs to book something to ensure you turn up, we offer everything from Xtreme HIIT to Beginners Yoga.\n\nSPEEDFLEX\n\nWith Speedflex you only get what you give, the machines respond to you, whether you’re an Olympic athlete or an 85 year old grandma.\n\nSuitable for anyone of any age or fitness level, Speedflex enables everyone to exercise at high intensities, but at levels set by each individual as the force you use to move the bar in one direction, is the same force you’re required to use to move it in the opposite direction.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\n\nMonday: 06.30am - 10:00pm\nTuesday:  06:30am - 10:00pm\nWednesday: 06:30am - 09:30pm\nThursday: 06:30am - 09:30pm\nFriday: 06:30am - 09:00pm\nSaturday: 08:00am - 06:00pm\nSunday: 08:00am - 06:00pm",
          "locations": [
            {
              "geolocation": [
                -0.20880539999996017,
                51.2376715
              ],
              "city": "Reigate",
              "postcode": "RH2",
              "_id": {
                "$oid": "5a1443d32288dd3a30f3f4ac"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "One Premium Fitness",
          "phone": {
            "number": "8445433636",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Free Weights Selection",
            "Functional Training Zone",
            "Fitness Studio"
          ],
          "testimonials": "LOVE YOUR GYM\nCompared to Doynings it is – cheaper, cleaner, far nicer changing facilities (especially lockers & showers), better array of machines, nicer location and you even have a better sound system!\nYour staff are welcoming and attentive. We were approached by one of your PTs Phil, who was very friendly and offered his help (something that has never happened once in hundreds of visits to Doynings).\nI also really like the concept of having the complimentary services (e.g. physio) on site.\nWe will be cancelling our current membership and will be taking advantage of the zero joining fee from 3rd September. Jamie\n\nNice clean gym and not over priced like others in the area. Karina\n\nCool gym. Nice people! Maria"
        },
        "score": 69
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "one_premium_fitness",
      "feature_in_area": "none",
      "__v": 15
    },
    {
      "_id": {
        "$oid": "588671501fd9097568ed2777"
      },
      "lastUpdated": {
        "$date": "2017-11-07T14:59:42.225Z"
      },
      "createdAt": 1485205840563,
      "email": "fitnesszonereigate@shoomon.xyz",
      "firstName": "Fitness Zone",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "nMO2l9t6Qmm5vyQ0wsATJg==",
      "hashedPassword": "N50i3n3MP0ahh0kG9Li2p7Igk7v93ScNyWm3p56s6pMJ9L0b5Oqb25DkElsmLZ6LT//t/Y7TCHjrpSBWASQz3Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JAFlL7Zk-fitnesszonereigatelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Beauty Treatments",
            "Fitness Classes",
            "Personal Training",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.fitnesszonereigate.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/iKt1gFi4-fitnesszonereigatebg.jpg",
            "size": {},
            "offset": 0
          },
          "offers": "Please visit our website for details of prices, packages and offers.\n\nClub Opening Times:\n\nMonday: 0830 to 2130\nTuesday: 0700 to 2130\nWednesday: 0830 to 2130\nThursday: 0700 to 2130\nFriday: 0830 to 2000\nSaturday: 0900 to 1600\nSunday: 0900 to 1300",
          "biography": "Over 80% of our members were either complete ‘Beginners’, who had never been in a gym or health club before, or ‘Restarts’ who had tried exercising in other facilities but were left on their own and therefore lacked motivation to attend.\n\nThe Fitness Zone takes great pride in welcoming new members and are here to support, encourage and motivate YOU every step of the way. Our team of dedicated, experienced and friendly staff are here to help!\n\nThe Fitness Zone caters for all ages, shapes and sizes. It doesn’t matter what your fitness level or how much previous exercise you’ve done – everyone deserves a chance to get in shape, in an environment they can enjoy.\n\nExercise can be made FUN!\n\nWe understand that getting started is the hardest part, making that initial phone call or walking through the door for the first time can be a daunting experience, but rest assured we are here to help make such a step easier.\nThe Fitness Zone is a relaxed and friendly place – it’s unlike any gym or fitness centre you're likely to have ever experienced before and our facilities are amongst the best in the industry.\nThe key as a member is that you aren't left on your own. Our systems involve regular reviews of your personalised programme and teaching so that YOU get your RESULTS quickly. It is therefore no surprise that the majority of our members renew their membership year-after-year.",
          "strapLine1": "Your Friendly, Local Health & Fitness Club For Almost 20 Years",
          "locations": [
            {
              "geolocation": [
                -0.20567379999999957,
                51.2371559
              ],
              "city": "Reigate",
              "postcode": "RH2 7BG",
              "_id": {
                "$oid": "5a01ca5e666de66e5617dfcd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Fitness Zone Health & Fitness Club",
          "phone": {
            "code": "+44",
            "number": "1737221803"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Tanning Facilities",
            "Slimming & Healthy Eating Club"
          ],
          "testimonials": "Adam Bellinger\nI wanted to improve every aspect of my fitness. I come 3-4 times a week and have seen some great results.\n\nThe staff are what makes The Fitness Zone great. I started as a novice and they get you motivated and confident on all the equipment and then will have as much or as little input in your programmes as you want.\n\nComing to the club has completely changed my lifestyle for the better.\n\nAngela Driscoll\nI wanted to keep fit and toned. I attend normally twice a week and find the classes really motivational and never miss my Thursday lunchtime core and tone.\n\nI find the staff very friendly and helpful and some of my work colleagues are also members because they enjoy the atmosphere like me.\n\nThank you for all your help.\n\nDawn O’Doherty\nI was struggling to lose weight after having my daughter. I dieted for a while and lost quite a lot but after a while my weight was at a standstill and I couldn’t seem to shift anymore so I joined The Fitness Zone.\n\nI have improved in so many ways. I feel so much healthier and happier and the staff are fantastic and so helpful, always boosting everyone’s confidence and very friendly.\n\nI go with a friend 3 times a week mainly to classes which are great fun. The staff always keep you smiling.\n\nKristian Ward\nI have been going two or three times a week. I find the staff very friendly and helpful which has been a great asset to me in my bid to lose weight."
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_fitness_zone_health_and_fitness_club_reigate",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "58867c411fd9097568ed29b9"
      },
      "lastUpdated": {
        "$date": "2017-10-27T09:40:12.218Z"
      },
      "createdAt": 1485208641937,
      "email": "bodyworkshoplewes@shoomon.xyz",
      "firstName": "BodyWorkshop",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "+SwhZju6zot7LdXvhkMcLg==",
      "hashedPassword": "WeAVKI9xryA0nmzDMNX/tqQQn3JJYZ+Ae/ZIkoOU16QCHQPzUvSs/AiIa+v7833Lc9criljyxBsrGl1Psjhk6A==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/15JBAUb9-bodyworkshoppro.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.bodyworkshop.biz/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9hrGED3L-bodyworkshopbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to the Body Workshop:\n\nThe friendly health and exercise club!\n\nWould you like to improve your fitness or body shape? Come and visit us at Body Workshop.\n\nWe can help with programs to suit all ages, fitness levels and sports. We can also give dietary advice.\n\nFully Qualified Instructors are available plus a range a range of fun and exciting exercise classes that aim to get you in tip-top shape.\n\nFrom pilates to pole fitness, boxercise to body building - we’re sure that you will find something to enjoy and hopefully make some new friends too.",
          "strapLine1": "The friendly health and exercise club!",
          "offers": "Please visit our website for details on proces, packages and offers.\n\nOpening Hours:\n\nMONDAY-THURSDAY\n7am – 9.30pm\nFRIDAY\n7am – 8.30pm\nSATURDAY & SUNDAY\n9am – 1pm",
          "locations": [
            {
              "geolocation": [
                0.010147200000005796,
                50.87814239999999
              ],
              "city": "North Street Industrial Estate",
              "postcode": "BN7 2PE",
              "_id": {
                "$oid": "59f2fefc6ee9327f153d23d0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Body Workshop",
          "phone": {
            "code": "+44",
            "number": "1273478525"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Sauna",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_body_workshop",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58871b471fd9097568ed4bbd"
      },
      "lastUpdated": {
        "$date": "2017-10-27T09:23:33.938Z"
      },
      "createdAt": 1485249351943,
      "email": "fxgymcroydon@shoomon.xyz",
      "firstName": "FX Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "nNzDJeRJ+4RpBqzC3XJlcA==",
      "hashedPassword": "md74X3AE503b7JkgtprJ3PqccmsESHDe/LAiNY6P4kiRHLhJjnaj7EpB+2CYumLqigbdKiDZGCsSqVc5Chvjkg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3RdLNvSU-fxgymlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Fitness Classes",
            "Ladies Only Classes",
            "Martial Arts",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://fxgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wMI4jzSz-fxgymbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "strapLine1": "This Ain't No Easy Gym",
          "biography": "Dedicated to building muscle and fitness, FX Gym is for all shapes and all sizes - first timers and professional trainers welcome. \n\nOur gym is home from home for some serious champions: we out-lift and out-weigh the competition for strength training.\n\nFX Gym features a beyond-sane collection of weights and resistance machines to match. Our aim is to help you train hard; whether it’s cardio or strength training, FX Gym hosts plenty of cardio machines and our team of personal trainers are on hand to help you shred, get rip and build big - we’re here to help beyond the exercise. With saunas, towels and a nutrition bar, FX Gym is fully loaded.\n\nFX Power Gym is overstocked with seriously heavy weights to help you pack on the mass: kettle bells, rack systems and barbells, you name it - we’ve created a beast for hardcore power lifting. If you’re after heavy - loaded squat racks, cable machines and benches then try us for size and arm up. FX Power Gym even has a tractor tyre, ready for you to power through and hit your super human targets.",
          "offers": "Please visit our website for details of  prices, packages and offers.\n\nOpening Times:\n\nOpen 7 days a week: \n\nWeekdays 06:00 – 23:00, \nSaturdays 09:00 – 20:00, \nSundays 09:00 – 18:00",
          "locations": [
            {
              "geolocation": [
                -0.10423689999993258,
                51.3742802
              ],
              "city": "Croydon",
              "postcode": "CR0 1RL",
              "_id": {
                "$oid": "59f2fb156ee9327f153d23cf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "FX Gym",
          "phone": {
            "code": "+44",
            "number": "2086497769"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Suspension Equipment",
            "Racks"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fx_gym",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "588722a11fd9097568ed4d3f"
      },
      "lastUpdated": {
        "$date": "2017-10-27T09:12:06.937Z"
      },
      "createdAt": 1485251233457,
      "email": "beckenhamgym@shoomon.xyz",
      "firstName": "Beckenham",
      "surname": "Gym Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "4UxMTK6nTxP5GUeOOmekxw==",
      "hashedPassword": "dHQYahrg9IBNfdI6CSLDdWNMWR3TlASky0aqSvm358w1bJ0MoSUljjJE8m+l0b+NcCx7six6o+OdhEOm1U1XRA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zF14uOR1-beckgymlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Fitness Classes",
            "Ladies Only Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.beckenhamgym.co.uk/index.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/z33DnhGL-beckgymbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The club with a difference",
          "biography": "Medi-Fit Approved Club:\n\nBehind every “Medi-Fit” supported fitness club is a Sports & Medicine trained Doctor to look after YOU and ensure that you get the right advice when you set off on your new healthy active journey! \nGreat facilities and a friendly welcome EVERY TIME YOU VISIT\nGYM – FREE WEIGHTS – GROUP CLASSES – SPINNING - OUTDOOR ACTIVITY AREAS\nMONEY BACK GUARANTEES on ALL Memberships\nHuge selection of Group Exercise Classes for ALL Levels\nUnique members 6 week Programming System\nRegular schedule of social activities, events and holidays\nOur Big Weights section for people who know what they are doing\n“NEW” Functional training areas\nPersonal Training services available 7 days a week with our team of “Expert” friendly trainers\nSpecial group activity sessions for Seniors to get together\nRegular Mums Mornings and Ladies Community sessions\nNutritional advice and dietary programmes available to ALL members\nWe’re the Ideal club if you are new to exercise or struggle to stick with a regular routine\n6 week Weight management programmes and Body transformation courses – our speciality!\nQuarterly “Reactivation” programmes to reinvigorate you and to teach you new techniques\nRegular “Reprogrammes” to keep you motivated\nA full range of “Fast acting” personal programmes for you to select from\nChoose from our wide range of gym based activities and group or individual tuition sessions",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\n\nMon - Wed: 06:00-22:00\nThurs & Fri: 06:00-21:00\nSat & Sun: 08:00-18:00\n\nBank Holidays: 08:00 - 16:00",
          "locations": [
            {
              "geolocation": [
                -0.030499100000042745,
                51.42043779999999
              ],
              "city": "Beckenham",
              "postcode": "BR3 1NZ",
              "_id": {
                "$oid": "59f2f8666ee9327f153d23cd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Beckenham Gym",
          "phone": {
            "code": "+44",
            "number": "2086583935"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Outdoor Training Area",
            "Fitness Studio",
            "Punch Bags"
          ],
          "testimonials": "\"Since taking the over 50's yoga classes my daily painkiller intake has reduced significantly...\"  - Vee\n \n\"I have moved forward dramatically and have more respect for my body and health in general and I am learning to concentrate more on things in hand and my life in general, Glad I joined this club  - James\n\n\"I started the gym in 2011 and in my first Yoga/Pilates session with Harold I told him about the pain in my neck and arms, after 3 of his sessions the pain stopped...\" -  Mrs Donna Gaynor\n\n\"When I first started Harold's class I was recovering from a car accident and also different from nervous exhaustion...\" - Kelly Chester"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "beckenham_gym",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58874cdc101f6f0fe5305f26"
      },
      "lastUpdated": {
        "$date": "2017-10-27T08:49:51.463Z"
      },
      "createdAt": 1485262044785,
      "email": "ukfitnesstilbury@shoomon.xyz",
      "firstName": "UK Fitness",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "xe3/i4pB3JSMqlUJuq7wuQ==",
      "hashedPassword": "ox34n3Au6eaZebn9em5Uz/qiISggsxBH2TQFFuE6VazPASLh4U6HU6nb+CNPNN9j8MYNq+zu9CDZKbSSThFU5Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3Kwlhc30-ukfitnessclubtilburylogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Ladies Only Classes",
            "Martial Arts",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.ukfitnessclub.net/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/87nmYU24-ukfitnessclubtilburybg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "biography": "UK FITNESS CLUB Offers Excellent value for money, with memberships available just from £22.50. We offer a different type of memberhips, chose the one that suits you the best. \n \nFrom cardio to functional, the new gym caters for all levels of fitness and different training needs, including:\n \n60+ station gym powered by Technogym\nCardio machines\nResistance machines\nFree weights\nFunctional area\n \nAs we are passionate about helping you achieve your fitness goals, we offer a range of  equipment in our new gym,  including a free induction for all new members.  afterwards a member of our team will continue to meet with you on a regular basis to provide as much advice and support as you need to ensure you're on the right track to achieving your health and fitness goals.",
          "offers": "Please visit our website for details on prices, packages and offers:\n\nOpening Hours:\n\nMon - Thurs: 6.30am - 10.00pm\nFri: 6.30am - 9.00pm\nSat 9.00am - 6.00pm\nSun: 9.00am - 4.00pm",
          "strapLine1": "Call us today to start getting fit!",
          "locations": [
            {
              "geolocation": [
                0.3573970999999574,
                51.46103929999999
              ],
              "city": "Tilbury",
              "postcode": "RM18 7BT",
              "_id": {
                "$oid": "59f2f32f6ee9327f153d23c6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "UK Fitness Club",
          "phone": {
            "code": "+44",
            "number": "1375845792"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Changing Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "uk_fitness_club",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "588751e0101f6f0fe5306028"
      },
      "lastUpdated": {
        "$date": "2017-11-28T10:05:03.078Z"
      },
      "createdAt": 1485263328725,
      "email": "naturalfitnesscentreeastbourne@shoomon.xyz",
      "firstName": "Narural",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "aRTKUQFB+v/gnSU1RQZbPg==",
      "hashedPassword": "oDGUExic6QlkfXdO8ZrhTyupukjAo7FCZwXCt8dRKAj6dnTVCqJih5t7jr9CvNDbZQLrYVuaJ27SB/03GEctow==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2tsWTRwE-natfitpro.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "General Yoga",
            "Improvers Pilates",
            "Private Classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://naturalfitnesscentre.co.uk/",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/iZ3Ia0MA-natfitbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Eastbourne’s Number One Destination for Pilates, Yoga & Complementary Therapies",
          "yearsOfExp": 2001,
          "biography": "Since opening in 2001, the Natural Fitness Centre has strived to achieve excellence in Complementary Health Care through a wide range of treatments and fitness and wellbeing classes – for all ages and abilities.\n\nWe believe in great customer service and pride ourselves on being friendly and approachable. Our highly qualified staff will use their knowledge and experience to support you in discovering safe and natural ways to reach your optimum health and wellbeing:\n\nNo membership or joining fees\nFree health consultations\nCommon sense approach\nWe support you to help yourself\n\nMore than 30 health enhancing services under one roof including fitness classes and wellbeing classes such as Pilates.\nThe Natural Fitness Centre is based on the lovely beachfront of Eastbourne in East Sussex; why not come down to breathe in the gorgeous, relaxing view?",
          "offers": "Please visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                0.29916720000005625,
                50.7738631
              ],
              "city": "Eastbourne",
              "postcode": "BN22 7AQ",
              "_id": {
                "$oid": "5a1d34cf7d852e353acb638f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Natural Fitness Centre",
          "phone": {
            "number": "1323732024",
            "code": "+44"
          },
          "qualifications": [
            "Improvers Pilates Class",
            "Pregnancy Pilates Class",
            "Foundation Pilates Class",
            "General Yoga Class",
            "Dru Yoga",
            "Pregnancy Yoga Class",
            "Meditation Class",
            "Tai Chi Class",
            "Balletcise",
            "Legs Bums & Tums"
          ]
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "natural_fitness_centre",
      "feature_in_area": "none",
      "__v": 11
    },
    {
      "_id": {
        "$oid": "58875f9a101f6f0fe530632a"
      },
      "lastUpdated": {
        "$date": "2017-11-21T14:42:17.814Z"
      },
      "createdAt": 1485266842990,
      "email": "citygymexpebourne@shoomon.xyz",
      "firstName": "City Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "PyTxmubhUUDRbncKji7lIw==",
      "hashedPassword": "Hp3cx42q7P1AVwBbzTTSgelZ8CM3HC5ZEB6uNiOEyx2W1PzxFf60zgrkGbB6gpumTWEEN0AHLO4uNn1FZzJBEQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TzUmy8SQ-citygymlogo.png"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://city-gym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Mq1LA4n0-citygymbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "HELPING YOU REACH YOUR TARGETS",
          "biography": "We are an independently-run Gym based in Eastbourne, local to the Town Centre (only a 10 minute walk from Eastbourne train station), offering friendly and approachable staff who are keen to help you with your goals and maximising your results.\n\nOur gym has a very personal touch with dedicated training areas depending on how you wish to train: we have a light training area for sparring, which also includes floor equipment and dumbbells. A larger training room is available with mixed heavy and lightweight exercise machines including cardio equipment and more free weights.\n\nBoth male and female showers and washrooms are located downstairs and are well kept, cleaned and maintained.\n\nThere are several locker areas provided, these do not cost, just ask a staff member for a key and you can lock up your belongings and keep them safe whilst you train.\n\nAt the reception area we have plenty of supplements available for you to purchase before or after your workout. We provide free drinking water and a warm and relaxed seating area if you are in need of a break or a relax prior to or after a workout.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours: \n\nMon - Fri: 06:30 - 21:00\nSat & Sun: 08:00 - 19:00",
          "locations": [
            {
              "geolocation": [
                0.27953130000003057,
                50.76588419999999
              ],
              "city": "Eastbourne",
              "postcode": "BN21 4EY",
              "_id": {
                "$oid": "5a143b492288dd3a30f3f49b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "City Gym Express",
          "phone": {
            "number": "1323648863",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Ladies Only Area",
            "Juice / Shake Bar",
            "Supplements",
            "Suspension Equipment",
            "Functional Training Zone",
            "Punch Bags",
            "Pads"
          ],
          "testimonials": "Really friendly staff and really great gym. I highly recommend it! Jake\n\nGreat place to go for good workout, staff is was lovely A+++++ highly recommended. Mantana\n\nI like this gym , very nice staff and friendly, good atmosphere to train and enjoy your hard work! Janeka\n\nHaving been to all of the gyms in Eastbourne, I have to say I have finally found somewhere I feel totally comfortable and for such good value for money. The staff are all extremely welcoming and friendly. The special protein shakes they make are the absolute best, I would recommend this gym to anyone. Darcey"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "city_gym_express",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58876a06101f6f0fe530656d"
      },
      "lastUpdated": {
        "$date": "2017-10-26T20:48:27.691Z"
      },
      "createdAt": 1485269510345,
      "email": "fullfitnessmorden@shoomon.xyz",
      "firstName": "Full Fitness",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "OwH9Gxd7OV3E7a/CIe/taA==",
      "hashedPassword": "yAaDtNpoFSPOw0GEbeSYarSV9SR7xRvGwPnpr4muyj+lCgi05aOtRnqAg+JwL9hqCcKo0/QsYipSjoB0bdAv9w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3qyAvxkF-fullfitnesslogo2.jpg"
        },
        "personal": {
          "disciplines": [
            "Academy",
            "Corporate Services",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.full-fitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EHhn3U20-fullfitnessbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "At Full Fitness we help you LIVE LIFE TO THE FULL!\n\nOur personalised approach to fitness involves helping you establish better exercise, fitness and healthier habits, so that you can benefit from...\n\nA fitter and healthier body\nHigher energy levels\nBetter postural alignment\nGreater flexibility\nReduced stress levels\n\nWe believe that there is nothing more important than forming good, healthy fitness habits. At Full Fitness we combine exercise, nutrition and healthy life practices to improve all aspects of your physical and mental well-being.\n\nOur aim is to improve the fitness levels of everyone from inactive participants to Professional Athletes.\n\nEach customer will be treated like a Professional and receive the highest quality of service: we ensure that everyone who attends is highly motivated and has a fitness pathway, whether it be to join our OCR Team, attend group training sessions, have a Personal Trainer or go it alone.\n\nSo start today - don’t wait until the New Year to change your life habits and don’t set yourself insurmountable goals. Let us help you with a little bit and often so that that you can get started now.\n\nFeel free to pop in, give us a call or visit our online shop – we look forward to your visit. Learn more about our individual team members, their specialist areas and their life mottos!",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\nMon - Fri: 06:30 - 22:00\nSat & Sun: 08:30 - 17:00",
          "strapLine1": "Full Fitness: LIVE LIFE TO THE FULL!",
          "locations": [
            {
              "geolocation": [
                -0.1718729000000394,
                51.3936462
              ],
              "city": "Morden",
              "postcode": "SM4 6BF",
              "_id": {
                "$oid": "59f24a1b6ee9327f153d23b6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Full Fitness",
          "phone": {
            "code": "+44",
            "number": "8005668481"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Changing Facilities",
            "Lockers",
            "Complimentary Amenities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "full_fitness",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58877947a8ce313ac39d5536"
      },
      "lastUpdated": {
        "$date": "2017-11-21T14:24:24.285Z"
      },
      "createdAt": 1485273415473,
      "email": "esphedulwich@shoomon.xyz",
      "firstName": "ESPH",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Q35P+LKOZQb0fs3ATtn/7g==",
      "hashedPassword": "t1Yba8bHzkcDBKx2ZkMHey+HyhA1k06qIaYNJ2dWdnyrbw8QEnHRSxBkZALxRoLuwIv8KMFYv3kcQdkSWjZA6Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pQaWdoz8-esphlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Assessments",
            "Fitness Classes",
            "Injury Rehabilitation",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.esph.co.uk/",
          "profileTitle": "Sports Therapy & Rehab",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hdlZakec-esphbg2.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Fit for life",
          "biography": "ESPH Harley Street and East Dulwich is one of London's leading health, fitness and wellbeing brands.\n\nOur centres offer extensive physiotherapy services, rehab, sports massage and diet and nutrition planning. We have dedicated Pilates equipment studios at each site and provide personal training and fitness assessments. The physiotherapy services in East Dulwich are complimented with a state of the art gym and fitness classes including yoga, indoor cycling and Tai Chi.\n\nCorporate assessments are available at our Harley Street site, which specialises in ergonomic and workstation reviews. We also provide podiatry at ESPH Harley Street.\n\nA bit more about us…\n\nESPH began when Clinical Director Libby Sharp decided she’d have a go at treating a few patients at home. That was back in 1979.  \n\nOver the years, ESPH had developed a thriving private physio practice, becoming one of the top physiotherapy providers in London, treating thousands of patients each year as well as delivering rehab and exercise programmes to our many customers. Nowadays the business is as much about exercise and fitness as it is treatment and rehab. We provide a tailored, thoughtful approach to health and fitness and encourage everyone to embrace exercise no matter their fitness level or goals.",
          "offers": "Please refer to our website for details of prices, packages and offers:",
          "locations": [
            {
              "geolocation": [
                -0.07649850000007063,
                51.4559945
              ],
              "city": "London",
              "postcode": "SE22 8HD",
              "_id": {
                "$oid": "5a1437182288dd3a30f3f498"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "ESPH Gym & Physio Centre",
          "phone": {
            "number": "2079071900",
            "code": "+44"
          },
          "qualifications": [
            "Podiatry",
            "Sports Massage",
            "Physiotherapy",
            "Pregnancy Physio",
            "Counselling"
          ],
          "testimonials": "I came to ESPH filled with gloom and trepidation that my just diagnosed sciatica was going to upset my ability to cope with every day life. But my session with James was extremely reassuring. He charted the way ahead, the steps to recovery in a calm and encouraging way and taught me various manoeuvres to help. And he did some great massage. So now I can see the light at the end of the tunnel. Optimism restored.\n\n— Physio client, East Dulwich ESPH\n\nFantastic 1st class facility. Top rehab equipment and staff.\n\n— Natalie Pennicotte-Collier, East Dulwich ESPH\n\nVery pleased with the assessment and treatment I received from Claire Bannerman today. Claire’s holistic approach has meant that I am very clear about how quickly I can expect any improvement, know what I need to do in the meantime and a follow-up appointment has also been booked. Thank you Claire.\n\n— Ava, Physio client at ESPH East Dulwich\n\nExcellent physio & a gym. I’ve seen Claire for rehabilitation after a knee replacement. She was amazing, I’m in good shape now and can walk well thanks to her. Staff are very kind and helpful. I highly recommend it.\n\n— Grayzna Shelle, client at ESPH East Dulwich\n\nGreat physio team. I have been seeing Claire and she has been awesome in helping me with an acute back problem.\n\n— Rob Steel, seeing Claire, Physio treatment at East Dulwich"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "esph_gym_and_physio_centre",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "58877e9ba8ce313ac39d5638"
      },
      "lastUpdated": {
        "$date": "2017-10-26T20:01:14.416Z"
      },
      "createdAt": 1485274779348,
      "email": "twistepsom@shoomon.xyx",
      "firstName": "Twist Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "eNMiZV3+sjlDuAT8vTHkSg==",
      "hashedPassword": "fOVvUoNdD9XLJKclgAerCJ1ajPkVd7DdTrvA5hvR03yqMr93L1KjPsg7gaT0QBJAzw1f3/E9NyGdcXjwX3C3XA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PmwVLGkP-twistepsomlogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Boxing",
            "Fitness Classes",
            "Kids Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.twistgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "price": {
            "min": 1,
            "max": 149
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xVjNBvN0-twistepsombg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "WE DELIVER STRUCTURED EXERCISE SESSIONS TO ALL ABILITIES, EVERY SESSION IS UNDER THE SUPERVISION OF A QUALIFIED TRAINER. TWIST GYM IS A PLACE WHERE YOU NEVER GET IGNORED & YOUR GOALS AND RESTRICTIONS ARE NEVER FORGOTTEN.",
          "strapLine1": "IT’S A GYM,  BUT NOT AS YOU KNOW IT",
          "offers": "Please visit our website for details of prices packages and offers.\n\nOPENING HOURS:\nMon - Fri: 6am - 9pm\nSat: 8:00am -12pm\nSun :8:00am - 11am\nBank Holidays: 9am-1pm",
          "locations": [
            {
              "geolocation": [
                -0.2412951000000021,
                51.3270393
              ],
              "city": "Epsom",
              "postcode": "KT17 4JA",
              "_id": {
                "$oid": "59f23f0a6ee9327f153d23b0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Twist Gym",
          "phone": {
            "code": "+44",
            "number": "1372877190"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Rig",
            "Punch Bags",
            "Heavy Tire",
            "ViPR"
          ],
          "testimonials": "“I originally came to The Twist Gym to train after the birth of my daughter and prepare for the filming of ‘Primeval’. Enjoyed the variety, the direction and the level of service. Very pleased as all of my goals were achieved. Laila Rouass (Actress, currently starring in Strictly Come Dancing).” Laila Rouass\n\n” Good instructions and personal attention. Much more friendly than a large impersonal gym. Up-to-date equipment and keen instructors.” Valerie Ives – Joined August 2007. Valerie Ives\n\n“I jointed Twist Gym on February 2014 and It has been life changing experience for me. I haven’t been in any other gyms before, but I think this is the best place to exercise. I lost 20kg and gone down from clothing size 14/16 to 8/10 and I look and feel better then ever. I was surprised to realise not carrying so much weight gives you endless energy. What I like best in the Gym is the atmosphere and the instructors. They are all very professional, patient and friendly. Also each day is different, we never repeat the same exercises so is it interesting and challenging all the time. Twist Gym changed not only the way I look, but also the way I feel about myself and remained me “If you set your heart on something you will achieve it”. Don’t give up. – from Stephanie….member since 2013…."
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "twist_gym",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5887869ba8ce313ac39d57fb"
      },
      "lastUpdated": {
        "$date": "2017-11-21T14:23:33.552Z"
      },
      "createdAt": 1485276827722,
      "email": "bodywisewhorsham@shoomon.xyz",
      "firstName": "Bodywise Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Nudj63vYOXllUjDtw4Q3sQ==",
      "hashedPassword": "2+QPgUgRGbrpgjw8SRNt4V07E8QcM/STMKEc4mxW77tS1ghcQZ/x3PnHKCnbou18ozvXOWYXLWkOQjTHW9lwIg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Kcrf5MKx-bodywisepro2.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Boxing",
            "Fitness Classes",
            "Kids Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://thebodywisegym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5HCwekfn-bodywisebg4.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Bodywise Gym and Studios. We are a privately owned Health Club located in Warnham, West Sussex. After a hard training session relax in the sauna, have a coffee or put your feet up and be pampered by our various onsite services. We are here to support a lifestyle, keep you training and smiling. Get that feeling of achievement and begin your journey with us today.\n\nWe have a great range of fitness classes at Bodywise gym and studios. Each class is catered for Beginner and Advance participants, This is due to our great instructors who will adapt for everyone's abilities. \n\nBodywise are here to help you and tailor your fitness workouts to suit your lifestyle. We appreciate no two people are the same so we will provide you with your own personal training workout to suit you and your situation. Our full nutrition and fitness plans will make sure you get the results you deserve and with support from our qualified trainers you can blitz the fat and be full of energy and confidence .",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:",
          "strapLine1": "Boxing, Pilates, Yoga, Zumba, Dance classes...and lots more",
          "locations": [
            {
              "geolocation": [
                -0.3480116000000635,
                51.0901783
              ],
              "city": "Warnham",
              "postcode": "RH12 3QW",
              "_id": {
                "$oid": "5a1436e52288dd3a30f3f497"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Bodywise Gym & Studios",
          "phone": {
            "number": "1403271698",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Free Weights Area",
            "Sauna",
            "Hairdresser",
            "Beauty Clinic"
          ],
          "testimonials": "Really great gym and studios with an excellent team!\nDEBBIE COOPER ,\n\nThe staff are very nice and make you feel welcome. Very nice gym.\nAMANDA POPPLETON,\n\nExcellent friendly gym would definitely recommend!!\nGEMMA CHARNLEY ,\n\nThis gym has had many improvements made/done since being taken over. Mitzi has plans for many more improvements and has created a comfortable atmosphere in this gym. Everybody is friendly and helpful. It feels more personal than my usual gym and I feel more supported in my training ambitions.\nSAM GEORGE ,\n\nHad my induction at Bodywise today and couldn’t be happier with the service they provide and attention they pay to getting you on the right track, treating each and every person individually to make a program that suits your own needs. Highly recommend.\nPAUL GRIFFITH ,\n\nAbsolutely love this gym. Friendly members and very helpful staff with great range of cardiovascular machines, resistance machines and awesome free weights.\nMOHAMMED HALIM,"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bodywise_gym_and_studios",
      "feature_in_area": "none",
      "__v": 13
    },
    {
      "_id": {
        "$oid": "58879671a8ce313ac39d5b7d"
      },
      "lastUpdated": {
        "$date": "2018-01-18T03:41:11.697Z"
      },
      "createdAt": 1485280881698,
      "email": "info@anniefitness.com",
      "firstName": "Annette",
      "surname": "Foulds",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "2e37f590a94235d0fde8bed40329fb73347a7a0c",
      "salt": "H8YQrmsJ2IZ0EKWbNP13zQ==",
      "hashedPassword": "KE8eVsGVtW5nuklrvr2QcxUZMYm9oebsBNI/VaUFlOPsYtXEixW53S8nCORexx+fXO7HcTLRh34LZhQdGNgCoQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kgnTka8p-anniefouldslogo2.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Endurance",
            "Mobile Trainer",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "price": {
            "min": 60,
            "max": 75
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kL4lz0AN-anniefouldsbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Annie Foulds Fitness,\nProviding one-to-one specialised fitness and wellbeing expertise to Adults. \n\nAnnie makes every effort to provide her customers with the best value and highest quality services at all times.  Her solid experience in the fitness industry and long list of previous and present clients demonstrates her reliability as a top Fitness Professional.\n \nWhat does Annie Foulds Fitness represent?\nCaring, nourishing, developing, and enhancing what it is already inside you. \n\nBy choosing Annie Foulds Fitness you will benefit from her passsion, motivation, expertise, dedication and availability throughout your journey.  You can be sure that together we will meet your required goal.\n\nWhat to expect:\n\nA scheduled a one-to-one meeting to evaluate your strengths and weaknesses and to develop the most beneficial and effective way to reach your specific goals. \n\nGym - park - home:\n\nKensington & Chelsea, Knightsbridge, Battersea & Fulham\n\nProrammes:\nOver 60's\nDance Fit\nAnnie's TRX Fitness\nChallenge Yourself\nStep 2 Fitness\nFitness\nStrength & Conditioning\nBoxing Fitness\nWeight Loss\nNutrition\nEndurance Coaching",
          "offers": "Please visit my website https://www.anniefouldsfitness.com/prices/  for details of my prices, packages and offers",
          "strapLine1": "Providing one to one specialised fitness and wellbeing expertise to Adults",
          "locations": [
            {
              "geolocation": [
                -0.17493600000000242,
                51.485093
              ],
              "city": "London",
              "postcode": "SW3 6AG",
              "_id": {
                "$oid": "5a601757765eba094646f77d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7885140751",
            "code": "+44"
          },
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Ambassador for Great Ormond Street Children's Hospital",
            "Ambassodor for ONELDN - Fulham",
            "Ambassador for Sweatybetty",
            "Accomplished Endurance Athlete",
            "Advanced Anatomy & Physiology",
            "Certified Insanity Instructor (Beachbody.com)",
            "Certified Piyo Instructor (Beachbody.com)",
            "Advanced client assessment methods (static & dynamic posture)",
            "Healthy & Fitness appraisal",
            "lifestyle analysis & Stress Management",
            "Advanced Flexibility methods",
            "Joint assessments muscle balance and postural correction",
            "Fitness testing for power muscular strength endurance and flexibility",
            "Core training and programme design",
            "Nutritional advice for physical activity",
            "Communication & motivation skills",
            "Personal training outside the gym",
            "Special populations: Obesity diabetes asthma and orthopaedic conditions",
            "Consultation & business skills",
            "Gym Instructor Level 2 Qualified",
            "First Aider",
            "Appraisal Nutrition & Weight Management",
            "Exercise Prescription for Pre & Post Natal Clients",
            "Level 3 (AIQ) Teaching Physical Activities with Children (ROA) Level 2"
          ]
        },
        "score": 67
      },
      "active": false,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "annette_foulds",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "5887985ea8ce313ac39d5bc0"
      },
      "lastUpdated": {
        "$date": "2018-01-18T03:30:24.942Z"
      },
      "createdAt": 1485281374291,
      "email": "personaltraining@heskethpt.co.uk",
      "firstName": "Will",
      "surname": "Hesketh",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "+0ZSLvCrqWup3rF9YLjYjw==",
      "hashedPassword": "4vz4zAQ47myC57ZGfCKHnbJbETJQLB52nTa7bj7Oeo7x9YxHTDX6ZzVbQRJ3wupPF9ul1Qvv6tqP1lj/4R+s5w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/H1laRf6H-image.jpeg"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Mobile Trainer",
            "Rehabilitation",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "max": 35,
            "min": 25
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Y4R5dc07-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -2.732898299999988,
                53.3246827
              ],
              "city": "Runcorn",
              "postcode": "WA7 4QX",
              "_id": {
                "$oid": "5a6014d0765eba094646f774"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7736317455",
            "code": "+44"
          },
          "biography": "Hesketh Personal Training are based in Chester, Liverpool, Northwich, Runcorn and Warrington. \n\nOur highly qualified team are dedicated to helping you achieve your goals in a safe, fun, effective and friendly environment.",
          "qualifications": [
            "B.Sc. (Hons) Sport Science",
            "B.A.W.L.A. Weight Training / Lifting",
            "BACR Phase IV Cardiac Rehabilitation",
            "CCPR Community Sports Leader Award",
            "Premier Exercise for special populations",
            "Wright Foundation - Level 3 GP Exercise Referral Specialist"
          ]
        },
        "score": 53
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "will_hesketh",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5887b109a8ce313ac39d6106"
      },
      "lastUpdated": {
        "$date": "2017-11-21T14:19:57.537Z"
      },
      "createdAt": 1485287689844,
      "email": "theoriginh&f@shoomon.xyz",
      "firstName": "Origin H&F",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "KmYBtiWoAEzcpizNVYg7Ag==",
      "hashedPassword": "zl3+/I93EHN0C6cPKR4f9X3FmwMJz179Fyt029/Tka8AyjHaYmbQnNu/Bg1AdlNkCc0jqU+hChjkC7JmQ585/A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4d4b7O6q-originh%26flogo.png"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Brazilian Jiu-jitsu",
            "Kickboxing",
            "MMA (Mixed Martial Arts)",
            "Sports Massage"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.origingym.com/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jSnbTZCg-originh%26fbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Origin Gym & Fitness:\n\nOrigin health and fitness is where you go where you want to get in the zone. It’s one of the largest free weights gyms in Essex and it offers excellent equipment, friendly and professional staff, a sauna and steam room and even massage facilities.\n\nA healthy lifestyle doesn’t mean hitting the gym during the first week of January then forgetting about it for the rest of the year. It means making a long term commitment to making exercise part of your lifestyle on a regular basis. It means setting goals for yourself to challenge your endurance, build your strength and transform your body.\n\nIt means appreciating your body as the beautiful instrument it is and celebrating all of the amazing things it can do.\n\nIn order to push yourself toward your fitness goals successfully, you need the rightenvironment and you will find it here at Origin Gym.",
          "strapLine1": "Friendly & vibrant: where to go to get in to the zone - don't give up",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMonday to Friday: 4am to 10pm\nSaturday: 7am to 6pm\nSunday: 7am to 2pm",
          "locations": [
            {
              "geolocation": [
                0.17560930000001918,
                51.52326919999999
              ],
              "city": "Rainham",
              "postcode": "RM13 8DE",
              "_id": {
                "$oid": "5a14360d2288dd3a30f3f496"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Origin Health & Fitness",
          "phone": {
            "number": "1708630987",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Cage Wall",
            "Boxing Ring"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "origin_health_and_fitness",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5887bce4a8ce313ac39d63c8"
      },
      "lastUpdated": {
        "$date": "2018-01-18T03:26:55.703Z"
      },
      "createdAt": 1485290724963,
      "email": "ed@edharbourne.co.uk",
      "firstName": "Ed",
      "surname": "Harbourne",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "ae3d035f5cfba668ab6e98442fca497b6750d077",
      "salt": "hh1Mi20rBInzP6OlEOA4Uw==",
      "hashedPassword": "pHISulmuzNcEGmPomqxDnE7KybSLXt3FZ+pwPMO7VudgjdyShX+5m157NzHWD5QrZLrNwr2lwz+eAFYM2L8dtg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gJTh1cai-2016-05-15%2016.08.18.jpg"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Nutrition",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 1,
          "price": {
            "min": 60,
            "max": 75
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zVPeWXQv-IMG_2512.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "edinspire",
            "link": "https://twitter.com/edinspire",
            "user_id": "398379386",
            "accessToken": "398379386-CtIuukHyQ2qnkxyT7O3yumB5KvdqXSbapkDxhWFH",
            "accessTokenSecret": "CEsTa2o6a0EuxAe7JQfkNrlO9KDIIbyLg7PTxZTeZqKHW"
          },
          "instagram": {
            "access_token": "243294961.8640130.38160f18fb064fec91e30d8d5f57acf8",
            "link": "https://www.instagram.com/edinspire"
          },
          "locations": [
            {
              "geolocation": [
                -0.13893849999999475,
                51.5145705
              ],
              "city": "London",
              "postcode": "W1F",
              "_id": {
                "$oid": "5a6013ff765eba094646f773"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7703777001",
            "code": "+44"
          },
          "biography": "Being a Personal Trainer in London has given me invaluable experience, and I have had the opportunity to train various types of people from all types of backgrounds with specific individual goals.\n\nI believe Personal Training to be a work of art, tailored to the unique needs of each individual, never compare yourself to others, it’s all about you and what you want. My demographic shows normally I deal with highly stressed, busy and tired 30-50 year old clients, however my client base is very varied.\n\nI specialise in nutrition and supplement protocols for lifestyle, fat loss and body conditioning. My approach is always to listen carefully, be realistic with you about the time and effort needed to achieve your goals. My belief is about keeping it simple, taking small steps and being consistent.",
          "qualifications": [
            "Level 3: Personal Trainer"
          ]
        },
        "score": 60
      },
      "active": false,
      "approved": true,
      "num_votes": 1,
      "trainer_tools_access": false,
      "username": "ed_harbourne",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5887c7d1a8ce313ac39d660a"
      },
      "lastUpdated": {
        "$date": "2017-11-21T14:18:35.037Z"
      },
      "createdAt": 1485293521444,
      "email": "riverclubworcesterpark@shoomon.xyz",
      "firstName": "The River",
      "surname": "Club Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "EBxTDNUfIYVj2p+TDdQjfA==",
      "hashedPassword": "pqgPJZZKhDr0bfDke6NVU4AI5DEreVHfbg/o0k6TwdEb8IlgfwTzgsbpAM3+0NEd3fhwHT1Qhi+DO7IhYjiotw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fjyYW1zY-riverclublogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boot Camp",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Physiotherapy"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.theriverclub.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FLpgd8KO-riverclubbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Balanced Mind In A Balanced Body",
          "biography": "Balancing health and fitness with relaxation and affordable luxury, our state of the art gym and work-out studios provide all the facilities you need to work out, get fit or just have some fun!\n \nIt is ideally located for New Malden, Worcester Park, Tolworth, Motspur Park, Surbiton and Chessington.\nWe have personal trainers who can introduce you to the gymnasium facilities through a personalised exercise programme and health check.\n\nImprove your tennis or learn how to play with the help of our coaches, on our five outdoor tennis courts.\n\nYou can treat yourself with our wide range of treatments, including deep cleansing, aromatherapy, regenerating treatments and reflexology.\n\nOur pool features a retractable roof and sun terrace. Steam and Sauna rooms are poolside for you to relax and unwind in and our juice bar is open for a tasty & healthy selection of light snacks & beverages.\n\nThe club has a fully qualified team of injury rehab specialists as well as other alternative treatment professionals.\n\nFor the golf enthusiast the club is equipped with a 4 hole golf course and a 9 hole practice putting green set in the grounds.",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpening Hours:\n\nMonday - Thursday: 6.30 am - 10.30 pm\nFriday: 6.30 am - 9.00 pm\nSaturday & Sunday: 8.00 am - 9.00 pm\n\nOff Peak Time\nMonday - Friday: 6.30 am - 5.00 pm\nSaturday - Sunday: 2.00 pm - 9.00 pm\n​",
          "locations": [
            {
              "geolocation": [
                -0.26215400000000955,
                51.3774304
              ],
              "city": "Worcester Park",
              "postcode": "KT4 7PX",
              "_id": {
                "$oid": "5a1435bb2288dd3a30f3f495"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The River Club: Health Club & Spa",
          "phone": {
            "number": "2083377788",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Swimming Pool",
            "Sauna",
            "Steam Room",
            "Tennis",
            "Golf"
          ],
          "testimonials": "I would like to recommend The River Club, it is a pleasure to visit this superlative health and fitness club based in the Surrey area. I am not only a member enjoying the premium facilities and benefits of this very up-market health and fitness club, I am also involved in joint marketing ventures and business 2 business modules with the friendly and highly professional staff. I can highly recommend the club and the staff if you are looking for a club that offers something special and would like to promote your products and services whilst meeting new people and working out visit The River Club and enjoy the difference.\n \nDrew Spink\nSales Executive\nMercedes-Benz Retail Used Cars Kingston\n \nWe have a great working relationship with The River Club.  We are always keen to help our employees lead a healthy lifestyle and feel that The River Club are there to lend support and use of their facilities to enable us to incorporate a fun and active lifestyle with work and to them personally. The River Club  come to our site to provide regular Health & Fitness screenings for all of our staff, and offer an  excellent discount on membership rates, which is also open to their friends and family. All new members of staff are also given a complimentary 7 day membership when they first join the company as an added benefit.\n \nDebbie\nNewmarket Promotions"
        },
        "score": 69
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_river_club:_health_club_and_spa",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "5887cc7ea8ce313ac39d670c"
      },
      "lastUpdated": {
        "$date": "2017-10-26T18:13:27.983Z"
      },
      "createdAt": 1485294718852,
      "email": "oasish&f@shoomon.xyz",
      "firstName": "Oasis H&F",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "BzjjyJ3/5ShWnN1rviTs7g==",
      "hashedPassword": "SeaL2QddjCTU7UWamfVsG9cLrHhnFGqvc9Rpbc42PCnLvSJcVdl+Zuit9IRU8FuR2hoDUdtuB3yt/IAgO4lNmg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/b62EbhpM-oasislogo2.png"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://oasishealthandfitness.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/444DzH9J-oasisbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "As a small community club we believe that the personal touch is the best way to make our members feel comfortable and at ease.\n\nWe pride ourselves in the knowledge that with the very best equipment; friendly, approachable and knowledgeable staff we are the only club that will get the results that you deserve and for a price that is right!\n\nWhatever your fitness goals we will do all we can to enable you to achieve them in an non intimidating, friendly environment.\n\nJust give us a call or pop in for a chat – no appointment necessary. We guarantee you won’t be disappointed.\n\nOasis Health and Fitness Club… making fitness affordable.\n\nPersonal training programmes at Oasis Health & Fitness are first and foremost based on individual client fitness development.\n\nThe client’s prior activity levels, general dietary habits, and current fitness levels are analysed, to bespoke programmes, most suitable for physical and health development/improvement!\n\nThis results in client’s health and fitness goals being consistently achieved, for optimum customer satisfaction\n\nBlock booked packages and ad-hoc sessions are available with both male and female instructors.",
          "strapLine1": "Your friendly local gym!",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Times: \n\nMonday: 9am – 10pm\nTuesday: 9am – 10pm\nWednesday: 9am – 10pm\nThursday: 9am – 10pm\nFriday: 9am – 9pm\nSaturday: 9am – 4:30pm\nSunday: 9am – 2pm",
          "locations": [
            {
              "geolocation": [
                0.4273990000000367,
                51.5153689
              ],
              "city": "Stanford-le-Hope",
              "postcode": "SS17 0HF",
              "_id": {
                "$oid": "59f225c76ee9327f153d239a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Oasis Health & Fitness",
          "phone": {
            "code": "+44",
            "number": "1375679533"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "oasis_health_and_fitness",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5887d8bca8ce313ac39d698e"
      },
      "lastUpdated": {
        "$date": "2017-10-26T18:01:09.795Z"
      },
      "createdAt": 1485297852712,
      "email": "clubpulsegoldsmiths@shoomon.xyz",
      "firstName": "Club Pulse",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "1ezOrx6TaTRC5osYX2Mffw==",
      "hashedPassword": "p6XXdeol0QGpcwDMUnDqq5O39D2DJ0wPiby57Y+KKl9wfA3N7QOIsAAC0i1lz5/gN3+N6Y8hBZeB9l4N0rCtHw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4aCvX1x8-clubpulsegsmiths.png"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Corporate Services",
            "Fitness Classes",
            "Ladies Only Gym",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.clubpulsegoldsmiths.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vIRNoeqB-pulsebg2.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Our State-Of-The-Art Gym Hosts The Latest Pulse Fitness Equipmen",
          "biography": "Goldsmiths has teamed up with leading leisure solutions company, Pulse Fitness, to offer state-of-the-art facilities for the whole community.\nClub Pulse Goldsmiths, operated by Pulse Fitness, are proud to be the leading supplier of fitness facilities to the local area. Our aim is to provide affordable and accessible facilities to Goldsmith students, staff and the local community, that will engage and motivate people of all ages and abilities to lead a healthier lifestyle.\nWith our support, expert knowledge and fantastic range of equipment and fitness classes we can help you to achieve your fitness goals in a positive, friendly environment.\nOur gym features an impressive arrange of state-of-the-art Pulse Fitness cardio equipment including treadmills, cross-trainers, bikes and more. Cardiovascular training helps you to live a fitter, healthier life.\nOur CV equipment includes high-tech consoles giving you a exceptional fitness experience with easy to use controls and great range of fitness programmes to choose from. Get in touch with our experienced trainers to understand the most effective workouts for your fitness goals.\nOur vast collection of freeweights and strength machines offer a fantastic way to improve your overall fitness, toning and muscle definition. It’s always best to combine weight training with use of our CV equipment for all round fitness.",
          "offers": "Please visit our website for details of  our prices, packages and offers.\n\nOpening Hours:\n\nMonday - Thursday: 7.00am – 9.45pm\nFriday: 7.00am – 8.30pm\nSaturday & Sunday:  10.30am – 4.30pm\nWomen's only gym times: \nWeekends 9.00am - 10.00am & 4.30pm - 5.30pm",
          "locations": [
            {
              "geolocation": [
                -0.03540109999994456,
                51.4741437
              ],
              "city": "London",
              "postcode": "SE14 6NL",
              "_id": {
                "$oid": "59f222e56ee9327f153d2399"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Club Pulse Goldsmiths",
          "phone": {
            "code": "+44",
            "number": "2086918404"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Student Membership",
            "Senior Membership"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "club_pulse_goldsmiths",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5887e06ba8ce313ac39d6b50"
      },
      "lastUpdated": {
        "$date": "2017-10-26T16:08:16.187Z"
      },
      "createdAt": 1485299819914,
      "email": "miguelsboxing@shoomon.xyz",
      "firstName": "Miguel's",
      "surname": "Boxing Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "1G4hwgjb9ecV+/RTGGnMTQ==",
      "hashedPassword": "Z79WCvzPKkIAGdojrG0bq3RAlwD9HqUsOY6F+Ufw5tfmrsw8T5q6H+tv4yuE5d+M9FAh3oj1EEU8xGhqYkAurA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AShS1426-miguelslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Kids Classes",
            "Ladies Only Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.miguelsboxinggym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pR2X17FD-miguelsbg3.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 1999,
          "strapLine1": "Boxing training for Men, Women & Children Of All Skill And Fitness Levels",
          "biography": "Why should you come to Miguel’s Boxing Gym London?\n\nEven though boxing is, in itself, an individual sport, if you like the idea of making new friends, with similar interests to yourself, then come along to Miguel’s Boxing Gym & Fitness Gym South London and become part of the team.\n\nAlternatively, just come along, work-out if you prefer not to join in with club activities.\n\nWe have certified personal trainers who are prepared to guide you to...\n\nlose excess inches\ntone and improve your body\ngain more energy\nundertake circuit training\nimprove your punch technique, pad and bag work\nskip for strength and stamina\nimprove your balance, stance and flexibility\ncontrol your aggression and release stress constructively\nimprove cardiac strength via aerobic exercise routines\nenjoy a total body workout, while improving your physical and mental health and, for extra safety.\nno sparring until you’re ready for the next stage – an organised fight – if that’s what you then want to do.",
          "offers": "Please visit our website for details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.10119650000001457,
                51.46506599999999
              ],
              "city": "London",
              "postcode": "SE24 0HN",
              "_id": {
                "$oid": "59f208706ee9327f153d2394"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Miguel's Boxing & Fitness Gym",
          "phone": {
            "code": "+44",
            "number": "2077331933"
          },
          "qualifications": [
            "Fully Equipped Boxing Gym",
            "ABA Club",
            "Experienced Trainers"
          ]
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "miguels_boxing_and_fitness_gym",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5887ebfca8ce313ac39d6dd6"
      },
      "lastUpdated": {
        "$date": "2017-10-26T15:50:35.068Z"
      },
      "createdAt": 1485302780492,
      "email": "alivegym@shoomon.xyz",
      "firstName": "Alive Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "aTJfGzIUTjXlDK0Q3hNmKg==",
      "hashedPassword": "TzdEcQGZzK1VCa3nSm4DtPKAztKR/pCOacG3JPRcJobucbv/qY8hzU2+v1DLUipFZ7fOwN9UoS5Oz2b+bBckow==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7vvnckfz-alivelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Personal Training",
            "Sports Massage",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.alivehealth.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VnybR4kd-alivebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Interested in Joining? Free Trials Are Available",
          "biography": "Alive host various classes throughout the week, view the timetable on our website - non members are welcome to all of our classes.\n\nDrop us a line for a FREE trial and come meet the crew, and at our creche we look after your mini-hero, while you workout and be a superhero!\n\nModern Medicine has shown us that in trauma it can work miracles. Every healing system has it’s strengths and weaknesses. There is no healing method that monopolizes the medicinal world which is why complimentary medicine should be embraced as it works together with modern medicine, in a common goal to relieve human suffering, working well with diseases that are not trauma related.\n\nAlive Fitness & Natural Health provides 3 treatment rooms and a salon offering a wide range of treatments, all of our therapist work independently at Alive.\n\nPlease contact our therapist directly to make a booking for a treatment via our website.",
          "offers": "Please visit our website for detils of our prices, packages and offers.\n\nOpening Hours:\n\nMonday to Friday 7.30am – 9pm\nSaturday 9am – 5.30pm\nSunday 9am – 3.30pm",
          "locations": [
            {
              "geolocation": [
                -0.14941079999994145,
                50.8237722
              ],
              "city": "Brighton",
              "postcode": "BN1 2HD",
              "_id": {
                "$oid": "59f2044b6ee9327f153d238e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Alive Gym and Natural Health",
          "phone": {
            "code": "+44",
            "number": "1273739606"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Functional Training Zone",
            "Punch Bags",
            "Gym Rings",
            "Sauna",
            "Benches",
            "Olympic Plates",
            "Racks",
            "Cable Machines",
            "Changing Facilities",
            "Lockers",
            "Creche"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "alive_gym_and_natural_health",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "5887f1bea8ce313ac39d6f19"
      },
      "lastUpdated": {
        "$date": "2017-11-16T11:04:10.301Z"
      },
      "createdAt": 1485304254009,
      "email": "bodyfactorydagenham@shoomon.xyz",
      "firstName": "The Body",
      "surname": "Factory Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "IoZA2G5uia/w9H/+U6i4Gg==",
      "hashedPassword": "f+rIXKkMA2cn/1LvD3gZZ0/F1vzeV2dBRyr9Mi3JqWiwa96e1BLh35VoxvnLFmQ7FMyjJPrC3mdoP7DyvFdYSQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hcLzQGXd-bodyfactpro.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Beauty Treatments",
            "Ladies Only Gym",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.tbfwomenonlygym.co.uk/index.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/syTRJkG8-bodyfactorybg2.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Welcome To The Only Ladies Health Club In Dagenham!",
          "biography": "The Body Factory was launched as a women only health club in 1994.\n\nThe beauty of the club is that it has everything a women needs for health & beauty, including; gym, toning tables, infra-red sauna, pool & spa, sunbeds, beauty salon, afro caribbean hair stylists and european hair stylists.\n\nAs a women only gym, all ladies of all different shapes and sizes feel comfortable and at ease here, not having to 'work out' in front of men. We cater for all ethnic groups and have facilities in place for disabled people.\n\nOur friendly atmosphere, created by dedicated staff and regular members, is noticed the moment you walk through the door.",
          "offers": "Please visit our website for details of prices, packages and offers. \n\nOpening Hours: \n\nMon- Thur: 7am to 10pm\nFri: 7am to 6pm\nSat: 9am to 6pm\nSun: 10am to 2pm",
          "yearsOfExp": 1994,
          "locations": [
            {
              "geolocation": [
                0.12932279999995444,
                51.53908269999999
              ],
              "city": "Dagenham",
              "postcode": "RM9 6BU",
              "_id": {
                "$oid": "5a0d70aa430ff53fda549bad"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Body Factory",
          "phone": {
            "code": "+44",
            "number": "2085956154"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Free Weights Area",
            "Sauna",
            "Spa Pool",
            "Tanning Facilities",
            "Toning Tables"
          ]
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_body_factory",
      "feature_in_area": "none",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "5888556ca8ce313ac39d845e"
      },
      "lastUpdated": {
        "$date": "2017-10-26T15:23:05.581Z"
      },
      "createdAt": 1485329772506,
      "email": "riptideh&f@shoomon.xyz",
      "firstName": "Riptide H&F",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "y3TkVP4fB9PAemfrw+ysUw==",
      "hashedPassword": "IAzjAkm20gp0YYRDNYxLVcD171iGydKZe/sx0kizNKgcjwc8iT1bS0C5V2XvHyF8eRHIGc4mSgy2sC/C1CWb6g==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KfmCk3dE-riptidelogo.png"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Personal Training",
            "Physiotherapy",
            "Small Group Training",
            "Sports Massage"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://riptide.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gz2E4fGP-riptidebg.jpeg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Nothing looks as good as healthy feels",
          "offers": "Please visit our website for details of proces, packages and offers.",
          "biography": "RIPTIDE GIVES YOU A FRESH AND ENGAGING FITNESS EXPERIENCE PLUS RESULTS:\n\nRiptide offers small & large group exercise classes, sport specific conditioning & personal training.\nWe use a private grass area, a well equipped indoor studio and the area around Hove Park.\nFriendly support, guidance and instruction toward your desired outcomes.\nA variety of coaches, instructors, therapists and teachers to support you.\nCoaching with passion, experience, knowledge and enthusiasm.\nCarefully designed, varied,  fun and effective training sessions.\nA great variety of exercise kit to create and stimulate challenges to your body and fitness levels.\nPhysical activity promoting strengthening, conditioning and improving your body's vitality.\nImproving how you, your mind and body cope with life in a truly positive environment.\nMany new updates to classes, courses and exercise sessions to keep it interesting and exciting. \nIn short – this is fitness training for your life.",
          "locations": [
            {
              "geolocation": [
                -0.17425950000006196,
                50.8401181
              ],
              "city": "Hove",
              "postcode": "BN3 7BF",
              "_id": {
                "$oid": "59f1fdd96ee9327f153d237e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Riptide Health & Fitness - Hove Park",
          "phone": {
            "code": "+44",
            "number": "7941534813"
          },
          "qualifications": [
            "Fitness Studio",
            "Free Weights Selection",
            "Functional Training Rig",
            "Kettleballs",
            "Outdoor Training Area"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "riptide_health_and_fitness_-_hove_park",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58886c28a8ce313ac39d8924"
      },
      "lastUpdated": {
        "$date": "2017-11-21T14:11:48.574Z"
      },
      "createdAt": 1485335592232,
      "email": "changehove@shoomon.xyz",
      "firstName": "Change Hove",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "bgukvn1xBQosBOcYyaOEJQ==",
      "hashedPassword": "YBmAcP4ySf80wn0WjP/A7vjcluaTwSQIHAgwg4IYuPt7CZdl5hayEMeBL+fGijTUNA94lR/MLfBFjRq1g6xBjQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Llwewxxm-changehovelogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Body Transformation",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://changehove.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/R7X3QL3b-changebg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "We understand that everyone has different reasons for joining a gym. We also know that everyone enjoys doing different types of activities. So whether it's through gym workouts, group classes or a combination of the two, CHANGE is here to help you achieve your fitness goals, whatever your current fitness level.\n\nIf you've been a member of a gym previously or you're new to the whole thing, we are here to help. Feel free to drop us a line if you have any questions or pop in to see us from 8am-8pm and have a look around.\nState of the art gym with special equipment - designed for beginners through to experienced gym-users\nNo contract\nFantastic value\nFriendly team & personal service\nRelaxed, welcoming atmosphere\nFree parking",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\n\n\nMonday: 6am - 10pm\nTuesday: 6am - 10pm\nWednesday: 6am - 10pm\nThursday: 6am - 10pm\nFriday: 6am - 8pm\nSaturday: 7.30am - 5pm\nSunday: 7.30am - 5pm",
          "yearsOfExp": 2002,
          "locations": [
            {
              "geolocation": [
                -0.1990046999999322,
                50.835988
              ],
              "city": "Hove",
              "postcode": "BN3 5SE",
              "_id": {
                "$oid": "5a1434242288dd3a30f3f493"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Change Gym and Studio",
          "phone": {
            "number": "1273415515",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Cable Machines",
            "Fitness Studio",
            "Free Parking",
            "eGYM"
          ],
          "testimonials": "Richard\nLost over 30kg of fat and gained 6kg of muscle in 24 weeks!\n\nLottie\nLost nearly 8kg of fat in just 12 sessions over 4 weeks!\n\nMaryam\nLost 10kg of fat and gained 3kg of muscle in just 12 sessions over 6 weeks!\n\nJustin\nLost 20kg of fat and gained 10kg of lean muscle in 36 weeks!\n\nNicola\nLost 10kg of fat and gained 3 kg of muscle in 12 weeks!\n\nKayleigh\nLost over 20kg of fat and gained 5kg of muscle with 36 sessions over 12 weeks!"
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "change_gym_and_studio",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5888742ba8ce313ac39d8ae6"
      },
      "lastUpdated": {
        "$date": "2017-10-26T14:40:07.966Z"
      },
      "createdAt": 1485337643148,
      "email": "cheetahs@shoomon.xyz",
      "firstName": "Cheetah's",
      "surname": "Gym Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "sA9A/2AiXQsJV2lsN9K4Nw==",
      "hashedPassword": "u753l28jt3xiJlkCCnoTvPQLqQ6jxoBwQ81vLKiqQRlL7xjjpOAVKtnKEM1C5PiPptB0igMIMNW1Ji0mbK5JPg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6skbDlQA-cheetahslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://cheetahsgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uU3vFHNf-cheeetahsbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Cheetahs Gym is the South Coasts premier gym facility providing customers with friendly and knowledgeable advice, outstanding fitness, unrivaled strength training and functional training since 1962.\n\nCombining the latest state of the art fitness equipment with more traditional methods of training plus a brand new climbing wall with auto belay systems.\n\nDivided over 4 floors, Cheetahs Gym challenges the monotony of other gyms providing separate training areas for each muscle group as well as boasting an impressive functional fitness zone and private female only gym.\n\nHigh intensity circuit classes are held daily and we are proud to have collaborated with Brighton City Boxing Club, hosting the  training sessions for over 10 years.\n\nCheetahs Gym is more than just fitness. Its a lifestyle.",
          "yearsOfExp": 1962,
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 7:00am - 10:00pm\nSat: 9:00am - 4:00pm\nSun: 10:00am - 2:00pm",
          "strapLine1": "With a separate ladies training gym and boxing gym",
          "locations": [
            {
              "geolocation": [
                -0.17881490000002032,
                50.8252602
              ],
              "city": "Hove",
              "postcode": "BN3",
              "_id": {
                "$oid": "59f1f3c76ee9327f153d2377"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Cheetah's Gym",
          "phone": {
            "code": "+44",
            "number": "1273206644"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Ladies Only Area",
            "Functional Training Zone",
            "Supplements",
            "Climbing Wall"
          ]
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "cheetahs_gym",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58887edba8ce313ac39d8d2a"
      },
      "lastUpdated": {
        "$date": "2017-11-16T11:04:49.905Z"
      },
      "createdAt": 1485340379120,
      "email": "miami@shoomon.xyz",
      "firstName": "Miami H&F",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "nBvF/LsZebqY0/DWSTlECw==",
      "hashedPassword": "bDDGl9SUwHvuIbiXk7V5MwJ8E/Z6LqMBGpkYWqWYPfcbVRnwc0sQT2pFkIpD0nQUEwwXpUVPk6hXeAYPO5fquQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/yCpI0nt6-miamilogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Fitness Classes",
            "Kids Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.miamihealthclub.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DuEJKILP-miamibg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "At the Miami Health Club is a family run business. We have been established from 1992 and offering several services to the public such as; gymnasium facilities, fitness classes, spa areas, beauty treatments and tanning. Our client varies from the ages of 3 upwards, catering to both men and women. We have options ideal for guests and members whether they wish to pursue a short term or long term membership. The gymnasium offers a variety of high spec equipment as well as the free weights area. \n \nClasses are run throughout the entire week catering for adults and children, from the age of 3 and above. The classes are very popular, especially as anyone can come in and join. We offer a selection of classes such as Indoor Cycling, Zumba, Aerobics, Acrobatics, Circuit Training, Tae-Kwon-Do, and Dance. \n \nWe offer studio and treatment room hire which can be useful for various activities such as; photography, educational purposes, beauty/ sports treatments and hairdressing.",
          "offers": "Please visit our website for full details of prices, packages and offers.\n\nOpening Hours:\n\nMONDAY, WEDNESDAY & FRIDAY: 7.00AM - 10.00PM\nTUESDAY & WEDNESDAY:  9:00AM - 10:00PM\nSATURDAY:​10.00AM - 6.00PM\nSUNDAY: 10.00AM - 4:00PM",
          "strapLine1": "So many classes, treatments, and therapies for you to enjoy!",
          "locations": [
            {
              "geolocation": [
                -0.08111759999997048,
                51.49034109999999
              ],
              "city": "London",
              "postcode": "SE1 5TY",
              "_id": {
                "$oid": "5a0d70d1430ff53fda549bae"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Miami Health & Fitness",
          "phone": {
            "code": "+44",
            "number": "2077039811"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Spa",
            "Free Weights Area",
            "Jacuzzi",
            "Sauna",
            "Steam Room",
            "Beauty Clinc",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "miami_health_and_fitness",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "5888834aa8ce313ac39d8e2c"
      },
      "lastUpdated": {
        "$date": "2017-10-26T13:56:15.453Z"
      },
      "createdAt": 1485341514156,
      "email": "theironclub@shoomon.xyz",
      "firstName": "The Iron",
      "surname": "Club Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "4UakK+vw2bAi0P0VfU3Rlg==",
      "hashedPassword": "C73ypOGxEE5rBoBktjOnUw+VbGO5aZAayYnkJ5DlwsBjEa9zm+xX/R/IHJ+nNvPwb/2GWu2ITh1/Jd7e8gJZTw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vpTmNBKS-ironlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Olympic Lifting",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.theironclub.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8KtyDt2I-ironbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "OLYMPIC WEIGHTLIFTING, PERSONAL TRAINING, STRENGTH TRAINING & SPORTS CONDITIONING\n\nWelcome to the Iron Club: \n\nWe are based in Central London just minutes away from Vauxhall Tube Station. We offer Gym membership, Classes, Personal Training and so much more. If you are serious about training, then we are the gym for you, offering you all the tools and expertise you need to help you achieve all your strength and fitness goals. \n\n​Why not book a gym tour and see for yourself.",
          "offers": "Please visit our website for details of prices, packages and offers",
          "strapLine1": "OLYMPIC WEIGHTLIFTING, PERSONAL TRAINING, STRENGTH TRAINING & SPORTS CONDITIONING",
          "locations": [
            {
              "geolocation": [
                -0.12190620000001218,
                51.4897875
              ],
              "city": "London",
              "postcode": "SE11 5EQ",
              "_id": {
                "$oid": "59f1e97f6ee9327f153d236c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Iron Club Gym",
          "phone": {
            "code": "+44",
            "number": "7764497594"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Olympic Plates",
            "Olympic Bars"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_iron_club_gym",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58888d71a8ce313ac39d906e"
      },
      "lastUpdated": {
        "$date": "2017-11-21T14:01:27.415Z"
      },
      "createdAt": 1485344113583,
      "email": "fybrefitness@shoomon.xyz",
      "firstName": "Fybre",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "QF904YFvh+iIn5GaypPF3w==",
      "hashedPassword": "xY24bpNS0AXDE4MF/Ud6bVzcbitdBRg4WRBrqLdG6ZcDk4pCa3W4JBYpk4h3N+ZSg0V7HWPAEEZSiCqgjmLedg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Jg8Diu2s-fybrepro.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Osteopathy",
            "Personal Training",
            "Sports Massage",
            "Wellness"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://fybrefit.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/z5NBHS9f-fybrebg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Fybre Fitness Hub in Battersea, your neighbourhood fitness/wellness hub with all the facilities you'd expect to find in larger gyms - but with a cosy twist!\n\nWhether you’re a beginner or a seasoned athlete, a free weights trainer or a triathlete, our helpful staff are trained to assist you in reaching your fitness and wellness goals. \n\nConveniently located on the Northcote Rd in the heart of Battersea, we provide the latest in cardiovascular and weight-training equipment, as well as fitness classes and a luxurious ‘endless’ pool boasting underwater video cameras to help you become a better swimmer with personal trainers and a personal touch you just won’t find anywhere else.\n\nThe Wellness Centre, based at Fybre fitness Hub,  offers Shiatsu massage to enhance your quality of life. Your Shiatsu practitioner will be highly trained and experienced in the art of guiding you to the way you are supposed to be and feel.",
          "strapLine1": "Fitness & Wellness On Your Doorstep",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 06.30 - 21.00 \nFri: 06.30 - 19.30\nSat: 09.00 - 18.00\nSun:10.00 - 16:00\nOFF-PEAK WEEKDAYS 10.00 - 17.00 \nOFF-PEAK SUNDAYS: 09.00 - 16:00",
          "locations": [
            {
              "geolocation": [
                -0.16619790000004286,
                51.45968899999999
              ],
              "city": "London",
              "postcode": "SW11 1NJ",
              "_id": {
                "$oid": "5a1431b72288dd3a30f3f492"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fybre Fitness Hub",
          "phone": {
            "number": "2072237660",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Swimming Pool",
            "Fitness Studio"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fybre_fitness_hub",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58889335a8ce313ac39d9170"
      },
      "lastUpdated": {
        "$date": "2017-11-18T12:25:45.328Z"
      },
      "createdAt": 1485345589922,
      "email": "thebridge@shoomon.xyz",
      "firstName": "The Bridge",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "5RfGuNAEsE244Y3y1l+KaA==",
      "hashedPassword": "Z1hbte8yQ3qBkCmoN+ZLK1wvsWwfzGmE9ti8hs+IZNBnMDgo+NbAX/fmA0dnDbNrMFNU4ZzeTl5v17FW45E6TA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nf1ahVee-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.thebridge-uk.org/index.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": null,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JDjBEOby-bridgebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Women Only Gym Owned By A Registered Charity",
          "biography": "The Bridge is a charity aiming to make a difference to the lives of women living or working in London.\n\nOur fitness facilities (Gym @ The Bridge) are women only but our licensed café (Kitchen @ The Bridge) and hireable meeting rooms (Meet @ The Bridge) are open to all.\n\nOur venue is the ideal place to meet up with friends and colleagues. You can hire a room for your business meeting or conference, work out in our state-of-the-art gym, book yourself a relaxing massage in our treatment room, try a variety of exciting studio classes and enjoy a delicious meal, coffee or glass of wine in our welcoming café.\n\nWhether you live or work in London, are new to the Capital or just passing through, The Bridge has much to offer you.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 07:30 - 21:00\nFri: 07:30 - 20:15\nSat & Sun: 09:30 - 18:00\nBank Holidays: Closed",
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Cafe"
          ],
          "locations": [
            {
              "geolocation": [
                -0.09543299999995725,
                51.5045849
              ],
              "city": "London",
              "postcode": "SE1 0NQ",
              "_id": {
                "$oid": "5a1026c9430ff53fda549bbb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Bridge",
          "phone": {
            "code": "+44",
            "number": "2070896250"
          }
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_bridge",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "58889bdca8ce313ac39d9372"
      },
      "lastUpdated": {
        "$date": "2017-11-16T11:06:12.479Z"
      },
      "createdAt": 1485347804075,
      "email": "thecircle@shoomon.xyz",
      "firstName": "The Circle",
      "surname": "Gym & Spa Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "6TIvgo5Btag+FcEnUDhDNQ==",
      "hashedPassword": "/j+KHmBbb0eaGibUN0app3fXzF7H6rR3tqnQxnt5NCfgQ7PNNHXE4u+1fiRh1ioXbO1Cx5WutcDHubYDp9aMJw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/M8SlKdY2-thecirclelogo.png"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Corporate Services",
            "Fitness Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.thecirclegymandspa.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SLzBhcAO-thecirclebg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "The Circle is a family-owned, boutique Gym & Spa nestled near the iconic Tower Bridge.\n\nWith a fully kitted Gym spread over three rooms, class studio, a luxurious swimming pool, jacuzzi, sauna, and a Spa that boasts an extensive range of treatments; The Circle Gym & Spa offers you the complete health club experience.\n\nIndividual, Couples and Corporate Memberships are available on a no-monthly contract basis. \n\nWith Tower Bridge & Bermondsey Tube Stations only a short walk away,and free parking to all members, we are easily accessible. \n\nThe Circle Gym & Spa Team look forward to welcoming you soon.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\n\n\nMonday\t06:30 - 22:30\nTuesday\t06:30 - 22:30\nWednesday\t06:30 - 22:30\nThursday\t06:30 - 22:30\nFriday\t06:30 - 22:30\nSaturday\t08:00 - 20:00\nSunday\t08:00 - 20:00",
          "strapLine1": "A family-owned, boutique Gym & Spa nestled near the iconic Tower Bridge.",
          "locations": [
            {
              "geolocation": [
                -0.07449689999998554,
                51.5014913
              ],
              "city": "London",
              "postcode": "SE1 2JE",
              "_id": {
                "$oid": "5a0d7124430ff53fda549baf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Circle Gym & Spa",
          "phone": {
            "code": "+44",
            "number": "2073787112"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Swimming Pool",
            "Fitness Studio",
            "Spa",
            "Jacuzzi",
            "Sauna",
            "Free Parking"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_circle_gym_and_spa",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "5888a208a8ce313ac39d94b7"
      },
      "lastUpdated": {
        "$date": "2017-11-16T11:29:13.330Z"
      },
      "createdAt": 1485349384082,
      "email": "energisegoodmayes@shoomon.xyz",
      "firstName": "Energise",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "UC4hD573ebeiOuH/UgweQA==",
      "hashedPassword": "b591BeGQWry68bHWUKj4gAKn0eblAqACpCVe3az8NPbTE+Y1snB4iWmBsd9JwqgDugdsunKJYoGtiitGZsu0bg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/x09GhKr9-energiselogo.png"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.energisehealthclub.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZeGIfUfe-energisebg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "At Energise we are dedicated to promoting the health, fitness, and well-being of women. We have created a safe and secure environment where women can exercise in comfort, have fun, and relax with like-minded people. We like to think we are more than just a gym, as we offer a whole range of services to promote health and well-being to our members, such as:\nLife coaching\nHair & beauty\nInfrared sauna\nFitness classes\nPersonal training\n90-day weight loss challenge\nAs we are passionate about helping others, we always have the energy, dedication, and commitment to help our members improve their health and to change their lives for the better.\n Come and explore the f",
          "strapLine1": "Get involved - have fun - feel energised",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMonday - Friday: 9am to 9pm. \nSaturday: 10am to 6pm. \nSunday: Closed.",
          "locations": [
            {
              "geolocation": [
                0.11862510000003113,
                51.5633501
              ],
              "city": "Ilford",
              "postcode": "IG3 9RT",
              "_id": {
                "$oid": "5a0d7689430ff53fda549bb2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Energise Health Club for Women",
          "phone": {
            "code": "+44",
            "number": "2034417373"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Hairdresser",
            "Beauty Lounge",
            "Sauna"
          ],
          "testimonials": "Ruth Mary — \"I have just started going. What a lovely, friendly, relaxed atmosphere. Great induction and fabulous new and clean equipment. I don’t usually do gyms but I’m a bit of a convert here. Great location for me as well.\"\n\nVisited CamIre Hairdressers who is situated within the Gym on Sat 16th September and had a cut, colour and blow dry. Was extremely happy with the service I had received and the advise that was given with regards too choosing the hair colour. I would highly recommend CamIre. Thank you for the lovely experience & professionalism that I had received and will definitely be revisiting again and more then happy to recommend you.\n\nVery professional service with importance not only paid to fitness but well being which is just as crucial.Staff very helpful and considerate.Would highly recommend to anyone looking to not only shed a few pounds but also looking for an honest and transparent service with you being the main focus.\n\nHad my first session with a personal trainer at energise this morning in co junction with a trial lighter life weight loss programme. Would be lying if I didn't admit I'm aching all over!!!!!! Am looking forward to my next session and losing loads of weight."
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "energise_health_club_for_women",
      "feature_in_area": "none",
      "__v": 12
    },
    {
      "_id": {
        "$oid": "5888aaeaa8ce313ac39d96b9"
      },
      "lastUpdated": {
        "$date": "2018-05-25T14:56:57.343Z"
      },
      "createdAt": 1485351658666,
      "email": "clairs@hotmail.com",
      "firstName": "Clair",
      "surname": "Yates",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "JMOCGYA9TuZi0AhWV6JsHA==",
      "hashedPassword": "gpvaxF5+f7QnEG5yuXbYyyqE1wMbDxSFBPHvFwMqy793GnNhZxEp+N3gysVvLBow1em90l0t+sKio9Kp4WK5FQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N3L37yYd-IMG_2094.JPG"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Hatha",
            "Meditation",
            "Private Classes",
            "Restorative"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 0,
          "price": {
            "min": 5,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6SOfAwi8-crest%20yoga%20logo.png",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155074920280362/",
          "offers": "Please visit my website for detailsof prices, packages and offers",
          "strapLine1": "Yoga and Meditation Classes in Sidcup, Bexley, Chislehurst and Hextable, Kent with Clair Yates",
          "biography": "Hello, my name is Clair Yates and I am a full-time, qualified yoga teacher, teaching practical yoga and meditation in Hextable, Swanley, Sidcup, Bexley and Chislehurst:\n\nYoga classes in Hextable, Swanley, Kent on Monday evenings\nYoga classes on Wednesday mornings, again in Hextable, Swanley, Kent\nYoga classes in Sidcup, Kent on Wednesday evenings\nYoga classes in Hextable, Swanley on Friday mornings\nPrivate one-to-ones, group and family yoga classes, in the local area, including Chislehurst, Bexley and Bexleyheath and at my home yoga studio in Sidcup.\nYoga and meditation workshops scheduled throughout the year\nI teach a range of yoga classes including beginners yoga classes, mixed experience classes, one-to-one yoga, small group private yoga classes and family yoga sessions at various times during the week, as well as yoga classes in secondary schools, with specialist groups and yoga and meditation workshops.\n\nI take part in events on a pro bono basis for charitable organisations.  Please contact me for details.\n\nI am fully insured, hold the Triyoga Teaching Diploma, a British Wheel of Yoga (BWY) accredited diploma, am a REPS, Registered Exercise Instructor, Level 3 and am qualified to teach Supple Strength classes that combine the techniques of yoga and Pilates to strengthen the core and increase flexibility.\n\nThe style of yoga I teach is alignment based Hatha yoga, practical yoga and meditation, accessible for all ages and abilities.  You don’t need to be flexible to start!",
          "locations": [
            {
              "geolocation": [
                0.18359699999996337,
                51.413115
              ],
              "city": "Swanley",
              "postcode": "BR8 7RT",
              "_id": {
                "$oid": "5b0822b9cb6b7909d3f6c8c7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7974728839",
            "code": "+44"
          },
          "qualifications": [
            "Level 3: Yoga Teacher",
            "Yoga teacher",
            "Meditation teacher",
            "Restorative Yoga"
          ],
          "facebookPage": "Crest.Yoga",
          "google": "https://plus.google.com/+ClairYates",
          "twitter": {
            "accessToken": "64696244-O2cY2hP7xJYZnMQTMxtRcdxYPIQYFWaEtXTkZkMCK",
            "accessTokenSecret": "Abs9y8H2hUhw52aMDk3180083PtFkSMTBq9WpHiBgASxz",
            "link": "https://twitter.com/clairyates",
            "screen_name": "clairyates",
            "user_id": "64696244"
          },
          "affiliation": "BWY, REPS, triyoga,",
          "yearsOfExp": 2011
        },
        "score": 83
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "clair_yates",
      "feature_in_area": "none",
      "__v": 8,
      "agent": {
        "family": "Safari",
        "major": "9",
        "minor": "1",
        "patch": "2",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_6) AppleWebKit/601.7.8 (KHTML, like Gecko) Version/9.1.2 Safari/601.7.7"
      },
      "lastLogin": 1527259746766
    },
    {
      "_id": {
        "$oid": "5888b38ea8ce313ac39d987e"
      },
      "lastUpdated": {
        "$date": "2017-10-26T12:19:25.216Z"
      },
      "createdAt": 1485353870224,
      "email": "fightcity@shoomon.xyx",
      "firstName": "Fight City",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "6AeM+gtCqyq3ehXQNi0R9Q==",
      "hashedPassword": "6ukjpIM20Oq25pmm6sFF8UPmPsno2zKLkYBUE/Of9uWXrOM4ClHOHwkGwPHg3kxTkE8xEUOI5PYZBwCe5X5nFA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8rFUyX7Z-fightcitylogo.png"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Krav Maga",
            "Muay Thai",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.fightcitygym.co.uk/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/slKrPo66-fightcitybg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "The Fight City Gym Offering:\n\nOur unique facility equally serves fighters (Boxing, Martial Arts, Muay Thai Kickboxing and Krav Maga) and athletes looking for a dedicated strength and conditioning training environment.\n\nLocated less than five minutes’ walk from Moorgate tube station Fight City Gym provides you with a place to learn from serious athletes and be inspired to push yourself further than you ever thought possible. High standards, elite coaches, state of the art training equipment for one purpose………RESULTS!!!\n\nFight City Gym caters for athletes at all levels who share the mind-set of a professional athlete. If that sounds like you, you will be in good company.\n\nWith a vibrant training atmosphere like no other, Fight City Gym is the antidote to the big chain gyms, focusing on you as a member.",
          "offers": "Please visit our website for details of proces, packages and offers.\n\nOpening Hours:\n\nMonday - Friday: 6am - 9pm\nSaturday: 9am - 4pm\nSunday: Closed",
          "strapLine1": "Boxing, MMA, Muay Thai Kickboxing, wrestling and BJJ classes for all abilities",
          "locations": [
            {
              "geolocation": [
                -0.08605339999996886,
                51.5224443
              ],
              "city": "London",
              "postcode": "EC2A 2DT",
              "_id": {
                "$oid": "59f1d2cd6ee9327f153d2353"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fight City Gym",
          "phone": {
            "code": "+44",
            "number": "2070436032"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Olympic Lifting Area",
            "MMA Cage",
            "Punch Bags",
            "Boxing Ring",
            "Cable Machines",
            "Sprint Track",
            "Sports Therapy",
            "Changing Facilities",
            "Showers",
            "Lockers"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fight_city_gym",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5888bb70739f5b6c586cba74"
      },
      "lastUpdated": {
        "$date": "2017-10-26T12:08:54.150Z"
      },
      "createdAt": 1485355888249,
      "email": "ambition@shoomon.xyz",
      "firstName": "Ambition",
      "surname": "Health Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "hZ7nyQI7DBTQAV6U/ThBFw==",
      "hashedPassword": "LrCAvl4L+yF1dpw8oO0ybJzIRtLkJNxoxrzvKpqovgfE9Zd4g52JZj7erBJXpvJWbfYplWaG3eu9kJ33WFi2uA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/doZcQwlC-ambitionlogo.png"
        },
        "personal": {
          "disciplines": [
            "Aches Pains Pulls & Strains",
            "Corrective Exercise",
            "Diagnosis",
            "Diet & Nutrition",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.ambitionfitness.com/",
          "profileTitle": "Sports Therapy & Rehab",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/f4JLzOBn-ambitionbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Let Food, Exercise and Lifestyle be your Medicine!",
          "biography": "Our philosophy is based upon the following:\n\n1) Educating our clients and patients on what truly constitutes a healthy diet\nHealthy food is based upon real, whole foods; what we are genetically designed to eat and have eaten throughout the past 3.5 million years of evolution.\n\n2) The identification of lifestyle factors that are damaging health\nWe then provide coaching on how to change this.\n\n3) The right type of exercise regime\nThis is exercise that will return someone to optimum strength and performance. Plus, if necessary we have\n\n4) The intervention of osteopathic or physiotherapy treatment\nTo deal with musculoskeletal pain and dysfunction.\n\nIt's also to do with a life philosophy based on following correct principles, and acting with integrity every step of the way. This overriding themes runs through the company from top to bottom and governs everything that we do. Yes, we are highly qualified and recognised by some of the best organisations in the world - the British School of Osteopathy, the CHEK Institute, Chartered Institute of Physiotherapists and Poliquin Education amongst others - but it is the principled application of this technical expertise that also makes us stands out; this is what you will experience with Ambition.",
          "offers": "Please visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.14086440000005496,
                51.4936416
              ],
              "city": "London",
              "postcode": "SW1V",
              "_id": {
                "$oid": "59f1d0566ee9327f153d234e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ambition Health & Performance",
          "phone": {
            "code": "+44",
            "number": "2076305930"
          },
          "qualifications": [
            "Osteopathy",
            "Physiotherapy",
            "Pilates",
            "Swedish Massage",
            "Sports Massage",
            "Reflexology",
            "Calisthenics",
            "Manual Therapy",
            "Bowen Technique"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ambition_health_and_performance",
      "feature_in_area": "none",
      "__v": 11
    },
    {
      "_id": {
        "$oid": "5888bd97739f5b6c586cbaf6"
      },
      "lastUpdated": {
        "$date": "2018-06-21T12:40:49.287Z"
      },
      "createdAt": 1485356439767,
      "surname": "Profile",
      "firstName": "Oneshoo",
      "email": "oneshoo@oneshoo.com",
      "providerId": "3896717402",
      "provider": "instagram",
      "username": "oneshoo",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "3896717402.8640130.8b8fdff1d16246c78731b32f46292d46",
            "link": "https://www.instagram.com/oneshooltd"
          },
          "disciplines": [
            "Body Transformation",
            "Build Muscle",
            "Disability Training",
            "Ladies Only Classes",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "HTTPS://www.oneshoo.com",
          "profileTitle": "Fitness Trainer Group",
          "radius": 0,
          "price": null,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/y1RiAVHP-49565667_ml.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "oneshoo",
            "link": "https://twitter.com/oneshoo",
            "user_id": "740174053437284352",
            "accessToken": "740174053437284352-1C35VZt7EbaEJAy1P5kzZHcHNfXLXLh",
            "accessTokenSecret": "YrOnCZskUBmeffglotFS5AFipqChu3P6SYMFJgTrBn8Zn"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154039720783440/",
          "strapLine1": "The Fitness Directory",
          "biography": "Oneshoo is a public, searchable directory of Fitness Professionals and Businesses, from Personal Trainers to Pilates Instructors and Leisure Centres to Dance Studios.\n\nIf you’re involved in providing Fitness Services or Facilities to clients and you want to be found and engaged by more of them, then you will want to be listed here on Oneshoo. Registration takes just a few minutes, and it is free - no catches.\n\nIf you are looking for a Fitness Experience, use the search facilities on our homepage to find the right Trainer and Health, Fitness or Leisure venue for you.",
          "offers": "Platinum 24HR Member:\nGET THE REST OF 2017 FREE!\nPLUS 3 FREE PT SESSIONS\nSAVE £89.97\n24 hr access & see a personal trainer every month.\n10% off supplements\nPrivate Facebook Group\nMonthly programmes from your PT\n25% off our transformation plans",
          "yearsOfExp": 2015,
          "qualifications": [
            "Shake Bar",
            "Functional Training Zone",
            "Dance & Exercise Studio",
            "Cardio Stations",
            "Fitness Studio",
            "Medical Centre"
          ],
          "facebookPage": "oneshooltd",
          "affiliation": "REPs",
          "locations": [
            {
              "geolocation": [
                -0.07029742933816303,
                51.50124227167247
              ],
              "city": "London",
              "postcode": "SE1",
              "_id": {
                "$oid": "59f1b30c6ee9327f153d2335"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Oneshoo",
          "phone": {
            "number": "8456443752",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jlyRQJyR-Icon-App-76x76%403x.png"
        },
        "score": 82,
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "password_reset_time": 1485364972844,
      "password_reset_code": "X",
      "hashedPassword": "",
      "lastLogin": 1529584849256,
      "feature_in_area": "gyms",
      "agent": {
        "family": "Chrome",
        "major": "67",
        "minor": "0",
        "patch": "3396",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/67.0.3396.87 Safari/537.36"
      },
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5888c3fd739f5b6c586cbc38"
      },
      "lastUpdated": {
        "$date": "2017-10-26T11:31:54.373Z"
      },
      "createdAt": 1485358077003,
      "email": "legendsharingey@shoomon.xyz",
      "firstName": "Legends H&F",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "e6h0WQT91ISv5CTsA6ifpA==",
      "hashedPassword": "iPHhh/BjInVEsb4lYZDeu5HOzrNVT37e3W6Fxt5cgo1m0W7ugkWSeLsbljYxK/k+wRFnAr82qHmYR1OWclsEfA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0IPQYAVy-legendslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Cardiovascular",
            "Diet & Nutrition",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://legendsgym.net/haringey.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/iVJdFUl3-legendsbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Legends maximize your weight training potential and provide you with the guidance and knowledge to achieve greater results.\n\nAt Legends Gym we offer everyone, no matter what their size, shape or ability level the opportunity to improve their level of fitness and have fun at an affordable price!\n\nSupplements Bar:\n\nOur Supplements Bar provides a variety of workout nutritions like, pre-workout drinks, recovery drinks, protien bars etc (Wholesale Available).\n\nPre-workout Drinks:\n\nHot Blood Scitec\nN.O Exlode\nSuper Pump\nJack 3D\nKronic Pump\n\nProtein Drinks:\n\nScitec Protein Shake\nMET-RX Shake\nMuscle Fury weight gainer\nPowerBar Protein Plus Drink\n\nProtein Bars:\n\nPower Bar Muscle Up\nPower Bar Ride Bar\nPower Bar Protein Plus\nVyomax Flapjack\n\nEnergy Drinks:\n\nMaxi Carbs\nMaxi Force\nMaxi Burn\nPower Bar L-Carnitine Drink",
          "offers": "Please visit our website for details of prices, packages and offers.\n\n\nOpening Hours:\n\nMon - Fri: 08:00 - 22:00\nSat & Sun 10:00 - 17:00",
          "locations": [
            {
              "geolocation": [
                -0.09956480000005286,
                51.5827357
              ],
              "city": "London",
              "postcode": "N8 0RX",
              "_id": {
                "$oid": "59f1c7aa6ee9327f153d2344"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Legends Gym Health & Fitness",
          "phone": {
            "code": "+44",
            "number": "2088007599"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Punch Bags",
            "Changing Facilities"
          ]
        },
        "score": 56
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "legends_gym_health_and_fitness",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5888c963739f5b6c586cbd7c"
      },
      "lastUpdated": {
        "$date": "2017-10-26T11:17:46.626Z"
      },
      "createdAt": 1485359459267,
      "email": "legendsdalston@shoomon.xyz",
      "firstName": "Legends",
      "surname": "Dalston Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "7Nb2IHWPD/HSCgxCQB5p5g==",
      "hashedPassword": "JYVnTPZP+UsbivUReNUvgKbbQl6H10UE8THhsiHwaug5kyEm94WoQgCisgL49F1hZ6t/jSC4RFCztClwoWc4kA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jTCz9LPC-legendslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Cardiovascular",
            "Diet & Nutrition",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://legendsgym.net/dalston.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WaSOeiCD-legendsdalstonbg.JPG",
            "size": {},
            "offset": 0
          },
          "biography": "Legends maximize your weight training potential and provide you with the guidance and knowledge to achieve greater results.\n\nAt Legends Gym we offer everyone, no matter what their size, shape or ability level the opportunity to improve their level of fitness and have fun at an affordable price!\n\nSupplements Bar:\n\nOur Supplements Bar provides a variety of workout nutritions like, pre-workout drinks, recovery drinks, protien bars etc (Wholesale Available).\n\nPre-workout Drinks:\n\nHot Blood Scitec\nN.O Exlode\nSuper Pump\nJack 3D\nKronic Pump\n\nProtein Drinks:\n\nScitec Protein Shake\nMET-RX Shake\nMuscle Fury weight gainer\nPowerBar Protein Plus Drink\n\nProtein Bars:\n\nPower Bar Muscle Up\nPower Bar Ride Bar\nPower Bar Protein Plus\nVyomax Flapjack\n\nEnergy Drinks:\n\nMaxi Carbs\nMaxi Force\nMaxi Burn\nPower Bar L-Carnitine Drink",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 08:00 - 22:00\nSat & Sun 10:00 - 17:00",
          "locations": [
            {
              "geolocation": [
                -0.07266260000005786,
                51.5480247
              ],
              "city": "London",
              "postcode": "E8 2NR",
              "_id": {
                "$oid": "59f1c45a6ee9327f153d2343"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Legends Gym Health & Fitness Centre",
          "phone": {
            "code": "+44",
            "number": "2078129600"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Punch Bags",
            "Changing Facilities",
            "Juice / Shake Bar"
          ]
        },
        "score": 56
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "legends_gym_health_and_fitness_centre",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5889b254fcf6f026f1a71a2a"
      },
      "lastUpdated": {
        "$date": "2018-01-18T03:22:18.088Z"
      },
      "createdAt": 1485419092906,
      "providerId": "10212291208815079",
      "provider": "facebook",
      "surname": "Steposz",
      "firstName": "Pawel",
      "email": "qechua@interia.pl",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10212291208815079/",
          "disciplines": [
            "Boxing",
            "Online Coaching",
            "Rehabilitation",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 6,
          "price": {
            "min": 40,
            "max": 45
          },
          "strapLine1": "Perpetuum Fit training is a multi disciplinary regime focussing on the integration of essential mind and body fitness.",
          "biography": "Pawel is a fully qualified Sports Physiotherapist and Personal Trainer who has spent years creating a unique and innovative set of programmes and individually-tailored routines for clients all over the world. \n\nSo whether your Perpetuum Fit programme is part of a group or on a one-to-one basis, Pawel guarantees maximum results using Perpetuum Fit methods.\n\nBy setting out sensible and achievable goals with Pawel from the beginning, Perpetuum Fit can then create an holistic strategy to achieve each of your agreed targets through exercise and dietary, nutritional and lifestyle advice.",
          "yearsOfExp": 2011,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MDoKCwy3-dont-be-the-same.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "PERPETUUMFIT",
            "link": "https://twitter.com/PERPETUUMFIT",
            "user_id": "4794413842",
            "accessToken": "4794413842-U5sApZywBKze6SjEB0mnImf8Yv4BAT3mfIFJ3W4",
            "accessTokenSecret": "uM9dKDgFQSu5BelELMR2UiG6olpnEG6lU8Y6l1ngILg1G"
          },
          "instagram": {
            "access_token": "1508231552.8640130.017edf11b5c14d42b08fd793b265855d",
            "link": "https://www.instagram.com/perpetuumfit"
          },
          "google": "https://plus.google.com/112621493904244442119",
          "locations": [
            {
              "geolocation": [
                0.0912206,
                51.5384521
              ],
              "city": "Barking",
              "postcode": "IG11 9XS",
              "_id": {
                "$oid": "5a6012ea765eba094646f771"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7472887785",
            "code": "+44"
          },
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Master degree in Physiotherapy",
            "Medical First Aid",
            "Mobility Teaching",
            "Functional Anatomy",
            "Athletic Forms of Motor Rehabilitation",
            "Biomechanics",
            "Posture correction",
            "Physiology",
            "Sport & Rehabilitation games",
            "Sport for the handicapped",
            "Manual Therapy Techniques",
            "Thera-Band Training",
            "Swiss Ball Training",
            "Physical Therapy in Knees' dysfunctions. Sport Injuries Treatment",
            "Trigger Points",
            "Muscle Tone Techniques",
            "Kinesiology Taping K-active",
            "Swedish and Thai massage treatment"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OWy6NMbq-filename.jpeg"
        },
        "score": 77
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "pawel_steposz_Hy6IfVwvg",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5889d607fcf6f026f1a721ec"
      },
      "lastUpdated": {
        "$date": "2017-10-26T11:09:59.329Z"
      },
      "createdAt": 1485428231070,
      "email": "titanfightermma@shoomo.xyz",
      "firstName": "Titan",
      "surname": "Fighter MMA Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "9LyD8XTxeZuXFvGJLaWViw==",
      "hashedPassword": "m8E9dx0hQg0CkdyKFR9Z0a5spTzGP1TDiwb9h/51QMI5+PJhfbF9D838RiRPCPMdEUniDrJrEkIxEjWhI26iaA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/To64HNXx-titanfightermmalog.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Brazilian Jiu-jitsu",
            "Kids Classes",
            "MMA (Mixed Martial Arts)",
            "Wrestling"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.titanfighter.com/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/96zw9MK2-titanfighterbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "strapLine1": "Warriors Are Not Born They Are Trained",
          "offers": "Please visit our website for prices, packages and offers",
          "locations": [
            {
              "geolocation": [
                -0.04325100000005477,
                51.6085702
              ],
              "city": "London",
              "postcode": "N18",
              "_id": {
                "$oid": "59f1c2876ee9327f153d2341"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Titan Fighter - Tottenham",
          "phone": {
            "code": "+44",
            "number": "2088876124"
          },
          "biography": "Our Training will help improve all aspects of your fitness\n\nTitan Kids welcome",
          "qualifications": []
        },
        "score": 55
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "titan_fighter_-_tottenham",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5889e8defcf6f026f1a725ef"
      },
      "lastUpdated": 1485521854890,
      "createdAt": 1485433054819,
      "email": "adamsruns@gmail.com",
      "firstName": "David",
      "surname": "Adams",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "dcdXOdX3FQyQ2IKSvXrmtA==",
      "hashedPassword": "7ynoTKbK7axbl86evtQacyzMHN71MsI9bXFK71kGE7nrXPC1IRSZwQFXEmtkHTO5WzDx2kYs+Vi9dBKJ5NNimQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "social": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/QRUQOlc0-IMG_8199.PNG"
        },
        "personal": {
          "disciplines": [
            "Endurance",
            "Online Coaching",
            "Personal Training",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "timezone": "America/New_York",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 50,
          "price": {
            "min": 10,
            "max": 15
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/k93Vl8xv-IMG_2430.PNG",
            "size": {},
            "offset": 0
          },
          "offers": "$50 / month training and meal planning through AdamsRunning APP. Apple Store and Google Play Store. Email me at david@adamsrunning.com or adamsruns@gmail.com",
          "yearsOfExp": 2016,
          "twitter": {
            "screen_name": "adamsruns",
            "link": "https://twitter.com/adamsruns",
            "user_id": "1344729650",
            "accessToken": "1344729650-ruEk4UCrqd7hTDDnCcUH1MGVy7LoMS2nDhiUQOZ",
            "accessTokenSecret": "mrKKn0iNmqf7lSro6MbB4ZIoDAYiKffm0wg3UJcenfltT"
          },
          "instagram": {
            "access_token": "24952460.8640130.fecd9eca2265496a937aa4ac73f298e7",
            "link": "https://www.instagram.com/adamsruns"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154317153076868/",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -80.88728358473846,
                32.31420206043852
              ],
              "country": "United States",
              "city": "Okatie",
              "postcode": "29909"
            }
          ],
          "phone": {
            "code": "+1",
            "number": "8432272637"
          }
        },
        "score": 76
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "david_adams"
    },
    {
      "_id": {
        "$oid": "5889fe19fcf6f026f1a72a73"
      },
      "lastUpdated": {
        "$date": "2017-11-21T13:53:44.890Z"
      },
      "createdAt": 1485438489541,
      "email": "thirdspacetowerbridge@shoomon.xyz",
      "firstName": "Third Space",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "k72Q03bzhdBKREKcpknauQ==",
      "hashedPassword": "PHHLSjA2trQ8zwukkQPT01iot2ylJ08Gjp2S+yr6Hbkk+cEan9VnyZIsv9R00vCX+ToJ1I3QV0syjpeaHcL9Sg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oVlRGkPi-thirdspacelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.thirdspace.london/tower-bridge/#services",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JnfZjRk6-thirdspacebg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Third Space are London’s luxury health clubs; individual in style, bound by a common philosophy: to provide outstanding fitness spaces that members feel are their own.\nEven the river view gives a sense of epic beginnings. Our newest club is pure double-height fitness inspiration – from a sprint track that brings outdoors in, to a pool suite with juniper log hot zones, a dedicated Hot Yoga studio, inspiring group exercise classes to bespoke interactive lighting design.\nOur state of the art gym floor has a wide range of cardio, free weight, plate loaded and functional training equipment - the best in class equipment from the leading worldwide manufacturers - so you have everything you would expect and more besides. Here is a selection of some of the other specialist facilities that you will find in the club, to maximise your training.",
          "strapLine1": "In our glorious, maddening city, your workout can be the difference between a good day or bad",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon-Fri: 06:00 - 22:00\nSat-Sun: 09:00 - 18:00\nBank Holidays: 09:00 - 18:00",
          "locations": [
            {
              "geolocation": [
                -0.08043420000001333,
                51.5052396
              ],
              "city": "London",
              "postcode": "SE1",
              "_id": {
                "$oid": "5a142fe82288dd3a30f3f48f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Third Space",
          "phone": {
            "number": "2079404937",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Plate Loaded Equipment",
            "Functional Training Rig",
            "Sprint Track",
            "Fitness Studio",
            "Cycle Studio",
            "Swimming Pool",
            "Steam Room",
            "Sauna",
            "Day Beds"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "third_space_B152iVkAb",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "588a0276fcf6f026f1a72b75"
      },
      "lastUpdated": {
        "$date": "2017-11-21T13:50:06.697Z"
      },
      "createdAt": 1485439606677,
      "email": "thirdspacesoho@shoomon.xyz",
      "firstName": "Third Space",
      "surname": "Soho Enquirires",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ommhDoUFH+xOPv/2f33b0A==",
      "hashedPassword": "MQXh/b0QT3X8V8y7bMuN6+HHM9B9j2jujr1TDbUwBPs+RsWgGzN+1Fl5l3iUO0vYWIvjAWwt4puBP/Di9VJyXQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uk8DmHRq-thirdspacelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Diet & Nutrition",
            "Fitness Classes",
            "Martial Arts",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.thirdspace.london/soho/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gDrf01Cv-thirdspacebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "In our glorious, maddening city, your workout can be the difference between a good day or bad",
          "biography": "Third Space are London’s luxury health clubs; individual in style, bound by a common philosophy: to provide outstanding fitness spaces that members feel are their own.\n15 years ago in the heart of Soho a different kind of health club was born – individual and committed to the outstanding. From some of London’s most energetic and vibrant streets, enter a sanctuary of total fitness – from hypoxic chamber to Natural Fitness Food, 75sqm of climbing wall to a suspended glass floor training area.\n\nOur state of the art gym floor has a wide range of cardio, free weight, plate loaded and functional training equipment - the best in class equipment from the leading worldwide manufacturers - so you have everything you would expect and more besides. In addition, maximise your training with a selection of other specialist facilities.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMon - Fri 06:30 - 23:00\nSat - Sun 08:30 - 20:30\nbank holidays: 08:30 - 20:30",
          "locations": [
            {
              "geolocation": [
                -0.13581090000002405,
                51.5112227
              ],
              "city": "London",
              "postcode": "W1F 9US",
              "_id": {
                "$oid": "5a142f0e2288dd3a30f3f48d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Third Space",
          "phone": {
            "number": "2074396333",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Boxing Ring",
            "Hypoxic Chamber",
            "Swimming Pool",
            "Medical Centre",
            "Nutrition Centre",
            "Dojo",
            "Climbing Wall",
            "Fitness Studio",
            "Sauna",
            "Steam Room"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "third_space_SyhjuEkRW",
      "feature_in_area": "none",
      "__v": 12
    },
    {
      "_id": {
        "$oid": "588bde748428b640e3988eda"
      },
      "lastUpdated": 1485561514551,
      "createdAt": 1485561460473,
      "email": "natashalndunk@gmail.com",
      "firstName": "Natasha",
      "surname": "Dunk",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "23f3d62050462d91b80b953287c150e1bb8ffd08",
      "salt": "iukKGRk7v6toMoh0n1lCFg==",
      "hashedPassword": "lYY/iP63utZUarJuZ75+AKzbhcuQRuoC0Y8UHjHmZuQPZ00T7tN7K8Xn4i8EvfYjX4W33wYrfZScuQtQ8RRGHA==",
      "settings": {
        "wizard": {
          "complete": false,
          "start": true,
          "additional": false,
          "social": false,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": null,
          "radius": 0,
          "price": {
            "max": 50,
            "min": 0
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.08800610000002962,
                51.49920729999999
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "SE1 4LS"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7515772518"
          }
        },
        "score": 30
      },
      "active": false,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "natasha_dunk"
    },
    {
      "_id": {
        "$oid": "588f0cce6c7b260dba4183d4"
      },
      "lastUpdated": {
        "$date": "2017-10-26T09:59:06.701Z"
      },
      "createdAt": 1485769934797,
      "email": "thirdspacemarylebone@shoomon.xyz",
      "firstName": "Third Space",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "kf4cPePH+Cs1wuE8XPe46w==",
      "hashedPassword": "+Aas8htRb3dTxjpUX2lGOaGXxowzU2fRa0vo+VUpVU+4WupGETlUrRwb/4Ydr2G8y9n7PM48b9/926PAii6ZqQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JJLM2w3z-thirdspacelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Cardiovascular",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.thirdspace.london/marylebone/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1eNfY7FR-thirdspacebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "In our glorious, maddening city, your workout can be the difference between a good day or bad",
          "biography": "Third Space are London’s luxury health clubs; individual in style, bound by a common philosophy: to provide outstanding fitness spaces that members feel are their own.\nThird Space Marylebone is an urban sanctuary. The club features the latest gym facilities including functional training and Watt Bike areas, a recently redeveloped spa with 18m pool, saunas, steam room and luxury treatments, and a huge range of small group training classes.\nOur state-of-the-art gym floor has a wide range of cardio, free weight, plate loaded and functional training equipment - the best in class equipment from the leading worldwide manufacturers - so you have everything you would expect and more besides. In addition, maximise your training with a selection of other specialist facilities:",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon-Fri: 06:30 - 23:00\nSat & Sun: 08:00 - 20:00\nChildren's hours: 10:00-12:00 and 15:30-17:30\nBank holidays: 08:00 - 20:00",
          "locations": [
            {
              "geolocation": [
                -0.150210799999968,
                51.51826699999999
              ],
              "city": "London",
              "postcode": "W1U 2HU",
              "_id": {
                "$oid": "59f1b1ea6ee9327f153d2331"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Third Space",
          "phone": {
            "code": "+44",
            "number": "2070426333"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Plate Loaded Equipment",
            "Functional Training Zone",
            "Functional Training Rig",
            "Swimming Pool",
            "Nutrition Centre",
            "Sauna",
            "Spa",
            "Steam Room",
            "Mind & Body Studio"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "third_space_HJDGNX1CW",
      "feature_in_area": "none",
      "__v": 14
    },
    {
      "_id": {
        "$oid": "588f12f96c7b260dba418516"
      },
      "lastUpdated": {
        "$date": "2017-10-26T10:00:57.065Z"
      },
      "createdAt": 1485771513880,
      "email": "thirdspacecanarywharf@shoomon.xyz",
      "firstName": "Third Space",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "cPQh9fLyhOiqS/w+MtPQZQ==",
      "hashedPassword": "lCLkJ7yk3A3vfY7kyKutjMfmxcxBlmKfC4FzJHotivP6Wj6nAAJ5E5xeFCgxFLc9K+My4xHO5MhEKIqPDtF1qg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9Fvmvsdz-thirdspacelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.thirdspace.london/canary-wharf/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/A2IYgE0W-thirdspacebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "In our glorious, maddening city, your workout can be the difference between a good day or bad",
          "biography": "Third Space are London’s luxury health clubs; individual in style, bound by a common philosophy: to provide outstanding fitness spaces that members feel are their own.\nThird Space Canary Wharf, located in the heart of Canada Square, is Europe’s largest luxury health club, offering state-of-the-art training facilities and 210 classes per week. Lose yourself in 100,000 square feet and 3 floors of luxury training space.\nLose yourself in 100,000 square feet and 3 floors of luxury training space: from one of the highest indoor climbing wall in Europe to a 23m pool filled with natural light; from the most extensive fitness timetable led by some of the best instructors in the UK to a full sized basketball court.\n\nThird Space Canary Wharf has an extensive class timetable, Canary Wharf Spa and a restaurant, The Pearson Room.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 05:30 - 22:30\nSat: 08:00 - 20:00\nSun: 10:00 - 20:00\nBank holidays: 10:00 - 20:00",
          "locations": [
            {
              "geolocation": [
                -0.016294700000003104,
                51.5046219
              ],
              "city": "London",
              "postcode": "E14 5AB",
              "_id": {
                "$oid": "59f1b2596ee9327f153d2332"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Third Space",
          "phone": {
            "code": "+44",
            "number": "2079700900"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Cycle Studio",
            "Functional Training Zone",
            "Swimming Pool",
            "Free Weights Zone",
            "Combat Zone",
            "Basketball Court",
            "Climbing Wall",
            "Plate Loaded Equipment",
            "Golf Lab",
            "Scuba Diving",
            "Lounge Area",
            "Changing Facilities",
            "Lockers",
            "Restaurant"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "third_space",
      "feature_in_area": "none",
      "__v": 13
    },
    {
      "_id": {
        "$oid": "588f1f8f6c7b260dba4187d8"
      },
      "lastUpdated": {
        "$date": "2017-11-21T13:42:45.442Z"
      },
      "createdAt": 1485774735623,
      "email": "theironworksgymmaidstone@shoomon.xyz",
      "firstName": "Ironworks",
      "surname": "Gym Maidstone Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "4oIO3WcnziXd1pkPrFiaAQ==",
      "hashedPassword": "VdMR8/T7WWI9fN8GR0n71b/bCJf3kFvbf7CSbEQLXosdm6VsLHfNOSuAfFpKG6K//DkRDDpl7lyY/GhbTQpeyw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Cu5WBydE-ironworksmaidstonelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Corporate Services",
            "Diet/Meal Plans",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.theironworksgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/azPCKyyV-ironworksbg2.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "At Ironworks Gymnasium Maidstone, we have an extensive range of custom made weight training equipment and next generation cardio machines as well as offering personal training or nutritional sessions to help you reach your goal.\n\nThe Ironworks Gymnasium is Kents most respected, disciplined and friendliest gym. We have over 4 tonnes of state of the art, brand new, custom made weight training equipment including an extensive range of freeweights, dumbells, iso-lever machines, pin stack machines, olympic and standard benches and next gen cardio equipment.\n\nWe offer an extensive range of protein shakes, protein bars, BCAAs, energy drinks and carb nutrition.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening hours: \n\nMon - Thurs: 7:00am - 10:00pm\nFri: 7:00am - 9:00pm\nSat: 9:00 - 7:00pm\nSun: 10:00am - 6:00pm",
          "strapLine1": "Kents most respected, disciplined and friendliest gym",
          "locations": [
            {
              "geolocation": [
                0.561798299999964,
                51.23957060000001
              ],
              "city": "Maidstone",
              "postcode": "ME15 9YF",
              "_id": {
                "$oid": "5a142d552288dd3a30f3f48a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ironworks Gymnasium",
          "phone": {
            "number": "1622764532",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Benches",
            "Racks",
            "Supplements",
            "Shop"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ironworks_gymnasium",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "588f27996c7b260dba41899a"
      },
      "lastUpdated": {
        "$date": "2018-01-18T03:10:50.183Z"
      },
      "createdAt": 1485776793869,
      "surname": "Featherstone",
      "firstName": "David",
      "email": "david@featherstonefitness.com",
      "providerId": "4868046",
      "provider": "instagram",
      "username": "david_featherstone",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "4868046.8640130.9cab5d657f5b4841a22772ffd9672984",
            "link": "https://www.instagram.com/dave_feather"
          },
          "disciplines": [
            "Bodybuilding",
            "Nutrition",
            "Online Coaching"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "price": {
            "max": 250,
            "min": 85
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3P27mSwE-Background.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "feather_fitness",
            "link": "https://twitter.com/feather_fitness",
            "user_id": "766542820190539776",
            "accessToken": "766542820190539776-ZQFo64L0dDsiyLnFw3AmJuAUyNbHJ2w",
            "accessTokenSecret": "ab3uJYidK5qcXI2CZJUoewycZxdSJ46Epg9i5NncSwt1B"
          },
          "facebook": null,
          "biography": "After 5 years working in the City as an Insurance Underwriter, David thought it was time to advance his passion for fitness into a career where he could help others and improve their quality of life.\n\nDavid specialises in optimising body composition, particularly fat loss and muscle gain; however, he also loves to see the psychological transformation that a client goes through: seeing clients walk in the studio stressed out or lacking in confidence but leaving feeling great about themselves. A complete mind and body transformation is what David aspires to achieve for each of his clients.",
          "locations": [
            {
              "geolocation": [
                -0.07627130000003035,
                51.5041673
              ],
              "city": "London",
              "postcode": "SE1 2UP",
              "_id": {
                "$oid": "5a60103a765eba094646f76b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7988113416",
            "code": "+44"
          },
          "qualifications": [
            "Level 3: Personal Trainer"
          ]
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZQXFlF3r-filename.jpeg"
        },
        "score": 60,
        "parent": {
          "$oid": "5888bd97739f5b6c586cbaf6"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "588f27cf6c7b260dba41899b"
      },
      "lastUpdated": {
        "$date": "2017-10-25T21:23:28.488Z"
      },
      "createdAt": 1485776847927,
      "email": "zeushastings@shoomon.xyz",
      "firstName": "Zeus Gym",
      "surname": "Hastings Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "5Zw/0/mwBqs6JlQ6khpzeA==",
      "hashedPassword": "ZuzF5MpsyJryEB7My0YZ+hdc2kFtC5d1l39iFdCgpD8s1bj+WJGlVDAhNiYXalrktcKCXmd6KWnjG9w/PT98kg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IDbqZemj-zeuslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.zeusgymhastings.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jRvcKHCS-zeusbg1.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Motivation is what gets you started - habit is what keeps you going",
          "biography": "We pride ourselves on giving you the tools to reach your goals.\n\nAt Zeus gym the first thing you will notice is the sense of community. No matter what your goals are there are staff and other members here to help you with form, technique or nutritional advice that they have honed over years of expert fitness training. We have a range of athletes that train under our roof including champion strongmen and women and UKBFF finalists.\n\nOur range of equipment includes:\n\nStatic and free-weight area\n\nCalisthenic training rig\n\nStrong man / woman equipment\n\nA full suite of cardio equipment\n\nA variety of studio classes",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 05:30 - 22:00\nSat & Sun 07:00 - 15:00",
          "locations": [
            {
              "geolocation": [
                0.5527988999999707,
                50.8685842
              ],
              "city": "Hastings",
              "postcode": "TN38",
              "_id": {
                "$oid": "59f100d02f10083d617ffe47"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Zeus Gym",
          "phone": {
            "code": "+44",
            "number": "1424446677"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Calisthenic Training Rig",
            "Strong Man / Woman Gear",
            "Squat Rack",
            "Nutrition Centre"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "zeus_gym",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "588f32076c7b260dba418bdd"
      },
      "lastUpdated": {
        "$date": "2017-10-25T20:54:28.084Z"
      },
      "createdAt": 1485779463319,
      "email": "stackzgym@shoomon.xyz",
      "firstName": "Stackz Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "eMS9c04AAoBJ8HG0p4IOxA==",
      "hashedPassword": "f5LA6KqIdMX/xZXYSyB3mzEu88A5ctahv/8RyDy4tju//1zmpO7d/OsA6lXLSCaLI72EOgPcHhJLQnVEYMotHQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FziRbh3G-stackslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.stackzgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dUqJMMv7-stackzbg.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                0.4817868999999746,
                50.8396532
              ],
              "city": "Bexhill",
              "postcode": "TN40 1EN",
              "_id": {
                "$oid": "59f0fa042f10083d617ffe3f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Stackz Gym",
          "phone": {
            "code": "+44",
            "number": "1424213212"
          },
          "biography": "Get Fit Today\nSTACKZ GYM has been changing lives all over Bexhill On Sea since we were established in 2012. We believe that fitness is not a hobby, but a way of life. We founded our gym to be the second home for all of our customers. Whether you exercise everyday or you’ve never stepped into a gym before, STACKZ GYM can help shape the new you. We want to be your next workout partner.\n \nSign Up Today & Get Your Free Day Pass\n \nCall Us Today!",
          "offers": "Please visit our website for details of prices, packages & offers.\n\nOpening Hours: \n\nMon - Fri: 8am - 9:30pm\nSat: 9am - 5pm\nSun: 10am - 4pm\nBank Holidays: 10am - 4pm",
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Mat Area",
            "Changing Facilities",
            "Shop",
            "Rowers",
            "Supplements"
          ]
        },
        "score": 56
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "stackz_gym",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "588f362d6c7b260dba418c9f"
      },
      "lastUpdated": {
        "$date": "2017-11-21T13:33:53.783Z"
      },
      "createdAt": 1485780525475,
      "email": "scitonbridge@shoomon.xyz",
      "firstName": "The SCI",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "16JjDPDAnuxPiI64+wJ79A==",
      "hashedPassword": "HgfV0E0ptFd8sYtv1Ftjnjt5zXeF1DIrFGDMAUXfZUQDR/az0dqTirC67BKIaGWfs//v7nb7T7nhm4BdS5YMHw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1X0544s7-scilogo.png"
        },
        "personal": {
          "disciplines": [
            "Body Transformation",
            "Ladies Only Classes",
            "Personal Training",
            "Physiotherapy",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.thesci.co/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gqI1MaHY-scibg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Award-winning gym: world class S & C coaching, sports specific training, personal training and nutritional consultation",
          "biography": "No Ordinary Gym:\nThe SCI was founded in 2012 by National Level Strength Coaches Alex and Martin to provide a level of training and expertise for people of all ages and abilities previously unseen in the fitness industry.\n \nHaving seen firsthand, and successfully repaired and healed, the damage done to many new and frustrated clients by ‘one size fits all’ gyms and training programmes, the Coaches were determined to create an inspiring and friendly facility with bespoke, state-of-the-art equipment, and offering completely tailored and customised nutrition and training programmes. Three years later, the SCI has enabled many hundreds of people to transform their lives, recover from injury, improve their sports performance and achieve their personal goals.\n \nThe SCI offers gym membership, postural analysis and correction, personal training, nutritional profiling, injury and imbalance rehabilitation, physiotherapy, and StrongMan and FitGirl classes. Our eight-week transformation programme, ‘Engineering the Super You’, has been successful and popular both locally and internationally, having recently having its 150th client complete the course. We also sell a range of vitamins, supplements and branded apparel.\n \nOur clients range from a 75-year-old who has had a double hip replacement, to competitive amateur mixed martial arts fighters, and everyone in between. We provide tailored nutrition and training programmes and consultations via telephone and the Internet.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 6:30 AM - 9.00PM\nFri: 6:30 AM - 8:00PM\nSat & Sun: 8:00 AM - 4:00PM",
          "locations": [
            {
              "geolocation": [
                0.2836263000000372,
                51.1957769
              ],
              "city": "Tonbridge",
              "postcode": "TN9 1FW",
              "_id": {
                "$oid": "5a142b412288dd3a30f3f489"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Strength & Conditioning Institute",
          "phone": {
            "number": "1732350366",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Free Weights Area",
            "Resistance Equipment",
            "Sprint Track",
            "Sled",
            "Prowler",
            "Heavy Tyre"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_strength_and_conditioning_institute",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "588f3cee6c7b260dba418e21"
      },
      "lastUpdated": {
        "$date": "2017-10-25T11:56:43.883Z"
      },
      "createdAt": 1485782254294,
      "email": "anatomy37@shoomon.xyz",
      "firstName": "Anatomy 37",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "YlHfTmIKunhoezgbNvVKQw==",
      "hashedPassword": "tvN7mrggr0yiNQ3FXfyZaiEdIQXzafJhccL6w+JqR0SQGN8KmhDTKU70y/oH6za+gfJ8lbKLFJdzsrGTgv1EyA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3dZ82DwZ-anatomy37logo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Personal Training",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.anatomy37.com/index.html#",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eWurlUxm-anatomy37bg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "State Of The Art Private Training Studio With Personal Trainers",
          "biography": "The newest independent gym is now open in Tonbridge, offering a state of the art facility and bespoke personal training service. \n \nWhether you’re looking for a personal trainer, gym facilities, group training sessions or nutritional advice we can help. All levels of fitness are welcome.\n \nWe’re now accepting new members; contact us today for a free one-day pass\n\nWe don’t believe in long contracts or high joining fees, we provide members with a flexible approach to improving fitness. Sign up for contract-free membership today.\n\n• Sweat – Sweat allows your body to detox, it’s also the best sign of hard work\n• Strength – We build strength not only physically, but mentally by sharing our knowledge\n• Success – We want to empower you on your health and fitness journey\n\nTHE GYM:\nWith its state-of-the-art gym facilities, Anatomy 37 offers the benefits of a private training studio with all the equipment associated with a larger gym. \n \nTo ensure you never have to compromise your workout, we have an optimum number of memberships available – to become one of our exclusive members, please contact us.\n \nWhat we can offer you:\n \n• 3,000 sq ft gym\n• Dedicated functional training studio\n• Strength and conditioning area\n• Outside training area\n• Highly sought after team of personal trainers\n• Group training sessions – contact us for the latest timetable",
          "offers": "Please visit our website for details ofprices, packages and offers.\n\nOpening Hours:\n\nMon-Thurs: 6.30am-9pm\nFri: 6.30am-8pm\nSat: 8am-1pm\nSun: 9am-12pm",
          "locations": [
            {
              "geolocation": [
                0.26870229999997264,
                51.1929646
              ],
              "city": "Tonbridge",
              "postcode": "TN9 1TQ",
              "_id": {
                "$oid": "59f07bfb2f10083d617ffe2d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Anatomy 37",
          "phone": {
            "code": "+44",
            "number": "1732361886"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Outside Training Area",
            "Changing Facilities",
            "Free Pass"
          ],
          "testimonials": "At Anatomy37 we are proud of our reputation for quality, reliability and high level of service but don’t just take our word for it… here’s what some of our satisfied clients have to say about our gym and personal trainers!\n\n“All aspects of life have changed in the two and a half years I've know Adam.   My body is much stronger and fitter, my confidence is at a new high,  I'm more alert during the day and I sleep much better at night.  I can't say thank you enough!\"\n\n“I was referred to Ashley for treatment after injuries suffered from Muay Thai fighting.  Ashley is extremely knowledgeable both in how injuries occur and how to effectively treat the cause of the problem.  I have been in sports for the last 19 years and I can honestly say Ashley provides a service which is of the highest quality!”\n\nA very friendly gym that will welcome everyone.  The owner Adam taught and showed me so many new techniques to my workouts that got me great results. For the 6 months I was there before heading over the pond I made more progress than I ever have in any other gym.  Go pop your head in and take a look for yourself.”\n\n“Great gym with all the equipment you need, the staff are all very friendly and knowledgeable. Always willing to give advise and help keep you motivated.”\n\n“What a great facility! Friendly welcoming staff and a nice airy space that is stocked with some seriously good kit. Sign me up!”"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "anatomy_37",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "588f419b6c7b260dba418f23"
      },
      "lastUpdated": {
        "$date": "2017-10-25T11:37:08.877Z"
      },
      "createdAt": 1485783451045,
      "email": "ironhousegravesend@shoomon.xyz",
      "firstName": "Iron House",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "MglZCTvW1hBb6AJEJLLraQ==",
      "hashedPassword": "mkVWCysMAUHGATtlg0oAaKlne7eooOS//lRFd40BqMyjQvy6li18wn0GppotBK3X30AHnoqY1Y8yzwW28+D2Aw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2EpvUJ19-ironhouselogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Cardiovascular",
            "Power Lifting",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.ironhousegym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0RBeLej3-ironhousebg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The perfect gym to train any time.",
          "biography": "THIS IS IRON HOUSE GYM:\n\nA 10,000 square foot space filled with over 50 resistance machines, free weights, heavy duty punch bags, cardio equipment, a protein bar, supplement shop and plenty of free parking.\n\nAll are welcome, from beginner to experienced. Whatever your skill level, you'll find the right equipment for your needs.\nOur friendly, knowledgeable and experienced staff are always on hand to help and advise.\n\nThe perfect gym to train any time.",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpening Times:\n\nM0n - Fri: 6am - 10pm\nSat & Sun: 9am - 6pm\nBank holidays: 9am - 6pm",
          "locations": [
            {
              "geolocation": [
                0.35665180000000873,
                51.44349949999999
              ],
              "city": "Gravesend",
              "postcode": "DA11 0DL",
              "_id": {
                "$oid": "59f077642f10083d617ffe27"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Iron House Gym",
          "phone": {
            "code": "+44",
            "number": "1474326904"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Dedicated Lifting Area",
            "Cable Machines",
            "Boxing Area",
            "Punch Bags",
            "Creche",
            "Juice / Shake Bar",
            "Supplements",
            "Lockers",
            "Changing Facilities"
          ],
          "testimonials": "Read what our amazing members think!\n\nVery friendly gym with smily and helpful instructors who go out of their way to make you feel welcome. I had my first session today and I can’t wait to go back tomorrow.\nGeorgina \nFantastic gym would recommend it to anyone in the area long or short term! Staff are a great help and machines are excellent quality!\nAdam \nFab gym, friendly atmosphere great place to workout!\nPaul"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "iron_house_gym",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "588f46c46c7b260dba419025"
      },
      "lastUpdated": {
        "$date": "2017-10-25T11:21:33.079Z"
      },
      "createdAt": 1485784772721,
      "email": "solanorpington@shoomon.xyz",
      "firstName": "Solan",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "PXg+h4+WtGSK9tM+UWzNUQ==",
      "hashedPassword": "VJQatusZlt9v/G+uud7/cfa2uCFI6TqDk3QynvyzpxpnQUKV/XpT6+ElQkw7858WtHCpcdIkqTqI9F/TeQnLsQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3eRavRkO-solanlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Martial Arts",
            "Physiotherapy",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.solanfitness.com/clubs/orpington-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SJsPIiHQ-solanbg3.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Multi Award Winning Club",
          "biography": "Located next to the Nugent Shopping Park, a few minutes’ walk from St Mary Cray train station and neighbours with the new AirJump Trampoline park, our Orpington Club resides within the business estate which offers a Mutli-Award Winning Gym for being the UK’s Best Strength Training Gym of the Year 2015, Best Gym Team of the Year 2016 and Combat Zone Gym of the Year 2016. We have been short listed to win again for 2017.\n\nWe have 3 diverse studios; Solan Cycle studio that is boutique like with exciting night club lights and a virtual trainer, a traditional combat martial arts studio with professional punch bags and MMA flooring and an immaculate state of the art fitness studio with a wood sprung dance floor. We host intense classes that will get you fit, strong and motivated.\n\nAfter a hardcore workout, indulge in our luxury changing rooms with complimentary shower gels and shampoo and then enjoy a freshly made protein shake bespoke to your fitness goals at the Fuel Hub. The Fuel Hub offers a nutrition and supplementation bar dedicated around your goals. We serve fresh pre-and post-workout beverages and macro friendly foods.\n\nWith our Solan Staff knowing all members by their first name, dedicated coaches to train you, free parking, diamond light sunbeds, private physiotherapy clinic, DJ night events and Solan Squad community events, it’s no wonder why we are a Multi-Award Winning Gym!\n\nJoin the Orpington Club today!",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMONDAY: 6AM – 10PM\nTUESDAY: 6AM – 10PM\nWEDNESDAY: 6AM – 10PM\nTHURSDAY: 6AM – 10PM\nFRIDAY: 6AM – 10PM\nSATURDAY: 9AM – 7PM\nSUNDAY: 9AM – 7PM",
          "locations": [
            {
              "geolocation": [
                0.11403649999999743,
                51.3911205
              ],
              "city": "Orpington",
              "postcode": "BR5 3QX",
              "_id": {
                "$oid": "59f073bd2f10083d617ffe1f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Solan Fitness Gym",
          "phone": {
            "code": "+44",
            "number": "1689830806"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Official Hammer Strength Centre",
            "Cycle Studio",
            "Fitness Studio",
            "Spa",
            "Martial Arts Studio",
            "Punch Bags",
            "Juice / Shake Bar",
            "Supplements",
            "Free Parking",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "solan_fitness_gym",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "588f4c386c7b260dba419169"
      },
      "lastUpdated": {
        "$date": "2017-11-16T11:47:30.171Z"
      },
      "createdAt": 1485786168998,
      "email": "solaneastgrinstead@shoomon.xyz",
      "firstName": "Solan",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "gJoJkg49WvqRJwrH6IWChQ==",
      "hashedPassword": "PWiTgTl8LRhx8YA6BJoDQZJFAg+YVXhNBD80Bh6g6nI6qGd5e1BKpjIJ2gsytrWMYN/lY2+ljDnx5p7Gj7bvHA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SzSciDDP-solanlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.solanfitness.com/clubs/east-grinstead-club/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MgY0rFeM-solanbg3.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The UK’s Best Strength Training Gym of the Year 2015 awarded by the National Fitness Awards",
          "biography": "Solan Fitness is located within The Atrium, neighbouring Scott’s Cinema and Weatherspoon’s.\n\nWe are proud to have built a state of the art fitness facility investing £1.3 million into the club providing only the best equipment and machines for members suitable for beginners and pro-level athletes. The Club has been designed to have a jaw dropping effect from the first second of walking through the doors and for guests and members to feel the Solan Experience.\n\nOur Club offers a Functional Zone offering a 20-meter sprint track, a striking Rig with full length monkey bars, battle rope and squatting stations, you can create a HIIT circuit with our HIIT equipment as we’re all about maximising results.\n\nOur impressive strength zone offers the best training equipment that is performance driven offering plate loaded, pin select machines and vast amount of free weights.\n\nThe Fuel Hub is a nutrition and supplementation fuel bar for our members fitness goals. The Fuel Hub serves and makes fresh delicious bespoke protein shakes, pre-and post workout beverages and food. It is also a store that sells whey protein and additional nutrition supplies for fitness enthusiasts.\n\nOur 3 unique studios offer Solan Cycle, Night Club effect Fitness studio and our modern Combat Zone studio. We’re proud to be the only Club in East Grinstead to offer Virtual and Instructor based classes allowing you to have access to a fitness class at any time and around your schedule!",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMONDAY: 6AM – 10PM\nTUESDAY: 6AM – 10PM\nWEDNESDAY: 6AM – 10PM\nTHURSDAY: 6AM – 10PM\nFRIDAY: 6AM – 10PM\nSATURDAY: 9AM – 7PM\nSUNDAY: 9AM – 7PM",
          "locations": [
            {
              "geolocation": [
                -0.008867099999974926,
                51.126622
              ],
              "city": "East Grinstead",
              "postcode": "RH19",
              "_id": {
                "$oid": "5a0d7ad2430ff53fda549bb3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Solan Fitness Gym - East Grinstead",
          "phone": {
            "code": "+44",
            "number": "1689820200"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Sprint Track",
            "Cycle Studio",
            "Functional Training Zone",
            "Battle Rope",
            "Plate Loaded",
            "Juice / Shake Bar",
            "Supplements"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "solan_fitness_gym_-_east_grinstead",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "588f5b4585706309618b051d"
      },
      "lastUpdated": {
        "$date": "2017-10-25T10:11:48.801Z"
      },
      "createdAt": 1485790021193,
      "email": "solutionsh&f@shoomon.xyz",
      "firstName": "Solutions",
      "surname": "H&F Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "TCrJJcEJpMub+vXX9DD/tg==",
      "hashedPassword": "6zb2IIuj2y2cRAc9MS7SFQ6AygRnYpUOlUv0+gTxmvwX/T78K0VUc/i0ykyySiDbtpqImaawTwTRChO7SD5xVQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NTxRs7O0-solutionslogo.png"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.solutionsfitness.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/q0lG0iIy-solutionsbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Solutions:\n\nAchieve all your health and fitness goals at Solutions Health and Fitness Club. We have years of experience helping you meet and exceed your fitness goals, no matter how busy your lifestyle.\n\nSolutions is a friendly, passionate, social environment where you can choose from a wide range group fitness classes, separate female only gym, state-of-the-art cardiovascular equipment, circuits, spinning, free weights, indoor swimming pool, steam room, sauna and Jacuzzi.\n\nWhatever your exercise goals, we will help you achieve them by offering a motivating, fun exercise environment and a wealth of expertise through our qualified and friendly staff.\n\nWhen you join Solutions Health and Fitness, you will be booked in for a personal one-to-one session with an instructor. In this session, the instructor will ask you questions about what you want to achieve from your visits to the club. Once the instructor knows your goals and exercise history, they will introduce you to the machines, so that you can start steps towards a fitter and healthier you. You will need to bring some kit such as track suit bottoms or shorts, a T-shirt and trainers.",
          "strapLine1": "Join online now - start getting fit today",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMon - Thurs: 06.30 - 22.00\nFri: 06:30 - 21:00 \nSat: 09:00 - 18:00\nSun: 09:00 - 17:00",
          "locations": [
            {
              "geolocation": [
                -0.09745150000003377,
                51.3771378
              ],
              "city": "Croydon",
              "postcode": "CR0 2BX",
              "_id": {
                "$oid": "59f063642f10083d617ffe11"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Solutions Health & Fitness",
          "phone": {
            "code": "+44",
            "number": "2086815353"
          },
          "qualifications": [
            "Indoor Swimming Pool",
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Spinning",
            "Sauna",
            "Steam Room",
            "Jacuzzi"
          ],
          "testimonials": "\"Nice big spa, clean and even with quite a few people it was not too crowded. Good facilities. \"\nLaura D. ★★★★★ (5 Stars)\n\n\"I had very short time today but I could finish my programme in time as the equipment was available and I will definitely continue my training at this venue\"\nOana L. ★★★★★ (5 Stars)\n\n\"Fresh and clean, specially around the pool area, steam room etc. friendly and helpful staff. I would highly recommend this gym.\"\nHanda M. ★★★★★ (5 Stars)\n\n\"I love Solutions gym, the people there want to train and it is a no thrill gym. The sauna and steam room are still amazing even though not huge. I like the service in their and all other gyms in the area are large chains so this makes a change. \"\nShanie P. ★★★★★ (5 Stars)\n\n\"This gym has great facilities, and all the different equipment you might want to use. The staff are really helpful, with everything from helping with signing up to giving advice on which classes to attend. I like the women only area because there's a wide range of equipment and entertainment on TV.\"\nSandip S. ★★★★★ (5 Stars)"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "solutions_health_and_fitness",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "588f5f1885706309618b05df"
      },
      "lastUpdated": {
        "$date": "2017-11-21T13:32:57.185Z"
      },
      "createdAt": 1485791000629,
      "email": "like2lift@shoomon.xyz",
      "firstName": "Like2lift",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "NrVb6iQPuVp6qRR3i9MHGw==",
      "hashedPassword": "NuxuSyZ/MI3S1qmjKfJXJZJJJBN4Ee8HVnZlabMRDTN9UR4n7pMLs7FpP2S8+bYhET6AqGNpJZyhP6EdD4dJ9Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ltGASCG9-like2liftlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Olympic Lifting",
            "Personal Training",
            "Personalised Programmes",
            "Power Lifting",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.like2lift.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1GfHuPpI-like2liftbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Maximise your potential with a unique strength, power & conditioning gym experience.",
          "biography": "Welcome to Like2Lift Gym in Redhill, Surrey:\n\nWe are committed to taking the best of what’s currently on the market to create a unique strength, power and conditioning gym experience! Our ethos is about employing the advances in modern sports science, whilst retaining an ‘old school’ train hard mentality to help you maximise your potential and reach your personal goals.\n\nAt Like2Lift we have a variety of the best Strength & Conditioning equipment in the UK, along with a friendly welcome, great atmosphere and a team that specialises in everything from fat loss to sports performance.\n\nJoin Like2Lift today, we can help tailor a bespoke programme that works for you, whatever your goal.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 6:00am - 9:00pm\nSat & Sun: 10:00am - 3:00pm",
          "locations": [
            {
              "geolocation": [
                -0.15366389999996954,
                51.2531097
              ],
              "city": "Redhill",
              "postcode": "RH1 2LJ",
              "_id": {
                "$oid": "5a142b092288dd3a30f3f488"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Like2Lift Gym",
          "phone": {
            "number": "7929071801",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Power Rack",
            "Squat Rack",
            "Sled",
            "Farmers Walk",
            "Plate Loaded Gear",
            "And Loads More!"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "like2lift_gym",
      "feature_in_area": "none",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "588f666e85706309618b0761"
      },
      "lastUpdated": {
        "$date": "2017-11-21T13:32:16.096Z"
      },
      "createdAt": 1485792878722,
      "email": "alpha@shoomon.xyz",
      "firstName": "Alpha",
      "surname": "Training Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "7NSXwO8gh0zjXBWsu452lw==",
      "hashedPassword": "SzI6l4U7wAoGH9TAlPngm05QtZflZU4LU2mHEY9pqS6w0LezJTUDhGgxeIQ73HfThYkXRhYE/vRFMEuIhp1o+Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8TpgQKVM-alphalogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Competition Preparation",
            "Diet/Meal Plans",
            "Personal Training",
            "Personalised Programmes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://alphatrainingburgesshill.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nYY9o3R9-alphabg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "If you would like to Join Alpha Training Burgess Hill via our website you will still need to sign your PARQ (health questionnaire) when you come into the gym. There is No Joining Fee, No Contract. If you would like to cancel your membership please do so via email , allowing us giving us 30 Days for payment processor to cancel payments.\n\nPersonal Training Sessions are offered as both 1-2-1 and Group Sessions, these are tailored to your specific needs. Whatever you are looking to do, whether it be getting fit, loosing weight, improving your physique, gaining muscle, improving your sporting performance, injury rehabilitation etc; Jake Scanlon will implement a plan that helps you maximise your results and achieve your goals.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Times:\n\nMon: 9am - 9pm\nTues: 7:00am - 9:00pm\nWed: 9:00am - 9:00pm\nThurs: 9:00am - 9:00pm\nFri: 7:00am - 9:00pm\nSat: 10am - 6pm\nSun: 10am - 2pm",
          "locations": [
            {
              "geolocation": [
                -0.14501689999997325,
                50.9545829
              ],
              "city": "Burgess Hill",
              "postcode": "RH15 9LR",
              "_id": {
                "$oid": "5a142ae02288dd3a30f3f487"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Alpha Training Gym",
          "phone": {
            "number": "7583784572",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Sled",
            "Heavy Tyre",
            "Keg",
            "Kettlebells"
          ]
        },
        "score": 56
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "alpha_training_gym",
      "feature_in_area": "none",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "588f6d7b85706309618b08e3"
      },
      "lastUpdated": {
        "$date": "2017-10-24T19:07:16.310Z"
      },
      "createdAt": 1485794683517,
      "email": "dualstrength@shoomon.xyz",
      "firstName": "Dual",
      "surname": "Strength H&F Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "i26+1uscAOFTAlXo/ieU8Q==",
      "hashedPassword": "80pjFgbwZszrPJyi87XQ5D55F5igm+tI0NxTP6FUVfB6g5IWTSfqLF7LB+uw09FjyQ89E1Cin1db1DvMIWvVFA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Aksfi2Iu-duallogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Cardiovascular",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.dualstrengthandfitness.co.uk/index.php",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/k4QqWTMZ-dualbg.png",
            "size": {},
            "offset": 0
          },
          "biography": "Hello! Welcome to our brand new gym!\n\nDual Strength and Fitness is your new and exciting low cost functional training facility, catering for all levels of physical ability. We offer top of the range strength and conditioning equipment, the very best in customer service, and on-site highly qualified Personal Trainers to help you reach and exceed your goals. We're passionate about offering you the right training environment, at the right price without compromising on service.\n\nSo what do we have?\nWell, alongside the usual in high quality cardio kit, the plate loaded strength equipment is provided by one of the UK's leading manufacturers, you won't find anywhere heavier or better quality than Dual Strength and Fitness.\nWe also have all the latest in functional movement training. TRX Suspension Trainers, Kettlebells, Ropes, Sledges and open areas of grass covered floor for you to practice the latest training techniques, all under the guidance of our team who are available for 1 on 1 training. Why not check out our trainer profiles and see if we can get you started!",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 06:00 - 22:00\nSat & Sun: 07:00 - 20:00",
          "strapLine1": "Low cost, no contract gym done the right way",
          "locations": [
            {
              "geolocation": [
                -0.3085399999999936,
                51.06949669999999
              ],
              "city": "Horsham",
              "postcode": "RH13 5QR",
              "_id": {
                "$oid": "59ef8f6494e4d86bf9be1bb4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Dual Strength & Fitness",
          "phone": {
            "code": "+44",
            "number": "1403260707"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Ropes",
            "Sledge",
            "TRX Suspension",
            "Kettlebells"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "dual_strength_and_fitness",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "588f755785706309618b0aa5"
      },
      "lastUpdated": {
        "$date": "2017-10-24T18:55:42.048Z"
      },
      "createdAt": 1485796695664,
      "email": "fatalsgym@shoomon.xyz",
      "firstName": "Fat Al's Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "qt6A1rTAZu8AuEO3LcUU0Q==",
      "hashedPassword": "IThjLaq1e3Fc6/kBaWiTHrSwv0u5I+LTnButT9r0lzU39lgIz2O9rpvXDewwBqR2JFXw4HkcrOs/6vpfq+2zRw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mlVw3i29-fatalslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Diet & Nutrition",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.fatalsgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 10,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Q5msxHcX-fatalsbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "strapLine1": "Gym, Personal Trainers and mobile services based in Shepperton, Surrey",
          "biography": "Fat Al's Gym:\n\nIs a place for you to work hard and excel. You will never know what your best is until you've trained at Fat Al's Gym.\nDesigned by me for those who want to work hard not work out. You will be encouraged to hit personal bests each time you work out.\n\nEvery piece of equipment has been chosen to have a useful purpose to aid your training not to suit the latest fad. I have trained in countless gyms over the years so I know what is needed for a great work out and what is a waste of time.\n\nFat Al’s Gym is a place for you to work hard and excel. You will never know what your best is until you’ve trained at Fat Al’s Gym.\n\nOne-on-One Personal Training :\n\nAt Fat Al’s Gym we offer REPs certified qualified advanced personal trainers. We can do one on one personal training sessions in the \ngym or we can arrange to meet you at your home or a park near you. \n\nOnline Virtual Personal Training:\n\nIf you can’t get into my gym you can still do Fat Al’s Gym Virtual training. \n\nNutrition:\n\nWe can devise easy to follow eating plans that are nutrionally balanced and calorie controlled.\n\nFood intolerance:\n\nLorisian, has over 30 years’ experience, specialising in laboratory tests that measure food triggers",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpening Hours:\n\n7 Days A Week: 6:00am - 10:00pm",
          "locations": [
            {
              "geolocation": [
                -0.4564073000000235,
                51.38342369999999
              ],
              "city": "Shepperton",
              "postcode": "TW17 9LQ",
              "_id": {
                "$oid": "59ef8cae94e4d86bf9be1bb0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fat Al's Gym",
          "phone": {
            "code": "+44",
            "number": "7837457763"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "State-Of-The-Art Machinery"
          ],
          "testimonials": "“I’ve been training for over 20 years and i could never shift the fat. I found Ali Stewart through a friend of mine and he totally changed my way of thinking, eating and training. In just 14 weeks i dropped 2 stone and I’ve never looked better. I’m 47 this year and I only wish I’d met Ali 20 years ago!”\nS Dennett 2/2016\n\nfantastic machines, and equipment .Al has opened my eyes up to a new way of training. I thought I would feel out of place there, huge fat girl in a lifting gym, but not at all. Everyone so far has seemed friendly. The building is nice bright and airy. The best of all its VERY CLEAN! D wheeler 8/2016\n\nI can honestly say that his gym is the best and the main reason i have kept going so long. I’ve followed Al from training in his 1st gym in the “shed” right through to where he is now and im gutted to be leaving. On the plus side i’ve made some good friends like Luke Austin and had some great advice along the way.\nI cannot thank Ali Stewart PT enough for all he has done for me over the years, dishing out advice & even letting Summer train with me for free. He is not just the guy who owns the gym but he has also become a good friend.\nIf anyone is ever thinking of joining a gym then go and join Fat Als. Everyone there makes you feel welcome and there are no ego’s. The place is full with everything you need and there are always people there to give you advice if you need it. Also the monthly fee is very very low.They also have the best PT trainers. K Wade 3/2017"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fat_als_gym",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "588f7b7185706309618b0be7"
      },
      "lastUpdated": {
        "$date": "2017-11-21T13:29:42.690Z"
      },
      "createdAt": 1485798257250,
      "email": "riverbourne@shoomon.xyz",
      "firstName": "The River",
      "surname": "Bourne Club Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ZYwrYYqqbh6YrsiBhtR7jw==",
      "hashedPassword": "6QsLD2Gw52XD6aMLHAe7giNzXvjyOalXocskPhrFMn7G9UPWdbirZ1s1IzVp+kZeZpSQhzRYOtB5cqXdgLjFyA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IXOE9gaX-riverbournelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Kids Classes",
            "Personal Training",
            "Swimming Lessons"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.rbhealthclub.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jT9x5ZcO-riverbournebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The River Bourne Club is the hub for Health & Fitness in the Chertsey community.",
          "biography": "The River Bourne Club is the hub for Health & Fitness in the Chertsey community.\n\nOur ethos is to provide first rate facilities and services to actively promote healthy living to the community en masse.  All are welcome at the Club, from babies learning to swim right through to those in their later years wishing to maintain their mobility and health through a gentle but effective exercise regime.\n\nThe Club was purchased by The Health Club Collection in 2013 and since then has seen considerable investment and development.  Included in these works has been refurbishment of the changing rooms and lounge area, club redecoration, introduction of a Crèche and an overall cosmetic facelift and re-brand. \n\nIf this wasn't enough, we've installed brand new CV Gym Equipment, a new Free Weights area with Olympic Lifting Platforms, a Functional Training Rig, a boxing zone and cutting edge equipment such as the revolutionary Milon Circle which has enabled us to extend the appeal of exercise far beyond the traditional gym bunny and make a healthy lifestyle more accessible.\n\nFACILITIES & SERVICES AVAILABLE TO YOU AS A MEMBER:\n \n25 metre Swimming Pool\nFull range of CV and Resistance Equipment\nFree weight area with Olympic Lifting Platform\nFunctional Training Rig\n3 Studios offering 80+ Classes per week\nMilon Circle - Fully automated fitness circuit delivering proven results from just 6 x 35 minute workouts per month\nWellness Treatment Room\nCreche\nCafe Lounge\n3 Hours Free Car Parking Per Day",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 6:30am - 10:00pm\nFri: 6:30am - 8:30pm\nWeekends: 8:00am - 6:00pm\nPublic Holidays: 8:00am - 6:00pm",
          "locations": [
            {
              "geolocation": [
                -0.5055668999999625,
                51.38923150000001
              ],
              "city": "Chertsey",
              "postcode": "KT16 9DR",
              "_id": {
                "$oid": "5a142a462288dd3a30f3f485"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The River Bourne Club",
          "phone": {
            "number": "1932560560",
            "code": "+44"
          },
          "qualifications": [
            "80+ Classes Per Week",
            "Swimming Pool",
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Olympic Lifting Platform",
            "Functional Training Rig",
            "Milon Circle",
            "Boditrax",
            "Cafe",
            "Lounge Area",
            "Free Parking",
            "Creche"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_river_bourne_club",
      "feature_in_area": "none",
      "__v": 13
    },
    {
      "_id": {
        "$oid": "588f7f9885706309618b0ce9"
      },
      "lastUpdated": {
        "$date": "2017-10-24T17:41:36.777Z"
      },
      "createdAt": 1485799320034,
      "email": "activzone@shoomon.xyz",
      "firstName": "Activzone",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ul5pzr3TVluBbedrWUJcFg==",
      "hashedPassword": "1jz9IIR1h46oY8mmYSaoEiU5anh/sUO0C94JAOIPGWBM7TqLSbyx7WWAkmyBMHxMPoeUNL/hocl9kfMXruE41g==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IpnXrL6n-activzonelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Cardiovascular",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.activzone.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UBdHCw9S-activzonebg2.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to the friendliest gym in Guildford!\n\nDon’t just take our word for it, even our members have voted us ‘Most Loved Business in Town’ and we are very proud that they think so!\n\nAll ages, shapes and sizes are welcomed and feel comfortable in the open friendly environment of the ActivZone gym.\nYou can park for free and from the moment you enter the air-conditioned Club you are greeted by name and given assistance if needed.\n\nOur fully-qualified fitness and nutritional instructors are committed to helping you reach your health and fitness goals.\nWhether you are enjoying one of our 32 weekly classes, using the variety of cardio machines or weight training, you can be sure our personal trainers will be on hand to give advice.\n\nYour wellbeing is our business and we take it very seriously!\n\nCardio Fitness:\n\n3 x Cross Trainers\n6 x Life Fitness Treadmills\n6 x Life Fitness Bikes\n2 x Concept 2 Rowing Machines\n10 x Spinner® Bikes\nStrength Training:\n\nWe have an excellent range of Star Trac resistance machines (see video)\n\nWe also have a great free weight area with the following:\n\nBench Press\nOlympic Bar\nSquat Rack\nDual adjustable pulley\nMax rack (see video)\nDumbbells ranging from 1kg-42.5kg\n\nFunctional Training:\n\nSuspension Training Zone\nHeavy Tyre\nHeavy Ropes\nKettlebells\nMedicine Balls\nPower Bags\nBoxing bag\n\nOther:\n\nIndoor climbing wall – (Indoor climbing wall video)",
          "strapLine1": "All are welcome to the Weight Watchers Club",
          "locations": [
            {
              "geolocation": [
                -0.5698717000000215,
                51.2460753
              ],
              "city": "Guildford",
              "postcode": "GU1 1EZ",
              "_id": {
                "$oid": "59ef7b5094e4d86bf9be1ba2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Activzone Gym - Within Guilford College",
          "phone": {
            "code": "+44",
            "number": "1483448666"
          },
          "offers": "Please visit our website for details of prices, packages & offers.\n\nOpening Hours:\n\n6:30am - 9pm Mon - Fri\n8am - 6pm Saturday\n8am - 2pm Sunday\nBank holidays 9am-5pm",
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Bench",
            "Olympic Bar",
            "Squat Rack",
            "Functional Training Zone",
            "Punch Bags",
            "Power Bags",
            "Battle Rope",
            "Heavy Tyre",
            "Climbing Wall"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "activzone_gym_-_within_guilford_college",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "588f819085706309618b0d6a"
      },
      "lastUpdated": {
        "$date": "2018-01-18T03:06:26.460Z"
      },
      "createdAt": 1485799824712,
      "surname": "Pryor",
      "firstName": "Richard",
      "email": "Omlessyoga@gmail.com",
      "providerId": "1304071333",
      "provider": "instagram",
      "username": "richard_pryor",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1304071333.8640130.d5fc0b4259294bae997be933639e6189",
            "link": "https://www.instagram.com/omlessyoga"
          },
          "disciplines": [
            "Group Classes",
            "Personal Training",
            "Retreats",
            "Strength & Conditioning",
            "Vinyasa flow"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 40,
          "price": {
            "min": 65,
            "max": 97
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2irhvhvV-IMG_20160805_182926.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Power Mobility and Performance Function Training",
          "yearsOfExp": 1997,
          "locations": [
            {
              "geolocation": [
                -0.127758299999982,
                51.5073509
              ],
              "city": "London",
              "postcode": "WC2N 5DU",
              "_id": {
                "$oid": "5a600f32765eba094646f769"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7958676081",
            "code": "+44"
          },
          "qualifications": [
            "2nd Dan Combat Ju Jitsu",
            "Karate",
            "Krav Maga",
            "Brazilian Ju Jitsu",
            "Western & Thai Boxing",
            "Escrimia System of Hand Stick  Edged Weapons",
            "Various Forms of Submission & Ground Fighting",
            "Strength & Conditioning Coach For a RFC",
            "Rugby Ready Course",
            "SO Medic - Ballistic Firearms Medic",
            "Crossfit Course",
            "Thai Yoga Massage & Injury Prevention",
            "Group Boot Camps & Personal Training"
          ],
          "biography": "After serving in the Royal Marines I split my time between 'The Circuit' , which saw me deployed on various security operations in Europe, USA and the Indian Ocean. These would range from close protection, maritime security and training & development, where I worked for several companies implementing VIP security, hostile environment packages and various armed and unarmed  packages.\n\nMy second passion is working as a strength & conditioning coach / personal trainer in functional fitness. I couple this with various combative arts for specialist groups.\n\nCurrently a tri-services trainer/operator and can be found swinging kettle-bells in obscure parts of London.\n\nYou are far more capable than you imagine: 'Sometimes you just need to open your eyes'."
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/36CubCzU-filename.jpeg"
        },
        "score": 69,
        "parent": {
          "$oid": "5888bd97739f5b6c586cbaf6"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1503488702130,
      "agent": {
        "family": "Chrome Mobile",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Linux; Android 7.1.1; E6853 Build/32.4.A.0.160) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.107 Mobile Safari/537.36"
      },
      "feature_in_area": "none",
      "__v": 18
    },
    {
      "_id": {
        "$oid": "588f8c4885706309618b0fae"
      },
      "lastUpdated": {
        "$date": "2017-10-24T17:24:21.260Z"
      },
      "createdAt": 1485802568940,
      "email": "godalmingfit@shoomon.xyz",
      "firstName": "Godalming",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "IUohMVjb2dSgROGYsAHlRQ==",
      "hashedPassword": "f7hYHZGjQb7AF3nr8wawlTj5Lis763EOiXta/o4Wx8bHskshJ/oxx8IxuDpxGt7UZCKqt7Rtu9woXPiLpsdlkA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8C7P8Kz3-godalminglogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.godalmingfitness.co.uk/index.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ttxp3j7T-godalmingbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The Club with a difference - Medi-Fit approved",
          "biography": "When it comes to running a successful fitness club that members love, we know that it’s much more than a collection of facilities.\n \nWe’ve got all the equipment you could possibly need in a fitness facility and we understand the importance of making you feel comfortable and part of our Club family.\n \nThat’s why you should join us. We understand that exercising does not come easy, and you are going to need the support, motivation and encouragement from us along the way.\n\nWe take your exercise programming seriously all the way through your membership with us – not just the “get go”. We create specially tailored programmes for you and monitor them using our unique programming tools so we can both evaluate your progress.\n \nAnd we also understand that as important as exercise is to your health ... it’s not as important as having a good time with people you like and that are why we are different to other clubs.\n \n \n \n \nWhen it comes to running a successful fitness club that members love, we know that it’s much more than a collection of facilities.\n \nWe’ve got all the equipment you could possibly need in a fitness facility and we understand the importance of making you feel comfortable and part of our Club family.\n \nThat’s why you should join us. We understand that exercising does not come easy, and you are going to need the support, motivation and encouragement from us along the way.\n\nWe’re not like other clubs, we don’t want to be – that’s a good reason why you should Pop down",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpening Hours: \n\nMon-Thus 06:00 - 21:30\nFri:  06:00 - 21:00 \nSat & Sun: 08:00-17:00",
          "locations": [
            {
              "geolocation": [
                -0.6119701999999734,
                51.1859459
              ],
              "city": "Godalming",
              "postcode": "GU7",
              "_id": {
                "$oid": "59ef774594e4d86bf9be1b9b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Godalming Fitness",
          "phone": {
            "code": "+44",
            "number": "1483418775"
          },
          "qualifications": [
            "Cardio Stations",
            "Changing Facilities",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Tanning Facilities",
            "Cycle Studio",
            "Lockers"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "godalming_fitness",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "588f924985706309618b10f0"
      },
      "lastUpdated": {
        "$date": "2017-10-24T17:07:57.087Z"
      },
      "createdAt": 1485804105032,
      "email": "uniqphysique@shoomon.xyz",
      "firstName": "Uniq",
      "surname": "Physique Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "NYC59OtLfMtBNW4FcTm3Fw==",
      "hashedPassword": "P1bqYTuEJjzVfHKZVAHz3KP2J4DaC179+hsSAHs51Vqo0Bh6DAB8m6ZyZ/eXFfiG8rfQf3UQw8kVbpBLr0fMDQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ldbn1Pmb-uniqpro.jpg"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.uniqphysique.com/Default.aspx",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TSqgNPVs-uniqbg2.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Uniq Physique; the intelligent approach to fitness training, based in Haslemere.\n\nUniq Physique is a boutique style gym that promises it's members individual instruction and coaching to ensure that each person achieves his or her personal fitness goals.\n\nOur professional team of instructors works with men, women and teenagers to provide tailored programmes for all levels of fitness.\n\nUsing specialist equipment, coupled with expert nutritional advice, Uniq Physique will help you lose weight, tone or build muscle and become leaner, fitter and stronger.\n\nWhy not visit us for a free training session and see for yourself what Uniq Physique can offer you?\n\nJOIN TODAY\n\nWe now provide the facility for you to sign up online by card payment or direct debit. The process takes just a couple of minutes to complete and all payment details are protected by a secure, encrypted online payment system.\n\nAn outdoor training unit and new equipment has been added to the facilities, meaning that members can now maximize their training and also enjoy the benefits of new classes run on a weekly basis.\n\nThe team at Uniq Physique really care about it's clients and guarantee to help them achieve their personal goals, whatever they may be.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMon - Fri: 05:00 -21:00\nSat: 8:00 - 16:00 \nSun: 10:00 - 14:00",
          "locations": [
            {
              "geolocation": [
                -0.7207210999999916,
                51.0890862
              ],
              "city": "Haslemere",
              "postcode": "GU27 1DN",
              "_id": {
                "$oid": "59ef736d94e4d86bf9be1b98"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Uniq Physique",
          "phone": {
            "code": "+44",
            "number": "1428644032"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Mat Area"
          ]
        },
        "score": 56
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "uniq_physique",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "588f96fa85706309618b11f2"
      },
      "lastUpdated": {
        "$date": "2017-10-24T16:49:56.089Z"
      },
      "createdAt": 1485805306723,
      "email": "studio-1@shoomon.xyz",
      "firstName": "Studio-1",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "3w1g2DLg7rQfnqGX5LJRMw==",
      "hashedPassword": "OLNQqzEFdk0/hK7GXz6YgjaMavn/P/B4/pPmxN+CyiATJb1W5VORlgjJRnI9d5IQtoEdYQxVg6YsRFC+yvHgYQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ccXakhIQ-studio-1pro.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Bodybuilding",
            "Fitness Classes",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.studio1-fitness.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/O4eWDa9j-studio-1bg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Train Gain Achieve",
          "biography": "Here at studio one we have gone to great efforts to incorporate all the aspects we feel necessary for both the serious competitive athlete and the everyday fitness enthusiasts. we have a wide range of resistance plate loading and selectorised equipment which is excellently maintained as well as a separate floor incorporating a large amount of cardiovascular equipment, beauty treatment and sports massage, sun beds and pole fitness classes. here at studio one we pride ourselves on being a results based gym with exceptionally good member retention. We are not driven by how many memberships we sell we have a capped membership of 250 which ensures that all members always have adequate facilities they are not queueing for pieces of equipment and the gym has a bustling yet efficient environment!!!\nJoin a fitness community that is passionate, results driven, and supportive. Whether you’re here for a day or committed to years, Studio1 has exactly what you need in terms of equipment, personal training, supplementation and nutrition, we even have our own clothing outlet and click and collect store.\n\n30 free on site parking spaces",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 07:00 - 21:00\nFriday: 07:00 - 20:00\nSaturday: 09:00 - 18:00 \nSunday: 10:00 - 14:00",
          "locations": [
            {
              "geolocation": [
                -0.764814900000033,
                51.2470356
              ],
              "city": "Aldershot",
              "postcode": "GU11 1LJ",
              "_id": {
                "$oid": "59ef6f3494e4d86bf9be1b92"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Studio-1 Gym",
          "phone": {
            "code": "+44",
            "number": "1252334965"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Plate Loading Equipment",
            "Tanning Facilities",
            "Pole Fitness Classes",
            "Shop",
            "Supplements"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "studio-1_gym",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "588fa41085706309618b14b4"
      },
      "lastUpdated": {
        "$date": "2017-10-24T16:32:30.747Z"
      },
      "createdAt": 1485808656266,
      "email": "fightsciencealdershot@shoomon.xyz",
      "firstName": "Fight",
      "surname": "Science Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "XgIPQ9Zm7QbSxWPKm0lwiA==",
      "hashedPassword": "XRa8oe7OEG/AVbvrDtxLZDOAKizGa0ClDrJDNZNc67qhtIlUtLKmG4R3rXUCnfoDo6f+lI62ZZv8C+oCKhiivg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vVheqPFL-fightsciencealdershotlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Brazilian Jiu-jitsu",
            "Judo",
            "Karate",
            "Kickboxing",
            "MMA (Mixed Martial Arts)"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://fight-science.co.uk/find-gym/aldershot/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AVcvAPBm-leedsbg1.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Performance Gyms and Martial Arts Centres",
          "biography": "At FIGHT SCIENCE Aldershot Gym we currently host the following classes:\nK1 KICKBOXING\nBRAZILIAN JIU JITSU\nMIXED MARTIAL ARTS\nJUDO\nKARATE\nTAE-KWON-DO\nURBAN COMBAT\nKUNG FU\nKRAV MAGA\n\nFIGHT SCIENCE Aldershot is a 20,000 square foot martial arts and performance gym, fitted with the industry's best state-of-the-art fitness and functional strength equipment. Accompanied by a 6,000 square foot tatami matted, martial arts training area split into three sections, the site also boasts a full size competition  cage.\n\nWhatever your fitness goals you will always be welcome at FIGHT SCIENCE, whether you are a combat athlete looking to complete our Fighter Development Programme, training for a particular sport or simply want to improve your general level of fitness, our location in Aldershot makes FIGHT SCIENCE Gym Aldershot the ideal place meet all your fitness and training needs.\n\nOur friendly team of fully-qualified and insured staff are at hand to help you along the way. We provide a first-class service in strength and conditioning, personal training, martial arts, dietary and healthy lifestyle management to ensure you’re well looked after when training with us.",
          "offers": "Please visit our website for details of prices, packages and offers. \n\nOpening Hours:\n\nOpen 7 days a week, 7am to 10pm Monday to Friday, 9am to 6pm Weekends and Bank Holidays.",
          "locations": [
            {
              "geolocation": [
                -0.7644874999999729,
                51.2549238
              ],
              "city": "Aldershot",
              "postcode": "GU11 2BT",
              "_id": {
                "$oid": "59ef6b1e94e4d86bf9be1b8d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fight Science",
          "phone": {
            "code": "+44",
            "number": "1252333135"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "MMA Cage",
            "Martial Arts Training Area",
            "Functional Training Zone",
            "Personal Training"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fight_science_Sku551apZ",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "588fb81585706309618b18f6"
      },
      "lastUpdated": {
        "$date": "2017-10-24T10:58:39.240Z"
      },
      "createdAt": 1485813781557,
      "email": "fightscienceleeds@shoomon.xyz",
      "firstName": "Fight",
      "surname": "Science Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "78u3490x9Ow5d27j3eIPEw==",
      "hashedPassword": "9lTJ2OnQ3E/9wEYQFiNUp23FPPSiMfxAXIC/d8UGUtfyxCv5I4nyIPuDPX1Rs68cB/zr8Tb0B5mDpk1wlLsRtw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SBlv1VRn-fightsciencealdershotlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Brazilian Jiu-jitsu",
            "Karate",
            "Kickboxing",
            "MMA (Mixed Martial Arts)",
            "Muay Thai"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://fight-science.co.uk/find-gym/leeds/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JQQAedLB-leedsbg1.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Performance Gyms and Martial Arts Centres",
          "biography": "CONQUEST MMA\nSTEPPIN RAZOR MUAY THAI BOXING\nGRACIE BARRA LEEDS BJJ\nLEEDS FREESTYLE KICKBOXING\nHIDDEN DRAGON KARATE\nWHITE ROSE AIKIDO\nKUNG FU\nCROSSFIT\n\nFIGHT SCIENCE Leeds is a 20,000 square foot martial arts and performance gym, fitted with the industries best state-of-the-art fitness and functional strength equipment. Accompanied by a 6,000 square foot tatami matted, martial arts training area split into three sections. The site boasts a full size competition boxing ring and cage.\n\nWhatever your fitness goals you will always be welcome at FIGHT SCIENCE, whether you are a combat athlete looking to complete our Fighter Development Programme, training for a particular sport or simply want to improve your general level of fitness, our location in Morley makes FIGHT SCIENCE Leeds the ideal place meet all you fitness and training needs.\n\nOur friendly team of fully qualified and insured staff are at hand to help you along the way. We provide a first class service in strength and conditioning, personal training, martial arts, dietary and healthy lifestyle management, to insure you’re well looked after when training with us.\nWe also have an on-site treatment rooms for sports massage and injury rehabilitation, we have a crossfit box, a cafe area that sells hot and cold drinks, a FIGHT SCIENCE shop that stocks everything you could possibly need, from supplements to branded clothing and training equipment and a café that sells hot and cold drinks, snacks and protein shakes.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nOpen 7 days a week, 6am to 10pm Monday to Friday, 7am to 6pm Weekends and Bank Holidays",
          "locations": [
            {
              "geolocation": [
                -1.6103259999999864,
                53.735228
              ],
              "city": "Morley",
              "postcode": "LS27",
              "_id": {
                "$oid": "59ef1cdf94e4d86bf9be1b81"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fight Science",
          "phone": {
            "code": "+44",
            "number": "1132524197"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "MMA Cage",
            "Boxing Ring",
            "Punch Bags",
            "Power Bags",
            "Kick / Body  Shields",
            "Competition Ring"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fight_science_rJtshcnpW",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "588fbc4a85706309618b19f8"
      },
      "lastUpdated": {
        "$date": "2017-10-24T10:43:29.113Z"
      },
      "createdAt": 1485814858862,
      "email": "fightsciencestaines@shoomon.xyz",
      "firstName": "Fight",
      "surname": "Science Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Q5eQRSujD3Pg6jOgdQAp2Q==",
      "hashedPassword": "dC1CK02HS0ZnzMs73ppmSlk5ONTnDu+H9LCiygDLg3ux/KAfk5XQ1LcCi0XfOPNHf1dglp4MaolFxWgVYJfSMw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/R2nLOvIN-fightsciencealdershotlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Capoeira",
            "Filipino Martial Arts",
            "Kickboxing",
            "MMA (Mixed Martial Arts)"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://fight-science.co.uk/find-gym/staines/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mW7isZa7-leedsbg1.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "KICKBOXING\nHAWAIIAN and FILIPINO MARTIAL ARTS\nCAPOEIRA\nBOXING\nK1/MMA\nKRAV MAGA\nFITNESS CLASSES\nPOLE FITESS/AERIAL HOOP/SILKS\nBRAZILIAN JIU JITSU\n\nFIGHT SCIENCE Staines is a 9,000 square foot martial arts and performance gym, fitted with the industries best state-of-the-art fitness and functional strength equipment. Accompanied by a large martial arts training area.\n\nWhatever your fitness goals you will always be welcome at FIGHT SCIENCE, whether you are a combat athlete looking to complete our Fighter Development Programme, training for a particular sport or simply want to improve your general level of fitness, our location on Thames Street in the heart of Staines town centre makes FIGHT SCIENCE the ideal place meet all you fitness and training needs.\n\nWe have a friendly team of fully qualified and insured staff who are at hand to help you along the way. We provide a first class service in strength and conditioning, personal training, martial arts, dietary and healthy lifestyle management, to insure you’re well looked after when training with us.\n\nWe also have an on-site treatment room for sports massage and injury rehabilitation, a shop that stocks everything you could possibly need, from supplements to branded clothing and training equipment, so drop in for a no obligation tour.\n\nJoin us and become part of what is rapidly becoming the UK’s most talked about martial arts and fitness facility.",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpen 7 days a week, 6:30am to 10pm Monday to Friday, 9am to 6pm Weekends and Bank Holidays.",
          "strapLine1": "Performance Gyms and Martial Arts Centres",
          "locations": [
            {
              "geolocation": [
                -0.5127841999999418,
                51.43303479999999
              ],
              "city": "Staines-upon-Thames",
              "postcode": "TW18 4SD",
              "_id": {
                "$oid": "59ef195194e4d86bf9be1b7f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fight Science",
          "phone": {
            "code": "+44",
            "number": "1784467853"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fight_science",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "588fc10585706309618b1afa"
      },
      "lastUpdated": {
        "$date": "2017-11-21T13:19:12.714Z"
      },
      "createdAt": 1485816069233,
      "email": "bodyzonebracknell@shoomon.xyz",
      "firstName": "Bodyzone",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "PUkZApbl0EWLviG5Nl7/UA==",
      "hashedPassword": "M8mzNZuUTIfNUjmngc/MfEAAW95mND6iDEtfd1ucVwEGnRgJzQ4pLktwseYLHrgFkUaHT4CMIxFGMEZDaCwdAg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rhlC3lix-bodyzonepro.jpg"
        },
        "personal": {
          "disciplines": [
            "Academy",
            "Brazilian Jiu-jitsu",
            "Diet/Meal Plans",
            "MMA (Mixed Martial Arts)",
            "Personalised Programmes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.bodyzonegym.com/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N4Hv2upn-bodyzonebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The gym that caters to all shapers and sizes - the ultimate fitness experience!",
          "biography": "We have the widest range of free weights, lever and stack machines in the area, as well as cardio-vascular fat burning and fitness machines. We are the areas longest established professional facility and provide a most superior, friendly gym experience, along with diet plans and training programmes. \n\nThe Bat Cave MMA Academy:\n \nThe BAT CAVE MMA Academy is a centre of excellence with a focus on providing a motivational, \ninspirational and supportive training zone for everyone.\n \nTHE BAT CAVE MMA Academy is owned by Brenden Flanagan, who is an accomplished Mixed Martialartist and Brazilian Jiu-Jitsu competitor representing Carlson Gracie.\n \nCome develop your skills in a range of styles from Boxing, Thai Boxing and K1. To MMA, Olympic wrestling, Brazilian Jui-Jitsu and functional Circuit training.\n\nAll over seen by our experienced coaching team.",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 6.30am - 10.00pm\nSat: 8am - 5pm\nSun: 8am - 4pm",
          "locations": [
            {
              "geolocation": [
                -0.747774800000002,
                51.40478459999999
              ],
              "city": "Bracknell",
              "postcode": "RG12 9SE",
              "_id": {
                "$oid": "5a1427d02288dd3a30f3f482"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Bodyzone Heavyweight Fitness & Health Gym",
          "phone": {
            "number": "1344868861",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Combat Zone",
            "Power Bags",
            "Punch Bags"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bodyzone_heavyweight_fitness_and_health_gym",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58905ac485706309618b3bbe"
      },
      "lastUpdated": {
        "$date": "2017-10-24T10:09:56.850Z"
      },
      "createdAt": 1485855428769,
      "email": "moodshavant@shoomon.xyz",
      "firstName": "MoodsFitness",
      "surname": "Studio Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "t8HDzbP5sl8m47nWneX3RA==",
      "hashedPassword": "oP0IytYo1D5jnkVd9M342wXjmbOF6GFeksa1Y9cCoybnyd6F48E/hKPWey7OtagasrhqJOIKMTn/cOs8xBI/6w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Siz6wD9k-moodspro.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Cardiovascular",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.moodsfitnesshavant.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qR4dz4Uw-moodsbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Let us get you in the mood for fitness and fun at Moods Fitness and Dance Studio",
          "biography": "Moods Studio Class Timetable offers a huge variety of cardiovascular and conditioning classes, ensuring there's something to suit everyone. Seven pilates classes per week and two yoga classes offer opportunities 6 days per week to strengthen and streamline your body, and cardio classes aid weight loss. \n\nThere are over 20 classes to choose from and our friendly, dedicated fitness instructors will give you information and advice on all classes to help you decide which ones suit you.\n  \nWe don't think you will find a more friendlier fitness venue than Moods Studio in Havant and with the new Curve Fit Club opening inside Moods in April, there will be monthly social events for members. A recent independent survey showed 100% of Moods Fitness customers would recommend us to a friend.\n \nPersonal training is available with REPS level 3 qualified instructors if required.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMon - Thurs: 9:00am - 9:30pm\nFri: 9:00am - 7:00pm\nSat: 9:00am - 6:00pm",
          "locations": [
            {
              "geolocation": [
                -0.9802815000000464,
                50.8509156
              ],
              "city": "Havant",
              "postcode": "PO9",
              "_id": {
                "$oid": "59ef117494e4d86bf9be1b6f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Moods Fitness Studio - Havant",
          "phone": {
            "code": "+44",
            "number": "2392455343"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "20+ Classes Per Week",
            "Licensed Bar"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "moods_fitness_studio_-_havant",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5890609585706309618b3d00"
      },
      "lastUpdated": {
        "$date": "2017-10-24T10:07:13.074Z"
      },
      "createdAt": 1485856917240,
      "email": "curvefithavant@shoomon.xyz",
      "firstName": "Curve Fit",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "a/gUK7I4W5P2wOdKUos2wQ==",
      "hashedPassword": "//ixlMtX1274X7tv8Mj+DJdw2tAyVLeTaUMB6uOph7qhm5FUlYYyjCiJ5iJEr+l8RYtTHwC1uaKpjfB4W8Q5SA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5NEshaWe-curvefitpro.jpg"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.moodsfitnesshavant.co.uk/curvefit",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/QMNaDTHM-curvefitbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Over 20 FITNESS CLASSES FREE with our CURVE FIT GYM membership: our class timetable includes Pilates, Yoga, Zumba, Legs, Bums & Tums, Total Body Workout, Abs Attack, Fab Abs express, Keep Fit, Combat, Circuits.\n\nCURVE FIT 'LADIES ONLY GYM' opened inside Moods Fitness Studio in May 2015. The ladies only gym has an Easyline Technogym 30 minute fitness, weight, inch and fat loss circuit, using state-of-the-art hydraulic equipment that combines aerobic exercise and muscle toning to achieve amazing results. \n\nThe specialised Easyline equipment, together with our traditional cardio gym equipment (treadmill, cross-trainer and bike), provides the ultimate fitness experience in stunning surroundings where you can relax after your routine and enjoy refreshments at our K Bar & Café.​\n\nMEMBERSHIP INCLUDES:\n\nUse of State-of-the-art hydraulic Easyline Gym equipment and traditional cardio equipment.\nA huge variety of fitness classes (over 20 classes per week) in our large mirrored studio.\nOur 28 day 'Re-Energize weight and fat loss programme - nutrition advice included.\nFriendly, supportive fitness instructors and coaches.\nGym inductions included in your membership and discounted personal training available.\nMonthly club social events.\nExclusive disounted offers at 'Kiri Beauty Salon' and 'Just Angels' (next to Moods Studio).\nRelax in our comfortable lounge area, 'K BAR' and enjoy a coffee and snack.\nOnly members can book functions (birthday, engagement and office parties) at Moods Studio.",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpening Hours: \n\nMon - Thurs: 9:00am - 9:30pm\nFri: 9:00am - 7:00pm\nSat: 9:00am - 6:00pm\nSun: 11:30am - 5:pm",
          "locations": [
            {
              "geolocation": [
                -0.9802815000000464,
                50.8509156
              ],
              "city": "Havant",
              "postcode": "PO9",
              "_id": {
                "$oid": "59ef10d194e4d86bf9be1b6d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Curve Fit Ladies-only Gym",
          "phone": {
            "code": "+44",
            "number": "2392455343"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Cafe",
            "20 + Fitness Classes Per Week"
          ],
          "strapLine1": "Inside Moods Fitness Studio"
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "curve_fit_ladies-only_gym",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58906d5185706309618b3fc2"
      },
      "lastUpdated": {
        "$date": "2017-12-14T17:07:42.187Z"
      },
      "createdAt": 1485860177284,
      "email": "progressionfitness@shoomon.xyz",
      "firstName": "Progression",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "cCwIqw7+rveyxy6IfZOiwg==",
      "hashedPassword": "275QdeN5ee3l/lHHNjs9Am5bQVnqazw2XVFVWj4+jJLY744wfoeHKTIriEoZimXXYZy8krLIIJIraVk8pyK/bw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Sn9b2ilH-progressionpro.jpg"
        },
        "personal": {
          "disciplines": [
            "Academy",
            "Boot Camp",
            "Fitness Classes",
            "Personal Training",
            "Personalised Programmes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.progressionfitness.co.uk/andover-gym.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 0,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Y3zuaHlA-progressionbg3.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Small, friendly gyms in Andover and Newbury that train and support you in achieving your goals",
          "biography": "​We are a small, friendly, low-cost gym situated in Walworth Industrial Park in Andover, which offers a range of services, equipment and support to help you achieve your fitness and wellbeing goals.\n\n​- Personal training: Some of us need more motivation than others and personal training (one-to-one session with a qualified instructor) may be the answer for you.\n\n- Cardiovascular and resistance equipment:\n\n- Free weights: which caters for all fitness levels\n\n- Core stability and vibration training equipment\n\n- A range of studio classes for all ages: Let's face it, the gym isn't for everyone. If you don't like the idea of working out alone, why not join one of our classes and make friends too.\n\n- Individual or group weight-loss and fitness programmes: Weight loss is one of the main reasons for people joining a gym but it is a combination of nutrition, mindset and exercise that's required.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMon - Friday: 07:00AM to 21:00PM \nSat: 09:00 -16:00\nSun: 09.00 - 12.00",
          "locations": [
            {
              "geolocation": [
                -1.4594207999999753,
                51.21885270000001
              ],
              "city": "Andover",
              "postcode": "SP10 5AZ",
              "_id": {
                "$oid": "5a32af77a95f7a0d7e4bdaf0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Progression Fitness Studio",
          "phone": {
            "number": "1264392239",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Core Stability Equipment",
            "Vibration Training Equipment"
          ],
          "testimonials": "''Since sitting down with Lucy and going through my diet and training consistently at Progression. I have lost 3 stone in 4 months, improved my fitness no end and feel great!''\n\n\"I have been going to Progression Fitness gym for a year now and I can vouch for the friendly atmosphere and knowledgeable staff.  I have increased core/torso strength as a result of going to the gym regularly.\" Adam \n\n\"Jon is an extremely proficient and caring instructor...If you want to join a small friendly gym, you won't be disappointed with Progression!\" Jan\n\n\"I have been a member of Progression Fitness since February 2009, I started going once a week and now go four times a week! Progression is exactly what this gym is about!\" Christina\n\n\"Progression Fitness is such a friendly gym. People smile and say hello/goodbye. Jon and his staff are superb and always ready to help. Going to the gym here is a pleasure not a chore PLUS I've lost lots of weight and got fit!\" Kim \n\n\"I've had PT with Jon for a number of years, both at Newbury and Andover. Without PT, I don't think I'd have had the motivation/will power to go. Don't get me wrong, I can't say I enjoy going to the gym but I always feel better for having been.  And it's so nice to go somewhere where people are normal and friendly, and not necessarily body beautiful.\" Sue"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "progression_fitness_studio",
      "feature_in_area": "none",
      "__v": 10,
      "approved_date": {
        "$date": "2017-12-14T17:07:42.158Z"
      }
    },
    {
      "_id": {
        "$oid": "589073d685706309618b4104"
      },
      "lastUpdated": {
        "$date": "2017-10-24T09:27:51.752Z"
      },
      "createdAt": 1485861846142,
      "email": "youfit@shoomon.xyz",
      "firstName": "You Fit Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "YhEj35f7nasJsfF3X5TkZQ==",
      "hashedPassword": "L4J1KWYQkD3JwW4JIcvUnIbFedz5Ttii6uPZd95XHnjaRDuTp4M8QluBdeA6k2jJwVUWwTI4VrbeutK+a9fqPg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YdktQrU2-youfitlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Physiotherapy",
            "Personal Training",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.progressionfitness.co.uk/newbury-gym.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8XuUhrap-youfitbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "You Fit In: Friendly. Personal. Motivational.",
          "biography": "​Welcome to You Fit Gym, a friendly and sociable environment for people of all ages, shapes and sizes to train safely and confidently with the support of our approachable and knowledgeable staff, helping you to achieve your fitness and nutrition goals.\n\nYour journey to fitness starts here! And remember; you fit in at You Fit Gym!",
          "offers": "Please visit our website for details of proces, packages and offers.\n\nOpening Hours:\n\nMONDAY 7:00 am - 9:00 pm\nTUESDAY 7:00 am - 9:00 pm\nWEDNESDAY 7:00 am - 9:00 pm\nTHURSDAY 7:00 am - 9:00 pm\nFRIDAY 7:00 am - 9:00 pm\nSATURDAY 8:30 am - 1:00 pm\nSUNDAY 8:30 am - 1:00 pm",
          "locations": [
            {
              "geolocation": [
                -1.3335506999999325,
                51.3811552
              ],
              "city": "West Berkshire",
              "postcode": "RG14 7RW",
              "_id": {
                "$oid": "59ef079794e4d86bf9be1b65"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "You Fit Gym",
          "phone": {
            "code": "+44",
            "number": "1635581900"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment"
          ],
          "testimonials": "“Nick is cheerful, upbeat… very encouraging… with a very positive attitude and demonstrates a real love of training and fitness” Helen\n\n“Claire makes me feel I am capable of anything… Claire has the ability to push you further and harder by empowering you.” Victoria\n\n“Matt is a good motivator… very motivated and knowledgeable” Lisa\n\n“Nick is friendly and professional… easy to understand and helpful” Anne\n\n“Matt has a positive approach… always has time to listen… is fun, focussed and motivational.” Moira\n\n“Nick is relaxed, encouraging and informative… always ready to help… a very likeable person.” Laurie\n\n“Sarah is friendly, fun and caring; informal but informative… she has a sense of fun and makes me feel that we are ‘in it together’… I’m achieving results.” Sandie\n\n“Claire is… friendly, enthusiastic and professional… continually learning new training techniques that make the workout fun and exciting.” John\n\n“Matt is friendly and approachable… always listens… I can always count on his advice if I’m not confident about something.” Maria"
        },
        "score": 69
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "you_fit_gym",
      "feature_in_area": "none",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "58907a4885706309618b4286"
      },
      "lastUpdated": {
        "$date": "2017-10-24T09:08:16.385Z"
      },
      "createdAt": 1485863496651,
      "email": "bstnewbury@shoomon.xyz",
      "firstName": "BST Fitness",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "31hZ3prt5BkrhWMXHw/2Yw==",
      "hashedPassword": "I2bmtajSbxUBFWvxTyWroy5S3HWHWBofM+0FvD62e/ZJZvKQv1f54YRgf1Mhh6xkNXp+HAYGY7zVDU6ivy2mMQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xUlWsTOn-bstlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Kids Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.mybst.org/newbury",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nM78FcRR-bstbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Do it for yourself",
          "biography": "Welcome to bstfitness, a brand new sports complex in the heart of Newbury, just a stone's throw away from Parkway shopping centre. \n\nbstfitness is home to a huge 100+ station gym with the latest Pulse® kit, allowing you to maximize your workout sessions in fun and innovative ways. \n\nStream your favorite TV programmes or games during your run on our state-of-the-art treadmills, excite your cycle ride on our virtual-reality Trixter® X-Dream bikes or tone your body in our supreme-comfort Evolve® resistance equipment.\n\nBut that's not it. Membership to the centre also includes access to our functional fitness area - a room dedicated to TRX® fitness, plate loading machines and functional equipment such as Viprs, Kettlebells and medicine balls.\n\nMembers also have access to a variety of studio classes throughout the week including circuits, core, spinning and so much more, all included within the membership.\n\nYou can also have a swim in our pool as part of your workout or simply have a relaxing dip - it's up to you. Lanes are available for all abilities - and yes, this is part of the membership package too. \n\nIt's all brand-new and state-of-the-art, and it's cheaper than you think too.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nClub Opening Hours:\n\nMon - Fri: 06.30 - 22.00\nSat & Sun: 08.00 - 19.00\n\nPool Opening Hours:\n\nMon - Fri: 06.30 - 08.55, 12.00 - 15.55 & 18.00 - 21.30\nSat & Sun: 08.00 - 08.55 & 12.00 - 18.30\n\nSauna Opening Hours:\n\nMon - Fri: 06.30 - 08.55, 12.00 - 15.55 & 18.00 - 21.30\n\nSat & Sun: 08.00 - 08.55 &  12.00 - 18.30",
          "locations": [
            {
              "geolocation": [
                -1.3221002999999882,
                51.4053583
              ],
              "city": "Newbury",
              "postcode": "RG14 1EA",
              "_id": {
                "$oid": "59ef030094e4d86bf9be1b56"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "BstFitness",
          "phone": {
            "code": "+44",
            "number": "1635701100"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Swimming Pool",
            "Skin Care Clinic",
            "Health & Wellbeing",
            "Sauna"
          ]
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bstfitness",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5890869485706309618b450a"
      },
      "lastUpdated": {
        "$date": "2017-10-24T08:41:49.451Z"
      },
      "createdAt": 1485866644137,
      "email": "corewoodley@shoomon.xyz",
      "firstName": "Core",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "eSOFo6PNeL29U8KhX5A5Tg==",
      "hashedPassword": "q3HCAOfkWnZ8nxs7w/Nj9bRkOkR/ffoydfKRav7bxr9XGwtECVdCpJ7TDzNvJdd3TOTok4ONPrMX/7NsKPhkAg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/V70vfP1O-corelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Cardiovascular",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://corefitnessandweights.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gGZ4Vsm0-corebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "ALL MEMBERS HAVE 24hr ACCESS",
          "biography": "Core Fitness is a gym users gym. We do not offer a wide variety of classes like some gyms do, what we do offer is a suitable location with plenty of parking, free weights, kettle bells, cardio equipment, quality fitness instruction and personal training.\n\nWe also have an environment in which you can train in a relatively private manner, as we are not a converted sports hall or an open air gallery.\n\nPlease pay us a visit and familiarise your self with our layout, equipment and atmosphere. You are welcome any time.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nALL MEMBERS HAVE 24hr ACCESS.\n\nMANNED HOURS:\n\n16:00 – 20:00 MONDAY & WEDNESDAY\n15:00 – 18:00 FRIDAY \nWE ARE UNMANNED AT WEEKENDS AND ON BANK HOLIDAYS",
          "locations": [
            {
              "geolocation": [
                -0.8883825999999999,
                51.4551005
              ],
              "city": "Woodley",
              "postcode": "RG5 4UL",
              "_id": {
                "$oid": "59eefccd94e4d86bf9be1b4f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Core Fitness & Weights",
          "phone": {
            "code": "+44",
            "number": "1184492094"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Kettlebells",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "core_fitness_and_weights",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58908c3185706309618b464c"
      },
      "lastUpdated": {
        "$date": "2017-10-24T08:18:25.700Z"
      },
      "createdAt": 1485868081295,
      "email": "3dfitness@shoomon.xyz",
      "firstName": "3d Health &",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ObHolNB1+HRJ6BgqcYb78A==",
      "hashedPassword": "3+BlfbrBAdZD9a8GuV7FEfGNfd+ZAwijjI9CpkDE3/gLwbNPfMfPV2zMaFSv1gmobYny40un4Xw9BjRNjYUthw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aKxchYSp-3dchandlerslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Beauty Treatments",
            "Fitness Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://3dhealthandfitness.co.uk/chandlers-ford/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gcJinLkX-3dchandlersbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Dare To Be different",
          "biography": "Hit The Gym:\nFeel an adrenaline rush and the buzz of a great workout in our light, bright, well equipped gym. Get your heart pumping in the CV area, tone up and build muscle with free and fixed weights then stretch out and enjoy a deep tissue massage on the Power Plates.\n\nTake A Class:\n\nFrom Body Conditioning to Pilates, Spin to Zumba there’s something to suit everyone in our exercise programme featuring over 50 classes weekly.  Need a challenge? Try one of our HIIT classes.\n\nFind A Trainer:\nTo keep focused and achieving great results a Personal Trainer is your secret weapon.  Each of our Gym Team offer their own unique style to ensure you get the utmost benefit from your exercise routine.  Free initial consultation.\n\nGet Pampered:\n\nRelax and unwind in our health suite, a sauna or steam treatment with eucalyptus essence will leave you feeling refreshed and invigorated. With proven health benefits, time spent in this area won’t be time wasted!\n\nAll new members will be offered a free full induction and personal fitness programme with one of our great instructors to help get you started.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 6:30am - 10pm\nFri: 6:30am - 8:30pm\nSat: 8am - 4pm\nSun: 9am - 4pm",
          "locations": [
            {
              "geolocation": [
                -1.3925378999999793,
                50.9802138
              ],
              "city": "Chandler's Ford",
              "postcode": "SO53 4BW",
              "_id": {
                "$oid": "59eef75194e4d86bf9be1b4c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "3d Health & Fitness",
          "phone": {
            "code": "+44",
            "number": "2380253536"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Powerplate",
            "Fitness Studio",
            "Steam Room",
            "Sauna"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "3d_health_and_fitness",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5890940385706309618b480e"
      },
      "lastUpdated": {
        "$date": "2017-10-24T08:03:20.953Z"
      },
      "createdAt": 1485870083844,
      "email": "studiored@shoomon.xyz",
      "firstName": "Studio Red",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "gcTtcSWUVHb//B2Bw5o+Gw==",
      "hashedPassword": "5I5ESUQ0e4xPUFVttR9ctB3A55QSnjrkXu35YlYA+R/fNhCzw6VFaMvIgpkKAwFslcvWyaAaPaaRAy3zjOCJiw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/f3CSZKfh-studioredpro.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Boxing",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.studioredfitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ikYB4TC7-studioredbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Studio Red Fitness: a fully equipped, pay as you go gym and health club in Southampton, dedicated to helping you achieve the health and fitness results that you're looking for. \n\nWith Studio Red, you benefit from an exceptional personal service with no joining fee and no contract:\n\nFully Equipped Gym:\nIncluding cardiovascular gym equipment, fixed resistance, strength, free weights, aerobic gym and purpose built spin studio.\n\nPowerplates: \nUsing our powerplate provides you with an enhanced full body workout, stretches and a massage.\n\nGroup Classes and Spin:\nFitness classes run in the mirrored aerobic gym and spin studio. Our specially developed spin techniques ensure we provide the most unique spin classes in Southampton, which target and train most upper body muscles whilst on the spin bike.\n\nPersonal Training Services:\nAs a private gym, Studio Red offers you the most tailored personal training services in Southampton as we will seek to develop a personalized plan around your goals and individual circumstances.\n\nWeight Loss Group:\nOur unique, fun, friendly and supportive weight loss group is designed to get you results in just 6 weeks. Get results!\n\nSauna:\nRelax your muscles and unwind your mind in our sauna – perfect after a workout.\n\nTherapy and treatment rooms:\nStudio Red provides beauty therapy treatments and massages - treat yourself!\n\nFree parking:\nThe gym's free car park is at the rear. There is another large car park nearby that has 5 hours free parking.",
          "strapLine1": "The difference between the impossible and the possible lies in a person's determination",
          "offers": "Please visit our website for details of our prices,  packages and offers.\n\nOpening Hours:\nMon - Thurs: 07:00 - 22:00\nFri: 07:00 - 20:00\nSat: 09:00 - 18:00\nSun: 10:00 - 18:00",
          "locations": [
            {
              "geolocation": [
                -1.4345505000000003,
                50.9223247
              ],
              "city": "Southampton",
              "postcode": "SO16 4RJ",
              "_id": {
                "$oid": "59eef3c894e4d86bf9be1b49"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Studio Red Fitness - Shirley",
          "phone": {
            "code": "+44",
            "number": "2380770730"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Cycle Studio",
            "Weigh Loss Group",
            "Sauna",
            "Beauty Therapy",
            "Massage"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "studio_red_fitness_-_shirley",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58909a6085706309618b4950"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:51:47.168Z"
      },
      "createdAt": 1485871712925,
      "email": "crossfitwycombe@shoomon.xyx",
      "firstName": "Crossfit",
      "surname": "Wycombe Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "p4F3P3Yya3bEUhIqXxs2cA==",
      "hashedPassword": "EcbfUHAsPz3XATQOdVxoXO0qLbuhkXsW3J0NWUh9/+V9VG5TQAc/3J0eomgA0awTvPA6npvNEDzYp71TK8m61g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8C4gzFZ9-crossfitwycombelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Boot Camp",
            "Fitness Classes",
            "Kids Classes",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://crossfitwycombe.com/#home",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fs3FHUNL-crossfitwycombebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Making People Better",
          "biography": "Fun, challenging, engaging, these short and effective sessions add up to the stronger, leaner, faster athlete that is in every one of us.\n\nWHAT IS CROSSFIT?\n\nFrom grandparent to grandchild, from beginner to competitive athlete, CrossFit is THE fitness program for reaching and surpassing your goals.\n\nBARBELL CLUB / BOOTCAMP:\n\nIt’s not just about CrossFit. There are many specialty fitness classes that you can attend. Check out our website for the schedule\n\nCROSSFIT KIDS:\n\nOur CrossFit Kids program is aimed at children aged 3-17. Our children need to push, pull, run, throw, climb, lift, and jump effectively and safely regardless of whether or not they play sport.\n\nCROSSFIT IS A SPORT:\n\nThe world’s fastest growing competitive sport, CrossFit athletes have to be seen to be believed. We believe that competition can drive us towards excellence. Be part of our team!",
          "offers": "Please visit our website for details of our prices, packages and prices.",
          "locations": [
            {
              "geolocation": [
                -0.7070940999999493,
                51.5731316
              ],
              "city": "Wooburn Green",
              "postcode": "SL8 5DT",
              "_id": {
                "$oid": "5a1421632288dd3a30f3f47a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Crossfit Wycombe",
          "phone": {
            "number": "7936675725",
            "code": "+44"
          },
          "qualifications": []
        },
        "score": 57
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "crossfit_wycombe",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58909e9d85706309618b4a52"
      },
      "lastUpdated": {
        "$date": "2017-10-24T07:34:31.926Z"
      },
      "createdAt": 1485872797656,
      "email": "absolutelyslough@shoomon.xyz",
      "firstName": "Absolutely",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "LN4dGwQro9B11w7zu+VWtQ==",
      "hashedPassword": "aUGI5C1UQBMFOw/GUPl74XmvGCLIPiWGKJQygNQqkZqdrRhR3mgooN0EKVDF8uYvbs/9zvVmmM7QYauVUd2ggA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qvaDZZDU-absolutelysloughlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.absolutely-fitness.co.uk/gym/slough-trading-estate-gym.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EJgO7fju-absolutelysloughbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Our Absolutely Fitness gym in Slough is located on Liverpool Road in the Trading Estate and has free parking.\n\nNot super enough to fly over traffic jams...don't worry, as we are located outside of the town centre, so whether you are an early riser or prefer your workouts after work you can get here as quick as a flash!\n\nWe've got everything to make you super again...\n\nCardiovascular Machines - Treadmills, Bikes, Rowers, Cross trainers, Steppers, Spin bikes and IFI accredited machines (inclusive for disabled users).\nStrength Equipment - Fixed resistance machines for all body parts (ideal for beginners), Cable machines for all body parts, Plate loaded free weight stations, Dumbbells, Barbells, Other, Stretching areas and Vibration training.\n\nFunctional Training - Power bags, Climbing ropes, FTR Rigging, Peg climbing boards, Olympic bars with competition, bumper plate, Kettle bells, Battling ropes, Rowing machines, Punch bags, Glute /Hamstring raisers, Dipping frames, Slam Balls and Medicine balls.\n\nLadies only gym for wonder women...\n\nYou will find the following equipment in this area Treadmills, Cross trainers, Exercise bikes, Rowing machines, Resistance, weights, Stretching area and a Power plate. \n\nAll perfect for feeling super again!",
          "strapLine1": "Including our Ladies-only Gym",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 6.00am - 10.00pm\nFri: 6.00am - 9.00pm\nSat & Sun: 8.30am - 6.00pm\nBank Holidays: 10.00am - 6.00pm",
          "locations": [
            {
              "geolocation": [
                -0.6239883000000646,
                51.5206268
              ],
              "city": "Slough",
              "postcode": "SL1 4QZ",
              "_id": {
                "$oid": "59eeed0794e4d86bf9be1b45"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Absolutely Fitness Gym",
          "phone": {
            "code": "+44",
            "number": "1753530062"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Free Weights Area",
            "Lifting Platforms",
            "Functional Training Zone",
            "150+ Pieces Of Equipment",
            "Climbing Ropes",
            "Peg Wall",
            "Free Parking",
            "Changing Facilities",
            "Lockers"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "absolutely_fitness_gym_rkHvhw2ab",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5890a36585706309618b4b54"
      },
      "lastUpdated": {
        "$date": "2017-10-24T07:26:02.558Z"
      },
      "createdAt": 1485874021028,
      "email": "absolutelybracknell@shoomon.xyz",
      "firstName": "Absolutely",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "/SZPEFF8GWh0EU7a6i5GiA==",
      "hashedPassword": "aQUP/4hZ6MNXybu4dgDvtK1P+1kgA4SyV0NNKDO6vmO3wbVzY21QIQL7sDB6Dc6owIgiLcCnqxkQN5IRH+p/Kw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1fFMDYxN-absolutelysloughlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.absolutely-fitness.co.uk/gym/bracknell-gym.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZhXsOgWo-absolutelysloughbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Including our Ladies-only Gym",
          "biography": "Small group training and fitness classes are available with every gym membership. From specialist classes like Spinning and Pilates, to gym floor classes like TRX and Spare Tyre workout. Use our online booking portal to save yourself some time.\nAbsolutely Fitness in Bracknell has a ladies only gym which offers all the main features of the main gym but with the added seclusion and privacy within which to enjoy a workout. Conveniently located next to the changing and shower room, this is the perfect area to de-stress from the daily grind and start feeling super again ready to take on the world!\nYou find the following equipment in this area: Treadmills, cross trainers, exercise bikes, rowing machines, resistance weights, stretching area and power plate (located just outside of this area).\nAt Absolutely Fitness we hand pick our Personal Trainers on their expert fitness knowledge and their personality. Because of this, our friendly, fun, and truly brilliant Personal Trainers are the crème de la crème of the fitness industry!",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Times:\n\nMon - Thurs: 6.00am - 10.00pm\nFri: 6:00am - 9:00pm\nSat & Sun: 8.30am - 6.00pm\nBank Holidays: 10.00am - 6.00pm",
          "locations": [
            {
              "geolocation": [
                -0.7626172000000224,
                51.4152381
              ],
              "city": "Bracknell",
              "postcode": "RG12 1NS",
              "_id": {
                "$oid": "59eeeb0a94e4d86bf9be1b40"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Absolutely Fitness Gym",
          "phone": {
            "code": "+44",
            "number": "1344305687"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Free Weights Area",
            "Boxing Zone",
            "Functional Training Zone",
            "150+ Pieces Of Equipment",
            "Powerplate",
            "Lifting Platforms",
            "Free Parking",
            "Changing Facilities"
          ],
          "testimonials": ""
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "absolutely_fitness_gym",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5890a83085706309618b4c56"
      },
      "lastUpdated": {
        "$date": "2017-10-24T07:21:13.667Z"
      },
      "createdAt": 1485875248786,
      "email": "absolutelylangley@shoomon.xyz",
      "firstName": "Absolutely",
      "surname": "Fitness",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "9OZ1Plwh5RQA1dxQg2+q4A==",
      "hashedPassword": "5mNfuiKS1aoMth/2cDSdYA5wnwVA+Cc7aADIh8JCy4fNfipHXZuC1Bnz6FKWG3boE0oCoQqyUGU3HN3yzreJpw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/puG89QES-absolutelysloughlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.absolutely-fitness.co.uk/gym/east-berkshire-college-gym.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/r5mwJBwX-sloughbg2.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "One Of The Few UK Charity Run Gyms",
          "biography": "Our Absolutely Fitness gym in East Berkshire College will be in the perfect location whether you live or just work in the area. Not super enough to fly over traffic jams? Don't worry as we will be located just outside of the town centre so you can get here as quick as a flash!\nWe will have everything to make you super again...\n20 stationed gym area\nGreat range of weekly group fitness classes\nAdditional sports facilities\nSuper Sports Facilities\nAt Absolutely Fitness Langley we have a wide range of activities available including Football, Basketball, Netball and Badminton for teams to enjoy. Whether it's for a competition, friendly or a team building event, Absolutely Fitness Langley's sports center will have just the space for you!",
          "offers": "Please visit our website for prices, packages and offers.\n\nOpening Times:\n\nMon - Fri: 6.00am - 8.00am & 5:30pm – 11:00pm\nSat: 8:30am - 6:00pm\nSun: 8:30am - 6:00pm",
          "locations": [
            {
              "geolocation": [
                -0.5461780000000545,
                51.5035925
              ],
              "city": "Slough",
              "postcode": "SL3 8BS",
              "_id": {
                "$oid": "59eee9e994e4d86bf9be1b3e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Absolutely Fitness Gym - Langley",
          "phone": {
            "code": "+44",
            "number": "1753545649"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Free Weights Area",
            "150+ Pieces Of Equipment",
            "Powerplate",
            "Climbing Ropes",
            "Functional Training Zone",
            "Free Parking",
            "Changing Facilities",
            "Lockers",
            "Ladies Only Area",
            "Peg Wall",
            "Lifting platforms",
            "Disabled Access"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "absolutely_fitness_gym_-_langley",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5890ac7885706309618b4d58"
      },
      "lastUpdated": {
        "$date": "2017-10-23T22:05:47.974Z"
      },
      "createdAt": 1485876344835,
      "email": "monkeymuaythai@shoomon.xyz",
      "firstName": "Monkey Muay",
      "surname": "Thai Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "eszA6nG035l3mFa67X74qQ==",
      "hashedPassword": "XSh7SKCVhObb+ouU56ipGOpturuz26ByADd/IzldcKl4Y/Rg9cngZPhaFWUU+XkfbHGsHxElDp8d+zaYfcNzNw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/q9XEnJKP-monkeylogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Coaching",
            "Kids Classes",
            "Martial Arts"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.monkeymuaythai.co.uk/",
          "profileTitle": "Sports & Activity Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Phr3AmC2-monkeybg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Muay Thai & Boxing classes and 1-2-1 training in Windsor",
          "biography": "Welcome to the website of Monkey Muay Thai!  We are a striking based martial arts club, based within Five Star Health & Fitness, Windsor, Berkshire.\n\nMonkey Muay Thai provides Muay Thai Boxing and Boxing classes.  We also provide martial arts based fitness and circuit classes in addition to kids classes.\n\nWith our experienced, friendly and helpful team of coaches and club, we welcome all.\n\nWhether you are a complete novice, professional fighter or anything in-between, we aim to help you reach your goals.  This could be anything from simply wanting to get fitter, learn some new skills, gain confidence in self-defence or looking to compete at interclubs or beyond.\n\nWe hope to see you at our club soon!\n\nThe Monkey Muay Thai Team",
          "offers": "Please visit our website for details of prices, packages and offers",
          "locations": [
            {
              "geolocation": [
                -0.634907300000009,
                51.48129729999999
              ],
              "city": "Windsor",
              "postcode": "SL4 5LA",
              "_id": {
                "$oid": "59ee67bb94e4d86bf9be1b34"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Monkey Muay Thai Martial Arts Club",
          "phone": {
            "code": "+44",
            "number": "7471656110"
          },
          "qualifications": [
            "Punch Bags",
            "Kick Shields",
            "Thai & Focus Pads",
            "Floor to Wall Matting",
            "Speed Ball",
            "Fitness Classes"
          ],
          "testimonials": "‘As a beginner to combat sports I found Carl’s approach great and able to cater to all skill levels. Carl likes to have a laugh and keeps the sessions tough but light hearted. Lots of pad and technique work, the session flies by. I couldn’t speak higher of Monkey Muay Thai and recommend to all’ - James \n\n‘Throwing elbows, knees and kicks at someone in a safe environment is brilliant!  Carl really gets you working hard and his teaching style makes you want to keep coming back’ - James\n\n‘I attended a Muay Thai session with Carl earlier this year. I thoroughly enjoyed it. Carl clearly knows his stuff and is not only supportive and a good teacher, but also makes it fun and amusing which is important for people who may feel slightly apprehensive about doing Muay Thai for the first time. It’s a great way to build up fitness and learn a new skill. Thanks Carl!’ - Sam\n\n‘Just wanted to say i’ve had a brilliant 1st week at monkey. Both james and scott have been patient with me and tried really hard to get me involved with everything, its great I cant speak highly enough of them but best of all is the atmosphere at the club.  Every person i’ve come accross is helpful and welcoming. Clearly this is part of your work ethic and what you bring to monkey so thanks very much and i’m really happy i signed up straight away.‘ Daniel"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "monkey_muay_thai_martial_arts_club",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5890b15385706309618b4e5a"
      },
      "lastUpdated": {
        "$date": "2017-10-23T21:47:36.927Z"
      },
      "createdAt": 1485877587126,
      "email": "fivestarwindsor@shoomon.xyz",
      "firstName": "Five Star",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "BGPXdJR9upLDFZSv4NNXlA==",
      "hashedPassword": "/7sM6Lv6HeSdF5AqnaAQ5bcZazWGvVoi0hT/5NZ8rT9RCRQYijCMTU6bKXbJLukwgY2WP+DmedLqoc604dX8JQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DbTEfcb0-fivestarlogo.png"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Fitness Classes",
            "Martial Arts",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://fivestarhealthwindsor.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fqNPNPxE-fivestarbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to the Five Star Health and Fitness Gym Windsor, the No.1 gym, fitness and lifestyle center in Berkshire. Founded by former Olympic finalist and World No.2 400m athlete Tim Benjamin and Alex Wheater.\n\nFive Star Health and Fitness is the ultimate fitness and lifestyle center aimed at getting you the results you deserve.\n\nAt Five Star you’ll find a club to suit all of your needs with a price tag to match. combining the latest equipment and a truly motivational environment, getting you the results you deserve is our absolute focus.\n\nAt Five Star we pride ourselves on our 5 unique zones of action:\n\nMind and Body zone\nStrength zone\nCardio zone\nFunctional zone\n\nOur team approach, and individual service, enables us to offer services that our competition can’t at membership rates that make Five Star a place to train for everyone in the community.\n\nAmong others, our members include general gym goers, professional tri-athletes, each with their own goals supported by our team of instructors.\n\nWorking with one of our top Personal Training Coaches is without doubt the fastest, safest and smartest way to get the results you deserve.\n\nAll of our Personal Training Coaches are Register of Exercise Professionals Level 3 qualified, ensuring you’re in the safest and best hands in the industry.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 06.00 - 22.00\nFri: 06.00 - 21.00 \nSat: 08.00 - 17.00 \nSun: 08.00 - 17.00",
          "strapLine1": "The No.1 Gym, Fitness And Lifestyle Center in Berkshire",
          "locations": [
            {
              "geolocation": [
                -0.634907300000009,
                51.48129729999999
              ],
              "city": "Windsor",
              "postcode": "SL4 5LA",
              "_id": {
                "$oid": "59ee637894e4d86bf9be1b2c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Five Star Health & Fitness",
          "phone": {
            "code": "+44",
            "number": "1753868646"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "five_star_health_and_fitness",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "5890b8ab85706309618b4fdc"
      },
      "lastUpdated": {
        "$date": "2017-10-23T21:36:06.402Z"
      },
      "createdAt": 1485879467393,
      "email": "lakeside@shoomon.xyz",
      "firstName": "Lakeside",
      "surname": "H & F Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "9N3TEzmUYzIgq8BbgBBL/Q==",
      "hashedPassword": "ZUP4O0QAQUy1DM2uO8/MZGofQnh6UXQVYWHuffo/ch431NwC6PkIzghhwwWbScrc2lkyvmBmQiMbXO63JqGA/w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pWyZdvot-lakepro.jpg"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.lakesidehealthandfitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1oSHj2yL-lakebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Look out over the stunning lake and grounds - the lake is alive with activity every day!",
          "biography": "Lakeside Health and Fitness is situated at Liquid Leisure in Datchet - it opened February 2011. This is a unique members-only training facility. \n\nWhether you want to loose a few pounds after Christmas or progress onto the next level of your sport we can advise you using the latest techniques. \n\nWith years of experience working with professional sportsman along with competing in many sports ourselves gives us a great advantage when designing training programs on a daily basis for you. \n\nSpecialising in preparing a bespoke training environment for our clients and members we are here to help to reach your goals regardless of of small or how ambitious.\n\nThis facility has a beautiful lake view in a vibrant environment. It is fully equipped with the latest fitness equipment including Precore cardiovascular trainers and nautilus selectorized, free weight and cable strength equipment, plus balls, bands and core trainers to compliment any training program.\n\nThe Lakeside gym is customer focused, the gym has a small membership base, which gives each member the opportunity to receive the best possible attention: “We want you to achieve your goals because they are the same as ours”\n\nWhen joining the club you will receive the following:\n\nPersonalized program design\nProgram re-assessment every month\nExtra motivation (when needed) in the gym.\nUnlimited use of the gym facilities\nGym changing facilities\nUse of liquid Leisure club facilities\nFREE Liquid Leisure Social membership",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMon - Thurs: 6.30am - 10.00pm\nFri: 6.30am - 8.00pm\nSat & Sun: 9.00am - 3.00pm\n\nBank Holidays 09.00 – 2.00pm",
          "locations": [
            {
              "geolocation": [
                -0.5608115999999654,
                51.4753938
              ],
              "city": "Datchet",
              "postcode": "SL3 9HY",
              "_id": {
                "$oid": "59ee60c694e4d86bf9be1b28"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Lakeside Health & Fitness",
          "phone": {
            "code": "+44",
            "number": "7718403859"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Changing Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "lakeside_health_and_fitness",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5890c62885706309618b52de"
      },
      "lastUpdated": {
        "$date": "2017-10-23T21:26:47.220Z"
      },
      "createdAt": 1485882920539,
      "email": "bulldoghighwycombe@shoomon.xyz",
      "firstName": "Bulldog",
      "surname": "Power Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "HW+etHSY+sTUVOXI2o76Zw==",
      "hashedPassword": "7/O4FrbEQqTFFRqeHIjpmRg1dwOQiKbJh2/5DSZo7BMhESFlqk0TJz6k1ANvtdOKA1rZaEREIjXZGrUcwAq7Vw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SKV81Wcu-bulldogpro.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Power Lifting",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.bulldogpowerandstrengthgym.epageuk.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DGuoi6cC-bulldogbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Owned by the one and only Dave \"Bulldog\" Beattie, holder of multiple world records in power lifting and owner of Genesis.\n\nBulldogs Gym has a huge variety of Bodybuilding equipment as well as a massive selection of strongman equipment to go with our competition benches, monolift, platforms and Ivanko plates.\n\nWe also offer personal training from Luke Wilson, 7 x Powerlifting British record holder and former London Wasps apprentice who is a specialist in Strength and Conditioning with the ISSA.",
          "strapLine1": "Specialists in Powerlifting and  Strength",
          "offers": "Please contact us for details on prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.7637108000000126,
                51.634535
              ],
              "city": "High Wycombe",
              "postcode": "HP11 2LR",
              "_id": {
                "$oid": "59ee5e9794e4d86bf9be1b25"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Bulldog Power & Strength Gym",
          "phone": {
            "code": "+44",
            "number": "1494700327"
          },
          "qualifications": [
            "Free Weights Selection",
            "Resistance Equipment",
            "Benches",
            "Platforms",
            "Monolift",
            "Ivanko Plates",
            "Strongman Equipment"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bulldog_power_and_strength_gym",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5890c99385706309618b5360"
      },
      "lastUpdated": {
        "$date": "2017-10-23T21:18:37.582Z"
      },
      "createdAt": 1485883795354,
      "email": "theclubhighwycombe@shoomon.xyz",
      "firstName": "The Club",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "oNzSsxO26pzxDPyVy/4NWg==",
      "hashedPassword": "7ltKeEqCDlr45NcVMkchzAE3klXuTiTefP4Nyc2EgXEA4S46wOYCTVwToU0RqWMDaDC/fq3cbE//MhQnz1OSOw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/n4EwWJ00-theclublogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Bodybuilding",
            "Kids Classes",
            "Martial Arts",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.theclub.tv/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gZSlqxkw-theclubbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "The Club / Titans Gym has always been a FAMILY fitness centre with a wide variety of activities for all! Whatever your age, shape, or current physical condition we can help you to get more juice out of life. Click the links below to find out more about our wide range of Fitness Activities and Martial Arts Classes.\n\nWhether you want to GET FIT, LOSE WEIGHT or just FEEL GREAT our expert instructors can help you to reach your health and fitness goals. From functional fitness to weight training we can help you rebuild your body with expert training and plenty of motivation and encouragement!\n \nLearning a Martial Arts has tremendous value for young people. It increases confidence, concentration and levels of fitness. With our award winning Tiny Titans programme for 3-5 year olds and our Titans Junior programme for 6-14 year olds, we have classes for all.\n \nJoin Master Instructor John Gilbert in our wide range of Martial Arts Classes. Learn the skills of Kickboxing and Brazilian Jiu Jitsu for fitness, sport and self defence.\n\nSo, whether you want to…..\n\nLose Some Weight And Tone Up\nFeel Fitter And Have More Energy\nImprove Your Strength And Coordination\nImprove Your Nutrition\nTake Exciting Fitness Classes\nStudy Martial Arts For Self-Defence And Confidence\n…….the Club can help you succeed! Contact us now",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 7.00am -10.00pm\nSat - Sun: 10.00am - 6.00pm",
          "strapLine1": "Martial Arts for Children and Adults",
          "locations": [
            {
              "geolocation": [
                -0.7719646000000466,
                51.63495429999999
              ],
              "city": "High Wycombe",
              "postcode": "HP12 3BG",
              "_id": {
                "$oid": "59ee5cad94e4d86bf9be1b22"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Club",
          "phone": {
            "code": "+44",
            "number": "1494629529"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_club",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5890cf0185706309618b54a2"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:50:34.431Z"
      },
      "createdAt": 1485885185933,
      "email": "gymfit4lesshighwycombe@shoomon.xyz",
      "firstName": "Gymfit4less",
      "surname": "High Wycombe Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "bbH1MhgWHEXlIT4OAEPZEg==",
      "hashedPassword": "4DMjpucRMXpjkwS90Gc3RBfU1Id7OdmBP4kUmhs73MabP9EtSKg/ZQHXEPyOyQksgH/eJAnbM2jeaL/leF2mGw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mUBa8yvk-gymfitlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.gymfit4less.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TAfAO17L-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Join the Gym Fit revolution",
          "biography": "Gym Fit 4 Less is a community gym for every member for all ages, shapes, sizes and fitness levels in-between.\n\nWe have amazing facilities, classes, nutritionist and personal trainers on site.  Our aim is to help you achieve your personal fitness goals, whether that’s toning up, losing weight or training for a marathon.\n\nWe are always here to offer support, advice and encouragement when you need it.\n\nSTATE OF THE ART EQUIPMENT:\n100 Cardio machines & fixed weights equipment.\n\nLARGE FREE WEIGHTS ROOM:\nOlympic lifting platform, dumbbells & plate loaded kit.\n\nSTUDIO CLASSES:\nOver 200 classes per month, from yoga and pilates to spin.\n\nOPEN 24 HOURS:\nBoxing room & virtual indoor cycling.\n\nPERSONAL TRAINING:\n1-2-1 support & specialist fitness and nutrition tips.\n\nFREE WIFI:\nFree wifi, why not?\n\nLADIES ONLY:\nSeparate ladies only areas.\n\nFREE PARKING:\nBecause it’s the last thing you need to worry about.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 24 Hours\nSat & Sun: 8am - 8pm",
          "locations": [
            {
              "geolocation": [
                -0.7670502999999371,
                51.63554129999999
              ],
              "city": "High Wycombe",
              "postcode": "HP12 3AB",
              "_id": {
                "$oid": "5a14211a2288dd3a30f3f479"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Gym Fit 4 Less - High Wycombe",
          "phone": {
            "number": "1494438834",
            "code": "+44"
          },
          "qualifications": [
            "Ladies Only Areas",
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "24 hr Entry",
            "Free Parking",
            "Free Wi-Fi",
            "Olympic Lifting Kit",
            "Plates",
            "Boxing Room",
            "Over 200 Classes Per Month",
            "Day Pass"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "gym_fit_4_less_-_high_wycombe",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "5890da1385706309618b5726"
      },
      "lastUpdated": {
        "$date": "2017-10-23T20:52:56.398Z"
      },
      "createdAt": 1485888019870,
      "email": "titanshighwycombe@shoomon.xyz",
      "firstName": "Titans Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "C4gh7EuBDchy7NpVFtLyZA==",
      "hashedPassword": "bTl2d88zaA369f958NvGOJLmOFRNnUYceBMepa1sm/jJr9ckghS9cJLExnj0M3Z6I23N7Yb+Sc4PsFpwDmCH6Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HkEQS0em-titanslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Kids Classes",
            "Martial Arts",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://titansgym.info/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lf3Ll6m5-titansbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Here at Titans Gym we offer only the best Hammer Strength equipment exclusive only to Titans members. We are the only and official Hammer Strength gym in Buckinghamshire - so what are you waiting for?\n\nBased in a large complex we offer a separate cardio suite and martial art area. Come in, look lean and keep fit! Come down and check what all the fuss is about!\n\nMARTIAL ARTS WITH JOHN GILBERT:\n\nKickboxing:\nA stand-up combat sport based on kicking and punching - a great way to develop self defence skills and build confidence as well as improving general fitness.\n\nMuai Thai:\nA combat sport of Thailand that uses stand-up striking along with various clinching techniques.\n\nAdult self defence:\nIncrease your stamina, reduce your stress and gain confidence by learning the martial Arts. Training in martial arts can help to put you back in control of your life, health, safety, mind and spirit.\n\nLeadership team:\nStudents are taught skills and qualities needed to teach other students and to lead their peers throught all skills necessary to pass their next belt rank. This encompasses being a positive role model, being polite and courteous, time keeping, lesson planning and how to make it all fun!",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nMon - Fri: 07:00 - 22:00\nSat & Sun: 10:00 - 18:00",
          "strapLine1": "Base for Kevin Capel's Brazilian Jiu Jitsu and Roger Gracie Academy",
          "locations": [
            {
              "geolocation": [
                -0.7709862000000385,
                51.6358702
              ],
              "city": "High Wycombe",
              "postcode": "HP12 3BQ",
              "_id": {
                "$oid": "59ee56a894e4d86bf9be1b1d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Titan's Gym",
          "phone": {
            "code": "+44",
            "number": "1494629529"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Hammer Strength Equipment",
            "Separate Martial Arts Area"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "titans_gym",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "5890e2df85706309618b58e8"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:42:53.486Z"
      },
      "createdAt": 1485890271334,
      "email": "pantherscowley@shoomon.xyz",
      "firstName": "PanthersGym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "4e0WybJVlDJnkiBnlgvfCw==",
      "hashedPassword": "UEmxKMPuT+m+UothMWDl4tpEtufzURAhy3usuP3yOUdZSrBxCGA0r8epwctwNrAqi/Pf5u6d4DQpYj84zoxq+g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PSD16RXJ-pantherslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Bodybuilding",
            "Competition Preparation",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://panthersgym.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": null,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/99xMKF8K-panthersbg2.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "IFBB Centre of Excellence",
          "biography": "OUR FAMILY RUN GYM IS READY TO WELCOME YOU WHETHER YOU ARE A BEGINNER OR SEASONED ATHLETE. WE PRIDE OURSELVES ON BEING A COMFORTABLE & FRIENDLY ATMOSPHERE TO WORK OUT. COME TRY US TODAY!\n\nWith such a huge range of dumbells, barbells, benches, cables and plate loaded machines you can have an intense workout without waiting.\n\nWhether you choose L.I.S.S. (Low Intensity Steady State) or H.I.T.T. (High Intensity Interval Training) the wide choice of equipment will ensure you utilize all your muscle groups to avoid injury and you wont get bored using the same machine everyday!\n\nChoose Treadmill, Upright, recumbent or Arm Bike, Stepper, Rower or Arc Trainer as well as boxing bags in the studio for circuits.\n\nSean and Helen, Panthers Gym owners are fitness and bodybuilding judges in their spare time and travel all over the world judging at various competitions.\n\nThese workshops are designed for those of you who wish to take your fitness and nutrition to the next level and show off your hard earned physiques on stage.\n\nThe workshops cover criteria, what the judges are looking for and how to pose and present your physique to its best.\n\nWith many champions and professional competitors having attended these workshops you are sure to gain some invaluable knowledge when starting out to compete on both the National and International Stage.\n\nWorkshops are held 2nd Saturday in every month.",
          "offers": "Please refer to our website for details of our prices, packages and offers.",
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Benches",
            "Cable Machines",
            "Spa",
            "Cafe",
            "Free Parking",
            "Function Room",
            "Sauna"
          ],
          "locations": [
            {
              "geolocation": [
                -0.4813295000000153,
                51.5199548
              ],
              "city": "Uxbridge",
              "postcode": "UB8 2JT",
              "_id": {
                "$oid": "5a141f4d2288dd3a30f3f477"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Panthers Gym - Cowley",
          "phone": {
            "number": "1895436000",
            "code": "+44"
          }
        },
        "score": 60
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "panthers_gym_-_cowley",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "5890e89685706309618b5a2a"
      },
      "lastUpdated": {
        "$date": "2017-10-23T20:28:32.253Z"
      },
      "createdAt": 1485891734028,
      "email": "whitenoise@shoomon.xyz",
      "firstName": "White Noise",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "NCi/l1IxJeBBZzpdyq0W3A==",
      "hashedPassword": "22taxJrwetxJlYtMrqjGkPtO36IO9AltqgvCE6/JJ0tVqk9bUFsEdLgzwMBtEmS0ttwiy7f9FVWn/PhRsvalDQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/b0dRCu4P-whitenoiselogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Olympic Lifting",
            "Personal Training",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://whitenoisegym.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZMZ6MU9Y-whitenoisebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "We are CrossFit Staines",
          "biography": "The White Noise Gym Staines-Upon-Thames, TW18 3JY is situated by the riverside in Tims Boat Yard with views over the River Thames.\nThe White Noise Gym Staines is a well maintained group training gym and personal training studio with a good selection of cardio, strength training equipment, free weights, olympic bars and strongman equipment.\nThe White Noise Gym does not operate like a typical commercial gym because the gym is not open to drop in users. We focus solely on, regulated gym use so the facility doesn’t get over crowded, private personal training and small group sessions that feel more like group PT sessions rather than your typical commercial gym fitness class.\nIn the groups we like to keep the coach to student ratio high so the attendees weight selection and technique are always kept in check and can be corrected swiftly if required. We aim for a high standard of delivered coaching regardless if you are in a small group session or 1:1 with a coach. We have male and female toilets and plenty of on-site free parking just in front of the gym for our members.\nWhite Noise Gym Staines is ideally situated for clients who live in Surrey or Middlesex, especially, Staines-Upon-Thames, Egham, Addlestone, Chertsey, Weybridge, Brooklands, Ottershaw, Walton-on-Thames, Thorpe, Wentworth & Virginia Water.\nWhite Noise Gym Staines is an excellent members gym where everyone is welcome, lets get you started.",
          "offers": "Please visit our website for prices, packages and offers,",
          "locations": [
            {
              "geolocation": [
                -0.5117235999999821,
                51.4293705
              ],
              "city": "Staines-upon-Thames",
              "postcode": "TW18 3JY",
              "_id": {
                "$oid": "59ee50f094e4d86bf9be1b16"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "White Noise Gym",
          "phone": {
            "code": "+44",
            "number": "7462019718"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Olympic Bars",
            "Strongman Equipment",
            "Free Parking",
            "Male & Female Toilets"
          ],
          "testimonials": "This is genuinely the best Gym in Staines and I’ve tried most of them. It subscribes to a Crossfit/Gym Jones style philosophy, so is well set up for that style of workout. There are small group sessions in the morning and evening. This is not your usual gym, it’s unique and guaranteed to get you results and keep you motivated; you will make friends with the other members.\n\nWhite Noise is a great gym. I have been going there for a while now and the instructors are all very friendly, knowledgeable and experienced. There are a lot of classes to choose from whether new to CrossFit or experienced and the doors of the gym open up onto the Thames. I have made lots of great friends there and would highly recommend this gym to anyone.\n\nGreat community CrossFit Box with group classes which vary each week. There are five trainers who all add something different. There’s the opportunity to take part in fun competitions for Olympic Weightlifting and CrossFit. With these guys you feel anything is possible, well anything that’s possible for a middle aged girl anyway! If this girl can so can you"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "white_noise_gym",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5890eec985706309618b5b6c"
      },
      "lastUpdated": {
        "$date": "2017-10-23T20:07:05.097Z"
      },
      "createdAt": 1485893321574,
      "email": "olympianhayes@shoomon.xyz",
      "firstName": "Olympian",
      "surname": "Fitness Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "FM9En8dI/6jfWsCwoxDpQw==",
      "hashedPassword": "0NqKbglDntjJ6f032+faBIAAfZeI056hxZyJO5F99OA4cickZ5WPVbt7qaNfXOoxChURVRm4zw7ETBxIVsVZiw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fJrotR7e-olympianhayeslogo.png"
        },
        "personal": {
          "disciplines": [
            "Ladies Only Gym",
            "Olympic Lifting",
            "Power Lifting",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.olympianfitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uX0nh8Tm-olympianbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Exercise your body - energise your mind",
          "biography": "The most-seriously equipped fitness club in West London",
          "offers": "Please visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.41355369999996583,
                51.50510190000001
              ],
              "city": "Hayes",
              "postcode": "UB3 3NB",
              "_id": {
                "$oid": "59ee4be994e4d86bf9be1b11"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Olympian Fitness Centre",
          "phone": {
            "code": "+44",
            "number": "2088483932"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Olympic Bench",
            "Punch Bags",
            "Lifefitness Plate Stack Machines"
          ]
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "olympian_fitness_centre",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5891e3d585706309618b8faf"
      },
      "lastUpdated": 1485956928050,
      "createdAt": 1485956053470,
      "email": "bodystudio@shoomon.xyz",
      "firstName": "The Body",
      "surname": "Studio Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "KohfQN2qTp+aUYX+WzF2zQ==",
      "hashedPassword": "G8TPD1IyrefSDkFzx7vBiDR2dqhthpDlQeqsN0mna5g95wfyePRkTsDuwlhCwf5Y8OIjIjO2a7uH10N9qUrdLQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "start": true,
          "additional": true,
          "social": true,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GXivnucC-bodystudiologo.jpg"
        },
        "personal": {
          "disciplines": [
            "Strength & Conditioning",
            "Sports Therapy",
            "Personal Training",
            "Yoga",
            "Martial Arts"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://bodystudio.co.uk/",
          "profileTitle": "General",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ef0fQdTi-bodystudiobg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "CHANGE THE WAY YOU TRAIN",
          "biography": "Olympians, athletes, expert coaches, and rehab specialists all coach at Body Studio. \n\nThey are leaders in their fields. Goals will be, and are achieved in this great training environment and facility.\n\nThe community of people at its centre are all moving towards greatness in mind and body.\n\nBody Studio allows you to achieve what you thought was potentially impossible.\n\nWe do this through caring, by providing education and sharing our experience.\n\nIf you want to climb a mountain, complete a triathlon, swim a lake, play tennis, ski, be pain-free,\nor just learn to do a handstand we can teach you the skills to strengthen, mobilize and eventually realise your true potential.\n\nWe cut the usual gym BS. Using traditional techniques and proven methods we help people achieve anything from weight loss to muscle gain. Our professional coaches, with their wealth of experience, titles and medals specialise in personal training, boxing, yoga and physiotherapy.\n\nWe never fail. We succeed in making people look and feel great.",
          "offers": "Please visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.07884660000001986,
                51.5261933
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "EC2A 3AY"
            }
          ],
          "companyName": "Body Studio - Shoreditch",
          "phone": {
            "code": "+44",
            "number": "2077290111"
          }
        },
        "score": 73
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "body_studio_-_shoreditch"
    },
    {
      "_id": {
        "$oid": "5891e86585706309618b90b1"
      },
      "lastUpdated": {
        "$date": "2017-10-23T19:41:57.894Z"
      },
      "createdAt": 1485957221790,
      "email": "healthyuhemel@shoomon.xyz",
      "firstName": "Healthy U",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Z4tQfPgm6H+apjhnF/xx5A==",
      "hashedPassword": "I/1N/gDtwhLEmBA4FTai2M9gdZCGjXT9XDaaZc3EA7grX/xjopV+gKFZzhR9JVpVaQ0DBGLVvs2ky28kzrpq7w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/CkqLHmiq-healthyulogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Kids Classes",
            "Ladies Only Gym",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.healthyugym.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tcjO0ZXz-healthyubg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Ladies Gym: Beauty - Nutrition",
          "biography": "Healthy U is an independent Ladies Fitness Centre in Hemel Hempstead. The centre has been built to make ladies feel comfortable and relaxed whilst working out.\n​\nOur aim is to help women no matter what their age, size or fitness levels and improve their health and fitness.\n​\nOffering a wide range of classes including Zumba, LBT & Pilates - we also love our 60's 70's 80's music morning's\nMonday-Friday catering for our morning ladies taste of music.\n​\nOur classes are designed to suit all fitness levels weather your a beginner, Intermediate or advance level - we welcome all ages from children to mature women who have retired.\n​\nIn our cardio room have our baby corner where mums can lay their little one while working out in the gym for no extra charge.\n\nWe have a passion to get our children moving and Kids Fitness does exactly that. Please go to our Kids Fitness page to find out more. As well as kids fitness we also offer Girls fitness, a chance for girls 12 years plus to use the gym under supervision.\n \nWe have our own Hairdresser in centre and soon a Sports masseur in the spring - please see our Hair and Beauty page.  \n\nHave a look around our website and call us if you need any more assistance.",
          "offers": "Please visit our website for detils of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 6am - 8pm\nSat & Sun: 9am - 6pm",
          "locations": [
            {
              "geolocation": [
                -0.4489571999999953,
                51.7423444
              ],
              "city": "Hemel Hempstead",
              "postcode": "HP3 8EW",
              "_id": {
                "$oid": "59ee460594e4d86bf9be1b0a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Healthy U Ladies Gym",
          "phone": {
            "code": "+44",
            "number": "1442769071"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Hairdresser",
            "Supplements",
            "Training Programme"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "healthy_u_ladies_gym",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5891ee2385706309618b91f5"
      },
      "lastUpdated": {
        "$date": "2017-11-18T12:27:28.364Z"
      },
      "createdAt": 1485958691794,
      "email": "nrgwatford@shoomon.xyz",
      "firstName": "NRG Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "PZ/OWAV6U3+Wcwm+58vkqg==",
      "hashedPassword": "k3iw3HAk4nr3SC2CotEHQQ4BbAxEoQ+PbXJOwfewx+E7XUTTGJUQETPSBHfSNaDf98IaKBr0Ql4N74sxdndlhg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NrvlR3Dq-nrglogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Boxing",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "https://www.nrggym.com/gym-locations/watford",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RgA2B7Py-nrgbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "At NRG GYM Watford we have a dedicated Female Zone, Cardio Zone, Strength Zone, Spin Zone, Boxing Zone and a great studio for free classes.\n\nNRG Watford is located right in the centre of town and offers the best value and convenience in the area. Our fantastic staff are available if you need any help with your with your diet, workout and training sessions.\n\nWORKING OUT AT NRG GYM 24-7\n\nGreat high spec equipment available for all at an affordable monthly price.\n\nNRG has been designed for people who want to join a great gym with great equipment at a great price. We have created a gym which works around you – No Contract, No Joining Fee and we are open 24 hours 7 days a week.\n\nWe have over 30 free classes per week and personal trainers available to help you reach your health and fitness goals.\n\nAt NRG Gym you will find well over 150 pieces of equipment and free weights.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nOpen 24 Hours",
          "locations": [
            {
              "geolocation": [
                -0.39801009999996495,
                51.6572714
              ],
              "city": "Watford",
              "postcode": "WD17 1BJ",
              "_id": {
                "$oid": "5a102730430ff53fda549bbe"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "NRG Gym - Watford",
          "phone": {
            "code": "+44",
            "number": "1923228485"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Ladies Only Area",
            "Olympic Bench",
            "24 hr Entry",
            "No Contract",
            "No Joining Fee",
            "Day Pass",
            "Shop"
          ],
          "strapLine1": "Powering Health & Fitness"
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "nrg_gym_-_watford",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5891f9ee85706309618b9477"
      },
      "lastUpdated": {
        "$date": "2017-10-23T19:10:15.550Z"
      },
      "createdAt": 1485961710927,
      "email": "zonewoodgreen@shoomon.xyz",
      "firstName": "Zone Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "1CO2KtRjxS3pngOT7gVCAg==",
      "hashedPassword": "YFAjBedUJuSgFmTl+jksyYoBvqW1ZVH6w3cETG4MeGTFZ7X2cV0J+zq1S+2S4fsh8MNLVjjuCC/ve5G3Hm4Jlw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/juQZgu0p-zonepro.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Martial Arts",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Sports Massage"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://zonegym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NVJv14u7-zonebg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Zone Gym is a Fully equipped Hammer Strength training centre:\n\nWe have over 200 specialist machines that cater to all body builders and athletes and an impressive full range of Ivanko Dumbbells & Barbbells in our free weights section. \n\nWe have a dedicated cardio suite, with many different machines including two Stair Master Climbers, an MMA Dojo room and Boxing facilities.\n\nYou also have access to changing rooms, showers, free car park, and in-house supplement shop. \n\nWe have been an established gym for 15 years and have many world class athletes who are part of the team here at Zone Gym.\n\nWhen you work with a 24 Hour Fitness personal trainer, you’ll benefit from a personal fitness training program that’s custom-built with your goals in mind.\n\nOur certified personal trainers provide guidance and inspiration through programs tailored to your health and fitness now, and the results you wish to achieve.\n\nWe offer a wide range of different style massages as well as physiotherapy with our qualified masseuse Renata. She’s excellent at providing a sports massage tailored to your specific requirements or injuries.\n\nComing very soon, we will be providing high quality sun beds for member use, and a relaxing hot sauna.",
          "strapLine1": "Unleash your inner strength - discover your physical potential with Zone Gym",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMon - Thurs: 6:00 AM - 12:00 AM\nFri: 6:00am - 10:00pm\nSat: 9:00am - 8:00pm\nSun: 9:00am - 5:00pm",
          "locations": [
            {
              "geolocation": [
                -0.11313680000000659,
                51.5946521
              ],
              "city": "London",
              "postcode": "N22 6UJ",
              "_id": {
                "$oid": "59ee3e9794e4d86bf9be1b01"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Zone Gym - Wood Green",
          "phone": {
            "code": "+44",
            "number": "2088818222"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "MMA Dojo",
            "Boxing Facilities",
            "Changing Facilities",
            "Free Parking",
            "Supplements"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "zone_gym_-_wood_green",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5892000785706309618b95b9"
      },
      "lastUpdated": {
        "$date": "2017-10-23T18:56:50.353Z"
      },
      "createdAt": 1485963271537,
      "email": "windsorswatford@shoomon.xyz",
      "firstName": "Windsor's",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "K5RHVd69nTH/F0Ri6h9qgg==",
      "hashedPassword": "+fzE+4zeEfEtxc8KEiQR+8kRgkHx+yCJQpGPAG3MtbntnSFG0kjdcz/70LQKPB74gaqnGi/cZ/NiMgPT9HvRGQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3VXaEWjp-windsorelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Beauty Treatments",
            "Cardiovascular",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.windsorsfitness.co.uk/gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IWzfQO0d-windsorsbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Windsors Fitness Gym in Watford:\n\nWindows Fitness offers a warm and friendly environment which you can relax and feel comfortable in when using it’s many fitness, beauty and function room facilities. It is located in the heart of North Watford, and is within close walking distance of Imperial Business Park, Greycainnes, Tudor, Herebreaks and St. Albans Road.\nLarge gym floor, many stations including cardio equipment, resistance machines, rowers, cable crossovers. We have two Studios for our regular classes, a separate free weights area with Olympic plates and benches. Power cage, smith machine. We have two vibrogyms, the leading manufacturer of this excellent form of training – click here for more information.\n\nWe have Level 3 Trainers, who are experienced in nutrition, one to one personal training, we also have a competition level free weights trainer, who is on hand to give you additional help if needed.\n\nFunction Rooms For Hire:\nWe have two function rooms , the larger of the two has it’s own licensed bar available during the day. Both rooms are modern, clean and have flat screen tv’s with FREE wi fi if required.\n\nBeauty Salon:\nAn experienced Beauty Therapist and specialist in St. Tropez Spray Tanning is available – also open to non-members.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMon - Fri: 06.30 - 21.30\nSat; 08.30 - 19.00\nSun: 08.30 - 17.00\nBank Hols:10.00 - 17.00",
          "strapLine1": "A warm and friendly environment with many fitness, beauty and function-room facilities",
          "locations": [
            {
              "geolocation": [
                -0.39230910000003405,
                51.6737478
              ],
              "city": "Watford",
              "postcode": "WD24 7UF",
              "_id": {
                "$oid": "59ee3b7294e4d86bf9be1afd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Windsor's Fitness - Watford",
          "phone": {
            "code": "+44",
            "number": "1923255400"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Free Weight Area",
            "Functional Training Zone",
            "Fitness Studio",
            "Function Rooms For Hire",
            "Olympic Plates",
            "Olympic Benches",
            "Vibrogyms",
            "Power Cage",
            "Smith Machine",
            "Beauty Salon",
            "Cable Machines",
            "Rowers",
            "Tanning Facilities"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "windsors_fitness_-_watford",
      "feature_in_area": "none",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "5892051b85706309618b96fb"
      },
      "lastUpdated": {
        "$date": "2017-11-18T12:28:34.100Z"
      },
      "createdAt": 1485964571172,
      "email": "pumpwatford@shoomon.xyz",
      "firstName": "Pump Gyms",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "0fAwO5ZCqSe9alFszq+75g==",
      "hashedPassword": "8yrshKDvNDRUwWPLORcpd6ieoClJUjuljKSOnDUqCcTLzMy22nDcNPWR8gVgs1S16EU8YHwS/z40D4WH3c2GMw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ndX3HwvD-pumplogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.pumpgyms.com/watford",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4Mt9ohsj-pumpbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "LOW COST. HIGH SPEC. ANYTIME.",
          "biography": "A WHOLE NEW APPROACH TO WORKING OUT AND GETTING FIT\n​\nPump Gyms offers a whole new approach to working out and getting fit. We're a 24 hour gym with affordable gym memberships, a refreshing alternative to restricted opening hours and expensive contracts, from which many of us don’t get our money’s worth. \nWe’ve stripped away the frills and the fluff so we can focus on what you really want: high spec equipment, spacious training areas and the very best in fitness staff and personal trainers.\n​It’s a low cost, high quality workout available 24 hours a day, 7 days a week. \n\nWe’ve invested heavily in over 140 pieces of state-of-the art cardio and strength equipment. \nOver 250 classes a month ALL included in your membership.\nEnormous free weights area\u0003with benches and dumb bells up to 60KG.\nWe are the first 24-hour gym in the UK to introduce fingerprint entry for extra security and safety.\nWe have a large variety of kettle bells for all your bespoke workouts.\nWe are open day and night all year so you don’t have to rush home from a long day at work.\nWe have a range of fitness classes, including Zumba, Yoga, Pilates, Spin, Boxercise and much more.\nPump Gyms only employ fully qualified and experienced local gym personal trainers.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nIt’s a low cost, high quality workout available 24 hours a day, 7 days a week.",
          "locations": [
            {
              "geolocation": [
                -0.42481920000000173,
                51.64460459999999
              ],
              "city": "Watford",
              "postcode": "WD18 8PH",
              "_id": {
                "$oid": "5a102772430ff53fda549bbf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Pump Gyms Watford",
          "phone": {
            "code": "+44",
            "number": "1923819233"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "24 hr Entry",
            "Supplements",
            "Kettlebells"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "pump_gyms_watford",
      "feature_in_area": "none",
      "__v": 11
    },
    {
      "_id": {
        "$oid": "5892f35e85706309618bc9bd"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:47:24.343Z"
      },
      "createdAt": 1486025566946,
      "email": "dgkramer88@googlemail.com",
      "firstName": "Dean",
      "surname": "Kramer",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "g2qtnGDzsFB6jcC3uwq/KA==",
      "hashedPassword": "Cv2kJY79DAaKPunFkFZn6+Nm9qoQ1hW0LqBJknb3RtMqyqJXOYdvdPVGvwQifFOultfQbcbHXXFs7UkEwmGW2g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2WV5rxZy-FB_IMG_1486382321204.jpg"
        },
        "personal": {
          "disciplines": [
            "Bootcamp",
            "Hiit",
            "Mobile Trainer",
            "Toning",
            "Weight Loss"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "price": {
            "max": 30,
            "min": 25
          },
          "strapLine1": "Do you need some motivation? Are you bored of the same old routine? It's Time to Get Lean with Dean",
          "offers": "All sessions include written up plans, support, results and body composition. \n\nAll sessions can be done outside or indoors. Can even train you and your staff at work. \n\nRefer a friend and get a free session\n\n£30 / hr per session\n\nBlock of 5 Sessions £125 (5 x 60 min Sessions)\n\n11 For 10 Package £300 (11 x 60 min Sessions)\n\nXpresss Package £150 (10 x 30 min Sessions)\n\nTrain with a Friend £45 p/h\n\n£25 Fitness Testing and Assessment \n\nGroup classes - various locations, days and times - prices various from £5 - £10\n\nGroup Sessions Available\n\nNutrition Plans\n\nRunning Plans and Run Club Also Available",
          "biography": "My aim in life is to help people achieve their health & fitness dreams whatever that may be from weight loss to improving sports performance and joining that person on their journey through the tough time to the good times. I live, breathe and sleep health & fitness. I have been involved in the fitness industry since 2011 as a Gym and Class instructor, Personal Trainer and Exercise Referral Specialist before that I was a sports coach which I still do to this day.\n\nI have worked for many companies including Virgin, NHS, GLL, Redbridge Vision, West Ham Community Trust, Nike and Adidas offering one on one sessions and group sessions. From a young age, I have always been involved in sports such as football, cricket, wrestling, MMA and athletics either as a coach or participant.\n\nThrough my experience and training at this level, I have provided run clubs for Nike and Adidas, helping people to get into running, carry on running and help them to achieve their running goals. Since then I have set up my own run club called Run Club East.\n\nI am currently working for the NHS at City and Hackney in Homerton within the TLSS Team using exercise and sports as a form of treatment for patients with mental health on the wards and within the community. Outside of this job I am involved in other projects such as providing walking football session for the West Ham Community Team in Stratford, taking 3 classes weekly for NHS staff at their workplace around East London.",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rrAQoDWQ-Business%20Card%202.png",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "27684712.8640130.f76c66faa3574017b3eed0ddf76ff1b3",
            "link": "https://www.instagram.com/getleanwithdean"
          },
          "locations": [
            {
              "geolocation": [
                0.1300724000000173,
                51.57947059999999
              ],
              "city": "Greater London",
              "postcode": "RM6 5AB",
              "_id": {
                "$oid": "5a600abc765eba094646f75c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7447931788",
            "code": "+44"
          },
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Sports Coach",
            "Level 2: Gym Instructor"
          ]
        },
        "score": 70
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "dean_kramer",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5893348285706309618bd7c1"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:45:36.263Z"
      },
      "createdAt": 1486042242573,
      "email": "claireweatherleyyoga@icloud.com",
      "firstName": "Claire",
      "surname": "Weatherley",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "586TPn8BDyP8XJDuOEiuKg==",
      "hashedPassword": "iHzPlC/jPCNm22OaBrqtU2dXEJSBux8j1rXMUihOQccNDpR9E2ltJP31B92OHKXxHHeaHNa7Zer/J1aRoaTm8g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3Yv6kZuh-IMG_4258.JPG"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Hatha",
            "Iyengar",
            "Mobile Teacher",
            "Private Classes"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 15,
          "price": {
            "min": 35,
            "max": 60
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qBs6S5pQ-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "OyogaCommunity",
            "link": "https://twitter.com/OyogaCommunity",
            "user_id": "4504178907",
            "accessToken": "4504178907-cQDKcnqQsHGvOAyGp64if5hcO7hjXVPKqDj1GmU",
            "accessTokenSecret": "rN2h6x1HB0nEAiEBqz28l1phbuXVqAkBvqVXUCiwvWp2F"
          },
          "instagram": {
            "access_token": "381513897.8640130.c11f2f01da79415b879a454aaec6b0c4",
            "link": "https://www.instagram.com/claireweatherleyyoga"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/365660480475662/",
          "locations": [
            {
              "geolocation": [
                -0.6155102999999826,
                51.3088692
              ],
              "city": "Brookwood",
              "postcode": "GU21",
              "_id": {
                "$oid": "5a600a50765eba094646f75a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7989533431",
            "code": "+44"
          },
          "biography": "I am a senior yoga teacher with the yoga alliance UK and have been teaching for over 7 years.\n\nAs well as yoga I am qualified in anatomy ND physiology, body massage, hot stone massage and N.L.P.\n\nAll of these talents I share in a beautiful, elegant and simple way with others to support them in realising their full potential, health, purpose and blissfulness.",
          "qualifications": [
            "Level 3: Yoga Teacher",
            "Anatomy ND physiology",
            "Body Massage",
            "Hot Stone Massage",
            "Neuro Linguistic Programming (NLP)"
          ]
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "num_votes": 2,
      "trainer_tools_access": false,
      "username": "claire_weatherley",
      "activation_email_sent": true,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58933fa485706309618bda04"
      },
      "lastUpdated": 1486045188906,
      "createdAt": 1486045092800,
      "email": "timmygind@mac.com",
      "firstName": "Tim",
      "surname": "Mygind",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "cbf376f59bcacc81221befcb9700f6cbefc8eb80",
      "salt": "E9KiohuYGCFTOlV6E95LVg==",
      "hashedPassword": "koHCJCi0pLnPA1nRg/svcwhlrUu4l3lKJVllHmtPbcpDzPiFgK/Jl8FnrVxa7Cl6Weese3eGDOldqe66wm4AcA==",
      "settings": {
        "wizard": {
          "complete": false,
          "start": true,
          "additional": false,
          "social": false,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "timezone": "Europe/London",
          "gender": "male",
          "companyWebsite": null,
          "profileTitle": null,
          "radius": 5,
          "price": {
            "min": 0,
            "max": 200
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.6145858160933437,
                51.185756917404426
              ],
              "country": "United Kingdom",
              "city": "Godalming",
              "postcode": "GU7 1DT"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7887408880"
          }
        },
        "score": 30
      },
      "active": false,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "tim_mygind"
    },
    {
      "_id": {
        "$oid": "5893bf4d85706309618bf547"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:35:32.118Z"
      },
      "createdAt": 1486077773042,
      "email": "info@lovemyspacekidsyoga.co.uk",
      "firstName": "Liana",
      "surname": "Swart",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "UCKxXn16Fi7uWye8uRs0hA==",
      "hashedPassword": "uYECwyF3SFGBAPWiYw/QBRuL0lurxVTJASQhuDciB2CX/tdJNOwBtigivobSpogCcxjyhh1FChxORHm0rRtGDQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sHUqkEHN-LoveMySpace_Square_Background-01.png"
        },
        "personal": {
          "disciplines": [
            "Private Classes"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 10,
          "price": {
            "min": 35,
            "max": 40
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eN5V9NYD-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10158118351860621/",
          "instagram": {
            "access_token": "4059267105.8640130.0ea51f05c6b8493087337fe31e0d76a7",
            "link": "https://www.instagram.com/lovemyspace.kids.yoga"
          },
          "locations": [
            {
              "geolocation": [
                -0.6179005651153419,
                51.48233065013231
              ],
              "city": "Windsor",
              "postcode": "SL4",
              "_id": {
                "$oid": "5a6007f4765eba094646f759"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7786890238",
            "code": "+44"
          },
          "biography": "Welcome little yogis!\n\nLove my space Kids Yoga is currently based in Windsor Berkshire. I offer classes to little ones aged 5 to 10. I also have experience in teaching younger ones so all kiddies are welcome! \nYoga will help build concentration, develop body awareness and will improve self confidence. \n\nAt Love my Space we learn how to use our bodies in a healthy way, we learn yoga poses through story telling and how to relax our bodies and mind through meditation. Toys and props are used to bring in an element of fun. \n\n\nClasses will be held every Thursdays from the 1st of December.\n\nYoga mats will be provided.\n\nPlease contact me for further details or any enquiries.",
          "qualifications": [
            "Level 3: Yoga Teacher"
          ]
        },
        "score": 60
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "liana_swart",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5894610385706309618c17cc"
      },
      "lastUpdated": {
        "$date": "2017-10-23T18:06:14.711Z"
      },
      "createdAt": 1486119171569,
      "email": "amafitnesssharrow@shoomon.xyz",
      "firstName": "AMA Fitness",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "tivwgzt93yYg3op5M4SKnA==",
      "hashedPassword": "QLWEskojdHAw4Akj4/xk2g9cUdpcUfDK4R5O2FRZ3PC92UOUi+DZOeLV4KHZSem5urpS8SJq2fZrMeu03exDgQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8k7zneAm-amalogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Kids Classes",
            "Ladies Only Gym",
            "Martial Arts",
            "Sports Therapy & Rehab",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://amafitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vS4vS3rK-amabg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "AMA fitness is a versatile gym that provides high-end training across a number of genres of exercise, an environment available for all.\n\nWe have the best facilities for training, whether it is bodybuilding, strength & conditioning, martial arts or whether it is to simply lose body fat.\n\nYour training in AMA fitness will be overseen by our elite team of fitness professionals who have many years of experience and success in the personal-training industry. Their specialties include body building, strength & conditioning, martial arts and injury rehabilitation.\n\nWe have a well-equipped, functional-training studio complete with a custom-built training rig consisting of hanging boxing bags, monkey bars, two squat racks and two wings complete with gymnastic rings.\n\nThe studio will hold a variety of specialist classes ranging from our own “AMA Extreme Boot camp” and “AMA Met-Con Workout” to various martial arts classes and AMA kids. These classes will be open to anyone, male or female, whether you’re just starting out or whether you are at a professional level.\n\nAMA Fitness is a fully segregated gym, providing for both men and women with added privacy. Our gym provides both sexes with separate entrances and exits. Separate times will be allocated for both men and women to use the studio and classes will be allocated accordingly.",
          "strapLine1": "A fully-segregated gym, providing added privacy for both men and women with separate entrances and exits.",
          "offers": "Please visit to our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 06:00 – 23:00\nSat - Sun: 0800 – 20:00",
          "locations": [
            {
              "geolocation": [
                -0.3554699999999684,
                51.5667351
              ],
              "city": "Harrow",
              "postcode": "HA2 8AT",
              "_id": {
                "$oid": "59ee2f9694e4d86bf9be1ae9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "AMA Fitness",
          "phone": {
            "code": "+44",
            "number": "2086345838"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Punch Bags",
            "Gymnastic Rings",
            "Fully Segregated Gym"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ama_fitness",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58946e7985706309618c1ace"
      },
      "lastUpdated": {
        "$date": "2017-10-23T17:54:13.357Z"
      },
      "createdAt": 1486122617087,
      "email": "genesiswembley@shoomon.xyz",
      "firstName": "Genesis Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Wb65yNLZJqJijsDisqhkUg==",
      "hashedPassword": "NrNGLjhukrR06q+/8mv/37+9MBWKhyGX9cU5gZdApMf09NY0FbwYY9eG+K431VJSYvMuFR9mwEM64Zzb6hbr9A==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/csEXeplz-genesiswembleylogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Diet & Nutrition",
            "Power Lifting",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.genesisgym.co.uk/index.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HDxWp3JF-genesiswembleybg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "London's professional, hardcore gymnasium for everyone, from first timers to professional athletes.",
          "biography": "Genesis Gym is the home of Bulldog Powerlifting, building champions since 1961 in London. Genesis Gym is a top hardcore London gym for everyone including powerlifters, bodybuilders, athletes and those who want to get back in shape and improve their fitness. Genesis Gym is designed for results, where everyone gets better and stronger.\n\nGenesis Gym has been owned by World Champion Powerlifter Dave \"Bulldog\" Beattie for the last 14 years.\n\nDave has helped train The Rock and Jimmy Marku who won Britain's Strongest Man in 2008 and regularly helps coach Andy Bolton, the World's Premier Super Heavy Weight powerlifter. \n\nThese athelets train with Dave in what is a \"no attitude\" friendly gym for everyone. Dave also has a strong powerlifting team, who have taken numerous titles. \n\nDave is keen to promote the use of weights in many sports and coaches gymnasts, body builders, endurance athletes and professional boxers to better themselves through the use of weights and initiative ideas. Dave is keen to help anyone succeed, including wheelchair and disabled athletes through to top International competitors in any sport.",
          "yearsOfExp": 1961,
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMonday to Friday: 6am - Midnight\nSaturday and Sunday: 9am - 6pm",
          "locations": [
            {
              "geolocation": [
                -0.3040254999999661,
                51.5374344
              ],
              "city": "Wembley",
              "postcode": "HA0 1EF",
              "_id": {
                "$oid": "59ee2cc594e4d86bf9be1ae4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Genesis Gym",
          "phone": {
            "code": "+44",
            "number": "2085668687"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Lounge Area",
            "Top Of The Range Powerlifting Equipment",
            "Hammer Strenght Machines",
            "Dumbells Of Up To 100kg",
            "Full Range of Power Lifting Bars",
            "Competition Benches",
            "Mono Lifts",
            "Olympic Lifting Area",
            "Olypmic Lifting Bars",
            "Extensive Range Of Strong Men Equipment"
          ]
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "genesis_gym_HkCZ3ijpb",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5894742785706309618c1bd0"
      },
      "lastUpdated": {
        "$date": "2017-10-23T17:38:59.809Z"
      },
      "createdAt": 1486124071464,
      "email": "oldironworks@shoomon.xyz",
      "firstName": "OldIronworks",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Ubwryqvc5tDpT7xZ+pht+g==",
      "hashedPassword": "fRAwClAtJveMMb3C+sHod7K5lO/uaeax7H8hUxm8Ax7HMO2FpZfcAzRjZXpDNRcQPhaioZCpfQjHP4X4ywpRLw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lfCTVBlO-old%20ironworkslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Diet & Nutrition",
            "Fitness Classes",
            "Ladies Only Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://oldironworksgym.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/whWxtqHC-oldironworksbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The best gym for personal training, bodybuilding, fitness and weight loss in Essex",
          "biography": "Old Ironworks Gym Maldon, Essex offers you a Bodybuilding Gym with Free Weights, Machines, Cardio Equipment - including Treadmills, Cross Trainers, and Rowers & Bikes - a tanning room with stand-up Sunbed.\n\nBoxing & MMA tuition is available at the Old Ironworks Gym with a large PT area complete with Bags- it's  two Gyms in one.\n\nYou can Lose Weight with keep-fit Personal Fitness Training.\n\nClasses available : Ladies Kick Chick – Spinning (indoor cycling) Circuits Classes.\n\nBoxing classes, MMA fitness, Bodybuilding Coaching and Weight Loss advice.\n\nYou are welcome to A Free First Workout and  toPay As You Go at Old Ironworks Gym Maldon, Essex, nearby to Chelmsford -  it's The friendliest gym In Maldon, Essex.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon – Fri: 6:30am – 10pm\nSaturday:  8am – 5pm\nSunday:  9am – 5pm\nBank Holidays: 8am – 4pm\nXmas Day: Closed\nBoxing Day: Closed",
          "locations": [
            {
              "geolocation": [
                0.6788785999999618,
                51.7349826
              ],
              "city": "Maldon",
              "postcode": "CM9 4LE",
              "_id": {
                "$oid": "59ee293394e4d86bf9be1ae1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Old Ironworks Gym",
          "phone": {
            "code": "+44",
            "number": "1621840098"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Tanning Facilities",
            "Supplements",
            "Shop"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "old_ironworks_gym",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5894954485706309618c2314"
      },
      "lastUpdated": {
        "$date": "2017-10-23T17:28:19.544Z"
      },
      "createdAt": 1486132548536,
      "email": "feelfitcowley@shoomon.xyz",
      "firstName": "Feel Fit",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "l/Xq6fTfBIConwZeQSZ9wA==",
      "hashedPassword": "FhEI7JYPWBBV5AqaVRBfa8fmSR7qV4HFxh9Yvzhnvgx20enqoaK4sWol12Ud5xvhWm4HxMa+bfQN83FKMdSAsw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HZCFuiXs-feelfitlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Fitness Classes",
            "Power Lifting",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://feelfitgym.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vxdR1Vnm-feelfitbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Oxford's Largest Gym & Spa With A Ladies Only Floor",
          "biography": "Feelfit has the best gym equipment available in Oxford. With the latest Matrix machines spanning two huge floors, more space equals an incredible experience! Never wait for your favourite machine again. We have even got a Nutrition Bar where you can get your protein shakes, morning coffee or a power bar. Tour the Feelfit Gym and start working out right now!\n\n30,000+ SQ FEET OF GYM SPACE:\nMore space equals more machines! Never wait for your favourite again.\n\n2 STUDIOS & OVER 50 CLASSES A WEEK:\nVariety is the spice of life! 2 large studios and loads of classes to choose from\n\nHOLISTIC NUTRITION BAR:\nGrab your training shakes, morning coffee, high protein bars and nutritious smoothies\n\nTOP FLOOR WOMEN ONLY GYM:\nDedicated top floor mini-gym for exclusive use by our female members\n\nSTEAM & SAUNA\n\nCOME AND TRY US OUT FOR FREE",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Fri: 6:00am - 11:00pm\nSat & Sun: 9:00am - 10:00am",
          "locations": [
            {
              "geolocation": [
                -1.216357099999982,
                51.73208149999999
              ],
              "city": "Oxford",
              "postcode": "OX4 3XH",
              "_id": {
                "$oid": "59ee26b394e4d86bf9be1adf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Feel Fit Gym & Spa - Square Cowley, Oxford",
          "phone": {
            "code": "+44",
            "number": "1865711333"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Zone",
            "Fitness Studio",
            "Cycle Studio",
            "Steam Room",
            "Changing Facilities",
            "Juice / Shake Bar",
            "Sauna",
            "Free Pass"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "feel_fit_gym_and_spa_-_square_cowley,_oxford",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5895ea5585706309618c6bd5"
      },
      "lastUpdated": {
        "$date": "2017-10-23T17:04:56.715Z"
      },
      "createdAt": 1486219861714,
      "providerId": "104153334211964951822",
      "provider": "google",
      "surname": "Morgan",
      "firstName": "Ashley",
      "email": "ashley@naturallynati.com",
      "settings": {
        "personal": {
          "google": "https://plus.google.com/104153334211964951822",
          "disciplines": [
            "Bootcamps",
            "Diet & Nutrition",
            "Group Classes",
            "Personal Training",
            "Yoga"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "Http://www.naturallynati.com",
          "profileTitle": "Mobile Personal Training",
          "radius": 20,
          "price": {
            "min": 10,
            "max": 70
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aYp7O4XM-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -84.40958205618375,
                39.1408480983592
              ],
              "city": "Cincinnati",
              "postcode": "45208",
              "_id": {
                "$oid": "59ee213894e4d86bf9be1adb"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "companyName": "NaturallyNati Fitness & Nutrition",
          "phone": {
            "code": "+1",
            "number": "5132678207"
          },
          "biography": "The idea behind NaturallyNati Fitness & Nutrition LLC is to bring fun into your workout while ensuring the plan is tailored to meet your needs and goals.  We offer in-home personal training, custom fitness and eating plans, boot camps and Fitness Parties! \n\nTrain:\n\nSpecialized workouts or yoga sessions that are designed to meet your fitness goals and are implemented by a certified personal trainer in the comfort of your own home.  \n*We are now offering exclusive online training programs! \n\nEat:\nIncludes a consultation, personalized meal plan, weekly meal planning and grocery shopping documents, recipe ideas & weight loss strategies that are designed based around concepts of flexible & sustainable lifestyle dieting.\n\nParty:\nDitch the gym & grab your friends.  Host your very own one-hour fitness class from the comfort of your own home.  Concepts of Pilates, Tabata, circuit training, yoga & aerobics will be integrated into your workout.  After each party participants will get to sample tasty & healthy treats and will receive a NaturallyNati Fitness & Nutrition goody bag.",
          "qualifications": [],
          "offers": "Please visit our website for details of prices, packages & offers.",
          "testimonials": "Mallory M.\n\n\"Ashley is wonderful! She is very knowledgable and easy to get a long with along with being flexible. She truly shows in interest in helping you get the results you want.\"\n\nSowmya A.\n\n\"I have enjoyed training with Ashley. She has made the experience fun and provides customized one and one workouts. She also provides insightful nutritonal tips. I have had several personal training experiences and NaturallyNati Fitness is the best so far!\"\n\nErin H.\n\n​\"Ashley did a fitness party as part of a bachelorette party I planned. The class was great and she was so friendly and accommodating to all my friends!\"\n\nAmanda V.\n\n\"I have attended a party in the park workout as well as hosted a Fitness Party, both have exceeded my expectations. I have another party scheduled in January and am excited to do another. The party was a great workout and an even better time with my girlfriends!\"\n\nLauren H.\n\n\"Ashley brings a challenging workout each time. She is so good to modify each exercise so everyone in the group is challenged appropriately. Oh and you finish with a healthy snack and recipe....Awesome!\""
        },
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WQmi5Bjp-IMG_1095.JPG"
        },
        "score": 57
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "naturallynati_fitness_and_nutrition",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5898355485706309618ce917"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:38:10.628Z"
      },
      "createdAt": 1486370132071,
      "email": "bodystudionorthwood@shoomon.xyz",
      "firstName": "The Body",
      "surname": "Studio Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "1U2E82cjB4P2+9kLDiK3rA==",
      "hashedPassword": "pIzuV5XPEEnYCqxuhMZcR9GyprpYiQvHXS3yq+LtQDJawJhiv+o9f13eVpCB9uSY/dbBSL7Xo3OCGQ6YubrQjA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OQHtgRF2-bodystudionwoodpro.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Wellness"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.thebodystudio.info/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qGsuDZ4V-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "biography": "Body Studio is based in the heart of Northwood Hills, North London, established in 2009 we have quickly become known as one of the best exclusive personal training clubs outside London.\n\nEntering into a world of wellbeing is arriving at The Body Studio. Our highly specialised and nurturing team puts YOU at the heart of your journey to optimum health.\n\nTailoring a fully integrated package to individually suit you, you will be assigned a personal ‘health coach’ who will look at the ‘whole’ of you, and introduce you to a team of health professionals who will support and inspire you within a compassionate and safe environment.\n\nSo wherever you are on the wellness scale, please come and join the Body Studio community, as we empower you to achieve your goals and guide you on this life-changing journey.\n\nWe have acquired our reputation as a club boasting the most experienced team of personal trainers outside London.\n\nPursuing our policy of offering the best possible value for money, state of the art equipment and a great atmosphere puts this studio above all training venues.",
          "strapLine1": "Discover your personal pathway to complete individual health",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours: \n\nMon - Thurs: 6:30am - 9:00pm\nFri: 6:30am - 7:30pm\nSat & Sun: 8:00am - 1:30pm\nBank Holidays : 08:00 to 14:00",
          "locations": [
            {
              "geolocation": [
                -0.4102218000000448,
                51.5995375
              ],
              "city": "Northwood",
              "postcode": "HA6 1NW",
              "_id": {
                "$oid": "5a141e322288dd3a30f3f476"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Body Studio",
          "phone": {
            "number": "1923848690",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Fitness Studio",
            "Changing Facilities",
            "Lockers",
            "Medical Centre"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_body_studio",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "58983acb85706309618cea19"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:36:44.116Z"
      },
      "createdAt": 1486371531597,
      "email": "pmgharrow@shoomon.xyz",
      "firstName": "Pure Muscles",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "hDtBBmgXKFagiC6ZgXfAUg==",
      "hashedPassword": "B/xvS40P1X30VYZ+Xr1ErmvhgHKf+YVNypjP9TNLa7f/GboCTE7TQSbWJgDBzdMNMRzvpsQG+b24iCN58wWV/A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LxIj7lqL-pmglogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Bodybuilding",
            "Boot Camp",
            "Diet & Nutrition",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.puremusclesgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uIlClQzH-pmgbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Family owned and run, the Pure Muscles Gym in both East London and West London are home to hundreds of members on a mission to meet their fitness goals and aspirations.\n\nBoth gyms are unique in the way they offer an atmosphere reminiscent of the famous Gold’s Gym in Venice Beach, California. We are home to some of the UK’s champion bodybuilders and other fitness athletes – you’ll also see the odd celebrity running around!\n\nWe welcome anyone and everyone at all levels of fitness. Whether you’re new to the gym or a seasoned gym-goer, there is an extensive range of cardiovascular machines, free weights and exclusive Olym weight training machines.\n\nFor years we have helped those in our local communities with their fitness goals. We offer qualified personal training, as well as expert nutrition and training advice to our members.\n\nWe pride ourselves in offering a home, mentorship and new direction for troubled youngsters and people of all ages in the community. Our efforts have also been recognized and awarded by the House of Lords for offering a really good sense of leadership and support for those who have truly benefited from our dedication to fitness and bettering of our community.\n\nWith years of experience, knowledge and dedication to anything and everything fitness, we make this promise – we will help you succeed and meet your goals.\n\nThe way in that is done comes in many shapes and forms, but one thing we know is that we won’t give up on you as long as YOU don’t give up.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon – Fri: 6am – 10pm\nSat – Sun: 10am – 6pm",
          "strapLine1": "Home to some of the UK’s champion bodybuilders and other fitness athletes",
          "locations": [
            {
              "geolocation": [
                -0.33007820000000265,
                51.5875352
              ],
              "city": "Harrow",
              "postcode": "HA1 2RZ",
              "_id": {
                "$oid": "5a141ddc2288dd3a30f3f475"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Pure Muscles Gym",
          "phone": {
            "number": "2084248855",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Shop",
            "Supplements",
            "Olym Machines"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "pure_muscles_gym",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5898408d85706309618ceb5b"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:36:23.510Z"
      },
      "createdAt": 1486373005774,
      "email": "pmgwalthamstow@shoomon.xyz",
      "firstName": "Pure Muscles",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "YFCqRmPOBc7+LZF5K+VhsQ==",
      "hashedPassword": "EaEMUSJcL9TM/fYA+127xPK7Kh7dKMsW8DuQwGof8RqqW9SSkHblIOlTG9hVF69TnCLt1uMnjWGdt4psHKq66w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hOtCwv89-pmglogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Bodybuilding",
            "Boot Camp",
            "Diet & Nutrition",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.puremusclesgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2QELi4qh-pmgbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Family owned and run, the Pure Muscles Gym in both East London and West London are home to hundreds of members on a mission to meet their fitness goals and aspirations.\n\nBoth gyms are unique in the way they offer an atmosphere reminiscent of the famous Gold’s Gym in Venice Beach, California. We are home to some of the UK’s champion bodybuilders and other fitness athletes – you’ll also see the odd celebrity running around!\n\nWe welcome anyone and everyone at all levels of fitness. Whether you’re new to the gym or a seasoned gym-goer, there is an extensive range of cardiovascular machines, free weights and exclusive Olym weight training machines.\n\nFor years we have helped those in our local communities with their fitness goals. We offer qualified personal training, as well as expert nutrition and training advice to our members.\n\nWe pride ourselves in offering a home, mentorship and new direction for troubled youngsters and people of all ages in the community. Our efforts have also been recognized and awarded by the House of Lords for offering a really good sense of leadership and support for those who have truly benefited from our dedication to fitness and bettering of our community.\n\nWith years of experience, knowledge and dedication to anything and everything fitness, we make this promise – we will help you succeed and meet your goals.\n\nThe way in that is done comes in many shapes and forms, but one thing we know is that we won’t give up on you as long as YOU don’t give up.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon – Fri: 6am – 10pm\nSat – Sun: 10am – 6pm",
          "strapLine1": "Home to some of the UK’s champion bodybuilders and other fitness athletes",
          "yearsOfExp": 2012,
          "locations": [
            {
              "geolocation": [
                -0.0010690000000295186,
                51.5847935
              ],
              "city": "London",
              "postcode": "E17",
              "_id": {
                "$oid": "5a141dc72288dd3a30f3f474"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Pure Muscles Gym - Walthamstow",
          "phone": {
            "number": "2085209989",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Shop",
            "Supplements",
            "Olym Machines"
          ]
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "pure_muscles_gym_-_walthamstow",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "589843e585706309618cec1d"
      },
      "lastUpdated": {
        "$date": "2017-10-23T15:52:46.344Z"
      },
      "createdAt": 1486373861172,
      "email": "peakfitnesscentre@shoomon.xyz",
      "firstName": "Peak Fitness",
      "surname": "Centre Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "AFoKx2LOIX8qbbqJGbNMxA==",
      "hashedPassword": "/jHvyHHV58Yt1JWecDw/8MSI+JEKVWwPVlhEeH1qoTlYRZeuBUBq6RWCtsdy9/z5QLhclwGSesIQOdErI2ruEg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JawhXQu3-peakfitnesscentrehitchinlogo.png"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Corporate Services",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://peakfitnessgym.co.uk/#top",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YYU6pq2J-peakfitnesscentrehitchinbg.png",
            "size": {},
            "offset": 0
          },
          "biography": "Peak Fitness Centre in Hitchin, Hertfordshire caters for anyone over 13 years old, that wants to improve or maintain their physique, general health & lifestyle. The gym features specific weight training areas, cardio workout, separate women’s training area, classes & equipment for all stages of fitness, as well as experienced and friendly staff.\n\nOur fitness suite  is fully equipped with the latest Precore range and we also have cardiovascular equipment and a free weights area - also a functional trainer that will find any areas that you're wanting to work.\n\nClasses work well for those of us that need to be pushed or just love the social aspect of working in a group, with a full timetable of legs bums and tums,studio  pump ,circuits, boxing blast, pilates and many more. We look forward to showing you what makes us different.\n\nPeak has always been associated with bodybuilding and understandably the new site has an amazing space that has been our flagship, and we are very proud of our new space. We have designated coaches that are accredited and experienced in helping you get your goals.The gym itself is a well equipped with plate loaded and pin loaded equipment plus free weights.\n\nWe are confidant and very proud  in saying we have the most comprehensive array of equipment in the area.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours: \n\n\nMONDAY 6:00 am - 10:00 pm\nTUESDAY 6:00 am - 10:00 pm\nWEDNESDAY 6:00 am - 10:00 pm\nTHURSDAY 6:00 am - 10:00 pm\nFRIDAY 6:00 am - 10:00 pm\nSATURDAY 8:00 am - 6:00 pm\nSUNDAY\t9:00 am - 4:00 pm",
          "locations": [
            {
              "geolocation": [
                -0.2638521999999739,
                51.9662704
              ],
              "city": "Hitchin",
              "postcode": "SG4 0TP",
              "_id": {
                "$oid": "59ee104e94e4d86bf9be1acd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Peak Fitness Centre",
          "phone": {
            "code": "+44",
            "number": "1462456222"
          },
          "qualifications": [
            "Ladies Only Area",
            "Creche",
            "Day Pass",
            "Free Weights Selection",
            "Cardio Stations",
            "Resistance Equipment"
          ],
          "strapLine1": "Fitness: Dreams Don't Work Unless You Do"
        },
        "score": 65
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "peak_fitness_centre",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58984c4485706309618ceddf"
      },
      "lastUpdated": {
        "$date": "2017-10-23T15:39:44.221Z"
      },
      "createdAt": 1486376004377,
      "email": "thefitnesspodmdsex@shoomon.xyz",
      "firstName": "The Fitness",
      "surname": "Pod Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "RMoLuS8qfW1HNFTwvvwVmw==",
      "hashedPassword": "uK2lx4mNjBmVqfN0zJ3YORU/ViV14jr/k190gqKY7Ae2MJajyI6Xae9m06u2MHr/V0o6qRH56S+U5sbpQZOdvw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AZL7qJBS-mdxlogo.png"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.mdx.ac.uk/life-at-middlesex/sport/sports-facilities/our-gym",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MEzXf6Ht-mdxbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "The Fitness Pod at Middlesex University\nThe Fitness Pod gym and changing facilities have recently been completely refurbished. The updated gym has the latest equipment by market leaders Pulse Fitness, incorporating an exciting new Multi-Functional rig alongside our updated range of static machines and free weight areas. The changing facilities in MDX House have also been refurbished with new showers, lockers and vanity areas.\n\nThe Fitness Pod is our fitness facility right here on campus. We offer no joining fee, no contract, no hassle memberships from as little as just £12 per month. We have a variety of flexible membership options, so there's something for everyone.\n\nA complementary range of therapies and treatments are available to students, including massage, as well as personal training, leisure classes and more.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMonday - Friday 6.45am -10.00pm\nWeekend 9.00am - 5.00pm\nLadies only Mondays and Thursdays 10.00 - 11.00am",
          "strapLine1": "No joining fee, no contract, no-hassle memberships for students, their families and the community",
          "locations": [
            {
              "geolocation": [
                -0.22844110000005458,
                51.5897139
              ],
              "city": "London",
              "postcode": "NW4 4BT",
              "_id": {
                "$oid": "59ee0d4094e4d86bf9be1aca"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Fitness Pod - Middlesex University: Hendon",
          "phone": {
            "code": "+44",
            "number": "2084116343"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Functional Training Rig",
            "Resistance Equipment",
            "Pulse Fitness Equipment",
            "Changing Facilities",
            "Complimentary Amenities",
            "Supplements"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_fitness_pod_-_middlesex_university:_hendon",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "589852a485706309618cef61"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:34:09.358Z"
      },
      "createdAt": 1486377636153,
      "email": "allaboutme@shoomon.xyz",
      "firstName": "All About Me",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "97gu7c76BRZddFOJujHVOw==",
      "hashedPassword": "nevs/8DwoKRbsPMODCDOnKqCKCs9eFX2Z8N2w1Znr8/afUaE1+B4wnV31B6xzp7hLkUITZDbr73ys8wXJyg/CA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JjX7P3No-allaboutmelogo.png"
        },
        "personal": {
          "disciplines": [
            "Boot Camp",
            "Diet & Nutrition",
            "Fitness Classes",
            "Sports Therapy & Rehab",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://allaboutmefitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/s3kRpXMZ-allaboutmebg.png",
            "size": {},
            "offset": 0
          },
          "biography": "Founded in May 2015, the owners of All About Me fitness set out to create a very different fitness product. With over 12 years experience in the fitness industry, Andy Plant (co-founder) set out to produce a welcoming gym that everyone could feel a part of and comfortable training in.\n\nThe club opened its ladies circuit on May 5th 2015 and since then has undergone a huge transformation in terms of the services it provides.\n\nThe introduction of the Bootcamp and Class timetable is now supported by The Helping Hand Program, meaning All About Me Fitness is not only leading the way in its welcoming approach but also in the overall customer service experience and support within the gym.\n\nThe ultimate aim is to provide a personal experience to every member, offering the same kind of accountability, knowledge and support you would expect from a personal trainer or slimming group.\n\nWe understand that sometimes going to the gym can be quite a daunting experience, this is why we pride ourselves in our warm and friendly welcome to everyone that comes through our doors.\n\nEnjoying your gym experience and feeling comfortable is paramount to both yours and our success, so we strive to make all our members feel a part of the gym. We do our utmost to boost the community feel and create a happy, supportive and friendly atmosphere.\n\nYour results are a sign of our success, this means that we put your goals as number 1 on our list.",
          "yearsOfExp": 2015,
          "strapLine1": "We’re Not Just Another Gym…Our Lifestyle Studio Provides A Relaxed And Friendly Atmosphere.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMonday – Friday: 8.45am – 6.30pm\nSaturday: 9.00am – 12.00pm",
          "locations": [
            {
              "geolocation": [
                -0.6635885000000599,
                51.9162515
              ],
              "city": "Leighton Buzzard",
              "postcode": "LU7",
              "_id": {
                "$oid": "5a141d412288dd3a30f3f473"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "All About Me Fitness",
          "phone": {
            "number": "1525219800",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Free Weights Selection"
          ],
          "testimonials": "“I Joined All About Me Fitness because after 2 hip replacements I needed to build muscle strength to support my joints. The coaches there are amazing and sympathetic of my situation. We started lightly and as I got fitter they encouraged me to do more. Everyone is just so friendly!!” – Ruth\n\nFrom the moment I walked into the gym I knew I was in the right place, as the coaches were so friendly and welcoming. I started to attend the Bootcamp sessions three times a week and with the help and guidance I shed Two stone in the first 8 weeks. I continued attending regularly and eating healthy, I have managed to maintain my weight and toned body. It’s the best decision I’ve made! – Sabrina\n\nI started training with All About Me Fitness just over a year ago to get me in shape for my wedding. The friendly approach means I can really enjoy the sessions qne I don’t feel pushed past my limits. My PT group became more like friends, whilst my results have remained consistant. I’ve even enjoyed a social life whilst still losing weight and I’ve never felt guilty for enjoying the odd treat. – Michelle"
        },
        "score": 71
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "all_about_me_fitness",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "5898575f85706309618cf063"
      },
      "lastUpdated": {
        "$date": "2017-11-21T19:46:09.473Z"
      },
      "createdAt": 1486378847489,
      "email": "oxygenfit@shoomon.xyz",
      "firstName": "Oxygen Fit",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "UCpffrfvh79hEKDonLtv4g==",
      "hashedPassword": "Fxh1NOSU6zxadv2dTyILUZL4wnQaSwJImbt6oSX0TwyelETz9q5nYrDBO8Pju3Eo8pva1Qy68wIJ2O7pcXXs0A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LeglyLpP-oxygenfitlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Personalised Programmes",
            "Small Group Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.oxygen-fit.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FmM3peMt-oxygenfitbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Oxygen Fit is a multi-award-winning gym:\n\n'Best New Gym', Winner, National Fitness Awards\n'Best International Gym Team', Winner, NPE\n'Best Member Achievement', Runner up, National Fitness Awards\n'Best Community Gym' Runner up, National Fitness Awards\nFor providing best service from a welcoming team of proactive staff who care about your results; where you’re known by name and not just number.\n\nUnlike many local gyms who sign you up then leave you to your own, don't provide a plan for you and don’t care about your results, where you are just another number, our Gym Membership services focus on knowing what you want, designing a plan to get you there, and we work together to achieve your goals.\n\nAs a member you will receive the following benefits when using our facility:\n\nNewly refurbished (2014) Extensive range of cardiovascular fitness equipment\nFree studio classes including; Zumba, Yoga, Belly Dancing, Pump, Step, Boxing, Aerobics\nPilates Circuits, Abs Blast, LBT and various body conditioning classes\nFree weights and resistance floor for to help you get the perfect shape muscle tone\nPunch bags, boxing area with boxing equipment, including boxing gloves, boxing pads, boxing classes\nFull tailored induction focussing on your current fitness needs/goals with a personalised programme\nFrequent fitness programme reviews to keep your body challenged\nPower plate\nPower showers and fully air conditioned facility\nPersonal Training and Nutritional Courses also available",
          "yearsOfExp": 2010,
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 6:00am - 10:00pm\nFri: 6:00am - 9:00pm\nSat & Sun: 8:00am - 6:00pm",
          "strapLine1": "The most friendly, non-intimidating, proactive family-run local gym, with tried & tested methods proven to get results.",
          "locations": [
            {
              "geolocation": [
                -0.15676970000004076,
                51.6352535
              ],
              "city": "London",
              "postcode": "EN4 8XE",
              "_id": {
                "$oid": "5a1482812288dd3a30f3f4e0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Oxygen Fit Gym - East Barnet",
          "phone": {
            "number": "2083683715",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Punch Bags",
            "Fitness Studio",
            "Air Conditioning",
            "Power Plate",
            "Changing Facilities",
            "Spa Pool",
            "Power Showers"
          ],
          "testimonials": "'Best New Gym', Winner, National Fitness Awards\n'Best International Gym Team', Winner, NPE\n'Best Member Achievement', Runner up, National Fitness Awards\n'Best Community Gym' Runner up, National Fitness Awards\n\nLucy, Age 28, Accountant\n\nI joined the programme because I wanted change; was fed up being overweight, unhealthy and making excuses.  During the 28 Day Programme I achieved an amazing weight loss of 15 lbs, had more energy, my skin was fresher and was happier in myself.\nIn my 2nd month I lost another 6 lbs!  When I first joined, my goal was to lose 2 stone in 10 months; I’ve already done that and some!  I feel so happy about my results. I never thought there would be this much change in such a short time and doing it in a health way.  I love having programme and a trainer; if you’re feeling down or de-motivated the trainers are always there to pick you back up and get you on track.\nThrough the programme I’ve made great friends and we encourage each other to keep going. If you’re thinking about joining the programme, stop thinking and start doing!!"
        },
        "score": 71
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "oxygen_fit_gym_-_east_barnet",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58985c7685706309618cf165"
      },
      "lastUpdated": {
        "$date": "2017-10-23T14:32:26.104Z"
      },
      "createdAt": 1486380150576,
      "email": "pumpedgym@shoomon.xyz",
      "firstName": "Pumped Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "uKIVF2EsrPfZgRLiJZHYvQ==",
      "hashedPassword": "3KSEkQtmO0aiGDB+pn04nLBcNeBDbPVkgr4/D1b5wcshsJtUDUUUI9dPgTyf+WANUH1a8OzyAkem/0OsiAEfsw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uylVSsf8-pumpedpro.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Ladies Only Classes",
            "Personal Training",
            "Sports Massage"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://pumpedgym.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ev4ZX50o-pumpedbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Work Out - Feel Good - Look Great: A number of membership options to suit every lifestyle.",
          "biography": "All three owners are genuinely passionate about helping you to achieve your fitness goals…\n\nWhich is why we are constantly investing in the very best equipment the fitness industry has to offer and training for all of our team to guarantee that you achieve your fitness goals.\n\nFrom the minute you first step into the gym you’ll see, feel and hear what a great atmosphere we have here at pumped gym, from the warm welcome at the door to your fellow members and all of the Pumped gym team members.\n\nWant to be in shape for a special event? then working 1-2-1 with one of our handpicked personal trainers will guarantee you achieve your goal by using their vast knowledge of what works for your aims and goals, but be warned, they want you to win as much as you do so they’ll push you that extra step beyond where you normally quit, so that when the day comes you know you’re going to feel good and look great!\n\nYou’ll quickly learn that our mission is to take you from where you are now to the body and fitness level you want to achieve in a program that is custom built to suit how you want to train when you want to train…\nWe do this by finding out as much as we can about what is important you, what training you’ve done in the past, where you are now and more importantly how you want your body to be from here on.\nThen we dig deep, into your diet, how you like to train, how often you ‘really’ are willing and want to make it to the gym on a weekly basis so that we can build you a great program",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nOpening Hours\nMonday - Friday: 6:00am to 10:00pm\nWeekend and Bank Holidays: 8:00am to 4:00pm",
          "locations": [
            {
              "geolocation": [
                -0.20739320000006956,
                51.91391019999999
              ],
              "city": "Stevenage",
              "postcode": "SG1 3QP",
              "_id": {
                "$oid": "59edfd7a94e4d86bf9be1abf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Pumped Gym",
          "phone": {
            "code": "+44",
            "number": "1438317772"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Plate Loaded Machines",
            "Nutrition Centre",
            "Supplements",
            "Sports Massage",
            "Teeth Whitening"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "pumped_gym",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "5898610385706309618cf267"
      },
      "lastUpdated": {
        "$date": "2017-11-28T10:38:15.853Z"
      },
      "createdAt": 1486381315280,
      "email": "xchange@shoomon.xyz",
      "firstName": "Xchange",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "8edO6V8+MmnzPs7ZH3e/mg==",
      "hashedPassword": "RbVo1D17no6LCvg3srXxXeD3kohQ6roUnsdmg716HEMbLNOgyxkUPVSaOdTXrWXwruG3LKbXj/Oslm9V7tZ4jw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lp3MJSGt-xchangelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet/Meal Plans",
            "Fitness Classes",
            "Personal Training",
            "Personalised Programmes",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://xchange-fitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YvBiQlvD-xchangebg.png",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Xchange Fitness:\n\nFirstly and most importantly we have an exceptionally talented team of Gym Instructors who have been trained and qualified to the highest standard to attend to your every training and fitness needs. They are highly motivated individuals who have been carefully selected to ensure we offer a broad range of knowledge, skills and services, including but not limited to: personal training, diet plans, rehabilitation, training programmes, sport injury massages, general fitness advice and supplement advice.\n \nSecondly we have an excellent team working our reception. They will make you feel welcome when you enter the fitness centre. They will look after your gym membership and after-sales service, general gym enquiries, over the counter sales, class bookings, and much more.\n \nThe owners at Xchange Fitness have many years experience in the fitness industry. With backgrounds in weight training, bodybuilding, boxing training, motivational techniques, fitness training, boot camp training, abs and core training and old style training techniques, we feel we have everything covered.\n \nWe also have a first class team of support staff that ensure the gym is always presented to our members and guests in the best possible condition.",
          "strapLine1": "THink fit! Get fit! Stay fit! At Xchange Fitness Gym",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMonday - Thursday 6.30am - 10.00pm\nFriday - 6.30am - 9.00pm\nSaturday - 8.00am - 6.00pm\nSunday - 8.00am - 6.00pm",
          "locations": [
            {
              "geolocation": [
                -0.2753545000000486,
                51.9472421
              ],
              "city": "Hitchin",
              "postcode": "SG4",
              "_id": {
                "$oid": "5a1d3c977d852e353acb6399"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xchange Fitness",
          "phone": {
            "number": "1462437757",
            "code": "+44"
          },
          "qualifications": [
            "Free Weights Selection",
            "Cable Machines",
            "Cardio Stations",
            "Resistance Equipment",
            "Tanning Facilities",
            "Punch Bags"
          ],
          "testimonials": "You’re the best                                        \n\nThe best and friendliest gym in Hitchin. The instructors really push you to your limits!\nJohn Wilkes\n\nLove the Cardio\n\nI didn’t think I was a ‘gym person’ but now having Lisa as my Personal Trainer, I really love it and I’m amazed at how quickly I’ve seen a big difference!\nMichelle Allen"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "xchange_fitness",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "589864b985706309618cf329"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:21:00.737Z"
      },
      "createdAt": 1486382265571,
      "email": "pumpingiron@shoomon.xyz",
      "firstName": "Pumping Iron",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "nElEw3z5LNmgkO1gcMe5nA==",
      "hashedPassword": "X2xO1H5rvX9AEPOUPRgyboB07Ja8BhTtJAikZHrQnmY0hBn9OZ2ZeoLxvCzphEqYQ83M70A8ZUxAkRWjzP1o6A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jLXMdBnd-pumpingironpro.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Diet & Nutrition",
            "Personal Training",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.pumpingironfitnessgym.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uPi73iK0-pumping%20ironbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Instructors at hand for help & advice plus personal training & eating plans.\n\nDr Zak a qualified NABBA Instructor/Trainer & holder of many body building titles, such as Mr Cyprus & Mr London, Mr Britain,  Jay Cutler Classic champion, a vailable to assist and advise whether you want to build or slim down or maintain a high level of fitness.\n\nAlso high quality products for pre-worked out and post work out with leading brands like Dr Zak’s products, Dorian Yates and Multipower.\n\nDr Zak’s Pumping Iron Gym offers services for anyone trying to loose weight or gain lean muscle. The gym is on two floors offering equipment for both male & female use. Our friendly family atmosphere  is welcoming  for everyone looking to adopt a healthy lifestyle.\n\nSome of our equipment includes:\n\n• Lifefitness Treadmills\n• Lifefitness Cross trainers\n• Lifefitness Steppers\n• Lifefitness Bikes\n• Concept II rower\n• Full range of Technogym weight assisted machines\n• Hammer Strength, Free Weight & Olympic equipment\n• Fully air conditioned, plasma screens, coffee area, protein drinks\n• Full range of various supplements available, DR Zak’s protein bread, pasta and coconut oil\n• Massage therapy\n• Pumping Iron clothing and merchandise",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nMon - Fri: 9 a.m. - 9.30 p.m.\nSaturday: 9 a.m. - 4 p.m.",
          "strapLine1": "The home of champions",
          "locations": [
            {
              "geolocation": [
                -0.14418269999998756,
                51.6179158
              ],
              "city": "London",
              "postcode": "N11 1LD",
              "_id": {
                "$oid": "5a141a2c2288dd3a30f3f471"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Dr Zak's Pumping Iron Gym - Arnos Grove",
          "phone": {
            "number": "208368536",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Sprint Track",
            "Olympic Lifting",
            "Air Conditioning",
            "Cafe",
            "Supplements",
            "Pumping Iron Merchandise"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "dr_zaks_pumping_iron_gym_-_arnos_grove",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5898693c85706309618cf42b"
      },
      "lastUpdated": {
        "$date": "2017-10-23T13:35:44.928Z"
      },
      "createdAt": 1486383420731,
      "email": "bunkergym@shoomon.xyz",
      "firstName": "The Bunker",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "HmMcovhImfA/+s2zO8yMyg==",
      "hashedPassword": "qTRPufxFTpE2XaD7FFziJYLt0AUeGC9Wo8nn2Kt+rtQF+bygExqUGJSM1srVmSV8fbZytxNWvA0+qz4HUOy6mQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/65IFZhD0-bunkerlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Fitness Classes",
            "Partner Training",
            "Personal Training",
            "Small Group Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://thebunkergym.co.uk/#the-bunker",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rB6XmtkO-bunkerbg2.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Get moving and sweat it out in our challenging but fun workout classes. Build strength and sculpt your body in our weights section. Push beyond your plateau with a personal training programme.\n\nThe Bunker’s camaraderie makes it easy to stay motivated, whatever your goals or fitness level. We also run boxing training for under 12s and a friendly morning class aimed at mums.\n\nHidden in a restyled WWII bunker, the gym is a few minutes walk from Dollis Hill and Willesden Green tube stations.\n\nThe Bunker’s signature sweat-fest in a friendly group setting. Constantly changing, mixing up boxing and circuits in high intensity interval training (HIIT) sessions. Three to five of these a week will get you in the best shape of your life. \n\nThe latest addition to the Bunker: a high-energy low-impact bike-based workout - these classes will get you sweating hard, shredding calories and your heart pumping along to some solid beats. Strap in and get ready for an exhilarating ride.\n\nJUNIOR BOXING: AFTER SCHOOL AT THE BUNKER\n\nBoxing training for all-round fitness that builds endurance, resilience, agility, balance and co-ordination, so you can take on whatever the future may throw at you. \n\nBespoke sessions planned out to help make your fitness goals reality. One hour of one-on-one knowledgeable instruction including warm-up and cool-down, either at The Bunker, a park of your choice, or at your home. You'll also be given tailored nutritional advice to help you achieve your fitness goals.",
          "offers": "Please visit our website for detils of prices, packages and offers.\n\nOpening Hours:\n\nMonday to Friday 5 - 8.30pm \nSaturday 11am - 2pm",
          "locations": [
            {
              "geolocation": [
                -0.2276824000000488,
                51.555046
              ],
              "city": "London",
              "postcode": "NW2 6BH",
              "_id": {
                "$oid": "59edf03094e4d86bf9be1ab2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Bunker Gym - nr Cricklewood",
          "phone": {
            "code": "+44",
            "number": "7792579334"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Limited On Site Parking"
          ],
          "strapLine1": "Power To Perform"
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_bunker_gym_-_nr_cricklewood",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58986ca385706309618cf4ed"
      },
      "lastUpdated": {
        "$date": "2017-10-23T13:17:40.660Z"
      },
      "createdAt": 1486384291949,
      "email": "justforwomen@shoomon.xyz",
      "firstName": "Just For",
      "surname": "Women Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "G/BzXgkuNf7L4sjTomnlKA==",
      "hashedPassword": "nhrdud3/QqstxcetKtpwmjzjdEfw3Ed6njamqqzYtRCUUjrRqQe4icJbhonIAhRInbq1XLUchHqHBYVLqRAP5w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5sufrWgi-justforwomenlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Fitness Classes",
            "Ladies Only Gym",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.justforwomen.fitness/home/4590669080",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IsahbTOX-justforwomenbg2.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Just For Women is North Hertfordshire's premier ladies only gym, based in Stevenage. We offer a friendly and welcoming service to all our members and are here to help you achieve your goals.\n \n \nWant to exercise at a gym but feel that conventional gyms are intimidating?  Find that going to a conventional gym is not producing the desired results...then Just For Women is the gym for you.\n\nWe offer a complete range of both cardio and resistance gym equipment specifically chosen with ladies in mind. There is a separate area for fitness assessments, stretching and abdominal work and two studio rooms in addition to that! We pride ourselves on the care and attention our trainers provide to our clients and the relationships and community amongst our members.\n \nWhether you want to burn some fat using the cross-trainers, or condition your body with resistance training, you can be sure our ladies only gym will help you achieve the results you want. Our growing class list is second to none and we also offer small-group training fostering our friendly inclusive environment and keeping motivation levels high!\n \nAll classes are included within memberships. All of our members receive a one-to-one personalised induction programme and regular reviews with a quality Instructor, all inclusive.  So...why not sign up today!",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nSunday 08:00 - 13:00\nMonday 07:30 -20:30\nTuesday 08:00-20:30\nWednesday 07:30-20:30\nThursday 08:00-20:30\nFriday 07:30 -20:30\nSaturday 08:00 - 13:00",
          "locations": [
            {
              "geolocation": [
                -0.17080510000005233,
                51.9251356
              ],
              "city": "Wedgewood Way",
              "postcode": "SG1 4SU",
              "_id": {
                "$oid": "59edebf494e4d86bf9be1aae"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Just For Women Gym & Fitness Club - Stevenage",
          "phone": {
            "code": "+44",
            "number": "1438725076"
          },
          "qualifications": [
            "Creche",
            "Changing Facilities",
            "Cafe",
            "Free Parking",
            "Cardio Stations",
            "Resistance Equipment",
            "Fitness Studio",
            "Fitness Assessments",
            "Mat Area",
            "Supplements"
          ],
          "strapLine1": "We are North Hert's Non-Intimidating Women's Only Fitness Centre!"
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "just_for_women_gym_and_fitness_club_-_stevenage",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "5898707f85706309618cf5af"
      },
      "lastUpdated": {
        "$date": "2017-10-23T13:00:24.857Z"
      },
      "createdAt": 1486385279022,
      "email": "renegade@shoomon.xyz",
      "firstName": "Renegade",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "D+qjwwrKmpdnuYzDgVVH9A==",
      "hashedPassword": "8lsjSEmeY2wfaI1IV0mgTeZVVr0McqrwS2SVltDznFMGsRJbrWQaNrWwYgmGLtCQGHSpEa5wbkyMgbpEZXBUdw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1llairAm-renegade%20pro.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Boxing",
            "Kickboxing",
            "MMA (Mixed Martial Arts)",
            "Muay Thai"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://www.renegadefitnessacademy.co.uk/",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 149
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OZRk8xxC-renegade%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Fully Equipped Gym & Martial Arts Centre",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMon - Thurs: 6:00am - 10:00pm\nFri - 6:00am - 9:00pm\nSat & Sun: 8:00am - 4:00pm",
          "biography": "Renegade Fitness Academy is a 10,000 square foot gym and Martial Arts Academy spread over 2 floors. The ground floor is a 5000 square foot commercial gym, equipped with cardio machines, free weights, plate loaded and resistance machines.\nThe first floor is a 5000 square foot Martial Arts academy equipped with 2 Boxing rings, MMA cage, 16 Boxing bag stations and a large matted area.\nRenegade Fitness Academy  offers a variety of memberships and classes in Muay Thai, Kickboxing, Boxing, MMA and BJJ as well as Bootcamps and an array of Fitness Classes.\nThis is the only gym of its kind in Hertfordshire and there will be very few like this in the UK!",
          "locations": [
            {
              "geolocation": [
                -0.17141800000001695,
                51.9275909
              ],
              "city": "Stevenage",
              "postcode": "SG1 4QS",
              "_id": {
                "$oid": "59ede7e894e4d86bf9be1aaa"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Renegade Fitness Academy",
          "phone": {
            "code": "+44",
            "number": "1438727833"
          },
          "qualifications": [
            "Martial Arts Academy",
            "Plate Loaded Machines",
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Boxing Ring",
            "MMA Cage",
            "Punch Bags",
            "Mat Area",
            "Highly Experienced Coaches and Personal Trainers"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "renegade_fitness_academy",
      "feature_in_area": "none",
      "__v": 16
    },
    {
      "_id": {
        "$oid": "58987ad9d3b4b20171ddbff6"
      },
      "lastUpdated": 1486387975169,
      "createdAt": 1486387929959,
      "providerId": "117782686341738480810",
      "provider": "google",
      "surname": "FitBiz",
      "firstName": "GrowYour",
      "email": "clcsportscentre@gmail.com",
      "settings": {
        "personal": {
          "google": "https://plus.google.com/117782686341738480810",
          "disciplines": [],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": null,
          "profileTitle": "General",
          "radius": 0,
          "price": {
            "min": 43,
            "max": 73
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -2.0820819000000004,
                51.8980305
              ],
              "country": "United Kingdom",
              "city": "Cheltenham",
              "postcode": "GL50 3AA"
            }
          ],
          "companyName": null,
          "phone": {
            "code": "+44",
            "number": "1242707484"
          }
        },
        "wizard": {
          "complete": false,
          "start": true,
          "additional": false,
          "social": false,
          "service": false,
          "place": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zEHPB6Kz_profilePicture.png"
        },
        "score": 37
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "growyour_fitbiz"
    },
    {
      "_id": {
        "$oid": "58987b38d3b4b20171ddbff8"
      },
      "lastUpdated": {
        "$date": "2017-10-23T11:28:14.837Z"
      },
      "createdAt": 1486388024887,
      "email": "igym@shoomon.xyz",
      "firstName": "iGym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ijmOEmcYpNp7roZ6Y6FVKw==",
      "hashedPassword": "qhwcyV4Gsbsry9M3X/nuhkyj/XEcNM2dP92+iMhcOk2icVLns07WG7Sxd1kA4EmZRJ+MHb0wCkgycOSUraVg6g==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/g2L6R98t-igymlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "companyWebsite": "http://igym.london/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "price": {
            "min": 1,
            "max": 129
          },
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xdBbN5gO-igymbg2.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "iGym is committed to deliver a personalised fitness experience for every member via our innovative mix of Pulse Fitness equipment and unique fitness tracking technology. \n\nPlus, our support doesn’t stop there we will do whatever we can to help you in your fitness journey; whether it be nutritional advice or guidance on which exercise will best help you in achieving your goal.\n\nWe all know that keeping fit is an important part of a healthy lifestyle and here at iGym we strive to help you find the right balance between training and motivation.\n\nOur 80 station gym offers all the latest Pulse Fitness equipment to keep you driven and challenged during every visit.\n\nYou will be able to track any workout you do on any of iGym’s cardio and resistance machines through PulseMove, the digital fitness tracker software offering a complete training programme, inside the gym and out.\n\nOur expansive, air conditioned Group Fitness Studio provides the perfect environment for you to keep fit in with our wide range of group exercise classes. Our classes cater for any ability, from beginners through to advanced levels.\n\nOur programme is reviewed regularly to ensure that we are offering the right classes at the right times for our members.\n\nAs part of our Group Exercise Timetable we are proud to be offering branded classes as part of our class portfolio. The Les Mills group exercise programme, well known for its BODYPUMP, GRIT and BODYBALANCE classes, will be available to you as an iGym member.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening hours:\n\nMonday – Thursday: 06:00 - 22:00\nFriday: 06:00 - 20:30\nSaturday – Sunday: 09:00 - 20:00",
          "strapLine1": "A state-of-the-art 80 station gym and group-exercise studio",
          "locations": [
            {
              "geolocation": [
                -0.26262900000006084,
                51.5226208
              ],
              "city": "London",
              "postcode": "W3",
              "_id": {
                "$oid": "59edd24e94e4d86bf9be1a90"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "iGym - Imperial College Acton Campus",
          "phone": {
            "code": "+44",
            "number": "2089928605"
          },
          "qualifications": [
            "Cycle Studio",
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Pulse Fitness Equipment",
            "Fitness Studio",
            "Air Conditioning"
          ]
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "igym_-_imperial_college_acton_campus",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "589a4514bec6935493e17698"
      },
      "lastUpdated": 1486505291201,
      "createdAt": 1486505236892,
      "email": "webb_nicola@hotmail.com",
      "firstName": "Nicola",
      "surname": "Webb",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "86a0b0efa04eca18594877f488f9316e1d1d2f1a",
      "salt": "uuBEZSVQTP8NBtb7chHBuA==",
      "hashedPassword": "eK9KPaFHojbJKGg/O0eVk7Z3jxEbZCnCzDQgTLOmkV8Jd/BQkz7Gl9izGSzG8sYWK+tdrfiN64Pi7Ockafvoug==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 12,
            "max": 30
          },
          "companyWebsite": null,
          "profileTitle": null,
          "radius": 15,
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -2.8357138484742825,
                53.193752244164415
              ],
              "country": "United Kingdom",
              "city": "Littleton",
              "postcode": "CH3 7DL"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7552952695"
          }
        },
        "score": 28
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "nicola_webb"
    },
    {
      "_id": {
        "$oid": "589b074dbec6935493e1a01a"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:10:04.569Z"
      },
      "createdAt": 1486554957870,
      "email": "pride@shoomon.xyz",
      "firstName": "Pride",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ezEKsPZleUMwye4NcTNp6Q==",
      "hashedPassword": "cps60F2KwQlV9Va0YY95j7ibSeh405EcU9BYqpyf04h0huQ618e0Sq3bM8MYicpodgGavkiqU/zlpaX0Bnlqxg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aVwTjY40-pridelogo.png"
        },
        "personal": {
          "disciplines": [
            "Biomechanics",
            "Boot Camp",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.pridefitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IhlJ7Kwj-pridebg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "biography": "Pride Fitness provides the best possible place to reach your fitness goals at a great value price. Award winning and nationally nominated, Pride Fitness is staffed by approachable fitness professionals whose main priority is to offer the BEST place for all members and guests to achieve their goals.\nPride Fitness offer state of the art equipment including a large selection of cardiovascular machines, fixed weight machines for every body part, top of the range plate loaded equipment and a well equipped, separate free weights area.\nWe also offer a busy class timetable, spread across 2 studios. Our timetable is jam packed with the latest releases from Les Mills, including BODYPUMP, as well as club favourites such as indoor cycling and bootcamp.",
          "offers": "Please visit our website for details of our prices, packages and offers.\n\nMon - Fri: 6:00am - 10:00 pm\nSat,  Sun & Bank Holidays: 8:00am - 6:00pm",
          "locations": [
            {
              "geolocation": [
                -0.23199650000003658,
                51.9800933
              ],
              "city": "Letchworth Garden City",
              "postcode": "SG6 4ES",
              "_id": {
                "$oid": "5a14179c2288dd3a30f3f470"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Pride Fitness",
          "phone": {
            "number": "1462476123",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Cable Machines",
            "Functional Training Zone",
            "Kettlebells",
            "ViPR",
            "Plate Loaded Equipment",
            "No Contract Membership Available",
            "Guest Pass",
            "Day Pass"
          ],
          "strapLine1": "No Contract Memberships Available"
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "pride_fitness",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "589b14eabec6935493e1a2dd"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:09:26.707Z"
      },
      "createdAt": 1486558442854,
      "email": "aidenjones@shoomon.xyz",
      "firstName": "Aiden Jones",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "whwiy8DEhEEmhZ6rgUmh5g==",
      "hashedPassword": "9Mh9LTXBZVXPDh9Jd/qSZWVuSnKDyI5kHRiEtYWGX7AN1XekVi34FyJZTw/Wrgrrk7vrZkIhv5XHY/RzS64fyA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/e0QaGkyr-aidenjpro.jpg"
        },
        "personal": {
          "disciplines": [
            "Boot Camp",
            "Boxing",
            "Diet & Nutrition",
            "Kids Classes",
            "Personal Training"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.aidenjones.co.uk/index.php",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/67TuTBKU-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Change your life -  be our next success",
          "biography": "Weight loss can be a daunting journey to begin, which is why undertaking it with a mentor is the best way to go. \nThe trainers at Aiden Jones Weight Loss & Fitness are always available via phone or email with any questions that you have. \nTraining sessions are made fun and can be done anywhere that suits you, whether that's in the comfort of your own home, in the gym or down the park. \nAgain, what works for one person might not work for another, which is why the sessions will be designed entirely around you, focusing on the things you enjoy. \nSo ask yourself what would you do with the best body of your life and book yourself a session today.\n\nHealth and Fitness means different things to different people, which is why training sessions need to be tailored to the individual. It's not just a question of doing x amount of work three times a week. It's needs to fit in with your lifestyle, which in turn leads to it becoming your lifestyle. \nAll the trainers at Aiden Jones Weight Loss & Fitness understand this which is why you won't find a training system better suited to you anywhere else. \nAll training methods are bang up to date making sure you get the most out of the time you have to spend exercising. Trainers are always available via email and text to ask any questions you have.",
          "locations": [
            {
              "geolocation": [
                -0.2639434000000165,
                52.0869937
              ],
              "city": "Biggleswade",
              "postcode": "SG18 0JH",
              "_id": {
                "$oid": "5a1417762288dd3a30f3f46f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Aiden Jones Weight Loss & Fitness",
          "phone": {
            "number": "1767316367",
            "code": "+44"
          },
          "offers": "Please visit our website for details of prices, packages & offers.\nOpening Hours:\n\nMon - Fri: 6:00am - 9:00pm\nSat & Sun: 7:00am - 3:00pm",
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment"
          ]
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "aiden_jones_weight_loss_and_fitness",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "589b1cb7bec6935493e1a4a1"
      },
      "lastUpdated": {
        "$date": "2017-10-20T18:08:38.960Z"
      },
      "createdAt": 1486560439483,
      "email": "fightingfit@shoomon.xyz",
      "firstName": "Fighting Fit",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "yY9oC6o9ihLhpO+Yl6cjHw==",
      "hashedPassword": "NIaR6SCxjdoQe/pruGfsE34Tlv17R5NI+ENikZQxFBWWTwiCK7YndlBdwtGW4MpdkTFbngv4Nx201dPdG0bBxg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9ehO1YSh-fightingfittorfaenlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Academy",
            "Fitness Classes",
            "Power Lifting",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.fightingfitgym.com/index.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FU2ykTs4-fightingfitbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Fitness Gym & Martial Arts Academy",
          "biography": "Our facility is built on a highly supportive, judgement free environment\nOur team consists of highly educated and expereinced coaches\nWe build programmes around YOU, the individual\nWe use the most up to date, cutting edge training methods\nMost Importantly...with us, you'll get RESULTS!\nAs a fitness facility, we are also community-based, aiming to promote physical activity to improve the quality of people's lives by getting them real results, fast. We are also aware that talk is cheap, so please take the time to visit our testimonials page to see what our clients and members say about us as well.\n\nWe are proud of what we do here and feel that if given a chance, you'll want to do business with us. We're proud to be a locally owned and operated, and look forward to helping you reach your fitness goals soon.",
          "offers": "Please visit our website for prices, packages and offers.\n\n Gym Opening Times\nMONDAY\n7am - 1pm / 4pm - 9pm (last entry 8:15pm)\nTUESDAY\n7am - 1pm / 4pm - 9pm (last entry 8:15pm)\nWEDNESDAY\n7am - 1pm / 4pm - 9pm (last entry 8:15pm)\nTHURSDAY\n7am - 1pm / 4pm - 9pm (last entry 8:15pm)\nFRIDAY\n7am - 1pm / 4pm - 9pm (last entry 8:15pm)\nSATURDAY\n9am- 1pm (last entry 12:15pm)\nSUNDAY\nClosed",
          "locations": [
            {
              "geolocation": [
                -3.0253155000000334,
                51.686249
              ],
              "city": "Griffithstown",
              "postcode": "NP4 5HY",
              "_id": {
                "$oid": "59ea3ba694e4d86bf9be1a6c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fighting Fit",
          "phone": {
            "code": "+44",
            "number": "1495769104"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Free Weights Selection",
            "Torfaen Power Lifting",
            "Power Racks",
            "Prowlers",
            "UKSCA Accredited S&C Coach."
          ],
          "testimonials": "Cheynee Fitzpatrick\nI feel like a different person. My energy levels & confidence are at levels I never could have imagined. Previously, I never had the courage to attend a gym of any sort as I felt embarrassed & lacked confidence in the way I looked & felt. When I finally decided that my weight & health were an issue, I joined Fighting Fit, not really sure of what to expect.\nSince that day, I have never looked back. The help the team at Fighting Fit have provided has played a huge role in the success I have achieved & have provided endless support. I am so proud of my achievements & prove that if you are prepared to change your body, the right guidance & support can lead to fantastic results!\n\nDeb Guy\nIn April 2014, I decided the time had come to lose weight, feel better about myself and make some positive changes. I had a friend who had previously been a member at Fighting Fit and raved about it, so we booked our consultation to see what the guys at the gym could do for us.\nWell, what a decision it was! After just 5 months of training, I am now lighter than when I had my eldest son nearly 30 years ago, have a new zest for life and have made some wonderful friends along the way! The training is hard, but the results are fantastic as a result of having a programme built around me. The staff and coaches are all so supportive and friendly - I never knew getting in shape could be so much fun! If you are looking at making a change, try Fighting Fit - you won't look back!"
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fighting_fit",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "589b40d5bec6935493e1ac66"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:07:03.238Z"
      },
      "createdAt": 1486569685962,
      "email": "fightingfitroyston@shoomon.xyz",
      "firstName": "Fighting Fit",
      "surname": "Enguiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ebZ94+A+wgKyEHM4pkNtHA==",
      "hashedPassword": "k+jphdkp901GRyacW1ynKwI1A9cx74Q9WAPQmAvn2uGEqceYvzaOCkAgzW9QlyZcGdsDCgWeru9UeIqXNvUS8A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OtcisBOg-fightingfitroystonpro.jpg"
        },
        "personal": {
          "disciplines": [
            "Boot Camp",
            "Fitness Classes",
            "Kids Classes",
            "Personal Training",
            "Sports Therapy & Rehab"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.fightingfituk.co/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eCzsQns8-fightingfitroystonbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Fitter  Faster Stronger",
          "biography": "The Fighting Fit Way: \nWelcome to Fighting Fit, home to Australia’s latest fitness concept that’s providing fun, motivational and challenging workouts here on The Heath.\n\nWe are the first official centre in the UK to open and are advocates to Fighting Fit Australia. We aim to help our customers get passionate about their fitness and be as healthy as possible.\n\nSo regardless of what your goals are or what your current fitness level is. I am here to support and guide you to become a Fitter Faster Stronger version of yourself!\n\nFighting Fit is committed to building a community of Mums, Dads, Brothers, Sisters and Kids all wanting to be fitter, healthier and happier! So come down to Fighting Fit and say g’day and I’ll help you take your first steps on your fitness journey.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours:\n\nMonday - Friday: 6am – 9pm \nSaturday: 7am – 9pm \nSunday: 8am to 8pm",
          "locations": [
            {
              "geolocation": [
                -0.02858000000003358,
                52.04787289999999
              ],
              "city": "Royston",
              "postcode": "SG8 5BG",
              "_id": {
                "$oid": "5a1416e72288dd3a30f3f46e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Fighting Fit UK - Royston",
          "phone": {
            "number": "7775507590",
            "code": "+44"
          },
          "qualifications": [
            "Fitness Studio",
            "Free Parking",
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Air Conditioning",
            "Cafe",
            "Changing Facilities",
            "Strength & Conditioning",
            "Ropes",
            "Kettlebells",
            "Wall Ball",
            "Pushing & Pulling Areas",
            "Gymnastics",
            "Olympic Lifting"
          ],
          "testimonials": "Fantastic Boot Camps\n\n“Now that the Board Shorts and Bikini Bootcamp has finished, I thought I’d write a quick review to say how fantastic I think the program was, and that I was so glad that I took part. It has made such a huge difference to my stamina and strength that I would not have achieved if working out alone at the gym. I was particularly impressed that each session was self paced as this made the sessions less daunting, and by the variety of activities on offer. Aussie is a knowledgeable coach- I really appreciated how dedicated he was to making us fitter, faster, and stronger throughout the program. I’m looking forward to continuing with the Fighting Fit sessions over the coming months!”\n\nClaire Louise"
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fighting_fit_uk_-_royston",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "589b4766bec6935493e1ada8"
      },
      "lastUpdated": {
        "$date": "2017-10-20T17:33:40.315Z"
      },
      "createdAt": 1486571366253,
      "email": "endeavoursg12@shoomon.xyz",
      "firstName": "Endeavour",
      "surname": "H & F Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "GC2qNNGLPu2ZTGCPsBaumg==",
      "hashedPassword": "cnxQjLIFP3le/NHQvm7nQivd42No/lh2SkzXYxhywq+S3uBXjUSQ+RqJw2vRuTGm3odtp3iKSrXi/A2weh8pyg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hIOpoYKW-endeavourlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.endeavourhealthclubs.co.uk/home.aspx",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rJ2xPrzq-endeavourbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Endeavour is a state-of-the-art boutique fitness club where every effort has been made to make getting fitter and healthier a fun and pleasant experience.\n\nFrom the exciting new gym environment to the studio, sunbed salon plus nutritional advice and products, Endeavour is here to help you 'achieve your aims'.\n\nTone Up:\nThe Endeavour team will guide you to your specific tone up - whatever size or shape you want to be. With  impressive free weights area and resistance equipment, at Endeavour you cannot fail to achieve.\n\nLose Weight:\nAt Endeavour we will support you through a wide range of activities to achieve your weight loss objectives. You'll lose weight and Endeavour will help you to keep it off. \n\nExercise:\nAt Endeavour we know just how important regular exercise is. It can reduce the risk of coronary heart disease, protect against high blood pressure, keep blood cholestrol at a healthy level, can be effective in the fight against obesity, reduce stress, protect against Osteoporois and back pain.\n\nThe Endeavour team will provide free personalised exercise programs for the duration of your membership to make sure you exercise safely, enjoy and achieve your goals.\n\nAll Ages:\nAs the years add up it becomes even more important to stay fit and healthy. Just a couple of sessions a week atEndeavour will help you continue to enjoy life.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOPENING HOURS\n\nMonday: 6:00 am - 9:30 pm\nTuesday: 6:00 am - 9:30 pm\nWednesday: 6:00 am - 9:30 pm\nThursday: 6:00 am - 9:30 pm\nFriday: 6:00 am - 9:30 pm\nSaturday: 8:00 am - 6:00 pm\nSunday\t10:00 am - 4:00 pm",
          "strapLine1": "State-Of-The-Art Boutique Fitness Club in Ware",
          "locations": [
            {
              "geolocation": [
                -0.029338899999970636,
                51.8068171
              ],
              "city": "Ware",
              "postcode": "SG12 9LR",
              "_id": {
                "$oid": "59ea337494e4d86bf9be1a65"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Endeavour Health & Fitness Club",
          "phone": {
            "code": "+44",
            "number": "1920466224"
          },
          "qualifications": [
            "Free Weights Selection",
            "Cardio Stations",
            "Resistance Equipment",
            "Tanning Facilities",
            "Cable Machines",
            "Matrix Equipment",
            "Changing Facilities"
          ]
        },
        "score": 62
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "endeavour_health_and_fitness_club",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "589b518fbec6935493e1afec"
      },
      "lastUpdated": {
        "$date": "2017-10-20T16:40:14.121Z"
      },
      "createdAt": 1486573967034,
      "email": "averagejoes@shoomon.xyz",
      "firstName": "Average",
      "surname": "Joe's Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "fL5MkqAyBZnDxjxUVnoIFA==",
      "hashedPassword": "sdVQub3C9BXu3hnIQxIPwBp87xkDbmQVcWKlckE+mRIp6YrRRTu0VwQPDZikxAYKZIpI4DHscDKxKL9FZQb2Qg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pyDK0tHG-ajlogo2.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Cardiovascular",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.averagejoesgymnasium.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EnE8unEJ-ajbg.png",
            "size": {},
            "offset": 0
          },
          "strapLine1": "The new you starts here - an independent gym based in Broxbourne.",
          "biography": "Average Joes Gymnasium provides a wide range of commercial fitness equipment.  \nCardio area - Cross Trainers, Treadmills, Upright Bikes, Recumbent Bikes, Steppers and Rowing Machines\nThere are also Flat screen Tv's in the Cardio area to keep you entertained during you workout\nResistance Area - A wide range of Stack and plate loaded resistance machines which target all the major muscle groups with both isolated and compound movements\nFree Weights - various Benches (Flat, Incline and Decline) Olympic Plates, Olympic Bars, Smiths Machine, Dumbells (5-60kg), Barbells (10-50kg)\nMatted Area - ideal for pre workout stretching Abs workouts, Medicine Balls, etc\nClimbing Wall - A great way to increase upper body strength especially the forearms is to use our 10 metre wide traversing wall. which has both easy and hard routes to attempt!\nChanging Area - Offering showers and a changing area with private lockers to keep your belongings in while you workout as well as toilet facilities.\nSupplement Bar - Also at Average Joes you will find our supplement and shakes Bar, plus all your dietary supplements.\nFree Inductions - Get shown how all of our equipment works by an instructor to ensure safe and proper use of all machines\nFree Programmes - Go round with one of our trainers who will create you a tailor-made workout programme based around your goals. We also provide free updates to keep you on track and motivated",
          "locations": [
            {
              "geolocation": [
                -0.002500300000065181,
                51.7418892
              ],
              "city": "Nazeing",
              "postcode": "EN10 6SU",
              "_id": {
                "$oid": "59ea26ee94e4d86bf9be1a5b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Average Joe's Gym - Broxbourne",
          "phone": {
            "code": "+44",
            "number": "1992443688"
          },
          "qualifications": [
            "Free Weights Selection",
            "Cardio Stations",
            "Resistance Equipment",
            "Tanning Facilities",
            "Supplements"
          ]
        },
        "score": 57
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "average_joes_gym_-_broxbourne",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "589badd2bec6935493e1c3ae"
      },
      "lastUpdated": 1486626497579,
      "createdAt": 1486597586583,
      "providerId": "642442335958263",
      "provider": "facebook",
      "surname": "Scotland",
      "firstName": "Ria",
      "email": "mariascotland@aol.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/642442335958263/",
          "disciplines": [
            "Meal Plans",
            "Personal Training",
            "Sports Nutrition",
            "Weight Management",
            "Dietary Advice"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 45,
            "max": 100
          },
          "companyWebsite": null,
          "profileTitle": "Nutritionist",
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Jc0McNPg-IMG_1328.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "mariascotland",
            "link": "https://twitter.com/mariascotland",
            "user_id": "420627157",
            "accessToken": "420627157-x4ldT4kvo4jeeInOs7ivQmeJtsYMjmlr6xzO3d0r",
            "accessTokenSecret": "FiEdU1gPggPRwoFiSnwRIJyYDvSYhefubrRy6kcid7c69"
          },
          "instagram": {
            "access_token": "1692094232.8640130.1000cfe6ede9463c88a58d4369c8faf8",
            "link": "https://www.instagram.com/ifbbproscotland"
          },
          "google": "https://plus.google.com/108618145452419378442",
          "qualifications": [
            "REPs Level 3: Nutrition",
            "REPs Level 4: Advanced Personal Trainer",
            "REPs Level 3: Master Personal Trainer",
            "REPs Level 4: Strength & Conditioning"
          ],
          "yearsOfExp": 2012,
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.20167513824836797,
                51.223531347011686
              ],
              "country": "United Kingdom",
              "city": "Reigate",
              "postcode": "RH2 8BG"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7854610236"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TmUIJQZW-filename.jpeg"
        },
        "score": 77
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ria_scotland"
    },
    {
      "_id": {
        "$oid": "589c4704bec6935493e1e470"
      },
      "lastUpdated": {
        "$date": "2017-10-20T16:43:32.125Z"
      },
      "createdAt": 1486636804725,
      "email": "avmfitness@shoomon.xyz",
      "firstName": "AVM Fitness",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "gvmDbPNFjyuakzFOO6EjOg==",
      "hashedPassword": "CxIW5/ejz5VlgP5i2mgGiKOzoSVoPOTMd7orEyz4O4kydmfkZhhYzDuFW0ucDnF8VWENSvF7UoBZ6+s3Rju8cg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7b9gHNwy-avmlogo.png"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.avmfitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/naVkyTte-avmbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Everyone deserves to be fit and healthy",
          "biography": "At AVM FITNESS GYM we offer fitness to a wide range of abilities to make you fitter, stronger and healthier.\nWhether you're a starting out or a seasoned pro, we have customised training programs to suit you - weight loss, body toning, muscle building, sport specific or just general health and fitness, AVM FITNESS GYM will provide you with all the requirements to achieve your goals. \n\nAVM FITNESS believes that everyone deserves to be fit and healthy. This is why our goal is to dedicate ourselves equally to each and every member and offer you our personal training expertise. \n\nWe have carefully constructed our training facilities to suit every need, which is available for you to use at an affordable price. \nWe are Antony and Vince Mistretta, two brothers who have trained together for years. We have turned our love for health and fitness into our everyday lives, where we share our knowledge and passion to all of our members.\nWe have both been heavily involved in a wide range of disciplines to help achieve an athletic and lean physique, but we also pride ourselves in the importance of fitness to ensure a healthy lifestyle. \nWe are dedicated to bringing you a complete workout solution and offer our services to you as your personal trainer. We will allocate you a time slot where we will go through your customised fitness criteria to ensure that your time with us is fully utilised in order for you to reach your targets.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMonday - Friday: 7am - 9pm \nSaturday - Sunday: 9am - 3pm",
          "locations": [
            {
              "geolocation": [
                -0.07111350000002403,
                51.6589033
              ],
              "city": "Enfield",
              "postcode": "EN1 3BB",
              "_id": {
                "$oid": "59ea27b494e4d86bf9be1a5f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "AVM Fitness",
          "phone": {
            "code": "+44",
            "number": "2083644406"
          },
          "qualifications": [
            "Battle Rope",
            "Kettlebells",
            "Cardio Stations",
            "Resistance Equipment"
          ],
          "openingTimes": ""
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "avm_fitness",
      "feature_in_area": "none",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "589c4b1dbec6935493e1e532"
      },
      "lastUpdated": {
        "$date": "2017-10-20T16:41:22.473Z"
      },
      "createdAt": 1486637853524,
      "email": "rippedgymharlow@shoomon.xyz",
      "firstName": "Ripped Gym",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "5RiSljG+RU4aS5DIcfuiBA==",
      "hashedPassword": "IDqPerHmCLIqzr3YTUP+MraA+vLwNBcznMb2Ob5Aeit2AVMSlTMzbzFctBnZo+nVwjpPh1vm3l6Wu1jgPJqlfA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TwD5kb4k-rippedgymlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Martial Arts",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.rippedgymharlow.co.uk/index.html",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TzxSRPlp-rippedbg2.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Ripped Gym is a multi-award winning Strength Training Gym equipped with state of the art machinery making it one of the best weight training gyms in the UK. Come inside and you will find an extensive range of Hammer Strength and Cybex machinery alongside cardio equipment, a large free weights area, a combat training area, a full kitchen providing delicious nutritional meals and a bunch of friendly staff!\n \nRipped Gym is the gym of choice for amateur and pro athletes alike. It prides itself on its passion and experience as the authority in bodybuilding and fitness. It aims to inspire members with unrivalled energy and fulfil the ultimate gym experience. Come in and find out today!",
          "offers": "Please visit our website for details of prices, packages \nand offers.\n\nOpening Times:\n\nMonday - Friday: 5:30am - 10:00pm\n \nSaturday: 7.30am - 7:00pm\nSunday: 8:00am - 6:00pm\n \nBank Holidays: 8:00am - 4:00pm",
          "locations": [
            {
              "geolocation": [
                0.08994000000006963,
                51.77224959999999
              ],
              "city": "Harlow",
              "postcode": "CM20 1QP",
              "_id": {
                "$oid": "59ea273294e4d86bf9be1a5c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Ripped Gym",
          "phone": {
            "code": "+44",
            "number": "1279433043"
          },
          "qualifications": [
            "Fightsports UK Mixed Martial Arts Training",
            "100 square metres of SAMA matted grappling area",
            "Boxing Ring",
            "14 ft Cage",
            "Punch Bags",
            "Kick Bags",
            "Cable Machines",
            "Cardio Stations",
            "Resistance Equipment",
            "Free Weights Selection",
            "Supplements",
            "Kitchen & Shakes",
            "Hairdresser"
          ],
          "strapLine1": "The Gym Of Choice For Amateur And Pro Athletes"
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ripped_gym",
      "feature_in_area": "none",
      "__v": 11
    },
    {
      "_id": {
        "$oid": "589c4ea6bec6935493e1e5f4"
      },
      "lastUpdated": {
        "$date": "2017-10-20T15:42:50.764Z"
      },
      "createdAt": 1486638758394,
      "email": "ttsswoodford@shoomon.xyz",
      "firstName": "TTS Personal",
      "surname": "Training Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Am7bliRsBHAuX7uXdDvpxg==",
      "hashedPassword": "abfw8KTlkwqVcFcXXIqiiUh0DroMNQkbUWKC/l+n9ErMyz+aoqi+uC4JJaAkhH1ZHWWQQOHAQQODKMkdLRGw3g==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/309tYVS9-ttsptswoodfordlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Diet & Nutrition",
            "Personal Training",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://ttspersonaltraining.co.uk/#sthash.gprso6cl.dpbs",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Y80IwOyo-ttsptswoodfordbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "In just over a decade, we've built a frame work to become some of the best movement specialists in the business. When we opened our studio in South Woodford we based it on honest advice, guidance and bullet proof results! Our ethos has always been Education, Motivation and Support and we strive to bring the best training and fitness to our clients. Training that will support and develop them into strong, functional individuals - whether its Matt getting ready for the extreme heat and intensity of Afghanistan war zone or helping Caroline regain control and flexibility to perform 15 years after her last Phantom of the Opera Show. \n\nWHY TTS WILL WORK FOR YOU:\n\nOur 1 to 1 Personal Training or our Group fitness - uses tried and tested techniques that get consistent sustainable results. We believe in better movement and corrective exercise that builds a solid foundation to develop a leaner, stronger you.",
          "offers": "Please contact us for details of prices, packages and offers.\n\nMon - Fri 6:00am - 9:00pm\nSat - Closed\nSun 9:30am - 1:00pm",
          "strapLine1": "Training For An Active Lifestyle - Education, Motivation and Support",
          "locations": [
            {
              "geolocation": [
                0.03742469999997411,
                51.5972786
              ],
              "city": "Woodford",
              "postcode": "E18 1ET",
              "_id": {
                "$oid": "59ea197a94e4d86bf9be1a4b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Total Training Systems",
          "phone": {
            "code": "+44",
            "number": "2081270394"
          },
          "qualifications": [
            "Power Bags",
            "Kettlebells",
            "WATT Bike",
            "rowing machines",
            "TRX Suspension",
            "Free Consultation"
          ]
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "total_training_systems",
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "589c56c7bec6935493e1e7b6"
      },
      "lastUpdated": {
        "$date": "2017-11-22T17:50:01.448Z"
      },
      "createdAt": 1486640839533,
      "email": "florentia@shoomon.xyz",
      "firstName": "Florentia",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "M6rXQs5TEnjB1rGIWQDQYA==",
      "hashedPassword": "zdq47pla7qSg7QYunGJJh0YMuKb7KrLmsoz3nf7rxkTtf/TNNfJcT6Q+7SnBEyjCib0RPblmLYZjPfq8+fIcXg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N51CDD0o-florentialogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Cardiovascular",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://florentiafitness.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/S5K5U3aa-florentiabg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Welcome to Florentia Fitness GYM, the best training gym in the London Harringay. Florentia Fitness GYM is fully equipped professional gym, offering a focussed training environment for anyone looking to achieve their fitness goals. We have a number of Personal Trainers available to offer advice and bespoke training. We provide access to the latest cardio-vascular and respiratory equipment, resistance machines and free weights, all at a great price.\n\nFlorentia Fitness GYM is all about education and results.  We go out of our way to educate you so you can achieve your goals in the most efficient and quickest way possible.  Our equipment has been carefully selected and built to high specification and the set up means we have created a place where someone with no training experience can come in and not feel intimidated by the environment.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours\n\nMON - FRI: 06:30 to 22:00\nSAT - SUN: 09:00 to 18:00 \nBANK HOLIDAYS: 09:00 to 21:00",
          "strapLine1": "Where The Adventure Begins",
          "locations": [
            {
              "geolocation": [
                -0.08845359999997982,
                51.5766364
              ],
              "city": "London",
              "postcode": "N4 1UP",
              "_id": {
                "$oid": "5a15b8c92288dd3a30f3f540"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Florentia Fitness",
          "phone": {
            "number": "2088022885",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Free Weights Selection",
            "Free Parking",
            "Free Wi-Fi",
            "Free Lockers",
            "Air Conditioning",
            "Changing Facilities",
            "No Contract",
            "No Joining Fee",
            "Pay As You Go"
          ]
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "florentia_fitness",
      "feature_in_area": "none",
      "__v": 12
    },
    {
      "_id": {
        "$oid": "589c600dbec6935493e1e9b8"
      },
      "lastUpdated": {
        "$date": "2017-11-22T17:37:36.302Z"
      },
      "createdAt": 1486643213913,
      "email": "synergyfitnessgym@shoomon.xyz",
      "firstName": "Synergy",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "YTjKZbKFzlTE8wGlishJHQ==",
      "hashedPassword": "n3EsTlvlFu9lU82XtYgKMDJM3ZuJrWh9pqoCWv1Ao+v6rDI9ZipwXEnBhaA+x3O5ouVo4PxayltGfBGbH7YdNA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5ghCmgni-synergylogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Martial Arts",
            "Personal Training",
            "Running Club"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://synergy-fitness.net/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8LXsdtHF-synergybg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Your local friendly gym",
          "biography": "FANTASTIC SUPERB FACILITIES:\nFrom World Class Martial Arts training through to Zumba, Kettlebells and specialist excercise classes. Synergy Fitness has something for everyone. Why not come down and see for yourself.\n\nLOCATION:\nApproximately 10 minutes walk from Fairlop Station (Central Line), Synergy Fitness is easily reached from Central London and The City. By road, Synergy is close to the North Circular, M11 and the A12, making it ideally situated for anyone living in North-East London.\n\nON SITE CAFÉ:\nRelax and enjoy a well earned Coffee after your work-out in our onsite Café. Or enjoy the facilities whilst you wait for classes to end. Our Coffee Republic café welcomes you.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nGYM OPENING TIMES:\n\nMONDAY - FRIDAY:\n6.30am - 10.00pm\n\nSATURDAY - SUNDAY:\n8.00 am - 6.00 pm\n\nBANK HOLIDAYS:\n8.00am - 6.00pm",
          "locations": [
            {
              "geolocation": [
                0.10033680000003642,
                51.59918210000001
              ],
              "city": "Ilford",
              "postcode": "IG6 3HJ",
              "_id": {
                "$oid": "5a15b5e02288dd3a30f3f53b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Synergy Fitness Gym",
          "phone": {
            "number": "2081142222",
            "code": "+44"
          },
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Free Weights Selection",
            "No Joining Fee",
            "Pay As You Go",
            "Cafe",
            "Creche"
          ]
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "synergy_fitness_gym",
      "feature_in_area": "none",
      "__v": 7
    },
    {
      "_id": {
        "$oid": "589d959763151c41a84419c4"
      },
      "lastUpdated": {
        "$date": "2017-11-21T16:16:50.769Z"
      },
      "createdAt": 1486722455180,
      "email": "muscleworksbgreen@shoomon.xyz",
      "firstName": "Muscle Works",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "B5tzAZTiC+9Tpkbj97cjXA==",
      "hashedPassword": "ntRE6S9mQDe1MoOEVIIsBKQsHobTlDD5BLiG6xo0v0tUt57ZDRseIs4EijlkCtWHqTkoNwhWpcSJmZMPATSbyg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mDJthclK-muscleworkslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Body Transformation",
            "Diet & Nutrition",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Free Weights",
            "Sunbeds",
            "Sauna",
            "Supplements for Sale",
            "Apparel for Sale"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://muscleworksgym.com/gym/bethnal-green/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/m61CbG3N-muscleworksbgbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "yearsOfExp": 1988,
          "strapLine1": "Men-only gym - the home of champions",
          "biography": "If you’re a regular member of Muscleworks you will know our energy comes from our desire to impress our customers rather than the zeal to best competitors.\n\nEver since we opened in 1985 – our goal has been to increase the value for our members. We’re internally driven to improve our service, adding benefits and features, before we have to.\n\nThese gyms are like my home, when you become a member. You are like family. You are looked after. You are put first. And you are always given open and honest advice.\n\nI don’t know much about the competition, I’ve never had to. It’s never been important to me. My focus has always been on caring for my customers.\n\nWhat I do know is that it takes something special to see an array of loyal members who’ve stuck with us through the decades, welcome guests who’ve travelled from all over the world to experience what makes our gyms tick, or witness the regulars who consistently travel from the other side of London passing all the competition, just to train at Muscleworks.\n\nI know we’re on the right track when people say our gyms are magical. When they try and persuade me to open up on their side of town or even back in their home countries. Or when top athletes choose our gyms as their training ground and magazines tout Muscleworks as the best gym in the UK.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMON-FRI – 7AM -10PM\nSAT & SUN 10AM - 5PM",
          "locations": [
            {
              "geolocation": [
                -0.06324459999996179,
                51.5262785
              ],
              "city": "London",
              "postcode": "E2 6HN",
              "_id": {
                "$oid": "5a1451722288dd3a30f3f4c4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Muscleworks Gym",
          "phone": {
            "number": "2072560916",
            "code": "+44"
          },
          "testimonials": "BRADY SELKIRK COMPLETELY TRANSFORMED HIS PHYSIQUE IN JUST 4 MONTHS\n\n“The transformation that I have achieved training with Nash in four months was simply surreal! Nash has completely changed my training system, introduced me to the most effective training techniques and created personalised nutrition program for me that has helped me keep my size and get rid of all that unwanted fat that I couldn’t lose for years. Training with Nash has completely changed my Life!” – Brady Selkirk\n\nMATT YOUNG SHREDDED BODY FAT AND GAINED MUSCLE SIMULTANEOUSLY IN 12 SHORT WEEKS!\n\n“After spending years trying to reach maximum size and definition I just hit the wall and reached a plateaux I couldn’t overcome. I was far away from my dream physique. Then, I came across Nash’s website and gave him a call. He started training me, introduced me to training techniques and a diet that I never heard of before. After only 12 weeks my dream came true! I’m now bigger than ever and in the best shape of my life. Without meeting Nash I would never have achieved this level of development.” – Matt Young"
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "muscleworks_gym_SkiO-RZgz",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "589d9bdc63151c41a8441b06"
      },
      "lastUpdated": {
        "$date": "2017-11-21T16:17:33.808Z"
      },
      "createdAt": 1486724060452,
      "email": "muscleworksstoken@shoomon.xyz",
      "firstName": "Muscleworks",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "cmXELuovefe4UEyTqlygVA==",
      "hashedPassword": "RO22vEb1D447/S6xzBR1glU/qvJ1BNLpfUl6Y+pvPEF/UyUfm25vCv2Qo0kb90dbskER90YLOEiQTMG5qLfUuw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JcuGcKhm-muscleworkslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Diet/Meal Plans",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Boxing Ring",
            "Supplements",
            "Shop",
            "Juice / Shake Bar"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://muscleworksgym.com/gym/stoke-newington/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/m0wv3QJT-muscleworkssnbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Unisex gym - the home of champions",
          "biography": "If you’re a regular member of Muscleworks you will know our energy comes from our desire to impress our customers rather than the zeal to best competitors.\n\nEver since we opened in 1985 – our goal has been to increase the value for our members. We’re internally driven to improve our service, adding benefits and features, before we have to.\n\nThese gyms are like my home, when you become a member. You are like family. You are looked after. You are put first. And you are always given open and honest advice.\n\nI don’t know much about the competition, I’ve never had to. It’s never been important to me. My focus has always been on caring for my customers.\n\nWhat I do know is that it takes something special to see an array of loyal members who’ve stuck with us through the decades, welcome guests who’ve travelled from all over the world to experience what makes our gyms tick, or witness the regulars who consistently travel from the other side of London passing all the competition, just to train at Muscleworks.\n\nI know we’re on the right track when people say our gyms are magical. When they try and persuade me to open up on their side of town or even back in their home countries. Or when top athletes choose our gyms as their training ground and magazines tout Muscleworks as the best gym in the UK.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMONDAY – FRIDAY: 8:00AM – 10:00PM\nSATURDAY AND SUNDAY: 10AM – 5PM",
          "locations": [
            {
              "geolocation": [
                -0.07340260000000853,
                51.55918759999999
              ],
              "city": "London",
              "postcode": "N16 7NA",
              "_id": {
                "$oid": "5a14519d2288dd3a30f3f4c5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Muscleworks Gym",
          "phone": {
            "number": "2072759773",
            "code": "+44"
          },
          "testimonials": "BRADY SELKIRK COMPLETELY TRANSFORMED HIS PHYSIQUE IN JUST 4 MONTHS\n\n“The transformation that I have achieved training with Nash in four months was simply surreal! Nash has completely changed my training system, introduced me to the most effective training techniques and created personalised nutrition program for me that has helped me keep my size and get rid of all that unwanted fat that I couldn’t lose for years. Training with Nash has completely changed my Life!” – Brady Selkirk\n\nMATT YOUNG SHREDDED BODY FAT AND GAINED MUSCLE SIMULTANEOUSLY IN 12 SHORT WEEKS!\n\n“After spending years trying to reach maximum size and definition I just hit the wall and reached a plateaux I couldn’t overcome. I was far away from my dream physique. Then, I came across Nash’s website and gave him a call. He started training me, introduced me to training techniques and a diet that I never heard of before. After only 12 weeks my dream came true! I’m now bigger than ever and in the best shape of my life. Without meeting Nash I would never have achieved this level of development.” – Matt Young"
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "muscleworks_gym_SkBybAWxM",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "589da1ec63151c41a8441c88"
      },
      "lastUpdated": {
        "$date": "2017-11-21T16:14:11.978Z"
      },
      "createdAt": 1486725612230,
      "email": "muscleworksenfield@shoomon.xyz",
      "firstName": "Muscleworks",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "p4AOs8X9Qgn0YIW9I65f3Q==",
      "hashedPassword": "52PTrCPgLkAsxicryRf9EUgWLCLe/HZmrFXE/MALGbM0S96hvKkza5T/pwAS26/WfaZ94H9Ch0D7vmSFQDxsOA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IXv9ZOat-muscleworkslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Body Transformation",
            "Diet/Meal Plans",
            "Personal Training",
            "Personalised Programmes",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Sauna",
            "Shop",
            "Supplements",
            "Car Park",
            "Juice / Shake Bar"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://muscleworksgym.com/gym/enfield/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NGkaIOP7-muscleworksenfieldbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Unisex gym - the home of champions",
          "biography": "If you’re a regular member of Muscleworks you will know our energy comes from our desire to impress our customers rather than the zeal to best competitors.\n\nEver since we opened in 1985 – our goal has been to increase the value for our members. We’re internally driven to improve our service, adding benefits and features, before we have to.\n\nThese gyms are like my home, when you become a member. You are like family. You are looked after. You are put first. And you are always given open and honest advice.\n\nI don’t know much about the competition, I’ve never had to. It’s never been important to me. My focus has always been on caring for my customers.\n\nWhat I do know is that it takes something special to see an array of loyal members who’ve stuck with us through the decades, welcome guests who’ve travelled from all over the world to experience what makes our gyms tick, or witness the regulars who consistently travel from the other side of London passing all the competition, just to train at Muscleworks.\n\nI know we’re on the right track when people say our gyms are magical. When they try and persuade me to open up on their side of town or even back in their home countries. Or when top athletes choose our gyms as their training ground and magazines tout Muscleworks as the best gym in the UK.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOPENING TIMES:\n\nMONDAY – FRIDAY: 6:30AM – 10:30PM\nSATURDAY AND SUNDAY: 8AM – 6PM\nOFF PEAK HOURS: MONDAY – FRIDAY 9AM – 4PM",
          "locations": [
            {
              "geolocation": [
                -0.016719599999987622,
                51.66853640000001
              ],
              "city": "Enfield",
              "postcode": "EN3",
              "_id": {
                "$oid": "5a1450d32288dd3a30f3f4c2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Muscleworks Gym",
          "phone": {
            "number": "1992762107",
            "code": "+44"
          },
          "testimonials": "BRADY SELKIRK COMPLETELY TRANSFORMED HIS PHYSIQUE IN JUST 4 MONTHS\n\n“The transformation that I have achieved training with Nash in four months was simply surreal! Nash has completely changed my training system, introduced me to the most effective training techniques and created personalised nutrition program for me that has helped me keep my size and get rid of all that unwanted fat that I couldn’t lose for years. Training with Nash has completely changed my Life!” – Brady Selkirk\n\nMATT YOUNG SHREDDED BODY FAT AND GAINED MUSCLE SIMULTANEOUSLY IN 12 SHORT WEEKS!\n\n“After spending years trying to reach maximum size and definition I just hit the wall and reached a plateaux I couldn’t overcome. I was far away from my dream physique. Then, I came across Nash’s website and gave him a call. He started training me, introduced me to training techniques and a diet that I never heard of before. After only 12 weeks my dream came true! I’m now bigger than ever and in the best shape of my life. Without meeting Nash I would never have achieved this level of development.” – Matt Young"
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "muscleworks_gym",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "589da9ef63151c41a8441e0a"
      },
      "lastUpdated": {
        "$date": "2017-10-20T13:19:01.717Z"
      },
      "createdAt": 1486727663487,
      "email": "thefactory@shoomon.xyz",
      "firstName": "The Factory",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "iKWdEDM/oCKBfqldPOqn/Q==",
      "hashedPassword": "dbyphvlYHc6cVz8gAjILUc0SLGAi8BpbScT1RYJDFI/91Bn/i7xCQP6EbQDwDVuAaKbi+VOxFvDxqXNUhZERvA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eDUfZ92A-Thefactorylogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Pole Dance",
            "Private Classes",
            "Yoga",
            "Zumba"
          ],
          "qualifications": [
            "Gym",
            "Dance & Exercise Studio",
            "Room Hire",
            "Personal Training",
            "Cardio stations",
            "Resistance Equipment",
            "Sprung Floors",
            "Mirrors",
            "Music"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.factorylondon.com/",
          "profileTitle": "Dance Studio",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/08mjm2wk-thefactorybg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "London's Best Private Gym, Dance, Fitness & Wellbeing Centre",
          "biography": "Here at the Factory Fitness and Dance Centre our main goal is to offer a fully equipped gym, with a varied timetable of fitness and dance classes available to all members.\n\nAs an independent business we take a personal approach to each and every member with the aim of keeping you motivated to achieve your fitness goals, and to get to know you along the way.\n\nAs part of your membership we will provide you with a fully-qualified personal trainer to work with you, design your exercise regime and coach you, free of charge!\n \nIn the 5 years since we opened our North London doors we are proud of the fitness goals achieved by our members:\n \nlosing 15 stone\nclimbing Kilimanjaro\nrunning a marathon\ncompleting an Ironman\nand cycling from London to Paris\n \nOur team consists of personal trainers who range from a B​ritish record-holding power lifter (women's' 60kg), a martial arts expert, an ex-­international tennis player, and a world record holding ocean rower. \n\nAll our fitness professionals have many years experience in the fitness industry and have worked with different clientèle and they are here to support you to achieve your fitness goals.",
          "offers": "Please visit our website for details of prices, packages and goals.",
          "locations": [
            {
              "geolocation": [
                -0.12176380000005338,
                51.56646429999999
              ],
              "city": "London",
              "postcode": "N19 4DX",
              "_id": {
                "$oid": "59e9f7c594e4d86bf9be1a2b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Factory",
          "phone": {
            "code": "+44",
            "number": "2072721122"
          }
        },
        "score": 60
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "the_factory",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "589daf7263151c41a8441f4c"
      },
      "lastUpdated": {
        "$date": "2017-10-20T11:27:38.733Z"
      },
      "createdAt": 1486729074313,
      "email": "dowedynamics@shoomon.xyz",
      "firstName": "Dowe",
      "surname": "Dynamics Gym Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "iWxW/AFAm9j0xLhkmy1DBA==",
      "hashedPassword": "wlmGfwRcM1AipY4hpgvft/9kNTA1cbHaRglJA8/vvE5/hGNV7Faac7AlAiG94Aeys241JtRFnAHc5z/FOQelxg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5MNWlD1u-dowepro.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Cardiovascular",
            "Diet & Nutrition",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Free Weights",
            "Saunas",
            "Tanning Facilities",
            "Cardio Stations",
            "Resistance Equipment",
            "Juice / Shake Bar"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.dowedynamics.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/i2CghX6G-dowebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Ordinary people achieving the  extraordinary - unleash your beast today",
          "biography": "Dowe Dynamics Personal Fitness Centre was launched by national bodybuilding champion Ian Dowe, who's competed against and rubbed shoulders with the likes of Arnold Schwarzenegger, well before he took Hollywood. Ian first competed in the 1970s Junior Mr. South Britain and went on to compete in and win the 1982 NABBA Mr. Britain competition.\n​\nFrom then on he was on a winning roll, bagging himself medals in the 1986 European Light-Heavyweight Championship, which put him on a trailblazing path towards securing more acclaimed titles. By 1993, Ian had successfully claimed a first place win in the IFBB Light Weight World Games. Already with several awards under his belt, in 2001 he won the World Masters IFBB Championship and scored highly in 2004 & 2005's IFBB World Championships. \n​\nHaving took the bodybuilding world by storm, Ian put his unrivalled skill to good use training the first ever and only female British Bodybuilder to win the auspicious IFBB Ms Olympia title in Las Vagas in 2000. Today, Ian is committed to helping others sculpt their way to chiseled looking bodies with weight training and conscious eating, because he knows better than anyone that the sky's the limit when it comes to what your body can really do.\n​",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nMon - Fri: 6am - 10pm\n​​Saturday + Sunday: 9am - 5pm",
          "locations": [
            {
              "geolocation": [
                -0.13419620000001942,
                51.56588929999999
              ],
              "city": "London",
              "postcode": "N19 3UB",
              "_id": {
                "$oid": "59e9ddaa94e4d86bf9be1a25"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Dowe Dynamics Gym",
          "phone": {
            "code": "+44",
            "number": "2072812267"
          }
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "dowe_dynamics_gym",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "589dc19963151c41a844234e"
      },
      "lastUpdated": {
        "$date": "2017-10-20T10:41:13.873Z"
      },
      "createdAt": 1486733721215,
      "email": "maximumfitness@shoomon.xyz",
      "firstName": "Maximum",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "jVF8hOLUw7s6rFcPcFbbfw==",
      "hashedPassword": "boTiCbaC6W/PIz9Mghc4UH8RM7yjeLivt9+Ed5YzT5ZJaSJi6oHIAgyxuSxMIlsF+/hFwUUIusT1ErDgdS6efg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vv70hawo-maximumlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Free Weights",
            "Resistance",
            "Supplements for Sale",
            "Apparel for Sale",
            "Tanning Facilities",
            "Cardio Stations"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://maxfit.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/f6ku9gGx-maximumbg.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 1983,
          "strapLine1": "Encouraging training excellence",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nMon – Fri 6.30am – 10pm\tSat – Sun & Bank Holidays 9am – 4pm",
          "biography": "Welcome to Maximum Fitness:\n\nMaximum Fitness is a large exercise centre that occupies ex-factory premises since 1983, sited behind the main row of shops near Tufnell Park tube station (Northern Line) in Fortess Road. \n\nThe entrance and our main reception can be found in the shopping parade.\n\nOur facilities are second to none, the club never gets too busy - at peak times we have a well co-ordinated and tidy training environment and you will always be able to go through your routine without interruption.\n\nYour health is our business!\n\nWe have a wealth of sports-conditioning experience and a member of the National Back Pain association so we have members of our team who can deal with virtually any training query.\n\nWe are able to advise people recovering from sports injury and disabling conditions, give you the most effective solutions for weight loss & weight gain and also to help people plan and start a healthy regime so you can get the best results for your time.\n\nIf you have any specific requirements please inform us and we will try our best to accommodate you.",
          "locations": [
            {
              "geolocation": [
                -0.1386916999999812,
                51.5561499
              ],
              "city": "London",
              "postcode": "NW5 2HP",
              "_id": {
                "$oid": "59e9d2c994e4d86bf9be1a20"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Maximum Fitness Gym",
          "phone": {
            "code": "+44",
            "number": "2074823941"
          }
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "maximum_fitness_gym",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "589dcf3363151c41a8442610"
      },
      "lastUpdated": {
        "$date": "2017-10-20T10:31:08.020Z"
      },
      "createdAt": 1486737203974,
      "email": "mrfitta@shoomon.xyz",
      "firstName": "Mr Fitta",
      "surname": "Gym Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "dRC0wJvYiZWATIxSn1E/5Q==",
      "hashedPassword": "ME30qGK3otKOz0unq4wYM/s3db1592zULcj5BD2LzIrwwzKVRW+h/9pZ9llbemFNk9QhORRN9VnEpQ3EkUxKaA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WHQVULTy-mrfittapro.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Power Lifting",
            "Personal Training",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio",
            "Free Weights",
            "Kettle Bells",
            "Club Bells",
            "Slam Balls",
            "Sledge Hammer"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.mrfitta.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/n56wObMp-mrfittabg.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 1976,
          "strapLine1": "The friendly gym",
          "biography": "Mr Fitta Gym was first opened in 1976 and over the years has built a reputation for offering a friendly environment where people can escape the day to day stresses of life (even if only for a couple of hours!) to focus on self development. \n\nWhether it is to build muscle, develop ‘usable’ strength, lose weight or simply get fit, we have the resources to get you there.\n\nSet over two floors our compact yet well equipped gym has a free weights area, and a resistance machine/ cardio area:\n\nWe have tried to keep the old school feel to the place and don’t believe the bells and whistles approach many chains employ(treadmills with tv screens etc) adds to the end result ie effective body transformation!\n\nTo that effect we incorporate traditional dumbells and barbells, kettle bells, club bells, battle ropes, slam balls as well as the good old fashioned sledge hammer into our member training programs",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nOpening Hours: \n\nMonday-Friday: 7.30am - 9pm\nSat/Sun: 11.30am - 4pm",
          "locations": [
            {
              "geolocation": [
                -0.22016759999996793,
                51.5871698
              ],
              "city": "London",
              "postcode": "NW4",
              "_id": {
                "$oid": "59e9d06b94e4d86bf9be1a1f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Mr Fitta Gym",
          "phone": {
            "code": "+44",
            "number": "2082015574"
          },
          "openingTimes": ""
        },
        "score": 64
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "mr_fitta_gym",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "589dda4b63151c41a8442892"
      },
      "lastUpdated": 1490290197094,
      "createdAt": 1486740043653,
      "email": "carla.turnbull@ehl.ch",
      "firstName": "Carla",
      "surname": "Turnbull",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ve2b5+1KT+O3LO3feoEtqw==",
      "hashedPassword": "daqCVkiR8XAbCiNvorivH0+hnRNtN7ySjVZgcqAHCJdcajAwfZ1B2/CruVLTO6NA6f8+a/nZ1QDpVFUb2Z08jg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AoryPFjh-Friyay.png"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Hiit",
            "Strength & Conditioning",
            "Weight Loss",
            "Toning"
          ],
          "qualifications": [
            "REPs Level 3: Personal Trainer",
            "REPs Level 2: Gym Instructor",
            "REPs Level 2: First Aid / CPR",
            "REPs Level 2: Group Exercise to Music Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 35,
            "max": 60
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xH3ON9v4-Screen%20Shot%202017-01-18%20at%2010.42.56.png",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "514231132.8640130.52db3807d0d4444eaf8f04f51a1e8ec9",
            "link": "https://www.instagram.com/carla_adele"
          },
          "yearsOfExp": 2016,
          "biography": "I believe in fitness as a lifestyle and integrating into our everyday routines. I come from a corporate background, so I understand the time pressures that we face when working a full time job. My sessions are very challenging, and I will push you as I want you to see results. It will be fun and by the end of it, you'll have made a long term commitment to yourself.\n\nHaving grown up in Africa, I have always had a love for the outdoors. When I moved to Switzerland, I was fortunate enough to have the mountains as my gym. I have spend the last 4 years competing in marathons and half ironman races all around the world, incorporating a mix of endurance, speed and strength training to get me at my best.\n\nDon't let anything stop you from going after what you truly want, change starts today, and it starts with you.\n\nWhether its weight loss, toning, sport specific, I can help you reach your goals. I always get my clients to understand the functional elements of training so that they can train hard with me and on their own.",
          "offers": "Personal Training: 1:1\nGOLD PACKAGE\n(WEIGHT LOSS/LEAN MUSCLE/BODY CONDITIONING)\nStart at £400* for 10 one hour sessions. \nMotivation to reach your goals, accountability to reach your goals\nNutrition consultation, meal plan and advised shopping list bespoke to you\nFood diary check ins\n*If you buy 20 gold sessions price will be £710\n*Price may vary on location due to travel\n\nSILVER PACKAGE\n(WEIGHT LOSS/LEAN MUSCLE/BODY CONDITIONING)\nStart at £350* for 10 one hour sessions\nMotivation\nBespoke plan for your time training\nNutritional guidance\nGeneral check ins\nFitness goals consultation: Goal setting\nWeigh in/body composition checks\nBespoke plan for your time training and guidance for training sessions during the week\nRegular milestone check ins\n*If you buy 20 sessions silver sessions will be £600\n\nSingle Sessions £50 per session\nI also do group sessions, please inbox me for details.",
          "strapLine1": "\"IF YOU ARE PERSISTENT, YOU WILL GET IT. IF YOU ARE CONSISTENT, YOU WILL KEEP IT.\"",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.12492789999998877,
                51.5589972
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "N19 4PJ"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7585700326"
          }
        },
        "score": 83,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "carla_turnbull"
    },
    {
      "_id": {
        "$oid": "589ddab863151c41a8442894"
      },
      "lastUpdated": {
        "$date": "2017-11-18T12:24:45.808Z"
      },
      "createdAt": 1486740152487,
      "email": "bloomsburyfitcent@shoomon.xys",
      "firstName": "Bloomsbury",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "z4y4NwNsef0SqM6D7XBgBA==",
      "hashedPassword": "/1pHGGO2k6/aEpO4Zl+pP7VegNYNjAeyigL8t5LU7VaPsSbEc5YNrCVTV+h9h9+yqJ925x59bH5sRDinYdapyw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VZaoPYKp-uclulogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Cardiovascular",
            "Fitness Classes",
            "Personal Training",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio",
            "Free Weights",
            "Resistance",
            "Squash Court(s)",
            "Mat Area",
            "Disabled Facilities"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://uclu.org/bloomsbury-fitness",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/f9g2dRy3-uclubg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "BF is a gym that gives back. As part of your students' union, we are able to keep prices low and service high while channelling profits back into the student activities and community you love.",
          "offers": "Please visit our website for details of prices, packages and offers.\n\n7.30am – 10.30pm Weekdays\n9am – 7pm Weekends",
          "strapLine1": "Built For fitness -  Built For strength - Built For you",
          "locations": [
            {
              "geolocation": [
                -0.1333627000000206,
                51.5256347
              ],
              "city": "London",
              "postcode": "WC1H 0AY",
              "_id": {
                "$oid": "5a10268d430ff53fda549bba"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Bloomsbury Fitness: UCLU",
          "phone": {
            "code": "+44",
            "number": "2076797221"
          }
        },
        "score": 60
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "bloomsbury_fitness:_uclu",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "589de8c263151c41a8442b96"
      },
      "lastUpdated": {
        "$date": "2017-10-19T11:52:23.239Z"
      },
      "createdAt": 1486743746970,
      "email": "aspirestanmore@shoomon.xyz",
      "firstName": "Aspire",
      "surname": "Leisure Centre Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "le3tvK6jTjW/7vQi64ciPg==",
      "hashedPassword": "BwTvSEaPWMlu/gfosEw9qtk+4Y0MwIcJSyyrQV5OXF6siFLc83O73H2sJCmTicyyIMogIrVuoUcprxgmxSY9qQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0ccjCXlC-aspirepro.jpg"
        },
        "personal": {
          "disciplines": [
            "Children's Parties",
            "Coaching",
            "Fitness Classes",
            "Kids Classes",
            "Swimming"
          ],
          "qualifications": [
            "Disabled Facilities",
            "Swimming Pool(s)",
            "Dance & Exercise Studio",
            "Kids Area",
            "Activity Camp",
            "Sports Hall",
            "Cafe"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.aspireleisurecentre.org.uk/",
          "profileTitle": "Leisure / Sports Centre",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3amXcUtO-aspirebg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Supporting People With Spinal Injury",
          "biography": "The Aspire Leisure Centre was the first fully-accessible Training Centre in Europe for disabled and non-disabled people.\n\nCurrently, around a third of our members are disabled, which compares with a national average of 2-3% in other sports centres.\n\nWe proactively encourage disabled people to join and take part in all activities by subsidising their membership and activity costs and offer a fully-Inclusive Fitness-Initiative approved fitness studio. \n\nAspire has gained Quest (UK Quality Scheme for Sport and Leisure) registration as 'Excellent'.\n\nThe good practice at the Aspire Leisure Centre inspired InstructAbility - a national project to train disabled people as fitness instructors. \n\nWith our deserved reputation for inclusive fitness, Aspire's expertise has been used by the NHS in their recent video.",
          "offers": "Mon-Fri: 6am-10pm\nSaturday: 6am-6pm\nSunday: 7am-6pm\nOff Peak: 9am-5pm\nBank Holiday: 8am- 4pm (Pool Closes at 3pm)\n\nPlease visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.310129599999982,
                51.6302952
              ],
              "city": "Stanmore",
              "postcode": "HA7",
              "_id": {
                "$oid": "59e891f794e4d86bf9be1a09"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Aspire Leisure Centre",
          "phone": {
            "code": "+44",
            "number": "2089545759"
          },
          "openingTimes": ""
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "aspire_leisure_centre",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "589ded2163151c41a8442c98"
      },
      "lastUpdated": {
        "$date": "2017-10-19T11:10:19.228Z"
      },
      "createdAt": 1486744865248,
      "email": "hayaa@shoomon.xyz",
      "firstName": "Hayaa",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "JDeK6/s+bqZcUQ1G4sMMiw==",
      "hashedPassword": "9TZTdVdytML4Qqjbf9g0dSj6h+D7KnVJlaxvD9fdzTvfiZQGHtKM+9gnhAa/VppgDwNm645bzlZCipg5F6Zylw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sWdV1ZS9-hayaalogo.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio",
            "Free Weights",
            "Resistance",
            "Sanarium",
            "Lounge area",
            "Disabled Facilities",
            "Changing Rooms",
            "Lockers",
            "Hot Showers",
            "Ladies-only Gym"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.hayaafitness.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RZLhGGgs-hayaabg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Men Only Health & Fitness",
          "biography": "The premier health and fitness facility, based at the London Muslim Centre was launched in February 2005 by the Minister of Sports Richard Caborn MP and the Commonwealth boxing champion, Danny Williams.\n\nProviding the very latest in the state-of-the-art facility, Hayaa Fitness promises to thrill all, with something for everyone. Whether you are young or old, beginner or an expert, you can achieve your desired fitness goals with the help of qualified instructors in a friendly environment.\n\nWomen-friendly:\nMembers attending our exclusive women-only days will feel at ease and be comfortable working outwith other women. With qualified female instructors, our programmes are tailored especially for women who have never exercised before or who feel self-conscious about exercising in a mixed environment.\n\nWhether you are too shy to try or need to reach specific goals – achieving the health level and mental freedom you want has never been easier.\n\nMuslim-friendly:\nIf you are conscious of missing your prayers whilst in the gym, you need not worry as our sound system is switched off during the Adhan and prayers. Take breaks to pray at the adjoining East London Mosque.",
          "offers": "Please visit our website for details of our prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                -0.06598350000001574,
                51.5170973
              ],
              "city": "London",
              "postcode": "E1",
              "_id": {
                "$oid": "59e8881b94e4d86bf9be1a05"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Hayaa Fitness - London Muslim Centre, Whitechapel",
          "phone": {
            "code": "+44",
            "number": "2076503060"
          },
          "openingTimes": "Mon - Fri 07:30 - 22:00 \nSat - Sun 10:00 - 19:00"
        },
        "score": 63
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "hayaa_fitness_-_london_muslim_centre,_whitechapel",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "589df51563151c41a8442e5a"
      },
      "lastUpdated": {
        "$date": "2017-10-20T11:30:10.113Z"
      },
      "createdAt": 1486746901222,
      "email": "studio360@shoomon.xyz",
      "firstName": "Studio 360",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "wUCDpWvzRXhc9pGIvVr9uQ==",
      "hashedPassword": "sg1H3doBMky6jkZ6xw++aQ/Grzhk2288ThOHrUQVd2nVdfk6IsR8f2l+/IXqMjwpVcfOqPY+I3k5vgPXAk/Bww==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tA1gBgN6-studio360logo.jpg"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Kids Classes",
            "Personal Training",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio",
            "Resistance",
            "Cafe",
            "Supplements for Sale",
            "24 meter sprint track",
            "Olympic Oartec rowing simulator",
            "50 state of the art 360 branded Body-bikes",
            "Boxing equipment",
            "A range of free weights and Olympic bars",
            "TRX frame"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://studio360fitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sBlqSt11-studio360bg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "We won't just train your body, we'll train your mind – we'll educate you",
          "biography": "Studio 360 Fitness offers a bespoke collection of some of the world’s latest fitness equipment housed in an open, dynamic and friendly club.\n\nA large fully equipped open plan gym, 2 spacious studio used for a variety of group exercise classes, a refuel bar, an in-house masseuse, experienced Personal Trainers and clean and bright changing facilities.Sports and therapeutic massage\n\nRelease your tension from a hard workout or a stressful week, or tackle niggling injuries with our fully qualified, fantastic sports therapist Karl Renata. \n\nWith several years experience in the industry working with a wide range of clients who have muscular dysfunction and sports injuries, Karl has helped to rehabilitate them back to health. Massage helps to relieve pain and increase circulation, giving an overall improvement in health, well being and functional movement.\n\nOur extensive of equipment includes:\n\nUnique cardio and resistance equipment\n24 meter sprint track\nOlympic Oartec rowing simulator\n50 state of the art 360 branded Body-bikes\nExclusive Indoor Walker cross trainers\nTRX frame\nKettlebells\nPowerbags\nVIPR\nBoxing equipment\nA range of free weights and Olympic bars\nStroop accelerator bands",
          "offers": "Please visit our website for details of prices, packages and offers.\n\nM\t6:00 am\t-\t10:00 pm\nT\t6:00 am\t-\t10:00 pm\nW\t6:00 am\t-\t10:00 pm\nT\t6:00 am\t-\t10:00 pm\nF\t6:00 am\t-\t8:00 pm\nS\t8:00 am\t-\t7:00 pm\nS\t8:00 am\t-\t7:00 pm",
          "locations": [
            {
              "geolocation": [
                0.4128127999999833,
                51.63090829999999
              ],
              "city": "Billericay",
              "postcode": "CM12 0BX",
              "_id": {
                "$oid": "59e9de4294e4d86bf9be1a26"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Studio360 Fitness",
          "phone": {
            "code": "+44",
            "number": "1277424360"
          },
          "openingTimes": ""
        },
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "studio360_fitness",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "589e0b1763151c41a84432dc"
      },
      "lastUpdated": {
        "$date": "2017-10-19T10:45:17.149Z"
      },
      "createdAt": 1486752535147,
      "email": "squad@shoomon.xyz",
      "firstName": "Squad",
      "surname": "Fitness Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "qkCIta4ACVhZvJCiYAE2iA==",
      "hashedPassword": "NvASJHe8lkiB95D5D5CGylv8VYviWJvuHToYK97BLPvZyDxxqjIUV02CV1wpLzsPY+SguuhHhh4Vf80QYzEl2Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vDsvlISu-squadlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Partner Training",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Free Weights Selection",
            "Battle Ropes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.squadfitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3IOzauEL-squadbg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Get in the shape of your life",
          "biography": "Get in the shape of your life and feel better than ever at Squad Fitness. Sign up for a free consultation now to see how we can transform your body and your life!\n\nNo matter which programme you enroll onto our team of experienced professionals will be with you every step of the way to offer the very best programming and monitoring advice.\n\nLearn about and implement a diet that will transform your physique.\n\nShape up:\nLose weight, increase muscle tone and feel more confident than ever!\n\nSupport & Motivation:\nTrain with like-minded individuals who will support and motivate you.\n\nNo matter what your goals are, 1-on-1 personal training is for you. Tailor-made to help you get the most out of your fitness programme, 1-on-1 training is perfect for individuals who want dedicated and focused workouts to ensure they achieve their targets and transform their lives.\n\nWhether you’re looking to increase muscle tone, burn body fat, increase endurance, or just improve your quality of life, opting for a personal trainer will put you on the right track.",
          "offers": "Please visit our website for details of prices, packages and offers.",
          "locations": [
            {
              "geolocation": [
                0.21633589999999003,
                51.5612931
              ],
              "city": "Hornchurch",
              "postcode": "RM12 4XA",
              "_id": {
                "$oid": "59e8823d94e4d86bf9be19fc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Squad Fitness - Hornchurch",
          "phone": {
            "code": "+44",
            "number": "1708470870"
          }
        },
        "score": 60
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "squad_fitness_-_hornchurch",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "589ebeca63151c41a844595e"
      },
      "lastUpdated": {
        "$date": "2018-01-24T15:48:31.031Z"
      },
      "createdAt": 1486798538333,
      "email": "jenny.devonshire@yahoo.com",
      "firstName": "jenny",
      "surname": "Devonshire",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "HtOzRBY8RXUPyCfJUtgm7Q==",
      "hashedPassword": "mnU24UNCyZKxPIx886JKJWVzea4t7gGI332ogh2rtZuKk5DSippQqm3VCWaUmb2mnPeNRxBnKdGPA1DXoLtfpw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/bVsHfhjS-IMG_2850.JPG"
        },
        "personal": {
          "disciplines": [
            "Hiit",
            "Online Coaching",
            "Pilates",
            "Weight Loss"
          ],
          "qualifications": [
            "REPs Level 3: Advanced Personal Trainer",
            "Stott Pilates",
            "Animal Flow",
            "Boxing",
            "Pre/Post Natal Exercise",
            "Powerplate",
            "Kettlebells",
            "Advanced Kettlebells",
            "TRX Suspension",
            "Circuit Training",
            "Ballet Barre",
            "Indoor Cycling"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 60,
            "max": 70
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rpyc1R46-IMG_3776.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "PTwPrecision",
            "link": "https://twitter.com/PTwPrecision",
            "user_id": "610011994",
            "accessToken": "610011994-UOscOh0CVREg4SIscvowniUHZ5sIVoENj2uigEZV",
            "accessTokenSecret": "B2Rq21kL9WIyvBJxZ0Kf2seKBIN41VnfQ2pdWcjvHUBn4"
          },
          "instagram": {
            "access_token": "280753035.8640130.01bf73ca25fa4581a18ce198f54df284",
            "link": "https://www.instagram.com/pilatesjen82"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10102377100320698/",
          "yearsOfExp": 2010,
          "strapLine1": "Working with both mind and body to provide a holistic approach to health and fitness",
          "biography": "I am a Level 3 Personal Trainer, Pilates Instructor and NLP Practitioner. I have always been passionate about health and fitness and helping my clients to achieve their health and fitness goals. I have always been dedicated to furthering my knowledge and the service I can offer, I do this through attending courses as well as home study. I have completed qualifications in STOTT Pilates, animal flow, boxing, PRe/Post Natal exercise, TRX, PowerPlate, Kettlebells, Advanced Kettlebells, Circuit Training, Ballet Barre and Indoor Cycling. I combine all these different training methods to offer a training style unique to me. I make sure my sessions are fun whilst also educational. Having studied NLP (Neuro Linguistic Programming) I also work with people's mindset, we can focus on what is holding them back with regards to their goals as well as forming new habits without the effort it usually takes.",
          "locations": [
            {
              "geolocation": [
                -0.170811299999968,
                51.4651737
              ],
              "city": "London",
              "postcode": "SW11",
              "_id": {
                "$oid": "5a68aacff913ab12c671c0c1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7766832797",
            "code": "+44"
          }
        },
        "score": 74,
        "parent": {
          "$oid": "5888bd97739f5b6c586cbaf6"
        }
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "jenny_devonshire",
      "lastLogin": 1503414948443,
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.101 Safari/537.36"
      },
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "589f17c1fa7d7c0fd729c5c4"
      },
      "lastUpdated": 1486829639700,
      "createdAt": 1486821313105,
      "providerId": "10155080400875159",
      "provider": "facebook",
      "surname": "Da Silva",
      "firstName": "Tatiane",
      "email": "taty.silva@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155080400875159/",
          "disciplines": [
            "Vinyasa flow",
            "Retreats",
            "Private Classes",
            "Mobile Teacher",
            "Group Classes"
          ],
          "qualifications": [
            "REPs Level 3: Yoga Teacher"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 60,
            "max": 120
          },
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IS7S0ea8-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "3746440385.8640130.4735d825b3e043a3a508bf29305653df",
            "link": "https://www.instagram.com/befit.befab.beyou"
          },
          "google": "https://plus.google.com/105353846130992822381",
          "yearsOfExp": 2011,
          "offers": "Every Tuesdays & Thursdays from 7 to 8:30pm\nInner Circle Yoga in Notting Hill \nSemi-private 5 people max\n£12 a session\n£30 for 3 session",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.15465055632304026,
                51.52620122769922
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "NW1 4NS"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7557079857"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Jj2A4x39-IMG_1936.JPG"
        },
        "score": 73
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "tatiane_da_silva"
    },
    {
      "_id": {
        "$oid": "58a97ba45fa99e0a9159c9e3"
      },
      "lastUpdated": 1487770928754,
      "createdAt": 1487502244132,
      "providerId": "1415356298504311",
      "provider": "facebook",
      "surname": "Porowski",
      "firstName": "Kamil",
      "email": "porowskikamil@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1415356298504311/",
          "disciplines": [
            "Personal Training",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss",
            "Endurance"
          ],
          "qualifications": [
            "REPs Level 3: Advanced Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 50,
            "max": 100
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/paPJnhYt-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2,
          "locations": [
            {
              "primary": true,
              "geolocation": [
                21.999119599999972,
                50.0411867
              ],
              "country": "Other",
              "city": "Rzeszów",
              "postcode": "35-315"
            }
          ],
          "phone": {
            "code": "+48",
            "number": "795813475"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/64qgmZDy-filename.jpeg"
        },
        "score": 67
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "kamil_porowski"
    },
    {
      "_id": {
        "$oid": "58ac477815f19f6d0a6d9dbd"
      },
      "lastUpdated": {
        "$date": "2017-10-19T10:33:19.853Z"
      },
      "createdAt": 1487685496376,
      "providerId": "10158169654650648",
      "provider": "facebook",
      "surname": "Cayton-Tang",
      "firstName": "Robert",
      "email": "robert@dancescape.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10158169654650648/",
          "disciplines": [
            "All Abilities",
            "Ballroom",
            "Fitness Classes",
            "Latin",
            "Private Classes"
          ],
          "qualifications": [
            "Dance & Exercise Studio",
            "Cardio",
            "Disabled Facilities",
            "Changing Rooms"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.dancescape.com",
          "profileTitle": "Dance Studio",
          "radius": 60,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ltUTxFlX-facebooklive_ipad-mini_03a_rb_1024x720_episodes_12.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "danceScape",
            "link": "https://twitter.com/danceScape",
            "user_id": "16329020",
            "accessToken": "16329020-cMyg8eu91sBnhl99sO0j8dsunARBpvKtxBAC9VQJY",
            "accessTokenSecret": "Vm3Ow8uxp2yGXKaGsIweutjYR8IMBxaqC66LX8X0eKvVF"
          },
          "instagram": {
            "access_token": "438228218.8640130.bce59d0b701c4410b0987d39ce46bce9",
            "link": "https://www.instagram.com/dancescape"
          },
          "yearsOfExp": 2001,
          "google": "https://plus.google.com/115145733391394904699",
          "biography": "Our mission is to inspire people to laugh, be fit, make fun friends, and experience romance through Dance. We teach people to gain confidence in themselves, not only on the dance floor but also in their personal and work life. We connect couples and singles from cross generations to live fun, fearless, and magical lives. Nothing makes our Team more happy than when students smile, laugh, hug, and embrace each other during their fun dance lessons and friendly social events. Our online and in-studio programs include Ballroom/Big Band, Salsa/Latin, West Coast Swing/Jive, danceTONE Fitness and danceFLOW QiGong/TaiChi. Download our FREE Video app at www.dancescape.com/app and start learning to dance online!",
          "offers": "Learn to Dance Online with our Video App:\n\nMonthly – ($14.97/mo. ALL Classes Pass (“ALL” Classes Pass with Bonus Lessons released monthly), or\n\nAnnual ($147/yr UNLIMITED “ALL” Classes Pass (ALL Classes Pass and Bonus Lessons available immediately). Includes other BONUS Tutorials, Styling Tips, and Choreography/Step Combinations.",
          "locations": [
            {
              "geolocation": [
                -79.7937973,
                43.32688889999999
              ],
              "city": "Burlington",
              "postcode": "L7R 1G2",
              "_id": {
                "$oid": "59e87f6f94e4d86bf9be19fa"
              },
              "country": "Canada",
              "primary": true
            },
            {
              "geolocation": [
                -79.7937973,
                43.32688889999999
              ],
              "city": "Burlington",
              "postcode": "L7R 1G2",
              "note": "Main Studio: 2077 Pine Street, Burlington",
              "_id": {
                "$oid": "59e87f6f94e4d86bf9be19f9"
              },
              "country": "Canada",
              "primary": false
            },
            {
              "geolocation": [
                -79.87780839999999,
                43.50053399999999
              ],
              "city": "Milton",
              "postcode": "L9T 0H7",
              "note": "danceScape at Dance Etc. 348 Bronte St. South, Unit 9 Milton",
              "_id": {
                "$oid": "59e87f6f94e4d86bf9be19f8"
              },
              "country": "Canada",
              "primary": false
            },
            {
              "geolocation": [
                -79.6838874,
                43.21929469999999
              ],
              "city": "Hamilton",
              "postcode": "L8E 5G6",
              "note": "danceScape: (Major Intersection: Fruitland Road at QEW)",
              "_id": {
                "$oid": "59e87f6f94e4d86bf9be19f7"
              },
              "country": "Canada",
              "primary": false
            }
          ],
          "companyName": "danceScape",
          "phone": {
            "code": "+1",
            "number": "9056338808"
          }
        },
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/l5M1Rpsy-14117887_1124829410885951_3092916405633710058_n.jpg"
        },
        "score": 68
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "dancescape",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58b04b69fff21b4d699ebe75"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:23:11.406Z"
      },
      "createdAt": 1487948649668,
      "email": "tvaughan91@gmail.com",
      "firstName": "Tara",
      "surname": "Vaughan",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "f20269628ed9f2a6ae307e4c720bd71f376fece7",
      "salt": "mR1845KSpkM/mTZEsvoMlA==",
      "hashedPassword": "ibieIz3R0K727HsPGvWPKK9Gy2xAMJ5P/Yvh8Te27H6IkfPODiYSscizV2PrSgcFzyFrrjkgFSeAq9u0vl3wWA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uCwh1533-TaraWeb015.jpg"
        },
        "personal": {
          "disciplines": [
            "Event Preparation",
            "Injury Assessment",
            "Injury Prevention",
            "Injury Rehabilitaion",
            "Massage"
          ],
          "qualifications": [
            "North London School of Sports Massage (NLSSM)",
            "Certified Sports and Remedial Massage Therapist"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 40,
            "max": 60
          },
          "companyWebsite": null,
          "profileTitle": "Sports Therapist",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vCeSK61G-TaraWeb014.jpg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "560445472.8640130.cbc38fce93634f84bcf13b622a40e461",
            "link": "https://www.instagram.com/misstvaughan"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10207886938964397/",
          "locations": [
            {
              "geolocation": [
                -0.19824029999995219,
                51.5006431
              ],
              "city": "London",
              "postcode": "W8 7QE",
              "_id": {
                "$oid": "5a60050f765eba094646f757"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7715576055",
            "code": "+44"
          },
          "biography": "Tara Vaughan grew up in Monmouthshire, Wales, where from a young age she was an active rider and skier.\n\nShe is now based in London where she is helping a large variety of patients ranging from professional athletes, to young adults, after graduating from the North London School of Sports Massage (NLSSM).\n\n​\n\nThroughout her life she has been doing all sorts of sports. The potential power of achieving excellent control and focus of body and mind using sports and other outdoor activities captured her interest from a very early age.\n\n​\n\nTara's great love for riding has long been the source of her deep awareness of the human body, and the magnificent abilities it has, if we just listened in.\n\n​\n\nPrior to becoming a therapist, Tara worked as a private PA. Despite her high profile job, she was always drawn to the ‘workings’ of the human body and healing. Her interest in the body, lead her then to train as a certified Sports and Remedial Massage Therapist.",
          "strapLine1": "Sports & Remedial Massage"
        },
        "score": 69
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "tara_vaughan",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58b6bb94fff21b4d69a01e4c"
      },
      "lastUpdated": {
        "$date": "2018-01-09T13:24:20.715Z"
      },
      "createdAt": 1488370580388,
      "email": "admin@ptpowerfitness.co.uk",
      "firstName": "Lindsey",
      "surname": "Anderson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "MOxDtrrJ4RlFXXcVWazBPw==",
      "hashedPassword": "yMjmrErl8nWCtVnPHdf6mvELZmcnVs0DhSZH0YFNI0s5RSke1rCU6In8aNwznFsYwTyx4o3exvpIxWjH9YZNaA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FQMxiK2U-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Fat Loss",
            "Partner Training",
            "Personal Training",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.ptpowerfitness.co.uk/",
          "profileTitle": "Mobile Personal Training",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nPHrRyFw-54545.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "PTpowerfitness",
            "link": "https://twitter.com/PTpowerfitness",
            "user_id": "805075337595994112",
            "accessToken": "805075337595994112-Wl4Jolc1pdgDgyR8HDa8R425kCF5A6D",
            "accessTokenSecret": "T7fFFKsd0BqDkAbfE27VG2uK6xuBi1lv0AENrlp8yD4SH"
          },
          "yearsOfExp": 2016,
          "strapLine1": "Mobile Personal Training",
          "biography": "PT Power Fitness is a mobile and online personal training company committed to working with clients to deliver sustainable, life changing results. We operate throughout Dunfermline and all surrounding areas, at times convenient to you. We specialise in designing highly personalised exercise programmes and nutritional plans utilising in-depth knowledge with the most cutting edge, innovative training methods available. Every plan will constantly progress towards your ultimate goal. We aim to supply all the experience, tools and knowledge so both our online and mobile clients are able to sustain their results long into the future. \n\nKeeping fit has never been easier. We provide the most exciting, versatile training equipment and offer sessions at your home, garden, workplace, local park or any other location of your choice.  So, whether you’re looking to burn body fat, build muscle, tone up or just get that little bit fitter; we have the answers and we want to help you.",
          "offers": "For more information please visit our website.",
          "locations": [
            {
              "geolocation": [
                -3.7839131000000634,
                56.00187750000001
              ],
              "city": "Falkirk",
              "postcode": "FK1",
              "_id": {
                "$oid": "5a54c240765eba094646f491"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -3.452150999999958,
                56.071741
              ],
              "city": "Dunfermline",
              "postcode": "KY12 7JA",
              "_id": {
                "$oid": "5a54c240765eba094646f490"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -3.7839131000000634,
                56.00187750000001
              ],
              "city": "Falkirk",
              "postcode": "FK1",
              "_id": {
                "$oid": "5a54c240765eba094646f48f"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -3.7918959999999515,
                56.11407299999999
              ],
              "city": "Alloa",
              "postcode": "FK10 1EH",
              "_id": {
                "$oid": "5a54c240765eba094646f48e"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -3.7166919999999664,
                56.06895799999999
              ],
              "city": "Kincardine",
              "postcode": "FK10",
              "_id": {
                "$oid": "5a54c240765eba094646f48d"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "PT Power Fitness",
          "phone": {
            "number": "7812383829",
            "code": "+44"
          }
        },
        "score": 60
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "pt_power_fitness",
      "lastLogin": 1515443202616,
      "feature_in_area": "none",
      "__v": 19,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      }
    },
    {
      "_id": {
        "$oid": "58b84c70fff21b4d69a073d5"
      },
      "lastUpdated": 1488475534053,
      "createdAt": 1488473200246,
      "providerId": "10208824317785960",
      "provider": "facebook",
      "surname": "Spoelman",
      "firstName": "Fallon",
      "email": "freckleface332@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10208824317785960/",
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Hiit",
            "Nutrition",
            "Personal Training"
          ],
          "qualifications": [
            "REPs Level 3: Advanced Personal Trainer",
            "REPs Level 3: Personal Trainer",
            "REPs Level 3: Master Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 33,
            "max": 60
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1hHTm9t7-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2016,
          "google": "https://plus.google.com/100130814908164544272",
          "offers": "My session are 30 min or 60 min in length!",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -111.75530872318126,
                33.46137159507113
              ],
              "country": "United States",
              "city": "Mesa",
              "postcode": "85213"
            }
          ],
          "phone": {
            "code": "+1",
            "number": "4802169556"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vuifjA3z-IMG_4832.PNG"
        },
        "score": 70
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fallon_spoelman"
    },
    {
      "_id": {
        "$oid": "58c1ccbe281e79554d936f57"
      },
      "lastUpdated": 1489148443680,
      "createdAt": 1489095870524,
      "email": "lawrence@lp-pt.com",
      "firstName": "Lawrence",
      "surname": "Price",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "oOTUwQAYu4VEM3UEP8elcw==",
      "hashedPassword": "IUInZm8plMO61ClKFAZgpRC7I5esC+J03x5M7ldfJ7XmpHUqrMVE4lokFfF4LdmBJRjbgJDclrtrnrUjJXKXnw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9nMcIxEe-IMG_1853.PNG"
        },
        "personal": {
          "disciplines": [
            "Online Coaching"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 90,
            "max": 100
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GEBBpVyD-IMG_0928.JPG",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2007,
          "strapLine1": "Health as Habit.",
          "offers": "Monthly subscription to Online Personal Training - get coached directly by Lawrence for a fraction of the cost of traditional PT. Just £50pcm - free week demo available upon request.",
          "biography": "Top London trainer Lawrence has Hollywood actors, military special forces and record breaking adventurers amongst his clientele. Become one of his Online Personal Training clients now to be coached and mentored directly towards your fitness goals.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.12775829999998223,
                51.5073509
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "WC2N 5DU"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7810800324"
          }
        },
        "score": 79
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "lawrence_price"
    },
    {
      "_id": {
        "$oid": "58c532ce8972aa10a5b18bf5"
      },
      "lastUpdated": 1490290266175,
      "createdAt": 1489318606185,
      "email": "griffin.healthandfitness@gmail.com",
      "firstName": "Alan",
      "surname": "Griffin",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "K0mlWJc2Awt/2XbuqcyY8A==",
      "hashedPassword": "8R60guYP7wXHuzb3e8+eRPOb3Hb6m/mOwqdkjyg/uxpHROwDGnuOCgIbmJ8+OuKk9yP9VUcz69T3DQSac/vrzQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/z7W1ST6d-16143342_10155732758053047_8031414163468645753_n.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Sports Massage",
            "Strength & Conditioning",
            "Weight Loss",
            "Mobile Trainer"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "Level 3: Nutrition",
            "HIIT V2",
            "Padwork/combat",
            "Suspension Training",
            "Sports Massage"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 50
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5ykN3It3-CHICK3-oneshoo.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "Griffinfit47",
            "link": "https://twitter.com/Griffinfit47",
            "user_id": "792504501504704513",
            "accessToken": "792504501504704513-UNUlumDFRVInG2gq6a4Szz3I8n9gwU7",
            "accessTokenSecret": "2YuLX9jrXOttcDAdIdfJ40ymeO299C9jhboHwpGJCBki6"
          },
          "instagram": {
            "access_token": "4414707692.8640130.106c7a3b567a4a22958cd078e54d64b5",
            "link": "https://www.instagram.com/griffinfit47"
          },
          "facebookPage": "griffin.healthandfitness.perso",
          "yearsOfExp": 2016,
          "affiliation": "REPS",
          "strapLine1": "Be Your Best! Faster-Fitter-Stronger!",
          "biography": "I have been a gym goer for many years (we won't quote exact numbers for the sake of my pride but suffice to say a long time). Like many I was just \"doing a bit to keep fit\" with no particular training direction.\n\nThe end of a challenging management career left me with time on my hands which I channelled into my health and fitness. I lost three stone and a considerable amount of body fat in recent years and became quite fond of road cycling. Taking part in many cycling events (Bike Oxford, London 100, Cambridge Gran Fondo, London to Paris). \nIt occurred to me that perhaps I could take this new found passion and make a life change towards helping others achieve their goals too.\n\nStudying for an advanced diploma in Personal Training and sports massage therapy has left me equipped with the means to assist you on the path to being the best you can be. Whether your goal may be increasing flexibility, gaining greater fitness for sports/playing with your kids, increasing your strength a little or perhaps you simply want to look better in your best clothes, working together we can utilise the best methods of strength and conditioning to get you to where you want to be. I have a calm and laid-back approach to training and consider each day to be an opportunity to learn and grow.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -1.2577263000000585,
                51.7520209
              ],
              "country": "United Kingdom",
              "city": "Oxford",
              "postcode": "OX1 3EX"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7792387057"
          }
        },
        "score": 85,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "alan_griffin",
      "welcome_email_sent": true,
      "password_reset_time": 1489320531955,
      "password_reset_code": "X"
    },
    {
      "_id": {
        "$oid": "58c68e358972aa10a5b1d5f9"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:16:35.228Z"
      },
      "createdAt": 1489407541868,
      "email": "personaltrainer2000@live.com",
      "firstName": "Steve",
      "surname": "Leppert",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "0kRlrACQ02Kvn/idDvOyKw==",
      "hashedPassword": "sIHf+4BwVqxCHm9A7YNVlsiapgo4phs13iqSOfnScCh/CKiTncXhHdgYK7AudcyQXWyALc0HrYcEOW2Y+KxeRw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/iLm7whgi-Photo%20(1).jpg"
        },
        "personal": {
          "disciplines": [
            "Bootcamp",
            "Functional Training",
            "Mobile Trainer",
            "Nutrition",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Level 3: Nutrition",
            "Certified Personal Trainer / Baseball Coach"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 60,
            "max": 75
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 25,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/E0whqpmB-FB_20160404_21_18_26_Saved_Picture.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "AffordableConditioningTraining",
          "yearsOfExp": 2000,
          "affiliation": "I.S.S.A.",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10203012172822998/",
          "biography": "Certified Fitness Trainer since 2000.\nCertified In-Line Sk8 Instructor since 1993\nPrivate Baseball Coach\nACTnow.Isagenix.com / Nutrition web site",
          "offers": "$50 per session paid a week in advance\n5% off - Package of 6 pre-paid\n10% off - Package of 12 pre-paid\n20% off - Package of 24 pre-paid\n25% off - Package of 36 pre-paid",
          "strapLine1": "I.S.S.A. Certified Fitness Trainer / Private Baseball Instructor",
          "locations": [
            {
              "geolocation": [
                -111.88949836355476,
                33.61126534091989
              ],
              "city": "Scottsdale",
              "postcode": "85260",
              "_id": {
                "$oid": "5a600383765eba094646f755"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "phone": {
            "number": "3173853574",
            "code": "+1"
          }
        },
        "score": 80,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "steve_leppert",
      "welcome_email_sent": true,
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "feature_in_area": "none",
      "lastLogin": 1506378837295,
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58c6a70f8972aa10a5b1db7c"
      },
      "lastUpdated": 1489413963206,
      "createdAt": 1489413903164,
      "email": "matt@executive-fitness.com",
      "firstName": "Matt",
      "surname": "Mitchell",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "123d9889b36cc391d2a690703b579e60f077c684",
      "salt": "yalGC+aWlTBWyNAhHqgWMA==",
      "hashedPassword": "tKFseC7Gjd/ioirvub5VP9EABbJXCj3+1kzNU8whkJQZot6jS8dfJ2zObZCRFTB2V+WKA0uliNHuF9a/23UAqA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 40,
            "max": 80
          },
          "companyWebsite": null,
          "profileTitle": null,
          "radius": 30,
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.8262400140928787,
                52.05803317326291
              ],
              "country": "United Kingdom",
              "city": "Greenleys",
              "postcode": "MK12"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7943702166"
          }
        },
        "score": 27
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "matt_mitchell"
    },
    {
      "_id": {
        "$oid": "58c6a9628972aa10a5b1dbbe"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:16:12.560Z"
      },
      "createdAt": 1489414498386,
      "email": "simsevofitness@gmail.com",
      "firstName": "Sam",
      "surname": "Sims",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "GWcTD+vTCNYdA3EFUnAtqg==",
      "hashedPassword": "dIMXXhuaZeRzKzpMyAVox1OcTmm6rDK6b3v8PgEmzI1BURS0GH78UnpRJ8sPNC4ER7vmZS8oAz2IRVGVgqo9BA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qY4HOmpO-evo%20logo.png"
        },
        "personal": {
          "disciplines": [
            "Activity Advisor",
            "Endurance",
            "Gym"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "American Football Coach Level 1"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 25,
            "max": 40
          },
          "companyWebsite": null,
          "profileTitle": "Gym instructor",
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jFliDeUy-evo%20logo.png",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "3583477824.8640130.c539b46c798340288cc7633bc085d1d7",
            "link": "https://www.instagram.com/ev0fit"
          },
          "facebookPage": "Evofiness/",
          "yearsOfExp": 2016,
          "affiliation": "Active IQ",
          "strapLine1": "New to the industry as instructor but been a gym enthusiast for many a year",
          "offers": "TBC",
          "biography": "Im a Wheat and Dairy intolerant person. Personal experience to see what diet can do to you!\n\nPrevious used to be uk size 20 and now a healthy and fit size 12. \n\nHappy to share my experiences with the world!",
          "locations": [
            {
              "geolocation": [
                -2.179403999999977,
                53.00266799999999
              ],
              "city": "Stoke-on-Trent",
              "postcode": "ST4 2RP",
              "_id": {
                "$oid": "5a60036c765eba094646f754"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7792383290",
            "code": "+44"
          }
        },
        "score": 76
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "sam_sims",
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58c6b2c28972aa10a5b1ddc0"
      },
      "lastUpdated": 1490290051693,
      "createdAt": 1489416898890,
      "email": "liza@forzafitness.online",
      "firstName": "Liza",
      "surname": "Smith",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "+PVk4X5BQ43/M5+4dCYIYA==",
      "hashedPassword": "dLIauh7/Pv7z9qho/Lhj/Qr+saDl/rt0WWc8gtfo9wQgGtjEsv7+dqN/pvulAbOa1R5xH5EGSpUXuDviF0ypqg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/W7n2mP2S-fullsizeoutput_2387.jpeg"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Strength & Conditioning",
            "Endurance",
            "Mobile Trainer",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Group Exercise to Music Instructor",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 20,
            "max": 20
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fPeI0VWW-IMG_2425.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "PTLiza_ForzaFit",
            "link": "https://twitter.com/PTLiza_ForzaFit",
            "user_id": "773978115068596224",
            "accessToken": "773978115068596224-Weu7PQbVNCeOiCmm13bEY0wy8FGLdxe",
            "accessTokenSecret": "Jw7pNyxFzjWsmjanatCEF7RgZlZGLc37pl4tfiFD8TwXc"
          },
          "instagram": {
            "access_token": "187675309.8640130.f4b12b813d4e42288446f5a7c9eead6c",
            "link": "https://www.instagram.com/forza_fitness_hants"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155213303926992/",
          "facebookPage": "ForzaFitnessHampshire",
          "yearsOfExp": 2016,
          "affiliation": "REPS",
          "strapLine1": "Do Something Today That FUTURE YOU Will Thank You For",
          "biography": "Results driven Personal Trainer, passionate about  helping people make long lasting and meaningful change. All methods tried and tested and all sessions fully recorded so you can track your progress. \nAbout me - I am a Obstacle Course Racer, Trail and Road Runner and love a Free Weight Gym session.",
          "offers": "Block bookings for PT sessions:\nBook 6 sessions for £100\nBook 12 sessions for £200\n\nWork out and trail with a friend for just £30 per hour.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.9739192104222756,
                50.861423304093016
              ],
              "country": "United Kingdom",
              "city": "Havant",
              "postcode": "PO9 2JP"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7875105416"
          }
        },
        "score": 91,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "liza_smith",
      "welcome_email_sent": true
    },
    {
      "_id": {
        "$oid": "58c6cf888972aa10a5b1e402"
      },
      "lastUpdated": 1493804778307,
      "createdAt": 1489424264438,
      "email": "cunliffepersonaltraining@outlook.com",
      "firstName": "John",
      "surname": "Cunliffe",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "aaa4bf4d03b57ecbde8bea2c1bcb6e93998d8813",
      "salt": "uZoXUw3suVUgnVPGSCDoqw==",
      "hashedPassword": "ie88M+hw50hq2K24ohs5OxgAFpjJsz6BBhgWpbcN8DthFst01CZ3o/kf15KyWAfYMwgpQi5l/RIeiYYanVyPeA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BQW6RDvO-090.JPG"
        },
        "personal": {
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Hiit",
            "Functional Training",
            "Endurance"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: Group Exercise to Music Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 15,
            "max": 30
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cHtcNgsX-110141589393219.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10211532085514475/",
          "affiliation": "Fitpro",
          "biography": "I'm a highly competent and enthusiastic Personal Trainer with a proven ability to help clients achieve their fitness goals.\n\nI possess a successful track record of evaluating a client's physical fitness, understanding their needs and have the skills and knowledge to develop a practical and achievable workout routine for clients to reach their targets.\n\nFun fact: I ran the Liverpool Marathon 2011, BUPA Great Manchester 10K 2012, and 50 Mile Ultra Run for Charity 2014.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -2.4945459980517626,
                53.74984122812748
              ],
              "country": "United Kingdom",
              "city": "Blackburn",
              "postcode": "BB2 1HF"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7784608784"
          }
        },
        "score": 67
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "john_cunliffe"
    },
    {
      "_id": {
        "$oid": "58c7295c8972aa10a5b1f745"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:15:27.051Z"
      },
      "createdAt": 1489447260064,
      "email": "eleonoraberra@yahoo.co.uk",
      "firstName": "Ellie",
      "surname": "Berra",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "1f4083d2ff2e5225c9cdd253b10646f9e4db1d9b",
      "salt": "c3satqeym8fwoLzA9jSQsg==",
      "hashedPassword": "toYDraXE8SHIFmEijBLzPMD80C8CreoFJvHoV7OZ1guOsL6TEqAKM8lvTZqz1TWlhnHbmf1/iTu/l7ougZBGOA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WdqMAD9j-IMG_0039.JPG"
        },
        "personal": {
          "disciplines": [
            "Endurance",
            "Group Classes",
            "Hiit",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 4: Obesity & Diabetes",
            "Level 3: Exercise Referral Instructor",
            "Level 3: Online",
            "Level 2: Gym Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 30,
            "max": 45
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sR03LP1N-IMG_0032.JPG",
            "size": {},
            "offset": 0
          },
          "biography": "Hi , Thank you for stopping by. I am Ellie, a personal trainer. If you are here chances are you have made the decision to look after your body and become a healthier, happier version of yourself to invest in your well-being .\nNot everyone can say that they love their job but I definitely can - I am passionate about fitness. My aim in life is to motivate, guide and inspire you to discover how strong you really are to help you  eliminate the barriers you  might be facing .\nI have not always been into sports; on the contrary, I was overweight for many years of my life, tried every diet and given up lacking the motivation, knowledge and frankly loving food more than I loved myself . Till I  discovered fitness, with a bit of hard  work and self belief,  the impossible became achievable .\nI am determined to share my knowledge and experience to help you achieve your goals.",
          "offers": "Each training session is 1 hour and will be tailored towards reaching your own personal goals. \n​\nTimings: Weekdays 7am - 10pm \nweek -end  contact me\n​\n \n \nFree Consultation:\nOne off session: £40\nFlexible Plans to suit your life style\n \nMonthly Pack: starting from £100\nPersonalised Plans are also available upon request\nOther packages available upon request\n \n \nHome Training\nThere's something quite nice about being able to train in the comfort of your own home contact me for more information\n​\n \nPartner Training\nBring along a friend to share the fun for only £10 more a session.\n \nCorporate Packages available\nPrices available upon request.",
          "strapLine1": "Feel The Fitness Love",
          "locations": [
            {
              "geolocation": [
                -0.31801902461312054,
                51.512753088383064
              ],
              "city": "London",
              "postcode": "W13 8QL",
              "_id": {
                "$oid": "5a60033f765eba094646f753"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7967466282",
            "code": "+44"
          }
        },
        "score": 68
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "ellie_berra",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58c968a48972aa10a5b27216"
      },
      "lastUpdated": 1493903372938,
      "createdAt": 1489594532778,
      "email": "alibenford@yahoo.co.uk",
      "firstName": "Alison",
      "surname": "Benford",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "2420c6f6d42a01d03c8b15871222cb3fb4178c01",
      "salt": "hhpEBaaHPRri2K3SdiqBag==",
      "hashedPassword": "hh/YCVFctToMLdvwGvv27b5zw/0QOhQbk7FlWH0sFZloM+rRoMpto1Zuop9iRe1ycLIS1nM4tzG9WReWNRRy3Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IEeEzjv4-alisonbenfordlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Circuit Training",
            "Strength & Conditioning",
            "Weight Loss",
            "Endurance"
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "max": 30,
            "min": 6
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "strapLine1": "FLEXIBILITY. STRENGTH. ENDURANCE",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fcqoMQow-alisonbenfordbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "I started instructing Piloxing classes in the evenings and loved it so much, that I decided to go on to train as a Fitness Instructor and Personal Trainer, giving up my job at school. I am continuing with my studies and am currently doing Exercise Referral and Sports Specific Conditioning.\n​\nGetting fit and strong helped me to remember how much I love a challenge. It helped me to remember how much joy you can get from trying new things and pushing yourself to do things that you never knew were possible! I never thought that I would be capable of doing a toe loop or a Salchow jump in the ice, yet at the age of 42 years, I can do both and more?! It taught me to love life again. Not only has my general health improved (my immune system seems to fight off all those bugs that the kids bring home) but I now have more energy, more motivation and better relationships with my children. I am able to run around with them, skate with them , do cartwheels again and interact better on all levels because I feel strong and empowered. I have gone on to compete in 2 more British Adult Skating Championships and a number of club competitions, as well as some Mud Runs in freezing temperatures! I am fitter and stronger now than I have ever been! I  am always on the look out for a new challenge!\n​\nNow, I want to help other people to find out what it is they love to do! I want to help people to be the best that they can be and find joy in life. It is never too late to make a change!",
          "offers": "Please visit my website for full details of Prices, Packages & Offers:  https://www.alison-personaltrainer.com/",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -1.9603099999999358,
                52.351897
              ],
              "country": "United Kingdom",
              "city": "Alvechurch",
              "postcode": "B48 7RG"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7790916947"
          }
        },
        "score": 76
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "alison_benford"
    },
    {
      "_id": {
        "$oid": "58c9e1068972aa10a5b28b9e"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:14:22.858Z"
      },
      "createdAt": 1489625350171,
      "providerId": "1292007097542051",
      "provider": "facebook",
      "surname": "Hobi",
      "firstName": "Raymond",
      "email": "raymondtrainerironmind@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1292007097542051/",
          "disciplines": [
            "Bodybuilding",
            "Endurance",
            "Functional Training",
            "Nutrition",
            "Sports Massage"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Older Adults Instructor",
            "Level 2: Gym Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 80
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 25,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sXByaGBf-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "raysmusclemasteryunlimited",
          "yearsOfExp": 1991,
          "affiliation": "ALPHA OMEGA TOTAL FITNESS",
          "offers": "1 person-$30 hour\n2 people-$50 hour\n3 people or more-$75 hour",
          "biography": "Competitive bodybuilder since 1981\nProfessional personal trainer since 1991\nGuaranteed results!",
          "strapLine1": "Fitness For Everyone",
          "locations": [
            {
              "geolocation": [
                -122.121512,
                47.6739881
              ],
              "city": "Redmond",
              "postcode": "98052",
              "_id": {
                "$oid": "5a6002fe765eba094646f752"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "phone": {
            "number": "4259192073",
            "code": "+1"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4U14wgQX-filename.jpeg"
        },
        "score": 79,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "raymond_hobi",
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58ca6a098972aa10a5b2a8e1"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:58:55.405Z"
      },
      "createdAt": 1489660425254,
      "email": "x4less.bolton@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "3Mp1plRc3ax3XoehTkX3qw==",
      "hashedPassword": "QMja6v2CEHLmHBkgL0B+d7xy54Efrqp4XHg3rw5uwpQpX6FQtRUBSH4PX5ahksUfG8R0OQqeQ8UUeKP4sO7H/w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EWhMgRX9-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of state-of-the-art gym equipment",
            "cardio free weights and personal training areas",
            "A combat zone with a boxing ring and MMA cage",
            "Over 200 FREE classes each month",
            "Large fitness studio",
            "Dedicated spin area",
            "Free parking"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/bolton-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1S3Xgzy8-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "At Xercise4less Bolton gym we give you more equipment with over 400 pieces of state of the art gym kit. More space for you, think cardio, free weights, a personal training area and a combat zone with a Boxing ring and MMA Cage. You can train how you want to.\nHere at our gym in Bolton we give you more classes. Over 200 FREE classes each month in our large fitness studio and dedicated spin area. From Zumba to Body pump to GRIT, Piloxing and Yoga. Whatever your motivation, we’ve got a class for you.\nMore advice is always on hand with the very best team of Personal Trainers making Xercise4Less Bolton the number one gym in Bolton. More space, more motivation.\n\nFREE* parking for all our members. One less thing to think about, one more reason to join!\n\n*Parking for Xercise4Less members only. Maximum stay 3 hours.",
          "offers": "More 4 Less:\nJoin now from £9.99 per month\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm \nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri. 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.423379299999965,
                53.5727482
              ],
              "city": "Bolton",
              "postcode": "BL2",
              "_id": {
                "$oid": "59e7500f94e4d86bf9be19bc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Bolton Gym",
          "phone": {
            "code": "+44",
            "number": "1204322828"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58f89829b5799e1be967f9b9"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "xercise4less_bolton_gym",
      "welcome_email_sent": true,
      "lastLogin": 1490134684436,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58ca81a28972aa10a5b2ade3"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:13:59.748Z"
      },
      "createdAt": 1489666466845,
      "email": "david@varietyfitness.co.uk",
      "firstName": "David",
      "surname": "Booth",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "xzQQDLOGK6Mv4imJCNNFRA==",
      "hashedPassword": "uFVhFH3AMFcy9i6PZ3QSVZO2WM8BzW/u52Y8eBiUXbsBEjPBYeR9/tzCr6JQBjcPCPmP0sy466AdVv3olJtpSg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/u0PdW24i-11025830_452691814896279_3491468970783472477_n.jpg"
        },
        "personal": {
          "disciplines": [
            "Hatha",
            "Personal Training",
            "Retreats",
            "Sports Therapy",
            "Workshops"
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Level 3: Yoga Teacher",
            "Level 4: Cardiac Rehab Phase IV Exercise Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 7,
            "max": 135
          },
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kzhAebd2-10559818_467595443405916_8666653280109868557_n.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "davevarietyfit",
            "link": "https://twitter.com/davevarietyfit",
            "user_id": "358365290",
            "accessToken": "358365290-Xgwsj1sxbw4RGG7TLUFVIswZvidLbphpodHcgsWg",
            "accessTokenSecret": "xFA1gnL6qdhZPdilqRaD3tLGMBjREqu3H0QcpOzqoudCn"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154140673632531/",
          "facebookPage": "var",
          "yearsOfExp": 2003,
          "strapLine1": "Yoga & Pilates Studio based in Macclesfield",
          "biography": "We’re a Pilates and Yoga Studio based in the centre of Macclesfield.\n\nRun by myself, David, and my Wife Sarah we offer a variety of holistic classes suitable for everyone from complete beginners to the experienced. Classes are available throughout the week and weekends at a range of times meaning there is always a class for you. A number of membership options are available starting from just £7.50 per week up to unlimited classes from just £12.69 per week.\n\nWith a University educated background in Sport Science our classes are friendly and relaxed.\n\nOur timetable is available on our website, if you would like more information get in touch!\n\nWe also offer Sports massage and Injury Rehabilitation as well as 1-2-1 training.\n\nWe look forward to meeting you!",
          "locations": [
            {
              "geolocation": [
                -2.1192869999999857,
                53.258663
              ],
              "city": "Macclesfield",
              "postcode": "SK10 1JN",
              "_id": {
                "$oid": "5a6002e7765eba094646f751"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7500114256",
            "code": "+44"
          }
        },
        "score": 74,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "david_booth",
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58ca8ba28972aa10a5b2b025"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:13:27.774Z"
      },
      "createdAt": 1489669026381,
      "email": "sarah@varietyfitness.co.uk",
      "firstName": "Sarah",
      "surname": "Booth",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "qZBmybJEX3XgFfKSuhO1CQ==",
      "hashedPassword": "RVjzAO/IghfcQt39ajdIb2jhINoAUR13JIrDCXm1amI1d7RCYo7w02mClf4FUeXEiG2UJ59AwOACsZ4ALYGPoA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SAury9a0-11025830_452691814896279_3491468970783472477_n.jpg"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Pre/post Natal",
            "Reformer",
            "Sports Therapy",
            "Studio"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Pilates Instructor",
            "Level 3: Nutrition"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 7,
            "max": 135
          },
          "companyWebsite": null,
          "profileTitle": "Pilates Instructor",
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AhwODT8r-10922729_440221092810018_3456327749733393227_n.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "Varietyfitness",
            "link": "https://twitter.com/Varietyfitness",
            "user_id": "155262839",
            "accessToken": "155262839-Aoc5K6xIol5s9MgRRAdzZW9lm2bH65gQspfAT9eI",
            "accessTokenSecret": "XG69u4UPd2ET8CveZ0b66vDxyYOTGvqVYm9kLEE42O3Ce"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154140673632531/",
          "facebookPage": "varietyfitness",
          "yearsOfExp": 2003,
          "strapLine1": "Pilates & Yoga Studio in Macclesfield",
          "biography": "We’re a Pilates and Yoga Studio based in the centre of Macclesfield.\n\nRun by myself and my husband David we offer a variety of holistic classes suitable for everyone from complete beginners to the experienced. Classes are available throughout the week and weekends at a range of times meaning there is always a class for you. A number of membership options are available starting from just £7.50 per week up to unlimited classes from just £12.69 per week.\n\nWith a University educated background in Sports Therapy our classes are friendly and relaxed.\n\nOur timetable is available on our website, if you would like more information get in touch!\n\nWe also offer Sports massage and Injury Rehabilitation as well as 1-2-1 training.\n\nWe look forward to meeting you!",
          "locations": [
            {
              "geolocation": [
                -2.1192869999999857,
                53.258663
              ],
              "city": "Macclesfield",
              "postcode": "SK10 1JN",
              "_id": {
                "$oid": "5a6002c7765eba094646f74f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7825159351",
            "code": "+44"
          }
        },
        "score": 74
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "sarah_booth",
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58cb64638972aa10a5b2de66"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:13:05.158Z"
      },
      "createdAt": 1489724515085,
      "surname": "Mendoza",
      "firstName": "Arnold",
      "email": "armmen98@yahoo.com",
      "providerId": "1064710879",
      "provider": "instagram",
      "username": "arnold_mendoza",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1064710879.8640130.ece82ab5d2b844de9fe841226a8ce287",
            "link": "https://www.instagram.com/armmen98"
          },
          "disciplines": [
            "Boxing",
            "Injury Rehab",
            "Personal Training",
            "Physiotherapy",
            "Strength"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Disability",
            "Level 2: First Aid / CPR",
            "Level 2: Older Adults Instructor",
            "Level 2: Gym Instructor",
            "Level 2: Physical Activity for Children Instructor",
            "Physical Therapist"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 60,
            "max": 82
          },
          "companyWebsite": null,
          "profileTitle": "Strength & Conditioning Coach",
          "radius": 52,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pnCdeZJi-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2007,
          "affiliation": "REPS",
          "biography": "Rehab & Conditioning Specialist",
          "locations": [
            {
              "geolocation": [
                55.404468,
                25.2799792
              ],
              "city": "Dubai",
              "_id": {
                "$oid": "5a6002b1765eba094646f74e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "555700820",
            "code": "+971"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HtQyynLc-filename.jpeg"
        },
        "score": 61
      },
      "role": "trainer",
      "activation_code": "d576046e7b092f378f641b208eda8c0f1c702f2e",
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58cff40d8972aa10a5b3d76a"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:58:00.981Z"
      },
      "createdAt": 1490023437858,
      "email": "x4less.bradford@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "o2hz3Z7sS6BnZlLQaL1ayQ==",
      "hashedPassword": "DmKHKOO0V+U5WWvEjp+vUAQ3MzPsiw+mBG58lZ7M29XquvQrUoB8DsGuKYtb8njwKJMAxuP/ZOMiuFOOhhgCZg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FXNe2kjv-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Ladies-only Area",
            "Dance & Exercise Studio",
            "Over 400 pieces of state-of-the-art gym equipment",
            "Extensive cardio resistance and free weight zones",
            "MMA Cage",
            "Large range of free weights (up to 75Kg)",
            "40 FREE classes each week",
            "Boxing ring & punch bags"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/bradford-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fxR66xw3-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "yearsOfExp": 2009,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Just a 10-minute walk from the City Centre and on the doorstop of Bradford University, Xercise4less Bradford is your local community gym. We give you more4less with over 400 pieces of state of the art gym equipment including extensive cardio, resistance and free weight zones. More to train how you want to at our gym in Bradford in our unique combat zone with a boxing ring, MMA Cage and punch bags.\nDedicated to Ladies fitness at the Bradford gym we have a separate Ladies only gym. Enjoy 40 FREE classes each week in our large fitness studio and dedicated spin area. From Zumba and Body pump to GRIT, Piloxing and Yoga, we even have classes for Kids! Whatever your motivation, we’ve got a class for you.\nMore advice is always on hand with the very best team of Personal Trainers and a unique personal training area making Xercise4Less Bradford the number one gym in Bradford. More space, more motivation because you deserve more4less.",
          "offers": "More 4 Less:\nJoin now from £9.99 per month\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm \nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri. 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.77033270000004,
                53.7962563
              ],
              "city": "Bradford",
              "postcode": "BD7 1JE",
              "_id": {
                "$oid": "59e74fd894e4d86bf9be19bb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "xercise4less Bradford Gym",
          "phone": {
            "code": "+44",
            "number": "1274987117"
          }
        },
        "score": 68,
        "parent": {
          "$oid": "58d132b73fa3e80afcab8cf3"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "xercise4less_bradford_gym",
      "welcome_email_sent": true,
      "lastLogin": 1498491240090,
      "agent": {
        "family": "Chrome",
        "major": "58",
        "minor": "0",
        "patch": "3029",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36"
      },
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58cff9448972aa10a5b3d8ac"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:57:21.859Z"
      },
      "createdAt": 1490024772075,
      "email": "x4less.brierleyhill@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "F93s3IcRClv1xqFVBCnvog==",
      "hashedPassword": "rzaXnE7HqRPAHNjylexmQjI2wsRvDvX4SaiB7FxtWxgfEAkszpjskGLod4TYPPiztR468eDdrh/1RS4FknIeFg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UZqduvXe-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Ladies-only Gym",
            "Dance & Exercise Studio",
            "Over 400 pieces of state-of-the-art gym equipment",
            "Extensive cardio resistance and free weight zones",
            "Combat zone with full-size boxing ring & bags",
            "200 FREE classes each month",
            "Dedicated spin area",
            "Classes for kids"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/brierley-hill-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5qkQurc7-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our Brierley Hill gym is one of the biggest and most spacious gyms in the Dudley area.\n\nXercise4less Brierley Hill has over 400 pieces of the very latest gym equipment across dedicated cardio, free weights and resistance training zones. Train in our unique combat zone featuring Boxing bags and a full size Boxing ring. \nWe give you more Ladies fitness at our Brierley Hill gym with a separate Ladies only gym. We have 200 FREE classes each month in our large fitness studio and dedicated spin area. From Zumba and Body pump to GRIT, Piloxing and Yoga, we even have classes for Kids! Whatever your motivation, we’ve got a class for you. \nMore advice is always on hand with the very best team of Personal Trainers and a unique personal training area. Xercise4Less Brierley Hill is your number one gym in the Dudley area. We are Xercise4less because you deserve more4less",
          "offers": "More 4 Less\nJoin now from £9.99 per month\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri. 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "google": "",
          "locations": [
            {
              "geolocation": [
                -2.1221665000000485,
                52.4800877
              ],
              "city": "Brierley Hill",
              "postcode": "DY5 3AB",
              "_id": {
                "$oid": "59e74fb194e4d86bf9be19ba"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Brierley Hill Gym",
          "phone": {
            "code": "+44",
            "number": "1384886800"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "xercise4less_brierley_hill_gym",
      "welcome_email_sent": true,
      "lastLogin": 1490135000877,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58cffe1d8972aa10a5b3d9ae"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:56:44.316Z"
      },
      "createdAt": 1490026013425,
      "email": "x4less.bristol@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "7Zg+enzyp5isVt0ky6uGOg==",
      "hashedPassword": "8obDByqFOWU/RhZJd4ampvav6198LnyaqXWfHsHVqIaz86mhDMPhRtoVZP4Luv42Mx5axM7qgDrYaS9vOYm6FQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aYfqsoW7-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Dance & Exercise Studio",
            "Over 400 pieces of state-of-the-art gym equipment",
            "Separate ladies-only gym",
            "Over 200 free classes each month",
            "Dedicated spinning area",
            "Combat zone with full-size boxing ring & bags",
            "virtual cycling experience with Myride+",
            "More equipment & free weights up to 75Kg"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/bristol-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ny0bQKtA-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Based in the Channons Hill Retail Park in the Fishponds area, Xercise4less is one of the largest gyms in Bristol. With over 400 pieces of state of the art gym equipment across dedicated cardio, extensive free weights and resistance zones you get much more fitness for less in your Bristol gym.\nWe give you a separate Ladies only gym and over 200 free classes each month in our large fitness studio from Zumba to Piloxing, Body Pump to Yoga. Whatever your goal, we’ve got a class for you. A dedicated Spinning area offers the unique virtual cycling experience with Myride+, it’s a virtual revolution in spinning!\nThe Bristol gym has a unique combat training zone with boxing ring and punch bags, your goals are there to be smashed! A team of experienced Personal Trainers offer more advice and more motivation to achieve your goals at Xercise4less Bristol.\n\nWe also have FREE* parking for all our members. \n\n*Parking for Xercise4Less members only. Maximum stay 3 hours.",
          "offers": "More 4 Less:\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri. 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "google": "",
          "locations": [
            {
              "geolocation": [
                -2.5353430000000117,
                51.4802924
              ],
              "city": "Bristol",
              "postcode": "BS16 2EA",
              "_id": {
                "$oid": "59e74f8c94e4d86bf9be19b9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Bristol Gym",
          "phone": {
            "code": "+44",
            "number": "1173707200"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d135f83fa3e80afcab8db7"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "xercise4less_bristol_gym",
      "welcome_email_sent": true,
      "lastLogin": 1490135242660,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d0481d8972aa10a5b3e971"
      },
      "lastUpdated": 1490290021035,
      "createdAt": 1490044957061,
      "email": "ediscaroline@hotmail.com",
      "firstName": "Caroline",
      "surname": "Edis",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "455a184f65112625f2e88e46656b71e9db12d50e",
      "salt": "t9cguu2zuYKcJlpNTGzP+A==",
      "hashedPassword": "NlSYQUBxf/8u7c4sSctHY6pWgNzsqeLky1+/JvmsWHmy4jZkjNC415Pm2LBuOlZ+0VOBueIm/nlXLIbk+4FHTg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NY0tXj1G-IMG_0013.JPG"
        },
        "personal": {
          "disciplines": [
            "Pre/post Natal",
            "Strength & Conditioning",
            "Toning",
            "Circuit Training",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Trainer & Nutrition Coach"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 50,
            "max": 55
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/h0LY3KoL-IMG_2264.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "ediscaroline",
            "link": "https://twitter.com/ediscaroline",
            "user_id": "62300487",
            "accessToken": "62300487-jtSERfhd3DeQvkFig311AGfw2RF7GEnGkmfDX1s7V",
            "accessTokenSecret": "zdTgEE1Jy2Ne17IbZK5dW8n81Wln0pBz10X0Qt3V6g5Yo"
          },
          "instagram": {
            "access_token": "1476283237.8640130.9278984f7c2c47a386f48e0548ef0ebb",
            "link": "https://www.instagram.com/cetraining"
          },
          "facebookPage": "CEdisTraining",
          "yearsOfExp": 2014,
          "affiliation": "REPs",
          "strapLine1": "Helping you get fitter and healthier!",
          "biography": "Previously a City Worker for 10 years, now following my passion in helping others achieve their fitness goals.",
          "offers": "Discounts on blocks of 12 and 15 sessions.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.0905205000000251,
                51.5213236
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "EC1Y"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7766114148"
          }
        },
        "score": 88,
        "parent": null
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "caroline_edis",
      "welcome_email_sent": true
    },
    {
      "_id": {
        "$oid": "58d0e9de8972aa10a5b40bf4"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:55:22.974Z"
      },
      "createdAt": 1490086366929,
      "email": "x4less.burnley@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "9f83e803a81f8a0deb1bb3d90324f4ce7dffddfd",
      "salt": "oVuGGQ+HSOc1XNYfucLdbw==",
      "hashedPassword": "66+s1YAWAxuqIcZQ7Zy7jg9DqsmMJxA2ne6qd5ehw++ck8QGgkSwXcVJCXlFmRHgY1ev1+GyJUTzgwB0XQ0ixQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TBqoFcQ7-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in Burnley",
            "Over 400 pieces of state-of-the-art gym equipment",
            "Unique combat zone with boxing ring & punch bag",
            "Separate ladies-only gym",
            "Over 200 free classes each month",
            "Large fitness studio",
            "Dedicated spin area",
            "Free parking"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/burnley-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ADuIq5az-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "offers": "More 4 Less:\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri. 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "biography": "Close to the city centre with easy rail access and on site free parking Xercise4less is one of the largest gyms in Burnley. Dedicated cardio and free weight zones give you 400 pieces of the very latest gym equipment combined with a unique combat zone with boxing ring and punch bag. We ensure your fitness goals easily become part of your lifestyle.\nOur Burnley gym offers more for Ladies fitness with a separate Ladies only gym. An Xercise4less gym membership includes 200 FREE classes each month in our large fitness studio and dedicated spin area. From Body pump to Boxercise, Zumba and Yoga, we even have classes for Kids! Whatever your motivation, we’ve got a class for you.\nOur experienced team of Personal Trainers are always on hand for more advice and more motivation making Xercise4less Burnley your gym to achieve your goals.\n\nFREE* parking for all our members!\n\n*Parking for Xercise4Less members only. Maximum stay 3 hours.",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "google": "",
          "locations": [
            {
              "geolocation": [
                -2.242683100000022,
                53.7916368
              ],
              "city": "Burnley",
              "postcode": "BB11 1BJ",
              "_id": {
                "$oid": "59e74f3a94e4d86bf9be19b8"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Burnley Gym",
          "phone": {
            "code": "+44",
            "number": "1282501150"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58f89829b5799e1be967f9b9"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "xercise4less_burnley_gym",
      "welcome_email_sent": true,
      "lastLogin": 1490135405692,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d0eda48972aa10a5b40cb6"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:54:45.957Z"
      },
      "createdAt": 1490087332922,
      "email": "x4less.castleford@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "e4e1c1a8a12cd10f1439fca378460c200faf02ce",
      "salt": "bag9+ur0xk9xR8kwiZN3Dw==",
      "hashedPassword": "ysiOAdiRx6dpSaFBPYaS2w5L/oce4xxFSdBSiMKvRf4s9T3JmU56u32bvCNsTajbz55nZt5J7c59FMNd18iU+w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OjHdrciX-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "The largest gym in the Castleford and Pontefract area",
            "Over 300 pieces of state of the art fitness equipment",
            "Overlooks the fabulous Ski-Slope view at Castleford Xscape’s Snozone!",
            "Largest free-weights section in the local area",
            "Dedicated cardio and combat zones",
            "Over 200 free classes each month",
            "Spinning zone",
            "Large fitness studio"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/castleford-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7J7rNcEu-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Situated in the Castleford Xscape complex, Xercise4less is the largest gym in the Castleford and Pontefract area. The Castleford gym contains over 300 pieces of state of the art fitness equipment overlooking the fabulous Ski-Slope view at Castleford Xscape’s Snozone!\nThe Castleford gym offers you a dedicated Cardio and Combat zone and the largest free-weights section in the local area helping you to smash your goals! An experienced Team of Personal Trainers are always on hand to offer more advice and keep you motivated!\nWe give you more4less. Your gym membership includes almost 200 free classes each month in our large fitness studio and spinning zone. From Circuits to Zumba and Piloxing, choose your class, choose your motivation!  We are Xercise4less, because it’s in you, it’s in us.\n\nFREE* parking available for all our members. \n\n*Parking for Xercise4Less members only. Maximum stay 4 hours.",
          "offers": "More 4 Less:\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun 8am-8pm\nOff Peak: 10am-4pm Mon-Fri. 8am-8pm Sat-Sun\nBank Holidays 10am-6pm",
          "google": "",
          "locations": [
            {
              "geolocation": [
                -1.3418964999999616,
                53.7101734
              ],
              "city": "Castleford",
              "postcode": "WF10 4TA",
              "_id": {
                "$oid": "59e74f1594e4d86bf9be19b7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4Less Castleford Gym",
          "phone": {
            "code": "+44",
            "number": "1977512233"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d132b73fa3e80afcab8cf3"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "xercise4less_castleford_gym",
      "welcome_email_sent": true,
      "lastLogin": 1490135583739,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d0fe738972aa10a5b41078"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:53:47.928Z"
      },
      "createdAt": 1490091635279,
      "email": "x4less.darlington@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "e92eac76ad4332d3a91c432de4a3d4979332bc08",
      "salt": "NbYcMoTktfIoNUSbzzN/DA==",
      "hashedPassword": "rawHADptzXKu2ycMc/i7zdARkz80kELBFXrWxs+Rm7uEiTEaV9L5w1GCjwSNaOBQSlDbCizCwbyBzXbB/+d0Sg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fYfRimVS-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in Darlington and County Durham",
            "Over 400 pieces of the very latest gym equipment",
            "Dedicated Cardio and free weights zones",
            "Over 200 FREE gym classes every single month",
            "Free member app - trackable results",
            "Private self-contained ladies-only gym",
            "Unique combat zone with boxing ring and punch bags"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/darlington-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MikGzpVR-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "biography": "On John Street a stones throw from the Odeon cinema Darlington, Xercise4less is one of the largest gyms in Darlington and County Durham.\nXercise4less has over 400 pieces of the very latest gym equipment across dedicated Cardio and free weights zones and a unique Combat zone with Boxing ring, punch bags and an MMA cage. Choose how you smash through your goals and realise your potential!\nOur Darlington gym has a private, self contained ladies only gym and membership includes over 200 FREE gym classes every single month. From Les Mills Body Pump to a Zumba class or the all new PiYo, we've got the fitness class for you. An experienced gym team and personal trainers will always be on hand with more advice, more motivation. We are Xercise4less because you deserve more for less.",
          "offers": "More 4 Less:\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm, Mon-Fri. 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "locations": [
            {
              "geolocation": [
                -1.5519873000000644,
                54.533342
              ],
              "city": "Darlington",
              "postcode": "DL1 1UW",
              "_id": {
                "$oid": "59e74edb94e4d86bf9be19b6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Darlington Gym",
          "phone": {
            "code": "+44",
            "number": "1325526070"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d11ce53fa3e80afcab886d"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "xercise4less_darlington_gym",
      "welcome_email_sent": true,
      "lastLogin": 1490135793013,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d103ba9fb8c0038b65b4b0"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:53:03.872Z"
      },
      "createdAt": 1490092986373,
      "email": "x4less.derby@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "JphOzbSQILH/OvfHm2vLLw==",
      "hashedPassword": "2n0X5bc+srNoPWWfjnCzjUP8CZaH+nwyJhK3UuQZBAfjNwHNfMPGzZ9BCD/gX8FXMPCFrO4ezEQXnMuUEYE2Jw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JgvsgnIJ-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of state-of-the-art gym equipment",
            "Extensive cardio resistance and free-weight zones",
            "A private self contained ladies-only gym",
            "Full-size boxing ring MMA cage and punch bags",
            "Over 200 free classes each month",
            "Large fitness studio",
            "Spin area",
            "Free member app - track your progress"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/derby-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TNgcVUMc-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Located in the heart of Derby City Centre, Xercise4less Derby is one of the biggest gyms in Derby.\nThe Xercise4less gym will have over 400 pieces of state of the art fitness kit across cardio, free weights and resistance training zones and a private, self contained ladies only gym. A full size boxing ring, MMA cage and punch bags give a pure gym zone dedicated to your training.\nThe gym will include access to any Xercise4Less gym across the country. The Derby gym has over 200 FREE fitness classes every month in a large fitness studio and spin area. If it’s an Abs class or Zumba that get’s you to your goal or Yoga after a long day, Xercise4less gives you much more gym for less. \nWe have the very best gym team and personal trainers with more advice to keep you motivated.  Make yourself stronger than your excuses. We believe in you.",
          "offers": "More 4 Less:\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.4750324999999975,
                52.921183
              ],
              "city": "Derby",
              "postcode": "DE1 2AF",
              "_id": {
                "$oid": "59e74eaf94e4d86bf9be19b5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Derby Gym",
          "phone": {
            "code": "+44",
            "number": "1332977387"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "xercise4less_derby_gym",
      "welcome_email_sent": true,
      "lastLogin": 1490135958633,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d10e8b3fa3e80afcab852a"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:52:28.720Z"
      },
      "createdAt": 1490095755416,
      "email": "x4less.doncaster@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "idBqAOFaVMFk1f8CGndhDw==",
      "hashedPassword": "jlyEUQAVKDuR7FWAbE8Ktl6r1yhw/mP2uxQxe97/50EcJty+JjUbfeYR5S2vjxpzNPD65jR4bdhYUTZGwQrKug==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Z1LNgKCW-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Won Budget Gym of The Year at The National Fitness Awards",
            "Over 400 pieces of fitness equipment",
            "Dedicated cardio and free-weights area",
            "Unique Combat zone with boxing ring",
            "Over 200 FREE classes every month",
            "Large fitness studio",
            "Indoor cycling studio",
            "Ladies-only training space"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/doncaster-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HBCh3R32-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "biography": "Be entitled. Join our gym in Doncaster and access any of our clubs nationwide\n\nOur Doncaster Gym, situated on York Road, won Budget Gym of The Year at The National Fitness Awards within the first 5 months of opening! With over 400 pieces of fitness equipment, a dedicated cardio and free-weights area and a boxing ring in our unique Combat zone, it’s not hard to see why.\nWe give you more for less Xercise4less Doncaster gym with over 200 FREE classes every month in our large fitness studio and spinning area. From Zumba to Body Attack, Circuits to Yoga classes, choose your energy burn!\nPersonal Trainers and our experienced team are always on hand to keep you motivated and smashing your goals.  Put your fitness first at our Xercise4Less Doncaster gym. Join today, train today.",
          "offers": "More 4 Less:\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "yearsOfExp": null,
          "affiliation": "",
          "locations": [
            {
              "geolocation": [
                -1.1481642999999622,
                53.53151969999999
              ],
              "city": "Doncaster",
              "postcode": "DN5 8XG",
              "_id": {
                "$oid": "59e74e8c94e4d86bf9be19b4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Doncaster Gym",
          "phone": {
            "code": "+44",
            "number": "1302246363"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d132b73fa3e80afcab8cf3"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490136128617,
      "username": "xercise4less_doncaster_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d11ce53fa3e80afcab886d"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:51:38.587Z"
      },
      "createdAt": 1490099429103,
      "email": "x4less.north.england@shoomon.xyz",
      "firstName": "Regional",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "tqreXFoXnMkeWLE7/s4GPA==",
      "hashedPassword": "9UrNBN+gDPcD39Wta4URYqlKBGB5gUGeo+lD6pxEaE7Ajs31I6NLcAT0OW05pLso0lV6z79sCprdjMKc+NdQeQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8H89dzU5-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Gyms Across The North East"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/S4raa5gJ-fruit-and-veg-from-Frank_Smith.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "facebook": null,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "yearsOfExp": 2011,
          "biography": "Our clubs offer members of all ages the opportunity to exercise and live a healthy lifestyle in a non-intimidating and judgement-free environment, at an affordable cost.\n\nOur aim is to offer the best value for money in the industry. We love it when our customers tell us that they can’t believe the facilities that they get for the price. We are a value brand that has designed its business around passing cost savings on to our customers.\n\nWe are a value brand, but we will not compromise on our customer service. We pride ourselves in giving the best customer service to all our members with fully staffed facilities, and a full class timetable, which is unique in our sector of the market. You will always get a smile from our team. We work very closely with all our members, and ensure we help them in any way possible. We offer everything from non-contract memberships, to the ability of being able to freeze your account when desirable, to ensure we can fit in with your lifestyle.\n\nWith various sites offering a full boxing ring, combat classes and TRX facilities, and with over 400 pieces of equipment at all of our clubs, we offer the best all round member experience in the industry compared to other budget operators.\n\nOur clubs are designed especially with customers in mind, fully air conditioned and have cha​nging room and shower facilities with secure lockers for you to use at your leisure.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times:\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.5797207000000526,
                53.8035059
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "59e74e5a94e4d86bf9be19b3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4Less - The North East",
          "phone": {
            "code": "+44",
            "number": "1132038668"
          }
        },
        "score": 68,
        "parent": {
          "$oid": "58d13cc83fa3e80afcab8f3b"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492686235225,
      "username": "xercise4less_-_the_north_east",
      "password_reset_time": 1490109458973,
      "password_reset_code": "X",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d121543fa3e80afcab892f"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:50:58.650Z"
      },
      "createdAt": 1490100564420,
      "email": "x4less.hounslow@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "edeb47ec644482b1f8c64429b68aa55d399ce34d",
      "salt": "DIAtq2PJcaER61aysEcc2A==",
      "hashedPassword": "oyp4PkoNqMz+dwo4OVXmhyrIT+hVc9SuQI5PoT80yb/f45Iw8B2Y7clp6c6xRKMmyZSWnE67KjYqi8X5og8nfA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PC68ZRJQ-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of the latest equipment",
            "cardio free weights and resistance zones",
            "unique combat zone with boxing ring MMA cage and punch bag",
            "separate ladies-only gym",
            "200 FREE classes each month",
            "spacious fitness studio",
            "spin area"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/hounslow-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tAmNmTO0-fruit-and-veg-from-Frank_Smith.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "yearsOfExp": null,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4Less Hounslow is our first London ​gym. A stones throw from The Treaty Shopping centre and Hounslow Central Tube station makes Xercise4Less a very easy gym to get to. With over 400 pieces of the latest equipment, Cardio, free weights and resistance zones and a unique Combat zone complete with Boxing ring, MMA cage and punch bag, our Hounslow gym is a very cheap gym giving you so much more4less!\n\nWe haven’t finished yet. We include a separate ladies only gym, 200 FREE classes each month in our spacious fitness studio and spin area. From Circuits to Body pump and Zumba to Yoga, however you want to smash your targets, there’s a fitness class for you.\n\nXercise4Less Hounslow is staffed by fitness enthusiasts and experienced Personal Trainers. Every day we want to help you to be better. Contract or non-contract memberships, join today.\n\nWe have FREE* parking for all our members. There are no more excuses.\n\n*Parking for Xercise4Less members only. Maximum stay 3 hours",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times:\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -0.3693522999999459,
                51.4670566
              ],
              "city": "Hounslow",
              "postcode": "TW3 3LF",
              "_id": {
                "$oid": "59e74e3294e4d86bf9be19b2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4Less Hounslow Gym",
          "phone": {
            "code": "+44",
            "number": "2038174080"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d137633fa3e80afcab8df9"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490136281996,
      "username": "xercise4less_hounslow_gym",
      "password_reset_time": 1490109474716,
      "password_reset_code": "X",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d12c423fa3e80afcab8bb1"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:50:18.802Z"
      },
      "createdAt": 1490103362043,
      "email": "x4less.scotland@shoomon.xyz",
      "firstName": "Regional",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "NHXc1LwCju82HJBMkuhSdA==",
      "hashedPassword": "8MUQwK1++LR70/Nx1HCRhCMSxfgkFODn8oAJhpERSisebH+jW5ICazdZKyrS5dn6q+H9Aq7GRxVu28C0w3I5Qw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9zM3YpwF-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Gyms Across Scotland"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0FQMTHv4-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "yearsOfExp": 2011,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our clubs offer members of all ages the opportunity to exercise and live a healthy lifestyle in a non-intimidating and judgement-free environment, at an affordable cost.\n\nOur aim is to offer the best value for money in the industry. We love it when our customers tell us that they can’t believe the facilities that they get for the price. We are a value brand that has designed its business around passing cost savings on to our customers.\n\nWe are a value brand, but we will not compromise on our customer service. We pride ourselves in giving the best customer service to all our members with fully staffed facilities, and a full class timetable, which is unique in our sector of the market. You will always get a smile from our team. We work very closely with all our members, and ensure we help them in any way possible. We offer everything from non-contract memberships, to the ability of being able to freeze your account when desirable, to ensure we can fit in with your lifestyle.\n\nWith various sites offering a full boxing ring, combat classes and TRX facilities, and with over 400 pieces of equipment at all of our clubs, we offer the best all round member experience in the industry compared to other budget operators.\n\nOur clubs are designed especially with customers in mind, fully air conditioned and have cha​nging room and shower facilities with secure lockers for you to use at your leisure.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.5797207000000526,
                53.8035059
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "59e74e0a94e4d86bf9be19b1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less - Scotland",
          "phone": {
            "code": "+44",
            "number": "1132038668"
          }
        },
        "score": 68,
        "parent": {
          "$oid": "58d13cc83fa3e80afcab8f3b"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492686357469,
      "username": "xercise4less_-_scotland",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d132b73fa3e80afcab8cf3"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:49:40.816Z"
      },
      "createdAt": 1490105015314,
      "email": "x4less.wales@shoomon.xyz",
      "firstName": "Regional",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "OEz8UsoGDBFjM2+c5cV8fA==",
      "hashedPassword": "COr+W+PRHp2R6B7E6zn9pJVEsw3Og228/bcY4mMTukUf6ALD7flNs9N41Kqz8EK/yBBGSRxQDndNTP59hngd7Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0Ov4j9Tr-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Gyms Across Yorkshire"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/q6Q2pOSd-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "yearsOfExp": 2011,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our clubs offer members of all ages the opportunity to exercise and live a healthy lifestyle in a non-intimidating and judgement-free environment, at an affordable cost.\n\nOur aim is to offer the best value for money in the industry. We love it when our customers tell us that they can’t believe the facilities that they get for the price. We are a value brand that has designed its business around passing cost savings on to our customers.\n\nWe are a value brand, but we will not compromise on our customer service. We pride ourselves in giving the best customer service to all our members with fully staffed facilities, and a full class timetable, which is unique in our sector of the market. You will always get a smile from our team. We work very closely with all our members, and ensure we help them in any way possible. We offer everything from non-contract memberships, to the ability of being able to freeze your account when desirable, to ensure we can fit in with your lifestyle.\n\nWith various sites offering a full boxing ring, combat classes and TRX facilities, and with over 400 pieces of equipment at all of our clubs, we offer the best all round member experience in the industry compared to other budget operators.\n\nOur clubs are designed especially with customers in mind, fully air conditioned and have cha​nging room and shower facilities with secure lockers for you to use at your leisure.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.5797207000000526,
                53.8035059
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "59e74de494e4d86bf9be19b0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less - Yorkshire",
          "phone": {
            "code": "+44",
            "number": "1132038668"
          }
        },
        "score": 68,
        "parent": {
          "$oid": "58d13cc83fa3e80afcab8f3b"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492686138338,
      "username": "xercise4less_-_yorkshire",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d1348a3fa3e80afcab8d75"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:48:56.444Z"
      },
      "createdAt": 1490105482488,
      "email": "x4less.west.england@shoomon.xyz",
      "firstName": "Regional",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "vfrBrajjDy/ycvf60Pp1kw==",
      "hashedPassword": "mSLWlWxPp2m14VG1Ge8FMym6vGIIVPFJ53xyRVO2GIreGkG8pP6EJh5uEzbmGd19jaIvU51lcL3jEzQ9hxnTbg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NG3UJl9N-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Gyms Across The Midlands"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wxRokUGO-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "yearsOfExp": 2011,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our clubs offer members of all ages the opportunity to exercise and live a healthy lifestyle in a non-intimidating and judgement-free environment, at an affordable cost.\n\nOur aim is to offer the best value for money in the industry. We love it when our customers tell us that they can’t believe the facilities that they get for the price. We are a value brand that has designed its business around passing cost savings on to our customers.\n\nWe are a value brand, but we will not compromise on our customer service. We pride ourselves in giving the best customer service to all our members with fully staffed facilities, and a full class timetable, which is unique in our sector of the market. You will always get a smile from our team. We work very closely with all our members, and ensure we help them in any way possible. We offer everything from non-contract memberships, to the ability of being able to freeze your account when desirable, to ensure we can fit in with your lifestyle.\n\nWith various sites offering a full boxing ring, combat classes and TRX facilities, and with over 400 pieces of equipment at all of our clubs, we offer the best all round member experience in the industry compared to other budget operators.\n\nOur clubs are designed especially with customers in mind, fully air conditioned and have cha​nging room and shower facilities with secure lockers for you to use at your leisure.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.5797207000000526,
                53.8035059
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "59e74db894e4d86bf9be19af"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less - The Midlands",
          "phone": {
            "code": "+44",
            "number": "1132038668"
          }
        },
        "score": 68,
        "parent": {
          "$oid": "58d13cc83fa3e80afcab8f3b"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492690046048,
      "username": "xercise4less_-_the_midlands",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d135f83fa3e80afcab8db7"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:48:15.565Z"
      },
      "createdAt": 1490105848080,
      "email": "x4less.south.england@shoomon.xyz",
      "firstName": "Regional",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "jEl1kJI726Z3G0rikSGpaQ==",
      "hashedPassword": "TN1zb5+zak41A4hNA4+NRDQUJDchQWtscoFPXMqTWMX1CkVUkGKlVs37tkU/51YcUuHu0iI4yifzDPambaK75w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/djU4aYka-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Gyms Across The South West"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VHT1vVHH-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2011,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our clubs offer members of all ages the opportunity to exercise and live a healthy lifestyle in a non-intimidating and judgement-free environment, at an affordable cost.\n\nOur aim is to offer the best value for money in the industry. We love it when our customers tell us that they can’t believe the facilities that they get for the price. We are a value brand that has designed its business around passing cost savings on to our customers.\n\nWe are a value brand, but we will not compromise on our customer service. We pride ourselves in giving the best customer service to all our members with fully staffed facilities, and a full class timetable, which is unique in our sector of the market. You will always get a smile from our team. We work very closely with all our members, and ensure we help them in any way possible. We offer everything from non-contract memberships, to the ability of being able to freeze your account when desirable, to ensure we can fit in with your lifestyle.\n\nWith various sites offering a full boxing ring, combat classes and TRX facilities, and with over 400 pieces of equipment at all of our clubs, we offer the best all round member experience in the industry compared to other budget operators.\n\nOur clubs are designed especially with customers in mind, fully air conditioned and have cha​nging room and shower facilities with secure lockers for you to use at your leisure.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "facebookPage": "Xercise4Less",
          "locations": [
            {
              "geolocation": [
                -1.5797207000000526,
                53.8035059
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "59e74d8f94e4d86bf9be19ae"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less - The South West",
          "phone": {
            "code": "+44",
            "number": "1132038668"
          }
        },
        "score": 68,
        "parent": {
          "$oid": "58d13cc83fa3e80afcab8f3b"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492685864081,
      "username": "xercise4less_-_the_south_west",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d137633fa3e80afcab8df9"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:47:44.655Z"
      },
      "createdAt": 1490106211181,
      "email": "x4less.east.england@shoomon.xyz",
      "firstName": "Regional",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "arLH9Hdrt4NdUMSO1XCf2Q==",
      "hashedPassword": "GhJYRWcuTgspQmyvXP665D43NeMyA4Zv1ipzBFOMweSz/H6nPxuqi/973ZxGYieIlA1seE7RFjLZQioddoUzLA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/B709N36p-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Gyms Across The South East"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2MgzYGKP-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "yearsOfExp": 2011,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our clubs offer members of all ages the opportunity to exercise and live a healthy lifestyle in a non-intimidating and judgement-free environment, at an affordable cost.\n\nOur aim is to offer the best value for money in the industry. We love it when our customers tell us that they can’t believe the facilities that they get for the price. We are a value brand that has designed its business around passing cost savings on to our customers.\n\nWe are a value brand, but we will not compromise on our customer service. We pride ourselves in giving the best customer service to all our members with fully staffed facilities, and a full class timetable, which is unique in our sector of the market. You will always get a smile from our team. We work very closely with all our members, and ensure we help them in any way possible. We offer everything from non-contract memberships, to the ability of being able to freeze your account when desirable, to ensure we can fit in with your lifestyle.\n\nWith various sites offering a full boxing ring, combat classes and TRX facilities, and with over 400 pieces of equipment at all of our clubs, we offer the best all round member experience in the industry compared to other budget operators.\n\nOur clubs are designed especially with customers in mind, fully air conditioned and have cha​nging room and shower facilities with secure lockers for you to use at your leisure.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "google": "https://plus.google.com/115945049033036313902",
          "locations": [
            {
              "geolocation": [
                -1.5797207000000526,
                53.8035059
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "59e74d7094e4d86bf9be19ad"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less - The South East",
          "phone": {
            "code": "+44",
            "number": "1132038668"
          }
        },
        "score": 70,
        "parent": {
          "$oid": "58d13cc83fa3e80afcab8f3b"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492686020541,
      "username": "xercise4less_-_the_south_east",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d13cc83fa3e80afcab8f3b"
      },
      "lastUpdated": {
        "$date": "2017-10-24T10:30:14.069Z"
      },
      "createdAt": 1490107592840,
      "email": "x4less.uk@shoomon.xyz",
      "firstName": "General",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "xy9swz9MiLiPYw+cify6bg==",
      "hashedPassword": "49AgaPRTYApDDndEDWaX/jX5xPupfKng/pMyqYnWHW5gW8x9KwD0GgWjFqk3Xc1Z2RiM6FRua8Mbv1F/lR7VRQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6cgL1TB8-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Gyms across the UK",
            "Changing Rooms",
            "Disabled Facilities",
            "Free Parking",
            "Hot Showers",
            "Lockers",
            "Boxing Ring",
            "Cardio",
            "Dance & Exercise Studio",
            "Ladies-only Gym",
            "Free Weights",
            "Functional Training Zone",
            "Gym",
            "Resistance",
            "Supplements for Sale",
            "MMA CAGE",
            "Personal Training Zone"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nBeGQjTC-BattleRopesLight.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "yearsOfExp": 2011,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our clubs offer members of all ages the opportunity to exercise and live a healthy lifestyle in a non-intimidating and judgement-free environment, at an affordable cost.\n\nOur aim is to offer the best value for money in the industry. We love it when our customers tell us that they can’t believe the facilities that they get for the price. We are a value brand that has designed its business around passing cost savings on to our customers.\n\nWe are a value brand, but we will not compromise on our customer service. We pride ourselves in giving the best customer service to all our members with fully staffed facilities, and a full class timetable, which is unique in our sector of the market. You will always get a smile from our team. We work very closely with all our members, and ensure we help them in any way possible. We offer everything from non-contract memberships, to the ability of being able to freeze your account when desirable, to ensure we can fit in with your lifestyle.\n\nWith various sites offering a full boxing ring, combat classes and TRX facilities, and with over 400 pieces of equipment at all of our clubs, we offer the best all round member experience in the industry compared to other budget operators.\n\nOur clubs are designed especially with customers in mind, fully air conditioned and have cha​nging room and shower facilities with secure lockers for you to use at your leisure.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.5797207000000526,
                53.8035059
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "59e74d4894e4d86bf9be19ac"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less - UK",
          "twitter": {
            "screen_name": "Xercise4Less",
            "link": "https://twitter.com/Xercise4Less",
            "user_id": "126035475",
            "accessToken": "126035475-0dcfYa1XhNrTewhByv75WVan9yXYbzYJt2cpQ6By",
            "accessTokenSecret": "ooTB7xSfBEQZIW6AXB2xffMAQ9Rkx9zNeFyv3de1D1jKQ"
          },
          "instagram": {
            "access_token": "302870052.8640130.a1b419ab18cb4641b4a523f6206e8a32",
            "link": "https://www.instagram.com/xercise4less"
          },
          "facebook": null,
          "phone": {
            "code": "+44",
            "number": "1132038668"
          }
        },
        "score": 73,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1508841014021,
      "username": "xercise4less_-_uk",
      "password_reset_time": 1495628724262,
      "password_reset_code": "X",
      "feature_in_area": "gyms",
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "__v": 10
    },
    {
      "_id": {
        "$oid": "58d146053fa3e80afcab913d"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:46:23.402Z"
      },
      "createdAt": 1490109957942,
      "email": "x4less.falkirk@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "0175cc4714a85f97c02bc4a78bc991fa5bba8e7a",
      "salt": "w3fKTxFmWwCt22AzM8cyLg==",
      "hashedPassword": "8Yx7VEN/m8wvjNP2I43MqZUWN/HAvNEISpuYpPltUWXbzzHtl6km6TNou5dgvuhPuMbWBVuZqJa/sugyJR9Bqg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/h3gqvGoh-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of the very latest gym equipment",
            "Dedicated cardio free weights and resistance training zones",
            "Full size boxing ring",
            "MMA cage and punch bags",
            "Separate ladies-only gym",
            "Large fitness studio",
            "Over 200 free classes each month",
            "A dedicated Spinning area offers the unique virtual cycling experience with Myride+"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/falkirk-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N41S49a9-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Gym memberships to suit all. From £9.99pm. \n\nThe Xercise4Less gym in Falkirk is the gym to join. Our Falkirk gym has over 400 pieces of the very latest gym equipment across dedicated cardio, free weights and resistance training zones. A full size boxing ring, MMA cage and punch bags give a pure gym zone dedicated to training Boxing and Martial Arts.\nXercise4less Falkirk has a separate ladies only gym and a large fitness studio offering over 200 free classes each month from Zumba to Circuits, Body Pump to Yoga. Whatever your goal, we’ve got a class for you. A dedicated Spinning area offers the unique virtual cycling experience with Myride+, it’s a virtual spin revolution!\nExpert advice is always on hand with the very best team of Personal Trainers and friendly gym team.  We are Xercise4less Falkirk, you bring your motivation, we'll smash your goals.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -3.7821305999999595,
                55.9992033
              ],
              "city": "Falkirk",
              "postcode": "FK1 1NT",
              "_id": {
                "$oid": "59e74d1f94e4d86bf9be19ab"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Falkirk Gym",
          "phone": {
            "code": "+44",
            "number": "1324464000"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d12c423fa3e80afcab8bb1"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490136358898,
      "username": "xercise4less_falkirk_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d148ec3fa3e80afcab91bf"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:45:48.923Z"
      },
      "createdAt": 1490110700739,
      "email": "x4less.eastkilbride@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "wF1u/sy7RrOKJ1FjP9Zb1g==",
      "hashedPassword": "TNrESc6Ah/9ACcKlP2KgAEq92opdqydb4QVHJKmaa6ujehf6uA6dP2Y/FpqL6UQ4zt00igHKxTwIl1IqheirsQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PrLP0s7h-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Our very first Xercise4less in Scotland",
            "Over 400 pieces of cutting edge gym equipment",
            "Spacious cardio free weight & functional training zones",
            "Unique combat zone with a full size boxing ring",
            "Separate ladies-only gym",
            "Over 200 FREE monthly gym classes",
            "Large fitness studio",
            "Dedicated Spinning area offers the unique virtual cycling experience with Myride+",
            "Weight-loss advice"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/east-kilbride-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wjq1twLw-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "An award winning gym where members get results!\n\nOur East Kilbride Gym situated on Stroud Road, 5 minutes from the town centre was our very first Xercise4less in Scotland. Membership at our East Kilbride gym gives you over 400 pieces of cutting edge gym equipment across spacious cardio, free weight & functional training zones. A unique Combat zone with a full size Boxing ring and a separate Ladies only gym gives you more fitness for less. Train how you want to.\nThe gym in East Kilbride has over 200 free monthly gym classes in our large fitness studio from Zumba to Piloxing, Body Pump to Yoga. Whatever your goal, we’ve got a class for you. A dedicated Spinning area offers the unique virtual cycling experience with Myride+, it’s a virtual revolution in spinning!\nPersonal Trainers and our experienced team are always on hand to keep you motivated and smashing your goals. Put your fitness first at our Xercise4Less East Kilbride gym. What do you want to achieve today?\n\nFREE* parking available for all our members. We've got it covered.\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -4.1628275000000485,
                55.7517679
              ],
              "city": "East Kilbride",
              "postcode": "G75 0YW",
              "_id": {
                "$oid": "59e74cfc94e4d86bf9be19aa"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less East Kilbride Gym",
          "phone": {
            "code": "+44",
            "number": "1355200360"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d12c423fa3e80afcab8bb1"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490110700966,
      "username": "xercise4less_east_kilbride_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d152073fa3e80afcab93c1"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:44:58.367Z"
      },
      "createdAt": 1490113031636,
      "email": "x4less.edinburghwest@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "0ec6c856cf61c65a728b8980a85450a98686f29c",
      "salt": "g2QJt1PumpnvA/KEXLpn1A==",
      "hashedPassword": "bHsDJivjU1iOEsAA29zdyuD7cZO0+qZekzFk+BLqDpvY4iKs1/5IRg5sRInbVBd+ggdKmo9DgdBQD7TH7m43og==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TlDT0JS0-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Ideal for locals and affordable for students",
            "Over 400 pieces of state-of-the-art gym equipment",
            "cardio resistance and free-weight zones",
            "Unique combat zone complete with boxing ring and punch bags",
            "Separate Ladies Only area",
            "Over 200 FREE classes each month",
            "Spacious fitness studio",
            "Dedicated spinning area",
            "Free classes for Kids!"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/edinburgh-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OZG66c5f-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Award winning gym in the heart of Edinburgh, near Heriot-Watt University\n\nXercise4Less is the gym to join. Ideal for locals. Affordable for students. Over 400 pieces of state of the art equipment across cardio, free weights and a unique Combat zone complete with Boxing ring and punch bags, means this spacious gym is bursting at the seams to give you more fitness for less!\nYour Xercise4Less gym membership includes a separate Ladies Only area and over 200 Free classes each month in our spacious fitness studio and dedicated spinning area.  From Zumba to Boxercise, Circuits to Yoga classes, choose your energy burn! We even have free classes for Kids!\nMore advice is always on hand with the very best team of Personal Trainers and a unique personal training area. Our Edinburgh gym is your gym. We are Xercise4Less because you deserve more4less.\n\nFREE* parking exclusive to all our members. \n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -3.284870299999966,
                55.9153801
              ],
              "city": "Edinburgh",
              "postcode": "EH14",
              "_id": {
                "$oid": "59e74cca94e4d86bf9be19a9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Edinburgh West Gym",
          "phone": {
            "code": "+44",
            "number": "1316037770"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d12c423fa3e80afcab8bb1"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490113031856,
      "username": "xercise4less_edinburgh_west_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d15caf3fa3e80afcab9603"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:44:30.609Z"
      },
      "createdAt": 1490115759966,
      "email": "x4less.glasgowcambridgestreet@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "755bf7a59f7458e461141f29d605d3d1d92d9120",
      "salt": "Fj5jhyUcgp/dgu+9a2hHJg==",
      "hashedPassword": "7k+V5AHUHA45j148F4wY6BL6MKBTeQGDdKH0uFd5H/p+vqtrGWhHmkYK7D0l0onX5/aaj0Fs7VkapDonD2Rmbw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7u9gB2Z5-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in the City of Glasgow",
            "Cardio free weights and resistance training zones",
            "Over 400 pieces of the latest fitness equipment",
            "Separate ladies-only area",
            "Dedicated indoor cycling area",
            "Free exercise classes for kids",
            "Wheel chair accessible entrance",
            "Disabled access lift to mezzanine"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/glasgow-cambridge-street-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/CQ90OOod-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "One of the most spacious gyms in Glasgow\n\nOur Glasgow gym, situated on Cambridge Street, in one of the largest gyms in the City of Glasgow. Whatever your personal fitness goal, our facilities and memberships will get you gym fit. We give you Cardio, free weights and resistance training zones packed full of over 400 pieces of the latest fitness equipment. \nYour gym membership allows you to access any Xercise4Less gym up and down the country. Our gyms offer Personal Training, separate Ladies Only areas, a dedicated indoor cycling studio and 200+ free gym classes each month in our spacious fitness studio.  Join in the fun and book into a group exercise class. We have everything from Zumba to Boxercise, Circuits to Yoga classes, choose your energy burn! We even have classes for free exercise classes for kids!\nMore advice is always on hand with the very best Personal Trainers and super helpful gym team. Xercise4Less is the gym worth joining in Glasgow. With membership from £9.99 a month, there is no excuse. We are Xercise4Less because you deserve more4less.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -4.259765300000026,
                55.8654765
              ],
              "city": "Glasgow",
              "postcode": "G2",
              "_id": {
                "$oid": "59e74cae94e4d86bf9be19a8"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Glasgow Cambridge Street Gym",
          "phone": {
            "code": "+44",
            "number": "1413751775"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d12c423fa3e80afcab8bb1"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490115760197,
      "username": "xercise4less_glasgow_cambridge_street_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d15fd43fa3e80afcab96c5"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:43:46.515Z"
      },
      "createdAt": 1490116564605,
      "email": "x4less.hamilton@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Xk6bqgCnMmz0ek+215+6lA==",
      "hashedPassword": "kmhtG2ktYHXMuZlZo4SlealC9PeAQsIBvNUv0VrEpeao/vinLsnFm1hDtf5K5AoENLreTZ+R6f26ZoJECaLKeQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YQsjTiRb-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Hamilton is one of the largest gyms in Lanarkshire",
            "Over 400 pieces of market-leading fitness equipment",
            "Dedicated cardio free weight and resistance zones",
            "A ladies-only gym",
            "Unique combat zone with a boxing ring and MMA cage",
            "Over 200 FREE classes each month",
            "Spacious fitness and spin studios",
            "Free parking"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/hamilton-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3YgsvASf-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "It's time to be the very best version of you. \n\nLocated in the New Cross Shopping Centre and a stones throw from Hamilton Central Train Station, Xercise4less Hamilton is one of the largest gyms in Lanarkshire.\nThe Hamilton gym has over 400 pieces of market leading fitness equipment across dedicated cardio free weight and resistance zones and a ladies only gym. A unique Combat zone with a Boxing ring and MMA cage let's you train how you want to. \nXercise4Less Hamilton gym membership includes over 200 FREE classes each month in spacious fitness and spin studios. From Les Mills Body Attack to Bootcamp, a Zumba class or Yoga, your goals, your fitness, we’ve got the rest.  There's an experienced gym team and personal trainers on hand to offer more advice, more motivation.\nWe believe you deserve more4less. \n\nFREE* parking available for all our members. No more excuses, grab your gym bag and join!\n\n*Parking for Xercise4Less members only. Maximum stay 2 hours.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -4.036391600000002,
                55.774914
              ],
              "city": "Hamilton",
              "postcode": "ML3",
              "_id": {
                "$oid": "59e74c8294e4d86bf9be19a7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Hamilton Gym",
          "phone": {
            "code": "+44",
            "number": "1698534448"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d12c423fa3e80afcab8bb1"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490116564799,
      "username": "xercise4less_hamilton_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d162603fa3e80afcab9747"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:43:13.610Z"
      },
      "createdAt": 1490117216830,
      "email": "x4less.harlow@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "b8b647eff2b769eee0d838afe2c64f25721de6aa",
      "salt": "7dWxokxf/ujoGi+vwodxeg==",
      "hashedPassword": "YIvAdn3dn9JnPg6aImbmZ+siXyxKMPURWBj0rXlKSlbYEI5QhYmG4Drt56Nzxsn5RIHI+MRZTofa1+G6Zd3jZg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nUNViWB4-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in Harlow Essex",
            "Unique zones for cardio free weights and resistance training",
            "Over 400 pieces of state-of-the-art gym equipment",
            "a private self-contained ladies-only gym",
            "Over 200 FREE classes every month",
            "Dedicated combat zone with an MMA cage boxing ring and punch bags",
            "FREE parking for members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/harlow-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6rIxOECy-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4less gym in Harlow is one of the largest gyms in Harlow, Essex.\n\nOur new Harlow gym is situated in the Harvey Shopping Centre with ample free parking for our Members. We offer you unique zones for cardio, free weights and resistance training containing over 400 pieces of state of the art fitness equipment. Get ripped, lose weight, just do it. Your ability is limitless. \nXercise4less Harlow has a private, self contained ladies only gym and your membership includes over 200 free classes every month. From Yoga to Zumba, Les Mills Body Combat to HIIT, all you need is your motivation. \nYour new Harlow gym has a dedicated Combat zone with an MMA cage, Boxing ring and punch bags. You choose your goals, you choose how you smash them.\nOur excited team of gym staff and experienced Personal Trainers cant wait to meet you.\n \nWe have FREE* parking for all our members. \n\n*Parking for Xercise4Less members only. Maximum stay 2.5 hours. Tickets validated at reception using the Harvey Centre car park only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                0.09358159999999316,
                51.7691903
              ],
              "city": "Harlow",
              "postcode": "CM20",
              "_id": {
                "$oid": "59e74c6194e4d86bf9be19a6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Harlow Gym",
          "phone": {
            "code": "+44",
            "number": "1279213335"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d137633fa3e80afcab8df9"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490117217073,
      "username": "xercise4less_harlow_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d164fb3fa3e80afcab97c9"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:42:29.780Z"
      },
      "createdAt": 1490117883740,
      "email": "x4less.hartlepool@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "12e8d49ac933b1cd866c431c24fde7da9176f038",
      "salt": "45TFTfJtQ9P8RnhUK4EV/A==",
      "hashedPassword": "qhphyAF8w3z1keyXOugSXKc90heFr4Guxi/q0yAlr5Ifn5HHs+iDFDSGBFuc2v56Pl2Kiw4beVQ+36KyJjOwPQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wbebEWhM-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Directly opposite Hartlepool College of Further Education",
            "Over 400 pieces of market-leading fitness equipment",
            "Cardio",
            "free weights and resistance zones",
            "Over 200 FREE classes every month",
            "FREE classes for Children age 3 - 5",
            "X4Kids are available to both members and non members",
            "A private self-contained ladies-only gym",
            "Combat training area with a full size MMA cage Boxing ring and punch bags"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/hartlepool-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Wg4c5DGL-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Situated above H&M in Middleton Grange shopping centre, Xercise4less Hartlepool will be the largest gym in the Hartlepool area. \n\nDirectly opposite Hartlepool College of Further Education, our spacious gym will contain over 400 pieces of market leading fitness equipment across cardio, free weights and resistance zones devoted to your training. You won't be disappointed. \nXercise4less Hartlepool gym membership includes over 200 classes every month including FREE classes for Children age 3 - 5, X4Kids are available to both members and non-members. There's a private, self contained ladies only gym, all you need is your motivation...and your trainers!\nOur Combat training area has a full size MMA cage, Boxing ring and punch bags. Work hard and be proud. #ProgressX4L\nWe understand your fitness has to be part of your lifestyle and our experienced gym team and Personal Trainers are always there to help. Set your goals high, we'll smash them together.\n\nMemberships from £9.99. Don't make excuses, it's time to make results.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.2140964000000167,
                54.6842728
              ],
              "city": "Hartlepool",
              "postcode": "TS24 7RX",
              "_id": {
                "$oid": "59e74c3594e4d86bf9be19a5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Hartlepool Gym",
          "phone": {
            "code": "+44",
            "number": "1429804333"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d11ce53fa3e80afcab886d"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490117883984,
      "username": "xercise4less_hartlepool_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d168183fa3e80afcab988b"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:41:49.976Z"
      },
      "createdAt": 1490118680664,
      "email": "x4less.hull@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "rKCl7WZlx2Kb6vqHieZuhA==",
      "hashedPassword": "T9eU/Mh9n4OofQc6MZ8o5qqAlOr7TF8EDrsFUagAR9PfoP+Dqwgni0x7cGVMIC6bGKjXsp0xCTfTEFSI+VE/Ww==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AG3n7XK5-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in the area",
            "Dedicated cardio Free Weight and Personal Training Zones",
            "Combat zone with a boxing ring and punch bags",
            "A ladies-only gym",
            "Over 200 FREE classes",
            "Spacious studio",
            "Spin area"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/hull-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RHD0hHUN-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Located on Clough Road, Xercise4Less Hull gym is one of the largest gyms in the area. \n\nOur gym in Hull gives you 400 pieces of state of the art equipment and dedicated training zones.  A Cardio Zone, a Free Weight Zone, a Personal Training Zone and a Combat Zone with a Boxing ring and punch bags.\nThere’s a ladies only gym and over 200 FREE classes in the Xercise4Less Hull gym’s spacious studio and spin area. Whether it’s Zumba or Yoga that’s your inspiration or Body attack and GRIT that gets you in the gym, we want you to train how you want to.\nXercise4less Hull is powered by motivation and staffed by the very best Personal Trainers and gym team. Membership starts from £9.99 a month so log off, join now and come work out. We give you more4less.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -0.34840759999997317,
                53.76778290000001
              ],
              "city": "Hull",
              "postcode": "HU5 1QG",
              "_id": {
                "$oid": "59e74c0d94e4d86bf9be19a4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Hull Gym",
          "phone": {
            "code": "+44",
            "number": "1482442222"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d132b73fa3e80afcab8cf3"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490118680874,
      "username": "xercise4less_hull_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d16ac63fa3e80afcab990d"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:41:02.163Z"
      },
      "createdAt": 1490119366838,
      "email": "x4less.leeds@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ezKq6UXajT5BCKxrRuYdEQ==",
      "hashedPassword": "7F6XeZ9jmWf0jv19EV8izHkRpmmLw5DbFSM4K9xtDm3qR9z92kA8pclQlLmnbZPNBhXI1Yzjfy/EhIcWosuapw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/re7yGP2s-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "one of the largest gyms in Leeds",
            "Over 400 pieces of state-of-the-art gym equipment",
            "Extensive free weights resistance areas and personal training zones",
            "A separate ladies-only gym",
            "200 FREE monthly gym classes",
            "Spacious fitness and spin studios",
            "Full size boxing ring MMA cage and punch bags",
            "FREE parking"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/leeds-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wvrIeZ5Y-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our Leeds gym is situated on Kirkstall Road and is one of the largest gyms in Leeds.\n\nHousing 400 pieces of state of the art fitness kit across Cardio, extensive free weights and resistance areas and Personal Training Zone. You train your way.\nXercise4Less Leeds has a separate Ladies only gym. Your gym membership include 200 FREE monthly gym classes in our spacious fitness and spin studios.  From Bootcamp to Les Mills Bodypump, the all new PiYo to Zumba. All you need is motivation, we’ve got everything else. We even have classes for kids!\nA full size boxing ring, MMA cage and punch bags give a gym zone dedicated to martial arts training. Thai boxing tuesdays are on! The gym has more personal trainers, more staff with more advice to keep you motivated.  Make yourself stronger than your excuses.\n\nMembership from £9.99, there is no excuse.\n\nFREE* parking for all our members. Just one more reason to join!\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.5797207000000526,
                53.8035059
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "59e74bde94e4d86bf9be19a3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Leeds Gym",
          "phone": {
            "code": "+44",
            "number": "1138878111"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d132b73fa3e80afcab8cf3"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490119367074,
      "username": "xercise4less_leeds_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d16d453fa3e80afcab998f"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:40:03.063Z"
      },
      "createdAt": 1490120005278,
      "email": "x4less.leedsnorth@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "YbQ9GJMU/CISXAOorp8uyg==",
      "hashedPassword": "+zgMsyQaUX2bUZ2B/SFyiyC1XRaykLuvbuPlfQHdJLd5c0nTEWhs0ewApAl0zMU3wIf55VsOIiwN+L+6ts9SYw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SqNitRuf-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "The largest industry-leading gym in West Yorkshire",
            "Over 400 pieces of state-of-the-art fitness kit",
            "Extensive cardio free weights and resistance-training zones",
            "Unique combat zone with MMA cage boxing ring and punch bags",
            "100m sprint track",
            "Dedicated HIIT circuit",
            "Personal-training zone",
            "Over 400 FREE classes across 3 studios every single month",
            "A private self-contained ladies-only gym",
            "FREE parking"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/leeds-north-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5uK1HbSr-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "It's the largest gym in Leeds. It's your new gym. We're waiting to meet you.  \n\n£0 joining fee and gym memberships from £9.99 a month.\n\nXercise4Less Leeds North is the largest, industry leading, gym in West Yorkshire. The gym will has over 400 pieces of state of the art fitness kit across Cardio, extensive free weights and dedicated resistance training zones.\nWith a unique combat zone with MMA cage, Boxing ring and punch bags and a 100m sprint track and a dedicated HIIT circuit, you smash your goals your way. \nThere's a unique zone, dedicated to Personal Training containing the ultimate fitness equipment.\nYou set goals, we smash them. Be stronger, be better. Show people who you are. Never apologise for being awesome. \nXercise4Less gym membership includes over 400 FREE classes across 3 studios, every single month and a private, self contained ladies only gym. We have an expert gym team always on hand. \nLife has no remote, you have to get up and change it yourself. \n\nWe also have FREE* parking for all our members. Join today, we have everything you need.\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.4615738999999621,
                53.82845210000001
              ],
              "city": "Leeds",
              "postcode": "LS14 1NH",
              "_id": {
                "$oid": "59e74ba394e4d86bf9be19a2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Leeds North Gym",
          "phone": {
            "code": "+44",
            "number": "1134670444"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d132b73fa3e80afcab8cf3"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490120005499,
      "username": "xercise4less_leeds_north_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d170663fa3e80afcab9a51"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:39:17.332Z"
      },
      "createdAt": 1490120806277,
      "email": "x4less.liverpoolbellevale@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "0d8cc8f4862d952bc07a4a24edbcb76040bfa574",
      "salt": "95/qXa7s7TSTRRn4Hwnezg==",
      "hashedPassword": "EzyrILb2NIjMIxowuRPvykqH6CAU+4+AmNP/BxCz0E4yGGjSVC6TwDYRtbBmPBfSsLTCcEk9ehdezx1RjnvT+g==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FaFlbTlo-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in Liverpool",
            "Over 400 pieces of the best fitness equipment",
            "Over 200 FREE fitness classes every month",
            "Huge studio and spin area",
            "Full-size boxing ring MMA cage and punch bags",
            "Cardio zone free weights and functional training",
            "Personal training area",
            "Separate Ladies only gym",
            "FREE parking for members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/liverpool-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3rsUz87t-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Located in Belle Vale Shopping Centre, Xercise4less Liverpool is one of the largest gyms in Liverpool and filled with over 400 pieces of the best fitness equipment. \n\nThere are over 200 FREE fitness classes every month in our huge studio and spin area. Whether it’s the all new Piloxing or Les Mill’s Body Pump, Zumba or Yoga at our Liverpool gym we’ve got a class for you.\nA full size boxing ring, MMA cage and punch bags give a pure gym zone dedicated to your training. There’s a Cardio zone, free weights, functional training and an area for Personal Training at Xercise4Less Liverpool gym.\nWe care about Ladies fitness, train in our separate Ladies only gym. Don’t make excuses, make results. Our Liverpool gym team and experienced Personal Trainers are there to help you reach your goals. We believe in you.\n\nFREE* parking is available for all our members. Join today!\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.85993289999999,
                53.39054119999999
              ],
              "city": "Liverpool",
              "postcode": "L25",
              "_id": {
                "$oid": "59e74b7594e4d86bf9be19a1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Liverpool (Belle Vale) Gym",
          "phone": {
            "code": "+44",
            "number": "1515567111"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58f89829b5799e1be967f9b9"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490120806496,
      "username": "xercise4less_liverpool_(belle_vale)_gym",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58d170c43fa3e80afcab9a53"
      },
      "lastUpdated": {
        "$date": "2017-11-21T12:04:44.527Z"
      },
      "createdAt": 1490120900953,
      "email": "peter@sphereprotectionservices.com",
      "firstName": "Peter",
      "surname": "Ellis",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "dEHgxFf9BDfvVOXxPdvNdA==",
      "hashedPassword": "3CapJNxJ4xOdUowdBzAp5l5ZoUhZxE8+gJLZ5kc8K/B1dvZAuRcg5uVdm5AXalQyorr0F/1v6Wp75Bq6jfpAFA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RoPRC3kZ-Picture2.png"
        },
        "personal": {
          "disciplines": [
            "Close Protection",
            "Corporate Services",
            "CPD Courses",
            "Martial Arts",
            "Self Defense"
          ],
          "qualifications": [
            "Free Parking",
            "Changing Rooms",
            "Sports Hall"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.sphereprotectionservices.com/",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JJMh3UNG-GREY.png",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 1994,
          "affiliation": "ASMAA  & SDF",
          "strapLine1": "Realise your true potential, train with us",
          "biography": "Background of Founder & Chief Instructor:\n \nPeter Ellis as been training and participating in Martial Arts-Combat for over four decades,twenty five of which he as also been instructing.     Holding high ranks in several Martial Arts.\n \n8th Degree Black Sash in Kung Fu(Master Grade)\n8th Dan Black Belt in Sphere Combat(Grand Master Grade)\n2nd Dan in Krav Maga\n1st Dan in ACCS (Military Combat)\nto name a few.\n \n​\n \nSome of the prestigeous positions he holds include:\n \nHead of Combat Training for the ICPTA (International Close Protection Training Association).\n \nCommittee member of I.T.A.C.(International Tactical Authority Committee)\n \nInternational Representative of International Combat Union.\n \nFounder of Sphere Combat  (registered,acknowledged and ratified martial arts/ military combat system)\n \nFounder Sphere Tactical Training\n \nCo-Founder of ICPTA Combatives",
          "offers": "Weekly classes just £6.00 per session\nOne to One lessons from just £25.00 per person\nCorporate courses from just £65.00 per person(1 Day Course)\nPersonal safety and security advice via our app or Skype(POA)",
          "twitter": {
            "screen_name": "sphereprotect",
            "link": "https://twitter.com/sphereprotect",
            "user_id": "762619008516497408",
            "accessToken": "762619008516497408-M0LogPmlvvIkgykUh3dyGQi1o1rDHV3",
            "accessTokenSecret": "7pX8a6jrPTdXMAZzxePu5jfHFTTfNLX5FAcnafzXgW2nC"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154590547957869/",
          "locations": [
            {
              "geolocation": [
                -2.2560081999999966,
                53.0615739
              ],
              "city": "Red Street",
              "postcode": "ST5 7AH",
              "_id": {
                "$oid": "5a14165c2288dd3a30f3f46d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Sphere Combat Systems",
          "phone": {
            "number": "7904604956",
            "code": "+44"
          }
        },
        "score": 72,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1500306756025,
      "username": "sphere_combat_systems",
      "agent": {
        "family": "Chrome",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.115 Safari/537.36"
      },
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d172fa3fa3e80afcab9ad5"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:38:37.409Z"
      },
      "createdAt": 1490121466341,
      "email": "x4less.liverpoolspeke@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "0de1884dd764769fedc19b0330ef3bbe62fe2804",
      "salt": "oPoIWSGVkKN0h2OTxomcrw==",
      "hashedPassword": "HZvrFAXIC+aF13kgeQO/UtwZW5Vdx1CBUsJm2przYtE+kkx6RyVCnUcVchPKlxlk+9Sw5RS+nraF77oPRbLkKw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9e8sDd3j-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Just minutes from Liverpool's John Lennon Airport",
            "Over 400 pieces of state-of-the-art gym equipment",
            "Cardio functional-training and personal-training Zones",
            "Unique combat zone with boxing ring MMA cage & punch bags",
            "Separate ladies-only gym",
            "Large fitness studio",
            "Over 200 FREE classes each month",
            "Dedicated Spinning area",
            "Unique virtual cycling experience with Myride+",
            "Classes for kids"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/liverpool-speke-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5tB04dfB-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4Less Liverpool Speke is situated in Hunts Cross Retail Park just minutes from Liverpool's John Lennon Airport. \n\nThe gym in Liverpool has over 400 pieces of state of the art fitness kit across a Cardio Zone, a functional training and Personal Training Zone and a unique Combat Zone with Boxing ring, MMA cage and punch bags. You train your way.\n\nThe Liverpool Speke gym has a separate Ladies only gym and a large fitness studio hosting over 200 FREE classes each month. Your gym membership includes all of our classes from Bootcamp to Bodypump, the all new PiYo to Zumba, all you need is motivation, we’ll bring everything else.\n\nA dedicated Spinning area offers the unique virtual cycling experience with Myride+, it’s a virtual spin revolution. We even have classes for Kids! Our highly trained Team are devoted to helping you realise your potential. Xercise4Less Liverpool Speke, because it’s in you. It’s in us.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.8671345999999858,
                53.3579403
              ],
              "city": "Liverpool",
              "postcode": "L24 9GB",
              "_id": {
                "$oid": "59e74b4d94e4d86bf9be19a0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Liverpool Speke Gym",
          "phone": {
            "code": "+44",
            "number": "1515591915"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58f89829b5799e1be967f9b9"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490121466566,
      "username": "xercise4less_liverpool_speke_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d175bf3fa3e80afcab9b57"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:37:50.786Z"
      },
      "createdAt": 1490122175455,
      "email": "x4less.livingston@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "PfWpvtD7IUByNaAneyFLgQ==",
      "hashedPassword": "zsqIpTWLqXCRT5z0gv3y3UbdOeAaqhdDvPMdStENJNRPg0E4tnWzcr+DyFCL6q7DbT+rEa5l6Wg1QA8Fn6u4lA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1CqNbO9f-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Large dedicated cardio zone",
            "Huge combat zone containing an MMA cage and boxing ring",
            "A private self-contained ladies-only area",
            "Over 200 FREE classes every single month",
            "Extensive free-weights area",
            "Unique 4FiiT dedicated PT zone"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/livingston-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/d2uiDDVQ-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4Less Livingston is open and we've brought you the largest, market leading, fitness facility in West Lothian. \n\nOur new Livingston Gym is expansive, with a large dedicated cardio zone and a huge combat zone which contains an MMA cage and boxing ring.\n\nWe have a private self contained ladies only area within the gym and over 200 FREE classes every single month for you to enjoy, including Xercise4Kids classes for 3-5 year olds.\n\nWe also have an extensive free weights area and out unique 4FiiT dedicated PT zone to help you be the very best version of you.\n\nWith free nationwide usage you really do get more4less.",
          "offers": "More 4 Less:\n\nJoin now from £6.99 per month for off-peak, single-site membership.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -3.5330348999999615,
                55.8809187
              ],
              "city": "Livingston",
              "postcode": "EH54 7DE",
              "_id": {
                "$oid": "59e74b1e94e4d86bf9be199f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Livingston Gym",
          "phone": {
            "code": "+44",
            "number": "1506537979"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d12c423fa3e80afcab8bb1"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490122175666,
      "username": "xercise4less_livingston_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d1787c3fa3e80afcab9c19"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:37:18.401Z"
      },
      "createdAt": 1490122876934,
      "email": "x4less.manchestermiddleton@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "6071714b91badbb2603ccfc7259e9470962bfbe6",
      "salt": "1uZNTIoMHeyA/X4Mzd/xWQ==",
      "hashedPassword": "wBN2BgtFUFDV40dGrnH3qy3tmmWkiPyJ0S8ajjffKAtlZfbXL8jHxdO0idcPVGIojW8SVws6os07+whlKQ0lxg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aaake5Kj-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Free Classes",
            "Ladies-only areas",
            "Personal training; indoor cycling & free weights",
            "One of the largest state-of-the-art gyms in the Manchester and Rochdale areas",
            "Spacious training zone dedicated to cardio",
            "Pure gym zone for combat: MMA cage; Boxing ring & punch bags",
            "Extensive free-weights and resistance-training areas",
            "Free parking for our members",
            "A Private self-contained ladies-only gym",
            "Peak members get over 200  FREE classes every month"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/middleton-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TmtIsZYk-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Welcome to Xercise4Less Middleton. Be dedicated. Get in the zone. \n\nGym membership from £9.99. Free Classes. Ladies only areas. Personal training, indoor cycling, free weights. Get more4Less at Xercise4Less.\n\nLocated in the Middleton Shopping Centre, Xercise4less is one of the largest, state of the art gyms in the Manchester and Rochdale areas.  \nThe Xercise4Less Middleton gym is home to a spacious training zone dedicated to cardio, a pure gym zone for combat, MMA cage, Boxing ring and punch bags. Our Middleton gym has extensive free weights and resistance training areas. You're going to be so good.\n\nLadies - train with peace of mind in a private, self contained ladies only gym. We'll also give every new Peak gym member over 200 free classes every month from Zumba to indoor cycling, Les Mills BodyPump to PiYo, you just need your motivation. \n\nWe are fully staffed with an expert gym team and Personal Trainers, on hand always.\n\nFREE parking for our members is included whilst you are training! If you were looking for the right time to join the gym, it's now. Do it today.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.2010510000000068,
                53.5482425
              ],
              "city": "Middleton",
              "postcode": "M24 4EL",
              "_id": {
                "$oid": "59e74afe94e4d86bf9be199e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Manchester Middleton Gym",
          "phone": {
            "code": "+44",
            "number": "1616969799"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58f89829b5799e1be967f9b9"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490122877195,
      "username": "xercise4less_manchester_middleton_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d234333fa3e80afcabc41b"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:36:47.868Z"
      },
      "createdAt": 1490170931540,
      "email": "x4less.miltonkeynes@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ecoAI+6ar9g2nSWVcnDnrQ==",
      "hashedPassword": "VPQG6o+f6AYuVCMsN8X2XmhYrxVdlEzhvDqVk06aa22CRlTqnDewWftXFxsLkZQOv97HOBHQpy3KQR8ox+RzjQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zGCtpchf-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "The biggest gym in Milton Keynes",
            "Over 600 pieces of the very latest fitness kit",
            "Dedicated cardio & free weights and resistance training zone",
            "A private ladies-only area",
            "Over 200 FREE fitness classes every month",
            "2 large fitness studios",
            "Spin area",
            "Full-size boxing ring & MMA cage and punch bags"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/milton-keynes-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xGbqRwJO-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "biography": "Gym membership costs from only £6.99 a month. Over 200 gym classes per month. View class timetables.\n\nXercise4Less is the biggest gym in Milton Keynes. You get so much fitness for less with over 600 pieces of the very latest fitness kit. Our gym in MK gives you a dedicated cardio area, free weights and resistance training zone and a private ladies only area.\n\nWe’ve not stopped there. Your gym membership includes over 200 FREE fitness classes every month in 2 large fitness studios and spin area. If it’s an Abs class or Zumba that get’s your heart pumping or Yoga after a long day we are the gym to join in Milton Keynes. Can you think of a reason why not?\n\nA full size boxing ring, MMA cage and punch bags give a pure gym zone dedicated to your training. The gym has more staff with more advice to keep you motivated.  you are stronger than your excuses. Xercise4Less gym membership from £6.99. Join us now.",
          "locations": [
            {
              "geolocation": [
                -0.7629703000000063,
                52.0282716
              ],
              "city": "Milton Keynes",
              "postcode": "MK6 1BN",
              "_id": {
                "$oid": "59e74adf94e4d86bf9be199d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Milton Keynes Gym",
          "phone": {
            "code": "+44",
            "number": "1908774190"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490170931748,
      "username": "xercise4less_milton_keynes_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d238483fa3e80afcabc4dd"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:36:01.519Z"
      },
      "createdAt": 1490171976099,
      "email": "x4less.newcastle-under-lyme@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "a5cde363e794f88030d2a6ced208170ce1673c10",
      "salt": "e5o6VeB3HcLjoF+KCVhueg==",
      "hashedPassword": "byIY7a1GPONiN4GZpqU4pp07bId4VZKGKtXN/9iSNxeUTuDva0RCJ8nTSTpB8PNDofQIzIcZzkR34lUGE0S/Fw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7eDzjAVR-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "The biggest and best budget gym in Newcastle-Under-Lyme",
            "Over 400 pieces of state-of-the-art gym equipment",
            "Cardio and Free weights & Resistance areas",
            "A separate ladies-only gym",
            "Combat zone with boxing ring & punch bags",
            "Over 200 FREE classes every month",
            "Spacious fitness studio and spin area",
            "Kids classes"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/newcastle-under-lyme-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SeFSD9vj-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4Less is the biggest and best budget gym in Newcastle-Under-Lyme.\n\nWe put your fitness first and include over 200 FREE classes every month in our spacious fitness studio and spin area. From Zumba to Circuits, Yoga to Bootcamp, whatever your motivation, we’ve got the fitness class. We even have classes for kids!\n\nAt our Newcastle-Under-Lyme gym there’s over 400 pieces of state of the art equipment across Cardio, Free weights & Resistance areas and a separate Ladeis only gym. A combat zone offers a boxing ring and punch bags and with membership from £9.99, why wouldn’t you?\n\nXercise4less Newcastle-Under-Lyme is powered by motivation and staffed by the very best Personal Trainers and gym team. Join online now, log off and come work out. We give you more4less.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.2234496999999465,
                53.0115019
              ],
              "city": "Newcastle-under-Lyme",
              "postcode": "ST5 1AW",
              "_id": {
                "$oid": "59e74ab194e4d86bf9be199c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Newcastle-Under-Lyme Gym",
          "phone": {
            "code": "+44",
            "number": "1782940777"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490171976317,
      "username": "xercise4less_newcastle-under-lyme_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d23b633fa3e80afcabc59f"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:35:27.404Z"
      },
      "createdAt": 1490172771879,
      "email": "x4less.newcastle-upon-tyne@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "E7qXCrWUfEWv7JRLhpO/3Q==",
      "hashedPassword": "WAsWtXrEer4Pf9rcsYFyKoIUIfQzea81QtnVXD7Uj/Qfz/KgbgqL/qkp2oyXKtlb7xEdoaeTcwXNfknlpd20Iw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AbG4UwwZ-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of state-of-the-art equipment",
            "Cardio and  free-weights & functional-training zones",
            "Personal training zone",
            "Combat zone with Boxing ring and MMA Cage & punch bags",
            "200 FREE gym classes every month",
            "Large fitness studio",
            "Spinning area with the virtual MyRide+ system"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/newcastle-upon-tyne-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/L93EGU7t-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4Less Newcastle is located in Whitley Road Leisure Park and is one of the largest gyms in the area.\n\nThe gym has over 400 pieces of state of the art equipment across 4 training zones; Cardio, Free weights and Functional Training, Personal Training and a Combat Zone complete with Boxing ring, MMA Cage and punch bags.\n\nYour gym membership includes 200 FREE gym classes every month in our large fitness studio from Abs to Bootcamp, Zumba to Yoga and a spinning area which features the revolutionary virtual MyRide+ system. Xercise4Less give you everything you need to smash your fitness goals, all you need is the motivation.\n\nWe are highly trained Personal Trainers and a fully staffed gym team. We are Xercise4less. Because you deserve more for less.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.5384907000000112,
                55.02166709999999
              ],
              "city": "Tyne and Wear",
              "postcode": "NE12 9SR",
              "_id": {
                "$oid": "59e74a8f94e4d86bf9be199b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Newcastle-Upon-Tyne Gym",
          "phone": {
            "code": "+44",
            "number": "1915009070"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d11ce53fa3e80afcab886d"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490172772086,
      "username": "xercise4less_newcastle-upon-tyne_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d23e083fa3e80afcabc621"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:34:51.285Z"
      },
      "createdAt": 1490173448252,
      "email": "x4less.newport@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "HyvMOk1RHp+9lyQVmnQP3w==",
      "hashedPassword": "zdwhFJMZwNhV4IMPj+tA8p7X9ENNQR07+QuuRI2PkSNdevtT/ew8WRbMaQLANQaFqZsa1lBAApR76FbJbqW+uw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YDf6ugeZ-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in Newport",
            "Over 400 pieces of the very latest gym equipment",
            "Dedicated Cardio and free weights & resistance zones",
            "Unique combat zone with boxing ring and MMA cage & punch bags",
            "A private self-contained ladies-only area",
            "Over 200 FREE classes a month",
            "FREE Parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/newport-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/usKoHchv-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Situated on the newly developed Newport Leisure Park and just across from Newport Retail Park Xercise4less Newport is one of the largest gyms in Newport.\n\nXercise4less gym has over 400 pieces of the very latest gym equipment across dedicated Cardio, free weights and resistance zones, a unique Combat zone with Boxing ring, punch bags and an MMA cage. More space, more kit, more for less.\n\nOur Newport Gym has a private, self contained ladies only area and membership includes over 200 classes a month. From Les Mills Body Pump to a Zumba class or the all new PiYo, we've got the fitness class for you to smash through your goals.\n\nWe are Xercise4less because Newport, you deserve more for less.\n\nFREE* parking available. Join now!\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.9435826000000134,
                51.5775701
              ],
              "city": "Newport",
              "postcode": "NP19 4QQ",
              "_id": {
                "$oid": "59e74a6b94e4d86bf9be199a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Newport Gym",
          "phone": {
            "code": "+44",
            "number": "1633744666"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d135f83fa3e80afcab8db7"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490173448470,
      "username": "xercise4less_newport_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d240803fa3e80afcabc6a3"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:34:00.743Z"
      },
      "createdAt": 1490174080370,
      "email": "x4less.northshields@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "PxIJxqJp3B0OEl+JEEBAVQ==",
      "hashedPassword": "RetMw86FvTqCaOA4Mh51XgBrdTjTaVodj4V5VeEF+Yecnh702KoObG59XO2z/FBjq1nB+eotv15aUPP8/ygSHQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PcyZY30Z-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of kit",
            "A separate ladies-only gym",
            "Combat zone with boxing ring & punch bags",
            "Over 200 FREE classes every month",
            "Large fitness studio and spinning area",
            "Cardio and free weights & resistance areas",
            "Free parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/north-shields-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/QkDVH4Lw-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4Less provide state of the art fitness at an affordable price. It's what we do. \n\nGym membership at our North Shields gym gives you over 400 pieces of kit across Cardio, Free weights & Resistance areas and a separate Ladies only gym. A combat zone offers a boxing ring and punch bags and with membership from £9.99, why haven’t you joined yet?\n\nWe give you more for less at our gym in North Shields with over 200 FREE classes every month in our large fitness studio and spinning area. From Zumba to Body Attack, Circuits to Yoga classes, you choose your energy burn!\n\nPersonal Trainers and our experienced team are always on hand to keep you motivated and smashing your goals.  Put your fitness first at our Xercise4Less North Shields gym.  \n\nWe have FREE* parking available. Join today, train today!\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.4653055999999651,
                54.9970869
              ],
              "city": "Royal Quays",
              "postcode": "NE29 6DE",
              "_id": {
                "$oid": "59e74a3894e4d86bf9be1999"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less North Shields Gym",
          "phone": {
            "code": "+44",
            "number": "1915947878"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d11ce53fa3e80afcab886d"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490174080600,
      "username": "xercise4less_north_shields_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d2429c3fa3e80afcabc725"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:31:24.768Z"
      },
      "createdAt": 1490174620174,
      "email": "x4less.nottingham@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "73c85ea319f9608cfec4516cc6305cfbb90d9aa1",
      "salt": "cTwxfwLMekZnrZ2ZhBfeNA==",
      "hashedPassword": "s73K/xQATTRn7a2Vb3hWROf0lKRRsIViq1Fj3uCjo1VynO/8yeN7PRJd3NJVnk8pNgoflRWxy1O21thH5QW2LQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nxzJX9SI-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of the latest fitness equipment",
            "Dedicated cardio area",
            "Seriously-big free-weights and resistance training zones",
            "A private ladies-only area",
            "Full-size Boxing ring and MMA cage & Punch bags",
            "Over 200 FREE classes",
            "Spacious studio and spin area",
            "FREE parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/nottingham-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/agCclmni-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4Less Nottingham gym is located behind Lidl Nottingham, just off Ilkeston Road.\n\nOne of the largest gyms in Nottingham, Xercise4less gives you much more fitness for less with over 400 pieces of the latest fitness equipment. Our Nottingham gym has a dedicated cardio area, seriously big free weights and resistance training zones and a private ladies only area.\n\nPrefer Combat over a treadmill? A full size Boxing ring, MMA cage and Punch bags make Xercise4less Nottingham your gym to realise your potential. Smash your goals.\n\nThe gym in Nottingham includes over 200 FREE classes in our spacious studio and spin area. From Circuits to Zumba, Les Mills’ Bodypump to Yoga, choose how you want to train today.\n\nDedicated Personal Trainers and a energetic gym team are always on hand to help makes Xercise4less the gym to join in Nottingham.\n\nFREE* parking available at Xercise4Less Nottingham.\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.1822121000000152,
                52.9561254
              ],
              "city": "Nottingham",
              "postcode": "NG7 3NY",
              "_id": {
                "$oid": "59e7499c94e4d86bf9be1998"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "xercise4less Nottingham Gym",
          "phone": {
            "code": "+44",
            "number": "1158225560"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490174620382,
      "username": "xercise4less_nottingham_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d248043fa3e80afcabc867"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:30:29.958Z"
      },
      "createdAt": 1490176004563,
      "email": "x4less.renfrew@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "0596dd872a9d67bac23badab913fca62375736fa",
      "salt": "W/iZMbsFPiuF0ndQIw85KQ==",
      "hashedPassword": "8+1gsGdoI/0bsr0l5b+epIJSybNx4tz75IiBJS7q+seZmVNIZ1hG+tHkNft76pmL+yvRC9t61hiJVxktzGp3Rw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ApxXvjjI-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in the Renfrew and Paisley area",
            "Over 400 pieces of state-of-the-art gym equipment",
            "Cardio and free-weights zones",
            "Personal training area",
            "Separate ladies-only gym",
            "Unique combat zone with full size boxing ring and punch bags",
            "Over 200 FREE classes each month",
            "Large fitness studio and dedicated spin area",
            "FREE parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/renfrew-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4UsjKMrD-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4less Renfrew gym is located on Renfrew Retail Park and one of the largest gyms in the Renfrew and Paisley area.\n\nWe give you more fitness for less at our Renfrew gym with over 400 pieces of state of the art gym kit. Cardio and free weights zones, a personal training area and a separate Ladies only gym. A unique Combat zone with a full size Boxing ring and punch bags, because we believe you deserve more4less.\n\nAt Xercise4less Renfrew your gym membership includes over 200 FREE classes each month in our large fitness studio and dedicated spin area. Crunch an Abs class, Zumba your way fit or relax in a Yoga class. It’s your motivation however you want to do it. Because it’s in you, it’s in us.\n\nMore advice is always on hand with the very best team of Personal Trainers and gym team. \n\nXercise4Less Renfrew is your gym. More space, more motivation and more free parking*!\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -4.403071800000021,
                55.8815809
              ],
              "city": "Renfrew",
              "postcode": "PA4 9EN",
              "_id": {
                "$oid": "59e7496594e4d86bf9be1997"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Renfrew Gym",
          "phone": {
            "code": "+44",
            "number": "1412128363"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d12c423fa3e80afcab8bb1"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490176004766,
      "username": "xercise4less_renfrew_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d24a363fa3e80afcabc8e9"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:29:55.588Z"
      },
      "createdAt": 1490176566405,
      "email": "x4less.rotherham@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "bfb18d5d74739c388032646424258c271aec43db",
      "salt": "ctVUoRDlYL18um7Cqju4zg==",
      "hashedPassword": "XgzxOw33OgVtAIG5aWDcbGsBhyUH1aVeZBGhxPosyKDFNtSiI8z5kbimkzfOgLtQjdwbSqKYseiWXH592uNZPw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OVQAX0BJ-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "400 pieces of state-of-the-art gym equipment",
            "Free-weights & Resistance areas",
            "A separate ladies-only gym",
            "Combat zone with boxing ring & punch bags",
            "Over 200 FREE classes per month",
            "Spacious studio and spin area",
            "Free parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/rotherham-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SphJa7pO-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4Less Rotherham is located on Greasborough Street and is the largest gym in Rotherham.\n\nAt Xercsie4less you get more gym for less. There’s 400 pieces of state of the art equipment across Cardio, Free weights & Resistance areas and a separate Ladies only gym. A combat zone offers a boxing ring and punch bags. What’s your pain, feel the gain.\n\nThe gym in Rotherham includes over 200 FREE classes per month in our spacious studio and spin area. Ab’s to Yoga, Les Mills’ Bodypump or the all new PiYo, you choose how you want to train today.\n\nA gym team full of energy and dedicated Personal Trainers and are always on hand to help.  \n\nFREE* parking available for all our members because you deserve more for less.\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.3632539999999835,
                53.4339386
              ],
              "city": "Rotherham",
              "postcode": "S60",
              "_id": {
                "$oid": "59e7494394e4d86bf9be1996"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Rotherham Gym",
          "phone": {
            "code": "+44",
            "number": "1709912255"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d132b73fa3e80afcab8cf3"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490176566614,
      "username": "xercise4less_rotherham_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d24cb13fa3e80afcabc96b"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:28:44.292Z"
      },
      "createdAt": 1490177201625,
      "email": "x4less.rugby@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "N1iMa21e+QWIkrFS3rEInw==",
      "hashedPassword": "wcCFWnlHKmpILnQex0Ygj0rQg0Gk/TDaRGfbI53wTZB81bCALt9Up+k4sDBvBrh2bA/rVCoRXd9nzooPIBSbdw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OPY93NTc-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of state-of-the-art equipment",
            "Cardio and free-weights & Resistance areas",
            "A separate ladies-only gym",
            "Combat zone with boxing ring and MMA cage & punch bags",
            "Over 200 FREE classes every month",
            "Spacious fitness studio and spin area",
            "Classes for kids",
            "Free parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/rugby-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/h4gRWftl-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4less is one of the largest gyms in Warwickshire situated in the lovely market town of Rugby.\n\nAt our Rugby gym there are over 400 pieces of state of the art equipment across Cardio, Free weights & Resistance areas and a separate Ladies only gym.\nA combat zone offers a boxing ring, MMA cage and punch bags.\n\nXercise4less Rugby is powered by a driven gym team and the very best Personal Trainers, you bring the motivation and we’ll do everything else. Membership from £9.99.\n\nThe gym in Rugby puts your fitness first and includes over 200 FREE classes every month in our spacious fitness studio and spin area. From Less Mills’ GRIT to Zumba, Circuits and Yoga. We even have classes for kids! \n\nFitness is not about being better than someone, it’s about being better that who you were yesterday.\n\nFREE* parking available. No excuses, join today!\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.2692328000000543,
                52.3869239
              ],
              "city": "Rugby",
              "postcode": "CV21 1DU",
              "_id": {
                "$oid": "59e748fc94e4d86bf9be1995"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Rugby Gym",
          "phone": {
            "code": "+44",
            "number": "1788220800"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490177201857,
      "username": "xercise4less_rugby_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d24ecf3fa3e80afcabc9ed"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:28:05.156Z"
      },
      "createdAt": 1490177743350,
      "email": "x4less.sheffield@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "5wEa/zRvjSVU087MQHH43Q==",
      "hashedPassword": "zWi+682RAuSd0t0LLY44ASVQsGPg9hlicTVGmbLMKInZWyPdFxeXQTYf7/YAFf9Ex96b8Xx7f8bz2/59eNPR1Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/v2Ke6Vly-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 200 FREE fitness classes every month",
            "Large fitness studio and spin area",
            "A private & self-contained ladies-only area",
            "Over 400 pieces of industry-leading fitness equipment",
            "Dedicated cardio and strength & resistance training zones",
            "Extensive free weights up to 75kg",
            "Full TRX  functional-training rigs",
            "Full-size boxing ring and MMA cage & punch bags"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/sheffield-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/acQgdElF-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4less Sheffield is located on Guernsey Road and is one of the largest gyms in Sheffield and South Yorkshire.\n\nYour gym membership includes over 200 FREE fitness classes every month in a large fitness studio and spin area making Xercise4Less the gym to join in Sheffield. Our Sheffield gym gives you a private, self contained ladies only area. \n\nMembers can enjoy over 400 pieces of industry leading fitness equipment in dedicated cardio, strength and resistance training zones, extensive free weights up to 75kg and full TRX  functional training rigs. A full size boxing ring, MMA cage and punch bags give combat sports enthusiasts a pure gym zone dedicated to your training.\n\nThe gym is powered by an experienced gym team and personal trainers with more advice to keep you motivated.\n\n You are stronger than your excuses. Join today, put your gym bag by the door, get excited.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.471132300000022,
                53.36348109999999
              ],
              "city": "Sheffield",
              "postcode": "S2 4HG",
              "_id": {
                "$oid": "59e748d594e4d86bf9be1994"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Sheffield Gym",
          "phone": {
            "code": "+44",
            "number": "1143999800"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d132b73fa3e80afcab8cf3"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490177743603,
      "username": "xercise4less_sheffield_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d252cc3fa3e80afcabcaaf"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:27:27.057Z"
      },
      "createdAt": 1490178764737,
      "email": "x4less.shrewsbury@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "c70ce16f7c645537942b4263649b43c9e604933c",
      "salt": "AH5k0cNUbY9iT7tYzvk57w==",
      "hashedPassword": "IXISrPemPaHqdAJljKuKorqAWpTvNmpRSWMADxcn3qs1A/1cJmissdOH0fJ/dw3IjoJoo7jZcSeNyLOYw+v+1g==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6Ryd46Ns-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Combat zones with boxing ring and MMA cage & boxing bags",
            "The most extensive free-weights area in Shropshire",
            "Over 400 pieces of the very latest fitness equipment",
            "Pure Cardio zone",
            "Ladies-only gym",
            "200 FREE classes each month",
            "Free parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/shrewsbury-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/o8NKLqd2-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Welcome to our Shrewsbury Gym!\n\nBe the change you want to see in 2017. The time is now. The place is Xercise4Less Shrewsbury. Bring your motivation, we've got everything else.\n\nSituated in the bustling town of Shrewsbury on the Sundorne Retail Park, Xercise4less is the gym to join in Shrewsbury. \n\nOur spacious gym has everything you need to realise your potential. From Combat zones with boxing bags, an MMA cage and a boxing ring to the most extensive free weights area in the Shropshire area. You won’t be disappointed.\n\nThe gym in Shrewsbury has over 400 pieces of the very latest fitness equipment, a pure Cardio zone and ladies only gym. If that’s not enough fitness we include 200 FREE classes each month! From Zumba to Les Mills Body Attack, Yoga or an Abs class, you bring the motivation, we’ve got the rest.\n\nOur experienced gym team and Personal Trainers are always on hand to help. Set your goals high, we won’t stop until you get there. From £9.99 a month, you can’t afford not to join.\n\nWe also have FREE* parking available. We give you more4less.\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.7185292000000345,
                52.7381097
              ],
              "city": "Shropshire",
              "postcode": "SY1",
              "_id": {
                "$oid": "59e748af94e4d86bf9be1993"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Shrewsbury Gym",
          "phone": {
            "code": "+44",
            "number": "1743297400"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490178765071,
      "username": "xercise4less_shrewsbury_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d255593fa3e80afcabcb31"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:26:38.753Z"
      },
      "createdAt": 1490179417076,
      "email": "x4less.smethwick@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "FKpWmesbiwDakvQP2IWCbA==",
      "hashedPassword": "wX0W+qRXfhWhW1h+wZb3NLK9baCihhcOgN4Y3drM3VFyIrejreuYhVVyOEZUH+dIqsBq0HRCh7qPXEwH27wRhQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/r4THcHXN-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of the very latest gym equipment",
            "Exclusive cardio zone",
            "Extensive free-weights and resistance-training areas",
            "Unique combat space with boxing ring and MMA cage & punch bags",
            "A separate ladies-only gym",
            "Over 200 FREE classes each month",
            "Free parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/smethwick-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JPyTSsj5-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4less Smethwick is situated just 5 minutes from the M5 on Sandwell Business Park.\n\nAs one of the biggest gyms in Birmingham we have over 400 pieces of the very latest gym equipment. In our gym in Smethwick you get a zone exclusively for Cardio, extensive free weights and resistance training areas and a unique Combat space with boxing ring, MMA cage and punch bags. More space, more kit, more for less.\n\nThe Smethwick gym has a separate ladies only gym and your gym membership includes over 200 FREE classes each month. Les Mills Body Pump to the all new Piloxing, Zumba your way to person you want to be! We even offer kids classes for some extra family time!\n\nThere’s a dedicated and friendly gym team and experienced Personal Trainers to help along your fitness journey. With membership from only £9.99 per month. \n\nFREE* parking available. Forget tomorrow, do it today.\n\n*Parking for Xercise4Less members only.",
          "offers": "ZERO Joining Fee & First month FREE!! Promo code newyou\n\nDo it now. Make yourself proud. Train, believe, achieve. Realise your potential.\n\nMore 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.9968330000000378,
                52.5050172
              ],
              "city": "Smethwick",
              "postcode": "B66 1QG",
              "_id": {
                "$oid": "59e7487e94e4d86bf9be1992"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Smethwick Gym",
          "phone": {
            "code": "+44",
            "number": "1212727373"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490179417287,
      "username": "xercise4less_smethwick_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d2580c3fa3e80afcabcbf3"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:25:41.823Z"
      },
      "createdAt": 1490180108757,
      "email": "x4less.southend@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "773b679c51153df3a6bfb7ebb38fea7fa4d02f07",
      "salt": "f4CZD3nCqQGVck+Zrt695A==",
      "hashedPassword": "e4OGtIAQyIgyv9UAEvZXpgTv2YvRcoYKNKKPwoCep/Tsk/Yn4uzTmAnVwUuCS9QzkUY+3oQYEtgk2KQuwTMQbw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OKHYZ18t-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "The most-spacious gym in Southend and Rochford",
            "Over 400 pieces of the latest gym kit",
            "Cardio and free-weights zone",
            "Personal-training area",
            "A separate ladies-only gym",
            "Unique combat zone with full-size boxing ring and MMA cage & punch bags",
            "Over 200 FREE classes each month",
            "Large fitness studio",
            "Dedicated spin area"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/southend-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xs2j4aWB-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4less Southend is the most spacious gym in Southend and Rochford.\n\nAt our Southend gym we give you more gym for less with over 400 pieces of the latest gym kit. We put your fitness first with a Cardio and free weights zone, a personal training area and a separate Ladies only gym. There’s a unique Combat zone with a full size Boxing ring, MMA cage and punch bags, you choose your pain.\n\nAt Xercise4less Southend we give you more classes. Over 200 FREE classes each month in our large fitness studio and dedicated spin area. Crunch an Abs class, Zumba your way fit or relax in a Yoga class. It’s your motivation however you want to do it. Because it’s in you, it’s in us.\n\nMore advice is always on hand with the very best gym team and experienced Personal Trainers. Membership from £9.99 a month, join now. Log off, grab your kit and make the change. Do it now.\n\nNever rush fitness. Fitness is the rush.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                0.7185512999999446,
                51.57678279999999
              ],
              "city": "Rochford",
              "postcode": "SS4 1NE",
              "_id": {
                "$oid": "59e7484594e4d86bf9be1991"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Southend Gym",
          "phone": {
            "code": "+44",
            "number": "1702416990"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d137633fa3e80afcab8df9"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490180108985,
      "username": "xercise4less_southend_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d25a4f3fa3e80afcabcc36"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:25:01.366Z"
      },
      "createdAt": 1490180687618,
      "email": "x4less.sthelens@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ySSBUxrS9gktA+2XucFjhw==",
      "hashedPassword": "wcPkPbLT2BjuxsbgnEtuUI8pEQM47NA26JREn9J44mpPexrNkzs2zEepWJ/A+4B76SgWZJyucMfK6j2yY3tSkg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/g2SfgytT-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in St Helens",
            "400 pieces of the very latest gym equipment",
            "Cardio and free-weights zone",
            "Punch bags",
            "A ladies-only gym",
            "200 FREE classes every month for members",
            "Large fitness studio",
            "Spinning area featuring the virtual MyRide+ system",
            "FREE parking for members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/st-helens-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HH1l22x9-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4less is located on Boundary Road a stones throw from Morrisons St Helens. \n\nOur gym is one of the largest gyms in St Helens with 400 pieces of the very latest gym equipment, a cardio and free weights zone as well as punch bags.\n\nXercise4less St Helens gym cares about ladies’ fitness and gives you a ladies only gym. We include 200 FREE classes / month in our large fitness studio from Abs to Bootcamp, Zumba to Yoga and a spinning area which features the revolutionary virtual MyRide+ system. You get much more for less.\n\nWe are highly trained Personal Trainers and a fully staffed gym team. We are Xercise4less because you deserve more for less. Membership from £9.99 a month, there are no more excuses.\n\nFREE* parking for all our members. Do you need another reason to join? Do it today.\n\n*Parking for Xercise4Less members only. Maximum stay 2 hours.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.7517606000000114,
                53.45124449999999
              ],
              "city": "Saint Helens",
              "postcode": "WA10 2PS",
              "_id": {
                "$oid": "59e7481d94e4d86bf9be1990"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less St Helens Gym",
          "phone": {
            "code": "+44",
            "number": "1744410999"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58f89829b5799e1be967f9b9"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490180687852,
      "username": "xercise4less_st_helens_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d25cac3fa3e80afcabccb8"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:24:21.581Z"
      },
      "createdAt": 1490181292277,
      "email": "x4less.stocktononteesnorth@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "fe0a3e2993090e460c872dd24874bb922bc54403",
      "salt": "s15MgPtvq1u2THK5ouh4fA==",
      "hashedPassword": "v4lnjHDUu+WKEix1qRP1eLxx1ZA3P/49YEBXDkP7dLKghEUZrGdlqDQ1Pf8YcCJfF3674k7cZBSsqdi0IAl1LQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7eD5B6Uw-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in Stockton on Tees",
            "Over 400 pieces of high-spec fitness equipment",
            "Dedicated cardio and free-weight zones",
            "A ladies-only gym",
            "Over 200 FREE classes each month",
            "Spacious fitness studio",
            "Spinning area featuring the virtual MyRide+ system"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/stockton-north-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/XBFM2Vn0-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4Less is based above Tesco Stockton on Tees area on Durham Road.\n\nAs one of the largest gyms in Stockton on Tees, we also offer the best value cheap gym membership with over 400 pieces of high spec fitness equipment across dedicated Cardio and Free weight zones.\n\nAt Xercise4less gym Stockton on Tees we put your fitness first. Your gym membership includes a Ladies only gym and over 200 FREE classes each month in our spacious fitness studio, choose you pain from Abs to Bootcamp, Zumba to Yoga and a spinning area which features the revolutionary virtual MyRide+ system.\n\nIt’s your goal, your dream. We believe in you.\n\nMore advice is always on hand with the very best gym team and experienced Personal Trainers. Membership from £9.99 a month. Join now because you can’t think of a reason why you shouldn’t.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.3505476999999928,
                54.59088009999999
              ],
              "city": "Stockton-on-Tees",
              "postcode": "TS19 9PB",
              "_id": {
                "$oid": "59e747f594e4d86bf9be198f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Stockton North Gym",
          "phone": {
            "code": "+44",
            "number": "1642967555"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d11ce53fa3e80afcab886d"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490181292521,
      "username": "xercise4less_stockton_north_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d25f143fa3e80afcabcd7a"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:23:21.898Z"
      },
      "createdAt": 1490181908271,
      "email": "x4less.stocktonsouth@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "7d847466e7bc203f389288302043e8b8e68e13c2",
      "salt": "1yR2iyw5urto1TWBO1kXfw==",
      "hashedPassword": "6J5dkWdecT8s5B+2BDtTXQ0dTrkjej3Lh5fVe2HuD23skMiuqU2ds81RNBcYwuiJPUdxkkJzGBBfgRRdfmiwrw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UMIXaWLS-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in the Stockton on Tees and Middlesbrough area",
            "Over 400 pieces of market-leading fitness equipment",
            "Dedicated cardio & free -eight and resistance zones",
            "Combat zone with boxing ring",
            "A ladies-only gym",
            "Over 200 FREE gym classes every month",
            "Spacious fitness and spin studios",
            "FREE parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/stockton-south-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6M6qT3NS-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Xercise4less is based on New Street. Mandale Triangle and is one of the largest gyms in the Stockton on Tees and Middlesbrough area. \n\nAt Xercise4less we give you a total fitness solution with over 400 pieces of market leading fitness equipment across dedicated cardio, free weight and resistance zones and a Combat zone with a Boxing ring.\n\nYour gym in Teeside includes a ladies only gym and over 200 FREE gym classes per month in our spacious fitness and spin studios. From Les Mills Body Attack to the all new Piloxing, a Zumba class or Yoga, you choose your fitness, we’ll do the rest.\n\nAn experienced gym team and personal trainers are always on hand to offer more advice, more motivation. Membership from £9.99. Your goals, your motivation. We believe in you. We believe you deserve more for less.\n\nFREE* parking also available.\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.3030724000000191,
                54.5571126
              ],
              "city": "Thornaby",
              "postcode": "TS17 6BU",
              "_id": {
                "$oid": "59e747b994e4d86bf9be198e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Stockton South Gym",
          "phone": {
            "code": "+44",
            "number": "1642915151"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d11ce53fa3e80afcab886d"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490181908499,
      "username": "xercise4less_stockton_south_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d261953fa3e80afcabcdfc"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:22:30.284Z"
      },
      "createdAt": 1490182549057,
      "email": "x4less.stokeontrent@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "0a4055027ee158298e6159b1ae54f24c2689735e",
      "salt": "nzTKpMchrp1v+FjefEsOjw==",
      "hashedPassword": "d62FXAskQ1JvT0WtndvBpxKl2MxpU0yRJ+CxPhqvSnudAefpLuJi8ZIhMNjOdFBH/PBUbyib9o3OhWEltQrbsQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nzIqGTWt-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in Stoke on Trent and Staffordshire area",
            "Over 400 pieces of market-leading fitness equipment",
            "A separate ladies-only gym",
            "Gym membership includes over 40 FREE classes each week",
            "Spacious fitness and spin studios",
            "Dedicated cardio and free-weight & resistance zones",
            "Unique combat zone with boxing ring and MMA cage & punch bags",
            "Classes for kids",
            "FREE parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/stoke-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6sS4X7Hi-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Located just off the A50 on China Street in Fenton, Xercise4less Stoke on Trent is one of the largest gyms in Stoke on Trent and Staffordshire area. \n\nWe offer you the best value cheap gym membership with over 400 pieces of market leading fitness equipment across dedicated cardio, free weight and resistance zones. We also have a unique Combat zone with a Boxing ring, Punch bags and an MMA cage\n\nThe gym in Stoke on Trent cares about Ladies with a separate ladies only gym. Your gym membership includes over 40 FREE classes each week in our spacious fitness and spin studios. From Les Mills Body Attack to Bootcamp, a Zumba class or Yoga, we even have classes for kids!\n\nAn experienced gym team and personal trainers are always on hand to offer more advice, more motivation. Membership from £9.99. Your lifestyle, your fitness. We believe in you.\n\nFREE* parking also available. We believe you deserve more for less.\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.1539999999999964,
                52.9960879
              ],
              "city": "Stoke-on-Trent",
              "postcode": "ST4 3NJ",
              "_id": {
                "$oid": "59e7478694e4d86bf9be198d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Stoke on Trent Gym",
          "phone": {
            "code": "+44",
            "number": "1782958777"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490182549303,
      "username": "xercise4less_stoke_on_trent_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d264423fa3e80afcabce7e"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:21:47.145Z"
      },
      "createdAt": 1490183234422,
      "email": "x4less.sunderland@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "c5151bc8794880b24e79de187ef7d3cb6b843c22",
      "salt": "b1FiYV85KFvH3dqg7SLMeQ==",
      "hashedPassword": "QxQj7MUnT/FSFCWZKCTbcW080Epaj4khbZo7L0eOjczfi4TisZ81rCItd2pF6euaJ8WTS3E6pJ22nMVXSH/FQw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8ahNAhbi-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Access over 200 gym classes a month with your membership",
            "One of the most-spacious gyms in Sunderland",
            "Over 400 pieces of the latest gym equipment",
            "Dedicated cardio and free-weight & resistance areas",
            "Personal training zone",
            "Unique combat zone with boxing ring and MMA cage & punch bags",
            "A ladies-only gym",
            "Over 200 FREE classes each month",
            "Spacious fitness and spin studios",
            "The revolutionary virtual MyRide+ system"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/sunderland-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/i4LNA2Ys-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our Sunderland gym offers state of the art facilities with gym memberships from £9.99 per month. Access over 200 gym classes a month with your gym membership.\n\nLocated a stone’s throw from the Stadium of Light and next to Richard Hardie Peugeot, Xercise4less Sunderland gym one of the most spacious gyms in Sunderland. We give you more gym for less with over 400 pieces of the latest gym equipment, dedicated cardio, free weight and resistance areas and a personal training zone. \n\nPrefer Boxing to running then our unique Combat zone with Boxing ring, punch bags and an MMA Cage will help you smash your goals! Xercise4less is powered by an energetic fitness team and experienced Personal Trainers, We are always here with more advice, more motivation. We believe you deserve for for less.  \n\nYour gym membership includes a Ladies only gym and over 200 FREE classes each month in our spacious fitness and spin studios. Choose your motivation from Abs to Bootcamp, Zumba to Yoga or why not try the revolutionary virtual MyRide+ system, choose your city, your bike and feel the burn! \n\nIt’s your goal, your dream. We got this together.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.4002629000000297,
                54.917407
              ],
              "city": "Sunderland",
              "postcode": "SR5 3XA",
              "_id": {
                "$oid": "59e7475b94e4d86bf9be198c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Sunderland Gym",
          "phone": {
            "code": "+44",
            "number": "1915009077"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d11ce53fa3e80afcab886d"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490183234685,
      "username": "xercise4less_sunderland_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d266573fa3e80afcabcf02"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:21:05.071Z"
      },
      "createdAt": 1490183767188,
      "email": "x4less.swansea@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "b6ae81c8eabe7768adc940c62f05be4b98be5d33",
      "salt": "fPVa7BQVq0cmX7W1rzzLqw==",
      "hashedPassword": "6veNPf3TjFVmNzfeZopYtxCQ+xXTXyYD1zoRPG90TfmRK6M9fZTO6nqHZdOwxwmvGv+JE1knTUTQff5mUGlgAQ==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HtRy3Sw5-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of the latest fitness equipment",
            "Dedicated cardio zone",
            "Huge free-weights & resistance training area",
            "A ladies-only gym",
            "Over 200 FREE classes each month",
            "Spacious fitness studio",
            "Spinning area featuring the virtual MyRide+ system",
            "Unique combat zone with boxing ring and MMA Cage & punch bags",
            "FREE parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/swansea-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/COgNY2yR-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Located in Parc Tawe Retail Park Xercise4less is one of the largest gyms in Swansea.\n\nThe gym in Swansea has much more gym for less with over 400 pieces of the latest fitness equipment. The Swansea Xercise4less gym has a dedicated cardio zone and a huge free weights and resistance training area.\n\nYour gym membership includes a Ladies only gym and over 200 FREE classes each month in our spacious fitness studio. Choose your fitness class from Les Mills Bodypump to Zumba or Yoga and a spinning area which features the revolutionary virtual MyRide+ system. It’s your life, your motivation. We'll do the rest.\n\nPrefer Boxing to running then our unique Combat zone with Boxing ring, punch bags and an MMA Cage will help you power through your goals! \n\nAt Xercise4less Parc Tawe Swansea, our energetic fitness team and experienced Personal Trainers are always here with more advice, more motivation. We believe you deserve more for less.  \n \nWe have FREE* parking at Xercise4Less Swansea\n\n*Parking for Xercise4Less members only. Maximum stay 3 hours.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -3.9387285999999904,
                51.62175560000001
              ],
              "city": "Swansea",
              "postcode": "SA1 2AS",
              "_id": {
                "$oid": "59e7473194e4d86bf9be198b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Swansea Gym",
          "phone": {
            "code": "+44",
            "number": "1792341034"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d135f83fa3e80afcab8db7"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490183767389,
      "username": "xercise4less_swansea_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d268743fa3e80afcabcf84"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:19:55.948Z"
      },
      "createdAt": 1490184308058,
      "email": "x4less.wakefield@shoomon.xyx",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "3ea243d403994c7181bd7d38e2bd6b942955f28e",
      "salt": "DYYCfWZeUjOh0LYgzuzsHw==",
      "hashedPassword": "3bt61YMLL5qJ7slN1xsNmHdlNw2aAkkuCJ5+nFwX+kzCQRJXosMhrbRlBL3VAT98unrrA61lSNcu5tZLur3ymA==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/imzvts62-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of the latest fitness equipment",
            "Cardio and free weights zone",
            "Personal-training area",
            "Unique Combat zone with full-size boxing ring",
            "Ladies only gym",
            "Membership includes over 200 FREE classes each month",
            "Large fitness studio and dedicated indoor cycling area",
            "Spin studio",
            "Sprint track",
            "Classes for kids"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/wakefield-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5GgKUMHJ-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "ZERO Joining Fee & First month FREE!! Promo code newyou\n\nWe've just had a £250k refurbishment.\n\nNEW Ladies only gym. NEW Spin Studio. NEW Boxing Ring. NEW Sprint Track and much more new equipment!\n\nXercise4less Wakefield gym is one of the largest gyms in West Yorkshire.\n\nLocated opposite Sainsburys on Denby Dale Road, Xercise4less Wakefield give you more gym for less with over 400 pieces of the latest fitness equipment. We put your fitness first with a Cardio and free weights zone, a personal training area and a unique Combat zone with a full size Boxing ring.\n\nThere’s a Ladies only gym and your membership includes over 200 FREE classes each month in our large fitness studio and dedicated indoor cycling area. Crunch your Abs, choose a Zumba class or stretch your way fit in Yoga. We even have classes for kids! \n\nIt’s your motivation however you want to do it. Because it’s in you, it’s in us and we will find it.\nMore advice is always on hand with the very best gym team and experienced Personal Trainers. \n\nWith Membership from £9.99 a month, it’s time you did now, what you could put off until tomorrow. \n\nWe have FREE* parking. You have no more excuses.\n\n*Parking for Xercise4Less members only.",
          "offers": "ZERO Joining Fee & First month FREE!! Promo code newyou\n\nMore 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.5006369999999833,
                53.6786002
              ],
              "city": "Wakefield",
              "postcode": "WF1 1HR",
              "_id": {
                "$oid": "59e746eb94e4d86bf9be198a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "xercise4less Wakefield Gym",
          "phone": {
            "code": "+44",
            "number": "1924298897"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d132b73fa3e80afcab8cf3"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490184308313,
      "username": "xercise4less_wakefield_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d26afe3fa3e80afcabd006"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:18:46.614Z"
      },
      "createdAt": 1490184958148,
      "email": "x4less.warrington@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "9pzZ0XPl7SXPDqhEF5nbOA==",
      "hashedPassword": "EBd5otBMDVNrlt0ofHu2WjjjRbeM6nepPy1ja3H2m043cGVw2iPhgneg/8qmL65p1oTf2a6wDoLyqgpnzTIvHg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UJOYsr9T-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in the Warrington and Cheshire area",
            "Over 400 pieces of state-of-the-art fitness kit",
            "Dedicated cardio and free-weights & resistance training zones",
            "A separate ladies-only gym",
            "Full-size boxing ring and MMA cage & punch bags",
            "Membership includes over 200 FREE fitness classes every month",
            "Large fitness studio and spin area",
            "FREE parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/warrington-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/yWB89PIO-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Located in the Cockhedge Shopping Centre, Xercise4Less Warrington is one of the largest gyms in the Warrington and Cheshire are.\n\nXercise4less has over 400 pieces of state of the art fitness kit across dedicated cardio, free weights and resistance training zones and a separate ladies only gym. A full size boxing ring, MMA cage and punch bags give a pure gym zone dedicated to your training.\n\nYour gym membership includes over 200 FREE fitness classes every month in a large fitness studio and spin area. If it’s an Abs class or Zumba that get’s you to your goal or Yoga after a long day, \n\nXercise4Less really is the gym to join in Warrington.\nOur Warrington gym is powered by an experienced gym team and personal trainers with more advice to keep you motivated.\n\nMake yourself stronger than your excuses. \n \nWe have FREE* parking. We give you much more for less.\n\n*Parking for Xercise4Less members only. Maximum stay 2.5 hours.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.589907499999981,
                53.3908959
              ],
              "city": "Warrington",
              "postcode": "WA1 2QQ",
              "_id": {
                "$oid": "59e746a694e4d86bf9be1989"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Warrington Gym",
          "phone": {
            "code": "+44",
            "number": "1925377545"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58f89829b5799e1be967f9b9"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490184958354,
      "username": "xercise4less_warrington_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d26cda3fa3e80afcabd049"
      },
      "lastUpdated": {
        "$date": "2017-12-01T17:42:38.900Z"
      },
      "createdAt": 1490185434896,
      "email": "tkfitnesslounge@gmail.com",
      "firstName": "Tommy",
      "surname": "k",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "tSh131DnbNAmaCkZofP4Ig==",
      "hashedPassword": "WbhlNNTVuht3u5UKMmQ/2Qwsla+Zq80cI7xgXxpdwEma62wlXnJb8pDgXTFSxt/ba74sFO/jTvtv2b0UrP7lFQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mDOT4bOq-IMG_0722.JPG"
        },
        "personal": {
          "disciplines": [
            "Mobile Services",
            "Partner Training",
            "Personal Training",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio Stations",
            "Resistance Equipment",
            "Free Weights Selection",
            "Suspension Equipment",
            "Changing Facilities",
            "Studio Rental"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.tkfitnesslounge.com",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4efMSjuY-IMG_0702.JPG",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "443391743.8640130.6d456cfb4c4d4a28bc2d6cce51c8ae11",
            "link": "https://www.instagram.com/tkfitnesslounge"
          },
          "yearsOfExp": 2015,
          "strapLine1": "Get fit now in your own private gym away from home",
          "offers": "Special sign up offer of Dhs 999!!!",
          "biography": "We are a private PT studio located in Al Barsha, Dubai.\n\nSpecialize in Private 1to1 PT sessions or classes.\nThe feel of training in large fitness centers are no more. Let our international team of trainers guide you through the best exercises for the quickest results.\nNo distraction from anyone, our trainers will give you 100% attention and motivation.\n\nThe Lounge is also available to rent for the upmost in Private & Exclusive fitness training.",
          "locations": [
            {
              "geolocation": [
                55.20292180000001,
                25.1105513
              ],
              "city": "Dubai",
              "_id": {
                "$oid": "5a21948ea95f7a0d7e4bda64"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "TK Fitness Lounge",
          "phone": {
            "number": "507840504",
            "code": "+971"
          }
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490187100853,
      "username": "tk_fitness_lounge",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58d26cf73fa3e80afcabd04b"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:17:55.745Z"
      },
      "createdAt": 1490185463575,
      "email": "x4less.wigan@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "E1F9/6d04kbW9waiPwWENg==",
      "hashedPassword": "QeSkU6jtbar3ASgxYJgE6RyZLz7YntwHPUt/2tJ3nHIUUK8W2re+/9tfyNPnFa36JpKB5g2yC/UoEQX0asYsEw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EW2yWSlP-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Over 400 pieces of the latest fitness equipment",
            "Cardio and free weights zone (free weights up to 75 kilograms)",
            "Personal training area",
            "Unique Combat zone with full-size Boxing ring",
            "More than 200 FREE gym classes every month",
            "Large and spacious studio facilities",
            "Dedicated indoor cycling areas",
            "FREE classes for kids",
            "FREE parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/wigan-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/G0BrYZ4K-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "On Chapel Lane in Wigan city centre, close to the train stations and opposite The Range. Xercise4less is the gym to join in Wigan.\n\nThe gym in Wigan has over 400 pieces of the latest fitness equipment across a Cardio and free weights zone (free weights up to 75 kilograms), a personal training area and a unique Combat zone with a full size Boxing ring.\n\nYour membership entitles you to visit any Xercise4Less gym in the UK. You wont be restricted to train solely at the Wigan gym like other gym chains often do. Get a cheap gym membership that'll help you achieve more.\n\nHit your goals. Achieve more. You don't even have to imagine it. Access more than 200 FREE gym classes every month instructed by expert Personal Trainers in large spacious studio facilities. \n\nTour around the club and you'll open up the doors to dedicated indoor cycling areas where you can get involved in virtual classes at any time of day you choose. Feel the pain in Circuits, choose a Zumba class or stretch your way fit in Yoga, we even have FREE classes for kids! It’s your goal however you want achieve it.\n\nBecause it’s in you, it’s in us and we will find it.\nMore advice is always on hand with the very best energetic gym team and experienced Personal Trainers. Memberships from £9.99 a month, we’ve made sure you have no more excuses. Make Xercise4less your Wigan gym today. Go on.\n\nFREE* parking for all our members. No excuses. Join today.\n\n*Parking for Xercise4Less members only. Maximum stay 3 hour",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.6316371000000345,
                53.5442168
              ],
              "city": "Wigan",
              "postcode": "WN1 1DY",
              "_id": {
                "$oid": "59e7467394e4d86bf9be1988"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Wigan Gym",
          "phone": {
            "code": "+44",
            "number": "1942367167"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58f89829b5799e1be967f9b9"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490185463819,
      "username": "xercise4less_wigan_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d26f043fa3e80afcabd0cd"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:16:52.283Z"
      },
      "createdAt": 1490185988027,
      "email": "x4less.wolverhampton@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "YCNa4MRk5ZGyUf6+6kx9uA==",
      "hashedPassword": "RXuijmdv0m2lDePedXSDPdgI2XIg4f8kdqOSs9rjMXJQ2/j243qcEbRoUblQ0oc7oO48YT6SSKUfz/b3aB8Z7A==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Fd0l2nbJ-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "One of the largest gyms in Wolverhampton",
            "Over 400 pieces of market-leading fitness equipment",
            "Dedicated cardio area",
            "Extensive free-weight and resistance zones",
            "Unique combat zone with boxing ring & Punch bags",
            "A separate ladies-only gym",
            "Membership includes over 200 FREE classes each month",
            "Spacious fitness and spin studios",
            "Classes for kids",
            "FREE parking for our members"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/wolverhampton-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9Yh5tPAf-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Located just 5 minutes from Wolverhampton race course in the heart of the local community, Xercise4less is the gym in Wolverhampton.\n\nOne of the largest gyms in Wolverhampton we have over 400 pieces of market leading fitness equipment across in a dedicated cardio area and extensive free weight and resistance zones. There’s a unique Combat zone with a Boxing ring and Punch bags for you to train how you want to.\n \nThe gym in Wolverhampton has a separate ladies only gym, we include over 200 FREE classes each month in our spacious fitness and spin studios. From Les Mills Body Attack to Bootcamp, a Zumba class or Yoga, your lifestyle, your fitness. We’ve got the rest. We even have classes for kids!\n \nOur Xercise4less experienced gym team and personal trainers are always on hand to offer more advice, more motivation.\n\nMembership from £9.99. Your goals, your motivation. We believe in you because you deserve more for less.\n \nFREE* parking for all our members. No more excuses, join now.\n\n*Parking for Xercise4Less members only.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -2.144879899999978,
                52.59453019999999
              ],
              "city": "Wolverhampton",
              "postcode": "WV6 0QZ",
              "_id": {
                "$oid": "59e7463494e4d86bf9be1987"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less Wolverhampton Gym",
          "phone": {
            "code": "+44",
            "number": "1902907444"
          }
        },
        "score": 65,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490185988352,
      "username": "xercise4less_wolverhampton_gym",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58d29feb3fa3e80afcabdb5d"
      },
      "lastUpdated": {
        "$date": "2017-11-21T11:36:39.610Z"
      },
      "createdAt": 1490198507979,
      "email": "no1fit.towerbridge@shoomon.xyz",
      "firstName": "No1 Fitness",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "79608436bd9ecb8a94e12fd34426b64aed2a2cd5",
      "salt": "zcN7Z5Mp9swdfKzPtHky9Q==",
      "hashedPassword": "Cyx6JNbIt02asHKvheSux3xW+udcPCp8NFVSbY/0hTbizEnutlRvgxUlWADAiKXoxwRuJ0rx+e44yFS2TaAjFQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DF9RSXn4-no1fitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Body Transformation",
            "Diet/Meal Plans",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Rig",
            "Suspension Equipment",
            "Changing Facilities"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.no1fitness.co.uk/our-studios/tower-bridge-studio/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/psVxh6kl-no1fitnessbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "No1fitnessPersonalTraining",
          "strapLine1": "Welcome to No1 Fitness, London's Leading Personal Training Service - helping YOU to look better naked!",
          "biography": "No1 Fitness Tower Bridge Studio\n\nThe first of our No1 Fitness studios, this one will always hold a special place in our heart! Not only this, but it has just undergone a refurbishment, giving our oldest studio a whole new lease of life. \n\nOffering everything from one-on-one PT sessions to group exercise 'fitness slots', we work with a host of local residents here, who drop in alongside the many working professionals in the area.\n\nEquipment includes everything from TRX to Power Plate, boxing bags, weights and more. As with all our studios, we provide the towels and shower gels, so you can go about your day without being weighed down by those extras.",
          "offers": "Please visit our website for details of prices, packages & offers.",
          "twitter": {
            "link": "https://twitter.com/no1fitness"
          },
          "instagram": {
            "link": "https://www.instagram.com/no1_fitness/?hl=en"
          },
          "locations": [
            {
              "geolocation": [
                -0.07893379999995886,
                51.500485
              ],
              "city": "London",
              "postcode": "SE1 3JB",
              "_id": {
                "$oid": "5a140fc72288dd3a30f3f466"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "No1 Fitness Tower Bridge Studio",
          "phone": {
            "number": "2074036660",
            "code": "+44"
          },
          "testimonials": "Steve is editor of Lifestyle at CITYAM.\n\nAnother amazing transformation at No1 Fitness. Steve has just completed 10 weeks with Harry and has achieved some amazing results.\n\nSteve came to us without having exercised for a good few years. He worked long hours and found himself drinking at least 3 nights a week with friends. Steve had decided, enough was enough and agreed to follow our advice strictly for 10 weeks and has now achieved some impressive results. He trained 4 times a week, followed a No1 Fitness nutrition plan and most importantly, did not have a single drop of alcohol throughout. This just shows what you can achieve when you put your mind to something. Well done Steve.\n\n\"Fitness challenge: the results after 10 weeks\" \n\nArthur came to one of our fitness studios (Tower Bridge) and started his journey with personal trainer Rupert .\n\nHis body transformation took him 16 weeks also including a 2 week holiday. After 54 sessions and around 201000 calories later, Arthur achieved his body goals, from 84.7kg to 77.4kg. \n\nDuring his journey Arthur mentioned to his trainer how it positively \"changed\" his life. The whole experience permeated through other areas of his life, he felt less stressed at home and more focused at work.\n\n\"This is why I always say it's more than just a body transformation\" - Rupert commented.\n\nWhen arriving at No1 Fitness it was easy to see that Patrick was not in bad shape, however he had a goal in mind: to lose body fat and build some muscle."
        },
        "score": 73,
        "parent": {
          "$oid": "58d2bb193fa3e80afcabe121"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490262679633,
      "username": "no1_fitness_tower_bridge_studio",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58d2b18a3fa3e80afcabdf1f"
      },
      "lastUpdated": {
        "$date": "2017-11-21T11:32:12.582Z"
      },
      "createdAt": 1490203018188,
      "email": "no1fit.city@shoomon.xyz",
      "firstName": "No1 Fitness",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "YHl5WCWdMN/uZrA7kdYTDA==",
      "hashedPassword": "fIpWrSXjVnI6flgJ+Rocm0Ry0cNF8s5bu2+ZsK+rv+waPMkVyy57BrL+6p7nwf0wY98bujOmrkbm8lkLpPmpoA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sGFR806u-no1fitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Body Transformation",
            "Diet/Meal Plans",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Rig",
            "Suspension Equipment",
            "Changing Facilities"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.no1fitness.co.uk/our-studios/the-city-studio/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rPx542Ng-no1fitnessbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "No1fitnessPersonalTraining",
          "strapLine1": "Welcome to No1 Fitness, London's Leading Personal Training Service - helping YOU to look better naked!",
          "biography": "Situated next to that iconic landmark, The Gherkin, and just round the corner from Bank and Liverpool Street station, our City studio is busy from 6 in the morning to 10pm at night. \n\nOffering everything from one-on-one PT sessions, to group exercise 'fitness slots', this is a great, central studio catering for every fitness goal.\n\nEquipment includes everything from TRX to Power Plate and a whole host of weights. This is also home to lots of nutritional consultations, with clients dropping in regularly throughout the week to ensure they stay on track. As with all our studios, we also advise on supplements and food delivery services here.\n\nOur high-end changing facilities mean showering and returning to work is a breeze. We supply towels and shower gels, so no need to carry around in your gym bag.",
          "offers": "Please visit our website for details of prices, packages & offers.",
          "twitter": {
            "link": "https://twitter.com/no1fitness"
          },
          "instagram": {
            "link": "https://www.instagram.com/explore/locations/264016272/no1-fitness/?hl=en"
          },
          "locations": [
            {
              "geolocation": [
                -0.07994510000003174,
                51.5151595
              ],
              "city": "London",
              "postcode": "EC3A 8FR",
              "_id": {
                "$oid": "5a140ebc2288dd3a30f3f462"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "No1 Fitness City Studio",
          "phone": {
            "number": "2076211312",
            "code": "+44"
          },
          "testimonials": "Steve is editor of Lifestyle at CITYAM.\n\nAnother amazing transformation at No1 Fitness. Steve has just completed 10 weeks with Harry and has achieved some amazing results.\n\nSteve came to us without having exercised for a good few years. He worked long hours and found himself drinking at least 3 nights a week with friends. Steve had decided, enough was enough and agreed to follow our advice strictly for 10 weeks and has now achieved some impressive results. He trained 4 times a week, followed a No1 Fitness nutrition plan and most importantly, did not have a single drop of alcohol throughout. This just shows what you can achieve when you put your mind to something. Well done Steve.\n\n\"Fitness challenge: the results after 10 weeks\" \n\nArthur came to one of our fitness studios (Tower Bridge) and started his journey with personal trainer Rupert .\n\nHis body transformation took him 16 weeks also including a 2 week holiday. After 54 sessions and around 201000 calories later, Arthur achieved his body goals, from 84.7kg to 77.4kg. \n\nDuring his journey Arthur mentioned to his trainer how it positively \"changed\" his life. The whole experience permeated through other areas of his life, he felt less stressed at home and more focused at work.\n\n\"This is why I always say it's more than just a body transformation\" - Rupert commented.\n\nWhen arriving at No1 Fitness it was easy to see that Patrick was not in bad shape, however he had a goal in mind: to lose body fat and build some muscle."
        },
        "score": 73,
        "parent": {
          "$oid": "58d2bb193fa3e80afcabe121"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490262236753,
      "username": "no1_fitness_city_studio",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58d2bb193fa3e80afcabe121"
      },
      "lastUpdated": {
        "$date": "2017-11-21T11:38:16.351Z"
      },
      "createdAt": 1490205465309,
      "email": "no1fit@shoomon.xyz",
      "firstName": "General",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "hsSUkkOsFsX7J2sGUgeH4w==",
      "hashedPassword": "a7kcS7eEi/yXolsT5WCKibibiYrA4olOkTbR3NQjDMdqbotPzIaoF/887AC0jpLvKMCiDGZCJDo8C6jf+EREjA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Cs2gCZp8-no1fitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Body Transformation",
            "Diet/Meal Plans",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Personal Training Studios across the UK",
            "Personal Training Studio Rental"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.no1fitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wZBeNVVO-no1fitnessbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "No1fitnessPersonalTraining",
          "strapLine1": "Welcome to No1 Fitness: London's Leading Personal Training and PT Academy Service",
          "biography": "At No1 Fitness it’s all about rediscovering and reshaping the body beneath your clothes – creating a more confident and comfortable you. \n\nWhether you're looking to transform your body for a special occasion, improve your fitness for a challenge that lies ahead, lose fat or simply want to gain a better quality of health, we can help you reach your goal.\n\nWe know not everyone has the confidence to bare it all in broad daylight—especially in front of a significant other or a new love interest. However, that’s not to say that you can’t get there. With our results-driven PT training programmes you can beat the bloat, trim your middle and start feeling more confident with the lights on!\n\nNo1 Fitness is not like your typical gym. Forget paying for a membership for months on end, our personal trainers work with you to set goals that work for you, a timeline in which to achieve these and monitor your progress every step of the way.\n\nOur simple mission is to get YOU the body you deserve and hopefully our client success stories speak from themselves.",
          "offers": "Please visit our website for details of prices, packages & offers",
          "twitter": {
            "link": "https://twitter.com/no1fitness"
          },
          "instagram": {
            "link": "https://www.instagram.com/no1_fitness/?hl=en"
          },
          "locations": [
            {
              "geolocation": [
                -0.07893379999995886,
                51.500485
              ],
              "city": "London",
              "postcode": "SE1 3JB",
              "_id": {
                "$oid": "5a1410282288dd3a30f3f467"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "No1 Fitness",
          "phone": {
            "number": "2074036660",
            "code": "+44"
          },
          "testimonials": "Steve is editor of Lifestyle at CITYAM.\n\nAnother amazing transformation at No1 Fitness. Steve has just completed 10 weeks with Harry and has achieved some amazing results.\n\nSteve came to us without having exercised for a good few years. He worked long hours and found himself drinking at least 3 nights a week with friends. Steve had decided, enough was enough and agreed to follow our advice strictly for 10 weeks and has now achieved some impressive results. He trained 4 times a week, followed a No1 Fitness nutrition plan and most importantly, did not have a single drop of alcohol throughout. This just shows what you can achieve when you put your mind to something. Well done Steve.\n\n\"Fitness challenge: the results after 10 weeks\" \n\nArthur came to one of our fitness studios (Tower Bridge) and started his journey with personal trainer Rupert .\n\nHis body transformation took him 16 weeks also including a 2 week holiday. After 54 sessions and around 201000 calories later, Arthur achieved his body goals, from 84.7kg to 77.4kg. \n\nDuring his journey Arthur mentioned to his trainer how it positively \"changed\" his life. The whole experience permeated through other areas of his life, he felt less stressed at home and more focused at work.\n\n\"This is why I always say it's more than just a body transformation\" - Rupert commented.\n\nWhen arriving at No1 Fitness it was easy to see that Patrick was not in bad shape, however he had a goal in mind: to lose body fat and build some muscle."
        },
        "score": 73,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490269717781,
      "username": "no1_fitness",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58d3a691bdc4ba1cd258c884"
      },
      "lastUpdated": {
        "$date": "2017-11-21T11:51:08.619Z"
      },
      "createdAt": 1490265745473,
      "email": "no1fit.educationstmaryaxe@shoomon.xyz",
      "firstName": "No1 Fitness",
      "surname": "Education Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "be4f6d85af1eab22f3b041e444cc748fc3dcce40",
      "salt": "zqYSbBwPf9Kac6YiUiAosw==",
      "hashedPassword": "HTlvTeH+DBRWS1meG3awOQTTBRyHf/FILPMu+ksaZSxL59TE4gsZVy97vmD0guaieM8wHBfNcP+/gh967XB8sQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nFr21gcI-no1fitnesseducationlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Blended Learning",
            "Finance Options",
            "Guaranteed Job Interview",
            "Level 3 Courses",
            "Mentorship"
          ],
          "qualifications": [
            "The very best tutors in the health & fitness industry",
            "Tutors are currently active & highly-successful PT's with their own clients",
            "Tutors have at least 5 years experience & are academic contributors to the industry",
            "Exceptional opportunity to learn from the best",
            "Comprehensive practical learning in our own studios",
            "Courses conducted using the newest and most-effective pieces of functional equipment",
            "Partnered with industry specialists Siren First Aid Training to deliver the EFAW course",
            "Full Personal Trainer qualification",
            "Transformation Nutrition Course - Level 1",
            "Level 3 Personal Trainer qualification"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.no1fitnesseducation.co.uk/",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wMLH8rMd-nofitnesseducationbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "no1fitnesseducation/?fref=ts",
          "strapLine1": "London's No1 Personal Training Academy with the No1 Fitness Education Certification Program",
          "biography": "As you may have seen, there are so many fitness education providers out there, some more known that others. Every provider will have to teach the same syllabus, which follows the government guidelines.\n\nSo what makes us different and why do we believe that you should be getting qualified from us?\n\n No1 Fitness - we started over 8 years ago by opening our first personal training studio in Tower Bridge. Since then we now have two studios in central London with a team of over 20 trainers.\nOver the years we have seen and worked with 100s of Personal Trainers and it’s been scary to think how some of them have actually qualified.\nWithin that time we have had regularly seen a number of things that have then seen their businesses decrease, these can range from:\n\nThe quality of the sessions\nLack of knowledge\nNo connection with clients\nNo methods of recording clients progress\nBody language\nConstantly being late\nNo organisation\nNo self-development\n\nThere are so many more examples, but we believe that these things are key in regards to becoming a successful trainer.",
          "offers": "LEVEL 3 PART TIME\n(LEVEL 2 QUALIFICATION REQUIRED)\n7 weeks (Saturday or Sunday)\n\nLEVEL 2 AND 3 PART TIME\nPERSONAL TRAINER DIPLOMA\n12 weeks (Saturday or Sunday)\n\nLEVEL 2 AND 3 INTENSIVE\nPERSONAL TRAINER DIPLOMA\n6 week Intensive (Mon,Tue,Wed)",
          "twitter": {
            "link": "https://twitter.com/No1FitnessEdu"
          },
          "instagram": {
            "link": "https://www.instagram.com/no1fitnesseducation/?hl=en"
          },
          "locations": [
            {
              "geolocation": [
                -0.07994510000003174,
                51.5151595
              ],
              "city": "London",
              "postcode": "EC3A 8FR",
              "_id": {
                "$oid": "5a14132c2288dd3a30f3f46c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "No1 Fitness Education",
          "phone": {
            "number": "2076211312",
            "code": "+44"
          }
        },
        "score": 69,
        "parent": {
          "$oid": "58d2bb193fa3e80afcabe121"
        },
        "inherit_from_parent": true
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490270038881,
      "username": "no1_fitness_education",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "58d5367f2d59db56b27fc7fb"
      },
      "lastUpdated": {
        "$date": "2017-11-21T11:32:20.914Z"
      },
      "createdAt": 1490368127783,
      "email": "no1fit.gyms@shoomon.xyz",
      "firstName": "The General",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "3KNfEvmPiKKEwbxph1lpOA==",
      "hashedPassword": "CSb2zYbvLQWe9+OMeGbxGbHi24TKSzdkHyB5AGrvhK+1BGPoVS4mVxiMta+6sHCIvLdDQjnxYM90MFnAi89KSw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aHv4na9X-no1fitnesslogo.png"
        },
        "personal": {
          "disciplines": [
            "Body Transformation",
            "Diet/Meal Plans",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Cardio Stations",
            "Free Weights Selection",
            "Resistance Equipment",
            "Functional Training Rig",
            "Suspension Equipment",
            "Changing Facilities"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.no1fitness.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/juMIb7JV-no1fitnessbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "No1fitnessPersonalTraining",
          "strapLine1": "Welcome to No1 Fitness: London's Leading Personal Training Service",
          "biography": "At No1 Fitness it’s all about rediscovering and reshaping the body beneath your clothes – creating a more confident and comfortable you. \n\nWhether you're looking to transform your body for a special occasion, improve your fitness for a challenge that lies ahead, lose fat or simply want to gain a better quality of health, we can help you reach your goal.\n\nWe know not everyone has the confidence to bare it all in broad daylight—especially in front of a significant other or a new love interest. However, that’s not to say that you can’t get there. With our results-driven PT training programmes you can beat the bloat, trim your middle and start feeling more confident with the lights on!\n\nNo1 Fitness is not like your typical gym. Forget paying for a membership for months on end, our personal trainers work with you to set goals that work for you, a timeline in which to achieve these and monitor your progress every step of the way.\n\nOur simple mission is to get YOU the body you deserve and hopefully our client success stories speak from themselves.",
          "offers": "Please visit our website for details of prices, packages & offers.",
          "instagram": {
            "link": "https://www.instagram.com/no1_fitness/?hl=en"
          },
          "twitter": {
            "link": "https://twitter.com/No1Fitness"
          },
          "locations": [
            {
              "geolocation": [
                -0.07893379999995886,
                51.500485
              ],
              "city": "London",
              "postcode": "SE1 3JB",
              "_id": {
                "$oid": "5a140ec42288dd3a30f3f463"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "No1 Fitness Studios",
          "phone": {
            "number": "2074036660",
            "code": "+44"
          },
          "testimonials": "Steve is editor of Lifestyle at CITYAM.\n\nAnother amazing transformation at No1 Fitness. Steve has just completed 10 weeks with Harry and has achieved some amazing results.\n\nSteve came to us without having exercised for a good few years. He worked long hours and found himself drinking at least 3 nights a week with friends. Steve had decided, enough was enough and agreed to follow our advice strictly for 10 weeks and has now achieved some impressive results. He trained 4 times a week, followed a No1 Fitness nutrition plan and most importantly, did not have a single drop of alcohol throughout. This just shows what you can achieve when you put your mind to something. Well done Steve.\n\n\"Fitness challenge: the results after 10 weeks\" \n\nArthur came to one of our fitness studios (Tower Bridge) and started his journey with personal trainer Rupert .\n\nHis body transformation took him 16 weeks also including a 2 week holiday. After 54 sessions and around 201000 calories later, Arthur achieved his body goals, from 84.7kg to 77.4kg. \n\nDuring his journey Arthur mentioned to his trainer how it positively \"changed\" his life. The whole experience permeated through other areas of his life, he felt less stressed at home and more focused at work.\n\n\"This is why I always say it's more than just a body transformation\" - Rupert commented.\n\nWhen arriving at No1 Fitness it was easy to see that Patrick was not in bad shape, however he had a goal in mind: to lose body fat and build some muscle."
        },
        "score": 73
      },
      "active": true,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490368128028,
      "username": "no1_fitness_studios",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58d70d002d59db56b2802c7d"
      },
      "lastUpdated": 1490607418233,
      "createdAt": 1490488576683,
      "email": "manchesterleigh@anytimefitness.co.uk",
      "firstName": "Rachel",
      "surname": "Gaskell",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "68afac4c31794e347ab46a5b1368643046eff0de",
      "salt": "bbdX5iFy6YpgIgOmkTLbyA==",
      "hashedPassword": "ybg9AzAYWOitVaVxNifBD+kwwq+P7NoGmAuqoEkXxKwXkp1ZAoLWSWDoOHWkaOLMEBm5EhJFx84v5pWo3Vo1Yg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KDE89ifn-anytime.jpg"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Nutrition",
            "Fitness Classes",
            "Cardio",
            "All Fitness Levels"
          ],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "Http://Www.anytimefitness.co.uk",
          "profileTitle": "General",
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DW3y9oCg-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -2.5187270000000126,
                53.496115
              ],
              "country": "United Kingdom",
              "city": "Leigh",
              "postcode": "WN7 4LX"
            }
          ],
          "companyName": "Anytime Fitness Leigh",
          "phone": {
            "code": "+44",
            "number": "1942316499"
          }
        },
        "score": 45
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490488576907,
      "username": "anytime_fitness_leigh"
    },
    {
      "_id": {
        "$oid": "58d7528e2d59db56b2803b3f"
      },
      "lastUpdated": {
        "$date": "2018-01-19T13:00:17.071Z"
      },
      "createdAt": 1490506382735,
      "email": "michaelfrascino@gmail.com",
      "firstName": "Michael",
      "surname": "Michael",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "a2cfddc13f60a279f681d51f9bc6654e1fa78808",
      "salt": "46SN2+qx1f6GF4x2hU+Lsg==",
      "hashedPassword": "KVm4ujIyuDdm8uXj7AyAzOv45NA19EhFXZIcktF5CuUmZANutnhXyU296G5LJJOLTKsiQ87HhyNQuantpQME1w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3rSqCBei-michaelfrascinologo.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Hiit",
            "Mobile Trainer",
            "Pre/post Natal"
          ],
          "qualifications": [
            "NFPT - Certified Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 200
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SFmMeudH-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "biography": "Global Fitness, Master Program – NFPT - Certified Personal Trainer",
          "yearsOfExp": 2014,
          "affiliation": "NFPT",
          "locations": [
            {
              "geolocation": [
                -118.2436849,
                34.0522342
              ],
              "city": "Los Angeles",
              "postcode": "90012",
              "_id": {
                "$oid": "5a61ebe1765eba094646f8df"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "companyName": null,
          "phone": {
            "number": "3233046854",
            "code": "+1"
          }
        },
        "score": 58,
        "parent": null
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490506383585,
      "username": "michael_michael",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58d850fa2d59db56b2807181"
      },
      "lastUpdated": {
        "$date": "2017-10-18T19:26:05.120Z"
      },
      "createdAt": 1490571514717,
      "email": "info@completefitness.ie",
      "firstName": "Marc",
      "surname": "Smith",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "3ISClQJYfA/QkextmD8w4w==",
      "hashedPassword": "AOpGlysbkactk7dtBQpHRVJ5ucU5/AUij4IIPolyLlLE74/WKt4SXiQj7bnLEHduGw0opzT/T4ol8cZCP/ikNw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rDO9Y2p9-IMG_4519.JPG"
        },
        "personal": {
          "disciplines": [
            "Fitness Classes",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Resistance",
            "TRX",
            "Pilates",
            "Personal Training",
            "Small group training",
            "Pilates Barre Classes",
            "Nutrition",
            "Physical Therapy",
            "Rehabilitation",
            "Running club",
            "Hiit Classes",
            "Suspension Pilates",
            "Functional Movement Classes",
            "Flow Pilates"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://completefitness.ie",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 10,
          "biography": "Complete Personal Training offers the best Personal Training in Dublin. At Complete Personal Training we are 100% devoted to what we do. We will support and help you to achieve your goals from the moment you come through our doors to the day that those goals are reached. We will plan everything from your New Healthy Eating Plan, exercise program, to motivating you along your road to success.",
          "offers": "This is a 28-day training program in which I give you absolutely EVERYTHING you need to lose weight, toneup, skyrocket your energy, improve your health, and look & feel great in 2017",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3Lm2oDyQ-IMG_4904.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "CFDUBLIN",
            "link": "https://twitter.com/CFDUBLIN",
            "user_id": "444762691",
            "accessToken": "444762691-MyesAzghxc4SFrsDlujosZr5ioPLBpix5UNI9JLw",
            "accessTokenSecret": "OoxiCiDWwCjBZJAl1DhbEYkd2pMfqxeLPIkhF8eeHw2Dd"
          },
          "instagram": {
            "access_token": "1217520877.8640130.fc754fa2f7fd4e929e58175b8abad1e2",
            "link": "https://www.instagram.com/completefitnessdublin2"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10210777239966181/",
          "facebookPage": "completefitnessd2/",
          "yearsOfExp": 2006,
          "locations": [
            {
              "geolocation": [
                -6.24490650000007,
                53.3373666
              ],
              "city": "Dublin",
              "postcode": "D02 E302",
              "_id": {
                "$oid": "59e7aacd94e4d86bf9be19d9"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -6.244911399999978,
                53.337366
              ],
              "city": "Dublin",
              "postcode": "D02 E302",
              "note": "PT Studio: 25 Mount Street Upper, Grand Canal Dock, Dublin 2",
              "_id": {
                "$oid": "59e7aacd94e4d86bf9be19d8"
              },
              "country": "Ireland",
              "primary": false
            },
            {
              "geolocation": [
                -6.2516361000000416,
                53.3370632
              ],
              "city": "Dublin",
              "postcode": "D02 FK06",
              "note": "Complete Fitness, (above the Paper-man Shop) 33 Lower Pembroke Street, Dublin 2",
              "_id": {
                "$oid": "59e7aacd94e4d86bf9be19d7"
              },
              "country": "Ireland",
              "primary": false
            }
          ],
          "companyName": "Complete Fitness",
          "phone": {
            "code": "+353",
            "number": "879799030"
          },
          "testimonials": ""
        },
        "score": 67
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490830866238,
      "username": "complete_fitness",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "58d8c46d2d59db56b2808a45"
      },
      "lastUpdated": {
        "$date": "2018-12-03T07:59:30.409Z"
      },
      "createdAt": 1490601069743,
      "email": "pierrewedja20@live.co.uk",
      "firstName": "Pierre",
      "surname": "WEDJA",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "zpBAWx+nMFjhIeaP1hddeQ==",
      "hashedPassword": "TZ6BA/odXt1TkGmMNG1ZOfAFlgckOi9861HMcC4AWgoVFGSIxonD5VReuUSV6F2WqRLYevEIa57J6XM7GhuUmw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/i8JDj3lv-IMG_20181123_144134_003.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Nutrition",
            "Online Coaching",
            "Strength & Conditioning",
            "Toning"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "BSc In Sports Coaching"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 30
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xGAlwWLH-2.png",
            "size": {},
            "offset": 0
          },
          "biography": "Muscle Mastery Fitness Academy is a PT company (MMFA) Is led by Pierre Wedja BSc In Sports Coaching. Pierre Believes on constant lifelong learning as he works towards enhancing the well being of his clients by empowering them with the knowledge, skills, support, guidance and resources to assist and inspire them on their journey to a healthier life. Pierre offers support in a holistic approach as he feels that's the best way of achieving results. Pierre will work on transforming his clients quality of life through active participation in regular fitness activities and the integration of guided fitness and nutrition programs, to make their health goals become a reality, in a caring, fun, and dynamic environment. Pierre is fully committed in providing high quality training to individuals of all fitness levels. Whether it is sport specific, Functional training or just Health. \n\nMMFA Specialty\n* Sport-Specific Training\n* Rugby Conditioning\n* Functional Training\n* Strength Training\n* Power Lifting\n* Core Strength\n*Muscle Hypertrophy (Bulking)\n*Weight Loss",
          "locations": [
            {
              "geolocation": [
                -0.2857578000000558,
                51.405628
              ],
              "city": "Kingston upon Thames",
              "postcode": "KT1 3HD",
              "_id": {
                "$oid": "5c04e1c8bf18d20974b3dc19"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7378862515",
            "code": "+44"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/YXNpZADpBWEVnNV9xSmg4dDYwejhVRWJKM3FBYWdQLS1kSzlhNzBCRThWbXhJZA3BhZAUQybkZA1cWh1TnpVTGJtTG5aRjhITVplYkU1dzY5UGlLUXByR0NjdEpaUXpDWi1waV95U25BckRpajlYZA1JHQXVaNFR5S1EZD/",
          "instagram": {
            "access_token": "484830931.8640130.f1be9d1cbe3f4d58bc4c94a7b5246aa8",
            "link": "https://www.instagram.com/ptwedja"
          }
        },
        "score": 60,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1543823521085,
      "username": "pierre_wedja",
      "feature_in_area": "none",
      "__v": 21,
      "agent": {
        "family": "Chrome Mobile",
        "major": "70",
        "minor": "0",
        "patch": "3538",
        "source": "Mozilla/5.0 (Linux; Android 8.1.0; SM-N960F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.110 Mobile Safari/537.36"
      }
    },
    {
      "_id": {
        "$oid": "58d8d61f2d59db56b2808e07"
      },
      "lastUpdated": 1490614019449,
      "createdAt": 1490605599986,
      "email": "cleancoachkatie@gmail.com",
      "firstName": "Katie",
      "surname": "Tucker",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "M5L7YnRQwE4CisRfNOcdCA==",
      "hashedPassword": "9XQJJ8RKGEcNrBYlDNw/ztcsszKwRPqwrkaSdD34exCLPg+6frdpG5JWKITGdUScuEff7/uIVbjUNv59Ic3skg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KhTR8uZb-WEB-LOGO.png"
        },
        "personal": {
          "disciplines": [
            "Endurance",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss",
            "Nutrition"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 30,
            "max": 45
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 30,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1jBEw9Xb-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "3658532067.8640130.906abac873194e37b0119d658f4ded31",
            "link": "https://www.instagram.com/cleancoachkatie"
          },
          "facebookPage": "cleancoachkatie",
          "yearsOfExp": 2017,
          "affiliation": "REPs",
          "strapLine1": "Empowering women to lead a healthier and happier lifestlye by achieving sustainable results",
          "offers": "121 Personal Training Sessions\nWeekly HIIT Classes\nOnline Training \nNutrition Coaching",
          "biography": "I’ve always had an active background after playing netball from a young age and following my dreams of studying Sports Science at Brunel University.\n\nI moved back home to Milton Keynes after I finished my degree and felt a little lost. I needed a new hobby and new direction. After a bit of research, in less than an hour I’d signed up for my first half marathon and had purchased a new pair of runners.\n\nI went on to complete a full Marathon back in 2014 and to this day have not stopped running, currently in the process of training for the London Marathon (2017).\n\nThrough my love of running, I have transformed my life, by understanding my nutrition, how to fuel my body and building up strength in the gym to compliment my training.\n\nAfter using exercise and nutrition to improve my life, I now want to be able to help others in the same way it has helped me.\n\nI’ve spent years gaining knowledge and continually learning on my own personal journey through various exercise environments. I can’t wait to share this with you all and help you follow your own health & fitness related goals.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.7594171000000642,
                52.0406224
              ],
              "country": "United Kingdom",
              "city": "Milton Keynes",
              "postcode": "MK9 3DX"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7814560893"
          }
        },
        "score": 68
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490613178043,
      "username": "katie_tucker"
    },
    {
      "_id": {
        "$oid": "58d8fd8a2d59db56b2809649"
      },
      "lastUpdated": 1493900440600,
      "createdAt": 1490615690807,
      "email": "movenowpt@gmail.com",
      "firstName": "Kirk",
      "surname": "Walker",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "8b73d60f9ede7fb69a89f4754da1942a1fd023a9",
      "salt": "YCNQQuMdEmxSQ4oJLTJcag==",
      "hashedPassword": "K5EFMRRuvb61eV70epfSQMmT0CbEKv3a3R7HNBLfP8QhpVnyrLJ5uYc9eLvhN1+noo+PAnz6qXJ2NE4oSI4dnw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/m11Mx7dO-movenowlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Weight Loss",
            "Nutrition",
            "Strength & Conditioning",
            "Rehabilitation",
            "Circuit Training"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 4: Cardiac Rehab Phase IV Exercise Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 15,
            "max": 20
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "biography": "After working in gyms as a Fitness Instructor for 15 years I have established MoveNow PT so I can work with clients 1 to 1 and share my experience and knowledge to benefit my clients.\n\nIf you are interested in learning new methods of training, need help with your diet, keen to lose weight or just want to get out of the gym and use the natural environment to put some life back into your training then MoveNowPersonal Training will be ideal for you.\n\nPeople seek personal training for many different reasons and it is important that we find the right training method for your goals but also for long term adherence. \n\nIts no good just doing what everyone else does because its the latest trend or because you don't know any different. We need to find the right training method for you and make sure you stay motivated and move towards the goals you want to achieve.\n\nAs well as your training (for some people more importantly) your diet is crucial to achieving your goals. So many people slog away in the gym for months and even sometimes years and don't see any improvements. This is when a nutrition consultation can help.\n\nI offer Clinical weight loss consultations where I will show you the difference between a standard diet based on grains and white carbs which the majority of people follow (even when they are trying to lose weight!) and a Weight Loss diet where you will actaully eat more food but consume less calories! It really is as simple as it sounds.",
          "offers": "To book a Personal Training session or a Clinical Weight Loss consultation feel free to call me on 07880706578.",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3qAkMtrV-movenowbg.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -1.5848519999999553,
                54.77525
              ],
              "country": "United Kingdom",
              "city": "Durham",
              "postcode": "DH1 4EE"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7880706578"
          }
        },
        "score": 68
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490615691002,
      "username": "kirk_walker"
    },
    {
      "_id": {
        "$oid": "58d92fef2d59db56b280a10b"
      },
      "lastUpdated": {
        "$date": "2017-11-28T15:31:48.687Z"
      },
      "createdAt": 1490628591259,
      "email": "ebc@shoomon.xyz",
      "firstName": "Essex",
      "surname": "Boot Camp Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Qz84B7vst4ohhFDIIDk+1w==",
      "hashedPassword": "9hdG+C2G8xdj315cC7l+KHX4cSeMm2mnweOslOnkfBG/4SZQPW1AnE9iaFNAbJ06d3mHjj/sVIOaI+/x8Ez8Pg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/n6KOBzYL-ebclogo2.jpg"
        },
        "personal": {
          "disciplines": [
            "Competetive Games",
            "Day Camps",
            "Military Style",
            "Personal Training",
            "Sports Therapy & Rehab"
          ],
          "qualifications": [
            "Boot Camps delivered all over Essex",
            "Corporate Training",
            "Bentwaters day & weekend camps",
            "School Sports Days & Events",
            "Personal Training",
            "EBC Therapies at Pheonic Place for Health: Maldon",
            "Essex Stretch Academy",
            "EBC Inspire: For the Younger Generation"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.essexbootcamp.co.uk/",
          "profileTitle": "Boot Camp",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/il17KfLI-ebcbg2.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "facebookPage": "essexbootcamp",
          "yearsOfExp": 2006,
          "strapLine1": "Essex Boot Camp: Providing unique outdoor solutions in Essex since 2006",
          "biography": "Premier outdoor fitness:\nEssex Boot Camps are developed and delivered to you by EBC Fitness Ltd and providing unique outdoor exercise solutions in Essex since 2006.\nWe are not a course of 6 or 8 weeks, we are an ongoing outdoor fitness initiative, motivating and supporting you 52 weeks of the year. \nWe offer 500+ one hour sessions per month at 25 locations across the county.\n\nMind Set:\nAt Essex Boot Camp. we believe that if you train your mind,your body will follow: “what the mind believes the body achieves”.\n\nSetting Goals:\nAchieve your goals through addressing mind set, exercising at the right intensity and nutrition with EBC: Eat Clean challenges.\n\nNutrition is Fuel:\nWe pride ourselves on training mind set, so that your training and nutritional requirements fall into place, leaving you with long lasting,\u0003sustainable results.",
          "locations": [
            {
              "geolocation": [
                0.49918879999995625,
                51.5791972
              ],
              "city": "Basildon",
              "postcode": "SS13 1RN",
              "_id": {
                "$oid": "5a1d81647d852e353acb63e0"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                0.49918879999995625,
                51.5791972
              ],
              "city": "Basildon",
              "postcode": "SS13 1RN",
              "note": "Northlands Park Site Start Times: Tues 19:00, Thurs 19:00, Sat 08:00",
              "_id": {
                "$oid": "5a1d81647d852e353acb63df"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                0.4153923000000077,
                51.6318624
              ],
              "city": "Billericay",
              "postcode": "CM12 0DT",
              "note": "Lake Meadows Site Start Times: Mon 19:00, Tue  09:30, Wed 19:00, Thurs 06:00 & 09:30, Sunday 09:00",
              "_id": {
                "$oid": "5a1d81647d852e353acb63de"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "Essex Bootcamp",
          "phone": {
            "number": "7739098790",
            "code": "+44"
          },
          "offers": "Please visit our website for details of memberships & offers."
        },
        "score": 66
      },
      "active": true,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490628591765,
      "username": "essex_bootcamp",
      "feature_in_area": "none",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "58d93cb72d59db56b280a3ce"
      },
      "lastUpdated": 1490635130769,
      "createdAt": 1490631863655,
      "email": "ebc.northlandspark@shoomon.xyz",
      "firstName": "Essex Boot",
      "surname": "Camp Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "8pFf6nbCmYBFj7vURfVcjw==",
      "hashedPassword": "8mzpzdTwazhPgpTUBQBb5cvVz2DyMjQ96GchhGlxGcQ4dLGfcBhS+fFGBPv84XWMZGxll2oA+UwMRJEj2KuYsg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fzb0aoiT-ebclogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Fitness Levels",
            "Bootcamp"
          ],
          "qualifications": [
            "The Green Open Spaces of Northlands Park"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.essexbootcamp.co.uk/location/northlands-park/",
          "profileTitle": "General",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rerjfKtj-ebcbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "essexbootcamp/",
          "yearsOfExp": null,
          "strapLine1": "Essex Boot Camp: Providing unique outdoor solutions in Essex since 2006",
          "biography": "Premier outdoor fitness:\n\nEssex Boot Camps are developed and delivered to you by EBC Fitness Ltd, providing unique outdoor exercise solutions in Essex since 2006. We are not a course of 6 or 8 weeks, we are an ongoing outdoor fitness initiative, motivating and supporting you 52 weeks of the year. \n\nOffering 500+ one hour sessions per month, at 25 locations across the county.\n\nMind Set:\n\nAt Essex Boot Camp we believe that if you train your mind, your body will follow, “what the mind believes the body achieves”.\n\nSetting Goals:\n\nAchieve your goals through addressing mindset, exercising at the right intensity and proper nutrition with EBC - Eat Clean challenges.\n\nNutrition is Fuel:\n\nWe pride ourselves on training mindset, so that your training and nutritional requirements fall into place, leaving you with long-lasting sustainable results.\n\nWe are everywhere, no excuses.\nYou can find us everywhere in Essex, and quickly too!",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                0.49683970000000954,
                51.58031740000001
              ],
              "country": "United Kingdom",
              "city": "Basildon",
              "postcode": "SS13 1BW"
            }
          ],
          "companyName": "Essex Boot Camp Northlands Park - Basildon",
          "phone": {
            "code": "+44",
            "number": "7739098790"
          }
        },
        "score": 70
      },
      "active": true,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490631863921,
      "username": "essex_boot_camp_northlands_park_-_basildon"
    },
    {
      "_id": {
        "$oid": "58d948692d59db56b280a650"
      },
      "lastUpdated": 1490635262976,
      "createdAt": 1490634857354,
      "email": "ebc.admiralspark@shoomon.xyz",
      "firstName": "Essex Boot",
      "surname": "Camp Enquiries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "OlccFR9/phyy2iKffeuxYg==",
      "hashedPassword": "RplJ4OLsOvluIy/sAazZU5pjBXwZgONONyhXz6nW+A8Pn+qvdl0RqG7fbGNJYf78OzP2VZl8SiFpHj8KacGB5Q==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6JWiixjY-ebclogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Fitness Levels",
            "Bootcamp"
          ],
          "qualifications": [
            "The Green Open Spaces of Admiral's Park"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.essexbootcamp.co.uk/location/admirals-park/",
          "profileTitle": "General",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/R0SiLXBI-ebcbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "essexbootcamp/",
          "strapLine1": "Essex Boot Camp: Providing unique outdoor solutions in Essex since 2006",
          "biography": "Premier outdoor fitness:\n\nEssex Boot Camps are developed and delivered to you by EBC Fitness Ltd, providing unique outdoor exercise solutions in Essex since 2006. We are not a course of 6 or 8 weeks, we are an ongoing outdoor fitness initiative, motivating and supporting you 52 weeks of the year. \n\nOffering 500+ one hour sessions per month, at 25 locations across the county.\n\nMind Set:\n\nAt Essex Boot Camp we believe that if you train your mind, your body will follow, “what the mind believes the body achieves”.\n\nSetting Goals:\n\nAchieve your goals through addressing mindset, exercising at the right intensity and proper nutrition with EBC - Eat Clean challenges.\n\nNutrition is Fuel:\n\nWe pride ourselves on training mindset, so that your training and nutritional requirements fall into place, leaving you with long-lasting sustainable results.\n\nWe are everywhere, no excuses.\nYou can find us everywhere in Essex, and quickly too!",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                0.4546887000000197,
                51.7402411
              ],
              "country": "United Kingdom",
              "city": "Chelmsford",
              "postcode": "CM1 2PN"
            }
          ],
          "companyName": "Essex Boot Camp Admiral's Park - Chelmsford",
          "phone": {
            "code": "+44",
            "number": "7739098790"
          }
        },
        "score": 48
      },
      "active": true,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490634857624,
      "username": "essex_boot_camp_admiral's_park_-_chelmsford"
    },
    {
      "_id": {
        "$oid": "58da770e2d59db56b280e714"
      },
      "lastUpdated": {
        "$date": "2017-10-23T11:05:11.753Z"
      },
      "createdAt": 1490712334655,
      "email": "info@probalancehealth.co.uk",
      "firstName": "Andy",
      "surname": "Jeffries",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "po6uz5UAMoAx0IVmGxUMzQ==",
      "hashedPassword": "UZMJQhfNHk1NHppUfDRF/7xBloyg2Pi70M7ZHYmAtpFyevhgB3EQooqGQdpbFw+nL7CuebM8T3I7gLobwHly3w==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/b8h3fILM-probalancelogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Free Weights",
            "Hot Showers",
            "Changing Rooms",
            "Cardio",
            "Resistance",
            "Functional Training Zone",
            "Lockers",
            "Suspension Equipment",
            "Gymnasium"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "Http://www.pro-balance.co.uk",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 2,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/guIRcrOl-IMG_1222.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Experience a new way of Personal Training in the City!\n\nExclusive Personal Training Gym that helps assist your motivation levels and achieve your health & fitness goals! Pro Balance has been set up as a specialist fitness studio dedicated to 1-2-1 Personal Training, using up to date training methods and technology and is conveniently located in the city centre. We offer all the benefits of training in a fully equipped gym without the queue for machines or the intimidation and hassle of a crowded atmosphere. Everything at Pro Balance is based on catering for the individual. Our private and professional atmosphere provides a prime training environment for you to achieve your individual targets.",
          "offers": "There are no joining fees at Pro Balance. Individual Personal Training and Corporate Lunch Sessions available",
          "twitter": {
            "screen_name": "probalance1",
            "link": "https://twitter.com/probalance1",
            "user_id": "21997126",
            "accessToken": "21997126-z91Rs8sUh4WXrJuyqnthqOBupGghUJ5AcU1pqKPnh",
            "accessTokenSecret": "bbzdmCsM26px6lfSFOoCrfmwViecEK35wGC4d0dElWU39"
          },
          "facebookPage": "probalance17",
          "yearsOfExp": 1999,
          "affiliation": "REPS and STA",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1512409432126004/",
          "locations": [
            {
              "geolocation": [
                -2.2480218000000605,
                53.47919510000001
              ],
              "city": "Manchester",
              "postcode": "M2 5WD",
              "_id": {
                "$oid": "59edcbd194e4d86bf9be1a85"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Probalance",
          "phone": {
            "code": "+44",
            "number": "1618334777"
          },
          "testimonials": "I can highly recommend Andy. After several years of abdominal surgery and resulting nerve damage I thought that I would just have to live with the constant twings and pain.After 30 sessions with Andy I now have no pain, strong abdominals and as an added bonas have improved my body shape.Andy absolutely knows his stuff, is fun to work with and most important of all cares about his clients. Reviewed by Jackie Lanham on Jan 2014. Rating: 5.0\n\nI have been coming to Pro Balance for 15 months and in that time have achieved a weight loss of over 3 Stone. As someone who hate's gyms and finds it very hard to get motivated to exercise, this is a great result for me. I would recommend Pro Balance to anyone! Reviewed by Javaid on Nov 2013. Rating: 5.0\n\nAndy was my trainer for many years before I flew off to Central America to work on the Panama Canal expansion project. I am not a naturally athletic person, but Andy's subtle influence got me running, circuit training and thinking about my health generally.He is certainly the best personal trainer that I know and I hope to work with him again when I return to the UK. Reviewed by Edward Davies on March 2013. Rating: 5.0",
          "openingTimes": "Our 24-7 availability means total access to a fully equipped private gym to use in the centre of Manchester City Centre at your convenience.",
          "strapLine1": "Exclusive 1-2-1 pt and sports therapy studio"
        },
        "score": 74
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1508756285190,
      "username": "probalance",
      "password_reset_time": 1490781854383,
      "password_reset_code": "X",
      "feature_in_area": "none",
      "__v": 14,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_2 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A421 Safari/604.1"
      }
    },
    {
      "_id": {
        "$oid": "58dac8452d59db56b280f85a"
      },
      "lastUpdated": {
        "$date": "2017-11-21T10:59:31.231Z"
      },
      "createdAt": 1490733125376,
      "email": "claire_picton@btinternet.com",
      "firstName": "Claire",
      "surname": "Picton",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "78FLhj9DABF+otXQz6FJLg==",
      "hashedPassword": "x9DKN8A0chqMIQoE69kqHeEmqUxhfEBqmJrfWkuiTUGgmjUXJmgu8XGLZib/uuECIuXSF1pagSWjNe4yW6m39w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6jdfQzpH-IMG_3201.PNG"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Sports Massage"
          ],
          "qualifications": [
            "Cardio",
            "Changing Rooms",
            "Free Parking",
            "Hot Showers",
            "Lounge area",
            "Free Weights",
            "Functional Training Zone",
            "Gym",
            "Climbing Wall"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://m.facebook.com/thefunctionaltrainingfacility/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cHHXuu5B-IMG_4480.JPG",
            "size": {},
            "offset": 0
          },
          "facebookPage": "https://m.facebook.com/thefunc",
          "yearsOfExp": 2015,
          "affiliation": "Reps",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1460998337257901/",
          "biography": "- Gym\n- Personal Training \n- Classes, pay as you go sessions and memberships options.",
          "offers": "Contact us for membership offers",
          "locations": [
            {
              "geolocation": [
                -2.6508231999999907,
                53.5494801
              ],
              "city": "Wigan",
              "postcode": "WN6 7LA",
              "_id": {
                "$oid": "5a1407132288dd3a30f3f45c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "The Functional Training Facility",
          "phone": {
            "number": "7718975333",
            "code": "+44"
          },
          "openingTimes": "Monday: 9:30AM - 11:00AM, 3:00PM - 9:00PM\nTuesday: 9:30AM - 11:00AM, 3:00PM - 9:00PM\nWednesday: 9:30AM - 11:00AM, 3:00PM - 11:00PM\nThursday: 9:30AM -11:00AM, 3:00PM - 9:00PM\nFriday: 9:30AM - 7:30PM\nSaturday: 9:00AM - 12:00PM\nSunday: CLOSED",
          "testimonials": "Liz Pearson: This is a great place to train. Claire & Danny are fab, and so supportive. Everyone is treated as an individual and helped by everyone around them....\n\nRich Eames: An extremely enjoyable training experience with just the right mixture of hard work and the odd laugh built in. \n\nGeorge Wiltshire: Never going to a traditional \"gym\" ever again. The guys at FTF make this place fun to sweat and burn!\n\nSteven Murphy: Awesome place full of like minded people all on different stages of their fitness journeys get down and give it a try you won't regret it\n\nDanny Campbell: No egos. Many various exercises and equipment. Classes actually focus on using your muscles and not just a flogging. Productive, smart, hard training."
        },
        "score": 69,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490733477156,
      "username": "the_functional_training_facility",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58dac9152d59db56b280f89b"
      },
      "lastUpdated": 1500400791001,
      "createdAt": 1490733333506,
      "providerId": "1460998337257901",
      "provider": "facebook",
      "surname": "Picton",
      "firstName": "Claire",
      "email": "clairepicton@live.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1460998337257901/",
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Group Classes",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 15,
            "max": 30
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "biography": "Owner of The Functional Training Facility",
          "offers": "Booking~ Inquiries~ Coaching:\n\nPlease direct message www.facebook.com/thefunctionaltrainingfacility",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JhqNHhck-tftfbg.jpg",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "geolocation": [
                -2.6325074000000086,
                53.5450645
              ],
              "country": "United Kingdom",
              "city": "Wigan",
              "postcode": "WN1 1JU",
              "primary": true
            }
          ],
          "companyName": null,
          "phone": {
            "code": "+44",
            "number": "7718975333"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wmJLNKfC_profilePicture.jpeg"
        },
        "score": 58,
        "parent": {
          "$oid": "58dac8452d59db56b280f85a"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "claire_picton",
      "lastLogin": 1490733449580
    },
    {
      "_id": {
        "$oid": "58dbacc82d59db56b281291d"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:08:33.609Z"
      },
      "createdAt": 1490791624030,
      "email": "daleygibbon9848@gmail.com",
      "firstName": "Daley",
      "surname": "Gibbon",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ljA2jCKsplFgKdmswS9AfQ==",
      "hashedPassword": "JjjeHBnaSwl/qYS5Be7IMtv2cYS/F4+Cn16lHhZ0Ba3ucCU/wvxfTat4gnjyHs6LiFqoLl0sGW5lOmAWCp5sXQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/i8gJUuvu-IMG-20170214-WA0000.jpg"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Mobile Trainer",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 4: Remedial Instructor (Military Only)",
            "Level 4: Strength & Conditioning",
            "Level 4: Mental Health",
            "Level 2: Disability"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 20,
            "max": 40
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 30,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/56qtuxQZ-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "google": "https://plus.google.com/102499044665378511511",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10210258690078272/",
          "yearsOfExp": 2005,
          "biography": "I am an ex military personal trainer.. My life (outside my family) is all about fitness. I am full of energy and\nenthusiasm and love to pass this on to clients to help them achieve their goals!!",
          "locations": [
            {
              "geolocation": [
                -2.524536000000012,
                52.7182875
              ],
              "city": "Telford and Wrekin",
              "postcode": "TF5 0PT",
              "_id": {
                "$oid": "5a6001a1765eba094646f749"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7493300846",
            "code": "+44"
          }
        },
        "score": 62,
        "parent": null,
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492866430597,
      "username": "daley_gibbon",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58dcecb196613e7d8507e5a6"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:08:09.457Z"
      },
      "createdAt": 1490873521784,
      "providerId": "1241437559307747",
      "provider": "facebook",
      "surname": "Briance PT",
      "firstName": "Spencer",
      "email": "spencer.briance@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1241437559307747/",
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Endurance",
            "Group Classes",
            "Nutrition"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 2: Gym Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 25,
            "max": 40
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 7,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6As6n8Go-IMG_2755.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "spencerbriance",
            "link": "https://twitter.com/spencerbriance",
            "user_id": "435167022",
            "accessToken": "435167022-aXofdQo2PZqDpHXAbKqFD5shNazdIBd7zrPR7YXs",
            "accessTokenSecret": "k1eLLDbdsemOQ9L6n3lJl4epHbA1Knx0cJPk3sJcI0K0g"
          },
          "instagram": {
            "access_token": "2884647545.8640130.b93e430ad383497f80a0115396653522",
            "link": "https://www.instagram.com/spencer_briance"
          },
          "google": "https://plus.google.com/107607524169084619471",
          "yearsOfExp": 2016,
          "affiliation": "Active IQ",
          "biography": "I've been a personal trainer for 8 months but I've training on and off for 5 years. During those five years I've often plateaued and hit a wall thinking what must I be doing wrong cause I'm training hard but wasn't seeing the results I wanted to see. I became a personal trainer to understand the anatomy of the human body and how it works in terms of exercise and nutrition to then continue learning to be a better fitness lifestyle personal trainer in helping others reach their own fitness goals, get results and to always constantly be motivational in one to one sessions or group sessions. I also offer online coaching too.  You can search me through my Facebook Group/Page: Mass Muscle Fitness. Email:Spencer.briance@gmail.com\nMobile: 07889748263",
          "offers": "My prices are £25 per hour or block sessions 12 sessions over a period of 3 months £240 etc etc it varies from clients with blocks as some prefer 2 months (8 week) period.",
          "facebookPage": "massmusclefitness",
          "strapLine1": "making change happen",
          "locations": [
            {
              "geolocation": [
                -1.4984049182399162,
                54.97570940298491
              ],
              "city": "Hebburn",
              "postcode": "NE32 5JR",
              "_id": {
                "$oid": "5a600189765eba094646f748"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7889748263",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EmZT8qAX-filename.jpeg"
        },
        "score": 86,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 1,
      "trainer_tools_access": false,
      "username": "spencer_briance_pt",
      "lastLogin": 1491867000843,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58dcf2d496613e7d8507e6e9"
      },
      "lastUpdated": 1490875092479,
      "createdAt": 1490875092160,
      "providerId": "1059833234121580",
      "provider": "facebook",
      "surname": "Bailey",
      "firstName": "Deb",
      "email": "debbailey@rogers.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1059833234121580/",
          "country": "United Kingdom",
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/a3hCzK9c_profilePicture.jpeg"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "deb_bailey",
      "lastLogin": 1490875092502
    },
    {
      "_id": {
        "$oid": "58dcfddb96613e7d8507e96a"
      },
      "lastUpdated": 1490877916115,
      "createdAt": 1490877915865,
      "providerId": "745064042338941",
      "provider": "facebook",
      "surname": "Wheatfall",
      "firstName": "Tonya",
      "email": "donavan@slimpixel.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/745064042338941/",
          "country": "United Kingdom",
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/izZYtgk2_profilePicture.jpeg"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "tonya_wheatfall",
      "lastLogin": 1490877941696
    },
    {
      "_id": {
        "$oid": "58dd151096613e7d8507ee2c"
      },
      "lastUpdated": 1493982561428,
      "createdAt": 1490883856704,
      "email": "nick.cravero@yahoo.co.uk",
      "firstName": "Nick",
      "surname": "Cravero",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "f98e696b8ed8202f165fbb478cd02958bcfa53f7",
      "salt": "CmwU45YQNUoiJuZUR1PXCQ==",
      "hashedPassword": "ggjPuaQIB/cEPt1Azl5h3WAHYFODVxx74MSYqSoEJqEev/AzEhUVc3+sh+5IVrPV6q3Qi4KPnZYZErWfmLJ2vA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/po8tks0W-nickcraverologo2.jpg"
        },
        "personal": {
          "disciplines": [
            "Strength & Conditioning",
            "Weight Loss",
            "Nutrition",
            "Mobile Trainer",
            "Bodybuilding"
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 40
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "strapLine1": "Become more than what you were, by starting today!",
          "biography": "I’m Nick, and I'd love to help you achieve your health, fitness and weight loss goals!\n\nStructured programming and diet with regular progression is the key to continual adaptations. Knowledge is the key to efficiency. Enjoyment is the key to adherence. As an experienced gym user I understand the obstacles involved with pursuing a training routine. As your personal trainer, I provide the knowledge and the motivation to drive you to achieve your goals, one small goal at a time. We will work together to adopt a lifestyle that you can maintain, that will get you results fast.\n\nMy favourite part of being a PT is when I take on a client and it becomes like a new project for me. We work together as a team to develop a program and diet that is customised to suit that person as best as it can. Then over time you see their body change and we continue to make small specific changes to the routine to keep the body adapting and keep progressing. As each persons body is different and will react differently to certain things, it's important to have a good understanding of how the body works. It's a very creative process and I very much enjoy playing with the science behind it.",
          "offers": "Please contact me directly on 07786 262553 for details of prices, packages & offers.",
          "affiliation": "REPs",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/45GVWpP2-nickcraverobg2.JPG",
            "size": {},
            "offset": 0
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.7551695255591924,
                51.40523798566243
              ],
              "country": "United Kingdom",
              "city": "Bracknell",
              "postcode": "RG12 7BB"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7786262553"
          }
        },
        "score": 76
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490883856967,
      "username": "nick_cravero"
    },
    {
      "_id": {
        "$oid": "58dd155d96613e7d8507ee6d"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:07:28.605Z"
      },
      "createdAt": 1490883933371,
      "providerId": "10212614273335856",
      "provider": "facebook",
      "surname": "De la Concepcion",
      "firstName": "Garai",
      "email": "clabern_18@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10212614273335856/",
          "disciplines": [
            "Bodybuilding",
            "Functional Training",
            "Martial Arts",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 4: Lower Back Pain Instructor",
            "Level 3: Exercise Referral Instructor",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "Martial arts coach"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 40
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/w5JrNROd-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "affiliation": "REPs",
          "yearsOfExp": 2015,
          "biography": "For those willing to get back to our true nature, who are bored to be in the gym performing those repetitive exercises over and over again without getting the improvements wished (being in a sticky point).Keep improving changing the programme! Three words to define this are Practical, efficient and adaptable. These are lots of new exercises for the real life (carrying, lifting, jumping, balancing, climbing, walking, running, crawling) learning the most efficient technique while improving conditioning, strength, coordination, mobility of the joints, flexibility, endurance, posture and alignment, lose weight and gain muscle in a funny and enjoiyable manner (games, cooperative working and much more). It is the way to come back to our true nature and natural movements, that is why after my experience I see that this is not a choice but a necessity of every human. I&#39;ve got experience in body transformations, strength and a bit of weightlifting, powelifting  and I also prepare people for obstacle race, and some events like martial arts (kyokushin european competitor who prepare people for competitions too).",
          "offers": "The prices are:\n- £30 single session\n-£275 taking 10 sessions in advance (£27.50 per session).\n-£500 taking 20 sessions in advance (£25 per session).\n- £360 per month: £30 per session plus (3 sessions a week, weekly dietary advice, body and goal assessments: postural, muscular and flexibility, goal: weight loss, muscle gain, strength...)\n- £440 per month: £27.5 per session plus (4 sessions a week, weekly dietary advice, body and goal assessments: postural, muscular and flexibility, goal: weight loss, muscle gain, strength...)\n- £660 2 months: £27.5 per session plus (3 sessions a week, weekly dietary advice, body and goal assessments: postural, muscular and flexibility, goal: weight loss, muscle gain, strength...)\n- £800 2 months: £25 per session plus (4 sessions a week, weekly dietary advice, body and goal assessments: postural, muscular and flexibility, goal: weight loss, muscle gain, strength...)",
          "locations": [
            {
              "geolocation": [
                -0.31168079999997644,
                51.5160352
              ],
              "city": "London",
              "postcode": "W5 2AW",
              "_id": {
                "$oid": "5a600160765eba094646f747"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7475480448",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9arnG78n-filename.jpeg"
        },
        "score": 72,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 3,
      "trainer_tools_access": false,
      "username": "garai_de_la_concepcion",
      "lastLogin": 1490967889309,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58dd253696613e7d8507f1ae"
      },
      "lastUpdated": {
        "$date": "2017-11-21T19:25:22.794Z"
      },
      "createdAt": 1490887990927,
      "surname": "Prynn",
      "firstName": "Sam",
      "email": "info@strongher.co.uk",
      "providerId": "4270564873",
      "provider": "instagram",
      "username": "strongher",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "4270564873.8640130.8fb16c44b35f445daf831dc2c603bee2",
            "link": "https://www.instagram.com/strongher_women"
          },
          "disciplines": [
            "All Abilities",
            "Diet & Nutrition",
            "Ladies Only Classes",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.strongher.co.uk",
          "profileTitle": "Fitness Trainer Group",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9YyP83P2-rsz_strongher_event_29_of_121.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "strongherwomen",
          "yearsOfExp": 2016,
          "biography": "A passion project & forward-thinking fitness concept by women only Personal Trainers Lyanne Hodson (Li-FT in the City) & Sam Prynn (SPPT)...focusing their combined energy and enthusiasm to help WOMEN of all shapes, sizes, backgrounds and cultures to discover their true potential, self worth, confidence and strength in safe, supportive and fun environment.\n\nBest friends for the last 5 years, founders Lyanne and Sam both came from professional dance backgrounds, so an industry full of scrutiny based on appearance, as well as dealing with personal body & health issues. As per the norm of a 20 something woman trying to lose body fat, they attacked countless fad diets, ridiculous gym regimes together and a whole lot of cardio, with no real success. Upon both working as receptionist and/or promo staff for some national chain gym they were introduced to working with weights. Learning little bits and bobs from peers and seeing some form of changes, they decided it was time to learn fro themselves and become personal trainers.\nOnce within the fitness industry it soon became very apparent that there were some huge conflicts in the information available, who was right who was wrong, who could they trust and how could they make something so intimidating fun?!",
          "offers": "Please visit our website for details of prices, packages and offers",
          "locations": [
            {
              "geolocation": [
                -0.05597169999998641,
                51.5206907
              ],
              "city": "London",
              "postcode": "E1 5QH",
              "_id": {
                "$oid": "5a147da22288dd3a30f3f4df"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.10883020000005672,
                51.518938
              ],
              "city": "London",
              "postcode": "EC1N 7SS",
              "note": "Gymbox Farringdon",
              "_id": {
                "$oid": "5a147da22288dd3a30f3f4de"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.05597169999998641,
                51.5206907
              ],
              "city": "London",
              "postcode": "E1 5QH",
              "note": "FE Gym Whitechapel",
              "_id": {
                "$oid": "5a147da22288dd3a30f3f4dd"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "StrongHer",
          "phone": {
            "number": "7547589560",
            "code": "+44"
          },
          "testimonials": "'Had my first StrongHer class with the ladies this week. Really worth it, best work out I've probably ever had, great motivation from the super positive, fun & happy duo. Thanks for the tips to help me do my exercises correctly, that's my biggest downfall - form. Well next to actually working out. I will be heading long for another class that's for sure & inviting friends.'  - RIANNA H\n\n'This is such an amazing company! They are supportive and caring people, I wanted to learn how to deadlift and after the barbell class I went into the gym confident on what to do and not caring what anyone else thought! Sam and Lyanne are amazing people and always so welcoming!' - EMMA M\n\n'I really enjoy the classes that Sam and Lyanne put on, it's a lot of fun and positive vibes, really good classes to help you be motivated and improve confidence!' - EMILY S",
          "strapLine1": "Where Modern Women Warriors Are Made"
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tR44o25X-filename.jpeg"
        },
        "score": 68
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490888470002,
      "feature_in_area": "none",
      "__v": 8
    },
    {
      "_id": {
        "$oid": "58dd5f8b96613e7d8507fe32"
      },
      "lastUpdated": 1490902975242,
      "createdAt": 1490902923481,
      "email": "james@eatwellandworkout.com",
      "firstName": "James",
      "surname": "Chandler",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "5a77ff1d1ad176af8c968ca37aa316e47ce76091",
      "salt": "2sRSF32x2A+lfDUKHUdq5w==",
      "hashedPassword": "gZY+MkiGMx/hyg7F2g85Denuvj62+eKv4qId+2Nz9O9bgSVwPFugGMWhvRbWZDfl62ihzNCnan7Z7A6ciJM1rA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": null,
          "profileTitle": "General",
          "radius": 100,
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.9090329004116413,
                51.37356147101172
              ],
              "country": "United Kingdom",
              "city": "Farley Hill",
              "postcode": "RG7"
            }
          ],
          "companyName": null,
          "phone": {
            "code": "+44",
            "number": "7870262741"
          }
        },
        "score": 23
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1490902923743
    },
    {
      "_id": {
        "$oid": "58dfa25f96613e7d850879c6"
      },
      "lastUpdated": 1495550135130,
      "createdAt": 1491051103127,
      "providerId": "10155195727353824",
      "provider": "facebook",
      "surname": "Kennerley",
      "firstName": "James-Elliott",
      "email": "james_kennerley@btinternet.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155195727353824/",
          "disciplines": [
            "Functional Training",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 35,
            "max": 60
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "biography": "I started developing my fitness from primary school and tried my hand at most sports throughout school and college. While studying public uniformed services there was an extensive amount of fitness this was when I realised I wanted to pursue fitness and health as a life career instead of joining a service I have since then worked within the fitness industry for over a year training to be a personal trainer picking up as much experience before Currently working as a level 3 PT. I've been at The Gym Group Manchester for 3 years. \nFuture goals are to achieve level 4 sports phychology or sports nutrition.\nI'm currently studying strength and conditioning at level 3 with Brendan chaplins mentorship.",
          "locations": [
            {
              "geolocation": [
                -2.238273100000015,
                53.47922390000001
              ],
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "yearsOfExp": 2014,
          "radius": 0,
          "companyName": null,
          "phone": {
            "code": "+44",
            "number": "8448732142"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ueytRRZZ-jameskennerlylogo.jpeg"
        },
        "score": 60
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "james-elliott_kennerley",
      "lastLogin": 1491051103592
    },
    {
      "_id": {
        "$oid": "58e15b1c96613e7d8508d7c9"
      },
      "lastUpdated": {
        "$date": "2018-01-19T12:50:21.348Z"
      },
      "createdAt": 1491163932850,
      "email": "arsportstherapyclinic@outlook.ie",
      "firstName": "Aidan",
      "surname": "Raftery",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "h9e+Y+PDpjoOH0RVNEXUmQ==",
      "hashedPassword": "ehhz7mJcLotJCqSw3SMHHAbWzxiL3G4rGzOjymTS+S5EiamxLhvcbFDa+jLli83DujN9WUL6E/vhUyvaW3H0aw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/k5QtX7NP-IMG_20161208_042129.jpg"
        },
        "personal": {
          "disciplines": [
            "Injury Assessment",
            "Injury Prevention",
            "Injury Rehabilitaion",
            "Massage",
            "Sports Specific"
          ],
          "qualifications": [
            "Sports Therapy",
            "Level 4: Online",
            "Level 2: First Aid / CPR",
            "Level 3: Online",
            "CFR",
            "neuromuscular",
            "Trigger point therapy",
            "Sports massage",
            "Occupational First Aid",
            "Kinesiology Taping",
            "ACL injuries",
            "And more"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 45,
            "max": 60
          },
          "companyWebsite": null,
          "profileTitle": "Sports Therapist",
          "radius": 60,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/anwxol4b-FB_IMG_1477312076763.jpg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "2058101412.8640130.6db681c9404a4af5be5883ff6b969e88",
            "link": "https://www.instagram.com/arsportstherapyclinic"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/1922639751304093/",
          "facebookPage": "ARsportstherapyclinic",
          "yearsOfExp": 2012,
          "affiliation": "IRISH MASSAGE THERAPISTS ASSOCIATION",
          "strapLine1": "At AR Sports Therapy Clinic I care about your recovery",
          "biography": "My name is Aidan Raftery. I am Sports Therapist with Boyle Celtic FC 1st team and I have my own Sports Therapy Clinic which I run by appointment.\n\nI tried to add pictures of my qualifications, certificates and references but the pictures are too large to uppload\n\nEMAIL arsportstherapyclinic@outlook.ie\n\nI do Injury Prevention Advice sessions for clubs, teams, individual Athletes and individual players in a wide range of sports.\n\nI would be grateful if you voted to promote me\n\nSPORTS THAT I HAVE EXPERIENCE IN TREATING INJURIES FOR\nGealic football \nHurling\nSoccer\nRugby\nRunning \nMarathons\nCycle races\nAnd more\n\nIf you need more information on my qualification and services don't hesitate to contact me and I would be happy to tell you more. \n\nPREVIOUS CLUBS: \nBallaghadereen FC 1st Team \nRoscommon & District League football team\nSt Brigids GAA Club\nElizabethtown College FC \nRoscommon Heals GAA Club\nManor Utd FC",
          "offers": "RATES\nStudents (with student card)   €25 per hour\n\nNormal €45 per hour\n\nInjury Prevention Advice Sessions €60\n\nPrice for individual games/blitzes/1/2 day tournaments €60.\n\nTraining sessions \nPer session €40\n3 training sessions €70\n\nRates for season long can be agreed depending on club/team requirements ie\nMatches & training sessions\nJust matches\nJust training sessions\n\nFor further information and to discuss your requirements or the requirements of your team or club just\nEMAIL arsportstherapyclinic@outlook.ie\nCALL/TEXT 0894304496",
          "locations": [
            {
              "geolocation": [
                -8.217317024413365,
                53.628797266827505
              ],
              "city": "Roscommon",
              "postcode": "F42 TK49",
              "_id": {
                "$oid": "5a61e98d765eba094646f8d7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "894304496",
            "code": "+353"
          }
        },
        "score": 81
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 1,
      "trainer_tools_access": false,
      "lastLogin": 1491301034835,
      "username": "aidan_raftery",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "58e26a9f96613e7d850911cf"
      },
      "lastUpdated": 1493640062138,
      "createdAt": 1491233439600,
      "email": "personaltrainingwithjulie@yahoo.co.uk",
      "firstName": "Julie",
      "surname": "Rabbage",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "7hnJoPyHF//11wN2mvQCKg==",
      "hashedPassword": "o+3+blsvO5yoB4lr9HoDMIvbqg94abc42VXsc5YTyB11+dO3p0T0Al6MyCP2Fj7wtM9zmw9C9qySQ6hRXW40aw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/d0sv5e3h-Julie.jpg"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Boxing",
            "Group Classes",
            "Mobile Trainer",
            "Pre/post Natal"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Ante & Post Natal",
            "Level 3 Torso Training",
            "Level 3 Exercise Referral",
            "Hatton ABC Fundamentals Boxing for Fitness",
            "Hatton ABC Advanced Boxing for Fitness",
            "Group Training",
            "Ante Post Natal Award",
            "First Aid for sport and fitness."
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 40,
            "max": 50
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6TbvFsHW-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "julie.rabbage.PT",
          "yearsOfExp": 2011,
          "affiliation": "REP's",
          "biography": "I love being able to help and motivate people to get fit and healthy. My sessions are all done outside in the local Park.\n\nIt doesn't matter if you're new to fitness, or just coming back to it, male, female, pregnant or post natal, I can create a session that works for you and your ability.\n\nI use a variety of equipment, as well as body weight exercises.\nExercise should be fun and motivating.",
          "offers": "1 to 1\n1 to 2\nsmall groups.\n\nYou can buy individual sessions or buy a block of 5 or 10 sessions.",
          "strapLine1": "Do you need help and motivation to take the next step to leading a more healthy lifestyle? Then look no further...",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.007658900000024005,
                51.48257659999999
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "SE10"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7815791454"
          }
        },
        "score": 82
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1493639905770,
      "username": "julie_rabbage"
    },
    {
      "_id": {
        "$oid": "58e2aad696613e7d85091f52"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:03:16.706Z"
      },
      "createdAt": 1491249878774,
      "surname": "Stadler",
      "firstName": "Jessica",
      "email": "JStadler810@gmail.com",
      "providerId": "3863290561",
      "provider": "instagram",
      "username": "jessica_stadler_HJ3GjQlpg",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "3863290561.8640130.ec0d5162f91b45b587cd113b7db9bc17",
            "link": "https://www.instagram.com/assbyjess"
          },
          "disciplines": [
            "Bootcamp",
            "Hiit",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 4: Master Personal Trainer",
            "Level 4: Online",
            "Level 2: First Aid / CPR"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 60,
            "max": 100
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HYwtAsHi-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10210677969123496/",
          "facebookPage": "Assbyjess",
          "yearsOfExp": 2013,
          "strapLine1": "Online fitness programs to help you burn fat and build muscle. Online Classes, challenges, and workouts.",
          "offers": "*6 week spring to summer challenge: get more information to sign up: April 17th. $120.00 \n\nWww.assbyjess.com for package details:\n$60.00 6 week program with email accountability \n$240 6 week program with phone call accountability\n\n*Online classes to purchase\n*In-person classes:\nTuesdays at 6:00pm\nThursdays at 9:00am",
          "biography": "Ass By Jess is a comprehensive fitness plan designed to help you burn fat while building target muscles at the same time through progressive 6 week workout phases.  You will learn proper form from detailed in-app video demonstrations, exercise descriptions, and example photos to help you progress each week on your fitness routine.  You will consistently break through mental and physical challenges and build confidence in your mind and body.  Ass By Jess workouts are designed to encourage women at any level to build better habits and strive to reach new levels.  Weekly accountability conversations and an active support community will push you to continuously change and grow in your fitness challenges. Now is your time to feel sexy, fit, strong, and beautiful.\n\nWww.assbyjess.com",
          "locations": [
            {
              "geolocation": [
                -87.62979819999998,
                41.8781136
              ],
              "city": "Chicago",
              "postcode": "60604",
              "_id": {
                "$oid": "5a600064765eba094646f743"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "phone": {
            "number": "7604705125",
            "code": "+1"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wm1yVyxV-filename.jpeg"
        },
        "score": 81
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1491249880307,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58e32ed096613e7d85093b95"
      },
      "lastUpdated": {
        "$date": "2018-01-18T02:02:52.110Z"
      },
      "createdAt": 1491283664933,
      "providerId": "10211566349490041",
      "provider": "facebook",
      "surname": "Meuris",
      "firstName": "Joeri",
      "email": "jmeuris@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10211566349490041/",
          "disciplines": [
            "Massage",
            "Nutrition",
            "Personal Training",
            "Sports Therapy",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 4: Strength & Conditioning",
            "Level 4: Master Personal Trainer",
            "Level 4: Advanced Personal Trainer",
            "Level 4: Stroke",
            "Level 2: Aqua Teacher",
            "Level 2: Exercise Music & Dance Partnership(EMDP) Teacher",
            "Level 2: Gym Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 40,
            "max": 150
          },
          "companyWebsite": null,
          "profileTitle": "Yoga Teacher",
          "radius": 100,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LIaxSVze-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2001,
          "biography": "GO-FiT Mechelen\n\nNekkerspoel 199 \n2800 Mechelen\n+32 (0)15 34 05 60\nBE 0864.843.882\nOpeningsuren\nWeekdagen - 9:00 tot 22:00 \nVrijdag - 9:00 tot 12:00 en 16:00 tot 22:00 \nWeekends - 9:00 - 13:00",
          "locations": [
            {
              "geolocation": [
                4.499419999999986,
                51.03204
              ],
              "city": "Mechelen",
              "postcode": "2800",
              "_id": {
                "$oid": "5a60004c765eba094646f742"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": null,
          "phone": {
            "number": "479283809",
            "code": "+32"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NxlSvakI-filename.jpeg"
        },
        "score": 59,
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "joeri_meuris",
      "lastLogin": 1491283665248,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58e36a4296613e7d85094856"
      },
      "lastUpdated": 1491302286067,
      "createdAt": 1491298882877,
      "surname": "Fernandes",
      "firstName": "Luke",
      "email": "fernandes.luke7@gmail.com",
      "providerId": "4229302693",
      "provider": "instagram",
      "username": "luke_fernandes",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "4229302693.8640130.0210501c8a3c4ee4a2d0a7f6bd157e1c",
            "link": "https://www.instagram.com/luke.fit.dxb"
          },
          "disciplines": [
            "Functional Training",
            "Rehabilitation",
            "Strength & Conditioning",
            "Sports Therapy",
            "Nutrition"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Older Adults Instructor",
            "Level 4: Chronic Respiratory Disease",
            "Level 4: Lower Back Pain Instructor",
            "Level 4: Mental Health",
            "Level 4: Stroke",
            "Level 4: Strength & Conditioning",
            "Level 4: Obesity & Diabetes",
            "Level 4: Cardiac Rehab Phase IV Exercise Instructor",
            "Level 4: Falls Prevention Instructor",
            "Level 4: Long-Term Neurological Conditions",
            "Level 4: Master Personal Trainer",
            "Level 3: Personal Trainer",
            "Strength and conditioning"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 80,
            "max": 80
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IHM7cyEG-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "affiliation": "REPS, ESSA",
          "yearsOfExp": 2010,
          "biography": "Experienced Specialist with a demonstrated history of working in the health wellness and fitness industry.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                55.270782800000006,
                25.2048493
              ],
              "country": "Other",
              "city": "Dubai",
              "postcode": "City Tower 2 Car Park, Sheikh Zayed Road"
            }
          ],
          "phone": {
            "code": "+971",
            "number": "563038114"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4ISJAA05-filename.jpeg"
        },
        "score": 69
      },
      "role": "trainer",
      "activation_code": "3668583d75b05bd633482b04fc5bfd14507a8e56",
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1491298883052
    },
    {
      "_id": {
        "$oid": "58e374dd96613e7d85094a97"
      },
      "lastUpdated": 1491311984162,
      "createdAt": 1491301597735,
      "providerId": "10154271143065927",
      "provider": "facebook",
      "surname": "Walker",
      "firstName": "John",
      "email": "jonny93mk@hotmail.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154271143065927/",
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Boxing",
            "Functional Training",
            "Hiit"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 25,
            "max": 30
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RutrGa54-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2011,
          "biography": "My name's John, Personal Trainer and founder of JW Personal Training. I provide high quality Personal Training in the Milton Keynes area, specialising in outdoor exercise. starting with a first session which is completely FREE!\n\nWhen you start with JW Personal Training we will discuss your fitness goals, experience, likes and dislikes with you to tailor a fitness programme around you. \n\nI am a strong believer that fitness and exercise should be fun and creative. This is why I endeavour to provide my clients with creative and exciting exercises and drills, with a variety of training styles and exercise variations to ensure that each session is inspiring, fun and varied.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.7594171000000642,
                52.0406224
              ],
              "country": "United Kingdom",
              "city": "Milton Keynes",
              "postcode": "MK9 3DX"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7958222894"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kfYTXbx4-filename.jpeg"
        },
        "score": 70
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "john_walker",
      "lastLogin": 1491301598053
    },
    {
      "_id": {
        "$oid": "58e444c2ec1af038522140ca"
      },
      "lastUpdated": 1491354818165,
      "createdAt": 1491354818165,
      "email": "daveskieoz@gmail.com",
      "firstName": "Davinia",
      "surname": "Ng",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "43f3e90c8f8f39d82b0a9c797a4579596a0a2a86",
      "salt": "EpnrzaJHlZiyyqcz5V6kWg==",
      "hashedPassword": "gMg2oCqNh5fpGy1S/wWoGXmh9MSR+Y4SP92i6tl1xi/vtptZ4tHn+NonmkpSPzkzlswjSB5uDpynTzvSL0eZ3Q==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "country": "United Kingdom",
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyWebsite": null,
          "profileTitle": null
        }
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1491354818790
    },
    {
      "_id": {
        "$oid": "58e51fdeec1af03852216f91"
      },
      "lastUpdated": {
        "$date": "2017-12-03T16:23:26.684Z"
      },
      "createdAt": 1491410910662,
      "providerId": "10154570275602852",
      "provider": "facebook",
      "surname": "Atkins",
      "firstName": "Jay",
      "email": "jasonatkins188@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154570275602852/",
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Olympic Lifting",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Free Weights",
            "Cardio",
            "Functional Training Zone",
            "Gym",
            "Apparel for Sale",
            "Changing Rooms",
            "Free Parking",
            "Hot Showers",
            "Lockers",
            "Mat Area",
            "Resistance"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.corethegym.com",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WQIekrBV-CORE_Oct_2017_097.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "CoreTheGym",
            "link": "https://twitter.com/CoreTheGym",
            "user_id": "3239321103",
            "accessToken": "3239321103-ZtaIB1JliodQOz9Dp6laUKafh6yolgCXJBD3SG8",
            "accessTokenSecret": "EHo9PIZj9PvwBgLnuAMAS5aWemkV5iDyDpeezpOhTjqZG"
          },
          "instagram": {
            "access_token": "2157274311.8640130.1ef79c0a28f24870bc8dc3246809930c",
            "link": "https://www.instagram.com/corethegym"
          },
          "google": "https://plus.google.com/105853791104152958751",
          "facebookPage": "CoreLeisure",
          "yearsOfExp": 2016,
          "offers": "SIMPLE & CLEAR PRICING\nThere's no contracts. Just pay as you go by direct debit. What are you waiting for?\n\nThere are 3 options available \n\nMonthly Membership £21.99 per month\n\nAnnual Membership £200 (£60 saving over monthly Direct Debit)\n\nLifetime Membership £550!",
          "biography": "CORE - THE GYM IN MAIDSTONE\n\nCore is a local, independent gym in Maidstone. We have a varied range of equipment and expert trainers on hand to offer personal training regimes, tailor-made to suit you, and a number of classes ranging from legs, bums and tums to Olympic weightlifting. Whether you are a fitness fanatic looking to sculpt your body to perfection, or a complete newbie just wanting to keep fit, Core is the perfect place for you to embark on an incredible journey to becoming a happier, healthier you!\n\nAt Core, we are dedicated to helping you achieve and maintain your health and fitness goals so if you're looking for a gym membership in Maidstone, Core is the perfect club for you. We understand that each customer is unique and we strive to help you find a programme and routine that works for you. In recent years we have seen a huge rise in people wanting to achieve a healthier lifestyle and we want to help you get to know your own body and become more 'health aware'. Call us today on 01622 828599 to take the first step to becoming a new, happier and healthier you!\n\nThis Maidstone gym is perfect for anyone living locally, as we offer the most extensive range of cardiovascular equipment and free weights in the Maidstone area. We pride ourselves on being excellent value for money.",
          "locations": [
            {
              "geolocation": [
                0.5261331000000382,
                51.27348809999999
              ],
              "city": "Maidstone",
              "postcode": "ME14 1BW",
              "_id": {
                "$oid": "5a2424f9a95f7a0d7e4bda72"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "CORE. the gym",
          "phone": {
            "number": "1622828599",
            "code": "+44"
          },
          "openingTimes": "Address:\nUnit 7D 56 King Street, ME14 1BW Maidstone, Kent\n\nOpen:\n\n6.00am - 10pm Monday to Friday\n\n7.30am - 8pm Saturday, Sunday and Bank Holidays",
          "strapLine1": "Voted Kent's Best Gym"
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EXQaxN3s-CORE_Oct_2017_144.jpg"
        },
        "score": 79
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "core._the_gym",
      "lastLogin": 1512317220764,
      "feature_in_area": "none",
      "__v": 12,
      "agent": {
        "family": "Safari",
        "major": "11",
        "minor": "0",
        "patch": "1",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_1) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0.1 Safari/604.3.5"
      }
    },
    {
      "_id": {
        "$oid": "58e636ba54fbd25db3b57056"
      },
      "lastUpdated": {
        "$date": "2017-11-21T10:49:06.349Z"
      },
      "createdAt": 1491482298703,
      "email": "neilowens@podfit.co.uk",
      "firstName": "Neil",
      "surname": "Owens",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "R7EZdxRxGDTmfL61+SOzow==",
      "hashedPassword": "7X75Ggu0jk+re8sbJ14JER+j3emhhvf5eWuAWPuhEug5lXCExmX3lm+PA4/O7VqYEBD3xl3ECF8qEe3gFyYOvw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tspmq8Q5-Podfit_Twitter_Profile_Image_2.png"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Corporate Services",
            "Diet & Nutrition",
            "Personal Training",
            "Wellness"
          ],
          "qualifications": [
            "Gym",
            "Private training zones"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.podfit.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2JBg0No7-Slide%206.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "facebookPage": "podfit",
          "yearsOfExp": 2013,
          "affiliation": "REPS",
          "strapLine1": "You're Only 1 Workout Away From A Good Mood",
          "biography": "PICTURE THE PERFECT YOU.\n\nYou are full of energy and feeling vibrant and healthy. Your weight is comfortable, your muscles are firm and toned and you can wear any clothes that you want. Everywhere you go people compliment you on your appearance and tell you that you look fantastic. More important, you actually feel fantastic!\n\nYou have no aches and pains, no disease or illness and you feel confident, alert and in control of your life. No physical endeavour feels like it's beyond you.​\n\nTHIS IS WHERE PODFIT WILL HELP YOU GET TO\nHailed as the top personal training team in York, our city centre based studios boast the best qualified, most experienced and most knowledgeable health and fitness experts in York who hold a sparkling array of qualifications and experience.\n\nAnd what's more, rather than merely making idle claims about what we can help our clients achieve, we back this up with the.....\n\nonly RESULTS GUARANTEED programmes of their type to be found anywhere in York, meaning that not only will you get great results from your time with us, but that if you don't, you won't pay us a penny!\n\nThis isn't low risk, it is no risk. All you have to do is follow our programme.",
          "locations": [
            {
              "geolocation": [
                -1.082066,
                53.958947800000004
              ],
              "city": "York",
              "postcode": "YO1 8SJ",
              "_id": {
                "$oid": "5a1404a22288dd3a30f3f45b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Podfit",
          "phone": {
            "number": "1904631539",
            "code": "+44"
          }
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1491482767979,
      "username": "podfit",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "58e78be654fbd25db3b5b91a"
      },
      "lastUpdated": 1491569638100,
      "createdAt": 1491569638100,
      "email": "info@mybodyunity.com",
      "firstName": "Emma",
      "surname": "Sills",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "5b3c66a0fc5b96f0935e42f8e6b861b8789c1cb3",
      "salt": "pe8lznqQFOMQG3sVTrTC+A==",
      "hashedPassword": "OYvfA/HUE02/hFOQPUZM5ekQ9E0LuanoBgu5MsfwT+4bC7COieHZ169xD+2FGCq0+KeCfpEr45i33X5Z67kvrw==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "country": "United Kingdom",
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyWebsite": null,
          "profileTitle": null
        }
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1491569638312
    },
    {
      "_id": {
        "$oid": "58e7bac654fbd25db3b5c31c"
      },
      "lastUpdated": {
        "$date": "2017-09-25T05:36:51.148Z"
      },
      "createdAt": 1491581638304,
      "email": "poslovni234@yahoo.com",
      "firstName": "Marija",
      "surname": "Pajović",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "fb7ef190aae85cf60fcd0b442444b2ec33e66b6a",
      "salt": "qb4p4CVrklHNeRi/yC9bLQ==",
      "hashedPassword": "ai3XaTGauyUbCGOPu1q/xpXn7Tn5+ddju7VnpsXD6imECn7wxiirAm/n3MzJlH4580cw9xUwGdu5851wp/yjDw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qXdNxnR3-IMG_2211.JPG"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Functional Training",
            "Mobile Trainer",
            "Online Coaching",
            "Sports Therapy"
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 0,
            "max": 200000000000000
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 1000,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FTDRSBsa-IMG_0360.JPG",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/185571411959213/",
          "biography": "Bodybuilding&Fitness Trainer Specialist",
          "locations": [
            {
              "geolocation": [
                13.799330000000054,
                47.91839
              ],
              "city": "Gmunden",
              "postcode": "4810",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "code": "+43",
            "number": "6766518444"
          }
        },
        "score": 65
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1491948057970,
      "username": "marija_pajović",
      "feature_in_area": "none",
      "password_reset_code": "f2594b4fbfd0d817abed866563c4cd26a1f5c56b",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58ea8be854fbd25db3b65d1e"
      },
      "lastUpdated": {
        "$date": "2018-01-19T12:08:02.189Z"
      },
      "createdAt": 1491766248144,
      "providerId": "1527214617298068",
      "provider": "facebook",
      "surname": "White",
      "firstName": "Brian",
      "email": "brian.p.white@ntlworld.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1527214617298068/",
          "disciplines": [
            "Endurance",
            "Nutrition",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Biomechanics Coach",
            "Level 3: Advanced Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 35,
            "max": 40
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 30,
          "biography": "As a highly qualified Personal Trainer and Biomechanics Coach, I can create the perfect individualised fitness programme for you, which will utilise nutritional & dietary advice, cardiovascular training and appropriate strength & weight-training techniques. Combine this programme with one-on-one tuition & support and you have the perfect recipe for successful goal achievement.\n\nPersonal Training is all about the individual. Once your specific needs and abilities are identified, I will construct an effective exercise programme. I want to improve my clients' lifestyles from a fitness perspective, and I have the experience to work with all levels, from new starts to experienced athletes.\n\nBiomechanical screening is a new approach to injury prevention & conditioning. Simply, I can make you move more freely thus enhancing training.\n\nAs an England Athletics Endurance Coach I can provide structured run training and technique analysis. You will achieve a more efficient running technique, improved performance and reduced injury concerns.\n\nSpecialties: Running Biomechanics,\nStrength & Conditioning,\nWeight Loss,\nBiomechanical Screening,\nBarefoot (forefoot) Running Instructor.",
          "strapLine1": "The Complete Personal Training Package and Biomechanics Coach for the Stevenage, Hertfordshire Area.",
          "locations": [
            {
              "geolocation": [
                -0.19167674908946525,
                51.92388323182328
              ],
              "city": "Stevenage",
              "postcode": "SG1 4RX",
              "_id": {
                "$oid": "5a61dfa2765eba094646f8bb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7989831042",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6uTQnjn2_profilePicture.jpeg"
        },
        "score": 66
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "brian_white",
      "lastLogin": 1491766248418,
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "58ebcae554fbd25db3b6a121"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:54:59.380Z"
      },
      "createdAt": 1491847909692,
      "email": "mattjdowse@yahoo.co.uk",
      "firstName": "Matt",
      "surname": "Dowse",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "28u/X0HREFlhqUiRW+f6rw==",
      "hashedPassword": "1QENnCViNs8u/sm0cYJVBag4kED4vOlBQCOg4JaEBKDM53TBw+J963dWEaoP8VWj9XMISGDYSHnZMLdL3EjD1w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0BsunbOr-IMG_1372.JPG"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Endurance",
            "Functional Training",
            "Hiit",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 50,
            "max": 75
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 8,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/g1qvG2H0-IMG_2757.JPG",
            "size": {},
            "offset": 0
          },
          "facebookPage": "MattDowsePersonalTraining",
          "yearsOfExp": 1996,
          "strapLine1": "Progression is everything, be it an hour session or 3 month programme, aim to end in a better place to where you started",
          "instagram": {
            "access_token": "1573658687.8640130.3a1b5f213d9e4939af57b71a14b81b60",
            "link": "https://www.instagram.com/mattjdowsept"
          },
          "biography": "Are you bored of doing loads of cardio, losing some weight, indeed, making some good progress in the gym initially. Now you've hit a bit of a roadblock, can't shift any more, these next few pounds just won't budge. Try something different! If you keep doing the same thing you'll keep getting the same results! Make a change, make a difference. You don't have to train harder, just smarter!",
          "locations": [
            {
              "geolocation": [
                -0.18321760681313407,
                51.436087875233085
              ],
              "city": "London",
              "postcode": "SW17 0JX",
              "_id": {
                "$oid": "5a5ffe73765eba094646f73c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7768871147",
            "code": "+44"
          }
        },
        "score": 71
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1491852154318,
      "username": "matt_dowse",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58ede3be54fbd25db3b713a2"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:54:39.570Z"
      },
      "createdAt": 1491985342050,
      "surname": "Philippou",
      "firstName": "Helena",
      "email": "info@helenaphil-fitness.com",
      "providerId": "200800058",
      "provider": "instagram",
      "username": "helena_philippou",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "200800058.8640130.027ab2ea6ac94502956dc2d756671b51",
            "link": "https://www.instagram.com/helenaphil_fitness"
          },
          "disciplines": [
            "Boxing",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Level 3: Master Personal Trainer",
            "Level 4: Mental Health",
            "Food Intolerance Practitioner & Brunel Health Practitioner",
            "Level 3: Advanced Group Exercise to Music Instructor",
            "Level 3: Nutrition",
            "Level 2: Exercise Music & Dance Partnership(EMDP) Teacher",
            "Level 2: Gym Instructor",
            "Level 2: Disability",
            "Level 3: Online",
            "Level 3: Exercise Referral Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 60,
            "max": 60
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YNFBhOS2-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "Helenaphilfit",
            "link": "https://twitter.com/Helenaphilfit",
            "user_id": "102767082",
            "accessToken": "102767082-55ThqDWd691BoWrLZhoCSGxNo17vdJ98Sf73UH2s",
            "accessTokenSecret": "CpdE1Y6BkwQJTEIWopc4WvIwQKSq5N4PspOO4R1ZI5TyK"
          },
          "google": "https://plus.google.com/115412927864094916883",
          "affiliation": "REPS",
          "yearsOfExp": 1993,
          "strapLine1": "diet fitness expert #TheCommonSenseCoach",
          "biography": "I have been in the fitness industry for 23​ years, in various roles from: teaching a variety of group-fitness classes, managing and personal training to nutrition. In addition, I am also a master trainer Flexi-Bar.\nI am also a Lorisian food Intolerance practitioner offering tests and also a Brunel Health practitioner collaborating with Harley Street Doctors for best professional advice.\n\nMy mission updating my knowledge keeping on top of the game, and promoting health & fitness as a lifestyle, not a fad. Letting the public know of the best investment your health.\n\nI also write and have written for numerous publications on the subject",
          "offers": "I offer various discounts on some of the tests I supply. Please email me: info@helenaphil-fitness.com\n\nSundried Clothing-get 50% discount use code: helena\nwww.sundried.co\n\nEnertor- foot soles as worn by Usain bolt for prevention of injuries. Get £10 of using code: PTHP10\nwww.enertor.com\n\nOrigym online education. Get 20% discount off any booked courses using code: ORIGYM-www.origympersonaltrainercourses.co.uk",
          "locations": [
            {
              "geolocation": [
                -0.12775829999998223,
                51.5073509
              ],
              "city": "London",
              "postcode": "WC2N 5DU",
              "_id": {
                "$oid": "5a5ffe5f765eba094646f73b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7450401477",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fcATsSiA-filename.jpeg"
        },
        "score": 81,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "c37d1b8f27ed30c63b024c9a14a960cc64283b12",
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1491985342293,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58ee769510bf9a2caacb39a8"
      },
      "lastUpdated": 1492173816038,
      "createdAt": 1492022933577,
      "email": "info@containertrainer.co.uk",
      "firstName": "Steve",
      "surname": "Lewis",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "af6bf180723f576f67904245630d53180b084af5",
      "salt": "DSNHZ44l8OPegry7G1hicQ==",
      "hashedPassword": "1NGZ3gopv+bh+A53I02LFbRTdy9Vf8o9Kf+i8HB7GU5m2QaecRAsY1Ce+UtwxKxbmUzr6Z7uD1ekAQNksdohWA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BVqnp0a5-Inkedthumbnail_IMG_20161113_190421_LI12.jpg"
        },
        "personal": {
          "disciplines": [
            "Personal Training",
            "Bootcamp",
            "Strength & Conditioning",
            "Micro Group Classes",
            "Cardio"
          ],
          "qualifications": [
            "UK Personal Training Franchise Opportunities"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.containertrainer.co.uk",
          "profileTitle": "General",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/w5Zdbsp6-20161212_214204.jpg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "3094662833.8640130.3f535b3f81944c87913c0ad23a40d78a",
            "link": "https://www.instagram.com/containertrainer"
          },
          "facebookPage": "containertrainer/",
          "yearsOfExp": 2013,
          "affiliation": "REPs/Cimpsa",
          "strapLine1": "Personal Training Inside Out",
          "offers": "For further information, please drop us a brief email at: Info@containertrainer.co.uk with the following information: Name, Age, Relevant Qualifications and Experience, The current number of weekly PT sessions you deliver, your current location and the your style of training or niche, for example CrossFit, Strength and Conditioning or High Intensity Interval Training etc.",
          "biography": "Container Training is seeking experienced, professional and motivated PT'S throughout the UK. If you're fed up giving away a large percentage of your earnings, chasing unrealistic targets, paying too much floor rent or working too many hours for FREE in order to offset your rent, and want to start your own business with full support, then get in touch.\n\nAll of our PT studios are totally bespoke, sympathetic to their environment and placed at unique locations throughout the UK where outdoor training space is abundant.\n\nOur PT studios have been rigorously piloted and continuously improved over a 36 month period, overcoming numerous obstacles to ensure an awesome training facility/experience in a constantly changing environment. \n\nBenefits of exercising outdoors (green exercise) include a higher calorific expenditure, improved mood and self esteem, constantly changing scenery and an essential hit of vitamin D. All six doors on our unique personal training studios open up to let the outside in!\n\nThis Franchise opportunity is available throughout the UK, enabling PT's to build successful businesses away from the constraints of the traditional corporate models.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -1.8561434000000645,
                52.3857315
              ],
              "country": "United Kingdom",
              "city": "Earlswood",
              "postcode": "B90 1PT"
            }
          ],
          "companyName": "CONTAINER TRAINER",
          "phone": {
            "code": "+44",
            "number": "7739396294"
          }
        },
        "score": 80,
        "parent": null
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492356734580,
      "username": "container_trainer"
    },
    {
      "_id": {
        "$oid": "58f1deb43c37e511af19040c"
      },
      "lastUpdated": {
        "$date": "2018-01-19T12:16:51.669Z"
      },
      "createdAt": 1492246196522,
      "email": "rhinocombatsports@gmail.com",
      "firstName": "Michael",
      "surname": "Kenna",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "dfc0f6455b5963be09c01ea4c4c0f4f15df07499",
      "salt": "D1Agl0scirzj4Miitivybg==",
      "hashedPassword": "1AlBlse3wneig8wZ/jp1wXojhtcAaw30bxXrWE1jD057HR6AQky/rY6+oMasiXVqtavUOlD2ZxJbQ3A571CGtw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "AUD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AYHAeKDe-michaelkennalogo.jpeg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Karate",
            "Kick-boxing",
            "Mobile Trainer",
            "Muay Thai"
          ],
          "qualifications": [
            "8th degree Black Belt (P) Zen Do Kai Karate Instructor",
            "7th Degree BJC Muay Thai Trainer/Coach",
            "7th Degree Black Belt Fighting Fit/Tara Miliata",
            "Recognised International Representative and Chief Instructor",
            "5th Degree Black Belt BJMA Krav Maga",
            "1st Degree Black Belt BJC Arnis Escrima Kali",
            "Diploma in Fitness & Sports Coaching",
            "Australasian Representative for the World Kickboxing & Kara-te Association",
            "Senior BJMA Training Coordinator",
            "Former WKA South Pacific Welterweight Muay Thai Champion (1998)"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 65,
            "max": 200
          },
          "companyWebsite": null,
          "profileTitle": "Martial Artist",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qfFNqkhp-rhinobg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Nothing beats the assurance you get from one-on-one instruction with a fully qualified fitness professional.  \nMichael has attained diplomas in Fitness and Sports coaching and can tailor your program to meet those goals and needs.\nOur sessions will focus on motivating you to achieve your personal goals and objectives, enhancing your proficiency in one or many of our martial art offerings or simply improving your fitness! \nWe also have an optional grading system that allows you to set some training goals along the way!\n\n8th degree Black Belt (P), Zen Do Kai Martial Arts Instructor\n7th Degree, BJC Muay Thai Trainer/Coach\n7th Degree Black Belt, Fighting Fit/Tara Miliata\nRecognised International Representative and Chief Instructor\n5th Degree Black Belt, BJMA Krav Maga\n1st Degree Black Belt, BJC Arnis Escrima Kali\nAustralasian Representative for the World Kickboxing & Kara-te Association\nSenior BJMA Training Coordinator\nFormer WKA South Pacific Welterweight Muay Thai Champion (1998)",
          "locations": [
            {
              "geolocation": [
                145.0190722571174,
                -37.63354155704159
              ],
              "city": "Epping",
              "postcode": "3076",
              "_id": {
                "$oid": "5a61e1b3765eba094646f8bf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": null,
          "phone": {
            "number": "419586559",
            "code": "+61"
          }
        },
        "score": 54
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492246197064,
      "username": "michael_kenna",
      "feature_in_area": "none",
      "__v": 13
    },
    {
      "_id": {
        "$oid": "58f527f83c37e511af19b78e"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:43:23.420Z"
      },
      "createdAt": 1492461560902,
      "email": "befitandlive@gmail.com",
      "firstName": "Robert",
      "surname": "LoFaso",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "GPjvORGz6SHIb0Y5EByPNQ==",
      "hashedPassword": "XdTd2KfqXcuL3LstXsl8wAldeh52j8DwE25gItnk/Q9INUKSsT0IPC5ZoetYeGh3STuRYg0CPWwLKmNmmwltHg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GTwuakKr-bob%20head%20cropped.JPG"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Endurance",
            "Functional Training",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Level 2: First Aid / CPR"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 65,
            "max": 75
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 8,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nX8iangp-14231247_1837449879819253_7689159289474623439_o.jpg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "761729645.8640130.95b3e38e41624f8ca964fca5b30fee9c",
            "link": "https://www.instagram.com/befitandlive"
          },
          "google": "https://plus.google.com/+RobertLoFasoBEFITANDLIVE",
          "facebookPage": "lofasobefitandlive/",
          "yearsOfExp": 2002,
          "affiliation": "Idea Health & Fitness Association",
          "biography": "Robert LoFaso is a Professional Fitness Trainer in New Jersey. \nHe is a U.S. Naval Veteran and holds exercise physiology certifications from S.U.N.Y Rockland Community College, World Instructors Training Schools at Bergen Community College and the Gray Institute.  \n\nRobert currently trains clients of all ages and fitness levels at their homes or at a local gym. Over his fitness training career, Robert has conducted over 17,000 sessions.  Robert believes in a holistic approach to fitness. He specializes in cardio, resistance, core training, stability, flexibility and functional movement workouts. For over three decades Robert has studied martial arts, holding 1st degree black belt in Tae Kwon Do and Aiki Jujitsu. He often uses teachings from his martial arts training in his fitness routines and techniques. \n\nRobert also provides longevity and wellness coaching.  He helps clients alter their eating and exercise behaviors to maintain a healthy and active lifestyle. Robert is a member of IDEA Health & Fitness Association and has achieved the title of Advance Professional Fitness Trainer.",
          "offers": "Train at Home or a private GYM \n◾Increase your energy level by improving your health\n◾Lose fat by increasing your muscle mass\n◾Increase flexibility and range of motion\n◾Relieve chronic pain through exercise\n◾Reduce the risk of disease as we age\n\nFitness Training\n◾Functional training to improve movement, performance, speed, and range of motion.\n◾Stability training for improved balance, stronger core and legs.\n◾Strength training & conditioning with free weights, bands, GYM equipment, and your body weight. \n◾Stretching to improve flexibility and to elongate each muscle group to improve elasticity and tone.\n\nNutrition\n•Nutritional guidance to help our clients live a healthier lifestyle through understanding what is good nutrition. \n•To give guidance in a proper diet, to make the necessary behavioral changes to maintain a new lifestyle. \n•I can help you improve your health, lower your body fat, and gain lean muscle . \n•I will help you achieve better performance and higher energy levels.\n\n◾Weight Management ◾Diet \n◾Recommend Supplements\n◾Food Shopping Assistance",
          "locations": [
            {
              "geolocation": [
                -74.14358429999999,
                41.0886216
              ],
              "city": "Mahwah",
              "postcode": "07430",
              "_id": {
                "$oid": "5a5ffbbb765eba094646f72e"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "phone": {
            "number": "8452164975",
            "code": "+1"
          }
        },
        "score": 73
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492462438513,
      "username": "robert_lofaso",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58f7ce0e3c37e511af1a4856"
      },
      "lastUpdated": 1492638628283,
      "createdAt": 1492635150353,
      "email": "info@www.thompson-personal-training.com",
      "firstName": "Chris",
      "surname": "Thompson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "TA1X+/nNLRfNbQ2thWDBPQ==",
      "hashedPassword": "Px4YoABuH1dMIFpGo4BJXTxaxsyHdh6NEcK53ZwOSTmR3qKnx50+/in2B20JkFxXIlCGK/kHP6GPRqBE7cZzvA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3LXnw13I-TPT-Badge-black-Fin.jpg"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Hiit",
            "Weight Loss",
            "Toning",
            "Functional Training"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 25,
            "max": 50
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 40,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lFIEJF8a-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "@ThompsonPersonalTrainingNE",
          "yearsOfExp": 2014,
          "affiliation": "REPS",
          "strapLine1": "Your Journey Starts Here",
          "offers": "For packages & prices please message for further details or visit \nwww.thompson-personal-training.com",
          "biography": "For all your fitness and nutrition needs Thompson Personal Training can help you create a new you.",
          "instagram": {
            "access_token": "1734605888.8640130.5a80fde93def4a359a410a30a65cad6f",
            "link": "https://www.instagram.com/thompsonpersonaltraining"
          },
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -1.603411000000051,
                54.95268
              ],
              "country": "United Kingdom",
              "city": "Gateshead",
              "postcode": "NE8"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7810510664"
          }
        },
        "score": 84,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 1,
      "trainer_tools_access": false,
      "lastLogin": 1492636363340,
      "username": "chris_thompson"
    },
    {
      "_id": {
        "$oid": "58f7dbe23c37e511af1a4b5a"
      },
      "lastUpdated": 1492645372279,
      "createdAt": 1492638690732,
      "surname": "Warburton",
      "firstName": "David",
      "email": "davidwarburtonfitness@gmail.com",
      "providerId": "300016714",
      "provider": "instagram",
      "username": "david_warburton",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "300016714.8640130.edd1cbaf65914ac3a1fbbe51e79d4139",
            "link": "https://www.instagram.com/david_warburton"
          },
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Online Coaching",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Degree in sports coaching",
            "advanced nutrition",
            "gym based boxing",
            "circuit training"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 25,
            "max": 30
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EtJxMo0N-IMG_0726.JPG",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155209216228050/",
          "facebookPage": "Warburtonfitness",
          "yearsOfExp": 2016,
          "affiliation": "REPS",
          "strapLine1": "Strive for greatness",
          "offers": "Individual sessions start at £30 a session for bulk bundles prices lower! \nGroup training also available!",
          "biography": "21 year old fitness enthusiast! Always aiming to improve my components of fitness whilst helping others change their lives! 💪🏽",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -1.6021931347749696,
                54.97352808417931
              ],
              "country": "United Kingdom",
              "city": "Newcastle upon Tyne",
              "postcode": "NE1"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7594510042"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SYA9aYIW-IMG_0733.JPG"
        },
        "score": 71,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492639487012
    },
    {
      "_id": {
        "$oid": "58f89829b5799e1be967f9b9"
      },
      "lastUpdated": {
        "$date": "2017-10-18T12:12:15.615Z"
      },
      "createdAt": 1492686889167,
      "email": "x4less.northwest.england@shoomon.xyz",
      "firstName": "Regional",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "haSvsQQdQL1N/iMEt3iyXw==",
      "hashedPassword": "LtPB4E6oCduJAihCfusBrui/ILDUOOgFR8sQjveTx2ACU0Mg6o04d9cLQAnZdWi2GHr5kZW5sFRfQ8kTpfKw5Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VAadzOE1-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Gyms Across The North West"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dSG3kGHb-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "Xercise4Less",
          "yearsOfExp": 2011,
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "biography": "Our clubs offer members of all ages the opportunity to exercise and live a healthy lifestyle in a non-intimidating and judgement-free environment, at an affordable cost.\n\nOur aim is to offer the best value for money in the industry. We love it when our customers tell us that they can’t believe the facilities that they get for the price. We are a value brand that has designed its business around passing cost savings on to our customers.\n\nWe are a value brand, but we will not compromise on our customer service. We pride ourselves in giving the best customer service to all our members with fully staffed facilities, and a full class timetable, which is unique in our sector of the market. You will always get a smile from our team. We work very closely with all our members, and ensure we help them in any way possible. We offer everything from non-contract memberships, to the ability of being able to freeze your account when desirable, to ensure we can fit in with your lifestyle.\n\nWith various sites offering a full boxing ring, combat classes and TRX facilities, and with over 400 pieces of equipment at all of our clubs, we offer the best all round member experience in the industry compared to other budget operators.\n\nOur clubs are designed especially with customers in mind, fully air conditioned and have cha​nging room and shower facilities with secure lockers for you to use at your leisure.",
          "offers": "More 4 Less:\n\nJoin now from £9.99 per month.\nOver 400 pieces of the latest gym equipment, 200 FREE classes every month, separate ladies-only areas, expert advice always on hand and open every day. Join now.\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "locations": [
            {
              "geolocation": [
                -1.5797207000000526,
                53.8035059
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "59e7451f94e4d86bf9be1986"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Xercise4less - The North West",
          "phone": {
            "code": "+44",
            "number": "1132038668"
          }
        },
        "score": 68,
        "parent": {
          "$oid": "58d13cc83fa3e80afcab8f3b"
        },
        "inherit_from_parent": true
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492686889392,
      "username": "xercise4less_-_the_north_west",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58f8c2ba4a1ced4daf7e9f7c"
      },
      "lastUpdated": {
        "$date": "2019-12-11T22:36:52.846Z"
      },
      "createdAt": 1492697786492,
      "email": "mark@sandersfitness.co.uk",
      "firstName": "Mark",
      "surname": "Billau",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "ff2c0185b15568d4b27886b0a506f5baf5840e5d",
      "salt": "ioyexbJXksxzuDpusldTrg==",
      "hashedPassword": "TnKM/imiw4YiX2GeV+JQcDflaGvI39qH6yKQW7APT7/YVQgzIdMLFd1iZviOhzsKg8VW+vPrXJ+4UuIg/Qa0zA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YvrkUhL7-fitnesscentre3.jpg"
        },
        "personal": {
          "disciplines": [
            "Corporate Services",
            "Fitness Classes",
            "Personal Training",
            "Small Group Training",
            "Sports Therapy & Rehab"
          ],
          "qualifications": [
            "Outdoor area",
            "Resistance",
            "Gym",
            "Cardio",
            "Apparel for Sale",
            "Free Parking"
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "http://www.sandersfitness.co.uk",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4kY6JW7b-fitnesscentre1.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2012,
          "affiliation": "Fitpro, Reps",
          "strapLine1": "#AchieveMore",
          "biography": "The Fitness Centre - Sanders Fitness centre – our goal is to make our fitness centre feel part of our members lives. We know for many people gym's can be intimidating, so we work hard to make our gym completely comfortable for everyone who wants to improve their fitness. The Sanders Fitness Centre is a fully equipped gym located in the beautiful surroundings of Gaudet Luce Golf and Leisure Complex in Droitwich Spa. Featuring Life Fitness and Technogym cardiovascular equipment, Concept 2 rowers, Technogym easy-line toning stations and Kinesis One, the Sanders Fitness Centre offers a bespoke service in a friendly, relaxed and discrete venue. As experts in functional training we use free weights, medicine balls, kettlebells, battling ropes, TRX suspension training and many other techniques to ensure our user experience is like no other.",
          "locations": [
            {
              "geolocation": [
                -2.129855300000031,
                52.25111870000001
              ],
              "city": "Hadzor",
              "postcode": "WR9 7JR",
              "_id": {
                "$oid": "59e7445194e4d86bf9be1985"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -2.129855300000031,
                52.25111870000001
              ],
              "city": "Hadzor",
              "postcode": "WR9 7JR",
              "note": "Sanders Fitness Centre,  Gaudet Luce Golf Club, Middle Lane,  Droitwich,  Worcestershire",
              "_id": {
                "$oid": "59e7445194e4d86bf9be1984"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "companyName": "Sanders Fitness",
          "phone": {
            "number": "1905779093",
            "code": "+44"
          },
          "offers": "GYM MEMBERSHIP: £ 28/pm  (Standing order)\n\nGYM MEMBERSHIP (CONCESSION): £ 25 /pm (Standing Order) - Includes Ladies and Fit For Golf Classes\n\nGYM & UNLIMITED CLASSES MEMBERSHIP: £ 38 /pm (Standing Order)\n\nPAYG\n\nGYM SESSION: £8\nCLASS (NON-MEMBER): £ 8\nCLASS (MEMBER): £5\nEXPRESS CLASS (NON MEMBER): £5\nEXPRESS CLASS (MEMBER): £3",
          "testimonials": "The team at Sanders Fitness have really helped me with my long term fitness after a partial knee replacement.\n\nBev Hinkley \nMember\n\nTwo years ago I was un-coordinated, unbalanced and low in confidence. They are now things of the past thanks to the ladies class.\n\nMaggie Smith \nSenior Lady\n\nBoot Camps are great outdoors with the tyres and battle ropes. There’s always a new exercise I haven’t tried. Thanks guys!\n\nDebbie Brogan \nMember\n\nI really like how easy and simple to use the easy-line equipment is.\n\nMrs K \nPAYG Customer\n\nShaun’s Challenges each month really motivate me to keep on improving and achieving my goals.\n\nMason Hall \nMember",
          "openingTimes": "Monday - Thursday: 7:00 am - 9:00 pm \nFriday: 7:00 am - 8:00 pm \nSaturday - Sunday: 9:00 am - 2:00 pm"
        },
        "score": 75
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492697786699,
      "username": "sanders_fitness",
      "feature_in_area": "none",
      "__v": 7,
      "password_reset_code": "b943d1526a215ef6d56c5db58b0b36111fd1bce3"
    },
    {
      "_id": {
        "$oid": "58f9fa8513ea180f1cca5f1f"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:42:21.096Z"
      },
      "createdAt": 1492777605617,
      "email": "carol.green73@outlook.com",
      "firstName": "Carol",
      "surname": "Green",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "e1cf76ee675eb3854132bf57293c2f93c03d89f4",
      "salt": "DLD6/GV/EPt97hNBNqG5Sg==",
      "hashedPassword": "jnLHCN4GDhSLBdJC61LOokKNLAbziDiB9BTy2IneWNeJgzX4mUiI8QwbTaJQ6EaT67qgNWbNOojIUWLSg5/ysQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/A1YCsGg0-Profile%20pic.jpg"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Nutrition",
            "Pre/post Natal",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Ante & Post Natal",
            "Level 3: Exercise Referral Instructor",
            "Level 3: Nutrition",
            "Level 2: Gym Instructor"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 30,
            "max": 40
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xYIFHMVy-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "Green73Carol",
            "link": "https://twitter.com/Green73Carol",
            "user_id": "2455182985",
            "accessToken": "2455182985-DwfvNirb12ctdYffrBw9XlunLWxYZ48HhOl6SOT",
            "accessTokenSecret": "dE7bapaTSeravFOhkF8NoEw6uVZReWKgpVSvWTIqJc8kL"
          },
          "yearsOfExp": 1995,
          "affiliation": "REPs, CIMSPA, FitPro",
          "biography": "I have always had an interest in sport both as a spectator, I am an avid Rugby League follower; and as a participant, I played badminton regularly and was a keen swimmer and qualified as a swimming instructor in 1974 teaching swimming and life saving for 10 years.\n\nI moved to Leeds in 1981 and started attending fitness classes which led to me becoming a fitness instructor, teaching various group classes and a year later took an advanced course to enable me to work with specialist groups. I worked for Leeds City Council Leisure Services from 1994 to 2011 when I returned to Doncaster and undertook further training to update my qualifications.\n\nI was overweight in my 20s so I can give you the benefit of my own experience and help you to achieve your personal goals by giving you a programme that is specific to you and help you to make physical activity and healthy-eating an enjoyable part of your life.\n\nPlease contact me for a free initial consultation where we will discuss your goals, any medical conditions or injuries which I may need to be aware of, and the times which are convenient for you to train.  There is no obligation to commit to any sessions after the consultation.",
          "facebookPage": "carol.green.92775",
          "offers": "Free initial consultation\n\nSave money by block booking",
          "locations": [
            {
              "geolocation": [
                -1.1284620000000132,
                53.52282
              ],
              "city": "Doncaster",
              "postcode": "DN1",
              "_id": {
                "$oid": "5a5ffb7d765eba094646f72d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7880544455",
            "code": "+44"
          }
        },
        "score": 69,
        "parent": null
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1492961047458,
      "username": "carol_green",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "58ffc24bf39542331af07a1b"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:41:57.909Z"
      },
      "createdAt": 1493156427970,
      "providerId": "656176684571352",
      "provider": "facebook",
      "surname": "Bowman",
      "firstName": "Zak",
      "email": "gstrengthc2013@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/656176684571352/",
          "disciplines": [
            "Boxing",
            "Groups / Teams",
            "Personal Training",
            "Sports Specific",
            "Strength"
          ],
          "qualifications": [
            "Level 4: Master Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Level 4: Online",
            "Level 3: Nutrition"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 60
          },
          "companyWebsite": null,
          "profileTitle": "Strength & Conditioning Coach",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/bhXx7arx-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2011,
          "affiliation": "REPS",
          "biography": "Spent years training individuals with all type of fitness goals. \n\nWorked with Watford FC as a professional Strength & Conditioning Coach.",
          "offers": "1-2-1 Training = £30ph\n\n2-2-1 Training = £50ph \n\n12 week training programme with weekly Skype catch ups = £80",
          "locations": [
            {
              "geolocation": [
                -0.6950494000000162,
                51.9136524
              ],
              "city": "Leighton Buzzard",
              "postcode": "LU7",
              "_id": {
                "$oid": "5a5ffb65765eba094646f72c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7801990848",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YDIda4eP-IMG_0056.PNG"
        },
        "score": 66,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "zak_bowman",
      "lastLogin": 1493156428158,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "590029f0f39542331af0901d"
      },
      "lastUpdated": 1495559490569,
      "createdAt": 1493182960967,
      "email": "info@leahpaulleyfitness.com",
      "firstName": "Leah",
      "surname": "Paulley",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "53baa326022fb491ca6236514289e6de35985578",
      "salt": "xeBjCYOwq4OYCIsUB+eFLQ==",
      "hashedPassword": "ToDYVM7u2p5L4KPSDQKOzwvMXjZ2WWt7LRLga3X+d9GRMO3dw/HrV2aYfWi3GEWC45IOI/qCFysYEhNcVt94AQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nsxUT5q3-leahpauleypro.jpg"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Nutrition",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 15,
            "max": 30
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "locations": [
            {
              "geolocation": [
                -1.931948000000034,
                52.548342
              ],
              "country": "United Kingdom",
              "city": "Birmingham",
              "postcode": "B43 6JN",
              "primary": true
            }
          ],
          "biography": "Welcome to Leah Paulley Fitness!\n\nAre you wanting some help – whether it be to lose weight, look better in your clothes or have a fitness goal or competition – I want to help you!\n\nI have been working in the fitness industry for 7 years and love helping people to make a change. Food choices and exercise is not all we will look at – a lot of it is helping with your mind set and approach.\n\nI offer personal training in Great Barr at Red House Park OR online coaching if you live further away.\n\nMy Social media channels are regularly updated with exercise videos, nutrition advice and tips and you can also subscribe to my newsletter at the bottom of the page!\n\nIf you have any questions just simply contact me and I will be happy to answer anything!",
          "offers": "30 Minute Sessions\n£15 or £50 for block of 4 sessions\nOnline Recipe Book when you buy a block of 4 sessions\n\n45 Minute Sessions\n£20 or £75 for block of 4 sessions\nOnline Recipe Book when you buy a block of 4 sessions\n\n1 hour Sessions\n£30 or £110 for block of 4 sessions\nOnline Recipe Book when you buy a block of 4 sessions",
          "companyName": null,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4HC4aODD-leahpauleybg.jpg",
            "size": {},
            "offset": 0
          },
          "strapLine1": "Leah Pauley Fitness -  I want to help you!",
          "facebook": "",
          "instagram": {
            "link": "https://www.instagram.com/Leah_Paulley_Fitness/"
          },
          "facebookPage": "LeahPaulleyFitness/",
          "phone": {
            "code": "+44",
            "number": "7858625655"
          }
        },
        "score": 73
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1493182961244,
      "username": "leah_paulley"
    },
    {
      "_id": {
        "$oid": "5901b44bcc2d2b5d6ef17846"
      },
      "lastUpdated": 1493283915294,
      "createdAt": 1493283915294,
      "email": "debandrun@aol.com",
      "firstName": "Debbie",
      "surname": "Gunning",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "e5d5a902f4d808b23d7d1e4b2a0711b1ba8e865f",
      "salt": "g1gYCBE98nz/X92RAbesdQ==",
      "hashedPassword": "uCXIifzaPNaugGHT0OfuTO/B20nuS0DgOVsKYSoD8xSOIY2FQZWqPv4pu6VW0+7up13OGl9HkyIrgU9hvc9uHA==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "country": "United Kingdom",
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyWebsite": null,
          "profileTitle": null
        }
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1493283915545
    },
    {
      "_id": {
        "$oid": "590797eecc2d2b5d6ef2b9cb"
      },
      "lastUpdated": 1493721003094,
      "createdAt": 1493669870902,
      "surname": "Yeomans",
      "firstName": "Danny",
      "email": "yeomans14@hotmail.com",
      "providerId": "2978343936",
      "provider": "instagram",
      "username": "danny_yeomans",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "2978343936.8640130.6f616aabf5544bdba619cf684f0147a4",
            "link": "https://www.instagram.com/danyeomansfitness",
            "username": "danyeomansfitness"
          },
          "disciplines": [
            "Functional Training",
            "Nutrition",
            "Online Coaching",
            "Rehabilitation",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 50,
            "max": 70
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZoZ5bxFY-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "biography": "Danny is a personal trainer with over 20 years experience working and helping clients from all different walk of life from the retired who may have a back condition to professional golfers who what to improve their conditioning to support their game. As a personal trainer Danny specialises in Corrective exercise, functional movement and golf fitness/conditioning he prides himself on keeping up to date on the current fitness trends and also always working on improving his knowledge to improve as a personal trainer. Danny is currently qualified through the CHEK Institute (Corrective Holistic Exercise Kenesiology) as a level 1 practitioner, FMS (Functional Movement System) certified level 1 & 2, TPI (Titliest Performance Institute) Level 1. Danny is also registered on the REPS register (Register of exercise professionals) as a level 3 personal trainer. Danny runs his business from an exclusive personal training studio in Beckenham south east London called Define Fitness. It is a fully equipped fitness studio with all up to date fitness equipment including cardio equipment such as LifeFitness treadmills, Concept 2 rowing machines, static bikes and cross trainers. Other equipment includes dual adjustable cable machine, squat rack, a good range of dumbells and kettle bells along with TRX straps and cross fit bands.",
          "locations": [
            {
              "primary": true,
              "geolocation": [
                0.008554671981351849,
                51.40671135982693
              ],
              "country": "United Kingdom",
              "city": "Bromley",
              "postcode": "BR2 0DA"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7919412373"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UXQEQEBm-filename.jpeg"
        },
        "score": 67
      },
      "role": "trainer",
      "activation_code": "07a182cba5b69ffb9781c983588f1a30c7ecf874",
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1493669871122
    },
    {
      "_id": {
        "$oid": "59079a85cc2d2b5d6ef2ba4d"
      },
      "lastUpdated": 1493670616956,
      "createdAt": 1493670533551,
      "email": "darko.basic9@gmail.com",
      "firstName": "Darko",
      "surname": "Basic",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "b780aa90c6bc55636d0300190e811453ea3dd3b2",
      "salt": "KU8ZXTHMsFLgbEq6z5zQwQ==",
      "hashedPassword": "bwN2D5jNfaZRtLs5VTMUQ0/FRMWCdzipzortblEJmbS4dBcztU5+s5HmXbJmbEpMqgdS0hhAuNrFhOMBwvWr/w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 20,
            "max": 100
          },
          "companyWebsite": null,
          "profileTitle": null,
          "radius": 80,
          "locations": [
            {
              "primary": true,
              "geolocation": [
                20.41729038914814,
                44.771734618232784
              ],
              "country": "Other",
              "city": "Beograd"
            }
          ],
          "phone": {
            "code": "+381",
            "number": "640064001"
          }
        },
        "score": 27
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1493670533880,
      "username": "darko_basic"
    },
    {
      "_id": {
        "$oid": "5908340ccc2d2b5d6ef2db0e"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:40:48.197Z"
      },
      "createdAt": 1493709836142,
      "surname": "williams",
      "firstName": "daniel",
      "email": "deediart@gmail.com",
      "providerId": "199145946",
      "provider": "instagram",
      "username": "daniel_williams",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "199145946.8640130.e340bbf686d54844a52e0b9e9ba8e0e0",
            "link": "https://www.instagram.com/getoutdaniel"
          },
          "disciplines": [
            "Bootcamp",
            "Group Classes",
            "Online Coaching",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR",
            "British Army PTI"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 25,
            "max": 45
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/u4S34x2R-L%20sprint%20.png",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "GetOutDaniel",
            "link": "https://twitter.com/GetOutDaniel",
            "user_id": "348961826",
            "accessToken": "348961826-59mHf4tolaiwtk9GjAheatAZbVB9A9NxLrpyyZ84",
            "accessTokenSecret": "ZCoq2UqBmmhJubfOERwBXjoeR8FMb6BB40URGeV2EZqsr"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/291714021259718/",
          "google": "https://plus.google.com/101319049049527271102",
          "yearsOfExp": 10,
          "strapLine1": "Everywhere Is Your Gym",
          "biography": "A very experienced multi talented expert in the leisure and fitness industry. From competing in multiple sports, at local to national levels, to becoming a Physical Training Instructor with the British Army, followed by working for large franchise leisure centres to small independent gyms. \nAfter taking a break from the leisure industry to work in the recruitment sector, which has given me skills and qualifications in HR and Payroll (Sage50), I have returned to the fitness world. \n\nI am passionate about fitness and want to help the any one and everyone reach their goals.",
          "locations": [
            {
              "geolocation": [
                -1.8904009999999971,
                52.48624299999999
              ],
              "city": "Birmingham",
              "postcode": "B4 7DA",
              "_id": {
                "$oid": "5a5ffb20765eba094646f72b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7882267502",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ePhcLMDM-3%20peaks.jpg"
        },
        "score": 77
      },
      "role": "trainer",
      "activation_code": "54583af529126d51bae9c92b7a047a88f89054c3",
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1493709836339,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "59083565cc2d2b5d6ef2db4f"
      },
      "lastUpdated": 1494432408125,
      "createdAt": 1493710181124,
      "providerId": "10154399249876881",
      "provider": "facebook",
      "surname": "Saeed",
      "firstName": "Adnan",
      "email": "adnansaeed49@hotmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154399249876881/",
          "disciplines": [
            "Circuit Training",
            "Endurance",
            "Group Classes",
            "Rehabilitation",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "IFPA"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 55,
            "max": 60
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1wOGFfr7-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "3940189589.8640130.e1c785d534d640ae84c59134efee55d7",
            "link": "https://www.instagram.com/adnansaeed81"
          },
          "yearsOfExp": 2015,
          "affiliation": "REPs",
          "biography": "Result Guarantee",
          "locations": [
            {
              "geolocation": [
                55.35087820000001,
                25.2766836
              ],
              "country": "Other",
              "city": "Dubai",
              "primary": true
            }
          ],
          "companyName": null,
          "phone": {
            "code": "+971",
            "number": "501329625"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/i0VVficY-filename.jpeg"
        },
        "score": 69,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "adnan_saeed",
      "lastLogin": 1493798200576
    },
    {
      "_id": {
        "$oid": "59088f0dac9ff118965107a9"
      },
      "lastUpdated": 1493733133515,
      "createdAt": 1493733133515,
      "email": "humdwils@hotmail.com",
      "firstName": "David",
      "surname": "Wilson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "c9678862c3bb9636d9329ac7e74aa90c1a7c5e72",
      "salt": "DWN7Px7mvjdXBG6Z/QUGnQ==",
      "hashedPassword": "TDc8drm3qEfgCo6wNq/RTFxnf3yHB7iwkPkXjwYRvvGd5qm2//7c9LDxJHMEijoX+4O1k6KifGMttXL8xlKnvw==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "country": "United Kingdom",
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyWebsite": null,
          "profileTitle": null
        }
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1493733133833
    },
    {
      "_id": {
        "$oid": "5909dac6ac9ff11896514e81"
      },
      "lastUpdated": 1494432388669,
      "createdAt": 1493818054231,
      "providerId": "10155276526394710",
      "provider": "facebook",
      "surname": "Cole",
      "firstName": "David",
      "email": "daverave2@hotmail.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155276526394710/",
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 25,
            "max": 40
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xByt6iQb-IMG_3022.PNG",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "227696836.8640130.1b61994a06ad402ab8f51d6ce0a720a7",
            "link": "https://www.instagram.com/physivation"
          },
          "facebookPage": "Physicolefitness",
          "strapLine1": "Your home to strength training welcomes you.",
          "biography": "Physicole Fitness was created by David Cole a former high jumper who competed for England many moons ago now turned powerlifter. \n\nHis aim is to help as many people as he can to become stronger versions of themselves. From his own past experiences with glandular fever he lost a lot of muscle and fat. As a result he lost a lot of weight to the point he could not even do a warm up. \nIt has taken many years to gain back the weight and confidence and he wants to help you to reach your goals. \n \nNo matter if your goals are to gain weight, lose inches, improve strength, overall fitness or any challenge etc - he will do what he can to help you get there.\n\nHe has been helping many runners - especially 1/2 to Marathon runners to build strength. Now its your turn to change your life around and be the best you can be.\nABOUT DAVID\nPictures of David before and after\nWith over 13+ Years experience coaching.\nCompeted at International Level.\nStrength Coach to high level athletes\nSub 93kg Powerlifter : \nSquat - 165kg\nBench - 125kg\nDeadlift - 205kg\n(Powerlifting numbers will increase)",
          "locations": [
            {
              "geolocation": [
                -0.5137253689349764,
                51.43333447564304
              ],
              "country": "United Kingdom",
              "city": "Staines-upon-Thames",
              "postcode": "TW18 4QY",
              "primary": true
            }
          ],
          "companyName": null,
          "phone": {
            "code": "+44",
            "number": "7702983945"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pWoBoYBd-filename.jpeg"
        },
        "score": 71,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "david_cole",
      "lastLogin": 1493819132435
    },
    {
      "_id": {
        "$oid": "590ae5b4ac9ff11896518786"
      },
      "lastUpdated": 1493886593000,
      "createdAt": 1493886388710,
      "email": "achieverspt@gmail.com",
      "firstName": "Del",
      "surname": "Wilson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "03fbd72161bb51513934ac0548b503b0a7bfdefc",
      "salt": "otgCanBMutyg98jhRMSitA==",
      "hashedPassword": "ljjqlCkjjpdB2Jzkg5ACf4PoZuJDO5zzdl3p95J/R16ph+JbldsyVHSEoEy5Leq8estyCOthVsHaDVfmKbScmw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 32,
            "max": 32
          },
          "companyWebsite": null,
          "profileTitle": null,
          "radius": 15,
          "locations": [
            {
              "primary": true,
              "geolocation": [
                -0.5893968034851245,
                52.276992701352825
              ],
              "country": "United Kingdom",
              "city": "Rushden",
              "postcode": "NN10 0GJ"
            }
          ],
          "phone": {
            "code": "+44",
            "number": "7730569570"
          }
        },
        "score": 33
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1493886388910,
      "username": "del_wilson"
    },
    {
      "_id": {
        "$oid": "590f96308d25580386cfad2c"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:34:05.045Z"
      },
      "createdAt": 1494193712918,
      "providerId": "10211777977358131",
      "provider": "facebook",
      "surname": "Clare",
      "firstName": "Sharon",
      "email": "sharon@bodycoachfitness.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10211777977358131/",
          "disciplines": [
            "Functional Training",
            "Online Coaching",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Obesity & weight management",
            "Sports therapy",
            "Level 3: Nutrition",
            "Level 3: Online",
            "Level 3: Tutor / Assessor"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 35,
            "max": 50
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tZAE9hU7-555.gif",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2005,
          "affiliation": "REPS  CIMSPA  FIT PRO  UK ACTIVE",
          "offers": "Sports Performance Hub-Assessment S&C  \ntraining \nSports Therapy\nOnline Personal Trainer Academy -Courses\nGenetic sports, nutrition fitness and wellness test\nObesity &Weight Management\nGP Referal\nBiomechanics coaching\nGait Analysis \nBike Fitting-Specialist sports packages\nObesity & Weight Management\nOnline Shop\nOnline personal training\nOnline nutritional plans & advice\nOut Door Training-Boot camps -Cani cross Nordic walking \nBuggy Fit Classes",
          "biography": "Sharon Clare is a level 4 specialist personal trainer \nand nutrition coach at Body coach Fitness- www.bodycoachfitness.com.Body Coach fitness 's vision is to\n educate, inspire and motivate others to be more \nactive more often through healthy nutrition, physical \nactivity and sports .",
          "strapLine1": "Body Coach Fitness-Educates,inspires & motivates others to be active more often & lead  a healthy lifestyle",
          "locations": [
            {
              "geolocation": [
                0.7533789000000297,
                52.1220755
              ],
              "city": "Alpheton",
              "postcode": "CO10 9BN",
              "_id": {
                "$oid": "5a5ff98d765eba094646f729"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7875086760",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0RWsjKog-555.gif"
        },
        "score": 76,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "sharon_clare",
      "lastLogin": 1494193713210,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "590f9b1d8d25580386cfae2e"
      },
      "lastUpdated": 1494432371600,
      "createdAt": 1494194973889,
      "email": "fpagett@gmail.com",
      "firstName": "Fiona",
      "surname": "Pagett",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "IylwZMfxUxVoOvN3I0u8Xw==",
      "hashedPassword": "dQgLjROEb/d6k5/I3vj0v6eDdR2Mq87w5XVjR5bpSsB8LIkXTV5vJChmUUi585c+gMmzkWPkpJS18M1JLJZdYw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/CDYtYPD6-fb%20profile.jpg"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Online Coaching",
            "Strength & Conditioning",
            "Toning"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Alpine Ski Leader",
            "Cardio Tennis",
            "Bowka L1",
            "spin"
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 25,
            "max": 39
          },
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nw50QZYW-DSC_1281.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "FiPagett",
            "link": "https://twitter.com/FiPagett",
            "user_id": "2207965117",
            "accessToken": "2207965117-4hhQOOt5LwI5WhdTnS0OIF3GV321EPreQyGew14",
            "accessTokenSecret": "EVKSWkDZ5Wonb7hXxwG6oHT70p7u5BdheH9jnqF5m8kqz"
          },
          "instagram": {
            "access_token": "719191911.8640130.52432e02b467429583906d7489daf3f6",
            "link": "https://www.instagram.com/keepingfitsimple"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154549543354135/",
          "facebookPage": "FionaPagettPT",
          "yearsOfExp": 2011,
          "strapLine1": "Founder of www.keepingfitsimple.com an online fitness membership site and community for women",
          "offers": "I support women through my online fitness membership site http://members.keepingfitsimple.com\nDo you lack time?  Feel demotivated?  Perhaps you've lost confidence in your ability?  I'm a 48yr old Personal Trainer and I empathise.  I encourage women to become more active by embracing exercise one step at a time.  I run a supportive community of like minded women who all want to feel better, more capable, stronger and fitter.  Ready to live life with energy and purpose?  Join us.",
          "biography": "I qualified as a Personal Trainer aged 42 after 20+ years in media and communications.  I'm a busy mum of two teenagers, I understand the challenges of fitting exercise into a busy day!  I also realise it's necessary to be active because yes, it's a use it or lose it scenario!  Sometimes I'm picking up the pieces, often I'm lending an ear, always, I'm gentle and encouraging.",
          "locations": [
            {
              "geolocation": [
                -3.188266999999996,
                55.953252
              ],
              "country": "United Kingdom",
              "city": "Edinburgh",
              "postcode": "EH1 3EG",
              "primary": true
            }
          ],
          "companyName": null,
          "phone": {
            "code": "+44",
            "number": "7738018245"
          }
        },
        "score": 81,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1494236756785,
      "username": "fiona_pagett"
    },
    {
      "_id": {
        "$oid": "5910361e8d25580386cfcf70"
      },
      "lastUpdated": 1494234654366,
      "createdAt": 1494234654366,
      "email": "na-zir@live.com",
      "firstName": "Nazir",
      "surname": "Bayan",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "47010fd04eb34642314679b8f6deb1163cc62424",
      "salt": "vJ915WAV6y083RWy15quWA==",
      "hashedPassword": "c6K/xES69VztP6VFVe0rtaa3hRCT5aLK68QMNWqG88+gt0ytQBFsto5hHqeLhFyppw9KKmfLgTsmJgtw9CPMiw==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "country": "United Kingdom",
          "disciplines": [],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyWebsite": null,
          "profileTitle": null
        }
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1494234654628
    },
    {
      "_id": {
        "$oid": "591036538d25580386cfcf72"
      },
      "lastUpdated": {
        "$date": "2017-11-21T18:57:43.663Z"
      },
      "createdAt": 1494234707521,
      "email": "claire@purefitnessuk.com",
      "firstName": "Claire",
      "surname": "Spurway",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "990205af8217065227e35be024eb7b918283bae5",
      "salt": "Hua9nkGLDlK/PUvl2DLQWQ==",
      "hashedPassword": "bKYmb3UOpVrK6UNgQcrAlMIo6UM4rMhmkLQHzdyI28wx+LF/ISzlg9ONCYp9oXRRIkgp29nW2RCZHtmxDXesjQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Wow8nqpT-image.jpeg"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Online Coaching",
            "Personal Training",
            "Sports Therapy & Rehab",
            "Sports Specific"
          ],
          "qualifications": [],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyWebsite": "Http://Purefitnessuk.com",
          "profileTitle": "Mobile Personal Training",
          "radius": 1000,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JtxgvQbX-purefitnessukbg.png",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "2358594269.8640130.40211a1783e54cf18643aa5fc7ae5c06",
            "link": "https://www.instagram.com/claire_spurway"
          },
          "facebookPage": "Pure.Fitness.Limited",
          "yearsOfExp": 2006,
          "biography": "Claire has been an athlete for over twenty years and has an overwhelming passion for sport, health and fitness.\n\nHer main sport is sprinting, 100m and 200m, and is training to the highest level alongside Olympic gold medallists, World Class coaches and therapists, at UK Athletics High Performance Centres and Worldwide. Competing in European races, Olympic, European and World Trials. This has given Claire first-hand experience of all aspects of training and nutrition and how to get results fast.\n\nEducated as a Sport Scientist at Loughborough University and with thousands of hours spent training a diverse range of clients over the years has given Claire enhanced knowledge of what truly gets results and a highly developed intuition of what a person’s body needs.\n\nClaire’s training philosophy is based on science and athletic performance but also other elements from kinesiology, eastern philosophies, meditation, psychology, raw living, spiritualism, pure and natural nutrition, plus creativity to bring a truly unique experience. These essential elements are drawn together but presented in a simple way to help reach any goal.\n\nPure Fitness was founded in 2006 with an ambition to empower as many people as possible to reach their own maximum health and wellbeing in the most efficient and effective way while making fitness simple. To do this Claire created Pure Lifestyle Packages and Pure Clubs which consistently produce fantastic results with clients at all levels.",
          "offers": "Please visit our website for details on prices, packages &  offers.\n\nOperating Hours: \n\nPure trainers are available from 6am to 10pm every day of the week enabling you to exercise at a convenient time, fitting into your life, however busy.",
          "strapLine1": "Fitness And Wellbeing: Pure And Natural Health Solutions",
          "locations": [
            {
              "geolocation": [
                -0.102478900000051,
                51.6341997
              ],
              "city": "London",
              "postcode": "N14 7JZ",
              "_id": {
                "$oid": "5a1477272288dd3a30f3f4d1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "companyName": "Pure Fitness UK",
          "phone": {
            "number": "7939987906",
            "code": "+44"
          }
        },
        "score": 64,
        "parent": null
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1494234707805,
      "username": "pure_fitness_uk",
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5911b0f48d25580386d02033"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:31:46.090Z"
      },
      "createdAt": 1494331636744,
      "providerId": "658804017661389",
      "provider": "facebook",
      "surname": "Exercise",
      "firstName": "Jensen",
      "email": "jensenexercise@aol.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/658804017661389/",
          "disciplines": [
            "Core Stability",
            "Injury Prevention",
            "Massage",
            "Mobile Therapist",
            "Personal Training"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Master Personal Trainer",
            "Level 3: Advanced Group Exercise to Music Instructor",
            "Level 2: First Aid / CPR",
            "Level 3: Ante & Post Natal",
            "Sports therapist"
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 25,
            "max": 45
          },
          "companyWebsite": null,
          "profileTitle": "Sports Therapist",
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fMWEqMy8-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "google": "https://plus.google.com/112291518463096986260",
          "yearsOfExp": 2001,
          "affiliation": "REPS",
          "strapLine1": "Mobile PT and sports massage in Surrey, Hampshire and Berkshire",
          "biography": "Covering Surrey, Hampshire and berkshire. With over 16 years of experience. Fully mobile: in the comfort of your own home, work or outside. Please visit www.jensenexercise.com for details",
          "offers": "Look at www.jensenexercise.com for details",
          "locations": [
            {
              "geolocation": [
                -0.6978940000000193,
                51.360343
              ],
              "city": "Bagshot",
              "postcode": "GU19 5EQ",
              "_id": {
                "$oid": "5a5ff902765eba094646f727"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "phone": {
            "number": "7971112221",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ioF1VuSI-filename.jpeg"
        },
        "score": 76
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "jensen_exercise",
      "lastLogin": 1494331636951,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "59162e1fe6945b17726f38aa"
      },
      "lastUpdated": {
        "$date": "2018-01-19T12:52:36.791Z"
      },
      "createdAt": 1494625823739,
      "email": "adds88@hotmail.com",
      "firstName": "Adam",
      "surname": "Waterson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "68d6820ba80026eef788ba9ca5e877ffef913762",
      "salt": "LKNT9Oa6PZMcXJn90IDmrw==",
      "hashedPassword": "C/TCU/M9q5X/sueyWoCT03BLAYN+UNjkwkMaItiaJ0U5OfVML94h9+YabzbYMnu2SJRPHjfLbJaxWhNM5tWUDg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "AUD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cQWOWFSb-IMG_9611.JPG"
        },
        "personal": {
          "disciplines": [
            "Groups / Teams",
            "Injury Rehab",
            "Personal Training",
            "Sports Specific",
            "Strength"
          ],
          "qualifications": [
            "ASCA Elite Coach",
            "Professional Coaches Scheme",
            "Level 2 Strength and Conditioning Coach"
          ],
          "locations": [
            {
              "geolocation": [
                151.21111099999996,
                -33.886111
              ],
              "city": "Surry Hills",
              "postcode": "2010",
              "_id": {
                "$oid": "5a61ea14765eba094646f8d8"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 50,
            "max": 150
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Strength & Conditioning Coach",
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1lkab8N0-IMG_9384.JPG",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "2027048546.8640130.70d52a4584f846878de4afbe6a609420",
            "link": "https://www.instagram.com/addsyw"
          },
          "biography": "Specialties: Staff Development & Training, Program Development, Exercise Prescription, Strength &\nConditioning, Football Conditioning",
          "phone": {
            "number": "402945110",
            "code": "+61"
          }
        },
        "score": 56
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1494626075939,
      "username": "adam_waterson",
      "feature_in_area": "none",
      "__v": 5
    },
    {
      "_id": {
        "$oid": "59171612e6945b17726f6a2c"
      },
      "lastUpdated": {
        "$date": "2018-01-19T12:34:19.461Z"
      },
      "createdAt": 1494685202355,
      "email": "kapsisalex@gmail.com",
      "firstName": "Alex",
      "surname": "Kapsis",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "9VNxnYGn7oe9PJwjEcjV+g==",
      "hashedPassword": "HyUULvsUxryjH93bzrMtYeFbQmpQgKY0w1OLroSSPS6oJSK3BzMZDZvYCiA9mgAaxzm/DDdyQakVupUWU/czZQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/udL0QfyJ-IMG_0247.JPG"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 4: Master Personal Trainer",
            "Level 4: Strength & Conditioning",
            "BSC Sport & Exercise",
            "Msc Strength &Conditioning: Leeds Beckett"
          ],
          "locations": [
            {
              "geolocation": [
                23.727538800000048,
                37.9838096
              ],
              "city": "Αθήνα",
              "postcode": "105 52",
              "_id": {
                "$oid": "5a61e5cb765eba094646f8cb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 80,
            "max": 100
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cZHLbNzy-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2016,
          "biography": "Experienced Football Coach with a demonstrated history of working in the sports industry. Skilled in Customer Service, Coaching, Fitness Training, Weight Loss Coaching, and Functional Training. Strong community and social services professional with a Bachelor's degree focused in Sports and Exercise from Ethnikon kai Kapodistriakon Panepistimion Athinon and a Master's degree focused in Strength and Conditioning from Leeds Beckett University.",
          "offers": ".",
          "phone": {
            "number": "6986584571",
            "code": "+30"
          }
        },
        "score": 60,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495018016701,
      "username": "alex_kapsis",
      "feature_in_area": "none",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "591978cee6945b17726fec6f"
      },
      "lastUpdated": {
        "$date": "2017-10-18T11:04:45.397Z"
      },
      "createdAt": 1494841550888,
      "email": "jo@corefitnesseducation.co.uk",
      "firstName": "Core Fitness",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "gWykaEYjKimwd3FgagjneQ==",
      "hashedPassword": "AeWY87JIsIBfFIaZQP+WRiajKRxL1dpck7Jcq4obLLn6atC5GkQ9fljSeQ9Hyg3+Q04HURs2cycLUSlr54mB4Q==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Fok5pDxW-core%20fitness%20edu.jpg"
        },
        "personal": {
          "disciplines": [
            "0% Finance",
            "Level 2 Courses",
            "Level 3 Courses",
            "Level 4 Courses",
            "Online Courses"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -1.0849944000000278,
                53.95673379999999
              ],
              "city": "York",
              "postcode": "YO1",
              "_id": {
                "$oid": "59e7354d94e4d86bf9be1979"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.021659699999986515,
                51.50518020000001
              ],
              "city": "London",
              "postcode": "E14 5AB",
              "note": "Body Reform  Level 2, Cabot Place West Canary Wharf London",
              "_id": {
                "$oid": "59e7354d94e4d86bf9be1978"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -1.0449330000000145,
                53.9518651
              ],
              "city": "York",
              "postcode": "YO10 3LG",
              "note": "David Lloyd  St John's Playing Field Hull Road, York North Yorkshire",
              "_id": {
                "$oid": "59e7354d94e4d86bf9be1977"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                0.017907799999989038,
                51.4033103
              ],
              "city": "Bromley",
              "postcode": "BR1 1DN",
              "note": "The Pavilion, Kentish Way, Bromley. Kent",
              "_id": {
                "$oid": "59e7354d94e4d86bf9be1976"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.35299370000007,
                53.79248519999999
              ],
              "city": "Hull",
              "postcode": "HU7 3DB",
              "note": "David Lloyd,  Raich Carter Way, Hull East Yorkshire",
              "_id": {
                "$oid": "59e7354d94e4d86bf9be1975"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "CORE Fitness Education",
          "companyWebsite": "https://www.corefitnesseducation.co.uk/",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qG3rmaQq-core%20fitness%20edu%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "facebookPage": "corefitnesseducation",
          "yearsOfExp": 2010,
          "affiliation": "Active IQ, CYQ, REPs",
          "strapLine1": "Become a Personal Trainer - Locations throughout the UK",
          "biography": "Quality, high standards, expertise and family feel are the words our students use to describe us. Founded in 2010 by fitness industry expert and medical doctor: Dr Dane Vishnubala. CORE Fitness Education is a group of doctors, physios, sports scientists, university lecturers and experienced fitness professionals with a passion for imparting their experience and knowledge.\n\nWe pride ourselves on delivering evidence (research) based high quality courses by our expert team of tutors and supporting our students on their journey, whether it be finding employment or setting up their own business.\n\nWe set our bar extremely high and in our biased opinion, our students are the best fitness professionals in the country!\n\nDelivering world-class research based education by the right people, raising the standards of the fitness industry and making sure our students are employable is our key goal. To do this, Dane has handpicked this extremely experienced team that you see below. Our team members are trained by us and only teach for us! What makes our team unique is not just their experience and qualifications but rather that the majority of the team have known each other for many years, some even decades, making us less a team and more a family!",
          "offers": "Hypertension and Exercise: £37\nEmergency First Aid at Work Certificate: £90\nSuspension Instructor Certificate: £120\nKettlebell Instructor Certificate: £120\nStudio Cycling Instructor: £197\nStep Instructor: £197\nGait Analysis: £197\nAqua Instructor: £347\nPre and Post Natal Exercise Instructor: £347\nDiploma in Exercise Referral: £497\nSports Massage Therapy Level 4: £497\nCertificate in Fitness Instructing: £497\nDiabetes and Obesity: £597\nLower Back Pain: £597\nExercise to Music Qualification: £597\nDiploma in Sports Massage Level 3: £997\nDiploma in Personal Training: £997",
          "places": "",
          "testimonials": "I did my Level 3 qualification with CORE Fitness after passing my Level 2 with a government funded corporation. I'd heard such good things about CORE that, although I could have got my Level 3 with government funding, I chose to undergo the qualification with CORE instead.\n\nI passed the course first time with the expert help of the tutors who, 3 years after I have qualified, still offer support and help around the clock.\n\nThe best thing about CORE is the community of qualified / qualifying PTs who are constantly helping each other, offering support, guidance and giving out useful tips to aid each other in bettering themselves as Personal Trainers.\n\nI've recommended the course already to many friends & clients and will continue to do so as the course just gets better and better. I am now delivering around 40 PT sessions a week and also coaching some boxing as well.\n\nAshley Martin - PT graduate 2012, ROKO Personal Trainer & Boxing Coach",
          "twitter": {
            "screen_name": "corefitnessed",
            "link": "https://twitter.com/corefitnessed",
            "user_id": "222169556",
            "accessToken": "222169556-D1hz2gRVJ5S2GG7MKG8fagOPvNDMD0leGi75KNkE",
            "accessTokenSecret": "XculF7a56L5nkWpUgWwpznFITlGzC76AFIoeKJmDqBELm"
          },
          "phone": {
            "code": "+44",
            "number": "8456191810"
          }
        },
        "score": 72,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1498475195841,
      "username": "core_fitness_education",
      "feature_in_area": "gyms",
      "password_reset_time": 1496760218948,
      "password_reset_code": "X",
      "agent": {
        "family": "Chrome",
        "major": "58",
        "minor": "0",
        "patch": "3029",
        "source": "Mozilla/5.0 (Windows NT 6.3; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36"
      },
      "__v": 4
    },
    {
      "_id": {
        "$oid": "591bf13d9edeff7e92d93665"
      },
      "lastUpdated": {
        "$date": "2018-01-19T12:26:31.407Z"
      },
      "createdAt": 1495003453086,
      "email": "morgansportstherapy@gmail.com",
      "firstName": "Harriet",
      "surname": "Thompson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "b1df0f593f141c12e6d81ed66fbab674094952d6",
      "salt": "lMFPj3OdExVQYMICpRth3g==",
      "hashedPassword": "mYBkxE3rminFmHRIK7oxYSLkZMvNXLjdihBFVbOU861V57IghEBwjBdSc6YU3yWInmF6HEKDPWHVbHnNXN5MdA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FNpEPxYx-harriet%20thompson.jpg"
        },
        "personal": {
          "disciplines": [
            "Injury Assessment",
            "Injury Prevention",
            "Injury Rehabilitaion",
            "Massage",
            "Nutrition"
          ],
          "qualifications": [
            "Sports & Remedial Massage Therapist",
            "Therapist with Northern Ballet"
          ],
          "locations": [
            {
              "geolocation": [
                -1.53781749999996,
                53.8293541
              ],
              "city": "Leeds",
              "postcode": "LS7 4NY",
              "_id": {
                "$oid": "5a61e3f7765eba094646f8c4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 40,
            "max": 50
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Sports Therapist",
          "radius": 20,
          "biography": "Morgan Sports Therapy is focused on the training and provision of Sports & Remedial Massage in Leeds, West Yorkshire. Based at Yoga Kula in Chapel Allerton, North Leeds specialising in massage for movement, yoga and dance.\n\nTo be injured and in pain can have debilitating consequences, both on your physical and mental wellbeing. The physical and psychological rewards from Sports & Remedial Massage can make the difference between winning and losing.\n\nWith experience of working with elite athletes at Northern Ballet, Castleford Tigers, Leeds Carnegie and Leeds & Bradford Triathlon Club, we appreciate that working collaboratively is fundamental to making soft tissue work effective. Pain perception is a very personal experience so adapting the depth of pressure with specific techniques needed for each individual can achieve great rewards.\n\nTraining is provided through 4 hour Deep Tissue Massage workshops at Yoga Kula in Chapel Allerton, Leeds. \n\nMorgan Sports Therapy - Rubbing you up the right way! Please don't rub me up the wrong way by contacting me with network marketing businesses.",
          "strapLine1": "MORGAN SPORTS THERAPY: Focused on the training and provision of Sports & Remedial Massage in Leeds, West Yorkshire",
          "affiliation": "Future Fit",
          "offers": "Sports Massage | 50 minute sessions £45\n\nNutrition for Sport and weight management\nConsultation £60 | 8 coaching sessions – £40 each (free initial consultation) *\n*NBT discount\n\nReflexology | 50 minute sessions £40\n\nPregnancy & Post Natal Massage | 60 minutes £50\nPost Natal includes the six week period after giving birth",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OQQhfWOT-morgansportstherapybg.jpg",
            "size": {},
            "offset": 0
          },
          "phone": {
            "number": "7801356361",
            "code": "+44"
          }
        },
        "score": 70
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495003453281,
      "username": "harriet_thompson",
      "feature_in_area": "none",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "591c1af19edeff7e92d93f6b"
      },
      "lastUpdated": 1495811869677,
      "createdAt": 1495014129095,
      "email": "chris@origym.co.uk",
      "firstName": "Chris",
      "surname": "Simon",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "MoN39ntOjng5z3SEOlG/pQ==",
      "hashedPassword": "Cb4Tyy9+pshJI41iELjMMpYqkf5L/7krdY30bths628phjI3nJFx9ch7E3FkpllKLq7cjduu1AhsiWg36JFVhA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aW09B21X-OrigymTwitter-ProfilePicture-Black.jpg"
        },
        "personal": {
          "disciplines": [
            "CPD Courses",
            "Level 2 Courses",
            "Level 3 Courses",
            "Level 4 Courses",
            "Online Courses"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -2.9957299999999805,
                53.44664
              ],
              "country": "United Kingdom",
              "city": "Bootle",
              "postcode": "L20 7AB",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Origym",
          "companyWebsite": "https://origympersonaltrainercourses.co.uk/",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EoiaThad-korra%20(1).png",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/772495036257175/",
          "yearsOfExp": 2015,
          "affiliation": "REP's, Active IQ, Skills Active",
          "strapLine1": "Origym are the UK's fastest growing Personal Trainer Course provider.",
          "biography": "Origym is a popular and trusted Personal Trainer Course provider. \n\nOrigym is founded on the principle of improving both the calibre and expertise of personal trainers entering the fitness industry through innovative learning methods. We feel responsibility for supporting each and every new student who is planning on entering the personal trainer industry. We will support you from the point of enrolment throughout your employment and even further.\n\nWe make our qualifications accessible to all. We provide the most affordable and flexible PT courses on the market. We incorporating innovative learning methods and resources that our students can utilise. This is why we allow you to choose what structure and resources are best for you.\n\nAt Origym we value quality. Quality is always at the forefront of our agenda and we believe that our courses and unconditional support is reflects that. We give you more than just a certificate in personal training, we give you life skills and the resources to exceed your qualification and become a successful fitness professional.\n\nOrigym partners with Active IQ, renown as the largest awarding organisation in the UK for Personal Training qualifications and are both nationally and internationally recognised. Our PT courses are all REPs recognised and endorsed by Skills Active, the Sector Skills Council for Health and Fitness in England and Wales. Employers respect the credibility of our course. This enables our graduates to easily secure employment.",
          "places": "Origym Academy, Bridgewater Complex, Bootle, Liverpool, L20 8AH.",
          "offers": "Level 2 Personal Trainer Course\n\nLevel 3 Personal Trainer Course\n\nAdvanced Diploma: Level 4 Personal Trainer Course\n\n10 x CPD courses (Contact for more specification)\n\nAll of these courses are available as Online courses, Fast Track and Personal Mentored. \n\nWe value your choice and that is why we let you choose your structure and method of study.",
          "testimonials": "Here are a few reviews of our TrustPilot account. This account is open to the general public and not fabricated in any way. The account has an average of 9.1 out of 10 and we believe this reflects the popularity of our courses. \n\nThese reviews were posted by current or former students.\n\n\n\"Excellent supportive company that has …\nExcellent supportive company that has been a positive experience for our gym and will be using them in near future too for all our staff\".\n\n\"All through out the course there was a strong sense mentoring going on. The smaller class sizes helped build a good rapport with tutors and that showed when tutors would take extra time out of hours for help with study. That is really important for such an intense course like this\" \n\n\"We had the benefit of having different session with 5 different experienced personal trainers as we got to learn different thoughts and strategies for actual on the job situations which is just as important to learn as the text books as this will prepare me for what it's like when I actually start my first PT job and starting to build new relationships with clients and what works best for some people may not be what works best for others\"",
          "twitter": {
            "screen_name": "Origym_COE",
            "link": "https://twitter.com/Origym_COE",
            "user_id": "4067355592",
            "accessToken": "4067355592-kmF5gGOBTHkhvrh8XbNRGSnbQZRmOpapEv6s6cV",
            "accessTokenSecret": "WiYxp74PRh2YOKOlZikOeW2f9KsfKWDoU8ZyZhOoSaKUH"
          },
          "facebookPage": "profile.php?id=100004900576721",
          "instagram": {
            "access_token": "5514987196.8640130.17578c1e57d14679ace9383ff1325d25",
            "link": "https://www.instagram.com/origym_group"
          },
          "phone": {
            "code": "+44",
            "number": "1704870439"
          }
        },
        "score": 79,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1496916899175,
      "username": "origym",
      "feature_in_area": "gyms"
    },
    {
      "_id": {
        "$oid": "591c20a09edeff7e92d940ad"
      },
      "lastUpdated": 1495015584037,
      "createdAt": 1495015584037,
      "email": "udge@live.com.au",
      "firstName": "Adriana",
      "surname": "Tantau",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "737b88b5ef25dc2e825a56d0d6a426622f46001a",
      "salt": "ePXqsDfBx0oIYVv/svuTZw==",
      "hashedPassword": "vjqaLziFIuFg8F06eWefcPW8Btnk/92n+EQHW/SKRdZ1FJFjLLTVfQ1B7lvdsUzwAfPdgNZWaDE2Dn/x+o5Bmg==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        }
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495015584545
    },
    {
      "_id": {
        "$oid": "591ef9eedd95196d1a4756f1"
      },
      "lastUpdated": {
        "$date": "2017-11-28T09:50:14.819Z"
      },
      "createdAt": 1495202286558,
      "providerId": "10211653286401260",
      "provider": "facebook",
      "surname": "Wilson",
      "firstName": "Carol",
      "email": "mscfitpro@gmail.com",
      "settings": {
        "personal": {
          "facebook": "",
          "disciplines": [
            "Aerial Yoga",
            "Reformer Pilates",
            "Vinyasa Flow Yoga",
            "Wellness",
            "Yogalates"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -96.78687939999998,
                32.799475
              ],
              "city": "Dallas",
              "postcode": "75204",
              "_id": {
                "$oid": "5a1d31567d852e353acb638b"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "FITNZ 360",
          "companyWebsite": "http://fitnz360.com",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Gih8VJA1-fitnz%20360%20bg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "FITNZ360/",
          "biography": "Our Pilates, Yoga and Wellness Studio operates on a unique model. We want to help you feel great and look your very best. Yoga and Pilates helps you work from the inside out. That means improving your posture, strength, flexibility, fitness and nutrition,. Once you do that, you.ll feel energized and revitalized. We offer customized programs for individuals, couples, groups and children. All Ages and Fitness Levels Welcome!",
          "offers": "Please visit our website for details on prices, packages & offers:  http://www.fitnz360.com/index.html",
          "strapLine1": "Pilates | Yoga | Wellness",
          "phone": {
            "number": "2147029975",
            "code": "+1"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/exfZFS4v-fitnx%20360%20logo.jpg"
        },
        "score": 58
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "fitnz_360",
      "lastLogin": 1495202286738,
      "feature_in_area": "none",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "5922e0b03a6e5c26375fabaa"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:14:46.640Z"
      },
      "createdAt": 1495457968109,
      "providerId": "10213248204865498",
      "provider": "facebook",
      "surname": "Stockton",
      "firstName": "Alison",
      "email": "ajfitness@aol.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10213248204865498/",
          "disciplines": [
            "Group Classes",
            "Mobile Trainer",
            "Nutrition",
            "Pilates",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Health Coach For Women"
          ],
          "locations": [
            {
              "geolocation": [
                6.496130099999959,
                46.50881269999999
              ],
              "city": "Morges",
              "postcode": "1110",
              "_id": {
                "$oid": "5a5ff506765eba094646f71c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 110,
            "max": 140
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "biography": "Welcome to AJ Fitness\n \nAlison Stockton of AJ Fitness has been a health and fitness coach across UK, Isle of Man, UAE and Switzerland. She has a wealth of knowledge and experience and has helped all her clients achieve their PERSONAL  HEALTH goals.\n\nThrough her studies of NLP, Alison is most passionate about empowering her clients to have the positive mindset to sustain their healthy life both mentally and physically.\n \nAlison works with people from all walks of life and has in the past included celebrities, sports figures, medical doctors,  musicians, CEOs, Mums - who have THE hardest job of staying at home with their children -lawyers as well as regular people like you and her.\n​\nAlison's passion is to help men and women break the cycle of unhappiness with their body, their unhealthy eating habits, their injuries, as well as - of course - their personal fitness challenges, whether they be to exercise 3 times per week, run a marathon or simple self belief. \n​\nAlison will help you achieve your desired health status and is always at the end of the phone or email to assist you. its not just about that hour you spend on a one to one session, she believes in the continual support following on from that session.  \n​\nThe  vision of AJ FITNESS is simple: she likes to help everyone and assist all walks of life toward a healthier mind, healthier body and to completely evolutionize your health and wellness.",
          "offers": "Currently all sessions are for outdoor training or at the clients home within the vaud area​\n \nFor other locations within Switzerland, please be advised that additional charges will occur (for travel costs)\n​ \nPersonal Training:\n ​\n10 sessions (2 sessions minimum, per week minimum are recommended) .\n​\nincludes 1 fitness assessment, nutritional guide, exercise plan body stats at begining and end of session package, before and after photos.\n​\nINDIVIDUAL PERSONAL TRAINING SESSIONS ARE 120chf PER HOUR,Per Person\n \nTOTAL PACKAGE 1100 chf\n \nPILATES SESSION:\n \n10 sessions 1200chf\n \n140chf per person per hour.\n​\nFor clients wanting to train with an additional person, please add 50% to package prices, per person.\n \nALL SESSIONS ARE  PAYABLE in advance.\n \nAll sessions and Packages MUST be paid in advance to receive any discounts offered\nAll sessions will expire 3 months from purchase date, no refunds, for sessions in leiu and sessions are NOT transferable to another person.\nCancellations are chargeable at full session rate if less that 24 hours notice given by client. \npayments can be made via bank transfer, paypal or cash\n \nNutritional assessment and menu writing is charged by the hour as an additional service",
          "radius": 20,
          "facebookPage": "ajfitnessinswitzerland/",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wFMJkd4V-ajfitnessbg.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 1996,
          "affiliation": "Premier Training, The Pilates Institute",
          "strapLine1": "AJ Fitness - the key to a happier, more focused & successful individual",
          "phone": {
            "number": "799526236",
            "code": "+41"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "CHF",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hsihuY33-ajfitnessprofile.jpg"
        },
        "score": 78
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "alison_stockton",
      "lastLogin": 1496084234850,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5922f21f3a6e5c26375faf6f"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:04:37.911Z"
      },
      "createdAt": 1495462431055,
      "providerId": "1263069817144572",
      "provider": "facebook",
      "surname": "Pilkerton",
      "firstName": "Knox",
      "email": "k.pilkerton.nac@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1263069817144572/",
          "disciplines": [
            "Circuit Training",
            "Endurance",
            "Functional Training",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Personal Trainer"
          ],
          "locations": [
            {
              "geolocation": [
                -86.81617949999998,
                36.1419748
              ],
              "city": "Nashville",
              "postcode": "37203",
              "_id": {
                "$oid": "5a5ff2a5765eba094646f71b"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 50
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GWgcP1Ea-IMG_20170406_142043.jpg",
            "size": {},
            "offset": 0
          },
          "openingTimes": "",
          "strapLine1": "Owner Pilkerton Training - Personal Training and Athletic Training",
          "biography": "My objective in the fitness industry has always been to push myself farther than most people feel is possible and train , influence and motivate those key leaders on the way. This was always my attitude on the Lacrosse Field and I took that same attitude with me into an industry that for 22 years has been the passion of my life",
          "yearsOfExp": 1995,
          "google": "https://plus.google.com/113457681329885099912",
          "phone": {
            "number": "6155738500",
            "code": "+1"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/40efvnqR-IMG_20170406_142043.jpg"
        },
        "score": 71,
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "knox_pilkerton",
      "lastLogin": 1498050205976,
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "59234a62d023fa4776169d13"
      },
      "lastUpdated": 1495530768197,
      "createdAt": 1495485026147,
      "providerId": "10154651437731527",
      "provider": "facebook",
      "surname": "Thompson",
      "firstName": "Alfred",
      "email": "fitnessimprint@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154651437731527/",
          "disciplines": [
            "Bodybuilding",
            "Bootcamp",
            "Functional Training",
            "Weight Loss",
            "Yoga"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Advanced Personal Trainer"
          ],
          "locations": [
            {
              "geolocation": [
                -77.30637330000002,
                38.84622359999999
              ],
              "country": "United States",
              "city": "Fairfax",
              "postcode": "22030",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 70,
            "max": 90
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 100,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KgoO6RZz-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 1997,
          "affiliation": "NGA, yoga fit",
          "offers": "Personal training\n$70.00 for 10,session\n$80.00 for.  8, session\n$90.00 fot.  6,session\n\nWedding package\n1, 2- Months  out $600. A month\n2, 4- Months out $560. A month\n3, 6- Months out $520.  A month",
          "biography": "Hi \nI fell in love with personal training in 1997. I have done some Fitness obstacle racing,  Fitness modeling. I started teaching  my yoga, now I teach my Wei-Yoga class.\n In 2007 I became Mr. Virginia bodybuilding, now I do Spartan races and I am a double trifecta",
          "phone": {
            "code": "+1",
            "number": "7032170954"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Nx6I8l7l-filename.jpeg"
        },
        "score": 58
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "alfred_thompson",
      "lastLogin": 1495485026440
    },
    {
      "_id": {
        "$oid": "59252352d023fa47761701d5"
      },
      "lastUpdated": 1495606098898,
      "createdAt": 1495606098661,
      "providerId": "1361847780558436",
      "provider": "facebook",
      "surname": "Sachikonye",
      "firstName": "Alfred",
      "email": "alffy7@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1361847780558436/",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eFaYyErJ_profilePicture.jpeg"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "alfred_sachikonye",
      "lastLogin": 1495606098927
    },
    {
      "_id": {
        "$oid": "592523fdd023fa4776170216"
      },
      "lastUpdated": 1495606269585,
      "createdAt": 1495606269382,
      "providerId": "100528642662831122322",
      "provider": "google",
      "surname": "Sachikonye",
      "firstName": "Alfred",
      "email": "alfredsachie@gmail.com",
      "settings": {
        "personal": {
          "google": "https://plus.google.com/100528642662831122322",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/25S0qth0_profilePicture.png"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "username": "alfred_sachikonye_rJLZEiMW-",
      "lastLogin": 1495606345201
    },
    {
      "_id": {
        "$oid": "59256b36d023fa4776171159"
      },
      "lastUpdated": 1495811796908,
      "createdAt": 1495624502614,
      "email": "sundried@shoomon.xyz",
      "firstName": "Sundried",
      "surname": "Clothing",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "234bbe998516b8aebf4eb09a442bf5c1a7226058",
      "salt": "vEIX79sWR95k9GuBLNL1BA==",
      "hashedPassword": "18QDm+CgOMlyDDptiG0hUkb9/jTKvJSLDwpv1LfaCXfYawgTyX2XZmnggQpb4hoULhrQ8VjXGBsUtAza4dk+Rg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qLnSswlX-sundriedlogo.png"
        },
        "personal": {
          "disciplines": [
            "Activewear",
            "Ethical Production",
            "Girls Apparel",
            "Intelligent Design",
            "Women's Clothing"
          ],
          "qualifications": [
            "Leisure",
            "Athletics",
            "Gym Going",
            "Outdoor Activities",
            "Indoor Sports"
          ],
          "locations": [
            {
              "geolocation": [
                0.7929446000000553,
                51.527098
              ],
              "country": "United Kingdom",
              "city": "Southend-on-Sea",
              "postcode": "SS3 9UJ",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Sundried Clothing",
          "companyWebsite": "https://www.sundried.com/",
          "profileTitle": "Sport & Fitness Shop",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jBC9sgt1-sundriedbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "facebookPage": "sundried",
          "strapLine1": "Activewear by Sundried: ethical production, premium fabrics, and intelligent design combining to create superior apparel",
          "biography": "Sundried represents the innovators, the thinkers, the dreamers. The people who see it differently. We create products that will support you in your next endeavor. Excited by adventure we push towards a vision of greatness.\n\nWe create incredible products pushing our way of life through our apparel. From the design process through to our low carbon brand we take responsibility in business as serious as we do when it comes to our outdoor way of life.\n\nWe take our inspiration from inspirational people. Motivators and those willing to explore what boundaries truly mean. Treating hard work as a challenge. Dreams as goals. We treat our brand with the respect that we hope our customers treat their goals, objectives and way of life.\n\nSundried is a brand and collective delivering technical performance and style to athletes who push themselves to reach their physical peak. Who live and breathe fitness as if it were as intrinsic to their everyday existence as oxygen and water. Each linked to each other through the apparel that enables them to achieve whilst looking their best. Inspired by the raw elements, nature’s own gym and the soulful, humanitarian people that inhabit this Earth, Sundried is a lifestyle choice for those wishing to use their surroundings to physically progress and better themselves and in return, offer something back to communities and environments needing support.",
          "offers": "FREE SHIPPING WORLDWIDE. NO MINIMUM PURCHASE REQUIRED.\n\nOneshoo members are very lucky to have a 50% discount on all Sundried products. At Sundried we love working with professionals within the fitness industry, with sponsored athletes and personal trainer ambassadors, so working together with Oneshoo we can combine our bases to create better opportunities for fitness professionals.\n\nTo take advantage of this fantastic offer from Sundried, register FREE on Oneshoo.",
          "places": "Sundried is an online apparel company that delivers products worldwide.",
          "phone": {
            "code": "+44",
            "number": "2032893847"
          }
        },
        "score": 68,
        "inherit_from_parent": false
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495624550198,
      "username": "sundried_clothing",
      "feature_in_area": "partners"
    },
    {
      "_id": {
        "$oid": "59257fc1d023fa477617159b"
      },
      "lastUpdated": 1495811784939,
      "createdAt": 1495629761648,
      "email": "totum@shoomon.xyz",
      "firstName": "Totum",
      "surname": "Sport",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "769f48223f6c68e2a0a2de0e3a5f0ebbddf2444e",
      "salt": "jCTSkTXhxk5pQGjOPQ9zWA==",
      "hashedPassword": "UlO6dxBaNrARq/GDl1aqRqxb3tCpFecaLx7WREPgTEBw41SzySbOAearSAnyf0jksD0LpFWKGxSXHZ8amP0OwQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Iuwd38wr-totumlogo.png"
        },
        "personal": {
          "disciplines": [
            "100% Natural",
            "Contains Minerals",
            "Contains Trace Elements",
            "Electrolyte Rich",
            "Supplements"
          ],
          "qualifications": [
            "High Intensity",
            "Leisure",
            "Indoor Sports",
            "Outdoor Activities"
          ],
          "locations": [
            {
              "geolocation": [
                -1.3907179999999926,
                54.90586589999999
              ],
              "country": "United Kingdom",
              "city": "Sunderland",
              "postcode": "SR1 3QD",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Totum Sport",
          "companyWebsite": "https://totumsport.co.uk/",
          "profileTitle": "Sport & Fitness Shop",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/It8bPGRO-totumbg.png",
            "size": {},
            "offset": 0
          },
          "facebookPage": "TotumSportUKIreland/",
          "biography": "Do you want to banish the dreaded DOMS?\n\nDo you wish you had quicker recovery between workouts?\n\nDo you want to recommend the best supplement to your clients so they can train more and see bigger benefits from their workout?\n\nIf you answered YES to any of those questions, then you need to find out more about Totum Sport and what it can do for you and your clients.\n\nTotum Sport is the world’s ONLY 100% natural sports supplement. It contains 78 electrolytes, replacing ALL  the electrolytes you lose through sweat during intense exercise and training. It’s the perfect on-the-go supplement, whether you're an athlete, gym bunny or weekend warrior. Basically, if you exercise you need Totum Sport in your life!\n\nSuper fans of Totum include Olympic marathon runner Aly Dixon, 1500m Olympian Ross Murray, Sunderland AFC and England Footballer Jermain Defoe.\n\nTotum Sport is different from any other supplement, as it’s the only one in the world that is 100% natural, with no additives, preservatives or sugars. Harvested from blooms in the ocean that are rich in phytoplankton and zooplankton, Totum Sport contains all the electrolytes your body needs in bioavailable form, which means they will be rapidly absorbed by your body for fast results when you need them most.\n\nKey benefits of Totum Sport include:\n\nOptimum hydration\nFaster recovery\nMaximum speed and endurance\nReduced muscle cramps and injuries\nBoosted immune system\nSustained strength and speed\nImproved stamina\nQuicker reaction times",
          "strapLine1": "100% natural sport supplement containing 78 electrolytes",
          "offers": "Want to try Totum Sport or think your clients would love it? Oneshoo members will receive an exclusive code on registration for them to use at www.totumsport.co.uk and receive 15% off Totum Sport. \n\nRegister FREE on Oneshoo today to receive this offer from Totum Sport",
          "places": "Totum Sport is an online sport supplement company that delivers products across the UK.",
          "phone": {
            "code": "+44",
            "number": "1913371553"
          }
        },
        "score": 68,
        "inherit_from_parent": false
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495637570355,
      "username": "totum_sport",
      "feature_in_area": "partners"
    },
    {
      "_id": {
        "$oid": "5925a5d4d023fa4776171de3"
      },
      "lastUpdated": 1495811771916,
      "createdAt": 1495639508786,
      "email": "fitproclientrecipes@shoomon.xyz",
      "firstName": "Fitpro",
      "surname": "Client Recipes",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "85361d718e425f81ad5eb4edd564861fce626058",
      "salt": "gpSD6z6yDwOYf3OPCqXIaw==",
      "hashedPassword": "E7VuAR4/fG7c0l2j6PTnLlfUC9kyjQZa2hY1HV9Ty935wupgNgZ7svqprmaceJ878DcGREkTNPQUlkB+OSD8fQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GUNMsLXC-fitprologo.png"
        },
        "personal": {
          "disciplines": [
            "Cookbooks",
            "Meal Plans",
            "Nutrition & Wellbeing",
            "Recipes",
            "Weight Management"
          ],
          "qualifications": [
            "Nutrition"
          ],
          "locations": [
            {
              "geolocation": [
                -0.5981223,
                53.18332849999999
              ],
              "country": "United Kingdom",
              "city": "North Hykeham",
              "postcode": "LN6 9PW",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Fit Pro Client Recipes",
          "companyWebsite": "http://www.fitproclientrecipes.co.uk/",
          "profileTitle": "Sport & Fitness Shop",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/djKcElQA-fitprobg.png",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "facebookPage": "FitProClientRecipes/",
          "yearsOfExp": 2013,
          "strapLine1": "GLOBAL LEADER IN DELICIOUS CLIENT FAT-LOSS RECIPES AND MEAL PLANS",
          "biography": "FitProClientRecipes aims to give FitPros a full range of white label amazing delicious recipes.\n\nCovering the different & most common fat-loss nutrition systems currently used by PTs with their clients.\n\nThis product will add value to their clients' experience of the PT service. \n\nKcals & Macros are stated on each recipe as well as whether it is Clean, Paleo, Higher Protein, Low Carb, Vegetarian, Vegan, Gluten-free & Dairy Free.\n\nOnce full members FitPros will be able to log on to the website & have access to 10 new recipes every week. this recipes can then be rebranded, loaded onto the PTs' own websites &/or printed up as handouts.\n\nThis is an awesome new product designed by a current PT to be the best available to FitPros worldwide.",
          "offers": "Oneshoo members will receive an exclusive code on registration for them to use at http://www.fitproclientrecipes.co.uk/ and receive over 20% off. \n\nRegister FREE on Oneshoo today to receive this offer from Fit Prp Client Recipes",
          "places": "Fit Pro Client Recipes is an online Nutrition company delivering delicious & nutritious bespoke recipes & meal plans across the UK.",
          "phone": {
            "code": "+44",
            "number": "7000000000"
          }
        },
        "score": 70,
        "inherit_from_parent": false
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495639509037,
      "username": "fit_pro_client_recipes",
      "feature_in_area": "partners"
    },
    {
      "_id": {
        "$oid": "5925b152d023fa4776172025"
      },
      "lastUpdated": 1495811758318,
      "createdAt": 1495642450168,
      "email": "jimbag@shoomon.xyz",
      "firstName": "Jimbag",
      "surname": ".",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "d1c86b754cff7a157e67dd2a262478ae41788a43",
      "salt": "NIs7xK+i8g6uqoyjjJwd2w==",
      "hashedPassword": "pyCGNGkcafR4bKFxxSGkNBb5EZxJLpBDJc80tm+N3HWxp35F8Spb8K+HLqgns3JijKGdHoAQRHKGjZZuwAoIXQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/t9ehQvb0-jimbaglogo.png"
        },
        "personal": {
          "disciplines": [
            "Fashion Accessories"
          ],
          "qualifications": [
            "Leisure",
            "Fashion",
            "Gym Going",
            "Outdoor Activities",
            "Indoor Sports"
          ],
          "locations": [
            {
              "geolocation": [
                -2.255055699999957,
                53.4787488
              ],
              "country": "United Kingdom",
              "city": "Manchester",
              "postcode": "M3 3BE",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Jimbag",
          "companyWebsite": "http://www.jimbag.co.uk/",
          "profileTitle": "Sport & Fitness Shop",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/j5I5NvbV-jimbagbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "JIMBAGuk/",
          "yearsOfExp": 2012,
          "strapLine1": "Stylish Fashion Accessories: Holdall bags | Barrel bags | Duffel bags | Rucksacks | Wash bags | Hoodies",
          "biography": "Born in Great Britain, Jimbag is a stylish fashion accessory that can be taken anywhere and everywhere for the modern man and woman. With functionality and style combined, we provide you with styles that are great to transition from the office to the gym.\nJimbag blurs the line between work and play, and transitions them both together - with a 'Jimbag' you can have both. We all crave simple solutions that make our busy lifestyles easier with the least amount of fuss. These simple solutions also have to look good - after all, ‘Style is Everything’.\n\nOur mission here at JimBag is to deliver versatile and fashionable fitness accessories that will take you straight from the gym to the street and everywhere in between. Starting with your bag, we aim to accommodate whatever your daily to-do list calls for, yet still offer functional features that are suitable for any workout. You already manage to do it all, why shouldn’t your accessory do the same?",
          "offers": "Jimbag blurs the lines between work, workouts and play with stylish, fashionable and versatile accessories.\n\nOneshoo members receive a 15% discount on all Jimbag products.\n\nTo take advantage of this fantastic offer from Jimbag, register FREE on Oneshoo.",
          "places": "Jimbag is an online Fashion Accessory Company that delivers products worldwide.",
          "phone": {
            "code": "+44",
            "number": "7712176151"
          }
        },
        "score": 70,
        "inherit_from_parent": false
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495642450393,
      "username": "jimbag",
      "feature_in_area": "partners"
    },
    {
      "_id": {
        "$oid": "5925ba86d023fa4776172227"
      },
      "lastUpdated": 1495811745888,
      "createdAt": 1495644806246,
      "email": "toopretty@shoomon.xyz",
      "firstName": "Too",
      "surname": "Pretty",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Lke8KKroi6NntUxrdVuERA==",
      "hashedPassword": "+9dB1KsKuxdplGXp+PS3mRHqpJw6LUiwjK5xqXLhtM885IsUsB2N9PqfUf4UQJzIYTSZKYxOMiNVoQ4LyAl9QA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/02np8yEs-tooprettylogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Accessories",
            "Activewear",
            "Girls Apparel",
            "Women's Clothing"
          ],
          "qualifications": [
            "Leisure",
            "Outdoor Activities",
            "Indoor Sports",
            "Fitness",
            "Gym Going",
            "Boxing"
          ],
          "locations": [
            {
              "geolocation": [
                -118.4911912,
                34.0194543
              ],
              "country": "United States",
              "city": "Santa Monica",
              "postcode": "90401",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Too Pretty",
          "companyWebsite": "https://www.tooprettybrand.com/",
          "profileTitle": "Sport & Fitness Shop",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/a56qEZJ7-tooprettybg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "tooprettybrand/",
          "yearsOfExp": 2010,
          "biography": "Welcome to Too Pretty!\n \nThe Too Pretty brand was created out of my experience in a male dominated sport - Boxing.\n\nAs a fighter I was told often that I was Too Pretty to be a boxer, which really meant - in their mind - that I was not capable.\n\nFast-forward 5 years and it dawned on me that I was not the only girl/woman experiencing these comments. There were business owners, surfers, managers, race car drivers, writers bodybuilders and the list goes on!\n\nI launched the Too Pretty brand in late 2015 with a few lines of T-shirts and halter tops. They were a hit (no pun intended)!\n\nI have thought lately about what my purpose is. I have come to the conclusion that it is to touch as many girls and women as I can and empower them. I struggled with finding my mother's purpose here given that she died at such a young age.\n\nMaybe her purpose was to bring me into this world.\n\nPlease join me in spreading the #nevertoopretty message and be a part of our great female community!",
          "offers": "Oneshoo members receive a 30% discount on Too Pretty brand products:\n\nThe hit fitness-clothing brand that empowers women & girls and confirms you're never too pretty to follow your dreams - get 30% off when you register for free on Oneshoo\n\nTo take advantage of this fantastic offer from Too Pretty, register FREE on Oneshoo.\n\nA contribution from select purchase will go to charity",
          "places": "Too Pretty is an online apparel company that delivers products worldwide.",
          "strapLine1": "A female empowerment brand that inspires girls & women to embrace their femininity while still being strong and powerful",
          "phone": {
            "code": "+44",
            "number": "7000000000"
          }
        },
        "score": 70,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495644820933,
      "username": "too_pretty",
      "feature_in_area": "partners"
    },
    {
      "_id": {
        "$oid": "5925d10ed023fa47761726e9"
      },
      "lastUpdated": {
        "$date": "2017-11-22T17:24:42.838Z"
      },
      "createdAt": 1495650574889,
      "email": "marisa@stevensonfitness.com",
      "firstName": "Marisa",
      "surname": "Hoff",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ypTs4Fa86aS3f9v1n9akgg==",
      "hashedPassword": "kKCrfh+JfB2cRMRveWx74K1keB5eryPoVPKvTXssxb3OKX4LT4vI4oHWzi0fckV87fBvxcItdjGClQb5U8wNHg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zFaWlQkb-outside%20enhanced%20copy.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Personal Training",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Air Conditioning",
            "Cable Machines",
            "Changing Facilities",
            "Childrens Play Area",
            "Complimentary Amenities",
            "Creche",
            "Fitness Studio",
            "Free Parking",
            "Free-Weights Selection",
            "Free Wi-Fi",
            "Lockers",
            "Punch Bags",
            "Resistance Equipment",
            "Suspension Equipment"
          ],
          "locations": [
            {
              "geolocation": [
                -118.7847552,
                34.1854384
              ],
              "city": "Oak Park",
              "postcode": "91377",
              "_id": {
                "$oid": "5a15b2da2288dd3a30f3f539"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Stevenson Fitness",
          "companyWebsite": "http://www.stevensonfitness.com/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 18,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/747ZxawQ-Chris%20Kick.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "stevensonfitness",
          "yearsOfExp": 2010,
          "strapLine1": "Your Community, Your Gym",
          "openingTimes": "Monday - Thursday: 4:30am to 10pm\nFriday: 4:30am to 7pm\nSaturday and Sunday: 6am to 7pm",
          "biography": "Stevenson Fitness is Your Community, Your Gym. It is big enough to provide everything you need, but NOT so big you lose that personal touch. From our dynamic Group X classes, knowledgeable personal trainers, to our exciting small group training, Stevenson Fitness is the best place to meet all of your health and fitness goals. Stevenson Fitness is surgically clean and is known for having an industry leading member experience. Our mission statement is, “At Stevenson Fitness, everyone leaves feeling better than when they arrive.” Every single member of our staff is committed to making sure that happens every time you visit!\n\nFacility Amenities\n* 7500 square feet full-service boutique health club\n* Diverse and dynamic Group Exercise program included with your membership\n* Brand new Technogym cardio equipment with DirecTV and Internet\n* Full locker rooms complete with showers and complimentary toiletries\n* Complimentary WiFi throughout the facility\n* Complimentary towel service\n* Morning childcare\n* Olympic Lifting platform",
          "phone": {
            "number": "8187070123",
            "code": "+1"
          }
        },
        "score": 66
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495650575216,
      "username": "stevenson_fitness",
      "feature_in_area": "none",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "5926cb7ad023fa4776175c6b"
      },
      "lastUpdated": {
        "$date": "2017-10-18T11:16:41.380Z"
      },
      "createdAt": 1495714682464,
      "email": "sparefruit@shoomon.xyz",
      "firstName": "Spare",
      "surname": "Fruit",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "lOlxFyXBZvPSTMm53TheHg==",
      "hashedPassword": "e3F7HYwjUw9k5YmQT7+b1XYI5JA3C+cKhJejwZNLM2ptQafWQgf7M4xipLcd9vzbIHwDrVRrLbrlUBrB7vfy0g==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aO7gnzdO-sparefruitlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "100% Natural",
            "Ethical Production",
            "Ethically Sourced",
            "High Fibre",
            "Low Calorie"
          ],
          "qualifications": [
            "Leisure",
            "Nutrition"
          ],
          "locations": [
            {
              "geolocation": [
                -0.30732850000003964,
                51.7516389
              ],
              "city": "Saint Albans",
              "postcode": "AL1 5DG",
              "_id": {
                "$oid": "59e7381994e4d86bf9be197b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Spare Fruit",
          "companyWebsite": "http://www.sparefruit.com/",
          "profileTitle": "Sport & Fitness Shop",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N3D745E5-spar%20fruibg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "sparefruitco/",
          "yearsOfExp": 2016,
          "strapLine1": "TRANSFORMING RESCUED FRUIT INTO DELICIOUS 100% FRUIT CRISPS",
          "biography": "We rescue fresh Kentish apples and pears that would otherwise be wasted and gently slice them whole (not wasting a pip), and slowly air-dry (never fry) them into subtly sweet and crunchy slices of natural fruity goodness.\n \nOur crisps are low calorie, high fibre, 100% fruit with absolutely nothing added.\n \nEnjoy as a healthy snack any time, or add to cereal, salads or cheese for some extra fruity crunch!\n\nTo date we have saved over 15 tonnes of apples and pears to create our delicious air-dried fruit crisps.\n \nGood food is our obsession. Sustainability is our passion.\n \nA few years back we started selling snack products in local cafes made from surplus fruit gathered from our local market and allotments in St Albans.\n \nWe were completely overwhelmed by the amount of produce that was going to waste and quickly realised that we were just scratching the surface. We discovered that working with small farms that struggle to deal with their surplus would enable us to transform more surplus produce, and in doing so help them become more sustainable both economically and environmentally - whilst feeding the consciousness of businesses and consumers on a much larger scale.\n \nAll of our profits go back into ensuring we can transform more surplus produce into more delicious products and crucially to raise more awareness of food waste in the process.",
          "offers": "FREE Delivery to the UK and Ireland - but we deliver worldwide.\n\nOneshoo members are very lucky to have a 10% discount on all Spare Fruit products, \n\nTo take advantage of this fantastic offer from Spare Fruit, register FREE on Oneshoo.",
          "places": "NOW AVAILABLE IN THE FOLLOWING OUTLETS:\n\nPlanet Organic\nSelfridges & Co\nDepartment Of Coffee & Social Affairs\nThe Natural Kitchen London\nFarmdrop\nFarmstand",
          "phone": {
            "code": "+44",
            "number": "7000000000"
          }
        },
        "score": 70,
        "inherit_from_parent": false
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "lastLogin": 1495714682676,
      "username": "spare_fruit",
      "feature_in_area": "none",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "59289a3eadf6b20261dcb85d"
      },
      "lastUpdated": 1495833150224,
      "createdAt": 1495833150224,
      "email": "davereadle@hotmail.com",
      "firstName": "Dave",
      "surname": "Readle",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "8858ed520c2c60b9f0fa0a0797418c867065dae2",
      "salt": "S5SJFu6v04nHY5CNHzVX9A==",
      "hashedPassword": "b/4sIJXEBK9bk5+dw2iGQPybtaQFd55bYTPd8iz+pkrPwM3qwyFQnef4Jvu0zZckBrlUyOKouwDi6l3VtK4/LA==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "inherit_from_parent": false
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1495833150435
    },
    {
      "_id": {
        "$oid": "592d36c2a080ee45a974f162"
      },
      "lastUpdated": 1496135362430,
      "createdAt": 1496135362430,
      "email": "valentina.serra@inspiring-fitness.co.uk",
      "firstName": "Valentina",
      "surname": "Serra",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "8533b44cf364856ff47aad30b78b25be4422ea4d",
      "salt": "wO3QH6i0bQ6gwYd9NDLY2g==",
      "hashedPassword": "hoXWUYLiP0fRJOBeiUOCiHojvZkDVx/viBEeTY5/EK/VFfwOeFIVVMvuZdJE6TM1K/x3lwp32IEU6eSETd4GBw==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "inherit_from_parent": false
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496135362684
    },
    {
      "_id": {
        "$oid": "592d55bba080ee45a974f823"
      },
      "lastUpdated": {
        "$date": "2017-10-18T10:32:03.894Z"
      },
      "createdAt": 1496143291338,
      "providerId": "10155279205150883",
      "provider": "facebook",
      "surname": "Slocum",
      "firstName": "Lucy",
      "email": "lucyslocum@hotmail.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155279205150883/",
          "disciplines": [
            "Diet & Nutrition",
            "Fat Loss",
            "Indoor Classes",
            "Personal Training",
            "Small Groups"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -0.09543299999995725,
                51.5045849
              ],
              "city": "London",
              "postcode": "SE1 0NQ",
              "_id": {
                "$oid": "59e72da394e4d86bf9be1965"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.09543299999995725,
                51.5045849
              ],
              "city": "London",
              "postcode": "SE1 0NQ",
              "note": "The Bridge Centre, 81 Southwark Bridge Road - Tubes: Borough, Southwark, London Bridge & Waterloo",
              "_id": {
                "$oid": "59e72da394e4d86bf9be1964"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "The Lodestar Clinic",
          "companyWebsite": "http://www.thelodestarclinic.com",
          "profileTitle": "Boot Camp",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uh3e3uS6-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "lodestarclinic",
            "link": "https://twitter.com/lodestarclinic",
            "user_id": "841557092309823488",
            "accessToken": "841557092309823488-cvDy9R8evxxeWY5ZcaGo44mlB6MfByK",
            "accessTokenSecret": "lo8zUGUzL2P1S1r4ZJZNDKAZFbqWZHbybbXxq7utz74tN"
          },
          "yearsOfExp": 2017,
          "affiliation": "NRPT membership, Master Trainer; including Active IQ’s Advanced Diploma in Personal Training (REPs Level 2 and 3). ACSM Fitness & Nutrition Coach Exercise Specialist; certified in postural retraining and corrective exercise. This includes functional training for sports and rehabilitation, back care and specific populations (older adults, diabetes, obesity, arthritis, osteoporosis, pregnancy and adolescents). Fitness Entrepreneur",
          "strapLine1": "A kickstart programme in health, fitness and nutrition for you!",
          "places": "",
          "biography": "A 12 week programme led by Dr Lucy Slocum enabling you to live a healthy lifestyle for life. A combination of personal training, health checks, motivation consultations and nutrition coaching. Become the fitter, leaner, healthier you!",
          "offers": "Morning workout sessions. \nConsultation times agreed between client & myself. \n\nSix Week Taster Programme = £500\nTwelve Week Taster Programme = £1500",
          "testimonials": "“She is enthusiastic and informed. She listens carefully and adapts the programme to my needs. Time goes very quickly and she pushed me just the right amount. You’re lucky to get to meet Dr Lucy”.  Frances\n\n“Dr Lucy has the right personality and plans the hour well. The sessions are excellent with her encouraging me all the way. She keeps pushing the limits which is good. Wishing her all the best – top lady!” Lyn\n\n“I really enjoyed training with Dr Lucy. It was good fun and I worked hard. I liked the different techniques she taught me with regard to core work. My heels feel a lot better since I started with her”. Mark",
          "facebookPage": "lodestarclinic",
          "instagram": {
            "access_token": "4818105980.8640130.ab2dd0aeaea449739d285cba6af2846a",
            "link": "https://www.instagram.com/lodestarclinic"
          },
          "phone": {
            "code": "+44",
            "number": "7388922553"
          }
        },
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oXN2ukKF-lodestar-positive-yellow-icon.jpg"
        },
        "inherit_from_parent": false,
        "score": 77
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "the_lodestar_clinic",
      "lastLogin": 1500461821712,
      "agent": {
        "family": "Edge",
        "major": "15",
        "minor": "15063",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/52.0.2743.116 Safari/537.36 Edge/15.15063"
      },
      "__v": 3
    },
    {
      "_id": {
        "$oid": "592ee2a04007bf76da5736d8"
      },
      "lastUpdated": 1496244896490,
      "createdAt": 1496244896490,
      "email": "herbert.schmale@equinox.com",
      "firstName": "Herbert",
      "surname": "Schmale",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "b1ca836be0a4fc7bd3013d684f02615884b0914c",
      "salt": "2cpSNe62+A+wBYQDhZF0Yg==",
      "hashedPassword": "zLDO9mc4hBbyR33KAnKPUTXo7N2Fxlo7XrZ0tx7yq+cqhnkjL/qp2w0JkeqzBMgw0dHYA7Wrr8SLZvcWg/4ayw==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "inherit_from_parent": false
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496244896762
    },
    {
      "_id": {
        "$oid": "59301a704007bf76da57799a"
      },
      "lastUpdated": {
        "$date": "2017-11-28T10:27:57.697Z"
      },
      "createdAt": 1496324720955,
      "email": "ali@fittolast.co.uk",
      "firstName": "Ali",
      "surname": "Page",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "HVANflFTr5roPLcKUFnvQA==",
      "hashedPassword": "LZDmGCHsz9C07fo09PlR8x09joTG1g/RS7uaEHIOw6CYOS9E6JBBBtffUvobkZ1GcgmUL51Opr7tpseXIEtVsw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YiPC555g-filename.jpeg"
        },
        "personal": {
          "disciplines": [
            "Corporate Wellness",
            "Diet & Nutrition",
            "Personal training",
            "Personalised Programmes",
            "Small Group Training"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -0.1333170999999993,
                51.4657437
              ],
              "city": "London",
              "postcode": "SW4",
              "_id": {
                "$oid": "5a1d3a2d7d852e353acb6397"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.13319290000004003,
                51.4657363
              ],
              "city": "London",
              "postcode": "SW4",
              "_id": {
                "$oid": "5a1d3a2d7d852e353acb6396"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Fit to Last Limited",
          "companyWebsite": "Http://www.fittolast.co.uk",
          "profileTitle": "Fitness Trainer Group",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KeqUbVvC-Hero%20image.jpeg",
            "size": {},
            "offset": 0
          },
          "biography": "ARE YOU LOOKING FOR A PERSONAL TRAINER IN CLAPHAM?\nOur programs offer customised nutrition planning and coaching, combined with a tailored training plan. This thorough approach ensures that you achieve and retain the results you’re aiming for.\nFit to Last is Clapham’s premier personal training service, operating from a private studio.\n\nFocus:\nCustom programs to build lean muscle mass, improve your metabolism, the way you move, cardiovascular fitness, strength, posture and flexibility.\n\nAccountability:\nRealistic goals and monitoring your progress consistently sets you up for success. Our app also helps you to develop healthy habits and monitor progress on a day-to-day basis.\n\nNutrition:\nLearn about and implement a customised nutrition plan that will transform your physique, improve your general wellbeing and boost your energy levels.\n\nResults:\nWith a team to support you, we ensure you are set you up for success. You'll feel great about the way you look, and improve your health and confidence.",
          "strapLine1": "Personal Training & Nutrition Coaching in Clapham, London",
          "offers": "Get in touch and one of our team will get straight back to you.\n\nContact Details\nali@fittolast.co.uk \n020 3740 9644 \n\nFit to Last Limited\n1P Clapham North Art Centre\n26-32 Voltaire Road\nLondon\nSW4 6DH\n\nJust a few minutes walk from Clapham North, Clapham Common & Clapham High Street stations.\n\nFree parking is available inside Clapham North Art Centre\n\nOpening Times:\n\nMon-Fri: 6:30am-9:00pm\nSaturday: 7:00am-1:00pm\nSunday: closed",
          "phone": {
            "number": "2037409644",
            "code": "+44"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/906620569476103/",
          "facebookPage": "fittolast",
          "google": "https://plus.google.com/114718798559620555526",
          "instagram": {
            "access_token": "274156820.8640130.9e6baaf5e3f547b08a3045480cbce8da",
            "link": "https://www.instagram.com/fittolastlondon"
          },
          "twitter": {
            "accessToken": "319124888-8ebR8MI4bER0fE7ZhKZw7notOag9wmxvgKf1OQ5o",
            "accessTokenSecret": "sg8XYk7eeQnyQR3tMKyyMgMPzYKpshVqQMBffRjQ15U2N",
            "link": "https://twitter.com/fittolast",
            "screen_name": "fittolast",
            "user_id": "319124888"
          },
          "yearsOfExp": 2010,
          "affiliation": "NA"
        },
        "inherit_from_parent": false,
        "score": 76
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1506085771356,
      "username": "fit_to_last_limited",
      "__v": 16,
      "password_reset_code": "X",
      "agent": {
        "family": "Safari",
        "major": "10",
        "minor": "1",
        "patch": "2",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_5) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.1.2 Safari/603.3.8"
      }
    },
    {
      "_id": {
        "$oid": "59311ff04007bf76da57b15c"
      },
      "lastUpdated": 1496937446819,
      "createdAt": 1496391664814,
      "email": "jademoulden@hotmail.co.uk",
      "firstName": "Jade",
      "surname": "Moulden",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "lTnodg7S4PGWloqn4cgz/w==",
      "hashedPassword": "UhheSaPlJvJ81LcD/KMVfpunizssrmeWEqY2R/mIOP6i/eIc4nEMnK6jIjMoZbZaMUOR32CYbV3z0M3tHR9I8w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OAUJ1AO7-Jade-BW-Copy.jpg"
        },
        "personal": {
          "disciplines": [
            "Gym"
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 2 Swimming Coach",
            "Trampoline Coach",
            "Expand certifications section Certifications Womens Sport Leadership Academy (WSLA)",
            "Sport England Future Leaders of Sport Programme"
          ],
          "locations": [
            {
              "geolocation": [
                -0.3258580000000393,
                51.062883
              ],
              "country": "United Kingdom",
              "city": "Chart Way",
              "postcode": "RH12 1XL",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 10,
            "max": 100
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Gym instructor",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VMp024zk-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2004,
          "biography": "Womens Sport Leadership Academy (WSLA) Sport England Future Leaders of Sport Programme Equality Training Lead Safeguarding Officer and Risk Assessment Training Trampoline Coach Level 2 Gym Instructor Level 2 Swimming Coach",
          "phone": {
            "code": "+44",
            "number": "7825651998"
          }
        },
        "inherit_from_parent": false,
        "score": 56
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496391665031,
      "username": "jade_moulden"
    },
    {
      "_id": {
        "$oid": "593158ff4007bf76da57bd67"
      },
      "lastUpdated": 1496601220525,
      "createdAt": 1496406271985,
      "email": "jsandersfitness@gmail.com",
      "firstName": "Jason",
      "surname": "Sanders",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "xCnsrw6uR6t4coxLmm2xcA==",
      "hashedPassword": "9lPxx5id+xQLnJnbV0HX5fIGeZBR2N96NO9EPhIiUv/yj0xPGQKiWqUJ6WcaZjVc/gWR42IuinbdlfAv1iMzcQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Tr1Vf2rc-fitness-trainer-1054.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Meal Plans / Recipes",
            "Personal Training",
            "Sports Specific",
            "Weight Loss"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -83.0508697,
                39.9485595
              ],
              "country": "United States",
              "city": "Columbus",
              "postcode": "43223",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Sanders Personal Training",
          "companyWebsite": "https://jsandersfitness.wixsite.com/getfitwithjason",
          "profileTitle": "Mobile Personal Training",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/iOjRnL2m-IMG_20170529_095848_696.jpg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "374220164.8640130.6c61369b7b8841b6b23cd6fe2cb37dcd",
            "link": "https://www.instagram.com/sanderspersonaltraining"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/1331026103647372/",
          "google": "https://plus.google.com/103058278731773267523",
          "yearsOfExp": 2005,
          "biography": "My name is Jason Sanders and I hold a Bachelors Degree in Exercise Science. I am ACE Certified and have 20+ years of experience. My motto is HELPING YOU GET TO WHERE YOU WANT TO BE.",
          "offers": "Personal Training\nBoxing\nWeight Loss\nBodybuilding\nSport Specific \nSeniors\nRehab\nAnd more",
          "places": "Columbus and surrounding. In your home or gym",
          "phone": {
            "code": "+1",
            "number": "6149579164"
          }
        },
        "inherit_from_parent": false,
        "score": 48
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496413539480,
      "username": "sanders_personal_training"
    },
    {
      "_id": {
        "$oid": "59350bf84007bf76da58876b"
      },
      "lastUpdated": 1496648696824,
      "createdAt": 1496648696824,
      "email": "chris@theexerciseclinic.co.uk",
      "firstName": "Chris",
      "surname": "Cottrell",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "75be743e987860284759970add00da88105d01f2",
      "salt": "GkwGS2TjUFbl/xIU+uhBjQ==",
      "hashedPassword": "iCL8hZELmE8IJA//3KjIIh/kwIowOci+/JTLQsMqk+4AVHmX2giJyXLONFWURgQX39uSF2EClTfW11YLV7qGJg==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "inherit_from_parent": false
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496648697072
    },
    {
      "_id": {
        "$oid": "593563514007bf76da589a2d"
      },
      "lastUpdated": {
        "$date": "2017-11-28T10:14:47.218Z"
      },
      "createdAt": 1496671057286,
      "email": "martyncashmore@hotmail.co.uk",
      "firstName": "Martyn",
      "surname": "Cashmore",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "ua1cxfjdfijZ8YHBOXMxvg==",
      "hashedPassword": "thDZyc5d33TQFUKonECo5oGUsyFK/It/aVEts75ypKsWACGKHhAL4aihqr8WhTjTAwYqC+VbywjbIN5MuCvvug==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vwwEYcMd-ps.png"
        },
        "personal": {
          "disciplines": [
            "Barre Concept",
            "Private Classes",
            "Wellness"
          ],
          "qualifications": [
            "Purestretch",
            "Spinal Mobility",
            "Core Power"
          ],
          "locations": [
            {
              "geolocation": [
                -0.9028940000000603,
                51.535764
              ],
              "city": "Henley-on-Thames",
              "postcode": "RG9",
              "_id": {
                "$oid": "5a1d37177d852e353acb6392"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Purestretch",
          "companyWebsite": "https://www.purestretch.co.uk",
          "profileTitle": "Yoga / Pilates Studio",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Io1ezzwH-purestretchbg.jpg",
            "size": {},
            "offset": 0
          },
          "biography": "Why purestretch?\nHave we mentioned how great purestretch is yet? Ok maybe – but what we haven’t told you is how well it complements all your favourite fitness regimes.\n\nWhether you’re a yoga master, golf enthusiast or clubbercise fanatic – purestretch will help your downward dog, improve your swing or help those post-clubbercise aching calf muscles…",
          "facebookPage": "PureStretch/",
          "testimonials": "“I feel amazing after every Purestretch class, wish I could do it every day! I have learnt many positions I practice in my own time and have definitely seen myself improve since starting. I would recommend it to anyone looking to improve their flexibility”\nLucy Scott — Corporate Client.\n\n“The relaxed atmosphere of the course allowed the instructors to feel at ease. Excellent content and loads of variations for clients of all levels”.\n\nDavid — he teaches Yoga and Bhangra dance",
          "phone": {
            "number": "7774161166",
            "code": "+44"
          }
        },
        "inherit_from_parent": false,
        "score": 57
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1497864188712,
      "username": "purestretch",
      "password_reset_time": 1497864106477,
      "password_reset_code": "X",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "59357116330bcd252b5bafe4"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:02:58.316Z"
      },
      "createdAt": 1496674582312,
      "providerId": "10156084739060752",
      "provider": "facebook",
      "surname": "McIntyre",
      "firstName": "Mark",
      "email": "mark@mdmcintyre.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10156084739060752/",
          "disciplines": [
            "Bootcamp",
            "Endurance",
            "Functional Training",
            "Group Classes",
            "Online Coaching"
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "locations": [
            {
              "geolocation": [
                -6.0946894,
                54.8191372
              ],
              "city": "Moorfields",
              "postcode": "BT42 3BY",
              "_id": {
                "$oid": "5a5ff242765eba094646f71a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 35,
            "max": 285
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 25,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/21fDXMQa-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "FitnessClassNI",
            "link": "https://twitter.com/FitnessClassNI",
            "user_id": "1863702793",
            "accessToken": "1863702793-Xc0J9eDf2VgbXy7PxmJjLWnk1ymaRZ91ofemrgV",
            "accessTokenSecret": "8MWNjikQPYoeVwsx9VCXqhERnZFSrTulfInWvvL5ptvEG"
          },
          "instagram": {
            "access_token": "2023689764.8640130.48916c04465a42c38543587f67aad57a",
            "link": "https://www.instagram.com/outdoors.fitness"
          },
          "google": "https://plus.google.com/+MarkMcIntyreOutdoorsFitness",
          "facebookPage": "outdoorsfitness",
          "yearsOfExp": 2005,
          "affiliation": "reps",
          "biography": "down to earth outdoors  fitness service include group fit, personal training and weekend warrior fitness holidays",
          "offers": "weekend warrior fitness holidays \n\noutdoor fitness for groups\nthese are 4 week courses and include running techniques , endurance, power and challenges,,, \n\npersonal training \nsingle session\n5x session plan\n10x session plan",
          "strapLine1": "Test, Tune, Challenge and Build your fitness with authentic bootcamping experiences in Belfast and Ballyclare",
          "phone": {
            "number": "7599467637",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MOdHMCo3-filename.jpeg"
        },
        "inherit_from_parent": false,
        "score": 86
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "mark_mcintyre",
      "lastLogin": 1496674582564,
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5936c2b0330bcd252b5bf7f1"
      },
      "lastUpdated": {
        "$date": "2018-01-18T01:01:29.894Z"
      },
      "createdAt": 1496761008188,
      "email": "neil827@optonline.net",
      "firstName": "Dianne",
      "surname": "Della Ratta",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "cb5c89582ecfb174e3f02c69453048a016413a06",
      "salt": "JL4m3/P2Pnnpf44PcdCb5g==",
      "hashedPassword": "iUpd42qjeXfNhn1UTPPyKw7jUZ0D/lNinqG+oIiNvndwJmW2HmUWOKbmjJh13Z6SsGH/NUwoImqmZgWbXkGEOw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N7dHiyuc-diannepro.jpg"
        },
        "personal": {
          "disciplines": [
            "Mobile Trainer",
            "Pilates",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "ACE - Certified Personal Trainer",
            "NASM - Corrective Exercise Specialist (CES)",
            "Power Pilates - Mat: Beginner Intermediate & Advanced",
            "TRX - TRX Group Suspension Training Course",
            "CPR Certified American Red Cross"
          ],
          "locations": [
            {
              "geolocation": [
                -73.64012960000002,
                40.6387141
              ],
              "city": "Oceanside",
              "postcode": "11572",
              "_id": {
                "$oid": "5a5ff1e9765eba094646f719"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 70,
            "max": 90
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "yearsOfExp": 2001,
          "affiliation": "ACE, NASM, American Red Cross",
          "biography": "Hi, I am Personal Fitness Professional residing on Long Island.\nI received Thumbtack 2015 Best Fitness Trainer Award for Garden City\n\nI specialize in Strength Training, Pilates, Toning, TRX Suspension Training, Senior Strength Training, and Balance. I also provide nutrition meal planning to assist with weight loss.\nPersonal Training is available in-home or in a gym/studio environment. Individual or group session available. \nStudio locations in Oceanside and Garden City, NY\n\nFree consultation.",
          "testimonials": "",
          "strapLine1": "Thumbtack Best Personal Trainer Award 2015 / 2016:  Do You Want to Get in Shape Without The Expense of a Gym?",
          "offers": "Personal Training Studio locations in Garden City and Oceanside, NY. \nFree membership while training in Garden City.\nReasonable rates for 1/2 hr and hour sessions\nFree consultation / trial session.\nPartner training available -  all levels welcome\nSpecial packages for new clients.\n\nTestimonials: \n\nDiane is a knowledgable, capable, and practical trainer. She has helped me significantly with flexibility and core strength issues related to a chronically bad back. Slowly but surely she has helped me gain flexibility and has ameliorated the pain. She can be demanding but in a good way and is always sensitive to what is working and what is not and whether a particular exercise is uncomfortable. I highly recommend her. \n\nAlan weinschel\n\nI started my sessions with Diane in November and since then I have seen great results! Diane is very patient and makes each session enjoyable by switching up the routine. After the holiday's I decided I wanted to sign up for another round of sessions! I never thought I would enjoy exercising this much! Thank you Diane for all you are doing to get me back in shape!\n\nDebbie D'amato",
          "phone": {
            "number": "5163532551",
            "code": "+1"
          }
        },
        "inherit_from_parent": false,
        "score": 73
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496761008630,
      "username": "dianne_della_ratta",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "59383191330bcd252b5c4639"
      },
      "lastUpdated": {
        "$date": "2018-01-19T12:55:54.766Z"
      },
      "createdAt": 1496854929278,
      "email": "smdemps0186@gmail.com",
      "firstName": "Stephen",
      "surname": "Dempski",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "xvfiTpwES+WMcQYYpcDctQ==",
      "hashedPassword": "//mcyHXdEMLz+QGrshKK3ESv5gWuMGcmyWU0L5D5QU/6PekX/cJbMkeg7T49tJqLhAUiy5k7tKQThzPrl6U1MA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/L2SvTXTw-sd.jpg"
        },
        "personal": {
          "disciplines": [
            "Functional Training",
            "Hiit",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Bsc Exercise Science"
          ],
          "locations": [
            {
              "geolocation": [
                -87.9516206,
                42.2758678
              ],
              "city": "Libertyville",
              "postcode": "60048",
              "_id": {
                "$oid": "5a61eada765eba094646f8db"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 60,
            "max": 120
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 25,
          "biography": "Hello, my name is Stephen Dempski, Profressional Integrated Fitness Specialist. This gift beseeched by God, is shared with grateful passion to clientele and future clientele. \nThe Bachelor of Science, Exercise Science Degree from Illinois State University, fitness coaching experience, and being a Type 1 Diabetic, has built an expansive knowledge and skill base in human fitness and wellness . Using this with various coaching modalities and tools, such as functional movement screening, corrective exercise, speed and agility, core balance, and High Intensity Interval Training (HIIT), sets myself apart from the rest in Professional Fitness Programming.\nThe specific individual needs of the population I can help is vast, and I dedicate myself to finding the answers to unlocking that individual's wellness path. I look forward to helping those who put their trust in me to reach their fitness and wellness goals and dreams. \nIf you are in need of help knocking over those mental and physical barriers, I can be of consult and contacted",
          "affiliation": "ACE, Powerplate",
          "phone": {
            "number": "8477859943",
            "code": "+1"
          }
        },
        "inherit_from_parent": false,
        "score": 57
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496854929607,
      "username": "stephen_dempski",
      "__v": 4
    },
    {
      "_id": {
        "$oid": "593873c5330bcd252b5c543a"
      },
      "lastUpdated": 1500462192517,
      "createdAt": 1496871877105,
      "surname": "Henrique",
      "firstName": "Fernando",
      "email": "fernando.acetrainer@gmail.com",
      "providerId": "4101197082",
      "provider": "instagram",
      "username": "fernando_henrique",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "4101197082.8640130.be0b8e14d0154c118293ff7e57f2a7f4",
            "link": "https://www.instagram.com/fernando_fitness_training"
          },
          "disciplines": [
            "Functional Training",
            "Online Coaching",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "ACE Certified Personal Trainer & Group Fitness Instructor / Fitness and Nutrition Online Coach"
          ],
          "locations": [
            {
              "geolocation": [
                -87.62979819999998,
                41.8781136
              ],
              "country": "United States",
              "city": "Chicago",
              "postcode": "60604",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 60,
            "max": 80
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Rk7TpUuR-FERNANDO%20FITNESS%20DARK%20RED1.png",
            "size": {},
            "offset": 0
          },
          "google": "https://plus.google.com/108977802341170627266",
          "affiliation": "American Council on Exercise- ACE",
          "yearsOfExp": 2012,
          "biography": "As an ACE-certified, enthusiastic and results-oriented Private Fitness Trainer & Group Fitness Instructor I’ve built a loyal client base, establishing long-term relationships while delivering proven and sustained results. I’m always keen on learning new exercise methods and techniques to keep my clients on track. I love being active and stimulating my clients to enrich their own lives through physical activity in an engaging and enjoyable training environment.\n\nSome of the results I can help you achieve:\n\n• Weight loss\n• Increase of muscle mass\n• Core strengthening and posture improvement\n• Cardio-respiratory conditioning\n• Flexibility, mobility and stability training.",
          "strapLine1": "THOUGHTS => EFFECTIVE ACTIONS => GOALS ACHIEVED",
          "phone": {
            "code": "+1",
            "number": "3129521352"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/y6q9vuZ5-2017-04-13%2007.58.53.jpg"
        },
        "inherit_from_parent": false,
        "score": 74
      },
      "role": "trainer",
      "activation_code": "44e254b6ba0d8c0db5499885c919739dfad945ad",
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496926497279
    },
    {
      "_id": {
        "$oid": "59388d21330bcd252b5c59bb"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:57:57.883Z"
      },
      "createdAt": 1496878369429,
      "surname": "Hamdi Hussein",
      "firstName": "Mido",
      "email": "mido_pt2011@yahoo.it",
      "providerId": "1667221304",
      "provider": "instagram",
      "username": "mido_hamdi_hussein",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1667221304.8640130.eac95c4ece8a417289708266f73011d5",
            "link": "https://www.instagram.com/mido.pt2011"
          },
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Functional Training",
            "Group Classes",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "ISSA - Certified Personal Fitness Trainer CFT 3"
          ],
          "locations": [
            {
              "geolocation": [
                8.825057600000036,
                45.82059890000001
              ],
              "city": "Varese",
              "postcode": "21100",
              "_id": {
                "$oid": "5a5ff115765eba094646f716"
              },
              "country": "Italy",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 40,
            "max": 60
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 50,
          "biography": "Certified personal fitness trainer- entry level at ISSA Italy. 2010 Master's degree in nutrition / supplementation and integration at ISSA Italy2003 - Degree in physical education - University of Alexandria 2003 ,Sports Massage Diploma at the faculty of education in gymnastics Alexandria 2008 Open Water Diver at PADI Europa .Professional Experience 2001-2003 massage therapist at the Village \"Grand Sharm\" Sharm el Sheikh; Instructor at gyms in Sharm Resort, Mexecana, Badawea, in Sharm el Sheikh. 2003-2004 safety officer safety at hotels, resorts, and clothing stores in Alexandria of Egypt and in Sharm el Sheikh; 2004-2005 Worker in precision engineering company \"Piotti Technique\" in Cardano al Campo (VA); 2005 - 2008 Security Officer, at \"Longoni Sport\" of Varese and Gallarate, at the \"Cisalfa\" of Milan; 2008-2009 - Instructor at \"Fitness First\" in Gallarate; - personal trainer at \"Mio club\"in Tradate club. -Dipente Vibram srl. Currently Personal fitness trainer at \"Fit Easy\" Jerago",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6WlC4Yf3-IMG_4040.JPG",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10203167686630241/",
          "yearsOfExp": 11,
          "phone": {
            "number": "3458497237",
            "code": "+39"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "EUR",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oqGVyILi_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "score": 66
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496984850430,
      "__v": 1
    },
    {
      "_id": {
        "$oid": "59393383330bcd252b5c7d3d"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:56:09.876Z"
      },
      "createdAt": 1496920963598,
      "email": "mike@origym.co.uk",
      "firstName": "Mike",
      "surname": "Wardle",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "f64875dba1778c2cff41f9aeb1e9d85d4c7fbdd2",
      "salt": "XjKs3YPig5Fu82xqAwjYlQ==",
      "hashedPassword": "+Y8yU1dPpL3dBDn5AHzBRxKj7KK4v4LOhWmRNnPBcQbUWib8c4Gmmmv0MpQgGiuYCvr2btG1TdsA4jEQtAAdvg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ntd5OZMF-15252746_10158025038465314_530271442812896767_o.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Bootcamp",
            "Circuit Training",
            "Functional Training",
            "Group Classes"
          ],
          "qualifications": [
            "Level 3: Master Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "locations": [
            {
              "geolocation": [
                -2.996538600000008,
                53.4456416
              ],
              "city": "Bootle",
              "postcode": "L20",
              "_id": {
                "$oid": "5a5ff0a9765eba094646f715"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 99,
            "max": 200
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4gXh2u4l-13653284_1717370411846274_7517306009868360786_o.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "origymliverpool",
          "strapLine1": "I am a fully qualified personal trainer operating out of his own gym outside of Liverpool. I am versatile and committed.",
          "biography": "My name is Mike Wardle and I am the head personal trainer of the Origym group. I aim to develop engaging but enjoyable sessions. I welcome anyone into the gym with open arms. \n\nThe Origym Group was formulated to give you the highest standard of both online fitness coaching and physical personal training for the lowest price the UK has to offer. By being so cost effective it enables you to reach your fitness goals without the financial burden that you can find elsewhere. Whether you want to train with an online personal trainer, virtual trainer or have face-to- face personal training sessions with a gym trainer within one of our fitness centres.\n \nOrigym maintains the highest quality for the most affordable price.\n \nEstablished in 2013 in coherence with our sister company Origym Centre of Excellence, The Origym Group is not just experts in online personal training and private fitness training, but also in fitness qualifications so when you decide to start moving towards your dream body, you know your trainer will have an extensive range of internationally recognised personal training certificates behind them.",
          "phone": {
            "number": "8000236539",
            "code": "+44"
          }
        },
        "inherit_from_parent": true,
        "score": 66,
        "parent": {
          "$oid": "591c1af19edeff7e92d93f6b"
        }
      },
      "active": false,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1496924951413,
      "username": "mike_wardle",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "59396d1e330bcd252b5c897e"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:55:05.178Z"
      },
      "createdAt": 1496935710992,
      "providerId": "1491112237619098",
      "provider": "facebook",
      "surname": "Taylor",
      "firstName": "Rich",
      "email": "richtaylorpt@aol.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1491112237619098/",
          "disciplines": [
            "Bodybuilding",
            "Nutrition",
            "Sports Massage",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 4: Cancer Rehabilitation",
            "Level 4 Sports Massage Therapy",
            "Level 3: Personal Trainer",
            "Level 3 Sports Massage Therapist",
            "Level 3: Exercise Referral Instructor",
            "Level 3 Improved Sports Performance",
            "Level 2: Gym Instructor",
            "BSc (hons) Sport",
            "CrossFit Level 1 Trainer",
            "Level 1 Olympic Lifting",
            "Level 1 Kettlebells",
            "Crossfit Strongman",
            "Health",
            "Exercise and Nutrition",
            "Level 1 Bulgarian Bag Training",
            "Level 1 Trigger Point Therapy",
            "Level 1 Dynamax Medicine Ball Coach",
            "Level 1 Sports Coach Leader Award",
            "Level 1 Play Work",
            "BTEC Sports Science"
          ],
          "locations": [
            {
              "geolocation": [
                -1.0872979000000669,
                53.95996510000001
              ],
              "city": "York",
              "postcode": "YO1",
              "_id": {
                "$oid": "5a5ff069765eba094646f713"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 25,
            "max": 40
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "yearsOfExp": null,
          "biography": "Rich Taylor has worked within the fitness industry since 2006. In this time he has worked with people of all abilities from professional athletes to those limited by age or have disability.Rich has a background in natural bodybuilding, competing in the BNBF Northern competition in 2012. He has competed in various strong man competitions, taking second place in the under 90kg Litchfield show of strength. The next challenge for Rich will be the Wild West CrossFit competition as well as the Northern under 90kg tested strongman competition.",
          "phone": {
            "number": "7814706264",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wk3o7BPV_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "score": 57
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "rich_taylor",
      "lastLogin": 1496935711217,
      "__v": 1
    },
    {
      "_id": {
        "$oid": "593a5519330bcd252b5cbb03"
      },
      "lastUpdated": 1497007471135,
      "createdAt": 1496995097874,
      "providerId": "424444081287801",
      "provider": "facebook",
      "surname": "Bbnb",
      "firstName": "Jess",
      "email": "enquiries@bikinibods.com.au",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/424444081287801/",
          "disciplines": [
            "Boxing",
            "Muay Thai",
            "Personal Training",
            "Self Defense",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Ladies Only Areas",
            "Punch Bags",
            "Kick / Body  Shields"
          ],
          "locations": [
            {
              "geolocation": [
                151.27040999999997,
                -33.7653
              ],
              "country": "Other",
              "city": "Brookvale",
              "postcode": "2100",
              "primary": true
            },
            {
              "geolocation": [
                151.20541000000003,
                -33.83965
              ],
              "country": "Other",
              "city": "North Sydney",
              "postcode": "2060"
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Bikini Bods",
          "companyWebsite": "https://bikinibods.com.au",
          "profileTitle": "Martial Arts Gym",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hwbcr30s-18451563_1445761492143049_5785956845621845514_o.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "BIKINIBODS",
          "yearsOfExp": 2008,
          "strapLine1": "Creating Beautiful Beasts",
          "biography": "Bikini Bods is about strengthening and empowering women through our unique fitness programs, our community and our amazing motivational coaches. We also offer amazing kick-ass Bikini Bods brand apparel and boxing equipment so you can train like a #BeautifulBeast anywhere in the world. \n\nWe welcome all women to join us regardless of age, fitness level or if they have never done boxing or kickboxing before. Everyone is welcomed, supported and encouraged at Bikini Bods. all our classes are suitable for beginners and allow you to go at your own pace as you feel your fitness and skill level improve. \n\nOur unique style of Muay Thai Kickboxing, Boxing, H.I.I.T + body weight circuit training has been specifically designed by us for women. It improves lean muscle, fat loss and cardiovascular endurance and results in amazing body transformations.",
          "offers": "Unlike other fitness programs, there is alPlease send us a Facebook message, email us at enquiries@bikinibods.com.au or check our website www.bikinibods.com.au. \n\nWe offer free 7-day trial passes and a money back guarantee. We know you will love our programs. \n\nWe are so excited to hear from you soon - come and see what all the fuss is about!ways something new to learn or to advance to when it comes to our style of training. We use various combinations of kickboxing, boxing and circuits to ensure our workouts are challenging and no class is ever the same!\n\nOur most popular training program offers you 6 training sessions per week for only $49 + GST. \n\nEven though we are strictly non-contact at Bikini Bods, we place great pride in always ensuring correct boxing and Muay Thai Kickboxing technique. We also ensure your general exercise technique and specialise in injury management and prevention. \n\nOur 4 amazing classes are Muay Thai Kickboxing, Muay Thai Conditioning, K.O Muay Thai and HIIT KB (Core + Abs + Booty). All four of these classes are challenging yet fun, have amazing variety and will never allow you to get bored or plateau. We also go on regular group training holidays to Thailand together where we train at a Muay Thai training camp! \n\nIf we sound like a place you would like to be a part of, we would love to have you!",
          "phone": {
            "code": "+61",
            "number": "498087867"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dMVlw9SH-filename.jpeg"
        },
        "inherit_from_parent": false,
        "score": 70
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "bikini_bods",
      "lastLogin": 1496995098301
    },
    {
      "_id": {
        "$oid": "593dc0e2330bcd252b5d75c5"
      },
      "lastUpdated": {
        "$date": "2017-11-21T16:40:43.748Z"
      },
      "createdAt": 1497219298216,
      "email": "kitmccartney@live.co.uk",
      "firstName": "Kit",
      "surname": "McCartney",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "gEDpRmEyEQNjLFIhmSAuJg==",
      "hashedPassword": "TULNufhDbggoeiTrXcZlUmxHp8RexSbR+VJWbExfZyqDhMdUxZwvb6zFNEyvp29p9w+1MbYjwJX5ynCNcOugwg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qPLnekmK-FRONT%20(1).jpg"
        },
        "personal": {
          "disciplines": [
            "Body Transformation",
            "Martial Arts",
            "Personal training",
            "Small Group Training",
            "Strength & Conditioning"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                0.024311799999964023,
                51.5796912
              ],
              "city": "London",
              "postcode": "E11 2AA",
              "_id": {
                "$oid": "5a14570b2288dd3a30f3f4c7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "KitFitness",
          "companyWebsite": "https://www.kitfitness.co.uk/",
          "profileTitle": "Fitness Trainer Group",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/huF85Kf3-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "4024123842.8640130.7196a18d657f4158be6db4145ea458fd",
            "link": "https://www.instagram.com/kitfitness.co.uk"
          },
          "facebookPage": "kitfitnesspt",
          "yearsOfExp": 2016,
          "affiliation": "REPs",
          "strapLine1": "Personal Training in East London tailored specifically to you. Specialising in Fat Burning, Lean Muscle Gain & Strength.",
          "biography": "Hi, welcome to KitFitness.\n \nKitFitness is a Personal Training company based in East London that strives to ensure every single one of our clients’ needs are met to the fullest degree. Whether our client wants to strip away some unwanted fat, pack on some lean muscle or feel fitter and stronger in everyday life, we push ourselves to be the best we can be.\n \nTraining, be it for ourselves or for our clients, is something all of us here at KitFitness thoroughly enjoys. Nothing gives us greater satisfaction than when we help a client reach their goals and achieve their desired results.\n \nSo if you want to lose weight, get bigger or simply want to look incredible on the beach, get in contact and start for FREE today!",
          "offers": "Please visit our website for details of prices, packages & offers.",
          "phone": {
            "number": "7793746787",
            "code": "+44"
          },
          "testimonials": ""
        },
        "inherit_from_parent": false,
        "score": 66
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1497456821039,
      "username": "kitfitness",
      "__v": 3
    },
    {
      "_id": {
        "$oid": "593e6dd8330bcd252b5d9ac8"
      },
      "lastUpdated": 1497438255396,
      "createdAt": 1497263576180,
      "email": "natacad@shoomon.xyz",
      "firstName": "National",
      "surname": "Academy Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "cfd1c449558b9d3d48133b00c06c47311bcf9461",
      "salt": "LHimwmVLsD7PlmtSd1+nYA==",
      "hashedPassword": "lMRJAUx8g9+6doZz2ON8rtFgtFM9hZPxnxA5gZ++OVdWOn27Y/MTO2mofZcPxLxjJ+WwbiZvp/n3ON6hwyf9FA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/h6g4JLYo-natacadoftpro.png"
        },
        "personal": {
          "disciplines": [
            "Gym Instructor Courses",
            "CPD Courses",
            "Level 2 Courses",
            "Level 3 Courses"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -1.843924799999968,
                52.42997419999999
              ],
              "country": "United Kingdom",
              "city": "Birmingham",
              "postcode": "B28 9HH",
              "primary": true
            },
            {
              "geolocation": [
                -1.1581085999999914,
                52.95478319999999
              ],
              "country": "United Kingdom",
              "city": "Nottingham",
              "postcode": "NG1 5AW",
              "note": "01157845663 / Nottingham@national-academy.co.uk"
            },
            {
              "geolocation": [
                -2.2426305000000184,
                53.4807593
              ],
              "country": "United Kingdom",
              "city": "Manchester",
              "postcode": "M2 4WU",
              "note": "01613028049 / Manchester@national-academy.co.uk"
            },
            {
              "geolocation": [
                -3.1790899999999738,
                51.48158100000001
              ],
              "country": "United Kingdom",
              "city": "Cardiff",
              "postcode": "CF10",
              "note": "02922401033 / Cardiff@national-academy.co.uk"
            },
            {
              "geolocation": [
                -1.6177800000000389,
                54.978252
              ],
              "country": "United Kingdom",
              "city": "Newcastle upon Tyne",
              "postcode": "NE1 4LF",
              "note": "01914906964 / Newcastle@national-academy.co.uk"
            },
            {
              "geolocation": [
                -1.8904009999999971,
                52.48624299999999
              ],
              "country": "United Kingdom",
              "city": "Birmingham",
              "postcode": "B4 7DA",
              "note": "01212965823 / Birmingham@national-academy.co.uk"
            },
            {
              "geolocation": [
                -2.9915726000000404,
                53.4083714
              ],
              "country": "United Kingdom",
              "city": "Liverpool",
              "postcode": "L2 2DP",
              "note": "01513636800 / Liverpool@national-academy.co.uk"
            },
            {
              "geolocation": [
                -1.5490773999999874,
                53.8007554
              ],
              "country": "United Kingdom",
              "city": "Leeds",
              "postcode": "LS1 3DA",
              "note": "01134576710 / Leeds@national-academy.co.uk"
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "National Academy of Training",
          "companyWebsite": "http://national-academy.co.uk/",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IQ246Ei5-natacadoftbg.png",
            "size": {},
            "offset": 0
          },
          "facebook": null,
          "facebookPage": "NAofTraining/",
          "strapLine1": "Become a Personal Trainer - get certified and work anywhere with an INTERNATIONALLY RECOGNISED QUALIFICATION",
          "biography": "National Academy is not just another training provider delivering Personal Training Qualifications. Our personal trainer academy was formed due to the increase in the number of personal trainers entering the market having just qualified with what seemed very little industry knowledge or confidence.\n\nNational Academy is dedicated to qualifying personal trainers to exceed the expectations of all our corporate partners that is why we only employ experienced tutors who themselves have been personal trainers and can truly prepare graduates for industry. We have come across so many students that have a story to tell of their training provider and we wanted to keep ensuring that graduates from National Academy are only ever success stories.\n\nNational Academy has gone from strength to strength and we now have dedicated tutors located within all the major cities. We pride ourselves on the relationships we build with our corporate partners and the learners that come through our academy.\n\nAt National Academy we don’t believe in the traditional Six Week intensive Personal Trainer Course. Instead, we make bespoke blended-learning programmes to ensure flexibility around our students’ lifestyles and learning needs. We are competitively priced without compromising on support.\n\nAll our Personal Trainer Qualifications are nationally accredited through Active IQ and are fully recognised by the Register of Exercise Professionals (REPs) and employers.",
          "offers": "JUST STARTING YOUR PERSONAL TRAINING CAREER?\n\nIf you’re thinking of getting into the industry, start the right way with one of our accredited certificates or diplomas. Not only will you get properly qualified, but you’ll also benefit from our interview placement once you complete your course.\n\nActive IQ Level 2 Certificate In Fitness\nActive IQ Level 3 Diploma In Fitness Instructing and Personal Training instructing\nActive IQ Level 3 Certificate In Personal Training\nActive IQ Level 3 Diploma In Personal Training And Business Skills For Fitness Professionals\n\nALREADY QUALIFIED? PROGRESS YOUR CAREER…\n\nIf you’re ready to push your personal training career to the next level and offer more to your clients choose one of our advanced courses.\n\nActive IQ Level 3 Diploma In Exercise Referral\nActive IQ Level 3 Award in Assessing Vocationally Related Achievement\nActive IQ Level 3 Award in Understanding the Principles and Practices of Assessment\nActive IQ Level 3 Award In Business Skills For Fitness Professionals\n\nSPECIALISE\n\nReady to really get focused? Capitalise on the current fitness treads, become a specialist and start growing your client base\n\nActive IQ Level 2 Award In Instructing Circuit Sessions\nActive IQ Level 2 Award In Instructing Kettlebells\nActive IQ Level 2 Award In Instructing Studio Cycling\nActive IQ Level 3 Award in Nutrition for Physical Activity",
          "places": "Birmingham: 01212965823 / Birmingham@national-academy.co.uk\nLiverpool: 01513636800 / Liverpool@national-academy.co.uk\nManchester: 01613028049 / Manchester@national-academy.co.uk\nNottingham: 01157845663 / Nottingham@national-academy.co.uk\nLeeds: 01134576710 / Leeds@national-academy.co.uk\nCardiff: 02922401033 / Cardiff@national-academy.co.uk\nNewcastle: 01914906964 / Newcastle@national-academy.co.uk",
          "testimonials": "Diploma in Fitness Instructing & Personal Training - Janine Farrow\n\n“Mom of three young children and fitness had always played a major role in my life. I was fortunate to have my course funded through the Advanced Learner Loan. Blended learning enabled me to still be a full time mom although at times was challenging especially when my little poorly was struck with a rare illness but with the flexibility and support of National Academy I completed my Qualification. I am now working part time within my local gym as a PT -  January 2017\n\nLevel 2 Certificate in Fitness Instructing - Paul Lowe\n\n“It has been a hard yet fun week and I want to thank you all for your help and support. I am glad to be a part of your student community“ December 2016 – Somerset\n\nDiploma In Fitness Instructing - Paige Chakki\n\n“I would definitely recommend National Academy and the Diploma in Fitness Instructing & Personal Training Course. The staff were very friendly & my tutor extremely knowledgeable. \nI thought the online learning modules were great and really helped me with my course. Liked the fact that I was also given a hard copy manual as well“\n\nLevel 2 Award in Kettlebels - Kai Hayes\n\n“I found the workshop well organised and I really enjoyed the practical elements of the course. I would definitely recommend“\n\nDiploma in Personal Training & Business Skills - Lois Morris\n\n“Faultless from start to finish – the course wasn’t easy! But well worth it. It’s opened up so many opportunities“",
          "phone": {
            "code": "+44",
            "number": "3301242621"
          }
        },
        "inherit_from_parent": false,
        "score": 68
      },
      "active": false,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1497263639296,
      "username": "national_academy_of_training"
    },
    {
      "_id": {
        "$oid": "593e89ba330bcd252b5da0ca"
      },
      "lastUpdated": 1497274448664,
      "createdAt": 1497270714362,
      "email": "beabetter@shoomon.xyz",
      "firstName": "Be A Better",
      "surname": "You Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "39578d9a800636e2dac90e8114524b33f26dac0e",
      "salt": "GxHt0kJ2hATbU1FO1xUFZw==",
      "hashedPassword": "vBIQ9EIqXVFWQL9X+aI3TYYdc/7JGfOlLP+aLItokn/CzFDlxp9tTGpTBUjfuvNBItU7+A403vfXbeR9flfN9g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Xo7WFxO6-beabeooerlogo.jpg"
        },
        "personal": {
          "disciplines": [
            "CPD Courses",
            "Level 2 Courses",
            "Level 3 Courses",
            "Level 4 Courses",
            "Online Courses"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -0.024573599999939688,
                51.4961107
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "E14",
              "primary": true
            },
            {
              "geolocation": [
                -1.8904009999999971,
                52.48624299999999
              ],
              "country": "United Kingdom",
              "city": "Birmingham",
              "postcode": "B4 7DA"
            },
            {
              "geolocation": [
                -0.12775829999998223,
                51.5073509
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "WC2N 5DU"
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Be A Better You +",
          "companyWebsite": "https://www.beabetteryou.com/",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HAj5IAFh-beabetterbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "BeABetterYouCourses/",
          "yearsOfExp": 2009,
          "offers": "REPs Level 3 Personal Trainer Certificate Course Face-to-Face\nREPs Level 3 Personal Trainer Certificate Course Online\nREPs Level 3 Exercise Referral Course Face-to-Face\nREPs Level 4 Obesity and Diabetes Course Face-to-Face\nREPs Level 3 Sports Massage Diploma Face-to-Face\nREPs Level 3 Personal Trainer Diploma Course Face-to-Face\nREPs Level 3 Personal Trainer Diploma Course Online\nREPs Level 2 Gym Fitness Instructor Certificate ourse\nFace-to-Face\nReps Level 3 Fast track Personal Trainer Qualification Online \nLevel 3 Certificate in Diet & Nutrition (RQF) - Distance Learning\nLevel 3 Sports Injuries Certificate of Achievement Online Course",
          "strapLine1": "ONE OF THE UK’S LEADING ADULT COURSES TRAINING PROVIDERS - ARE YOU LOOKING TO STUDY IN ORDER TO START A NEW CAREER?",
          "biography": "WE ARE ONE OF THE UK’S LEADING ADULT COURSES TRAINING PROVIDERS - ARE YOU LOOKING TO STUDY IN ORDER TO START A NEW CAREER?\n\nWe might just have the right course for you.\nWe are very well known for our Personal Training courses. We are a specialist Courses Training Provider. We have been training and placing successful Personal Trainers with our partner Gyms since 2009. So if you want to be a fully REPS qualified Personal Trainer you have come to the right place.\nAs our reputation kept growing for being known for delivering great quality training, we kept being asked if we offered other courses.  As a direct result of listening to your requests, we started offering other courses, in order to help you with your career goals.\nYou might also like to know that we offer:\nLevel 3 Sports Massage\nLevel 3 Exercise Referral\nLevel 4 Obesity and Diabetes\nAnd many more to come …\nSo, if you are ready to start your training now, we are here waiting to serve you.  If cost is a barrier for you to start then we have good news. If you are a UK resident aged 19 or over, Be A Better You in partnership with 19+ Advanced Learner Loan, a government incentive scheme, might be able to fund your chosen course. Basically, this will allow you to study now and pay later. Check if you qualify. Limited places available.\nWe also have other flexible payment options available.  Call us to find out more.",
          "places": "Birmingham\nLondon",
          "testimonials": "'' This Personal Trainer course allowed me to extend existing practical skills of gym sessions utilisation for adults. I have learnt much about training approaches and its importance in relation to a specific fitness goal (weight locc, muscle gain, etc). I highly recommend this course to everyone with basic knowledge of the fitness training.''\n\nAnastasia Shedvko - Blended Learning Level 3 Personal Trainer Course.\n\n'' I picked Be A Better You over other companies because of the cost of the course and location. John Fotiadis was very helpful throughout the course and took the time to explain the topics clearly. The facilities at the gym are also excellent and provide a great place to learn.''\n\nMichael McCleary - Level 3 Personal Trainer Diploma course.",
          "phone": {
            "code": "+44",
            "number": "2038200497"
          }
        },
        "inherit_from_parent": false,
        "score": 69
      },
      "active": false,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1497270714584,
      "username": "be_a_better_you_+"
    },
    {
      "_id": {
        "$oid": "593e98eb330bcd252b5da40c"
      },
      "lastUpdated": 1497279568776,
      "createdAt": 1497274603497,
      "email": "millwood@shoomon.xyz",
      "firstName": "Millwood PT",
      "surname": "Academy Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "a5dwU0gzrU0gslRBWWES6g==",
      "hashedPassword": "hHRI6hfPSH6jzTsfkKSpnwjAytkh1ZGKG+kyXbpAnMSPnh0Q35FeUSR3cKRzff0FGDKJeLTGeri9UXoTctNmpA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9h2OJDbW-millwoodpro.jpg"
        },
        "personal": {
          "disciplines": [
            "0% Finance",
            "CPD Courses",
            "Level 2 Courses",
            "Level 3 Courses",
            "Level 4 Courses"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -2.888641399999983,
                53.35242470000001
              ],
              "country": "United Kingdom",
              "city": "Garston",
              "postcode": "L19 2NY",
              "primary": true
            },
            {
              "geolocation": [
                -2.888641399999983,
                53.35242470000001
              ],
              "country": "United Kingdom",
              "city": "Garston",
              "postcode": "L19 2NY",
              "note": "0800 124 4106 / info@millwoodtraining.co.uk"
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Millwood Personal Trainer Academy",
          "companyWebsite": "http://www.millwoodptacademy.co.uk/",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tYpHDs3Y-millwoodbg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "millwoodptacademy",
          "affiliation": "Active IQ",
          "strapLine1": "The Personal Trainer Academy specialising in delivering Fitness Qualifications that fit around you",
          "biography": "Millwood Personal Trainer Academy specialise in delivering fitness qualifications that fit around you.  If you’re looking to upskill, become your own boss, boost a business you already have, or change careers by becoming employable in the fitness industry, Millwood Personal Trainer Academy is the answer!\n\nCombining E-Learning  with ongoing tutor support, top quality course learning materials, a vibrant social media community, and a drop in ‘learning zone’, Millwood’s supportive, flexible study and assessments will help you to achieve your goals,  around your current commitments.\n\nWe offer various finance options to make your qualification affordable. Contact our team today to discuss the options available and to see if you can combine courses and get a discount.",
          "offers": "Level 2 Award in Instructing Studio Cycling\nLevel 2 Award In Instructing Suspended Movement Training\nLevel 2 Award in Understanding Nutrition, Performance and Healthy Eating\nLevel 2 Award in Instructing Suspended Movement Training\nLevel 2 Certificate in Fitness Instructing – Gym or Exercise to Music\nLevel 2 Award in Kettlebells\nLevel 2 Award in Instructing Circuits\nLevel 2 Emergency First Aid at Work (1 day)\nLevel 2 Exercise to Music Conversion\nLevel 3 Award in Applying Hot and Cold Techniques in Sport and Active Leisure\nLevel 3 Award in Tapping and Strapping for Sport and Active Leisure\nLevel 3 Award in Assessing Vocationally Related Achievement\nLevel 3 Award in Education and Training\nLevel 3 Diploma in Fitness Instructing and Personal Training\nLevel 3 Diploma in Sports Massage Therapy\nLevel 3 Diploma in Exercise Referral\nLevel 3 Certificate in Personal Training\nLevel 3 Award in Nutrition for Sport & Exercise Performance\nLevel 3 Award in Instructing Outdoor Fitness\nLevel 4 Certificate in Sports Massage Therapy\n7 Wonders of Fitness",
          "places": "Liverpool: 0800 124 4106 /  info@millwoodtraining.co.uk",
          "testimonials": "I would like to thank everyone at Millwood for believing in me, encouraging, supporting and new friendships - In my opinion, a fantastic company to learn with!\n\nTracey Geraghty\n\nI heard of Millwood's Sports Massage Therapy Diploma through a friend and it ticked all the boxes - flexible studt, lots of practical sessions, experienced tutors and all covered by the Advanced Learning Loan.\n\nMartin Hewitt\n\nI have never known a company in my life who are so welcoming, supportive, encouraging, approachable and professional. Every member of staff makes you feel that what you are saying or asking is important no matter what!\n\nVikki Draper",
          "phone": {
            "code": "+44",
            "number": "8001244106"
          }
        },
        "inherit_from_parent": false,
        "score": 69
      },
      "active": true,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1497274603702,
      "username": "millwood_personal_trainer_academy"
    },
    {
      "_id": {
        "$oid": "593eafd0330bcd252b5da8ce"
      },
      "lastUpdated": 1497441002609,
      "createdAt": 1497280464519,
      "email": "bodycore@shoomon.xyz",
      "firstName": "Bodycore",
      "surname": "Training Information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "UkMMVyxJjFCT0sQ0XHMncA==",
      "hashedPassword": "0dGoTvMN1OuteKBpazN4GozehD+JbLVA2slSTAQMzowytrgcyVSX2IjtRejnyh+XyRtPgy2tYLoywnWYD7q9Ug==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qBzRSDWl-bodycorepro.jpg"
        },
        "personal": {
          "disciplines": [
            "Gym Instructor Courses",
            "CPD Courses",
            "Level 2 Courses",
            "Level 3 Courses",
            "Level 4 Courses"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -4.279991500000051,
                55.8598906
              ],
              "country": "United Kingdom",
              "city": "Glasgow",
              "postcode": "G3 8HB",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Bodycore Training",
          "companyWebsite": "https://www.bodycoretraining.com/",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wSG6G75A-bodycorebg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "bodycoretraining/",
          "affiliation": "CIMSPA, REPS, OFQUAL, ACTIVE IQ",
          "biography": "Welcome to BODYCORE!\n\nI created BODYCORE out of a passion for people and Mentoring them toward their Personal training certifications, success, health, happiness and ultimately a better life!\n\nAfter 20 years of Personal Training and life coaching all over the world, I have come to this one irrefutable fact about life:\n\n“Accountability is the difference between success and failure”.\n\nAccountability to a PT MENTOR ensures your long term development and commitment to attainment of your desires and goals!\n\nA PT MENTOR is simply someone who’s actions inspire and motivate others to dream more, learn more, do more and ultimately BECOME more.\n\nPT MENTORS are the personal trainers that we see in our society DOING and BEING, ‘that’ which we are wanting to be, do or have.\n\nSetting Specific, Measurable, Action-oriented, Resource-full and Time bound goals, with a PT MENTOR whom your investing in, ensures accountability, and will give you the edge over your competition.\n\nPT MENTORS are the inspirational and motivational people in our lives.\n\nThey are the ATHLETES, ENTREPRENEURS and PERSONAL TRAINERS running successful personal training and fitness related businesses!\n\nThey THINK, EAT and ACT in accordance with their SMART goals and emulate the visionaries of their industry.\nPT MENTORS, are purposed to lead and guide, motivate and inspire, set SMART goals, reach targets and to hold our trainees accountable for the results they are getting.\n\nHealthily yours,\nTobias Moir,\nManaging director",
          "places": "UK Wide",
          "testimonials": "Had an excellent experience going through my level 2 and 3 with the guys from Bodycore.\nDarren was a superb mentor and went above and beyond to help me get through the course and helped with study sessions around my already hectic lifestyle.\nI would highly recommend them to anyone looking to get through their PT qualifications.\n\nGORDIE ADAM\n\nThanks you Bodycore for you excellent training to become a qualified PT. Tobias and Rosie are great people and instructors. Highly recommend for anyone wanting to qualify to be a personal trainer. Much Appreciation thank you.\n\nSTEPHEN MONSTER SIMMONS \n\nHad Darren Marr as a mentor for Level 2 Gym Instructor Course just there. Phenomenal teacher! Made it all so seem so easy. Enjoyed every minute of it.\n\nDUNCAN IVOR ANDERSON\n\nRecently completed lvl2 & PT lvl 3, courses with Bodycore team are seriously amazing! Always had fun on lessons. It’s NOT only like ‘pay, have certificates and go’ they’re taking care of you, helping to get a job, helping to create a good bussines etc. I’m very happy that I could make the courses with them!\n\nROBERT GRUCELA\n\nExcellent Team,I`ve really learnt a lot through the course.Bodycore will take you to be one of the best PT in the market simply because they work in an unique way.Highly recommend!\n\nANDREIY NERO\n\nI highly recommend Bodycore – professional service – great mentoring – given me the tools I need to succeed.\n\nSPENCER PEEK",
          "offers": "ACTIVE IQ LEVEL 2 GYM INSTRUCTOR COURSE\nACTIVE IQ LEVEL 3 PERSONAL TRAINING COURSE\nACTIVE IQ GYM & PERSONAL TRAINER COMBI COURSE\nEMERGENCY FIRST AID AT WORK COURSE\nLEVEL 2 INDOOR STUDIO CYCLING\nLEVEL 2 KETTLEBELL TEACHER TRAINING  COURSE\nADVANCED CPD COURSES",
          "strapLine1": "Become A Successful Personal Trainer",
          "google": "https://plus.google.com/115945049033036313902",
          "phone": {
            "code": "+44",
            "number": "8002465712"
          }
        },
        "inherit_from_parent": false,
        "score": 72
      },
      "active": true,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1497436302503,
      "username": "bodycore_training"
    },
    {
      "_id": {
        "$oid": "593ec1f2330bcd252b5dacd0"
      },
      "lastUpdated": 1497289097257,
      "createdAt": 1497285106739,
      "email": "advancedcoaching@shoomon.xyz",
      "firstName": "Advanced",
      "surname": "Coaching information",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "67ad987c0004c5d9063b664cd60ec36851b9f2bc",
      "salt": "bsjB+al6wLhqrA7rkZIVHw==",
      "hashedPassword": "FnSxGAY4wNOSrCDwgrEnd/dmrnPiZmtWs/pwG7VWqcaTXoBPGxREqbTxRvgDsNXr5xJKyX3OXR6YP4/uz7jOQg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RMBjpmiC-acalogo.png"
        },
        "personal": {
          "disciplines": [
            "Online Courses"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                0.024968899999976202,
                51.4124601
              ],
              "country": "United Kingdom",
              "city": "Bromley",
              "postcode": "BR1 3JE",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Advanced Coaching Academy",
          "companyWebsite": "https://www.advancedcoachingacademy.com/",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VTSgFHlu-acabg.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "PhilLearney/",
          "affiliation": "CIMPSA, Active IQ, AFN",
          "strapLine1": "TRANSFORM YOUR PERSONAL TRAINING BUSINESS NOW",
          "biography": "The Advanced Coaching Academy is an accredited online educational platform designed for health and fitness professionals around the world.\n\nThe Advanced Coaching Academy is split into three modular courses: \n\nBUSINESS ACUMEN,\nAPPLIED NUTRITION\nADVANCED COACHING\n\nall of which are designed be studied in your own time, simultaneously or as individual courses.\n\nContent is delivered weekly and designed to give coaches the theoretical and practical knowledge required to operate highly successful fitness businesses.\n\nJoin over 4000 individuals, small businesses and leading brands that have chosen the Advanced Coaching Academy for their education.\n\nThe ACA content is designed and delivered around the needs of the modern day coach and more importantly the people their businesses serve.\n\nThe content is sourced and compiled from the best available systematic and research evidence. It has then been through the process of national accreditation, endorsement and recognition through Active IQ and the Chartered Institute for the management of sport and physical activity (CIMSPA).\n\nThe Applied Nutrition and Supplementation certification, an integrated part of the applied nutrition module has also been externally scrutinised and accredited by the Association for Nutrition (AfN)",
          "offers": "BUSINESS ACUMEN:\n\nThe Coaching Academy includes an extensive business course covering all aspects of operating a health and fitness business in order to help coaches develop long term, sustainable and profitable business models.\n\nThe Business Acumen course is designed to improve an individual's understanding of their own business, how to enhance it’s moving parts, scale it beyond its core offering and create a vehicle for improving its overall financial performance.\n\nAPPLIED NUTRITION:\n\nThe Applied Nutrition module is packed with evidence- based modular content covering all aspects of applied nutrition, performance nutrition and weight management.\n\nThe core content is externally scrutinised and accredited by Active IQ and CIMSPA and the 16 week integrated Applied Nutrition and\nSupplementation Certification is certified by the AfN (Association for Nutrition) and is one of only a few AfN certified courses in the leisure\nindustry.\n\nADVANCED COACHING:\n\nThe Advanced Coaching course breaks down all of the elements required for a coach to be delivering effective, sustainable and results based programs.\n\nThe course addresses human anatomy, biomechanics, human physiology, energetics and brings it all together within the training theory and\npractice modules.",
          "places": "Global Coverage - Online Courses",
          "testimonials": "I’ve 2 degrees and quite a few courses done over the years and I can safely say this was the best investment I’ve ever made. You have created something very special.\n\nMatt Cooney:  McFuture Fitness\n\nStarted this a couple of weeks back and the content is spot on! Look forward to new things every week with mini quizzes to check I’m taking it all in. Amazing value from a genuinely great bloke that is obviously in love with the fitness industry!\n\nDarren Brook: Darren Brook Personal Training\n\nThe content is amazing and really “real world” focused without over analysing the little things that don’t matter. All backed by research, however, very layman in terms of how to absorb the information.\n\nPhil Learney has a way of getting complicated information across in an easy way to learn, highly recommending this to anyone interested.\n\nFabio Bonnano:  Fabio Bonnano Coaching\n\nFrom someone new to the industry the ACA was fundamental in my development. Learning how to build a business and studying the psychology\nbehind human behaviour helped me understand my clients more, and avoid the potential pitfalls which lay ahead. This course is a must for any PT, and I can\nguarantee the business section alone will transform your business dramatically.\n\nSign up now, or it’s another day wasted.\n\nGraham Hurst: H Fitness\n\nI can’t recommend the ACA highly enough, I’ve seen training courses out there charging absolutely ridiculous money with poor quality content.\n\nKeith Homan: The Fitness Lifestyle Coach",
          "phone": {
            "code": "+44",
            "number": "7966164489"
          }
        },
        "inherit_from_parent": false,
        "score": 69
      },
      "active": false,
      "approved": false,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1497285106935,
      "username": "advanced_coaching_academy"
    },
    {
      "_id": {
        "$oid": "593ef2f8330bcd252b5db751"
      },
      "lastUpdated": 1497363695352,
      "createdAt": 1497297656660,
      "providerId": "10213183423680836",
      "provider": "facebook",
      "surname": "O'Connell",
      "firstName": "Michael",
      "email": "mickboxer@hotmail.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10213183423680836/",
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Hiit",
            "Mobile Trainer",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Nutrition",
            "Level 3: Personal Trainer",
            "Level 3: Master Personal Trainer",
            "Level 3: Advanced Personal Trainer"
          ],
          "locations": [
            {
              "geolocation": [
                -5.862959099999999,
                54.3337892
              ],
              "country": "United Kingdom",
              "city": "County Down",
              "postcode": "BT24 8NU",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 45
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tRFocFC0-Banner.1.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2015,
          "affiliation": "REP's / NRPT",
          "biography": "My name is Mick and I am a fully qualified and experienced personal trainer who prioritises your personal needs when it comes to your health and fitness. Based in Ballynahinch, County Down, I am a mobile fitness coach with a well-equipped studio in my own home to offer you a flexible service. I have gained a wealth of experience from being a top ranked Heavyweight Boxer on the international boxing circuit for more than 15 years. \nBacked by more than 20 years in the industry and working with a vast array of clients, I receive numerous fantastic reviews and also offer group sessions for those who are more self-conscious. Certified with the NRPT (National Register of Personal Trainers), I also have the skills and expertise to work with clients who have suffered an injury or have limited mobility skills.",
          "strapLine1": "ELITE PERSONAL TRAINER WITH LEVEL 3 REPS",
          "offers": "6 WEEK PACKAGES: First time = 30% Discount\n\n1 x Session per week = £168 (Save £72)\n2 x Sessions per week = £315 (Save £135)\n3 x Sessions per week = £441 (Save £189)\n\nThere is also ongoing \"Value for money\" Personal Training",
          "twitter": {
            "screen_name": "mickboxer",
            "link": "https://twitter.com/mickboxer",
            "user_id": "93825164",
            "accessToken": "93825164-Yes1tD1aTR58ilQTCUfrkFR1CWueT7ipE5lgHq9mg",
            "accessTokenSecret": "1Vp6gzjsfTuJNrNNJNMeGQAsiAHNvrlTpMcFznxNzVxHW"
          },
          "instagram": {
            "access_token": "3656270237.8640130.9a2c1ca4005048c8bf4a8b5e635792fb",
            "link": "https://www.instagram.com/oconnell7052"
          },
          "facebookPage": "hammerfitnessni",
          "phone": {
            "code": "+44",
            "number": "7939682457"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HsTHrfXe-Castlewellan.1.jpg"
        },
        "inherit_from_parent": false,
        "score": 87
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "michael_o'connell",
      "lastLogin": 1497297656873
    },
    {
      "_id": {
        "$oid": "5941b1c2330bcd252b5e4d24"
      },
      "lastUpdated": 1497704332955,
      "createdAt": 1497477570749,
      "providerId": "1234150623349287",
      "provider": "facebook",
      "surname": "Corlos",
      "firstName": "Gav",
      "email": "streetgymuk@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1234150623349287/",
          "disciplines": [
            "Circuit Training",
            "Endurance",
            "Hiit",
            "Mobile Trainer",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Advanced Personal Trainer",
            "IKFF Certified Kettlebell Trainer (Level 1 & 2)"
          ],
          "locations": [
            {
              "geolocation": [
                -3.2038645999999744,
                55.95569
              ],
              "country": "United Kingdom",
              "city": "Edinburgh",
              "postcode": "EH3",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 45
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JMpLJOpq-268444_104922986272062_751440_n.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "StreetGymUK",
            "link": "https://twitter.com/StreetGymUK",
            "user_id": "860081834297872385",
            "accessToken": "860081834297872385-1DjnGyvIEOtY6LmqtoBsi6bRvQIKrsT",
            "accessTokenSecret": "U3cFEI0h0wJ1xDi0KILAm2QPoiiFIe2UYk5fq5cUOtA8t"
          },
          "instagram": {
            "access_token": "5546169216.8640130.eb640b341840476fb57236af034593f8",
            "link": "https://www.instagram.com/streetgympro"
          },
          "google": "https://plus.google.com/113034084471475595465",
          "yearsOfExp": 2008,
          "affiliation": "Premier",
          "strapLine1": "Play Hard? Train Hard! Rest Easy.",
          "biography": "SGUK is an outdoor specific fitness training company, but also trains clients at home and at work; the main drive is to offer fun, effective fitness in an environment that suits the individual. \n\nTraining plans and sessions are tailored to the individual,  designed to progressively reach their goals not just build them up and also creating a fitness structure to fit their lifestyle.\n\nPersonal trainer Gavin Corlos-Moreton is an ex-Military PT: he's run a successful Personal Training business since 2008 and worked with clients, at home, outside, in studios and in gyms working with all types from beginner to advanced. He started one of the first Bootcamps in Edinburgh, and later went on to create a unique training concept: StreetGym UK (street based fitness training) for all fitness types looking for something completely different, focusing especially on those involved in contact sports such as MMA, boxing, rugby, stunt performance and other contact based activities. \n\nSince his career, he's trained people for Tough Guy, Tough Mudder, Deer Stalker, Marathons, Police and Army entry and even North Pole expeditions. He now trains predominantly in calisthenics using the City streets, his skills over the years have seen him organizing team building bootcamps, setting up Tourist Running Tours and even designing Team building events for ocean rowers and companies.",
          "offers": "Prices 2017/18\n \n \nPay as you train: £45 per session\n \n4 PT sessions: £160 - £40 per session\n \n6 PT sessions: £210 - £35 per session\n \n10 PT sessions: £330 - £33 per session\n \n12 PT sessions: £360 - £30 per session\n \nReduced Rate Packages - 3-6 month commitment required.\n \n6 sessions per month - £30ph \n(3 month commitment) £540 to pay.\n \n8 sessions per month - £25ph \n(4 month commitment) £800 to pay.\n \n12 sessions  per month - £22.50ph \n(5 month commitment) £1350 to pay.\n \n16 sessions per month - £20ph\n(6 month commitment) £1920 to pay.",
          "phone": {
            "code": "+44",
            "number": "7719744630"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/V78Mhwph-8196_391810224250002_1587599757_n.jpg"
        },
        "inherit_from_parent": false,
        "score": 84
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "gav_corlos",
      "lastLogin": 1497703599390
    },
    {
      "_id": {
        "$oid": "594277fa330bcd252b5e77a6"
      },
      "lastUpdated": {
        "$date": "2017-08-01T17:18:08.827Z"
      },
      "createdAt": 1497528314868,
      "email": "iris@notjustr.com",
      "firstName": "No Targets",
      "surname": "Just Routine Info",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "c7px7AoY4j3ZXYqwitkPew==",
      "hashedPassword": "C2ziUdtXK6DZKuIojj+ZmKhoKA+rsjqDa+Kt/GGAj93eXbyS7vDPN2KoAc/+Vo2pNDjIIbpOzUpo9776PjV0bw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zuN8jkH2-notargetslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "Dietary Analysis",
            "Recipes",
            "Sports Nutrition",
            "Stress & Fatigue",
            "Weight Management"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -0.3052254000000403,
                51.40173859999999
              ],
              "city": "Kingston upon Thames",
              "postcode": "KT1 2JW",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "No Targets Just Routine",
          "companyWebsite": "http://www.notjustr.com/",
          "profileTitle": "Diet & Nutrition",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5YrGE4HF-notargetsbg7.jpg",
            "size": {},
            "offset": 0
          },
          "affiliation": "PMA",
          "yearsOfExp": 2013,
          "strapLine1": "A healthy lifestyle has no targets just routine",
          "biography": "Welcome to the junction of food, exercise & rest for better health.\nWe simplify this complex world to explain how to adopt a healthier lifestyle.\n\nThe No Targets Trinity\n\n#1 Eat Smart\n\nEat real foods. Supersize on fruit and vegetables. Include a generous amount of fibre, legumes and healthy fats in your routine nutrition as well as a daily probiotic. Switch to 100% whole grain carbs. Keep protein lean and eat red meat in moderation. Take the time to read labels on all processed food, but avoid if possible. Eat real foods instead. It can be fun to cook so try to make it part of your routine. Don’t be afraid to allow yourself a small amount of food you crave – more than a taste, but less than a portion.\n\n#2 Exercise Daily\n\nExercise regularly. Exercise is the silver bullet for a better quality of life. To be fit and healthy you need to move. There is no right or wrong type of exercise and no right or wrong time of day or time of life for exercise. Make daily exercise non-negotiable and alternate between exercises each time you work out. Lift weights at least twice a week to develop better control of your body. \n\n#3 Sleep Sound\n\nGet a good night’s sleep. Sleep is vital for health and should never be overlooked in a healthy lifestyle. Daytime exercise and bright light exposure will promote a regular circadian rhythm for melatonin production, an important hormone necessary for a good night’s sleep and the only known antioxidant in nature that can protect all parts of a cell.",
          "facebookPage": "NoTargetsJustRoutine/",
          "google": "",
          "offers": "Just Routine Premium is an in-app subscription that is only £1.99 per month, or £19.99 per year. Enjoy a one month free trial.\n\nIt's all about what you eat\n\nSee how your food stacks up on a daily and weekly basis as you track current nutrition against routine nutrition. Every small change in your eating routine can, over time, have a big impact on your health.\n\t\nA new way of looking at food\n\nTrack your food with quick and easy access to foods you eat regularly and those you love to eat. Add food with a simple swipe. See food in a new light, the rainbow colours of real food, the amber and red of processed treats and cheats.\n\nFocused on Real Food for better health\n\nThis system is for anyone concerned about what they eat and keen to cut down on processed foods. Use the ‘Bodyswipe’ to see how your rainbow profile improves from one day to the next.",
          "testimonials": "Sam Allen reviewed Just Routine — 5 star: March 30\n\nThis app is fantastic, it's great to have a health and food app that focusses on nutrition rather than calories. Easy to use, and I love the recipes!\n\nChris Longden reviewed Just Routine — 5 star March 27. \n\nExcellent application. Very easy to use and a friendly, fast search interface. I've been logging my food for a little while, and I'm ready to progress to the next stage by trying my hand at some of the great looking recipes. It takes some time to get used to portion sizes and granular logging, but it's made easier by the ability to create your own meal's, so re-entry is never an issue. The human body visualisation of your consumption of the main food groups is elegant and the ability to look at this using an aggregate view over weeks and months makes visualising your long term progress really easy. Great job!\n\nKim MacMillan Pechter reviewed Just Routine — 5 star",
          "phone": {
            "code": "+44",
            "number": "7000000000"
          }
        },
        "inherit_from_parent": false,
        "score": 69
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "gyms",
      "lastLogin": 1499693337749,
      "username": "no_targets_just_routine",
      "password_reset_time": 1499692646764,
      "password_reset_code": "X",
      "agent": {
        "family": "Chrome",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.115 Safari/537.36"
      },
      "__v": 5
    },
    {
      "_id": {
        "$oid": "59441065330bcd252b5ecea9"
      },
      "lastUpdated": 1497632869437,
      "createdAt": 1497632869226,
      "providerId": "120020575256264",
      "provider": "facebook",
      "surname": "Calvin",
      "firstName": "Jon",
      "email": "bfs8675@outlook.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/120020575256264/",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/068bGzlo_profilePicture.jpeg"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "jon_calvin",
      "lastLogin": 1497632871806
    },
    {
      "_id": {
        "$oid": "5947a048330bcd252b5f912c"
      },
      "lastUpdated": 1497868915375,
      "createdAt": 1497866312743,
      "email": "rob@robbeale.com",
      "firstName": "Rob",
      "surname": "Beale",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "3zBUVTRR0QFn9cWc0Il+dg==",
      "hashedPassword": "yj0hqGVtrRrVXviDgLNVzbvayTCiz82BEUaSawOpcmPDAX8h1ikY4Br2BfnurhZqj18wlF6tnSHzHn5LgdFBhw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/s1sLFtBZ-IMG_3474.PNG"
        },
        "personal": {
          "disciplines": [
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Nutrition",
            "Wellness Coach"
          ],
          "locations": [
            {
              "geolocation": [
                -0.158096459548086,
                51.4646883593722
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "SW11 5SX",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 75,
            "max": 100
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JCmWHDxx-IMG_3088.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "RobBeale",
            "link": "https://twitter.com/RobBeale",
            "user_id": "220138697",
            "accessToken": "220138697-mVeATHQGm4xJ3q65xBd79vTKUnLNHWzeCwWd8pvE",
            "accessTokenSecret": "taNSf106rPmUzeblITR7xGnH9XlQeoUZec7LRFlKQkkZl"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10158849787980374/",
          "facebookPage": "Rob Beale",
          "yearsOfExp": 2001,
          "affiliation": "REPS",
          "biography": "For the last 12 years I have held senior Fitness Director roles for the largest H&F companies in the U.K and abroad, focussing on improving the skills of PT's so they get great results for their clients. I have helped over 10,000 PT's do this. \n\nI also have a few personal clients who I help lead better, healthier, and happier lives through coaching, exercise, and nutrition.",
          "offers": "12 month programme for PT's:  £495\n\nPersonal clients:  By request.",
          "phone": {
            "code": "+44",
            "number": "7909986429"
          }
        },
        "inherit_from_parent": false,
        "score": 61
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1498668403892,
      "username": "rob_beale",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      }
    },
    {
      "_id": {
        "$oid": "59482433330bcd252b5fad6d"
      },
      "lastUpdated": 1497907410054,
      "createdAt": 1497900083022,
      "providerId": "10212731877633255",
      "provider": "facebook",
      "surname": "Lunnen",
      "firstName": "Allison",
      "email": "allisonlunnen@yahoo.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10212731877633255/",
          "disciplines": [
            "Bootcamp",
            "Hiit",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Nutrition"
          ],
          "locations": [
            {
              "geolocation": [
                -90.72781099999997,
                38.806444
              ],
              "country": "United States",
              "city": "O'Fallon",
              "postcode": "63366",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 20,
            "max": 65
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 16,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/soEfW4GY-e0e553a7-30de-4855-8b1c-935dbae03d7a.jpg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "204277333.8640130.22ba67f8390b4c03aba1069602374a32",
            "link": "https://www.instagram.com/allisonlunnenfitness"
          },
          "facebookPage": "Bodybyali",
          "yearsOfExp": 2015,
          "affiliation": "ACE",
          "strapLine1": "Challenge Pro Trainer",
          "biography": "I have been helping others achieve their health, fitness and nutrition goals since high school - in 2015 I decided to get certified after the birth of my son. I worked for 2 large gym franchises before starting my own personal training business in the summer of 2016. I am located in O'Fallon, MO where I offer 1:1 personal training, small group training and bootcamps - also nutritional products, supplements, meal planning and nutritional counseling. In-home training is available, location and schedule permitting.",
          "offers": "1:1 Personal Training: $65\nSmall Group Training: $40\nBootcamp: $20\nNutritional Consult: $50",
          "phone": {
            "code": "+1",
            "number": "8168138610"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6Z0zRtwA-FB_IMG_1497283181899.jpg"
        },
        "inherit_from_parent": false,
        "score": 80
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "allison_lunnen",
      "lastLogin": 1499052890698,
      "agent": {
        "family": "Chrome Mobile",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG-SM-G935A Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.125 Mobile Safari/537.36"
      }
    },
    {
      "_id": {
        "$oid": "594b520193693911e0254dde"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:51:32.952Z"
      },
      "createdAt": 1498108417688,
      "providerId": "10154941999849139",
      "provider": "facebook",
      "surname": "Barraclough",
      "firstName": "Peter",
      "email": "pbarraclough_38@hotmail.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154941999849139/",
          "disciplines": [
            "Circuit Training",
            "Group Classes",
            "Hiit",
            "Mobile Trainer",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "locations": [
            {
              "geolocation": [
                -3.4250019999999495,
                53.18390600000001
              ],
              "city": "Denbigh",
              "postcode": "LL16",
              "_id": {
                "$oid": "5a5fef94765eba094646f711"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 15,
            "max": 30
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/V8frH7Ye-bacebg.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2013,
          "biography": "With classes in Denbigh and Ruthin on a regular basis, I can cater for anyone in the area who wants to get fit. People who appreciate honesty and understand that I have their best interests at heart will benefit from individual fitness programmes, tailored to your needs. \n\nYou must appreciate that it may be a long process, but if we both work hard towards your goal, we can get good results as quickly as we can. \n\nCome and join a fun, inclusive environment, taking each day as a chance to improve and learn! Most importantly start a journey because you want to do something great for YOU!\n\nI help ordinary people achieve extraordinary things! How?\n\nI start by taking some initial statistics from you. These stats are re-tested along the way and are paramount in changing your fitness plan to ensure you're always on track to reach your goal.\n\nYou'll train with me either in just private classes or group classes throughout the week. I know that most people don't actually like exercise, so the programmes are fun. Be prepared to expect the unexpected! :-) \n\nMost importantly, I will get you results. Contact me to discuss how I can help you.",
          "strapLine1": "BACE Fitness In Denbigh & Ruthin - I help ordinary people achieve extraordinary things!",
          "facebookPage": "bacefitness",
          "phone": {
            "number": "7519910514",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eRrMKV3q-bacelogo.jpg"
        },
        "inherit_from_parent": false,
        "score": 71
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "peter_barraclough",
      "lastLogin": 1498108417934,
      "__v": 1
    },
    {
      "_id": {
        "$oid": "594c9ab993693911e0259420"
      },
      "lastUpdated": 1498330415759,
      "createdAt": 1498192569013,
      "email": "paige.el.olsen@gmail.com",
      "firstName": "Paige",
      "surname": "Olsen",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "b5e80a3671982fec685c776fd17b5622941db640",
      "salt": "tZ5valV7oJKZugqgZgRTKw==",
      "hashedPassword": "qZr12NrI/hN5WKd9yccF497kynh03zLdlOCRJi27bQ0TyaSAmvZBCzBYMJK2tX1i4sLgcuMI9tshnRb53FjYLA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MG1FHRV6-IMG_0952.JPG"
        },
        "personal": {
          "disciplines": [
            "Flexibility",
            "Functional Training",
            "Hiit",
            "Nutrition",
            "Pre/post Natal"
          ],
          "qualifications": [
            "B.S Nutrition and Exercise Physiology",
            "ACE Personal Trainer Certification",
            "CPR/AED and First Aid"
          ],
          "locations": [
            {
              "geolocation": [
                -122.66157595968075,
                45.72681564256292
              ],
              "country": "United States",
              "city": "Vancouver",
              "postcode": "98685",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 45,
            "max": 65
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aNZkuGIQ-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2012,
          "offers": "Personal Training - 1 hr ($50), 30 min ($30)\nAssisted Stretching - 1 hr ($35) 30 min ($20)\nNutrition Counseling - 1 hr ($40) \nOnline Coaching - $10/wk\n\nPlease email me at paige.el.olsen@gmail.com with any queries",
          "biography": "Hello!\nWhat I provide is a way to have a healthy relationship with your body, exercise and food using small changes towards a healthier lifestyle. My goal is to educate on why we exercise and why we fuel our bodies the way we do in order to help you flourish rather than punish you with grueling exercise programs and extreme diets. \nIf you have a history of yo-yo dieting, binge eating, punishing yourself with exercise, losing 10 lbs only to gain 15 lbs then I urge you to contact me for a free hour evaluation where we'll discuss goals and see if I would be the right fit for you! \nHope to hear from you soon!",
          "phone": null
        },
        "inherit_from_parent": false,
        "score": 61
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1498330284837,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "username": "paige_olsen"
    },
    {
      "_id": {
        "$oid": "594cafca93693911e02598a2"
      },
      "lastUpdated": 1498222204237,
      "createdAt": 1498197962889,
      "email": "garethmcknight@hotmail.com",
      "firstName": "Gareth",
      "surname": "Mcknight",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "hXFg5sqV1ilLQMyUprlspw==",
      "hashedPassword": "CAqr9RhlgJnX14rxN77YijRwcUfmP/hlLCXwj9YIlq7mTnHWWl/04L9T1QDZojtMtnxsshuTpUDbgwqe9lpZjQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sCCeqIC9-gm.jpg"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Active IQ Indoor Cycling"
          ],
          "locations": [
            {
              "geolocation": [
                -0.2405298999999559,
                52.56949849999999
              ],
              "country": "United Kingdom",
              "city": "Peterborough",
              "postcode": "PE1 1EL",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 35,
            "max": 45
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 30,
          "offers": "REACH YOUR GOALS\nINDIVIDUAL & GROUP PACKAGES AVAILABLE\nFLEXIBLE LOCATIONS AND AT HOME TRAINING OPTIONS",
          "biography": "KNIGHT FIT GROUP & PERSONAL TRAINING SPECIALIST IN WEIGHT LOSS, STRENGTH & CONDITIONING AS WELL AS NUTRITION & DIETARY ADVICE\nFULL SUPPORT GIVEN TO CLIENTS\nFREE FITNESS ASSESSMENTS\nAMAZING RESULTS!!",
          "phone": {
            "code": "+44",
            "number": "7860287501"
          }
        },
        "inherit_from_parent": false,
        "score": 57
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1498197963199,
      "agent": {
        "family": "Facebook",
        "major": "129",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; SM-G900F Build/MMB29M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/58.0.3029.83 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/129.0.0.29.67;]"
      },
      "username": "gareth_mcknight"
    },
    {
      "_id": {
        "$oid": "594e195e93693911e025e5a3"
      },
      "lastUpdated": 1498910400515,
      "createdAt": 1498290526532,
      "providerId": "109992476778518438290",
      "provider": "google",
      "surname": "Angel",
      "firstName": "Anshika",
      "email": "angelanshika09@gmail.com",
      "settings": {
        "personal": {
          "google": "https://plus.google.com/109992476778518438290",
          "disciplines": [
            "Activity for Children"
          ],
          "qualifications": [
            "Level 2: Gym Instructor"
          ],
          "locations": [
            {
              "geolocation": [
                76.7794179,
                30.7333148
              ],
              "country": "Other",
              "city": "Chandigarh",
              "postcode": "160022",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 20,
            "max": 200000
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Gym instructor",
          "radius": 1,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/bDCUqH9x-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2014,
          "phone": {
            "code": "+91",
            "number": "8350906267"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/07XCmUce-filename.jpeg"
        },
        "inherit_from_parent": false,
        "score": 55
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "anshika_angel",
      "lastLogin": 1498457928824,
      "agent": {
        "family": "Chrome",
        "major": "51",
        "minor": "0",
        "patch": "2683",
        "source": "Mozilla/5.0 (Windows NT 6.2; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2683.0 Safari/537.36"
      }
    },
    {
      "_id": {
        "$oid": "594f85c193693911e0263365"
      },
      "lastUpdated": {
        "$date": "2018-08-20T06:36:37.063Z"
      },
      "createdAt": 1498383809858,
      "email": "mfertin@hotmail.com",
      "firstName": "Mira",
      "surname": "Fertin",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "62Ar4+fKQ+2AhAkrVziICg==",
      "hashedPassword": "mWQICvdDdZyMDFY26Q5mEbCdTFwJgoTHi3Ja8sdujKu+StZHgpZWP2fM2Iiv9FbAbgqq2nQMHHuJBAjE/QEenQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "AUD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1yt5pwi7-12088149_857308944386190_4593898716125553900_n.jpg"
        },
        "personal": {
          "disciplines": [
            "Bootcamp",
            "Boxing",
            "Hiit",
            "Online Coaching",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 1 personal trainer"
          ],
          "locations": [
            {
              "geolocation": [
                144.96305600000005,
                -37.81361100000001
              ],
              "city": "Melbourne",
              "postcode": "3000",
              "_id": {
                "$oid": "5b7a6108bf18d20974b3dc00"
              },
              "country": "Australia",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 50,
            "max": 500
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8uvLf49A-14707797_305725769799940_2997272011789330360_o.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2016,
          "affiliation": "Australian institute of Fitness",
          "biography": "I'm the \"Scandinavian health coach\".\nI hail from Finland and follow \"Sissu\" (Perseverance) along side Viking philosophy.\n\nI specialise in short high intensity fat burning workouts and am a qualified boxing instructor. \n\nI also offer online coaching.",
          "offers": "- HIT courses\n- Boxing courses \n- Online challenges",
          "phone": {
            "number": "417553117",
            "code": "+61"
          },
          "strapLine1": "Building warriors"
        },
        "inherit_from_parent": false,
        "score": 62
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1534742136630,
      "agent": {
        "family": "Chrome",
        "major": "68",
        "minor": "0",
        "patch": "3440",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/68.0.3440.106 Safari/537.36"
      },
      "username": "mira_fertin",
      "__v": 9
    },
    {
      "_id": {
        "$oid": "5952e367925c1b7b3bfb7a8a"
      },
      "lastUpdated": 1498725340791,
      "createdAt": 1498604391728,
      "surname": "Djemal",
      "firstName": "Mustafa",
      "email": "m.djemal@yahoo.co.uk",
      "providerId": "5587246909",
      "provider": "instagram",
      "username": "mustafa_djemal",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "5587246909.8640130.be0bf61684ff49abbefa98ea27a70957",
            "link": "https://www.instagram.com/drogofitness"
          },
          "disciplines": [
            "Bodybuilding",
            "Martial Arts",
            "Mobile Trainer",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Level 4: Lower Back Pain Instructor",
            "Level 3: Nutrition",
            "Level 3: Personal Trainer",
            "Brazilian Jiu Jitsu",
            "Muay Thai",
            "Boxing",
            "Trigger Point Foam Rolling Level 1",
            "Doctor Referral",
            "Lower Back Pain"
          ],
          "locations": [
            {
              "geolocation": [
                -0.0804583,
                51.606302
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "N17 8JJ",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 35,
            "max": 60
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EJRSnybZ-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10213183793012722/",
          "facebookPage": "drogofitness",
          "yearsOfExp": 2008,
          "affiliation": "REPS",
          "offers": "First two sessions half price.",
          "biography": "I have 16 years of experience in health and fitness. I am also a Brazilian Jiu Jitsu martial artist and I compete at a professional level. I'm here to help you reach your goals what ever they maybe. My workouts are challenging yet enjoyable.",
          "phone": {
            "code": "+44",
            "number": "7397200020"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pVuN3rN0-IMG_20170615_105104.jpg"
        },
        "inherit_from_parent": false,
        "score": 71
      },
      "role": "trainer",
      "activation_code": "5c7cc96740c02e7ac85dee9e46fbd9038dc9c208",
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1498604392160,
      "agent": {
        "family": "Chrome Mobile",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Linux; Android 7.0; EVA-L09 Build/HUAWEIEVA-L09) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.117 Mobile Safari/537.36"
      }
    },
    {
      "_id": {
        "$oid": "59538bd5925c1b7b3bfb7a8c"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:49:01.322Z"
      },
      "createdAt": 1498647509295,
      "email": "amshape1@gmail.com",
      "firstName": "Andrew",
      "surname": "Digby",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "vBw3jKYtIg87x+YOYs1xsw==",
      "hashedPassword": "wEyLQu9up9jXwYo1j2tGesQ9TJkSlBuLzMaiyJlS48QNP1RUY5J5zfGSuDzbKG5mFmilHY08oR6bXmyorl6c4g==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/efcLIKAV-fitlife.jpg"
        },
        "personal": {
          "disciplines": [
            "Endurance",
            "Functional Training",
            "Group Classes",
            "Hiit",
            "Pre/post Natal"
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Level 3: Ante & Post Natal"
          ],
          "locations": [
            {
              "geolocation": [
                -1.08729790000007,
                53.9599651
              ],
              "city": "York",
              "postcode": "YO1",
              "_id": {
                "$oid": "5a5feefd765eba094646f710"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 35,
            "max": 40
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7El6CDsh-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/1305467402907157/",
          "yearsOfExp": 2000,
          "biography": "Experienced coach of over 17 years. Covering York and the surrounding area as well as online training options.",
          "offers": "8, 12 and 16 week package options. Please contact for more details.\n\nOnline training packages available",
          "phone": {
            "number": "7904023208",
            "code": "+44"
          }
        },
        "inherit_from_parent": false,
        "score": 63
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1513168749319,
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36"
      },
      "username": "andrew_digby",
      "__v": 6
    },
    {
      "_id": {
        "$oid": "5953dc42925c1b7b3bfb7a93"
      },
      "lastUpdated": 1498687744625,
      "createdAt": 1498668098552,
      "surname": "Abraham",
      "firstName": "Amir",
      "email": "amirfarhade@ymail.com",
      "providerId": "386880398",
      "provider": "instagram",
      "username": "amir_abraham",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "386880398.8640130.281b2cd084a64fa289624ba215464920",
            "link": "https://www.instagram.com/amirfarhadabraham"
          },
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Functional Training",
            "Group Classes",
            "Hiit"
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "locations": [
            {
              "geolocation": [
                101.65593490000003,
                2.921318
              ],
              "country": "Other",
              "city": "Cyberjaya",
              "postcode": "63000",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 50,
            "max": 100
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BRKSPFtt-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2014,
          "biography": "Happiness is the only thing worth fighting for in your life.\n\nThe discipline you learn and character you build from setting and achieving a goal can be more valuable than the achievement of the goal itself.",
          "phone": {
            "code": "+60",
            "number": "1112380567"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/giLfbZ4c-filename.jpeg"
        },
        "inherit_from_parent": false,
        "score": 59
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1498668098762,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.5742 Version/10.0 Mobile/14F89 Safari/602.1"
      }
    },
    {
      "_id": {
        "$oid": "595936027844f858cb4b7b04"
      },
      "lastUpdated": 1499688000555,
      "createdAt": 1499018754139,
      "email": "aseematri@gmail.com",
      "firstName": "Aseem",
      "surname": "Atri",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "1c0dffdd751501c66f3d1bee26a432c0f9463dbe",
      "salt": "5JIlaXPqgyhUlDOdmCXFog==",
      "hashedPassword": "CX2S3n+AI9UTuJ4d0ciJmdeYoj9se2Mtzx1O4ewFZvYYsTR4Mr0739eINFngU0KqNDWuLZoqdsDrOiRkjarZ9w==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mTcEAKOY-IMG_1301.JPG"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Endurance",
            "Group Classes",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer"
          ],
          "locations": [
            {
              "geolocation": [
                77.22739580000007,
                28.6618976
              ],
              "country": "Other",
              "city": "New Delhi",
              "postcode": "110006",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 20000,
            "max": 50000
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 100,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GELhqz3C-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "phone": {
            "code": "+91",
            "number": "9991189488"
          }
        },
        "inherit_from_parent": false,
        "score": 48
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1499018754658,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "username": "aseem_atri"
    },
    {
      "_id": {
        "$oid": "595a48de7844f858cb4b7b06"
      },
      "lastUpdated": {
        "$date": "2019-09-17T18:20:07.442Z"
      },
      "createdAt": 1499089118702,
      "email": "paulstefangill@hotmail.com",
      "firstName": "Paul Stefan",
      "surname": "Gill",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "rFOWfvhanCOnHPvwjbVVnA==",
      "hashedPassword": "YdHL7zQGdvacEotwUfXfkzvSKcACtK7PJH1J0ONHiIEeaVuPTF12cIM1IoHqtr8sBeMHPZP4ejBXY16DQmcv6A==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pne6UEyR-Capture%20ig.PNG"
        },
        "personal": {
          "disciplines": [
            "Agility"
          ],
          "qualifications": [
            "Level 5 Advanced Diploma in Nutrition for Sports and Exercise",
            "Level 4 Elite Strength and Conditioning",
            "Level 4 Medical Conditions",
            "Level 4 Advanced Nutrition: Dietetic management of Disease",
            "Level 3 Personal Trainer Diploma",
            "Level 3 Pre/Post Natal",
            "Level 3 GP Referral Exercise Specialist Diploma",
            "Level 3 Life Coach Diploma",
            "Level 3 Sports Nutrition",
            "Level 3 Education and Learning",
            "Level 2 Life Guard and First aid",
            "Level 2 Gym",
            "Level 2 Olympic Lifting",
            "Pad work Instructor",
            "Kettlebells Instructor",
            "Special Populations",
            "Disability",
            "Exercise for Ultimate Fat Loss",
            "Food Labeling",
            "Nutrition for Sports and Exercise",
            "Healthy Eating Guidelines"
          ],
          "locations": [
            {
              "city": "Bradford",
              "geolocation": [
                -1.7593980000000329,
                53.795984
              ],
              "postcode": "BD1 2RD",
              "_id": {
                "$oid": "5d8123d7dbd9e20939885b4d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30,
            "max": 30
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Strength & Conditioning Coach",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/afjwp17J-logo.JPG",
            "size": {},
            "offset": 0
          },
          "facebookPage": "",
          "yearsOfExp": 2009,
          "biography": "I’ve always been a gym bunny but never particularly sporty thanks to the medical conditions I suffer with hindering me.\nSee, I have Spina Bifida; this has had a lasting effect on my life and how I train. It’s due to this that I have Dyspraxia, and later developed Arthritis in my hips.\nDuring my late teens, my immune system turned inward and began attacking my thyroid. This caused major metabolic issues and I topped out at 20 stone. The uphill battle that followed saw me lose 8 stone, with nothing but the odds stacked firmly against me.\nNow I put my particular focus onto the medical and well-being side of exercise and fitness.\nMy aim is to help those who feel helpless, and progress athletes and prevent injury while progressing everyone towards their goal.",
          "offers": "1 1:1 Session £30 \n4 1:1 Session £90 \n10 1:1 Sessions £180\n18 1:1 Sessions £270\nUnlimited* £375",
          "phone": {
            "number": "7526154023",
            "code": "+44"
          },
          "affiliation": ""
        },
        "inherit_from_parent": true,
        "score": 60,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1568744306271,
      "agent": {
        "family": "Chrome",
        "major": "75",
        "minor": "0",
        "patch": "3770",
        "source": "Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.142 Safari/537.36"
      },
      "username": "paul_stefan_gill",
      "__v": 51
    },
    {
      "_id": {
        "$oid": "595b42457844f858cb4b7b08"
      },
      "lastUpdated": 1499762412142,
      "createdAt": 1499152965948,
      "email": "oluagbaoye@gmail.com",
      "firstName": "olugbuyi",
      "surname": "Agbaoye",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "AszjB9HdhiFMzmg/tUTr0Q==",
      "hashedPassword": "Dlfs1jOJU4AckenWW04Q4bvQFl/p4J1f51K0RlVuwrtShV+Ophx91+3GGdEnIurgA6TQ26m3qEMHT1H9Q0l0HA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": false,
          "social": true,
          "qualifications": true,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "USD",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wjGYOrk4-olu.jpg"
        },
        "personal": {
          "disciplines": [
            "Group Classes",
            "Martial Arts",
            "Mobile Trainer",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "MSc Exercise Physiology"
          ],
          "locations": [
            {
              "geolocation": [
                3.3463074999999662,
                6.6208769
              ],
              "country": "Other",
              "city": "Ikeja",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "biography": "An Exercise Physiologist, Fitness and Wellness entrepreneur,Personal trainer, Fitness consultant. I hold a 3rd dan black belt in karate from Karate Union of Great Britain(KUGB). I am very passionate about the results my duties bring to people I service. Karate Coach with experience in teaching children and adults, teaching karate to children from preschool to secondary school and university level, organizing martial arts and sports tournaments.Corporate wellness consultant experienced in corporate fitness programming with focus on corporate fitness/wellness program assessment/design and setting up of corporate fitness facilities and programs. Promotion of wellness lifestyle and Karate.\n\nSpecialties: Personal FitnessTraining, performance improvement, general conditioning, group fitness training, organising martial arts and fitness clinics, tournaments, fitness/martial arts fundraiser events. Pre-school, primary and secondary schools extra- curricular activities. fitness consultancy. Corporate wellness programs.",
          "affiliation": "Karate Union of Geat Britain",
          "phone": {
            "code": "+234",
            "number": "8092612475"
          }
        },
        "inherit_from_parent": false,
        "score": 54
      },
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1499206630342,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1"
      },
      "username": "olugbuyi_agbaoye"
    },
    {
      "_id": {
        "$oid": "595e5eab7844f858cb4b7b3e"
      },
      "lastUpdated": 1500029005133,
      "createdAt": 1499356843414,
      "providerId": "109550690562981282051",
      "provider": "google",
      "surname": "Katsoula",
      "firstName": "Alexandra",
      "email": "alexandrakatsoula@gmail.com",
      "settings": {
        "personal": {
          "google": "https://plus.google.com/109550690562981282051",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0uDVP8hY_profilePicture.png"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "alexandra_katsoula",
      "lastLogin": 1499356843897,
      "agent": {
        "family": "IE",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; rv:11.0) like Gecko"
      }
    },
    {
      "_id": {
        "$oid": "596101257844f858cb4b7b49"
      },
      "lastUpdated": 1499603525881,
      "createdAt": 1499529509217,
      "surname": "Ogunjobi",
      "firstName": "Lateef",
      "email": "lateef.ogunjobi@mindsetmusic.co.uk",
      "providerId": "5685412152",
      "provider": "instagram",
      "username": "lateef_ogunjobi",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "5685412152.8640130.12ebf3d180934eda805b8ae8bc9aebd9",
            "link": "https://www.instagram.com/eelateef"
          },
          "disciplines": [
            "Bodybuilding",
            "Boxing",
            "Core Fitness",
            "Mobile Trainer",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Boxing instructor"
          ],
          "locations": [
            {
              "geolocation": [
                -0.0338827,
                51.5510789
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "E9 5QG",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 50,
            "max": 60
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xxVqKMB0-IMG_20161116_213155.jpg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2015,
          "affiliation": "Elite evolution",
          "offers": "£180 for 4 sessions",
          "biography": "My aim for my clients is to construct a detailed and specialised workout program tailored to help you achieve your personal goals. You will receive a step by step guide of each exercise, focusing on the perfect technique and form. I can facilitate the sessions in the comfort of your own home, or in your area as i understand the lifestyle commitments that may present themselves. All sessions will undoubtedly be challenging but enjoyable including fun things you like to do whether its weights, boxing, spinning and so forth. \n\nBenefits of Personal  Training\n\n•Boost metabolism\n•Fat loss\n•Diverse exercises\n•Improve cardio and stamina\n•Strengthen and tone\n•Maintain healthy weight\n•Increase muscle mass\n•Stress release\n•Fun\n\nHEALTH IS WEALTH",
          "phone": {
            "code": "+44",
            "number": "7479531910"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NlUH5hfK-filename.jpeg"
        },
        "inherit_from_parent": false,
        "score": 55
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1499531249307,
      "agent": {
        "family": "Samsung Internet",
        "major": "5",
        "minor": "4",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/5.4 Chrome/51.0.2704.106 Mobile Safari/537.36"
      }
    },
    {
      "_id": {
        "$oid": "596338dc7844f858cb4b7b61"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:40:24.653Z"
      },
      "createdAt": 1499674844804,
      "providerId": "10154577160931576",
      "provider": "facebook",
      "surname": "Turpin",
      "firstName": "Sam",
      "email": "samuel.turpin@ntlworld.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154577160931576/",
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Functional Training",
            "Hiit",
            "Nutrition"
          ],
          "qualifications": [
            "Level 4: Strength & Conditioning",
            "Level 2: Physical Activity for Children Instructor",
            "Level 3: Advanced Personal Trainer",
            "Level 3: Personal Trainer"
          ],
          "locations": [
            {
              "geolocation": [
                -1.5848654000000002,
                53.807873199999996
              ],
              "city": "Leeds",
              "postcode": "LS4 2JU",
              "_id": {
                "$oid": "5a5fecf8765eba094646f70d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 25,
            "max": 50
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KsixPfhP-x4lessbg4fit.JPG",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2016,
          "affiliation": "Degree, REPS",
          "phone": {
            "number": "7840493748",
            "code": "+44"
          },
          "biography": "Quality personal training in Leeds and the surrounding area."
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9C8SSVIk-filename.jpeg"
        },
        "inherit_from_parent": true,
        "score": 61,
        "parent": {
          "$oid": "58d16ac63fa3e80afcab990d"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "sam_turpin",
      "lastLogin": 1508841062087,
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "__v": 4,
      "approved_date": {
        "$date": "2017-10-24T10:21:21.797Z"
      }
    },
    {
      "_id": {
        "$oid": "596495d0d3869c26ad3bd4ae"
      },
      "lastUpdated": 1500379200526,
      "createdAt": 1499764176051,
      "providerId": "116557003820767674901",
      "provider": "google",
      "surname": "Jemiolo",
      "firstName": "Andrzej",
      "email": "andyjem@googlemail.com",
      "settings": {
        "personal": {
          "google": "https://plus.google.com/116557003820767674901",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/N2gXeZ3m_profilePicture.jpeg"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "andrzej_jemiolo",
      "lastLogin": 1499764176502,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      }
    },
    {
      "_id": {
        "$oid": "596550c0d3869c26ad3bd564"
      },
      "lastUpdated": {
        "$date": "2018-03-11T15:36:05.183Z"
      },
      "createdAt": 1499812032559,
      "email": "david.edgington@live.com",
      "firstName": "David",
      "surname": "Edgington",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "SsHi5Nf6Y//4XHk0M2naww==",
      "hashedPassword": "0SvLUbv3fhW7kIuXwN7sSfBU1hDHqKTXm4BKm07VHky6ZL96XuRdwltX0F1CnzN1+iIHKDS+yjkhOFu5eRWvlw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/azBGur68-IMG_7772.JPG"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Mobile Trainer",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR",
            "Spin Instructor"
          ],
          "locations": [
            {
              "geolocation": [
                -1.8474504000000707,
                53.76346499999999
              ],
              "city": "Queensbury",
              "postcode": "BD13 2AS",
              "_id": {
                "$oid": "5aa54ccc6c3e4f0956cda2c3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 20
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 30,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rYwbjjaG-IMG_5766.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "DEdgingtonPT",
            "link": "https://twitter.com/DEdgingtonPT",
            "user_id": "846118053926653953",
            "accessToken": "846118053926653953-NfYab52sFnnpLh4zA2kZ6dFrhfBeQYb",
            "accessTokenSecret": "Dw1Sfl75pWKIiikIbFa55yghmesVbLgaWWSLyBMTN25LN"
          },
          "instagram": {
            "access_token": "2079643159.8640130.5a4f7bf1c31d45cab9931c2027dd4b78",
            "link": "https://www.instagram.com/thedeafgym"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/1146346908799821/",
          "facebookPage": "DavidEdgingtonPT/",
          "yearsOfExp": 2011,
          "affiliation": "City and Guilds",
          "biography": "I specialised in weight and fat loss, boost confidence, re-rehabilitate injuries, strength conditioning, muscles gain and much more!\n\n\nI am profoundly Deaf myself but have good clear speech and lipreading skills. I regard myself as bilingual as I am equally comfortable to converse in English and British Sign language.\n\nI’m founder of The Deaf Gym, since 2011 and now have 2,300 members. All of our members have some form of hearing loss, however, most of them are severe or profound. They all are also part of British Sign Language community where they use sign language to communicate. Sadly, the society out there don't use sign language and this has created a barrier for the Deaf people to access information everywhere and this includes accessing fitness. \nRecent statistics have shown that 1 in 3 Deaf people suffered some form of mental issues and I have identified this leading to my passion to educating the Deaf people in fitness, health and wellbeing believing involving in exercising can help with their wellbeing and reducing the numbers of mental health issues. The Deaf Gym was established from there and now with members scattering worldwide including USA and Asia.\n\nI would be a suitable personal trainer that treats everyone equally, no matter what is their race, disability, religion or age.",
          "offers": "Single personal training - £15\n1 PT a week for 4 weeks - £60\n2 PT a week for 4 weeks - £120\n3 PT a week for 4 weeks - £180\n4 PT a week for 4 weeks - £240\n5 PT a week for 4 weeks - £300\n4 weeks online PT - £30\n8 weeks online PT -£60\n12 weeks online PT - £90\n\nCHRISTMAS SPECIAL OFFER \n50% off Single PT - £7.50",
          "phone": {
            "number": "7521677936",
            "code": "+44"
          },
          "strapLine1": "Client experience + Personal trainer experience = Result!"
        },
        "inherit_from_parent": true,
        "score": 84,
        "parent": null
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1520782440390,
      "agent": {
        "family": "Safari",
        "major": "11",
        "minor": "0",
        "patch": "2",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_2) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0.2 Safari/604.4.7"
      },
      "username": "david_edgington",
      "__v": 37
    },
    {
      "_id": {
        "$oid": "59673a45d3869c26ad3bd566"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:39:04.729Z"
      },
      "createdAt": 1499937349956,
      "email": "finn.tasker.pt@gmail.com",
      "firstName": "Finn",
      "surname": "Tasker",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "BmJ7mP4tg4o/vtwZNWb0TQ==",
      "hashedPassword": "ivZ86v44AJ1+cU9puwF89xOrV2budA9hgkMZnc4sL7FQOZEWCf6xYYuwhil/CKU1iYcPDpVUSI235asWq8rIQA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/C6h9d0Li-97603069446e6f689c74ab18166b73e244b4.jpg"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Hiit",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 2: Gym Instructor",
            "Level 2: Group Exercise to Music Instructor",
            "Level 2: First Aid / CPR"
          ],
          "locations": [
            {
              "geolocation": [
                -1.183504399999947,
                52.95780759999999
              ],
              "city": "Nottingham",
              "postcode": "NG7",
              "_id": {
                "$oid": "5a5feca8765eba094646f70c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ud9Ub3Oj-x4lessbg4fit.JPG",
            "size": {},
            "offset": 0
          },
          "affiliation": "Reps, Lifetime Training",
          "yearsOfExp": 2013,
          "biography": "Contact Details:\nPhone: 07538821568\nEmail: finn.tasker.pt@gmail.com\n\nHi there! I'm Finn.\n\nI've been in the fitness industry since 2010 (working as Sales and Management, until finally making the full-time switch to Personal Training in 2015) and thus have gained a vast array of experience within that time!\n\nI started working at Xercise4Less in February 2016 and have found a great amount of success and progression with clients in that time, so much so I began renting my own studio (Located in  Nottingham City Centre) in May 2017.\n\nI'm currently accepting clients in both facilities!\n\nI've trained everyone from professional squash player and former footballers to Doctors aspiring to look like fitness models, to average Joe's looking to stay in shape or get a bit trimmer!\n\nSo no matter your goal, personal background or lifestyle I'm the right PT for you!",
          "offers": "Studio Prices available upon request.\n\nXercsie4Less Prices (Per Session):\n\n1 Session - £30.00*\n4 Sessions - £22.50*\n10 Sessions - £18.00*\n18 Session - £15.00*\n\nUnlimited (as many sessions as you like): £375.00*\n\n*All sessions must be purchased as a block.\n*All sessions must be used within 30 days.",
          "phone": {
            "number": "7538821568",
            "code": "+44"
          }
        },
        "inherit_from_parent": true,
        "score": 65,
        "parent": {
          "$oid": "58d2429c3fa3e80afcabc725"
        }
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1499964171685,
      "agent": {
        "family": "Chrome Mobile iOS",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) CriOS/59.0.3071.102 Mobile/14F89 Safari/602.1"
      },
      "username": "finn_tasker",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5967dccad3869c26ad3bd567"
      },
      "lastUpdated": 1500638400733,
      "createdAt": 1499978954764,
      "providerId": "114949109131728",
      "provider": "facebook",
      "surname": "Sharpless-Willis",
      "firstName": "Helen",
      "email": "hsharplesswillis@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/114949109131728/",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5vCcBcyz_profilePicture.jpeg"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "helen_sharpless-willis",
      "lastLogin": 1499978954968,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.6026 Version/10.0 Mobile/14F89 Safari/602.1"
      }
    },
    {
      "_id": {
        "$oid": "59692b597db6ad7e8658c002"
      },
      "lastUpdated": 1500724800727,
      "createdAt": 1500064601400,
      "email": "amy.searle@aol.com",
      "firstName": "Amy",
      "surname": "Searle",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "ac31e29f21b14bec97aa02aa9fc827b381f3e670",
      "salt": "iOADklPtRZ5iqE36orbZBQ==",
      "hashedPassword": "YV6BAVpRYY9tG2xtfnqAdB2g6YeoYxCqKKKLz86Klc6fm9rDdOfhpkYuGkZVQAR+gtkfdzlwdA8N/7fLfjwbJQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                0.21687199999996665,
                51.44621
              ],
              "country": "United Kingdom",
              "city": "Dartford",
              "postcode": "DA1 1BG",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 30
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null,
          "radius": 40,
          "phone": {
            "code": "+44",
            "number": "7709338110"
          }
        },
        "inherit_from_parent": false,
        "score": 22
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1500064601644,
      "agent": {
        "family": "Chrome",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.115 Safari/537.36"
      },
      "username": "amy_searle"
    },
    {
      "_id": {
        "$oid": "596c6ae17db6ad7e8658c004"
      },
      "lastUpdated": 1500324293870,
      "createdAt": 1500277473548,
      "email": "kevin@beyond-fitness.co.uk",
      "firstName": "Kevin",
      "surname": "Dobson",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "WAm4wxS2UBafbOyDbckTGA==",
      "hashedPassword": "cDJMqS0/IzhA0Qc4HA84W0s9URttZCHVsGGdBwp4G/CEcwNfri02BK2oNfnNi/pqq7K/i55r8c4bQO+kZj8soA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zQvpnuHi-ADE_5798sm.jpg"
        },
        "personal": {
          "disciplines": [
            "Meal Plans",
            "Personal Training",
            "Sports Nutrition",
            "Strength & Conditioning",
            "Weight Management"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer"
          ],
          "locations": [
            {
              "geolocation": [
                -0.7769422000000077,
                51.57194430000001
              ],
              "country": "United Kingdom",
              "city": "Marlow",
              "postcode": "SL7 3HH",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 70
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Nutritionist",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SJONfi8B-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2006,
          "biography": "Helping men in their 40s and 50s to lose weight and keep it off\n\nI have numerous years experience in the Health and Fitness industry. My main area of expertise is weight management.\n\nMy training sessions are focused on the client’s needs and capabilities, simple yet effective, making sure the client is always challenged; but not pushed too far. I pride myself on my work, offering permanent success rather than a quick fix.\n\nI have extensive experience and knowledge, which I use with a range of clients from professional sports people to the general public. I enjoy this diversity of challenges and am known for my success.\n\nIn my spare time, I coach Marlow Youth FC under 14s, putting the knowledge I have gained throughout my career into helping young rising stars",
          "phone": {
            "code": "+44",
            "number": "7909658540"
          }
        },
        "inherit_from_parent": false,
        "score": 57
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1500277473866,
      "agent": {
        "family": "Edge",
        "major": "14",
        "minor": "14393",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.79 Safari/537.36 Edge/14.14393"
      },
      "username": "kevin_dobson"
    },
    {
      "_id": {
        "$oid": "596ce7d07db6ad7e8658c006"
      },
      "lastUpdated": {
        "$date": "2017-08-01T16:50:07.662Z"
      },
      "createdAt": 1500309456961,
      "email": "karl@theptmentor.uk",
      "firstName": "Karl",
      "surname": "Muir",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "UkjoniUoho+f9jSy1HUwTw==",
      "hashedPassword": "uA6d2rUARKq6ON2tfNeHF6ExWNWi3JC4BaUQ0ekY74ujkPjloGn5I4frMd4fv8YhPA+lEx/aMHtcwMy5YXTcuA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wUOZhLB6-unnamed.png"
        },
        "personal": {
          "disciplines": [
            "Blended Learning",
            "Course Discounts",
            "Level 3 Courses",
            "Mentorship",
            "Personal Trainer Courses"
          ],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -0.1568883177387817,
                51.81530820222024
              ],
              "city": "Tewin",
              "postcode": "AL6 0JU",
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "The PT Mentor UK",
          "companyWebsite": "http://www.theptmentor.uk",
          "profileTitle": "Academy",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TgXLatWt-unnamed2.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "theptmentoruk",
          "yearsOfExp": 2010,
          "affiliation": "REP's, Active IQ",
          "strapLine1": "Helping you go from \"course to clients.\"",
          "biography": "Hertfordshire based training  provider offering Active IQ fitness and assessing courses.\n\nIf you are already qualified we give you a great refer-a-friend rate.\n\nWe are also on the look for PT's up and down the country to come on board as freelance assessors to take on your own learners in your area.",
          "offers": "Level 2 Fitness Instruction\nLevel 3 Personal Training\nLevel 3 CAVA (assessing qualification)",
          "places": "Nationwide",
          "phone": {
            "code": "+44",
            "number": "7970429492"
          }
        },
        "inherit_from_parent": false,
        "score": 66
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "gyms",
      "lastLogin": 1500406994243,
      "agent": {
        "family": "Firefox",
        "major": "54",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:54.0) Gecko/20100101 Firefox/54.0"
      },
      "username": "the_pt_mentor_uk",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "596d20987db6ad7e8658c00a"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:37:51.229Z"
      },
      "createdAt": 1500323992224,
      "email": "bluefitnessacademy@gmail.com",
      "firstName": "Lorraine",
      "surname": "Blue",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "fa4c554719cec2d0d8bdee9404ccb22b7646240e",
      "salt": "uJESAAgy7TqrTOAqZPwAFg==",
      "hashedPassword": "kI78pD4QkXDaGq9QJ/yR9rtFAAcPMSNbOHZIw5EpZn642nIsiMf0OKj9cB1XKl070FsSAFZ60sfZy+lRy3k4XA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AeZpRqXN-Untitled2.jpg"
        },
        "personal": {
          "disciplines": [
            "Bodybuilding",
            "Nutrition",
            "Sports Massage",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 4: Tutor / Assessor",
            "Sport Science Bsc"
          ],
          "locations": [
            {
              "geolocation": [
                0.022177800000008574,
                51.36545020000001
              ],
              "city": "Bromley",
              "postcode": "BR2 7AH",
              "_id": {
                "$oid": "5a5fec5f765eba094646f70b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 30
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UU0iCCKn-251947_10151036792318490_2098673288_n.jpg",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "BlueFitnessAcad",
            "link": "https://twitter.com/BlueFitnessAcad",
            "user_id": "1211824382",
            "accessToken": "1211824382-jLxkPwmWhDTart3OVxPeCz2CuoZrCEgeJGd9GMd",
            "accessTokenSecret": "vCXmQJbLAyXfjo58hOWOe14kILhpze3HqqCqQ8trmOwkg"
          },
          "instagram": {
            "access_token": "5694034125.8640130.140fdf52045f4d35a57b2b77c34767a7",
            "link": "https://www.instagram.com/bluefitnessacademy"
          },
          "yearsOfExp": 2005,
          "strapLine1": "Train Today, Change Tomorrow",
          "biography": "As a Bsc Sports Scientist i developed my qualifications and became a qualified personal trainer and i have been training and teaching clients since 2006.\n\nI now run my own fitness training provider and personal training clinic with links to a physiotherapy and sports massage business. \n\nI am a big believer in weight training to aid in weight loss and build confidence in both men and women!",
          "offers": "12 week transformation package just £300 for 12 PT sessions, a full health and fitness assessment and ongoing mentoring support. \n\nBook a session and get the health and fitness assessment for free!",
          "phone": {
            "number": "7534947065",
            "code": "+44"
          }
        },
        "inherit_from_parent": false,
        "score": 75,
        "parent": null
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1500324146939,
      "agent": {
        "family": "Chrome",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.115 Safari/537.36"
      },
      "username": "lorraine_blue_rkbPZo9Bb",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "596db21c7db6ad7e8658c00c"
      },
      "lastUpdated": 1500984000760,
      "createdAt": 1500361244951,
      "email": "georgehunter14@gmail.com",
      "firstName": "George",
      "surname": "Hunter",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "a2b6c81d773b85beb70b12820baae570f6b3acc4",
      "salt": "WTkK0J3T6T58u43BzBL8ZA==",
      "hashedPassword": "TNG2wr9llaC/Zk44IJeQGp9Vwv+LYGm5hSV7jabjfX3B2HjVAqMK50+qg2avzQyWavjBJ6njroKcgGuKfsd5iA==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -0.9781302999999753,
                51.4542645
              ],
              "country": "United Kingdom",
              "city": "Reading",
              "postcode": "RG1",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 40
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null,
          "radius": 10,
          "phone": {
            "code": "+44",
            "number": "7825885697"
          }
        },
        "inherit_from_parent": false,
        "score": 22
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1500361245233,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_2_1 like Mac OS X) AppleWebKit/602.4.6 (KHTML, like Gecko) Version/10.0 Mobile/14D27 Safari/602.1"
      },
      "username": "george_hunter"
    },
    {
      "_id": {
        "$oid": "596ddd717db6ad7e8658c01b"
      },
      "lastUpdated": {
        "$date": "2017-10-18T09:26:30.804Z"
      },
      "createdAt": 1500372337801,
      "email": "rapidresultspt@yahoo.co.uk",
      "firstName": "Jenny",
      "surname": "Weller",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "Aa6VKdnkUZRvs4NXu3Jbvw==",
      "hashedPassword": "DwaH4C5R3mwO1G1BS4xLrdaWBkcyAGW7nKBKGCbk2RJ93vPdzFgqZNsYHSDbroyFxR4bK9gwlftxx0YEr22VFw==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tKcuwLO6-IMG_0191.JPG"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Personal Training",
            "Small Group Training",
            "Sports Massage",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Apparel for Sale",
            "Free Parking",
            "Hot Showers",
            "Towel Hire",
            "Cardio",
            "Free Weights",
            "Mat Area",
            "Resistance"
          ],
          "locations": [
            {
              "geolocation": [
                -0.019342000000051485,
                50.972328
              ],
              "city": "North Chailey",
              "postcode": "BN8 4ES",
              "_id": {
                "$oid": "59e71e4694e4d86bf9be195e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Rapid Results Personal Training",
          "companyWebsite": "http://www.rapidresultspt.com",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Du7A6ypF-IMG_0303.JPG",
            "size": {},
            "offset": 0
          },
          "twitter": {
            "screen_name": "jenrapidresults",
            "link": "https://twitter.com/jenrapidresults",
            "user_id": "479841984",
            "accessToken": "479841984-4rl5E9w1Gzw1i8xuJTvxVC15ri0sKRWnDLdAqsBK",
            "accessTokenSecret": "RPbwQxKodZtjzlUgjpxfK7Cj469AfaMzRSXErqwDneWmU"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154521125481809/",
          "yearsOfExp": 2010,
          "affiliation": "Fitpro",
          "offers": "Monday - Friday 4pm-8pm\nSaturdays 8am-12pm\n\nComing September 2017: additional sessions Mon-Fri 10am-2pm",
          "biography": "Brand New Small Group Personal Training facility in North Chailey, opening next Monday, 24th July from 4pm. Fun sessions, Fantastic trainers, individual programmes and great results. Suitable for all ages, abilities and goals. Free parking! \n\nwww.rapidresultspt.com \n\nCheck out our new website, and Book your place online. Max 4 clients per session.",
          "phone": {
            "code": "+44",
            "number": "7921725045"
          },
          "testimonials": "“The personal training sessions are fun and entertaining, with the degree of difficulty at just the right level thereby making the exercises challenging but achievable, to provide an action packed hour that whizzes by!!””\n\n“Last year I completed The 3 Peaks challenge for charity. I want to thank Jenny for her guidance, support, motivation and more importantly increased fitness to complete this tough challenge. I was one of only 7 from 30 to complete this mission!”\n\n“Jen has helped me work towards many goals. Her knowledge, experience and motivation are outstanding and she makes all the training sessions fun but worthwhile. Jen goes that extra mile, whether it be tailormaking a nutritional advice sheet to work in conjunction with your programme or checking in on your progress between sessions. She gave me back my enthusiasm for exercise, something I thought I had lost a long time ago! Everyone needs a ‘Jen’ in their life! She sets realistic targets and offers the best educational and support network to get you there. Thanks Jen!”"
        },
        "inherit_from_parent": false,
        "score": 68
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1500373820903,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "username": "rapid_results_personal_training",
      "__v": 2
    },
    {
      "_id": {
        "$oid": "596e00457db6ad7e8658c01d"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:36:52.286Z"
      },
      "createdAt": 1500381253937,
      "email": "jessicaamyguest@hotmail.com",
      "firstName": "Jessica",
      "surname": "Guest",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "IbRJOsZJqI9L2DLBg6KVqA==",
      "hashedPassword": "fckP3AF+qMRWoBzjkjHll8zJMMDOBTj+IAyJHHwWSyte7BhZv4B5Gv/FOtBetqmkw/16ah7GiU8dJlFmjWV/fg==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Krf0xKcd-IMG_8013.JPG"
        },
        "personal": {
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Group Classes",
            "Hiit",
            "Strength & Conditioning"
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR"
          ],
          "locations": [
            {
              "geolocation": [
                -1.1986739999999827,
                53.14719499999999
              ],
              "city": "Mansfield",
              "postcode": "NG18 1PJ",
              "_id": {
                "$oid": "5a5fec24765eba094646f70a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 25
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/HlZ2dZ9V-x4lessbg4fit.JPG",
            "size": {},
            "offset": 0
          },
          "phone": {
            "number": "7508155477",
            "code": "+44"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/10215117616751711/",
          "facebookPage": "jessicaamyguest@hotmail.com",
          "strapLine1": "Wondering how to start your fitness journey? Join me now! I currently am based at Xercise4Less Mansfield.",
          "biography": "Currently based at Xercise4Less Mansfield. I am passionate about the fitness industry and love helping people achieve their goals."
        },
        "inherit_from_parent": true,
        "score": 68,
        "parent": {
          "$oid": "596f46d9f3425e317853150d"
        }
      },
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1515573917305,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1"
      },
      "username": "jessica_guest",
      "password_reset_code": "X",
      "__v": 18,
      "approved_date": {
        "$date": "2018-01-10T18:29:22.953Z"
      }
    },
    {
      "_id": {
        "$oid": "596e055b7db6ad7e8658c01f"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:36:06.833Z"
      },
      "createdAt": 1500382555746,
      "email": "zoeharvey20@gmail.com",
      "firstName": "Zoe",
      "surname": "Harvey",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "X",
      "salt": "y8xgEsGyQPXCy+yWEmYZYQ==",
      "hashedPassword": "NAzF0kPeygb8TQzVSMMzFmRWSdvixpYu0zhrvLk8y7vI/cEXbbT5AjEBQAZvmB0rr0hZh42FqsJMEjU7GdPpbQ==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Dc5XfCGI-IMG_6424.PNG"
        },
        "personal": {
          "disciplines": [
            "Boxing",
            "Core Fitness",
            "Hiit",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Nutrition",
            "Specific Dietary Planning"
          ],
          "locations": [
            {
              "geolocation": [
                -3.7839131000000634,
                56.00187750000001
              ],
              "city": "Falkirk",
              "postcode": "FK1",
              "_id": {
                "$oid": "5a5febf6765eba094646f709"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 30
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9vC58dXw-IMG_6412.JPG",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2013,
          "affiliation": "REPS",
          "offers": "Single Session - £30\nBlock of 4 - £90\nBlock of 10 - £180",
          "biography": "Hi everyone! \n\nI'm a qualified personal trainer at Xercise4Less in Falkirk, I've got over 5 years of experience in the fitness industry. I specialise in Weight Loss and have done for the latter 3 years. \nAll of my clients will receive tailored dietary planning specific for your body type and goals. \n\nI offer a free consultation to discuss your goals and what our approach is to get you there! \n\nMeet you all soon!",
          "phone": {
            "number": "7740186612",
            "code": "+44"
          },
          "facebook": "https://www.facebook.com/app_scoped_user_id/1895182060493781/",
          "facebookPage": "zoeharvey20@gmail.com"
        },
        "inherit_from_parent": true,
        "score": 69,
        "parent": {
          "$oid": "58d146053fa3e80afcab913d"
        }
      },
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1509972047588,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_3 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A432 Safari/604.1"
      },
      "username": "zoe_harvey",
      "__v": 10
    },
    {
      "_id": {
        "$oid": "596e05677db6ad7e8658c020"
      },
      "lastUpdated": 1500382568739,
      "createdAt": 1500382567164,
      "providerId": "107966346666501097452",
      "provider": "google",
      "surname": "Foord",
      "firstName": "Luke",
      "email": "lukegfoord@gmail.com",
      "settings": {
        "personal": {
          "google": "https://plus.google.com/107966346666501097452",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GMgYWxgw_profilePicture.jpeg"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "luke_foord",
      "lastLogin": 1500382568746,
      "agent": {
        "family": "Facebook",
        "major": "132",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Mobile/14F89 [FBAN/FBIOS;FBAV/132.0.0.46.75;FBBV/64862532;FBDV/iPhone9,4;FBMD/iPhone;FBSN/iOS;FBSV/10.3.2;FBSS/3;FBCR/Three;FBID/phone;FBLC/en_GB;FBOP/5;FBRV/0]"
      }
    },
    {
      "_id": {
        "$oid": "596e0a317db6ad7e8658c021"
      },
      "lastUpdated": 1500383794119,
      "createdAt": 1500383793895,
      "providerId": "1763711120312245",
      "provider": "facebook",
      "surname": "Graham",
      "firstName": "Shirley",
      "email": "shirleyjt3@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1763711120312245/",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZuuVoFeZ_profilePicture.jpeg"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "shirley_graham",
      "lastLogin": 1500383794127,
      "agent": {
        "family": "Facebook",
        "major": "132",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Mobile/14F89 [FBAN/FBIOS;FBAV/132.0.0.46.75;FBBV/64862532;FBDV/iPhone8,4;FBMD/iPhone;FBSN/iOS;FBSV/10.3.2;FBSS/2;FBCR/Three;FBID/phone;FBLC/en_GB;FBOP/5;FBRV/0]"
      }
    },
    {
      "_id": {
        "$oid": "596e0b737db6ad7e8658c022"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:35:37.450Z"
      },
      "createdAt": 1500384115471,
      "providerId": "10214026810203005",
      "provider": "facebook",
      "surname": "Reeve",
      "firstName": "Michaela",
      "email": "missmreeve@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10214026810203005/",
          "disciplines": [
            "Group Classes",
            "Mobile Trainer",
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "locations": [
            {
              "geolocation": [
                -1.1986739999999827,
                53.14719499999999
              ],
              "city": "Mansfield",
              "postcode": "NG18 1PJ",
              "_id": {
                "$oid": "5a5febd9765eba094646f708"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 30
          },
          "companyName": "Michaela PT",
          "companyWebsite": "HTTPS://Www.xercise4less.com",
          "profileTitle": "Personal Trainer",
          "radius": 16,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GWvG4Mv7-x4lessbg4fit.JPG",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2017,
          "affiliation": "REPs",
          "phone": {
            "number": "7562643422",
            "code": "+44"
          },
          "biography": "Hi, my name is Michaela and I am dedicated to help you reach your health & fitness goals."
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zqhlB1b0-filename.jpeg"
        },
        "inherit_from_parent": true,
        "score": 61,
        "parent": {
          "$oid": "596f46d9f3425e317853150d"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "michaela_reeve",
      "lastLogin": 1500384837793,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "__v": 8,
      "approved_date": {
        "$date": "2018-01-10T18:23:41.314Z"
      }
    },
    {
      "_id": {
        "$oid": "596e10e27db6ad7e8658c023"
      },
      "lastUpdated": 1500385507000,
      "createdAt": 1500385506841,
      "providerId": "1828964493796880",
      "provider": "facebook",
      "surname": "Slater",
      "firstName": "Wayne",
      "email": "wayne_alan79@yahoo.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1828964493796880/",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/n1dpESDb_profilePicture.jpeg"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "wayne_slater",
      "lastLogin": 1500385507016,
      "agent": {
        "family": "Facebook",
        "major": "131",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; U; Android 4.3; en-gb; GT-I9300 Build/JSS15J) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30 [FB_IAB/FB4A;FBAV/131.0.0.32.69;]"
      }
    },
    {
      "_id": {
        "$oid": "596e289f7db6ad7e8658c026"
      },
      "lastUpdated": {
        "$date": "2018-01-11T00:35:09.008Z"
      },
      "createdAt": 1500391583850,
      "providerId": "1812656642397318",
      "provider": "facebook",
      "surname": "Charman",
      "firstName": "James",
      "email": "jamescharman@icloud.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1812656642397318/",
          "disciplines": [
            "Bootcamp",
            "Mobile Trainer",
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 2: Group Exercise to Music Instructor",
            "Rugby coach"
          ],
          "locations": [
            {
              "geolocation": [
                -2.7174980303967113,
                52.73830055288763
              ],
              "city": "Shropshire",
              "postcode": "SY1 4YA",
              "_id": {
                "$oid": "5a56b13c765eba094646f540"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 8
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Zys5rHub-IMG_1973.JPG",
            "size": {},
            "offset": 0
          },
          "yearsOfExp": 2015,
          "biography": "Hey everyone iv been training myself for the past 5years and have been training clients for the past 3years\nI currently work at xercise4less while running my own bootcamp and home PT business \nI can help you improve in all aspect of fitness however I especially in body composition, helping u tone, lose or gain weight",
          "offers": "Bootcamps are free for the first session: phone for details \n\nGroup PT is £8 per person\n\nOne2one PT is £25 \n\nBlock bookings and packages will have discounts get intouch for details",
          "phone": {
            "number": "7983112678",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3TNTmSTo-IMG_1364.JPG"
        },
        "inherit_from_parent": true,
        "score": 64,
        "parent": {
          "$oid": "58d252cc3fa3e80afcabcaaf"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "james_charman",
      "lastLogin": 1500392285268,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "__v": 1
    },
    {
      "_id": {
        "$oid": "596e8e467db6ad7e8658c027"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:34:44.561Z"
      },
      "createdAt": 1500417606425,
      "providerId": "1720658524902044",
      "provider": "facebook",
      "surname": "Unsworth",
      "firstName": "Dean",
      "email": "deanunsworth.pt@outlook.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1720658524902044/",
          "disciplines": [
            "Rehabilitation",
            "Sports Massage",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR",
            "Level 3 Sports Massage Therapy"
          ],
          "locations": [
            {
              "geolocation": [
                -2.423379299999965,
                53.5727482
              ],
              "city": "Bolton",
              "postcode": "BL2",
              "_id": {
                "$oid": "5a5feba4765eba094646f707"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 18
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Mou4k6ri-20170719_062631.jpg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "1412120772.8640130.ad2c1507f7d642889f994cf8cefae017",
            "link": "https://www.instagram.com/perform_pt_smt"
          },
          "yearsOfExp": 2014,
          "affiliation": "REPS",
          "offers": "1-2-1 PERSONAL TRAINING\n\nSINGLE SESSION (1 HOUR)       £30\n4 PER MONTH                              £90\n10 PER MONTH                            £180\n18 PER MONTH                            £270\nUNLIMITED                                    £525\n\n*T&C'S APPLY",
          "strapLine1": "SPORTS MASSAGE THERAPIST",
          "biography": "As a trainer I am motivating, encouraging and determined. I WILL help you to achieve your goals and we will enjoy every step of the journey. I am very interested in sports specific conditioning, fat loss & core strengthening.\n\nI design my training programs so that they are progressive, challenging and most importantly ....... rewarding! I stay committed and focused to your goals, I make every session different to provide maximum variety to challenge both body & mind. As well as a fitness program, I can help you with your diet and keep you on track with reviews on your progress. But remember it's not just about changing your diet & exercise, its about changing your lifestyle. You can make a big difference to yourself by making small changes to your habits.  \n\nI believe that personal training is a two way process, you get out what you put in! If you can give me your time & comitment and be open minded to try different training methods, I promise you, you will see results, you will exceed your goals and you will be who you want to be.",
          "phone": {
            "number": "7468463050",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KtpgoLq0-FB_IMG_1500444634643.jpg"
        },
        "inherit_from_parent": true,
        "score": 78,
        "parent": {
          "$oid": "58ca6a098972aa10a5b2a8e1"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "dean_unsworth",
      "lastLogin": 1500444990142,
      "agent": {
        "family": "Facebook",
        "major": "132",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G935F Build/NRD90M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/59.0.3071.125 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/132.0.0.20.85;]"
      },
      "__v": 3
    },
    {
      "_id": {
        "$oid": "596f2d4e7db6ad7e8658c028"
      },
      "lastUpdated": {
        "$date": "2018-11-15T07:49:56.468Z"
      },
      "createdAt": 1500458318006,
      "providerId": "10211673265405496",
      "provider": "facebook",
      "surname": "Toth",
      "firstName": "Marian",
      "email": "mrntoth@yahoo.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10211673265405496/",
          "disciplines": [
            "Ballet"
          ],
          "qualifications": [
            "Level 2: Ante & Post Natal - Aqua"
          ],
          "locations": [
            {
              "geolocation": [
                -2.179403999999977,
                53.00266799999999
              ],
              "city": "Stoke-on-Trent",
              "postcode": "ST4 2RP",
              "_id": {
                "$oid": "5bed2524bf18d20974b3dc15"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "female",
          "price": {
            "min": 100
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Dance Teacher",
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ul7cW8lO-x4lessbg4fit.JPG",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "35579820.8640130.21a8dce3fff346bb932090f3f85d2be6",
            "link": "https://www.instagram.com/ptmarian"
          },
          "phone": {
            "number": "234529923",
            "code": "+44"
          },
          "biography": "J"
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qB5z5w7A-54FABB97-4A9A-4BA1-A752-B2DE6EF81D87.jpeg"
        },
        "inherit_from_parent": true,
        "score": 58,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": true,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "marian_toth",
      "lastLogin": 1542266047085,
      "agent": {
        "family": "Mobile Safari",
        "major": "12",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 12_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Mobile/15E148 Safari/604.1"
      },
      "approved_date": {
        "$date": "2018-01-10T18:20:33.236Z"
      },
      "__v": 19
    },
    {
      "_id": {
        "$oid": "596f46d9f3425e317853150d"
      },
      "lastUpdated": {
        "$date": "2017-10-18T09:08:42.300Z"
      },
      "createdAt": 1500464857103,
      "email": "x4less.mansfield@shoomon.xyz",
      "firstName": "The",
      "surname": "Manager",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "0e3c8416b53dcbf8338f79acd1b7e3e03b7f3108",
      "salt": "e3p5ylGq7AwHitJHYNpZTg==",
      "hashedPassword": "0KXvz0gTgTHE0dPFsFKzTuApOn2d3oeFh8MdlZY8kF+3if3dUI7XXi5BmEjT8Y6kRrIz5PhpLA17I7721F4jEg==",
      "settings": {
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "serviceProvider",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6QFFA9HZ-x4lesslogo.jpg"
        },
        "personal": {
          "disciplines": [
            "All Abilities",
            "Fitness Classes",
            "Free Kids Classes",
            "Member App",
            "Personal Training"
          ],
          "qualifications": [
            "Award Winning Gym Chain",
            "A private self contained ladies only gym",
            "Ladies Only Zones",
            "Free Group Exercise Classes",
            "Over 400 pieces of state of the art gym kit",
            "2Kg-75Kg Free Weights",
            "Over 200 classes each and every month",
            "Classes for Children age 3 - 5",
            "Xercise4kids classes are for both members and non-members",
            "Boxing ring and punch bags",
            "Unique combat zone - MMA cage",
            "Free parking available for all our members"
          ],
          "locations": [
            {
              "geolocation": [
                -1.1874407000000247,
                53.15584119999999
              ],
              "city": "Mansfield Woodhouse",
              "postcode": "NG19 9BG",
              "_id": {
                "$oid": "59e71a1a94e4d86bf9be195c"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": null,
          "price": null,
          "companyName": "Xercise4less Mansfield Gym",
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/mansfield-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DJefdhUl-fruit-and-veg.jpg",
            "size": {},
            "offset": 0
          },
          "instagram": null,
          "facebookPage": "Xercise4Less",
          "biography": "JOIN NOW! Founder memberships are limited and just £9.99 pm!\n\nYour Mansfield gym will include over 400 pieces of state of the art gym kit. We'll give you specific areas for cardio, free weights and resistance training. Your gym includes a unique combat zone - MMA cage, boxing ring and punch bags. It's your goal, it's won't be easy but we promise it will be worth it.\nDoors officially open 10am, Friday 28th July 2017\n\nXercise4Less bring you a private, self contained ladies only gym. Your membership includes over 200 classes each and every month. There will be classes for Children age 3 - 5, Xercise4kids classes are for both members and non-members. Children see, children do, be healthy and happy together.\nThere will be an experienced team of Personal Trainers and a gym team who just cant wait to meet everyone. \nYou can and you will. We believe in you.\nFree parking available for all our members.",
          "offers": "More 4 Less:\n\nJOIN NOW! Founder memberships are limited and just £9.99 pm!\n\nOpening Times\n\nMon-Fri: 6am-10pm\nSat-Sun: 8am-8pm\nOff Peak: 10am-4pm Mon-Fri, 8am-8pm Sat-Sun\nBank Holidays: 10am-6pm",
          "strapLine1": "Monthly gym membership from £9.99 & access all clubs",
          "phone": {
            "code": "+44",
            "number": "1623703377"
          }
        },
        "inherit_from_parent": true,
        "score": 64,
        "parent": {
          "$oid": "58d1348a3fa3e80afcab8d75"
        }
      },
      "active": false,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1500464857301,
      "agent": {
        "family": "Chrome",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.115 Safari/537.36"
      },
      "username": "xercise4less_mansfield_gym",
      "__v": 1
    },
    {
      "_id": {
        "$oid": "596f5c46f3425e317853150e"
      },
      "lastUpdated": {
        "$date": "2018-01-18T00:32:24.102Z"
      },
      "createdAt": 1500470342511,
      "providerId": "10155379777386438",
      "provider": "facebook",
      "surname": "Whittaker",
      "firstName": "Andrew",
      "email": "adjwhitt@hotmail.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155379777386438/",
          "disciplines": [
            "Bootcamp",
            "Mobile Trainer",
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Advanced Group Exercise to Music Instructor",
            "Level 2: Exercise Music & Dance Partnership(EMDP) Teacher",
            "Level 2: Gym Instructor",
            "Level 2: Older Adults Instructor",
            "Level 2: Physical Activity for Children Instructor",
            "Level 2: Group Exercise to Music Instructor",
            "Spinning",
            "Boxing",
            "circuit training",
            "weight loss",
            "nutrition",
            "army physical training instructor"
          ],
          "locations": [
            {
              "geolocation": [
                -2.3114484000000175,
                53.6853644
              ],
              "city": "Haslingden",
              "postcode": "BB4 6LB",
              "_id": {
                "$oid": "5a5feb18765eba094646f704"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 40
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 40,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cSaVWuom-filename.jpeg",
            "size": {},
            "offset": 0
          },
          "instagram": {
            "access_token": "259502374.8640130.d9949eeb0bdf43ad882f508b3dff509e",
            "link": "https://www.instagram.com/andy_whittaker_personaltrainer"
          },
          "yearsOfExp": 2013,
          "affiliation": "Reps",
          "biography": "Mobile Personal training at its best! \nFun, Invigorating, Results Driven",
          "offers": "Free Consultation & Taster Session \n\n6 Week Introduction Packages 30% OFF!!!!!\n\nOn going Value for money Packages 10% OFF!!!!!",
          "strapLine1": "Mobile Personal Training At its Best!!",
          "phone": {
            "number": "7376862466",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OXtyV6JB-IMG_0944.PNG"
        },
        "inherit_from_parent": false,
        "score": 76
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "andrew_whittaker",
      "lastLogin": 1500470342853,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5970836bf3425e3178531511"
      },
      "lastUpdated": 1500545899782,
      "createdAt": 1500545899464,
      "providerId": "10212071817702366",
      "provider": "facebook",
      "surname": "Sandkvist",
      "firstName": "Robert",
      "email": "r100562@gmail.com",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10212071817702366/",
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1BsvDyeh_profilePicture.jpeg"
        },
        "inherit_from_parent": false
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "robert_sandkvist",
      "lastLogin": 1500545899791,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.6490.1 Version/10.0 Mobile/14E304 Safari/602.1"
      }
    },
    {
      "_id": {
        "$oid": "5974b5b1f3425e317853151a"
      },
      "lastUpdated": {
        "$date": "2018-01-12T11:24:29.327Z"
      },
      "createdAt": 1500820913187,
      "surname": "Riekstins",
      "firstName": "Ben",
      "email": "benriekstins90@gmail.com",
      "providerId": "212948666",
      "provider": "instagram",
      "username": "ben_riekstins",
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "212948666.8640130.8f04a88e07a241e68e2efe929edf46c8",
            "link": "https://www.instagram.com/benr1990"
          },
          "disciplines": [
            "Core Fitness",
            "Functional Training",
            "Hiit",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Degree of higher education: Sport Science and Personal Training",
            "Level 1 sprint coach",
            "Level 1 spin instructor"
          ],
          "locations": [
            {
              "geolocation": [
                -2.7553268000000344,
                52.70730289999999
              ],
              "city": "Shrewsbury",
              "postcode": "SY1",
              "_id": {
                "$oid": "5a589aed765eba094646f568"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 30
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UyIWjJqC-SBA%20FITNESS%20.jpg",
            "size": {},
            "offset": 0
          },
          "google": "https://plus.google.com/111370356227196953151",
          "biography": "I have had a huge hunger for anything and everything to do with  fitness, over the years this hunger has grown into a passion that has driven me to devote many hours to practising and studying a vast selection of sports activities, it was during this time I found myself refining and falling in love with athletics. I have spent over 17 years within the sport setting myself goal after goal to improve every aspect of my fitness, technique and mental determination. \n\nWith achieving so much as an athlete I wanted to learn more about fitness in general as I've always felt the need to inspire people to get into fitness. I studied sport science and personal training  at the University Bedfordshire back in 2009 and graduated in 2013.\n\n I specialize in mainly explosive fitness & sport performance due to my experience in athletics I've worked with athletes across a wide range of sports such as sprinting, basketball, rugby, netball etc however I do see myself as a versatile PT.",
          "strapLine1": "Unlock your inner athlete:    See it  Believe it  Achieve it",
          "facebookPage": "SBAFIT",
          "offers": "TBC",
          "phone": {
            "number": "7908085752",
            "code": "+44"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4KAPZW3r-filename.jpeg"
        },
        "inherit_from_parent": true,
        "score": 74,
        "parent": {
          "$oid": "58d252cc3fa3e80afcabcaaf"
        }
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": true,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1500822728099,
      "agent": {
        "family": "Samsung Internet",
        "major": "5",
        "minor": "4",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/5.4 Chrome/51.0.2704.106 Mobile Safari/537.36"
      },
      "__v": 1
    },
    {
      "_id": {
        "$oid": "5976ed25f3425e317853151d"
      },
      "lastUpdated": {
        "$date": "2018-01-12T18:45:29.326Z"
      },
      "createdAt": 1500966181418,
      "providerId": "10155580051569605",
      "provider": "facebook",
      "surname": "Almond",
      "firstName": "Karl",
      "email": "karlalmond@live.co.uk",
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155580051569605/",
          "disciplines": [
            "Boxing",
            "Functional Training",
            "Movement",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "Spartan Coach",
            "IKSA Kettlebell Coach",
            "Cancer Rehabilition"
          ],
          "locations": [
            {
              "geolocation": [
                -3.018890699999929,
                51.66167720000001
              ],
              "city": "Pontrhydyrun",
              "postcode": "NP44 1UU",
              "_id": {
                "$oid": "5a590249765eba094646f57a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 46
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": "Personal Trainer",
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AjVqbioQ-beth-trueman-and-training-partner.jpg",
            "size": {},
            "offset": 0
          },
          "facebookPage": "CoacnNuttyPT",
          "yearsOfExp": 2010,
          "affiliation": "CIMSPA",
          "strapLine1": "Take Your Fitness To New Heights, using proven methods & avoiding fads & expensive promises.",
          "offers": "10 pack £180\n4 pack £90\n1 session £30",
          "biography": "Pt bio\nMy name is Karl I'm a Personal Trainer, Group Fitness Trainer And Obstacle Coach\nMy pet hate is seeing people being caught up in a system of yo-yo dieting, minimal calorie intake and hours of mindless cardio. \nIt's unnecessary, not conducive to achieving any kind of significant results and most importantly it is not sustainable in the long term!\nA keen sportsperson from a young age I had always considered myself in relatively good shape, after a long career in the military, i realised I have a passion for seeing someone achieve their goals,\nNutrition is a key block in the foundations to achieving fitness, happiness & self belief,\n\nI love the variation, challenge and empowerment I get from training and after finding several ways to make to make whole foods just as good as my favourite meals, I was hooked! Oh and the results were pretty pleasing too!\n\nWhy choose Sustained Results?\n- I aim to inspire and motivate my clients into achieving their ideal physique. I train hard and practice what I preach.\n- Education. You are more likely to stick to the plan if you understand why you are doing something rather than simply being told to do it. I will share my experience, knowledge and lessons learnt with you so you don't have to make the same mistakes.\n- Results driven. I want you to succeed \nI don't offer a quick fix, but what I do offer is a platform for you to gain your picture perfect physique, while gaining the knowledge and experience to make your results last a lifetime.",
          "phone": {
            "number": "7720831167",
            "code": "+44"
          },
          "instagram": {
            "access_token": "1089299256.8640130.9a326f2fe77442b480eadf4d303df201",
            "link": "https://www.instagram.com/coachnuttypt"
          },
          "twitter": {
            "accessToken": "264318670-XgeHdLshMBmsi01MDnoZFKzYdBpFao1DA7RJEmPB",
            "accessTokenSecret": "fQphZX7HyOzYicBYOymokWhqjQ6qUwhc0tdwFo02fIUNS",
            "link": "https://twitter.com/CoachNuttyPT",
            "screen_name": "CoachNuttyPT",
            "user_id": "264318670"
          }
        },
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fKCFsKsf-profile.jpg"
        },
        "inherit_from_parent": true,
        "score": 83,
        "parent": null
      },
      "role": "trainer",
      "activation_code": "X",
      "active": true,
      "approved": true,
      "activation_email_sent": false,
      "welcome_email_sent": true,
      "reminder_email_sent": false,
      "num_votes": 1,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "username": "karl_almond",
      "lastLogin": 1515781883743,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "__v": 16
    },
    {
      "_id": {
        "$oid": "5976f87df3425e317853151f"
      },
      "lastUpdated": 1500969085318,
      "createdAt": 1500969085318,
      "email": "j-e-p-t@hatmail.co.uk",
      "firstName": "Joe",
      "surname": "Eldridge",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "e8f2ffa6b7f96f68d14b98f0d63171acc55a57e5",
      "salt": "04cCH214Ji2JGp7PiPLAPg==",
      "hashedPassword": "YeyO+nzEMAH8D0UTzdZU2nEJQb6BgTECYw7x9JxDCKgRJHRA3pDcuM0F+/F2fdyyF2HMwk1Wka3MDxNe/DSb7g==",
      "settings": {
        "wizard": {
          "start": false,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "locations": [],
          "timezone": "Europe/London",
          "gender": "male",
          "price": null,
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null
        },
        "inherit_from_parent": false
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1500969086041,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 10_3_2 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.6490.1 Version/10.0 Mobile/14F89 Safari/602.1"
      }
    },
    {
      "_id": {
        "$oid": "5977837ff3425e3178531521"
      },
      "lastUpdated": 1501004742501,
      "createdAt": 1501004671796,
      "email": "matthewruiz@hotmail.co.uk",
      "firstName": "Matthew",
      "surname": "Ruiz",
      "promoCode": "PTEARLY",
      "role": "trainer",
      "provider": "local",
      "activation_code": "3f32caac1fb7cbc8a8bfc60548dce58a3c7a606b",
      "salt": "0Y74Yjo4SjMrIsWeOcrtuQ==",
      "hashedPassword": "jgMOdhTjw/2sSHHCi94PvYFHWL4y2VR8fLV4gn3aVA7wvuN6PTal9iuCE+1I644tAQ8zygSt93XDM2mghUgTmw==",
      "settings": {
        "wizard": {
          "start": true,
          "additional": false,
          "social": false,
          "qualifications": false,
          "complete": false
        },
        "hideWelcomeMessage": false,
        "hideDashboardTour": false,
        "hideFreeTrialMessage": false,
        "hideCalendarIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideAssessmentsIntroVideo": false,
        "language": "en",
        "type": "individual",
        "billing": {
          "defaultCurrency": "GBP",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/"
        },
        "personal": {
          "disciplines": [],
          "qualifications": [],
          "locations": [
            {
              "geolocation": [
                -0.10375517277632264,
                51.49977648250423
              ],
              "country": "United Kingdom",
              "city": "London",
              "postcode": "SE1 0RX",
              "primary": true
            }
          ],
          "timezone": "Europe/London",
          "gender": "male",
          "price": {
            "min": 40
          },
          "companyName": null,
          "companyWebsite": null,
          "profileTitle": null,
          "radius": 10,
          "phone": {
            "code": "+44",
            "number": "7557382318"
          }
        },
        "inherit_from_parent": false,
        "score": 22
      },
      "active": false,
      "approved": false,
      "activation_email_sent": false,
      "welcome_email_sent": false,
      "reminder_email_sent": false,
      "num_votes": 0,
      "trainer_tools_access": false,
      "feature_in_area": "none",
      "lastLogin": 1501004672061,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_2_1 like Mac OS X) AppleWebKit/602.4.6 (KHTML, like Gecko) Version/10.0 Mobile/14D27 Safari/602.1"
      },
      "username": "matthew_ruiz"
    },
    {
      "_id": {
        "$oid": "597a31bb3a9fa50ca931f0c9"
      },
      "email": "andyjem@googlemail.con",
      "firstName": "Andrzej",
      "surname": "Jemiolo",
      "promoCode": "PTEARLY",
      "activation_code": "04efcac57e5227da32261df1f8cbb2da20c183da",
      "salt": "hw3JOJhie9dmRtckOattIg==",
      "hashedPassword": "7yRZxqdeLYrPIUe9l3g7FoVVLgSaUlm/IkfEv26rQrl7859GPfLwGBu8yWSDVZ7dvPsYMXXmZ29E7be7F3tj3A==",
      "lastUpdated": {
        "$date": "2017-08-04T12:00:01.247Z"
      },
      "createdAt": {
        "$date": "2017-07-27T18:32:27.772Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": false,
      "__v": 0,
      "lastLogin": 1501180347914
    },
    {
      "_id": {
        "$oid": "597a40a83a9fa50ca931f0cb"
      },
      "email": "sam@shapesmithstraining.com",
      "firstName": "Samantha",
      "surname": "Edgar",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "lDVqbVm67FNIa9q3oAoxQg==",
      "hashedPassword": "YeUNf1bdItV6tE6puBHyuFPene3NT4vPTX5NpE0ve6ZSAYOYy6Hw5W56A5Guym9qb3xZYDU8N6B6wXhtJi230w==",
      "lastUpdated": {
        "$date": "2017-07-28T12:37:13.224Z"
      },
      "createdAt": {
        "$date": "2017-07-27T19:36:08.773Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7932691071"
          },
          "locations": [
            {
              "city": "Haslemere",
              "geolocation": [
                -0.7133730000000469,
                51.090856
              ],
              "postcode": "GU27 2AZ",
              "_id": {
                "$oid": "597a40fe3a9fa50ca931f0cc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Ante & Post Natal",
            "TRX and Suspension Training",
            "Boxing Padwork",
            "Bootcamp and Circuits"
          ],
          "disciplines": [
            "Boxing",
            "Hiit",
            "Mobile Trainer",
            "Pre/post Natal",
            "Weight Loss"
          ],
          "price": {
            "min": 40
          },
          "radius": 20,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ig5vBvnQ-Banner_Panorama4_1.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "Shapesmithstraining",
          "instagram": {
            "access_token": "2987387871.8640130.9f1b0b13c6854c978a01a969e934ebe3",
            "link": "https://www.instagram.com/shapesmithstraining"
          },
          "twitter": {
            "accessToken": "752595482862612480-fbUrqwr0Qk46XUsRDIangfcHRRoPNtU",
            "accessTokenSecret": "7LD2rHhaUnjNmdA617DS5tyev4J0L847cQZHYAX7WKyeF",
            "link": "https://twitter.com/shapesmiths_t",
            "screen_name": "shapesmiths_t",
            "user_id": "752595482862612480"
          },
          "affiliation": "REP's",
          "yearsOfExp": 2016,
          "strapLine1": "A friendly and bespoke Personal Training service, helping you achieve your body shape goals",
          "biography": "Hi there, my name is Sam. Welcome to Shapesmiths Training. \n \nI've always been passionate about fitness, exercise and well-being so, following the birth of my son, and looking for greater work/life balance, I decided to leave my full time career in Investment Banking to set up Shapesmiths Training. I've never looked back!  \n​\nI'm a fully qualified Personal Trainer and I am passionate about empowering people through exercise to help you achieve the body shape you want and deserve. \n​\nWith my knowledge and experience, whatever your aspirations, whether that's losing weight, toning up, increasing strength, improving endurance, boosting your general well-being or wanting to de-stress... I'll be on hand all the way to provide you with support, motivation and a training plan tailored specifically to your needs. \n​\n Contact me for a no-obligation consultation. \n \nFirst training session is free!\n\nThanks\nSam",
          "offers": "Contact me for a no obligation consultation. Your first session is free. \n\nI offer a number of different packages so get in touch."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nKaQgiqR-fullsizeoutput_1845.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 84
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 24,
      "lastLogin": 1501184168932,
      "username": "samantha_edgar"
    },
    {
      "_id": {
        "$oid": "597f708d3a9fa50ca931f0dd"
      },
      "providerId": "10155443114584437",
      "surname": "Nickerson",
      "firstName": "Amanda",
      "email": "amanda_nickerson@hotmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-01T10:17:05.511Z"
      },
      "createdAt": {
        "$date": "2017-07-31T18:01:49.743Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155443114584437/",
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "code": "+1",
            "number": "6047257727"
          },
          "locations": [
            {
              "city": "Vancouver",
              "geolocation": [
                -123.10613582036703,
                49.254909748744204
              ],
              "postcode": "V5Y 2A3",
              "_id": {
                "$oid": "597f70e33a9fa50ca931f0de"
              },
              "country": "Canada",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Yoga Teacher",
            "Level 3: Personal Trainer",
            "Level 2: Group Exercise to Music Instructor",
            "Level 3: Nutrition",
            "Level 3: Online"
          ],
          "disciplines": [
            "Personal Training",
            "Recipes",
            "Sports Nutrition",
            "Strength & Conditioning",
            "Yoga"
          ],
          "price": {
            "min": 100
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/O0vXH3rM-bigstock-Smoothies--97804160-3.jpg"
          },
          "profileTitle": "Nutritionist",
          "facebookPage": "soulsportsnutrition",
          "google": "https://plus.google.com/101430512439165306588",
          "instagram": {
            "access_token": "252753173.8640130.4ae2042090694ddda6752bf82a2d173a",
            "link": "https://www.instagram.com/soulsportsnutrition"
          },
          "twitter": {
            "accessToken": "133534866-foEzSatIYnF9telhXW5vKTU88Ef5pg3mSxfZdRWk",
            "accessTokenSecret": "osh5zsQnqSkHUSG0qB0BegsgVmmCdUoFNpxBx6bVJFWt4",
            "link": "https://twitter.com/wholefoodbliss",
            "screen_name": "wholefoodbliss",
            "user_id": "133534866"
          },
          "affiliation": "ACSM, The Canadian School of Natural Nutrition",
          "yearsOfExp": null,
          "biography": "So, are you finally ready to put yourself first and transform your health & fitness?  Well, you've come to the right place.\n\nLet's get this out of the way first.  I am a Registered Holistic Nutritionist, ACSM Personal Trainer, Yoga & Spin Instructor.  As you can see, I am passionate about health and fitness.  This isn't just my passion, it's my life mission to empower women to become the absolute best version of themselves by eating well and living well.\n\nI truly believe that anyone can be an athlete.  It doesn't matter what your current level of fitness or experience.  It's never too late to take control of your health.  I want you to understand that your health is in your own hands and your own terms.  You know your body best and your body wants to give it's best when given the right tools.  We are all unique and therefore have varying nutritional needs.  What works for one, definitely does not work for all.",
          "offers": "Visit https://soulsportsnutrition.com/shop/ to view all of my packages and offerings!"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "CAD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NtiCj1Rf-yogahome.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 71
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 17,
      "username": "amanda_nickerson_S1I9leTUW",
      "lastLogin": 1501524110101
    },
    {
      "_id": {
        "$oid": "598012753a9fa50ca931f0e0"
      },
      "email": "sport4everinfo@gmail.com",
      "firstName": "Zoltan",
      "surname": "Kiss",
      "promoCode": "PTEARLY",
      "activation_code": "43fe8517bb72aed2c19de45262e88c83516c29b7",
      "salt": "5+jQKOpE5lBgl2faYZmrUg==",
      "hashedPassword": "ztDnCWg4D2Hj71CFHig9PbxW3+xL5sC23itjIalVVn3yQwumQOp2e7fe3Sxt+raBYf5AwRyNvWVpcllMN2GtPA==",
      "lastUpdated": {
        "$date": "2017-08-01T17:35:22.275Z"
      },
      "createdAt": {
        "$date": "2017-08-01T05:32:37.126Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "36306134834"
          },
          "locations": [
            {
              "city": "Debrecen",
              "geolocation": [
                21.62731229999997,
                47.5316049
              ],
              "postcode": "4024",
              "_id": {
                "$oid": "598014753a9fa50ca931f0e1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Level 4: Tutor / Assessor",
            "Level 3: Exercise Referral Instructor",
            "Recreation"
          ],
          "disciplines": [
            "Core Fitness",
            "Functional Training",
            "Group Classes",
            "Martial Arts",
            "Mobile Trainer"
          ],
          "price": {
            "min": 20
          },
          "radius": 30,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tgcx2iPE-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "biography": "¤ Rekreáció Sportok Oktatása ¤ Sportprogramok Tervezése ¤ Sport Edzések Vezetése \n¤ Sportrendezvények szervezése. ¤ Különleges Csoportoknak Különleges Edzés,",
          "affiliation": "Freelancer",
          "yearsOfExp": 2007
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "EUR",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/p3uHrrur-N%C3%A9vtelen%209.bmp.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 59,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 11,
      "lastLogin": 1501565557456,
      "username": "zoltan_kiss"
    },
    {
      "_id": {
        "$oid": "598072a33a9fa50ca931f0e3"
      },
      "email": "kieron_rsf@live.co.uk",
      "firstName": "Kieron",
      "surname": "Franklin",
      "promoCode": "PTEARLY",
      "activation_code": "962d4a15d901ab60662b411726d3a0192074f648",
      "salt": "L+WV5eergh5L+sRTSBWBjQ==",
      "hashedPassword": "+PFY47ztSAqlafXJdKFDGggXZP4WB2AYEh/x2wsN5/M+2eULwzzCp/MlUevk7zzVg1obJUgmwPtatMlliA98Iw==",
      "lastUpdated": {
        "$date": "2018-01-18T00:27:17.955Z"
      },
      "createdAt": {
        "$date": "2017-08-01T12:22:59.681Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7919530778",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.9858930000000328,
                50.7282665
              ],
              "city": "Poole",
              "postcode": "BH15 3AH",
              "_id": {
                "$oid": "5a5fe9e5765eba094646f702"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR"
          ],
          "disciplines": [
            "Mobile Trainer",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 35
          },
          "radius": 40,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZbJzLUeh-JKH1_transparent.png"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154890174875765/",
          "facebookPage": "jkhousetrainingcentre",
          "google": "https://plus.google.com/112946015931235624516",
          "instagram": {
            "access_token": "3580833971.8640130.cb70040b136741e2a9e82e840ec95b7d",
            "link": "https://www.instagram.com/teamjkhouse"
          },
          "twitter": {
            "accessToken": "3415981349-1LoRDpU71W2MXfF06jVO6pIJQZmE1p2tSNlLCDz",
            "accessTokenSecret": "0b10je5HbwBNkpkUcEYXVPdnczAgRNK84xkDERkFUrEZj",
            "link": "https://twitter.com/JkCentre",
            "screen_name": "JkCentre",
            "user_id": "3415981349"
          },
          "affiliation": "Reps",
          "yearsOfExp": 2016,
          "biography": "Why I Became A Professional Personal Trainer\n \nHaving seen what the human body can endure while serving in the British Army, I became fascinated about how it can be pushed to the limits by everyday people like you and I to achieve near on superhuman abilities. Before I joined I would never of thought of being able to run for as long and as fast as I did. I had no real understanding of fitness before then, I knew what a gym was but wasn’t to sure on what to do once I got there, not very many people were willing to help back then either. Through trial and error, sometimes big errors, I found my way, researching the science behind training gave me a huge advantage above everyone else in the gym, understanding not only how the body works but why it works that way, I gave myself the upper had to manipulate it to achieve the results I got and quick as I got them, from bulking up to shredding body fat, to becoming a cardio machine to building muscle, each journey took lots of research and understanding, I was addicted!\nNow I present the opportunity to change your body into what you want it to be, keeping up the giggles and laughter as are training sessions go, increasing your limits, driving you forward, always at your side. What better feeling is there from getting someone from a ‘Yeah right’ to a ‘HELL YEAH!’\n​\nEvery client of mine brings their own rewards, their own development, I wouldn’t be the trainer I am today if it wasn’t for the people I have helped and continue to help.",
          "offers": "Free Consultation\nFree Taster Session\n\nTriall 6 weeks package!"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tizh1Kt2-639240622926090.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 77
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 1,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 18,
      "lastLogin": 1501921953568,
      "username": "kieron_franklin"
    },
    {
      "_id": {
        "$oid": "5980e22a3a9fa50ca931f0f5"
      },
      "surname": "Clark",
      "firstName": "Dan",
      "email": "danclarkbespokept@yahoo.com",
      "providerId": "4452284266",
      "username": "dan_clark",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-02T05:35:14.105Z"
      },
      "createdAt": {
        "$date": "2017-08-01T20:18:50.896Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "4452284266.8640130.3d38c7f7a466495e8a1a1d13f807b898",
            "link": "https://www.instagram.com/danclarkpt",
            "username": "danclarkpt"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7542250865"
          },
          "locations": [
            {
              "city": "London",
              "geolocation": [
                -0.07814669495180014,
                51.52240935405855
              ],
              "postcode": "E1 6PJ",
              "_id": {
                "$oid": "5980e26a3a9fa50ca931f0f6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Indoor cycling",
            "bootcamp",
            "circuits",
            "advanced fitness testing"
          ],
          "disciplines": [
            "Bodybuilding",
            "Body Sculpting",
            "Circuit Training",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 60
          },
          "radius": 5,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tC7b7sS9-IMG_1414.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "Danclarkpt",
          "affiliation": "REPS",
          "yearsOfExp": 2017,
          "offers": "One-to-one coaching available \nSemi-private sessions available\nGroup sessions available \nNutrition plans available\nWorkout plans available\nOnline coaching available\nBlock package deals available",
          "biography": "Enthusiastic trainer with a great deal of knowledge and expertise in fitness industry. Client satisfaction is paramount and my aim is to give my clients exactly what the desire to make them happy ! Firm believer that hard work combined with scientifically proven training methods yields optimal results"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FZ5Emp95-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 58
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 28,
      "lastLogin": 1501652114084
    },
    {
      "_id": {
        "$oid": "5981aa2a3a9fa50ca931f0fb"
      },
      "email": "jc_stepsup@hotmail.co.uk",
      "firstName": "Jordan",
      "surname": "Christides",
      "promoCode": "PTEARLY",
      "activation_code": "9e270da5c7905c3129052d73845225defe05cf0f",
      "salt": "2LPfikeDJ3t9wV0twkfPaw==",
      "hashedPassword": "f/jsktJaUP5FTltrkST4mdbnNB7DeeOeuiJydCahnzZFCsykbSE+uY7NXaGrx0lUbbGNOg4nv2pf4MQCV09cKg==",
      "lastUpdated": {
        "$date": "2017-08-03T17:31:07.641Z"
      },
      "createdAt": {
        "$date": "2017-08-02T10:32:10.085Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7702259025"
          },
          "locations": [
            {
              "city": "London",
              "geolocation": [
                -0.12775829999998223,
                51.5073509
              ],
              "postcode": "WC2N 5DU",
              "_id": {
                "$oid": "5981aa823a9fa50ca931f0fc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Level 3: Advanced Personal Trainer",
            "Advance Boxing Instructor",
            "Kettlebell Instructor",
            "Circuit Trainer",
            "Group Exercise",
            "Nutritional Advisor"
          ],
          "disciplines": [
            "Bodybuilding",
            "Boxing",
            "Circuit Training",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 50
          },
          "radius": 10,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/f3mV9v4V-IMG_1762.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "www.facebook.com/beastlypt",
          "instagram": {
            "access_token": "4236684966.8640130.14cc601cf54742058bd42081088e78c7",
            "link": "https://www.instagram.com/beastlypt"
          },
          "affiliation": "REPs",
          "yearsOfExp": 2016,
          "offers": "£50 per hour - Once a week\n£80 per week - Four times a week.\nPrices are negotiable.",
          "biography": "I am a passionate, ambitious and enthusiastic individual with the ability to help people achieve their goals. Dedication and determination are traits that have been embedded in me since I was young, this is mostly due to being in sports; which has made me confident in any environment. Fitness for me is a necessity that provides many possibilities akin to mental and physical health and needless to say, I take it very seriously."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/L4Yy2ZWa-IMG_1761.JPG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 69,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 15,
      "lastLogin": 1501669930208,
      "username": "jordan_christides"
    },
    {
      "_id": {
        "$oid": "5981d23f3a9fa50ca931f0fd"
      },
      "surname": "Cvetanovic",
      "firstName": "Aleksandar",
      "email": "scateam.aleksandar@gmail.com",
      "providerId": "546402878",
      "username": "aleksandar_cvetanovic",
      "activation_code": "fc653ca372ed58ab1787da09fac22350dbb47a35",
      "lastUpdated": {
        "$date": "2018-01-18T00:20:06.131Z"
      },
      "createdAt": {
        "$date": "2017-08-02T13:23:11.759Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "546402878.8640130.f11a5714d0b64dd389354c23f6419f56",
            "link": "https://www.instagram.com/salac7",
            "username": "salac7"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "790181531",
            "code": "+46"
          },
          "locations": [
            {
              "geolocation": [
                18.068580800000063,
                59.32932349999999
              ],
              "city": "Stockholm",
              "postcode": "111 52",
              "_id": {
                "$oid": "5a5fe836765eba094646f700"
              },
              "country": "Sweden",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Master Personal Trainer",
            "Strength and Conditioning coach",
            "Fitness coach",
            "https://totalworkout.fitness/"
          ],
          "disciplines": [
            "Functional Training",
            "Hiit",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 60
          },
          "radius": 80,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AZz7Sr26-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10212533288634645/",
          "yearsOfExp": 2010,
          "biography": "Experienced Certified Personal Trainer with a demonstrated history of working in the health wellness and fitness industry. Skilled in Weight Training, Strength Training, Coaching, Fitness Training, and Personal Training. Strong community and social services professional with a Master of Science - MS focused in Sports Studies from Faculty of Sport and Tourism TIMS. Head coah at SCA Strength and Conditioning Coaching, Head coach at https://totalworkout.fitness/",
          "google": "https://plus.google.com/108187909536477452493",
          "offers": "Online coaching and mentoring\n\n600 SEK / 60€  -  1h training\n6000 SEK / 600€ - 12 trainings\n8000 SEK / 800€ - 16 trainings\n10000 SEK / 1000€ - 20 trainings\n\nOnline coaching / mentoring\n5000 SEK / 500€ monthly"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "EUR",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/juJAn9Vo-IMG_0004.JPG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 73
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 60,
      "lastLogin": 1504188032088,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.0 Mobile/14G60 Safari/602.1"
      }
    },
    {
      "_id": {
        "$oid": "5981e3553a9fa50ca931f102"
      },
      "providerId": "1433103186781632",
      "surname": "Godchaux",
      "firstName": "Jacob",
      "email": "jace3x7@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-09T14:38:39.415Z"
      },
      "createdAt": {
        "$date": "2017-08-02T14:36:05.523Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1433103186781632/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xGc9CH0x_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": true,
      "__v": 0,
      "username": "jacob_godchaux",
      "lastLogin": 1501684565814
    },
    {
      "_id": {
        "$oid": "5982ad9f3a9fa50ca931f104"
      },
      "email": "championtdepoe@yahoo.com",
      "firstName": "Ted",
      "surname": "Depoe",
      "promoCode": "PTEARLY",
      "activation_code": "0c775ffb63f6f4c5e05eb0251b92ef19e48124c4",
      "salt": "WIDvm5Xl0Evrn5S5ZxeroQ==",
      "hashedPassword": "CrSbJHhFEfETkCSt1kfJCtPLUZhCWVUinMRbdtTLCdLxJz8+S3xyU/3wDZDyRQFvPf+0ZlkQq35lDdxcBi+26w==",
      "lastUpdated": {
        "$date": "2018-04-25T16:39:47.521Z"
      },
      "createdAt": {
        "$date": "2017-08-03T04:59:11.521Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "3608883330",
            "code": "+1"
          },
          "locations": [
            {
              "city": "Olympia",
              "geolocation": [
                -122.90069510000001,
                47.0378741
              ],
              "postcode": "98501",
              "_id": {
                "$oid": "5982aeea3a9fa50ca931f105"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Strength & Conditioning",
            "Level 4: Master Personal Trainer",
            "Level 4: Lower Back Pain Instructor",
            "Level 3: Nutrition"
          ],
          "disciplines": [
            "Mobile Trainer",
            "Rehabilitation",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 70
          },
          "radius": 15,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xcGvvywf-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "Bachelor of Arts with Emphasis in Sports Medicine",
          "yearsOfExp": 1998,
          "offers": "I'm a mobile personal trainer.  I'll discuss personal training prices with you that will be determined by the package that you purchase.",
          "biography": "-USA Powerlifting 4 Time Washington State Men's Open Champion\n-1995 A.A.U. Junior Olympic Powerlifting Gold Medalist\n-Set 181 Pound Weight Class A.A.U. Ages (18-19) Powerlifting American Record with a 500 Pound Deadlift weighing 176 Pounds (Record has since been broken)\n-1998 USA Powerlifting Junior (ages 20-23) National Championships 3rd Place\n-1999 USA Powerlifting Junior (ages 20-23) National Champion in Lincoln, Nebraska\n-1999 USA Powerlifting I.P.F. Junior World Team Member in the Czech Republic\n-8th Place at the 1999 I.P.F. Junior (ages 14-23) World Championships\n-2000 Wrestling West Regional Olympic Trials Competitor at Oregon State University\n-Set USA Powerlifting Junior (ages 20-23) Washington State Records in the 220 pound weight class weighing 216 pounds with a 633 pound Squat, 644 pound Deadlift also weighing 216 pounds and set the total record at 1686 pounds.  All of these records were set in Lincoln, Nebraska in June 1999 at the USA Powerlifting Junior National Championships as the Washington State Junior Records (ages 20-23).  These records were retired in 2014 due to new weight classifications within USA Powerlifting."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WscNql6P-BENCH.JPG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 17,
      "lastLogin": 1501822167426,
      "username": "ted_depoe",
      "password_reset_code": "X"
    },
    {
      "_id": {
        "$oid": "598340723a9fa50ca931f106"
      },
      "providerId": "1616502835057390",
      "surname": "Bjj",
      "firstName": "Giba",
      "email": "gibateamjiu@yahoo.com.br",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-14T14:54:21.259Z"
      },
      "createdAt": {
        "$date": "2017-08-03T15:25:38.781Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1616502835057390/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+55",
            "number": "11954447316"
          },
          "locations": [
            {
              "city": "São Paulo",
              "geolocation": [
                -46.63330940000003,
                -23.5505199
              ],
              "postcode": "01016-020",
              "_id": {
                "$oid": "598340f43a9fa50ca931f107"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Master in Brazilian Jiu Jitsu",
            "Jiu Jitsu & MMA Coach"
          ],
          "disciplines": [
            "Functional Training",
            "Martial Arts",
            "Sports Therapy"
          ],
          "price": {
            "min": 60
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5ynGcDUr-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "biography": "I give seminars and classes for Self Defense Police Teachers manage my team in the world"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pjuXdWZS-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": true,
          "start": true
        },
        "score": 55
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 6,
      "username": "giba_bjj",
      "lastLogin": 1501773939240
    },
    {
      "_id": {
        "$oid": "598397e73a9fa50ca931f10a"
      },
      "email": "gaz@parafituk.com",
      "firstName": "Gaz",
      "surname": "Kennedy",
      "promoCode": "PTEARLY",
      "activation_code": "79ce70e38fdbae6b0f63613c727f00124e16512d",
      "salt": "bkR0qKybwp060c59Fs7Lrw==",
      "hashedPassword": "tNMvcDqmEDynPntMHrZb4TQXj1q4+G44NawbN28y85u3/CRDBOhTpe+9Lb6IFHhJ5mo19F37uOj8UoptdWhqNA==",
      "lastUpdated": {
        "$date": "2017-08-11T12:00:00.193Z"
      },
      "createdAt": {
        "$date": "2017-08-03T21:38:47.293Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": false,
      "__v": 0,
      "lastLogin": 1502285953610
    },
    {
      "_id": {
        "$oid": "59845f4e3a9fa50ca931f111"
      },
      "email": "biyuotz@gmail.com",
      "firstName": "coach",
      "surname": "vergel",
      "promoCode": "PTEARLY",
      "activation_code": "fae7ca78e332e5c96c249f47e6ac5355c89f3b5d",
      "salt": "3Ro0nEJPqtPe8ZLTLYIlIw==",
      "hashedPassword": "IZPbAhrn1cHjwTNHyy9D6K9QxQwrs+nzQW9rHDscevgg3CFfijWFgQYZsA3uH05lUo2Idc2r07vK7YFFMGRvTg==",
      "lastUpdated": {
        "$date": "2017-08-11T12:00:00.170Z"
      },
      "createdAt": {
        "$date": "2017-08-04T11:49:34.998Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+971",
            "number": "559067646"
          },
          "locations": [
            {
              "city": "Dubai",
              "geolocation": [
                55.26469840159029,
                25.19900134048921
              ],
              "_id": {
                "$oid": "59845fc23a9fa50ca931f112"
              },
              "country": "United Arab Emirates",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 50
          },
          "radius": 1
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 22
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": false,
      "__v": 3,
      "lastLogin": 1501847375314,
      "username": "coach_vergel"
    },
    {
      "_id": {
        "$oid": "598574ae3a9fa50ca931f114"
      },
      "email": "tara.t.buckley@gmail.com",
      "firstName": "Tara",
      "surname": "Buckley",
      "promoCode": "PTEARLY",
      "activation_code": "3b7c598f3baee8eea592ad5bc1554bba8ac8f0cd",
      "salt": "5SYP+M6MQYg/kbymfFj+0A==",
      "hashedPassword": "ttD19zpMSitq2/PBn7QxNLxZGLC/CC0q9MABNmRqlWeWxa9VpVVBVYgMu4w8NymfX+TpQxFXMaqfFIQhWV7OFA==",
      "lastUpdated": {
        "$date": "2017-08-07T05:42:45.481Z"
      },
      "createdAt": {
        "$date": "2017-08-05T07:33:02.664Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "code": "+61",
            "number": "406347837"
          },
          "locations": [
            {
              "city": "Melbourne",
              "geolocation": [
                144.96305600000005,
                -37.81361100000001
              ],
              "postcode": "3004",
              "_id": {
                "$oid": "5985750a3a9fa50ca931f115"
              },
              "country": "Australia",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 4: Advanced Personal Trainer"
          ],
          "disciplines": [
            "Group Classes",
            "Hiit",
            "Mobile Trainer",
            "Online Coaching",
            "Weight Loss"
          ],
          "price": {
            "min": 50
          },
          "radius": 15,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/yiXYsNS3-image_9e7b7706-6a60-4d4d-82b9-c946e20cc61c20170619_105056.jpg"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "2882745929.8640130.d306ff703eca49a180c206cc17e10d86",
            "link": "https://www.instagram.com/tarabuckley_pt"
          },
          "biography": "☆Certified Personal Trainer☆BeyondBlue Mental health advocate☆Asthma Australia advocate☆"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "AUD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JXRX7oL5-IMG_20170510_100956_382.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 55
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 7,
      "lastLogin": 1501918383256,
      "username": "tara_buckley"
    },
    {
      "_id": {
        "$oid": "59894c1f3a9fa50ca931f121"
      },
      "surname": "Salvador",
      "firstName": "Carole",
      "email": "1420682377@email.com",
      "providerId": "1420682377",
      "username": "carole_salvador",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-15T12:00:00.568Z"
      },
      "createdAt": {
        "$date": "2017-08-08T05:29:03.534Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1420682377.8640130.7532afe2101b4352b726a623a0a7f736",
            "link": "https://www.instagram.com/carole_salvador_",
            "username": "carole_salvador_"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3NLtSXXW_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "instagram",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": false,
      "__v": 0,
      "lastLogin": 1502170143952
    },
    {
      "_id": {
        "$oid": "598a1ddb3a9fa50ca931f125"
      },
      "email": "hello@wellfitconcierge.com",
      "firstName": "Jenn",
      "surname": "Menzer",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "cZJ9mHcomNwbT/3/q5GAkg==",
      "hashedPassword": "sfmA0iCNWoBaXBUuWHgFtYrhHRaJbDLEMOmY5LVrKKoD0ZHvE8yBQSKmppZDw5TYxmYfXDM6g2E1OCJUrRCTew==",
      "lastUpdated": {
        "$date": "2017-08-09T08:38:42.733Z"
      },
      "createdAt": {
        "$date": "2017-08-08T20:23:55.523Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "code": "+1",
            "number": "6178337880"
          },
          "locations": [
            {
              "city": "Phoenix",
              "geolocation": [
                -111.99448632852754,
                33.512456214371724
              ],
              "postcode": "85018",
              "_id": {
                "$oid": "598a1e283a9fa50ca931f126"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Master Personal Trainer",
            "Certified Master Trainer",
            "Integrative Nutrition Coach",
            "Functional Strength Coach",
            "Pilates Teacher",
            "Yoga Teacher",
            "TRX",
            "Pre/Post Natal",
            "Meditation",
            "Kettlebells",
            "HIIT",
            "Bootcamp"
          ],
          "disciplines": [
            "Mobile Trainer",
            "Nutrition",
            "Online Coaching",
            "Pre/post Natal",
            "Yoga"
          ],
          "price": {
            "min": 85
          },
          "radius": 6,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6XUM4SyY-jenn_1506.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "wellfitconcierge/",
          "instagram": {
            "access_token": "179720044.8640130.738e796e6c0c4d06ac35a6e9acf92aeb",
            "link": "https://www.instagram.com/jennmenzer"
          },
          "twitter": {
            "accessToken": "214713914-25lwMbX7Mqoi0zP8J4RmAAjAC7twaysqIJlEywMj",
            "accessTokenSecret": "0FSCMVHrXPEpudO5nz4KF4uN9AgLLUGsZCKLJli6t8Zvx",
            "link": "https://twitter.com/JennMenzer",
            "screen_name": "JennMenzer",
            "user_id": "214713914"
          },
          "affiliation": "AFAA, STOTT, YA, Annette Lang, TRX",
          "yearsOfExp": 2003,
          "strapLine1": "Motivation Accountability Support",
          "biography": "I am a wellness expert offering remote and on-site Fitness & Nutrition Coaching. Pulling from over 14 years of experience, 15 (and counting!) diverse fitness certifications, an M.S. in Counseling Psychology, and an Integrative Nutrition Coaching practice, I create truly customized and effective programming that will change your life. Part coach, concierge, educator, and 100% motivator; I am your greatest health-enabler. You’ll also get a healthy dose of humor and delightfully direct banter to keep you motivated and entertained all while I sneakily propel you through plateaus.\n\nARE YOU READY TO GET STRONGER & FEEL MORE ALIVE?\n\nAs a total wellness expert, I tailor each session to your unique needs and goals. Improving fitness and nutrition should be dynamic, exciting and fun. Changing up your routine is the only way to keep your body improving, and your mind engaged.\n\nI love working with clients of all ages and goals, and particularly enjoy working with clients new to exercise, expectant and new mothers, pre and post injury rehabilitation, and clients seeking to improve flexibility and mobility in addition to strength!\n\nYou deserve to feel comfortable in your own skin, and to walk through your life strong and confident. I am here to support and encourage that vision in a way that keeps you interested, motivated and engaged. We'll have fun when you sweat, I promise.",
          "offers": "$85 per session \n$800 for package of 10"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EYg8ibmx-jenn_6512.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 61
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 13,
      "lastLogin": 1502223835873,
      "username": "jenn_menzer"
    },
    {
      "_id": {
        "$oid": "598ae03f3a9fa50ca931f127"
      },
      "providerId": "969566055774",
      "surname": "Ryan",
      "firstName": "Lynsay",
      "email": "lynsay.ryan@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-18T00:16:25.484Z"
      },
      "createdAt": {
        "$date": "2017-08-09T10:13:19.679Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/969566055774/",
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "3092997527",
            "code": "+1"
          },
          "locations": [
            {
              "geolocation": [
                -87.629818,
                41.8956265
              ],
              "city": "Chicago",
              "postcode": "60654",
              "_id": {
                "$oid": "5a5fe759765eba094646f6ff"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Master Personal Trainer",
            "Level 3: Older Adults Instructor",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR",
            "Level 2: Aqua Teacher"
          ],
          "disciplines": [
            "Bootcamp",
            "Core Fitness",
            "Flexibility",
            "Functional Training",
            "Hiit"
          ],
          "price": {
            "min": 80
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RIyEi0hP-16194877_903701134604_6726257021325012866_n.jpg"
          },
          "profileTitle": "Personal Trainer",
          "google": "https://plus.google.com/101063839072130542020",
          "instagram": {
            "access_token": "184224842.8640130.f4d772b4a91e46e58a8ea8e87bdf8589",
            "link": "https://www.instagram.com/chicago_house_call_fitness"
          },
          "affiliation": "ACE",
          "yearsOfExp": 2010,
          "strapLine1": "Chicago House Call Fitness brings the gym to you!",
          "biography": "For me, an exercise regimen is the key ingredient of a healthy lifestyle and it should be the initial element to create a daily outlook that is productive in all elements of one's life. Through regular exercise, one will gain a more positive view of oneself and others. As one sees changes for the better, in a physical way, mental and emotional improvements will naturally follow. With an improved outlook, the world in which one interacts will be seen more positively. The need for negative perceptions will be lessened. The glass will be seen as half-full through a steady pattern of self-improvement. Better eating habits, including wiser nutritional choices, and clearer decisions of what goes into one's body will develop as a part of the entire process."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ifcT8fgb-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 77
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 15,
      "username": "lynsay_ryan",
      "lastLogin": 1502273600098
    },
    {
      "_id": {
        "$oid": "598c5e9fe78c0276217fbe12"
      },
      "providerId": "10154769248626024",
      "surname": "Samat",
      "firstName": "Shafiq",
      "email": "shafiq.samat@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-11T11:18:05.456Z"
      },
      "createdAt": {
        "$date": "2017-08-10T13:24:47.323Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154769248626024/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+65",
            "number": "90889498"
          },
          "locations": [
            {
              "city": "Singapore",
              "geolocation": [
                103.86774439999999,
                1.3553794
              ],
              "postcode": "556762",
              "_id": {
                "$oid": "598c5f27e78c0276217fbe13"
              },
              "country": "Singapore",
              "primary": true
            }
          ],
          "qualifications": [
            "BSc Exercise and Sports Science"
          ],
          "disciplines": [
            "Circuit Training",
            "Martial Arts",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 100
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DflCGcWq-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "180382218.8640130.bbb6e2def7fd44db8063859802f27ea6",
            "link": "https://www.instagram.com/mightymusclemeraki"
          },
          "yearsOfExp": 2015,
          "biography": "Meraki [may-rah-kee] (adjective) (Greek) - doing something with soul, creativity, and love - when you put “something of yourself” into what you're doing, whatever it may be.\n\nNusantara (noun) (Javanese) - Nusa means \"island\", Antara means \"in between\". Archipelago.\n\nMeraki x Nusantara Fitness is a Muay Thai, Kickboxing and Fitness Bootcamp enthusiast located at the sunny east-side of Singapore.\n\nWe are not professionals in Muay Thai or any other martial arts, neither do we compete at any levels, but we are like-minded enthusiast in martial arts that would like to impart our knowledge and the appreciation of martial arts to make one better in self-defense and being fit."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wrJ1CIJ2-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 63
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 6,
      "username": "shafiq_samat",
      "agent": {
        "family": "Chrome Mobile",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G950F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.125 Mobile Safari/537.36"
      },
      "lastLogin": 1502371487770
    },
    {
      "_id": {
        "$oid": "598ca3ffe78c0276217fbe14"
      },
      "providerId": "10213749637083852",
      "surname": "Cotton",
      "firstName": "Michelle",
      "email": "ctmikki@hotmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-11T07:07:55.676Z"
      },
      "createdAt": {
        "$date": "2017-08-10T18:20:47.911Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10213749637083852/",
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "code": "+1",
            "number": "2035217824"
          },
          "locations": [
            {
              "city": "Milford",
              "geolocation": [
                -73.06403599999999,
                41.2306979
              ],
              "postcode": "06460",
              "_id": {
                "$oid": "598ca431e78c0276217fbe15"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Online"
          ],
          "disciplines": [
            "Bootcamp",
            "Functional Training",
            "Hiit",
            "Online Coaching",
            "Weight Loss"
          ],
          "price": {
            "min": 60
          },
          "radius": 15,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/84hCdAWb-20170808_120842.jpg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "ISSA",
          "yearsOfExp": 2014,
          "biography": "I am in my 40s, have a child with a rare medical disease called SCADD,  and have lost 80lbs on my own.  I am now a personal trainer and body composition specialist, have all of my CPR, RT, OH, and FN training and certification.   I specialize in weight loss, kettlebells, race coaching, and body composition testing.",
          "offers": "Email me for packages at miksagilityfitness@gmail.com\nIf you are at a plateau, training for a specific sport, or don't know how to start, come to Live Lean RX for body composition testing using DXA, resting metabolic rate and VO2Max testing.  \nKnow EXACTLY what your baseline is.  \nYou can get your body fat %, LEAN muscle, bone and fat amounts, down to the ounce, in each aspect of your body.  \nFind out if your arms or legs are very different.  Get your visceral fat amount, which is the indicator for diabetes and heart disease.   \nFind out your TRUE numbers, what your real health status is, and what you have to work on, depending on your goals.  \nWe can then refer you to a doctor, personal trainer, whatever you need, to help you.\nThree simple tests, 10-30 min each, for as low as $58 per test!  And, you can use your pretax HSA account!\nHere's is our current groupon as well:  \nDXA Body Scan, VO2 Cardio Fitness Reading, or RMR Metabolic Health Test at Live Lean Rx (Up to 34% Off)\nhttps://www.groupon.com/deals/live-lean-rx?utm_source=&utm_campaign=UserReferral_ma\nCall me at 203.693.1513 or email michelle@liveleanrx.com for any questions, or book today at www.liveleanrx.com.  If there are no time slots that work for you, give us a call, and we will help you!\nBuy a package, and share with friends or family.  It's that simple!  We can't wait to hear from you!\nLike our Facebook page for updates on specials: https://www.facebook.com/liveleanmilfordct/"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EnknaFHm-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 68
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 16,
      "username": "michelle_cotton",
      "agent": {
        "family": "Chrome Mobile",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G950U Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.125 Mobile Safari/537.36"
      },
      "lastLogin": 1502389248360
    },
    {
      "_id": {
        "$oid": "598d053de78c0276217fbe17"
      },
      "email": "ja3ja3@hotmail.co.uk",
      "firstName": "Jae",
      "surname": "Green",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "OvedsjV99Ys4QMBdaAhm0g==",
      "hashedPassword": "59M/iqrKcUN/4ktne27DmMu0DjnxVkQxhtVzX7a9ildT86XweibjqpT/6z99EVuIDSlnIw8MlXvHtRXJpTmaFw==",
      "lastUpdated": {
        "$date": "2018-01-18T00:14:59.528Z"
      },
      "createdAt": {
        "$date": "2017-08-11T01:15:41.534Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7982902976",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.187830399999939,
                53.158172
              ],
              "city": "Mansfield Woodhouse",
              "postcode": "NG19 9AL",
              "_id": {
                "$oid": "5a5fe703765eba094646f6fe"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 2: Gym Instructor",
            "Level 5 Nutritional Therapist",
            "Diet & Metabolism Specialist"
          ],
          "disciplines": [
            "Bodybuilding",
            "Nutrition",
            "Online Coaching",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ikojn9BJ-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1501483073244331/",
          "facebookPage": "jaegreenuk",
          "affiliation": "REPS",
          "yearsOfExp": 2017,
          "biography": "I am a REPS Level 2 & 3 YMCA qualified gym instructor and personal trainer.\n\nI am also a level 5 qualified nutritional therapist & diet & metabolism specialist.\n\nI am currently studying sports nutrition and due to start a level 4-6 degree in sports, fitness and coaching.\n\nI also work closely with BodyPower and BPI Sports UK and I am very passionate about fitness.\n\nDuring my spare time I am training to compete as a physique athlete."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Szdlmuf0-20170729_015922.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": {
          "$oid": "596f46d9f3425e317853150d"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 20,
      "agent": {
        "family": "Facebook",
        "major": "136",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G955F Build/NRD90M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/59.0.3071.125 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/136.0.0.22.91;]"
      },
      "lastLogin": 1502414471010,
      "username": "jae_green"
    },
    {
      "_id": {
        "$oid": "598d59f4e78c0276217fbe1a"
      },
      "email": "steph@parafituk.com",
      "firstName": "ParaFit UK",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "activation_code": "75bb13145ca7e8c0cdb6c38e9eaf9c312e282152",
      "salt": "5CeXgPBg1FB79+Qms78kcQ==",
      "hashedPassword": "FsmypEsaJ2MBTbRcrjYgyj0ljVidQmTFD9HbmD62VYyZuZU9293pIZLWH/dPf1QQbPmLluWd1HzPVefpJCSOEQ==",
      "lastUpdated": {
        "$date": "2017-08-11T14:01:35.608Z"
      },
      "createdAt": {
        "$date": "2017-08-11T07:17:08.786Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "1252333614"
          },
          "locations": [
            {
              "city": "Farnborough",
              "geolocation": [
                -0.7380421000000297,
                51.2834656
              ],
              "postcode": "GU14 6PQ",
              "_id": {
                "$oid": "598d5a7fe78c0276217fbe1b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [
            "All Abilities",
            "Competetive Games",
            "Military Style",
            "Personal Training",
            "Weight Loss"
          ],
          "price": null,
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/QfG7SJ8q-parafitbg.png"
          },
          "companyName": "ParaFit UK",
          "companyWebsite": "http://www.para-fit.com/",
          "profileTitle": "Boot Camp",
          "facebook": null,
          "facebookPage": "ParaFitUk",
          "strapLine1": "Elite Military Bootcamps: your first Session is free - just turn up",
          "biography": "ParaFit is one of the best outdoor fitness companies today: it is the no-nonsense training programme created around The Elite Parachute Regiment's fitness training programme.\n\nThis elite-style fitness training is designed to take your fitness to levels you never thought were possible.  The hard-style approach and dedication of our elite physical training instructors is paramount.\n\nWith this in mind, and your 100 percent commitment, YOU WILL achieve your goals, whether it is for weight loss or to push yourself that little bit harder.\n\nParaFit will not only help you build an elite functional fitness, strength, stamina, durability, endurance and mental toughness, but we believe that hard physical exercise builds character and self confidence for a better life.\n\nThese sessions can be physically demanding and can be carried out in military style boot camp groups or if you feel that you would like the personal touch we can offer you sessions on a one to one basis.",
          "offers": "Your first session is FREE - no need to book, just turn up.\n\nWe have a flexible payment structure to suit everyone. Just look at the options available:\n\nUNLIMITED SESSIONS:\nUnlimited sessions at 1 park £24.99 per month\nUnlimited sessions at all parks £29.99 per month\n\nKEY WORKERS:\nUnlimited sessions at 1 park £19.99 per month\nUnlimited sessions at all parks £24.99 per month\n\nBLOCK SESSIONS:\nBlock of 10 sessions to use at any park £100\nBlock of 20 sessions to use at any park £190\n\n10 FOR 10\n\nGrab yourself 10 sessions at any of our boot camps for only £10:\n\nDownload the ParaFit app to your smartphone or device to purchase these sessions.  Once you have the app you can then book on to your future sessions.\n\n1-2-1 Personal training: \n\nPlease call 01252 333 614 or email Steph@parafituk.com for details",
          "places": "Bootcamps across the UK\n\nAndover: SP10 3LF\nCharlton just off the A343 by the Sports Academy\n\nBasingstoke: RG21 4AG\nWar Memorial Park, Crossborough Hill\n\nBracknell: RG40 3EE\nBy Great Hollands Park, South Road, opposite East hampstead Park Cemetery & Crematorium\n\nFarnborough: GU14 6PQ\nAt King George V Playing Fields\n\nFleet: GU51 4AD\nCalthorpe Park (near Fleet Cricket Club),Reading Road North\n\nGuilford: GU1 1TU\nOff the A25 Parkway Road follow the signs for Guildford Spectrum\n\nNewbury: RG14 1RS \nNorthcroft and Goldwell Parks, Northcroft Lane\n\nSalisbury: SP1 3SA \nHudson’s Field, Castle Road\n\nSouthampton: SO16 8FA \nLordshill Recreation Ground, Redbridge Lane\n\nWinchester: SO23 7DD \nGordon Road\n\nWoking: GU21 2JH\nWaterers Park, Knaphill, Woking\n\nPlease call 01252 333 614 or email Steph@parafituk.com for details",
          "testimonials": "''Can’t speak highly enough of this company!  All the instructors are brilliant, pushing you well beyond your limit, whilst always having your best interest at heart!  They want you to achieve your best in every session, and push your fitness through the roof.  Every session is different, with new circuits, exercises and layout style regularly added.''\n\n''I would encourage anyone to do their first free session and you will see how addictive it is!  The results are brilliant and will keep you coming back!  ParaFit isn’t just an exercise class; it’s a family!  I have made friendships for life, one of the best decisions I made a year ago to join!''\n\nGeorgie-Rose Palmer\n\n''With a relaxed atmosphere & really good set up with plenty of staff to keep you motivated and equipment to keep you interested.  The sessions are varied and provide a fantastic workout; both cardio and strength.  The other members there are enthusiastic, friendly & provide encouragement when you need that little push.''\n\nJason Prior\n\n''One of the best workouts and trainers I have ever had, and I have tried them all.  My body changed in only two weeks and I have much more energy.  Try and see for yourself.''\n\nRachael Price\n\n''Superb workout, changes every session, pushes you but not too far.  Great instructors, sociable group, excellent value.  5 months in, wish I’d started ages ago!''\n\nNeil Abbott"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YN9vzz9l-parafitlogo.jpg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 68
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 52,
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.90 Safari/537.36"
      },
      "lastLogin": 1502442798790,
      "username": "parafit_uk"
    },
    {
      "_id": {
        "$oid": "598d93f5e78c0276217fbe1d"
      },
      "email": "para.basingstoke@shoomon.xyz",
      "firstName": "Basingstoke",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "Aln20X0UbOF3VPIqBzTTMQ==",
      "hashedPassword": "sWKUbf+RY3qvNDSEhjOFAX4ROhfWTNDnfrv0eKPqJMk3rTLsxBf+zhZzsvnhtJ2L7hy/q3vO/PKMHsLC8a5Szw==",
      "lastUpdated": {
        "$date": "2017-08-11T14:03:05.381Z"
      },
      "createdAt": {
        "$date": "2017-08-11T11:24:37.437Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "1252333614"
          },
          "locations": [
            {
              "city": "Basingstoke",
              "geolocation": [
                -1.079394500000035,
                51.2604526
              ],
              "postcode": "RG21 4AG",
              "_id": {
                "$oid": "598d946be78c0276217fbe1e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "disciplines": [
            "All Abilities",
            "Competetive Games",
            "Military Style",
            "Personal Training",
            "Weight Loss"
          ],
          "price": null,
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/p1V3gmRK-parafitbg.png"
          },
          "companyName": "ParaFit Outdoor Fitness Basingstoke",
          "companyWebsite": "http://www.para-fit.com/location/basingstoke/",
          "profileTitle": "Boot Camp",
          "facebookPage": "ParaFitUk",
          "strapLine1": "Elite Military Bootcamps: your first Session is free - just turn up",
          "biography": "ParaFit is one of the best outdoor fitness companies today: it is the no-nonsense training programme created around The Elite Parachute Regiment's fitness training programme.\n\nThis elite-style fitness training is designed to take your fitness to levels you never thought were possible. The hard-style approach and dedication of our elite physical training instructors is paramount.\n\nWith this in mind, and your 100 percent commitment, YOU WILL achieve your goals, whether it is for weight loss or to push yourself that little bit harder.\n\nParaFit will not only help you build an elite functional fitness, strength, stamina, durability, endurance and mental toughness, but we believe that hard physical exercise builds character and self confidence for a better life.\n\nThese sessions can be physically demanding and can be carried out in military style boot camp groups or if you feel that you would like the personal touch we can offer you sessions on a one to one basis.\nShow Less",
          "offers": "Your first session is FREE - no need to book, just turn up.\n\nWe have a flexible payment structure to suit everyone. Just look at the options available:\n\nUNLIMITED SESSIONS:\nUnlimited sessions at 1 park £24.99 per month\nUnlimited sessions at all parks £29.99 per month\n\nKEY WORKERS:\nUnlimited sessions at 1 park £19.99 per month\nUnlimited sessions at all parks £24.99 per month\n\nBLOCK SESSIONS:\nBlock of 10 sessions to use at any park £100\nBlock of 20 sessions to use at any park £190\n\n10 FOR 10\n\nGrab yourself 10 sessions at any of our boot camps for only £10:\n\nDownload the ParaFit app to your smartphone or device to purchase these sessions. Once you have the app you can then book on to your future sessions.\n\n1-2-1 Personal training:\n\nPlease call 01252 333 614 or email Steph@parafituk.com for details",
          "places": "Basingstoke Session Times:\n\nMonday & Wednesday 19.00 - 20.00\nSaturday 09.30 -10.30\n\nGrid Reference: SU 64334 51657\n\nWar Memorial Park\nCrossborough Hill\nBasingstoke\nRG21 4AG\n\nHow to find us:\n\nFrom Festival Place, follow Festival Way which turns into New Road.  Take a left onto London Road and you will see the Memorial Park on your right.\n\nFrom the A30 head South on the Ringroad.  When you reach Hackwood Roundabout take the 3rd exit onto Hackwood Road then turn right onto London Road.  The Memorial Park will be on your right.\n\nPlease call 01252 333 614 or email Steph@parafituk.com for details",
          "testimonials": "''Can’t speak highly enough of this company!  All the instructors are brilliant, pushing you well beyond your limit, whilst always having your best interest at heart!  They want you to achieve your best in every session, and push your fitness through the roof.  Every session is different, with new circuits, exercises and layout style regularly added.''\n\n''I would encourage anyone to do their first free session and you will see how addictive it is!  The results are brilliant and will keep you coming back!  ParaFit isn’t just an exercise class; it’s a family!  I have made friendships for life, one of the best decisions I made a year ago to join!''\n\nGeorgie-Rose Palmer\n\n''With a relaxed atmosphere & really good set up with plenty of staff to keep you motivated and equipment to keep you interested.  The sessions are varied and provide a fantastic workout; both cardio and strength.  The other members there are enthusiastic, friendly & provide encouragement when you need that little push.''\n\nJason Prior\n\n''One of the best workouts and trainers I have ever had, and I have tried them all.  My body changed in only two weeks and I have much more energy.  Try and see for yourself.''\n\nRachael Price\n\n''Superb workout, changes every session, pushes you but not too far.  Great instructors, sociable group, excellent value.  5 months in, wish I’d started ages ago!''\n\nNeil Abbott"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hNA6KdOl-parafitlogo.jpg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 73,
        "parent": {
          "$oid": "598d59f4e78c0276217fbe1a"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 26,
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.90 Safari/537.36"
      },
      "lastLogin": 1502450677574,
      "username": "parafit_outdoor_fitness_basingstoke"
    },
    {
      "_id": {
        "$oid": "598d9b5de78c0276217fbe20"
      },
      "email": "para.fleet@shoomon.xyz",
      "firstName": "Fleet",
      "surname": "Enquiries",
      "promoCode": "PTEARLY",
      "activation_code": "1647eb3a24a29db6e7edebd4abaa55b58869489a",
      "salt": "e+UX080GB3pRDAcb3kTdPQ==",
      "hashedPassword": "aExkJWVp7wCkskXHP+myFH4uOvipUNbeMcvqD26N3uKsyshi341yVkseVBni8drG96X4/icdRgyysXY1O84xiw==",
      "lastUpdated": {
        "$date": "2017-08-11T14:07:39.357Z"
      },
      "createdAt": {
        "$date": "2017-08-11T11:56:13.907Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "1252333614"
          },
          "locations": [
            {
              "city": "Fleet",
              "geolocation": [
                -0.847228800000039,
                51.2785036
              ],
              "postcode": "GU51 4AD",
              "_id": {
                "$oid": "598d9b9ce78c0276217fbe21"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "disciplines": [
            "All Abilities",
            "Competetive Games",
            "Military Style",
            "Personal Training",
            "Weight Loss"
          ],
          "price": null,
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tffXrsYc-parafitbg.png"
          },
          "companyName": "ParaFit Outdoor Fitness Fleet",
          "companyWebsite": "http://www.para-fit.com/location/fleet/",
          "profileTitle": "Boot Camp",
          "facebookPage": "ParaFitUk",
          "biography": "ParaFit is one of the best outdoor fitness companies today: it is the no-nonsense training programme created around The Elite Parachute Regiment's fitness training programme.\n\nThis elite-style fitness training is designed to take your fitness to levels you never thought were possible. The hard-style approach and dedication of our elite physical training instructors is paramount.\n\nWith this in mind, and your 100 percent commitment, YOU WILL achieve your goals, whether it is for weight loss or to push yourself that little bit harder.\n\nParaFit will not only help you build an elite functional fitness, strength, stamina, durability, endurance and mental toughness, but we believe that hard physical exercise builds character and self confidence for a better life.\n\nThese sessions can be physically demanding and can be carried out in military style boot camp groups or if you feel that you would like the personal touch we can offer you sessions on a one to one basis.\nShow Less",
          "testimonials": "''Can’t speak highly enough of this company!  All the instructors are brilliant, pushing you well beyond your limit, whilst always having your best interest at heart!  They want you to achieve your best in every session, and push your fitness through the roof.  Every session is different, with new circuits, exercises and layout style regularly added.''\n\n''I would encourage anyone to do their first free session and you will see how addictive it is!  The results are brilliant and will keep you coming back!  ParaFit isn’t just an exercise class; it’s a family!  I have made friendships for life, one of the best decisions I made a year ago to join!''\n\nGeorgie-Rose Palmer\n\n''With a relaxed atmosphere & really good set up with plenty of staff to keep you motivated and equipment to keep you interested.  The sessions are varied and provide a fantastic workout; both cardio and strength.  The other members there are enthusiastic, friendly & provide encouragement when you need that little push.''\n\nJason Prior\n\n''One of the best workouts and trainers I have ever had, and I have tried them all.  My body changed in only two weeks and I have much more energy.  Try and see for yourself.''\n\nRachael Price\n\n''Superb workout, changes every session, pushes you but not too far.  Great instructors, sociable group, excellent value.  5 months in, wish I’d started ages ago!''\n\nNeil Abbott",
          "offers": "Your first session is FREE - no need to book, just turn up.\n\nWe have a flexible payment structure to suit everyone. Just look at the options available:\n\nUNLIMITED SESSIONS:\nUnlimited sessions at 1 park £24.99 per month\nUnlimited sessions at all parks £29.99 per month\n\nKEY WORKERS:\nUnlimited sessions at 1 park £19.99 per month\nUnlimited sessions at all parks £24.99 per month\n\nBLOCK SESSIONS:\nBlock of 10 sessions to use at any park £100\nBlock of 20 sessions to use at any park £190\n\n10 FOR 10\n\nGrab yourself 10 sessions at any of our boot camps for only £10:\n\nDownload the ParaFit app to your smartphone or device to purchase these sessions. Once you have the app you can then book on to your future sessions.\n\n1-2-1 Personal training:\n\nPlease call 01252 333 614 or email Steph@parafituk.com for details",
          "places": "Fleet Session Times:\n\nTuesdays & Thursdays: 19.00 - 20.00\nSunday: 10.00 -11.00\n\nGrid Reference: SU 80259 53797\n\nCalthorpe Park (near Fleet Cricket Club)\nReading Road North\nFleet\nHants\nGU51 4AD\n\nHow to find us:\n\nFrom Farnborough, head towards Fleet High Street, travel through the High Street, once at the end (by The Oatsheaf) turn right at the traffic lights onto Reading Road North, then take your first left just before the pedestrian crossing. This is Calthorpe Park where we are located.\n\nPlease call 01252 333 614 or email Steph@parafituk.com for details",
          "strapLine1": "Elite Military Bootcamps: your first Session is free - just turn up"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5gf5Px5i-parafitlogo.jpg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 66,
        "parent": {
          "$oid": "598d59f4e78c0276217fbe1a"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 25,
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.90 Safari/537.36"
      },
      "lastLogin": 1502452574050,
      "username": "parafit_outdoor_fitness_fleet"
    },
    {
      "_id": {
        "$oid": "5990196596b4835f108dfa45"
      },
      "surname": "Yoseef",
      "firstName": "Shaimaa",
      "email": "shosho1982.ss@gmail.com",
      "providerId": "1241173286",
      "username": "shaimaa_yoseef",
      "activation_code": "91888874c7b1421672923f8ce06e78b12866a97f",
      "lastUpdated": {
        "$date": "2017-09-12T09:38:48.006Z"
      },
      "createdAt": {
        "$date": "2017-08-13T09:18:29.778Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1241173286.8640130.c28e7a1f33ef42d89cb9f65031dd9cc9",
            "link": "https://www.instagram.com/shimoshimozzz",
            "username": "shimoshimozzz"
          },
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "code": "+20",
            "number": "1006209917"
          },
          "locations": [
            {
              "city": "New Cairo City",
              "geolocation": [
                31.491318200000023,
                30.00741300000001
              ],
              "_id": {
                "$oid": "59901ad896b4835f108dfa46"
              },
              "country": "Egypt",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 25
          },
          "radius": 0
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/v2cpET6n_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 25
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "instagram",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": false,
      "__v": 8,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "lastLogin": 1505209127969
    },
    {
      "_id": {
        "$oid": "5990528696b4835f108dfa47"
      },
      "providerId": "10212533288634645",
      "surname": "Cvetanovic",
      "firstName": "Aleksandar",
      "email": "salac_master@live.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-21T12:21:05.478Z"
      },
      "createdAt": {
        "$date": "2017-08-13T13:22:14.592Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10212533288634645/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kIUU6Z1U_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": true,
      "__v": 0,
      "username": "aleksandar_cvetanovic_rky5fRpPW",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1503318065439
    },
    {
      "_id": {
        "$oid": "5992c81e96b4835f108dfa48"
      },
      "surname": "Knight",
      "firstName": "Anthony",
      "email": "aoknight13@googlemail.com",
      "providerId": "10999219",
      "username": "anthony_knight",
      "activation_code": "3435d4b87f1bbd2113f4c2d156bf7590469887ee",
      "lastUpdated": {
        "$date": "2017-08-15T20:02:57.728Z"
      },
      "createdAt": {
        "$date": "2017-08-15T10:08:30.656Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "10999219.8640130.f25b9771c1a44cc88db43f0297f62e7e",
            "link": "https://www.instagram.com/mr_iron_warrior",
            "username": "mr_iron_warrior"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7746325683"
          },
          "locations": [
            {
              "city": "Huddersfield",
              "geolocation": [
                -1.7728647,
                53.6744997
              ],
              "postcode": "HD2 1NL",
              "_id": {
                "$oid": "5992c89296b4835f108dfa49"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Endurance",
            "Functional Training",
            "Hiit",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 25
          },
          "radius": 5,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/iljG7kTA-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "twitter": {
            "accessToken": "161855954-e6784Pwxml76yRsycXx9rpBKs2Kt62ZZrMLomM6n",
            "accessTokenSecret": "xhToHw02huyvHU2iurLuObl6KV91nlk3jCh5FSmksKqDx",
            "link": "https://twitter.com/Mr_Iron_Warrior",
            "screen_name": "Mr_Iron_Warrior",
            "user_id": "161855954"
          },
          "yearsOfExp": 2011,
          "biography": "Sports enthusiast,  Rugby league for 27yrs.  Love to help others reach their potentials",
          "offers": "Contact for info"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0I6NYBZ3-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 58
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 13,
      "agent": {
        "family": "Chrome Mobile",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.125 Mobile Safari/537.36"
      },
      "lastLogin": 1502791710915
    },
    {
      "_id": {
        "$oid": "5995d06396b4835f108dfa5e"
      },
      "surname": "Cordero",
      "firstName": "Freddy",
      "email": "32111549@email.com",
      "providerId": "32111549",
      "username": "freddy_cordero",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-25T12:00:00.600Z"
      },
      "createdAt": {
        "$date": "2017-08-17T17:20:35.664Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "32111549.8640130.cf4c0ef00f414c76a841a81af5d0451d",
            "link": "https://www.instagram.com/thefitcook",
            "username": "thefitcook"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4bSKDsnc_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "instagram",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": false,
      "__v": 0,
      "agent": {
        "family": "Safari",
        "major": "10",
        "minor": "1",
        "patch": "2",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_6) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.1.2 Safari/603.3.8"
      },
      "lastLogin": 1502990436057
    },
    {
      "_id": {
        "$oid": "5999ac1a96b4835f108dfaec"
      },
      "email": "psychic-gina@hotmail.com",
      "firstName": "gina",
      "surname": "Hudson psychic",
      "promoCode": "PTEARLY",
      "activation_code": "d6ea72aa2be0a0a59b7c68fbc220d792f294ab71",
      "salt": "ADFwFSFzObom0olI8N9acQ==",
      "hashedPassword": "CY/zIUsCR4ECZXPu54uRiuTc9hX1ppS5lHDX2HN6qe6jBhJ5PKZAt3MiIu50VimM7qc1yTHy6I92YQCeI7E8CQ==",
      "lastUpdated": {
        "$date": "2017-08-28T12:00:00.657Z"
      },
      "createdAt": {
        "$date": "2017-08-20T15:34:50.129Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": false,
      "__v": 0,
      "agent": {
        "family": "Mobile Safari UI/WKWebView",
        "major": "9",
        "minor": "3",
        "patch": "5",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 9_3_5 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Mobile/13G36 Twitter for iPhone"
      },
      "lastLogin": 1503243292918
    },
    {
      "_id": {
        "$oid": "599acd3696b4835f108dfaf2"
      },
      "providerId": "107574125864511202653",
      "surname": "Desmond",
      "firstName": "Henry",
      "email": "hjdesmond54@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-29T12:00:00.624Z"
      },
      "createdAt": {
        "$date": "2017-08-21T12:08:22.793Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/107574125864511202653",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Hk4lGNp7_profilePicture.png"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "google",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": true,
      "__v": 0,
      "username": "henry_desmond",
      "agent": {
        "family": "Chrome Mobile",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; SM-J320V Build/MMB29M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.125 Mobile Safari/537.36"
      },
      "lastLogin": 1503317303343
    },
    {
      "_id": {
        "$oid": "599c5e3896b4835f108dfb02"
      },
      "surname": "Brookes",
      "firstName": "Mike",
      "email": "mikebrookespt@gmail.com",
      "providerId": "1822070979",
      "username": "mike_brookes",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-18T00:13:30.263Z"
      },
      "createdAt": {
        "$date": "2017-08-22T16:39:20.970Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1822070979.8640130.15cb0959df344c9da232958813220c19",
            "link": "https://www.instagram.com/mikebrookespt",
            "username": "mikebrookespt"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "447841473796",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.2426305000000184,
                53.4807593
              ],
              "city": "Manchester",
              "postcode": "M2 4WU",
              "_id": {
                "$oid": "5a5fe6aa765eba094646f6fd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Les Mills GRIT"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Hiit",
            "Online Coaching"
          ],
          "price": {
            "min": 25
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wKZGlxd8-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1835052333491079/",
          "yearsOfExp": 2015,
          "offers": "10 x PT Sessions £285\n5 x PT Sessions £170",
          "biography": "20 years experience in fitness from hiit training, to bodybuilding."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/35q3S3u7-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 64
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 17,
      "agent": {
        "family": "Samsung Internet",
        "major": "5",
        "minor": "4",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/5.4 Chrome/51.0.2704.106 Mobile Safari/537.36"
      },
      "lastLogin": 1503419961286
    },
    {
      "_id": {
        "$oid": "599c917396b4835f108dfb04"
      },
      "surname": "Moore",
      "firstName": "Aarin",
      "email": "aarinfit30@gmail.com",
      "providerId": "191582034",
      "username": "aarin_moore_ifbbpro",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-23T18:01:54.512Z"
      },
      "createdAt": {
        "$date": "2017-08-22T20:17:55.242Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "191582034.8640130.b7e0538fb0314008b889cf328654d16d",
            "link": "https://www.instagram.com/aarin_moore_ifbbpro",
            "username": "aarin_moore_ifbbpro"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+1",
            "number": "9519415035"
          },
          "locations": [
            {
              "city": "Houston",
              "geolocation": [
                -95.39121530627622,
                29.77727141170221
              ],
              "postcode": "77007",
              "_id": {
                "$oid": "599c92e596b4835f108dfb05"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "NASM",
            "ISSA",
            "Nesta certified"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Functional Training",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 90
          },
          "radius": 20,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xgGv9h6f-IMG_5181.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1687664744586281/",
          "affiliation": "Labrada Nutrition",
          "yearsOfExp": 2008,
          "biography": "With twelve years of training experience in fitness studio's, corporate gyms and in-home clients, I have helped clients from all walks of life reach their fitness goals. From 12 year old kids to my eldest client of 85 years old helping people improve the quality of their lives and progress to their absolute goal is a very rewarding feeling and why I love my career. With experience as a professional bodybuilder, years of managing Personal Training programs and training new personal trainers to be the best they can be, I am very confident with my help, together we can get you to any and all health and fitness goals you seek. If you are serious about your results, I am the trainer for you.",
          "offers": "Private 30 minute sessions - 50 per session \n\nPrivate 55 minute sessions - 90 per session \n\nSemi private group training - 25 per session (12 session minimum)"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eotj7nHg-IMG_5206.JPG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 69
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 18,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1503445256252
    },
    {
      "_id": {
        "$oid": "599da9cf96b4835f108dfb11"
      },
      "email": "nicole@wegym.co.uk",
      "firstName": "Nicole",
      "surname": "C",
      "promoCode": "PTEARLY",
      "activation_code": "f6b720c60aa1f9a5a7622e8a3b2d2a80542c3bc0",
      "salt": "8eSIqA1yuKd2CEN8yr6/kA==",
      "hashedPassword": "I3XgvPgw7fZrtLtu66jZD+5pq0bxz5MsN93hkbqLwbZSQ62POYELrTOBMvW2QEz5wA6PL6jq4gLL1YX/xhSn7g==",
      "lastUpdated": {
        "$date": "2017-08-31T12:00:00.636Z"
      },
      "createdAt": {
        "$date": "2017-08-23T16:14:07.234Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": false,
      "__v": 0,
      "agent": {
        "family": "Chrome Mobile iOS",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (iPad; CPU OS 10_3_3 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) CriOS/60.0.3112.89 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1503504849505
    },
    {
      "_id": {
        "$oid": "59a0985c96b4835f108dfb12"
      },
      "providerId": "105800910832034023866",
      "surname": "Bolessa",
      "firstName": "Jordon",
      "email": "jordon.bolessa@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-08-26T18:24:12.702Z"
      },
      "createdAt": {
        "$date": "2017-08-25T21:36:28.892Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/105800910832034023866",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7958413685"
          },
          "locations": [
            {
              "city": "Southend-on-Sea",
              "geolocation": [
                0.7092241999999942,
                51.5631484
              ],
              "postcode": "SS2 5SE",
              "_id": {
                "$oid": "59a0990f96b4835f108dfb15"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Mobile Trainer",
            "Online Coaching",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 35
          },
          "radius": 10,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/arqYNfHM-IMG_20170715_110804_613.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "Jordonbolessapt",
          "instagram": {
            "access_token": "1995202280.8640130.d54490acc7824385abb8d2cf9b91f68c",
            "link": "https://www.instagram.com/jordonbolessapt"
          },
          "twitter": null,
          "affiliation": "REPS",
          "yearsOfExp": 2013,
          "facebook": "https://www.facebook.com/app_scoped_user_id/10214166298140769/",
          "biography": "Starting a sporting journey at the age of eleven, Jordon has competed at a high level in basketball and internationally for the Great Britain Lions American Football Team. In the process of balancing studying and competitive sport, his passion for training led him to becoming a Level 3 Personal Trainer in 2013.\n\nCurrently studying for a BSc (Hon) in Sport and Exercise Science and apart of the Do Training Internship programme, this has given him access to elite level athletes, few of which competing at the Olympic and Paralympic Games. This wealth of experience and knowledge allows Jordon to apply the basic principles of training to build a strong foundation with correct functional movement, meeting the needs of every individual to achieve their goals.\n\nJordon specialises in strength & conditioning and performance training from daily life to sports specific. Welcoming any professional to athlete who are always willing to give 100% effort to a better life.\n\n​- BSc (Hon) in Sport and Exercise Science (Currently Studying) \n\n- Level 3 Personal Trainer \n\n- Advanced Apprenticeship in Sporting Excellence",
          "offers": "Pay as you go- £35\nPack of 10- £300\nOnline Training- £40 per month\nOnline 12 Week Transformation- £100\n\n* If you refer a friend then you will win a prize!!!"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0Ipd2q0e-IMG_20170707_204703_698.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 67,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": false,
      "provider": "google",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 21,
      "username": "jordon_bolessa",
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.101 Safari/537.36"
      },
      "lastLogin": 1503764938836
    },
    {
      "_id": {
        "$oid": "59a3f8ec96b4835f108dfb30"
      },
      "email": "jackmacrae1@hotmail.com",
      "firstName": "Jack",
      "surname": "Whitfield",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "+eLKmH6U3WeH2ANuYm6wog==",
      "hashedPassword": "rMYjUJTLSBqLVBqn8JGW77gsCJ24g/Cj++MHb+JqzmyavWzzsHGUabC8oTV/WMxibSCuHjgJ2UZZmZCI/dveJw==",
      "lastUpdated": {
        "$date": "2018-01-18T00:11:22.932Z"
      },
      "createdAt": {
        "$date": "2017-08-28T11:05:16.223Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7527915213",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.2597971,
                55.8650623
              ],
              "city": "Glasgow",
              "postcode": "G2 3EN",
              "_id": {
                "$oid": "5a5fe62a765eba094646f6fc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Core Fitness",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9MpOJqXu-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPS",
          "yearsOfExp": 2013,
          "biography": "Hello everyone! \nI'm Jack and i'm a personal trainer for Xercise 4 Less in Glasgow, \n  I've been in the fitness industry for about 4 years and have trained with all sorts of clients, \n My passion for fitness is very evident, I hope to share that energy with my clients and help them excel in whatever  area they feel their lacking in."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vitzrFDe-jack%20whitfield.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 60,
        "parent": {
          "$oid": "58d15caf3fa3e80afcab9603"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": true,
      "provider": "local",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 20,
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.101 Safari/537.36"
      },
      "lastLogin": 1503918608678,
      "username": "jack_whitfield"
    },
    {
      "_id": {
        "$oid": "59a5c61f96b4835f108dfb32"
      },
      "providerId": "10156553767094746",
      "surname": "Klat",
      "firstName": "Darren",
      "email": "darren1981klat@hotmail.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-09-06T12:00:00.901Z"
      },
      "createdAt": {
        "$date": "2017-08-29T19:53:03.932Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10156553767094746/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jBmRvFet_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": true,
      "__v": 0,
      "username": "darren_klat",
      "agent": {
        "family": "Facebook",
        "major": "137",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 5.1.1; SM-J500FN Build/LMY48B; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/59.0.3071.125 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/137.0.0.24.91;]"
      },
      "lastLogin": 1504036384418
    },
    {
      "_id": {
        "$oid": "59a8332296b4835f108dfb47"
      },
      "providerId": "106428409682108168922",
      "surname": "balogun",
      "firstName": "sarah",
      "email": "sbalogun15@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-09-08T12:00:00.870Z"
      },
      "createdAt": {
        "$date": "2017-08-31T16:02:42.584Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/106428409682108168922",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WKOnv4UR_profilePicture.png"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "google",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "active": true,
      "__v": 0,
      "username": "sarah_balogun",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "lastLogin": 1504195362928
    },
    {
      "_id": {
        "$oid": "59a840da96b4835f108dfb48"
      },
      "surname": "Hayes",
      "firstName": "Kate",
      "email": "londonfit@gmail.com",
      "providerId": "5801245672",
      "username": "kate_hayes",
      "activation_code": "1b4205ed55da73e15a9b241f1472a7cc184530b7",
      "lastUpdated": {
        "$date": "2017-09-03T16:16:54.591Z"
      },
      "createdAt": {
        "$date": "2017-08-31T17:01:14.579Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "5801245672.8640130.dc514254ed0e4f13baedc5a09fd2e8f4",
            "link": "https://www.instagram.com/fullintotraining",
            "username": "fullintotraining"
          },
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7445037544"
          },
          "locations": [
            {
              "city": "London",
              "geolocation": [
                -0.1293589933343262,
                51.431281077924446
              ],
              "postcode": "SW16",
              "_id": {
                "$oid": "59a8411096b4835f108dfb49"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Master Personal Trainer"
          ],
          "disciplines": [
            "Core Stability",
            "Injury Prevention",
            "Injury Rehabilitaion",
            "Personal Training",
            "Sports Specific"
          ],
          "price": {
            "min": 55
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/bb5GAjH3-filename.jpeg"
          },
          "profileTitle": "Sports Therapist",
          "facebookPage": "Londonfit",
          "affiliation": "Cimspa",
          "yearsOfExp": 2010,
          "offers": "30 minutes £35\n45 minutes £45\n60 minutes £55",
          "biography": "I am a qualified Exercise rehabilitation specialist and Personal trainer working in London. \n\nI am passionate about sports, exercise and wellness.\n\nI help individuals reach personal fitness, weight and sports goals while ensuring that they are injury and pain free. I believe that by keeping your body moving and muscles strong one will lead a great quality of life.",
          "strapLine1": "Balanced Mind in a Balanced Body: Londonfit Biokineticist with Kate Hayes"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wrs0TeIM-Kate%20Hayes%20profil.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 77
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "activation_email_sent": false,
      "provider": "instagram",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": false,
      "__v": 16,
      "agent": {
        "family": "Facebook",
        "major": "138",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Mobile/14G60 [FBAN/FBIOS;FBAV/138.0.0.49.91;FBBV/69441604;FBDV/iPhone7,1;FBMD/iPhone;FBSN/iOS;FBSV/10.3.3;FBSS/3;FBCR/Three;FBID/phone;FBLC/en_GB;FBOP/5;FBRV/0]"
      },
      "lastLogin": 1504198876114
    },
    {
      "_id": {
        "$oid": "59aaade396b4835f108dfb4c"
      },
      "providerId": "10159218784910142",
      "surname": "Hoskin",
      "firstName": "Simon",
      "email": "simonh13@me.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-18T00:10:09.967Z"
      },
      "createdAt": {
        "$date": "2017-09-02T13:10:59.283Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10159218784910142/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7498114650",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.8956052,
                51.8606679
              ],
              "city": "Colchester",
              "postcode": "CO2",
              "_id": {
                "$oid": "5a5fe5e1765eba094646f6fb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Online Fitness Services"
          ],
          "disciplines": [
            "Bootcamp",
            "Body Sculpting",
            "Hiit",
            "Online Coaching",
            "Weight Loss"
          ],
          "price": {
            "min": 35
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rOBg9LEB-Screenshot_20170830-172606.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "Sxfitnessonline",
          "yearsOfExp": 2008,
          "biography": "SXFitness are a passionate, motivated fully online personal training portal.\nWe originate from Essex in the UK.\nGiven the growing popularity in fitness and health, it’s becoming a lot harder and expensive to find the right fitness and dietary advice.\nWhatever your goals may be; losing weight, toning up, improving fitness levels, gaining strength or sports specific, our experience and desire will motivate you to achieve your goals.\nWe are big believers of making fitness as fun and enjoyable as possible.\nWe thrive on the results and success of our clients.\nWe are dedicated athlete’s who live and breathe the fitness industry.\n\nWe specialise in providing bespoke fitness and diet plans along with full online support throughout. Each program is designed around you and your lifestyle and we will NOT promise something that is not achievable.\n\nNo plan is ever the same and is made to work for you.\n\nYou tell us your goal and we will help you get there.\n\nAll you need is a desire to change and we will do the rest…",
          "offers": "Support Program\nPerfect if you are new to fitness or have been out of the game for a while.\nProvides you with the basic skills and knowledge to lose fat and build toned muscle.\nPersonalised plan built around your goal and lifestyle for the chosen period of time\nFull meal plan,\nFull online support\nAccess to SXFitness support group\n£ 39.00\n\nProgram\nPerfect for you if you are active on a regular basis but seem to have lost your drive or have plateaued with your current exercise routine.\nHigh intensity training combined with weight workouts to build a perfect physique.\nPersonalised plan built around your goals and lifestyle for the chosen period of time\nPlan split over 2 phases:\nReset your body to burn fat.\nReshape your physique with gender specific strength training.\nFull meal plan\nFull online support\nAccess to SXFitness support group\n£ 59.00\n\nProgram\nDesigned for those who wish to train like a pro, become lean and perform to their absolute best.\nHigh intensity training combined with intense weight workouts to bring body fat down and build ripped lean muscle.\nPersonalised plan built around your goals and lifestyle for the chosen period of time\nPlan split over 3 phases:\nShred surplus body fat in readiness for muscle gain involves medium cardio & high rep weight training.\nStart building toned muscle through low rep high weight training and low cardio.\nTeach you body to bulk muscle\nHigh protein meal plan\nFull online support\nAccess to SXFitness VIP support group",
          "strapLine1": "GET FIT ON THE SPOT No transport time, no gym, no people. Just you – where you want to be."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1CyVIDYq-Screenshot_20170830-191441.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 76
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "feature_in_area": "none",
      "role": "trainer",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "active": true,
      "__v": 11,
      "username": "simon_hoskin",
      "agent": {
        "family": "Facebook",
        "major": "139",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G935F Build/NRD90M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/60.0.3112.107 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/139.0.0.22.93;]"
      },
      "lastLogin": 1504357859774
    },
    {
      "_id": {
        "$oid": "59afce17fd62c74723260302"
      },
      "surname": "Omda",
      "firstName": "Mohamed",
      "email": "simoz.omdaz@gmail.com",
      "providerId": "1386911164",
      "username": "mohamed_omda",
      "activation_code": "e235b6a9ac43c82799f9f5e2019227ff4fa905dd",
      "lastUpdated": {
        "$date": "2018-01-17T23:57:01.100Z"
      },
      "createdAt": {
        "$date": "2017-09-06T10:29:43.392Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1386911164.8640130.8ff1d164062149ac8492bf1156a220c2",
            "link": "https://www.instagram.com/elomda.row",
            "username": "elomda.row"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "96565588958",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                47.48176599999999,
                29.31166
              ],
              "city": "Al Jahra Governorate",
              "_id": {
                "$oid": "5a5fe2cd765eba094646f6fa"
              },
              "country": "Kuwait",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Advanced Group Exercise to Music Instructor",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Endurance",
            "Functional Training",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 35
          },
          "radius": 10,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ftIgVZRy-IMG_3195.PNG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2009,
          "biography": "Mohamed Omda: Crossfit athlete, Fittest man in Egypt 2015,16,17. Evaluated 1th place in battle East Coach@circuit plus.CF@Kwait WL coach Certified personal trainer",
          "strapLine1": ""
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "EUR",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uzj8Xo4E-mohamed%20omda%20pro.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 59,
        "parent": null
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "active": false,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": {
        "$numberLong": "1504693783682"
      },
      "approved_date": {
        "$date": "2017-10-09T18:09:38.378Z"
      }
    },
    {
      "_id": {
        "$oid": "59afea25fd62c74723260304"
      },
      "providerId": "10213668897456873",
      "surname": "Cofman",
      "firstName": "Maurie",
      "email": "acepro@sbcglobal.net",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:56:37.130Z"
      },
      "createdAt": {
        "$date": "2017-09-06T12:29:25.278Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10213668897456873/",
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "3145171565",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -90.3468724,
                38.6288491
              ],
              "city": "Brentwood",
              "postcode": "63144",
              "_id": {
                "$oid": "5a5fe2b5765eba094646f6f9"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Strength & Conditioning",
            "Level 4: Obesity & Diabetes",
            "Level 4: Falls Prevention Instructor",
            "Level 3: Master Personal Trainer",
            "Level 3: Older Adults Instructor",
            "Level 2: First Aid / CPR",
            "Certified Medical Exercise Specialist"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Group Classes",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 75
          },
          "radius": 0,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JTZ1X6fr-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "https://www.facebook.com/mauri",
          "google": "https://plus.google.com/+MaurieCofman",
          "twitter": {
            "accessToken": "27704712-pVyekb0LpyUwW40U6eFr09CaLXHpsXOPYkbJK1Qxo",
            "accessTokenSecret": "G3CG97TLNxbDB3MtiOqsiiAwOZH3NjfbDbb70EgkU4GDA",
            "link": "https://twitter.com/Mauriecofman",
            "screen_name": "Mauriecofman",
            "user_id": "27704712"
          },
          "affiliation": "ACE",
          "yearsOfExp": 1997,
          "biography": "My personal training Company, Healthy Lifestyles with Maurie, LLC focuses on helping you build a fit and strong body so that you can enjoy all the activities and sports you love. Our individualized programs focus on utilizing whole-body movements that develop integrated strength and functional movements so that your body remains pain free and fully functional. At the same time, we help you adopt a healthy lifestyle with health coaching and personal training.\nI believe having a trainer is a team effort-- I provide the tools to use and you provide the effort.\n\"With the rise in the obesity epidemic, I have become passionate about helping people make informed decisions about their exercise and nutrition habits. Because not only will I train you, but I will teach you and help you reach your fitness goals.\"\nIf you've decided it's time to adopt a healthier lifestyle and need some personal help, call me for a free evaluation.",
          "offers": "12 session - $840"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/sphkzCzO-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 77
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 10,
      "username": "maurie_cofman",
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.101 Safari/537.36"
      },
      "lastLogin": 1504700965645
    },
    {
      "_id": {
        "$oid": "59b14751fd62c74723260329"
      },
      "providerId": "106648767728149141932",
      "surname": "Smith",
      "firstName": "John",
      "email": "sales.material.bkk@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-09-15T12:00:00.918Z"
      },
      "createdAt": {
        "$date": "2017-09-07T13:19:13.815Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7475491010"
          },
          "locations": [
            {
              "city": "London",
              "geolocation": [
                -0.0822229,
                51.525503
              ],
              "postcode": "EC2A 3JL",
              "_id": {
                "$oid": "59b1478cfd62c7472326032a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [
            "Core Fitness"
          ],
          "price": {
            "min": 30
          },
          "radius": 10,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uWhLxUQa-filename.jpeg"
          },
          "profileTitle": "Personal Trainer"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9Ea1jjay-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 42
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 4,
      "username": "john_smith",
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.113 Safari/537.36"
      },
      "lastLogin": 1504790354047
    },
    {
      "_id": {
        "$oid": "59b27de8fd62c7472326032b"
      },
      "providerId": "10159224784685534",
      "surname": "Shaw",
      "firstName": "Gemma",
      "email": "gimmy07@hotmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:53:29.692Z"
      },
      "createdAt": {
        "$date": "2017-09-08T11:24:24.055Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10159224784685534/",
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7816061508",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.017877499999940483,
                51.4688038
              ],
              "city": "London",
              "postcode": "SE8 4JW",
              "_id": {
                "$oid": "5a5fe1f9765eba094646f6f8"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR"
          ],
          "disciplines": [
            "Core Fitness",
            "Endurance",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 60
          },
          "radius": 17,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LH0n0EsG-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPs",
          "yearsOfExp": 2017,
          "biography": "Coming from a background in languages, sales and marketing I left the corporate world to follow my dream and work in health and fitness as a personal trainer and have never looked back!\n\nI'm fully qualified to assist you in reaching your full potential, whether you wish to lose weight, gain strength, tone up, or just want to make exercise a part of your routine and enjoy it!!\n\nGet in touch and let's arrange your free consultation!",
          "offers": "15% Discount for blocks of 10\n\n10% Discount for blocks of 5\n\n£15 Discount for outdoor"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2EN0h1RT-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 18,
      "username": "gemma_shaw",
      "agent": {
        "family": "Safari",
        "major": "9",
        "minor": "1",
        "patch": "3",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_9_5) AppleWebKit/601.7.8 (KHTML, like Gecko) Version/9.1.3 Safari/537.86.7"
      },
      "lastLogin": 1504876222648
    },
    {
      "_id": {
        "$oid": "59b50678fd62c7472326032e"
      },
      "email": "diablotrainingfitness@gmail.com",
      "firstName": "Stuart",
      "surname": "Amies",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "4vwybFJprMeHHEqqzy9zog==",
      "hashedPassword": "KuQAqW3F7NJxPJXpmylUk/5w0rBRFQRQWawEqWIK3xD+FyrVw3YqdfpxfFHlab9S7rnUjeCCD6F0VSSAbYluig==",
      "lastUpdated": {
        "$date": "2017-09-10T16:26:25.615Z"
      },
      "createdAt": {
        "$date": "2017-09-10T09:31:36.655Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7732007378"
          },
          "locations": [
            {
              "city": "Bedford",
              "geolocation": [
                -0.42596010491291614,
                52.111672475051236
              ],
              "postcode": "MK44 3EQ",
              "_id": {
                "$oid": "59b506f5fd62c7472326032f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Diploma sports nutrition",
            "exercise & fitness"
          ],
          "disciplines": [
            "Bodybuilding",
            "Functional Training",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 35
          },
          "radius": 35,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VxCO9x1e-IMG_5744.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "Diablotrainingfitness",
          "affiliation": "REPs",
          "yearsOfExp": 2017,
          "offers": "8 week Personal training transformation \n12 week package\n16 week package\n1 month kick up the ass\n1 to 1 sessions\nBeach body ready",
          "biography": "Fully qualified personal trainer passionate about getting you to your goals without scams or unnecessary products and supplements. Healthy and active living to help you reach your fitness goals.\nGiving you the knowledge and understanding to keep it going and offering packages and products that work for you and your home life. \nKeep your social life and feel good day to day.\nActually see the change in yourself and others will to.\nStick with the plans and guaranteed results.",
          "strapLine1": "DARE TO BE BETTER",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10159433544665128/",
          "instagram": {
            "access_token": "5421258762.8640130.9fb7e1727771425aa5adeea302b10e37",
            "link": "https://www.instagram.com/diablotrainingfitness"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5BUsJPWF-IMG_6737.JPG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 81
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 22,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_2 like Mac OS X) AppleWebKit/602.3.12 (KHTML, like Gecko) Version/10.0 Mobile/14C92 Safari/602.1"
      },
      "lastLogin": 1505040503609,
      "username": "stuart_amies"
    },
    {
      "_id": {
        "$oid": "59b664c9fd62c74723260331"
      },
      "email": "info@citywellness.co.uk",
      "firstName": "Tom",
      "surname": "Cheeseman",
      "promoCode": "PTEARLY",
      "activation_code": "71e16bf14e15eba434d3e4b1d91b7f06569f7d5d",
      "salt": "mRyZCa3DtWbHrUl0v9EpcQ==",
      "hashedPassword": "DM669GVE0qVCED9c/eN8geCojzD66oU3yKwi1Leef9gVAF6dga7NRbiifnZ2XprdS5hxE55XKoQpb8HMbR9mMQ==",
      "lastUpdated": {
        "$date": "2017-09-12T17:15:04.050Z"
      },
      "createdAt": {
        "$date": "2017-09-11T10:26:17.867Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "447879357410"
          },
          "locations": [
            {
              "city": "London",
              "geolocation": [
                -0.12775829999998223,
                51.5073509
              ],
              "postcode": "WC2N 5DU",
              "_id": {
                "$oid": "59b6669bfd62c74723260332"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [
            "All Ages",
            "Business Psychology",
            "Confidence Building",
            "Diet & Nutrition"
          ],
          "price": null,
          "radius": 100,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Cg0iBjA5-City%20wellness.png"
          },
          "companyName": "CityWellness",
          "companyWebsite": "http://www.citywellness.co.uk",
          "profileTitle": "PT Training Services",
          "instagram": {
            "link": "https://www.instagram.com/coincido",
            "access_token": "2290335337.8640130.62b08ee5d0cd47a3a8292d6aeeed2d71"
          },
          "twitter": {
            "accessToken": "825048421102923776-sQc9CwRFyCvZXcfHoVkUQ5T6CWcodlh",
            "accessTokenSecret": "oYM3WzR6eCkRch9BsqahPN8QZppv56LCiktbzhxhy5Q8q",
            "link": "https://twitter.com/CityWellnessUK",
            "screen_name": "CityWellnessUK",
            "user_id": "825048421102923776"
          },
          "facebookPage": "citywellnessuk",
          "yearsOfExp": 2011,
          "biography": "City Wellness was created over a number of years from working within the corporate sector to the NHS. We saw the need to offer bespoke integrated wellness to the workplace. Our aim is to bring specialists into your company to improve the wellness and vitality of you and your employees.  \n \nWe are passionate about what we do and believe in what we offer. We see the future of city wellness to be an integral part of your company\n\nHow it works\nCity Wellness create bespoke packages for your company.\nWe provide wellness specialists to help your employees reach peak performance.\nOur sessions range from group fitness and one-to-one personal training, yoga and mindfulness to osteopathy, massage and much more.",
          "offers": "Stress Relief:\n\nIn our day to day life dealing with daily stressors we need to incorporate ways to help ourselves . From working out to getting regular massages .  By getting in touch with our bodies and being aware of what we are putting into our system can help us align with how we want to live our lives.  \n\nInjury Prevention and Rehabilitation:\n\nThrough staying well and being active we need to maintain our bodies to prevent injuries from occurring -allowing us to continue with the goals we set. This package is for prevention but also for injury rehabilitation.\n\nBody Transformation:\n\nOur collection of specialists will devise a comprehensive body transformation programme adapting to the individuals' needs, whether the goal is weight loss or an increase in lean muscle mass. An in-depth package that will explore more than just the exercise and nutrition. Analysing sleep patterns, stress levels and other external factors. Each plan will have a specific time frame that our professionals will work towards and ensure that you're life will change for the better.\n\nEvent Orientated:\n\nWhether it's a 3km run or Coast to Coast expedition, City Wellness will get you physically and mentally prepared for the challenge. Our aim is to guide you, push boundaries and achieve goals that may have seemed unreachable. Personal training will feature heavily in this programme, in conjunction with regular pre-habilitation sessions with our osteopath, to race day nutritional advice from our dietician,",
          "testimonials": "' I'm a full time working mum so finding time to exercise is hard at the best of times. When I heard about personal training services being offered at work I jumped at the chance. It's never easy and I'm often sleep deprived but the team plans and adjusts the sessions accordingly so that I always leave feeling energised and more productive for the day ahead. '\n \nKT Hiscox Insurance",
          "strapLine1": "The aim of City Wellness is to help you and your company reach  an optimal state of health, both physically and mentally"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zs39urMr-City%20wellness.png.jpg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 72
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 16,
      "agent": {
        "family": "Opera",
        "major": "47",
        "minor": "0",
        "patch": "2631",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.90 Safari/537.36 OPR/47.0.2631.80"
      },
      "lastLogin": 1505125578358,
      "username": "citywellness"
    },
    {
      "_id": {
        "$oid": "59ba56affd62c74723260333"
      },
      "providerId": "1595811103822867",
      "surname": "Ladovic",
      "firstName": "Monika",
      "email": "monika.ladovic.92@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-09-21T12:00:00.457Z"
      },
      "createdAt": {
        "$date": "2017-09-14T10:15:11.746Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1595811103822867/",
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "code": "+385",
            "number": "977641375"
          },
          "locations": [
            {
              "city": "Zagreb",
              "geolocation": [
                16.00151881027774,
                45.7638038854419
              ],
              "postcode": "10000",
              "_id": {
                "$oid": "59ba56f1fd62c74723260334"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 20
          },
          "radius": 15
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Rhv7FrnG_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 28
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 2,
      "username": "monika_ladovic",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.8829 Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1505384112142
    },
    {
      "_id": {
        "$oid": "59ba7098fd62c74723260335"
      },
      "providerId": "10214397415912471",
      "surname": "Politi",
      "firstName": "Maureen",
      "email": "maurinetrainor@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-09-22T08:33:35.101Z"
      },
      "createdAt": {
        "$date": "2017-09-14T12:05:44.404Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10214397415912471/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2Y9b8aIS_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "maureen_politi",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.8655 Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1505390744803
    },
    {
      "_id": {
        "$oid": "59ba9561fd62c74723260337"
      },
      "providerId": "10212232002066640",
      "surname": "Lagouvardou",
      "firstName": "Lydia",
      "email": "lilagouv@hotmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-09-22T08:33:35.112Z"
      },
      "createdAt": {
        "$date": "2017-09-14T14:42:41.720Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10212232002066640/",
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "code": "+30",
            "number": "6981630545"
          },
          "locations": [
            {
              "city": "Βάρη",
              "geolocation": [
                23.80577646528018,
                37.830834244787916
              ],
              "postcode": "166 72",
              "_id": {
                "$oid": "59ba95aefd62c74723260338"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 20
          },
          "radius": 0
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "EUR",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OiJPAgVm_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 28
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 2,
      "username": "lydia_lagouvardou",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.8655 Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1505400162495
    },
    {
      "_id": {
        "$oid": "59bce64dfd62c7472326033a"
      },
      "email": "anthonyhaughian@yahoo.co.uk",
      "firstName": "Anthony",
      "surname": "Haughian",
      "promoCode": "PTEARLY",
      "activation_code": "d92f036b7b4829ce9ad738872e5896715f9c90bb",
      "salt": "Pke3VCEe39cLAO6gd64qyQ==",
      "hashedPassword": "8z/vorA0rh3Z+guKV9n0Uua6O7C9dHyzgVSEYzJe9tRrYmyaRnocMKXWMpZNjGiz9vdItxDGAuKl/5pnnAjl1w==",
      "lastUpdated": {
        "$date": "2017-09-23T12:00:00.723Z"
      },
      "createdAt": {
        "$date": "2017-09-16T08:52:29.644Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.8829 Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "lastLogin": 1505551949791
    },
    {
      "_id": {
        "$oid": "59bf8e1cfd62c7472326033c"
      },
      "email": "rob@gagnonfitness.com",
      "firstName": "Rob",
      "surname": "Gagnon",
      "promoCode": "PTEARLY",
      "activation_code": "38fa0fde2659901f6aa21a4fb4f3cb91f954315d",
      "salt": "FeEprgIU770lrdHEoPe8FQ==",
      "hashedPassword": "IlWIwxARCb3snSBPNDXkHYFGqTSUmB0zhnGgX0A3nkqBvgg4pp29hQZ7qhUWaq75UaKkJmJdUEJuZS8iVhcHEQ==",
      "lastUpdated": {
        "$date": "2017-09-19T18:45:44.040Z"
      },
      "createdAt": {
        "$date": "2017-09-18T09:13:00.114Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "9783172237"
          },
          "locations": [
            {
              "city": "Danvers",
              "geolocation": [
                -70.93212199999999,
                42.5750009
              ],
              "postcode": "01923",
              "_id": {
                "$oid": "59bf8e9afd62c7472326033d"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "MA Exercise and Sport Science - Athletic Training"
          ],
          "disciplines": [
            "Functional Training",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 75
          },
          "radius": 25,
          "biography": "Experienced Senior Instructor with a demonstrated history of working in the health wellness and fitness industry.\nStrong human resources professional skilled in Coaching, Athletic Training, Fitness Training, Agility, and Athletics.",
          "profileTitle": "Personal Trainer"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9eNeEggE-Rob%20G.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 53
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 5,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.8829 Version/10.0 Mobile/14G57 Safari/602.1"
      },
      "lastLogin": 1505725980633,
      "username": "rob_gagnon"
    },
    {
      "_id": {
        "$oid": "59bfbfdbfd62c7472326034f"
      },
      "providerId": "10155945022416178",
      "surname": "O'Dell PT",
      "firstName": "Liam",
      "email": "liam-odell@hotmail.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-09-18T13:15:36.068Z"
      },
      "createdAt": {
        "$date": "2017-09-18T12:45:15.594Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155945022416178/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7757615646"
          },
          "locations": [
            {
              "city": "Necton",
              "geolocation": [
                0.7722369000000526,
                52.652105
              ],
              "postcode": "PE37 8HR",
              "_id": {
                "$oid": "59bfc007fd62c74723260350"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Strength & Conditioning",
            "Level 3: Advanced Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 3: Nutrition",
            "Athletics Coach",
            "Strength and Conditioning Coach",
            "Personal Trainer"
          ],
          "disciplines": [
            "Groups / Teams",
            "Injury Prevention",
            "Injury Rehab",
            "Personal Training",
            "Sports Specific"
          ],
          "price": {
            "min": 35
          },
          "radius": 30,
          "background": {
            "offset": 0,
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qqxuNU7j-%23BreakingRecords%20logo%203%20youtube.png"
          },
          "profileTitle": "Strength & Conditioning Coach",
          "facebookPage": "BreakingRecordsFitness",
          "affiliation": "REPs",
          "yearsOfExp": 2011,
          "offers": "For more visit - http://www.breakingrecordsfitness.co.uk/strength-and-conditioning",
          "biography": "For more visit - http://www.breakingrecordsfitness.co.uk/about-us"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UWbOVqFl-12112366_494120717415054_630125757869966337_n.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 58
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 9,
      "username": "liam_odell_pt",
      "agent": {
        "family": "Edge",
        "major": "15",
        "minor": "15063",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/52.0.2743.116 Safari/537.36 Edge/15.15063"
      },
      "lastLogin": 1505738716030
    },
    {
      "_id": {
        "$oid": "59c0edb7fd62c74723260352"
      },
      "email": "kenny@cfptonline.com",
      "firstName": "Kenny",
      "surname": "Karr",
      "promoCode": "PTEARLY",
      "activation_code": "d212d13bbf9bee03b7ea1be2a663871e9f84ed4b",
      "salt": "HCxp3hZ7mgQxabcSTepjHA==",
      "hashedPassword": "5eXI1ckQKwhcZLPnv0E63di5tLzd+7OrG+ISBY8uq/aDhAFSOSmZ+CgLVfDxMb4Z/Fn12fFhHdR4aZnmlEgHyA==",
      "lastUpdated": {
        "$date": "2017-09-26T12:00:01.024Z"
      },
      "createdAt": {
        "$date": "2017-09-19T10:13:11.994Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome Mobile",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G955U Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.116 Mobile Safari/537.36"
      },
      "lastLogin": 1505815992363
    },
    {
      "_id": {
        "$oid": "59c272f938850c7ae0150144"
      },
      "email": "livtraining@live.ca",
      "firstName": "LiV",
      "surname": "Training",
      "promoCode": "PTEARLY",
      "activation_code": "206b7cb9ed5d516736de55530c6501444fae955b",
      "salt": "hhODv3JLBCiuZVmKwKRc7w==",
      "hashedPassword": "5wGYwhfCKqai4PgsLaNY7TPG6ObuTiwngJaVC4DUzdkDILGuZik37lMhmcuVtnbHG/0lNcJp/Zyw3Sd0zv6x7w==",
      "lastUpdated": {
        "$date": "2017-11-27T18:37:59.436Z"
      },
      "createdAt": {
        "$date": "2017-09-20T13:54:02.014Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "number": "9056887447",
            "code": "+1"
          },
          "locations": [
            {
              "geolocation": [
                -79.2322248,
                43.187200399999995
              ],
              "city": "St. Catharines",
              "postcode": "L2N",
              "_id": {
                "$oid": "5a1c5b872288dd3a30f3f57d"
              },
              "country": "Canada",
              "primary": true
            }
          ],
          "qualifications": [
            "Private Studio",
            "Free Weights Selection",
            "Resistance Equipment",
            "Free Towels",
            "Free Toiletries",
            "Free Showers",
            "Free Parking",
            "Ladies Only Area"
          ],
          "disciplines": [
            "Diet & Nutrition",
            "Fitness Classes",
            "Ladies Only Classes",
            "Personal Training",
            "Personalised Programmes"
          ],
          "price": null,
          "radius": 25,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Rm3PboSf-20170225_093711.jpg"
          },
          "companyName": "LiV Personal Training Studio",
          "companyWebsite": "http://livfitness.ca",
          "profileTitle": "Gymnasium / Health Club",
          "facebookPage": "livpersonaltrainingstudio",
          "yearsOfExp": 2008,
          "biography": "Have you ever felt uneasy about going to or working out in a large impersonal gym?  Or simply been lost in all the gargin and mythology of weight loss and fitness?\n\nIn 2008 we created LiV Personal Training Studio.  For those looking for a private personal space to increase their level of health and fitness.  A place to learn how to bring body fat to a more healthy level, and most of all increase overall fitness.\n\nWe offer you/our clients a safe, fun and intimate exercise experience.\n\nAt LiV we realize that beginning a new exerciser program can be intimidating.\nThe struggle to get and remain healthy, mobile and pain free is real.\nLiV’s fitness experience will help you set goals, meet, then exceed them.\nThrough coaching and proper guidance, you can expect amazing results!\n\nGET RESULTS\n\n– Experienced trainers in a private non-intimidating gym setting\n– Initial consultation\n– Body composition assessment\n– Health history assessment\n– Initial Functional Fitness Assessment\n– Goal setting with realistic time frames and expectations\n– Individual program design\n– Initial Nutrition session focused on real food choices\n\nTake a moment and request more information about LiV.  Realize a strong, active, healthy body for your life.\nWith expert coaching and support let us guide you through smart nutrition and work-out programming.\nYour desired fitness level – whether it be losing 10-15 lbs of unwanted body weight or overcoming pain and improving immobility.",
          "offers": "Hi,\nOur Intro Special is designed to allow you to become more familiar with, us, our space and our training styles.\n\nThe $99 Special includes the following:\n\n– health assessment, which takes roughly an hour and would require you to wear some loose clothing. You may sweat a little bit. This is designed to give us a little background information on you and your health history as well as provide us with some base line starting points to get your workout program started.\n\n–  after the health assessment we find days and times that fit into your day and schedule you with a trainer that fits your needs. For example some people would rather work with a female… so we setup their appointments accordingly.\n\nBefore all this though, we need to schedule a time for you to come in. It would be great if we could find a time for you to come in and just chat with us a little about what you are looking for long term. This initial meeting would only be about 15 mins and is of no cost to you - I hope we can chat soon!",
          "strapLine1": "PRIVATE PERSONAL TRAINING BOUTIQUE"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xBhNMZb4-P_20160819_104316_BF.jpg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 68
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 24,
      "agent": {
        "family": "Chrome",
        "major": "60",
        "minor": "0",
        "patch": "3112",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.113 Safari/537.36"
      },
      "lastLogin": 1505915642410,
      "username": "liv_personal_training_studio",
      "approved_date": {
        "$date": "2017-09-22T12:17:52.500Z"
      }
    },
    {
      "_id": {
        "$oid": "59c959f512ba5626b14fc7c2"
      },
      "providerId": "10155253880208026",
      "surname": "Shaddock",
      "firstName": "John",
      "email": "shaddjo@aol.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-10-03T12:00:01.529Z"
      },
      "createdAt": {
        "$date": "2017-09-25T19:33:09.686Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155253880208026/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Zkz2X2ND_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "john_shaddock",
      "agent": {
        "family": "Opera",
        "major": "47",
        "minor": "0",
        "patch": "2631",
        "source": "Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.90 Safari/537.36 OPR/47.0.2631.71"
      },
      "lastLogin": 1506368149314
    },
    {
      "_id": {
        "$oid": "59cb303dad07d459108b0e1f"
      },
      "providerId": "10154798996041714",
      "surname": "Dellebeke",
      "firstName": "Diderik",
      "email": "diderikdellebeke@hotmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:49:49.861Z"
      },
      "createdAt": {
        "$date": "2017-09-27T04:59:41.091Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154798996041714/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "641241607",
            "code": "+31"
          },
          "locations": [
            {
              "geolocation": [
                5.469722499999989,
                51.44164199999999
              ],
              "city": "Eindhoven",
              "postcode": "5611 BE",
              "_id": {
                "$oid": "5a5fe11d765eba094646f6f7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Personal Trainer"
          ],
          "disciplines": [
            "Functional Training",
            "Rehabilitation",
            "Sports Massage",
            "Sports Therapy",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 60
          },
          "radius": 100,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vP0dh9TR-IMG_20170510_172632_2.jpg"
          },
          "profileTitle": "Personal Trainer",
          "biography": "Dellebeke Personal Training is actief in de personal training, medical training en sportbegeleiding."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "EUR",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ovAsPsE4-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 7,
      "username": "diderik_dellebeke",
      "agent": {
        "family": "Chrome Mobile",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Linux; Android 7.0; EVA-L09 Build/HUAWEIEVA-L09) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.98 Mobile Safari/537.36"
      },
      "lastLogin": 1506488382041,
      "approved_date": {
        "$date": "2017-10-18T19:35:00.766Z"
      }
    },
    {
      "_id": {
        "$oid": "59cca8bbcc3d650f7e4b3630"
      },
      "email": "lynn.page@americansportandfitness.com",
      "firstName": "Customer",
      "surname": "Support",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "CoWmYKz4xld7eY1qITCqDw==",
      "hashedPassword": "zpVSrTdLvePxUcBBSiMNQvGn2K54YtZ7IcIRrRS6zNdhVZm74rfvbAoYAaNlEjfymAuKIIKeHgBHCs/YJ7UxrQ==",
      "lastUpdated": {
        "$date": "2017-10-18T17:43:17.866Z"
      },
      "createdAt": {
        "$date": "2017-09-28T07:46:03.627Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "code": "+1",
            "number": "8778510283"
          },
          "locations": [
            {
              "city": "St. Louis",
              "geolocation": [
                -90.47854949999999,
                38.6624944
              ],
              "postcode": "63141",
              "_id": {
                "$oid": "59e792b594e4d86bf9be19c7"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [
            "CPD Courses",
            "Home Study",
            "Online Courses",
            "Personal Trainer Courses"
          ],
          "price": null,
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BMHX2dBv-ASFA%20Full%20Logo1%20%20Oct%202014.jpg"
          },
          "companyName": "American Sports & Fitness Association®",
          "companyWebsite": "https://www.americansportandfitness.com/",
          "profileTitle": "Academy",
          "facebook": null,
          "facebookPage": "AmericanSportsandFitnessAssoci",
          "affiliation": "Human Kinetics, Physical Therapist sites, HPSO,",
          "strapLine1": "The online resource for fitness professionals",
          "biography": "Our motto says it all:  “The online resource for fitness professionals.”  ASFA® specializes and tailors certifications to those who already work in the fitness industry and have an extensive pre-existing knowledge in the area.  ASFA® helps you specialize your continuing education and marketability by testing in industry-specific fields. Take one of our risk-free tests today.  Pay only if you pass!\n\nOur goal at ASFA® is to provide quality testing, certifications and educational materials for fitness professionals.  ASFA® certifications have assisted thousands of fitness professionals by enhancing their careers through continuing education.  There is no wrong time to take a continuing education qualification and further your professional achievements in the ever changing fitness industry.\n\nASFA® provides certifications for continuing education, emphasizing specialty qualifications.  With our certifications, not only can you further your professional education but also expand your knowledge and potential client base.  Are you ready to maximize your potential in the fitness industry?  If so, then follow the many thousands in the industry who use ASFA® as their online resource for fitness certifications.\n\nAll certifications require 70% correct to pass and will be emailed in .pdf format upon satisfactory completion and payment. As physical fitness becomes more and more a way of life, now is the time to enhance your knowledge in the dynamic fitness industry.",
          "offers": "Advanced Personal Training Certification\nBalance and Stability Instruction Certification\nBallet Fitness Instruction Certification\nCycling Instruction Certification\nDance/Hip-hop Aerobics Instruction Certification\nFunctional Fitness Instruction Certification\nGolf Fitness Instruction Certification\nGroup Fitness/Bootcamp Instruction Certification\nHealth Club Management Certification\nKettlebell Instruction Certification\nKickbox/Step Aerobic Instruction Certification\nMartial Arts Fitness Instruction Certification\nMaster Personal Training Certification\nPersonal Training Certification\nPilates Certification\nRunning Fitness Instruction Certification\nSelf Defense Instruction Certification\nSenior Fitness Instruction Certification\nSpeed and Agility Instruction Certification\nSport Specific Training Certification\nSports Nutritionist Certification\nStretching and Flexibility Instruction Certification\nTriathlon Fitness Instruction Certification\nWater Aerobics Instruction Certification\nWomen's Fitness Instruction Certification\nYouth Fitness Training Certification\nYoga Certification\nFitness Professional Kit",
          "testimonials": "“Human Kinetics is proud to work with the American Sport and Fitness Association® to help achieve the mission of conveniently providing high quality educational materials to ASFA® members to help them grow and prosper as fitness professionals.”\n\nHealthcare Providers Service Organization and American Sports and Fitness Association® have partnered together to bring an added benefit to ASFA® members. ASFA® has chosen HPSO as its official provider of professional liability insurance to its members. Known for their exceptional client service and comprehensive coverage, HPSO has been providing professional liability coverage to healthcare professionals for over 25 years. Before renewing your current policy, please consider reviewing the HPSO program to get the peace of mind that comes along with having your own individual professional liability program.\n\nPhysicalTherapistSites has partnered with ASFA® and specializes in creating websites that are professional, affordable, and effective. All you pay is a monthly hosting fee of $59/month with no long-term contracts. They deliver credit card processing, appointment requests, search engine optimization, and more — it's all included! Attracting new clients and generating more revenue is easy with PhysicalTherapistSites!  If you don't have a website for your personal training business, consider this as a cost-effective way to promote yourself online and gain greater credibility."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/G5Iunm4e-ASFA%20Icon%20Logo%20Oct%202014.jpg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 67
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "partners",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 58,
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "lastLogin": 1508348431896,
      "username": "american_sports_and_fitness_association®",
      "approved_date": {
        "$date": "2017-10-12T17:12:00.549Z"
      },
      "password_reset_code": "X"
    },
    {
      "_id": {
        "$oid": "59ccf504cc3d650f7e4b363a"
      },
      "email": "adamelsworthpt@hotmail.com",
      "firstName": "Adam",
      "surname": "Elsworth",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "ObtusB0+v0B+aGrmL9dMNA==",
      "hashedPassword": "XlgX2dUUlb/Q04VERw62kGcmbA/1p7SLPz02LrEqQimB4FrtOd4JlPEPTH1FVmlSig9snK7KdqCY+EwsgEd0uw==",
      "lastUpdated": {
        "$date": "2017-10-10T12:14:02.487Z"
      },
      "createdAt": {
        "$date": "2017-09-28T13:11:32.410Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7539337916"
          },
          "locations": [
            {
              "city": "Leeds",
              "geolocation": [
                -1.5490773999999874,
                53.8007554
              ],
              "postcode": "LS1 3DA",
              "_id": {
                "$oid": "59ccf574cc3d650f7e4b363b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "Applying principles of nutrition to physical activity programmes"
          ],
          "disciplines": [
            "Boxing",
            "Circuit Training",
            "Group Classes",
            "Mobile Trainer",
            "Weight Loss"
          ],
          "price": {
            "min": 40
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1ZlLcFUh-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REP's",
          "yearsOfExp": 2016,
          "strapLine1": "I BRING THE GYM TO YOU",
          "offers": "Block bookings\n- 4 sessions minimum \n- Reduced to £30 a session when paid in advance \n\nGroup sessions and Fit4Work \n- please email for details on group sizes",
          "biography": "Hi my name is Adam Elsworth\nBorn and bred in Yorkshire, I have been personal training for just over a year, and have had some amazing results from clients at home and in the gym\n\nI decided to take my business further by developing a programme to bring health and well being into workplaces. This is called Fit4Work\n\nFit4Work sessions are a great way for collegues to connect through physical activity, these sessions are designed to be fun and challenging so are a great team building exercise. Research shows that physical activity also reduces stress levels and boosts productivity while getting fit!\n\nThis is a great way to show employees that you care about there well fair while reaping the benefits of a happier and more productive workforce."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0YFoTrCa-P%20F%20-%20Logo%20(2).jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 72
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 19,
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "lastLogin": 1507637642450,
      "username": "adam_elsworth",
      "approved_date": {
        "$date": "2017-10-01T18:26:36.496Z"
      }
    },
    {
      "_id": {
        "$oid": "59ccfefccc3d650f7e4b363c"
      },
      "providerId": "101403307913918470078",
      "surname": "mb",
      "firstName": "santosh",
      "email": "pi81santosh@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-10-17T04:50:07.901Z"
      },
      "createdAt": {
        "$date": "2017-09-28T13:54:04.952Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/101403307913918470078",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+84",
            "number": "1657172303"
          },
          "locations": [
            {
              "city": "Ho Chi Minh City",
              "geolocation": [
                106.6296638,
                10.8230989
              ],
              "_id": {
                "$oid": "59e58bff94e4d86bf9be186f"
              },
              "country": "Vietnam",
              "primary": true
            }
          ],
          "qualifications": [
            "hatha yoga",
            "TTC",
            "TNT KICK BOXING",
            "Fitness Instructor",
            "Kettle bell",
            "Survival edge",
            "TRX"
          ],
          "disciplines": [
            "Boxing",
            "Flexibility",
            "Hiit",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 25
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cpLHpWeG-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "biography": "My name is Santosh burud and I am truly passionate about health and fitness and incorporate a philosophy of living a healthy lifestyle into everything I do.  I specialize in all areas of personal training including weight loss, muscle building, strength and conditioning as well as general fitness.I am a strong believer in the role nutrition plays in helping people reach their goals and work to assist all of my clients with incorporating a proper nutritional plan into their overall training regimen.I am completely dedicated to my clients and their goals.  \nI am here anytime you have questions or need advice, not just when we are working out together.\nI am a hands on personal trainer with a highly motivational style who doesn't believe in just standing around holding a clipboard during training sessions."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7Oxyh5hm-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 57
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 8,
      "username": "santosh_mb",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_2 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.9726.1 Version/10.0 Mobile/15A421 Safari/602.1"
      },
      "lastLogin": 1508215624794,
      "approved_date": {
        "$date": "2017-10-01T19:18:21.481Z"
      }
    },
    {
      "_id": {
        "$oid": "59d31c89cc3d650f7e4b3642"
      },
      "providerId": "10156703332709196",
      "surname": "Cheal",
      "firstName": "Ryan",
      "email": "rjcheal@googlemail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-10-10T12:00:01.707Z"
      },
      "createdAt": {
        "$date": "2017-10-03T05:13:45.725Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10156703332709196/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DPndKnjc_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "ryan_cheal",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "3",
        "patch": "3",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.3504 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1507007626131
    },
    {
      "_id": {
        "$oid": "59d5fcd4cc3d650f7e4b3657"
      },
      "email": "thelondonfitclub@icloud.com",
      "firstName": "Director",
      "surname": "Business and sales",
      "promoCode": "PTEARLY",
      "activation_code": "1550ef2948425eac2176cf3184fc4ca10cb1069e",
      "salt": "OYJlRnNsAqnwJ7tUMksquA==",
      "hashedPassword": "H7J0G4U4qW49XWcmFgqu1WrUrUoV2f1PangW6c7gO3wy3Yg6JNFJEByC3OnSYuZaPuD8gtbcnM9tHE0ALrc81g==",
      "lastUpdated": {
        "$date": "2017-10-17T22:30:45.771Z"
      },
      "createdAt": {
        "$date": "2017-10-05T09:35:16.670Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7824556214"
          },
          "locations": [
            {
              "geolocation": [
                -0.12775829999998223,
                51.5073509
              ],
              "city": "London",
              "postcode": "WC2N 5DU",
              "_id": {
                "$oid": "59e6849594e4d86bf9be194a"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Cognitive Therapy",
            "Holistic Therapies",
            "Sports Massage",
            "Indian Head Massage",
            "Nutrition",
            "Life Coaching",
            "Kindness Behaviour",
            "Yoga",
            "Bodywork",
            "Osteopathy",
            "Relapse Prevention",
            "Shower and lockers provided"
          ],
          "disciplines": [
            "Corrective Exercise",
            "Diet & Nutrition",
            "Elite Sport",
            "Injury Prevention",
            "Personal Training"
          ],
          "price": null,
          "radius": 200,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lizFjzCa-IMG_20170929_223848_328.jpg"
          },
          "companyName": "The london fit club ltd",
          "companyWebsite": "https://www.thelondonfitclub.co.uk",
          "profileTitle": "Sports Therapy & Rehab",
          "google": "https://plus.google.com/102506377023790224977",
          "affiliation": "Sinai 7",
          "yearsOfExp": 2009,
          "biography": "Welcome to the London fit club, your one stop shop for holistic health and wellbeing based in London",
          "offers": "We offer a range of services from, kids programmes to athletic performance. We specialise in health care. Spinal health inclusive of neurology nlp and mindfulness coaches. #osteopathy, massage, physiotherapy, athletics, boxing and Thai boxing. Foundation is key. You will need to be screened and commit to a smart development plan to ensure your health and safety and the programme designed for you is the one for you . Charity is also key for us and interaction with the local community"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LuJB60Gf-IMG_20170915_085351_740.jpg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 60
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 18,
      "agent": {
        "family": "Chrome Mobile",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.98 Mobile Safari/537.36"
      },
      "lastLogin": 1507196117277,
      "username": "the_london_fit_club_ltd",
      "approved_date": {
        "$date": "2017-10-06T08:10:49.115Z"
      }
    },
    {
      "_id": {
        "$oid": "59dfa1b0cc3d650f7e4b3665"
      },
      "providerId": "1116222598512614",
      "surname": "Rabeaa",
      "firstName": "Mohamed",
      "email": "mohamed.yehia91@yahoo.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-10-20T12:00:01.504Z"
      },
      "createdAt": {
        "$date": "2017-10-12T17:09:04.524Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1116222598512614/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JCEpRwC9_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "mohamed_rabeaa",
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "lastLogin": 1507828144972
    },
    {
      "_id": {
        "$oid": "59e1f36194e4d86bf9be1855"
      },
      "providerId": "2113256208700383",
      "surname": "Masterkeyfitness",
      "firstName": "Dean",
      "email": "dean@masterkeyfitness.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:45:01.322Z"
      },
      "createdAt": {
        "$date": "2017-10-14T11:22:09.937Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/2113256208700383/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7456362298",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.0867334,
                51.5288883
              ],
              "city": "London",
              "postcode": "N1 6BS",
              "_id": {
                "$oid": "5a5fdffd765eba094646f6f6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Lower Back Pain Instructor",
            "Level 3: Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 3: Nutrition",
            "Level 2: First Aid / CPR"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Functional Training",
            "Rehabilitation",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 30
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1w7Oxext-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "4270357014.8640130.a41654f8e29440b694e73ae1c12a9d0a",
            "link": "https://www.instagram.com/masterkeyfitness"
          },
          "twitter": {
            "accessToken": "2297243074-0tKmKbLwITGfAJQuzSuu7GH8A3HQINE0t71qPLw",
            "accessTokenSecret": "JxVfRkGBXCE6mjDrSagAoKWiu8CFXSLU0tt4QexSu8kEd",
            "link": "https://twitter.com/Ptpercyfitness",
            "screen_name": "Ptpercyfitness",
            "user_id": "2297243074"
          },
          "affiliation": "Reps",
          "yearsOfExp": 6,
          "offers": "Please check website \nWww.masterkeyfitness.co.uk",
          "biography": "Please check out website \nWww.masterkeyfitness co.uk"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wrVaMxpN-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 70
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 19,
      "username": "dean_masterkeyfitness",
      "agent": {
        "family": "Chrome Mobile",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.98 Mobile Safari/537.36"
      },
      "lastLogin": 1507980696164,
      "approved_date": {
        "$date": "2017-10-15T16:57:50.129Z"
      }
    },
    {
      "_id": {
        "$oid": "59e9175094e4d86bf9be1a15"
      },
      "email": "boutiquefitness66@gmail.com",
      "firstName": "Jill",
      "surname": "Jones",
      "promoCode": "PTEARLY",
      "activation_code": "101fef6af1da9c0c3501dc52b19ef83d736f4cc2",
      "salt": "YC+oO2m1tDYb6mtH8aOq2Q==",
      "hashedPassword": "xl+lw2sveguL1shUOOJiYHpR0RuX+b/imbYpEFgML/AMnTy7XPuAkuMLaxPtPFsRs2AZfSk6H6GzYGnMXJiMMA==",
      "lastUpdated": {
        "$date": "2017-10-27T12:00:01.413Z"
      },
      "createdAt": {
        "$date": "2017-10-19T21:21:20.253Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7594694526"
          },
          "locations": [
            {
              "city": "Mossley",
              "geolocation": [
                -2.0346733,
                53.5141789
              ],
              "postcode": "OL5 9SF",
              "_id": {
                "$oid": "59e9180b94e4d86bf9be1a17"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": null,
          "radius": 20
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 19
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Chrome Mobile",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Linux; Android 6.0; F3311 Build/37.0.A.2.108) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.98 Mobile Safari/537.36"
      },
      "lastLogin": 1508448080542
    },
    {
      "_id": {
        "$oid": "59ea096794e4d86bf9be1a37"
      },
      "email": "weightsnwine@outlook.com",
      "firstName": "Emma",
      "surname": "Cooke",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "OPln5J5Ej3Blc5jGRXCktg==",
      "hashedPassword": "mCJ8xlQEKfSkBQ2rPEySDpQFUAyoCh89dBLlE/R7q/6uZMjbhmC2MJK4Kf0zRpOi7DC7wMzzVq07aK5enneswQ==",
      "lastUpdated": {
        "$date": "2018-01-17T23:43:29.500Z"
      },
      "createdAt": {
        "$date": "2017-10-20T14:34:15.408Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7809128003",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.2426305000000184,
                53.4807593
              ],
              "city": "Manchester",
              "postcode": "M2 4WU",
              "_id": {
                "$oid": "5a5fdfa1765eba094646f6f2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 2: Gym Instructor",
            "Level 2: Group Exercise to Music Instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Group Classes",
            "Hiit",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 35
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NReOh5Ub-36597571941_97f53fe686_z%20med.jpg"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "205974352.8640130.d3dd384a09a54217811ff0bda9aa796e",
            "link": "https://www.instagram.com/weights_wine"
          },
          "twitter": {
            "accessToken": "796996593119612928-dSCYyv7iqM3lPDmDyGw0m0MQ4NU6aBF",
            "accessTokenSecret": "bid1HuKO2CWv2qsTiJVD5ZYd6V7ioPS4DDJlHpRD6Yftn",
            "link": "https://twitter.com/Weights_Wine",
            "screen_name": "Weights_Wine",
            "user_id": "796996593119612928"
          },
          "yearsOfExp": 2017,
          "biography": "PERSONAL TRAINING\n​\nI am based in Manchester and provide Personal Training Sessions at Pro Balance Health in Manchester City Centre just off Albert Square.\n \nYou can book a one off session or block book to get excellent discounts. We also offer lunch time discounted rates for 20 minute sessions. \n​\nThere are no joining fees at Pro Balance, the prices are all inclusive costs of one-to-one training sessions with your own personal instructor and exclusive use of the Pro Balance Gym.\n​\nPlease contact me to book a complimentary consultation and discuss packages. Email: weightsnwine@outlook.com \n​\n​\nQualifications\n​\nLevel 2 Fitness Instructor\nLevel 3 Certified Personal Trainer\nCircuit Instruction\nSpinning\nOutdoor Fitness\nExercise Referral\nSports Conditioning\n​\nCONTACT ME:\nweightsnwine@outlook.com",
          "strapLine1": "https://www.weightsandwine.co.uk/"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hRxOhScb-Weights%26Wine%201.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 71,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 28,
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "lastLogin": 1508844866333,
      "username": "emma_cooke",
      "approved_date": {
        "$date": "2017-10-21T09:58:06.385Z"
      }
    },
    {
      "_id": {
        "$oid": "59ea48a394e4d86bf9be1a6d"
      },
      "providerId": "10155956332633856",
      "surname": "Saboya",
      "firstName": "Eric",
      "email": "ericsaboya@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-10-28T09:17:21.386Z"
      },
      "createdAt": {
        "$date": "2017-10-20T19:04:03.860Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155956332633856/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/X7iuCfmJ_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "eric_saboya",
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "lastLogin": 1508526311488
    },
    {
      "_id": {
        "$oid": "59edaf3894e4d86bf9be1a70"
      },
      "providerId": "10155126089138214",
      "surname": "Cockroft",
      "firstName": "Emma",
      "email": "emz_babe85@msn.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:41:45.253Z"
      },
      "createdAt": {
        "$date": "2017-10-23T08:58:32.972Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155126089138214/",
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7787942008",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.2327641913790784,
                51.5541125958856
              ],
              "city": "London",
              "postcode": "NW10 1HS",
              "_id": {
                "$oid": "5a5fdf39765eba094646f6f1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Endurance",
            "Hiit",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 60
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GCOBkY3S-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "44122678.8640130.0f2957b19cc6463c8f16d219815f1a2a",
            "link": "https://www.instagram.com/emajoyc"
          },
          "yearsOfExp": 2017,
          "biography": "Marathon runner, aiming to train clients from the beginning, wanting to run your first 5/10K race? I can give you the tools & confidence to run your own pace and complete a race!",
          "offers": "Contact me via my email address: emajoy4@gmail.com for prices and consultations."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kHPLHGOP-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 65
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 15,
      "username": "emma_cockroft",
      "agent": {
        "family": "Mobile Safari UI/WKWebView",
        "major": "10",
        "minor": "3",
        "patch": "3",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/602.1.32 (KHTML, like Gecko) Mobile/14G60 Twitter for iPhone"
      },
      "lastLogin": 1508749113516,
      "approved_date": {
        "$date": "2017-12-12T22:57:07.100Z"
      }
    },
    {
      "_id": {
        "$oid": "59edbd0694e4d86bf9be1a7a"
      },
      "email": "elmedus@yahoo.co.uk",
      "firstName": "Emma",
      "surname": "E M Active",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "uh57HNpUeigTleBqzwLBSg==",
      "hashedPassword": "S0YsA8Ueg/ecKEeEHb/qKmo0s088LYPGjIKlhho4WmZxm+K58CiBHa6Gpz1XsL6EFbDkYOf6ilzRnY7l0rDxqA==",
      "lastUpdated": {
        "$date": "2018-01-17T23:38:18.978Z"
      },
      "createdAt": {
        "$date": "2017-10-23T09:57:26.226Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7769340026",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.2020453999999745,
                53.35776560000001
              ],
              "city": "Cheadle Hulme",
              "postcode": "SK8 6PT",
              "_id": {
                "$oid": "5a5fde6a765eba094646f6f0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Bsc Hons Sports Therapy",
            "Level 3: Advanced Personal Trainer",
            "Level 3: Ante & Post Natal",
            "Level 4: Strength & Conditioning",
            "Level 3: Nutrition",
            "Level 2: Disability",
            "Level 2: Group Exercise to Music Instructor"
          ],
          "disciplines": [
            "Functional Training",
            "Nutrition",
            "Pre/post Natal",
            "Sports Therapy",
            "Weight Loss"
          ],
          "price": {
            "min": 35
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Sjb2ShC4-69A8EB9E-C11C-435B-9E69-7E86455BFBC2.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "EmmaMedusPersonalTraining",
          "yearsOfExp": 2009,
          "strapLine1": "Making exercise fun, functional & effective. Specialising in rehabilitation & training with a disability",
          "offers": "1 x per week - £140 per month \n\n2 x per week - £280 per month\n\n10 sessions (+ 1 free) - £300",
          "biography": "I have been a Personal Trainer for 7 years, working at a variety of gyms over the years.\n\nI am now based at Fusion Health and specialise in rehabilitation & training with a disability. \n\nMy focus is to make training fun & functional and to try to make fitness and nutrition as easy as possible."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rdSFdIpM-F8A0EF9F-B6F5-468A-A8FD-D0EAA321CED4.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 71,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 21,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_3 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A432 Safari/604.1"
      },
      "lastLogin": 1508754176744,
      "username": "emma_e_m_active",
      "approved_date": {
        "$date": "2017-10-23T11:46:12.331Z"
      }
    },
    {
      "_id": {
        "$oid": "59ef25ef94e4d86bf9be1b83"
      },
      "email": "katepringle1985@gmail.com",
      "firstName": "Katie",
      "surname": "Pringle",
      "promoCode": "PTEARLY",
      "activation_code": "6e542484f312ec71e81dbf7e5779ca038e01ee0f",
      "salt": "yyjdQaNXvOD6+3LsXx0EsA==",
      "hashedPassword": "uhUIASDY2ip9W5moy/B0yByv8OvbIbNmzpHoVBPpKT3Q6MfE8S34TOAR4ylymzZZii59ea9FS9/T4T8QrMue/g==",
      "lastUpdated": {
        "$date": "2018-01-17T23:37:09.025Z"
      },
      "createdAt": {
        "$date": "2017-10-24T11:37:19.113Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7479471526",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -3.116320299999984,
                51.6892137
              ],
              "city": "Hafodyrynys",
              "postcode": "NP11 5BG",
              "_id": {
                "$oid": "5a5fde24765eba094646f6ee"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Bsc Honours Degree in Sports Development & Coaching",
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 2: Group Exercise to Music Instructor",
            "Level 2: Exercise Music & Dance Partnership(EMDP) Teacher",
            "Level 2: Gym Instructor",
            "Les Mills Bodypump & Bodycombat"
          ],
          "disciplines": [
            "Group Classes",
            "Mobile Trainer",
            "Nutrition",
            "Weight Loss"
          ],
          "price": {
            "min": 25
          },
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DE19t3eq-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "katepringle1985@gmail.com",
          "affiliation": "REPS Level 3",
          "yearsOfExp": 2006,
          "biography": "Hi everyone my names Kate,  I’ve been in the fitness industry for over 12 years. I do mobile personal training and I am freelance group exercise instructor, I teach a variety of classes from spin, circuits, boot camp, aerobics Les Mills Bodypump and Bodycombat. I can help you with your all your goals whether it’s weight loss, muscle building, fitness, endurance or your nutrition. Contact me if you want to go that extra mile! Speak to you soon :)",
          "offers": "10 week diet plan £15\n10 1 hour PT sessions for £175\n\n1 10 week diet plan & 1 hour PT £30"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mKudU56K-C812264E-0AD6-4D58-BE7D-CAAC4DD58C89.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 17,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 11_0_3 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A432 Safari/604.1"
      },
      "lastLogin": 1509013312646,
      "username": "katie_pringle",
      "approved_date": {
        "$date": "2017-10-24T16:53:04.580Z"
      }
    },
    {
      "_id": {
        "$oid": "59f04fb394e4d86bf9be1bc4"
      },
      "email": "jbolton1980@gmail.com",
      "firstName": "James",
      "surname": "Boulton",
      "promoCode": "PTEARLY",
      "activation_code": "b8ab5a8b7c4be8ce338fac0b164cb10a7aea6c19",
      "salt": "Th5J0TTwf6vdaaeaJvG/eQ==",
      "hashedPassword": "ytGrKc3Jv4Lr9m7Sgskx0BGh/SMS5IY2zT0BcXODzdaBpUf/Bm04Io3GNEoKZgofgoNVeUrTQjqON3CbigrU6A==",
      "lastUpdated": {
        "$date": "2017-11-01T12:00:01.457Z"
      },
      "createdAt": {
        "$date": "2017-10-25T08:47:47.064Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7516928937"
          },
          "locations": [
            {
              "city": "London",
              "geolocation": [
                -0.12775829999998223,
                51.5073509
              ],
              "postcode": "WC2N 5DU",
              "_id": {
                "$oid": "59f04fef94e4d86bf9be1bc5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 40
          },
          "radius": 5
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 22
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Chrome Mobile",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Linux; Android 6.0; F3311 Build/37.0.A.1.105) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.98 Mobile Safari/537.36"
      },
      "lastLogin": 1508921267590,
      "username": "james_boulton"
    },
    {
      "_id": {
        "$oid": "59f0ca9a2f10083d617ffe2f"
      },
      "email": "caro@supreme-fitness.co.uk",
      "firstName": "Caro",
      "surname": "Pemberton",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "T0y0BIPnTU4A3GKxgawecg==",
      "hashedPassword": "XeVfM2xAgDnwVacMZiZXGyczc+xhKuT2Iak0iMWQDWtmz96WFHE/Wd+KiIwIvja8/yEJgPyYdRXx1lR9vyceqA==",
      "lastUpdated": {
        "$date": "2017-10-27T09:05:21.790Z"
      },
      "createdAt": {
        "$date": "2017-10-25T17:32:11.005Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7514002001"
          },
          "locations": [
            {
              "geolocation": [
                -1.8059580000000324,
                52.4796194
              ],
              "city": "Birmingham",
              "postcode": "B33",
              "_id": {
                "$oid": "59f2f6d16ee9327f153d23c9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Nutrition",
            "Personal Trainer",
            "Nutritionist"
          ],
          "disciplines": [
            "Core Fitness",
            "Hiit",
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 32,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/q1WFH89P-a439.png"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "Caro1.co.uk",
          "affiliation": "NRPT",
          "yearsOfExp": 2015,
          "offers": "30% Off Discount Offer to New Clients\n\nFree Consultation & Free Taster Session",
          "biography": "I am Caro Pemberton a Mobile Personal Trainer based in Birmingham. \n\nWhat made me want to be a personal trainer was down to the passion That I have for health and fitness. \n\nSitting down and thinking on the time and effort that I was putting into my daily training made me question myself why not turn my daily hobby into a daily career and from there I went into studying personal training level 2 and level 3 professional and began building my career in something that I do best.\n\n \n\nBefore becoming a Birmingham personal trainer I have always encouraged family and friends that trained with me into keeping health & fitness as a part of their life style. \n\nI enjoy helping others to keep fit and healthy so that they can progress further to achieving their goals through my years of experience in personal training.",
          "strapLine1": "Do You Want to Look Good Feel Good and Build up Your Confidence?"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JHAM63ud-caro%20facebook%20profile%20image.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 77,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 22,
      "agent": {
        "family": "IE",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; rv:11.0) like Gecko"
      },
      "lastLogin": 1508961175665,
      "username": "caro_pemberton",
      "approved_date": {
        "$date": "2017-10-27T09:05:21.763Z"
      }
    },
    {
      "_id": {
        "$oid": "59f24abb6ee9327f153d23b7"
      },
      "providerId": "107357935947095376793",
      "surname": "simutis",
      "firstName": "kostas",
      "email": "ksimutis@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-10-29T21:46:47.788Z"
      },
      "createdAt": {
        "$date": "2017-10-26T20:51:07.424Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/107357935947095376793",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+353",
            "number": "868711531"
          },
          "locations": [
            {
              "geolocation": [
                -6.87904253072115,
                53.71644477086225
              ],
              "city": "Kells",
              "postcode": "A82 Y6T0",
              "_id": {
                "$oid": "59f64c476ee9327f153d23d9"
              },
              "country": "Ireland",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Master Personal Trainer",
            "Kettlebell coach",
            "strength and conditioning"
          ],
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 50
          },
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8NZWCYlP-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1880600535314132/",
          "affiliation": "IUKL",
          "yearsOfExp": 2010,
          "biography": "I Started my sports career 1979 in Rowing and in 1984 I won a bronze medal in the World Championship Rowing competition when I represented the USSR National Team. From 1985 to 1990 I served in the USSR army (special forces) after which I trained the Lithuanian National Rowing Team.\n\nIn 2008 I qualified as personal trainer and nutritional adviser in NTC (National Training Centre) Dublin and at the same time underwent training in kettle bell sport. I have been a board member of the All Ireland Kettle Bell Sport Federation (AIKLF) since 2012.\n\nIn 2012 I won the international Kettle Bell Sport tournament in Latvia and in the same year picked up silver in the World Championship where I represented the Irish National Team.\n\nIn 2013 I won bronze in European Championships while once again representing Ireland.\n\nI have also trained actors on the set of Love Hate."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "EUR",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/p5ZUsML1-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 66,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 24,
      "username": "kostas_simutis",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.10.240 Version/10.0 Mobile/15A432 Safari/602.1"
      },
      "lastLogin": 1509196847566,
      "approved_date": {
        "$date": "2017-10-29T21:46:47.734Z"
      }
    },
    {
      "_id": {
        "$oid": "59f2a8cc6ee9327f153d23bb"
      },
      "providerId": "1411502041",
      "surname": "Fultz",
      "firstName": "Jim",
      "email": "jfultz2395@gmail.com",
      "username": "jim_fultz",
      "activation_code": "b1ec1ae06c55da2d66fb8e3ef2c6e660dd9f2b37",
      "lastUpdated": {
        "$date": "2017-11-03T12:00:01.042Z"
      },
      "createdAt": {
        "$date": "2017-10-27T03:32:28.592Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1411502041.8640130.ac5a73d5fba34b7e96a7617c07775cee",
            "link": "https://www.instagram.com/jimfultz",
            "username": "jimfultz"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "8157126519"
          },
          "locations": [
            {
              "city": "Ottawa",
              "geolocation": [
                -88.8344305,
                41.3328898
              ],
              "postcode": "61350",
              "_id": {
                "$oid": "59f2a96a6ee9327f153d23bc"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 20
          },
          "radius": 0
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/b9BraVrM_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 28
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": true,
      "provider": "instagram",
      "active": false,
      "role": "trainer",
      "__v": 6,
      "agent": {
        "family": "Chrome Mobile",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G950U Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.98 Mobile Safari/537.36"
      },
      "lastLogin": 1509075366879
    },
    {
      "_id": {
        "$oid": "59f87c2d6ee9327f153d23de"
      },
      "email": "kass129@wp.pl",
      "firstName": "Katarzyna",
      "surname": "Wasiluk",
      "promoCode": "PTEARLY",
      "activation_code": "41927e2fdde395928ddb39b6aa413c3ed6ce9f72",
      "salt": "ZroYVydSHrj4f88r/w/RRA==",
      "hashedPassword": "clglQ+oa2dkbTd1ZbTBzSuFcjPfJzoCAwZ3VApvGNMUo5TwKNmJoBum4VE6IOSKCuI3WYExI8iUZ16Fi+jRC5w==",
      "lastUpdated": {
        "$date": "2017-11-08T06:19:29.419Z"
      },
      "createdAt": {
        "$date": "2017-10-31T13:35:41.851Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1509456942126
    },
    {
      "_id": {
        "$oid": "59fb19df6ee9327f153d23df"
      },
      "providerId": "111475955616401747937",
      "surname": "Hardaway",
      "firstName": "Peyton",
      "email": "peytonplace105@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-11-10T12:00:01.736Z"
      },
      "createdAt": {
        "$date": "2017-11-02T13:13:03.489Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/111475955616401747937",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6utv4aUw_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "peyton_hardaway",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.10.240 Version/10.0 Mobile/15A432 Safari/602.1"
      },
      "lastLogin": 1509628384218
    },
    {
      "_id": {
        "$oid": "59ff992b6ee9327f153d23e7"
      },
      "email": "jhewittcoach@me.com",
      "firstName": "John JJ",
      "surname": "Hewitt",
      "promoCode": "PTEARLY",
      "activation_code": "659ca413495f710cf9e0e96246808504ce61093f",
      "salt": "ptBbFOcfGp8FO5piWiIc3g==",
      "hashedPassword": "0hjQ1qLeHL4AmyH54L+YKpvHTkohX//VbwVAL7GhnQiaBuu6w0jPyb7TEq0Ee8MAb8iPuCPV0Yeuj0VGKh3b5g==",
      "lastUpdated": {
        "$date": "2018-01-19T12:56:37.374Z"
      },
      "createdAt": {
        "$date": "2017-11-05T23:05:15.678Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7786868189",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.028057151679028017,
                51.591169822076935
              ],
              "city": "London",
              "postcode": "E18 1PS",
              "_id": {
                "$oid": "5a61eb05765eba094646f8dc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Health & Lifestyle Coach",
            "Nutritional Advisor",
            "NLP Practitioner"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Hiit",
            "Nutrition",
            "Weight Loss"
          ],
          "price": {
            "min": 60
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ByEnWlmd-IMG_2171.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "https://www.facebook.com/JohnH",
          "yearsOfExp": 2005,
          "biography": "Changing lives...\n\nI am a confident, enthusiastic individual who receives great satisfaction from helping and supporting people I work and socialise with. My aim is to develop further skills within my target industry and to seek ways to self improve. I'm of smart professional appearance and I take a huge amount of pride in my work by being able to change peoples lives.\n\nMy professional goal is too help as many people realise their potential in terms of their health and wellness, fitness and emotional well being. \n\nWith regards to my training philosophy, I'm a true advocate of HIIT training. I believe in a controlled, precise execution of any given exercise that is then taken to muscular exhaustion, always putting intensity before duration. I'm a very focused coach giving each client my full attention. As well as offering nutritional advise I also offer 1-2-1 therapy sessions for those who wish to overcome limiting beliefs and to improve cognitive well being.\n\n\"If it doesn't challenge you, it doesn't change you\"\n\nI lean towards a Paelo type way of eating with a few of my own adjustments and rely on eating whole foods. I don't advocate the use of lots of supplements but add just a few to top up my nutritional needs. I can't recommend enough the need for rest, recuperation and time to heal with the aid of organic, whole-food nutrition and quality supplementation."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8m4r9R4f-IMG_0539.PNG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 60
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 12,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 10_3_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.10.527 Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1509923115906,
      "username": "john_jj_hewitt",
      "approved_date": {
        "$date": "2017-11-06T09:32:47.393Z"
      }
    },
    {
      "_id": {
        "$oid": "5a004c47fa4ea6558755be44"
      },
      "providerId": "105280142258500666536",
      "surname": "Noble",
      "firstName": "Danny",
      "email": "nobledannypt@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:27:43.617Z"
      },
      "createdAt": {
        "$date": "2017-11-06T11:49:27.687Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7593106872",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.2650320000000193,
                52.370878
              ],
              "city": "Rugby",
              "postcode": "CV21 3DW",
              "_id": {
                "$oid": "5a5fdbef765eba094646f6eb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 2: Gym Instructor",
            "Level 2: Older Adults Instructor",
            "Level 2: First Aid / CPR",
            "Level 2: Disability",
            "Spinning Instructor",
            "Gym Based Boxing",
            "Kettle Bell Circuits",
            "Bootcamp"
          ],
          "disciplines": [
            "Group Classes",
            "Hiit",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 18
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MeY3RIEU-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/165427340719244/",
          "facebookPage": "dannynoblept",
          "instagram": {
            "access_token": "5533719087.8640130.bc55e51bea694bc5b92afd6fa28cc600",
            "link": "https://www.instagram.com/nobledannypt"
          },
          "affiliation": "REP's",
          "yearsOfExp": 7,
          "biography": "I believe a healthy outside starts from the inside. \nMy approach is to educate as well to train. A change in lifestyle is the best way to a lifelong healthy body,",
          "offers": "1 session - £30\n4 sessions - £90\n10 sessions - £180\n18 sessions - £270"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eFJELRjH-image.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 71,
        "parent": {
          "$oid": "58d24cb13fa3e80afcabc96b"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 38,
      "username": "danny_noble",
      "agent": {
        "family": "Mobile Safari",
        "major": "9",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 9_3_2 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13F69 Safari/601.1"
      },
      "lastLogin": 1511946577787,
      "approved_date": {
        "$date": "2017-11-08T12:21:37.743Z"
      }
    },
    {
      "_id": {
        "$oid": "5a004c9efa4ea6558755be46"
      },
      "email": "martaaureliabarczak@gmail.com",
      "firstName": "Marta",
      "surname": "Barczak",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "8yS+DjhmdhGKy7Qlkr9eTA==",
      "hashedPassword": "ZLbhCr/kseGl3Bk5tjEU2s/sl4XiHSSa/jC5/9JvCklHm8cFJzDnymtvUPnQAZkGdju04qFY9RPIGyezqgrPJA==",
      "lastUpdated": {
        "$date": "2018-02-28T13:11:11.315Z"
      },
      "createdAt": {
        "$date": "2017-11-06T11:50:54.915Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7561409834",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.7418836,
                52.035434
              ],
              "city": "Fishermead",
              "postcode": "MK6 2DJ",
              "_id": {
                "$oid": "5a5fdbb9765eba094646f6e9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR",
            "Nutrition Advice",
            "studio cycling instructor",
            "group exercise instructor",
            "boot camp instructor",
            "boxercise"
          ],
          "disciplines": [
            "Bootcamp",
            "Body Sculpting",
            "Core Fitness",
            "Functional Training",
            "Nutrition"
          ],
          "price": {
            "min": 30
          },
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mx2lG1DT-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1969989943027746/",
          "instagram": {
            "access_token": "5963008103.8640130.c03c7fd2fce6483ea214c15ef1fd6291",
            "link": "https://www.instagram.com/tomartab"
          },
          "strapLine1": "Every champion was once a contender- don't give up, fight for your goals!",
          "offers": "1 PT hour: 30£\n4 PT hours: 90£\n10 PT hours: 180 £\nValid for 30 days",
          "biography": "Hi! Are you looking for extra help and motivation in your fitness journey? I'm your shot :-) I know how it feels to be down and feel not right in own skin. I know every struggle. But guess what? You can face it all and become happy from the way you look. It all take patience and effort but I'll be there for you on every step. Get in touch and we will work it through! I am looking forward to meet you :-D"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/yTx52XhO-PicsArt_10-27-10.09.59.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 71,
        "parent": {
          "$oid": "58d234333fa3e80afcabc41b"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 52,
      "agent": {
        "family": "Chrome Mobile",
        "major": "64",
        "minor": "0",
        "patch": "3282",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G930F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.137 Mobile Safari/537.36"
      },
      "lastLogin": 1519823471233,
      "username": "marta_barczak",
      "password_reset_code": "X",
      "approved_date": {
        "$date": "2017-11-08T12:20:13.334Z"
      }
    },
    {
      "_id": {
        "$oid": "5a004df1fa4ea6558755be4e"
      },
      "email": "anakapelka@gmail.com",
      "firstName": "Ana",
      "surname": "Kapelka",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "3tSSx1jKB/BfAbKl0XRQoQ==",
      "hashedPassword": "91oHfApc6VU1kzfNMW38GENyVbOvYAd4m3uhPv0/vjFCvegyb686zTVoR99BU/g94+y1tHWgl4E5a4te4U6zfQ==",
      "lastUpdated": {
        "$date": "2018-01-22T10:27:25.091Z"
      },
      "createdAt": {
        "$date": "2017-11-06T11:56:33.563Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7516986899",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.7594171000000642,
                52.0406224
              ],
              "city": "Milton Keynes",
              "postcode": "MK9 3DX",
              "_id": {
                "$oid": "5a65bc8d5f0b475e1c9b1d0f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 3: Nutrition",
            "Level 2: First Aid / CPR",
            "PiYo instructor",
            "Spin instructor",
            "Circuit training instructor"
          ],
          "disciplines": [
            "Functional Training",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/m7JgJLET-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "3290539013.8640130.8feca314ef1a49599965afe5a651cd1b",
            "link": "https://www.instagram.com/a_fitmk"
          },
          "affiliation": "REPS",
          "yearsOfExp": 2017,
          "offers": "One session - £30\n4 sessions a month - £90\n10 sessions a month - £180",
          "strapLine1": "Want to tone your body, get into that dress or want to look great in this bikini on your next vacation - get in touch!",
          "biography": "I have been a gym user for the past 13 years helping family and friends along the way to achieve their body goals.\n\nMy experience, broad knowledge and passion for fitness will help you to get to your ideal body quicker! :)"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3sDt1NGW-unnamed%20(1).jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 74,
        "parent": {
          "$oid": "58d234333fa3e80afcabc41b"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 29,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516088655603,
      "username": "ana_kapelka",
      "approved_date": {
        "$date": "2018-01-10T12:56:23.374Z"
      }
    },
    {
      "_id": {
        "$oid": "5a004e81fa4ea6558755be51"
      },
      "providerId": "1301075606",
      "surname": "Delieu",
      "firstName": "Connor",
      "email": "c.delieu171@gmail.com",
      "username": "connor_delieu",
      "activation_code": "a6ca3f69bb7a3b441e4db1dc97f71389b3f0f6df",
      "lastUpdated": {
        "$date": "2018-01-17T23:22:03.643Z"
      },
      "createdAt": {
        "$date": "2017-11-06T11:58:57.555Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1301075606.8640130.b7594d4d0d464c4e92f60d720eb9633c",
            "link": "https://www.instagram.com/connor_delieu",
            "username": "connor_delieu"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7591638913",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.0878060000000005,
                51.76778699999999
              ],
              "city": "Harlow",
              "postcode": "CM19 4BX",
              "_id": {
                "$oid": "5a5fda9b765eba094646f6e7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3 sports strength and conditioning",
            "Level 2: Gym Instructor",
            "indoor cycling"
          ],
          "disciplines": [
            "Bodybuilding",
            "Functional Training",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Wj4n8Tqf-IMG_20170923_095103_582.jpg"
          },
          "profileTitle": "Personal Trainer",
          "google": "https://plus.google.com/116335278943782735800",
          "twitter": {
            "accessToken": "922433753418489856-vQ00Fsi9fF9RaPUamkuq23C3MLLib7H",
            "accessTokenSecret": "LdXDncM8rTjAYAK2qrMN1bD8bA9VXxebPmGENs7qvTIJc",
            "link": "https://twitter.com/ConnorDelieuPT",
            "screen_name": "ConnorDelieuPT",
            "user_id": "922433753418489856"
          },
          "affiliation": "REPs",
          "yearsOfExp": 2017,
          "offers": "1 one 2 one session -£30\n\n4 one 2 one sessions-£90 \n\n10 one 2 one sessions-£180",
          "biography": "Hi, my name is  Connor and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nIIov5wh-IMG_20171027_194637_292.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 70,
        "parent": {
          "$oid": "58d162603fa3e80afcab9747"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "active": false,
      "role": "trainer",
      "__v": 15,
      "agent": {
        "family": "Chrome Mobile WebView",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G935F Build/NRD90M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/61.0.3163.98 Mobile Safari/537.36 Instagram 21.0.0.11.62 Android (24/7.0; 640dpi; 1440x2560; samsung; SM-G935F; hero2lte; samsungexynos8890; en_GB)"
      },
      "lastLogin": 1510010454090,
      "approved_date": {
        "$date": "2018-01-10T16:18:41.671Z"
      }
    },
    {
      "_id": {
        "$oid": "5a009787fa4ea6558755be72"
      },
      "providerId": "115945049033036313902",
      "surname": "Spinelli",
      "firstName": "Frank",
      "email": "frank@longden.net",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-07-02T19:51:14.029Z"
      },
      "createdAt": {
        "$date": "2017-11-06T17:10:31.070Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/115945049033036313902",
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "number": "7950305865",
            "code": "+44"
          },
          "locations": [
            {
              "city": "Lambourne End",
              "geolocation": [
                0.1372719,
                51.634813199999996
              ],
              "postcode": "RM4 1AS",
              "_id": {
                "$oid": "5b360352cb6b7909d3f6c906"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.11706290000006447,
                51.4398548
              ],
              "city": "London",
              "postcode": "SW2 3SN",
              "_id": {
                "$oid": "5b3602a2cb6b7909d3f6c904"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "qualifications": [
            "Abs Class",
            "Childrens Play Area",
            "Body Attack Class",
            "Towels",
            "Lockers"
          ],
          "disciplines": [
            "Academy",
            "All Abilities"
          ],
          "price": null,
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/k23ckunp-filename.jpeg"
          },
          "profileTitle": "Gymnasium / Health Club",
          "biography": "bio here",
          "companyName": "Training Ltd",
          "companyWebsite": "https://www.oneshoo.com/trainer/wizard/additional",
          "twitter": {
            "accessToken": "2412906253-wIpcBDkTJ6zJHUqmTpXuIu81ZqjgAIrfmmA8A1p",
            "accessTokenSecret": "HICqH4SX21InfpOJKKMWoV0op4Rqtjpt8NNUUiM0xqwSc",
            "link": "https://twitter.com/fspinell",
            "screen_name": "fspinell",
            "user_id": "2412906253"
          },
          "affiliation": "REPs",
          "yearsOfExp": 2014
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mmWdUTHT-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": true,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 58,
        "parent": null
      },
      "reminder_email_sent": true,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 54,
      "username": "training_ltd",
      "agent": {
        "family": "Chrome",
        "major": "67",
        "minor": "0",
        "patch": "3396",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/67.0.3396.99 Safari/537.36"
      },
      "lastLogin": 1530266478356,
      "approved_date": {
        "$date": "2018-06-21T10:45:31.220Z"
      }
    },
    {
      "_id": {
        "$oid": "5a019cd6666de66e5617dfc1"
      },
      "email": "general.manager.bolton@xercise4less.co.uk",
      "firstName": "Tim",
      "surname": "Weir",
      "promoCode": "PTEARLY",
      "activation_code": "0d161c60b91b516dbc140dea48eafd7d54218b2c",
      "salt": "Vs67oXNVue1UL3YyIvOMMA==",
      "hashedPassword": "hCdj+C4mAmn+M4dpdCurwk8PbaUW/lnUqCgmq7h8I/+0dfXIPWtArFnACuEOvAfXtazR51WyHRFuMGmj8Hhh6w==",
      "lastUpdated": {
        "$date": "2017-11-14T12:00:01.019Z"
      },
      "createdAt": {
        "$date": "2017-11-07T11:45:26.938Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "1204322828"
          },
          "locations": [
            {
              "geolocation": [
                -2.4282192000000578,
                53.57686469999999
              ],
              "city": "Bolton",
              "postcode": "BL1 1LS",
              "_id": {
                "$oid": "5a019d72666de66e5617dfc3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Gymnasium",
            "Air Conditioning",
            "Fitness Studio",
            "Cycle Studio"
          ],
          "disciplines": [
            "Boxing",
            "Cardiovascular",
            "Fitness Classes",
            "Free Fitness Check",
            "Personal Training"
          ],
          "price": null,
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VIM8OKHU-filename.jpeg"
          },
          "companyName": "Xercise4Less Bolton",
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/bolton-gym/",
          "profileTitle": "Gymnasium / Health Club",
          "yearsOfExp": 2010,
          "strapLine1": "4FiiT"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RJjMn4Ez-15823394_144454299379926_5376133920522277271_n.jpg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 45,
        "parent": {
          "$oid": "58ca6a098972aa10a5b2a8e1"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 7,
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "lastLogin": 1510055127143,
      "username": "xercise4less_bolton"
    },
    {
      "_id": {
        "$oid": "5a0234c1666de66e5617dfef"
      },
      "email": "seanmckirdy8@gmail.com",
      "firstName": "Sean",
      "surname": "Mckirdy",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "cFnl3/7uzP4cuDqHXg3u7w==",
      "hashedPassword": "wpnuCoGVtKqwfRBSIGtmVImJNINpqxPMNPY8L1NGVksCJPBgMxIHD9MTFmUiPDtJJFzgxmZWAZX/a9wquOklyQ==",
      "lastUpdated": {
        "$date": "2018-01-17T23:21:05.596Z"
      },
      "createdAt": {
        "$date": "2017-11-07T22:33:37.674Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7578059995",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -3.188266999999996,
                55.953252
              ],
              "city": "Edinburgh",
              "postcode": "EH1 3EG",
              "_id": {
                "$oid": "5a5fda61765eba094646f6e6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Hiit",
            "Movement",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 18
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/yBfREYH8-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "221197117.8640130.332e12c3ecdc4059b23f7564ced13c77",
            "link": "https://www.instagram.com/seanmckirdy35"
          },
          "twitter": {
            "accessToken": "281188975-sGfIWc8COTz6pJkbd7Y8tiqF4CJu1Xf9PMz5nNUu",
            "accessTokenSecret": "A149YLBEWywPrqEBZ5ATYt3DPBl0jGPO5Ojj466AyXNor",
            "link": "https://twitter.com/seanmckirdy35",
            "screen_name": "seanmckirdy35",
            "user_id": "281188975"
          },
          "yearsOfExp": 2017,
          "strapLine1": "'It's never to late to be what you could have been.'",
          "biography": "New to the industry but can offer a wide range of experience having played professional football the last 3 years. Try to make programmes as in depth and specific as possible while keeping it fun! Specialise in toning, weight loss, sport specific and functional training.",
          "offers": "1 session:£30\n4 sessions:£90\n10 sessions: £180\nTransformation camp:£100*\nCouples PT also available!"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kgijroXK-IMG_7964.JPG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 74,
        "parent": {
          "$oid": "58d152073fa3e80afcab93c1"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 21,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_1 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C153 Safari/604.1"
      },
      "lastLogin": 1516201267460,
      "username": "sean_mckirdy",
      "approved_date": {
        "$date": "2017-11-08T12:17:52.719Z"
      }
    },
    {
      "_id": {
        "$oid": "5a02ae3f430ff53fda549aeb"
      },
      "providerId": "10159552421295427",
      "surname": "McGuire",
      "firstName": "Elizabeth",
      "email": "e_male@hotmail.ca",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-11-15T12:00:01.386Z"
      },
      "createdAt": {
        "$date": "2017-11-08T07:11:59.337Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10159552421295427/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xVhRhlpt_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "elizabeth_mcguire",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.10.527 Version/10.0 Mobile/15A432 Safari/602.1"
      },
      "lastLogin": 1510125119855
    },
    {
      "_id": {
        "$oid": "5a030b84430ff53fda549af9"
      },
      "email": "phillip-27@hotmail.co.uk",
      "firstName": "Phil",
      "surname": "Lloyd",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "Hsmq8uRhClgeLu4yGFPD6Q==",
      "hashedPassword": "Ctam7YaexBK/F6nEZIdkdXEYGI8qQQQwpgGF2GxtSGB5RVGEL91fBBIWGscysqyxcDKlfGPoQjd3Ka580Ka7QA==",
      "lastUpdated": {
        "$date": "2017-11-09T17:59:11.612Z"
      },
      "createdAt": {
        "$date": "2017-11-08T13:49:57.001Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "720216190"
          },
          "locations": [
            {
              "geolocation": [
                -2.9915726000000404,
                53.4083714
              ],
              "city": "Liverpool",
              "postcode": "L2 2DP",
              "_id": {
                "$oid": "5a031b41430ff53fda549b01"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: Group Exercise to Music Instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 18
          },
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/z3sqozVe-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "1476049151.8640130.45e446d89fc446b2adf8b1001e839502",
            "link": "https://www.instagram.com/phillloydpt"
          },
          "yearsOfExp": 2012,
          "offers": "FREE mini consultation\n15 minutes consultation to discus your specific training goals and requirements. Feel free to approach me to ask questions on the gym floor or online to book a mini consultation. \n\nFull consultation £20.00\nA 30 minute to 1 hour in depth consultation to discuss your specific training goals and requirements. This includes a fitness test and taking measurements if you wish.\n\nX1 Personal training session £25.00\nThis consists of free full consultation, x1 personal training session based on your specific training goals and requirements. A workout programme for non PT sessions and nutritional advice to maximise results.  \n\nX4 Personal training session £100.00\nThis consists of free full consultation, x4 personal training session based on your specific training goals and requirements. A workout programme for non PT sessions and nutritional advice to maximise results. \n\nX10 Personal training session £180.00\nThis consists of free full consultation, x10 personal training session based on your specific training goals and requirements. A workout programme for non PT sessions and nutritional advice to maximise results. \n\nWorkout Programme £20.00\nA specific workout programme for non PT sessions and nutritional advice to maximise results.",
          "biography": "I have always participated in sporting activities from an early age playing football at a consistently high level. I went on to improve performance further by regularly attending my local gym gaining a vast amount of experience in fitness training. I have a background in combat sports from an early age and experience in Boxing, Mixed martial arts, wrestling, Brazilian Jiu jitsu, boxing circuits, strength & conditioning and weight loss. I will tailor all programmes and nutritional advice specifically for the individual’s goals, whether this is weight loss, improving sport performance, gaining muscle mass, reducing body fat % or any other requirements.  \n\nI have trained with some of the best athletes in the country whether this is semi pro football or professional mixed martial arts. I know what it takes to improve your fitness levels beyond your imagination.  I am friendly, enthusiastic and will motivate you to achieve a happier and healthier lifestyle. I understand that everybody’s fitness abilities are different so whether you are a complete novice or an advanced athlete I can continually educate and help you achieve your goals. With hard work and determination from you, and my guidance, I can guarantee improvements in attaining your desired goals. Whether your goal is to achieve a 6 pack, increase sports performance or lose weight, you will benefit from my experience in strength and conditioning, circuit training, weight management and mixed martial arts.",
          "strapLine1": "Weight loss Specialist"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IisYFJkW-IMG_3325.JPG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 61,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 29,
      "agent": {
        "family": "Firefox",
        "major": "45",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:45.0) Gecko/20100101 Firefox/45.0"
      },
      "lastLogin": 1510152986397,
      "username": "phil_lloyd",
      "approved_date": {
        "$date": "2017-11-09T17:59:11.565Z"
      }
    },
    {
      "_id": {
        "$oid": "5a03cdce430ff53fda549b03"
      },
      "email": "get2fitsd@gmail.com",
      "firstName": "Parker",
      "surname": "Lennon",
      "promoCode": "PTEARLY",
      "activation_code": "496d5720b1ee604920fe1e82b8e14485ee56ebed",
      "salt": "Ku23cxQ5iRU2AyBy3gw8EQ==",
      "hashedPassword": "mph+guujP/gzCrgRUidw6GM0MQNi1RVo1jTUMC0SrCBvPbIryC1pnRGpAmsZ9kT7PZkSrbwVsHyLJYblS0zEDQ==",
      "lastUpdated": {
        "$date": "2017-11-16T12:00:01.031Z"
      },
      "createdAt": {
        "$date": "2017-11-09T03:38:54.174Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+1",
            "number": "7657497984"
          },
          "locations": [
            {
              "city": "Temple Terrace",
              "geolocation": [
                -82.38804266751988,
                28.05209753953399
              ],
              "postcode": "33617",
              "_id": {
                "$oid": "5a03ce12430ff53fda549b04"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 50
          },
          "radius": 20
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 22
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.7.8829 Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "lastLogin": 1510198734882,
      "username": "parker_lennon"
    },
    {
      "_id": {
        "$oid": "5a040944430ff53fda549b05"
      },
      "providerId": "5831459535",
      "surname": "Pourreza",
      "firstName": "Masoud",
      "email": "masoudprrz@yahoo.com",
      "username": "masoud_pourreza",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:19:01.694Z"
      },
      "createdAt": {
        "$date": "2017-11-09T07:52:36.409Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "5831459535.8640130.358dff7802194655a33b6a5d71c2f8d2",
            "link": "https://www.instagram.com/masoud.pourreza",
            "username": "masoud.pourreza"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "9359971119",
            "code": "+98"
          },
          "locations": [
            {
              "geolocation": [
                50.1708424,
                37.2025481
              ],
              "city": "Langarud",
              "_id": {
                "$oid": "5a5fd9e5765eba094646f6e5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Level 3: Master Personal Trainer",
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR",
            "Fitness personal trainer"
          ],
          "disciplines": [
            "Bodybuilding",
            "Core Fitness",
            "Nutrition",
            "Online Coaching",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 50
          },
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9cOSYKDO-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "Ifbb coaching diploma",
          "yearsOfExp": 2008,
          "biography": "I have 6years of experience in the fitness and training industry.I can make workouts interesting and fun,I'm a great motivator.I know injury prevention and correction.I have experience in advanced program design for athletes,knowledge customizing nutritional background,understanding of sportspsychology.I'm dedicated to bringing out the best in others.I have knowledge of physical fitness,strength training and property exercise techniques.I love the idea of working and being in the gym,I'm passionate about changing lives,and making an impact on the lives of all clients.\nName:\nmasoud pourreza\nEmail:\nmasoudprrz@Yahoo.com",
          "offers": "Online coaching.nutrition and exercise program"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/isKWXBCO-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 69
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "active": true,
      "role": "trainer",
      "__v": 16,
      "agent": {
        "family": "Chrome Mobile",
        "major": "59",
        "minor": "0",
        "patch": "3071",
        "source": "Mozilla/5.0 (Linux; Android 4.1.2; HTC Desire 500 Build/JZO54K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.125 Mobile Safari/537.36"
      },
      "lastLogin": 1510219933215,
      "approved_date": {
        "$date": "2017-11-09T10:33:25.650Z"
      }
    },
    {
      "_id": {
        "$oid": "5a047fd4430ff53fda549b0d"
      },
      "providerId": "1992952917619001",
      "surname": "Dalton",
      "firstName": "Matthew",
      "email": "matthewdalton383@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-11-17T12:00:01.599Z"
      },
      "createdAt": {
        "$date": "2017-11-09T16:18:28.821Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1992952917619001/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NjoAQptB_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "matthew_dalton",
      "agent": {
        "family": "Chrome Mobile WebView",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; D5803 Build/23.5.A.1.291; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/62.0.3202.84 Mobile Safari/537.36"
      },
      "lastLogin": 1510244309253
    },
    {
      "_id": {
        "$oid": "5a050219430ff53fda549b0e"
      },
      "providerId": "10100542266700139",
      "surname": "Kontra",
      "firstName": "Bryan",
      "email": "bryan.kontra@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-11-17T12:00:01.103Z"
      },
      "createdAt": {
        "$date": "2017-11-10T01:34:17.100Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10100542266700139/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+1",
            "number": "2039935731"
          },
          "locations": [
            {
              "city": "Milford",
              "geolocation": [
                -73.06403599999999,
                41.2306979
              ],
              "postcode": "06460",
              "_id": {
                "$oid": "5a05036e430ff53fda549b0f"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 200
          },
          "radius": 0
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IEA230ES_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 28
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 2,
      "username": "bryan_kontra",
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.89 Safari/537.36"
      },
      "lastLogin": 1510277657501
    },
    {
      "_id": {
        "$oid": "5a05c3a5430ff53fda549b11"
      },
      "email": "kieran.hoskinspt@gmail.com",
      "firstName": "Kieran",
      "surname": "Hoskins",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "VOU+qA4EYStizyZPemspGw==",
      "hashedPassword": "q8xNYvzngEiuFV6Gx796devJd5afy2spu99TTpRyQh1AKnVQeQpV10MB2IRBkpx/jUrI0vgTfLXTS0UCgfHqDQ==",
      "lastUpdated": {
        "$date": "2018-01-17T23:17:33.912Z"
      },
      "createdAt": {
        "$date": "2017-11-10T15:20:05.958Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7491369441",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -3.9385189,
                51.6239671
              ],
              "city": "Swansea",
              "postcode": "SA1 2AA",
              "_id": {
                "$oid": "5a5fd98d765eba094646f6e4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Football Coach"
          ],
          "disciplines": [
            "Circuit Training",
            "Endurance",
            "Hiit",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/C7WKw5Cb-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPS",
          "yearsOfExp": 2016,
          "strapLine1": "\"You got one life to live. Why wait until tomorrow to start it?” – Greg Plitt",
          "biography": "Hi, my name is Kieran and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8mpjB63p-IMG-20170618-WA0005.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 67,
        "parent": {
          "$oid": "58d266573fa3e80afcabcf02"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 16,
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"
      },
      "lastLogin": 1510327206197,
      "username": "kieran_hoskins",
      "approved_date": {
        "$date": "2017-11-10T15:58:29.776Z"
      }
    },
    {
      "_id": {
        "$oid": "5a05eafd430ff53fda549b16"
      },
      "email": "ashleyptn@gmail.com",
      "firstName": "Ashley",
      "surname": "Hawksworth",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "XjyXnVWOJ1qvXlGqD1zZMA==",
      "hashedPassword": "3WqiDnaslVZj67SrVa1VqNELK19fUx6NWCiu0/5xrTpzCb/Oe1a7r3oPy1MEeJtBpCFwhLNjhKtxls9AbXlKUQ==",
      "lastUpdated": {
        "$date": "2018-01-17T23:16:14.331Z"
      },
      "createdAt": {
        "$date": "2017-11-10T18:07:57.362Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7874085252",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.465317,
                54.996899
              ],
              "city": "Royal Quays",
              "postcode": "NE29 6DE",
              "_id": {
                "$oid": "5a5fd93e765eba094646f6e3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: Physical Activity for Children Instructor",
            "Level 2: First Aid / CPR",
            "Nutritionist"
          ],
          "disciplines": [
            "4 Kids",
            "Bootcamp",
            "Circuit Training",
            "Nutrition",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/jtD3s3er-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "hawksworthfitness",
          "yearsOfExp": 2017,
          "strapLine1": "Weight Loss - Weight Gain - Build Muscle - Nutrition - Kids Class. Whatever it is, I can help!",
          "offers": "",
          "biography": "Hi, my name is Ashley and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IpEzseBm-AfterSpin001.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 67,
        "parent": {
          "$oid": "58d240803fa3e80afcabc6a3"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 18,
      "agent": {
        "family": "IE",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; rv:11.0) like Gecko"
      },
      "lastLogin": 1510337878452,
      "username": "ashley_hawksworth",
      "approved_date": {
        "$date": "2018-01-09T21:00:34.364Z"
      }
    },
    {
      "_id": {
        "$oid": "5a05fab6430ff53fda549b1b"
      },
      "providerId": "10159465984300368",
      "surname": "Hill",
      "firstName": "Thomas",
      "email": "thomashill.mem@googlemail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-11-10T19:38:45.425Z"
      },
      "createdAt": {
        "$date": "2017-11-10T19:15:02.603Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10159465984300368/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "7896014138"
          },
          "locations": [
            {
              "geolocation": [
                -0.39432439999999996,
                54.284238599999995
              ],
              "city": "Scarborough",
              "postcode": "YO11 1QG",
              "_id": {
                "$oid": "5a05fb39430ff53fda549b1d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Strength Matters Certified Level 1 and 2"
          ],
          "disciplines": [
            "Flexibility",
            "Functional Training",
            "Movement",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 30
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uuGHp436-IMG_20171018_095923_643%20(1).jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "tomhillPT",
          "instagram": {
            "access_token": "8668053.8640130.d9a988802fc94fdbbc7c4a2d60ae6f15",
            "link": "https://www.instagram.com/tomhill_pt"
          },
          "strapLine1": "Functional    Fitness     Mobility     Conditioning",
          "biography": "I'm training the everyday athlete. Someone who wants their work in the gym to make their life easier."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gfTzBJc8-FB_IMG_1475665777279.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 58
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 9,
      "username": "thomas_hill",
      "agent": {
        "family": "Chrome",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (X11; CrOS x86_64 9765.85.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.123 Safari/537.36"
      },
      "lastLogin": 1510341302863,
      "approved_date": {
        "$date": "2017-11-10T19:38:45.398Z"
      }
    },
    {
      "_id": {
        "$oid": "5a05fdf4430ff53fda549b1f"
      },
      "email": "contact@hiitlifefitness.com",
      "firstName": "Nick",
      "surname": "Marino",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "ow7TJAYgjEsXBBi3TIAfXQ==",
      "hashedPassword": "sJaGqXE2IGN/7gMfLbooi0/iizQxo5pWnCSwEoVFG/vDWlVRI8JIKcE3xvctFTCApLb8Mn1INef5AzDYa9ZxNw==",
      "lastUpdated": {
        "$date": "2017-11-16T11:12:42.872Z"
      },
      "createdAt": {
        "$date": "2017-11-10T19:28:52.444Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "code": "+1",
            "number": "7027517348"
          },
          "locations": [
            {
              "geolocation": [
                -115.2412496,
                36.0844989
              ],
              "city": "Las Vegas",
              "postcode": "89118",
              "_id": {
                "$oid": "5a0d72aa430ff53fda549bb1"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "No Contract",
            "Corporate Discounts",
            "24 hr Entry",
            "Air Conditioning",
            "Cable Machines",
            "Cardio Stations",
            "Fitness Studio",
            "Free Parking",
            "Free Wi-Fi",
            "Free Weights Selection",
            "Functional Training Rig",
            "Lounge Area",
            "Suspension Equipment",
            "Resistance Equipment",
            "Punch Bags",
            "Mat Area"
          ],
          "disciplines": [
            "Corporate Services",
            "Diet & Nutrition",
            "Fitness Classes",
            "Personal Training",
            "Strength & Conditioning"
          ],
          "price": null,
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5oIZfcLm-hitlife_weiss-40.JPG"
          },
          "companyName": "HIIT Life Fitness",
          "companyWebsite": "http://www.HIITLifeFitness.com",
          "profileTitle": "Gymnasium / Health Club",
          "facebookPage": "HIITlifefitness",
          "yearsOfExp": 2017,
          "biography": "HIIT Life is dedicated to changing lives through fitness. We offer members no contracts, no annual fees, personal and comfortable atmosphere, high-quality workout equipment, internet access at your fingertips, and 24/7 access to our club. With HIIT Life Fitness, we provide more than just a gym experience, we want to use our collective 30+ years of knowledge and appreciation for wellness to build a community that empowers each member to be the healthiest, happiest version of themselves. Health is not just about working out, it is about you. With HIIT Life Fitness you’ll have everything you need to get the results you want.",
          "strapLine1": "Inspiring Life Changes: Building Healthy Futures",
          "google": "https://plus.google.com/108363711073599629500",
          "instagram": {
            "access_token": "5416894103.8640130.c1a93e5f87a6491ab601089b85aceffb",
            "link": "https://www.instagram.com/hiitlifefitness"
          },
          "twitter": {
            "accessToken": "878124018804473856-jlYMoavOOTM1jOaCWiQPNSzr0sWai8H",
            "accessTokenSecret": "YAfumwl1pR9KhwdeMj35SsaGa3WH66PgD7t02l1z6Mefd",
            "link": "https://twitter.com/HIITLifeFitness",
            "screen_name": "HIITLifeFitness",
            "user_id": "878124018804473856"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/fvJp6Ksh-Logo%20Small.png"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 71
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 21,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 11_1_1 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B150 Safari/604.1"
      },
      "lastLogin": 1510343093887,
      "username": "hiit_life_fitness",
      "approved_date": {
        "$date": "2017-11-10T19:50:24.112Z"
      }
    },
    {
      "_id": {
        "$oid": "5a075b45430ff53fda549b26"
      },
      "email": "alpheushyde@gmail.com",
      "firstName": "Alpheus",
      "surname": "Hyde",
      "promoCode": "PTEARLY",
      "activation_code": "ad55fc895fdaa624f88a2893653a2e10c4998d68",
      "salt": "b+IOyPjfYAUxk1V7kXnlng==",
      "hashedPassword": "npOoz/gzkT580xkptK1wMcZ69/CCoGvn3g1iEgrjbJLoW5622VyRva+KUwh4tema2qSGQYW3HKzHT0nz1uMRLg==",
      "lastUpdated": {
        "$date": "2017-11-19T12:00:01.598Z"
      },
      "createdAt": {
        "$date": "2017-11-11T20:19:17.816Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44",
            "number": "38174080"
          },
          "locations": [
            {
              "city": "Hounslow",
              "geolocation": [
                -0.37314900000001217,
                51.4609218
              ],
              "postcode": "TW3 3SY",
              "_id": {
                "$oid": "5a075bbc430ff53fda549b27"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 30
          },
          "radius": 0
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 22
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.89 Safari/537.36"
      },
      "lastLogin": 1510431558028,
      "username": "alpheus_hyde"
    },
    {
      "_id": {
        "$oid": "5a0842e3430ff53fda549b29"
      },
      "email": "jamie88shaw@gmail.com",
      "firstName": "Jamie-Lynn",
      "surname": "Shaw",
      "promoCode": "PTEARLY",
      "activation_code": "38746eee54a014053161b20cf6b79c20e76e9f3f",
      "salt": "Se7i7f3RQqqXVSHXr2KFFA==",
      "hashedPassword": "XMKfF9EBKLfPMu4TcBfl28RPKo5lS86rNanyUQ57sjEhE9IfhLHu91NsJlvC4/m2EIh+s6nCuiYKQ17uQa/xlQ==",
      "lastUpdated": {
        "$date": "2018-01-17T23:10:07.085Z"
      },
      "createdAt": {
        "$date": "2017-11-12T12:47:31.879Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7445609427",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.3915419999999585,
                55.875322
              ],
              "city": "Renfrew",
              "postcode": "PA4 0RU",
              "_id": {
                "$oid": "5a5fd7cf765eba094646f6e2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Level 4: Obesity & Diabetes",
            "Level 3: Nutrition",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "Level 2: Disability"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Hiit",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/NlRTR1uA-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "twitter": {
            "accessToken": "2419457366-WpXnSlFGr2UYYCju5Ch6cB9kDwePskpFCnatGnw",
            "accessTokenSecret": "LJdINBUw57i6Rvy5aGOv7gGaCHUxqhofcnUAMX5QL6Jqd",
            "link": "https://twitter.com/JamieShawLynn",
            "screen_name": "JamieShawLynn",
            "user_id": "2419457366"
          },
          "affiliation": "CanFitPro, Certified Nutritional Practitioner",
          "yearsOfExp": 2011,
          "biography": "Hi, my name is Jamie-Lynn and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ca3LGpVY-Jamie%20Shaw%20PT.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 61,
        "parent": {
          "$oid": "58d248043fa3e80afcabc867"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 9,
      "agent": {
        "family": "Chrome",
        "major": "38",
        "minor": "0",
        "patch": "2125",
        "source": "Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/38.0.2125.104 Safari/537.36"
      },
      "lastLogin": 1510490852104,
      "username": "jamie-lynn_shaw",
      "approved_date": {
        "$date": "2018-01-10T13:12:54.650Z"
      }
    },
    {
      "_id": {
        "$oid": "5a0afa6a430ff53fda549b48"
      },
      "email": "davecp66@hotmail.com",
      "firstName": "Dave",
      "surname": "Playfoot",
      "promoCode": "PTEARLY",
      "activation_code": "841cc1597d87599a0be304bb91064acb5bd95e65",
      "salt": "lDOFSyDPDZA8Kui3Ug9ujg==",
      "hashedPassword": "43Zb0sE6zwhej+BaAcuEtzP7YfvUfNAKRciGXrQFc0WyqevInVbJZfXab1M59GveB+IegTplrKVxtuQXFT9O4A==",
      "lastUpdated": {
        "$date": "2018-01-17T23:09:22.493Z"
      },
      "createdAt": {
        "$date": "2017-11-14T14:15:06.417Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7971525658",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.3367412999999715,
                53.7456709
              ],
              "city": "Hull",
              "postcode": "HU1",
              "_id": {
                "$oid": "5a5fd7a2765eba094646f6e0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Endurance",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 5,
          "biography": "Hi, my name is Dave and I am dedicated to help you reach your health & fitness goals.",
          "profileTitle": "Personal Trainer",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6AQ49DZS-x4lessbg4fit.JPG"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vZZVyeqD-dave%20playfoot.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d168183fa3e80afcab988b"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 23,
      "agent": {
        "family": "Chrome Mobile",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G920F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.84 Mobile Safari/537.36"
      },
      "lastLogin": 1510671571500,
      "username": "dave_playfoot",
      "approved_date": {
        "$date": "2018-01-10T16:13:24.327Z"
      }
    },
    {
      "_id": {
        "$oid": "5a0b4a5c430ff53fda549b56"
      },
      "email": "lisabayley@aol.com",
      "firstName": "Lisa",
      "surname": "Bayley",
      "promoCode": "PTEARLY",
      "activation_code": "829499eb7fc7cdf740ce4783e1a4f9cad87765b8",
      "salt": "adb+BWeyTNB8lXeSiXjutA==",
      "hashedPassword": "InX/7psdyolfls0ePrRGcOyeRcfN6FQvAboqwt3MXCRKnlkGbgpd0QD6PM/b3X9eJaBBRGh41V1XdeDD3MlRNg==",
      "lastUpdated": {
        "$date": "2018-01-17T23:08:31.516Z"
      },
      "createdAt": {
        "$date": "2017-11-14T19:56:12.851Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7375092941",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.3046034999999847,
                51.0674612
              ],
              "city": "Horsham",
              "postcode": "RH13 6DE",
              "_id": {
                "$oid": "5a5fd76f765eba094646f6df"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 3: Advanced Personal Trainer",
            "nutritionist",
            "sports massage.",
            "Power plate accredited instructor"
          ],
          "disciplines": [
            "Mobile Trainer",
            "Nutrition",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 40
          },
          "radius": 30,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TQvu7VHJ-06466656-4177-4B72-BD7C-F8E78F4CADB5.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "lisabayley@aol.com",
          "affiliation": "Reps and premiere",
          "yearsOfExp": 1998,
          "biography": "Hi my name is Lisa and I have been a fitness trainer and personal trainer since 1998.\nMy passion is helping others achieve there lifetime goals. \nWhether it’s losing weight.\nRunning a Marsthon.\nOr just improving there health and lifestyle.",
          "strapLine1": "www.horshampowerplatesndptstudio.co.",
          "offers": "Power plate : 10 sessions of 25 mins £350\nPt sessions : 10 sessions of 30 mins £350\nPt sessions 10 sessions of 1 hour £400\nBikini plan £400 for 12 sessions \nMarathon training :£700",
          "facebook": "https://www.facebook.com/app_scoped_user_id/500666470298778/"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3aaLVGXn-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 77,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 34,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 11_1_1 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B150 Safari/604.1"
      },
      "lastLogin": 1510802716944,
      "username": "lisa_bayley",
      "approved_date": {
        "$date": "2017-11-15T14:12:53.883Z"
      }
    },
    {
      "_id": {
        "$oid": "5a0b7aea430ff53fda549b5a"
      },
      "email": "laurenconey@yahoo.co.uk",
      "firstName": "Lauren",
      "surname": "Coney",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "GMoUaC3D64LXoHDgDExTCA==",
      "hashedPassword": "jfCYIzewT+YPRw1/BL4xDSdIXhP3P7o1hV4SfCSFp8emrmCcpeUgkbL52/W7xG7Uxn6Qc53vmbV36W/9MksNiA==",
      "lastUpdated": {
        "$date": "2018-01-17T23:07:34.712Z"
      },
      "createdAt": {
        "$date": "2017-11-14T23:23:22.801Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7809637492",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.3367412999999715,
                53.7456709
              ],
              "city": "Hull",
              "postcode": "HU1",
              "_id": {
                "$oid": "5a5fd736765eba094646f6de"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Core Fitness",
            "Endurance",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "biography": "Hi, my name is Lauren and I am dedicated to help you reach your health & fitness goals.",
          "profileTitle": "Personal Trainer",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JGVwlrLf-x4lessbg4fit.JPG"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/v9dI7kXI-lauren%20coney.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d168183fa3e80afcab988b"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 6,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_1_2 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B202 Safari/604.1"
      },
      "lastLogin": 1511532364620,
      "password_reset_code": "X",
      "username": "lauren_coney",
      "approved_date": {
        "$date": "2018-01-10T16:11:54.457Z"
      }
    },
    {
      "_id": {
        "$oid": "5a0d8a1c430ff53fda549bb5"
      },
      "email": "chrissallispt@gmail.com",
      "firstName": "Chris",
      "surname": "Sallis",
      "promoCode": "PTEARLY",
      "activation_code": "bc7e6f6af34243701e0fa20885ee95f9033754a9",
      "salt": "LtyJhIAA2EXDCoHfs7iU7Q==",
      "hashedPassword": "JvksQjuarRs2onf9sZIkRUfDEsH1wUMWnMj9pZAC4VST/ZtaQqrhDO4iXq4exaggUJMvyNGki9ppoXDd/XS64A==",
      "lastUpdated": {
        "$date": "2017-11-24T12:00:01.152Z"
      },
      "createdAt": {
        "$date": "2017-11-16T12:52:44.514Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome Mobile",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G925F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.84 Mobile Safari/537.36"
      },
      "lastLogin": 1510836764898
    },
    {
      "_id": {
        "$oid": "5a0e9483430ff53fda549bb7"
      },
      "email": "andi_digs@yahoo.co.uk",
      "firstName": "Andrew",
      "surname": "Digby",
      "promoCode": "PTEARLY",
      "activation_code": "7ccf37dcd7ca9c01bf5bb92f6f5c9f547042abd9",
      "salt": "/ht4IHArh87Z7bjKl2lKFw==",
      "hashedPassword": "70/xUB3DzeDxCv0ESsUTGf52BgpsXsLELfjxeFFbntk3ZRNYV5ytf0gl5WCaWXfo5kueUc8CYm1CYIuJBAapbg==",
      "lastUpdated": {
        "$date": "2017-11-24T12:00:01.098Z"
      },
      "createdAt": {
        "$date": "2017-11-17T07:49:23.228Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7904023208",
            "code": "+44"
          },
          "locations": [
            {
              "city": "Hull",
              "geolocation": [
                -0.34840759999997317,
                53.76778290000001
              ],
              "postcode": "HU5 1QG",
              "_id": {
                "$oid": "5a0e94fd430ff53fda549bb8"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 25
          },
          "radius": 0
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 22
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36"
      },
      "lastLogin": 1510904963428,
      "username": "andrew_digby_SyUZHfhkM"
    },
    {
      "_id": {
        "$oid": "5a119737430ff53fda549bc1"
      },
      "providerId": "1245673403",
      "surname": "lewis",
      "firstName": "Hawken",
      "email": "hawkenlewis.fit@gmail.com",
      "username": "hawken_lewis",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-11-19T23:34:37.654Z"
      },
      "createdAt": {
        "$date": "2017-11-19T14:37:43.235Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "1245673403.8640130.48d067c9b956463597404f28dd2cda5b",
            "link": "https://www.instagram.com/hawkenlewis",
            "username": "hawkenlewis"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+1",
            "number": "7402940898"
          },
          "locations": [
            {
              "geolocation": [
                -81.93321300000002,
                40.285237
              ],
              "city": "Coshocton",
              "postcode": "43812",
              "_id": {
                "$oid": "5a12150d430ff53fda549bcc"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Level 4: Online",
            "Level 3: Online",
            "NASM personal Trainer",
            "B.A. Exercise Science",
            "Precision Nutrition Level 1 Cert."
          ],
          "disciplines": [
            "Bodybuilding",
            "Mobile Trainer",
            "Nutrition",
            "Online Coaching",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 50
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ob8XrQP8-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2012,
          "biography": "I am 24 years old and I was born and raised in Coshocton, Ohio. I am a competitive Bodybuilder, Classic Physique athlete, and a Competitive Powerlifter. \n\nI have my degree in Exercise Science. I am a level-1 Precision Nutrition Certified Coach a Certified Personal Trainer through NASM (National Academy of Sports Medicine) and NCSF ( National Council of Strength and Fitness.\n\nThis is my love and passion, I want to show people that they are capable of so much more than they realize both mentally and physically."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2OEzkYc0-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 60
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "active": true,
      "role": "trainer",
      "__v": 11,
      "agent": {
        "family": "Chrome Mobile",
        "major": "61",
        "minor": "0",
        "patch": "3163",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G930V Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.98 Mobile Safari/537.36"
      },
      "lastLogin": 1511113704260,
      "approved_date": {
        "$date": "2017-11-19T23:34:37.616Z"
      }
    },
    {
      "_id": {
        "$oid": "5a11bfcf430ff53fda549bc3"
      },
      "email": "deafpersonaltrainer@hotmail.com",
      "firstName": "Dean",
      "surname": "Chester",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "LLgsZdNvxazkOXyXqHRRpQ==",
      "hashedPassword": "OJTnLh6JOvsChGyrL3A+GxVKi//Aly03ZaDjGP7TbGxpKYsu1Tbh2Kf07gFNbfF+9n35bHE/Wr23B0h5WcO2RQ==",
      "lastUpdated": {
        "$date": "2018-05-04T06:27:11.498Z"
      },
      "createdAt": {
        "$date": "2017-11-19T17:30:55.135Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7950961406",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.385662400000001,
                53.58590390000001
              ],
              "city": "Bolton",
              "postcode": "BL2",
              "_id": {
                "$oid": "5aebfbf5a891fb09471e1296"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Scuba Diving Rescuer",
            "Level 2: First Aid / CPR",
            "Level 3: Nutrition"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Endurance",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 20
          },
          "radius": 25,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Mxmte2JR-11225752_10156136285265626_3166699954212920997_o.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10159670166090626/",
          "facebookPage": "deafpersonaltrainer16/",
          "instagram": {
            "access_token": "201925008.8640130.95232e8cda7f436bae1917ad3f650aaf",
            "link": "https://www.instagram.com/chest21100"
          },
          "twitter": {
            "accessToken": "828219701448814592-KavZCEoGCxsjJI4an4qFpHAD0TNcwp9",
            "accessTokenSecret": "Ox9FaaU3A34d2eKSjOtoiM8NANYfgzwZ4bKhzKxPF7hN9",
            "link": "https://twitter.com/Deaf_PT_25",
            "screen_name": "Deaf_PT_25",
            "user_id": "828219701448814592"
          },
          "affiliation": "REPs",
          "yearsOfExp": 2014,
          "biography": "I am Profoundly Deaf. I was overweight and became very low self-esteem. I joined in the gym with my gym partner and I discovered that fitness changed my life around! I have lost 4 stones in a year! This is the reason why, I became a Personal Trainer to help Deaf and disabled people to break their barriers down. My goal is make sure everyone is treated equal and knocking your barrier down. My focus is on individual who are want to achieve their goals. I specialist with beginner to advanced levels, all abilities, weight loss, strength, endurance, bodybuilding, improving posture and general fitness. My favorite workout is deadlift and squat. My favorite quote is ‘Never Backward, Always Forward’. Best of all I don’t just stand there and train you while you’re working out. I WILL be working out with you! If you are looking for hard work, sweaty, fun and positive, you have come to the right Personal Trainer! Please don’t be shy to talk to me!",
          "offers": "1 Session £20\n5 Sessions £50 in with 2 weeks\n10 Sessions £100 in with 3 to 4 weeks\n\nCouple 1 session £35\n\nGroup 1 session £15pp"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MHFuSAVG-10403091_10154951688750626_7690218744453277244_n.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 74,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 32,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 11_1_2 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B202 Safari/604.1"
      },
      "lastLogin": 1525414661706,
      "username": "dean_chester",
      "approved_date": {
        "$date": "2017-11-19T20:23:06.246Z"
      }
    },
    {
      "_id": {
        "$oid": "5a1443572288dd3a30f3f4ab"
      },
      "providerId": "1120587674710877",
      "surname": "Peterson",
      "firstName": "Jonny",
      "email": "jonnypeterson@live.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:03:27.215Z"
      },
      "createdAt": {
        "$date": "2017-11-21T15:16:39.316Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1120587674710877/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7724742266",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.737094999999954,
                53.456307
              ],
              "city": "St Helens",
              "postcode": "WA10 1UH",
              "_id": {
                "$oid": "5a5fd63f765eba094646f6dc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Gym based boxing NLP",
            "Circuits NLP",
            "Spinning NLP"
          ],
          "disciplines": [
            "Bodybuilding",
            "Boxing",
            "Circuit Training",
            "Group Classes",
            "Movement"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Yw3w6LsT-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "The Training Room",
          "yearsOfExp": 2016,
          "strapLine1": "Personal Training since May 2016, I love to do what I do and enjoy working with a wide range of peoeple.",
          "biography": "Hi, my name is Jonny and I am dedicated to help you reach your health & fitness goals. I am based at Xercise4Less St Helens.",
          "offers": "Contact club for details."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LQI0yo9i-IMG_0002.JPG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 74,
        "parent": {
          "$oid": "58d25a4f3fa3e80afcabcc36"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 13,
      "username": "jonny_peterson",
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36"
      },
      "lastLogin": 1511277496038,
      "approved_date": {
        "$date": "2017-11-22T08:49:19.422Z"
      }
    },
    {
      "_id": {
        "$oid": "5a154b972288dd3a30f3f4fa"
      },
      "email": "falkensteinsteve@aol.com",
      "firstName": "Steven",
      "surname": "Falkenstein",
      "promoCode": "PTEARLY",
      "activation_code": "f1c5bfcab9b6d5a232744a61db45752a08ffcb39",
      "salt": "w3AF0IjdUmF0mOxFiHjpqg==",
      "hashedPassword": "dua3csMgtxdQ08kp5P6xCoNuLLiVzbfe36T8w9k76y2Nty61EV7/dmGZhmOtHBZQfTMstAWWwcwFve/xouhhQg==",
      "lastUpdated": {
        "$date": "2017-11-29T12:00:00.774Z"
      },
      "createdAt": {
        "$date": "2017-11-22T10:04:07.883Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "6105924521",
            "code": "+44"
          },
          "locations": [
            {
              "city": "Brigantine",
              "geolocation": [
                -74.370477,
                39.41959840000001
              ],
              "postcode": "08203",
              "_id": {
                "$oid": "5a154c162288dd3a30f3f4fb"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 25
          },
          "radius": 50
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 22
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Chrome Mobile",
        "major": "55",
        "minor": "0",
        "patch": "2883",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; SM-J320V Build/MMB29M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/55.0.2883.91 Mobile Safari/537.36"
      },
      "lastLogin": 1511345048310,
      "username": "steven_falkenstein"
    },
    {
      "_id": {
        "$oid": "5a15bf252288dd3a30f3f541"
      },
      "providerId": "10155287385540735",
      "surname": "West",
      "firstName": "Chris",
      "email": "chriswest1234@live.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:01:29.883Z"
      },
      "createdAt": {
        "$date": "2017-11-22T18:17:09.295Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155287385540735/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7988871521",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.1581085999999914,
                52.95478319999999
              ],
              "city": "Nottingham",
              "postcode": "NG1 5AW",
              "_id": {
                "$oid": "5a5fd5c9765eba094646f6db"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Functional Training",
            "Group Classes",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/g8NYkWWL-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "biography": "Hi, my name is Chris and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/y208aZFo-filename.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58d2429c3fa3e80afcabc725"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 7,
      "username": "chris_west",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_1_1 like Mac OS X) AppleWebKit/602.2.14 (KHTML, like Gecko) Version/10.0 Mobile/14B100 Safari/602.1"
      },
      "lastLogin": 1511374629599,
      "approved_date": {
        "$date": "2018-01-10T15:33:42.254Z"
      }
    },
    {
      "_id": {
        "$oid": "5a1603c62288dd3a30f3f544"
      },
      "providerId": "11431298",
      "surname": "Jno-Baptiste",
      "firstName": "Jermeel",
      "email": "jermeeljb@yahoo.com",
      "username": "jermeel_jno-baptiste",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T23:00:55.085Z"
      },
      "createdAt": {
        "$date": "2017-11-22T23:09:58.569Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "11431298.8640130.74745b785fce4390a9a616c1f4f3f498",
            "link": "https://www.instagram.com/jermeeljb",
            "username": "jermeeljb"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7543016241",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.24074880000000576,
                51.4779764
              ],
              "city": "London",
              "postcode": "SW13",
              "_id": {
                "$oid": "5a5fd5a7765eba094646f6da"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Advanced Personal Trainer",
            "Level 3: Nutrition",
            "Boxing"
          ],
          "disciplines": [
            "Circuit Training",
            "Hiit",
            "Mobile Trainer",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 50
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/soW4IKkw-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10156014146983680/",
          "facebookPage": "jermeel",
          "twitter": {
            "accessToken": "40126025-2WWtUpRY2w79yCzWdZsiDPtz8RATDQTClgmJKWxhT",
            "accessTokenSecret": "p0MM8OtB5Hnt5nwmpU3sP385blQGyOptJj99ljbM1D7Fr",
            "link": "https://twitter.com/JermeelJB",
            "screen_name": "JermeelJB",
            "user_id": "40126025"
          },
          "affiliation": "The training room",
          "yearsOfExp": 2013,
          "google": "https://plus.google.com/103490017568087897611",
          "biography": "I can get you in your best shape,  whether it's to lose weight, tone up or get stronger. I have clients ranging from the ages of 17-65 all with different goals, such and getting a stronger deadlift, being able to move better or to drop a dress or jean size.",
          "offers": "Please contact me for details."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IhGDxEqr-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 77
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "active": true,
      "role": "trainer",
      "__v": 25,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_1_1 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B150 Safari/604.1"
      },
      "lastLogin": 1511392544095,
      "approved_date": {
        "$date": "2017-11-23T12:43:10.649Z"
      }
    },
    {
      "_id": {
        "$oid": "5a16aae52288dd3a30f3f54d"
      },
      "email": "shahrokh_radan@yahoo.com",
      "firstName": "Shah pt",
      "surname": "Azizi",
      "promoCode": "PTEARLY",
      "activation_code": "aef4d6e272ef48029d11dec602e8f29cc0df1110",
      "salt": "vByeEY19xbcbE03YjUybnQ==",
      "hashedPassword": "WYiq+/bBqUwCErs6jhybfvMWbrJVnjWOgkhn9hQbOIYOc0EESw+bNHpImhDJ6vEfgrmslnkaUxZO+K79rR0PgQ==",
      "lastUpdated": {
        "$date": "2017-11-30T12:00:00.731Z"
      },
      "createdAt": {
        "$date": "2017-11-23T11:03:01.412Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Version/10.0 Mobile/14F89 Safari/602.1"
      },
      "lastLogin": 1511434981680
    },
    {
      "_id": {
        "$oid": "5a175c292288dd3a30f3f553"
      },
      "providerId": "949204788564704",
      "surname": "Hastings",
      "firstName": "William",
      "email": "whastings9@yahoo.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-12T10:59:47.338Z"
      },
      "createdAt": {
        "$date": "2017-11-23T23:39:21.121Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/949204788564704/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7463473020",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.3574882,
                55.8806916
              ],
              "city": "Glasgow",
              "postcode": "G14 0DA",
              "_id": {
                "$oid": "5a589523765eba094646f552"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2j3xu9tV-background%20image.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "Hastings121Training/",
          "instagram": {
            "access_token": "280239690.8640130.ce01dececcb54ccf9d817f9d9a2d7e09",
            "link": "https://www.instagram.com/hastings9"
          },
          "twitter": {
            "screen_name": "whastings9",
            "link": "https://twitter.com/whastings9",
            "user_id": "776435077",
            "accessToken": "776435077-Q9NO830pWgZXsanJgV2C3QCoB0Dfy4B01W98c8Xi",
            "accessTokenSecret": "yVWXnsGWo2eH3sqVWC767EwkpsKltYX81Sd5eAbHb6yAx"
          },
          "affiliation": "REPs",
          "yearsOfExp": 2017,
          "biography": "I have been in training from an early age, being involved in various sports has enabled me to maintain my dedication and discipline to a high fitness standard, I have 15 years experience of training in gyms throughout the UK and abroad. \nMy passion for the fitness industry has only continued to grow and with it my knowledge on training styles and techniques, I truly believe that you can build the body that you want be it weight loss, muscle growth or general fitness with commitment and hard work. \nI full understand that getting started and staying committed to changing your lifestyle can be difficult \"we all have to start somewhere\" it’s not always just a physical challenge, but a mental one, and that’s why I’m here to help. \nI aim to help benefit my clients by creating a bespoke plan for each individual doing everything I can to give them the best results and experience when it comes to training and achieving their own personal goals. \nIf you are tired of seeing limited results, feel you need the guidance or push to get the results you  want contact me.  ​"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hZH1h3aY-Screen%20Shot%202017-11-23%20at%2023.43.31.png"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 71,
        "parent": {
          "$oid": "58d15caf3fa3e80afcab9603"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 38,
      "username": "william_hastings",
      "agent": {
        "family": "Chrome Mobile",
        "major": "38",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 5.0.2; HTC_One Build/LRX22G) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/38.0.0.0 Mobile Safari/537.36"
      },
      "lastLogin": 1511481401402,
      "approved_date": {
        "$date": "2018-01-10T12:54:16.739Z"
      }
    },
    {
      "_id": {
        "$oid": "5a18231a2288dd3a30f3f56a"
      },
      "email": "eigarza10@yahoo.com",
      "firstName": "Eric",
      "surname": "Garza",
      "promoCode": "PTEARLY",
      "activation_code": "89307893bbb2e1a3d0db2577bdc2926f80188383",
      "salt": "dUFEx42m6y50l+bTcqVQvA==",
      "hashedPassword": "SE76ul4A29gQfBkymQeprZVixJdR0jmBpoGeETQTw0e2WxYuT9xKMsOenSUfWKOAp0vpITDsYaaAC5ujlOe39A==",
      "lastUpdated": {
        "$date": "2017-12-01T14:21:58.054Z"
      },
      "createdAt": {
        "$date": "2017-11-24T13:48:10.462Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "4692852559",
            "code": "+1"
          },
          "locations": [
            {
              "city": "Dallas",
              "geolocation": [
                -96.84298310000003,
                32.8286487
              ],
              "postcode": "75235",
              "_id": {
                "$oid": "5a1823bb2288dd3a30f3f56b"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 80
          },
          "radius": 0
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 19
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_1_1 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) LinkedIn/9.10.1248.2 Version/10.0 Mobile/15B150 Safari/602.1"
      },
      "lastLogin": 1511531290783,
      "username": "eric_garza"
    },
    {
      "_id": {
        "$oid": "5a18a98f2288dd3a30f3f56f"
      },
      "providerId": "1931306413551645",
      "surname": "Premovic",
      "firstName": "Djordjije",
      "email": "premovicdjole@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2017-12-02T12:00:01.830Z"
      },
      "createdAt": {
        "$date": "2017-11-24T23:21:51.784Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1931306413551645/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/6PT364jR_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "djordjije_premovic",
      "agent": {
        "family": "Chrome Mobile",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; SM-G903F Build/MMB29K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.84 Mobile Safari/537.36"
      },
      "lastLogin": 1511565729927
    },
    {
      "_id": {
        "$oid": "5a1bfa2e2288dd3a30f3f573"
      },
      "email": "piluniu@gmail.com",
      "firstName": "Artur",
      "surname": "Pilch",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "AUi+dqLDns05t4m972OieA==",
      "hashedPassword": "GmRhzpTJBq8rsgLHcDYm+Pn+fTW3QhQFfn/L0nK+O2O1OD45VbQeYAemXHeSxSTm9tr/S6RjwM+g8uLQAjcbbA==",
      "lastUpdated": {
        "$date": "2018-01-17T22:58:49.139Z"
      },
      "createdAt": {
        "$date": "2017-11-27T11:42:38.828Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7903158579",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.2710944000000381,
                52.3711304
              ],
              "city": "Rugby",
              "postcode": "CV21 2EJ",
              "_id": {
                "$oid": "5a5fd529765eba094646f6d8"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "Level 2: Older Adults Instructor",
            "Level 4: Obesity & Diabetes",
            "Spinning instructor",
            "Level 2: Disability",
            "Gym Based Boxing"
          ],
          "disciplines": [
            "Bodybuilding",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 18
          },
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/E8qiaEt5-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPs",
          "yearsOfExp": 2011,
          "offers": "One session £30\n\n4 sessions    £90\n\n10 sessions  £180\n\n18 sessions  £270\n\n\n8 weeks transformation camp £100",
          "biography": "Former professional football player.\n\n20 years of experience in sports.\n\nFootball coach at FC Rascal Rugby. \n\nLvl 4 advanced Personal trainer.\n\nExpert of losing weight, active ageing, rehabilitation, body building, injury prevention. \n\nXercise4less Rugby oldest personal trainer.",
          "strapLine1": "Level 4: Advanced Personal Trainer"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/XshEDeR9-20170810_181323_001.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 72,
        "parent": {
          "$oid": "58d24cb13fa3e80afcabc96b"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 40,
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G930F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1512398968424,
      "username": "artur_pilch",
      "approved_date": {
        "$date": "2018-01-10T13:00:18.079Z"
      }
    },
    {
      "_id": {
        "$oid": "5a1d50567d852e353acb639b"
      },
      "email": "omf@shoomon.xyz",
      "firstName": "OMF",
      "surname": "Information",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "Lxo1EEo97jRIqTng1IFOYQ==",
      "hashedPassword": "2Mt5B3p53sYB8v1pMYuFPodlt25GMX8psi7dVCc+zoZohORoS/NKO+q+7171OwjS4YRn9OpFTlI6EhiW3LSA5g==",
      "lastUpdated": {
        "$date": "2017-11-28T13:38:17.818Z"
      },
      "createdAt": {
        "$date": "2017-11-28T12:02:30.822Z"
      },
      "settings": {
        "personal": {
          "gender": null,
          "timezone": "Europe/London",
          "phone": {
            "number": "7974801611",
            "code": "+44"
          },
          "locations": [
            {
              "city": "Horsham",
              "geolocation": [
                -0.33041019999996024,
                51.0646402
              ],
              "postcode": "RH12",
              "_id": {
                "$oid": "5a1d65bb7d852e353acb63bb"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -0.33041019999996024,
                51.0646402
              ],
              "city": "Horsham",
              "postcode": "RH12",
              "note": "Horsham Park: Meet by the Superbowl car park or look for the OMF banner on the South end of the park",
              "_id": {
                "$oid": "5a1d623a7d852e353acb63b9"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.15532519999999295,
                51.12734259999999
              ],
              "city": "Crawley",
              "postcode": "RH10 3BA",
              "note": "Grattons Park: Meet by the Tavern On The Green pub. Look for the OMF banner on Grattons Drive.",
              "_id": {
                "$oid": "5a1d623a7d852e353acb63b8"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.10656519999997727,
                50.9989052
              ],
              "city": "Haywards Heath",
              "postcode": "RH16",
              "note": "Victoria Park: Look for the banner or van for the meeting point just off the B2272 South Road.",
              "_id": {
                "$oid": "5a1d623a7d852e353acb63b7"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.02274149999993824,
                51.10526489999999
              ],
              "city": "Saint Hill Green",
              "postcode": "RH19 4NG",
              "note": "Deers Leap Park: Look for our banners on the main road and meet in the car park - Free Parking.",
              "_id": {
                "$oid": "5a1d623a7d852e353acb63b6"
              },
              "country": "United Kingdom",
              "primary": false
            },
            {
              "geolocation": [
                -0.13617950000002566,
                50.95909169999999
              ],
              "city": "Burgess Hill",
              "postcode": "RH15 8HG",
              "note": "St Johns Park: Meet in the Tennis Court Car Park off Park Road. Look for the OMF banner or van.",
              "_id": {
                "$oid": "5a1d623a7d852e353acb63b5"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "qualifications": [],
          "disciplines": [
            "All Abilities",
            "Fat Loss",
            "Interval Training",
            "Military Style",
            "Personal Training"
          ],
          "price": null,
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/A6iaLazg-omfbg1.jpg"
          },
          "companyName": "Outdoor Military Fitness (OMF)",
          "companyWebsite": "https://www.outdoormilitaryfitness.co.uk/",
          "profileTitle": "Boot Camp",
          "facebook": null,
          "strapLine1": "Life Begins Outside Your Comfort Zone So Come And Give Us A Try - The First Class Is Free!",
          "biography": "Outdoor Military Fitness (OMF) is an all-weather outdoor bootcamp:\n\nOMF is everything it claims to be, run by currently serving or ex-forces instructors with a fitness background. The classes are tailored to suit all fitness levels using the natural environment as our outdoor gym. You will always feel like part of the team, whilst the instructors push you to the limits of your personal ability.  There is no chance to get bored with repetitive workouts as no class is ever the same. Each Session will include a varied mix of circuit training, relays, partner exercises and running.  You will to achieve all over body fitness.\n\nMinimal equipment with Maximum effort:\n\nThis is an effective and challenging workout where you will be able to achieve your own personal goals. Be it weight loss, a higher level of fitness, or a healthy body and mind. In addition to this, you will meet new people, have lots of fun, laugh until you have ‘abs like slabs’ and feel ‘totes amazeballs!’\n\nWave “goodbye” to the boring gym!\n\nIt’s a well known fact that running outside is more effective than running on a treadmill. You will also work much harder in a group. Our instructors will ensure you push yourself and keep you engaged for the duration of the class. No waiting for equipment, or queuing at the water fountain just pure uninterrupted phys!\n\nYOUR FIRST CLASS IS FREE! SIGN UP NOW",
          "offers": "Please visit our website for membership details, offers and timetables",
          "testimonials": "“My only regret about OMF is not joining up sooner, they make getting fit so much fun. So what are you waiting for? Get into shape, have fun, meet like minded people and make some amazing new friends”\n\nMaria Gualtieri\n\n“So following a much needed holiday I relaxed my eating habits and pretty much ate everything in sight! It wasnt a real shock to find out that I put on over half a stone in the week! When I heard some of the girls from work were attending OMF bootcamp. I thought I would give it a go myself and jump straight in with a session 6 hours after landing. Im not gonna lie, its hard as hell and in only a week and a half I have already lost half a stone of the holiday weight that was put on! Obviously I have cut out the junk too but couldn’t be happier and am finding myself looking forward to the next grueling workout 🙂 I did think there would be more abuse coming from Liam but he seems quite tame at the moment, in all seriousness though he is a great instructor and will definitely keep you motivated for the whole session even if you say you can’t go on! Im definitely gong to carry it on and hopefully loose another stone or two :-)”\n\nAlex Watson – Crawley Borough Council"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5aODCH5h-omfhorsham.jpg"
        },
        "inherit_from_parent": false,
        "type": "serviceProvider",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 59
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 53,
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36"
      },
      "lastLogin": 1511870551196,
      "username": "outdoor_military_fitness_(omf)",
      "approved_date": {
        "$date": "2017-11-28T13:38:17.704Z"
      }
    },
    {
      "_id": {
        "$oid": "5a1d70a07d852e353acb63bf"
      },
      "email": "warwickdonnelly.pt@mail.com",
      "firstName": "Warwick",
      "surname": "Donnelly",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "jOYG9rGDvz16TFTRyblGIg==",
      "hashedPassword": "kkWofUDYm/TI63i4pfxAm3QIc5++IBJC/Dbka2uNK8GwfZsQnYV422ZKFkmxll5x30/hK0tn9aSW0RmIAMvE9Q==",
      "lastUpdated": {
        "$date": "2018-01-17T22:56:26.062Z"
      },
      "createdAt": {
        "$date": "2017-11-28T14:20:16.630Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7534014468",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.3501739499999994,
                52.34782804999998
              ],
              "city": "Stretton-on-Dunsmore",
              "postcode": "CV23 9JJ",
              "_id": {
                "$oid": "5a5fd49a765eba094646f6d7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "level 3 sports conditioning",
            "circuit training",
            "outdoor circuit instruction",
            "indoor cycling instructor"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Functional Training",
            "Nutrition",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Mo185nzk-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "",
          "yearsOfExp": 2017,
          "strapLine1": "SET A GOAL - REACH THAT GOAL - SET A NEW GOAL - LETS DO IT !!!",
          "biography": "2 years ago I found myself overweight, uninspired in my current job, lacking confidence and my life headed in a direction I didn't want  it to.\n\nAt this moment I decided to make some changes I starting hitting the gym sorting my nutrition and educating myself on health and fitness.\n\n18 months later I had lost 5 stone in weight gained my confidence back and just felt all round better about myself, during this journey I found a passion for a healthy lifestyle and all things fitness.\n\nAt this point I knew a career as a personal trainer was for me so I got qualified and haven't looked back since.\n\nEvery person is different and so are the things that motivate them and the goals they want to achieve , I find it very rewarding getting to know a person and helping them to achieve their goals whatever they might be and once those goals are achieved setting new goals.\n\nThe physical and mental benefits of a healthier more active lifestyle are huge and if I can help someone in that area of their life I'm a happy man!",
          "offers": "XERCISE4LESS MEMBERSHIP REQUIRED\n(£14.99  a month peak membership+joining fee)\n\nsingle session(1hour)- £30\n\n4 per month (£22.50 per session) - £90\n\n10 per month (£18 per session) - £180\n\n18 per month (£15 per session) - £270\n\n8 week transformation camp - £100\n3 group sessions a week for 8 weeks (24 total)",
          "facebookPage": "warwickdonnelly.pt@mail.com",
          "instagram": {
            "access_token": "6519997340.8640130.bcbef9ba4a1d49a08ef2747114230dc5",
            "link": "https://www.instagram.com/warwickdonnelly.pt"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6LPU0FqI-E9D45195-9190-4BFB-8E2E-5658B822885B.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 79,
        "parent": {
          "$oid": "58d24cb13fa3e80afcabc96b"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 35,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_1 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A402 Safari/604.1"
      },
      "lastLogin": 1515686988922,
      "username": "warwick_donnelly",
      "approved_date": {
        "$date": "2018-01-10T12:57:43.197Z"
      }
    },
    {
      "_id": {
        "$oid": "5a217fa1a95f7a0d7e4bda3c"
      },
      "email": "benjy@venuescanner.com",
      "firstName": "Benjy",
      "surname": "Meyer",
      "promoCode": "PTEARLY",
      "activation_code": "ad730cce218265c07a4a18567483ce65777960f6",
      "salt": "9wVKBLryH1o3hOtarckFlg==",
      "hashedPassword": "i/Gi0ICr+lQBcQ9uYiP7v5ufcztr8/cvIx4XiJx/f3IbHEh3sF7MXnv/K1CWGg1Jv9M3b3QpzKBcmaJ/m6TU/A==",
      "lastUpdated": {
        "$date": "2017-12-09T12:00:01.180Z"
      },
      "createdAt": {
        "$date": "2017-12-01T16:13:21.810Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36"
      },
      "lastLogin": 1512144803422
    },
    {
      "_id": {
        "$oid": "5a253a32a95f7a0d7e4bda76"
      },
      "email": "gethinjohn17@gmail.com",
      "firstName": "Gethin",
      "surname": "Jonn",
      "promoCode": "PTEARLY",
      "activation_code": "5a453776f6be37ac4020d791cba71233fecb879b",
      "salt": "OzJPChhSmqvsy9a1kEuP8g==",
      "hashedPassword": "O6WpUxB5zVkO248kRdBqJth32JVTj2RQiF2QBufQzXoQ8Pt89pdRHx3HgM2A3QQF1/tSJkn9evTm/zoASq/MWw==",
      "lastUpdated": {
        "$date": "2017-12-12T12:00:00.235Z"
      },
      "createdAt": {
        "$date": "2017-12-04T12:06:10.465Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_1_2 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B202 Safari/604.1"
      },
      "lastLogin": 1512389170717
    },
    {
      "_id": {
        "$oid": "5a257341a95f7a0d7e4bda79"
      },
      "email": "chloehughen@gmail.com",
      "firstName": "Chloe",
      "surname": "Hughen",
      "promoCode": "PTEARLY",
      "activation_code": "2103b016a4857ea5b1b7a25839d6ffe546fb049d",
      "salt": "L3zdPPxUm6hZI/O42BBCtg==",
      "hashedPassword": "bS1HLILjXzYuH1+yE9v76AIdq1sWCWRdO6f2bZvHssR7P7kkyRkcyXraXpqJTYN0N/uPieKlvbz4xD1nbutv6Q==",
      "lastUpdated": {
        "$date": "2018-01-17T22:54:20.631Z"
      },
      "createdAt": {
        "$date": "2017-12-04T16:09:37.673Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7793001326",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.2596683,
                55.8654381
              ],
              "city": "Glasgow",
              "postcode": "G2",
              "_id": {
                "$oid": "5a5fd41c765eba094646f6d4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Endurance",
            "Nutrition",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "biography": "Hi, my name is Chloe and I am dedicated to help you reach your health & fitness goals.",
          "profileTitle": "Personal Trainer",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4PBlhSsz-x4lessbg4fit.JPG"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/z0trDD88-chloe%20hughen.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d15caf3fa3e80afcab9603"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 7,
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1512403778000,
      "username": "chloe_hughen",
      "approved_date": {
        "$date": "2018-01-10T15:57:07.107Z"
      }
    },
    {
      "_id": {
        "$oid": "5a257437a95f7a0d7e4bda7c"
      },
      "email": "davidgilmorept@hotmail.com",
      "firstName": "David",
      "surname": "Gilmore",
      "promoCode": "PTEARLY",
      "activation_code": "1ccde2cebe446fe780b3c7a5804f31c9de717225",
      "salt": "B99YeQljSC+3/IfPo+bFtg==",
      "hashedPassword": "I9RXY3spwgQLlU7xLV5vuOWptD2u+JsIIIpMOAGi3ywI+jKuflxNL4taprteDkcJZwmwR9TdcE37oIuQnY60Hg==",
      "lastUpdated": {
        "$date": "2018-01-22T10:20:19.589Z"
      },
      "createdAt": {
        "$date": "2017-12-04T16:13:43.599Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "1324464000",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -3.7839131000000634,
                56.00187750000001
              ],
              "city": "Falkirk",
              "postcode": "FK1",
              "_id": {
                "$oid": "5a65bae35f0b475e1c9b1d0e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Group Classes",
            "Hiit",
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 18.5
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EZSbzzvS-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2013,
          "strapLine1": "4FIIT Personal Training                       PT without the price tag",
          "biography": "Hi, my name is David and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4JQTJi4a-david%20pt.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 64,
        "parent": {
          "$oid": "58d146053fa3e80afcab913d"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 20,
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36"
      },
      "lastLogin": 1512404023824,
      "username": "david_gilmore",
      "approved_date": {
        "$date": "2018-01-10T15:28:38.407Z"
      }
    },
    {
      "_id": {
        "$oid": "5a257dfca95f7a0d7e4bda85"
      },
      "email": "naomichiswell7@icloud.com",
      "firstName": "Naomi",
      "surname": "Chiswell",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "8RuQMZvOdSgJ48wryEeUPQ==",
      "hashedPassword": "CbtF/fWlMUoq4YeJu9JEdSPsgH8RQkrqBgWiiMFa7mW2R4GJ3Z/Uca4fO4bCkYBI2hAIAQ0YjolYbKmo07RUyg==",
      "lastUpdated": {
        "$date": "2018-01-17T22:49:19.696Z"
      },
      "createdAt": {
        "$date": "2017-12-04T16:55:24.722Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7990072297",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.4020078308047694,
                54.91701576465039
              ],
              "city": "Sunderland",
              "postcode": "SR5 2DA",
              "_id": {
                "$oid": "5a5fd2ef765eba094646f6d2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "BA Hons Dance Degree"
          ],
          "disciplines": [
            "Core Fitness",
            "Flexibility",
            "Group Classes",
            "Toning"
          ],
          "price": {
            "min": 18
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tzEUjNGn-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10215188313330813/",
          "facebookPage": "naomichiswell7@icloud.com",
          "instagram": null,
          "yearsOfExp": 2014,
          "offers": "£30 -   1 PT session\n£180 - 10 PT sessions\n£270 - 18 PT sessions \nCouples training available for all options\n\n\n£100 - Transformation camp \n3 group PT sessions p/w\nWeekly weigh ins\nOne free pt session\nRecipe e-book\nOnline support \nWelcome seminar",
          "biography": "Health and fitness has been a large part of my life from a young age, through training in various dance styles I have always been active and involved in physical activity. When I was 18 I moved from Hereford up to Sunderland to complete a BA Hons Dance degree. Although I thoroughly enjoyed studying dance I found myself very interested in the fitness and training side of things, which then led me to complete my level 2 and 3 fitness and personal trainer qualification upon graduating. While teaching classes and training individuals I decided this was something I would like to progress further in and enrolled on a PGCE PCET and qualified as a teacher. Through teaching in both gyms and educational settings I feel I have the effective planning and delivery to help motivate and achieve personal goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xmNm6bqc-93E0664E-7A2F-4AC3-BFF9-F06E5D4C0CBE.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 67,
        "parent": {
          "$oid": "58d264423fa3e80afcabce7e"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 34,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_3 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A432 Safari/604.1"
      },
      "lastLogin": 1512406525034,
      "username": "naomi_chiswell",
      "approved_date": {
        "$date": "2017-12-04T21:03:40.938Z"
      }
    },
    {
      "_id": {
        "$oid": "5a259475a95f7a0d7e4bda92"
      },
      "email": "laurajwhite@sky.com",
      "firstName": "Laura",
      "surname": "White",
      "promoCode": "PTEARLY",
      "activation_code": "8f467c73d08d6af5750f4c52f76e146d0440c970",
      "salt": "vzIlzHKr2IWHpyWtss64tQ==",
      "hashedPassword": "1h9rT4+N3w6bfFCSuyTrmEglVXsYeCtp44wcXtXWkyhWrr3YKiZPDAbJxiOkvvBlGolmHGeazplzrGrDHWu03Q==",
      "lastUpdated": {
        "$date": "2018-01-17T22:48:33.891Z"
      },
      "createdAt": {
        "$date": "2017-12-04T18:31:17.189Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "1158225560",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.1581085999999914,
                52.95478319999999
              ],
              "city": "Nottingham",
              "postcode": "NG1 5AW",
              "_id": {
                "$oid": "5a5fd2c1765eba094646f6d1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Core Fitness",
            "Functional Training",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/K51IJGI1-x4lessbg4fit.JPG"
          },
          "companyName": "xercise4less",
          "companyWebsite": "https://www.xercise4less.co.uk",
          "profileTitle": "Personal Trainer",
          "yearsOfExp": null,
          "biography": "Hi, my name is Laura and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0Hr6O1J2-X4L_twitter_full.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d2429c3fa3e80afcabc725"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 15,
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36"
      },
      "lastLogin": 1512412277442,
      "username": "laura_white",
      "approved_date": {
        "$date": "2018-01-10T15:24:36.385Z"
      }
    },
    {
      "_id": {
        "$oid": "5a26cb75a95f7a0d7e4bda99"
      },
      "email": "l.v6@outlook.com",
      "firstName": "Lorenzo",
      "surname": "Vaughan",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "L+BsBqjg1mvP/TnqJVTaBw==",
      "hashedPassword": "rz+Im4I0DuwDnPIxWE+438mVYEUXs4oZrcsXCvjWkboGyaU7n3WJ+9rB/TL/ONtshHWfwLMz0eqOURayL8gnRA==",
      "lastUpdated": {
        "$date": "2018-01-16T17:44:41.516Z"
      },
      "createdAt": {
        "$date": "2017-12-05T16:38:13.540Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7792039327",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.0878060000000005,
                51.76778699999999
              ],
              "city": "Harlow",
              "postcode": "CM19 4BX",
              "_id": {
                "$oid": "5a5e39fb765eba094646f5d0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Kettle bell instructor"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Core Fitness",
            "Mobile Trainer",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KPMYxO5e-5842D170-DA46-477A-BED9-14255BC805C7.png"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1486059068114928/",
          "instagram": {
            "access_token": "5961959915.8640130.454d5a6a2bf74e3cb9bf81e62cffd14b",
            "link": "https://www.instagram.com/lorenzo.v_fitness"
          },
          "yearsOfExp": 2017,
          "strapLine1": "Transforming lives one body at a time",
          "biography": "I’m a mobile personal trainer with a great interest in fitness and those looking to change and embrace change. I’m an Ex Army instructor with a wealth of knowledge and experience to help you to achieve your goals and make your dreams a reality!",
          "offers": "£30 1-2-1 PT",
          "affiliation": "Active IQ"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Wx34XjS5-2B229F3F-C325-418E-9C49-CFF1CA94805A.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 76,
        "parent": {
          "$oid": "58d162603fa3e80afcab9747"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 43,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0_2 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A421 Safari/604.1"
      },
      "lastLogin": 1516124535894,
      "username": "lorenzo_vaughan",
      "approved_date": {
        "$date": "2017-12-06T08:41:27.446Z"
      },
      "password_reset_code": "X"
    },
    {
      "_id": {
        "$oid": "5a26cbc2a95f7a0d7e4bda9b"
      },
      "email": "amypackman1@gmail.com",
      "firstName": "Amy",
      "surname": "Packman",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "8HyXuRY3LDSRbFpMdYcNEA==",
      "hashedPassword": "E3TBFUn7haSiE+ye9qy+X9b5fd4sjUKtE57bY/dQPg413GlZ9aL/b3n7UTEWQLQBXNiiLaPpGGMZAOFHBULBVg==",
      "lastUpdated": {
        "$date": "2018-01-12T11:01:06.610Z"
      },
      "createdAt": {
        "$date": "2017-12-05T16:39:30.277Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7399551112",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.0878060000000005,
                51.76778699999999
              ],
              "city": "Harlow",
              "postcode": "CM19 4BX",
              "_id": {
                "$oid": "5a589572765eba094646f553"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Flexibility",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xnFpJ2Sc-077A9660-3B68-4702-A1F8-CB35BF88F8F9.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "",
          "yearsOfExp": 2017,
          "biography": "I’m a qualified level 2 fitness instructor and level 3 personal trainer as well as level 2 In coaching. Im here to help all types of people on different fitness levels reach their goals. Also motivate them to live a healthy lifestyle. What ever your goals are I’m here to make it happen.",
          "strapLine1": "Come and train insane or remain the same!!",
          "facebookPage": "amypackman1@gmail.com",
          "instagram": null
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BIIIB6Ud-4C015FE2-997B-4054-98A9-6C64BD6F0CCD.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 69,
        "parent": {
          "$oid": "58d162603fa3e80afcab9747"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 44,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_1_1 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B150 Safari/604.1"
      },
      "lastLogin": 1512512781419,
      "username": "amy_packman",
      "approved_date": {
        "$date": "2017-12-06T08:42:00.885Z"
      }
    },
    {
      "_id": {
        "$oid": "5a26cc9ca95f7a0d7e4bda9f"
      },
      "email": "c.c.delieu171@gmail.com",
      "firstName": "Connor",
      "surname": "Delieu",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "bmLLv8Pz8p04NZDTwus3Ig==",
      "hashedPassword": "5IAm+F6w3ug3B1U9Akff1aNXzinq576a87XIz5kBoup2hsxn/IurXSvBEqzJ+Mw+NxrMxV2W2/kjUW4L8opsog==",
      "lastUpdated": {
        "$date": "2018-01-17T22:46:41.981Z"
      },
      "createdAt": {
        "$date": "2017-12-05T16:43:08.594Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7591638913",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.0878060000000005,
                51.76778699999999
              ],
              "city": "Harlow",
              "postcode": "CM19 4BX",
              "_id": {
                "$oid": "5a5fd251765eba094646f6d0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Strength and conditioning",
            "bodypump",
            "indoor cyling"
          ],
          "disciplines": [
            "Bodybuilding",
            "Core Fitness",
            "Endurance",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ZfusNXkd-IMG-20171205-WA0005.jpg"
          },
          "profileTitle": "Personal Trainer",
          "google": "https://plus.google.com/116335278943782735800",
          "affiliation": "REPs",
          "yearsOfExp": 2017,
          "strapLine1": "Here to do whatever it takes to achive your gaol whatever it may be!",
          "offers": "1 session - £30\n4 sessions - £90\n10 sessions - £180 \n18 sessions -£275",
          "biography": "I have always been into sports and wanted to work within the fitness industry. I love to help people achieve their goals whatever they maybe. \nI speacialize in strenght and conditioning after getting into weights when I could no longer play rugby"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BmmH7iNt-IMG-20171205-WA0004~2.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 74,
        "parent": {
          "$oid": "58d162603fa3e80afcab9747"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 16,
      "agent": {
        "family": "Chrome Mobile",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.84 Mobile Safari/537.36"
      },
      "lastLogin": 1512492188871,
      "username": "connor_delieu_rJL2prNbM",
      "approved_date": {
        "$date": "2017-12-06T08:42:30.923Z"
      }
    },
    {
      "_id": {
        "$oid": "5a27f674a95f7a0d7e4bdab5"
      },
      "providerId": "160058711412883",
      "surname": "Carlow PT",
      "firstName": "John",
      "email": "john@rockhardbodies.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T22:45:29.987Z"
      },
      "createdAt": {
        "$date": "2017-12-06T13:53:56.072Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/160058711412883/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7908201104",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.5290967999999339,
                51.26975460000001
              ],
              "city": "Maidstone",
              "postcode": "ME15 7SP",
              "_id": {
                "$oid": "5a5fd209765eba094646f6cf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Karate Instructor"
          ],
          "disciplines": [
            "Flexibility",
            "Martial Arts",
            "Mobile Trainer",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 35
          },
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/075H9Tco-Car%20Cover.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "rockhardbodiesuk",
          "twitter": {
            "accessToken": "859435655755177984-lRYTkv1eeYcHHEzJqDuZNTufnLwfpOz",
            "accessTokenSecret": "RFQuj6ZxLqtt4EFL3SdOzHYAxhMC5ZKDcPqWiRMDEVcs1",
            "link": "https://twitter.com/RockHardBodies",
            "screen_name": "RockHardBodies",
            "user_id": "859435655755177984"
          },
          "affiliation": "REPS",
          "yearsOfExp": 2016,
          "biography": "A LITTLE BIT ABOUT ME\nMy name is John Carlow and I achieved my level 3 diploma in fitness instruction and personal training from Premier Personal Training in 2016. I am also a black belt in karate and teach several classes.\n \nIt is my ambition to help as many people as possible achieve total fitness and health using a mixture of training and nutrition so that they can lead longer, happier and more confident lives.\n \nMy martial arts background means that I have an interest and focus on full body flexibility and functional fitness i.e. strength and coordination for improved effectiveness in real life activities.\n \nI also love to play and listen to Rock, Heavy Metal and alternative music and since they don't play much Metal over gym PAs and in fitness classes I wanted to create a business where the client's tastes are taken into account as well as their fitness needs.\n \nBesides offering Heavy Metal, Rock and Gothic playlists to exercise to, I would also like to make people feel comfortable whatever their background. If that means you want to train by candlelight at midnight or barefoot in the park then so be it.",
          "offers": "I offer single session rates as well as package discounts and a subscription service saving you money. Please give me a call to discuss your needs and book a consultation.\n\nPaypal and credit card payments accepted.",
          "strapLine1": "Pumping Heavy Metal \\m/ \\m/"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IOm2GX7I-filename.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 81
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 17,
      "username": "john_carlow_pt",
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36"
      },
      "lastLogin": 1512568436845,
      "approved_date": {
        "$date": "2017-12-06T15:46:57.777Z"
      }
    },
    {
      "_id": {
        "$oid": "5a2a48dca95f7a0d7e4bdabe"
      },
      "email": "seg.mason@icloud.com",
      "firstName": "Seg",
      "surname": "Mason",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "tuQVKivakCJ13NHym4kGCQ==",
      "hashedPassword": "2uR/Cg7YHZx/exTem3p5NcfNHwwtk+s76n1s5A9fbozPbQ4gE1c5mat6ub+3veIp5d1G/W6wdK1cQc/PaXzB+A==",
      "lastUpdated": {
        "$date": "2018-01-17T22:44:55.198Z"
      },
      "createdAt": {
        "$date": "2017-12-08T08:10:04.383Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "447894876183",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.7185512999999446,
                51.57678279999999
              ],
              "city": "Rochford",
              "postcode": "SS4 1NE",
              "_id": {
                "$oid": "5a5fd1e7765eba094646f6ce"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Spin Instructor"
          ],
          "disciplines": [
            "Core Fitness",
            "Nutrition",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 2,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DfPHZFi7-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "seg.mason",
          "affiliation": "Active IQ",
          "yearsOfExp": 2015,
          "biography": "My style of training is fun but you will work super hard you won’t even know where the time has gone. I can help you with losing weight, toning,building,core fitness. You won’t be disappointed with my training. So what are you waiting for come and have a session with me. \n\nKind Regards \n\nSeg Mason PT",
          "offers": "Single Session - £30.00\n4 Sessions Per Month - £90.00\n10 Sessions Per Month - £180.00\n18 Sessions Per Month - £270.00"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dU1Wk1yj-image.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 67,
        "parent": {
          "$oid": "58d2580c3fa3e80afcabcbf3"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 34,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515014973025,
      "username": "seg_mason",
      "approved_date": {
        "$date": "2017-12-11T13:44:15.838Z"
      }
    },
    {
      "_id": {
        "$oid": "5a2a4d9ca95f7a0d7e4bdac7"
      },
      "providerId": "425661260",
      "surname": "Winch",
      "firstName": "Ben",
      "email": "ben.winch@yahoo.co.uk",
      "username": "ben_winch",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T22:44:13.135Z"
      },
      "createdAt": {
        "$date": "2017-12-08T08:30:20.230Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "425661260.8640130.69692eaf7cff4f02adb67f44b9a2aa0b",
            "link": "https://www.instagram.com/benwinch97",
            "username": "benwinch97"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7455711137",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.7605107080441161,
                52.02593661387529
              ],
              "city": "Milton Keynes",
              "postcode": "MK6",
              "_id": {
                "$oid": "5a5fd1bd765eba094646f6cd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Circuit Training",
            "Group Classes",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SCze6nTL-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "Reps",
          "yearsOfExp": 2017,
          "offers": "Single 1 hour session - £30\n\n4 sessions per month - £90 \n\n10 session per month - £180 \n\n18 sessions per month -£270",
          "biography": "I’m a Personal trainer based in Milton Keynes. \n\nYoung, friendly and energetic making your fitness goals mine and ensuring you get the results you desire."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eibtAfBE-filename.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": {
          "$oid": "58d234333fa3e80afcabc41b"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "active": true,
      "role": "trainer",
      "__v": 17,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_1_2 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B202 Safari/604.1"
      },
      "lastLogin": 1512775256895,
      "approved_date": {
        "$date": "2017-12-11T14:22:48.156Z"
      }
    },
    {
      "_id": {
        "$oid": "5a2e4ec4a95f7a0d7e4bdae1"
      },
      "email": "helenhughes69@hotmail.co.uk",
      "firstName": "Helen",
      "surname": "Hughes",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "V9TcoCnZRUD7gf+n12vHWg==",
      "hashedPassword": "6DReX7hZV2xMHupwiBwxY6qYXnOxa+o8ltZEBf0cg4zNtyXL7b4yMKRD1JAp17pqKrqiEstvX+HKMajA9yuJ+A==",
      "lastUpdated": {
        "$date": "2018-01-17T22:41:51.970Z"
      },
      "createdAt": {
        "$date": "2017-12-11T09:24:20.286Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7905066307",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.9915726000000404,
                53.4083714
              ],
              "city": "Liverpool",
              "postcode": "L2 2DP",
              "_id": {
                "$oid": "5a5fd12f765eba094646f6cc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "ZUMBA (toning.step.aqua.gold.atomics)",
            "Indoor Cycling",
            "Kettlebells",
            "Clubbercise",
            "BOKWA"
          ],
          "disciplines": [
            "Bootcamp",
            "Group Classes",
            "Hiit",
            "Sports Massage",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xmJ0fXyW-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "biography": "Hi, my name is Helen and I am dedicated to help you reach your health & fitness goals.",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1750118618351925/",
          "offers": "Transformation camps.\nSee in club price.\nPT 1-2-1 sessions \n1= £30 per month\n4 = £90 per month\n10 = £180 per month"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lVWxjFb5-IMG_20160812_161841.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 59,
        "parent": {
          "$oid": "58d170663fa3e80afcab9a51"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 21,
      "agent": {
        "family": "Chrome Mobile",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; SM-J510FN Build/MMB29M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.83 Mobile Safari/537.36"
      },
      "lastLogin": 1515661882395,
      "username": "helen_hughes",
      "approved_date": {
        "$date": "2018-01-10T15:20:13.504Z"
      }
    },
    {
      "_id": {
        "$oid": "5a2fdc37a95f7a0d7e4bdae7"
      },
      "providerId": "10156204096146015",
      "surname": "Wheatley",
      "firstName": "Mall",
      "email": "malfit@hotmail.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-24T08:26:04.706Z"
      },
      "createdAt": {
        "$date": "2017-12-12T13:40:07.707Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10156204096146015/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7805055290",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.5794327999999496,
                53.8046254
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "5a6842b5f913ab12c671c0bd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Advanced Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 3: Older Adults Instructor",
            "Boxercisekick..metafit..metapower..core conditioning ..spinning...over 50s...circuits...legs bums tums and TORNADO TOTAL TRAINING"
          ],
          "disciplines": [
            "4 Kids",
            "Core Fitness",
            "Disability Training",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 15
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wPxREPjh-x4lessbg4fit.JPG"
          },
          "companyName": "Xercie4less",
          "companyWebsite": "https://www.xercise4less.co.uk",
          "profileTitle": "Personal Trainer",
          "affiliation": "Reps",
          "biography": "WITH MY PERSONAL TRAINING U CAN  IMPROVE BODY SHAPE\nINCREASE STRENGTH, ENDURANCE, CARDIOVASCULAR FITNESS, SPEED AGILITY QUICKNESS POWER AND FLEXIBILITY, LOSE WEIGHT AND GET AN ATHLETIC PHYSIQUE, NO MATTER WHAT YOUR LEVEL OF FITNESS AT THE START. WHATEVER YOUR GOALS I WILL GET YOU THERE FAST!",
          "offers": "Xercise4less membership required to get £18 per session",
          "yearsOfExp": 1989
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/lDQmAgO4-IMG-20180116-WA0004.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": {
          "$oid": "58d25f143fa3e80afcabcd7a"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 30,
      "username": "mall_wheatley",
      "agent": {
        "family": "Chrome Mobile",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G955F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.111 Mobile Safari/537.36"
      },
      "lastLogin": 1516781942426,
      "approved_date": {
        "$date": "2018-01-10T15:15:04.358Z"
      }
    },
    {
      "_id": {
        "$oid": "5a31120ba95f7a0d7e4bdaee"
      },
      "email": "j.harrison@2017.ljmu.ac.uk",
      "firstName": "Jamie",
      "surname": "Harrison",
      "promoCode": "PTEARLY",
      "activation_code": "517b8ec01a4399197182561c7215cea268ed8e09",
      "salt": "sioY9gbM1zVlRkhgEtyf7A==",
      "hashedPassword": "KCROUTNnT6+bEM3uf/ntgX6XSSW0VGzRZBWqKdyWs7b3iVdwK86fK6Qe2FF5OJse2l9urnNiggjqrrVAyI0ZXg==",
      "lastUpdated": {
        "$date": "2018-01-17T22:36:15.469Z"
      },
      "createdAt": {
        "$date": "2017-12-13T11:42:03.141Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7921338508",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.7517606000000114,
                53.45124449999999
              ],
              "city": "St Helens",
              "postcode": "WA10 2PS",
              "_id": {
                "$oid": "5a5fcfdf765eba094646f6c9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bodybuilding",
            "Body Sculpting",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 22.5
          },
          "radius": 10,
          "biography": "Hi, my name is Jamie and I am dedicated to help you reach your health & fitness goals.",
          "profileTitle": "Personal Trainer",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YZ06gl5A-x4lessbg4fit.JPG"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6hJjiakO-jamie%20harrison.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d25a4f3fa3e80afcabcc36"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 6,
      "agent": {
        "family": "Chrome",
        "major": "62",
        "minor": "0",
        "patch": "3202",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36"
      },
      "lastLogin": 1513165323349,
      "username": "jamie_harrison",
      "approved_date": {
        "$date": "2018-01-10T15:12:45.649Z"
      }
    },
    {
      "_id": {
        "$oid": "5a3936baa95f7a0d7e4bdaff"
      },
      "email": "chrisbowe1988@gmail.com",
      "firstName": "Christopher",
      "surname": "Bowe",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "dQFgeVceHJv8d3JnEV7Ibg==",
      "hashedPassword": "/QQEJF4L3HJ8HvIksG7NPzHalesxAIMh0ELs3XbZ+bFwGgh+yHbDXY+bDpwh3pLRrF1Dj9xGzkx0GbspP6nSuw==",
      "lastUpdated": {
        "$date": "2018-01-17T22:35:29.308Z"
      },
      "createdAt": {
        "$date": "2017-12-19T15:56:42.238Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7539396688",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.7522529,
                53.4511915
              ],
              "city": "St Helens",
              "postcode": "WA10",
              "_id": {
                "$oid": "5a5fcfb1765eba094646f6c8"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "FdSc Exercise",
            "Health & Fitness level 4"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Hiit",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/V2hLT5rx-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2016,
          "biography": "Hey Guys, i'm Chris personal trainer at Xercise4less St Helens, my passion for fitness is like no other. I will always be on my toes to improve upon my skills and strive to offer the best possible PT experience to all my clients so they achieve all the primary goals. I'm currently studying for my degree in exercise, health & fitness of which i am sitting my second year and to go onto study for my Bachelors in 2018."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tMWfbEPG-4FIIT.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 57,
        "parent": {
          "$oid": "58d25a4f3fa3e80afcabcc36"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 12,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1513699002492,
      "username": "christopher_bowe",
      "approved_date": {
        "$date": "2018-01-09T20:53:31.482Z"
      }
    },
    {
      "_id": {
        "$oid": "5a3a83e3a95f7a0d7e4bdb07"
      },
      "email": "khalifahumayra@gmail.com",
      "firstName": "Humayra",
      "surname": "Khalifa",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "YibO8outSb7PPluFKgxkQw==",
      "hashedPassword": "+6F5nOubnHAH9h5Y5gSpkzHgX0PBYrC6SN3hy6/vZPMeui9/yApaMpks+Xu5oDr5BJU7G7x0b9B40T+/eVsLYg==",
      "lastUpdated": {
        "$date": "2018-01-17T22:34:47.280Z"
      },
      "createdAt": {
        "$date": "2017-12-20T15:38:11.246Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7492113064",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.7699437999999998,
                53.795088
              ],
              "city": "Bradford",
              "postcode": "BD7 1JD",
              "_id": {
                "$oid": "5a5fcf87765eba094646f6c7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Gym"
          ],
          "price": {
            "min": 30
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TakhWFUX-x4lessbg4fit.JPG"
          },
          "profileTitle": "Gym instructor",
          "affiliation": "ICON TRAINING",
          "biography": "Hi, my name is Humayra and I am dedicated to help you reach your health & fitness goals.\n\nWant to shed the extra pounds and have fun at the same time? I'm your girl!!! XXX\n\n**********\n*****\n***\n*"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LIBPk88Z-big_thumbnail.png"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 56,
        "parent": {
          "$oid": "58cff40d8972aa10a5b3d76a"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 12,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1513784291464,
      "username": "humayra_khalifa",
      "approved_date": {
        "$date": "2018-01-10T13:17:39.090Z"
      }
    },
    {
      "_id": {
        "$oid": "5a4642457586bb560255ffc4"
      },
      "email": "joel.wheelhouse1990@hotmail.com",
      "firstName": "Joel",
      "surname": "Wheelhouse",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "S4NSuONHTDntygyd1tKQMA==",
      "hashedPassword": "Lg21CuMg7m2cd5movWllGsNm2SFKEYZKXyhFKk4tT+3pIfPE+8BJAjkWtu00Ka4pXGeJaY3nWAA4sQP+WviR1A==",
      "lastUpdated": {
        "$date": "2018-01-21T00:29:02.533Z"
      },
      "createdAt": {
        "$date": "2017-12-29T13:25:25.905Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7541724102",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.4424141000000001,
                53.8422412
              ],
              "city": "West Yorkshire",
              "postcode": "LS14 3DW",
              "_id": {
                "$oid": "5a63dece765eba094646f98f"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Core Fitness",
            "Group Classes",
            "Hiit"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Fm7Yy94b-IMG_3815.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2017,
          "offers": "I start sessions for £30 an hour but can reduce the cost for block bookings.\n4 sessions a month £90 = £22.50 an hour\n10 sessions a month £180 = £18 an hour\n18 sessions a month £270= £ 15 an hour\n\nI run 8 week transformation camps with three group sessions a week as well which are on offer at £100.",
          "biography": "Hello.\nI'm recently qualified but have years of gym experience under my belt. My style of instructing is to teach what I have tried out myself. If I see an exercise that works, I will teach it to clients but if it doesn't, I won't so this way, you know that what you are being taught is tried and tested by myself. \n\nI like to see your goals in the fitness game as my goal so i will work with you to help you achieve your goals. Doesn't matter what the challenge is, bring it on!. I always find a way to beat any goal and nothing hasn't stopped me yet."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uSWVhvPz-IMG_3812.JPG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 62,
        "parent": {
          "$oid": "58d16d453fa3e80afcab998f"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 29,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516389839902,
      "password_reset_code": "X",
      "username": "joel_wheelhouse",
      "approved_date": {
        "$date": "2018-01-21T00:29:02.478Z"
      }
    },
    {
      "_id": {
        "$oid": "5a4686077586bb560255ffc6"
      },
      "email": "kate_davies_pt@outlook.com",
      "firstName": "Kate",
      "surname": "Davies",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "KvYY26BQaRIdQgupicnHBA==",
      "hashedPassword": "itFGL/b/Pio7k5Ja4mohaJtFzAX/eZ3nzCclSQwjJQukBqdkHpcfxJe3xQowzfyN8WRuTE1FmQ42FA+eVzFSog==",
      "lastUpdated": {
        "$date": "2018-01-17T22:16:17.130Z"
      },
      "createdAt": {
        "$date": "2017-12-29T18:14:31.656Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7476689034",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.1840078,
                52.9575816
              ],
              "city": "Nottingham",
              "postcode": "NG7 3AG",
              "_id": {
                "$oid": "5a5fcb31765eba094646f6c5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR",
            "Spinning Instructor",
            "3-5yrs delivering physical activities instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Hiit",
            "Strength & Conditioning",
            "Toning"
          ],
          "price": {
            "min": 20
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7xPnbaY0-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPs",
          "yearsOfExp": 2017,
          "strapLine1": "If It doesnt challenge you, it wont change you!",
          "biography": "Been interested in fitness nearly all my life, started back in school i was always the child who looked forward to P.E, and enjoyed running laps around the field. i was a keen dancer which i then studied in collage for 3 years & also a form of fitness to keep fit on the outside, i then found a new hobby that i enjoyed more, which was going to the Gym, ive  Been weight training for about 3 Years now."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aFrsENj1-image1.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 69,
        "parent": {
          "$oid": "58d2429c3fa3e80afcabc725"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 17,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1514571271881,
      "username": "kate_davies",
      "approved_date": {
        "$date": "2018-01-09T20:52:37.693Z"
      }
    },
    {
      "_id": {
        "$oid": "5a4cea51765eba094646f3ff"
      },
      "email": "sarahjayne.king96@hotmail.com",
      "firstName": "Sarah Jayne",
      "surname": "King",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "On3nHn6jRJ4YsexZBeNL9Q==",
      "hashedPassword": "j5QtJXiU8FO3LL0pEZ/O3APA8TgowBO8b63zNMfzaAnu7CG+SUgnP7acNX2atrPWPCPT1c4e++BBzK09xQt40A==",
      "lastUpdated": {
        "$date": "2018-01-22T10:14:06.862Z"
      },
      "createdAt": {
        "$date": "2018-01-03T14:36:01.713Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7592783765",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.2405034999999316,
                53.7892877
              ],
              "city": "Burnley",
              "postcode": "BB11 1QJ",
              "_id": {
                "$oid": "5a65b96d3b64a45ae6c54ff0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR",
            "Level 2: Group Exercise to Music Instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Flexibility",
            "Hiit",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/KmzUzXv0-F9EA8E42-87F0-4970-96E5-3964613AA8C9.png"
          },
          "companyName": "Xercise4less",
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2015,
          "strapLine1": "“There’s No Courage Without Fear”",
          "offers": "£30 -    X1   One 2 One PT Session\n£90 -    X4   One 2 One PT Sessions\n£180 -  X10 One 2 One PT Sessions",
          "biography": "Hi, my name is Sarah and I am dedicated to help you reach your health & fitness goals:\n\nSUPPORT, MOTIVATION, DETERMINATION."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Q9889rJ0-BD5C687E-9A18-407B-8AAB-1518CC43F425.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 69,
        "parent": {
          "$oid": "58d0e9de8972aa10a5b40bf4"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 27,
      "agent": {
        "family": "Chrome Mobile iOS",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (iPad; CPU OS 11_2_1 like Mac OS X) AppleWebKit/604.1.34 (KHTML, like Gecko) CriOS/63.0.3239.73 Mobile/15C153 Safari/604.1"
      },
      "lastLogin": 1514990162131,
      "username": "sarah_jayne_king",
      "approved_date": {
        "$date": "2018-01-10T14:55:30.988Z"
      }
    },
    {
      "_id": {
        "$oid": "5a4cf1d8765eba094646f407"
      },
      "providerId": "1201123950020178",
      "surname": "Lewis",
      "firstName": "Gareth",
      "email": "pancake210212@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T22:14:54.015Z"
      },
      "createdAt": {
        "$date": "2018-01-03T15:08:08.420Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1201123950020178/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7496155237",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.229088356379139,
                53.79094895404298
              ],
              "city": "Burnley",
              "postcode": "BB10",
              "_id": {
                "$oid": "5a5fcadd765eba094646f6c3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6vqcFKNL-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "biography": "Hi, my name is Gareth and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/I3VP1Yck-filename.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58d0e9de8972aa10a5b40bf4"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 6,
      "username": "gareth_lewis",
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_1 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C153 Safari/604.1"
      },
      "lastLogin": 1514992089074,
      "approved_date": {
        "$date": "2018-01-10T14:51:00.501Z"
      }
    },
    {
      "_id": {
        "$oid": "5a4e25aa765eba094646f40d"
      },
      "providerId": "10155887635357416",
      "surname": "Walker",
      "firstName": "Derek",
      "email": "derekwalker388@hotmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T22:14:21.373Z"
      },
      "createdAt": {
        "$date": "2018-01-04T13:01:30.167Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155887635357416/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7916253888",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.5320002,
                52.718283
              ],
              "city": "Telford and Wrekin",
              "postcode": "TF5 0LW",
              "_id": {
                "$oid": "5a5fcabd765eba094646f6c1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "Level 3: Nutrition"
          ],
          "disciplines": [
            "Endurance",
            "Nutrition",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/yLMjxnJ8-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "Cimspa",
          "yearsOfExp": 2017,
          "biography": "As a personal trainer I love helping people to reach there desired goals either through training or diet. I will be there for you every step of the way helping with advise, motivation and encouragement through your exercise journey. So please don't hesitate to get in touch I will make your fitness journey fun and exciting."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kPlb7ji4-filename.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 63,
        "parent": {
          "$oid": "58d252cc3fa3e80afcabcaaf"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 12,
      "username": "derek_walker",
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G930F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1515344889339,
      "approved_date": {
        "$date": "2018-01-09T20:50:32.465Z"
      }
    },
    {
      "_id": {
        "$oid": "5a4f8ee2765eba094646f412"
      },
      "email": "adamwithy@hotmail.co.uk",
      "firstName": "Adam",
      "surname": "Witherington",
      "promoCode": "PTEARLY",
      "activation_code": "5b04ee4846fb8e074be9280cfb5dce4bdbdff2a4",
      "salt": "lVu+VlaFGU6wjq7eB26CvQ==",
      "hashedPassword": "ZJ2DgyJSg0mJ4CJs23MqniM0pqklRZ4jocjdsueAe7fXSuXyoqlknxMRsE4c+7fEEc9cfhpASjy98BtnF8rbJQ==",
      "lastUpdated": {
        "$date": "2018-01-18T15:51:22.655Z"
      },
      "createdAt": {
        "$date": "2018-01-05T14:42:42.210Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7412764352",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.6317487999999685,
                53.5403011
              ],
              "city": "Wigan",
              "postcode": "WN3 4HG",
              "_id": {
                "$oid": "5a5fca9f765eba094646f6bf"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Sport & Exercise Science BSc",
            "Strength & Conditioning Coach (Hons)",
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Circuit Training",
            "Endurance",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/J8Bdkvvr-x4lessbg4fit.JPG"
          },
          "companyName": "Xercise4Less Wigan",
          "companyWebsite": "http://www.xercise4less.co.uk",
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2018,
          "affiliation": "reps",
          "biography": "Hi, my name is Adam and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/wrXgGWi3-IMG_1440.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 58,
        "parent": {
          "$oid": "58d26cf73fa3e80afcabd04b"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 16,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515163362436,
      "username": "adam_witherington",
      "approved_date": {
        "$date": "2018-01-10T14:45:31.451Z"
      }
    },
    {
      "_id": {
        "$oid": "5a4f917e765eba094646f415"
      },
      "email": "amylouise22112@hotmail.com",
      "firstName": "Amy Hunt",
      "surname": "PT",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "q508YasflrhBGK+vJq5GkQ==",
      "hashedPassword": "YZoiYyCA1iQ5/gxdqOshuk3YDbcMwQPJ1RR3WmKY/Qhyma17RqOUrIDMPFdxyXX6JKaBUg076QHsowEfT2+FMg==",
      "lastUpdated": {
        "$date": "2018-01-23T11:35:20.159Z"
      },
      "createdAt": {
        "$date": "2018-01-05T14:53:50.082Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7807973583",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.179403999999977,
                53.00266799999999
              ],
              "city": "Stoke-on-Trent",
              "postcode": "ST4 2RP",
              "_id": {
                "$oid": "5a5fca7e765eba094646f6be"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Bootcamp",
            "Rehabilitation",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ihctLHrs-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "offers": "£30: 1 hour 1-2-1 session\n£90: 4x 1 hour 1-2-1 session\n£180: 10x 1 hour 1-2-1 session",
          "biography": "I love what I do because it can change someone's life... from better mobility, more strength, more confidence and much more. I work with a lot of different people and it makes me proud to say that they are all progressing really well. Everyone has different needs and I have the solution to yours!",
          "instagram": {
            "access_token": "5884360439.8640130.303fc0561c0d4ac992221099821bef51",
            "link": "https://www.instagram.com/amyhuntpt"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WR3PA086-IMG_0077.JPG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 60,
        "parent": {
          "$oid": "58d261953fa3e80afcabcdfc"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 31,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1516707320091,
      "username": "amy_hunt_pt",
      "approved_date": {
        "$date": "2018-01-09T20:45:23.513Z"
      }
    },
    {
      "_id": {
        "$oid": "5a4f95ea765eba094646f428"
      },
      "email": "sam.turpinn@gmail.com",
      "firstName": "sam",
      "surname": "Turpin",
      "promoCode": "PTEARLY",
      "activation_code": "782dc2435525b5e33380379c78d49e6b68fd91a2",
      "salt": "fLLMPNs3uJbj7Y+sE0ZlPw==",
      "hashedPassword": "K5Jo74HlycVeYVYvmOqYZGoG50wblneugB5kGKRFcbsruYiFp3MLYhOMXONfRB2kVdx4dvEC2I8UrOeSvbmUIQ==",
      "lastUpdated": {
        "$date": "2018-01-13T12:00:01.059Z"
      },
      "createdAt": {
        "$date": "2018-01-05T15:12:42.389Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7840493748",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.5848464999999998,
                53.8066645
              ],
              "city": "Leeds",
              "postcode": "LS4 2HD",
              "_id": {
                "$oid": "5a4f9973765eba094646f42e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Strength & Conditioning"
          ],
          "disciplines": [
            "Core Fitness"
          ],
          "price": {
            "min": 25
          },
          "radius": 100,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/SCqmFnH4-4TCIMAGE.png"
          },
          "profileTitle": "Strength & Conditioning Coach",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10154577160931576/",
          "yearsOfExp": 2017
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Q3yqHfUq-pull%20up%20challenge.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 55,
        "parent": {
          "$oid": "58cff9448972aa10a5b3d8ac"
        }
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 7,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515165162597,
      "username": "sam_turpin_SJVmUz67G"
    },
    {
      "_id": {
        "$oid": "5a4fb55e765eba094646f431"
      },
      "email": "natalieeckertfitness@gmail.com",
      "firstName": "Natalie",
      "surname": "Eckert",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "WlwTBGs5FlyEjLUBHuTYMw==",
      "hashedPassword": "bR4rNmT9rykbWcaoa/nHobU6wmVpi4M/1mfjmetRoc66Zkv6t7gi6bQjc7JrG6ecU+QimkomkVM0WHmBpuQTkw==",
      "lastUpdated": {
        "$date": "2018-01-12T11:36:14.355Z"
      },
      "createdAt": {
        "$date": "2018-01-05T17:26:54.870Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7771667241",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.179403999999977,
                53.00266799999999
              ],
              "city": "Stoke-on-Trent",
              "postcode": "ST4 2RP",
              "_id": {
                "$oid": "5a589dae765eba094646f573"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Indoor Cycling",
            "Boogie Bounce",
            "Les Mills",
            "First Aid"
          ],
          "disciplines": [
            "Bodybuilding",
            "Bootcamp",
            "Group Classes",
            "Nutrition",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "biography": "Hello I'm a qualified Personal Trainer & Nutrition Advisor with a wealth of enthusiasm for fun fitness and healthy food. From minor diet changes to full-body transformations; I have the knowledge and experience to make your journey fun and rewarding.  Let me transform your body & mind!",
          "profileTitle": "Personal Trainer",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/imSKCVPq-PSFix_20171219_183719.jpeg"
          },
          "affiliation": "REPS",
          "yearsOfExp": 2016,
          "strapLine1": "With your commitment I will make you the happiest, healthiest person you can be"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eY7Rrayj-20180103_161104.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 69,
        "parent": {
          "$oid": "58d261953fa3e80afcabcdfc"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 18,
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1515530726238,
      "username": "natalie_eckert",
      "approved_date": {
        "$date": "2018-01-09T20:44:28.042Z"
      }
    },
    {
      "_id": {
        "$oid": "5a4ffe70765eba094646f436"
      },
      "email": "annabelgummow1@gmail.com",
      "firstName": "Annabel",
      "surname": "Delaney",
      "promoCode": "PTEARLY",
      "activation_code": "b47a7e86f821ec4ec1bb2ec7222abd3d442487c2",
      "salt": "olazWjDblRcefWm7EggMwQ==",
      "hashedPassword": "O1GK6RouFLya1iPLoEyWNNR9wzwnMknePPfutoEo8Ou/VaU9dmm4dulCf8Hs11xF5fign9PRlPNRpCqVd7EJNQ==",
      "lastUpdated": {
        "$date": "2018-01-17T22:07:59.120Z"
      },
      "createdAt": {
        "$date": "2018-01-05T22:38:40.557Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7846080800",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.0878060000000005,
                51.76778699999999
              ],
              "city": "Harlow",
              "postcode": "CM19 4BX",
              "_id": {
                "$oid": "5a5fc93f765eba094646f6bd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Bootcamp",
            "Group Classes",
            "Hiit",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/4Zn327Ww-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10211630551664009/",
          "yearsOfExp": 2017,
          "biography": "Hi, my name is Annabel and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/J1M7NCAp-154DB6A7-9528-40DF-B00A-B45499D97FAD.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 58,
        "parent": {
          "$oid": "58d162603fa3e80afcab9747"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 15,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_1_2 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B202 Safari/604.1"
      },
      "lastLogin": 1515191920782,
      "username": "annabel_delaney",
      "approved_date": {
        "$date": "2018-01-10T14:42:16.217Z"
      }
    },
    {
      "_id": {
        "$oid": "5a50027a765eba094646f440"
      },
      "email": "annnabel@mail.com",
      "firstName": "Annabel",
      "surname": "Gummow",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "sRk1A7KmxPWrifkl3/anIA==",
      "hashedPassword": "GyzxLXqwsbK3y5qqFxr/iRLXm5XGxGIMwjMG/qVQY4FgFSHUAbvP6XmCNVA07c3q7mAQqwTPzcqKW7tUo+e+0Q==",
      "lastUpdated": {
        "$date": "2018-01-17T22:07:29.369Z"
      },
      "createdAt": {
        "$date": "2018-01-05T22:55:54.136Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7846080800",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.0878060000000005,
                51.76778699999999
              ],
              "city": "Harlow",
              "postcode": "CM19 4BX",
              "_id": {
                "$oid": "5a5fc921765eba094646f6bc"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Group Classes",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AFLdcYkl-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10211630551664009/",
          "affiliation": "REPS Level 2",
          "yearsOfExp": 2017,
          "biography": "Here and happy to help! :)"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gStA3sbQ-CA21D7E1-B349-47F6-951D-7C0585E9AD27.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 61,
        "parent": {
          "$oid": "58d162603fa3e80afcab9747"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 13,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_1_2 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B202 Safari/604.1"
      },
      "lastLogin": 1515192954367,
      "username": "annabel_gummow",
      "approved_date": {
        "$date": "2018-01-09T20:43:07.993Z"
      }
    },
    {
      "_id": {
        "$oid": "5a50f181765eba094646f44b"
      },
      "email": "dansawyer2014@gmail.com",
      "firstName": "Dan",
      "surname": "Sawyer",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "gN9sXL4zmkuwwUK+b+yOHQ==",
      "hashedPassword": "1xgy2sFjLdkxBwzF/cthLZc4VW1ebKpZiZx6ztNOaTtLkM+v6m1/8Etsgex7RW2Ip4IWPf7RBv5iyOY1B+8Wew==",
      "lastUpdated": {
        "$date": "2018-01-17T22:06:45.180Z"
      },
      "createdAt": {
        "$date": "2018-01-06T15:55:45.880Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7951414072",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.179403999999977,
                53.00266799999999
              ],
              "city": "Stoke-on-Trent",
              "postcode": "ST4 2RP",
              "_id": {
                "$oid": "5a5fc8f5765eba094646f6bb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bodybuilding",
            "Bootcamp",
            "Circuit Training",
            "Functional Training",
            "Nutrition"
          ],
          "price": {
            "min": 30
          },
          "radius": 50,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ki6H0553-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPs",
          "yearsOfExp": 2015,
          "biography": "Get up, Get out, Get active this is what I live by if you want know more about me holla....peace out",
          "offers": "Feel free get in touch all different packages for all types of training and fitness levels"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Gemi7guw-filename.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 61,
        "parent": {
          "$oid": "58d261953fa3e80afcabcdfc"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 22,
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; SAMSUNG SM-G900F Build/MMB29M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1515255278553,
      "username": "dan_sawyer",
      "approved_date": {
        "$date": "2018-01-09T20:42:05.453Z"
      }
    },
    {
      "_id": {
        "$oid": "5a51529c765eba094646f456"
      },
      "email": "ledassxxx21@gmail.com",
      "firstName": "MODESTAS",
      "surname": "KULBIS",
      "promoCode": "PTEARLY",
      "activation_code": "766e68b09ff3b22ed532083b877f3b5faff0488d",
      "salt": "vhy8Ebq7gorN67Oyu28aog==",
      "hashedPassword": "xoSqPG0ex9Vv4FAKuoG0IVJhmhcyowubDWbOPuXr2iuYiWycpFnWT3gX1x89N1t9s19HAxyfkOBfLVLStzL+MQ==",
      "lastUpdated": {
        "$date": "2018-01-17T22:06:14.633Z"
      },
      "createdAt": {
        "$date": "2018-01-06T22:50:04.514Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7521258360",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.6325074000000086,
                53.5450645
              ],
              "city": "Wigan",
              "postcode": "WN1 1JU",
              "_id": {
                "$oid": "5a5fc8d6765eba094646f6ba"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Core Fitness",
            "Endurance",
            "Functional Training",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 15
          },
          "radius": 1,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RcNF1uBq-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "biography": "Hi, my name is Modestas and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/F27NuUUn-IMG-20171220-WA0003.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d26cf73fa3e80afcabd04b"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 6,
      "agent": {
        "family": "Chrome Mobile",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Linux; Android 7.0; HTC One M9 Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.111 Mobile Safari/537.36"
      },
      "lastLogin": 1515279004836,
      "username": "modestas_kulbis",
      "approved_date": {
        "$date": "2018-01-10T14:40:02.607Z"
      }
    },
    {
      "_id": {
        "$oid": "5a52721b765eba094646f45a"
      },
      "email": "jvmason@sky.com",
      "firstName": "Joanne",
      "surname": "Mason",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "GEsg5yqsPLBpOrFK286CSw==",
      "hashedPassword": "EDVIQr5KJ6/VinO0SzK8Q+sLUL7NFkEklzCIykPfyP2lnO+EyTrPZ+ZKP1izy3QQ0ysxO2rm/zC8u3uXaIX+Rg==",
      "lastUpdated": {
        "$date": "2018-01-17T22:05:25.691Z"
      },
      "createdAt": {
        "$date": "2018-01-07T19:16:43.731Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7974217026",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.9435826000000134,
                51.5775701
              ],
              "city": "Newport",
              "postcode": "NP19 4QQ",
              "_id": {
                "$oid": "5a5fc8a5765eba094646f6b9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Ante & Post Natal - Aqua",
            "Level 2: Aqua Teacher",
            "Level 2: Group Exercise to Music Instructor",
            "Level 2: Gym Instructor",
            "Level 3: Advanced Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 3: Older Adults Instructor",
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 4: Cardiac Rehab Phase IV Exercise Instructor",
            "Ba Hons Leisure and Recreation Management",
            "Les Mills Body Pump",
            "Body Combat",
            "Spin",
            "advanced zumba",
            "kettlebells",
            "boxing fitness",
            "yoga"
          ],
          "disciplines": [
            "Body Sculpting",
            "Group Classes",
            "Hiit",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Kh1HsUyM-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "Fitpro",
          "biography": "I have been in the fitness industry for over 15\nyears After receiving my degree in leisure and\nrecreation management I began to get my\npassion for anything fitness orientated which included personal training and a vast knowledge of\nanything group exercise related. I am extremely\ndedicated and committed to my job and believe I can help people achieve there goals and\naspiration's. As long as they work with me I will help them achieve anything they put there mind\ntoo. I studied a wide range of health issues and how exercise benefits these conditions, which \nincludes cardiac rehabilitation, arthritis, anxiety \nand depression, osteoporosis, high blood \npressure among a few other things and how\nexercise effects these conditions and how to overcome them. I believe everyone who wants to \nwork hard can achieve any goal they set there\nsights on and I will help to guide them and put\nthem on the right path to gain total wellbeing of \nmind, body and soul. I took this career path\nbecause i knew I could make a difference to\npeople lives and I continue to learn new things\neveryday through study and life experience and\npass it on to people who wish to commit to a \ncomplete life style change or just need help in \nprogressing further in there training."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dr8sfXJR-profile%201.png"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 57,
        "parent": {
          "$oid": "58d23e083fa3e80afcabc621"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 23,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_2 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C202 Safari/604.1"
      },
      "lastLogin": 1515919750520,
      "username": "joanne_mason",
      "approved_date": {
        "$date": "2018-01-10T14:31:47.533Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5281d6765eba094646f45e"
      },
      "email": "mountainfitness@outlook.com",
      "firstName": "Steven",
      "surname": "Barter",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "+WIZVqbnOudwBhQADPq54w==",
      "hashedPassword": "1dtyFdpV3S6glKTZ60ZfC2TKU7siIf4HzQJ1UR5XKpPmyJSbjU2jTPcFbzeRjmGYDeBCy8Nw2qmVEW0Ic6+2MQ==",
      "lastUpdated": {
        "$date": "2018-01-17T22:00:02.021Z"
      },
      "createdAt": {
        "$date": "2018-01-07T20:23:50.253Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7989196315",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.9435826000000134,
                51.5775701
              ],
              "city": "Newport",
              "postcode": "NP19 4QQ",
              "_id": {
                "$oid": "5a5fc761765eba094646f6b7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Functional Training",
            "Hiit",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9AXKQmqQ-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "stevebarterpt",
          "instagram": {
            "access_token": "1491532639.8640130.14a77e9bdb2f4e6fa29a6aba0afae0db",
            "link": "https://www.instagram.com/steveb_pt"
          },
          "affiliation": "reps",
          "yearsOfExp": 2014,
          "biography": "hello and thanks for reading\nI am a big lover of fitness of overcoming challenges. \n\nMy journey into fitness started from a young age but I was considered an over weight child. One thing that I have learned in my journey is that getting guidance and support from the right people will help take you a long way in gaining knowledge that you need in order to reach your goals.\n\nIn my time coaching people, I have taught them how to lose weight, increase strength, improve muscle tone and more importantly how to be happier with themselves."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9sMhLibi-004.JPG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": {
          "$oid": "58d23e083fa3e80afcabc621"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 12,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1515356630526,
      "username": "steven_barter",
      "approved_date": {
        "$date": "2018-01-09T20:39:02.828Z"
      }
    },
    {
      "_id": {
        "$oid": "5a538fbe765eba094646f463"
      },
      "email": "cudzis.tomas@gmail.com",
      "firstName": "Tomas",
      "surname": "Cudzis",
      "promoCode": "PTEARLY",
      "activation_code": "6787c141136a376cbd5fc6e31dc0a2fa01b01dd1",
      "salt": "r4GCVlXAVd1Seq5fk4Nczg==",
      "hashedPassword": "PZPzn3PPQE3Dv/tUTW8Uq8i3bCn8TzmMiyE7ciiFcsWdxt97Vd4JkzTR3JQYd/jL4QFcT+4CMVNM925oyAuNDA==",
      "lastUpdated": {
        "$date": "2018-01-17T21:58:46.296Z"
      },
      "createdAt": {
        "$date": "2018-01-08T15:35:26.984Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7564294705",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.9968330000000378,
                52.5050172
              ],
              "city": "Smethwick",
              "postcode": "B66 1QG",
              "_id": {
                "$oid": "5a5fc716765eba094646f6b6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Disability Training",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/OWOaTCFW-x4lessbg4fit.JPG"
          },
          "companyName": "Xercise4Less",
          "companyWebsite": "https://xercise4less.virtuagym.com",
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2017,
          "biography": "I was always skinny due to my unusually small frame for a guy. For years people told me ‘You'll never be big or strong’ at the same time as ‘Eat more! Exercise!’\n\nGym changed all of that. It was the best decision of my life despite all the hardships I had to endure in the beginning and the lack of knowledge I had. My transformation inspired lot of people around me and that inspired me to become a Personal Trainer. When people ask me how I did it, it’s quite simple: I always believed.\n\nI took responsibility for the way I looked, because despite having a smaller frame, I didn’t eat enough, didn’t exercise and didn’t know what to eat and why, or how to exercise and why. I wish I had. It would have saved me years. I know how it feels when you fail and mostly it's because of unrealistic expectations."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/b9YmI6lb-TC%20profile%20pic.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 57,
        "parent": {
          "$oid": "58d255593fa3e80afcabcb31"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 22,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515427342990,
      "username": "tomas_cudzis",
      "approved_date": {
        "$date": "2018-01-10T14:21:12.628Z"
      }
    },
    {
      "_id": {
        "$oid": "5a53ac99765eba094646f46b"
      },
      "providerId": "103877835732009795492",
      "surname": "Nostran",
      "firstName": "Mattia",
      "email": "mattia.nostran@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T21:55:24.350Z"
      },
      "createdAt": {
        "$date": "2018-01-08T17:38:33.883Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/103877835732009795492",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7591275051",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.5879099999999653,
                51.454513
              ],
              "city": "Bristol",
              "postcode": "BS1 6FS",
              "_id": {
                "$oid": "5a5fc64c765eba094646f6b5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Functional Training",
            "Online Coaching",
            "Rehabilitation",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ddQPxw60-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2017,
          "strapLine1": "Why I've chosen to be a Personal Trainer?  To help people to achieve their goals and  to live healthy and happy!",
          "biography": "A graduate in Sport Science and also with Level 3 Personal Trainer with multiple experience as strength and conditioning coach in a Basketball Academy , Physical Rehabilitator for professional athletes and not and also as PE teacher.",
          "offers": "1 Session £30\n4 Sessions £90\n10 Sessions £180"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/i1ijO6f0-x4lesslogo.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 71,
        "parent": {
          "$oid": "58cffe1d8972aa10a5b3d9ae"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 13,
      "username": "mattia_nostran",
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515433114179,
      "approved_date": {
        "$date": "2018-01-10T20:58:35.000Z"
      }
    },
    {
      "_id": {
        "$oid": "5a53ea10765eba094646f479"
      },
      "email": "sophieplunkett@hotmail.com",
      "firstName": "Sophie",
      "surname": "Plunkett",
      "promoCode": "PTEARLY",
      "activation_code": "3778db0ce4375bed909616b37408b173069963cf",
      "salt": "lYjVkKj68SUjwjwJI2TRmA==",
      "hashedPassword": "ltaCH5/RQ4D/s4z9FnQORO4VNctaz4RpuQA0HbgMhXgsgcJCvUDxcC14/PMlENBvy31lV3W+Rb3IlkMXkPv2cw==",
      "lastUpdated": {
        "$date": "2018-01-22T10:36:21.922Z"
      },
      "createdAt": {
        "$date": "2018-01-08T22:00:48.287Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7722599534",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.328982099999962,
                54.5704551
              ],
              "city": "Stockton-on-Tees",
              "postcode": "TS19 0SQ",
              "_id": {
                "$oid": "5a65bea55f0b475e1c9b1d14"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2 Kettlbell Training",
            "Level 2 Spinning",
            "Level 2 Suspended Movement Training",
            "Level 2 Circuit Training"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Core Fitness",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zzdmHPOb-Sophie%20Plunkett%20-%20Stockton%20North%20Digital-01%20(3).png"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2016,
          "strapLine1": "There is no quick fix to get you where you want to be, so why not see when I'm available and we can discuss your goals",
          "biography": "Hi, my name is Sopuie and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dfvtv2aO-sophie.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 64,
        "parent": {
          "$oid": "58d25cac3fa3e80afcabccb8"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "IE",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; rv:11.0) like Gecko"
      },
      "lastLogin": 1515519729063,
      "username": "sophie_plunkett",
      "approved_date": {
        "$date": "2018-01-10T14:03:22.792Z"
      }
    },
    {
      "_id": {
        "$oid": "5a540216765eba094646f47a"
      },
      "providerId": "945841938915063",
      "surname": "Van-meir",
      "firstName": "Rikki",
      "email": "rikki9167@hotmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T21:54:20.813Z"
      },
      "createdAt": {
        "$date": "2018-01-08T23:43:18.778Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/945841938915063/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7378858994",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.5344357000000173,
                51.4796094
              ],
              "city": "Bristol",
              "postcode": "BS16 2DY",
              "_id": {
                "$oid": "5a5fc60c765eba094646f6b4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Nutrition",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Nutrition"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/eahfNhid-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "414328352.8640130.8a9db3ed70a4432a970f473683bc6344",
            "link": "https://www.instagram.com/rvanmeir"
          },
          "affiliation": "Reps",
          "yearsOfExp": 2017,
          "strapLine1": "Creative and Motivated personal trainer",
          "biography": "As mentioned, very creative and motivated personal trainer with a military background, have years of experience training and motivating a team of men that has been transferred into the PT world.\n\nI am very friendly and easy to get on with, mixed with an air of confidence.\n\nI do everything to the best of my ability and will go above and beyond to make sure I hit my required target."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/QmrBrkIA-IMG_0207.PNG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 74,
        "parent": {
          "$oid": "58cffe1d8972aa10a5b3d9ae"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 10,
      "username": "rikki_van-meir",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_2_1 like Mac OS X) AppleWebKit/602.4.6 (KHTML, like Gecko) Version/10.0 Mobile/14D27 Safari/602.1"
      },
      "lastLogin": 1515454999447,
      "approved_date": {
        "$date": "2018-01-10T13:59:32.712Z"
      }
    },
    {
      "_id": {
        "$oid": "5a548d17765eba094646f47e"
      },
      "email": "athhealthandfitness@gmail.com",
      "firstName": "Adrian",
      "surname": "Heritage",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "8feAUkQbJKJGnoTpy5o+QQ==",
      "hashedPassword": "7M7dmrOHOudwNPXsAptpim7lS5ItsQGM5rcNVHbJPRaHtm1+inVYJsCeXsIzstYMs2v7u6T35q2FBEYUVHD5Ng==",
      "lastUpdated": {
        "$date": "2018-01-25T14:47:05.259Z"
      },
      "createdAt": {
        "$date": "2018-01-09T09:36:23.691Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7794620665",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -3.0195558,
                51.6505416
              ],
              "city": "Cwmbran",
              "postcode": "NP44 3QG",
              "_id": {
                "$oid": "5a58990d765eba094646f560"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Boxercise Instructor",
            "Group Exercise Instructor",
            "TRX Suspension Trainer",
            "Indoor Cycling"
          ],
          "disciplines": [
            "Circuit Training",
            "Nutrition",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/yaNgOS6x-2016-05-15%2011.11.44.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155379990314492/",
          "facebookPage": "ATHhealthandfitness",
          "instagram": {
            "access_token": "2094640450.8640130.fe670c90439442f5b2066987b65f56cd",
            "link": "https://www.instagram.com/eyes19th"
          },
          "yearsOfExp": 2016,
          "strapLine1": "ITS NEVER TOO EARLY TOO START",
          "biography": "About 18 Months ago i turned my passion and hobby into a career that I enjoy. I’m dedicated to help you achieve your goals because nothing would make my day more than to see you succeed at what you want! I understand that life isn’t as simple as work hard and play hard, Life throws unexpected challenges situations at you and that’s where I can help work through them and achieve that desired goal. So \nwhether your GOAL be losing those unwanted pounds Ready for the BIG day, smashing those PBs ready for the next competition or even just being able to enjoy the fun of everyday life with loved ones then I’m the guy that can and wants to help you.\nWE ALL START SOMEWHERE AND TOGETHER WE CAN I PROMISE."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EeVP29M3-IMG-20170522-WA0000.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 74,
        "parent": {
          "$oid": "58d23e083fa3e80afcabc621"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 16,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 11_2_2 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C202 Safari/604.1"
      },
      "lastLogin": 1516891625189,
      "username": "adrian_heritage",
      "approved_date": {
        "$date": "2018-01-09T12:40:18.151Z"
      }
    },
    {
      "_id": {
        "$oid": "5a548ea4765eba094646f481"
      },
      "email": "abby.demetriou1999@outlook.com",
      "firstName": "Abby",
      "surname": "Demetriou",
      "promoCode": "PTEARLY",
      "activation_code": "192fbaad77318099f8e4c307b384b2aecc881b06",
      "salt": "6xotv1chiZLuTWnT3HoYKg==",
      "hashedPassword": "qD5nqhDiNWvvBI/Xx/jQHfckmgXl01+zV5lGKHRdqRvWpm9RpWfi2aKnDLRpKM3+dkuztnZz38vv8Qu9+LSt9A==",
      "lastUpdated": {
        "$date": "2018-01-17T21:53:12.116Z"
      },
      "createdAt": {
        "$date": "2018-01-09T09:43:00.790Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7403312373",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.1855369999999998,
                53.157284499999996
              ],
              "city": "Mansfield Woodhouse",
              "postcode": "NG19",
              "_id": {
                "$oid": "5a5fc5c8765eba094646f6b3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Kettle bell instructor",
            "Circuit training instructor",
            "Children's fitness instructor",
            "Boot camp instructor"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Core Fitness",
            "Group Classes",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PXbHcA1S-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2016,
          "facebookPage": "abby.demetriou1999@outlook.com",
          "offers": "Join my upcoming transformation camp today! 3 sessions a week for 8 weeks long see how your body tranforms and gains strength, endurance and life long benefits! \nOnly £100! \nDon’t miss this opportunity! \n\n\nOne to one Packages:\nOne single session £30 \nFour sessions £90 (£22.50 per session)\nTen sessions £180 (£18 per session)\nEighteen sessions £270 (£15 per session)",
          "biography": "I have been working here for a short while and have loved teaching many varieties of classes, I teach indoor cycling, LBT, abs, Xercise4less pump and many more, Before working here I had experience helping in schools with exercise for children and also putting on circuit sessions for teachers to help relax them more as they take on a lot of stress.",
          "strapLine1": "Summer bodies are made in winter"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vUOUzO4H-IMG_6117.JPG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 73,
        "parent": {
          "$oid": "596f46d9f3425e317853150d"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 27,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1515564785162,
      "username": "abby_demetriou",
      "approved_date": {
        "$date": "2018-01-10T13:54:26.781Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54c447765eba094646f492"
      },
      "providerId": "1955005154533454",
      "surname": "Argent",
      "firstName": "Connor",
      "email": "connorargent14@ntlworld.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-20T12:15:11.312Z"
      },
      "createdAt": {
        "$date": "2018-01-09T13:31:51.083Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1955005154533454/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7539319034",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.1986739999999827,
                53.14719499999999
              ],
              "city": "Mansfield",
              "postcode": "NG18 1PJ",
              "_id": {
                "$oid": "5a63328b765eba094646f981"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Bootcamp",
            "Core Fitness",
            "Functional Training",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FjlR5RN7-x4lessbg4fit.JPG"
          },
          "companyName": "Xercise4Less",
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/mansfield-gym/",
          "profileTitle": "Personal Trainer",
          "affiliation": "Les Mills, REPS",
          "yearsOfExp": 2015,
          "biography": "Hi, my name is Connor  and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Af9AONL4-20180107_195244.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 61,
        "parent": {
          "$oid": "596f46d9f3425e317853150d"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 18,
      "username": "connor_argent",
      "agent": {
        "family": "Chrome Mobile",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-A310F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.111 Mobile Safari/537.36"
      },
      "lastLogin": 1516450278766,
      "approved_date": {
        "$date": "2018-01-11T17:04:42.749Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54c974765eba094646f494"
      },
      "email": "bacon.peter@yahoo.com",
      "firstName": "Peter",
      "surname": "Bacon",
      "promoCode": "PTEARLY",
      "activation_code": "5c9dfaf638da45a52fcd591b9cdd5180c95bbde2",
      "salt": "+YxqzZJ2DR5Ks3cv6ioDWg==",
      "hashedPassword": "UEP3MRZ1Km6ihGfO/pyBCO2J+GGG4fjQitMF/HhXoq4Oum/yLeyqZVKrM/4Ac8vMWzWA+Wq4uNF6UTfwhJ1z2A==",
      "lastUpdated": {
        "$date": "2018-01-17T21:51:38.999Z"
      },
      "createdAt": {
        "$date": "2018-01-09T13:53:56.470Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "491999461",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.4654551,
                54.996968599999995
              ],
              "city": "Royal Quays",
              "postcode": "NE29 6DE",
              "_id": {
                "$oid": "5a5fc56a765eba094646f6b1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bootcamp",
            "Core Fitness",
            "Endurance",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7vJ0JSsH-4fiit.jpg"
          },
          "companyName": "Xercise4Less",
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2015,
          "biography": "Hi, my name is Peter and I am dedicated to help you reach your health & fitness goals.",
          "offers": "",
          "testimonials": ""
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1sfM7KtN-PBacon.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58d240803fa3e80afcabc6a3"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 25,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1515506036743,
      "username": "peter_bacon",
      "approved_date": {
        "$date": "2018-01-10T13:41:46.608Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54d33d765eba094646f498"
      },
      "email": "dcfitness2015@outlook.com",
      "firstName": "Dane",
      "surname": "Cunningham",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "cPu95JrCQyGAcORk2MnMhg==",
      "hashedPassword": "tHBSqUHGUxthnrDAj8AZm+zPYOYIsFGkmT9om7Me5sjUKVC0YXjTOFcMOSJvrUTdcv3hUMuhsE4uxPz42zHj4w==",
      "lastUpdated": {
        "$date": "2018-01-17T21:51:04.009Z"
      },
      "createdAt": {
        "$date": "2018-01-09T14:35:41.231Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7412546315",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.20493620000002,
                53.5541091
              ],
              "city": "Middleton",
              "postcode": "M24 5SG",
              "_id": {
                "$oid": "5a5fc547765eba094646f6b0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Boxing",
            "Circuit Training",
            "Group Classes",
            "Strength & Conditioning",
            "Toning"
          ],
          "price": {
            "min": 18
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mAedxJE8-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "biography": "Level 3 Personal Trainer Based at Xercise4less Middleton",
          "offers": "1 session - £30\n4 Sessions - £90\n10 Sessions - £180 \n18 Sessions - £270\n8 Week Transformation Option - From £100"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gqhX3dpA-Dane%20Cunningham%20PT.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 56,
        "parent": {
          "$oid": "58d1787c3fa3e80afcab9c19"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 13,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515508541432,
      "username": "dane_cunningham",
      "approved_date": {
        "$date": "2018-01-09T18:54:57.752Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54d4c5765eba094646f49c"
      },
      "email": "nicolanlivia@hotmail.co.uk",
      "firstName": "Nicola",
      "surname": "Birkett",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "ahEyzugT4PSU2zTtYbYZUg==",
      "hashedPassword": "zflNO4V1G6zc9ZMCSecPD8n6mKwwny7AoRkaVY+ZTz6QwtB/Q997COok69+jEQm0f7BMw7qGubbrCy+CkBoitw==",
      "lastUpdated": {
        "$date": "2018-01-17T21:50:21.868Z"
      },
      "createdAt": {
        "$date": "2018-01-09T14:42:13.608Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7598236067",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.20493620000002,
                53.5541091
              ],
              "city": "Middleton",
              "postcode": "M24 5SG",
              "_id": {
                "$oid": "5a5fc51d765eba094646f6af"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Circuit Training",
            "Group Classes",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 18
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3D33q1R0-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "offers": "1 session - £30\n4 Sessions - £90\n10 Sessions - £180 \n18 Sessions - £270\n8 Week Transformation Option - From £100",
          "biography": "Level 3 Personal Trainer Based at Xercise4Less Middleton"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/kokatzq7-NicolaBirkett.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 56,
        "parent": {
          "$oid": "58d1787c3fa3e80afcab9c19"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 19,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515509642900,
      "username": "nicola_birkett",
      "approved_date": {
        "$date": "2018-01-09T18:53:29.426Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54d57f765eba094646f4a0"
      },
      "email": "xoliviasmithx@hotmail.co.uk",
      "firstName": "Olivia",
      "surname": "Smith",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "6DsfwqN7eE7tZ82LTyVBQg==",
      "hashedPassword": "mBwnL1xIaK/Cho29OMje1G42Bgjz4RAqg3wPLFtnhDb7rDA9f0rLx/AdaEBE/VcPHauo1MxYyn+LNjxEwYARQw==",
      "lastUpdated": {
        "$date": "2018-01-19T12:58:10.191Z"
      },
      "createdAt": {
        "$date": "2018-01-09T14:45:19.651Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7825158909",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.20493620000002,
                53.5541091
              ],
              "city": "Middleton",
              "postcode": "M24 5SG",
              "_id": {
                "$oid": "5a61eb62765eba094646f8de"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Body Sculpting",
            "Core Fitness",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 18
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BFI1MwJV-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "offers": "1 session - £30\n4 Sessions - £90\n10 Sessions - £180 \n18 Sessions - £270\n8 Week Transformation Option - From £100",
          "biography": "Level 3 Personal Trainer based at Xercise4Less Middleton"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IEh9KyJY-6X-ZjkMd_400x400.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 56,
        "parent": {
          "$oid": "58d1787c3fa3e80afcab9c19"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 15,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515509119842,
      "username": "olivia_smith",
      "approved_date": {
        "$date": "2018-01-09T18:52:38.456Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54d5ff765eba094646f4a4"
      },
      "email": "danieldavisonpt@hotmail.co.uk",
      "firstName": "Daniel",
      "surname": "Davison",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "kiF09frDJvH9ZTacX0XrHQ==",
      "hashedPassword": "X3qAl7RhMGYVuAvCK3Q3+YkXWXiZEiXZp0NSxNBCY6KQkasCnAHqKHyjMzfa40jm6xmw1m2frdC1bE8Eb3i2Tg==",
      "lastUpdated": {
        "$date": "2018-01-17T21:48:39.164Z"
      },
      "createdAt": {
        "$date": "2018-01-09T14:47:27.328Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7795153687",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.20493620000002,
                53.5541091
              ],
              "city": "Middleton",
              "postcode": "M24 5SG",
              "_id": {
                "$oid": "5a5fc4b7765eba094646f6ad"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Endurance",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 18
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7aeqg2CD-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "offers": "1 session - £30\n4 Sessions - £90\n10 Sessions - £180 \n18 Sessions - £270\n8 Week Transformation Option - From £100",
          "biography": "Level 3 Personal Trainer Based at Xercise4Less Middleton"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/X3AaiO5I-dandavisonpt.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 56,
        "parent": {
          "$oid": "58d1787c3fa3e80afcab9c19"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 17,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515509247533,
      "username": "daniel_davison",
      "approved_date": {
        "$date": "2018-01-09T18:51:18.204Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54daba765eba094646f4ac"
      },
      "email": "dani.ward@yahoo.co.uk",
      "firstName": "Danielle",
      "surname": "Ward",
      "promoCode": "PTEARLY",
      "activation_code": "66438cd803f4170ae9b1196f3088f0f7b44cce0c",
      "salt": "xPUYmCNy71SuqASZCeRKdg==",
      "hashedPassword": "QXPh/T/smCpLfqUkqMjyMXhwHUs0y8C3hMQTn2y7oQIRAgMsWmp7qbupPRLyu6CVCBEyRmHMgGNQsANv94kO8w==",
      "lastUpdated": {
        "$date": "2018-01-17T21:47:22.818Z"
      },
      "createdAt": {
        "$date": "2018-01-09T15:07:38.885Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7968844098",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.328982099999962,
                54.5704551
              ],
              "city": "Stockton-on-Tees",
              "postcode": "TS19 0SQ",
              "_id": {
                "$oid": "5a5fc46a765eba094646f6ac"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: First Aid / CPR",
            "Level 2: Group Exercise to Music Instructor",
            "Level 2: Aqua Teacher",
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 2: Physical Activity for Children Instructor",
            "Metafit",
            "Body Pump",
            "Kettlebells",
            "Circuit Training",
            "Transformation Camps",
            "Indoor cycling/ Spinning"
          ],
          "disciplines": [
            "Bootcamp",
            "Core Fitness",
            "Endurance",
            "Hiit",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 30
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3luJlImp-Danielle%20Ward%20-%20Stockton%20North%20Digital-01.png"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2014,
          "biography": "About Me\n\nI have always been interested in health and fitness and have taken part in many different types of fitness activities from a young age.\nI am a mother to 2 young children and continued to exercise throughout both of my pregnancies which I believe helped me to get back into shape quickly and regain my fitness levels.\nPrevious to starting x4l I was working as a PT instructing clients from home and outdoors. \nAs well as being qualified to level 3 in personal training, I am also a freelance fitness instructor teaching spinning, aqua aerobics, metafit, bums & tums, circuit training, bootcamps and children’s fitness classes, and I also coach gymnastics and trampoline classes.\n\nWhy Choose Me \n\n I have always been interested in health and fitness and have taken part in many different types of fitness activities from a young age.\nI am a mother to 2 young children and continued to exercise throughout both of my pregnancies which I believe helped me to get back into shape quickly and regain my fitness levels.\nPrevious to starting x4l I was working as a PT instructing clients from home and outdoors. \nAs well as being qualified to level 3 in personal training, I am also a freelance fitness instructor teaching spinning, aqua aerobics, metafit, bums & tums, circuit training, bootcamps and children’s fitness classes, and I also coach gymnastics and trampoline classes."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pkbPv18X-Danielle.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 57,
        "parent": {
          "$oid": "58d25cac3fa3e80afcabccb8"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 13,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515510459102,
      "username": "danielle_ward",
      "approved_date": {
        "$date": "2018-01-10T13:45:02.625Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54e4a3765eba094646f4b1"
      },
      "email": "bencarlisle_3@hotmail.co.uk",
      "firstName": "Ben",
      "surname": "Carlisle",
      "promoCode": "PTEARLY",
      "activation_code": "9b48b3cd1c5d421502344e433bd8813afed2dbab",
      "salt": "UcLd1gRiqvxRcbXgsv03Vg==",
      "hashedPassword": "GRkzjMWB5H5icm6RTDrHB8GvA+ZUe8JC2nNPxPg8ZwNSjinxLV/5o3/nDY7yN03nbBN5A0X6sdY7eRiowa6QOQ==",
      "lastUpdated": {
        "$date": "2018-01-17T21:46:48.800Z"
      },
      "createdAt": {
        "$date": "2018-01-09T15:49:55.509Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7415517739",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.4282192000000578,
                53.57686469999999
              ],
              "city": "Bolton",
              "postcode": "BL1 1LS",
              "_id": {
                "$oid": "5a5fc448765eba094646f6ab"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Group Classes",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 20
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AVMQpbpa-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10155477982404092/",
          "instagram": {
            "access_token": "28109755.8640130.7722058c6b5e4713a7c8b240d145a6cf",
            "link": "https://www.instagram.com/bencarlisle3"
          },
          "yearsOfExp": 2014,
          "biography": "Hi, my name is Ben and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/WDESt1rR-IMG_0787.JPG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 61,
        "parent": {
          "$oid": "58ca6a098972aa10a5b2a8e1"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 7,
      "agent": {
        "family": "Safari",
        "major": "10",
        "minor": "0",
        "patch": "3",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_3) AppleWebKit/602.4.8 (KHTML, like Gecko) Version/10.0.3 Safari/602.4.8"
      },
      "lastLogin": 1515512995827,
      "username": "ben_carlisle",
      "approved_date": {
        "$date": "2018-01-10T13:37:15.337Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54f225765eba094646f4b9"
      },
      "email": "ehsanhardanis@gmail.com",
      "firstName": "Ehsan",
      "surname": "Hardan",
      "promoCode": "PTEARLY",
      "activation_code": "034a3edbe9ef88aca2e77f4a429a1bdab8e19c46",
      "salt": "t/3dipNR9kxSCw80KBB1xA==",
      "hashedPassword": "SXYZYWqqgicgv4kfxGtAixt0ChN3Ml+FLkrj98FiumSkU2rZOo493072xgQJ0qNA8xC4FaJElQcDnyLgf4twvg==",
      "lastUpdated": {
        "$date": "2018-01-10T13:35:11.931Z"
      },
      "createdAt": {
        "$date": "2018-01-09T16:47:33.236Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "5947878",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.4654527,
                54.996931100000005
              ],
              "city": "Royal Quays",
              "postcode": "NE29 6DE",
              "_id": {
                "$oid": "5a56168f765eba094646f501"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bootcamp",
            "Core Fitness",
            "Endurance",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/L7FWtWq3-4fiit.jpg"
          },
          "companyName": "Xercise4Less",
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Personal Trainer",
          "biography": "Hi, my name is and I am dedicated to help you reach your health & fitness goals.",
          "testimonials": "",
          "offers": ""
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/nWO1GcTj-EHardan.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d240803fa3e80afcabc6a3"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 16,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1515516453458,
      "username": "ehsan_hardan",
      "approved_date": {
        "$date": "2018-01-10T13:34:09.460Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54f2c5765eba094646f4bc"
      },
      "email": "gemmaj45@hotmail.com",
      "firstName": "Gemma",
      "surname": "Jones",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "XvnbECFH0qk7I+EMJwjh9A==",
      "hashedPassword": "P2XgjJZsk0T4YUr5IvVm1rUgLjoboEXyVlq0N+ba7sWScvpzj50Zt0FIp5D76OktqlYCwrpaG0x+/2ZMorUx6g==",
      "lastUpdated": {
        "$date": "2018-01-17T21:45:51.819Z"
      },
      "createdAt": {
        "$date": "2018-01-09T16:50:13.517Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7717290594",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.7508517,
                51.4905235
              ],
              "city": "Portishead",
              "postcode": "BS20 7QD",
              "_id": {
                "$oid": "5a5fc40f765eba094646f6aa"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Nutrition Coach (Precision Nutrition)"
          ],
          "disciplines": [
            "Functional Training",
            "Group Classes",
            "Nutrition",
            "Online Coaching",
            "Sports Massage"
          ],
          "price": {
            "min": 50
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/CndXUmIT-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2017,
          "biography": "Will complete this later"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/CYiTWb9v-20180108_115344.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58cffe1d8972aa10a5b3d9ae"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 12,
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G925F/G925FXXS5EQL5 Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1515516613908,
      "username": "gemma_jones",
      "approved_date": {
        "$date": "2018-01-09T18:42:24.062Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54f5cf765eba094646f4c1"
      },
      "email": "karlmcguire@hotmail.co.uk",
      "firstName": "Karl",
      "surname": "McGuire",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "OnJWz0AKPPY8rG+Twc0RFw==",
      "hashedPassword": "avAXSmOH+O6YY/FxXgVPnI9HhgihtrKQSpKyBd9hJxVy95jb40PGgkKhJazdeoR4Dk3mzaODZg3/zabZbnyUAA==",
      "lastUpdated": {
        "$date": "2018-01-17T21:44:46.222Z"
      },
      "createdAt": {
        "$date": "2018-01-09T17:03:11.019Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "5947878",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.4654505,
                54.996929599999994
              ],
              "city": "Royal Quays",
              "postcode": "NE29 6DE",
              "_id": {
                "$oid": "5a5fc3ce765eba094646f6a9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bootcamp",
            "Core Fitness",
            "Endurance",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DwS86Zz7-x4lessbg4fit.JPG"
          },
          "companyName": "Xercise4Less",
          "companyWebsite": "https://www.xercise4less.co.uk/",
          "profileTitle": "Personal Trainer",
          "biography": "Hi, my name is Karl and I am dedicated to help you reach your health & fitness goals.",
          "offers": "",
          "testimonials": ""
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LDNqLRrS-Karl.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d240803fa3e80afcabc6a3"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 17,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1515517391985,
      "username": "karl_mcguire",
      "approved_date": {
        "$date": "2018-01-10T13:30:26.427Z"
      }
    },
    {
      "_id": {
        "$oid": "5a54fbc8765eba094646f4c5"
      },
      "email": "stephen.clancy71@sky.com",
      "firstName": "Stephen",
      "surname": "Clancy",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "+zIxKvC39ZhMBRp17vlD9A==",
      "hashedPassword": "g1p7e1GdxBZrupHrFiLZrz0tBLSVmef9bd8N0JvKkUTPRTM7idAtDSPX5LTtE76TAQ6WBIQ4+fGj3jMWH2AU3Q==",
      "lastUpdated": {
        "$date": "2018-01-22T10:37:44.833Z"
      },
      "createdAt": {
        "$date": "2018-01-09T17:28:40.919Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7903221485",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.328982099999962,
                54.5704551
              ],
              "city": "Stockton-on-Tees",
              "postcode": "TS19 0SQ",
              "_id": {
                "$oid": "5a65bef85f0b475e1c9b1d16"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Bootcamp",
            "Boxing",
            "Circuit Training",
            "Core Fitness",
            "Nutrition"
          ],
          "price": {
            "min": 30
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Up0KhQpD-Stephen%20Clancy%20-%20Stockton%20North%20Digital-01.png"
          },
          "profileTitle": "Personal Trainer",
          "biography": "Hi, my name is Stephen and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3I387B5Q-FullSizeRender%20(22).jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d25cac3fa3e80afcabccb8"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515518921153,
      "username": "stephen_clancy",
      "approved_date": {
        "$date": "2018-01-10T13:26:44.608Z"
      },
      "password_reset_code": "4401dbcf97a227e07f392c0d7019c62720d5d45f"
    },
    {
      "_id": {
        "$oid": "5a5624cc765eba094646f514"
      },
      "email": "hamisibobby@gmail.com",
      "firstName": "Trainer",
      "surname": "Nakhisa",
      "promoCode": "PTEARLY",
      "activation_code": "fc102781bc5474bc2fa9e84b79f91efc4408ac8a",
      "salt": "OZiZJEljiQmP6iN17UH9tw==",
      "hashedPassword": "vYnqVhezRP75DmDbf/bWokvnw5iG1f6khX3Fl2DciXEEpDrFUmLMBHwMlHLVabrhEz/wg3Tl2tNFDrjVARlGvA==",
      "lastUpdated": {
        "$date": "2018-01-18T12:00:01.035Z"
      },
      "createdAt": {
        "$date": "2018-01-10T14:35:56.584Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_1 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C153 Safari/604.1"
      },
      "lastLogin": 1515594957194
    },
    {
      "_id": {
        "$oid": "5a56975e765eba094646f53b"
      },
      "email": "antar707@gmail.com",
      "firstName": "Mamhoud",
      "surname": "Abouelmaaty",
      "promoCode": "PTEARLY",
      "activation_code": "eadeff362eca9eb6a8ab3484e17f288c8a2a8b2b",
      "salt": "6rBqk3ZtRfajRAaFzpWRDg==",
      "hashedPassword": "PRW2PRKloa0gbteOb0Hd+2onRUQUujarH48WKl/bNlwb6IbixNUFZa6vWKyl8EyptQmpn+QRMd/BrYqVwbej3Q==",
      "lastUpdated": {
        "$date": "2018-01-10T23:06:22.526Z"
      },
      "createdAt": {
        "$date": "2018-01-10T22:44:46.523Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "6463617452",
            "code": "+1"
          },
          "locations": [
            {
              "geolocation": [
                -80.14844069999998,
                25.783312
              ],
              "city": "Miami Beach",
              "postcode": "33139",
              "_id": {
                "$oid": "5a5697cf765eba094646f53d"
              },
              "country": "United States",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Nutritionist",
            "Personal Trainer"
          ],
          "disciplines": [
            "Bodybuilding",
            "Bootcamp",
            "Circuit Training",
            "Core Fitness",
            "Disability Training"
          ],
          "price": {
            "min": 75
          },
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2mUUYlb5-7B2DC004-462E-4191-8947-DB0AA4140EC3%20(1).JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "Antar707",
          "affiliation": "Muscle & Fitness",
          "yearsOfExp": 2000,
          "biography": "I am an exercise and fitness nutritional specialist. I also have a sports background which includes soccer and track. My specific specializations as a trainer include- weight loss, sports training, toning and sculpting, muscle gain, strength and conditioning, and nutritional guidance. My desire for health and fitness is tremendous. I am tenacious at having my clients receive their desired end result or “dream body” and will stop at nothing to make sure they accomplish this. A clients’ fitness goals are my goals, we're on this fitness journey together. Fitness should be enjoyable and shouldn't feel like something you're forced to do. I take pride in instilling this principle in individuals and motivating them to live a healthy active lifestyle. Also I have hosted many live talk shows in regards to nutrition.\n\n \n•\tGroup Fitness Instructor \n•\tPersonalized Coaching\n\n•\tBody Building\n•\tNutrition Planning"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/t7KZzdS1-IMG_9548.JPG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 61
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 10,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
      },
      "lastLogin": 1515624286860,
      "username": "mamhoud_abouelmaaty",
      "approved_date": {
        "$date": "2018-01-10T23:06:22.469Z"
      }
    },
    {
      "_id": {
        "$oid": "5a590c15765eba094646f57c"
      },
      "email": "oliver.t1fitness@gmail.com",
      "firstName": "Oliver",
      "surname": "Clarke",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "fSD0ewDmov9hqnYfIZDB1w==",
      "hashedPassword": "wo8+TDvjgXlkvWMdtCVw4FoqhhuSq7F0XNhQppg7TOIgTWbVIwoqKKbEye7TShw3+keyjiiQiFIrgKlsKUkxVA==",
      "lastUpdated": {
        "$date": "2018-03-04T19:42:14.871Z"
      },
      "createdAt": {
        "$date": "2018-01-12T19:27:17.023Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7942902321",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.1397591999999577,
                52.6368778
              ],
              "city": "Leicester",
              "postcode": "LE1 4NR",
              "_id": {
                "$oid": "5a9c4c16f3d6ea3af77dc466"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Body Sculpting",
            "Functional Training",
            "Hiit",
            "Online Coaching",
            "Weight Loss"
          ],
          "price": {
            "min": 35
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ariQpGeq-fruit-and-veg.jpg"
          },
          "profileTitle": "Personal Trainer",
          "biography": "Having worked 12+ hours everyday as a Head Chef for over 3 years, I learnt a lot about good food but lost my passion. Coming home after working like this meant the last thing I wanted to do was cook a healthy meal for myself so I decided to take a pay cut, hang up my apron and move into the Fitness industry and I haven't looked back since. This then launched the brand, Type One Fitness\n\nWorking at a director level for various large-scale fitness brands, I have developed an un-rivalled level of professionalism, client care and business understanding allowing me to deliver a far higher calibre of training session for you as a client.\n\nOver the years I have trained those wanting to compete: athletes, celebrities and many others, all of whom have seen great results. Now it's your turn...",
          "strapLine1": "Type One Fitness: It's Time To Take Back Control",
          "offers": "Please Contact me for details of prices, packages & offers"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/s2RXpvXO-8DA1F9B0-CC97-4642-8770-81F79A860B14.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 11,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516047115413,
      "username": "oliver_clarke",
      "approved_date": {
        "$date": "2018-01-15T11:39:47.915Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5e2bb2765eba094646f5c9"
      },
      "email": "luke_locke@hotmail.co.uk",
      "firstName": "Luke",
      "surname": "Locke",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "BFbr1/ed8xRn0eTxOyRRQg==",
      "hashedPassword": "/Yo8tk3JS9NMp0KxdbN4xhxc+xjpHeoRADrIVwUafnaLxik4dbEX+BNCMKZTHa3ggFWaBkFiPL0RX+vh2md1/A==",
      "lastUpdated": {
        "$date": "2018-01-28T11:34:48.504Z"
      },
      "createdAt": {
        "$date": "2018-01-16T16:43:30.454Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7792032003",
            "code": "+44"
          },
          "locations": [
            {
              "city": "Smethwick",
              "geolocation": [
                -1.9961609,
                52.503486699999996
              ],
              "postcode": "B66 1RJ",
              "_id": {
                "$oid": "5a6586569137203fc9daa34d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 3: Personal Trainer",
            "BSc Hons Exercise and Health",
            "Level 2 GP referral exercise specialist",
            "spin instructor",
            "body pump instructor"
          ],
          "disciplines": [
            "Boxing",
            "Circuit Training",
            "Hiit",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RUKJ8H5C-eat%20clean%20train%20dirty%203.png"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPS",
          "yearsOfExp": 2016,
          "offers": "4 SESSIONS FOR - £90 \n\n10 SESSIONS FOR - £180 \n\n18 SESSIONS FOR - £ 270",
          "biography": "Currently I have been working with clients in the \nfitness industry for the past 4 years, gaining \nexperience,  training with all age groups and \nensuring that they reach their goals. I always put 100% effort so that clients can get the most out of their sessions with me and make their fitness\n dreams come true. \nWhether it is strength training, toning, weight loss or muscle gain.  Also I currently work within the\ngym at the moment and already have clients \nreaching their goals with me. So why not come \nand start your journey with me today.",
          "strapLine1": "Life is a marathon, so lets start training for it now",
          "instagram": {
            "access_token": "3109271658.8640130.13da67bc993f43d0b15f30a788ad3cd9",
            "link": "https://www.instagram.com/lukefitness"
          },
          "twitter": {
            "accessToken": "954398727376134144-H3YespS4eMViOK7NhhcaDV4G6hY30nT",
            "accessTokenSecret": "2v56fg8UbSmHsnkL92xnCKgYRsGsLuYDWuipa37htfepk",
            "link": "https://twitter.com/lukelocke3",
            "screen_name": "lukelocke3",
            "user_id": "954398727376134144"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IjZ3uTgR-luke.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 77,
        "parent": {
          "$oid": "58d255593fa3e80afcabcb31"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 46,
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G955F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1517139288449,
      "username": "luke_locke",
      "approved_date": {
        "$date": "2018-01-17T12:53:47.612Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5e37bb765eba094646f5cc"
      },
      "providerId": "2001758063483458",
      "surname": "Andrews PT",
      "firstName": "Jay",
      "email": "jlasmassage@hotmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T22:20:01.425Z"
      },
      "createdAt": {
        "$date": "2018-01-16T17:34:51.312Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/2001758063483458/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7710688142",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.2233728,
                53.0117337
              ],
              "city": "Newcastle-under-Lyme",
              "postcode": "ST5 1AW",
              "_id": {
                "$oid": "5a5f479e765eba094646f5fe"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR",
            "Level 3: Personal Trainer",
            "Level 2: Group Exercise to Music Instructor",
            "level3 sports therapist"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Hiit",
            "Nutrition",
            "Sports Massage"
          ],
          "price": {
            "min": 30
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/EpZBm03d-1_GAobIoeZ3lqgwD9MIRaRxg.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "vctc",
          "yearsOfExp": 2010,
          "biography": "Hi I'm Jay one of the personal trainers at Xercise4less Newcastle under Lyme, if you're after a personal trainer who guarantees to not just push you hard to achieve you're goals whether it be weight loss, fitness,endurance, strength or building muscle but you'll also get a friend who will support you 24/7.\nI also have military background which consists of time in the Royal Air Force & Royal Marines so if you want to improve you're fitness physically & mentally then i can give you support in that area.\nI am also a qualified sports massage therapist too & can provide professional advice & treatment such as deep tissue massage & manipulation for injuries, muscle aches & pains which will improve you're workouts & daily tasks.\nFeel free to have a chat if you see me around the gym or contact me.",
          "offers": "TRANSFORMATION CAMPS- \n8 WEEKS- 3x 1 hour Sessions a week.\nAll for £100- Works Out At £2.40 per session.\n\nPT SESSIONS-\n1 Session-£30\n4 Sessions-£90 \n10 Sessions-£180\n18 Sessions- £270"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/yK4tHJpM-IMG_0201.PNG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 67,
        "parent": {
          "$oid": "58d238483fa3e80afcabc4dd"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 15,
      "username": "jay_andrews_pt",
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_1 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C153 Safari/604.1"
      },
      "lastLogin": 1516227601391,
      "approved_date": {
        "$date": "2018-01-17T12:54:54.606Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5e3aa2765eba094646f5d1"
      },
      "providerId": "1818980704787665",
      "surname": "Johnson",
      "firstName": "Daniel",
      "email": "dj-dan-1@hotmail.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T21:42:04.729Z"
      },
      "createdAt": {
        "$date": "2018-01-16T17:47:14.139Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1818980704787665/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7447140340",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.4119947111743023,
                53.01287139866729
              ],
              "city": "Denby Village",
              "postcode": "DE5 8PA",
              "_id": {
                "$oid": "5a5fc32c765eba094646f6a7"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer"
          ],
          "disciplines": [
            "Bodybuilding",
            "Functional Training",
            "Hiit",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LPB6DHFT-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPS",
          "yearsOfExp": 2016,
          "offers": "1 Free Taster\n£30 a session \n£90 for 4\nInbox me for more details and let me know all your goals!",
          "strapLine1": "",
          "biography": "21, Young and Hungry \nEx footballer \nI've been around weights and training for perfection my entire life, Im friendly,easily approachable and also willing to push you to the brink. I have worked at Xercise4less now 2 months, not only have I pickdd up clients around the gym while teaching classes and interacting, i've picked up clients just on people seeing the intensity I train myself with."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/savoRN0v-filename.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": {
          "$oid": "58d103ba9fb8c0038b65b4b0"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 14,
      "username": "daniel_johnson",
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1516141747188,
      "approved_date": {
        "$date": "2018-01-17T07:56:55.332Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5e45a3765eba094646f5d8"
      },
      "providerId": "117944668366374210186",
      "surname": "Edwards",
      "firstName": "Jordan",
      "email": "jordan.athl207@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-17T21:41:33.530Z"
      },
      "createdAt": {
        "$date": "2018-01-16T18:34:11.250Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7800531631",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.4746185999999852,
                52.9225301
              ],
              "city": "Derby",
              "postcode": "DE1 2AW",
              "_id": {
                "$oid": "5a5fc30d765eba094646f6a6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Endurance",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "biography": "Hi, my name is Jordan and I am dedicated to help you reach your health & fitness goals.",
          "profileTitle": "Personal Trainer",
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/V7umFrGj-x4lessbg4fit.JPG"
          }
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/v4evpI13-x4lesslogo.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52,
        "parent": {
          "$oid": "58d103ba9fb8c0038b65b4b0"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 8,
      "username": "jordan_edwards",
      "agent": {
        "family": "Chrome Mobile",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Linux; Android 7.1.1; E5823 Build/32.4.A.1.54) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.111 Mobile Safari/537.36"
      },
      "lastLogin": 1516185845292,
      "approved_date": {
        "$date": "2018-01-17T13:39:42.742Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5e5b24765eba094646f5da"
      },
      "email": "curtisrussell23@yahoo.co.uk",
      "firstName": "Curtis",
      "surname": "Russell",
      "promoCode": "PTEARLY",
      "activation_code": "441ce22aa504e63a44a4a523a191c683e670fe14",
      "salt": "ZvxR+8c2aj4lB07COmOvaQ==",
      "hashedPassword": "2+m3hTy3fhnHpe69Q7gyT35OwLoKdZczm8W7By8hpCj0oeEHfeH5buy+ousu1bnmN/rvnXPK+igJJJ7FLfnZ1A==",
      "lastUpdated": {
        "$date": "2018-01-24T12:00:00.955Z"
      },
      "createdAt": {
        "$date": "2018-01-16T20:05:56.329Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_1 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C153 Safari/604.1"
      },
      "lastLogin": 1516133156623
    },
    {
      "_id": {
        "$oid": "5a5e6518765eba094646f5dc"
      },
      "email": "will.callison@btinternet.com",
      "firstName": "Will",
      "surname": "Callison",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "vPXmPw3/ndsEr/D6AoOvag==",
      "hashedPassword": "Y0x65PKMu2ee8IqeYQWgKHW18swU4g3JeLaF0aGkQ6u2tkpeuH0MvhbP0GhzjLBxOSMkB8L7XI6kogiHX4Rqrw==",
      "lastUpdated": {
        "$date": "2018-01-17T08:02:50.026Z"
      },
      "createdAt": {
        "$date": "2018-01-16T20:48:24.511Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7828177405",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.49879199999998,
                55.614719
              ],
              "city": "Kilmarnock",
              "postcode": "KA3 1HA",
              "_id": {
                "$oid": "5a5f0329765eba094646f5f9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "NASM certified PT PES",
            "CES"
          ],
          "disciplines": [
            "Flexibility",
            "Injury Rehab",
            "Personal Training",
            "Speed",
            "Strength"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/2fyuCVqW-5230B231-44B0-4BEA-A9F9-1FA0214E82E1.jpeg"
          },
          "profileTitle": "Strength & Conditioning Coach",
          "facebookPage": "will.callison@btinternet.com",
          "affiliation": "REPs",
          "yearsOfExp": 14,
          "biography": "I have been a Personal Trainer and Strength & Conditioning coach for the past 14 years.\nI have worked with many top level elite athletes.\nI currently work with profession ice hockey team Braehead Clan where I work on player performance, strength & Conditioning.",
          "offers": "1 hour single session £30\nGroup S&C session with ongoing support £95"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/n6EnkbKe-48FB7807-042F-486E-A1BA-8558D0426709.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": {
          "$oid": "58d15fd43fa3e80afcab96c5"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 18,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPad; CPU OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1"
      },
      "lastLogin": 1516135704731,
      "username": "will_callison",
      "approved_date": {
        "$date": "2018-01-17T08:02:49.974Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5e7d55765eba094646f5e5"
      },
      "email": "jakejarvispt@hotmail.com",
      "firstName": "Jake",
      "surname": "Jarvis",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "8ctqipD0e6oQSaFaBA+PjQ==",
      "hashedPassword": "H5TdAZzKOGyCW+SUAjOgmTfdB5jTqTd/GNwHcxASQv/kuck7oZ7W0QJ4KhOWyzqc7A8MHANV4NtrmlIhbAT70w==",
      "lastUpdated": {
        "$date": "2018-01-17T07:53:23.290Z"
      },
      "createdAt": {
        "$date": "2018-01-16T22:31:49.593Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7790542035",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.576336,
                53.3473394
              ],
              "city": "Appleton",
              "postcode": "WA4",
              "_id": {
                "$oid": "5a5f00f3765eba094646f5f6"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Calisthenics",
            "Functional Training",
            "HIIT",
            "Nutrition"
          ],
          "disciplines": [
            "Core Fitness",
            "Functional Training",
            "Hiit",
            "Rehabilitation",
            "Toning"
          ],
          "price": {
            "min": 22
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YKFeeBbS-Group%20Press%20Up.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "calisthenicbodies",
          "affiliation": "Reps",
          "yearsOfExp": 2013,
          "offers": "1 Session £30\n4 Sessions £90\n10 Sessions £180\n18 Sessions £270",
          "strapLine1": "The Body You Want Using The Body You Have",
          "biography": "Hi, my name is Jake and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/qz3EJG64-Capture.PNG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 73,
        "parent": {
          "$oid": "58d26afe3fa3e80afcabd006"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516141909864,
      "username": "jake_jarvis",
      "approved_date": {
        "$date": "2018-01-17T07:53:23.236Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5ef968765eba094646f5ef"
      },
      "email": "david.goodchild@xercise4less.co.uk",
      "firstName": "David",
      "surname": "Goodchild",
      "promoCode": "PTEARLY",
      "activation_code": "2a8def00e3423fb9e21170441334c0106d39295b",
      "salt": "H7TmoVnr2knQY1mFQDnJ5g==",
      "hashedPassword": "SBUPOl287PIpePwR9O1eHXx7dhHB3P22knG5y084Ygn8UIF/1ufkkCXgVmTU1J5KAsBn1eXX5wYI9X+kT3xBew==",
      "lastUpdated": {
        "$date": "2018-01-17T21:34:07.229Z"
      },
      "createdAt": {
        "$date": "2018-01-17T07:21:12.524Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7929039750",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.5794327999999496,
                53.8046254
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "5a5fc14f765eba094646f6a5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Functional Training",
            "Nutrition",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VSFP4vIi-x4lessbg4fit.JPG"
          },
          "companyName": "Xercise4less",
          "companyWebsite": "https://www.xercise4less.co.uk",
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2015,
          "biography": "Hi, my name is David and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/c029EmPS-1COVER%20IMAGE%20vg.JPG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58d0fe738972aa10a5b41078"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 10,
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.1.1; SAMSUNG SM-N950F Build/NMF26X) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1516173672784,
      "username": "david_goodchild",
      "approved_date": {
        "$date": "2018-01-17T13:21:52.318Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5f49f2765eba094646f600"
      },
      "email": "rachelstu2012@live.co.uk",
      "firstName": "Rachel",
      "surname": "Stuart",
      "promoCode": "PTEARLY",
      "activation_code": "e13764404565730cab1c62ebeacc97d8214f6316",
      "salt": "b+aoafmNrKa5u5XepjanOA==",
      "hashedPassword": "DIDaEvoS0THWZgkTXshzJiQsBZujeta9rs1ksTnZ3BAE/1mU8wISzZOZJ7YvZ7b+gdX36jgTQ2aFU+5r8q/fEA==",
      "lastUpdated": {
        "$date": "2018-01-17T21:33:34.300Z"
      },
      "createdAt": {
        "$date": "2018-01-17T13:04:50.373Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7973232179",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.2140964000000167,
                54.6842728
              ],
              "city": "Hartlepool",
              "postcode": "TS24 7RX",
              "_id": {
                "$oid": "5a5fc12e765eba094646f6a4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bodybuilding",
            "Body Sculpting",
            "Circuit Training",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 30
          },
          "radius": 11,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FFDjiLMG-x4lessbg4fit.JPG"
          },
          "companyName": "Xercise4less",
          "companyWebsite": "http://www.xercise4less.co.uk",
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2016,
          "biography": "Hi, my name is Rachel and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BE9EWVgu-Photo.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58d164fb3fa3e80afcab97c9"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 11,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516194290655,
      "username": "rachel_stuart",
      "approved_date": {
        "$date": "2018-01-17T13:33:48.183Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5f4d7d765eba094646f606"
      },
      "email": "leeturner2010@hotmail.co.uk",
      "firstName": "Lee",
      "surname": "Turner",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "tYWh+bw3eztkAtJLDRrQ/g==",
      "hashedPassword": "GHHmI88arB/T3svHG7znglxkndtxBSIAJ8ul231vFZSYR8QH/x07vBeEd+Am3XsQ37qmfb/gXgDP8k3D7byJaA==",
      "lastUpdated": {
        "$date": "2018-01-17T21:32:59.719Z"
      },
      "createdAt": {
        "$date": "2018-01-17T13:19:57.077Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7508883825",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.2307598035115057,
                54.71048076546995
              ],
              "city": "Hartlepool",
              "postcode": "TS24 9LY",
              "_id": {
                "$oid": "5a5fc10b765eba094646f6a3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 4: Online"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Hiit",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 25
          },
          "radius": 1,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/r4aE6usl-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1758849110800685/",
          "facebookPage": "leeturner25@hotmail.co.uk",
          "instagram": {
            "access_token": "2221307925.8640130.f0708a39503349b890d84f8c6e797126",
            "link": "https://www.instagram.com/leeturner1996"
          },
          "yearsOfExp": 2016,
          "biography": "I am 21 years old. Fitness has been my passions since I was 9 years old and swam in the pool for the first time I then reached a national level after years of competing. Once I stopped competing I started the gym and it not only helps with my fitness and health it also helps me mentally. I love helping people reach there goals and seeing them better them selfs.",
          "offers": "1 session £30.00\n4 sessions £90.00\n10 sessions £180.00"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gO65ncJc-EDBE7B43-88DC-4D69-8E62-CE8EA26B5F73.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 69,
        "parent": {
          "$oid": "58d164fb3fa3e80afcab97c9"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 15,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_2 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C202 Safari/604.1"
      },
      "lastLogin": 1516196186117,
      "username": "lee_turner",
      "approved_date": {
        "$date": "2018-01-17T13:45:42.562Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5f5202765eba094646f613"
      },
      "email": "scottreevept@gmail.com",
      "firstName": "Scott",
      "surname": "Reeve PT",
      "promoCode": "PTEARLY",
      "activation_code": "a14229184440d57679af0e7da57c4336e479159b",
      "salt": "w+58qjeiTF3+CKyxe8nr1g==",
      "hashedPassword": "GukEKlDSoWJYoQv1MIib3W+87//rjfKU2REkXqywDsLJivni/ROHtHDmVUp/cbhxi2u6482N1LmgNLPH0GUOXw==",
      "lastUpdated": {
        "$date": "2018-01-17T21:30:52.525Z"
      },
      "createdAt": {
        "$date": "2018-01-17T13:39:14.950Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7535248833",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.2140964000000167,
                54.6842728
              ],
              "city": "Hartlepool",
              "postcode": "TS24 7RX",
              "_id": {
                "$oid": "5a5fc08c765eba094646f6a1"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Group Exercise to Music Instructor",
            "body pump",
            "spin"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Functional Training",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 0
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FbLwNwVV-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2011,
          "biography": "Hi, my name is Scott and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/UjrK7STT-scotty.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58d164fb3fa3e80afcab97c9"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 7,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516196355168,
      "username": "scott_reeve_pt",
      "approved_date": {
        "$date": "2018-01-17T13:50:42.303Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5f67c7765eba094646f680"
      },
      "email": "sarahxm92@hotmail.co.uk",
      "firstName": "Sarah",
      "surname": "Mccarthy",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "MPVeExWCSGqVhQFxrhx4uA==",
      "hashedPassword": "XbgG8zsB592Ls/ng5aIa5KH6josYfhV2dsYxjz9W2Tx4bWZZhCqfQFZQnPreltaMMBIYqGnQti5DiiT66FL7Mw==",
      "lastUpdated": {
        "$date": "2018-01-18T18:57:26.064Z"
      },
      "createdAt": {
        "$date": "2018-01-17T15:12:07.247Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7817335569",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.5874505430150037,
                53.39182408588005
              ],
              "city": "Warrington",
              "postcode": "WA1 2PR",
              "_id": {
                "$oid": "5a60ddcc765eba094646f825"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Functional Training",
            "Group Classes",
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IFE3G93x-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "sarahxm92@hotmail.co.uk",
          "affiliation": "Diploma",
          "yearsOfExp": 2014,
          "offers": "4 sessions £120\n8 sessions £240",
          "biography": "Hi, I’ve been personal training for 4 years and the gym is my passion! Seeing my clients reaching different goals is the highlight of my job!"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/tJonJyLq-image.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 65,
        "parent": {
          "$oid": "58d26afe3fa3e80afcabd006"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 17,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_2 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C202 Safari/604.1"
      },
      "lastLogin": 1516301846019,
      "username": "sarah_mccarthy",
      "approved_date": {
        "$date": "2018-01-18T17:47:56.746Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5f6e66765eba094646f68a"
      },
      "email": "lewisblackham@hotmail.com",
      "firstName": "Lewis",
      "surname": "Blackham",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "rYWk9PE3Xc07aWTCtSeMww==",
      "hashedPassword": "iRwT1LEhq5vJapY91/zd1lEds2fmUzBsvoQvlebCCfDPsdiq4zOvcE/65UvVUvIsym+kY5nyzHDWpfVJJyVc+Q==",
      "lastUpdated": {
        "$date": "2018-01-17T18:26:29.547Z"
      },
      "createdAt": {
        "$date": "2018-01-17T15:40:22.731Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7703287086",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                0.0878060000000005,
                51.76778699999999
              ],
              "city": "Harlow",
              "postcode": "CM19 4BX",
              "_id": {
                "$oid": "5a5f9555765eba094646f6a0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 3: Nutrition"
          ],
          "disciplines": [
            "Bootcamp",
            "Group Classes",
            "Online Coaching",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9wo23vBF-79f93449-d96b-4323-ac8d-dfa306ad6707.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "reps",
          "yearsOfExp": 2017,
          "biography": "Hello my names Lewis Blackham\n\nI Joined the army when I was 16 years old and from a young age, I quickly promoted through the ranks, that then made me realise my passion for coaching/mentoring others achieve their goals. \n\nIn the Army, we did up to two PT sessions a day keeping our fitness peak. Being in an infantry battalion that was my life, once again from a very young age I quickly realised my passion for fitness, pushing myself beyond my limits, seeing my body transform and my cardiovascular fitness shoot through the roof. \n\nI left the army in 2016 the only thing that made sense to me was to get myself into the fitness industry where I can continue my long-held passion for helping others achieve their goals and helping people get fit and healthy. \n\nI'm looking forward to the journey"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/LcagSDmq-32bf91ee-13a8-464f-b4eb-e2cec34ba0c3.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 60,
        "parent": {
          "$oid": "58d162603fa3e80afcab9747"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 15,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516211234170,
      "username": "lewis_blackham",
      "approved_date": {
        "$date": "2018-01-17T18:26:29.508Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5f8b7f765eba094646f699"
      },
      "providerId": "574892915",
      "surname": "Mallaburn",
      "firstName": "Toria",
      "email": "toria.mall@hotmail.co.uk",
      "username": "toria_mallaburn",
      "activation_code": "b497b2a7262ea5b8cf43c94c8c9b68fee51eacdf",
      "lastUpdated": {
        "$date": "2018-01-18T10:18:21.089Z"
      },
      "createdAt": {
        "$date": "2018-01-17T17:44:31.377Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "574892915.8640130.63a48217724340619d98fa178bc7d0bf",
            "link": "https://www.instagram.com/toriamallaburn",
            "username": "toriamallaburn"
          },
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7544052902",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.2233861,
                53.0117568
              ],
              "city": "Newcastle-under-Lyme",
              "postcode": "ST5 1AW",
              "_id": {
                "$oid": "5a5fc0ae765eba094646f6a2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Group Exercise to Music Instructor",
            "Level 3: Advanced Personal Trainer",
            "Level 3: Exercise Music & Dance Partnership (EMDP) Teacher",
            "Level 3: Personal Trainer",
            "Dance",
            "Nutrition",
            "Conditioning",
            "Les Mills BodyPump",
            "Spin",
            "Legs",
            "Bums and Tums",
            "Abs"
          ],
          "disciplines": [
            "Circuit Training",
            "Flexibility",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/1ADomAf9-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2017,
          "strapLine1": "Providing a 24/7 support system for all my clients",
          "biography": "With a dancing background, i specialise in flexibility and strength. Providing all my clients with 24/7 nutrition advice"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RB7Y35Ry-filename.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 68,
        "parent": {
          "$oid": "58d238483fa3e80afcabc4dd"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "active": false,
      "role": "trainer",
      "__v": 17,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516270701041,
      "approved_date": {
        "$date": "2018-01-17T18:23:37.196Z"
      }
    },
    {
      "_id": {
        "$oid": "5a5fcacc765eba094646f6c2"
      },
      "email": "kavanogradyy@gmail.com",
      "firstName": "Kavan",
      "surname": "PT",
      "promoCode": "PTEARLY",
      "activation_code": "b1af6f77e9bf25d3f98bd84a25f348cac407e99e",
      "salt": "4AlPzkcnzAa/KKW89nyFuA==",
      "hashedPassword": "WaRtHO4zX8UZyL3n8JWvo95lvBXjs11/Ht+H2UPR3bYdZmcipmiz31biZd197I88+KONquDPTkLMSK/y2OlqWw==",
      "lastUpdated": {
        "$date": "2018-01-18T16:49:51.410Z"
      },
      "createdAt": {
        "$date": "2018-01-17T22:14:36.250Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7880638475",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.12030900000002,
                52.4821669
              ],
              "city": "Brierley Hill",
              "postcode": "DY5 3BB",
              "_id": {
                "$oid": "5a5fe909765eba094646f701"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Group Classes",
            "Online Coaching"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/o6GKyvVa-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2018,
          "biography": "Hi, my name is Kavan and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/pOqusMiS-bf3b05ee11366ae1a6bd5739773aabf3fab1.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58cff9448972aa10a5b3d8ac"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 7,
      "agent": {
        "family": "IE",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; rv:11.0) like Gecko"
      },
      "lastLogin": 1516294191356,
      "username": "kavan_pt",
      "approved_date": {
        "$date": "2018-01-18T00:23:37.954Z"
      }
    },
    {
      "_id": {
        "$oid": "5a603fc3765eba094646f78f"
      },
      "email": "scott.montgomery1@yahoo.co.uk",
      "firstName": "Scott",
      "surname": "Montgomery",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "aAaaAVsPVI0jLBkHUm2lxA==",
      "hashedPassword": "J7BIIpnWDlUNmhW9o9hOg5QvJU5rx4w6x09F0Yr4B3D/856MWSGoTxhLqmueG6W+bdEpNZyKH2kYH2pG6xiiZg==",
      "lastUpdated": {
        "$date": "2018-01-18T10:18:45.912Z"
      },
      "createdAt": {
        "$date": "2018-01-18T06:33:39.843Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7909992012",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.037021,
                55.7749106
              ],
              "city": "Hamilton",
              "postcode": "ML3",
              "_id": {
                "$oid": "5a607485765eba094646f7aa"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Nutrition",
            "Fitness testing",
            "Anatomy and Physiology"
          ],
          "disciplines": [
            "Flexibility",
            "Functional Training",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/a7PmSKwx-4fiit%20logo.jpg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "CYQ",
          "yearsOfExp": 2014,
          "offers": "1-2-1 Personal Training          Cost              \n\n\nSingle session 1 hour:              30                  \n4 per month(30 days):              90                   \n10 per month(30 days):           180                \n18 per month(30 days):           270                  \nUnlimited(30 days):                 375",
          "biography": "Being a personal trainer allows me to share the knowledge i have gained over the years of training to help others with their health and fitness goals, which is a great feeling."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ykKtCoyw-scott%20profile%20picture.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 64,
        "parent": {
          "$oid": "58d15fd43fa3e80afcab96c5"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516257220063,
      "username": "scott_montgomery",
      "approved_date": {
        "$date": "2018-01-18T07:52:17.292Z"
      }
    },
    {
      "_id": {
        "$oid": "5a6060b0765eba094646f794"
      },
      "email": "louski.lowe@gmail.com",
      "firstName": "Louise",
      "surname": "Lowe",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "OWK/LLMln4FeankBU07KFA==",
      "hashedPassword": "uCIXYSOe9YVjNJX7V0hjIq8g3BJ2K934uOH22ZLc7nj5OX69zQsluka//FgBTJkEHRnkUo3jWXW5chy5BNcS1Q==",
      "lastUpdated": {
        "$date": "2018-01-18T17:21:47.592Z"
      },
      "createdAt": {
        "$date": "2018-01-18T08:54:08.601Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7480864537",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.1986739999999827,
                53.14719499999999
              ],
              "city": "Mansfield",
              "postcode": "NG18 1PJ",
              "_id": {
                "$oid": "5a60d7ab765eba094646f823"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 4 Stress Management Consultancy",
            "Level 3 Food and Environmental Allergies",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Group Classes",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/AOX22Ojp-x4lessbg4fit.JPG"
          },
          "companyName": "Xcercise4less",
          "companyWebsite": "Http://Www.xercise4less.Co.uk",
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2018,
          "affiliation": "Premier Global",
          "strapLine1": "Strength is physical and mental",
          "biography": "At 22 I was a size 22, I was told by my Doctors that my ill health would continue, eventually my painful legs would stop working altogether and I would likely live my life in a wheelchair. I decided otherwise, it has been a long journey and I am now qualified and experienced to help you on yours.",
          "offers": "1 single session  £30\n4 per month £90\n10 per month £180 \n18 per month £270\nUnlimited  £375 *t&cs apply \nThe more commitment you give the better value the sessions are"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/TFW4HCb8-IMG_8616.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 72,
        "parent": {
          "$oid": "596f46d9f3425e317853150d"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 27,
      "agent": {
        "family": "Chrome Mobile",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Linux; Android 5.0.1; GT-I9505 Build/LRX22C) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.111 Mobile Safari/537.36"
      },
      "lastLogin": 1516295609565,
      "username": "louise_lowe",
      "approved_date": {
        "$date": "2018-01-18T17:21:47.562Z"
      }
    },
    {
      "_id": {
        "$oid": "5a606d8d765eba094646f7a2"
      },
      "email": "louisehutcheon@live.co.uk",
      "firstName": "Louise",
      "surname": "Hutcheon",
      "promoCode": "PTEARLY",
      "activation_code": "501f6859c6330fdc5a84402960f507b25ffc9fc7",
      "salt": "/OV4miL8Cs4JYWGOh1ZRxw==",
      "hashedPassword": "rs3IcDDoayqBZOY8mqSFJoeWonKhVc6oQySCOvgwSmMk5H/rLkLzWLRMJe7ruC2bgQyJRrXzi+1RbfgihpY2KQ==",
      "lastUpdated": {
        "$date": "2018-01-18T18:30:31.098Z"
      },
      "createdAt": {
        "$date": "2018-01-18T09:49:01.049Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7796148929",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.8703296,
                53.357889799999995
              ],
              "city": "Liverpool",
              "postcode": "L24",
              "_id": {
                "$oid": "5a60e7c7765eba094646f82b"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bootcamp",
            "Body Sculpting",
            "Circuit Training",
            "Core Fitness",
            "Group Classes"
          ],
          "price": {
            "min": 30
          },
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/9eQEzMLQ-4fiit.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/1585505418205751/",
          "yearsOfExp": 2015,
          "biography": "I have 22 years if Military experience and have trainer/motivated many soldiers to achieve more than they thought was physically possible. I understand how to gauge, motivate and encourage people in order to get the best out of them, with my own unique style.\n\nI have an infectious enthusiasm for helping people to achieve their goals, whether through 1-2-1 personal training or transforming themselves via transformation camps and classes.\n\nI love doing this to see all individuals achieve their goals, feeling better,looking better and filled with confidence.",
          "offers": "8 week Transformation Camp - £100\n1 Session - £30\n4 sessions - £90\n10 sessions - £180\n18 sessions - £270\n\nAll at X4L Speke"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GznmFVUM-Louise%20Hutcheon%20PT.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 64,
        "parent": {
          "$oid": "58d172fa3fa3e80afcab9ad5"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516268941431,
      "username": "louise_hutcheon",
      "approved_date": {
        "$date": "2018-01-18T18:30:31.068Z"
      }
    },
    {
      "_id": {
        "$oid": "5a606eea765eba094646f7a6"
      },
      "email": "toritraining@hotmail.com",
      "firstName": "Victoria",
      "surname": "Morgan",
      "promoCode": "PTEARLY",
      "activation_code": "503013781fc7228494601967b713e7e0463da48f",
      "salt": "RrxJyZqWAA+Ud/v/J4rzNQ==",
      "hashedPassword": "8MG3srgDPa0FDjyYSLgTHMOqCOakZsoX35SnslxMBqFIu1SNnzaeLJgSvGvGqHJRS1TghqSSgTcarOQ+4OKycw==",
      "lastUpdated": {
        "$date": "2018-01-20T17:49:36.198Z"
      },
      "createdAt": {
        "$date": "2018-01-18T09:54:50.325Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7510075124",
            "code": "+44"
          },
          "locations": [
            {
              "city": "Brierley Hill",
              "geolocation": [
                -2.12030900000002,
                52.4821669
              ],
              "postcode": "DY5 3BB",
              "_id": {
                "$oid": "5a607a27765eba094646f7b4"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer"
          ],
          "disciplines": [
            "Bootcamp",
            "Body Sculpting",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/R7v238pO-IMG_1821.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": null,
          "instagram": {
            "access_token": "4526523818.8640130.85d16376f03d42f68d2245553161dcf6",
            "link": "https://www.instagram.com/4fiit_pt"
          },
          "affiliation": "",
          "yearsOfExp": 2010,
          "strapLine1": "Be the best version of you! Start today :)",
          "offers": "Monthly sessions & packages \n(All sessions are 1 hour & include a program, nutritional plan & frequent weigh & measures) \n\nSingle sessions - £30 \n4 sessions - £90 \n10 sessions - £180 \n18 - £270\nUnlimited - £375 \n\n8 week Transformation bootcamp - £100 \n•24 group Pt sessions \n•group weigh & measures \n•pro access to my nutritional app\n•E recipes \n•24/7 help and support",
          "biography": "Hey I'm Victoria, one of the personal trainers here at Xercise4Less. I have been working within the fitness industry for 9 years, I have competeted nationally and internationally for the country in athletics gaining my England vest at the age of 15. \n\nHealth and fitness is my absolute passion and I love to transfer all the knowledge I have gained over the years to my clients to guide them in to achieving their own personal goals. I like to think myself as a coach, a coach helps to guide you to becoming a better version of you! It's about a complete lifestyle change and as long as you are prepared to change I can assist you a long the way! \n\nI love to keep my sessions varied, challenging but most of all FUN! I offer free consultations and a 30 minute try before you buy session as i will Guarantee it will get you hooked!"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/g3YQMHKt-IMG_4281.PNG"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 73,
        "parent": {
          "$oid": "58cff9448972aa10a5b3d8ac"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 21,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.0 Mobile/14G60 Safari/602.1"
      },
      "lastLogin": 1516470576120,
      "username": "victoria_morgan",
      "approved_date": {
        "$date": "2018-01-18T10:35:10.204Z"
      }
    },
    {
      "_id": {
        "$oid": "5a607562765eba094646f7ab"
      },
      "providerId": "1834323336578467",
      "surname": "Stanyer",
      "firstName": "Jack",
      "email": "jackstanyer@hotmail.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-18T12:16:57.684Z"
      },
      "createdAt": {
        "$date": "2018-01-18T10:22:26.313Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1834323336578467/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7564718511",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.2234214999999997,
                53.0117352
              ],
              "city": "Newcastle-under-Lyme",
              "postcode": "ST5 1AW",
              "_id": {
                "$oid": "5a609039765eba094646f7c3"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "Level 2: Group Exercise to Music Instructor",
            "Online Coach",
            "Bodybuilding Competition Prep",
            "Kettle-bells",
            "Circuit Training",
            "Indoor Cycling"
          ],
          "disciplines": [
            "Bootcamp",
            "Nutrition",
            "Online Coaching",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gLTpUHtV-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "JSPTonline",
          "instagram": {
            "access_token": "607393183.8640130.49cc97241aff42c9a3beae6fb3ad3288",
            "link": "https://www.instagram.com/the_vegandream"
          },
          "affiliation": "REPs",
          "yearsOfExp": 2017,
          "offers": "Personal Training - Xercise4less\nSingle Session - £30\n4 Sessions Per Month - £90 (£22.50 Per Session)\n10 Sessions Per Month - £180 (£18.00 Per Session)\n18 Sessions Per Month - £270 (£15.00 Per Session)\n\nOnline Coaching:\nOne off Diet Plan - £40\nOne off Training Plan- £40\n24hr Online Coaching (weekly check in) - £100\n24hr Online Contest Prep Coaching -£140",
          "biography": "Since I was a child I’ve always looked up to the strong characters in movies and in comics; how they were always capable of protecting others whilst being kind and intelligent. From that spawned an interest in how I could use exercise, sports and weights to transform my physique, physical endurance and mental wellbeing. I started out in my own house with a set of dumbbells and a barbell and ever since then I’ve been hooked. It helped to take me through a lot of tough times in my life as I grew up such as my parents splitting, family members dying, the stress of school GCSEs and College. Not only did it help me through depression, it improved my whole life beyond comprehension. After 5 years of training myself I found a huge pleasure in helping others with their goals and dreams. From this a new passion was born. Helping people implement exercise and fitness of some form so that they may receive the benefits, just like it had for me, is something I knew I wanted to do. To follow my new passion as a career I decided to take it further by studying and achieving a qualification in personal training. And Joined 4fiit to follow my dream of being a Personal Trainer.\n\nIn 2017 I took my training to the next level and competed in my first competitive bodybuilding season as a natural. I managed to win The UKDFBA UK & International Championships and placed 2nd in the WNBF World Final in Boston (America) as a Teen.",
          "strapLine1": "I'm not a personal trainer I'm a life Changer. #BeEpic"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/yKC0t3a7-filename.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 81,
        "parent": {
          "$oid": "58d238483fa3e80afcabc4dd"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 15,
      "username": "jack_stanyer",
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516270946939,
      "approved_date": {
        "$date": "2018-01-18T12:16:57.634Z"
      }
    },
    {
      "_id": {
        "$oid": "5a607c77765eba094646f7b7"
      },
      "email": "xatzimike93@gmail.com",
      "firstName": "Michael",
      "surname": "Chatziminas",
      "promoCode": "PTEARLY",
      "activation_code": "2d9a393cc6fab76ef2b212d5738846d2be1d2870",
      "salt": "DjaFchBGgLpFjZxlEp/+cw==",
      "hashedPassword": "AA99+XQVQjIFQkSoSTg334rPIk00o8ohJ5L2aK6phzYfyUCtwatGj43gS2EO8O2gaqeFhQBHSCP+HLb5bolVHA==",
      "lastUpdated": {
        "$date": "2018-01-18T15:50:17.483Z"
      },
      "createdAt": {
        "$date": "2018-01-18T10:52:39.879Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7784965472",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.6301288,
                53.542921
              ],
              "city": "Wigan",
              "postcode": "WN1",
              "_id": {
                "$oid": "5a609258765eba094646f7c5"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zUfQKUor-x4lessbg4fit.JPG"
          },
          "companyName": "Xercise4less Wigan",
          "companyWebsite": "http://www.xercise4less.co.uk",
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2012,
          "affiliation": "reps",
          "biography": "Hi, my name is Michael and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oamzofXH-mike2.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 58,
        "parent": {
          "$oid": "58d26cf73fa3e80afcabd04b"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516272760111,
      "username": "michael_chatziminas",
      "approved_date": {
        "$date": "2018-01-18T12:26:00.822Z"
      }
    },
    {
      "_id": {
        "$oid": "5a608452765eba094646f7be"
      },
      "email": "warbie1@hotmail.com",
      "firstName": "Kelly",
      "surname": "Sullavan",
      "promoCode": "PTEARLY",
      "activation_code": "fc8090f7be8e59b069e193deb99f03bc17a7026a",
      "salt": "bUNmzWlMmhWB08LVdnMFVQ==",
      "hashedPassword": "cRc+lOZS5qmLYwf8ENCPqfXj7lS+6lrWTpcNDAFujtG/9mK4uCJPp0osnsTb9vNdQYzjc81L1vURc//7fySl1A==",
      "lastUpdated": {
        "$date": "2018-01-18T16:40:33.122Z"
      },
      "createdAt": {
        "$date": "2018-01-18T11:26:10.762Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7828249696",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.423379299999965,
                53.5727482
              ],
              "city": "Bolton",
              "postcode": "BL2",
              "_id": {
                "$oid": "5a60ce01765eba094646f80d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Core Fitness",
            "Flexibility",
            "Nutrition"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/xfFjiIxo-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "reps",
          "yearsOfExp": 2012,
          "biography": "Hi, my name is Kelly and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/k9SW2aBK-19060010_10211474402929010_8792894921109918619_n.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 58,
        "parent": {
          "$oid": "58ca6a098972aa10a5b2a8e1"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 13,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516274770999,
      "username": "kelly_sullavan",
      "approved_date": {
        "$date": "2018-01-18T16:32:03.047Z"
      }
    },
    {
      "_id": {
        "$oid": "5a60b6ac765eba094646f7d9"
      },
      "email": "chris.bailey2112@yahoo.co.uk",
      "firstName": "Chris",
      "surname": "Bailey",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "T4/zGWRZHbHpswh1Kaa9bw==",
      "hashedPassword": "yp1EO7FOVfkxm+mYIM2ZNQzHWWJumFALD4PnjCv5WMXWPF3yUY6s5N6R5bgl5eeATowCiz1MJVFs21pw9yeX4Q==",
      "lastUpdated": {
        "$date": "2018-02-06T13:18:07.425Z"
      },
      "createdAt": {
        "$date": "2018-01-18T15:01:00.256Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7866641227",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.128820000000019,
                52.586973
              ],
              "city": "Saint Peters Square",
              "postcode": "WV1 9SE",
              "_id": {
                "$oid": "5a724642f913ab12c671c153"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Master Personal Trainer",
            "Level 2: Gym Instructor",
            "Nutrition coach"
          ],
          "disciplines": [
            "Circuit Training",
            "Core Fitness",
            "Functional Training",
            "Group Classes",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/RzMTApxC-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "google": "https://plus.google.com/115384351857365171198",
          "affiliation": "Reps, NRPT, European Institute of Fitness",
          "yearsOfExp": 2016,
          "biography": "Master personal trainer with a passion for fitness and helping others achieve their fitness goals from weight loss and maintenance or hypertrophy."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/5f8dW1Lo-20180131_120802.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 62,
        "parent": {
          "$oid": "58d26f043fa3e80afcabd0cd"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 17,
      "agent": {
        "family": "Chrome Mobile",
        "major": "64",
        "minor": "0",
        "patch": "3282",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-A320FL Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.137 Mobile Safari/537.36"
      },
      "lastLogin": 1517923087311,
      "username": "chris_bailey",
      "approved_date": {
        "$date": "2018-01-18T16:42:21.307Z"
      }
    },
    {
      "_id": {
        "$oid": "5a60b9d0765eba094646f7dd"
      },
      "email": "m--d2013@live.co.uk",
      "firstName": "Mr",
      "surname": "Lee",
      "promoCode": "PTEARLY",
      "activation_code": "042e80d2f89f532c335a95e0edad11dae7670221",
      "salt": "088eyB8MZPNSt0tpp/g/fQ==",
      "hashedPassword": "lm7a4XIjwbRNvDuM8rYoMU231zS7TCja9QemIBksravzNcoyybXxHDyimFrVzBtz3Og0Uh4/b9OABeksdxf6pg==",
      "lastUpdated": {
        "$date": "2018-01-26T12:00:00.976Z"
      },
      "createdAt": {
        "$date": "2018-01-18T15:14:24.332Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7534031553",
            "code": "+44"
          },
          "locations": [
            {
              "city": "Brierley Hill",
              "geolocation": [
                -2.12030900000002,
                52.4821669
              ],
              "postcode": "DY5 3BB",
              "_id": {
                "$oid": "5a60ba78765eba094646f7df"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 25
          },
          "radius": 15
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 22
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Firefox",
        "major": "57",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:57.0) Gecko/20100101 Firefox/57.0"
      },
      "lastLogin": 1516288464560,
      "username": "mr_lee"
    },
    {
      "_id": {
        "$oid": "5a60c83e765eba094646f7ef"
      },
      "email": "miastonebridge@hotmail.com",
      "firstName": "Mia",
      "surname": "Arrowsmith",
      "promoCode": "PTEARLY",
      "activation_code": "fb941532cfd9fc3a97f011b906aedee56cde0d75",
      "salt": "3z1kyZEbx6hXkAclg+H/BQ==",
      "hashedPassword": "UX2542YHo+9xOyT6YBJ9X0koZhRGisxowK8FUnV75si1zbuW/GCZhYWlRHI5XyeU2FrfosZTjfqvTuGJd8Q8jQ==",
      "lastUpdated": {
        "$date": "2018-01-18T17:05:06.698Z"
      },
      "createdAt": {
        "$date": "2018-01-18T16:15:58.294Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7944655009",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.1416569000000436,
                52.5964099
              ],
              "city": "Wolverhampton",
              "postcode": "WV6",
              "_id": {
                "$oid": "5a60d3c2765eba094646f81c"
              },
              "country": "United Kingdom",
              "primary": true
            },
            {
              "geolocation": [
                -2.1206600000000435,
                52.806693
              ],
              "city": "Stafford",
              "postcode": "ST16",
              "_id": {
                "$oid": "5a60d3c2765eba094646f81b"
              },
              "country": "United Kingdom",
              "primary": false
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Core Fitness",
            "Functional Training",
            "Nutrition",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/zbNmLBxV-TextSwag-1496928150626.png"
          },
          "companyName": "Xercise4less",
          "companyWebsite": "https://www.xercise4less.co.uk",
          "profileTitle": "Personal Trainer",
          "affiliation": "Fitpro, British Cycling",
          "yearsOfExp": 2011,
          "facebookPage": "ladymiaarrowsmith",
          "instagram": {
            "access_token": "1379296861.8640130.9f9725573a854ccaaffe85083c23d513",
            "link": "https://www.instagram.com/miaarrowsmith1"
          },
          "biography": "Hi, my name is Mia and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/6DmL7HVR-20180109_094630.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 64,
        "parent": {
          "$oid": "58d26f043fa3e80afcabd0cd"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 34,
      "agent": {
        "family": "Chrome Mobile",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.111 Mobile Safari/537.36"
      },
      "lastLogin": 1516293270926,
      "username": "mia_arrowsmith",
      "approved_date": {
        "$date": "2018-01-18T17:05:06.653Z"
      }
    },
    {
      "_id": {
        "$oid": "5a60c928765eba094646f7f2"
      },
      "email": "jamesswanpt@gmail.com",
      "firstName": "James",
      "surname": "Swan",
      "promoCode": "PTEARLY",
      "activation_code": "086e3b2ecc75f72952f40fc9bafb9eec7cbce2a2",
      "salt": "9laEvQl9vRZnXdWRpiUucA==",
      "hashedPassword": "2iYR1r7aJDqtmOh+pN9J7OpnysO4y9icxpwHXoLHcJZWxwkEICxCpWtRX4j6il8rq1lH7PjaldaUgDVq8GDkjw==",
      "lastUpdated": {
        "$date": "2018-01-22T10:29:11.788Z"
      },
      "createdAt": {
        "$date": "2018-01-18T16:19:52.065Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7863791386",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.5476509999999999,
                55.0158401
              ],
              "city": "Longbenton",
              "postcode": "NE12 9SR",
              "_id": {
                "$oid": "5a65bcf75f0b475e1c9b1d12"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Level 3: Nutrition",
            "Level 2: Gym Instructor",
            "Level 2: Group Exercise to Music Instructor",
            "Level 2: First Aid / CPR"
          ],
          "disciplines": [
            "Circuit Training",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 16.0934,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dLLK8ZhS-1COVER%20IMAGE%20vg%20(3).JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2017,
          "biography": "Hi, my name is James and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/K0JekVk6-20170920_101748.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58d23b633fa3e80afcabc59f"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 8,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516292392297,
      "username": "james_swan",
      "approved_date": {
        "$date": "2018-01-18T18:25:39.646Z"
      }
    },
    {
      "_id": {
        "$oid": "5a60c9c6765eba094646f7f6"
      },
      "email": "hannahwhitley@live.co.uk",
      "firstName": "Hannah",
      "surname": "Toon",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "ejT6onYNnmaOzeLqoPCEjg==",
      "hashedPassword": "H185A1j41yGyih2dGMznMW3srwm4oy2sm4n7X5kCly8TDN5La+lcO4kdHPj+8dOX87+QxbLp3haAX8XTkhfpJA==",
      "lastUpdated": {
        "$date": "2018-01-18T16:45:18.106Z"
      },
      "createdAt": {
        "$date": "2018-01-18T16:22:30.163Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7535214640",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.5006019788188938,
                53.67799277360038
              ],
              "city": "Wakefield",
              "postcode": "wh11hr",
              "_id": {
                "$oid": "5a60cf1e765eba094646f814"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Exercise Music & Dance Partnership(EMDP) Teacher",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Core Fitness",
            "Endurance",
            "Exercise to Music",
            "Flexibility",
            "Gym"
          ],
          "price": {
            "min": 20
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/0EFJHkyw-x4lessbg4fit.JPG"
          },
          "profileTitle": "Gym instructor",
          "facebookPage": "hannahwhitley@live.co.uk",
          "instagram": {
            "access_token": "21799655.8640130.7deaaae4677244a8a1aca0bae0fa0297",
            "link": "https://www.instagram.com/hannahtoon23"
          },
          "yearsOfExp": 2017,
          "biography": "Fitness Professional & Gym Instructor \n24 \nEuropean & British Championship Dancer",
          "offers": "-"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/rEf3Cnwx-FBF3346C-0362-460D-A04C-21FE24A49EB0.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 64,
        "parent": {
          "$oid": "58d268743fa3e80afcabcf84"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_1 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C153 Safari/604.1"
      },
      "lastLogin": 1516292550616,
      "username": "hannah_toon",
      "approved_date": {
        "$date": "2018-01-18T16:45:18.073Z"
      }
    },
    {
      "_id": {
        "$oid": "5a60cb67765eba094646f800"
      },
      "email": "hurleyjane@yahoo.com",
      "firstName": "Jane",
      "surname": "Hurley",
      "promoCode": "PTEARLY",
      "activation_code": "3240a4c791c2d88349ed8ded87a5a0bc2734a598",
      "salt": "wtVGJ4uGknYoYR+5fyORTw==",
      "hashedPassword": "JjKZdtlxgTFUgnmhDngrz2XV8D/MJsOBaqbjwNLvYQHSMduBPgJh/0BBaII3NePvgaoE+LclfWeyBlKUJE+9UQ==",
      "lastUpdated": {
        "$date": "2018-01-22T10:28:31.479Z"
      },
      "createdAt": {
        "$date": "2018-01-18T16:29:27.816Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7910266126",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.5476516,
                55.0157452
              ],
              "city": "Longbenton",
              "postcode": "NE12 9SR",
              "_id": {
                "$oid": "5a65bccf5f0b475e1c9b1d11"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 4: Advanced Personal Trainer",
            "Level 4: Strength & Conditioning",
            "Level 3: Personal Trainer",
            "Level 3: Nutrition",
            "Level 3: Advanced Group Exercise to Music Instructor",
            "Level 2: Aqua Teacher",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Circuit Training",
            "Hiit",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 16.0934,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FGXWtSXW-1COVER%20IMAGE%20vg.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2000,
          "biography": "Hi, my name is Jane and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/MccClQHc-20170920_101756.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 55,
        "parent": {
          "$oid": "58d23b633fa3e80afcabc59f"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 8,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516292968030,
      "username": "jane_hurley",
      "approved_date": {
        "$date": "2018-01-18T18:22:42.636Z"
      }
    },
    {
      "_id": {
        "$oid": "5a60d58c765eba094646f81e"
      },
      "email": "richmonkmanpt@hotmail.com",
      "firstName": "rich",
      "surname": "monkman",
      "promoCode": "PTEARLY",
      "activation_code": "9980007158ef7d9c64b992be9a67b487db7a4a40",
      "salt": "cnXxP+SG+DLoEzX1FQt8Tw==",
      "hashedPassword": "zM+YVjlWctJFGlvSbiMQhqwa52WcxfYURro5f68jgGZ+J110LWfymBuDwti1x6lsCTlAt6CczCiGMQtYfxZW1Q==",
      "lastUpdated": {
        "$date": "2018-01-18T17:53:39.604Z"
      },
      "createdAt": {
        "$date": "2018-01-18T17:12:44.232Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7583876935",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.128820000000019,
                52.586973
              ],
              "city": "Saint Peters Square",
              "postcode": "WV1 9SE",
              "_id": {
                "$oid": "5a60deda765eba094646f826"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR",
            "Level 2: Gym Instructor",
            "bootcamps and circuits qualified"
          ],
          "disciplines": [
            "Bodybuilding",
            "Bootcamp",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 20
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/VhJydUu8-Arnold-Schwarzenegger-Quotes-1.jpg"
          },
          "profileTitle": "Personal Trainer",
          "facebookPage": "",
          "affiliation": "reps, lifetimefitness",
          "yearsOfExp": 2016,
          "strapLine1": "Professional, Reliable , Affordable..",
          "biography": "Hi all, \nim Rich, a personal trainer from the west midlands area. I've been doing this for 2 years and managed to build myself up from a part time PT, to gathering a clientele of fitness enthusiasts able to work with me and make it a full time career choice.\nI've been into fitness/bodybuilding for around 6 years now and never looked back.\nI love my job and love giving the dedicated enthusiasts their dream bodies and results, whatever they need.\n\nI'm a friendly, approachable guy who knows the mental and physical demands of transformations and work extremely close with clients to form a brilliant rapport to support all there needs :)",
          "offers": "please feel free to contact me and packages will be discussed"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vvBsCE0m-212bdce4ea831ea980fb981d8469b3b80785.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 71,
        "parent": {
          "$oid": "58d26f043fa3e80afcabd0cd"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 16,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516295564449,
      "username": "rich_monkman",
      "approved_date": {
        "$date": "2018-01-18T17:53:39.563Z"
      }
    },
    {
      "_id": {
        "$oid": "5a61089d765eba094646f82d"
      },
      "email": "sara.challinor@gmail.com",
      "firstName": "Sara",
      "surname": "Challinor",
      "promoCode": "PTEARLY",
      "activation_code": "326f15509b2659a295b68f014e65018639056f35",
      "salt": "3uQ7/281lotDbpDrUADdbA==",
      "hashedPassword": "bot9dRW0yRQnFb5WGnvoU6J3BQ6sU9sR2dsd5spFEZsTRtw5dBeuuzxyxE5J7boohvI4Oh1byMsiay3DgidZ7A==",
      "lastUpdated": {
        "$date": "2018-01-18T21:12:44.010Z"
      },
      "createdAt": {
        "$date": "2018-01-18T20:50:37.216Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "447922531463",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.227300199999945,
                53.013208
              ],
              "city": "Newcastle-under-Lyme",
              "postcode": "ST5 2AD",
              "_id": {
                "$oid": "5a610dcb765eba094646f830"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 4: Obesity & Diabetes",
            "Les mills Bodypump"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 7,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/V4aJi6oy-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "39151540.8640130.28d9a8ac02e8407cbb1ca23d8ca14d77",
            "link": "https://www.instagram.com/sara_chall85"
          },
          "affiliation": "REPs",
          "yearsOfExp": 2017,
          "biography": "Personal training is what I love! \n\nAfter having my own weight loss journey and going from someone who was 18 stone and hated fitness, to 4.5 stone lighter and love being in the gym I decided that I wanted to help other people on their journey \n\nI know what it takes and I want to work with you to achieve your goals \n\nGet in touch :)"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/gCceS0v9-received_10155597501788427.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 63,
        "parent": {
          "$oid": "58d238483fa3e80afcabc4dd"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 10,
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G930F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1516308637473,
      "username": "sara_challinor_SkmeKtCNG",
      "approved_date": {
        "$date": "2018-01-18T21:12:43.972Z"
      }
    },
    {
      "_id": {
        "$oid": "5a61ceac765eba094646f8a4"
      },
      "email": "bptfitness@outlook.com",
      "firstName": "Barry",
      "surname": "Bowman",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "953Xh/Jzw9XB9ldOps0dkQ==",
      "hashedPassword": "4dEdzlDubvA1cSHqAhN9Mf1gSbaeR5dmcNIvPf3wmPQGtT+LCc7mdEZrBMre9qnAdhpMXyB8T9lBWFGyZTEhgw==",
      "lastUpdated": {
        "$date": "2018-01-19T12:41:42.951Z"
      },
      "createdAt": {
        "$date": "2018-01-19T10:55:40.525Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "1355200360",
            "code": "+44"
          },
          "locations": [
            {
              "city": "East Kilbride",
              "geolocation": [
                -4.176998700000013,
                55.76435240000001
              ],
              "postcode": "G74 4LT",
              "_id": {
                "$oid": "5a61e786765eba094646f8cd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 4: Advanced Personal Trainer",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Endurance",
            "Flexibility",
            "Group Classes",
            "Hiit",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/M6FamM59-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "Armed forces",
          "yearsOfExp": 2006,
          "biography": "I have been a certified trainer since 2006, as a kid I was always interested in sports and fitness.Once I finished secondary school I joined the army for four years and I enjoyed the level of fitness I could achieve and see on a daily basis.\n\nWhen I got out, I realised that personal training would be the best career for me. Its a place where i can better myself, see friends and clients excel beyond their goals and be sociable. My clients have achieved great weight loss, toned up, bulked up and some even went on to compete in compensations.\n\nTo start out, we will set up an appointment for a consultation, This will give us a chance to get to know each other, We will talk about your goals and how fast you would like to achieve them, if you are new to nutrition we can set up a meal plan and ill give you information that will compliment your workout structure. From there on out your with me and we will work together and accomplish those goals. I believe that fitness should be fun! You should find a way t enjoy being at the gym. You will see I have a great sense of humor but I will cater to whatever best suits your needs, Some people love having a great laugh and joking around while they're working out, whereas others prefer to have to be a drill sergeant. Then there are people who need both aspects combined! I'm a very flexible person, who is punctual, knowledgeable, and extremely passionate about health!",
          "offers": "1 Session - £30\n4 Sessions - £90\n10 Sessions - £180 \n18 Sessions - £270\n\nCouple Training \n\n1 Session - £45\n4 Sessions - £135 \n10 Sessions - £270\n18 Sessions - £412.50\n\n8 Week Transformation Camps £100!!! \n\nTrain 3 times a week for 1 hour in a group\nOnce a week weigh in!!\n\nVarious times & dates"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/YIGA9EQ6-BARRY.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 65,
        "parent": {
          "$oid": "58d148ec3fa3e80afcab91bf"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 24,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516365693875,
      "username": "barry_bowman",
      "approved_date": {
        "$date": "2018-01-19T11:53:53.974Z"
      }
    },
    {
      "_id": {
        "$oid": "5a61d9e4765eba094646f8ac"
      },
      "email": "laurareid38@outlook.com",
      "firstName": "laura",
      "surname": "reid",
      "promoCode": "PTEARLY",
      "activation_code": "9cf95b63a72271daf31c859879ff2ef185102880",
      "salt": "1IYBJcOe3x2JXbq1TSA42g==",
      "hashedPassword": "QiURIuQk0HoEQ3EAwl2DKlfM1gCXlPUIr5RKXvOknQS7QFGJqQsoSUZwFgBZ5TO4GWjct5fwfMHlpO1/Xx/3MA==",
      "lastUpdated": {
        "$date": "2018-01-19T13:11:46.729Z"
      },
      "createdAt": {
        "$date": "2018-01-19T11:43:32.532Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "1355200360",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.176998700000013,
                55.76435240000001
              ],
              "city": "East Kilbride",
              "postcode": "G74 4LT",
              "_id": {
                "$oid": "5a61ee92765eba094646f8e0"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Gym Instructor",
            "Level 2: Group Exercise to Music Instructor",
            "Metafit",
            "Boxercise."
          ],
          "disciplines": [
            "Bodybuilding",
            "Boxing",
            "Circuit Training",
            "Hiit",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hlz6t8Vx-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2016,
          "biography": "You dont get what you wish for, you get what you work for!\n\nPCA Athletic Figure Competitor 3rd Place 2016\nUKBFF Masters Body Fitness Competitor 1st place 2016\nNinja Warrior UK 2016 Contestant\n\nSelf confessed fitness lover, Busy mum of 2 boys working with a \"zero excuse\" ethic. \n\nI didn't embark on my fitness journey really until I was 38! \n\nYou absolutely cannot go wrong if you do it right.\n\nTrain hard, eat well, rest and repeat!\n\nRemember its not all about the outside that counts, the inside will thank you too.",
          "offers": "1 Session - £30\n4 Sessions - £90\n10 Sessions - £180 \n18 Sessions - £270\n\nCouple Training \n\n1 Session - £45\n4 Sessions - £135 \n10 Sessions - £270\n18 Sessions - £412.50\n\n8 Week Transformation Camps £100!!! \n\nTrain 3 times a week for 1 hour in a group\nOnce a week weigh in!!\n\nVarious times & dates"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/PWBbx2eL-Laura%20reid..jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 62,
        "parent": {
          "$oid": "58d148ec3fa3e80afcab91bf"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516364079975,
      "username": "laura_reid",
      "approved_date": {
        "$date": "2018-01-19T13:11:46.673Z"
      }
    },
    {
      "_id": {
        "$oid": "5a61dad5765eba094646f8b5"
      },
      "email": "jkhadley@hotmail.co.uk",
      "firstName": "Jacob",
      "surname": "Hadley PT",
      "promoCode": "PTEARLY",
      "activation_code": "ef70f45a55e2b721031fa03528ad093841c56e6a",
      "salt": "G7SIapsWIOfCANYNXtg80Q==",
      "hashedPassword": "WFLutj30E+CvfZghQdXv660ceZ/aXFQU2jHwfjKMytH68VmfBTkoq6keg2cluhoeRaUKfaWkkP3S7axr1jKjvw==",
      "lastUpdated": {
        "$date": "2018-01-19T15:01:59.897Z"
      },
      "createdAt": {
        "$date": "2018-01-19T11:47:33.748Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7583133353",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.7553268000000344,
                52.70730289999999
              ],
              "city": "Shrewsbury",
              "postcode": "SY1",
              "_id": {
                "$oid": "5a620867765eba094646f903"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 2: Gym Instructor",
            "Level 2: Group Exercise to Music Instructor",
            "Indoor cycling"
          ],
          "disciplines": [
            "Circuit Training",
            "Functional Training",
            "Martial Arts",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3oqIcrxQ-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": null,
          "facebookPage": "jkhfitness",
          "affiliation": "REPs",
          "yearsOfExp": 2017,
          "biography": "Hi, my name is Jacob and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/IFtaIfam-19401935_10155017600109145_8883430699530873262_o.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 61,
        "parent": {
          "$oid": "58d252cc3fa3e80afcabcaaf"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 6,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516362454200,
      "username": "jacob_hadley_pt",
      "approved_date": {
        "$date": "2018-01-19T15:01:59.868Z"
      }
    },
    {
      "_id": {
        "$oid": "5a61e1d8765eba094646f8c1"
      },
      "email": "garybutlermail@yahoo.co.uk",
      "firstName": "gary",
      "surname": "butler",
      "promoCode": "PTEARLY",
      "activation_code": "c6f6d8dc076a2e9b9ac18eb4e8e7a8581085a3e9",
      "salt": "aRhi0rCA4ZkioD5CO7vumQ==",
      "hashedPassword": "se6qIS1tPQ5O/29WUaprpzfSza5R8dKyOVmOxCJFXyKhm6xThaQV1VKwc6lD+O6qRPho0dWOfKoGjkFG+X+fQg==",
      "lastUpdated": {
        "$date": "2018-01-19T13:14:04.828Z"
      },
      "createdAt": {
        "$date": "2018-01-19T12:17:28.752Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "1355200360",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.176998700000013,
                55.76435240000001
              ],
              "city": "East Kilbride",
              "postcode": "G74 4LT",
              "_id": {
                "$oid": "5a61ef1c765eba094646f8e2"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Nutrition",
            "Strength and conditioning"
          ],
          "disciplines": [
            "Bodybuilding",
            "Core Fitness",
            "Hiit",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Ueu5n3Y9-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2013,
          "offers": "1 Session - £30\n4 Sessions - £90\n10 Sessions - £180 \n18 Sessions - £270\n\nCouple Training \n\n1 Session - £45\n4 Sessions - £135 \n10 Sessions - £270\n18 Sessions - £412.50\n\n8 Week Transformation Camps £100!!! \n\nTrain 3 times a week for 1 hour in a group\nOnce a week weigh in!!\n\nVarious times & dates",
          "biography": "I qualified as a psychiatric nurse in 1994 and have almost two decades experience working in institutions and in the community in the field on mental health, homelessness and addiction. My entire working life has been about improving the quality of peoples lives, bringing families together and allowing people to feel better about themselves.\n\nIf you are considering Personal Training it means that you are serious about your goals and that you are prepared to invesest the time and effort in order to achieve them. I offer you here the opportunity to make a profound difference to your quality of life with the right balance of training and nutrition. Each training and nutrition plan will be based on you as an individual with your goals central to every part of it."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/BZP46za5-Gary.png"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 62,
        "parent": {
          "$oid": "58d148ec3fa3e80afcab91bf"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 12,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516364248970,
      "username": "gary_butler",
      "approved_date": {
        "$date": "2018-01-19T13:14:01.006Z"
      }
    },
    {
      "_id": {
        "$oid": "5a61e482765eba094646f8c6"
      },
      "email": "markmcnicolpt@gmail.com",
      "firstName": "mark",
      "surname": "mcnicol",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "L7iuNFlsWJnYUwmxZxQROA==",
      "hashedPassword": "ZSjbi9ZodVxavIv273m+FbG6ZVX3V2f60fOR2DCvVCJ6gUjgMOnzli9AAgj6BNXycy9KOD2seKuiuxkqzcSvtw==",
      "lastUpdated": {
        "$date": "2018-02-11T11:37:05.916Z"
      },
      "createdAt": {
        "$date": "2018-01-19T12:28:50.100Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "1355200360",
            "code": "+44"
          },
          "locations": [
            {
              "city": "East Kilbride",
              "geolocation": [
                -4.176998700000013,
                55.76435240000001
              ],
              "postcode": "G74 4LT",
              "_id": {
                "$oid": "5a68bb83f913ab12c671c0ce"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer"
          ],
          "disciplines": [
            "Bodybuilding",
            "Bootcamp",
            "Hiit",
            "Nutrition",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/8PBcrtsR-CUT_MACHINES.png"
          },
          "profileTitle": "Personal Trainer",
          "yearsOfExp": 2017,
          "biography": "My name is Mark McNicol and I am a fully qualified R.E.P.S Level 3 Personal Trainer and I extremely passionate about passing on my love for fitness and training to everyone I can. \n\nAfter a year out to travel the world I decided to pursue the career I always wanted. \n\nI want to help others believe they can do more than they can imagine. I want to show you that it is possible to lose weight, build muscle, get into competing, run your 1st marathon, hit PBs, or just be fitter and healthier. Whatever the goal, you can achieve it.\n\nAny training or nutrition inquiries contact:\nmarkmcnicolpt@gmail.com",
          "offers": "1 Session - £30\n4 Sessions - £90\n10 Sessions - £180 \n18 Sessions - £270\n\nCouple Training \n\n1 Session - £45\n4 Sessions - £135 \n10 Sessions - £270\n18 Sessions - £412.50\n\n8 Week Transformation Camps £100!!! \n\nTrain 3 times a week for 1 hour in a group\nOnce a week weigh in!!\n\nVarious times & dates",
          "instagram": {
            "access_token": "930332915.8640130.844f8cc344504c3ea293ee6a0e12658d",
            "link": "https://www.instagram.com/markmcnicol"
          },
          "affiliation": "REPs"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/FmU2p6xb-Mark.jpg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 68,
        "parent": {
          "$oid": "58d148ec3fa3e80afcab91bf"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 55,
      "agent": {
        "family": "Chrome",
        "major": "64",
        "minor": "0",
        "patch": "3282",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.140 Safari/537.36"
      },
      "lastLogin": 1518349025893,
      "username": "mark_mcnicol",
      "password_reset_code": "X",
      "approved_date": {
        "$date": "2018-01-19T13:17:06.053Z"
      }
    },
    {
      "_id": {
        "$oid": "5a61e978765eba094646f8d6"
      },
      "email": "hastieaudrey@hotmail.com",
      "firstName": "Audrey",
      "surname": "Hastie",
      "promoCode": "PTEARLY",
      "activation_code": "495b057686171f6f4f31034391aa351de18edc29",
      "salt": "+vB7u6tzFggbVK8p7GXPpg==",
      "hashedPassword": "iI1c2r+YfjfHdLl6Il8leFTA6aAl+oKyecDTP5kH9V8BjDDP048bOAH1kltkDs3NDnfZ4Afc+sBC10GLK0vv5Q==",
      "lastUpdated": {
        "$date": "2018-01-27T12:00:00.937Z"
      },
      "createdAt": {
        "$date": "2018-01-19T12:50:00.863Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome Mobile",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G935F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.111 Mobile Safari/537.36"
      },
      "lastLogin": 1516366201255
    },
    {
      "_id": {
        "$oid": "5a61ef6e765eba094646f8e3"
      },
      "providerId": "260367206",
      "surname": "Rees",
      "firstName": "Tom",
      "email": "tom_rees_7@hotmail.co.uk",
      "username": "tom_rees",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-19T13:45:23.726Z"
      },
      "createdAt": {
        "$date": "2018-01-19T13:15:26.256Z"
      },
      "settings": {
        "personal": {
          "instagram": {
            "access_token": "260367206.8640130.d23950a820744ee5b9d7526e462b8755",
            "link": "https://www.instagram.com/tomrees7",
            "username": "tomrees7"
          },
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7535153567",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.997663999999986,
                51.58415099999999
              ],
              "city": "Newport",
              "postcode": "NP20 4DW",
              "_id": {
                "$oid": "5a61f673765eba094646f8ea"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 3: Nutrition"
          ],
          "disciplines": [
            "Bodybuilding",
            "Body Sculpting",
            "Circuit Training",
            "Hiit",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/3GEEj6pk-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "The training room",
          "yearsOfExp": 2012,
          "offers": "*All sessions to be used within 30 days of purchase*\n\n1 session-£30\n4 sessions-£90\n10 sessions-£180",
          "biography": "I’ve always been involved in exercise  since a very early age through multiple sports and activities but my fitness journey wouldn’t start until 2010 when I suffered a compound fracture of my left leg. Unable to play sport I joined a gym for rehabilitation purposes, I suffered very much with my body image unable to play sport...this is wear I started weight training to build myself up again. Finally feeling comfortable in my own skin and with my fitness levels back I began my personal training qualification. 6 months later I qualified in my level 3 personal training and advanced nutrition course and began running boot camps along side a full time job. After a few years I quit my job to concentrate solely on the job I was most passionate about, personal training. I thrive in being able to help people overcome the body image issues I too once suffered and in turn improving the quality of life of my clients. I am extremely passionate about what I do and consider myself very lucky to be able to go to a job I love day I’m and day out."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Xzr3yFaL-54DDB0D2-EB13-436E-9502-AC630CDDECC3.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 66,
        "parent": {
          "$oid": "58d23e083fa3e80afcabc621"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "instagram",
      "active": true,
      "role": "trainer",
      "__v": 14,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_2 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C202 Safari/604.1"
      },
      "lastLogin": 1516369444438,
      "approved_date": {
        "$date": "2018-01-19T13:45:23.673Z"
      }
    },
    {
      "_id": {
        "$oid": "5a621020765eba094646f910"
      },
      "providerId": "2014459802131735",
      "surname": "Connelly",
      "firstName": "Mathew",
      "email": "m.s.c.getfit@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-01-20T15:27:10.965Z"
      },
      "createdAt": {
        "$date": "2018-01-19T15:34:56.360Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/2014459802131735/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7757743362",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.128820000000019,
                52.586973
              ],
              "city": "Saint Peters Square",
              "postcode": "WV1 9SE",
              "_id": {
                "$oid": "5a635f7c765eba094646f984"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR"
          ],
          "disciplines": [
            "Functional Training",
            "Hiit",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/GbqUTkVl-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "6089233715.8640130.6fea7ce4d24a420c8f73bc334264f52f",
            "link": "https://www.instagram.com/matt.connelly.pt"
          },
          "affiliation": "Reps",
          "yearsOfExp": 2015,
          "biography": "Hi, my name is Matthew and I am dedicated to help you reach your health & fitness goals.",
          "offers": "X4L Monthly PT Prices (30days to use)\n×1 session £30 / £45 couple session\n×4 sessions £90 / £135 couple session\n×10 sessions £180 / £270 couple session\n×18 sessions £270 / £405 couple session\n\nM.S.C.getfit PT Prices\n£30×1 session\n£28×4 sessions\n£26×8 sessions\n£24×12 sessions\n\nM.S.C.getfit 30min HIT sessions\n£20×1 session\n£18×4 sessions\n\nM.S.C.getfit group PT Prices (2-6 participants)\n×1 session      £15\n×2 sessions    £12.50/£25\n×3 sessions    £10/£30"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/aS0pjp48-filename.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 69,
        "parent": {
          "$oid": "58d26f043fa3e80afcabd0cd"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 14,
      "username": "mathew_connelly",
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; SAMSUNG SM-J500FN Build/MMB29M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1516396342656,
      "approved_date": {
        "$date": "2018-01-19T17:13:16.840Z"
      }
    },
    {
      "_id": {
        "$oid": "5a6326dd765eba094646f977"
      },
      "email": "g.lowther21@gmail.com",
      "firstName": "Glynn",
      "surname": "Lowther",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "D1dEv/qTYPml3C7/EsJWdw==",
      "hashedPassword": "WvOhO0UOh+o67CQmAYdrP9p6vjqv6+IfbRovnnE9XUX2tyiy/17tfoLD4TImkH0HrnxajWWTuNgom8VGSOEkeg==",
      "lastUpdated": {
        "$date": "2018-01-21T00:18:06.358Z"
      },
      "createdAt": {
        "$date": "2018-01-20T11:24:13.387Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "1302246363",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.5794327999999496,
                53.8046254
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "5a63dc3e765eba094646f98d"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR"
          ],
          "disciplines": [
            "Boxing",
            "Functional Training",
            "Strength & Conditioning",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/mIedaQZa-16722823_10154971913412008_7880500965614325729_o.jpg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPS",
          "yearsOfExp": 2017,
          "offers": "Free consultation \nNutritional support\nOffers available on block sessions",
          "biography": "More than anything I want to help people who are struggling with losing weight, building muscle\nand general health fitness goals, no matter what age group you are in and level of fitness you\nare."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/av2Rop7x-08a39614297488bf42039c29b81b40abfebd.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 62,
        "parent": {
          "$oid": "58d10e8b3fa3e80afcab852a"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 12,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516447453595,
      "username": "glynn_lowther",
      "approved_date": {
        "$date": "2018-01-21T00:18:06.300Z"
      }
    },
    {
      "_id": {
        "$oid": "5a632b3d765eba094646f97d"
      },
      "email": "jmattyl60@gmail.com",
      "firstName": "Matt",
      "surname": "Lees",
      "promoCode": "PTEARLY",
      "activation_code": "e1e6ee7536c23eb6cdb4966d773d4818e4681154",
      "salt": "amYkHhCUnbJBYxYpyEEQ5w==",
      "hashedPassword": "JC3JDvFe/VJJauC6YmBL43+CQeIsBVq2uA1JOvI53W+pxa8hpqPamAdgPKVtO5Qoda8sIWBj4RpbKWeH5se4Lw==",
      "lastUpdated": {
        "$date": "2018-01-21T00:25:48.695Z"
      },
      "createdAt": {
        "$date": "2018-01-20T11:42:53.546Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "1302246363",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -1.5794327999999496,
                53.8046254
              ],
              "city": "Leeds",
              "postcode": "LS4",
              "_id": {
                "$oid": "5a63de0c765eba094646f98e"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: First Aid / CPR"
          ],
          "disciplines": [
            "Bodybuilding",
            "Boxing",
            "Group Classes",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/oJyxaNi9-16722823_10154971913412008_7880500965614325729_o.jpg"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPS",
          "yearsOfExp": 2015,
          "offers": "Free consultation \nNutritional support\nOffers available on block sessions",
          "biography": "I have a passion for fitness that means every session is fun and informative - giving you the tools to achieve your fitness goals.\nSessions are structured to suit your fitness levels and push you to achieve."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/ATKeOKxk-Matt.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 62,
        "parent": {
          "$oid": "58d10e8b3fa3e80afcab852a"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 11,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516448573776,
      "username": "matt_lees",
      "approved_date": {
        "$date": "2018-01-21T00:25:48.638Z"
      }
    },
    {
      "_id": {
        "$oid": "5a660cc25f0b475e1c9b1d19"
      },
      "email": "alex_caldwell3@btinternet.com",
      "firstName": "Alex",
      "surname": "Caldwell",
      "promoCode": "PTEARLY",
      "activation_code": "7a15917412b86d4eb21f8e3b0769e051594d1fca",
      "salt": "KV5d4Nza6Yw3WbV8NHw8Lg==",
      "hashedPassword": "z/Ge0lf1XUNsZb4sXMQmxQFL59mnvw/u7qFulpwv+3f5kGeoV0Z7T2yWQN6YYxglv+Y7ghrJURK4H3ifpcOSmw==",
      "lastUpdated": {
        "$date": "2018-01-23T06:19:44.495Z"
      },
      "createdAt": {
        "$date": "2018-01-22T16:09:38.330Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7950737871",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.40124454872381,
                55.88216254893155
              ],
              "city": "Renfrew",
              "postcode": "PA4 9EB",
              "_id": {
                "$oid": "5a66d400abf3352030e9da30"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3 Personal Trainer",
            "HND Fitness",
            "Health and Exercise",
            "Level 2 Gym Instructor",
            "Exercise To Music"
          ],
          "disciplines": [
            "Bodybuilding",
            "Circuit Training",
            "Nutrition",
            "Strength & Conditioning",
            "Weight Loss"
          ],
          "price": {
            "min": 22
          },
          "radius": 10,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/X6ORyiiK-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REP’s",
          "yearsOfExp": 2017,
          "biography": "Hi, my name is Alex and I am dedicated to help you reach your health & fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/cjaJQBG7-02318946-FAE1-49D4-B683-BF82B9535AA1.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 58,
        "parent": {
          "$oid": "58d248043fa3e80afcabc867"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 10,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_1_1 like Mac OS X) AppleWebKit/604.3.5 (KHTML, like Gecko) Version/11.0 Mobile/15B150 Safari/604.1"
      },
      "lastLogin": 1516663443955,
      "username": "alex_caldwell",
      "approved_date": {
        "$date": "2018-01-23T06:19:44.436Z"
      }
    },
    {
      "_id": {
        "$oid": "5a675a1cf913ab12c671c09f"
      },
      "email": "r.whiteford@hotmail.co.uk",
      "firstName": "Rebecca",
      "surname": "Whiteford",
      "promoCode": "PTEARLY",
      "activation_code": "20bf2188bf9369bdff5773b0741632c0e0a58e55",
      "salt": "aobv5CS2ONNbauwy18M81w==",
      "hashedPassword": "MSjyT36M2o0pTSxHGtGSACl7Ybmu5+Sg4e5fuOhNFNCDxWwptW6cySxzbbo3vY9s6QM2zk/AfjyCjXpZQMNhlw==",
      "lastUpdated": {
        "$date": "2018-01-23T16:13:55.859Z"
      },
      "createdAt": {
        "$date": "2018-01-23T15:51:56.681Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7384672667",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -3.5330348999999615,
                55.8809187
              ],
              "city": "Livingston",
              "postcode": "EH54 7DE",
              "_id": {
                "$oid": "5a675f43f913ab12c671c0a9"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Core Conditioning",
            "Exercise to Music"
          ],
          "disciplines": [
            "Bootcamp",
            "Core Fitness",
            "Functional Training",
            "Nutrition",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/T7FeLwUz-4FiiT%20Logo.png"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPS",
          "yearsOfExp": 2017,
          "offers": "INDIVIDUALS\n1 Session - £30\n4 Sessions - £90\n10 Sessions - £180\n18 Sessions - £270\n\n2 OR MORE IN A GROUP\n1 Session - £45\n4 Sessions - £135\n10 Sessions - £270",
          "strapLine1": "Let me help you reach your goals!!",
          "biography": "I am a new PT to the X4L Livingston club.\nI thrive on helping clients achieve their goals and helping them in all areas of their lifestyle.\nWhy not book a consultation and see how I can help you."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/JD3oR9tX-image2.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 71,
        "parent": {
          "$oid": "58d175bf3fa3e80afcab9b57"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 15,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516722716902,
      "username": "rebecca_whiteford",
      "approved_date": {
        "$date": "2018-01-23T16:13:55.822Z"
      }
    },
    {
      "_id": {
        "$oid": "5a675e54f913ab12c671c0a6"
      },
      "email": "williamhardie@me.com",
      "firstName": "William",
      "surname": "Hardie",
      "promoCode": "PTEARLY",
      "activation_code": "6430a12b2d6a3935f587c874e32bbec6de111228",
      "salt": "tQvUTe48lEkHk8P/F4JtGA==",
      "hashedPassword": "cUMDYNFqkC1RuZC89bUs502Te84XsyyawQyQ0jzns40HFrV6sA5ZwEMFTLyLg/qOsIHQd4VUkqkcXatidG4g1Q==",
      "lastUpdated": {
        "$date": "2018-01-23T16:19:06.177Z"
      },
      "createdAt": {
        "$date": "2018-01-23T16:09:56.051Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7805516873",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -3.5330348999999615,
                55.8809187
              ],
              "city": "Livingston",
              "postcode": "EH54 7DE",
              "_id": {
                "$oid": "5a67607af913ab12c671c0aa"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Boxing Instructor",
            "Boot Camps"
          ],
          "disciplines": [
            "Bootcamp",
            "Boxing",
            "Circuit Training",
            "Functional Training",
            "Strength & Conditioning"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/z1QHWqOC-4FiiT%20Logo.png"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPS",
          "yearsOfExp": 2016,
          "offers": "Individuals \n1 Session - £30\n4 Sessions - £90\n10 Sessions - £180\n18 Sessions - £270\n\n2 or more Group PT\n1 Sessions - £45\n4 Sessions - £135\n10 Sessions - £270",
          "biography": "I am a new PT to the X4L Livingston club.\nHave a huge passion for Boxing and like to incorporate into sessions with my clients.\nI am highly motivated, passionate and have fun doing what I do.\nWhy not book a consultation with me and see what I have to offer."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/vHxUenPd-image1.jpeg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 64,
        "parent": {
          "$oid": "58d175bf3fa3e80afcab9b57"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 11,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1516723796274,
      "username": "william_hardie",
      "approved_date": {
        "$date": "2018-01-23T16:19:06.120Z"
      }
    },
    {
      "_id": {
        "$oid": "5a73258df913ab12c671c156"
      },
      "email": "info@wizleesfitness.co.uk",
      "firstName": "Wiz",
      "surname": "Lees",
      "promoCode": "PTEARLY",
      "activation_code": "3df6d395243e200268b4be3fa67054e04703c86e",
      "salt": "U1/6xVhlYXz/p9yVo9DB9Q==",
      "hashedPassword": "VudN3/TkBHss0ITkhMUk9e9vgZZGJ9OmrXjJ1vCgSVnEnJ+A4TEZd1uNkmbUZbiOa1QkP4TP/UfJCmkmVGehFA==",
      "lastUpdated": {
        "$date": "2018-02-01T15:14:45.717Z"
      },
      "createdAt": {
        "$date": "2018-02-01T14:34:53.185Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7909531769",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -2.67808100000002,
                54.531661
              ],
              "city": "Shap",
              "postcode": "CA10 3NG",
              "_id": {
                "$oid": "5a732ee5f913ab12c671c165"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Exercise Music & Dance Partnership(EMDP) Teacher",
            "Beachbody Coach"
          ],
          "disciplines": [
            "Circuit Training",
            "Group Classes",
            "Hiit",
            "Online Coaching",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 20,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DMQd7iUM-filename.jpeg"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "5578736648.8640130.e5d0bdc45e1c44eeb3aa6034c563ab4f",
            "link": "https://www.instagram.com/wizleesfitness"
          },
          "affiliation": "REPs",
          "yearsOfExp": 2015,
          "facebook": null,
          "biography": "I’m a Fitness Instructor, Personal Trainer and Online Beachbody Coach. No matter where you\nlive with online coaching I can help with fitness and nutrition with daily support. Or if you live nearby I have 6 classes a week and Personal Training sessions available.",
          "strapLine1": "With online training and face to face I can help you where ever you live.",
          "offers": "https://mysite.coach.teambeachbody.com?coachId=1528360&locale=en_GB"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Qg3jgARO-DDC706BE-6A14-4A4B-9390-8D825739D8F0.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 74
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 24,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_2 like Mac OS X) AppleWebKit/604.4.7 (KHTML, like Gecko) Version/11.0 Mobile/15C202 Safari/604.1"
      },
      "lastLogin": 1517496038950,
      "username": "wiz_lees",
      "approved_date": {
        "$date": "2018-02-01T15:14:45.683Z"
      }
    },
    {
      "_id": {
        "$oid": "5a732865f913ab12c671c160"
      },
      "email": "bumpsmumstums@gmail.com",
      "firstName": "Louise",
      "surname": "MacGregor",
      "promoCode": "PTEARLY",
      "activation_code": "76dc920e74fc3820b6d6712dfb4fad3350cf1d68",
      "salt": "cf9dzEc4q4LZ9rKakPJPkQ==",
      "hashedPassword": "lNnAUHwh7YYnCt2/MY2BxQAmU68kaA+uatDQW/U+55UwU/sGpKJv9Vie0tIdV4Rg1EiVlS711l6pY+WurpegJQ==",
      "lastUpdated": {
        "$date": "2018-02-09T12:00:01.974Z"
      },
      "createdAt": {
        "$date": "2018-02-01T14:47:01.894Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7740105265",
            "code": "+44"
          },
          "locations": [
            {
              "city": "Bramhall",
              "geolocation": [
                -2.160875364062921,
                53.35585024345327
              ],
              "postcode": "SK7 1LJ",
              "_id": {
                "$oid": "5a7328a7f913ab12c671c161"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 30
          },
          "radius": 0
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 22
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Mobile Safari UI/WKWebView",
        "major": "11",
        "minor": "2",
        "patch": "5",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_5 like Mac OS X) AppleWebKit/604.5.6 (KHTML, like Gecko) Mobile/15D60"
      },
      "lastLogin": 1517496422155,
      "username": "louise_macgregor"
    },
    {
      "_id": {
        "$oid": "5a762dd5f913ab12c671c171"
      },
      "providerId": "10215691289463237",
      "surname": "Carena",
      "firstName": "Luca",
      "email": "lucaacarena@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-02-11T12:00:01.714Z"
      },
      "createdAt": {
        "$date": "2018-02-03T21:47:01.475Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10215691289463237/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/8r1RfSDi_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "luca_carena",
      "agent": {
        "family": "Samsung Internet",
        "major": "6",
        "minor": "2",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-N920V Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/6.2 Chrome/56.0.2924.87 Mobile Safari/537.36"
      },
      "lastLogin": 1517694422208
    },
    {
      "_id": {
        "$oid": "5a766f3bf913ab12c671c173"
      },
      "email": "godwinmordi2001@yahoo.com",
      "firstName": "Godwin",
      "surname": "Mordi",
      "promoCode": "PTEARLY",
      "activation_code": "a3a0092748a0bb7a27f3bedc18ad2c0d8f76ae4f",
      "salt": "1fJAcATWyMi/JiUMghMt1A==",
      "hashedPassword": "CoSBEMNdGtG2AVPsTl18WKmaDIvegnJojZmevTMO4om8n9vco2YFBb7PqGuGcdIqyFYzO5GpO9oyE1YBlZq9UA==",
      "lastUpdated": {
        "$date": "2018-02-11T12:00:01.745Z"
      },
      "createdAt": {
        "$date": "2018-02-04T02:26:03.121Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari UI/WKWebView",
        "major": "11",
        "minor": "2",
        "patch": "5",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_5 like Mac OS X) AppleWebKit/604.5.6 (KHTML, like Gecko) Mobile/15D60"
      },
      "lastLogin": 1517711163527
    },
    {
      "_id": {
        "$oid": "5a79ce4bf3d6ea3af77dc3ea"
      },
      "email": "helen-chowaniec@hotmail.co.uk",
      "firstName": "Helen",
      "surname": "Chowaniec",
      "promoCode": "PTEARLY",
      "activation_code": "dfc37860d311be93449848dc928e7bd90d7533f0",
      "salt": "AMfMItsgPtcyWwmByHwrNw==",
      "hashedPassword": "KgC42EJyoMvK/wELyOcj1rLTgDOnnSLZdKrlHlAZqEAuYm2R3jHvtqR1NcapjOszCbnXUtZh5D6r8g4Nc0zatw==",
      "lastUpdated": {
        "$date": "2018-02-07T07:39:17.823Z"
      },
      "createdAt": {
        "$date": "2018-02-06T15:48:27.653Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7821878190",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -3.7839131000000634,
                56.00187750000001
              ],
              "city": "Falkirk",
              "postcode": "FK1",
              "_id": {
                "$oid": "5a7aad25f3d6ea3af77dc3fb"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 3: Exercise Referral Instructor",
            "Level 2: Gym Instructor"
          ],
          "disciplines": [
            "Endurance",
            "Functional Training",
            "Pre/post Natal",
            "Rehabilitation",
            "Weight Loss"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/DyNHmvmz-x4lessbg4fit.JPG"
          },
          "companyName": "Xercise4Less",
          "companyWebsite": "https://www.xercise4less.co.uk/find-a-gym/falkirk-gym/",
          "profileTitle": "Personal Trainer",
          "affiliation": "REPs",
          "yearsOfExp": 2015,
          "biography": "I have been a personal trainer and class instructor in the fitness industry for over two and a half years. I teach classes on a weekly basis and work with a variety of clients from all walks of life and with many different fitness goals and I am dedicated to help you acheive your goals.\n\nI can help you succeed with goals ranging from weight loss and training for a long distance event to ante and post natal to clients who have long term health conditions. \n\nSpecialties: Gym instructing, bodyweight exercises, ante & post natal, exercise referral (rehab), weight loss and training for long distance events.",
          "strapLine1": "Always Working Hard For You"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/hpek0FHn-images%20(1).jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 69,
        "parent": {
          "$oid": "58d146053fa3e80afcab913d"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 34,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1517983816144,
      "username": "helen_chowaniec",
      "approved_date": {
        "$date": "2018-02-07T05:26:24.540Z"
      }
    },
    {
      "_id": {
        "$oid": "5a8169f9f3d6ea3af77dc41c"
      },
      "providerId": "108583133509725744457",
      "surname": "Cullen",
      "firstName": "Jordan",
      "email": "jordanjacullen@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-02-12T11:01:30.848Z"
      },
      "createdAt": {
        "$date": "2018-02-12T10:18:33.710Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/108583133509725744457",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7568081921",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -4.4151967999999995,
                55.8428646
              ],
              "city": "Paisley",
              "postcode": "PA1 1JT",
              "_id": {
                "$oid": "5a81740af3d6ea3af77dc421"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Personal Trainer",
            "Level 2: Gym Instructor",
            "Level 2: First Aid / CPR",
            "HNC Fitness, Health and Exercise",
            "Human Physiology in the Development of Performance"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Functional Training",
            "Group Classes"
          ],
          "price": {
            "min": 30
          },
          "radius": 0,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/l5cntjT1-x4lessbg4fit.JPG"
          },
          "profileTitle": "Personal Trainer",
          "affiliation": "REPs",
          "yearsOfExp": 2016,
          "biography": "Hello everyone, My name is Jordan, I am a Personal Trainer at Xercise4Less in Renfrew. Before coming to Xercise4Less I was the Personal Trainer tasked with the classes at Airspace, East Kilbride. This was great experience as it developed my ability to adapt activities to the area, and equipment I have available. I have a Level 3 in Personal Training which I achieved in 2016, I also have a qualification in Nutrition which I achieved as part of my HNC in Fitness, Health and Exercise. Nutrition is an underrated knowledge, I feel all clients could benefit from a basic knowledge, this is something I like to en still in my clients. I also have my First Aid Certificate and PVG which will give anyone who would like to train or would like to book training for someone else peace of mind. If you would like a Personal Trainer for a sport based goal I would love to help you reach that goal using my qualification in Human Physiology in the Development of Performance.\n\nIf you think i could be the trainer for you book a consultation now. I am going to make 2018 the year to make peoples goals and dreams a REALITY!",
          "offers": "Right now at Xercise4Less Renfrew:\n\n1 Session Per Week £22           Saving £8\n2 Sessions Per Week £38           Saving £22\n3 Sessions Per Week £48           Saving £42\n\nWe also have our Transformation Camps on sale from the original price of £240 to now only £100\n\nAlso remember when booking to use PT Code 6603"
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/uMSciQL6-eaab81872363f1d6cb338fddf75d5972d26a.jpg"
        },
        "inherit_from_parent": true,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 68,
        "parent": {
          "$oid": "58d248043fa3e80afcabc867"
        }
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 15,
      "username": "jordan_cullen",
      "agent": {
        "family": "Chrome",
        "major": "55",
        "minor": "0",
        "patch": "2883",
        "source": "Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/55.0.2883.87 Safari/537.36"
      },
      "lastLogin": 1518430714084,
      "approved_date": {
        "$date": "2018-02-12T11:01:30.783Z"
      }
    },
    {
      "_id": {
        "$oid": "5a819534f3d6ea3af77dc423"
      },
      "providerId": "1680776938646730",
      "surname": "Aslam",
      "firstName": "Shaikh",
      "email": "stuffboyaslam@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-02-20T12:00:01.002Z"
      },
      "createdAt": {
        "$date": "2018-02-12T13:23:00.902Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/1680776938646730/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/G4RPMVAW_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "shaikh_aslam",
      "agent": {
        "family": "Chrome Mobile",
        "major": "64",
        "minor": "0",
        "patch": "3282",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; Redmi 3S Build/MMB29M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.137 Mobile Safari/537.36"
      },
      "lastLogin": 1518441781901
    },
    {
      "_id": {
        "$oid": "5a96250df3d6ea3af77dc454"
      },
      "providerId": "10208940354385864",
      "surname": "Agawi",
      "firstName": "Steve",
      "email": "happyswah123@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-03-07T12:00:01.710Z"
      },
      "createdAt": {
        "$date": "2018-02-28T03:42:06.024Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/10208940354385864/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/MsEpiJYC_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "steve_agawi",
      "agent": {
        "family": "Chrome Mobile",
        "major": "64",
        "minor": "0",
        "patch": "3282",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-J730F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.137 Mobile Safari/537.36"
      },
      "lastLogin": 1519789326675
    },
    {
      "_id": {
        "$oid": "5a9e6014f3d6ea3af77dc468"
      },
      "email": "hayleyeyre@yahoo.co.uk",
      "firstName": "Hayley",
      "surname": "eyre",
      "promoCode": "PTEARLY",
      "activation_code": "dc230e7366c2007ff104c43c133843b829e63fe8",
      "salt": "pGTxEInQpr5MHQ+uhACKmA==",
      "hashedPassword": "sfMcv0olPNjcnIlA1tFKuZ7VKNmXWMeG//RMPE+D2jzNrjliuv934UKJmLL0ZAK/BHuoDCjDjwh7JNESqOuhEA==",
      "lastUpdated": {
        "$date": "2018-03-15T12:00:01.323Z"
      },
      "createdAt": {
        "$date": "2018-03-06T09:32:04.539Z"
      },
      "settings": {
        "personal": {
          "gender": "female",
          "timezone": "Europe/London",
          "phone": {
            "number": "7557417849",
            "code": "+44"
          },
          "locations": [
            {
              "city": "Leeds",
              "geolocation": [
                -1.5794327999999496,
                53.8046254
              ],
              "postcode": "LS4",
              "_id": {
                "$oid": "5a9e6059f3d6ea3af77dc469"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [],
          "disciplines": [],
          "price": {
            "min": 30
          },
          "radius": 0
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": true
        },
        "score": 22
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 3,
      "agent": {
        "family": "Chrome",
        "major": "63",
        "minor": "0",
        "patch": "3239",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36"
      },
      "lastLogin": 1520328724788,
      "username": "hayley_eyre"
    },
    {
      "_id": {
        "$oid": "5aa9762f6c3e4f0956cda2c6"
      },
      "email": "misshannahcanning@gmail.com",
      "firstName": "Hannah",
      "surname": "Canning",
      "promoCode": "PTEARLY",
      "activation_code": "1fc0d3e6478a7b1536b76c521352320022f6c282",
      "salt": "c+ZEUl8F27U8pbYO8xjX6g==",
      "hashedPassword": "y1weXNdjVBDAps49OFqTyDq4wK8M781FYKfSTXqkYXJ9eeqF8WLuCpqnTtpNjXwkwamT8ZW3Tmvwo3iWToVvqA==",
      "lastUpdated": {
        "$date": "2018-03-22T12:00:02.189Z"
      },
      "createdAt": {
        "$date": "2018-03-14T19:21:19.682Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari UI/WKWebView",
        "major": "11",
        "minor": "2",
        "patch": "6",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_6 like Mac OS X) AppleWebKit/604.5.6 (KHTML, like Gecko) Mobile/15D100 Instagram 36.0.0.7.96 (iPhone6,2; iOS 11_2_6; en_GB; en-GB; scale=2.00; gamut=normal; 640x1136)"
      },
      "lastLogin": 1521055279923
    },
    {
      "_id": {
        "$oid": "5abaa4c56c3e4f0956cda2cb"
      },
      "email": "louis@gymbusy.com",
      "firstName": "Louis",
      "surname": "hollingsworth",
      "promoCode": "PTEARLY",
      "activation_code": "261090f6dff1540ce4d7d0e639ba0fb4ac71884f",
      "salt": "fEtBKuvTKjduuHleD0YKEw==",
      "hashedPassword": "anydRuXn3SsmdQgYkMTmjArCvPiO/EkyVWp0bYkLxVnE3r5aWARGV66SkaD5RTCFmHKVW8ykdw1t7oA8KYvLFg==",
      "lastUpdated": {
        "$date": "2018-03-31T15:20:09.474Z"
      },
      "createdAt": {
        "$date": "2018-03-27T20:08:37.315Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "7743504732",
            "code": "+44"
          },
          "locations": [
            {
              "geolocation": [
                -0.07854150000002846,
                51.4198825
              ],
              "city": "London",
              "postcode": "SE19",
              "_id": {
                "$oid": "5abaabe86c3e4f0956cda2cd"
              },
              "country": "United Kingdom",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 3: Advanced Personal Trainer",
            "Level 2: Gym Instructor",
            "Fa level one"
          ],
          "disciplines": [
            "Bootcamp",
            "Circuit Training",
            "Mobile Trainer",
            "Toning",
            "Weight Loss"
          ],
          "price": {
            "min": 20
          },
          "radius": 5,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/67mKsHki-IMG_0682.JPG"
          },
          "profileTitle": "Personal Trainer",
          "instagram": {
            "access_token": "200544090.8640130.ed624a0b3c564e92825f4057a74f55bb",
            "link": "https://www.instagram.com/louis1touch"
          },
          "offers": "Pay for 5 sessions and extra one Free\n £100 \n\nPay for 10 session  and get two extra sessions FREE and a FREE Gymbusy Water bottle \n£200",
          "biography": "Ex footballer who is highly motivated with a real passion for health and fitness . \"I love to see people getting the best results from my sessions.\" Your journeys starts with self belief once you start you will see sublime action ."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/Y0O9vMIF-IMG_0796.JPG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "start": true,
          "additional": true,
          "social": true,
          "qualifications": true,
          "complete": true
        },
        "score": 52
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": true,
      "activation_email_sent": true,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 11,
      "agent": {
        "family": "Mobile Safari",
        "major": "10",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 10_2 like Mac OS X) AppleWebKit/602.3.12 (KHTML, like Gecko) Version/10.0 Mobile/14C92 Safari/602.1"
      },
      "lastLogin": 1522181317589,
      "username": "louis_hollingsworth",
      "approved_date": {
        "$date": "2018-03-31T15:20:09.420Z"
      }
    },
    {
      "_id": {
        "$oid": "5abd4d11a891fb09471e1256"
      },
      "email": "drummerdude_3005@hotmail.co.uk",
      "firstName": "George",
      "surname": "Hutchinson",
      "promoCode": "PTEARLY",
      "activation_code": "9bf2c9d64be5537dd69f567186319caca7b95e52",
      "salt": "6vuIQ6NScLw0RYJDnW6wnQ==",
      "hashedPassword": "iBkCKUA8WOoXGWty9C3Bu70zjEaBaB6wvFhH451s33nAM7T78OwiBBnRPsUnf3GwGb+3A40BwOodfPM/IxaGhA==",
      "lastUpdated": {
        "$date": "2018-04-06T12:00:01.211Z"
      },
      "createdAt": {
        "$date": "2018-03-29T20:31:13.717Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_6 like Mac OS X) AppleWebKit/604.5.6 (KHTML, like Gecko) Version/11.0 Mobile/15D100 Safari/604.1"
      },
      "lastLogin": 1522355473993
    },
    {
      "_id": {
        "$oid": "5abd5256a891fb09471e1258"
      },
      "email": "alex.peel@m-gi.co.uk",
      "firstName": "Alex",
      "surname": "Peel",
      "promoCode": "PTEARLY",
      "activation_code": "f8d5191d001631ee96fc0b43a9d993e5e326f072",
      "salt": "ncqY+/isWSFa8oid10nFqQ==",
      "hashedPassword": "HJ8O8YUipmXjNsGo2UFTpm1OyPvla4rKU4k/FsrVr9p5WEUiX3SeTR9n1wy8zQuJbQHjoVGHsIS+U1cmTQISwA==",
      "lastUpdated": {
        "$date": "2018-04-06T12:00:04.069Z"
      },
      "createdAt": {
        "$date": "2018-03-29T20:53:42.955Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Mobile Safari",
        "major": "11",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_6 like Mac OS X) AppleWebKit/604.5.6 (KHTML, like Gecko) Version/11.0 Mobile/15D100 Safari/604.1"
      },
      "lastLogin": 1522356823174
    },
    {
      "_id": {
        "$oid": "5b728684bf18d20974b3dbfb"
      },
      "providerId": "110389608024588186934",
      "surname": "Matters Chandigarh",
      "firstName": "Fitness",
      "email": "fitnessmatters8@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-08-21T12:00:02.217Z"
      },
      "createdAt": {
        "$date": "2018-08-14T07:36:36.710Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/110389608024588186934",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/XJuEdXJM_profilePicture.png"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "fitness_matters_chandigarh",
      "agent": {
        "family": "Chrome",
        "major": "68",
        "minor": "0",
        "patch": "3440",
        "source": "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/68.0.3440.106 Safari/537.36"
      },
      "lastLogin": 1534232197470
    },
    {
      "_id": {
        "$oid": "5b97e4f9bf18d20974b3dc08"
      },
      "email": "amirabraham.bookings@gmail.com",
      "firstName": "Amir Abraham",
      "surname": "coachamir",
      "promoCode": "PTEARLY",
      "activation_code": "X",
      "salt": "vXm35uKpnbpK+8/pWM51bg==",
      "hashedPassword": "bJBNDxz1P2jVzgiG0SvsgDKLlvw6Ks1rTxnAqA6vC07viadMFhBG5V+5ziFGYmdSohBP/5oBES0PEaa/tFeE3g==",
      "lastUpdated": {
        "$date": "2018-09-11T16:10:11.661Z"
      },
      "createdAt": {
        "$date": "2018-09-11T15:53:29.030Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "number": "1112380567",
            "code": "+212"
          },
          "locations": [
            {
              "geolocation": [
                101.65593490000003,
                2.921318
              ],
              "city": "Cyberjaya",
              "postcode": "63000",
              "_id": {
                "$oid": "5b97e767bf18d20974b3dc0b"
              },
              "country": "Malaysia",
              "primary": true
            }
          ],
          "qualifications": [
            "Level 2: Group Exercise to Music Instructor",
            "Level 2: Physical Activity for Children Instructor",
            "Level 4: Strength & Conditioning",
            "Level 3: Nutrition",
            "Level 2: First Aid / CPR",
            "Level 3: Ante & Post Natal",
            "Level 3: Advanced Personal Trainer"
          ],
          "disciplines": [
            "Bodybuilding",
            "Functional Training",
            "Group Classes",
            "Nutrition",
            "Weight Loss"
          ],
          "price": {
            "min": 20
          },
          "radius": 15,
          "background": {
            "src": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/7nfAf2AB-IMG_2574.JPG"
          },
          "profileTitle": "Personal Trainer",
          "facebook": "https://www.facebook.com/app_scoped_user_id/YXNpZADpBWEVBeXQ2Q050MnZAxTVpBblJaRWRRSGt6MW1CcVM2Q3V1eFZASbkgtQUZAfaVVLaDFuMk5jNC1sZA3NRV1hqcEdfeTZAQYTBCaF9KMnNaVWlHRnZAtVDdOelVNdjRYSTJBSC02U002Y2pWZADB2UXFKYmVTS0EZD/",
          "facebookPage": "amirabraham",
          "instagram": {
            "access_token": "386880398.8640130.d0fee8947c954a5bb6b167e23fe81a96",
            "link": "https://www.instagram.com/imamirabraham"
          },
          "twitter": {
            "accessToken": "1020561648644313088-RY8rS4q9eLOOn2zc4nWb1tvrc2TE1B",
            "accessTokenSecret": "GTcWP7endhsqEMoQin4d5DKdfQv9myjiq1rFnZwn0ARU3",
            "link": "https://twitter.com/amirabraham10",
            "screen_name": "amirabraham10",
            "user_id": "1020561648644313088"
          },
          "yearsOfExp": 2012,
          "offers": "check my website \nwww.coachamirabraham.com/",
          "biography": "I have been involved in the health and fitness industry for nearly 14 years, I am qualified ACE/PTDP,CYQ Level 2 Group Fitness Instructor, and an O2/Emergency First Aid Response interactor. All our fitness instructors are qualified and experienced. We will clearly illustrate how best to feed and exercise your body type for great results, results you will see and feel results that your friends and family will notice and respect.\n\nWe dedicate working days to our clients, offering exceptional fitness coaching to anyone willing to try. Despite being inundated with health related products, new reports, gyms and fancy equipment people are gaining fat weight like never before, even our children are suffering serious weight ‘issues’ that defies belief the average person today is physically unhealthier than their counterpart was ten or twenty years ago.\n\nOur programs are based on the requirement for our clients and are not ‘Diet ‘\n\nwhich arereally short term solutions to bigger problems nor are they ‘starvation regimes’, we will not ask you to limit all food intake while you painfully reach your desired weight. Our programs are fast, effective and will provide maximum results in minimal amount of time. We also provide the magical link the jump start, the push, the encouragement, the reason calls it what you will the all-important spark the ignites you, gets you started and keeps you on the road while you achieve your fitness goals."
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "USD",
          "invoiceNumber": 0,
          "logoUrl": "https://s3-eu-west-1.amazonaws.com/images.oneshoo.com/dOtgvXvT-IMG_2504.JPG"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": true,
          "qualifications": true,
          "social": true,
          "additional": true,
          "start": true
        },
        "score": 64,
        "parent": null
      },
      "reminder_email_sent": false,
      "welcome_email_sent": true,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": true,
      "provider": "local",
      "active": true,
      "role": "trainer",
      "__v": 17,
      "agent": {
        "family": "Chrome",
        "major": "68",
        "minor": "0",
        "patch": "3440",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/68.0.3440.106 Safari/537.36"
      },
      "lastLogin": 1536681209979,
      "username": "amir_abraham_coachamir"
    },
    {
      "_id": {
        "$oid": "5be19e16bf18d20974b3dc0d"
      },
      "providerId": "10154039720783440",
      "surname": "Longden",
      "firstName": "Chris",
      "email": "chris@exchange.longden.net",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-11-14T12:00:02.414Z"
      },
      "createdAt": {
        "$date": "2018-11-06T13:58:46.192Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/YXNpZADpBWEZAGelZAJanVrVzUxMFFFMHZA3VGlZAdkRKV3dEbmNsbF9wNk5zTkQzYUlLS2pQU3FScEg2clZAHbzllX1ZATMUVPaVBIMGo1TVVXaUZAzYUg5M25fUWQtc1JzUlhEb1M1NXNtbGVVYzN2dFpR/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/G6wom2Lg_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "chris_longden_Q-4Ik8YrI",
      "agent": {
        "family": "Firefox",
        "major": "63",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:63.0) Gecko/20100101 Firefox/63.0"
      },
      "lastLogin": 1541512727007
    },
    {
      "_id": {
        "$oid": "5be8653dbf18d20974b3dc0e"
      },
      "providerId": "10156413206036154",
      "surname": "Ingram",
      "firstName": "Ricky",
      "email": "rickstar1123@hotmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-11-19T12:00:02.157Z"
      },
      "createdAt": {
        "$date": "2018-11-11T17:22:05.721Z"
      },
      "settings": {
        "personal": {
          "facebook": "https://www.facebook.com/app_scoped_user_id/YXNpZADpBWEVlRVd1QTZASZAUN1LWVDUDFWUVBCQjFPRVY2YVYybzNncWctb21SU21kaW9scTlLdmxvTDZAaNndTUDVzRUpBMDNjSVBva0lCZAEFfNWJTb2tlSUtLNHY1OWV6NjAxYm00NTlERmtjQWZAn/",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/ra5Bwcld_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "ricky_ingram",
      "agent": {
        "family": "Safari",
        "major": "12",
        "minor": "0",
        "patch": "0",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Safari/605.1.15"
      },
      "lastLogin": 1541956926323
    },
    {
      "_id": {
        "$oid": "5bfd86f9bf18d20974b3dc17"
      },
      "providerId": "100100899017480671189",
      "surname": "D",
      "firstName": "S &",
      "email": "dtufail400@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2018-12-05T12:00:02.084Z"
      },
      "createdAt": {
        "$date": "2018-11-27T18:03:37.101Z"
      },
      "settings": {
        "personal": {
          "google": "https://plus.google.com/100100899017480671189",
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/bfquOu4G_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "s_and_d",
      "agent": {
        "family": "Chrome",
        "major": "70",
        "minor": "0",
        "patch": "3538",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.110 Safari/537.36"
      },
      "lastLogin": 1543341818197
    },
    {
      "_id": {
        "$oid": "5c407926441cf2125a8e9f18"
      },
      "providerId": "10161490623860515",
      "surname": "Romeu",
      "firstName": "Joey",
      "email": "joey-r@hotmail.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2019-01-25T12:00:01.374Z"
      },
      "createdAt": {
        "$date": "2019-01-17T12:46:30.067Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/26N8VGtd_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "joey_romeu",
      "agent": {
        "family": "Chrome Mobile",
        "major": "71",
        "minor": "0",
        "patch": "3578",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SM-G950F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/71.0.3578.99 Mobile Safari/537.36"
      },
      "lastLogin": 1547741877307
    },
    {
      "_id": {
        "$oid": "5c409732441cf2125a8e9f19"
      },
      "providerId": "1455973324419809",
      "surname": "Spinelli",
      "firstName": "Franco",
      "email": "francspinelli@yahoo.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2019-01-25T12:00:01.372Z"
      },
      "createdAt": {
        "$date": "2019-01-17T14:54:42.380Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/9ngL2umf_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "franco_spinelli",
      "agent": {
        "family": "Chrome",
        "major": "71",
        "minor": "0",
        "patch": "3578",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/71.0.3578.98 Safari/537.36"
      },
      "lastLogin": 1548240291765
    },
    {
      "_id": {
        "$oid": "5c40a2a6441cf2125a8e9f1b"
      },
      "email": "chris@longden.net",
      "firstName": "Chris",
      "surname": "Longden",
      "promoCode": "PTEARLY",
      "activation_code": "cc026247d74286b66aac86d02475dcd0738177c8",
      "salt": "ro8M1ESD/F/2KpIkxikWsw==",
      "hashedPassword": "UbRuPVDjv8DsieKlnWxT8ye+o/H5bCqoF1hgq03+J0iwwx/2eheOgwvraHxe8+VSKNhA1ITVbkCDmkqP9Bfh/A==",
      "lastUpdated": {
        "$date": "2019-01-25T12:00:01.278Z"
      },
      "createdAt": {
        "$date": "2019-01-17T15:43:34.766Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome",
        "major": "71",
        "minor": "0",
        "patch": "3578",
        "source": "Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/71.0.3578.98 Safari/537.36"
      },
      "lastLogin": 1548067811290
    },
    {
      "_id": {
        "$oid": "5ca6f89e179d22090bb96da0"
      },
      "providerId": "10150061036640718",
      "surname": "Reed",
      "firstName": "Crystal",
      "email": "qeliiziptr_1543819427@tfbnw.net",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2019-04-12T12:00:00.686Z"
      },
      "createdAt": {
        "$date": "2019-04-05T06:41:34.467Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/9Hg9Hgmr_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "facebook",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "crystal_reed",
      "agent": {
        "family": "Chrome",
        "major": "73",
        "minor": "0",
        "patch": "3683",
        "source": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/73.0.3683.86 Safari/537.36"
      },
      "lastLogin": 1554446495067
    },
    {
      "_id": {
        "$oid": "5ca6fdcf179d22090bb96da2"
      },
      "email": "123@longden.net",
      "firstName": "Chris",
      "surname": "Longden",
      "promoCode": "PTEARLY",
      "activation_code": "43a5dc49f39839a592e4cb026a98a153e25e013f",
      "salt": "SkeQT1DzTcq3TcFqySWYrw==",
      "hashedPassword": "DWDuAisdqd0tj8UZBDkz8kzS/rACJqri2rn++4/PNPPv8j0jbC7rl5zkwNkpb6wE2t5Wwd7PQcenst13TkzW1Q==",
      "lastUpdated": {
        "$date": "2019-04-12T12:00:00.620Z"
      },
      "createdAt": {
        "$date": "2019-04-05T07:03:43.509Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome",
        "major": "73",
        "minor": "0",
        "patch": "3683",
        "source": "Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/73.0.3683.86 Safari/537.36"
      },
      "lastLogin": 1554447823779
    },
    {
      "_id": {
        "$oid": "5ca9b0bb179d22090bb96da4"
      },
      "email": "chris1@longden.net",
      "firstName": "Chris",
      "surname": "Longden",
      "promoCode": "PTEARLY",
      "activation_code": "61bde481834074b24ced13f6232b00a2299df714",
      "salt": "fEzM31f84LymMQujpq+B5w==",
      "hashedPassword": "YKN5eC0YKePqzo/ynuyigQTmoyqX0Zx1aCNkNSofDV/lRZ4JeZmUBFD4QWAX4wh6yQUjKIZC0JrEhC24B1JUJA==",
      "lastUpdated": {
        "$date": "2019-04-14T12:00:01.339Z"
      },
      "createdAt": {
        "$date": "2019-04-07T08:11:39.239Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome",
        "major": "73",
        "minor": "0",
        "patch": "3683",
        "source": "Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/73.0.3683.86 Safari/537.36"
      },
      "lastLogin": 1554624699501
    },
    {
      "_id": {
        "$oid": "5ce27fa577fb5b0934ff9a9d"
      },
      "email": "matt.bowen@train.fitness",
      "firstName": "Matt",
      "surname": "Bowen",
      "promoCode": "PTEARLY",
      "activation_code": "bd1fd4f1c3cdbc7e2e7dc127610c7c6f81567dde",
      "salt": "mFTdzZhX9VK/5CcW++W+Pg==",
      "hashedPassword": "6rGeTfiGVUSQ4b+Av1RldEArnmkSwGQG9ND+uk90p86xaXBl1f4qmnsr5CLb76PopcNnq3mcpGu8vZ9SJ+kZHg==",
      "lastUpdated": {
        "$date": "2019-05-28T12:00:00.584Z"
      },
      "createdAt": {
        "$date": "2019-05-20T10:21:25.169Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome",
        "major": "74",
        "minor": "0",
        "patch": "3729",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.157 Safari/537.36"
      },
      "lastLogin": 1558347685314
    },
    {
      "_id": {
        "$oid": "5cf113ea77fb5b0934ff9a9f"
      },
      "email": "sepideh.hosseinian@gmail.com",
      "firstName": "Sepideh",
      "surname": "Hosseinian",
      "promoCode": "PTEARLY",
      "activation_code": "22beea91ac19c0dcdf046c4ab2797cad7581728e",
      "salt": "bFqD2Tn5puJXvDPOr7gz9w==",
      "hashedPassword": "VQze13+F1CxvjC3HS9At5IGBAPNAWDM5ykJyLvFf0fK85WTfgGTFXcFMY9CRitxFLrK8D9zZpSJzW46W4t2sBw==",
      "lastUpdated": {
        "$date": "2019-06-07T12:00:00.642Z"
      },
      "createdAt": {
        "$date": "2019-05-31T11:45:46.443Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome Mobile",
        "major": "74",
        "minor": "0",
        "patch": "3729",
        "source": "Mozilla/5.0 (Linux; Android 6.0.1; SM-J700F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.157 Mobile Safari/537.36"
      },
      "lastLogin": 1559303879006
    },
    {
      "_id": {
        "$oid": "5d2427bd77fb5b0934ff9aa3"
      },
      "providerId": "105294708544393992564",
      "surname": "Afrizal",
      "firstName": "Dimas",
      "email": "dimasafrizal966@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2019-07-16T12:00:00.968Z"
      },
      "createdAt": {
        "$date": "2019-07-09T05:35:57.638Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/rBSTHPeN_profilePicture.png"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "dimas_afrizal",
      "agent": {
        "family": "Chrome Mobile",
        "major": "68",
        "minor": "0",
        "patch": "3440",
        "source": "Mozilla/5.0 (Linux; Android 8.1.0; CPH1803 Build/OPM1.171019.026) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/68.0.3440.91 Mobile Safari/537.36"
      },
      "lastLogin": 1562650558063
    },
    {
      "_id": {
        "$oid": "5d498c0677fb5b0934ff9aaa"
      },
      "providerId": "117200474626708697383",
      "surname": "Willson",
      "firstName": "Andrew",
      "email": "enquiries@fitnique.co.uk",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2019-08-14T12:00:00.128Z"
      },
      "createdAt": {
        "$date": "2019-08-06T14:17:42.281Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/XpApMuwr_profilePicture.png"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "andrew_willson",
      "agent": {
        "family": "Chrome",
        "major": "76",
        "minor": "0",
        "patch": "3809",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/76.0.3809.87 Safari/537.36"
      },
      "lastLogin": 1565101361932
    },
    {
      "_id": {
        "$oid": "5d4f0b2f77fb5b0934ff9aac"
      },
      "email": "johncwright88@gmail.com",
      "firstName": "John",
      "surname": "Wright",
      "promoCode": "PTEARLY",
      "activation_code": "254eee213092a7924d024c5af6f3640622c792aa",
      "salt": "K5mPPQrj72jYkO3ybCbavw==",
      "hashedPassword": "i47KCRIQxLQ/1UCA92m5Xboa/rgVYwas0ZgYNWfiaJkX85W8BL81+HwNchcTmiZgjPkQWTVk+X2AfZcMCbSF+A==",
      "lastUpdated": {
        "$date": "2019-08-18T14:04:17.135Z"
      },
      "createdAt": {
        "$date": "2019-08-10T18:21:35.771Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Samsung Internet",
        "major": "9",
        "minor": "4",
        "patch": "0",
        "source": "Mozilla/5.0 (Linux; Android 7.0; SAMSUNG SM-G920F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
      },
      "lastLogin": 1566137057118
    },
    {
      "_id": {
        "$oid": "5d77b6a9dbd9e20939885b47"
      },
      "providerId": "113034084471475595465",
      "surname": "Moreton PT",
      "firstName": "Gav",
      "email": "gav.moreton@gmail.com",
      "activation_code": "X",
      "lastUpdated": {
        "$date": "2019-09-18T12:00:01.666Z"
      },
      "createdAt": {
        "$date": "2019-09-10T14:43:53.297Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0,
          "logoUrl": "https://s3.eu-west-1.amazonaws.com/images.oneshoo.com/sFLzSc3F_profilePicture.jpeg"
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "google",
      "active": true,
      "role": "trainer",
      "__v": 0,
      "username": "gav_moreton_pt",
      "agent": {
        "family": "Chrome",
        "major": "76",
        "minor": "0",
        "patch": "3809",
        "source": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/76.0.3809.132 Safari/537.36"
      },
      "lastLogin": 1568126957600
    },
    {
      "_id": {
        "$oid": "5de161199fbae1091b34cae8"
      },
      "email": "vtandon16@hotmail.com",
      "firstName": "Vik",
      "surname": "Tan",
      "promoCode": "PTEARLY",
      "activation_code": "d99a5ad1c3aa15446f39d55d2044b0209008e799",
      "salt": "RaJSVr8CNW/Ccz86pdFJ9Q==",
      "hashedPassword": "0uarzQ1hsQH2bumBgRykG4LKmOEyME9+QjUtC8PvJUYO9L6B5lEEWN1+JucsQqjZ2Mxd4/M5ZZmLyF7H3vvnRg==",
      "lastUpdated": {
        "$date": "2019-12-07T12:00:01.016Z"
      },
      "createdAt": {
        "$date": "2019-11-29T18:19:05.837Z"
      },
      "settings": {
        "personal": {
          "gender": "male",
          "timezone": "Europe/London",
          "phone": {
            "code": "+44"
          },
          "locations": [],
          "qualifications": [],
          "disciplines": []
        },
        "billing": {
          "termsAndConditions": "Please refer to standard terms and conditions of sale on http://www.standardtermsofsale.com/",
          "paymentTerms": "Please pay all invoices within 7 days of receipt.",
          "defaultCurrency": "GBP",
          "invoiceNumber": 0
        },
        "inherit_from_parent": false,
        "type": "individual",
        "language": "en",
        "hideAssessmentsIntroVideo": false,
        "hidePlansIntroVideo": false,
        "hideBookingIntroVideo": false,
        "hideCalendarIntroVideo": false,
        "hideFreeTrialMessage": false,
        "hideDashboardTour": false,
        "hideWelcomeMessage": false,
        "wizard": {
          "complete": false,
          "qualifications": false,
          "social": false,
          "additional": false,
          "start": false
        },
        "score": 0
      },
      "reminder_email_sent": true,
      "welcome_email_sent": false,
      "feature_in_area": "none",
      "num_votes": 0,
      "trainer_tools_access": false,
      "approved": false,
      "activation_email_sent": false,
      "provider": "local",
      "active": false,
      "role": "trainer",
      "__v": 0,
      "agent": {
        "family": "Chrome",
        "major": "78",
        "minor": "0",
        "patch": "3904",
        "source": "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/78.0.3904.108 Safari/537.36"
      },
      "lastLogin": 1575051545986
    }
  ]
};

export default data;