import React from 'react';
import data from './data/trainer'
import { createUserProfileDocument } from './firebase/firebase.utils.js'

function App() {
  console.log(data.trainers[0]);

  for(var i = 0; i < 1; i++) {
    var item = data.trainers[i];

    console.log(item.firstName, item.surname, item.username, item.role, item.provider,
      item.email, item._id.$oid, item.createdAt, item.lastUpdated.$date)

      createUserProfileDocument(item.firstName, item.surname);

      //console.log(item.settings);
  }

  return (
    <div className="App">
      Under Construction.
    </div>
  );
}

export default App;
