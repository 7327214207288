import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyDDsVPoM8jTJMVkIYYyUTqntrTV5qUuSew",
    authDomain: "oneshoo-1102.firebaseapp.com",
    databaseURL: "https://oneshoo-1102.firebaseio.com",
    projectId: "oneshoo-1102",
    storageBucket: "oneshoo-1102.appspot.com",
    messagingSenderId: "361783859977",
    appId: "1:361783859977:web:2f6d0cdb4099b48e7791d8",
    measurementId: "G-1Q763CQJCC"
}

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account'});

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const createUserProfileDocument = async(firstName, lastName) => {


    

    return null;
}

export default firebase